import React, {Component} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {Button, TextField, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';
import {checkCoupon} from "../../services/PaymentService";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import CouponDetails from "./CouponDetails";

class CouponForm extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            promoCode: "",
            coupon: null,
            isChecking: false
        }
    }

    onSubmit = async() => {
        this.setState({isChecking: true});
        const coupon = await checkCoupon(this.context, this.props, this.state.promoCode, this.props.licenseType);
        if (coupon) {
            this.setState({coupon});
            this.props.onCouponAdd(coupon);
        }
        this.setState({isChecking: false});
    }

    onRemove = () => {
        this.setState({coupon: null, promoCode: ""});
        this.props.onCouponRemove();
    }

    render() {
        return (
            <Grid container spacing={2} justifyContent="center">

                {!this.state.coupon && <>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            <FormattedMessage id="payment.coupon.redeem.subtitle"/>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="couponId"
                            required
                            value={this.state.promoCode}
                            variant="outlined"
                            placeholder={this.props.intl.formatMessage({id: "payment.coupon.textField.placeholder"})}
                            inputProps={{maxLength: 128}}
                            style={{width: "50%"}}
                            onChange={(e) => this.setState({promoCode: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!this.state.promoCode || this.state.isChecking}
                            onClick={() => this.onSubmit()}>
                            <FormattedMessage id="payment.coupon.redeem.button"/>
                        </Button>
                    </Grid>

                </>}

                {this.state.coupon && <>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            <FormattedMessage id="payment.coupon.redeemed.subtitle"/>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <CouponDetails
                            promoCode={this.state.promoCode}
                            coupon={this.state.coupon}
                            currency={this.props.currency}
                            licenseType={this.props.licenseType}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Button onClick={() => this.onRemove()} variant="text" color="primary">
                            <FormattedMessage id="commons.remove.button"/>
                        </Button>
                    </Grid>
                </>}

            </Grid>
        );
    }
}

CouponForm.propTypes = {
    licenseType: PropTypes.string.isRequired,
    onCouponAdd: PropTypes.func.isRequired,
    onCouponRemove: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired
}

export default injectIntl(withSnackbar(CouponForm));
