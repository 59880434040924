import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {GeneralContext} from "../../contexts/GeneralContext";
import {
    Button,
    Card, CardContent, Checkbox, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from "@material-ui/core";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import {DIALOG_TYPE_INFO} from "../../../util/Constants";
import {bodyBackgroundColor} from "../../../util/ColorTheme";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import {Tooltip} from "@mui/material";
import ValidationIcon from "../../validation/ValidationIcon";

class ArticleFilterCategories extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            filterCategoryTypeToEdit: null,
            currentFilterCategories: this.getOldFilterCategories(),
            newOptionName: ""
        };
    }

    getOldFilterCategories = () => {
        return JSON.parse(JSON.stringify(this.props.filterCategories));
    }

    onOptionsSave = () => {
        const newOptions = this.state.currentFilterCategories.find(fc => fc.filterCategoryType === this.state.filterCategoryTypeToEdit)?.filterOptions ?? [];
        this.props.handleFilterCategoryOptionsChanged(this.state.filterCategoryTypeToEdit, [...newOptions]);
        if (!newOptions?.length || newOptions.length < 2) {
            this.props.handleFilterCategoryMultipleChoiceChanged(this.state.filterCategoryTypeToEdit, false);
        }
        this.setState({filterCategoryTypeToEdit: null, currentFilterCategories: this.getOldFilterCategories()});
    }

    formatOptions = (filterCategoryType) => {
        const options = this.props.filterCategories?.find(fc => fc.filterCategoryType === filterCategoryType)?.filterOptions;
        if (!options?.length) {
            return "";
        }
        let chips = [];
        for (let i = 0; i < options.length; i++) {
            chips.push(
                <Grid item key={"filter-category-" + filterCategoryType + "-option-" + options[i].name}>
                    <div style={{borderRadius: 20, border: "1px solid #b3c0c7", backgroundColor: "#f3f6f8",
                        paddingLeft: 20, paddingRight: 20, paddingTop: 2, paddingBottom: 2}}>
                        {options[i].name}
                    </div>
                </Grid>
            );
        }
        return (
            <Grid container spacing={1}>
                {chips}
            </Grid>
        );
    }

    getLocaleFromFilterCategoryType = (filterCategoryType) => {
        if (!filterCategoryType) {
            return "";
        }
        return this.props.intl.formatMessage({id: "constants.FilterCategory." + filterCategoryType});
    }

    getOptionsSelection = () => {
        const filterCategoryType = this.state.filterCategoryTypeToEdit;
        const currentOptions = this.state.currentFilterCategories.find(fc => fc.filterCategoryType === filterCategoryType)?.filterOptions;
        return (
            <>

                <Card style={{padding: "0px", backgroundColor: "#f3f6f8"}}>
                    <CardContent>

                        <Typography style={{marginLeft: 15, marginBottom: 10}} variant="h6" component="div">
                            <FormattedMessage id="articleConfigurator.filterCategories.selectedOptions.title"/>
                        </Typography>

                        {!currentOptions?.length && <Typography variant="subtitle2" style={{marginLeft: 15}}>
                            <FormattedMessage id="articleConfigurator.filterCategories.noOptions.warning"/>
                        </Typography>}

                        <Grid item xs={12} md={6}>

                            {!!currentOptions?.length && <List dense={true}>
                                {currentOptions?.map(option =>
                                    <ListItem key={option.id + option.name}>
                                        <ListItemText
                                            primary={option.name}
                                            secondary={null}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                variant="text"
                                                style={{padding: 5}}
                                                onClick={() => this.removeOption(filterCategoryType, option)}>
                                                <RemoveCircleOutlineOutlinedIcon color="error"/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>}
                        </Grid>
                    </CardContent>
                </Card>

                <Grid container wrap="nowrap" alignItems="center" spacing={2} style={{marginBottom: 20, marginTop: 20}}>
                    <Grid item>
                        <FormattedMessage id="articleConfigurator.filterCategories.options.add.title"/>
                    </Grid>
                    <Grid item>
                        <TextField
                            value={this.state.newOptionName}
                            variant="outlined"
                            onChange={(e) => this.setState({newOptionName: e.target.value})}
                            inputProps={{maxLength: 128}}
                            error={this.isDuplicate(filterCategoryType, this.state.newOptionName)}
                            helperText={this.isDuplicate(filterCategoryType, this.state.newOptionName)
                                ? this.props.intl.formatMessage({id: "articleConfigurator.filterCategories.options.duplicateName"}) : ""}/>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="text"
                            onClick={() => this.addOption(filterCategoryType)}
                            disabled={!this.state.newOptionName || this.isDuplicate(filterCategoryType, this.state.newOptionName)}>
                            <AddIcon/>
                        </Button>
                    </Grid>
                </Grid>

            </>
        );
    }

    isDuplicate = (filterCategoryType, optionName) => {
        return !!this.state.currentFilterCategories?.find(fc => fc.filterCategoryType === filterCategoryType)?.filterOptions?.find(o => o.name === optionName);
    }

    removeOption = (filterCategoryType, option) => {
        let filterCategories = this.state.currentFilterCategories;
        let filterCategory = filterCategories.find(fc => fc.filterCategoryType === filterCategoryType);
        const newFilterOptions = filterCategory.filterOptions?.filter(o => o.id !== option.id || o.name !== option.name);
        filterCategory.filterOptions = newFilterOptions;
        filterCategories = filterCategories.map(fc => fc.filterCategoryType === filterCategoryType ? filterCategory : fc);
        this.setState({currentFilterCategories: filterCategories});
    }

    addOption = (filterCategoryType) => {
        let filterCategories = this.state.currentFilterCategories;
        let filterCategory = filterCategories.find(fc => fc.filterCategoryType === filterCategoryType);
        filterCategory.filterOptions?.push({name: this.state.newOptionName});
        filterCategories = filterCategories.map(fc => fc.filterCategoryType === filterCategoryType ? filterCategory : fc);
        this.setState({currentFilterCategories: filterCategories, newOptionName: ""});
    }

    getMultipleChoiceState = (filterCategoryType) => {
        return this.state.currentFilterCategories.find(fc => fc.filterCategoryType === filterCategoryType)?.isMultipleChoice;
    }

    hasMultipleOptions = (filterCategoryType) => {
        const optionsLength = this.state.currentFilterCategories.find(fc => fc.filterCategoryType === filterCategoryType)?.filterOptions?.length;
        return optionsLength && optionsLength > 1;
    }

    render() {
        return (
            <>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={!!this.state.filterCategoryTypeToEdit}
                    titleId="articleConfigurator.filterCategories.options.title"
                    subtitleId="articleConfigurator.filterCategories.options.subtitle"
                    replacements={{filterCategory: this.state.filterCategoryTypeToEdit
                            ? this.props.intl.formatMessage({id: "constants.FilterCategory." + this.state.filterCategoryTypeToEdit})
                            : ""}}
                    cancelId="commons.cancel.button"
                    onCancel={() => this.setState({filterCategoryTypeToEdit: null, currentFilterCategories: this.getOldFilterCategories()})}
                    actionId="commons.save.button"
                    onAction={() => this.onOptionsSave()}
                    content={this.getOptionsSelection()}/>

                <Card className="child">

                    <CardContent>

                        <Grid container alignItems="flex-start">
                            <Grid item xs>
                                <Typography variant="h6" component="h2">
                                    <FormattedMessage id="articleConfigurator.filterCategories.h1"/>*
                                </Typography>
                            </Grid>
                            <Grid item>
                                <ValidationIcon complete={this.props.isValid} size={30}/>
                            </Grid>
                        </Grid>

                        <Typography color="textSecondary" style={{marginBottom: "20px"}}>
                            <FormattedMessage id="articleConfigurator.filterCategories.h2"/>
                        </Typography>

                        <TableContainer component={Paper} style={{overflowX: "scroll"}}>

                            <Table aria-label="article-filter-table">

                                <TableHead style={{backgroundColor: bodyBackgroundColor}}>
                                    <TableRow>
                                        <TableCell key="filter-type-title">
                                            <FormattedMessage id="articleConfigurator.filterCategories.h1"/>
                                        </TableCell>
                                        <TableCell key="filter-options-title">
                                            <FormattedMessage id="articleConfigurator.filterCategories.selectedOptions"/>
                                        </TableCell>
                                        <TableCell key="filter-edit-title" align="right" width="200px">
                                            <FormattedMessage id="articleConfigurator.filterCategories.edit"/>
                                        </TableCell>
                                        <TableCell key="filter-multiple-choice-title" align="right" width="200px">
                                            <FormattedMessage id="articleConfigurator.filterCategories.multipleChoice"/>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {this.context.appData.filterCategoryTypes
                                        ?.sort((a,b) => this.getLocaleFromFilterCategoryType(a)
                                            .localeCompare(this.getLocaleFromFilterCategoryType(b)))
                                        ?.map(type => (
                                        <TableRow
                                            key={type+"-row"}>

                                            <TableCell component="th" scope="row">
                                                <FormattedMessage id={"constants.FilterCategory." + type}/>
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {this.formatOptions(type)}
                                            </TableCell>

                                            <TableCell align="right" width="200px">
                                                <IconButton onClick={() => this.setState({filterCategoryTypeToEdit: type})}>
                                                    <EditIcon/>
                                                </IconButton>
                                            </TableCell>

                                            <TableCell align="right" width="200px">
                                                <Tooltip title={this.hasMultipleOptions(type)
                                                    ? ""
                                                    : this.props.intl.formatMessage({id: "articleConfigurator.filterCategories.multipleChoice.tooltip"})}>
                                                    <div>
                                                        <Checkbox
                                                            checked={this.getMultipleChoiceState(type)}
                                                            color="primary"
                                                            disabled={!this.hasMultipleOptions(type)}
                                                            onChange={() => this.props.handleFilterCategoryMultipleChoiceChanged(type, !this.getMultipleChoiceState(type))}/>
                                                    </div>
                                                </Tooltip>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>

                        </TableContainer>

                    </CardContent>

                </Card>

            </>
        );
    }
}

export default injectIntl(ArticleFilterCategories);
