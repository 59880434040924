import React, {Component} from 'react';
import {Grid, Typography, Divider, Button, Chip} from '@material-ui/core';
import {FormattedMessage, injectIntl} from 'react-intl';
import {texisionRed} from '../../../util/ColorTheme';
import {GeneralContext} from "../../contexts/GeneralContext";
import {DIALOG_TYPE_WARNING, OPERATING_RESOURCE_CONFIGURATOR_ROUTE} from "../../../util/Constants";
import {withRouter} from "react-router-dom";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import {withSnackbar} from "notistack";
import {deleteResource} from "../../../services/OperatingResourceService";

class OperatingResourceElement extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showDeleteDialog: false
        }
    }

    deleteResource = async() => {
        await deleteResource(this.context, this.props, this.props.operatingResource.id);
        this.setState({showDeleteDialog: false});
        this.props.onDelete();
    }

    render() {
        if (!this.props.operatingResource) {
            return null;
        }
        let features = this.props.operatingResource.features ?? [];
        let uiElementIds = 1;
        return (
            <>

                <TexisionDialog
                    open={this.state.showDeleteDialog}
                    type={DIALOG_TYPE_WARNING}
                    titleId="operatingResources.overview.delete.title"
                    subtitleId="operatingResources.overview.delete.subtitle"
                    replacements={{name: this.props.operatingResource.name}}
                    onCancel={() => this.setState({showDeleteDialog: false})}
                    cancelId="commons.cancel.button"
                    onAction={() => this.deleteResource()}
                    actionId="commons.delete.button"/>

                <Divider/>

                <Grid container style={{paddingTop: 20, paddingBottom: 20}}>

                    <Grid item xs={3} sm={2} style={{display: "flex", alignItems: "center"}}>
                        {!!this.props.operatingResource?.imageVos?.length && this.props.operatingResource.imageVos[0].image?.url
                            && <img
                                alt="OperatingResourceImage"
                                src={this.props.operatingResource.imageVos[0].image.url}
                                style={{maxHeight: 120, maxWidth: "100%"}}/>}
                    </Grid>

                    <Grid item xs={9} sm style={{display: "flex", alignItems: "center", paddingLeft: 20}}>

                        <Grid container direction="column" spacing={1}>

                            <Grid item xs={12}>
                                <Typography variant="h4">
                                    {this.props.operatingResource.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    {this.props.operatingResource.description}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}/>

                            <Grid item xs={12}>

                                <Grid container direction="row" alignItems="flex-start" spacing={1}>

                                    {features.map(feature => {
                                        return <Grid key={uiElementIds++} item><Chip key={uiElementIds++} label={feature.value} color="primary"/></Grid>
                                    })}

                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item style={{display: "flex", alignItems: "center", marginLeft: 20}}>

                        <Button
                            variant="contained"
                            style={{backgroundColor: texisionRed, color: "white"}}
                            onClick={() => this.setState({showDeleteDialog: true})}>
                            <FormattedMessage id="commons.delete.button"/>
                        </Button>

                    </Grid>

                    <Grid item style={{display: "flex", alignItems: "center"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                this.props.history.push({
                                    pathname: OPERATING_RESOURCE_CONFIGURATOR_ROUTE,
                                    state: {operatingResource: this.props.operatingResource}
                                });
                            }}>
                            <FormattedMessage id="commons.edit.button"/>
                        </Button>
                    </Grid>

                </Grid>

            </>
        )
    }
}

export default withSnackbar(withRouter(injectIntl(OperatingResourceElement)));
