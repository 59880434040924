import { useIntl } from "react-intl";

const DividerWithText = ({textId, text}) => {

    const intl = useIntl();

    return (
        <div style={{display: "flex", alignItems: "center"}}>

            <div style={{borderBottom: "2px solid lightgray", width: "100%"}}/>

            <span style={{paddingTop: 10, paddingBottom: 10, paddingRight: 20, whiteSpace: "nowrap",
                paddingLeft: 20, fontWeight: 500, fontSize: 14, color: "lightgray"}}>

                    {text ? text : (textId ? intl.formatMessage({id: textId}) : "")}

            </span>

            <div style={{borderBottom: "2px solid lightgray", width: "100%"}}/>

        </div>
    );
}

export {
    DividerWithText
}
