import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import defaultUserIcon from '../../../resources/default_user_icon.png';

const OfferSummaryCompany = ({user}) => {

    const intl = useIntl();

    const address = () => {
        if (!user || (!user.streetAndNumber && !user.zipCode && !user.city && !user.country)) {
            return intl.formatMessage({id: "offers.overview.noAddress"});
        }
        return <div>
            <div>{user.streetAndNumber ?? ""}</div>
            <div>{(user.zipCode && user.city) ?? ""}</div>
            <div>{user.country ? intl.formatMessage({id: "constants.country." + user.country.toLowerCase()}) : ""}</div>
        </div>;
    }

    return (
        <Accordion key="company-accordion">

            <AccordionSummary 
                expandIcon={<ExpandMoreIcon/>}
                aria-label="Expand-company"
                aria-controls="company-content"
                id="company-header">

                <Typography variant="h3" component="span">
                    <FormattedMessage id="offerSuitability.companyData.title"/>
                </Typography>

            </AccordionSummary>

            <AccordionDetails>

                <Grid container wrap="nowrap" className="offerContentGrid">

                    <Grid item className="offerContentDataTitle">
                        <Grid item>
                            <img 
                                src={user.imageVo?.image?.url ?? defaultUserIcon} 
                                className="offerProfileImage" 
                                alt=""/>
                        </Grid>
                    </Grid>

                    <Grid item xs>
                        <Grid container className="offerContentDataRow" spacing={2}>

                            {/* name */}
                            <Grid item>
                                <div className="offerContentDataItemTitle">
                                    {intl.formatMessage({id: "offers.overview.user." + user.title}) }
                                </div>
                                <div className="proectContentDataItemText">
                                    <Typography variant="h4">
                                        {user.firstName + " " + user.lastName} 
                                    </Typography>
                                </div>
                            </Grid>

                            {/* mail */}
                            <Grid item>
                                <div className="offerContentDataItemTitle">
                                    {intl.formatMessage({id: "offers.overview.email"})}
                                </div>
                                <div className="proectContentDataItemText">
                                    <Typography variant="h4">
                                        <a href={"mailto:" + user.email}>{user.email}</a>
                                    </Typography>
                                </div>
                            </Grid>

                            {/* address */}
                            <Grid item>
                                <div className="offerContentDataItemTitle">
                                    {intl.formatMessage({id: "offers.overview.address"})}
                                </div>
                                <div className="proectContentDataItemText">
                                    <Typography variant="h4">
                                        {address(user)}
                                    </Typography>
                                </div>
                            </Grid>

                            {/* vat id */}
                            <Grid item>
                                <div className="offerContentDataItemTitle">
                                    {intl.formatMessage({id: "offers.overview.vatId"})}
                                </div>
                                <div className="offerContentDataItemText">
                                    <Typography variant="h4">
                                        {user?.vatId ?? intl.formatMessage({id: "offers.overview.noVatId"})}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </AccordionDetails>

        </Accordion>
    );
}

export {
    OfferSummaryCompany
}
