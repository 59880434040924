import { Checkbox, Chip, Grid, TextField } from "@material-ui/core";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { canSaveRatingCriteria, foundZeroWeighting, hasMissingRatingArguments, ratingCriteriaSum } from "../../util/ProcedureUtil";
import ProcedureAccordion from "./ProcedureAccordion";
import React, { useEffect, useState } from "react";
import { getRatingArguments, RATING_CATEGORY } from "../../util/Constants";
import { texisionRed } from "../../util/ColorTheme";
import { RatingArgumentsSelection } from "./RatingArgumentsSelection";
import { RatingArgumentIcon } from "./RatingArgumentIcon";
import { texisionBlueGrayDark } from "../../util/ColorTheme";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const RatingCriteriaValidation = {
    VALID: "VALID",
    MAXIMUM_EXCEEDED: "MAXIMUM_EXCEEDED",
    INVALID_PRICE_WEIGHTING: "INVALID_PRICE_WEIGHTING",
    ZERO_VALUE_FOUND: "ZERO_VALUE_FOUND",
    SUM_TOO_SMALL: "SUM_TOO_SMALL"
}

const RatingCriteriaSelection = ({currentRatingCriteria, onChange, onSave, projectId}) => {

    const [ratingCriteria, setRatingCriteria] = useState(currentRatingCriteria
        ? Object.values(JSON.parse(JSON.stringify(currentRatingCriteria))) : []);
    const [hasChanges, setHasChanges] = useState(false);
    const [validation, setValidation] = useState(RatingCriteriaValidation.VALID);
    const [categoryForArguments, setCategoryForArguments] = useState(null);

    const intl = useIntl();

    useEffect(() => {
        setRatingCriteria(currentRatingCriteria ? Object.values(JSON.parse(JSON.stringify(currentRatingCriteria))) : []);
    }, [currentRatingCriteria]);


    useEffect(() => {
        const validateWeighting = () => {
            const sum = ratingCriteriaSum(ratingCriteria);
            const priceWeighting = ratingCriteria?.find(c => c.ratingCategory === RATING_CATEGORY.PRICE)?.weightPercent;
            if (foundZeroWeighting(ratingCriteria)) {
                setValidation(RatingCriteriaValidation.ZERO_VALUE_FOUND);
            } else if (sum < 100) {
                setValidation(RatingCriteriaValidation.SUM_TOO_SMALL);
            } else if (sum === 100 && priceWeighting >= 30) {
                setValidation(RatingCriteriaValidation.VALID);
            } else if (sum === 100) {
                setValidation(RatingCriteriaValidation.INVALID_PRICE_WEIGHTING);
            } else {
                setValidation(RatingCriteriaValidation.MAXIMUM_EXCEEDED);
            }
        }
        validateWeighting();
    }, [ratingCriteria]);

    if (!ratingCriteria) {
        return <div/>;
    }

    const validationText = () => {
        const sum = ratingCriteriaSum(ratingCriteria);
        const invalid = [RatingCriteriaValidation.MAXIMUM_EXCEEDED, RatingCriteriaValidation.SUM_TOO_SMALL]
            .includes(validation);
        return <div style={{
            marginLeft: 10, 
            fontWeight: "bolder", 
            color: invalid ? texisionRed : null
            }}>
            <FormattedMessage id="procedure.ratingCriteria.maximumExceeded" values={{sum: sum}}/>
            {hasCheckboxError(RATING_CATEGORY.PRICE) 
            && <div><FormattedMessage id="procedure.ratingCriteria.offerPriceNotSelected" values={{sum: sum}}/></div>}
        </div>;
    }

    const argumentsValidationText = () => {
        if (hasMissingRatingArguments(ratingCriteria)) {
            return <div style={{marginLeft: 10, fontWeight: "bolder", color: texisionRed}}>
                <FormattedMessage id="procedure.ratingCriteria.missingArguments"/>
            </div>;
        } else {
            return "";
        }
    }

    const validationTooltip = () => {
        switch (validation) {
            case RatingCriteriaValidation.INVALID_PRICE_WEIGHTING:
                return <FormattedMessage id="procedure.ratingCriteria.invalidPriceWeighting"/>;
            case RatingCriteriaValidation.ZERO_VALUE_FOUND:
                return <FormattedMessage id="procedure.ratingCriteria.zeroValueFound"/>;
            case RatingCriteriaValidation.SUM_TOO_SMALL:
                return <FormattedMessage id="procedure.ratingCriteria.sumTooSmall"/>;
            default:
                return "";
        }
    }

    const onRatingCriteriaChange = (checked, category) => {
        if (checked) {
            let weightPercent = 1;
            if (category === RATING_CATEGORY.PRICE) {
                weightPercent = 30;
            }
            let criterion = {projectId: projectId, ratingCategory: category, weightPercent: weightPercent, ratingArguments: []};
            setRatingCriteria([...ratingCriteria, criterion]);
            if (category !== RATING_CATEGORY.PRICE) {
                setCategoryForArguments(criterion);
            }
        } else {
            setRatingCriteria([...ratingCriteria.filter(c => c.ratingCategory !== category)]);
        }
        setHasChanges(true);
        onChange(true);
    }

    const onRatingArgumentsChange = (categoryName, ratingArguments) => {
        const criterion = ratingCriteria.find(c => c.ratingCategory === categoryName);
        if (criterion && ratingArguments) {
            criterion.ratingArguments = [...ratingArguments];
        }
        setRatingCriteria([...ratingCriteria]);
        setHasChanges(true);
        onChange(true);
        setCategoryForArguments(null);
    }

    const onWeightingChange = (category, percent) => {
        const percentRegex = /^[1-9][0-9]?$|^100$/;
        if (percent === "" || percent === "0" || percentRegex.test(percent)) {
            const criterion = ratingCriteria.find(c => c.ratingCategory === category);
            criterion.weightPercent = percent;
            setRatingCriteria([...ratingCriteria]);
            setHasChanges(true);
            onChange(true);
        }
    }

    const onWeightingBlur = (category, percent) => {
        if (percent === "") {
            const criterion = ratingCriteria.find(c => c.ratingCategory === category);
            criterion.weightPercent = 0;
            setRatingCriteria([...ratingCriteria]);
            setHasChanges(true);
            onChange(true);
        }
    }

    const hasTextFieldError = (categoryName) => {
        const foundCategory = ratingCriteria?.find(c => c.ratingCategory === categoryName);
        return foundCategory?.weightPercent < 1 
            || (categoryName === RATING_CATEGORY.PRICE && foundCategory?.weightPercent < 30);
    }

    const helperText = (categoryName) => {
        if (hasTextFieldError(categoryName) && categoryName === RATING_CATEGORY.PRICE) {
            return <FormattedMessage id="procedure.ratingCriteria.price.errorText"/>;
        } else if (hasTextFieldError(categoryName)) {
            return <FormattedMessage id="procedure.ratingCriteria.nonPrice.errorText"/>;
        } else {
            return "";
        }
    }

    const hasCheckboxError = (categoryName) => {
        return categoryName === RATING_CATEGORY.PRICE 
            && !ratingCriteria?.find(c => c.ratingCategory === categoryName);
    }

    const getInitialArguments = () => {
        if (!categoryForArguments) {
            return [];
        }
        if (!categoryForArguments.ratingArguments || categoryForArguments.ratingArguments.length < 1) {
            return getRatingArguments(categoryForArguments.ratingCategory).map(a => intl.formatMessage({id: "constants.RatingType." + a}));
        } else {
            return categoryForArguments.ratingArguments;
        }
    }

    const cancelArguments = () => {
        if (!categoryForArguments.ratingArguments || categoryForArguments.ratingArguments.length < 1) {
            setRatingCriteria([...ratingCriteria.filter(c => c.ratingCategory !== categoryForArguments.ratingCategory)]);
        }
        setCategoryForArguments(null);
    }

    return (<React.Fragment>
        <RatingArgumentsSelection
            key={categoryForArguments?.ratingCategory} 
            criterionName={categoryForArguments?.ratingCategory}
            currentArguments={getInitialArguments()} 
            onSave={(categoryName, newArguments) => {
                onRatingArgumentsChange(categoryName, newArguments);
                setHasChanges(true);
            }}
            onCancel={() => cancelArguments()}/>

        <ProcedureAccordion
            mandatory={true}
            complete={canSaveRatingCriteria(currentRatingCriteria ? Object.values(currentRatingCriteria) : [])}
            titleId="procedure.conditions.ratingCriteria.title"
            subtitle={intl.formatMessage({id: "procedure.conditions.ratingCriteria.subtitle"})}
            saveButtonId="procedure.ratingCriteria.save.button"
            onSave={() => {
                onSave(ratingCriteria);
                setHasChanges(false);
                onChange(false);
            }}
            tooltip={validationTooltip()}
            disableSaveButton={!hasChanges || !canSaveRatingCriteria(ratingCriteria)}>
            <Grid container>
                {Object.values(RATING_CATEGORY)
                .sort((a,b) => intl.formatMessage({id: "constants.RatingCategory." + a}).localeCompare(intl.formatMessage({id: "constants.RatingCategory." + b})))
                .map(category => {
                    const criterion = ratingCriteria?.find(c => c.ratingCategory === category);
                    return <Grid key={category} item xs={12} style={{marginBottom: 20}}>
                        <Grid container justifyContent="flex-start" wrap="nowrap" alignItems="top" spacing="2">
                            <Grid item xs={2} style={{minWidth: 200}}>
                                <Grid container wrap="nowrap" alignItems="center" style={{wordBreak: "break-word"}}>
                                    <Checkbox
                                        color="primary"
                                        style={{color: hasCheckboxError(category) ? "red" : null}}
                                        checked={!!criterion} 
                                        onChange={(event) => onRatingCriteriaChange(event.target.checked, category)}/>
                                    <FormattedMessage id={"constants.RatingCategory." + category}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    helperText={helperText(category)}
                                    error={hasTextFieldError(category)}
                                    id={"weighting-" + category}
                                    disabled={!criterion}
                                    value={criterion?.weightPercent ?? ""}
                                    label={intl.formatMessage({id: "procedure.conditions.ratingCriteria.weightingFactor"})}
                                    variant="outlined"
                                    style={{minWidth: 120}}
                                    inputProps={{maxLength: 3}}
                                    onChange={(e) => onWeightingChange(category, e.target.value)}
                                    onBlur={(e) => onWeightingBlur(category, e.target.value)}/>
                            </Grid>
                            {(category !== RATING_CATEGORY.PRICE && !!criterion) && <Grid item xs style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap"}}>

                                {criterion.ratingArguments
                                ?.sort((a,b) => a.localeCompare(b))
                                .map(argument => {
                                    return <Chip style={{marginRight:5, marginBottom:5}} label={argument} variant="outlined"
                                                icon={<RatingArgumentIcon 
                                                backgroundColor={texisionBlueGrayDark}
                                                criterionName={criterion.ratingCategory} 
                                                ratingArgument={argument}/>} />
                                })}
                            </Grid>}

                            {(category !== RATING_CATEGORY.PRICE && !!criterion) && <Grid item>
                                <Tooltip title={intl.formatMessage({id: "procedure.ratingCriteria.tooltip"})}>
                                    <IconButton
                                        color="primary"
                                        variant="text"
                                        size={"small"}
                                        onClick={() => setCategoryForArguments(criterion)}>
                                        <EditIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>}

                        </Grid>
                    </Grid>})}
            </Grid>
            {validationText()}
            {argumentsValidationText()}
        </ProcedureAccordion>
    </React.Fragment>);
}

export default injectIntl(RatingCriteriaSelection);
