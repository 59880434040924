import { Collapse, List } from "@material-ui/core";
import React, { Component } from "react";
import {
    INVOLVED_BIDDERS_ROUTE,
    LAST_OFFER_PROJECT_ID,
    OFFER_DOCUMENT_ROUTE,
    OFFER_RATING_ROUTE,
    OFFER_RESULT_ROUTE,
    OFFER_SUITABILITY_ROUTE,
    PRICE_COMPARISON_ROUTE,
    PROJECT_STATUS_IS_PUBLISHED,
} from "../../../util/Constants";
import { GeneralContext } from "../../contexts/GeneralContext";
import NavBarItem from "./NavBarItem";
import {injectIntl} from "react-intl";

class NavBarOfferRound extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {roundOpen: false};
    }

    render() {
        // the project of this offer round
        const offerProject = this.props.offerProject;
        let disabledTooltipSuffix = offerProject?.status;
        let isNotPublished = offerProject?.status !== PROJECT_STATUS_IS_PUBLISHED;

        return (
            <>
                <NavBarItem
                    level={2}
                    open={this.state.roundOpen}
                    onClick={() => {
                        this.setState({roundOpen: !this.state.roundOpen});
                        localStorage.setItem(LAST_OFFER_PROJECT_ID, offerProject?.id);
                    }}
                    directTitle={(offerProject?.offerIndex + 1) + ". "
                        + this.props.intl.formatMessage({id: "navBar.offerRound.title"})}
                    icon="icon-note-hook"
                    disabled={isNotPublished}
                    disabledTitle={"navBar.offer.disabled.tooltip." + disabledTooltipSuffix}/>

                <Collapse in={this.state.roundOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        <NavBarItem
                            level={3}
                            pathname={OFFER_DOCUMENT_ROUTE}
                            offerProject={offerProject}
                            disabled={isNotPublished}
                            disabledTitle={"navBar.offerDocuments.disabled.tooltip." + disabledTooltipSuffix}/>

                        <NavBarItem
                            level={3}
                            pathname={INVOLVED_BIDDERS_ROUTE}
                            offerProject={offerProject}
                            disabled={isNotPublished}
                            disabledTitle={"navBar.involvedBidders.disabled.tooltip." + disabledTooltipSuffix}/>

                        <NavBarItem
                            level={3}
                            pathname={PRICE_COMPARISON_ROUTE}
                            offerProject={offerProject}
                            disabled={isNotPublished}
                            disabledTitle={"navBar.offer.disabled.tooltip." + disabledTooltipSuffix}
                        />

                        <NavBarItem
                            level={3}
                            pathname={OFFER_SUITABILITY_ROUTE}
                            offerProject={offerProject}
                            disabled={isNotPublished}
                            disabledTitle={"navBar.offer.disabled.tooltip." + disabledTooltipSuffix}/>

                        <NavBarItem
                            level={3}
                            pathname={OFFER_RATING_ROUTE}
                            offerProject={offerProject}
                            disabled={isNotPublished}
                            disabledTitle={"navBar.offer.disabled.tooltip." + disabledTooltipSuffix}/>

                        <NavBarItem
                            level={3}
                            pathname={OFFER_RESULT_ROUTE}
                            offerProject={offerProject}
                            disabled={isNotPublished}
                            disabledTitle={"navBar.offer.disabled.tooltip." + disabledTooltipSuffix}/>

                    </List>
                </Collapse>
            </>
        );
    }
}

export default injectIntl(NavBarOfferRound);
