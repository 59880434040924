import {PopupModal, useCalendlyEventListener} from "react-calendly";
import {useContext} from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import {postAsyncCatch} from "../../services/BackendService";
import {useIntl} from "react-intl";
import { useSnackbar } from "notistack";
import {getPaymentsAndEvents} from "../../services/PaymentService";

export const CalendlyPopup = (props) => {

    const context = useContext(GeneralContext);
    const intl = useIntl();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const operationId = props.operationId;
    const eventType = props.eventType;

    useCalendlyEventListener({
        onEventScheduled: props.showCalendly ? async(e) => {
            const eventUri = e.data?.payload?.event?.uri;
            const inviteeUri = e.data?.payload?.invitee?.uri;
            const eventId = eventUri?.substring(eventUri?.lastIndexOf("/") + 1);
            const inviteeId = inviteeUri?.substring(inviteeUri?.lastIndexOf("/") + 1);
            if (eventId !== null && eventId !== undefined && eventType && operationId !== null && operationId !== undefined) {
                let operationEvent = {operationId, eventId, inviteeId, eventType};
                const success = await postAsyncCatch(context, "/operation/event/" + operationId.toString(), operationEvent,
                    {intl, enqueueSnackbar, closeSnackbar}, true);
                if (success) {
                    await getPaymentsAndEvents(context);
                }
            }
        } : null
    });

    const calendlyBaseUrl = context.appData.calendlyBaseUrl;
    const name = (context.currentUser.firstName ?? "") + " " + (context.currentUser.lastName ?? "");
    const phoneNumber = (context.currentUser.phoneNumber ?? "").replace("0","+49");
    return (
        <PopupModal
            open={props.showCalendly}
            url={calendlyBaseUrl + props.eventDuration}
            textColor="#ffffff"
            color="#00a2ff"
            onModalClose={() => props.onClose()}
            prefill={{email: context.currentUser.username, name: name, location: phoneNumber}}
            rootElement={document.getElementById("root")}
        />
    );
}
