import React, {Component} from "react";
import {Grid, Switch, Tooltip} from "@material-ui/core";
import {USE_ALTERNATE_ARTICLE_NAMES} from "../../util/Constants";
import {FormattedMessage, injectIntl} from "react-intl";
import InfoIcon from "@mui/icons-material/Info";
import {texisionBlueGrayDark} from "../../util/ColorTheme";
import {GeneralContext} from "../contexts/GeneralContext";

class ArticleNameSwitch extends Component {

    static contextType = GeneralContext;

    handleArticleNameSwitch = async(value) => {
        await this.context.setUserStateValue(USE_ALTERNATE_ARTICLE_NAMES, value);
    }

    render() {
        return (
            <Grid container alignItems="center" style={{marginBottom: 20}}>
                <Switch
                    checked={!!this.context.getUserStateValue(USE_ALTERNATE_ARTICLE_NAMES)}
                    onChange={(e) => this.handleArticleNameSwitch(e.target.checked)}
                    name="alternate-article-name-switch"
                    inputProps={{'aria-label': 'secondary checkbox'}}/>
                <FormattedMessage id="article.alternateNames.switch"/>
                <Tooltip
                    title={this.props.intl.formatMessage({id: "article.alternateNames.tooltip"})}
                    style={{whiteSpace: "pre-line"}}>
                    <InfoIcon style={{marginLeft: 10, color: texisionBlueGrayDark}}/>
                </Tooltip>
            </Grid>
        );
    }
}

export default injectIntl(ArticleNameSwitch);
