import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ArticleFeaturePanel from './CustomerArticleFeaturePanel';

import '../../apps/App.css';



import {validateAllFeaturesHaveValues} from '../../../util/Util';
import {GeneralContext} from "../../contexts/GeneralContext";


class CustomerArticleContent extends Component {

    static contextType = GeneralContext;

    handleAbortEdit = () => {
        this.props.onCancel();
    }

    handleChange = (propertyName, value) => {
        this.props.onDataChange(propertyName, value);
    }

    featureCallback = (features) => {
        this.props.onDataChange("features", features);
    }

    render() {
        const description = this.props.formData.description;
        const volumeType = this.props.formData.volumeType;
        const features = this.props.formData.features;
        const volume = this.props.formData.volume;

        let buttonText = this.props.isInEditMode ? this.props.intl.formatMessage({id: "commons.apply.button"}) :
                    this.props.intl.formatMessage({id: "customerArticle.create.buttontext.create"});
        let error = null;

        return (
            <div style={{padding: "20px 0"}}>

                <Typography color="secondary" style={{marginBottom: "20px", marginTop: "20px"}}>
                    <FormattedMessage id="customerArticle.create.props.title"/>
                </Typography>

                <Typography color="textSecondary" style={{marginBottom: "20px"}}>
                    <FormattedMessage id={this.props.hideVolume ? "customerArticle.create.props.noVolume.subtitle" : "customerArticle.create.props.subtitle"}/>
                </Typography>

                <Grid container direction="row" spacing={1} alignItems="center">

                    <Grid item xs={6}>
                        <TextField
                            required
                            id="description"
                            value={description}
                            label={this.props.intl.formatMessage({id: "customerArticle.create.props.description"})}
                            variant="filled"
                            inputProps={{maxLength: 300}}
                            onChange={(e) => this.handleChange("description", e.target.value)}
                            fullWidth={true}
                            />
                    </Grid>

                    {!this.props.hideVolume && <Grid item xs={3}>
                        <TextField
                            id="volume"
                            value={volume}
                            label={this.props.intl.formatMessage({id: "customerArticle.create.props.volume"})}
                            variant="filled"
                            inputProps={{maxLength: 10}}
                            onChange={(e) => {
                                const positivNumberRegex = /^[0-9\b]+$/;
                                if (e.target.value === '' || positivNumberRegex.test(e.target.value)) {
                                    this.handleChange("volume", e.target.value);
                                }
                            }}
                            fullWidth={true}
                        />
                    </Grid>}

                    <Grid item xs={3}>
                        <FormControl variant="outlined" style={{width: "100%"}} error={error}>
                            <InputLabel id="volumenTypeLabel">
                                <FormattedMessage id="customerArticle.table.header.volumeType"/> *
                            </InputLabel>
                            <Select
                                native
                                value={volumeType}
                                onChange={(e) => this.handleChange("volumeType", e.target.value)}
                                style={{width: "100%"}}
                                label={this.props.intl.formatMessage({id: "customerArticle.table.header.volumeType"})}>
                                <option aria-label="None" value="" />
                                {
                                    this.context.appData.volumeTypes.map((volumeType) => {
                                        let optionValue = this.props.intl.formatMessage({id: "constants.VolumeType." + volumeType});
                                        return <option key={volumeType} value={volumeType}>{optionValue}</option>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color="secondary" style={{marginBottom: "20px", marginTop: "20px"}}>
                            <FormattedMessage id="customerArticle.create.features.title"/>
                        </Typography>
                        <Typography color="textSecondary">
                            {"Bei gewissen Artikeln können spezielle Liefer- oder Bearbeitungsarten gefordert sein. An dieser Stelle können sie diese Anforderungen hinterlegen."}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <ArticleFeaturePanel features={features} 
                            onChange={this.featureCallback} 
                            allFeatureTypes={this.context.appData.customerArticleFeatures}
                            valuesEndpoint="customerarticle/featureValues/"
                            i18nPrefix={"constants.ArticleFeatureType."}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={1} justifyContent="flex-end">

                            <Grid item>
                                <Button
                                    style={{marginTop: "40px"}}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => this.handleAbortEdit()}>
                                    <FormattedMessage id="commons.cancel.button"/>
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    style={{marginTop: "40px"}}
                                    color="primary"
                                    onClick={() => this.props.onSubmit()}
                                    disabled={
                                        this.props.formData.description === "" ||
                                        this.props.formData.volumeType === "" ||
                                        validateAllFeaturesHaveValues(features)}>
                                    {buttonText}
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default injectIntl(CustomerArticleContent);
