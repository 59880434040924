import React, {Component} from 'react';

class ArticleImage extends Component {

    render() {
        const thumbnail = this.props.article?.images?.length ? this.props.article?.images[0]?.thumbnail?.url : null;
        if (!thumbnail) {
            return null;
        } else {
            return (
                <img
                    src={thumbnail}
                    alt={thumbnail}
                    onClick={() => this.props.onClick()}
                    style={{width: "auto", maxHeight: "50px", cursor: "pointer"}}/>
            );
        }
    }
}

export {ArticleImage}
