import {deleteAsyncCatch, getAsyncCatch, postAsyncCatch, putAsyncCatch} from "./BackendService";
import {DELIVERY, DELIVERY_PICKUP} from "../util/Constants";

export async function loadAddress(context, props, addressId) {
    const address = await getAsyncCatch(context, "/address/" + addressId, props);
    return address ? address : null;
}

export async function getUnitAddresses(context, props, unitId) {
    const businessUnitId = unitId !== null && unitId !== undefined ? unitId : context.appData.activeUnitId;
    const addresses = await getAsyncCatch(context, "/unit/" + businessUnitId + "/addresses", props);
    return addresses ? addresses : [];
}

export async function createAddress(context, props, address) {
    // return value is a list because you can create the delivery and pickup address in one call by selecting the
    // address type option "DELIVERY_PICKUP"
    const createdAddresses = await postAsyncCatch(context, "/address/" + context.appData.activeProjectId, address, props);
    return createdAddresses ? createdAddresses : [];
}

export async function updateAddress(context, props, address) {
    const updatedAddress = await putAsyncCatch(context, "/address/" + context.appData.activeProjectId, address, props);
    return updatedAddress ? updatedAddress : null;
}

export async function deleteAddress(context, props, addressId) {
    return await deleteAsyncCatch(context, "/address/" + context.appData.activeProjectId + "/" + addressId, props);
}

export function getDeliveryAddressesForUnit(context, unitId) {
    return context.appData.unitIdToDeliveryAddressesMap?.get(unitId)?.filter(a => [DELIVERY, DELIVERY_PICKUP].includes(a.addressType));
}
