import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {CATEGORY, SUB_CATEGORY, ARTICLE_CATEGORY} from '../../../../util/Constants';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import '../../../../css/ArticleOffsetting.css';
import {GeneralContext} from "../../../contexts/GeneralContext";


class ResidentsLaundryOffsettingEntry extends Component {

    static contextType = GeneralContext;

    render() {
        let label = null;
        let className = "";
        switch (this.props.category) {
            case CATEGORY:
                className = "category pl-2 pt-1 pb-1";
                label = <Typography variant="h6">
                    {this.props.name}
                </Typography>
                break;
            case SUB_CATEGORY:
                className = "subCategory pl-2 bold";
                label = <Typography variant="h4">
                    {this.props.name}
                </Typography>
                break;
            case ARTICLE_CATEGORY:
                className = "articleCategory pl-2";
                label = <Typography className="pl-2">
                    {this.props.name}
                </Typography>
                break;
            default:
                break;
        }
        return (
            <div>
                <Grid container alignItems="center">
                    <Grid item xs={5} className={className}>
                        {label}
                    </Grid>
                    <Grid item xs={7} className={className}>
                        <div style={{paddingTop: this.props.category === CATEGORY ? "8px" : "0px"}}>
                            {this.props.intl.formatMessage({id: "constants.ArticleOffsetting.DELIVERED_QUANTITY"})}
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default injectIntl(ResidentsLaundryOffsettingEntry);
