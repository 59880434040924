import React, {Component} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import Paper from "@material-ui/core/Paper";
import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@material-ui/core";
import {bodyBackgroundColor} from "../../../util/ColorTheme";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import {featuresText} from "../../../util/Util";

class OperatingResourcesSubCategory extends Component {

    static contextType = GeneralContext;

    render() {
        return <>
            <TableContainer
                component={Paper}
                style={{overflowX: "scroll"}}>

                <Table aria-label="operating-resource-table-added">

                    <TableHead style={{backgroundColor: bodyBackgroundColor}}>

                        <TableRow>
                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.image"})}</TableCell>
                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.name"})}</TableCell>
                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.description"})}</TableCell>
                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.features"})}</TableCell>
                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.actions"})}</TableCell>
                        </TableRow>

                    </TableHead>

                    <TableBody>

                        {this.props.operatingResources
                            .filter((r) => (!this.props.selectedResource || r.id === this.props.selectedResource.operatingResourceId))
                            .sort((a,b) => a.name.localeCompare(b.name))
                            .map(resource =>

                                <TableRow key={"available-" + resource.id}>

                                    <TableCell component="th" scope="row">
                                        {!!resource.imageVos?.length && resource.imageVos[0].image?.url && <img
                                            alt="OperatingResourceImage"
                                            src={resource.imageVos[0].image?.url}
                                            style={{height: 90, width: "auto"}}/>}
                                    </TableCell>

                                    <TableCell>{resource.name}</TableCell>

                                    <TableCell>{resource.description}</TableCell>

                                    <TableCell>
                                        <Grid container direction="row" alignItems="flex-start" spacing={1}>
                                            {featuresText(resource.features)}
                                        </Grid>
                                    </TableCell>

                                    <TableCell>
                                        <Tooltip title={this.props.intl.formatMessage({id: "commons.add.button"})}>
                                            <IconButton
                                                disabled={this.props.readOnly}
                                                onClick={(e) => this.props.onClick(resource, e.currentTarget)}>
                                                {this.props.selectedResource ? <DeleteIcon/> : <AddIcon/>}
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>

                                </TableRow>
                            )
                        }

                    </TableBody>

                </Table>

            </TableContainer>
        </>
    }
}
OperatingResourcesSubCategory.propTypes = {
    operatingResources: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired
};
export default injectIntl(OperatingResourcesSubCategory);