import React, {Component} from 'react';

import {
    buildRouteWithPathVariable,
    LAST_OPERATION_ID,
    LAST_PROJECT_ID,
    ORDER_CONFIGURATION_ROUTE,
    ORDER_DASHBOARD_ROUTE,
    ORDER_UNIT_ROUTE
} from '../../../util/Constants';

import {Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import { GeneralContext } from '../../contexts/GeneralContext';
import { injectIntl } from 'react-intl';
import NavBar from '../shared/NavBar';
import NavBarItem from '../shared/NavBarItem';
import { withRouter } from 'react-router-dom';
import {getPaymentsAndEvents} from "../../../services/PaymentService";
import {getOperationByProjectId} from "../../../services/OperationService";
import {loadOrderDataOffline, loadOrderDataOnline} from "../../../services/OrderService";

class OrderNavBarDesktop extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            drawerMobileOpen: false
        }
    }

    setActiveOperation = async(projectId) => {
        if (projectId !== null && projectId !== undefined) {
            localStorage.setItem(LAST_PROJECT_ID, projectId);
            this.context.setActiveProjectId(projectId);
            
            if (this.context.offline) {
                await loadOrderDataOffline(this.context);
            } else {
                const operations = this.context.appData.operations;
                const operationId = getOperationByProjectId(this.context, projectId).id;
                localStorage.setItem(LAST_OPERATION_ID, operationId);
                await getPaymentsAndEvents(this.context);
                await loadOrderDataOnline(this.context, operations, operationId);
            }

            this.props.history.push(ORDER_DASHBOARD_ROUTE);
        }
    }

    handleNavigation = (unitId) => {
        this.context.setActiveUnitId(unitId);
        this.props.history.push(buildRouteWithPathVariable(ORDER_UNIT_ROUTE, unitId));
    }

    sideMenuItems = () => {
        const projects = this.context.appData.allProjects?.filter(p => p.businessUnits?.units?.length);

        const hasProjects = projects?.length > 0;

        const activeProject = projects?.find(p => p.id === this.context.appData.activeProjectId);
        return (
            <>
                <div style={{margin: 15, marginTop: 48}}>
                    <FormControl fullWidth>

                        <InputLabel 
                            id="project"
                            disabled={!hasProjects}>
                            {this.props.intl.formatMessage({id: hasProjects 
                                ? "navBar.order.select.placeholder" : "navBar.order.select.empty"})}
                        </InputLabel>

                        <Select
                            labelId="project"
                            label={this.props.intl.formatMessage({id: hasProjects 
                                ? "navBar.order.select.placeholder" : "navBar.order.select.empty"})}
                            value={activeProject?.name ?? ""}
                            disabled={!hasProjects}>

                            {projects?.sort((a,b) => a.name.localeCompare(b.name))?.map(p =>
                                <MenuItem 
                                    key={"order-project" + p.id} 
                                    value={p.name}
                                    onClick={() => this.setActiveOperation(p.id)}>
                                    {p.name}
                                </MenuItem>
                            )}

                        </Select>

                    </FormControl>
                </div>

                {activeProject?.businessUnits?.units?.sort((a,b) => a.name.localeCompare(b.name))?.map(u =>
                    <NavBarItem
                        key={"business-unit-order-" + u.id}
                        level={1}
                        icon="icon-building"
                        onClick={() => this.handleNavigation(u.id)}
                        directTitle={u.name}
                        pathname={buildRouteWithPathVariable(ORDER_UNIT_ROUTE, u.id)}
                        unitId={u.id}/>
                )}

                <Divider style={{width: "100%"}}/>

                <NavBarItem
                    key="order-settings"
                    level={1}
                    icon="icon-cog"
                    directTitle={this.props.intl.formatMessage({id: "order.settings.title"})}
                    pathname={ORDER_CONFIGURATION_ROUTE} />
            </>
        );
    }

    render() {
        return (
            <NavBar mobile={this.props.mobile} sideMenuItems={this.sideMenuItems()}/>
        );
    }
}

export default withRouter(injectIntl(OrderNavBarDesktop));
