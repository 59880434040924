import { Card, CardContent, Typography } from "@material-ui/core";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ProfilePanel from "../profile/ProfilePanel";

class ArticleProfiles extends Component {

    render() {
        return (
            <Card className="child">

                <CardContent>

                    <Typography variant="h6" component="h2">
                        <FormattedMessage id="articleConfigurator.profiles.h1"/>
                    </Typography>

                    <Typography color="textSecondary">
                        <FormattedMessage id="articleConfigurator.profiles.h2"/>
                    </Typography>

                    <ProfilePanel 
                        profileIds={this.props.profileIds}
                        allDisabled={true}
                        onChange={this.props.handleProfileChanged}/> 

                </CardContent>

            </Card>
        );
    }
}

export default injectIntl(ArticleProfiles);
