import { Card, IconButton } from "@material-ui/core";
import { Component } from "react";
import { GeneralContext } from "../../contexts/GeneralContext";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {isOfferUser} from "../../../services/UserService";
import {getActiveOperation} from "../../../services/OperationService";
import {disableNewPriceItemForTenderUser} from "../../../services/PriceOfferService";
import {isCockpit} from "../../../util/Util";

class ArticleButtonCell extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.isNewCockpitItemForTenderUser = false;
    }

    componentDidMount() {
        this.isNewCockpitItemForTenderUser = isCockpit()
            && isOfferUser(getActiveOperation(this.context)?.bidder)
            && disableNewPriceItemForTenderUser(this.context, this.props.priceItem, this.props.exportType);
    }

    handleArticleNumberAdded = () => {
        const priceItem = this.props.priceItem;
        priceItem.articleNumbers = priceItem.articleNumbers.concat(" ");
        this.props.handleItemChange(priceItem);
    }

    render() {

        if (this.props.disabled || this.isNewCockpitItemForTenderUser) {
            return (
                <Card/>
            );

        } else {
            return (
                <IconButton size="small" onClick={(_) => this.handleArticleNumberAdded()}>
                    <ControlPointIcon/>
                </IconButton>
            );
        }
    }
}

export default ArticleButtonCell;
