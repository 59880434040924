import React from 'react';
import {Line} from "recharts";
import {getChartColorByCategory, white} from "../../../../util/ColorTheme";
import {CATEGORY_FLAT_LINEN, CATEGORY_WORK_WEAR} from "../../../../util/Constants";
import {getExtrapolatedDataPoints} from "../../../../services/StatisticsService";

export const CategoryStatisticsLineData = ({
    activeCategory,
    activeTime,
    intl,
    priceOfferMap,
    allSelected,
    workWearSelected,
    flatLinenSelected,
    customerArticlesSelected,
    residentsLaundrySelected,
    operatingResourcesSelected,
    selectedTab,
    showNet,
    onShowTooltip,
    onHideTooltip,
    minDate
}) => {

    if (!priceOfferMap?.size) {
        return [[], []];
    }

    let tabKey;
    if (selectedTab === "price") {
        tabKey = "p";
    } else if (selectedTab === "pricePerCase") {
        tabKey = "ppc";
    } else if (selectedTab === "pricePerDay") {
        tabKey = "ppd";
    } else if (selectedTab === "amount") {
        tabKey = "a";
    } else if (selectedTab === "amountPerCase") {
        tabKey = "apc";
    } else if (selectedTab === "amountPerDay") {
        tabKey = "apd";
    }

    const customDot = ({cx, cy, key, payload, color}) => {
        const isSelected = activeCategory === key && payload.time === activeTime;
        return (
            <circle
                key={key + cx + cy}
                cx={cx}
                cy={cy}
                r={isSelected ? 8 : 4}
                stroke={color}
                strokeWidth={3}
                fill={white}/>
        );
    }

    const customActiveDot = ({cx, cy, key, color, payload}) => {
        const isActive = key === activeCategory && payload.time === activeTime;
        return (
            <circle
                cx={cx}
                cy={cy}
                r={8}
                stroke={isActive ? color : "transparent"}
                strokeWidth={3}
                fill={isActive ? white : "transparent"}
                onMouseOver={(_) => onShowTooltip(key, payload)}
                onMouseLeave={(_) => onHideTooltip()}/>
        );
    }

    const getLine = (time, key, nameId, color, isExtrapolated) => {
        const date = new Date(time).getMonth() + "-" + new Date(time).getFullYear();
        return (
            <Line
                key={key + date}
                connectNulls={true}
                type="linear"
                name={intl.formatMessage({id: nameId})}
                dataKey={key}
                strokeWidth={3}
                stroke={color}
                legendType={isExtrapolated ? "none" : "line"}
                xAxisId="time"
                strokeDasharray={isExtrapolated ? "3 3" : null}
                yAxisId={selectedTab}
                dot={({cx, cy, payload}) => customDot({cx, cy, key, payload, color})}
                activeDot={({cx, cy, payload}) => customActiveDot({cx, cy, key, color, payload})}/>
        );
    }

    const extrapolate = (data, lines) => {
        let allData = [...data];
        let allLines = [...lines];
        for (let a of [
            {selected: allSelected && showPrices, data, key: "all", color: getChartColorByCategory("all"), nameId: "cockpit.statistics.categories.fullPrices"},
            {selected: workWearSelected, data, key: "ww", color: getChartColorByCategory("ww"), nameId: "constants.Category.WORKWEAR"},
            {selected: flatLinenSelected, data, key: "fl", color: getChartColorByCategory("fl"), nameId: "constants.Category.FLAT_LINEN"},
            {selected: customerArticlesSelected, data, key: "ca", color: getChartColorByCategory("ca"), nameId: "constants.PRICE_TYPE.CUSTOMER_ARTICLE"},
            {selected: residentsLaundrySelected, data, key: "rl", color: getChartColorByCategory("rl"), nameId: "constants.PRICE_TYPE.RESIDENTS_LAUNDRY"},
            {selected: operatingResourcesSelected, data, key: "or", color: getChartColorByCategory("or"), nameId: "constants.PRICE_TYPE.OPERATING_RESOURCE"}
        ]) {
            const extrapolation = getExtrapolatedDataPoints(a.selected, a.data, a.key, a.color, a.nameId, tabKey, getLine);
            allData.push(...extrapolation[0]);
            allLines.push(...extrapolation[1]);
        }
        let uniqueData = [];
        for (let time of new Set(allData.map(d => d.time))) {
            let uniqueDataPoint = {time: time};
            for (let dataPoint of allData.filter(d => d.time === time)) {
                uniqueDataPoint = {...uniqueDataPoint, ...dataPoint};
            }
            uniqueData.push(uniqueDataPoint);
        }
        return [uniqueData.sort((d1, d2) => d1.time - d2.time), allLines];
    }

    const showPrices = selectedTab.includes("price");

    let data = [];
    let lines = [];

    for (const [timeString, priceOffer] of Array.from(priceOfferMap.entries())
        .sort(([timeString1, priceOffer1],[timeString2, priceOffer2]) => parseInt(timeString2) - parseInt(timeString1))) {

        const priceUnitMap = priceOffer.priceUnitMap;

        if (!priceUnitMap || !timeString) {
            continue;
        }

        let time = parseInt(timeString);

        if (time < minDate && !data.find(d => new Date(d.time).getMonth() === new Date(minDate).getMonth()
            && new Date(d.time).getFullYear() === new Date(minDate).getFullYear())) {
            time = minDate;
        } else if (time < minDate) {
            continue;
        }

        const priceUnit = Array.from(Object.values(priceUnitMap))[0];

        const dataPoints = {time: time};

        if (allSelected && showPrices) {
            const price = showNet ? priceOffer.sumNet : priceOffer.sumGross;
            dataPoints["all" + tabKey] = price / 12.0;
            lines.push(getLine(time,"all" + tabKey, "cockpit.statistics.categories.fullPrices", getChartColorByCategory("all")));
        }

        if (workWearSelected) {
            const workWearMap = priceUnit.articleGroupMap[CATEGORY_WORK_WEAR];
            let price = 0.0;
            let amount = 0;
            if (workWearMap) {
                Object.values(workWearMap).forEach(priceGroup => {
                    price += (showNet ? priceGroup.sumNet : priceGroup.sumGross);
                    amount += priceGroup.sumAmount;
                });
            }
            dataPoints["ww" + tabKey] = showPrices ? price / 12.0 : amount;
            lines.push(getLine(time, "ww" + tabKey, "constants.Category.WORKWEAR", getChartColorByCategory("ww")));
        }


        if (flatLinenSelected) {
            const flatLinenMap = priceUnit.articleGroupMap[CATEGORY_FLAT_LINEN];
            let price = 0.0;
            let amount = 0;
            if (flatLinenMap) {
                Object.values(flatLinenMap).forEach(priceGroup => {
                    price += (showNet ? priceGroup.sumNet : priceGroup.sumGross)
                    amount += priceGroup.sumAmount;
                });
            }
            dataPoints["fl" + tabKey] = showPrices ? price / 12.0 : amount;
            lines.push(getLine(time, "fl" + tabKey, "constants.Category.FLAT_LINEN", getChartColorByCategory("fl")));
        }

        if (customerArticlesSelected) {
            const price = (showNet
                ? priceUnit.customerArticleGroup?.sumNet : priceUnit.customerArticleGroup?.sumGross) ?? 0.0;
            dataPoints["ca" + tabKey] = showPrices ? price / 12.0 : (priceUnit.customerArticleGroup?.sumAmount ?? 0);
            lines.push(getLine(time, "ca" + tabKey, "constants.PRICE_TYPE.CUSTOMER_ARTICLE", getChartColorByCategory("ca")));
        }

        if (residentsLaundrySelected) {
            const price = (showNet
                ? priceUnit.residentsLaundryGroup?.sumNet : priceUnit.residentsLaundryGroup?.sumGross) ?? 0.0;
            dataPoints["rl" + tabKey] = showPrices ? price/12.0 : (priceUnit.residentsLaundryGroup?.sumAmount ?? 0);
            lines.push(getLine(time, "rl" + tabKey, "constants.PRICE_TYPE.RESIDENTS_LAUNDRY", getChartColorByCategory("rl")));
        }

        if (operatingResourcesSelected) {
            const price = (showNet
                ? priceUnit.operatingResourceGroup?.sumNet : priceUnit.operatingResourceGroup?.sumGross) ?? 0.0;
            dataPoints["or" + tabKey] = showPrices ? price/12.0 : (priceUnit.operatingResourceGroup?.sumAmount ?? 0);
            lines.push(getLine(time, "or" + tabKey, "constants.PRICE_TYPE.OPERATING_RESOURCE", getChartColorByCategory("or")));
        }

        data.push(dataPoints);
    }

    return extrapolate(data, lines);
}
