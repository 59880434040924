import {Button, Card, Collapse, Grid, Icon, Typography} from "@material-ui/core";
import React, { useContext, useState } from "react";
import {FormattedMessage, useIntl} from "react-intl";

import { EXPORT_TYPE_MERGE } from "../../../util/Constants";
import { RentalLinenPriceTable } from "./tables/RentalLinenPriceTable";
import { CustomerArticlesPriceTable } from "./tables/CustomerArticlesPriceTable";
import { SpecialServicesPriceTable } from "./tables/SpecialServicesPriceTable";
import { ResidentsLaundryPriceTable } from "./tables/ResidentsLaundryPriceTable";
import { ResourcesPriceTable } from "./tables/ResourcesPriceTable";
import styled from 'styled-components';
import {GeneralContext} from "../../contexts/GeneralContext";
import {getActiveProject} from "../../../services/ProjectService";
import {getUnitsForActiveProjectFromContext} from "../../../services/BusinessUnitService";
import {exportTenderPriceSheet} from "../../../services/ExportService";
import {useSnackbar} from "notistack";

export const Hover = styled.div`
        :hover {
            background-color: rgba(0,0,0,0.03);
            cursor: pointer;
        }
    `

const InvoiceControllingOffer = ({contents, offer, priceOffer}) => {

    const [priceOfferVisible, setPriceOfferVisible] = useState(false);
    const [openedUnits, setOpenedUnits] = useState([]);

    const context = useContext(GeneralContext);
    const intl = useIntl();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const props = {
        intl : intl,
        enqueueSnackbar : enqueueSnackbar,
        closeSnackbar : closeSnackbar
    }

    const activeProject = getActiveProject(context);

    const currency = activeProject.currency;

    const tables = (priceUnit, unitOrProjectId) => {
        return <Grid container>
            <Grid item xs={12}>
                <RentalLinenPriceTable priceUnit={priceUnit} contents={contents} currency={currency} unitOrProjectId={unitOrProjectId}/>
            </Grid>
            <Grid item xs={12}>
                <CustomerArticlesPriceTable priceUnit={priceUnit} contents={contents} currency={currency} unitOrProjectId={unitOrProjectId}/>
            </Grid>
            <Grid item xs={12}>
                <ResidentsLaundryPriceTable priceUnit={priceUnit} contents={contents} currency={currency} unitOrProjectId={unitOrProjectId}/>
            </Grid>
            <Grid item xs={12}>
                <ResourcesPriceTable priceUnit={priceUnit} contents={contents} currency={currency} unitOrProjectId={unitOrProjectId}/>
            </Grid>
            <Grid item xs={12}>
                <SpecialServicesPriceTable priceUnit={priceUnit} contents={contents} currency={currency} unitOrProjectId={unitOrProjectId}/>
            </Grid>
        </Grid>;
    }

    const handleExportClick = async function (e, offerId) {
        e.stopPropagation();
        await exportTenderPriceSheet(context, props, offerId);
    };

    return <Card key={"invoice-controlling-offer-" + priceOffer.offerId} style={{padding: 15}}>

        <Hover>
            <Grid 
                container 
                spacing={2}
                alignItems="center" 
                onClick={() => setPriceOfferVisible(!priceOfferVisible)} 
                style={{padding: 20}}>
                <Grid item xs>
                    <Typography variant="h2">
                        {offer.user?.company}
                    </Typography>
                </Grid>
                <Grid item>
                    <FormattedMessage id="invoice.controlling.offer.id" values={{offerId: priceOffer.offerId}}/>
                </Grid>
                <Grid item xs={3}>

                    <Button
                        color="primary"
                        variant="contained"
                        style={{minWidth: "300px", maxWidth: "300px", marginRight: "10px", display: "block"}}
                        onClick={(e) => handleExportClick(e, priceOffer.offerId) }>
                        { intl.formatMessage({ id : "priceSheet.download.button.text" }) }
                    </Button>
                </Grid>
                <Grid item>
                    <Icon className={priceOfferVisible ? "icon-caret-up-large" : "icon-caret-down-large"} style={{fontSize: "10pt"}}/>
                </Grid>
            </Grid>
        </Hover>
        
        <Collapse in={priceOfferVisible}>

            {Object.entries(priceOffer.priceUnitMap).map(([unitOrProjectId, priceUnit]) => <div key={"unit" + unitOrProjectId}>

                {(priceOffer.exportType === EXPORT_TYPE_MERGE || Object.entries(priceOffer.priceUnitMap).length < 2) 
                    ? tables(priceUnit, unitOrProjectId)
                    : <React.Fragment>
                        <Hover>
                            <Grid 
                                container
                                alignItems="center"
                                style={{padding: 20}} 
                                onClick={() => {
                                    if (openedUnits.includes(unitOrProjectId)) {
                                        setOpenedUnits([...openedUnits.filter(id => id !== unitOrProjectId)]);
                                    } else {
                                        setOpenedUnits([...openedUnits, unitOrProjectId]);
                                    }
                                }}>
                                <Grid item xs>
                                    {<Typography variant="h3">
                                        {getUnitsForActiveProjectFromContext(context).find(u => u.id === parseInt(unitOrProjectId))?.name}
                                    </Typography>}
                                </Grid>
                                <Grid item>
                                    <Icon className={openedUnits.includes(unitOrProjectId) ? "icon-caret-up-large" : "icon-caret-down-large"} style={{fontSize: "10pt"}}/>
                                </Grid>
                            </Grid>
                        </Hover>

                        <Collapse in={openedUnits.includes(unitOrProjectId)}>
                            {tables(priceUnit, unitOrProjectId)}
                        </Collapse>    
                    </React.Fragment>}
            
            </div>)}
        </Collapse>
    </Card>
}

export {
    InvoiceControllingOffer
}
