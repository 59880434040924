import { Button, Grid, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { deleteRequestAsync, uploadBlob } from "../../services/BackendService";
import { FILE_TYPES } from "../../util/DocumentUtil";
import { createSuccessMessage } from "../../util/Util";
import { GeneralContext } from "../contexts/GeneralContext";
import ImageCropper from "../share/ImageCropper";

const MAX_FILE_SIZE_IN_BYTE = 5000000;

class UserImage extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            imageVo: this.props.currentImage,
            croppedImage: null,
            selectedImage: null,
            selectedFilename: null,
        };
    }

    updateUserImage = async() => {
        const username = this.context.currentUser.username;

        const image = this.state.imageVo?.image;

        if (image) {
            await deleteRequestAsync('user/image/' + image.filename);
        }

        if (this.state.croppedImage) {
            let blob = await new Promise(resolve => this.state.croppedImage.toBlob(resolve));
            if (blob) {
                let imageBlob = new File([blob], this.state.selectedFilename, {type: 'image/png'});
                let imageResponse = await uploadBlob('user/image/' + this.props.version, imageBlob, username);
                if (imageResponse?.status === 409) {
                    this.context.setShowOptimisticLockDialog(true);
                } else if ([401, 403].includes(imageResponse?.status)) {
                    this.context.logout();
                } else if (imageResponse?.status === 200) {
                    this.setState({croppedImage: null, selectedFilename: null, selectedImage: null});
                    createSuccessMessage(this.props.intl.formatMessage({id: "commons.update.message.success"}), this.props);
                    this.props.onSuccess();

                    // refresh current user in context
                    this.context.currentUser.imageVo = imageResponse.data;
                }
            }
            this.context.setUnsavedChanges(false);
        }
    }

    handleImageCropped = (croppedImage) => {
        this.context.setUnsavedChanges(true);
        this.setState({croppedImage: croppedImage})
    }

    handleImageSelection = (file) => {
        if (file.size >= MAX_FILE_SIZE_IN_BYTE) {
            this.setState({showSelectedImageToLargeDialog: true});
        } else {
            let url = URL.createObjectURL(file);
            this.setState({selectedImage: url, selectedFilename: file.name});
        }
    }

    handleRemoveImage = async () => {
        const image = this.state.imageVo?.image;
        if (image) {
            await deleteRequestAsync('user/image/' + image.filename);
        }
        this.setState({
            imageVo: null,
            croppedImage: null,
            selectedImage: null,
            selectedFilename: null
        });
        createSuccessMessage(this.props.intl.formatMessage({id: "commons.update.message.success"}), this.props);
        this.props.onSuccess();
    }

    render() {
        return (
            <>
            
                <Typography variant="h5" component="h2">
                    <FormattedMessage id="userprofil.picture"/>
                </Typography>

                <Grid style={{marginTop: "20px"}} container spacing={1} justifyContent="flex-start" alignItems="center">
                    <Grid container direction="column" spacing={1}>

                        <Grid item>

                            <Button variant="contained" color="secondary" component="label" style={{marginRight: "10px"}}>
                                {!this.state.imageVo && !this.state.selectedImage ?
                                    <FormattedMessage id="commons.image.choose"/> :
                                    <FormattedMessage id="commons.image.chooseAnother"/>}
                                <input 
                                    type="file" 
                                    accept={FILE_TYPES.IMAGE} 
                                    style={{display: "none"}} 
                                    onClick={(e) => e.target.value = null}
                                    onChange={(e) => this.handleImageSelection(e.target.files[0])}/>
                            </Button>

                            {this.state.imageVo &&
                                <Button 
                                    variant="contained" 
                                    color="secondary" 
                                    onClick={this.handleRemoveImage}>
                                    <FormattedMessage id="commons.image.remove"/>
                                </Button>}
                        </Grid>

                        <Grid item>

                            {this.state.imageVo && !this.state.selectedImage 
                            && <img 
                                src={this.state.imageVo.image?.url} 
                                style={{width: "33%", marginBottom: "45px"}} 
                                alt=""/>}

                            {this.state.selectedImage && <ImageCropper 
                                src={this.state.selectedImage} 
                                ratio={1/1} 
                                previewClass="img-preview-square" 
                                croppedImageCallback={this.handleImageCropped}>
                            </ImageCropper>}

                        </Grid>

                    </Grid>

                    <Grid item xs={12}>
                        <Button 
                            style={{marginTop: "40px"}} 
                            color="primary" 
                            variant="contained"
                            disabled={!this.state.croppedImage}
                            onClick={this.updateUserImage}>
                            <FormattedMessage id="userprofil.button.uploadImage"/>
                        </Button>
                    </Grid>
                </Grid>
            
            </>
        );
    }
}

export default injectIntl(withSnackbar(UserImage));
