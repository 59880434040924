import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import {renderCategories, renderImage} from '../../../../../util/TableUtil';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {injectIntl, FormattedMessage} from 'react-intl';
import {getAsync, putAsyncCatch} from '../../../../../services/BackendService';
import {withSnackbar} from 'notistack';
import {createErrorMessage, createSuccessMessage} from '../../../../../util/Util';
import {
    EQUIPMENT_TYPE_ASSORTMENT,
    EQUIPMENT_TYPE_AREA,
    EQUIPMENT_TYPE_PROFESSIONAL_GROUP
} from '../../../../../util/Constants';
import {FLAT_RATE, DELIVERED_QUANTITY, SPLIT_PRICE, NO_OFFSETTING_SELECTED} from '../../../../../util/Constants';
import {withRouter} from "react-router-dom";
import ArticleDetail from '../../../../share/ArticleDetail';
import {texisionRed, texisionFontColorDark, bodyBackgroundColor} from '../../../../../util/ColorTheme';
import '../../../../apps/App.css';
import EditIcon from '@material-ui/icons/Edit';
import {IconButton, Table, TableCell, TableContainer, TableHead, TableRow, TableBody} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {GeneralContext} from "../../../../contexts/GeneralContext";
import {updateValidationMap} from "../../../../../services/ProjectService";

class LogisticsQuantities extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            offsettingData: [],
            // list with currently saved delivery quantities to compare with allAssignedArticles,
            // so for updating only the changed articles will be sent to the server
            currentlyAssignedArticles: this.props.allAssignedArticles
                ? JSON.parse(JSON.stringify(this.props.allAssignedArticles))
                : null,
            allAssignedArticles: this.props.allAssignedArticles,
            showArticleDetails: false, 
            articleForDetailView: null,
            isEditing: false,
            quantities: []
        }
    }

    async componentDidMount() {
        // A redirect will be done in the render function. Due to the component life cycle we need to check this here again.
        if (this.props.deliveryAddress?.id !== null && this.props.deliveryAddress?.id !== undefined) {
            let offsettingData = await this.loadOffsettingData();
            this.setState({offsettingData: offsettingData});
            await this.loadQuantities();
        }
    }

    reloadAssignedArticles = async() => {
        let response = await getAsync("/quantity/articles/" + this.context.appData.activeUnitId + "/" + this.props.deliveryAddress.id);
        if (response?.status === 200) {
            return response.data;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    loadQuantities = async() => {
        let response = await getAsync("/quantity/" + this.props.deliveryAddress.id);
        if (response?.status === 200) {
            this.setState({quantities: response.data});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    loadOffsettingData = async() => {
        let response = await getAsync("/offsetting/" + this.context.appData.activeUnitId);
        if (response?.status === 200) {
            return response.data.articleOffsetting;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    saveQuantities = async() => {
        let changedObjects = this.state.allAssignedArticles.filter(a => {
            let currentArticle = this.state.currentlyAssignedArticles.find(c => c.articleDeliveryQuantityId === a.articleDeliveryQuantityId);
            return !!(currentArticle && (a.deliveryQuantity !== currentArticle.deliveryQuantity || a.equipQuantity !== currentArticle.equipQuantity));
        });

        let quantities = [];

        for (let delivery of changedObjects) {
            let quantity = this.state.quantities.find(q => q.id === delivery.articleDeliveryQuantityId);
            // create object structure for saving. The id of the quantity is always null, because we don't need the information in the backend.
            quantities.push({
                id: quantity?.id,
                businessUnitId: delivery.businessUnitId,
                deliveryAddressId: delivery.deliveryAddressId,
                articleId: delivery.articleId,
                deliveryAmount: delivery.deliveryQuantity,
                equipAmount: delivery.equipQuantity,
                source: delivery.source,
                version: quantity?.version ?? 0
            });
        }

        const responseData = await putAsyncCatch(this.context, "/quantity/" + this.context.appData.activeProjectId + "/"
            + this.props.deliveryAddress.id, quantities, this.props, true);
        if (responseData) {
            let allAssignedArticles = await this.reloadAssignedArticles();
            let offsettingData = await this.loadOffsettingData();
            await this.loadQuantities();
            this.props.onSave();
            await updateValidationMap(this.context);

            if (!allAssignedArticles || !offsettingData) {
                createErrorMessage(this.props.intl.formatMessage({id: "articleDeliveryQuantity.messages.error"}), this.props);
            } else {
                this.setState({
                    allAssignedArticles: allAssignedArticles, 
                    currentlyAssignedArticles: JSON.parse(JSON.stringify(allAssignedArticles)), 
                    offsettingData: offsettingData,
                    isEditing: false
                });
                createSuccessMessage(this.props.intl.formatMessage({id: "articleDeliveryQuantity.messages.success"}), this.props);
                this.context.setUnsavedChanges(false);
            }
        } else {
            this.setState({articlesToAssign: []});
        }
    }

    updateQuantity = (articleDeliveryQuantityId, newValue, isDeliveryQuantity) => {
        const positivNumberRegex = /^[0-9\b]+$/;
        if (newValue === "" || positivNumberRegex.test(newValue)) {
            let allAssignedArticles = this.state.allAssignedArticles;
            let article = allAssignedArticles.find(a => a.articleDeliveryQuantityId === articleDeliveryQuantityId);
            if (isDeliveryQuantity) {
                article.deliveryQuantity = newValue;
            } else {
                article.equipQuantity = newValue;
            }
            this.setState({allAssignedArticles});
        }
    }

    showArticleDetails = (article) => {
        this.setState({showArticleDetails: true, articleForDetailView: article});
    }

    handleDialogClose = () => {
        this.setState({showArticleDetails: false, articleForDetailView: null});
    }

    abortEditing = () => {
        this.context.setUnsavedChanges(false);
        this.setState({
            isEditing: false, 
            allAssignedArticles: JSON.parse(JSON.stringify(this.state.currentlyAssignedArticles))
        })
    }

    titleRow = () => {
        return (
            <Grid 
                container 
                justifyContent="space-between" 
                alignContent="center"
                style={{alignSelf: 'stretch', flexDirection: 'row', paddingLeft: "5px", paddingRight: "5px", marginTop: 20}}>

                <Grid item xs>
                    <Typography 
                        variant="h3" 
                        component="span">
                        {this.props.intl.formatMessage({id: "articleDeliveryQuantity.table.deliveryPoint"}) 
                            + " " 
                            + this.props.deliveryAddress.description}
                    </Typography>
                </Grid>

                <Grid item>
                    <Grid container>

                        <Grid item xs style={{height: "60px"}}>

                            {this.state.isEditing && <>
                                <Button
                                    onClick={() => this.saveQuantities()}
                                    color="primary"
                                    variant="contained"
                                    style={{minWidth: "150px"}}>
                                    <FormattedMessage id="commons.save.button"/>
                                </Button>

                                <Button
                                    onClick={() => this.abortEditing()}
                                    color="primary"
                                    style={{minWidth: "150px"}}>
                                    <FormattedMessage id="commons.cancel.button"/>
                                </Button>
                            </>}

                            {!this.state.isEditing && <>
                                <IconButton
                                    aria-label="edit"
                                    size="small"
                                    style={{marginRight: 15}}
                                    onClick={() => this.setState({isEditing: true})}
                                    disabled={this.props.readOnly}>
                                    <EditIcon/>
                                </IconButton>
                            </>}

                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        );
    }

    isEditable = (articleId, field) => {
        let offsetting = this.state.offsettingData.find(offsetting => offsetting.articleId === articleId);
        if (!offsetting) {
            return false;
        } else if (offsetting.articleCategoryOffsetting === FLAT_RATE && field === "equipQuantity") {
            return true;

        } else if (offsetting.articleCategoryOffsetting === DELIVERED_QUANTITY && field === "deliveryQuantity") {
            return true;

        } else if (offsetting.articleCategoryOffsetting === SPLIT_PRICE) {
            return true;
        }
        return false;
    }

    handleAmountChanged = (newValue, isDeliveryQuantity, articleDeliveryQuantityId) => {
        const amountRegex = /^[0-9]*$/;
        if (amountRegex.test(newValue)) {
            this.context.setUnsavedChanges(true);
            this.updateQuantity(articleDeliveryQuantityId, newValue, isDeliveryQuantity);
        }
    }

    onFocus = (newValue, isDeliveryQuantity, articleDeliveryQuantityId) => {
        if (newValue === "0") {
            this.updateQuantity(articleDeliveryQuantityId, "", isDeliveryQuantity);
        }
    }

    onBlur = (newValue, isDeliveryQuantity, articleDeliveryQuantityId) => {
        if (newValue === "") {
            this.updateQuantity(articleDeliveryQuantityId, "0", isDeliveryQuantity);
        }
    }

    renderOffsetting = (article) => {
        let offsetting = this.state.offsettingData.find(offsetting => offsetting.articleId === article.articleId);
        let textColor =  offsetting.articleCategoryOffsetting === NO_OFFSETTING_SELECTED ? texisionRed : texisionFontColorDark;
        return (
            <React.Fragment>
                <Typography style={{color: textColor}}>
                    <FormattedMessage id={"constants.ArticleOffsetting." + offsetting.articleCategoryOffsetting}/>
                </Typography>
            </React.Fragment>
        );
    }

    renderSourceLink = (source) => {
        let sourceTypeString;
        let sourceName = "";
        switch (source.type) {
            case EQUIPMENT_TYPE_AREA:
                let area = this.props.allAreas.find(a => a && a.id === source.sourceId);
                if (area) {
                    sourceName = area.name;
                    sourceTypeString = "area";
                }
                break;
            case EQUIPMENT_TYPE_PROFESSIONAL_GROUP:
                let group = this.props.allProfessionalGroups.find(g => g && g.id === source.sourceId);
                if (group) {
                    sourceName = group.name;
                    sourceTypeString = "professionalGroup";
                }
                break;
            case EQUIPMENT_TYPE_ASSORTMENT:
                sourceTypeString = "assortment";
                break;
            default:
                sourceTypeString = "assortment";
                break;
        }            
        if (sourceName) {
            sourceName = " (" + sourceName + ")";
        }
        let text = this.props.intl.formatMessage({id: "articleDeliveryQuantity.table.source." + sourceTypeString}) + sourceName;
        return <Typography>{text}</Typography>;
    }

    render() {

        if (!this.props.deliveryAddress || !this.state.allAssignedArticles?.length) {
            return null;
        }

        const articleDetailsDialog = this.state.articleForDetailView 
            ? <ArticleDetail 
                article={this.state.articleForDetailView}
                showDialog={this.state.showArticleDetails}
                handleDialogClose={this.handleDialogClose}/>
            : null;
        
        let articles = [];
        for (let article of this.state.allAssignedArticles) {
            let offset = this.state.offsettingData?.find(o => o.articleId === article.articleId);
            if (offset) {
                let data = {};
                data = Object.assign(data, article);
                articles.push(data);
            }
        }
        return (
            <>
                {articleDetailsDialog}

                {this.titleRow()}

                <TableContainer
                    component={Paper}
                    style={{overflowX: "scroll"}}>

                    <Table aria-label="delivery-quantity-table">

                        <TableHead style={{backgroundColor: bodyBackgroundColor}}>

                            <TableRow>
                                <TableCell>{this.props.intl.formatMessage({id: "entities.article.category"})}</TableCell>
                                <TableCell>{this.props.intl.formatMessage({id: "entities.article.subCategory"})}</TableCell>
                                <TableCell>{this.props.intl.formatMessage({id: "entities.article.articleCategory"})}</TableCell>
                                <TableCell>{this.props.intl.formatMessage({id: "entities.article.description"})}</TableCell>
                                <TableCell>{this.props.intl.formatMessage({id: "entities.article.image"})}</TableCell>
                                <TableCell>{this.props.intl.formatMessage({id: "articleDeliveryQuantity.table.source"})}</TableCell>
                                <TableCell>{this.props.intl.formatMessage({id: "articleDeliveryQuantity.table.titles.offsetting"})}</TableCell>
                                <TableCell>{this.props.intl.formatMessage({id: "articleDeliveryQuantity.table.titles.deliveryQuantity"})}</TableCell>
                                <TableCell>{this.props.intl.formatMessage({id: "articleDeliveryQuantity.table.titles.equipQuantity"})}</TableCell>
                            </TableRow>

                        </TableHead>

                        <TableBody>

                            {articles.sort((a, b) => a.articleDeliveryQuantityId - b.articleDeliveryQuantityId).map(a => (

                                <TableRow key={a.articleDeliveryQuantityId}>

                                    <TableCell component="th" scope="row">{renderCategories(a, "Category")}</TableCell>
                                    <TableCell>{renderCategories(a, "SubCategory")}</TableCell>
                                    <TableCell>{renderCategories(a, "ArticleCategory")}</TableCell>
                                    <TableCell>{a.description}</TableCell>

                                    <TableCell>{renderImage(this.props.assortmentData.find(d => d.id === a.articleId), this.showArticleDetails)}</TableCell>

                                    <TableCell>{this.renderSourceLink(a.source)}</TableCell>
                                    <TableCell>{this.renderOffsetting(a)}</TableCell>

                                    <TableCell>
                                        {this.isEditable(a.articleId, "deliveryQuantity")
                                            ? <TextField
                                                value = {a.deliveryQuantity}
                                                onChange = {e => this.handleAmountChanged(e.target.value, true, a.articleDeliveryQuantityId)}
                                                onFocus = {e => this.onFocus(e.target.value, true, a.articleDeliveryQuantityId)}
                                                onBlur = {e => this.onBlur(e.target.value, true, a.articleDeliveryQuantityId)}
                                                disabled = {!this.state.isEditing}/>
                                            : a.deliveryQuantity}
                                    </TableCell>

                                    <TableCell>
                                        {this.isEditable(a.articleId, "equipQuantity")
                                            ? <TextField
                                                value = {a.equipQuantity}
                                                onChange = {e => this.handleAmountChanged(e.target.value, false, a.articleDeliveryQuantityId)}
                                                onFocus = {e => this.onFocus(e.target.value, false, a.articleDeliveryQuantityId)}
                                                onBlur = {e => this.onBlur(e.target.value, false, a.articleDeliveryQuantityId)}
                                                disabled = {!this.state.isEditing}/>
                                            : a.equipQuantity}
                                    </TableCell>

                                </TableRow>
                            ))}

                        </TableBody>

                    </Table>

                </TableContainer>
            </>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(LogisticsQuantities)));
