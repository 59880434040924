import { Accordion, AccordionDetails, AccordionSummary, IconButton, Stack, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Component } from "react";
import OperationDifferencesTable from "../contractchanges/OperationDifferencesTable";
import { FormattedMessage, injectIntl } from "react-intl";
import { getSimpleDate } from "../../../util/DateUtil";
import { Button, DialogContentText, Grid, TextField, Typography } from "@material-ui/core";
import { GeneralContext } from "../../contexts/GeneralContext";
import { texisionRed, white } from "../../../util/ColorTheme";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import { DIALOG_TYPE_WARNING } from "../../../util/Constants";
import {getActiveOperation} from "../../../services/OperationService";
import {isOfferUser} from "../../../services/UserService";

class ContractFutureItem extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            deleteConfirmationText: "",
            showAcceptDialog: false,
            showRejectDialog: false,
            showRequestDialog: false,
            showWithdrawDialog: false,
            showApplyDialog: false
        }
    }

    getContractListToDelete = () => {
        const effectiveFrom = this.props.operationDifference?.effectiveFrom;
        
        const projectsToDelete = this.props.futureProjects
            ?.filter(p => p.effectiveFrom >= effectiveFrom)
            ?.sort((a,b) => a.effectiveFrom - b.effectiveFrom);

        if (!effectiveFrom || !projectsToDelete || projectsToDelete.length === 0) {
            return "";
        }

        return (
            <ul>
                {projectsToDelete.map(p => <li>
                    <FormattedMessage 
                        id="operation.difference.future.title" 
                        values={{date: getSimpleDate(new Date(p.effectiveFrom))}}/>
                </li>)}
            </ul>
        );
    }

    actionButtons = () => {
        const activeOperation = getActiveOperation(this.context);
        const requestedWithdrawId = this.props.requestedWithdrawId;
        const hasBidder = isOfferUser(activeOperation?.bidder);
        const deleteItemMessageId = hasBidder ? "contractStatus.deleteFutureItem.button"
            : "contractStatus.deleteFutureItem.button.withoutBidder";
        let tooltipId;

        if (this.props.hasWorkingProject) {
            tooltipId = "contractStatus.deleteFutureItem.hasWorkingProject.tooltip";
        } else if (requestedWithdrawId !== null && requestedWithdrawId !== undefined 
            && requestedWithdrawId !== this.props.operationDifference?.projectTargetId) {
            tooltipId = "contractStatus.deleteFutureItem.requestExists.tooltip";
        } 

        if (tooltipId) {
            return (
                <Tooltip title={this.props.intl.formatMessage({id: tooltipId})}>
                    <div>
                        <Button 
                            variant="contained" 
                            disabled={true}>
                            <FormattedMessage id={deleteItemMessageId}/>
                        </Button>
                    </div>
                </Tooltip>
            );
        }

        if (requestedWithdrawId === null || requestedWithdrawId === undefined) {
            return (
                <Button 
                    variant="contained" 
                    style={{backgroundColor: texisionRed, color: white}} 
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (hasBidder) {
                            this.setState({showRequestDialog: true});
                        } else {
                            this.setState({showApplyDialog: true});
                        }
                    }}>
                    <FormattedMessage id={deleteItemMessageId}/>
                </Button>
            );
        } else if (activeOperation?.editable) {
            return (
                <Grid container spacing={1}>
                    <Grid item>
                        <Button 
                            variant="contained"
                            color="primary" 
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({showAcceptDialog: true});
                            }}>
                            <FormattedMessage id="contractStatus.acceptWithdraw.button"/>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="contained"
                            style={{backgroundColor: texisionRed, color: white}}  
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({showRejectDialog: true});
                            }}>
                            <FormattedMessage id="contractStatus.rejectWithdraw.button"/>
                        </Button>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid container spacing={1}>
                    <Grid item>
                        <Tooltip title={this.props.intl.formatMessage({id: "contractStatus.deleteFutureItem.requestSent.tooltip"})}>
                            <div>
                                <Button 
                                    variant="contained" 
                                    disabled={true}>
                                    <FormattedMessage id="contractStatus.deleteRequestSent.button"/>
                                </Button>
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="contained" 
                            style={{backgroundColor: texisionRed, color: white}} 
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({showWithdrawDialog: true});
                            }}>
                            <FormattedMessage id="contractStatus.withdrawWithdraw.button"/>
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    }

    onCancel = () => {
        this.setState({
            comment: "",
            deleteConfirmationText: "",
            showAcceptDialog: false, 
            showRejectDialog: false, 
            showRequestDialog: false, 
            showWithdrawDialog: false,
            showApplyDialog: false
        })
    }

    getDialog = () => {
        let actionType;
        let onAction;
        let confirmDelete;
        if (this.state.showAcceptDialog) {
            actionType = "acceptWithdraw";
            onAction = () => this.props.acceptWithdraw(this.state.comment);
            confirmDelete = true;
        } else if (this.state.showRejectDialog) {
            actionType = "rejectWithdraw";
            onAction = () => this.props.rejectWithdraw(this.state.comment);
        } else if (this.state.showRequestDialog) {
            actionType = "requestWithdraw";
            onAction = () => this.props.requestWithdraw(this.props.operationDifference.projectTargetId, this.state.comment);
        } else if (this.state.showWithdrawDialog) {
            actionType = "withdrawWithdraw";
            onAction = () => this.props.withdrawWithdraw(this.state.comment);
        } else if (this.state.showApplyDialog) {
            actionType = "applyWithdraw";
            onAction = () => this.props.requestWithdraw(this.props.operationDifference.projectTargetId);
            confirmDelete = true;
        }  else {
            return null;
        }
        return (
            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={true}
                titleId={"cockpit.contractStatus.dialogTitle." + actionType}
                content={
                    <>
                        {!confirmDelete && <DialogContentText id="alert-dialog-description" style={{whiteSpace: "pre-line"}}>
                            
                            <FormattedMessage id={"cockpit.contractStatus.dialogSubtitle." + actionType}/>

                        </DialogContentText>}

                        {confirmDelete && <>

                            <DialogContentText id="alert-dialog-description" style={{whiteSpace: "pre-line"}}>
                                <div style={{marginBottom: 20}}>
                                    <FormattedMessage id={"cockpit.contractStatus.dialogSubtitle." + actionType + ".introduction"}/>
                                </div>

                                {this.getContractListToDelete()}

                                <Typography variant="h4" style={{marginTop: 20}}>
                                    <FormattedMessage 
                                        id="cockpit.contractStatus.dialogSubtitle.acceptWithdraw.confirmation"
                                        values={{deleteConfirmationText: this.props.intl.formatMessage({id: "contractStatus.deleteFutureItem.confirmationText"})}}/>
                                </Typography>

                                <TextField
                                    value={this.state.deleteConfirmationText}
                                    label={<FormattedMessage id="contractStatus.deleteFutureItem.confirmationTextField"/>}
                                    onChange={(e) => this.setState({deleteConfirmationText: e.target.value})}
                                    multiline
                                    style={{width: '100%', marginBottom: 30}}/>
                    
                            </DialogContentText>

                        </>}

                        {actionType !== "applyWithdraw" && <TextField
                            value={this.state.comment}
                            label={<FormattedMessage id="cockpit.contractStatus.comment"/>}
                            onChange={(e) => this.setState({comment: e.target.value})}
                            multiline
                            style={{width: '100%'}}/>}
                    </>
                }
                cancelId="commons.no.button"
                actionId="commons.yes.button"
                onCancel={() => this.onCancel()}
                onAction={() => {
                    onAction();
                    this.onCancel();
                }}
                actionDisabled={confirmDelete
                    && this.state.deleteConfirmationText !== this.props.intl.formatMessage({id: "contractStatus.deleteFutureItem.confirmationText"})}/>
        );
    }

    render() {

        if (this.props.operationDifference) {

            const startdate = new Date(this.props.operationDifference.effectiveFrom);

            return (
                <>

                    {this.getDialog()}
                
                    <Accordion style={{minWidth: 300}}>

                        <AccordionSummary 
                            expandIcon={<ExpandMoreIcon/>}>

                                <Grid container alignItems="center">
                                    <Grid item xs>
                                        <Grid container alignItems="center" wrap="nowrap">
                                            <Grid item>
                                                <Tooltip 
                                                    title={this.props.intl.formatMessage({id: "contract.history.tooltip"})}>
                                                    <IconButton>
                                                        <InfoOutlinedIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <h3 style={{color: "grey"}}>
                                                    <FormattedMessage 
                                                        id="operation.difference.future.title" 
                                                        values={{date: getSimpleDate(startdate)}}/>
                                                </h3>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{marginRight: 20}}>
                                        {this.actionButtons()}
                                    </Grid>
                                </Grid>

                        </AccordionSummary>

                        <AccordionDetails>

                            <Stack spacing={1} width={"100%"}>
                                <OperationDifferencesTable operationDifference={this.props.operationDifference}/>
                            </Stack>

                        </AccordionDetails>

                    </Accordion>
                
                </>
            );

        } else {
            return null;
        }
    }
}

export default injectIntl(ContractFutureItem);
