import React, {Component} from 'react';
import {Accordion, AccordionSummary, FormControl, IconButton, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import {isAdmin} from "../../../services/UserService";
import {GeneralContext} from "../../contexts/GeneralContext";
import {defaultTextColor} from "../../../util/ColorTheme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {FormattedMessage, injectIntl} from "react-intl";
import {LAST_PROJECT_ID, LAST_OPERATION_ID} from "../../../util/Constants";
import {createDummyProject, deleteProjectForce} from "../../../services/ProjectService";
import {withSnackbar} from "notistack";
import {isCockpit} from "../../../util/Util";

class DummyProjectCreator extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            dummyToCreate: "",
            dummyToDelete: null,
        }
    }

    dummyProjects() {
        let projects = this.context.appData.allProjects;
        if (isCockpit()) {
            projects = this.context.appData.operations?.map(o => o.activeProject);
        } 
        const dummyKey = '00-TEST-';
        return projects?.filter(p => p.name?.includes(dummyKey));
    }

    dummyChoices = () => {
        return [
            "IN_PROGRESS",
            "IS_PUBLISHED",
            "IN_EDITING",
            "IS_ACCEPTED"
        ]
    }

    handleCreate = async () => {
        const createdProject = await createDummyProject(this.context, this.props, this.state.dummyToCreate);
        if (createdProject) {
            await this.context.reloadAppData();
            localStorage.setItem(LAST_PROJECT_ID, createdProject.id);
            localStorage.setItem(LAST_OPERATION_ID, createdProject.operationId);
            this.context.setActiveProjectId(createdProject.id);
        }
    }

    handleDelete = async () => {
        await deleteProjectForce(this.context, this.props, this.state.dummyToDelete.id);
        window.location.reload()
    }

    render() {
        const user = this.context.currentUser;

        if (isAdmin(user)) {

            return (
                <div>
                    <Accordion elevation={0} style={{padding: 8}}>
                        <AccordionSummary style={{margin: 0}} expandIcon={<ExpandMoreIcon/>}>
                            <h3 style={{color: defaultTextColor}}>
                                <FormattedMessage id="superadmin.dummyproject.title"/>
                            </h3>

                        </AccordionSummary>
                        <Stack spacing={2}>

                            <Stack direction="row">

                                <FormControl fullWidth>

                                    <InputLabel id="dummyTyp">
                                        <FormattedMessage id="superadmin.dummyproject.createlabel"/>
                                    </InputLabel>
                                    <Select labelId="dummyTyp"
                                            label={<FormattedMessage id="superadmin.dummyproject.createlabel"/>}
                                            value={this.state.dummyToCreate}
                                            variant="outlined">

                                        {this.dummyChoices().map(choice =>
                                            <MenuItem key={choice} value={choice.toString()}
                                                      onClick={() => this.setState({dummyToCreate: choice})}>
                                                {choice}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                                <IconButton disabled={this.state.dummyToCreate === ""} color="primary"
                                            onClick={this.handleCreate}><NoteAddIcon/></IconButton>
                            </Stack>

                            <Stack direction="row">

                                <FormControl fullWidth>
                                    <InputLabel id="deleteDummy">
                                        <FormattedMessage id="superadmin.dummyproject.deletelabel"/>
                                    </InputLabel>

                                    <Select labelId="deleteDummy"
                                            label={<FormattedMessage id="superadmin.dummyproject.deletelabel"/>}
                                            value={this.state.dummyToDelete?.name ?? ""}
                                            variant="outlined">
                                        {this.dummyProjects()?.map(p =>
                                            <MenuItem key={"dummyProject" + p.id} value={p.name}
                                                      onClick={() => this.setState({dummyToDelete: p})}>{p.name}</MenuItem>
                                        )}

                                    </Select>
                                </FormControl>
                                <IconButton color="secondary" disabled={this.state.dummyToDelete === null}
                                            onClick={this.handleDelete}><DeleteForeverIcon/></IconButton>
                            </Stack>
                        </Stack>

                    </Accordion>

                </div>
            )
        } else {
            return <div/>
        }

    }


}

export default withSnackbar(injectIntl(DummyProjectCreator));
