import {CATEGORY, SUB_CATEGORY, ARTICLE_CATEGORY, ROOT_NODE_NAME} from '../../../../util/Constants';

export function createDataStructure(tree, originalTree, articleOffsetting, getInternalId) {

    if (!tree.getRoot()) {
        originalTree.addNode(ROOT_NODE_NAME, getInternalId(ROOT_NODE_NAME), null);
        tree.addNode(ROOT_NODE_NAME, getInternalId(ROOT_NODE_NAME), null);
    }

    //name, internalId, partenInternalId, type, offsetting, label

    for (let offsetting of articleOffsetting.articleOffsetting) {
        
        tree.addNode(offsetting.category, getInternalId(offsetting.category), 
            getInternalId(ROOT_NODE_NAME), CATEGORY, offsetting.categoryOffsetting, offsetting.category);
        originalTree.addNode(offsetting.category, getInternalId(offsetting.category), 
            getInternalId(ROOT_NODE_NAME), CATEGORY, offsetting.categoryOffsetting, offsetting.category);

        tree.addNode(offsetting.subCategory, getInternalId(offsetting.category+offsetting.subCategory), 
            getInternalId(offsetting.category), SUB_CATEGORY, offsetting.subCategoryOffsetting, offsetting.subCategory);
        originalTree.addNode(offsetting.subCategory, getInternalId(offsetting.category+offsetting.subCategory), 
            getInternalId(offsetting.category), SUB_CATEGORY, offsetting.subCategoryOffsetting, offsetting.subCategory);
        
        tree.addNode(offsetting.articleCategory, getInternalId(offsetting.category+offsetting.subCategory+offsetting.articleCategory), 
            getInternalId(offsetting.category+offsetting.subCategory), ARTICLE_CATEGORY, offsetting.articleCategoryOffsetting, offsetting.articleCategory);
        originalTree.addNode(offsetting.articleCategory, getInternalId(offsetting.category+offsetting.subCategory+offsetting.articleCategory), 
            getInternalId(offsetting.category+offsetting.subCategory), ARTICLE_CATEGORY, offsetting.articleCategoryOffsetting, offsetting.articleCategory);

    }
}