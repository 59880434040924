import {getAsync} from "./BackendService";
import {getActiveOperation} from "./OperationService";


export async function loadProfiles(context){
    let response = await getAsync("/profiles");
    if (response?.status === 200) {
        return response.data;
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    }
    return [];
}

export async function loadProfile(context) {
    let profileId = getActiveOperation(context)?.profileIds[0];
    let response = await getAsync("/profile/" + profileId);
    if (response?.status === 200) {
        return response.data;
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    }
    return null;
}
