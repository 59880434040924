import {CONFLICT_TYPES, PROJECT_TASK_GROUP, PROJECT_TASKS} from "./Constants";

export const getFlatValidationList = (validationMap) => {
    if (!validationMap?.size) {
        return [];
    }
    const flatList = [];
    for (let [task, value] of validationMap) {
        if (task && value) {
            for (let [objectId, validation] of value) {
                if (objectId) {
                    flatList.push(validation);
                }
            }
        }
    }
    return flatList;
}

export const mayCompleteTender = (projectId, validationMap) => {
    if (projectId === null || projectId === undefined || !validationMap?.size) {
        return false;
    }
    const unitValidationMap = validationMap.get(PROJECT_TASKS.BUSINESS_UNIT);
    if (!unitValidationMap?.size) {
        return false;
    }
    for (let [unitId, validation] of unitValidationMap) {
        if (unitId && !validation.complete) {
            return false;
        }
    }
    return true;
}

export const getProjectTaskGroup = (projectTask) => {
    switch (projectTask) {
        case PROJECT_TASKS.PROJECT:
        case PROJECT_TASKS.CONTRACT:
        case PROJECT_TASKS.PROCEDURE:
            return PROJECT_TASK_GROUP.PROJECT;
        case PROJECT_TASKS.BUSINESS_UNIT:
        case PROJECT_TASKS.RENTAL_LINEN:
        case PROJECT_TASKS.CUSTOMER_ARTICLES:
        case PROJECT_TASKS.RESIDENTS_LAUNDRY:
        case PROJECT_TASKS.OPERATING_RESOURCES:
        case PROJECT_TASKS.SPECIAL_SERVICES:
        case PROJECT_TASKS.OFFSETTING:
        case PROJECT_TASKS.LOGISTIC:
            return PROJECT_TASK_GROUP.BUSINESS_UNIT;
        case PROJECT_TASKS.DELIVERY_ADDRESS:
        case PROJECT_TASKS.PICKUP_ADDRESS:
        case PROJECT_TASKS.ARTICLE_ASSIGNMENT:
        case PROJECT_TASKS.DELIVERY_QUANTITY:
            return PROJECT_TASK_GROUP.LOGISTIC_UNIT;
        default:
            return null;
    }
}

export const getProjectTaskIcon = (projectTask) => {
    switch (projectTask) {
        case PROJECT_TASKS.PROJECT:
            return "icon-dashboard";
        case PROJECT_TASKS.CONTRACT:
            return "icon-note-euro";
        case PROJECT_TASKS.PROCEDURE:
            return "icon-note-hook";
        case PROJECT_TASKS.BUSINESS_UNIT:
            return "icon-building";
        case PROJECT_TASKS.RENTAL_LINEN:
        case PROJECT_TASKS.CUSTOMER_ARTICLES:
        case PROJECT_TASKS.RESIDENTS_LAUNDRY:
            return "icon-clothings";
        case PROJECT_TASKS.OPERATING_RESOURCES:
            return "icon-roll-cage";
        case PROJECT_TASKS.SPECIAL_SERVICES:
            return "icon-note-hook";
        case PROJECT_TASKS.OFFSETTING:
            return "icon-note-pencil";
        case PROJECT_TASKS.LOGISTIC:
        case PROJECT_TASKS.DELIVERY_ADDRESS:
        case PROJECT_TASKS.PICKUP_ADDRESS:
        case PROJECT_TASKS.ARTICLE_ASSIGNMENT:
        case PROJECT_TASKS.DELIVERY_QUANTITY:
            return "icon-truck";
        default:
            return "icon-cog";
    }
}

export const getValidationId = (projectTask, objectId, validationMap) => {
    if (!validationMap?.size || !projectTask || objectId === null || objectId === undefined) {
        return null;
    }
    for (let [key, value] of validationMap) {
        if (key === projectTask && value?.get(objectId)?.task === projectTask) {
            return value.id;
        }
    }
    return null;
}

export const convertValidationObjectToMap = (validationObject) => {
    if (validationObject) {
        let fullOuterMap = new Map();
        const outerMap = new Map(Object.entries(validationObject));
        for (let [key, value] of outerMap) {
            if (value) {
                let fullInnerMap = new Map();
                let innerMap = new Map(Object.entries(value));
                for (let [innerKey, innerValue] of innerMap) {
                    fullInnerMap.set(parseInt(innerKey), innerValue);
                }
                fullOuterMap.set(key, fullInnerMap);
            }
        }
        return fullOuterMap;
    }
    return new Map();
}

export const getRelevantConflictTypes = (documentType) => {
    switch (documentType) {
        case "assortmentAndResources":
            return [
                CONFLICT_TYPES.MISSING_BUSINESS_UNIT,
                CONFLICT_TYPES.ASSORTMENT_ARTICLES_MISSING,
                CONFLICT_TYPES.RESIDENTS_LAUNDRY_MISSING,
                CONFLICT_TYPES.CUSTOMER_ARTICLES_MISSING,
                CONFLICT_TYPES.OPERATING_RESOURCES_MISSING
            ];
        case "billOfQuantities":
            return [
                CONFLICT_TYPES.MISSING_BUSINESS_UNIT,
                CONFLICT_TYPES.ASSORTMENT_ARTICLES_MISSING,
                CONFLICT_TYPES.RESIDENTS_LAUNDRY_MISSING,
                CONFLICT_TYPES.CUSTOMER_ARTICLES_MISSING,
                CONFLICT_TYPES.OPERATING_RESOURCES_MISSING,
                CONFLICT_TYPES.RESOURCE_OFFSETTING_MISMATCH,
                CONFLICT_TYPES.STATIONARY_OFFSETTING_MISMATCH,
                CONFLICT_TYPES.WORK_WEAR_OFFSETTING_MISMATCH,
                CONFLICT_TYPES.SURGICAL_TEXTILES_OFFSETTING_MISMATCH,
                CONFLICT_TYPES.STATIONARY_OFFSETTING_MISSING,
                CONFLICT_TYPES.WORK_WEAR_OFFSETTING_MISSING,
                CONFLICT_TYPES.SURGICAL_TEXTILES_OFFSETTING_MISSING,
                CONFLICT_TYPES.RESOURCE_OFFSETTING_MISSING,
                CONFLICT_TYPES.SPECIAL_SERVICE_OFFSETTING_MISSING
            ];
        case "priceSheet":
            return [
                CONFLICT_TYPES.MISSING_BUSINESS_UNIT,
                CONFLICT_TYPES.ASSORTMENT_ARTICLES_MISSING,
                CONFLICT_TYPES.RESIDENTS_LAUNDRY_MISSING,
                CONFLICT_TYPES.CUSTOMER_ARTICLES_MISSING,
                CONFLICT_TYPES.OPERATING_RESOURCES_MISSING,
                CONFLICT_TYPES.RESOURCE_OFFSETTING_MISMATCH,
                CONFLICT_TYPES.STATIONARY_OFFSETTING_MISMATCH,
                CONFLICT_TYPES.WORK_WEAR_OFFSETTING_MISMATCH,
                CONFLICT_TYPES.SURGICAL_TEXTILES_OFFSETTING_MISMATCH,
                CONFLICT_TYPES.STATIONARY_OFFSETTING_MISSING,
                CONFLICT_TYPES.WORK_WEAR_OFFSETTING_MISSING,
                CONFLICT_TYPES.SURGICAL_TEXTILES_OFFSETTING_MISSING,
                CONFLICT_TYPES.RESOURCE_OFFSETTING_MISSING,
                CONFLICT_TYPES.SPECIAL_SERVICE_OFFSETTING_MISSING
            ];
        case "logistics":
            return [
                CONFLICT_TYPES.ONLY_ONE_CENTRAL_DELIVERY_ADDRESS,
                CONFLICT_TYPES.ONLY_ONE_CENTRAL_PICKUP_ADDRESS,
                CONFLICT_TYPES.CENTRAL_NO_DECENTRAL_DELIVERY_TIMES,
                CONFLICT_TYPES.CENTRAL_NO_DECENTRAL_PICK_UP_ADDRESSES,
                CONFLICT_TYPES.CENTRAL_MISSING_CENTRAL_DELIVERY_ADDRESS,
                CONFLICT_TYPES.CENTRAL_MISSING_CENTRAL_PICKUP_ADDRESS,
                CONFLICT_TYPES.DECENTRAL_NO_CENTRAL_DELIVERY_ADDRESSES,
                CONFLICT_TYPES.DECENTRAL_NO_CENTRAL_PICKUP_ADDRESSES,
                CONFLICT_TYPES.DECENTRAL_MISSING_DECENTRAL_PICKUP_ADDRESS,
                CONFLICT_TYPES.MISSING_DECENTRAL_DELIVERY_ADDRESS,
                CONFLICT_TYPES.SINGLE_NO_DECENTRAL_DELIVERY_ADDRESSES,
                CONFLICT_TYPES.SINGLE_NO_DECENTRAL_PICKUP_ADDRESSES,
                CONFLICT_TYPES.SINGLE_MISSING_CENTRAL_DELIVERY_ADDRESS,
                CONFLICT_TYPES.SINGLE_MISSING_CENTRAL_PICKUP_ADDRESS,
                CONFLICT_TYPES.DELIVERY_QUANTITIES_MISSING,
                CONFLICT_TYPES.CENTRAL_NO_CENTRAL_DELIVERY_QUANTITIES,
                CONFLICT_TYPES.CENTRAL_MISSING_CENTRAL_DELIVERY_TIMES,
                CONFLICT_TYPES.CENTRAL_MISSING_CENTRAL_PICKUP_TIMES,
                CONFLICT_TYPES.DECENTRAL_MISSING_DECENTRAL_DELIVERY_TIMES,
                CONFLICT_TYPES.DECENTRAL_MISSING_DECENTRAL_PICKUP_TIMES,
                CONFLICT_TYPES.SINGLE_MISSING_CENTRAL_DELIVERY_TIMES,
                CONFLICT_TYPES.SINGLE_MISSING_CENTRAL_PICKUP_TIMES
            ];
        case "contract":
            return [];
        default:
            return [];
    }
}
