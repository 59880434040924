import { Grid, TextField, Tooltip } from "@material-ui/core";
import { Component } from "react";
import { injectIntl } from "react-intl";
import { GeneralContext } from "../../contexts/GeneralContext";
import {isOfferUser} from "../../../services/UserService";
import {getActiveOperation} from "../../../services/OperationService";
import {disableNewPriceItemForTenderUser} from "../../../services/PriceOfferService";
import {isCockpit} from "../../../util/Util";
import {PriceType} from "../../../util/Constants";

class ArticleNumberCell extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.isNewCockpitItemForTenderUser = false;
    }

    componentDidMount() {
        this.isNewCockpitItemForTenderUser = isCockpit()
            && isOfferUser(getActiveOperation(this.context)?.bidder)
            && disableNewPriceItemForTenderUser(this.context, this.props.priceItem, this.props.exportType);
    }

    handleArticleNumberChange = (newArticleNumber, index) => {
        let priceItem = this.props.priceItem;
        let newList = priceItem.articleNumbers;
        let oldArticleNumber = priceItem.articleNumbers[index];
        let allNumbers = this.props.allArticleNumbers;

        allNumbers[oldArticleNumber] = allNumbers[oldArticleNumber] - 1;
        if (newArticleNumber === "") {
            newList = newList.filter((number) => number !== newList[index]);
        } else {
            let formatted = newArticleNumber.trim().toUpperCase();
            allNumbers[formatted] = allNumbers[formatted] === undefined ? 1 : allNumbers[formatted] + 1;
            newList[index] = formatted;
        }
        priceItem.articleNumbers = newList;
        this.props.handleItemChange(priceItem);
        this.context.setUnsavedChanges(true);
        this.setState({});
    }

    render() {
        const priceItem = this.props.priceItem;

        const prefix = priceItem?.type?.toString() + "-" + priceItem?.objectId?.toString();
        let index = 0;
        if (priceItem !== null && priceItem?.articleNumbers.length === 0) {
            priceItem.articleNumbers[0] = " ";
        }

        return (
            <Tooltip title={this.isNewCockpitItemForTenderUser 
                ? this.props.intl.formatMessage({id: "cockpit.priceSheet.newArticle.tooltip"}) : ""}>
                <Grid container justifyContent="center">
                    {priceItem?.articleNumbers.map(articleNumber => {

                        const id = index++;
                        const isDuplicate = this.props.allArticleNumbers[articleNumber] > 1;
                        // do not show helper text if item is rental linen, because there are cases where duplicate article numbers are allowed
                        const showError = isDuplicate && ![PriceType.ARTICLE_WEEKLY, PriceType.ARTICLE_QUANTITY].includes(this.props.priceItem.type);

                        return <Grid item xs={12} key={"article-number-cell-" + prefix + "-" + id.toString()}>
                            <TextField 
                                key={prefix + "-" + id.toString()}
                                value={articleNumber.toString()} 
                                disabled={this.props.disabled || this.isNewCockpitItemForTenderUser} 
                                onChange={(e) => this.handleArticleNumberChange(e.target.value, id)}
                                helperText={showError ? this.props.intl.formatMessage({id: "bidder.priceSheet.error.duplicate.subtitle"}) : ""}
                                error={showError}/>
                        </Grid>
                    })}
                </Grid>
            </Tooltip>
        );
    }
}

export default injectIntl(ArticleNumberCell);
