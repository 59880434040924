import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Grid, Switch} from "@material-ui/core";
import ExportConflicts from "../validation/ExportConflicts";
import {getFlatValidationList, getRelevantConflictTypes} from "../../util/ValidationUtil";
import {BILL_OF_QUANTITIES_TYPE, EXPORT_TYPE_CONCATENATE, EXPORT_TYPE_MERGE} from "../../util/Constants";
import {LinearProgressIndicator} from "../uiLibrary/LinearProgressIndicator";
import {GeneralContext} from "../contexts/GeneralContext";
import {getExportType, isExportTypeMerge} from "../../util/DocumentUtil";

class ExportButton extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            buttonType: null,
            isExporting: false
        };
    }

    async componentDidMount() {
        if (this.hasConflicts() && isExportTypeMerge(this.context)) {
            await this.context.setUserStateValue(BILL_OF_QUANTITIES_TYPE, EXPORT_TYPE_CONCATENATE);
        }
    }

    handleExportButton = async(e) => {
        if (!this.hasConflicts()) {
            this.setState({isExporting: true});
            await this.props.export(getExportType(this.context), e);
            this.setState({isExporting: false});
        } else {
            this.handleDialog(true, "export");
        }
    }

    handleExportSwitchClicked = (newValue) => {
        if (newValue && this.hasConflicts()) {
            this.handleDialog(true, "merge");
        } else {
            this.props.handleExportSwitch(newValue);
        }
    }

    handleDialog = (newValue, buttonType) => {
        this.setState({dialogOpen: newValue, buttonType: buttonType});
    }

    hasConflicts = () => {
        const validationList = getFlatValidationList(this.context.appData.validationMap);
        if (!validationList?.length) {
            return false;
        }
        const relevantConflictTypes = getRelevantConflictTypes(this.props.documentType);
        for (let validation of validationList) {
            if (validation.conflictTypes?.length) {
                for (let conflictType of validation.conflictTypes) {
                    if (relevantConflictTypes.includes(conflictType)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    render() {
        return (
            <>
                <Grid container justifyContent="flex-end" alignItems="center" style={{minWidth: "350px", maxWidth: "350px"}}>

                    <Grid container item xs={12} justifyContent="flex-end" alignItems="center">

                        <div style={{position: "relative"}}>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={this.props.exportDisabled}
                                style={{minWidth: "300px", maxWidth: "300px", marginRight: "10px", display: "block"}}
                                onClick={(e) => this.handleExportButton(e)}>
                                <FormattedMessage id={this.props.exportButtonId}/>
                            </Button>

                            {this.hasConflicts() && <div style={{position: "absolute", left: "-7px", top: "-7px"}}>
                                <ExportConflicts
                                    dialogOpen={this.state.dialogOpen}
                                    handleDialog={this.handleDialog}
                                    documentType={this.props.documentType}
                                    buttonType={this.state.buttonType}
                                />
                            </div>}

                        </div>

                    </Grid>

                    {this.props.handleExportSwitch &&
                        <Grid item xs={12} container justifyContent="flex-end" alignItems="center" spacing={1}>
                            <FormattedMessage id="export.switch.title"/>
                            <Switch
                                checked={this.context.getUserStateValue(BILL_OF_QUANTITIES_TYPE) === EXPORT_TYPE_MERGE && !this.hasConflicts()}
                                onChange={(e) => this.handleExportSwitchClicked(e.target.checked)}
                                name="exportSwitch"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                        </Grid>}
                </Grid>

                {(this.state.isExporting || this.props.isExporting) && <Grid item xs={12} style={{marginTop: 10}}>
                    <LinearProgressIndicator active={this.state.isExporting || this.props.isExporting}/>
                </Grid>}
            </>
        );
    }
}

export default injectIntl(ExportButton);
