import {FEDERAL_STATES} from "./Constants";
import {formatDate} from "./DocumentUtil";

const moment = require('moment-feiertage');

export const isDateInvalid = (newDate, federalState) => {
    let isHoliday = moment(newDate).isHoliday(federalState.toString());
    return newDate.getDay() === 0 || newDate.getDay() === 6 || isHoliday;
}

export const convertStringToDate = (dateString) => {
    if (!dateString) {
        return null;
    }
    return new Date(dateString);
}

export const setWorkingDay = (newDateInMillis, federalState) => {
    let result = newDateInMillis
    while (isDateInvalid(new Date(result), federalState)) {
        result = result + 86400000;
    } 
    return result;
}

// return true if date1 (last procedure date) is less than 90 days before the contract start 
export const areDeadlinesTooTight = (date1, date2) => {
    if (!date1 || !date2) {
        return false;
    } else {
        // 90 days
        return date2 - date1 < 7776000000;
    }
}

export const lastYear = () => {
    // javascript style
    return new Date(new Date().setFullYear(new Date().getFullYear()-1));
}

export const canSaveDeadlines = (procedure) => {
    const {firstCallForSubmissionDate, visitDate, questionsDeadline, responseDeadline, firstOfferDeadline, conceptEvaluationDate, 
        negotiationDate, bindingDeadline, startOfServiceDate, federalState} = procedure;
    return firstCallForSubmissionDate > lastYear()
        && firstCallForSubmissionDate < visitDate 
        && visitDate < questionsDeadline 
        && questionsDeadline < responseDeadline 
        && responseDeadline < firstOfferDeadline 
        && firstOfferDeadline < conceptEvaluationDate 
        && conceptEvaluationDate < negotiationDate
        && negotiationDate < bindingDeadline
        && bindingDeadline < startOfServiceDate
        && FEDERAL_STATES[federalState] !== FEDERAL_STATES.UNDEFINED;
}

export const performSuggestions = (procedure, onSave, onInvalidStartTime, onTight) => {
    const federalState = procedure.federalState;
    const firstCallForSubmissionDate = procedure.firstCallForSubmissionDate;
    const dayInMilliSeconds = 86400000;
    procedure.visitDate = setWorkingDay(firstCallForSubmissionDate+14*dayInMilliSeconds, federalState);
    procedure.questionsDeadline = setWorkingDay(procedure.visitDate+7*dayInMilliSeconds, federalState);
    procedure.responseDeadline = setWorkingDay(procedure.questionsDeadline + dayInMilliSeconds, federalState);
    procedure.firstOfferDeadline = setWorkingDay(procedure.responseDeadline+7*dayInMilliSeconds, federalState);
    procedure.conceptEvaluationDate = setWorkingDay(procedure.firstOfferDeadline+14*dayInMilliSeconds, federalState);
    procedure.negotiationDate = setWorkingDay(procedure.conceptEvaluationDate+7*dayInMilliSeconds, federalState);
    procedure.bindingDeadline = setWorkingDay(procedure.negotiationDate + 21 * dayInMilliSeconds, federalState);
    onSave(procedure);
    if (procedure.startOfServiceDate && procedure.startOfServiceDate > 0 && procedure.startOfServiceDate <= procedure.bindingDeadline) {
        onInvalidStartTime();
    } else if (areDeadlinesTooTight(procedure.bindingDeadline, procedure.startOfServiceDate)) {
        onTight(procedure.bindingDeadline);
    }
}

// adds trailing Zero. 1.6.2020 -> 01.06.2020
export const getSimpleDate = (date) => {
    return ('0' + date.getDate()).slice(-2) + '.'
        + ('0' + (date.getMonth() + 1)).slice(-2) + '.'
        + date.getFullYear()
}

export function remapDay(month, day) {
    switch (month) {
        case 1:
            return Math.min(day, 28);
        case 3:
        case 5:
        case 8:
        case 10:
            return Math.min(day, 30);
        default:
            return Math.min(day, 31);
    }
}

export function getEffectiveFromSuffix(intl, operation) {
    const effectiveFrom = operation?.workingProject?.effectiveFrom;
    return effectiveFrom && effectiveFrom > 1
        ? (" " + intl.formatMessage({id: "cockpit.contractChanges.effectiveFrom"}) + " "
            + formatDate(intl, effectiveFrom))
        : "";
}

// month starts at 1
export function getMaxDay(month) {
    switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
            return 31;
        case 2:
            return 28;
        default:
            return 30;
    }
}
