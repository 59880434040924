import { AppBar, FormControl, Grid, InputLabel, List, MenuItem, Select, SwipeableDrawer, Toolbar } from "@mui/material";
import React, { Component } from "react";
import {headerColor, white} from "../../../util/ColorTheme";
import {
    APP_TYPE_KEY,
    COCKPIT_APP,
    EVENT_DURATIONS,
    LAST_OPERATION_ID,
    LAST_PROJECT_ID,
    OFFER_APP,
    ORDER_APP,
    TENDER_APP
} from "../../../util/Constants";
import ProfileMenu from '../shared/ProfileMenu';
import {TenderBreadCrumbs} from "../tender/TenderBreadCrumbs";
import LegalMenu from '../shared/LegalMenu';
import AdminMenu from '../shared/AdminMenu';
import { GeneralContext } from "../../contexts/GeneralContext";
import AppHeader from "./AppHeader";
import {injectIntl} from "react-intl";
import {
    isAdmin,
    isBidderReadOnly,
    isOrderUser,
    isProjectAdmin,
    mayUseCockpit,
    mayUseOffer,
    mayUseOrder,
    mayUseTender
} from "../../../services/UserService";
import SupportMenu from "./SupportMenu";
import SupportDrawer from "./SupportDrawer";
import { withRouter } from "react-router-dom";
import DummyProjectCreator from "./DummyProjectCreator";
import CalendlyMenu from "./CalendlyMenu";
import {CalendlyPopup} from "../../calendly/CalendlyPopup";
import ActionButtons from "./ActionButtons";
import {isCockpit} from "../../../util/Util";

class NavBar extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showSideMenu: false,
            showCalendly: false,
            showFreeAnalysisDialog: false,
            showNoAnalysisBookedDialog: false,
            calendlyEventType: null
        };
    }

    async componentDidMount() {
        let search = window.location.search;
        let requestParameters = new URLSearchParams(search);
        
        // change app via url parameter
        const appType = requestParameters.get("app-type");

        if (appType) {

            requestParameters.delete("app-type");
            this.props.history.replace({
                search: requestParameters.toString(),
            });

            if ([TENDER_APP, OFFER_APP, COCKPIT_APP, ORDER_APP].includes(appType) 
                && localStorage.getItem(APP_TYPE_KEY) !== appType) {

                // return is necessary because navbar will be newly constructed
                return await this.context.setActiveApp(appType);
            }
        }

        // go to specific operation via url parameter
        const operationIdString = requestParameters.get("operation-id");

        if (operationIdString) {

            const operationId = parseInt(operationIdString);

            const operation = this.context.appData.operations?.find(o => o.id === operationId);

            if (operation) {
                if (isCockpit()) {
                    await this.context.setActiveOperationIdForCockpit(operationId);
                } else {
                    this.context.setActiveOperationId(operationId);
                }
                localStorage.setItem(LAST_OPERATION_ID, operationId);
    
                let projectId;
    
                switch (appType) {
                    case TENDER_APP:
                    case COCKPIT_APP:
                    case ORDER_APP:
                        projectId = operation?.activeProject?.id;
                        break;
                    default:
                        break;
                }
                
                if (projectId) {
                    this.context.setActiveProjectId(projectId);
                    localStorage.setItem(LAST_PROJECT_ID, projectId);
                }   
            }

            requestParameters.delete("operation-id");
            this.props.history.replace({
                search: requestParameters.toString(),
            });
        }

        // open support chat via url parameter
        const chatOpen = requestParameters.get("chat-open");

        if (chatOpen) {

            if (chatOpen === "true") {
                this.context.handleSupportOverlay();
            }

            requestParameters.delete("chat-open");
            this.props.history.replace({
                search: requestParameters.toString(),
            });
        }
    }

    isApp = (appType) => {
        return localStorage.getItem(APP_TYPE_KEY) === appType;
    }

    miniAppSwitcher = () => {

        const user = this.context.currentUser;

        if (isOrderUser(user) || isBidderReadOnly(user)) {
            return <div/>;
        }

        let currentAppName;

        switch (localStorage.getItem(APP_TYPE_KEY)) {
            case COCKPIT_APP:
                currentAppName = "Cockpit";
                break;
            case ORDER_APP:
                currentAppName = "Order";
                break;
            case OFFER_APP:
                currentAppName = "Offer";
                break;
            case TENDER_APP:
            default:
                currentAppName = "Tender";
                break;
        }

        return (
            <div style={{margin: 15, marginTop: 30}}>
                <FormControl fullWidth>

                    <InputLabel 
                        id="mini-app-switcher">
                        {this.props.intl.formatMessage({id: "app.switch.title"})}
                    </InputLabel>

                    <Select
                        labelId="mini-app-switcher-select"
                        label={this.props.intl.formatMessage({id: "app.switch.title"})}
                        value={currentAppName}>

                        {mayUseTender(user) && <MenuItem 
                            key="mini-app-switcher-tender" 
                            value={"Tender"}
                            onClick={() => {
                                this.setState({showMobileDrawer: false});
                                this.context.setActiveApp(TENDER_APP);
                            }}
                            disabled={this.context.offline}>
                            {"Tender"}
                        </MenuItem>}

                        {mayUseOffer(user) && <MenuItem 
                            key="mini-app-switcher-offer" 
                            value={"Offer"}
                            onClick={() => {
                                this.setState({showMobileDrawer: false});
                                this.context.setActiveApp(OFFER_APP);
                            }}
                            disabled={this.context.offline}>
                            {"Offer"}
                        </MenuItem>}
                        

                        {mayUseCockpit(user) && <MenuItem 
                            key="mini-app-switcher-cockpit"
                            value="Cockpit"
                            onClick={() => {
                                this.setState({showMobileDrawer: false});
                                this.context.setActiveApp(COCKPIT_APP);
                            }}
                            disabled={this.context.offline}>
                            {"Cockpit"}
                        </MenuItem>}

                        {mayUseOrder(user) && <MenuItem 
                            key="mini-app-switcher-order"
                            value="Order"
                            onClick={() => {
                                this.setState({showMobileDrawer: false});
                                this.context.setActiveApp(ORDER_APP);
                            }}>
                            {"Order"}
                        </MenuItem>}

                    </Select>

                </FormControl>
            </div>
        );
    }

    getOperation = () => {
        const operationId = localStorage.getItem(LAST_OPERATION_ID);
        return this.context.appData.operations?.find(o => o.id.toString() === operationId);
    }

    render() {
        const iconColor = this.props.mobile ? headerColor : white;
        const backgroundColor = this.props.mobile ? white : headerColor;

        const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

        return (
            <AppBar 
                elevation={10}
                style={{backgroundColor: backgroundColor}}>

                <CalendlyPopup
                    key={"calendly-nav-bar-popup-operation-" + this.getOperation()?.id
                        + "-type-" + this.state.calendlyEventType + "-duration-" + EVENT_DURATIONS.HALF_HOUR}
                    showCalendly={this.state.showCalendly}
                    operationId={this.getOperation()?.id}
                    eventType={this.state.calendlyEventType}
                    eventDuration={EVENT_DURATIONS.HALF_HOUR}
                    onClose={() => this.setState({showCalendly: false})}/>

                <SwipeableDrawer
                    anchor="left"
                    open={!this.props.mobile || this.state.showSideMenu}
                    onOpen={() => this.setState({showSideMenu: true})}
                    onClose={() => this.setState({showSideMenu: false})}
                    variant={this.props.mobile ? "temporary" : "permanent"}
                    className="drawer"
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    color={backgroundColor}
                    style={{overflowX: "hidden"}}
                    classes={{paper: "drawer-paper", overflowX: "hidden"}}>

                    {!this.props.mobile && <AppHeader mobile={false}/>}

                    <List
                        component="nav"
                        id="main-menu">
                        {this.props.mobile && this.miniAppSwitcher()}
                        {this.props.sideMenuItems}
                    </List>

                    {(this.isApp(TENDER_APP) || this.isApp(OFFER_APP) || this.isApp(COCKPIT_APP)) && <DummyProjectCreator/>}

                </SwipeableDrawer>

                <SwipeableDrawer 
                    anchor="right"
                    open={this.context.showSupportOverlay}
                    onOpen={() => this.context.handleSupportOverlay()}
                    onClose={() => this.context.handleSupportOverlay()}
                    variant="temporary"
                    className="support-drawer"
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    color={backgroundColor}
                    classes={{paper: "support-drawer-paper"}}>

                    <SupportDrawer/>
                    
                </SwipeableDrawer>

                <Toolbar 
                    id={this.props.mobile ? null : "main-toolbar"} 
                    style={{backgroundColor: backgroundColor}}>

                    <Grid 
                        className="scrollbar-hidden" 
                        container 
                        justifyContent="space-between" 
                        alignItems="center" 
                        spacing={1} 
                        wrap="nowrap" 
                        style={{overflowX: "auto", scrollbarWidth: "none", msOverflowStyle: "none"}}>

                        <Grid item>
                            {this.props.mobile 
                            && <AppHeader 
                                mobile={true}
                                showMobileDrawer={() => this.setState({showSideMenu: true})}/>}
                        </Grid>

                        <Grid item xs>
                            {this.isApp(TENDER_APP) && !this.props.mobile && <TenderBreadCrumbs/>}
                        </Grid>

                        <Grid item>
                            <Grid container alignItems="center" spacing={1} wrap="nowrap">

                                <Grid item>
                                    <ActionButtons
                                        mobile={this.props.mobile}
                                        showCalendly={(type) => this.setState({showCalendly: true, calendlyEventType: type})}/>
                                </Grid>

                                <Grid item>
                                    <SupportMenu color={iconColor}/>
                                </Grid>

                                {(isAdmin(this.context.currentUser) || isProjectAdmin(this.context.currentUser)) && !!this.getOperation() && <Grid item>
                                    <CalendlyMenu
                                        color={iconColor}
                                        operationId={this.getOperation().id}
                                        showCalendly={(eventType) => this.setState({showCalendly: true, calendlyEventType: eventType})}/>
                                </Grid>}

                                {(isAdmin(this.context.currentUser) || isProjectAdmin(this.context.currentUser)) && <Grid item>
                                    <AdminMenu color={iconColor}/>
                                </Grid>}

                                <Grid item>
                                    <LegalMenu color={iconColor}/>
                                </Grid>

                                <Grid item>
                                    <ProfileMenu color={iconColor}/>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>

                </Toolbar>

            </AppBar>
        );
    }
}

export default withRouter(injectIntl(NavBar));
