import {deleteAsyncCatch, getAsync, getAsyncCatch, postAsyncCatch, putAsyncCatch, uploadBlobCatch} from './BackendService';
import {isTender, sortAsc} from "../util/Util";
import {getActiveOperation} from "./OperationService";

export function getActiveUnit(context) {
    if (context.appData.activeProjectId === null || context.appData.activeProjectId === undefined
        || context.appData.activeUnitId === null || context.appData.activeUnitId === undefined) {
        return null;
    }
    if (isTender()) {
        return context.appData.projectIdToUnitsMap?.get(context.appData.activeProjectId)?.find(u => u.id === context.appData.activeUnitId);
    } else {
        const activeOperation = getActiveOperation(context);
        let project;
        if (activeOperation?.activeProject?.id === context.appData.activeProjectId) {
            project = activeOperation.activeProject;
        } else if (activeOperation?.workingProject?.id === context.appData.activeProjectId) {
            project = activeOperation.workingProject;
        }
        if (project) {
            return project.businessUnits?.units?.find(u => u.id === context.appData.activeUnitId);
        }
    }
}

export function getUnitById(context, unitId) {
    return context.appData.allProjects?.find(p => p.id === context.appData.activeProjectId)?.businessUnits?.units?.find(u => u.id === unitId);
}

export async function getBusinessUnitById(context, props, id) {
    const businessUnit = await getAsyncCatch(context, "/unit/" + id, props);
    return businessUnit ? businessUnit : null;
}

export async function createBusinessUnit(context, props, businessUnit, croppedImageBlob, selectedLogo, projectId, hideSuccess, reloadAppData) {
    const unitProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    const errorTextMap = new Map();
    errorTextMap.set(402, "businessUnit.maxCount");
    const createdUnit = await postAsyncCatch(context, "/" + unitProjectId + "/unit", businessUnit, props, hideSuccess, errorTextMap);
    if (!createdUnit) {
        return null;
    }
    let currentVersion = createdUnit.version;
    if (croppedImageBlob) {
        await uploadBlobCatch(context, '/unit/image/' + unitProjectId + "/" + createdUnit.id + "/" + currentVersion, croppedImageBlob,
            null, props, true);
    }
    if (selectedLogo) {
        // get current version because it could have been changed by the previous image update
        currentVersion = (await getBusinessUnitById(context, props, createdUnit.id))?.version;
        await uploadBlobCatch(context, '/unit/logo/' + unitProjectId + "/" + createdUnit.id + "/" + currentVersion, selectedLogo,
            null, props, true);
    }
    if (reloadAppData) {
        await context.reloadAppData();
    }
    return createdUnit;
}

export async function updateBusinessUnit(context, props, businessUnit, croppedImageBlob, imageToDelete, selectedLogo, logoToDelete, projectId, hideSuccess) {
    const unitProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    const updatedUnit = await putAsyncCatch(context, "/" + unitProjectId + "/unit/", businessUnit, props, hideSuccess);
    if (!updatedUnit) {
        return null;
    }
    let currentVersion = updatedUnit.version;
    if (croppedImageBlob) {
        await uploadBlobCatch(context, '/unit/image/' + unitProjectId + "/" + updatedUnit.id + "/" + currentVersion, croppedImageBlob,
            null, props, true);
    } else if (imageToDelete) {
        await deleteAsyncCatch(context, '/unit/image/' + unitProjectId + "/" + imageToDelete.parentId + "/" + imageToDelete.filename, props, null, true);
    }
    if (selectedLogo) {
        // get current version because it could have been changed by the previous image update
        currentVersion = (await getBusinessUnitById(context, props, updatedUnit.id))?.version;
        await uploadBlobCatch(context, '/unit/logo/' + unitProjectId + "/" + updatedUnit.id + "/" + currentVersion, selectedLogo,
            null, props, true);
    } else if (logoToDelete) {
        await deleteAsyncCatch(context, '/unit/logo/' + unitProjectId + '/' + logoToDelete.parentId + "/" + logoToDelete.filename, props, null, true);
    }
    return updatedUnit;
}

export async function deleteBusinessUnit(context, props, unitId) {
    const success = await deleteAsyncCatch(context, "/" + context.appData.activeProjectId + "/unit/" + unitId, props);
    if (success) {
        await context.reloadAppData();
    }
    return success;
}

export async function reloadUnitsForActiveProject(context) {
    const response = await getAsync('/project/' + context.appData.activeProjectId);
    if (response?.status === 200) {
        const allUnits = response.data?.businessUnits?.units;
        if (allUnits?.length) {
            allUnits.sort((a, b) => sortAsc(a.name, b.name));
            const projectIdToUnitsMap = context.appData.projectIdToUnitsMap;
            projectIdToUnitsMap.set(response.data.id, allUnits);
            context.setProjectIdToUnitsMap(projectIdToUnitsMap);
        }
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    }
}

export function getUnitsForActiveProjectFromContext(context) {
    let projectId = context.appData.activeProjectId;
    const projectIdToUnitsMap = context.appData.projectIdToUnitsMap;
    if (!projectIdToUnitsMap || !projectIdToUnitsMap.get(projectId)) {
        return [];
    }
    return projectIdToUnitsMap.get(projectId);
}

export async function getBusinessUnitVersion(context, props, unitId) {
    const businessUnitId = unitId !== null && unitId !== undefined ? unitId : context.appData.activeUnitId;
    const unit = await getAsyncCatch(context, "/unit/" + businessUnitId, props);
    if (unit) {
        return unit.version;
    } else {
        return null;
    }
}

export async function clearBusinessUnitArticles(context, props, projectId, unitId) {
    const path = "/" + projectId + "/unit/" + unitId + "/clear";
    await deleteAsyncCatch(context, path, props, null, true);
}
