import {Component} from "react";
import {ComposableMap, Geographies, Geography, Marker, ZoomableGroup} from "react-simple-maps";
import {FEDERAL_STATES_CAPITALS_DATA} from "../../../util/Constants";
import {primaryColor, texisionOrange} from "../../../util/ColorTheme";
import {Button, Tooltip} from "@material-ui/core";
import BidderSummary from "./BidderSummary";
import {FormattedMessage, injectIntl} from "react-intl";

class BidderMap extends Component {

    render() {
        return (
            <div style={{height: 500, width: 1000, overflow: "hidden"}}>
                <ComposableMap
                    height={500}
                    width={1000}
                    projectionConfig={{
                        scale: 3000,
                        center: [this.props.sourceLongitude, this.props.sourceLatitude]
                    }}>
                    <ZoomableGroup zoom={this.props.defaultZoom} center={[this.props.sourceLongitude, this.props.sourceLatitude]}>

                        <Geographies
                            geography={"/germany-geo.json"}>
                            {({geographies}) => {
                                return geographies.map((geo) => {
                                    return <>
                                        <Geography
                                            key={geo.rsmKey} geography={geo}
                                            fill="#9998A3"
                                            stroke="#EAEAEC"/>
                                    </>})}
                            }
                        </Geographies>

                        {FEDERAL_STATES_CAPITALS_DATA.map(cd => <Marker
                            coordinates={cd.coordinates}
                            style={{marginTop: 10}}>
                            <circle r={1} fill="black"/>
                            <text textAnchor="middle" y={cd.labelOffsetY} fontSize="6pt" style={{marginBottom: 10}}>
                                {this.props.intl.formatMessage({id: "constants.cities." + cd.textKey})}
                            </text>
                        </Marker>)}

                        {this.props.userZipDistances?.map(userZipDistance => {
                            const isSelected = !!this.props.selectedBidders.find(s => s.id === userZipDistance.userVo.id);
                            const targetCoordinates = userZipDistance.zipDistanceVo.targetCoordinates;
                            return (
                                <Tooltip
                                    interactive={true}
                                    title={<>
                                        <BidderSummary userZipDistanceVo={userZipDistance}/>
                                        <Button
                                            onClick={() => this.props.onBidderSelect(!isSelected, userZipDistance.userVo)}
                                            color="primary"
                                            style={{marginTop: 10, marginLeft: 5, marginBottom: 10}}
                                            variant="contained">
                                            <FormattedMessage id={isSelected ? "commons.remove.button" : "commons.select"}/>
                                        </Button>
                                    </>}>
                                    <Marker
                                        coordinates={[targetCoordinates?.longitude, targetCoordinates?.latitude]}>
                                        <g
                                            fill="none"
                                            stroke={isSelected ? primaryColor : texisionOrange}
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            transform="translate(-12, -24)"
                                            onClick={() => this.props.onBidderSelect(!isSelected, userZipDistance.userVo)}>
                                            <circle
                                                cx="12"
                                                cy="10"
                                                r={3}
                                                fill={isSelected ? primaryColor : texisionOrange}/>
                                            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                                        </g>
                                    </Marker>
                                </Tooltip>
                            );
                        })}
                    </ZoomableGroup>
                </ComposableMap>
            </div>
        );
    }
}

export default injectIntl(BidderMap);
