import { Button, Grid, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { LinearProgressIndicator } from "../uiLibrary/LinearProgressIndicator";

const PriceSheetHeader = ({disabled, exportPriceSheet, isDownloading, titleId, subtitleId}) => {
    return (
        <>
            <LinearProgressIndicator active={isDownloading}/>

            <Grid container>

                <Grid item xs style={{marginRight: 10}}>

                    <Typography variant="h1">
                        <FormattedMessage id={titleId}/>
                    </Typography>

                    <Typography variant="subtitle1">
                        <FormattedMessage id={subtitleId}/>
                    </Typography>

                </Grid>

                <Grid item>
                    <Button
                        variant="contained" 
                        style={{marginBottom: 20}} 
                        color="primary"
                        disabled={disabled || isDownloading}
                        onClick={() => exportPriceSheet()}>
                        <FormattedMessage id="bidder.priceSheet.download.button"/>
                    </Button>
                </Grid>

            </Grid>
        </>
    );
}

export {
    PriceSheetHeader
}
