import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {injectIntl} from 'react-intl';
import {withSnackbar} from 'notistack';

import '../../apps/App.css';
import '../../../css/ArticleOffsetting.css';
import {GeneralContext} from "../../contexts/GeneralContext";
import SpecialServiceOffsettings from './SpecialServiceOffsettings';
import ResourceOffsettings from './ResourceOffsettings';
import ArticleAndLaundryOffsettings from './ArticleAndLaundryOffsettings';
import {getBusinessUnitVersion} from "../../../services/BusinessUnitService";
import {getResidentsLaundryForUnit} from "../../../services/ResidentsLaundryService";
import {loadOperatingResourcesAndOffsettingMap, saveResourceOffsettings} from "../../../services/OperatingResourceService";
import {createErrorMessage, createSuccessMessage} from "../../../util/Util";
import {updateValidationMap} from "../../../services/ProjectService";

class OffsettingOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            unitVersion: 0,
            residentsLaundry: [],
            operatingResources: [],
            oldResourcesOffsettingMap: new Map()
        }
    }

    async componentDidMount() {
        await this.loadResourceData();
        this.setState({residentsLaundry: (await getResidentsLaundryForUnit(this.context, this.props)).filter(l => l.selected === true && l.amount > 0)});
        await this.updateBusinessUnitVersion();
    }

    updateBusinessUnitVersion = async() => {
        this.setState({unitVersion: await getBusinessUnitVersion(this.context, this.props)});
    }

    loadResourceData = async() => {
        const resourcesAndOffsettingMap = await loadOperatingResourcesAndOffsettingMap(this.context, this.props);
        this.setState({
            operatingResources: resourcesAndOffsettingMap.operatingResources,
            oldResourcesOffsettingMap: resourcesAndOffsettingMap.oldResourcesOffsettingMap
        });
    }

    onSave = async(newResourcesOffsettingMap) => {
        await saveResourceOffsettings(
            this.context, this.props,
            this.state.oldResourcesOffsettingMap, newResourcesOffsettingMap, this.state.operatingResources,
            this.onSuccess, this.onError, this.onFinish
        );
    }

    onSuccess = async() => {
        createSuccessMessage(this.props.intl.formatMessage({id: "operatingResources.offsetting.update.successMessage"}), this.props);
        await this.loadResourceData();
        await this.updateBusinessUnitVersion();
    }

    onError = async() => {
        createErrorMessage(this.props.intl.formatMessage({id: "operatingResources.offsetting.update.errorMessage"}), this.props);
    }

    onFinish = async() => {
        await updateValidationMap(this.context);
    }

    render() {

        return (
            <>
                <Grid className="parent">

                    <ArticleAndLaundryOffsettings
                        projectId={this.context.appData.activeProjectId}
                        unitId={this.context.appData.activeUnitId}
                        readOnly={this.props.readOnly}
                        residentsLaundry={this.state.residentsLaundry}/>

                    <ResourceOffsettings
                        key={"resource-offsetting-map-" + this.state.oldResourcesOffsettingMap?.size}
                        readOnly={this.props.readOnly}
                        operatingResources={this.state.operatingResources}
                        oldResourcesOffsettingMap={this.state.oldResourcesOffsettingMap}
                        onSave={(newResourcesOffsettingMap) => this.onSave(newResourcesOffsettingMap)}/>

                    <SpecialServiceOffsettings readOnly={this.props.readOnly}/>

                </Grid>
            </>
        );
    }
}

export default injectIntl(withSnackbar(OffsettingOverview));
