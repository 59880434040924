import {TextField} from '@material-ui/core';
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import {withSnackbar} from 'notistack';
import {RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL, RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH} from "../../../util/Constants";
import {GeneralContext} from "../../contexts/GeneralContext";
import {getResidentsLaundryForUnit, updateResidentsLaundry} from "../../../services/ResidentsLaundryService";


class ResidentsLaundry extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {residentsLaundry: []}
    }

    async componentDidMount() {
        this.setState({residentsLaundry: await getResidentsLaundryForUnit(this.context, this.props)});
    }

    save = async() => {
        const updatedResidentsLaundry = await updateResidentsLaundry(this.context, this.props, this.state.residentsLaundry);
        this.context.setUnsavedChanges(!updatedResidentsLaundry);
        this.setState({residentsLaundry: await getResidentsLaundryForUnit(this.context, this.props)});
    }

    handleCheckBoxChanged = (id, newValue) => {
        this.state.residentsLaundry.find(l => l.id === id).selected = newValue;
        this.context.setUnsavedChanges(true);
        this.setState({});
    }

    handleAllCheckBoxesChanged = (newValue, cleanType) => {
        for (let l of this.state.residentsLaundry.filter(l => l.cleanType === cleanType)) {
            l.selected = newValue;
        }
        this.context.setUnsavedChanges(true);
        this.setState({});
    }

    handleTextFieldsChanged = (id, newAmount) => {
        this.state.residentsLaundry.find(l => l.id === id).amount = newAmount;
        this.context.setUnsavedChanges(true);
        this.setState({});
    }

    renderTitleRow = (cleanType) => {
        let tableTitle;
        if (cleanType === RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH) {
            tableTitle = "wash";
        } else {
            tableTitle = "chemical";
        }
        let selected = true;
        if (!this.state.residentsLaundry || this.state.residentsLaundry.length < 1) {
            selected = false;
        } else {
            for (let l of this.state.residentsLaundry.filter(l => l.cleanType === cleanType)) {
                if (!l?.selected) {
                    selected = false;
                    break;
                }
            }
        }
        return (
            <div key={cleanType}>
                <Grid container alignItems="center">
                    <Grid style={{width: "50px"}}>
                        <Checkbox
                            color="primary"
                            indeterminate = {!selected && !!this.state.residentsLaundry.find(l => l.selected === true && l.cleanType === cleanType)}
                            checked = {selected}
                            onChange = {(newValue) => this.handleAllCheckBoxesChanged(newValue.target.checked, cleanType)}
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h3">
                            {this.props.intl.formatMessage({id: "residentsLaundry.table.title." + tableTitle})}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" className={"category pt-1 pb-1"}>
                    <Grid style={{width: "50px"}}/>
                    <Grid item xs>
                        <Typography variant="h4">
                            {this.props.intl.formatMessage({id: "residentsLaundry.article.name"})}
                        </Typography>
                    </Grid>
                    <Grid style={{width: "20px"}}/>
                    <Grid item xs>
                        <Typography variant="h4">
                            {this.props.intl.formatMessage({id: "residentsLaundry.article.amount"})}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }

    render() {
        const chemicalArticles = [];
        for (let c of this.state.residentsLaundry.sort((a, b) => ("" + a.name).localeCompare(b.name)).filter(l => l.cleanType === RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL)) {
            chemicalArticles.push(<LaundryRow
                readOnly={this.props.readOnly}
                key={c.id}
                text={c.amount} 
                checked={c.selected} 
                id={c.id}
                articleName={c.name}
                checkboxCallback={(id, checked) => this.handleCheckBoxChanged(id, checked)} 
                textCallback={(id, text) => this.handleTextFieldsChanged(id, text)}/>);
        }
        const washedArticles = [];
        for (let w of this.state.residentsLaundry.sort((a, b) => ("" + a.name).localeCompare(b.name)).filter(l => l.cleanType === RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH)) {
            washedArticles.push(<LaundryRow
                readOnly={this.props.readOnly}
                key={w.id}
                text={w.amount} 
                checked={w.selected} 
                id={w.id}
                articleName={w.name}
                checkboxCallback={(id, checked) => this.handleCheckBoxChanged(id, checked)} 
                textCallback={(id, text) => this.handleTextFieldsChanged(id, text)}/>);
        }
        return <div>

            <Button 
                color="primary" 
                variant="contained" 
                style={{marginRight: "17%", marginBottom: 30}} 
                disabled={!this.context.hasUnsavedChanges() || this.props.readOnly}
                onClick={() => this.save()}>
                <FormattedMessage id="commons.save.button"/>
            </Button>

            <Grid container spacing={3}>
                <Grid style={{minWidth: "350px"}} item xs>
                    <Card className="child">
                        <CardContent>
                            {this.renderTitleRow(RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH)}
                            {washedArticles}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid style={{minWidth: "350px"}} item xs>
                    <Card className="child">
                        <CardContent>
                            {this.renderTitleRow(RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL)}
                            {chemicalArticles}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    }
}

export default injectIntl(withSnackbar(ResidentsLaundry));

export class LaundryRow extends Component {

    static contextType = GeneralContext;

    shouldComponentUpdate(nextProps) {
        return nextProps.checked !== this.props.checked || nextProps.text !== this.props.text;
    }

    handleAmountChanged = (text) => {
        this.context.setUnsavedChanges(true);

        const amountRegex = /^[0-9]*$/;
        if (amountRegex.test(text)) {
            this.props.textCallback(this.props.id, text);
        }
    }

    onFocus = (text) => {
        if (text === "0") {
            this.props.textCallback(this.props.id, "");
        }
    }

    onBlur = (text) => {
        if (text === "") {
            this.props.textCallback(this.props.id, "0");
        }
    }

    handleCheckBoxChanged = (newValue) => {
        this.context.setUnsavedChanges(true);

        if (!newValue) {
            this.props.textCallback(this.props.id, "0");
        }
        this.props.checkboxCallback(this.props.id, newValue);
    }

    render() {
        return <div key={this.props.id}>
            <Grid container alignItems="center" style={{paddingBottom: "10px", paddingTop: "10px"}}>
                <Grid style={{width: "50px"}}>
                    <Checkbox
                        color="primary"
                        checked = {this.props.checked}
                        onChange = {e => this.handleCheckBoxChanged(e.target.checked)}
                        inputProps={{'aria-label': 'primary checkbox'}}
                        disabled={this.props.readOnly}
                    />
                </Grid>
                <Grid item xs>
                    {this.props.articleName}
                </Grid>
                <Grid style={{width: "20px"}}/>
                <Grid item xs>
                    <TextField
                        value = {this.props.text}
                        onChange = {e => this.handleAmountChanged(e.target.value)}
                        onFocus = {e => this.onFocus(e.target.value)}
                        onBlur = {e => this.onBlur(e.target.value)}
                        disabled = {this.props.checked !== true || this.props.readOnly}
                    />
                </Grid>
            </Grid>
            <Divider/>
        </div>
    }
}
