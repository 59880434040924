import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from 'notistack';
import "../../css/Procedure.css";
import { LinearProgressIndicator } from "../uiLibrary/LinearProgressIndicator";
import { ProcedureDocumentsSelection } from "./ProcedureDocumentsSelection";
import { DeadlinesSelection } from "./DeadlinesSelection";
import RatingCriteriaSelection from "./RatingCriteriaSelection";
import SuitabilityCriteriaSelection from "./SuitabilityCriteriaSelection";
import {GeneralContext} from "../contexts/GeneralContext";
import {Typography} from "@material-ui/core";
import ValidationHeader from "../validation/ValidationHeader";
import {PROJECT_TASKS} from "../../util/Constants";
import {getProcedure, updateProcedure} from "../../services/ProcedureService";
import {ProcedureDocumentTypeSelection} from "./ProcedureDocumentTypeSelection";
import {isComponentReadOnly} from "../uiLibrary/ReadOnlyWrapper";


class ProcedureConditions extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            procedure: null,
            isLoading: false,
            hasChanges: false
        };
    }

    async componentDidMount() {
        this.setState({procedure: await getProcedure(this.context, this.props)});
    }

    componentDidUpdate() {
        this.context.setUnsavedChanges(this.state.hasChanges);
    }

    saveSuitabilityCriteria = async(suitabilityCriteria) => {
        const procedure = this.state.procedure;
        procedure.suitabilityCriteria = suitabilityCriteria;
        if (procedure.ratingCriteria) {
            // necessary because server expects an array, but when loading the procedure the client gets a map
            procedure.ratingCriteria = Object.values(procedure.ratingCriteria);
        }
        await this.saveProcedure(procedure);
    }

    saveRatingCriteria = async(ratingCriteria) => {
        const procedure = this.state.procedure;
        // converting to array not necessary because it is already done in the RatingCriteriaSelection component
        procedure.ratingCriteria = ratingCriteria;
        await this.saveProcedure(procedure);
    }

    saveProcedure = async(procedure) => {
        if (procedure.ratingCriteria) {
            // necessary because server expects an array, but when loading the procedure the client gets a map
            procedure.ratingCriteria = Object.values(procedure.ratingCriteria);
        }
        const updatedProcedure = await updateProcedure(this.context, this.props, procedure);
        this.setState({procedure: updatedProcedure, hasChanges: !!updatedProcedure});
        if (updatedProcedure) {
            this.context.setUnsavedChanges(false);
            this.context.setProcedureVersion(updatedProcedure.version);
        }
    }

    readOnly = () => {
        return isComponentReadOnly(this.context, PROJECT_TASKS.PROCEDURE, this.context.appData.activeProjectId);
    }

    render() {

        return <>

            <LinearProgressIndicator active={this.state.isLoading}/>

            <ValidationHeader
                title={this.props.intl.formatMessage({id: "procedure.conditions.title"})}
                projectTask={PROJECT_TASKS.PROCEDURE}
                objectId={this.state.procedure?.projectId}
                projectId={this.state.procedure?.projectId}/>

            <Typography className="headerSubtitle">
                <FormattedMessage id="procedure.conditions.subtitle"/>
            </Typography>

            <ProcedureDocumentTypeSelection
                context={this.context}
                props={this.props}
            />

            <ProcedureDocumentsSelection
                documents={this.state.procedure?.documents}
                context={this.context}
                props={this.props}
                reloadProcedure={async() => this.setState({procedure: await getProcedure(this.context, this.props)})}
                projectId={this.context.appData.activeProjectId}
                onUpload={(isLoading) => this.setState({isLoading: isLoading})}/>

            <DeadlinesSelection
                currentProcedure={this.state.procedure}
                disabled={this.readOnly()}
                onChange={(hasChanges) => this.setState({hasChanges})}
                onSave={(procedure) => this.saveProcedure(procedure)}
                context={this.context}/>

            <SuitabilityCriteriaSelection
                currentSuitabilityCriteria={this.state.procedure?.suitabilityCriteria}
                onChange={(hasChanges) => this.setState({hasChanges})}
                onSave={(suitabilityCriteria) => this.saveSuitabilityCriteria(suitabilityCriteria)}/>

            <RatingCriteriaSelection
                currentRatingCriteria={this.state.procedure?.ratingCriteria}
                onChange={(hasChanges) => this.setState({hasChanges})}
                onSave={(ratingCriteria) => this.saveRatingCriteria(ratingCriteria)}
                projectId={this.context.appData.activeProjectId}/>

        </>;
    }
}

export default injectIntl(withSnackbar(ProcedureConditions));
