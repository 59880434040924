import {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {MAX_BUSINESS_UNIT_COUNT, MAX_USER_COUNT, TEXISION_VERTRIEB_MAIL_ADDRESS} from "../../util/Constants";
import {Grid} from "@material-ui/core";

export const MAJOR_CUSTOMER_INFORMATION_TYPE = {
    USER: "USER",
    UNIT: "UNIT",
    BOTH: "BOTH"
}

class MajorCustomerInformation extends Component {

    render() {
        let textId;
        if (this.props.type === MAJOR_CUSTOMER_INFORMATION_TYPE.USER) {
            textId = "payment.majorCustomerInformation.userCount.text";
        } else if (this.props.type === MAJOR_CUSTOMER_INFORMATION_TYPE.UNIT) {
            textId = "payment.majorCustomerInformation.unitCount.text";
        } else {
            textId = "payment.majorCustomerInformation.text";
        }
        if (this.props.vertical) {
            return (
                <Grid container>
                    <Grid item xs={12} style={{display: "flex", justifyContent: "center", textAlign: "center", marginBottom: 20}}>
                        <FormattedMessage id={textId} values={{maxUnitCount: MAX_BUSINESS_UNIT_COUNT, maxUserCount: MAX_USER_COUNT}}/>
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                        <a href={"mailto:" + TEXISION_VERTRIEB_MAIL_ADDRESS} style={{marginLeft: 10}}>
                            <FormattedMessage id="payment.majorCustomerInformation.link"/>
                        </a>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <div style={{display: "flex", justifyContent: "center"}}>

                    <FormattedMessage id={textId} values={{maxUnitCount: MAX_BUSINESS_UNIT_COUNT, maxUserCount: MAX_USER_COUNT}}/>

                    <a href={"mailto:" + TEXISION_VERTRIEB_MAIL_ADDRESS} style={{marginLeft: 10}}>
                        <FormattedMessage id="payment.majorCustomerInformation.link"/>
                    </a>

                </div>
            );
        }
    }
}

export default injectIntl(MajorCustomerInformation);
