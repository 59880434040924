import {getAsyncCatch, putAsyncCatch, uploadBlobCatch} from "./BackendService";
import {updateValidationMap} from "./ProjectService";

export async function getProcedure(context, props, projectId) {
    const currentProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    const procedure = await getAsyncCatch(context, "/procedure/" + currentProjectId, props);
    return procedure ? procedure : null;
}

export async function updateProcedure(context, props, procedure) {
    let errorTextMap = new Map();
    errorTextMap.set(404, "procedureService.updateProcedure.404");
    const updatedProcedure = await putAsyncCatch(context, "/procedure/" + context.appData.activeProjectId, procedure, props, false, errorTextMap);
    if (updatedProcedure) {
        await updateValidationMap(context);
    }
    return updatedProcedure ? updatedProcedure : null;
}

export async function uploadProcedureDocument(context, props, projectId, document) {
    return await uploadBlobCatch(context, "/document/procedure/" + projectId + "/", document, null, props);
}
