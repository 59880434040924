import {getAsyncCatch, postAsyncCatch} from "./BackendService";


export async function loadArticleConfigurations(context, props, profileId) {
    let url = "/article/configuration";
    if (profileId) {
        url += `?profileId=${encodeURIComponent(profileId)}`;
    }
    return await getAsyncCatch(context, url, props);
}

export async function createOrGetArticle(context, props, articleConfiguration, filterOptionList) {
    let filterOptions = filterOptionList ?? getFirstFilterOptions(articleConfiguration.filterCategories);
    const article = {
        id: null,
        articleConfigurationId: articleConfiguration.id,
        category: articleConfiguration.category,
        subcategory: articleConfiguration.subCategory,
        articleCategory: articleConfiguration.articleCategory,
        description: articleConfiguration.description,
        filterOptions: filterOptions,
        images: articleConfiguration.images
    }
    return await postAsyncCatch(context, "/article", article, props, true);
}

export function getFirstFilterOptions(filterCategories) {
    let optionsList = [];
    for (let filterCategory of filterCategories) {
        if (filterCategory.filterOptions.length) {
            optionsList.push(filterCategory.filterOptions[0]);
        }
    }
    return optionsList;
}
