import { TextField } from "@material-ui/core";
import { Component } from "react";
import { injectIntl } from "react-intl";
import { GeneralContext } from "../../contexts/GeneralContext";

class AlternateDescriptionCell extends Component {

    static contextType = GeneralContext;

    handleDescriptionChange = (newDescription) => {
        let priceItem = this.props.priceItem;
        priceItem.alternateDescription = newDescription;
        this.props.handleItemChange(priceItem);
        this.context.setUnsavedChanges(true);
        this.setState({});
    }

    render() {
        return (
            <TextField
                value={this.props.priceItem?.alternateDescription}
                disabled={this.props.disabled}
                onChange={(e) => this.handleDescriptionChange(e.target.value)}
            />
        );
    }
}

export default injectIntl(AlternateDescriptionCell);
