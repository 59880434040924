import { TableCell, TableRow } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PriceType } from "../../../util/Constants";
import { offerDisabledTooltip } from "../../../util/ProcedureUtil";
import {formatPrice, isTender} from "../../../util/Util";
import ArticleButtonCell from "../cells/ArticleButtonCell";
import ArticleNumberCell from "../cells/ArticleNumberCell";
import ArticlePriceCell from "../cells/ArticlePriceCell";
import { PriceSheetTable } from "./PriceSheetTable";
import AlternateDescriptionCell from "../cells/AlternateDescriptionCell";

const PriceSheetResidentsLaundryTable = ({
    readOnly,
    singlePriceOnly,
    allArticleNumbers, 
    editable,
    exportType,
    lockArticleNumbers,
    lockPrices,
    priceGroup, 
    project,
    resetAllArticleNumbers,
    residentsLaundry, 
    show, 
    updatePriceSheet,
    // following prop is only used for bidders
    offer,
    isPrevious
}) => {

    const [laundryMap, setLaundryMap] = useState(priceGroup?.priceItemMap ? JSON.parse(JSON.stringify(priceGroup.priceItemMap)) : null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (priceGroup?.priceItemMap) {
            setLaundryMap(JSON.parse(JSON.stringify(priceGroup.priceItemMap)));
        }
    }, [priceGroup])

    const intl = useIntl();

    if (!show) {
        return (<div/>);
    }

    const handleItemChange = (newLaundryItem) => {
        let map = laundryMap;
        map[newLaundryItem.objectId] = newLaundryItem;
        setLaundryMap({...map});
    }

    const resetItemMap = () => {
        setLaundryMap(JSON.parse(JSON.stringify(priceGroup.priceItemMap)));
        resetAllArticleNumbers();
    }

    const disabled = !isEditing || (!editable && isTender() && offerDisabledTooltip(project, offer, isPrevious, intl.formatMessage));

    let headerTitles = [
        "residentsLaundry.pricesheet.table.cleanType",
        "residentsLaundry.pricesheet.table.name"
    ];

    if (!singlePriceOnly) {
        headerTitles.push("residentsLaundry.pricesheet.table.amount");
    }

    return (
        <PriceSheetTable
            title={intl.formatMessage({id: "bidder.priceSheet.table.residentsLaundry.title"})
                + " - " + intl.formatMessage({id: "constants.ArticleOffsetting.DELIVERED_QUANTITY"})}
            type="residentsLaundry"
            headerTitles={headerTitles}
            editButtonLabel={lockArticleNumbers && lockPrices ? "bidder.priceSheet.editAlternateLabels.button" : null}
            rows={residentsLaundry.sort((a,b) => a.name.localeCompare(b.name)).map(laundry => {

                const priceItem = laundryMap[laundry.id];

                return (
                    <TableRow key={laundry.id}>

                        <TableCell>{intl.formatMessage({id: "residentsLaundry.table.title." + laundry.cleanType.toLowerCase()})}</TableCell>
                        
                        <TableCell>{laundry.name}</TableCell>

                        {!singlePriceOnly && <TableCell>{laundry.amount}</TableCell>}

                        <TableCell>
                            <ArticlePriceCell 
                                disabled={disabled || lockPrices}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                        {!singlePriceOnly && <TableCell>{isEditing ? "" : formatPrice(priceItem?.sumNet)}</TableCell>}

                        {!singlePriceOnly && <TableCell>{isEditing ? "" : formatPrice(priceItem?.sumGross)}</TableCell>}

                        <TableCell>
                            <AlternateDescriptionCell
                                disabled={disabled}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={(newLaundryItem) => handleItemChange(newLaundryItem)}/>
                        </TableCell>

                        <TableCell>
                            <ArticleNumberCell
                                allArticleNumbers={allArticleNumbers}  
                                disabled={disabled || lockArticleNumbers}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                        <TableCell>
                            <ArticleButtonCell 
                                allArticleNumbers={allArticleNumbers} 
                                disabled={disabled || lockArticleNumbers}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                    </TableRow>
                );
            })}
            isEditing={isEditing}
            readOnly={readOnly}
            singlePriceOnly={singlePriceOnly}
            offer={offer}
            project={project}
            priceGroup={priceGroup}
            onSave={() => updatePriceSheet(PriceType.RESIDENTS_LAUNDRY, laundryMap)}
            resetItemMap={() => resetItemMap()}
            setIsEditing={(value) => setIsEditing(value)}
            editable={editable}
        />
    );
}

export {
    PriceSheetResidentsLaundryTable
}
