import React, {Component} from 'react';
import {Typography, Button, Divider, Tooltip} from '@material-ui/core';
import {injectIntl, FormattedMessage} from 'react-intl';
import Grid from "@material-ui/core/Grid";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {withSnackbar} from 'notistack';

import {isAdmin, isProjectAdmin} from '../../services/UserService';

import '../../css/ProjectDashboard.css';
import {
    BIDDER_PROJECT_DETAILS_ROUTE,
    buildRouteWithPathVariable, MAX_BUSINESS_UNIT_COUNT,
    PROJECT_STATUS_IS_PUBLISHED,
    PROJECT_TASKS
} from '../../util/Constants';
import {createSuccessMessage} from '../../util/Util';
import {GeneralContext} from "../contexts/GeneralContext";


class ProjectListItem extends Component {

    static contextType = GeneralContext;

    isCompleted = () => {
        const hasValidProject = this.context.appData.validationMap?.get(PROJECT_TASKS.PROJECT)?.get(this.props.project.id)?.complete;
        return hasValidProject || this.props.project.status === PROJECT_STATUS_IS_PUBLISHED;
    }

    render() {

        const { allProfiles, operation, project } = this.props;
        const profileIds = operation?.profileIds;

        let profileNames = "";
        if (allProfiles && profileIds) {
            allProfiles.filter(p => profileIds.includes(p.id)).sort((a, b) => a.name.localeCompare(b.name)).forEach(profile => {
                profileNames = profileNames.concat(profile.name + ", ");
            });
            if (profileNames.length > 1) {
                profileNames = profileNames.substring(0, profileNames.length - 2);
            }
        }

        return (
            <div className="projectContent">

                <Grid container wrap="nowrap" className="projectContentGrid">

                    <Grid item className="projectContentDataTitle">
                        {project.name}
                    </Grid>

                    <Grid item xs>
                        <Grid container className="projectContentDataRow">
                            <Grid item>
                                <div className="projectContentDataItemTitle">
                                    <FormattedMessage id="project.item.profiles"/>
                                </div>
                                <div className="proectContentDataItemText">
                                    <Typography variant="h4">
                                        {profileNames}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="projectContentDataItemTitle">
                                    <FormattedMessage id="project.item.createdBy"/>
                                </div>
                                <div className="proectContentDataItemText">
                                    <Typography variant="h4">
                                        {project.createdBy}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="projectContentDataItemTitle">
                                    <FormattedMessage id="project.item.creationDate"/>
                                </div>
                                <div className="proectContentDataItemText">
                                    <Typography variant="h4">
                                        {this.props.intl.formatDate(project.createdAt)}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="projectContentDataItemTitle">
                                    <FormattedMessage id="project.item.maxBusinessUnits"/>
                                </div>
                                <div className="proectContentDataItemText">
                                    <Typography variant="h4">
                                        {MAX_BUSINESS_UNIT_COUNT}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="projectContentDataItemTitle">
                                    <FormattedMessage id="project.item.currentPhase"/>
                                </div>
                                <div className="proectContentDataItemText">
                                    <Typography variant="h4">
                                        {this.props.intl.formatMessage({id: "project.status." + project.status + ".title"})}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Divider/>

                <div className="projectItemActions">

                    {this.isCompleted() && <CopyToClipboard
                        onCopy={() => createSuccessMessage(this.props.intl.formatMessage({id: "project.copyLink.success"}), this.props)} 
                        text={window.location.origin+buildRouteWithPathVariable(BIDDER_PROJECT_DETAILS_ROUTE, operation.id)}>
                            <Tooltip title={this.props.intl.formatMessage({id: "project.copyLink.tooltip"})}>
                                <Button variant="text" color="primary">
                                    {this.props.intl.formatMessage({id: "project.copyLink.button"})}
                                </Button>
                            </Tooltip>
                    </CopyToClipboard>}

                    {(isAdmin(this.context.currentUser) || isProjectAdmin(this.context.currentUser)) && 
                    <Button color="primary" variant="text" onClick={() => this.props.handleEdit(this.props.project)}>
                        <FormattedMessage id="commons.edit.button"/>
                    </Button>}

                    {(isAdmin(this.context.currentUser) || isProjectAdmin(this.context.currentUser)) &&
                    <Button color="primary" variant="text" onClick={() => this.props.handleDelete(this.props.project)}>
                        <FormattedMessage id="project.delete.button.text"/>
                    </Button>}

                </div>

            </div>
        );
    }
}

export default injectIntl(withSnackbar(ProjectListItem));
