import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Select, Grid, Typography} from '@material-ui/core'
import {GeneralContext} from "../../contexts/GeneralContext";

class OffsettingEntry extends Component {

    static contextType = GeneralContext;
    
    render() {
        return (
            <React.Fragment>
                <Grid item xs={5} className="pt-4" style={{wordBreak: "break-all"}}>
                    <Typography variant="h5">
                        {this.props.name}
                    </Typography>
                </Grid>
                <Grid item xs={7} className="pt-4">
                    <Select 
                        native 
                        disableUnderline 
                        style={{width: "40%"}}
                        value={this.props.currentOffsetting}
                        onChange={(e) => this.props.handleChanged(this.props.id, e.target.value)}
                        disabled={this.props.readOnly}>
                        {
                            this.props.offsettings.map(offsetting => {
                                return <option key={offsetting} value={offsetting}>
                                    {this.props.intl.formatMessage({id: "constants.ResourceOffsetting." + offsetting})}
                                </option>
                            })
                        }
                    </Select>
                </Grid>
            </React.Fragment>
        );
    }
}

export default injectIntl(OffsettingEntry);
