import { FormattedMessage } from "react-intl";
import ProcedureAccordion from "./ProcedureAccordion";
import React, { useEffect, useState } from 'react';
import {areDeadlinesTooTight, canSaveDeadlines, lastYear, performSuggestions} from "../../util/DateUtil";
import { DIALOG_TYPE_INFO, DIALOG_TYPE_WARNING, FEDERAL_STATES, PROJECT_STATUS_IN_EDITING, PROJECT_STATUS_IN_PROGRESS } from "../../util/Constants";
import { Grid, Select } from "@material-ui/core";
import DeadlineSelection from "./DeadlineSelection";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import "../../css/Procedure.css";
import {getActiveProject} from "../../services/ProjectService";

const DeadlinesSelection = ({onSave, currentProcedure, onChange, context, hasGlobalChanges, disabled}) => {
    const [procedure, setProcedure] = useState(currentProcedure);
    const [showTightDialog, setShowTightDialog] = useState(false);
    const [bufferedBindingDeadline, setBufferedBindingDeadline] = useState(null);
    const [showSuggestionsDialog, setShowSuggestionsDialog] = useState(false);
    const [showInvalidStart, setShowInvalidStart] = useState(false);
    const [hasChanges, setHasChanges] = useState(hasGlobalChanges);

    useEffect(() => {
        setProcedure(currentProcedure);
    }, [currentProcedure]);

    useEffect(() => {
        setHasChanges(hasGlobalChanges);
    }, [hasGlobalChanges]);

    if (!procedure) {
        return <div/>;
    }

    const onStateChange = (hasChanges) => {
        setHasChanges(hasChanges);
        onChange(hasChanges);
    }

    const setBindingDeadline = (newDate) => {
        if (areDeadlinesTooTight(newDate, procedure.startOfServiceDate)) {
            setShowTightDialog(true);
            setBufferedBindingDeadline(newDate);
        } else {
            setProcedure((prevProcedure) => ({...prevProcedure, bindingDeadline: newDate}));
            onStateChange(true);
        }
    }

    const handleFederalStateChange = (newFederalState) => {
        setProcedure((prevProcedure) => ({...prevProcedure, federalState: newFederalState}));
        onStateChange(true);
    }

    const conditions = () => {
        const {firstCallForSubmissionDate, visitDate, questionsDeadline, responseDeadline, firstOfferDeadline, conceptEvaluationDate, 
            negotiationDate, bindingDeadline, startOfServiceDate} = procedure;
        return [
            {name: "firstCallForSubmissionDate", date: firstCallForSubmissionDate,  minDate: lastYear(), dividerTextId: "procedure.conditions.offerRound",
            onChange: (newDate) => {
                setProcedure((prevProcedure) => ({...prevProcedure, firstCallForSubmissionDate: newDate}));
                onStateChange(true);
                setShowSuggestionsDialog(true);
            }},
            {name: "visitDate", date: visitDate, minDate: firstCallForSubmissionDate,
            onChange: (newDate) => {
                setProcedure((prevProcedure) => ({...prevProcedure, visitDate: newDate}));
                onStateChange(true);
            }},
            {name: "questionsDeadline", date: questionsDeadline, minDate: visitDate,
            onChange: (newDate) => {
                setProcedure((prevProcedure) => ({...prevProcedure, questionsDeadline: newDate}));
                onStateChange(true);
            }},
            {name: "responseDeadline", date: responseDeadline, minDate: questionsDeadline,
            onChange: (newDate) => {
                setProcedure((prevProcedure) => ({...prevProcedure, responseDeadline: newDate}));
                onStateChange(true);
            }},
            {name: "firstOfferDeadline", date: firstOfferDeadline, minDate: responseDeadline,
            onChange: (newDate) => {
                setProcedure((prevProcedure) => ({...prevProcedure, firstOfferDeadline: newDate}));
                onStateChange(true);
            }},
            {name: "conceptEvaluationDate", date: conceptEvaluationDate, minDate: firstOfferDeadline,
            onChange: (newDate) => {
                setProcedure((prevProcedure) => ({...prevProcedure, conceptEvaluationDate: newDate}));
                onStateChange(true);
            }},
            {name: "negotiationDate", date: negotiationDate, minDate: conceptEvaluationDate,
            onChange: (newDate) => {
                setProcedure((prevProcedure) => ({...prevProcedure, negotiationDate: newDate}));
                onStateChange(true);
            },},
            {name: "bindingDeadline", date: bindingDeadline, minDate: negotiationDate, isTemporarily: !procedure?.isRecalled, dividerTextId: "procedure.conditions.validityAndContractStart",
            onChange: (newDate) => {
                setBindingDeadline(newDate)}},
            {name: "startOfServiceDate", date: startOfServiceDate, onChange: () => {}, isStartTime: true, minDate: bindingDeadline},
        ];
    }

    const activeProject = getActiveProject(context);

    const inProgress = activeProject?.status === PROJECT_STATUS_IN_PROGRESS;
    let subtitleId;
    if (inProgress) {
        subtitleId = "procedure.conditions.dates.subtitle.inProgress";
    } else if (procedure?.isRecalled) {
        subtitleId = "procedure.conditions.dates.subtitle.recalled";
    } else {
        subtitleId = "procedure.conditions.dates.subtitle.published";
    }

    return (<React.Fragment>

        {/* If user selects a publishing date ask if the user wants to use our suggestions for all other dates*/}
        <TexisionDialog
            type={DIALOG_TYPE_INFO}
            open={showSuggestionsDialog}
            titleId="procedure.conditions.suggestions.title"
            subtitleId="procedure.conditions.suggestions.subtitle"
            cancelId="commons.no.button"
            actionId="commons.yes.button"
            onCancel={() => setShowSuggestionsDialog(false)}
            onAction={() => performSuggestions(procedure, 
                (newProcedure) => {
                    setProcedure(newProcedure); 
                    setShowSuggestionsDialog(false);
                }, 
                () => setShowInvalidStart(true), 
                (bindingDeadline) => setBindingDeadline(bindingDeadline))}/>

        {/* If user selects an offer deadline that is less than 90 days before the start time
            ask if the user is sure to continue*/}
        <TexisionDialog
            type={DIALOG_TYPE_WARNING}
            open={showTightDialog}
            titleId="procedure.conditions.tooTight.title"
            subtitleId="procedure.conditions.tooTight.subtitle"
            cancelId="commons.no.button"
            actionId="commons.yes.button"
            onCancel={() => {
                setShowTightDialog(false);
                setBufferedBindingDeadline(null);
            }}
            onAction={() => {
                setProcedure((prevProcedure) => ({...prevProcedure, bindingDeadline: bufferedBindingDeadline}));
                setShowTightDialog(false);
                setBufferedBindingDeadline(null);
                onStateChange(true);
            }}/>

        {/* After performing our suggestions, if start time becomes invalid, show a warning*/}
        <TexisionDialog
            type={DIALOG_TYPE_WARNING}
            open={showInvalidStart}
            titleId="procedure.conditions.suggestions.invalidStartTime.title"
            subtitleId="procedure.conditions.suggestions.invalidStartTime.subtitle"
            actionId="commons.okay.button"
            onAction={() => setShowInvalidStart(false)}/>

        <ProcedureAccordion
            mandatory={true}
            complete={canSaveDeadlines(currentProcedure)}
            titleId="procedure.conditions.dates.title"
            subtitle={<FormattedMessage id={subtitleId}/>}
            saveButtonId={procedure?.isRecalled || ![PROJECT_STATUS_IN_PROGRESS, PROJECT_STATUS_IN_EDITING].includes(activeProject?.status)
                ? "" : "procedure.dates.save.button"}
            onSave={() => {
                onSave(procedure);
                onStateChange(false);
            }}
            readOnly={disabled}
            disableSaveButton={!hasChanges || !canSaveDeadlines(procedure) || disabled}
            tooltipId={canSaveDeadlines(procedure) ? "" : "procedure.dates.save.tooltip"}>
            <Grid key={'federal-state-select'} className="procedureDatePickerGrid" container spacing={2}>
                <Grid item xs>
                    <FormattedMessage id="procedure.federal.state.description"/>
                </Grid>
                <Grid item>
                    <Select 
                        className="procedureComboBox"
                        native
                        disabled={!inProgress || disabled}
                        value={procedure.federalState ?? FEDERAL_STATES.UNDEFINED}
                        onChange={(e) => handleFederalStateChange(e.target.value)}
                        style={{width: "100%"}}>
                        {[...(new Map(Object.entries(FEDERAL_STATES)))].map(([key, value]) => {
                            return <option key={key} value={key}>{value}</option>
                        })}
                    </Select>
                </Grid>
            </Grid>
            {conditions().map((condition) => <DeadlineSelection 
                key={condition.name}
                disabled={disabled}
                condition={condition} 
                procedure={procedure} 
                context={context}/>)}
        </ProcedureAccordion>
    </React.Fragment>);
}

export {
    DeadlinesSelection
}
