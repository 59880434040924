import { Button, Grid, Tooltip } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";

const InvoiceStatisticsSelectButton = ({
    hasNoContractItems,
    hasNoPeriod,
    onClick
}) => {

    const intl = useIntl();

    return (
        <Grid item xs>
            <Tooltip title={hasNoContractItems 
                ? intl.formatMessage({id: "cockpit.statistics.noItems"}) 
                : (
                    hasNoPeriod
                    ? intl.formatMessage({id: "cockpit.statistics.noPeriod"})
                    : ""
                )}>
                <div>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={onClick}
                        disabled={hasNoContractItems || hasNoPeriod}
                        style={{whiteSpace: "nowrap"}}>
                        <FormattedMessage id="cockpit.statistics.addItem.button"/>
                    </Button>
                </div>
            </Tooltip>
        </Grid>
    );
}

export {
    InvoiceStatisticsSelectButton
}
