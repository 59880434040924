import {downloadUsingGet, getAsyncCatch} from "./BackendService";
import fileDownload from "js-file-download";
import {DE, LICENSE_TYPES} from "../util/Constants";
import {createErrorMessage, isCockpit, isTender} from "../util/Util";

export async function exportBillOfQuantities(context, projectId, exportType, onExportLocked) {
    const response = await downloadUsingGet('/billofquantities/' + projectId + '/' + exportType);
    if (response?.status === 200) {
        const fileName = response.headers['content-disposition'].split("filename=")[1];
        fileDownload(response.data, fileName);
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    } else if (response?.status === 402) {
        context.showNoLicenseDialog(isTender() ? LICENSE_TYPES.TENDER_LICENSE : LICENSE_TYPES.COOPERATION_LICENSE);
    } else if (response?.status === 423) {
        onExportLocked();
    }
}

export function exportTenderPriceSheet(context, props, offerId) {
    void exportPriceSheet(context, props, '/pricesheet/offer/' + offerId + '/' + DE)
}

export function exportCockpitPriceSheet(context, props, projectId) {
    void exportPriceSheet(context, props, '/pricesheet/cockpit/' + projectId + '/' + DE)
}

export async function exportPriceSheet(context, props, path) {
    const response = await downloadUsingGet(path);
    if (response?.status === 200) {
        const fileName = response.headers['content-disposition'].split("filename=")[1];
        fileDownload(response.data, fileName);
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    } else if (response?.status === 402) {
        context.showNoLicenseDialog(isCockpit()
            ? LICENSE_TYPES.COOPERATION_LICENSE : LICENSE_TYPES.TENDER_LICENSE);
    } else {
        createErrorMessage(props.intl.formatMessage({id: "bidder.priceSheet.export.errorMessage"}), props);
    }
}

export async function getProjectDocumentZip(context, props, projectId) {
    const path = '/project/export/' + projectId + "/" + DE;
    return await getAsyncCatch(context, path, props);
}
