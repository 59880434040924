import {getAsyncCatch} from "./BackendService";

export async function loadVersions(context, props) {
    const releaseVersionsVo = await getAsyncCatch(context, "/versions", props);
    return releaseVersionsVo?.versions ? releaseVersionsVo.versions : [];
}

export async function loadVersionMarkdownById(context, props, versionId) {
    const releaseVersionVo = await getAsyncCatch(context, "/versions/" + versionId, props);
    return releaseVersionVo?.releaseNotes ? releaseVersionVo.releaseNotes : null;
}
