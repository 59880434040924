import {deleteAsyncCatch, getAsyncCatch, postAsyncCatch, putAsyncCatch} from "./BackendService";
import {updateValidationMap} from "./ProjectService";

export async function getUnitAreas(context, props) {
    const unitAreas = await getAsyncCatch(context, "/unit/areas/" + context.appData.activeUnitId, props);
    return unitAreas ? unitAreas : [];
}

export async function getAreaById(context, props, areaId) {
    const area = await getAsyncCatch(context, "/area/" + areaId, props);
    return area ? area : null;
}

export async function createArea(context, props, area, activeProjectId, hideSuccess) {
    const projectId = activeProjectId ? activeProjectId : context.appData.activeProjectId;
    const createdArea = await postAsyncCatch(context, "/area/" + projectId, area, props, hideSuccess);
    return createdArea ? createdArea : null;
}

export async function updateArea(context, props, area, activeProjectId) {
    let projectId = activeProjectId ? activeProjectId : context.appData.activeProjectId;
    const updatedArea = await putAsyncCatch(context, "/area/" + projectId, area, props);
    if (updatedArea) {
        await updateValidationMap(context);
    }
    return updatedArea ? updatedArea : null;
}

export async function deleteArea(context, props, areaId) {
    const success = await deleteAsyncCatch(context, "/area/" + context.appData.activeProjectId + "/" + areaId, props);
    if (success) {
        await updateValidationMap(context);
    }
    return success;
}
