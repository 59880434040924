import { Component } from "react";
import { Redirect } from "react-router-dom";
import { ROOT_ROUTE } from "../../../util/Constants";
import { GeneralContext } from "../../contexts/GeneralContext";

class PrivateRoute extends Component {

    static contextType = GeneralContext;

    render() {
        if ((this.props.roles && this.props.roles.includes(this.context.currentUser.role)) || !this.props.unavailable) {
            const PrivateComponent = this.props.component;
            return <PrivateComponent/>;
        } else {
            this.context.showRouteUnavailableDialog();
            return (
                <Redirect to={ROOT_ROUTE}/>
            );
        }
    }
}

export default PrivateRoute;
