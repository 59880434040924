import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {GeneralContext} from "../../contexts/GeneralContext";
import ContractHistoryItem from "./ContractHistoryItem";
import History from "../../project/History";

class ContractHistory extends Component {

    static contextType = GeneralContext;

    render() {
        return (
            <History
                operationId={this.context.appData.activeOperationId} 
                historyItem={(opDiff) => <ContractHistoryItem key={opDiff.effectiveFrom} operationDifference={opDiff}/>} 
                titleId="contract.history.title" 
                subtitleId="contract.history.subtitle"/>
        );
    }
}

export default injectIntl(withSnackbar(ContractHistory));
