import {Grid, Switch} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { putAsyncCatch } from "../../services/BackendService";
import { EXPORT_TYPE_CONCATENATE, EXPORT_TYPE_MERGE } from "../../util/Constants";
import ProcedureAccordion from "./ProcedureAccordion";
import "../../css/Procedure.css";
import {getActiveProject} from "../../services/ProjectService";

const ProcedureDocumentTypeSelection = ({context, props}) => {
    return <ProcedureAccordion
        mandatory={false}
        complete={true}
        titleId="procedure.export.type.title"
        subtitle={<React.Fragment>
            <FormattedMessage id="procedure.export.type.subtitle"/><br/>
            <FormattedMessage id="procedure.export.type.question"/>
        </React.Fragment>}>
        <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
                <Grid className="procedureDocumentGrid" container alignItems="center">
                    <FormattedMessage id="export.switch.title"/>
                    <Switch
                        checked={getActiveProject(context)?.exportType === EXPORT_TYPE_MERGE}
                        onChange={async (e) => {
                            const project = getActiveProject(context);
                            project.exportType = e.target.checked ? EXPORT_TYPE_MERGE : EXPORT_TYPE_CONCATENATE;
                            await putAsyncCatch(context, '/project/' + context.appData.activeProjectId, project, props);
                            await context.reloadTenderData();
                            await context.setActiveProjectId(project.id);
                        }}
                        name="exportSwitch"
                        inputProps={{'aria-label': 'secondary checkbox'}}/>
                </Grid>
            </Grid>
        </Grid>

    </ProcedureAccordion>
}

export {
    ProcedureDocumentTypeSelection
}
