import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {GeneralContext} from "../../contexts/GeneralContext";
import {Button, Card, CardContent, Grid, Typography} from "@material-ui/core";
import {FILE_TYPES, selectDocument} from "../../../util/DocumentUtil";
import {getAsyncCatch, uploadBlobCatch} from "../../../services/BackendService";
import {DocumentPreview} from "../../uiLibrary/DocumentPreview";
import {withSnackbar} from "notistack";
import {isOfferUser} from "../../../services/UserService";
import {getActiveOperation} from "../../../services/OperationService";

class CockpitContractDocuments extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            isUploading: false
        }
    }

    async componentDidMount() {
        await this.loadDocuments();
    }

    loadDocuments = async() => {
        const operationId = getActiveOperation(this.context)?.id;
        const documents = await getAsyncCatch(this.context,
            "/document/project/" + this.context.appData.activeProjectId + "/operation/" + operationId, this.props);
        this.setState({documents: documents ? documents : []});
    }

    render() {
        const operation = getActiveOperation(this.context);
        const canUploadNewDocuments = !isOfferUser(operation?.bidder);
        const disabled = !operation?.editable || !canUploadNewDocuments;
        return (
          <>
              <Typography variant="h1">
                  <FormattedMessage id="navBar.cockpitContract.title"/>
              </Typography>

              <Typography variant="subtitle1">
                  <FormattedMessage id={canUploadNewDocuments && !disabled ? "cockpit.documents.subtitle" : "cockpit.documents.hasBidder.subtitle"}/>
              </Typography>

              <Card>
                  <CardContent>

                      {!!this.state.documents?.length && <Grid className="offerDocumentsGrid" container spacing={2}>
                          {this.state.documents.map((document) =>
                              <DocumentPreview
                                  key={"cockpit-contract-document" + document.filename}
                                  document={document}
                                  disabled={disabled}
                                  deleteEndpoint="/document/project/"
                                  context={this.context}
                                  props={this.props}
                                  callback={() => this.loadDocuments()}/>)}
                      </Grid>}

                      {canUploadNewDocuments && <Button
                          color="primary"
                          component="label">
                          <FormattedMessage id="offers.document.select.button"/>
                          <input
                              type="file"
                              id="offer-document-input"
                              accept={FILE_TYPES.PDF}
                              style={{display: "none"}}
                              onChange={(e) => selectDocument(e.target.files[0], async(document) => {
                                  this.setState({isUploading: true});
                                  await uploadBlobCatch(this.context, "/document/project/" + this.context.appData.activeProjectId
                                      + "/", document, null, this.props);
                                  await this.loadDocuments();
                                  this.setState({isUploading: false});
                              }, this.props, this.state.documents)}
                              onClick={(event)=> {event.target.value = null}}/>
                      </Button>}
                  </CardContent>
              </Card>
          </>
        );
    }
}

export default withSnackbar(injectIntl(CockpitContractDocuments));
