import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {withSnackbar} from 'notistack';

import '../../../css/NavBar.css';
import {GeneralContext} from "../../contexts/GeneralContext";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Operation from './Operation';
import {
    CONTRACT_HISTORY_ROUTE,
    COCKPIT_INVOICES_ROUTE,
    PREFLIGHT_CHECK_ROUTE,
    PREDICTED_STATISTICS_ROUTE,
    DASHBOARD_ROUTE,
    PROJECT_OVERVIEW_ROUTE,
    INVOICE_STATISTICS_ROUTE, BASE_DATA_ROUTE, LICENSE_TYPES
} from '../../../util/Constants';
import NavBarItem from '../shared/NavBarItem';
import {Collapse, List, Tooltip} from '@material-ui/core';
import NavBar from '../shared/NavBar';
import {isOfferUser, Role} from "../../../services/UserService";
import {getActiveOperation} from "../../../services/OperationService";
import {getContract} from "../../../services/ContractService";
import {getPaymentsAndEvents, latestActivePayment} from "../../../services/PaymentService";

class CockpitNavBar extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showPreflight: false,
            statisticsOpen: false
        };
    }

    async componentDidMount() {
        const activeOperation = getActiveOperation(this.context);
        if (!isOfferUser(activeOperation?.bidder)) {
            return;
        }
        const contract = await getContract(this.context, this.props, activeOperation?.activeProject?.id);
        if (contract) {
            this.setState({showPreflight: contract.startOfServiceDate > new Date().getTime()});
        }
    }

    setActiveOperation = async(operationId) => {
        await getPaymentsAndEvents(this.context, operationId);
        await this.context.setActiveOperationIdForCockpit(operationId);
    }

    sideMenuItems = () => {

        const activeOperation = getActiveOperation(this.context);
        const allOperations = this.context.appData.operations;
        const hasOperations = allOperations && allOperations.length > 0;
        const isBidder = isOfferUser(this.context.currentUser);

        let tooltip = "";
        if (!hasOperations && isBidder) {
            tooltip = this.props.intl.formatMessage({id: "cockpit.select.empty.bidder.tooltip"});
        } else if (!hasOperations && !isBidder) {
            tooltip = this.props.intl.formatMessage({id: "cockpit.select.empty.tooltip"});
        }

        return (
            <>

                <div style={{margin: 15, marginTop: 48}}>
                    <Tooltip title={tooltip}>
                        <FormControl fullWidth>
                            <InputLabel 
                                id="operation"
                                disabled={!hasOperations}>
                                {this.props.intl.formatMessage({id: hasOperations ? "navBar.cockpit.select.placeholder" : "navBar.cockpit.select.empty"})}
                            </InputLabel>
                            <Select 
                                labelId="operation" 
                                label={this.props.intl.formatMessage({id: hasOperations ? "navBar.cockpit.select.placeholder" : "navBar.cockpit.select.empty"})}
                                value={activeOperation?.activeProject?.name ?? ""}
                                disabled={!hasOperations}>
                                {allOperations?.sort((a,b) => a.activeProject.name.localeCompare(b.activeProject.name))?.map(o =>
                                    <MenuItem
                                        key={o.id} 
                                        value={o.activeProject?.name}
                                        onClick={() => this.setActiveOperation(o.id)}>
                                        {o.activeProject?.name}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Tooltip>
                </div>

                <NavBarItem
                    alwaysVisible={!isBidder}
                    level={0}
                    pathname={DASHBOARD_ROUTE}
                    icon="icon-cog"
                />

                {this.state.showPreflight && <NavBarItem
                    level={0}
                    pathname={PREFLIGHT_CHECK_ROUTE}
                    icon="icon-clock"
                />}

                <Operation operation={activeOperation}/>

                <NavBarItem
                    level={0}
                    title="navBar.invoices.title"
                    pathname={COCKPIT_INVOICES_ROUTE}
                    roles={[Role.ADMIN, Role.PROJECT_ADMIN, Role.USER]}
                    icon="icon-note-euro"/>

                <NavBarItem
                    level={0}
                    open={this.state.statisticsOpen}
                    showHint={latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE) && this.context.missingBaseData}
                    onClick={() => this.setState({statisticsOpen: !this.state.statisticsOpen})}
                    roles={[Role.ADMIN, Role.PROJECT_ADMIN, Role.USER]}
                    title="navBar.statistics.title"
                    icon="icon-note-hook"/>

                <Collapse in={this.state.statisticsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        <NavBarItem
                            level={1}
                            pathname={BASE_DATA_ROUTE}
                            showHint={latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE) && this.context.missingBaseData}
                            roles={[Role.ADMIN, Role.PROJECT_ADMIN, Role.USER]}
                            icon="icon-documents"/>

                        <NavBarItem
                            level={1}
                            pathname={PREDICTED_STATISTICS_ROUTE}
                            roles={[Role.ADMIN, Role.PROJECT_ADMIN, Role.USER]}
                            icon="icon-export"/>

                        <NavBarItem
                            level={1}
                            pathname={INVOICE_STATISTICS_ROUTE}
                            roles={[Role.ADMIN, Role.PROJECT_ADMIN, Role.USER]}
                            icon="icon-note-hook"/>

                    </List>
                </Collapse>

                <NavBarItem
                    level={0}
                    pathname={CONTRACT_HISTORY_ROUTE}
                    icon="icon-documents"/>

                <NavBarItem
                    alwaysVisible={!isBidder}
                    level={0}
                    pathname={PROJECT_OVERVIEW_ROUTE}
                    roles={[Role.ADMIN, Role.PROJECT_ADMIN]}
                    icon="icon-cog"/>

            </>
        );
    }

    render() {
        return (
            <NavBar mobile={this.props.mobile} sideMenuItems={this.sideMenuItems()}/>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(CockpitNavBar)));
