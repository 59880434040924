import React, {Component} from 'react';
import {Button, Grid, TextField} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {getAsyncCatch, postAsyncCatch} from "../../../../services/BackendService";
import {createSuccessMessage} from "../../../../util/Util";
import {withSnackbar} from "notistack";
import {DIALOG_TYPE_INFO} from "../../../../util/Constants";
import {CircularProgress, InputAdornment} from "@mui/material";
import {texisionBlue} from "../../../../util/ColorTheme";
import Checkbox from "@material-ui/core/Checkbox";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {getActiveOperation} from "../../../../services/OperationService";

class InviteBidderButton extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            selectedBidder: null,
            searchTerm: "",
            isSearching: false,
            bidderList: [],
            isInviting: false
        }
    }

    inviteBidder = async(selectedBidder) => {
        this.setState({isInviting: true});
        const operationId = getActiveOperation(this.context)?.id;
        const response = await postAsyncCatch(this.context, "/user/" + selectedBidder.id + "/operation/" + operationId + "/invite",
            null, this.props, true);
        this.setState({isInviting: false});
        if (response) {
            createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.invite.success"}, {company: selectedBidder.company}), this.props);
            this.setState({showDialog: false, selectedBidder: null, bidderList: [], searchTerm: ""});
        }
    }

    searchBidders = async(searchTerm) => {
        const response = await getAsyncCatch(this.context, "/find/bidder?searchTerm=" + searchTerm, this.props);
        if (response) {
            this.setState({bidderList: response});
        }
    }

    handleSearchTermChanged = async(newTerm) => {
        this.setState({searchTerm: newTerm});
        if (newTerm && newTerm.length >= 3) {
            this.setState({isSearching: true});
            await this.searchBidders(newTerm);
            this.setState({isSearching: false});
        }
    }

    render() {
        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showDialog}
                    titleId="cockpit.contractStatus.invite.dialog.title"
                    subtitleId="cockpit.contractStatus.invite.dialog.subtitle"
                    cancelId="commons.cancel.button"
                    actionId="cockpit.contractStatus.invite.button"
                    onCancel={() => this.setState({showDialog: false, selectedBidder: null, searchTerm: ""})}
                    onAction={() => this.inviteBidder(this.state.selectedBidder)}
                    actionDisabled={!this.state.selectedBidder || this.state.isInviting}
                    content={<>

                        <TextField
                            label={this.props.intl.formatMessage({id: "cockpit.contractStatus.invite.textField.label"})}
                            id="bidder-invite-text-field"
                            inputProps={{maxLength: 32}}
                            style={{minWidth: 400, marginBottom: 30}}
                            variant="outlined"
                            InputProps={{endAdornment: this.state.isSearching ? <InputAdornment position="end">
                                    <CircularProgress size={20} style={{color: texisionBlue}}/>
                                </InputAdornment> : null}}
                            value={this.state.searchTerm ?? ""}
                            onChange={event => this.handleSearchTermChanged(event.target.value)}/>

                        {this.state.bidderList?.map(userCompany => <Grid id={"user-company-" + userCompany.id} container alignItems="center">
                            <Grid item>
                                <Checkbox
                                    color="primary"
                                    checked={this.state.selectedBidder?.id === userCompany.id}
                                    onChange={() => this.setState({selectedBidder: this.state.selectedBidder?.id === userCompany.id ? null : userCompany})}
                                />
                            </Grid>
                            <Grid item xs>
                                {userCompany.company}
                            </Grid>
                        </Grid>)}

                        <div>
                            {!!this.state.searchTerm && this.state.searchTerm.length >= 3 && !this.state.bidderList?.length && !this.state.isSearching
                                && <FormattedMessage id="cockpit.contractStatus.invite.noResults"/>}
                        </div>

                    </>}/>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({showDialog: true})}>
                        <FormattedMessage id="cockpit.contractStatus.invite.button"/>
                    </Button>
            </>
        );
    }
}

export default injectIntl(withSnackbar(InviteBidderButton));
