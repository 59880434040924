import { LAST_PROJECT_ID } from "../util/Constants";
import { getAsync } from './BackendService';
import { loadProject, loadAllProjects, loadDeliveryAddresses, loadContractItems, storeProject, storeDeliveryAddresses, storeContractItems, clearProjectData } from './Database';

export async function loadOrderDataOnline(context, operations, activeOperationId) {
    let activeProjectId;
    let activeUnitId;
    let deliveryAddressIdToRentalLinenMap = new Map();
    const unitIdToDeliveryAddressesMap = new Map();
    const allProjects = operations.map(o => o.activeProject);

    // store active project and its business units
    const activeOperation = operations.find(o => o.id === activeOperationId);
    const activeProject = activeOperation?.activeProject;
    activeProjectId = activeProject.id;
    localStorage.setItem(LAST_PROJECT_ID, activeProjectId);
    await clearProjectData(activeProjectId);
    await storeProject(activeProject);

    // load and store delivery addresses
    const units = activeProject?.businessUnits?.units;
    if (units && units.length > 0) {
        activeUnitId = units[0].id;
        for (let unit of units) {
            const deliveryAddressResponse = await getAsync("/unit/" + unit.id + "/addresses");
            if (deliveryAddressResponse?.status === 200) {
                unitIdToDeliveryAddressesMap.set(unit.id, deliveryAddressResponse.data);
                await storeDeliveryAddresses(activeProjectId, unitIdToDeliveryAddressesMap.get(unit.id));
            }
        }
    }

    // load and store contract items
    const itemsResponse = await getAsync("/priceoffer/project/" + activeProjectId + "/address/");
    if (itemsResponse?.status === 200) {
        deliveryAddressIdToRentalLinenMap = new Map(Object.entries(itemsResponse.data));
        await storeContractItems(activeProjectId, deliveryAddressIdToRentalLinenMap);
    }

    let orderData = {
        operations,
        allProjects,
        unitIdToDeliveryAddressesMap,
        deliveryAddressIdToRentalLinenMap,
        activeProjectId,
        activeUnitId
    };
    context.setOrderData(orderData);
}

export async function loadOrderDataOffline(context) {
    let activeProjectId;
    let activeUnitId;
    let allProjects = await loadAllProjects();
    let deliveryAddressIdToRentalLinenMap = new Map();
    const unitIdToDeliveryAddressesMap = new Map();

    const lastProjectId = parseInt(localStorage.getItem(LAST_PROJECT_ID));
    let project = await loadProject(lastProjectId);
    if (project) {
        allProjects = [...allProjects.filter(p => p.id !== project.id), project];
        activeProjectId = project.id;
        const units = project.businessUnits?.units;
        if (units && units.length > 0) {
            activeUnitId = units[0].id;
            for (let unit of units) {
                const addresses = await loadDeliveryAddresses(unit.id);
                if (addresses && addresses.length > 0) {
                    unitIdToDeliveryAddressesMap.set(unit.id, addresses);
                }
            }
        }
        deliveryAddressIdToRentalLinenMap = await loadContractItems(lastProjectId);
    }

    let orderData = {
        allProjects,
        unitIdToDeliveryAddressesMap,
        deliveryAddressIdToRentalLinenMap,
        activeProjectId,
        activeUnitId
    };
    context.setOrderData(orderData);
}
