import React, {Component} from "react";
import {
    DIALOG_TYPE_INFO,
    EVENT_TYPES,
    LICENSE_TYPES,
    PAYMENT_MANAGEMENT_ROUTE
} from "../../../util/Constants";
import {Button} from "@material-ui/core";
import {isAdmin, isOfferUser} from "../../../services/UserService";
import {Grid, Stack} from "@mui/material";
import TenderActionButtons from "../tender/TenderActionButtons";
import {GeneralContext} from "../../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import IconButton from "@material-ui/core/IconButton";
import {headerColor} from "../../../util/ColorTheme";
import ArticleIcon from "@mui/icons-material/Article";
import Popover from "@material-ui/core/Popover";
import PopoverItem from "./PopoverItem";
import {getActiveOperation} from "../../../services/OperationService";
import {getAllSucceededPayments, latestActivePayment} from "../../../services/PaymentService";
import {isTender} from "../../../util/Util";
import ProjectCreateOrCopyDialog from "../../project/ProjectCreateOrCopyDialog";

class ActionButtons extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
            showNoAnalysisBookedDialog: false,
            showFreeAnalysisDialog: false,
            showNewOperationDialog: false
        }
    }

    onBookLicenseClicked = (hasAnalysisBooked, isTenderApp) => {
        if (!isTenderApp || hasAnalysisBooked || getAllSucceededPayments(this.context)?.length) {
            this.props.history.push(PAYMENT_MANAGEMENT_ROUTE);
        } else {
            this.setState({showNoAnalysisBookedDialog: true});
        }
    }

    onFreeAnalysisDialogClose = () => {
        this.setState({showFreeAnalysisDialog: false});
    }

    onFreeAnalysisDialogConfirm = () => {
        this.setState({showFreeAnalysisDialog: false});
        this.props.showCalendly(EVENT_TYPES.ANALYSIS);
    }

    onNewOperationClicked = () => {
        this.setState({showNewOperationDialog: true});
    }

    onNewOperationDialogClose = () => {
        this.setState({showNewOperationDialog: false});
    }

    onNoAnalysisDialogClose = () => {
        this.setState({showNoAnalysisBookedDialog: false});
        this.props.history.push(PAYMENT_MANAGEMENT_ROUTE);
    }

    onNoAnalysisDialogConfirm = () => {
        this.setState({showNoAnalysisBookedDialog: false});
        this.props.showCalendly(EVENT_TYPES.ANALYSIS);
    }

    getPopupItems = (hasAnalysisBooked, showFreeAnalysisButton, showBookButton, showNewOperationButton, isTenderApp) => {
        const items = [
            {title: "navBar.freeAnalysis.button", onClick: () => this.setState({showFreeAnalysisDialog: true}), show: showFreeAnalysisButton},
            {title: "navBar.bookLicense.button", onClick: () => this.onBookLicenseClicked(hasAnalysisBooked, isTenderApp), show: showBookButton},
            {title: "navBar.newOperation.button", onClick: () => this.setState({showNewOperationDialog: true}), show: showNewOperationButton},
        ];
        return items.map(item => item.show
            ? <PopoverItem
                key={item.title}
                title={item.title}
                onClick={item.onClick}
                handleClose={this.handleClose}/>
            : null
        );
    }

    handleClose = () => {
        this.setState({open: !this.state.open, anchorEl: null});
    }

    render() {
        const operation = getActiveOperation(this.context);
        const hasAnalysisBooked = this.context.operationEvents?.hasAnalysisBooked;
        const hasTenderLicense = !!latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE);
        const hasCooperationLicense = !!latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE);

        const tenderActionButtons = isTender()
            ? <TenderActionButtons mobile={this.props.mobile} projectId={this.context.appData.activeProjectId}/>
            : null;

        const showFreeAnalysisButton = !!operation && !hasAnalysisBooked && isTender() && !hasTenderLicense
            && !isAdmin(this.context.currentUser);
        const showBookButton = ((isTender() && !hasTenderLicense) || (!isTender() && !hasCooperationLicense))
            && !!operation && !isOfferUser(this.context.currentUser) && !isAdmin(this.context.currentUser);
        const showNewOperationButton = !!operation && !isTender() && !isOfferUser(this.context.currentUser);

        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showFreeAnalysisDialog}
                    titleId={"navBar.freeAnalysis.dialog.title"}
                    subtitleId={"navBar.freeAnalysis.dialog.subtitle"}
                    cancelId={"commons.close.label"}
                    actionId={"calendly.book.button"}
                    onCancel={this.onFreeAnalysisDialogClose}
                    onAction={this.onFreeAnalysisDialogConfirm}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showNoAnalysisBookedDialog}
                    titleId="navBar.noAnalysisBooked.dialog.title"
                    subtitleId="navBar.noAnalysisBooked.dialog.subtitle"
                    actionId="navBar.openFreeAnalysis.button"
                    cancelId="navBar.profile.showSubscription"
                    onAction={this.onNoAnalysisDialogConfirm}
                    onCancel={this.onNoAnalysisDialogClose}/>

                {showNewOperationButton && <ProjectCreateOrCopyDialog
                    open={this.state.showNewOperationDialog}
                    operation={operation}
                    closeDialog={() => this.onNewOperationDialogClose()}
                />}

                {this.props.mobile

                    ? <>

                        <IconButton
                            style={{color: headerColor}}
                            aria-haspopup="true"
                            color="inherit"
                            onClick={(event) => this.setState({open: !this.state.open, anchorEl: event.currentTarget})}>
                            <ArticleIcon/>
                        </IconButton>

                        <Popover
                            open={this.state.open}
                            style={{marginTop: 18}}
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            PaperProps={{style: {width: 270}}}
                            transformOrigin={{vertical: 'top', horizontal: 'center'}}
                            onClose={this.handleClose}>

                            <Stack component="div">

                                {this.getPopupItems(hasAnalysisBooked, showFreeAnalysisButton, showBookButton, showNewOperationButton, isTender())}
                                {tenderActionButtons}

                            </Stack>

                        </Popover>

                    </>

                    : <Grid container alignItems="center" spacing={2} wrap="nowrap">
                        {showFreeAnalysisButton && <Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.setState({showFreeAnalysisDialog: true})}
                                style={{whiteSpace: "nowrap", marginLeft: 15, marginTop: 5, borderRadius: 5, fontSize: 12}}>
                                {this.props.intl.formatMessage({id: "navBar.freeAnalysis.button"})}
                            </Button>
                        </Grid>}

                        {showBookButton && <Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.onBookLicenseClicked(hasAnalysisBooked, isTender())}
                                style={{whiteSpace: "nowrap", marginLeft: 15, marginTop: 5, borderRadius: 5, fontSize: 12}}>
                                {this.props.intl.formatMessage({id: "navBar.bookLicense.button"})}
                            </Button>
                        </Grid>}

                        {showNewOperationButton && <Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.onNewOperationClicked()}
                                style={{whiteSpace: "nowrap", marginLeft: 15, marginTop: 5, borderRadius: 5, fontSize: 12}}>
                                {this.props.intl.formatMessage({id: "navBar.newOperation.button"})}
                            </Button>
                        </Grid>}

                        <Grid style={{marginTop: 5}}>{tenderActionButtons}</Grid>
                    </Grid>}
            </>
        );
    }
}

export default withRouter(injectIntl(ActionButtons));
