import React, {Component}  from 'react';
import '../../apps/App.css';
import '../../../css/UserAdmin.css';

import {getAsync, postAsyncCatch, putAsyncCatch} from '../../../services/BackendService';
import MaterialTable from 'material-table';

import {FormattedMessage, injectIntl} from 'react-intl';

import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import {bodyBackgroundColor, texisionFontColorDark} from '../../../util/ColorTheme';
import UserManagementCreateDialog from './UserManagementCreateDialog';
import UserManagementOperationAssignmentDialog from './UserManagementOperationAssignmentDialog';

import WorkIcon from '@material-ui/icons/Work';
import {isAdmin, isBidder, isBidderReadOnly, isOrderUser, isProjectAdmin, isUser, Role} from '../../../services/UserService';
import {renderProjectCount} from '../../../util/TableUtil';
import {withSnackbar} from 'notistack';

import DialogContentText from '@material-ui/core/DialogContentText';

import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {COUNTRIES, DIALOG_TYPE_INFO, LAST_OPERATION_ID, LICENSE_TYPES, MOBILE_THRESHOLD} from '../../../util/Constants';
import {GeneralContext} from "../../contexts/GeneralContext";
import MediaQuery from "react-responsive";
import { Divider, IconButton, Tooltip } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import TextsmsIcon from '@mui/icons-material/Textsms';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { withRouter } from 'react-router-dom';
import {SimpleTextCard} from "../../uiLibrary/SimpleTextCard";
import MajorCustomerInformation, {MAJOR_CUSTOMER_INFORMATION_TYPE} from "../../money/MajorCustomerInformation";
import {latestActivePayment} from "../../../services/PaymentService";

class UserManagement extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.state = {
            userList: [],
            showUserCreationDialog: false,
            showUserAssignmentDialog: false,
            showUserDeactivationDialog: false,
            showUserActivationDialog: false,
            showVerifyBidderDialog: false,
            showUnverifyBidderDialog: false,
            selectedUser: null,
            allOperations: []
        }
    }

    async componentDidMount() {
        const userList = await this.loadUserList();
        if (userList?.length > 0) {
            // open bidder verification dialog if userId is passed in url
            const requestParameters = new URLSearchParams(window.location.search);
            const userIdString = requestParameters.get("userId");
            if (userIdString) {
                const userId = Number(userIdString);
                const userToVerify = userList.find(user => user.id === userId);
                if (isBidderReadOnly(userToVerify)) {
                    this.setState({selectedUser: userToVerify, showVerifyBidderDialog: true, userList});
                    requestParameters.delete("userId");
                    this.props.history.replace({search: requestParameters.toString()});
                    return;
                }
            }
        }
        const allOperations = await this.loadOperations();
        this.setState({allOperations, userList});
    }
    
    loadUserList = async() => {
        let userListResponse;
        if (isAdmin(this.context.currentUser)) {
            userListResponse = await getAsync('/users');
        } else if (isProjectAdmin(this.context.currentUser)) {
            const operationId = localStorage.getItem(LAST_OPERATION_ID);
            if (operationId) {
                userListResponse = await getAsync('/users/' + operationId);
            }
        }
        if (userListResponse?.status === 200) {
            return userListResponse.data;
        } else if ([401, 403].includes(userListResponse?.status)) {
            await this.context.logout();
        }
    }

    loadOperations = async() => {
        const response = await getAsync('/operation');
        if (response?.status === 200) {
            return response.data;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    checkResponseAndReloadUserList = async(responseData) => {
        if (responseData) {
            const userList = await this.loadUserList();
            this.setState({userList: userList});
        }
    }

    createUser = async(title, email, firstName, lastName, company, role, jobTitle, 
        streetAndNumber, zipCode, city, phoneNumber, vatId, operationIds) => {
        
        let user = {
            username: email.toLowerCase(),
            title: title,
            email: email,
            firstName: firstName,
            lastName: lastName,
            company: company,
            role: role,
            jobTitle: jobTitle,
            country: COUNTRIES.DE,
            operationIds: operationIds,
            activated: true,
            password: ""
        }

        if (role !== Role.ORDERER) {
            user.streetAndNumber = streetAndNumber;
            user.zipCode = zipCode;
            user.city = city;
            user.phoneNumber = phoneNumber;
            user.vatId = vatId;
        }

        const errorTextMap = new Map();
        errorTextMap.set(406, "user.create.duplicate");
        errorTextMap.set(402, "user.create.paymentRequired");
        
        const responseData = await postAsyncCatch(this.context, '/user', user, this.props, false, errorTextMap);
        await this.checkResponseAndReloadUserList(responseData);
        return responseData;
    }

    deactivateUser = async(user) => {
        const responseData = await putAsyncCatch(this.context, "/user/deactivate/" + user.id + "/" + user.version, null, this.props);
        this.setState({showUserDeactivationDialog: false, selectedUser: null});
        await this.checkResponseAndReloadUserList(responseData);
    }

    activateUser = async(user) => {
        const responseData = await putAsyncCatch(this.context, "/user/activate/" + user.id + "/" + user.version, null, this.props);
        this.setState({showUserActivationDialog: false, selectedUser: null});
        await this.checkResponseAndReloadUserList(responseData);
    }

    unverifyBidder = async(user) => {
        const responseData = await postAsyncCatch(this.context, "/unverify/bidder/" + user.id, null, this.props);
        this.setState({showUnverifyBidderDialog: false, selectedUser: null});
        await this.checkResponseAndReloadUserList(responseData);
    }

    verifyBidder = async(user) => {
        const responseData = await postAsyncCatch(this.context, "/verify/bidder/" + user.id, null, this.props);
        this.setState({showVerifyBidderDialog: false, selectedUser: null});
        await this.checkResponseAndReloadUserList(responseData);
    }

    handleAssignOperations = async(operationIdSet) => {
        
        const selectedUser = this.state.selectedUser;
        let userOperationIds = new Set(selectedUser.operationIds);
        this.state.allOperations
            .map(o => o.id)
            .forEach(id => {
                if (operationIdSet.includes(id)) {
                    userOperationIds.add(id);
                } else {
                    userOperationIds.delete(id);
                }
            });

        let user = {
            id: selectedUser.id,
            title: selectedUser.title,
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            email: selectedUser.email,
            username: selectedUser.username,
            role: selectedUser.role,
            company: selectedUser.company,
            jobTitle: selectedUser.jobTitle,
            streetAndNumber: selectedUser.streetAndNumber,
            zipCode: selectedUser.zipCode,
            city: selectedUser.city,
            country: selectedUser.country,
            phoneNumber: selectedUser.phoneNumber,
            vatId: selectedUser.vatId,
            operationIds: [...userOperationIds],
            version: selectedUser.version
        }

        const errorTextMap = new Map();
        errorTextMap.set(402, "user.create.paymentRequired");
        errorTextMap.set(405, "user.update.payedFor");
        errorTextMap.set(503, "user.update.serviceError");

        const responseData = await putAsyncCatch(this.context, "/user", user, this.props, false, errorTextMap);
        await this.checkResponseAndReloadUserList(responseData);
    }

    renderFieldData = (user, field) => {
        if (field === "role") {
            return this.props.intl.formatMessage({id: "constants.UserRole." + user.role});
        } else if (field === "projectIds") {
            return renderProjectCount(user);
        } else if (field === "hasNewsletter") {
            return this.renderNewsletterStatus(user);
        } else {
            return user[field];
        }
    }

    renderNewsletterStatus = (user) => {
        if (user.hasNewsletter) {
            return <FormattedMessage id="commons.yes.button"/>;
        } else if (user.newsletterDisabledReason) {
            return (
                <Grid container spacing={2}>
                    <Grid item>
                        <FormattedMessage id="commons.no.button"/>
                    </Grid>
                    <Grid item>
                        <Tooltip title={this.props.intl.formatMessage({id: "useradmin.newsletter.reason"}) + ": " + user.newsletterDisabledReason}>
                            <TextsmsIcon style={{color: "gray"}}/>
                        </Tooltip>
                    </Grid>
                </Grid>
            );
        } else {
            return <FormattedMessage id="commons.no.button"/>;
        }
    }

    render() {

        const columns = [
            {title: this.props.intl.formatMessage({id: "useradmin.username"}) + "/" + this.props.intl.formatMessage({id: "useradmin.email"}), field: "username"},
            {title: this.props.intl.formatMessage({id: "useradmin.firstname"}), field: "firstName"},
            {title: this.props.intl.formatMessage({id: "useradmin.lastname"}), field: "lastName"},
            {title: this.props.intl.formatMessage({id: "useradmin.role"}), field: "role", render: (user) => this.props.intl.formatMessage({id: "constants.UserRole." + user.role})}, 
            {title: this.props.intl.formatMessage({id: "useradmin.projectCount"}), field: "projectIds", render: (user) => renderProjectCount(user)},
            {title: this.props.intl.formatMessage({id: "useradmin.hasNewsletter"}), field: "hasNewsletter", render: (user) => this.renderNewsletterStatus(user)}
        ];

        const users = this.state.userList?.sort((a,b) => a.username?.localeCompare(b.username));

        const hasPermission = isAdmin(this.context.currentUser) || latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE)
            || latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE);

        return (
            <>

                <UserManagementCreateDialog
                    showUserCreationDialog={this.state.showUserCreationDialog} 
                    hideUserCreationDialog={() => this.setState({showUserCreationDialog: false})} 
                    createUser={this.createUser}
                    allOperations={this.state.allOperations}/>

                <UserManagementOperationAssignmentDialog
                    key={this.state.selectedUser?.id}
                    showUserAssignmentDialog={this.state.showUserAssignmentDialog} 
                    hideOperationAssignmentDialog={() => this.setState({showUserAssignmentDialog: false, selectedUser: null})}
                    selectedUser={this.state.selectedUser}
                    assignedOperations={this.handleAssignOperations}
                    allOperations={this.state.allOperations}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showUserDeactivationDialog}
                    titleId="useradmin.deactivateUserDialog.title"
                    actionId="commons.yes.button"
                    cancelId="commons.no.button"
                    onCancel={() => this.setState({showUserDeactivationDialog: false, selectedUser: null})}
                    onAction={() => this.deactivateUser(this.state.selectedUser)}
                    content={<DialogContentText id="alert-dialog-description-2">
                        <Typography color="textPrimary">
                            <FormattedMessage id="useradmin.deactivateUserDialog.text" values={{username: this.state.selectedUser?.username}}/>
                        </Typography>
                    </DialogContentText>}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showUserActivationDialog}
                    titleId="useradmin.activateUserDialog.title"
                    actionId="commons.yes.button"
                    cancelId="commons.no.button"
                    onCancel={() => this.setState({showUserActivationDialog: false, selectedUser: null})}
                    onAction={() => this.activateUser(this.state.selectedUser)}
                    content={<DialogContentText id="alert-dialog-description-2">
                        <Typography color="textPrimary">
                            <FormattedMessage id="useradmin.activateUserDialog.text" values={{username: this.state.selectedUser?.username}}/>
                        </Typography>
                    </DialogContentText>}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showUnverifyBidderDialog}
                    titleId="useradmin.unverifyBidder"
                    actionId="commons.yes.button"
                    cancelId="commons.no.button"
                    onCancel={() => this.setState({showUnverifyBidderDialog: false, selectedUser: null})}
                    onAction={() => this.unverifyBidder(this.state.selectedUser)}
                    content={<DialogContentText id="alert-dialog-description-2">
                        <Typography color="textPrimary">
                            <FormattedMessage id="useradmin.unverifyBidder.subtitle" values={{username: this.state.selectedUser?.username}}/>
                        </Typography>
                    </DialogContentText>}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showVerifyBidderDialog}
                    titleId="useradmin.verifyBidder"
                    actionId="commons.yes.button"
                    cancelId="commons.no.button"
                    onCancel={() => this.setState({showVerifyBidderDialog: false, selectedUser: null})}
                    onAction={() => this.verifyBidder(this.state.selectedUser)}
                    content={<DialogContentText id="alert-dialog-description-2">
                        <Typography color="textPrimary">
                            <FormattedMessage id="useradmin.verifyBidder.subtitle" values={{username: this.state.selectedUser?.username}}/>
                        </Typography>
                    </DialogContentText>}/>

                <Typography variant="h1">
                    <FormattedMessage id="useradmin.h1"/>
                </Typography>

                <Typography variant="subtitle1" style={{marginBottom: "20px"}}>
                    <FormattedMessage id={hasPermission ? "useradmin.h2" : "userManagement.noLicense.subtitle"}/>
                </Typography>

                <SimpleTextCard show={!hasPermission} textId="userManagement.noLicense"/>

                {hasPermission && <Card className="child" style={{minWidth: 200}}>
                    <CardContent>

                        {!this.context.offline && <Grid container justifyContent="flex-end" alignItems="center">
                            <Grid item style={{whiteSpace: "nowrap"}}>
                                {
                                    (isAdmin(this.context.currentUser) || isProjectAdmin(this.context.currentUser)) && 
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            onClick={() => this.setState({showUserCreationDialog: true})}
                                            disabled={this.context.offline}>
                                            <FormattedMessage id="useradmin.createUser.button"/>
                                        </Button>
                                }            
                            </Grid>
                        </Grid>}

                        {this.context.offline && (!this.state.userList || this.state.userList.length === 0) && <Typography variant="subtitle2">
                            <FormattedMessage id="offline.userManagement.hint"/>
                        </Typography>}

                        <Grid container style={{marginBottom: 40}}>
                            <Grid item xs={12}>

                                <MediaQuery minWidth={MOBILE_THRESHOLD}>

                                    <MaterialTable
                                        title={this.props.intl.formatMessage({id: "useradmin.table.title"}, {count: this.state.userList?.length ?? 0})}
                                        columns={columns}
                                        data={users}
                                        options={{
                                            padding: "dense",
                                            grouping: false,
                                            filtering: false,
                                            search: false,
                                            paging: false,
                                            draggable: false,
                                            headerStyle: {
                                                fontWeight: 'bold',
                                                backgroundColor: bodyBackgroundColor,
                                                color: texisionFontColorDark
                                            },
                                            rowStyle: rowData => ({ color: rowData.activated ? '#000' : '#AAA' }),
                                            showTitle: true,
                                            actionsColumnIndex: -1
                                        }}
                                        actions={[
                                            rowData => (rowData.activated && rowData.username !== this.context.currentUser.username && isAdmin(this.context.currentUser) ? 
                                                {
                                                    tooltip: this.props.intl.formatMessage({id: "useradmin.deactivateUserDialog"}), 
                                                    icon: () => <LockOpenIcon color="primary"/>,
                                                    onClick: (_, rowData) => this.setState({showUserDeactivationDialog: true, selectedUser: rowData}),
                                                    disabled: false
                                                } : ""),
                                            rowData => (!rowData.activated && rowData.username !== this.context.currentUser.username && isAdmin(this.context.currentUser) ? 
                                                {
                                                    tooltip: this.props.intl.formatMessage({id: "useradmin.activateUserDialog"}), 
                                                    icon: () => <LockIcon color="error"/>,
                                                    onClick: (_, rowData) => this.setState({showUserActivationDialog: true, selectedUser: rowData}),
                                                    disabled: false
                                                } : ""),
                                            rowData => (rowData.role === Role.BIDDER_READ_ONLY && isAdmin(this.context.currentUser) ?
                                                {
                                                    tooltip: this.props.intl.formatMessage({id: "useradmin.verifyBidder"}), 
                                                    icon: () => <RemoveModeratorIcon color="error"/>,
                                                    onClick: (_, rowData) => this.setState({showVerifyBidderDialog: true, selectedUser: rowData}),
                                                    disabled: false
                                                } : ""),
                                            rowData => (rowData.role === Role.BIDDER && isAdmin(this.context.currentUser) ?
                                                {
                                                    tooltip: this.props.intl.formatMessage({id: "useradmin.unverifyBidder"}), 
                                                    icon: () => <VerifiedUserIcon color="primary"/>,
                                                    onClick: (_, rowData) => this.setState({showUnverifyBidderDialog: true, selectedUser: rowData}),
                                                    disabled: false
                                                } : ""),
                                            rowData => (rowData.activated && (isProjectAdmin(rowData) || isUser(rowData) || isAdmin(rowData) || isOrderUser(rowData)) ?
                                                {
                                                    tooltip: this.props.intl.formatMessage({id: "useradmin.assignProjects"}), 
                                                    icon: () => <WorkIcon/>,
                                                    onClick: (_, rowData) => this.setState({showUserAssignmentDialog: true, selectedUser: rowData}),
                                                    disabled: false
                                                } : ""),
                                        ]}
                                        localization={{
                                            body: {
                                                emptyDataSourceMessage: this.props.intl.formatMessage({id: "useradmin.noData"})  
                                            },
                                            header: {
                                                actions: this.props.intl.formatMessage({id: "useradmin.table.actionHeader"}) 
                                            }
                                        }}
                                        components={{
                                            Container: props => (<Paper {...props} key={this.props.type} elevation={0}/>)}
                                        }
                                    />

                                </MediaQuery>

                                <MediaQuery maxWidth={MOBILE_THRESHOLD-1}>

                                    <Grid container spacing={1} alignItems="center" style={{marginTop: 30, minWidth: 200}}>

                                        {users?.map(user => <React.Fragment key={user.id}>
                                        
                                            {columns.map(c => <Grid item xs={12} key={c.field}>
                                                <Grid container justifyContent="space-between">

                                                    <Grid item>
                                                        <Typography variant="h4">
                                                            {c.title}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item>
                                                        {this.renderFieldData(user, c.field)}
                                                    </Grid>

                                                </Grid>
                                            </Grid>)}

                                            {(user.activated && user.username !== this.context.currentUser.username && isAdmin(this.context.currentUser)) && 
                                                <Grid item>
                                                    <Tooltip title={this.props.intl.formatMessage({id: "useradmin.deactivateUserDialog"})}>
                                                        <IconButton onClick={() => this.setState({showUserDeactivationDialog: true, selectedUser: user})}>
                                                            <LockOpenIcon color="primary"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>}

                                            {(!user.activated && user.username !== this.context.currentUser.username && isAdmin(this.context.currentUser)) && 
                                                <Grid item>
                                                    <Tooltip title={this.props.intl.formatMessage({id: "useradmin.activateUserDialog"})}>
                                                        <IconButton onClick={() => this.setState({showUserActivationDialog: true, selectedUser: user})}>
                                                            <LockIcon color="error"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>}

                                            {isBidderReadOnly(user) && isAdmin(this.context.currentUser) && 
                                                <Grid item>
                                                    <Tooltip title={this.props.intl.formatMessage({id: "useradmin.verifyBidder"})}>
                                                        <IconButton onClick={() => this.setState({showVerifyBidderDialog: true, selectedUser: user})}>
                                                            <RemoveModeratorIcon color="error"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            }

                                            {isBidder(user) && isAdmin(this.context.currentUser) && 
                                                <Grid item>
                                                    <Tooltip title={this.props.intl.formatMessage({id: "useradmin.unverifyBidder"})}>
                                                        <IconButton onClick={() => this.setState({showUnverifyBidderDialog: true, selectedUser: user})}>
                                                            <VerifiedUserIcon color="primary"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            }

                                            {user.activated && (isProjectAdmin(user) || isUser(user) || isAdmin(user) || isOrderUser(user)) && 
                                                <Grid item>
                                                    <Tooltip title={this.props.intl.formatMessage({id: "useradmin.assignProjects"})}>
                                                        <IconButton onClick={() => this.setState({showUserAssignmentDialog: true, selectedUser: user})}>
                                                            <WorkIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>}

                                            <Grid item xs={12}>
                                                <Divider style={{marginTop: 12, marginBottom: 12}}/>
                                            </Grid>
                                            
                                        </React.Fragment>)}
                                
                                    </Grid>

                                </MediaQuery>

                            </Grid>
                        </Grid>

                        <MajorCustomerInformation type={MAJOR_CUSTOMER_INFORMATION_TYPE.USER}/>

                    </CardContent>
                </Card>}
                
            </>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(UserManagement)));
