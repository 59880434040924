import React, { Component } from "react";
import  { injectIntl } from "react-intl";
import { getAsync, putAsync } from "../../../services/BackendService";

import { withSnackbar } from 'notistack';
import {createErrorMessage, createSuccessMessage, isTender, sortAsc} from "../../../util/Util";
import { downloadS3Document } from "../../../util/DocumentUtil";
import {
    PROJECT_STATUS_IS_PUBLISHED
} from "../../../util/Constants";

import "../../../css/OffersOverview.css";
import "../../../css/Procedure.css";
import "../../../css/ProjectDashboard.css";
import { LinearProgressIndicator } from "../../uiLibrary/LinearProgressIndicator";
import { Header } from "../../uiLibrary/Header";
import { OfferPreviews } from "./OfferPreviews";
import { SimpleTextCard } from "../../uiLibrary/SimpleTextCard";
import { OfferSummaryDocuments } from "./OfferSummaryDocuments";
import { OfferSummaryCompany } from "./OfferSummaryCompany";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { OfferSuitabilityChecking } from "./OfferSuitabilityChecking";
import { checkSuitabilityStatus, isFirstDeadlineOver, isSubmitted } from "../../../util/ProcedureUtil";
import { texisionGreen, warningColor } from "../../../util/ColorTheme";
import { OfferSummaryPrice } from "./OfferSummaryPrice";
import {GeneralContext} from "../../contexts/GeneralContext";
import {getProcedure} from "../../../services/ProcedureService";
import {getOfferProject} from "../../../services/ProjectService";
import {loadOffers} from "../../../services/OfferService";


class OfferSuitabilityOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            offers: [],
            suitabilities: null,
            priceOffers: [],
            procedure: null,
            participationCount: 0,
            isDownloading: false,
            isInitializing: true,
        }
    }

    async componentDidMount() {
        await this.reloadData();
    }

    reloadData = async() => {
        const procedure = await getProcedure(this.context, this.props, this.context.appData.offerProjectId);
        let offers = await loadOffers(this.context);
        const participationCount = offers?.length;
        if (offers && offers.length > 0) {
            const suitabilities = await this.loadSuitabilities();
            const priceOffers = await this.loadPriceOffers(offers?.map(offer => offer.id));
            offers = offers.filter((o) => isSubmitted(o));
            offers.sort((a, b) => sortAsc(a.user.company, b.user.company));
            this.setState({offers, priceOffers, suitabilities, procedure, participationCount});
        }
        this.setState({isInitializing: false});
    }

    loadPriceOffers = async(offerIds) => {
        if (!offerIds || offerIds.length === 0) {
            return [];
        }
        let offerIdsPathVariable = "";
        for (let i = 0; i < offerIds.length; i++) {
            offerIdsPathVariable += offerIds[i];
            if (i < offerIds.length - 1) {
                offerIdsPathVariable += ",";
            }
        }
        const response = await getAsync("/priceoffer/list/" + offerIdsPathVariable);
        if (response?.status === 200) {
            return response.data?.priceOfferVos;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    loadSuitabilities = async() => {
        let suitabilities = [];
        const response1 = await getAsync("/offer/suitabilities/" + this.context.appData.offerProjectId);
        if (response1?.status === 200) {
            suitabilities = response1.data;
        } else if ([401, 403].includes(response1?.status)) {
            await this.context.logout();
        }
        return suitabilities;
    }

    saveSuitabilities = async() => {
        const response = await putAsync("/offer/suitabilities/" + this.context.appData.offerProjectId, this.state.suitabilities);
        if (response?.status === 200) {
            createSuccessMessage(this.props.intl.formatMessage({id: "offerSuitability.save.success"}), this.props);
            const suitabilities = await this.loadSuitabilities();
            this.setState({suitabilities});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "offerSuitability.save.error"}), this.props);
        }
    }

    download = async(document) => {
        this.setState({isDownloading: true});
        await downloadS3Document(this.props, document)
        this.setState({isDownloading: false});
    }

    suitabilityFlag = (offerId) => {
        const isSuitable = checkSuitabilityStatus(this.state.suitabilities?.offerEvaluationMap, offerId, this.state.procedure?.suitabilityCriteria);
        const textId = isSuitable ? "offerSuitability.flag.suitable" : "offerSuitability.flag.notSuitable";
        const textColor = isSuitable ? texisionGreen : warningColor;
        return <Typography 
            variant="subtitle2" 
            style={{color: textColor, marginTop: 5, fontWeight: "bold"}}>
            {this.props.intl.formatMessage({id: textId})}
        </Typography>;
    }

    onSuitabilityChecked = (offerId, checked, criterion) => {
        const suitabilities = this.state.suitabilities;
        const offerEvaluationMap = suitabilities.offerEvaluationMap;
        if (!offerEvaluationMap[offerId]) {
            const criteriaMap = new Map();
            criteriaMap.set(criterion, {offerId: offerId, suitabilityCriterion: criterion, value: checked});
            offerEvaluationMap.set(offerId, criteriaMap);
        } else if (!offerEvaluationMap[offerId][criterion]) {
            offerEvaluationMap[offerId].set(criterion, {offerId: offerId, suitabilityCriterion: criterion, value: checked});
        } else {
            offerEvaluationMap[offerId][criterion].value = checked;
        }
        this.setState({suitabilities});
    }

    render() {

        if (isTender()) {
            const project = getOfferProject(this.context);
            const isPublished = project?.status === PROJECT_STATUS_IS_PUBLISHED;
            if (!isPublished) {
                return (
                    <>
                        <Header titleId="offerSuitability.title"/>
                        <SimpleTextCard show={true} textId="offerSuitability.projectNotPublished"/>
                    </>
                );
            }
        }
        if (this.state.isInitializing) {
            return <div/>;
        }

        const {procedure} = this.state;
        const isClosed = isFirstDeadlineOver(procedure);
        let offerIndex = 1;

        // at this point only submitted offers are in the list because all others are removed in the reloadData method
        let offers = this.state.offers ?? [];
        let participationCount = this.state.participationCount;
        const hasOffers = offers.length > 0;

        return <>

            <LinearProgressIndicator active={this.state.isDownloading}/>

            <Header titleId="offerSuitability.title" subtitleId={isClosed ? "offerSuitability.subtitle" : "offerSuitability.subtitle2"}/>

            {!isClosed && <OfferPreviews
                participationCount={participationCount} 
                submittedOffersCount={offers?.length}/>}

            <SimpleTextCard show={!hasOffers && isClosed} textId="offers.overview.noOffers"/>

            {hasOffers && isClosed && offers.map(offer => {
                const priceOffer = this.state.priceOffers?.find(p => p.offerId === offer.id);
                return (
                    <Card key={offer.id} className="mb-5">

                        <CardContent>

                            <Grid container spacing={4}>

                                <Grid item>
                                    <Typography variant="h2">
                                        {offerIndex++ + "."} 
                                    </Typography>
                                </Grid>

                                <Grid item xs>
                                    <Typography variant="h2">
                                        {this.props.intl.formatMessage({id: "offerSuitability.offer.title"}).replace("{company}", offer.user.company)} 
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    {this.suitabilityFlag(offer.id)}
                                </Grid>

                            </Grid>

                            <OfferSummaryCompany 
                                user={offer.user}/>

                            <OfferSummaryPrice 
                                offer={offer}
                                priceOffer={priceOffer}/>

                            <OfferSummaryDocuments 
                                documents={offer.documents}
                                onDownload={(document) => this.download(document)}/>

                            <OfferSuitabilityChecking
                                props={this.props} 
                                offer={offer}
                                procedure={this.state.procedure}
                                suitabilities={this.state.suitabilities}
                                onChecked={(offerId, checked, criterion) => this.onSuitabilityChecked(offerId, checked, criterion)}
                                onSave={() => this.saveSuitabilities()}/>
                    
                        </CardContent>
                    </Card>
                );
            })}
            
        </>;
    }
}

export default injectIntl(withSnackbar(OfferSuitabilityOverview));
