import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Card, CardActions, CardContent, Grid, Typography, Tooltip, IconButton, Icon, Divider} from '@material-ui/core';
import AssignmentIcon from '@mui/icons-material/Assignment';

import '../../../css/EquipmentOverview.css';
import {GeneralContext} from "../../contexts/GeneralContext";



class ProfessionalGroupCard extends Component {

    static contextType = GeneralContext;

    render() {
        
        let cardToRender = null;

        switch (this.props.mode) {
            
            case "new":
                cardToRender = 
                    <Card className="equipmentCard">
                        <CardContent>
                            <Grid container spacing={2}> 
                                <Grid container justifyContent="center" alignItems="center" item xs={12}>
                                    <Typography variant="body1">
                                        <FormattedMessage id="professionalGroup.create.title"/>
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent="center" alignItems="center" item xs={12}>
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        onClick={() => this.props.showDialog(true)}
                                        disabled={this.props.readOnly}>
                                        <FormattedMessage id="commons.create.button"/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card> 
                break;

            case "withControls":
                cardToRender = 
                    <Card className="equipmentCard">  
                        <CardContent>
                            <Grid container justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography className="equipmentCardName" variant="h3">{this.props.professionalGroup.name}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        {this.props.intl.formatMessage({id: "professionalGroup.articleCount"}) + this.props.professionalGroup.articleIds.length}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container item justifyContent="center" alignItems="center">
                                        <Icon className="icon-medical-doctor" style={{fontSize: 60}}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>  

                        <Divider/>

                        <CardActions>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item>
                                    <Tooltip title={this.props.intl.formatMessage({id: this.props.readOnly
                                            ? "professionalGroup.showAssignedArticles.tooltip" : "professionalGroup.assignArticles.tooltip"})}>
                                        <IconButton 
                                            edge="end" 
                                            aria-label="assign" 
                                            onClick={() => this.props.assignArticlesToProfessionalGroup(this.props.professionalGroup)}>
                                            <AssignmentIcon style={{fontSize: "21pt"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={this.props.intl.formatMessage({id: "professionalGroup.edit.tooltip"})}>
                                        <IconButton 
                                            edge="end" 
                                            aria-label="edit" 
                                            onClick={() => this.props.editProfessionalGroup(this.props.professionalGroup)}
                                            disabled={this.props.readOnly}>
                                            <Icon className="icon-edit" style={{fontSize: "21pt"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={this.props.intl.formatMessage({id: "professionalGroup.delete.tooltip"})}>
                                        <IconButton 
                                            aria-label="delete" 
                                            onClick={() => this.props.deleteProfessionalGroup(this.props.professionalGroup.id)}
                                            disabled={this.props.readOnly}>
                                            <Icon className="icon-trash" style={{fontSize: "21pt"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                break;
            default:
                break;
        }
        
        return <div>{cardToRender}</div>;
    }
}

export default injectIntl(ProfessionalGroupCard);
