import React, {Component} from "react";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {FormattedMessage, injectIntl} from "react-intl";
import OperatingResourceSubCategorySelect
    from "../../../administration/operatingResources/OperatingResourceSubCategorySelect";
import PropTypes from "prop-types";
import OperatingResourcesSubCategory from "../../../assortment/operatingResource/OperatingResourcesSubCategory";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {RESOURCE_OFFSETTINGS} from "../../../../util/Constants";
import Spacer from "../../../uiLibrary/Spacer";

class OperatingResourcesForm extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        const subCategory = this.props.operatingResource?.subCategory;
        this.state = {
            operatingResource: this.props.operatingResource,
            subCategory: subCategory,
            operatingResourceList: this.props.operatingResourceList.filter(r => r.subCategory === subCategory)
        };
    }

    handleResourceClicked = (resource) => {
        if (this.state.operatingResource?.id === resource.id) {
            this.setState({operatingResource: null});
        } else {
            let operatingResource = {
                operatingResourceId: resource.id,
                businessUnitId: this.props.businessUnitId,
                count: 0,
                resourceOffsetting: RESOURCE_OFFSETTINGS.NONE,
                version: resource.version,
                name: resource.name,
                description: resource.description,
                category: resource.category,
                subCategory: resource.subCategory,
                features: resource.features,
                imageVos: resource.imageVos
            }
            this.setState({operatingResource});
        }
    }

    render() {
        return <>
            <OperatingResourceSubCategorySelect
                value={this.state.subCategory}
                onChange={(subCategory) => this.setState({subCategory, operatingResource: null,
                    operatingResourceList: this.props.operatingResourceList.filter(r => r.subCategory === subCategory)})}
            />

            <Spacer size={20}/>

            {this.state.subCategory && <OperatingResourcesSubCategory
                selectedResource={this.state.operatingResource}
                operatingResources={this.state.operatingResourceList}
                onClick={(operatingResource, target) => this.handleResourceClicked(operatingResource)}
            />}

            <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                    <Button style={{marginTop: "40px"}} variant="contained"
                            color="secondary"
                            onClick={() => this.props.onCancel()}>
                        <FormattedMessage id="commons.cancel.button"/>
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained"
                            style={{marginTop: "40px"}}
                            color="primary"
                            onClick={() => this.props.onSubmit(this.state.operatingResource)}
                            disabled={!this.state.operatingResource}>
                        {this.props.intl.formatMessage({id: "commons.apply.button"})}
                    </Button>
                </Grid>
            </Grid>
        </>
    }
}
OperatingResourcesForm.propTypes = {
    businessUnitId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    operatingResourceList: PropTypes.array.isRequired
};
export default injectIntl(OperatingResourcesForm);