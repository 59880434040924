import React from 'react';
import {Box} from '@material-ui/core';

function ArticleOverviewTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (<Box className="articleTabContainer">{children}</Box>)}
        </div>
    );
}

export default ArticleOverviewTabPanel;
