import React, {Component} from 'react';

import {FormattedMessage, injectIntl} from 'react-intl';
import {Box} from '@material-ui/core';
import {Link} from "react-router-dom";
import {AGB_ROUTE, IMPRESSUM_ROUTE, PRICES_ROUTE, PRIVACY_ROUTE} from '../../../util/Constants';
import '../../apps/App.css';
import '../../../css/Footer.css';

class Footer extends Component {

    render() {
        return (
            <div className={this.props.sticky ? "sticky-footer" : "non-sticky-footer"}>
                 <Box justifyContent="center" display="flex" alignItems="center" style={{flexWrap: "wrap"}}>
                     <div className="link-box">
                         <Link to={{pathname: IMPRESSUM_ROUTE}} target="_blank">
                            <FormattedMessage id="footer.impressum.title"/>
                        </Link>
                    </div>
                    <div className="link-box">
                        <Link to={{pathname: AGB_ROUTE}} target="_blank">
                            <FormattedMessage id="footer.termsOfUse.title"/>
                        </Link>
                    </div>
                    <div className="link-box">
                        <Link to={{pathname: PRIVACY_ROUTE}} target="_blank">
                            <FormattedMessage id="footer.privacy.title"/>
                        </Link>
                    </div>
                    <div className="link-box">
                        <Link to={{pathname: PRICES_ROUTE}} >
                            <FormattedMessage id="footer.prices.title"/>
                        </Link>
                    </div>
                    
                </Box>
            </div>
        );
    }
}

export default injectIntl(Footer);
