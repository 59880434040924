import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {EQUIPMENT_TYPE_ASSORTMENT, EQUIPMENT_TYPE_AREA, EQUIPMENT_TYPE_PROFESSIONAL_GROUP} from '../../../../../util/Constants';


import ArticleDetail from '../../../../share/ArticleDetail';
import {renderCategories, renderImage} from '../../../../../util/TableUtil';
import {GeneralContext} from "../../../../contexts/GeneralContext";


class LogisticsArticleDetails extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {showArticleDetails: false, articleForDetailView: null};
    }

    showArticleDetails = (article) => {
        this.setState({showArticleDetails: true, articleForDetailView: article});
    }

    handleDialogClose = () => {
        this.setState({showArticleDetails: false, articleForDetailView: null});
    }

    getSourceName = (source) => {
        let sourceTypeString;
        let sourceName = "";
        switch (source.type) {
            case EQUIPMENT_TYPE_AREA:
                sourceTypeString = "area";
                let area = this.props.allAreas.find(a => a && a.id === source.sourceId);
                if (area) {
                    sourceName = area.name;  
                }
                break;
            case EQUIPMENT_TYPE_PROFESSIONAL_GROUP:
                sourceTypeString = "professionalGroup";
                let professionalGroup = this.props.allProfessionalGroups.find(p => p && p.id === source.sourceId);
                if (professionalGroup) {
                    sourceName = professionalGroup.name;
                }
                break;
            case EQUIPMENT_TYPE_ASSORTMENT:
                sourceTypeString = "assortment";
                break;
            default:
                sourceTypeString = "assortment";
                break;
        }            
        if (sourceName) {
            sourceName = " (" + sourceName + ")";
        }
        return this.props.intl.formatMessage({id: "articleDeliveryPointOverview.table.titles.articleSource." + sourceTypeString}) + sourceName;
    }

    render() {
        const articleDetailsDialog = this.state.articleForDetailView 
            ? <ArticleDetail
                article={this.state.articleForDetailView}
                showDialog={this.state.showArticleDetails}
                handleDialogClose={this.handleDialogClose}/>
            : null;

        let rowData = {};
        let articles = [];
        for (let article of this.props.articles) {
            let data = {};
            data = Object.assign(data, article);
            data.sourceName = this.getSourceName(article.source);
            articles.push(data);
        }
        return (
            <div style={{marginBottom: "30px"}}>

                {articleDetailsDialog}

                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>

                        <MaterialTable
                            key="articleToDeliveryPointOverviewDetailTable"
                            title={this.props.intl.formatMessage({id: "articleDeliveryPointOverview.detailpanel.title"}, {name: rowData.description})}
                            columns={[
                                {title: this.props.intl.formatMessage({id: "entities.article.category"}),
                                 field: "category",
                                 render: article => renderCategories(article, "Category")},

                                {title: this.props.intl.formatMessage({id: "entities.article.subCategory"}),
                                 field: "subcategory",
                                 render: article => renderCategories(article, "SubCategory")},

                                {title: this.props.intl.formatMessage({id: "entities.article.articleCategory"}),
                                 field: "articleCategory",
                                 render: article => renderCategories(article, "ArticleCategory")},

                                {title: this.props.intl.formatMessage({id: "entities.article.description"}),
                                 field: "description",
                                 grouping: false},

                                {title: this.props.intl.formatMessage({id: "entities.article.image"}), 
                                 field: "image", 
                                 render: article => renderImage(this.props.assortmentData.find(d => d.id === article.articleId), this.showArticleDetails)},

                                {title: this.props.intl.formatMessage({id: "articleDeliveryPointOverview.table.titles.deliveryQuantity"}),
                                 field: "deliveryQuantity",
                                 grouping: false},

                                {title: this.props.intl.formatMessage({id: "articleDeliveryPointOverview.table.titles.equipQuantity"}),
                                 field: "equipQuantity",
                                 grouping: false},

                                {title: this.props.intl.formatMessage({id: "articleDeliveryPointOverview.table.titles.articleSource"}),
                                field: "sourceName"},
                            ]}
                            data={articles.sort((a, b) => a.articleDeliveryQuantityId - b.articleDeliveryQuantityId)}
                            options={{
                                grouping: false,
                                filtering: false,
                                sorting: articles.length > 0,
                                search: false,
                                paging: false,
                                selection: false,
                                headerStyle: {fontWeight: 'bold'},
                                showTitle: true,
                                toolbar: true
                            }}
                            style={{padding: "5px", marginTop: "10px", width: "100%"}}
                            components={{Container: props => (<Paper {...props} key={this.props.type} elevation={0}/>)}}
                            localization={{
                                grouping: {
                                    placeholder: this.props.intl.formatMessage({id: "commons.table.grouping.placeholder"}),
                                    groupedBy: this.props.intl.formatMessage({id: "commons.table.grouping.groupedBy"})
                                },
                            }}
                        />
                    </Grid>
                </Grid>

            </div>
        )
    }
}

export default injectIntl(LogisticsArticleDetails);
