import { Collapse, Grid, Icon, ListItemIcon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { headerColor, texisionBlueGrayDark, white } from "../../../../util/ColorTheme";
import React, { useState } from "react";
import { Hover } from "../InvoiceControllingOffer";

const InvoiceControllingTable = ({body, currency, titleId, titles, icon}) => {

    const [isOpen, setIsOpen] = useState(false);

    return <div key={"invoice-controlling-table-" + titleId}>

        <Hover>
            <Grid container alignItems="center" style={{padding: 20}} onClick={() => setIsOpen(!isOpen)}>
                <Grid item>
                    <ListItemIcon style={{fontSize: 24, color: texisionBlueGrayDark}} className={icon}/>
                </Grid>
                <Grid item xs>
                    <Typography variant="h4">
                        <FormattedMessage id={titleId}/>
                    </Typography>
                </Grid>
                <Grid item>
                    <Icon className={isOpen ? "icon-caret-up-large" : "icon-caret-down-large"} style={{fontSize: "10pt"}}/>
                </Grid>
            </Grid>
        </Hover>

        <Collapse in={isOpen}>
        
            <TableContainer component={Paper} style={{overflowX: "scroll", marginBottom: 20, marginTop: 5}}>
                <Table aria-label="invoice-controlling-table">

                    <TableHead style={{backgroundColor: headerColor}}>

                        <TableRow>
                            {(titles ?? [
                                "invoiceControlling.table.name.title",
                                "invoiceControlling.table.offsetting.title",
                                "invoiceControlling.table.price.title",
                                "invoiceControlling.table.texisionArticleNumber.title",
                                "invoiceControlling.table.bidderArticleNumber.title"
                            ]).map(title => <TableCell key={title+titleId} style={{fontWeight: "bold", color: white}}>
                                <FormattedMessage id={title} values={{currency: currency}}/>
                            </TableCell>)}
                        </TableRow>

                    </TableHead>

                    <TableBody>{body}</TableBody>

                </Table>
            </TableContainer>
        
        </Collapse>

    </div>;
}

export {
    InvoiceControllingTable
}
