import {Checkbox, Grid, Tooltip} from '@mui/material';
import { useIntl } from "react-intl";
import {DIALOG_TYPE_INFO, INVOICE_CONFLICT_TYPE} from "../../../../util/Constants";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {TreeItem, TreeView} from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {HorizontalRule} from "@mui/icons-material";
import {texisionFontColorDark} from "../../../../util/ColorTheme";

const InvoiceStatisticsItemSelection = ({
    bufferedNodes,
    cancelSelection,
    rootNode,
    invoices,
    onItemChecked,
    contractItemMap,
    saveSelection,
    showItemSelection
}) => {

    const intl = useIntl();

    const renderTree = (node) => {
        const checked = !!bufferedNodes.find(i => i.key === node.key);
        const checkBoxDisabled = !checked && bufferedNodes.length >= 10;
        const color = checked ? node.color : texisionFontColorDark;
        const description = node.description?.substring(0, 60);
        return (<Grid container alignItems="left" padding={0.5}>
                    <Grid item>
                        <Tooltip
                            title={checkBoxDisabled
                                ? intl.formatMessage({id: "cockpit.statistics.itemSelection.maxItems.tooltip"})
                                : ""}>
                            <div>
                                <Checkbox
                                    style={{width: "5px", height: "5px", marginRight: "5px", color: color}}
                                    disabled={checkBoxDisabled}
                                    onChange={(_, checked) => onItemChecked(node, checked)}
                                    checked={checked}
                                />
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <TreeItem key={node.key} nodeId={node.key} label={description}>
                            {Array.isArray(node.children) ? node.children.map((node) => renderTree(node)) : null}
                        </TreeItem>
                    </Grid>
                </Grid>)
    };

    const itemSelection = () => {

        if (!showItemSelection) {
            return null;
        }

        if (!contractItemMap || contractItemMap.size === 0) {
            return null;
        }

        let unknownArticles = [];
        let wrongPriceArticles = [];
        let duplicatedArticles = [];
        let missingArticles = [];

        for (let invoice of invoices) {
            for (let invoiceItem of invoice.invoiceItems) {
                let pseudoContractItem = {
                    id: invoiceItem.articleName,
                    type: null,
                    price: null,
                    item: {name: invoiceItem.articleName, amount: invoiceItem.amount},
                    articleNumbers: [invoiceItem.articleNumber]
                };

                const correspondingContractItem = Array.from(contractItemMap)
                    ?.find(([articleNumber, item]) => pseudoContractItem?.articleNumbers?.includes(articleNumber));
                if (correspondingContractItem) {
                    pseudoContractItem.alternateDescription = correspondingContractItem[1].alternateDescription;
                }

                if (invoiceItem.conflictType === INVOICE_CONFLICT_TYPE.UNKNOWN_ARTICLE 
                    && !unknownArticles.find(([articleNumber, contractItem]) => contractItem.item.name === invoiceItem.articleName)) {

                    unknownArticles.push([null, pseudoContractItem]);

                } else if (invoiceItem.conflictType === INVOICE_CONFLICT_TYPE.WRONG_PRICE
                    && !wrongPriceArticles.find(([articleNumber, contractItem]) => contractItem.item.name === invoiceItem.articleName)) {

                        wrongPriceArticles.push([null, pseudoContractItem]);

                } else if (invoiceItem.conflictType === INVOICE_CONFLICT_TYPE.DUPLICATED_ARTICLE
                    && !duplicatedArticles.find(([articleNumber, contractItem]) => contractItem.item.name === invoiceItem.articleName)) {

                        duplicatedArticles.push([null, pseudoContractItem]);

                } else if (invoiceItem.conflictType === INVOICE_CONFLICT_TYPE.MISSING_ARTICLE
                    && !missingArticles.find(([articleNumber, contractItem]) => contractItem.item.name === invoiceItem.articleName)) {

                        missingArticles.push([null, pseudoContractItem]);
                }
            }
        }

        return (
            <Grid container alignItems="center">
                <Grid item xs={8} key="item tree">
                    <div style={{padding: "10px", transform:"scale(1.1,1.1) translate(5%, 0%)"}}>
                        <TreeView
                            defaultExpanded={['Gesamt']}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            defaultEndIcon={<HorizontalRule />}
                        >
                            {renderTree(rootNode)}
                        </TreeView>
                    </div>
                </Grid>
            </Grid>
        );
    }

    return (
        <TexisionDialog
            type={DIALOG_TYPE_INFO}
            open={showItemSelection}
            style={{}}
            titleId="cockpit.statistics.itemSelection.title"
            subtitleId="cockpit.statistics.itemSelection.subtitle"
            actionId="commons.save.button"
            cancelId="commons.cancel.button"
            onAction={() => saveSelection()}
            onCancel={() => cancelSelection()}
            content={itemSelection()}/>
    );
}

export {
    InvoiceStatisticsItemSelection
}
