import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import {DELIVERY, DELIVERY_PICKUP, PICKUP} from '../../../util/Constants';
import Grid from '@material-ui/core/Grid';
import '../../apps/App.css';

import {withSnackbar} from 'notistack';
import {GeneralContext} from "../../contexts/GeneralContext";
import DeliveryAddressOverview from "./deliveryAddresses/DeliveryAddressOverview";
import PickupAddressOverview from "./pickupAddresses/PickupAddressOverview";
import LogisticTypeOverview from "./logisticType/LogisticTypeOverview";
import {getUnitAddresses} from "../../../services/AddressService";


class LogisticsOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            allDeliveryAddresses: [],
            allPickupAddresses: []
        }
    }

    async componentDidMount() {
        await this.loadAddresses();
    }

    loadAddresses = async() => {
        const addresses = await getUnitAddresses(this.context, this.props);
        this.setState({
            allDeliveryAddresses: addresses?.filter(a => a.addressType === DELIVERY || a.addressType === DELIVERY_PICKUP) ?? [],
            allPickupAddresses: addresses?.filter(a => a.addressType === PICKUP || a.addressType === DELIVERY_PICKUP) ?? [],
        });
    }

    render() {
        return (
            <>

                <Grid className="parent">

                    <LogisticTypeOverview
                        readOnly={this.props.readOnly}/>

                    <DeliveryAddressOverview
                        readOnly={this.props.readOnly}
                        deliveryAndPickupType={this.state.deliveryAndPickUpType}
                        allDeliveryAddresses={this.state.allDeliveryAddresses}
                        allPickupAddresses={this.state.allPickupAddresses}
                        reloadAddresses={() => this.loadAddresses()}/>

                    <PickupAddressOverview
                        readOnly={this.props.readOnly}
                        deliveryAndPickUpType={this.state.deliveryAndPickUpType}
                        allDeliveryAddresses={this.state.allDeliveryAddresses}
                        allPickupAddresses={this.state.allPickupAddresses}
                        reloadAddresses={() => this.loadAddresses()}/>

                </Grid>
            </>
        );
    }
}

export default injectIntl(withSnackbar(LogisticsOverview));
