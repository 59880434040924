import React, {Component} from "react";
import {injectIntl} from "react-intl";
import LogisticsTile from "../../shared/LogisticsTile";
import {DIALOG_TYPE_INFO, PROJECT_TASKS} from "../../../../../util/Constants";
import LogisticsAreaAssignment from "./LogisticsAreaAssignment";
import LogisticsProfessionalAssignment from "./LogisticsProfessionalAssignment";
import TexisionDialog from "../../../../uiLibrary/TexisionDialog";
import {GeneralContext} from "../../../../contexts/GeneralContext";
import {Tab, Tabs, Tooltip} from "@material-ui/core";
import {TabPanel} from "../../../../uiLibrary/TabPanel";
import LogisticsArticleDetails from "./LogsticsArticleDetails";
import {loadAddress} from "../../../../../services/AddressService";

class ArticleAssignmentTile extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            articleAssignmentOpen: false,
            addressVersion: null,
            tabIndex: 0
        };
    }

    async componentDidMount() {
        await this.loadAddressVersion();
    }

    loadAddressVersion = async() => {
        if (this.props.deliveryAddress) {
            const address = await loadAddress(this.context, this.props, this.props.deliveryAddress.id);
            this.setState({addressVersion: address?.version});
        }
    }

    title = () => {
        const numberOfAssignedArticles = this.props.allAssignedArticles?.length ?? 0;
        return this.props.intl.formatMessage({id: "logistics.assignment.numberOfAssignedArticles"}) + ": " + numberOfAssignedArticles;
    }

    assignmentComponent = () => {
        return (
            <>
                <div style={{display: "flex", justifyContent: "center", marginBottom: 30}}>
                    <Tabs
                        className="public-prices-tab"
                        value={this.state.tabIndex}
                        onChange={(event, newValue) => this.setState({tabIndex: newValue})}
                        indicatorColor="primary"
                        textColor="primary">
                        <Tab
                            key="area-assignment-tab"
                            label={this.props.intl.formatMessage({id: "logistics.articleAssignment.area.tab"})}
                            disableRipple={true}/>
                        <Tab
                            key="professional-group-assignment-tab"
                            label={this.props.intl.formatMessage({id: "logistics.articleAssignment.professionalGroup.tab"})}
                            disableRipple={true}/>
                        <Tab
                            key="assigned-articles-tab"
                            label={this.props.intl.formatMessage({id: "logistics.articleAssignment.assignedArticles.tab"})}
                            disableRipple={true}/>
                    </Tabs>
                </div>
                <TabPanel value={this.state.tabIndex} index={0}>
                    <LogisticsAreaAssignment
                        addressVersion={this.state.addressVersion}
                        reloadAddressVersion={this.loadAddressVersion}
                        allAreas={this.props.allAreas}
                        deliveryAddress={this.props.deliveryAddress}
                        onSave={this.props.onSave}/>
                </TabPanel>
                <TabPanel value={this.state.tabIndex} index={1}>
                    <LogisticsProfessionalAssignment
                        addressVersion={this.state.addressVersion}
                        reloadAddressVersion={this.loadAddressVersion}
                        allProfessionalGroups={this.props.allProfessionalGroups}
                        deliveryAddress={this.props.deliveryAddress}
                        onSave={this.props.onSave}/>
                </TabPanel>
                <TabPanel value={this.state.tabIndex} index={2}>
                    <LogisticsArticleDetails
                        articles={this.props.allAssignedArticles}
                        allAreas={this.props.allAreas}
                        allProfessionalGroups={this.props.allProfessionalGroups}
                        assortmentData={this.props.assortmentData}
                    />
                </TabPanel>
            </>
        );
    }

    render() {
        if (!this.props.deliveryAddress) {
            return null;
        }

        const hasNoArticles = !this.props.allAreas?.length && !this.props.allProfessionalGroups?.length;
        return (
            <>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.articleAssignmentOpen}
                    size="large"
                    titleId="logistics.articleAssignment.dialog.title"
                    subtitleId="logistics.articleAssignment.dialog.subtitle"
                    content={this.assignmentComponent()}
                    cancelId="commons.close.label"
                    onCancel={() => this.setState({articleAssignmentOpen: false})}
                />

                <Tooltip title={hasNoArticles ? this.props.intl.formatMessage({id: "logistics.articleAssignment.noArticles.tooltip"}) : ""}>
                    <div>
                        <LogisticsTile
                            content={this.title()}
                            disabled={hasNoArticles || this.props.readOnly}
                            onEdit={() => this.setState({articleAssignmentOpen: true})}
                            projectTask={PROJECT_TASKS.ARTICLE_ASSIGNMENT}
                            objectId={this.props.deliveryAddress.id}/>
                    </div>
                </Tooltip>

            </>
        )
    }
}

export default injectIntl(ArticleAssignmentTile);
