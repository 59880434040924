import React, {Component} from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import {Route, Router, Switch} from "react-router-dom";
import history from "../navigation/shared/History";
import ScrollToTop from "../uiLibrary/ScrollToTop";
import {
    ACCOUNT_ACTIVATE_ROUTE,
    AGB_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    IMPRESSUM_ROUTE, LAUNDRY_RADAR,
    LOGIN_ROUTE, OPERATION_ASSIGNMENT_ROUTE, PRICES_ROUTE, PRIVACY_ROUTE,
    REGISTRATION_ROUTE,
    RESET_PASSWORD_ROUTE, ROOT_ROUTE
} from "../../util/Constants";
import Login from "../authentication/Login";
import Registration from "../authentication/Registration";
import ForgotPassword from "../authentication/ForgotPassword";
import ResetPasswordLanding from "../authentication/ResetPasswordLanding";
import Impressum from "../legal/Impressum";
import TermsOfUse from "../legal/TermsOfUse";
import Privacy from "../legal/Privacy";
import PublicProductsOverview from "../money/PublicProductsOverview";
import { withRouter } from 'react-router-dom';
import PublicLaundryRadar from "../navigation/tender/PublicLaundryRadar";

class PreLoginApp extends Component {

    static contextType = GeneralContext;


    render() {
        return (
            <Router history={history}>

                <ScrollToTop history={history}/>

                <Switch>

                    <Route
                        path={LOGIN_ROUTE}
                        render={(props) => <Login
                            {...props}
                            setUserStateValue={this.setUserStateValue}
                            setCurrentUser={(currentUser) => this.setState({currentUser})}
                            logout={this.logout}/>
                        }/>
                    <Route
                        path={REGISTRATION_ROUTE}
                        component={Registration}/>
                    <Route
                        path={FORGOT_PASSWORD_ROUTE}
                        component={ForgotPassword}/>
                    <Route
                        path={RESET_PASSWORD_ROUTE}
                        component={ResetPasswordLanding}/>
                    <Route
                        path={IMPRESSUM_ROUTE}
                        component={Impressum}/>
                    <Route
                        path={AGB_ROUTE}
                        component={TermsOfUse}/>
                    <Route
                        path={PRIVACY_ROUTE}
                        component={Privacy}/>
                    <Route
                        path={PRICES_ROUTE}
                        component={PublicProductsOverview}/>
                    <Route
                        path={ACCOUNT_ACTIVATE_ROUTE}
                        component={Login}/>
                    <Route
                        path={OPERATION_ASSIGNMENT_ROUTE}
                        component={Login}/>
                    <Route
                        path={LAUNDRY_RADAR}
                        component={PublicLaundryRadar}/>
                    <Route
                        path={ROOT_ROUTE}
                        render={(props) => <Login {...props} redirectLocation={this.props.location}/>}/>

                </Switch>

            </Router>
        );
    }
}
export default withRouter(PreLoginApp);