import React, {Component} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {Button, Grid, Switch, Tooltip} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {getOfferProject, isPublished} from "../../../services/ProjectService";
import {EXPORT_TYPE_MERGE} from "../../../util/Constants";
import {getProjectDocumentZip} from "../../../services/ExportService";
import {downloadS3Document} from "../../../util/DocumentUtil";
import {LinearProgressIndicator} from "../../uiLibrary/LinearProgressIndicator";
import {withSnackbar} from "notistack";
import {Header} from "../../uiLibrary/Header";
import {Card, CardContent} from "@mui/material";


class OfferDocumentOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoading : false
        };
    }

    onDownloadDocument = async() => {
        this.setState({isLoading : true});
        let project = getOfferProject(this.context);
        getProjectDocumentZip(this.context, this.props, project.id)
            .then(async (s3Object) => {
                if (!!s3Object) {
                    await downloadS3Document(this.props, s3Object);
                }
        }).finally(() => this.setState({isLoading : false}));
    }

    render() {
        let project = getOfferProject(this.context);
        let downloadButtonDisabled = !project || !isPublished(project);
        let downloadTooltip = downloadButtonDisabled
            ? this.props.intl.formatMessage({id: "bidder.projectDetails.downloadDocuments.tooltip"})
            : "";
        let switchTooltip = this.props.intl.formatMessage({id: "commons.project.published.error"});
        return <>
            <LinearProgressIndicator active={this.state.isLoading}/>

            <Header
                titleId="procedure.export.type.title"
                subtitleId="procedure.export.type.subtitle"/>

            <Card className="child" style={{minWidth: 200}}>
                <CardContent>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item>
                            <Grid className="procedureDocumentGrid" container alignItems="center">
                                <FormattedMessage id="export.switch.title"/>
                                <Tooltip title={switchTooltip}>
                                    <span>
                                        <Switch
                                            checked={project?.exportType === EXPORT_TYPE_MERGE}
                                            disabled={true}
                                            name="exportSwitch"
                                            inputProps={{'aria-label': 'secondary checkbox'}}/>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Tooltip title={downloadTooltip}>
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={downloadButtonDisabled}
                                    onClick={() => this.onDownloadDocument()}>
                                    {this.props.intl.formatMessage({id: "bidder.projectDetails.downloadDocuments.button"})}
                                </Button>
                            </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    }
}
export default injectIntl(withSnackbar(OfferDocumentOverview));