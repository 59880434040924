import React, { Component } from "react";

import {injectIntl} from "react-intl";
import { OfferResultTable } from "./OfferResultTable";
import { putAsync } from "../../../services/BackendService";
import { SimpleTextCard } from "../../uiLibrary/SimpleTextCard";
import {
    COCKPIT_APP,
    DE,
    DIALOG_TYPE_INFO,
    EXPORT_TYPE_MERGE,
    LAST_OPERATION_ID,
    LICENSE_TYPES,
    OFFER_STATUS_ACCEPTED,
    PACKAGE_TYPES,
    PAYMENT_METHOD_TYPES,
    PREFLIGHT_CHECK_ROUTE,
    PROCEDURE_CONDITIONS_ROUTE
} from "../../../util/Constants";
import {createErrorMessage, createSuccessMessage} from "../../../util/Util";
import 'date-fns';

import '../../../css/OfferComparison.css';
import { withSnackbar } from "notistack";
import { WinnerSelectionDialog } from "./dialogs/WinnerSelectionDialog";
import { WinnerTextDialog } from "./dialogs/WinnerTextDialog";
import { OfferResultHeader } from "./OfferResultHeader";
import { isFirstDeadlineOver } from "../../../util/ProcedureUtil";
import { GeneralContext } from "../../contexts/GeneralContext";
import {getProcedure} from "../../../services/ProcedureService";
import {
    getActiveProject,
    getNextOfferRound,
    isPreviousProject,
    updateValidationMap
} from "../../../services/ProjectService";
import {
    createPayment, getActivePaymentMethodType, getPaymentByPriceId,
    getProductsAndPrices,
    getTrialMonths,
    latestActivePayment, sendPaymentConfirmationMail,
} from "../../../services/PaymentService";
import Payment from "../../money/Payment";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import {getPriceOfferListByOfferIds} from "../../../services/PriceOfferService";
import {loadOfferResult, loadSubmittedOffers} from "../../../services/OfferService";
import CouponForm from "../../money/CouponForm";
import {Grid} from "@mui/material";
import {Button} from "@material-ui/core";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import {getActiveOperation, prepareNextOfferRound} from "../../../services/OperationService";
import {withRouter} from "react-router-dom";


class OfferResultOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: true,
            offers: [],
            result: null,
            procedure: null,
            priceOfferContainer: null,
            exportType: EXPORT_TYPE_MERGE,
            contentVos: [],
            showWinnerSelection: false,
            selectedOfferId: null,
            winnerId: null,
            winnerText: "",
            showNextOfferRoundDialog: false,
            showSuccessDialog: false,
            showCouponForm: false,
            coupon: null,
            products: [],
            prices: []
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async() => {
        this.setState({isLoadingData: true});
        const offers = await loadSubmittedOffers(this.context, this.props);
        const procedure = await getProcedure(this.context, this.props, this.context.appData.offerProjectId);
        const result = await loadOfferResult(this.context, this.props);
        let priceOfferContainer;
        if (offers) {
            priceOfferContainer = await getPriceOfferListByOfferIds(this.context, this.props, offers.map(o => o.id));
        }
        if (result && offers) {
            for (let offer of offers) {
                offer.rank = result.offerResults?.find(r => r.offerId === offer.id)?.rank;
            }
        }
        const productsPricesVo = await getProductsAndPrices(this.context, this.props);
        const products = productsPricesVo?.products ? Array.from(Object.values(productsPricesVo.products)) : [];
        const prices = productsPricesVo?.prices ? Array.from(Object.values(productsPricesVo.prices)) : [];

        this.setState({
            isLoadingData: false, 
            offers, 
            procedure, 
            result, 
            priceOfferContainer, 
            products, 
            prices
        });
    }

    payLicense = async() => {
        this.setState({isRequesting: true, showCouponForm: false});

        const licenseType = LICENSE_TYPES.OFFER_LICENSE;
        const methodType = getActivePaymentMethodType(this.context, licenseType);

        const priceId = Array.from(this.state.prices.values()).find(price => price.id === this.state.products
            .find(p => p.metadata?.licenseType === licenseType)?.defaultPrice)?.id;
        let payment = getPaymentByPriceId(this.context, priceId);
        if (!payment) {
            payment = await createPayment(this.context, this.props, licenseType, PACKAGE_TYPES.EASY_FOR_EVERYONE_PACKAGE,
                priceId, this.state.winnerId, this.state.coupon?.id);
            if (!payment) {
                createErrorMessage(this.props.intl.formatMessage({id: "offers.overview.payment.errorMessage"}), this.props);
            }
        }

        if (methodType === PAYMENT_METHOD_TYPES.SEPA_OR_CARD) {
            const clientSecret = payment?.paymentIntent?.clientSecret ?? payment?.subscription?.pendingSetupIntentObject?.clientSecret;
            if (clientSecret) {
                this.setState({
                    clientSecret: clientSecret,
                    showPaymentDialog: true,
                    isSetupIntent: !payment.paymentIntent?.clientSecret
                });
            } else {
                createErrorMessage(this.props.intl.formatMessage({id: "offers.overview.payment.errorMessage"}), this.props);
            }
        } else if (methodType === PAYMENT_METHOD_TYPES.INVOICE) {
            this.setState({
                clientSecret: null,
                showPaymentDialog: true,
            });
        }

        this.setState({isRequesting: false});
    }

    onPaymentCancel = () => {
        this.setState({
            showPaymentDialog: false,
            winnerId: null,
            selectedOfferId: null,
            winnerText: "",
            showCouponForm: false,
            coupon: null,
            isRequesting: false
        });
    }

    onPaymentSuccess = async() => {
        await sendPaymentConfirmationMail(this.context, this.props, LICENSE_TYPES.OFFER_LICENSE, parseInt(localStorage.getItem(LAST_OPERATION_ID)));
        await this.selectWinner();
    }

    selectWinner = async() => {
        const offerResponseVo = {
            offerId: this.state.winnerId,
            answerText: this.state.winnerText,
            locale: DE
        };
        this.setState({showWinnerTextDialog: false, isRequesting: true});
        let projectId = this.context.appData.activeProjectId;
        const response = await putAsync("/operation/accept/project/" + projectId, offerResponseVo);
        if (response?.status === 200) {
            this.setState({showPaymentDialog: false});
            await this.loadData();
            this.setState({showSuccessDialog: true});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "offers.selectWinner.error"}), this.props);
        }
        this.setState({isRequesting: false, winnerId: null, winnerText: "", selectedOfferId: null});
    }

    onNextOfferRound = async () => {
        const nextOfferRound = getNextOfferRound(this.context);
        await prepareNextOfferRound(this.context,
            () => {
                createSuccessMessage(this.props.intl.formatMessage({id: "offerResult.prepareNextRound.success"}, {offerRound: nextOfferRound}), this.props);
                this.props.history.push(PROCEDURE_CONDITIONS_ROUTE);
            },
            () => {
                createErrorMessage(this.props.intl.formatMessage({id: "offerResult.prepareNextRound.error"}, {offerRound: nextOfferRound}), this.props);
                this.setState({showNextOfferRoundDialog: false});
            }
        );
        await updateValidationMap(this.context);
        window.location.reload();
    }

    getOfferLicensePrice = () => {
        const priceId = this.getProduct()?.defaultPrice;
        const price = this.state.prices?.find(price => price.id === priceId);
        let percent = 1;
        if (price?.unitAmount && price.transformQuantity?.divideBy) {
            percent = price.unitAmount/price.transformQuantity.divideBy;
        }
        return this.state.priceOfferContainer.priceOfferVos?.find(priceOffer => priceOffer.offerId === this.state.winnerId)?.sumGross * percent / 100.0;
    }

    getProduct = () => {
        return this.state.products.find(p => p.metadata?.licenseType === LICENSE_TYPES.OFFER_LICENSE);
    }

    getPrice = () => {
        return this.state.prices.find(price => price.id === this.getProduct()?.defaultPrice);
    }

    changeAppButton = () => {
        return (
            <Grid container spacing={2}>
                <Grid item>

                    <Button
                        onClick={async() => {
                            const projectId = getActiveProject(this.context)?.id;
                            await this.context.setActiveApp(COCKPIT_APP);
                            this.context.setActiveProjectId(projectId);
                            const activeOperationId = this.context.appData.operations?.find(o => o.activeProject.id === projectId)?.id;
                            if (activeOperationId !== null && activeOperationId !== undefined) {
                                this.context.setActiveOperationIdForCockpit(activeOperationId);
                            }
                            this.props.history.push(PREFLIGHT_CHECK_ROUTE);
                        }}
                        variant="contained" color="primary"
                        style={{marginTop: 10}}
                        endIcon={<DoubleArrowIcon/>}>
                        {this.props.intl.formatMessage({id: "offerResult.changeApp.button"})}
                    </Button>
                </Grid>


            </Grid>
        );
    }

    render() {
        if (this.state.isLoadingData) {
            return <div/>;
        }

        const operationId = getActiveOperation(this.context)?.id;
        const procedure = this.state.procedure;
        const hasWinner = this.state.offers && this.state.offers.filter(o => o.status === OFFER_STATUS_ACCEPTED).length > 0;
        const hasRatedOffers = this.state.result?.offerResults && this.state.result.offerResults.length > 0;

        const hasData = !!(
            this.state.offers?.length
            && this.state.result 
            && procedure 
            && this.state.priceOfferContainer?.priceOfferVos?.length
            && this.state.priceOfferContainer?.contentVos?.length
        );

        const isClosed = isFirstDeadlineOver(procedure);
        const isPrevious = isPreviousProject(this.context);

        let subtitleId;
        if (!isClosed) {
            subtitleId = "offerResult.notClosed.subtitle";
        } else if (!hasRatedOffers) {
            subtitleId = "offerResult.noRatedOffers.subtitle";
        } else if (!hasData) {
            subtitleId = "offers.comparison.noData";
        } else  {
            subtitleId = "offerResult.firstRound.subtitle";
        }

        const showButtons = isClosed && hasRatedOffers && hasData && !hasWinner && !isPrevious;
        const hasOfferLicense = latestActivePayment(this.context, LICENSE_TYPES.OFFER_LICENSE);

        return (
            <>

                <WinnerSelectionDialog
                    open={this.state.showWinnerSelection}
                    offers={this.state.offers}
                    result={this.state.result}
                    selectedOfferId={this.state.selectedOfferId}
                    onChange={(offerId) => this.setState({selectedOfferId: offerId})}
                    onCancel={() => this.setState({showWinnerSelection: false, selectedOfferId: null, winnerId: null, winnerText: ""})}
                    onAction={(offerId) => this.setState({showWinnerSelection: false, winnerId: offerId, showWinnerTextDialog: true})}/>
    
                <WinnerTextDialog
                    offers={this.state.offers}
                    show={this.state.showWinnerTextDialog}
                    winnerId={this.state.winnerId}
                    winnerText={this.state.winnerText}
                    hasCooperationLicense={hasOfferLicense}
                    onChange={(text) => this.setState({winnerText: text})}
                    actionButtonDisabled={this.state.isRequesting}
                    onCancel={() => this.setState({winnerId: null, winnerText: "", selectedOfferId: null, showWinnerTextDialog: false})}
                    onAction={() => hasOfferLicense ? this.selectWinner() : this.setState({showWinnerTextDialog: false, showCouponForm: true})}/>
    
                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showCouponForm}
                    titleId="payment.coupon.redeem.title"
                    content={<CouponForm
                        licenseType={LICENSE_TYPES.OFFER_LICENSE}
                        onCouponAdd={(coupon) => this.setState({coupon})}
                        onCouponRemove={() => this.setState({coupon: null})}
                        currency={getActiveProject(this.context).currency}
                    />}
                    cancelId="commons.cancel.button"
                    onCancel={() => this.onPaymentCancel()}
                    actionId={this.state.coupon ? "payment.coupon.continueWithCoupon.button" : "payment.coupon.continueWithoutCoupon.button"}
                    actionDisabled={this.state.isRequesting}
                    onAction={async() => await this.payLicense()}/>
    
                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    size="lg"
                    open={this.state.showPaymentDialog}
                    titleId="payment.subscribe.dialog.title"
                    content={<Payment
                        operationId={operationId}
                        coupon={this.state.coupon}
                        trialMonths={getTrialMonths(this.getProduct())}
                        clientSecret={this.state.clientSecret}
                        isSetupIntent={this.state.isSetupIntent}
                        currency={getActiveProject(this.context).currency}
                        offerLicensePrice={this.getOfferLicensePrice()}
                        price={this.getPrice()}
                        product={this.getProduct()}
                        onPaymentSuccess={() => this.onPaymentSuccess()}
                        onPaymentCancel={() => this.onPaymentCancel()}/>}
                    cancelId="commons.close.label"/>
    
                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showSuccessDialog}
                    titleId="offers.overview.successDialog.title"
                    subtitleId="offers.overview.successDialog.subtitle"
                    content={this.changeAppButton()}
                    actionId="commons.close.label"
                    onAction={() => this.setState({showSuccessDialog: false})}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showNextOfferRoundDialog}
                    titleId="offerResult.prepareNextRound.dialog.title"
                    subtitleId="offerResult.prepareNextRound.dialog.subtitle"
                    cancelId="commons.cancel.button"
                    actionId="commons.okay.button"
                    onCancel={() => this.setState({showNextOfferRoundDialog: false})}
                    onAction={() => this.onNextOfferRound()}/>
    
                <OfferResultHeader
                    showRecallButton={showButtons}
                    showWinnerButton={showButtons}
                    titleId={"offerResult.firstRound.title"}
                    subtitleId={!hasWinner && hasData && isClosed && hasRatedOffers ? subtitleId : null}
                    onRecallClicked={() => this.setState({showNextOfferRoundDialog: true })}
                    onWinnerClicked={() => this.setState({showWinnerSelection: true, selectedOfferId: null, winnerId: null, winnerText: ""})}/>
    
                <SimpleTextCard 
                    show={hasWinner} 
                    textId="offerResult.hasWinner.subtitle" 
                    replacements={{company: this.state.offers?.find(o => o.status === OFFER_STATUS_ACCEPTED)?.user?.company}}
                    style={{marginBottom: 30}}/>
    
                <SimpleTextCard
                    show={!hasWinner && (!isClosed || !hasData || !hasRatedOffers)}
                    textId={subtitleId}
                    style={{marginTop: 20}}/>
    
                {isClosed && hasData && hasRatedOffers && <>
    
                    <OfferResultTable
                        offers={this.state.offers.filter(offer => this.state.result.offerResults?.map(r => r.offerId)?.includes(offer.id) === true)}
                        result={this.state.result}/>
    
                </> }
    
            </>
        );
    }
}

export default injectIntl(withRouter(withSnackbar(OfferResultOverview)));
