import { TableCell, TableRow } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { formatArticleNumbers } from "../../../../util/TableUtil";
import { formatPrice } from "../../../../util/Util";
import { InvoiceControllingTable } from "./InvoiceControllingTable";

const ResidentsLaundryPriceTable = ({priceUnit, contents, currency, unitOrProjectId}) => {

    const intl = useIntl();

    const residentsLaundry = contents.find(c => c.exportable.id === parseInt(unitOrProjectId))?.residentsLaundry;
    
    if (!residentsLaundry || residentsLaundry.length === 0) {
        return <div/>;
    }

    return <InvoiceControllingTable 
        key="invoice-table-residents"
        icon="icon-clothings"
        titleId="navBar.residentsLaundry.title" 
        currency={currency}
        titles={[
            "invoiceControlling.table.name.title",
            "residentsLaundry.pricesheet.table.cleanType",
            "invoiceControlling.table.offsetting.title",
            "invoiceControlling.table.price.title",
            "invoiceControlling.table.texisionArticleNumber.title",
            "invoiceControlling.table.bidderArticleNumber.title"
        ]}
        body={
        
        Object.values(priceUnit.residentsLaundryGroup.priceItemMap).map((priceItem) => {

            const laundry = residentsLaundry.find(c => c.id === priceItem.objectId);

            return <TableRow key={"residents-laundry-price-table-" + priceItem.objectId}>
                <TableCell>
                    {laundry?.name}
                </TableCell>
                <TableCell>
                    {laundry?.cleanType ? intl.formatMessage({id: "residentsLaundry.table.title." + laundry.cleanType.toLowerCase()}) : ""}
                </TableCell>
                <TableCell>
                    <FormattedMessage id={"constants.ArticleOffsetting.DELIVERED_QUANTITY"}/>
                </TableCell>
                <TableCell>
                    {formatPrice(priceItem.price)}
                </TableCell>
                <TableCell>
                    {"TX-" + priceItem.objectId}
                </TableCell>
                <TableCell>
                    {formatArticleNumbers(priceItem.articleNumbers)}
                </TableCell>
            </TableRow>;
        })
    }/>;
}

export {
    ResidentsLaundryPriceTable
}
