import {Radio} from "@material-ui/core"
import React, {useContext} from "react"
import {useIntl} from "react-intl"
import { DIALOG_TYPE_INFO } from "../../../../util/Constants"
import TexisionDialog from "../../../uiLibrary/TexisionDialog"
import {GeneralContext} from "../../../contexts/GeneralContext";
import {getActiveProject} from "../../../../services/ProjectService";

const WinnerSelectionDialog = ({open, offers, selectedOfferId, onChange, onCancel, onAction, result}) => {

    const context = useContext(GeneralContext);
    const intl = useIntl();

    const badges = result?.offerBadges;
    const offerResults = result?.offerResults;

    const getDetailsText = (offerId) => {
        const rank = offerResults?.find(r => r.offerId === offerId)?.rank;
        let awards = [];
        if (badges) {
            for (let [badge, resultOfferId] of Object.entries(badges)) {
                if (resultOfferId === offerId) {
                    awards = [...awards, badge];
                }
            }
        }
        let badgesText = "";
        for (let i = 0; i < awards.length; i++) {
            badgesText += intl.formatMessage({id: "constants.OfferBadge." + awards[i]});
            if (i < awards.length - 1) {
                badgesText += ", ";
            }
        }
        let detailsText = "";
        if (rank || badgesText) {
            detailsText += " (";
            if (rank) {
                detailsText += rank + ". " + intl.formatMessage({id: "offerResult.winnerSelection.rank"});
                if (badgesText) {
                    detailsText += ", " + badgesText;
                }
            } else {
                detailsText += badgesText;
            }
            detailsText += ")";
        }
        return detailsText;
    }


    const activeProject = getActiveProject(context);

    return (
        <TexisionDialog
            type={DIALOG_TYPE_INFO}
            open={open}
            replacements={{tender: activeProject.name}}
            titleId="offerResult.selectWinner.dialog.title"
            subtitleId="offerResult.selectWinner.dialog.subtitle"
            content={<div style={{marginTop: 30}}>
                {offers?.sort((a,b) => a.rank - b.rank)?.map(offer => {
                    return <div key={"winner-radio-button-" + offer.id}>
                        <Radio
                            color="primary"
                            style={{textDecorationColor: "black"}}
                            value={offer.id}
                            checked={offer.id === selectedOfferId}
                            onChange={() => onChange(offer.id)}/>
                        {offer?.user?.company + getDetailsText(offer.id)}
                    </div>})}
            </div>}
            cancelId="commons.close.label"
            onCancel={() => onCancel()}
            actionId="commons.continue.button"
            actionDisabled={selectedOfferId === null || selectedOfferId === undefined}
            onAction={() => onAction(selectedOfferId)}/>
    );
}

export {
    WinnerSelectionDialog
}
