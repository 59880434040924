import React, {Component} from 'react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import {Grid} from "@material-ui/core";

class OfferRatingSelection extends Component {

    getColor = (value) => {
        if (!this.props.value || this.props.value < 0) {
            return "disabled";
        } else {
            return this.props.value >= value ? "primary" : "disabled";
        }
    }

    render() {
        return (
            <Grid container>
                {[1, 2, 3, 4, 5].map(value => {
                    const Icon = this.getColor(value) === "disabled" ? StarBorderIcon : StarIcon;
                    return (
                        <Grid item key={this.props.rowKey + value}>
                            <Icon color={this.getColor(value)} onClick={this.props.disabled ? null : () => this.props.onChange(value)}/>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }
}

export default OfferRatingSelection;
