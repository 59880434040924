import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";
import { Component } from "react";
import { injectIntl } from "react-intl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContractFutureItem from "../contractstatus/ContractFutureItem";
import { createErrorMessage, createSuccessMessage } from "../../../util/Util";
import { GeneralContext } from "../../contexts/GeneralContext";
import { withSnackbar } from "notistack";
import {acceptWithdraw, getActiveOperation, rejectWithdraw, requestWithdraw} from "../../../services/OperationService";
import {isOfferUser} from "../../../services/UserService";

class ContractFuture extends Component {

    static contextType = GeneralContext;

    acceptOperationWithdraw = async(activeOperation, effectiveFrom, comment) => {
        await acceptWithdraw(
            this.context,
            activeOperation,
            effectiveFrom,
            this.props.requestedWithdrawId,
            comment,
            () => {
                createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.acceptWithdraw.successMessage"}), this.props);
                this.props.reloadData();
            },
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.acceptWithdraw.errorMessage"}), this.props));
    }

    rejectOperationWithdraw = async(activeOperation, effectiveFrom, comment) => {
        await rejectWithdraw(
            this.context,
            activeOperation,
            effectiveFrom,
            this.props.requestedWithdrawId,
            comment,
            () => {
                createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.rejectWithdraw.successMessage"}), this.props);
                this.props.reloadData();
            },
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.rejectWithdraw.errorMessage"}), this.props));
    }

    requestOperationWithdraw = async(activeOperation, effectiveFrom, projectId, comment) => {
        const hasBidder = isOfferUser(activeOperation?.bidder);
        await requestWithdraw(
            this.context,
            activeOperation,
            effectiveFrom,
            projectId,
            comment,
            () => {
                const messageId = hasBidder ? "cockpit.contractStatus.requestWithdraw.successMessage"
                    : "cockpit.contractStatus.requestWithdraw.successMessage.withoutBidder";
                createSuccessMessage(this.props.intl.formatMessage({id: messageId}), this.props);
                this.props.reloadData();
            },
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.requestWithdraw.errorMessage"}), this.props));
    }

    withdrawWithdraw = async(activeOperation, effectiveFrom, comment) => {
        await rejectWithdraw(
            this.context,
            activeOperation,
            effectiveFrom,
            this.props.requestedWithdrawId,
            comment,
            () => {
                createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.withdrawWithdraw.successMessage"}), this.props);
                this.props.reloadData();
            },
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.withdrawWithdraw.errorMessage"}), this.props));
    }

    render() {
        
        const activeOperation = getActiveOperation(this.context);

        if (this.props.allFutureDifferences && this.props.allFutureDifferences.length > 0) {

            return (
                <Accordion
                    style={{marginTop: 40, minWidth: 300}}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}>
                        <h2 style={{fontWeight: "bold", fontSize: 20}}>
                            {this.props.intl.formatMessage({id: "contractStatus.futureDifferences.title"})}
                        </h2>
                    </AccordionSummary>

                    <AccordionDetails 
                        style={{padding: 0}}>
                        <Grid container>
                            {this.props.allFutureDifferences.map(futureDifference => <Grid 
                                key={"source-" + futureDifference.projectSourceId + "-target-" + futureDifference.projectTargetId} 
                                item xs={12}>
                                <ContractFutureItem
                                    key={futureDifference.effectiveFrom}
                                    futureProjects={this.props.futureProjects}
                                    operationDifference={futureDifference}
                                    requestedWithdrawId={this.props.requestedWithdrawId}
                                    hasWorkingProject={this.props.hasWorkingProject}
                                    acceptWithdraw={(comment) => this.acceptOperationWithdraw(activeOperation, futureDifference.effectiveFrom, comment)}
                                    rejectWithdraw={(comment) => this.rejectOperationWithdraw(activeOperation, futureDifference.effectiveFrom, comment)}
                                    requestWithdraw={(projectId, comment) => this.requestOperationWithdraw(activeOperation, futureDifference.effectiveFrom, projectId, comment)}
                                    withdrawWithdraw={(comment) => this.withdrawWithdraw(activeOperation, futureDifference.effectiveFrom, comment)}/>
                            </Grid>)}
                        </Grid>
                    </AccordionDetails>

                </Accordion>
            );
        } else {
            return null;
        }
    }
}

export default injectIntl(withSnackbar(ContractFuture));
