import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import TenderHistoryItem from "./TenderHistoryItem";
import { GeneralContext } from "../contexts/GeneralContext";
import History from "../project/History";
import {getActiveProject} from "../../services/ProjectService";

class TenderHistory extends Component {

    static contextType = GeneralContext;

    render() {

        const project = getActiveProject(this.context);
        if (!project) {
            return <div/>;
        }
        
        const operation = this.context.appData.operations
            .find(operation => operation.activeProject?.id === project.id 
            || operation.workingProject?.id === project.id);

        const operationId = operation?.id;
        const activeProjectId = operation?.activeProject?.id;

        return (
            <History
                key={activeProjectId}
                isTender={true}
                operationId={operationId} 
                historyItem={(opDiff) => <TenderHistoryItem key={opDiff.effectiveFrom} operationDifference={opDiff}/>} 
                titleId="tender.history.title" 
                subtitleId="tender.history.subtitle"/>
        );
    }
}

export default injectIntl(withSnackbar(TenderHistory));
