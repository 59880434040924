import { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import {DASHBOARD_ROUTE} from "../../../util/Constants";
import { GeneralContext } from "../../contexts/GeneralContext";
import KeyWrapper from "../../uiLibrary/KeyWrapper";

class OfferRoundRoute extends Component {

    static contextType = GeneralContext;

    render() {
        const activeProjectId = this.context.appData.activeProjectId;
        if (activeProjectId !== null && activeProjectId !== undefined) {
            return (
                <Route
                    path={this.props.path}
                    render={(_) =>
                        <KeyWrapper
                            key={this.context.appData.offerProjectId}
                            projectId={activeProjectId}
                            component={this.props.component}
                        />
                    }
                />
            );
        } else {
            return (
                <Redirect to={DASHBOARD_ROUTE}/>
            );
        }
    }
}

export default OfferRoundRoute;
