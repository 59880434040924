import React, {Component} from 'react';
import 'date-fns';
import {Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Button, Card, Switch} from '@material-ui/core';
import {injectIntl, FormattedMessage} from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withSnackbar} from 'notistack';
import '../../../css/BillOfQuantities.css';
import {Icon} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {
    APP_TYPE_KEY,
    COCKPIT_APP,
    DIALOG_TYPE_ERROR,
    DIALOG_TYPE_WARNING, LICENSE_TYPES,
    PROJECT_STATUS_IN_NEGOTIATION,
    PROJECT_STATUS_IN_POST_PROCESSING, PROJECT_TASKS
} from '../../../util/Constants';
import {GeneralContext} from "../../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import ValidationHeader from "../../validation/ValidationHeader";
import ExportButton from "../ExportButton";
import {isExportTypeMerge} from "../../../util/DocumentUtil";
import {isComponentReadOnly} from "../../uiLibrary/ReadOnlyWrapper";
import {isOfferUser} from "../../../services/UserService";
import {
    contractBlockText,
    contractSubChapterText,
    downloadContract,
    getContract,
    isContractBlockIncomplete,
    updateContract
} from "../../../services/ContractService";
import {convertStringToNumber, isTender} from "../../../util/Util";
import ContractBlock from "./ContractBlock";
import {getActiveOperation} from "../../../services/OperationService";
import {getActiveProject} from "../../../services/ProjectService";
import {latestActivePayment} from "../../../services/PaymentService";

class ContractOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            contract: null, 
            showFalseDataDialog: false, 
            showIncompleteDataDialog: false,
            isCockpit: false,
            isLocked: false,
            isLoading: true
        };
    }

    async componentDidMount() {
        const isCockpit = localStorage.getItem(APP_TYPE_KEY) === COCKPIT_APP;
        const isLocked = !isCockpit && !latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE);
        this.setState({isCockpit, isLocked});
        await this.loadContract();
    }

    readOnly = () => {
        return isComponentReadOnly(this.context, PROJECT_TASKS.CONTRACT, this.context.appData.activeProjectId);
    }

    // server calls and state changes

    loadContract = async() => {
        this.setState({isLoading: true});
        const contract = await getContract(this.context, this.props);
        this.setState({contract});
        if (contract) this.context.setUnsavedChanges(false);
        this.setState({isLoading: false});
    }

    sendContract = async() => {
        if (this.hasContractConflicts()) {
            this.setState({showFalseDataDialog: true});
            return;
        } else if (!this.isContractComplete()) {
            this.setState({showIncompleteDataDialog: true});
            return;
        }
        const contract = JSON.parse(JSON.stringify(this.state.contract));
        contract.contractDurationMonths = convertStringToNumber(contract.contractDurationMonths);
        contract.contractRenewalMonths = convertStringToNumber(contract.contractRenewalMonths);
        contract.contractTerminationMonths = convertStringToNumber(contract.contractTerminationMonths);
        contract.paymentTermDays = convertStringToNumber(contract.paymentTermDays);
        contract.keyLossEuro = convertStringToNumber(contract.keyLossEuro);
        contract.propertyDamageEuro = convertStringToNumber(contract.propertyDamageEuro);
        contract.personalDamageEuro = convertStringToNumber(contract.personalDamageEuro);
        contract.processingDamageEuro = convertStringToNumber(contract.processingDamageEuro);
        if (contract.contractChapter) {
            delete contract.contractChapter;
        }
        if (contract.assortmentCategories) {
            delete contract.assortmentCategories;
        }

        const updatedContract = await updateContract(this.context, this.props, contract);
        this.setState({contract: updatedContract ? updatedContract : this.state.contract});
    }


    exportContract = async() => {
        if (this.hasContractConflicts()) {
            this.setState({showFalseDataDialog: true});
            return;
        } else if (!this.isContractComplete()) {
            this.setState({showIncompleteDataDialog: true});
            return;
        }
        await downloadContract(this.context, this.props);
    }

    changePriceHedgingActive = () => {
        let contract = this.state.contract;
        contract.hasPriceHedging = contract.hasPriceHedging !== true;
        this.context.setUnsavedChanges(true);
        this.setState({contract: contract});
    }

    // components to render

    priceHedgingSwitch = () => {
        if (this.state.contract) {
            return <div style={{paddingLeft: "20px"}}>
                {this.props.intl.formatMessage({id: "contract.priceHedging.switch.title"})}
                <Switch 
                    onChange={this.changePriceHedgingActive} 
                    checked={this.state.contract.hasPriceHedging}
                    disabled={this.readOnly()}/>
            </div>;
        }
    }

    contractBlockEditIcon = (chapter) => {
        if (!this.readOnly() && chapter.text.includes("{") && chapter.text.includes("}") && !chapter.text.includes("assortmentCategories")) {
            let iconColor;
            if (this.hasContractBlockConflict(chapter.index)) {
                iconColor = "red";
            } else if (isContractBlockIncomplete(this.state.contract, chapter.index)) {
                iconColor = "orange";
            } else {
                iconColor = "grey";
            }
            return <Icon style={{paddingLeft: "10px", color: iconColor}}>edit</Icon>;
        } else {
            return <div/>;
        }
    }

    // validation and components for conflicts and hints

    isContractComplete = () => {
        let contract = this.state.contract;
        if (!contract) {
            return false;
        }
        const globalMandatoryFields = contract.startOfServiceDate && contract.startOfServiceDate > 0
            && contract.contractDurationMonths && contract.contractDurationMonths > 0
            && contract.contractRenewalMonths && contract.contractRenewalMonths > 0
            && contract.contractTerminationMonths && contract.contractTerminationMonths > 0
            && contract.paymentTermDays && contract.paymentTermDays > 0;
        const tenderMandatoryFields = contract.processingDamageEuro && contract.processingDamageEuro > 0
            && contract.personalDamageEuro && contract.personalDamageEuro > 0
            && contract.propertyDamageEuro && contract.propertyDamageEuro > 0
            && contract.keyLossEuro && contract.keyLossEuro > 0
            && (!contract.hasPriceHedging || (contract.priceHedgingTo && contract.priceHedgingTo > 0))
            && (contract.useXRechnung || contract.useZugferd);
        if (isTender()) {
            return globalMandatoryFields && tenderMandatoryFields;
        } else {
            return globalMandatoryFields;
        }
    }

    hasContractConflicts = () => {
        let contract = this.state.contract;
        return this.state.contract.hasPriceHedging && this.hasTimeConflict(contract.startOfServiceDate, contract.priceHedgingTo);
    }

    hasTimeConflict = (constraint, time) => {
        return constraint && constraint > 0 && time && time > 0 && time < constraint;
    }

    hasContractBlockConflict = (index) => {
        let contract = this.state.contract;
        return !!(contract && index === 6 && (this.hasTimeConflict(contract.startOfServiceDate, contract.priceHedgingTo)));
    }

    contractBlockIncompleteHint = () => {
        return <div style={{color: "orange"}}>{this.props.intl.formatMessage({id: "contract.chapter.incomplete.hint"})}</div>;
    }

    contractBlockConflictHint = () => {
        return <div style={{color: "red"}}>{this.props.intl.formatMessage({id: "contract.chapter.conflict.hint"})}</div>;
    }

    getSubtitleId = () => {
        if (localStorage.getItem(APP_TYPE_KEY) !== COCKPIT_APP) {
            return "contract.subtitle";
        }
        const activeOperation = getActiveOperation(this.context);
        const hasBidder = isOfferUser(activeOperation?.bidder);
        if (!hasBidder) {
            return "contract.subtitle";
        }
        let isActiveProject;
        if (activeOperation.activeProject.id === this.context.appData.activeProjectId) {
            isActiveProject = true;
        } else if (activeOperation.workingProject?.id === this.context.appData.activeProjectId) {
            isActiveProject = false;
        }
        const editable = !isActiveProject && activeOperation?.editable;

        if (isActiveProject) {
            return "contract.activeProject.subtitle";
        } else if (!editable && activeOperation.workingProject?.status === PROJECT_STATUS_IN_NEGOTIATION) {
            return "contract.readonly.inNegotiation.subtitle";
        } else if (!editable && activeOperation.workingProject?.status === PROJECT_STATUS_IN_POST_PROCESSING) {
            return "contract.readonly.inProcess.subtitle";
        } else if (activeOperation.workingProject?.status === PROJECT_STATUS_IN_NEGOTIATION) {
            return "contract.working.inNegotiation.subtitle";
        } else {
            return "contract.working.inProcess.subtitle";
        }
    }

    render() {
        const hasContract = this.state.contract && this.state.contract.contractChapter?.length;
        
        return (
            <div className={this.state.isLocked ? "contentAreaExportLocked" : null}>

                <ValidationHeader
                    title={<Grid container alignItems="flex-start">
                        <Grid item xs>
                            <Typography variant="h1">
                                <FormattedMessage id="contract.title"/>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ExportButton
                                documentType="contract"
                                exportButtonId="contract.export.text"
                                export={(exportType, e) => this.exportContract()}
                                exportDisabled={!hasContract || this.state.isLocked}
                                forAllUnits={true}
                            />
                        </Grid>
                    </Grid>}
                    incomplete={!this.isContractComplete()}
                    incompleteTextId="contract.validation.incompleteData.subtitle"
                    objectId={this.state.contract?.projectId}
                    projectId={this.state.contract?.projectId}
                    projectTask={PROJECT_TASKS.CONTRACT}/>

                <Typography variant="subtitle1" style={{marginBottom: "20px"}}>
                    <FormattedMessage id={this.getSubtitleId()}/>
                    <div>{""}</div>
                    {isExportTypeMerge(this.context)
                        && <FormattedMessage id={"export.subtitle.merge"}/>}
                </Typography>

                {/* the state for the dialog is set in the export method from ExportUtil */}
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showIncompleteDataDialog}
                    titleId="contract.export.incompleteData.title"
                    subtitleId="contract.export.incompleteData.subtitle"
                    actionId="commons.okay.button"
                    onAction={() => this.setState({showIncompleteDataDialog: false})}/>

                <TexisionDialog
                    type={DIALOG_TYPE_ERROR}
                    open={this.state.showFalseDataDialog}
                    titleId="contract.export.falseData.title"
                    subtitleId="contract.export.falseData.subtitle"
                    actionId="commons.okay.button"
                    onAction={() => this.setState({showFalseDataDialog: false})}/>
                
                <Card>
                    <CardContent>

                        {!hasContract && !this.state.isLoading && <Typography variant="h6" style={{marginBottom: "5%"}}>
                            {this.props.intl.formatMessage({id: "contract.chapter.notAvailable"})}
                        </Typography>}

                        {hasContract && <Grid container>
                            <Grid item xs={9}>
                                <Grid container>
                                    <Grid item>
                                        <Typography variant="h2" style={{marginBottom: "5%"}}>
                                            {this.props.intl.formatMessage({id: "contract.chapter.title"})}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        {this.priceHedgingSwitch()}
                                    </Grid>
                                </Grid>


                            </Grid>
                            <Grid item xs={3} container direction="row" justifyContent="flex-end" alignItems="flex-start">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{marginRight: 0, marginBottom: "20px"}}
                                    onClick={(e) => this.sendContract()}
                                    disabled={!hasContract || this.readOnly() || !this.context.hasUnsavedChanges()}>
                                    <FormattedMessage id="commons.save.button"/>
                                </Button>
                            </Grid>
                        </Grid>}

                        {hasContract && this.state.contract.contractChapter.sort((a, b) => a.index - b.index).map(chapter => {
                            if (!this.state.contract.hasPriceHedging && chapter.index === 6) {
                                return null;
                            } else {
                                return (
                                    <Accordion key={chapter.title} elevation={1}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls={chapter.title + "-CONTENT"}
                                            id={chapter.title + "-HEADER"}>
                                            <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
                                                <Grid item xs>
                                                    <Typography variant="h6">
                                                        {chapter.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {this.hasContractBlockConflict(chapter.index)
                                                        ? this.contractBlockConflictHint()
                                                        : (isContractBlockIncomplete(this.state.contract, chapter.index)
                                                            ? this.contractBlockIncompleteHint(chapter.index) : <div/>)}
                                                </Grid>
                                                <Grid item>
                                                    {this.contractBlockEditIcon(chapter)}
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{width:"100%"}}>
                                                <ContractBlock
                                                    readOnly={this.readOnly()}
                                                    contract={this.state.contract}
                                                    onContractChange={(contract) => this.setState({contract})}
                                                    index={chapter.index}/>
                                                {contractBlockText(this.state.contract, chapter, this.props.intl, getActiveProject(this.context)?.currency)}
                                                {chapter.subChapters?.map(subChapter => contractSubChapterText(this.state.contract, subChapter, this.props.intl))}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            }
                        })}
                    </CardContent>
                </Card>
                
            </div>
        );
    }
}

export default withRouter(injectIntl(withSnackbar(ContractOverview)));
