import { Card, CardContent, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { Component } from "react";
import { injectIntl } from "react-intl";
import { GeneralContext } from "../contexts/GeneralContext";
import { PriceUnitSummary } from "./PriceUnitSummary";
import { PriceSheetCustomerArticlesTable } from "./tables/PriceSheetCustomerArticlesTable";
import { PriceSheetRentalLinenTable } from "./tables/PriceSheetRentalLinenTable";
import { PriceSheetResidentsLaundryTable } from "./tables/PriceSheetResidentsLaundryTable";
import { PriceSheetResourcesTable } from "./tables/PriceSheetResourcesTable";
import { PriceSheetSpecialServicesTable } from "./tables/PriceSheetSpecialServicesTable";
import {updatePriceOffer} from "../../services/PriceOfferService";
import {getActiveProjectByOperationId} from "../../services/ProjectService";

class PriceSheetUnit extends Component {

    static contextType = GeneralContext;

    render() {

        const { 
            allArticleNumbers,
            contentVo, 
            exportType, 
            editable, 
            offer, 
            priceUnitMap,
            project, 
            resetAllArticleNumbers,
            readOnly,
            singlePriceOnly
        } = this.props;

        const priceUnit = priceUnitMap[contentVo.exportable.id];

        const activeProject = getActiveProjectByOperationId(this.context, project.operationId);
        const isPrevious = activeProject?.id !== project.id;
        const hasRentalLinen = contentVo.articles && new Map(Object.entries(contentVo.articles)).size > 0;
        const hasCustomerArticles = contentVo.customerArticles && contentVo.customerArticles.length > 0;
        const hasResidentsLaundry = contentVo.residentsLaundry && contentVo.residentsLaundry.length > 0;
        const hasSpecialServices = contentVo.specialServices && contentVo.specialServices.length > 0;
        const hasOperatingResources = contentVo.unitResources && contentVo.unitResources.length > 0 
            && contentVo.operatingResources && contentVo.operatingResources.length > 0;

        if (!hasRentalLinen && !hasCustomerArticles && !hasRentalLinen && !hasSpecialServices 
            && !hasOperatingResources && !hasResidentsLaundry) {

            return (
                <div key="nocontent"/>
            );
        }

        const otherProps = {
            allArticleNumbers,
            project,
            editable,
            exportType,
            offer,
            resetAllArticleNumbers,
            readOnly,
            singlePriceOnly,
            updatePriceSheet: (type, itemMap) => updatePriceOffer(
                this.context, this.props, offer?.id,
                project?.id, type, itemMap, allArticleNumbers,
                this.props.reloadPriceSheet, false, priceUnitMap
            )
        };

        return <Card style={{paddingBottom: 20, marginBottom: 20}} key={contentVo.exportable.id}>

            <CardContent>

                <Typography variant="h3" style={{marginBottom: 20}}>{contentVo.exportable.name}</Typography>

                <PriceUnitSummary
                    currency={project?.currency}
                    exportType={exportType}
                    sumGross={priceUnit?.sumGross}
                    sumNet={priceUnit?.sumNet}/>

                <PriceSheetRentalLinenTable
                    lockArticleNumbers={this.props.lockArticleNumbers}
                    lockPrices={this.props.lockPrices}
                    show={hasRentalLinen} 
                    priceGroupMap={priceUnit.articleGroupMap}
                    rentalLinen={contentVo.articles}
                    isPrevious={isPrevious}
                    {...otherProps}/>

                <PriceSheetCustomerArticlesTable
                    lockArticleNumbers={this.props.lockArticleNumbers}
                    lockPrices={this.props.lockPrices}
                    show={hasCustomerArticles} 
                    priceGroup={priceUnit.customerArticleGroup}
                    customerArticles={contentVo.customerArticles}
                    isPrevious={isPrevious}
                    {...otherProps}/>

                <PriceSheetResidentsLaundryTable
                    lockArticleNumbers={this.props.lockArticleNumbers}
                    lockPrices={this.props.lockPrices}
                    show={hasResidentsLaundry} 
                    priceGroup={priceUnit.residentsLaundryGroup}
                    residentsLaundry={contentVo.residentsLaundry}
                    isPrevious={isPrevious}
                    {...otherProps}/>

                <PriceSheetResourcesTable
                    lockArticleNumbers={this.props.lockArticleNumbers}
                    lockPrices={this.props.lockPrices}
                    show={hasOperatingResources} 
                    priceGroup={priceUnit.operatingResourceGroup}
                    operatingResources={contentVo.operatingResources}
                    unitResources={contentVo.unitResources}
                    isPrevious={isPrevious}
                    {...otherProps}/>

                <PriceSheetSpecialServicesTable
                    lockArticleNumbers={this.props.lockArticleNumbers}
                    lockPrices={this.props.lockPrices}
                    show={hasSpecialServices} 
                    priceGroup={priceUnit.specialServiceGroup}
                    specialServices={contentVo.specialServices}
                    isPrevious={isPrevious}
                    {...otherProps}/>


            </CardContent>

        </Card>;
    }
}

export default injectIntl(withSnackbar(PriceSheetUnit));
