import { Button, Grid } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FILE_TYPES, selectDocument } from "../../util/DocumentUtil";
import { DocumentPreview } from "../uiLibrary/DocumentPreview";
import ProcedureAccordion from "./ProcedureAccordion";
import "../../css/Procedure.css";
import {uploadProcedureDocument} from "../../services/ProcedureService";

const ProcedureDocumentsSelection = ({documents, props, onUpload, reloadProcedure, projectId, context}) => {

    return (<React.Fragment>

        <ProcedureAccordion
            mandatory={false}
            complete={true}
            titleId="procedure.documents.title"
            subtitle={<FormattedMessage id="procedure.documents.subtitle"/>}>
            {documents && documents.length > 0 
            && <Grid className="procedureDocumentGrid" container spacing={2}>
                {documents.map((document) => 
                <DocumentPreview 
                    document={document} 
                    deleteEndpoint="/document/procedure/"
                    context={context} 
                    props={props} 
                    callback={() => reloadProcedure()}/>)}
            </Grid>}
            <Button 
                className="procedureButton" 
                color="primary" 
                component="label">
                <FormattedMessage id="procedure.document.select.button"/>
                <input 
                    type="file" 
                    id="procedure-document-input" 
                    accept={FILE_TYPES.PDF} 
                    style={{display: "none"}} 
                    onChange={(e) => selectDocument(e.target.files[0], async(document) => {
                        onUpload(true);
                        await uploadProcedureDocument(context, props, projectId, document);
                        await reloadProcedure();
                        onUpload(false);
                    }, props, documents)} 
                    onClick={(event)=> {event.target.value = null}}/>
            </Button>
        </ProcedureAccordion>   
    </React.Fragment>);
};

export {
    ProcedureDocumentsSelection
};
