import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { INVOICE_CONFLICT_TYPE } from "../../../util/Constants";
import { Accordion, AccordionSummary, Grid, Typography, AccordionDetails } from "@material-ui/core";
import { Button, List, ListItem, ListItemIcon, ListItemText, Popover } from "@mui/material";
import { texisionGreen, texisionOrange, texisionRed } from "../../../util/ColorTheme";
import { formatPrice } from "../../../util/Util";
import { GeneralContext } from "../../contexts/GeneralContext";
import { formatDate } from "../../../util/DocumentUtil";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InvoiceArticleDetails from "./InvoiceArticleDetails";
import {getActiveOperation} from "../../../services/OperationService";

class InvoiceValidation extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            invoiceItemToShow: null,
            anchorEl: null
        }
    }

    change = (before, after) => {
        return <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
                {" ("}
            </Grid>
            <Grid item>
                <Typography variant="h4" style={{color: texisionGreen, whiteSpace: "nowrap"}}>
                    {before}
                </Typography>
            </Grid>
            <Grid item style={{marginTop: 6}}>
                <ArrowRightAltIcon/>
            </Grid>
            <Grid item>
                <Typography variant="h4" style={{color: texisionRed, whiteSpace: "nowrap"}}>
                    {after}
                </Typography>
            </Grid>
            <Grid item>
                {")"}
            </Grid>
        </Grid>
    }

    priceChange = (invoiceItem) => {
        const currency = getActiveOperation(this.context).activeProject.currency;
        return this.change(
            formatPrice(this.getContractPrice(invoiceItem)) + " " + currency, 
            formatPrice(invoiceItem.price) + " " + currency
        );
    }

    getContractPrice = (invoiceItem) => {
        const contractItem = this.getContractItem(invoiceItem);
        if (contractItem) {
            return contractItem.price;
        } else {
            return "?";
        }
    }

    getContractItem = (invoiceItem) => {
        return this.props.contractItems?.get(invoiceItem?.articleNumber);
    }

    articleDetailsButton = (invoiceItem) => {
        if (invoiceItem && this.getContractItem(invoiceItem)) {
            return (
                <Button onClick={(event) => this.showArticleDetails(event, invoiceItem)}>
                    <FormattedMessage id="cockpit.invoices.articleDetails.button"/>
                </Button>
            );
        }
    }

    showArticleDetails = (event, invoiceItem) => {
        this.setState({invoiceItemToShow: invoiceItem, anchorEl: event.currentTarget});
    }

    closeArticleDetails = () => {
        this.setState({invoiceItemToShow: null, anchorEl: null});
    }

    itemValidationGroup = (conflictType, hideTitle) => {
        let icon;
        switch (conflictType) {
            case INVOICE_CONFLICT_TYPE.MISSING_ARTICLE:
            case INVOICE_CONFLICT_TYPE.DUPLICATED_ARTICLE:
                icon = <WarningAmberIcon style={{color: texisionOrange}}/>;
                break;
            case INVOICE_CONFLICT_TYPE.NO_CONFLICT:
                icon = <CheckCircleOutlineIcon style={{color: texisionGreen}}/>;
                break;
            case INVOICE_CONFLICT_TYPE.WRONG_PRICE:
            case INVOICE_CONFLICT_TYPE.UNKNOWN_ARTICLE:
                icon = <ErrorOutlineIcon style={{color: texisionRed}}/>;
                break;
            default:
                break;
        }
        const items = this.props.invoice.invoiceItems.filter(i => i.conflictType === conflictType);
        if (items.length === 0) {
            return (<div/>);
        }
        return (
            <div style={{marginBottom: 25, overflow: "auto"}}>
                {!hideTitle && <Typography variant="h4">
                    <FormattedMessage id={"cockpit.invoices.validation." + conflictType + ".title"}/>
                </Typography>}
                <Typography variant="body2">
                    <FormattedMessage id={"cockpit.invoices.validation." + conflictType + ".subtitle"}/>
                </Typography>
                <List>
                    {items.map((invoiceItem) => {
                        return (
                            <ListItem key={invoiceItem.articleName} value={invoiceItem} dense>
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText>
                                    <Grid container alignItems="center" spacing={3} wrap="nowrap">
                                        <Grid item style={{fontWeight: "bold"}}>
                                            {invoiceItem.articleName}
                                        </Grid>
                                        {conflictType === INVOICE_CONFLICT_TYPE.WRONG_PRICE && <Grid item>
                                            {this.priceChange(invoiceItem)}
                                        </Grid>}
                                        {[
                                            INVOICE_CONFLICT_TYPE.WRONG_PRICE, 
                                            INVOICE_CONFLICT_TYPE.MISSING_ARTICLE, 
                                            INVOICE_CONFLICT_TYPE.DUPLICATED_ARTICLE
                                        ].includes(conflictType)
                                        && <Grid item xs style={{display: "flex", justifyContent: "flex-end"}}>
                                            {this.articleDetailsButton(invoiceItem)}
                                        </Grid>}
                                    </Grid>
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        );
    }

    generalValidation = () => {
        const invoicePaymentDate = this.props.invoice.invoicePaymentDate;
        const contractPaymentDate = this.props.invoice.contractPaymentDate;
        const paymentDateConflict = invoicePaymentDate < contractPaymentDate;

        const invoiceTax = this.props.invoice.invoiceTax;
        const contractTax = this.props.invoice.contractTax;
        const taxConflict = invoiceTax !== contractTax;

        if (!paymentDateConflict && !taxConflict) {
            return <div/>;
        } else {
            return (
                <div style={{marginBottom: 25, overflow: "auto"}}>
                    <Typography variant="h4">
                        <FormattedMessage id="cockpit.invoices.validation.general.title"/>
                    </Typography>
                    <Typography variant="body2">
                        <FormattedMessage id="cockpit.invoices.validation.general.subtitle"/>
                    </Typography>
                    <List>

                        {taxConflict && <ListItem dense>
                            <ListItemIcon>
                                <ErrorOutlineIcon style={{color: texisionRed}}/>
                            </ListItemIcon>
                            <ListItemText>
                                <Grid container alignItems="center" spacing={3} wrap="nowrap">
                                    <Grid item style={{fontWeight: "bold"}}>
                                        <FormattedMessage id="cockpit.invoices.validation.tax"/>
                                    </Grid>
                                    <Grid item>
                                        {this.change(contractTax + " %", invoiceTax + " %")}
                                    </Grid>
                                </Grid>
                            </ListItemText>
                        </ListItem>}

                        {paymentDateConflict && <ListItem dense>
                            <ListItemIcon>
                                <ErrorOutlineIcon style={{color: texisionRed}}/>
                            </ListItemIcon>
                            <ListItemText>
                                <Grid container alignItems="center" spacing={3} wrap="nowrap">
                                    <Grid item style={{fontWeight: "bold"}}>
                                        <FormattedMessage id="cockpit.invoices.validation.paymentDate"/>
                                    </Grid>
                                    <Grid item>
                                        {this.change(
                                            formatDate(this.props.intl, contractPaymentDate), 
                                            formatDate(this.props.intl, invoicePaymentDate)
                                        )}
                                    </Grid>
                                </Grid>
                            </ListItemText>
                        </ListItem>}

                    </List>
                </div>
            );
        }
    }

    render() {
        const hasCorrectItems = this.props.invoice.invoiceItems.filter(i => i.conflictType === INVOICE_CONFLICT_TYPE.NO_CONFLICT).length > 0;

        return (
            <div style={{marginLeft: 10, marginTop: 20}}>
                <Popover 
                    key="article-popover" 
                    open={!!this.state.invoiceItemToShow} 
                    anchorEl={this.state.anchorEl} 
                    onClose={() => this.closeArticleDetails()}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} 
                    transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <InvoiceArticleDetails 
                        invoiceItem={this.state.invoiceItemToShow} 
                        contractItem={this.getContractItem(this.state.invoiceItemToShow)}/>
                </Popover>

                {this.generalValidation()}

                {this.itemValidationGroup(INVOICE_CONFLICT_TYPE.WRONG_PRICE)}
                {this.itemValidationGroup(INVOICE_CONFLICT_TYPE.UNKNOWN_ARTICLE)}
                {this.itemValidationGroup(INVOICE_CONFLICT_TYPE.MISSING_ARTICLE)}
                {this.itemValidationGroup(INVOICE_CONFLICT_TYPE.DUPLICATED_ARTICLE)}

                {hasCorrectItems && <Accordion>
                    <AccordionSummary style={{margin: 0}} expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant="h4">
                            <FormattedMessage id={"cockpit.invoices.validation.NO_CONFLICT.title"}/>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.itemValidationGroup(INVOICE_CONFLICT_TYPE.NO_CONFLICT, true)}
                    </AccordionDetails>
                </Accordion>}
            </div>
        );
    }
}

export default injectIntl(InvoiceValidation);
