import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Button,
    Card,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from "@material-ui/core";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {getAsyncCatch, postAsyncCatch} from "../../../services/BackendService";
import {withSnackbar} from 'notistack';
import {
    EQUIPMENT_TYPE_AREA,
    EQUIPMENT_TYPE_PROFESSIONAL_GROUP,
    RENTAL_LINEN_ROUTE
} from "../../../util/Constants";
import {Link, Redirect, withRouter} from "react-router-dom";
import '../../../css/RentalLinen.css';
import {GeneralContext} from "../../contexts/GeneralContext";
import MaterialTable from "material-table";
import {renderImage} from "../../../util/TableUtil";
import {bodyBackgroundColor, texisionFontColorDark} from "../../../util/ColorTheme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArticleDetail from "../../share/ArticleDetail";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import {createErrorMessage} from "../../../util/Util";
import {wrapOptionsWithCategories} from "../../../util/ArticleHelper";
import {getAreaById, updateArea} from "../../../services/AreaService";
import {getProfessionalGroupById, updateProfessionalGroup} from "../../../services/ProfessionalGroupService";
import ArticleEditDialog from "./ArticleEditDialog";
import {createOrGetArticle} from "../../../services/ArticleConfigurationService";


class RentalLinenAssignment extends Component {
    
    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            keyIndex: 0,
            area: this.props.location.state?.area,
            professionalGroup: this.props.location.state?.professionalGroup,
            assignedArticles: this.props.location.state?.assignedArticles ?? [],
            assignedArticleIds: [],
            isSaved: false,
            isLoading: false,
            articleForDetailView: null,
            selectedArticle: null
        }
        if (this.state.area) {
            this.state.assignedArticleIds = [...this.state.area.articleIds];
        } else if (this.state.professionalGroup) {
            this.state.assignedArticleIds = [...this.state.professionalGroup.articleIds];
        }
    }

    componentDidUpdate() {
        this.context.setUnsavedChanges(this.hasUnsavedChanges() && !this.state.isSaved);
    }

    reloadArea = async() => {
        const area = await getAreaById(this.context, this.props, this.state.area.id);
        this.setState({area, assignedArticleIds: area?.articleIds ?? []});
    }

    reloadProfessionalGroup = async() => {
        const professionalGroup = await getProfessionalGroupById(this.context, this.props, this.state.professionalGroup.id);
        this.setState({professionalGroup, assignedArticleIds: professionalGroup?.articleIds ?? []});
    }

    reloadAssignedArticles = async() => {
        const data = await getAsyncCatch(this.context, "/assortment/" + this.context.appData.activeUnitId, this.props);
        if (data) {
            let assortmentArticles = data.articles;
            let articles = await postAsyncCatch(this.context, "/articles", {ids: assortmentArticles.map(a => a.articleId)}, this.props, true);
            if (articles) {
                for (let article of articles) {
                    article.sources = assortmentArticles.find(a => a.articleId === article.id)?.sources;
                }
                if (this.state.area) {
                    articles = articles.filter(a => a.sources?.find(s => s.sourceId === this.state.area.id && s.type === EQUIPMENT_TYPE_AREA));
                } else if (this.state.professionalGroup) {
                    articles = articles.filter(a => a.sources?.find(s => s.sourceId === this.state.professionalGroup.id && s.type === EQUIPMENT_TYPE_PROFESSIONAL_GROUP));
                } else {
                    articles = [];
                }
                this.setState({assignedArticles: articles});
            }
        }
    }

    hasUnsavedChanges = () => {
        let originalList = [];
        if (this.state.area) {
            originalList = [...this.state.area.articleIds];
        } else if (this.state.professionalGroup) {
            originalList = [...this.state.professionalGroup.articleIds];
        }
        if (!this.state.assignedArticleIds || !originalList) {
            return false;
        } else {
            return this.state.assignedArticleIds.sort().join(',') !== originalList.sort().join(',');
        }
    }

    saveChanges = () => {
        this.context.setUnsavedChanges(false);
        this.setState({isSaved: true});
    }

    onSaveArticle = async(articleConfiguration, filterOptions) => {
        let articleVo = await createOrGetArticle(
            this.context,
            this.props,
            articleConfiguration,
            filterOptions);
        if (articleVo) {
            const articleId = articleVo.id;
            if (this.state.assignedArticleIds?.includes(articleId)) {
                createErrorMessage(this.props.intl.formatMessage({id: "rentalLinen.assignment.duplicateArticle.hint"}), this.props);
            } else {
                await this.setState((prevState) => {
                    const assignedArticleIds = new Set(prevState.assignedArticleIds);
                    if (prevState.selectedArticle) {
                        assignedArticleIds.delete(prevState.selectedArticle.id);
                    }
                    assignedArticleIds.add(articleId);
                    return { assignedArticleIds: Array.from(assignedArticleIds), selectedArticle: null };
                });
                await this.assignArticle();
                this.setState({keyIndex: this.state.keyIndex + 1, showArticleDialog: false});
            }
        }
    }

    assignArticle = async() => {
        this.setState({isLoading: true});
        if (this.state.area) {
            await this.assignToArea(this.state.assignedArticleIds);
        } else if (this.state.professionalGroup) {
            await this.assignToProfessionalGroup(this.state.assignedArticleIds);
        }
        this.setState({isLoading: false});
    }

    removeArticle = async(articleId) => {
        this.setState({isLoading: true});
        if (this.state.area) {
            await this.assignToArea(this.state.assignedArticleIds.filter(id => id !== articleId));
        } else if (this.state.professionalGroup) {
            await this.assignToProfessionalGroup(this.state.assignedArticleIds.filter(id => id !== articleId));
        }
        this.setState({isLoading: false});
    }

    assignToArea = async(assignedArticleIds) => {
        const area = this.state.area;
        const areaToUpdate = {
            id: area.id,
            businessUnitId: area.businessUnitId,
            name: area.name,
            articleIds: assignedArticleIds,
            version: area.version
        }
        const updatedArea = await updateArea(this.context, this.props, areaToUpdate);
        this.saveChanges();
        if (updatedArea) {
            this.setState({category: "", subCategory: "", articleCategory: "", newFilterCategories: []});
            await Promise.all([
                this.reloadArea(),
                this.reloadAssignedArticles()
            ]);
        }
    }

    assignToProfessionalGroup = async(assignedArticleIds) => {
        const professionalGroup = this.state.professionalGroup;
        const professionalGroupToUpdate = {
            id: professionalGroup.id,
            businessUnitId: professionalGroup.businessUnitId,
            name: professionalGroup.name,
            type: professionalGroup.type,
            gender: professionalGroup.gender,
            articleIds: assignedArticleIds,
            version: professionalGroup.version
        }
        const updatedProfessionalGroup = await updateProfessionalGroup(this.context, this.props, professionalGroupToUpdate);
        this.saveChanges();
        if (updatedProfessionalGroup) {
            this.setState({category: "", subCategory: "", articleCategory: "", newFilterCategories: []});
            await Promise.all([
                this.reloadProfessionalGroup(),
                this.reloadAssignedArticles()
            ]);
        }
    }

    findAssignedArticle = (category, subCategory, articleCategory) => {
        return this.state.assignedArticles?.find(a =>
            a.category === category &&
            a.subcategory === subCategory &&
            a.articleCategory === articleCategory);
    }

    headline = () => {
        if (this.state.area) {
            return this.props.intl.formatMessage({id: "area.assignment.title"}) + this.state.area.name;
        } else if (this.state.professionalGroup) {
            return this.props.intl.formatMessage({id: "professionalGroup.assignment.title"}) + this.state.professionalGroup.name;
        } else {
            return "";
        }
    }

    subtitle = () => {
        if (this.state.area) {
            return this.props.intl.formatMessage({id: "area.assignment.subtitle"});
        } else if (this.state.professionalGroup) {
            return this.props.intl.formatMessage({id: "professionalGroup.assignment.subtitle"});
        } else {
            return "";
        }
    }

    showArticleDetails = (article) => {
        let articleForDetailView = {...article};
        articleForDetailView.filterCategories = wrapOptionsWithCategories(article, this.props.location.state?.articleConfigurations);
        this.setState({articleForDetailView});
    }

    actionButtons = (article) => {
        return (
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Tooltip title={this.props.intl.formatMessage({id: "commons.edit.button"})}>
                    <IconButton onClick={() => this.setState({selectedArticle: article, showArticleDialog: true})}
                                disabled={this.state.isLoading || this.props.readOnly}>
                       <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={this.props.intl.formatMessage({id: "commons.remove.button"})}>
                    <IconButton onClick={() => this.removeArticle(article.id)}
                                disabled={this.state.isLoading || this.props.readOnly}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

    rentalLinenTable = (category) => {
        let data = this.state.assignedArticles.filter(r => r.category === category)
            .sort((a,b) => this.props.intl.formatMessage({id: "constants.SubCategory." + a.subcategory})
                .localeCompare(this.props.intl.formatMessage({id: "constants.SubCategory." + b.subcategory})));
        return (
            <Grid item xs={12}>
                <MaterialTable
                    className="rentalLinenTable"
                    columns={[
                        {title: this.props.intl.formatMessage({id: "entities.article.subCategory"}), field: "subcategory", filtering: false, sorting: false,
                            render: article => this.props.intl.formatMessage({id: "constants.SubCategory." + article.subcategory})},
                        {title: this.props.intl.formatMessage({id: "entities.article.articleCategory"}), field: "articleCategory", filtering: false, sorting: false,
                            render: article => this.props.intl.formatMessage({id: "constants.ArticleCategory." + article.articleCategory})},
                        {title: this.props.intl.formatMessage({id: "entities.article.description"}), field: "description", grouping: false, filtering: false},
                        {title: this.props.intl.formatMessage({id: "entities.article.image"}), field: "features", grouping: false, filtering: false,
                            render: article => renderImage(article, this.showArticleDetails)},
                        {title: this.props.intl.formatMessage({id: "rentalLinen.assignment.actions"}), align: "right", cellStyle: {width: 180},
                            render: article => this.actionButtons(article), grouping: false, filtering: false}
                    ]}
                    data={data}
                    options={{
                        showTitle: false,
                        search: false,
                        paging: false,
                        draggable: false,
                        toolbar: false,
                        sorting: data.length > 0,
                        headerStyle: {
                            fontWeight: 'bold',
                            backgroundColor: bodyBackgroundColor,
                            color: texisionFontColorDark
                        }
                    }}/>
            </Grid>
        );
    }

    assortment = () => {
        const articles = this.state.assignedArticles;
        return (
            <div>
                {!articles || articles.length === 0 ? <Card>
                    {this.props.intl.formatMessage({id: "rentalLinen.assignment.noArticles"})}
                </Card> : <div/>}

                {this.context.appData.categories
                    ?.sort((a,b) => this.props.intl.formatMessage({id: "constants.Category." + a})
                        .localeCompare(this.props.intl.formatMessage({id: "constants.Category." + b})))
                    ?.map(category => {
                    const filteredArticles = articles.filter(a => a.category === category);
                    if (filteredArticles && filteredArticles.length > 0) {
                        return (
                            <Grid item xs={12} key={category}>

                                <Accordion elevation={0}>

                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="-CONTENT"
                                        id="-HEADER">

                                        <Typography variant="h6">
                                            {this.props.intl.formatMessage({id: "constants.Category." + category})}
                                        </Typography>

                                    </AccordionSummary>

                                    <AccordionDetails>
                                        {this.rentalLinenTable(category)}
                                    </AccordionDetails>

                                </Accordion>

                            </Grid>
                        );
                    } else {
                        return <div/>;
                    }
                })}
            </div>
        );
    }

    render() {
        if (!this.props.location.state) {
            return (
                <Redirect to={RENTAL_LINEN_ROUTE}/>
            );
        }
        const type = this.state.area ? "area" : "professionalGroup";
        return (
          <>
              <ArticleEditDialog
                  show={this.state.showArticleDialog}
                  key={this.state.keyIndex}
                  article={this.state.selectedArticle}
                  articleConfigurations={this.props.location.state.articleConfigurations}
                  type={type}
                  onCancel={() => this.setState({showArticleDialog: false, selectedArticle: null})}
                  onSubmit={(configuration, options) => this.onSaveArticle(configuration, options)}
              />

              {this.state.articleForDetailView && <ArticleDetail
                  article={this.state.articleForDetailView}
                  showDialog={!!this.state.articleForDetailView}
                  handleDialogClose={() => this.setState({articleForDetailView: null})}/>}

              <Link to={{pathname: RENTAL_LINEN_ROUTE}}>
                  &lt; &lt; &nbsp;<FormattedMessage id="commons.back.button"/>
              </Link>

              <Typography className="withSpace" variant="h1">
                  {this.props.intl.formatMessage({id: "rentalLinen.assignment.title"})}
              </Typography>

              <Typography variant="subtitle1">
                  {this.subtitle()}
              </Typography>

              <Card>
                  {!this.props.readOnly &&
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="h2">
                              {this.headline()}
                          </Typography>
                          <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this.setState({showArticleDialog: true})}>
                              <FormattedMessage id="rentalLinen.assignment.assign.button"/>
                          </Button>
                      </div>
                  }

                  {!this.props.readOnly && <div style={{height: 30}}/>}

                  {this.assortment()}
              </Card>
          </>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(RentalLinenAssignment)));
