import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {texisionBlue} from "../../../util/ColorTheme";
import MenuIcon from '@mui/icons-material/Menu';
import Popover from '@material-ui/core/Popover';
import tenderLogo from '../../../resources/tender_logo.svg';
import offerLogo from '../../../resources/offer_logo.svg';
import cockpitLogo from '../../../resources/cockpit_logo.svg';
import orderLogo from '../../../resources/order_logo.svg';
import {
    BIDDER_DASHBOARD_ROUTE,
    COCKPIT_APP,
    DASHBOARD_ROUTE,
    OFFER_APP,
    ORDER_APP,
    ORDER_DASHBOARD_ROUTE,
    TENDER_APP
} from '../../../util/Constants';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, CardActionArea, CardContent, CardHeader, CardMedia, Typography} from '@mui/material';
import {GeneralContext} from "../../contexts/GeneralContext";
import { isOrderUser, mayUseCockpit, mayUseOffer, mayUseOrder, mayUseTender } from '../../../services/UserService';
import {withRouter} from "react-router-dom";

class AppMenu extends React.Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        }
    }

    handleClick = (event) => {
        if (this.props.mobile && this.props.showMobileDrawer) {
            this.props.showMobileDrawer();
        } else {
            this.setState({open: !this.state.open, anchorEl: event.currentTarget});
        }
    }

    handleClose = () => {
        this.setState({open: !this.state.open, anchorEl: null});
    }

    popoverContent = () => {

        const user = this.context.currentUser;

        return (
            <Card variant="outlined" style={{padding: 16}}>

                <CardHeader 
                    title={this.props.intl.formatMessage({id: "app.switch.title"}).toUpperCase()} 
                    titleTypographyProps={{color: texisionBlue}}/>

                {this.context.offline && <Typography variant="subtitle2" style={{marginBottom: 20}}>
                    <FormattedMessage id="app.switch.offline"/>
                </Typography>}

                {mayUseTender(user) && !this.context.offline && <>
                
                    <Card variant="outlined" style={{marginBottom: 8, padding: 0}}>
                        <CardActionArea
                            style={{padding: 8}}
                            onClick={() => {
                                this.handleClose();
                                this.context.setActiveApp(TENDER_APP);
                                this.props.history.push(DASHBOARD_ROUTE);
                            }}>
                            <CardContent>

                                <CardMedia>
                                    <img 
                                        src={tenderLogo} 
                                        style={{height: 25}} 
                                        alt="Tender"/>
                                </CardMedia>
                                
                                <Typography color="text.secondary" style={{marginTop: 4}}>
                                    <FormattedMessage id="app.menu.tender"/>
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                    </Card>

                </>}

                {mayUseOffer(user) && !this.context.offline &&
                
                    <Card variant="outlined" style={{marginBottom: 8, padding: 0}}>
                        <CardActionArea
                            style={{padding: 8}}
                            onClick={() => {
                                this.handleClose();
                                this.context.setActiveApp(OFFER_APP);
                                this.props.history.push(BIDDER_DASHBOARD_ROUTE);
                            }}>
                            <CardContent>

                                <CardMedia>
                                    <img 
                                        src={offerLogo} 
                                        style={{height: 25}} 
                                        alt="Offer"/>
                                </CardMedia>
                                
                                <Typography color="text.secondary" style={{marginTop: 4}}>
                                    <FormattedMessage id="app.menu.offer"/>
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                    </Card>

                }

                {mayUseCockpit(user) && !this.context.offline &&
                
                    <Card variant="outlined" style={{marginBottom: 8, padding: 0}}>
                        <CardActionArea
                            style={{padding: 8}}
                            onClick={() => {
                                this.handleClose();
                                this.context.setActiveApp(COCKPIT_APP);
                                this.props.history.push(DASHBOARD_ROUTE);
                            }}>
                            <CardContent>

                                <CardMedia>
                                    <img src={cockpitLogo} style={{height: 25}} alt="Cockpit"/>
                                </CardMedia>
                                <Typography color="text.secondary" style={{marginTop: 4}}>
                                    <FormattedMessage id="app.menu.cockpit"/>
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                    </Card>

                }


                {mayUseOrder(user) && 
                
                    <Card variant="outlined" style={{marginBottom: 8, padding:0}}>
                        <CardActionArea
                            style={{padding: 8}}
                            onClick={() => {
                                this.handleClose();
                                this.context.setActiveApp(ORDER_APP);
                                this.props.history.push(ORDER_DASHBOARD_ROUTE);
                            }}>
                            <CardContent>

                                <CardMedia>
                                    <img src={orderLogo} style={{height: 25}} alt="Order"/>
                                </CardMedia>
                                <Typography color="text.secondary" style={{marginTop: 4}}>
                                    <FormattedMessage id="app.menu.order"/>
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                    </Card>
                
                }

            </Card>
        );
    }

    render() {

        if (isOrderUser(this.context.currentUser) && !this.props.mobile) {
            return null;
        }

        return (
            <>

                <IconButton
                    style={{color: texisionBlue}}
                    aria-haspopup="true"
                    color="inherit"
                    onClick={(event) => this.handleClick(event)}>
                    <MenuIcon/>
                </IconButton>

                <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    style={{marginTop: 18}}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    transformOrigin={{vertical: 'top', horizontal: 'center'}}
                    onClose={() => this.handleClose()}>

                    {this.popoverContent()}                    

                </Popover>

            </>
        )
    }


}

export default withRouter(injectIntl(AppMenu));
