import React, {Component} from 'react';
import {Collapse, Grid, Icon, MenuItem, Typography} from "@material-ui/core";
import {formatPriceDifference} from "../../../util/Util";
import {EXPORT_TYPE_MERGE} from "../../../util/Constants";
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import {bodyBackgroundColor} from "../../../util/ColorTheme";

class BusinessUnitPrices extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            open: true
        }
    }

    tableTitleByText = (text) => {
        return (
            <MenuItem
                onClick={() => this.setState({open: !this.state.open})}
                style={{marginTop: 40, padding: 0, minWidth: 300 + this.props.offers.length*150}}
            >
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid
                        item
                        xs
                        className="tableTitle"
                        style={{backgroundColor: bodyBackgroundColor}}
                    >
                        <Typography variant="h3">
                            {text}
                        </Typography>
                    </Grid>
                    <Grid item className="caretIcon" style={{backgroundColor: bodyBackgroundColor}}>
                        <Icon
                            className={this.state.open
                                ? "icon-caret-up-large"
                                : "icon-caret-down-large"}
                            style={{fontSize: "10pt", marginRight: 10}}
                        />
                    </Grid>
                </Grid>
            </MenuItem>
        );
    }

    getPriceUnit = (offerId, unitOrProjectId, previousPrice) => {
        if (previousPrice && this.props.round === 2) {
            return this.props.previousPriceOfferMap.get(offerId)?.priceUnitMap[unitOrProjectId];
        } else {
            return this.props.priceOffers.find(p => p.offerId === offerId)
                ?.priceUnitMap[unitOrProjectId];
        }
    }

    getPriceForUnit = (offerId, unitId)=> {
        let previousUnit = this.getPriceUnit(offerId, unitId, true);
        let currentUnit = this.getPriceUnit(offerId, unitId, false);

        const previousPrice = this.props.showBrutto
            ? previousUnit?.sumGross
            : previousUnit?.sumNet;
        const currentPrice = this.props.showBrutto
            ? currentUnit?.sumGross
            : currentUnit?.sumNet;
        return formatPriceDifference(this.props.round, previousPrice, currentPrice);
    }
    
    render() {
        const priceOffers = this.props.priceOffers;
        if (!priceOffers || priceOffers.length === 0) {
            return null;
        }
        const merged = this.props.priceOffers[0].exportType === EXPORT_TYPE_MERGE;
        if (merged) {
            return null;
        }
        const currency = this.props.project.currency;
        const priceType = this.props.intl.formatMessage({id: "offers.comparison."
                + (this.props.showBrutto ? "gross" : "net")});
        return (
            <>
                <Grid
                    item
                    xs={12}
                    style={{minWidth: 380+this.props.offers.length*200}}>
                    {this.tableTitleByText(
                        this.props.intl.formatMessage(
                            {id: "offers.comparison.unitPrices.title"}
                        ) + " (" + (currency ? (currency + ", ") : "")
                        + priceType + ")",
                        "unitPrices"
                    )}
                </Grid>
                
                <Grid item xs={12}>
                    <Collapse in={this.state.open}>

                        {this.props.project.businessUnits.units.map(u =>
                            <Grid item xs={12} key={u.id+"unitprice"}>

                                <Grid container wrap="nowrap" alignItems="center">

                                    {this.props.firstColumnCell(this.props.titleByText(u.name))}

                                    {this.props.offers
                                        .map(o => this.props.cell(
                                            u.id+"unit-" +o.id+"unitPrice",
                                            this.getPriceForUnit(o.id, u.id),
                                            true
                                        ))
                                    }

                                </Grid>
                            </Grid>)}

                    </Collapse>
                </Grid>
            </>
        );
    }
}

BusinessUnitPrices.propTypes = {
    cell: PropTypes.func,
    firstColumnCell: PropTypes.func,
    offers: PropTypes.array,
    priceOffers: PropTypes.array,
    project: PropTypes.object,
    showBrutto: PropTypes.bool,
    titleByText: PropTypes.func
}

export default injectIntl(BusinessUnitPrices);
