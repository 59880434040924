import { Button, Grid, Icon, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Avatar } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { bodyBackgroundColor, texisionGreen, white, warningColor, headerColor } from "../../../util/ColorTheme";
import { DIALOG_TYPE_INFO, RATING_CATEGORY, OFFER_STATUS_ACCEPTED } from "../../../util/Constants";
import { isFullyEvaluated } from "../../../util/ProcedureUtil";

import TexisionDialog from '../../../components/uiLibrary/TexisionDialog';
import { cutName, formatDate } from "../../../util/DocumentUtil";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Header } from "../../uiLibrary/Header";
import {GeneralContext} from "../../contexts/GeneralContext";
import OfferRatingSelection from "./OfferRatingSelection";
import {TextField} from "@mui/material";

const OfferRatingTable = ({currentRatings, onSave, onDownload, onExport, offers, ratingCriteria, isPreviousRound}) => {

    const [ratings, setRatings] = useState(currentRatings);
    const [hasChanges, setHasChanges] = useState(false);
    const [openedCategories, setOpenedCategories] = useState([]);
    // following state variables for downloading documents for a specific offer and rating category
    const [offerId, setOfferId] = useState(null);
    const [ratingCategory, setRatingCategory] = useState(null);
    const [isExporting, setIsExporting] = useState(false);

    const context = useContext(GeneralContext);
    const intl = useIntl();

    useEffect(() => {
        setRatings(currentRatings);
    }, [currentRatings])

    const onValueChange = (offerId, ratingCategory, ratingArgument, points) => {
        const offerRating = ratings.offerEvaluationMap[offerId][ratingCategory][ratingArgument];
        if (offerRating.value === points) {
            offerRating.value = 0;
        } else {
            offerRating.value = points;
        }
        setRatings({...ratings});
        setHasChanges(true);
        context.setUnsavedChanges(true);
    }

    const getReason = (ratingCategory, ratingArgument) => {
        if (ratings.reasonMap && Object.keys(ratings.reasonMap)?.length) {
            const argumentsMap = ratings.reasonMap[ratingCategory];
            if (!argumentsMap || !Object.keys(argumentsMap)?.length) {
                return "";
            } else {
                return argumentsMap[ratingArgument]?.reason ?? "";
            }
        } else {
            return "";
        }
    }

    const updateOfferRatingReason = (ratingCategory, ratingArgument, reason) => {
        const newRatings = ratings;
        let reasonMap = {};
        if (newRatings.reasonMap && Object.keys(newRatings.reasonMap)?.length) {
            reasonMap = newRatings.reasonMap;
        }
        const categoryMap = reasonMap[ratingCategory];
        if (!categoryMap) {
            const argumentMap = {};
            argumentMap[ratingArgument] = {ratingCategory, ratingArgument, reason};
            reasonMap[ratingCategory] = argumentMap;
        } else {
            categoryMap[ratingArgument] = {ratingCategory, ratingArgument, reason};
        }
        newRatings.reasonMap = reasonMap;
        setRatings({...newRatings});
        setHasChanges(true);
        context.setUnsavedChanges(true);
    }

    const onCollapse = (ratingCategory) => {
        if (openedCategories.includes(ratingCategory)) {
            setOpenedCategories([...openedCategories.filter(c => c !== ratingCategory)]);
        } else {
            setOpenedCategories([...openedCategories, ratingCategory]);
        }
    }

    const ratingStatusIcon = (offerId) => {
        const complete = isFullyEvaluated(ratings?.offerEvaluationMap, offerId);
        const backgroundColor = complete ? "transparent" : warningColor;
        const color = complete ? texisionGreen : white;
        const StatusIcon = complete ? CheckCircleRoundedIcon : MoreHorizRoundedIcon;
        return <Tooltip title={complete ? "" : intl.formatMessage({id: "offerRating.notFullyEvaluated.tooltip"})}>
            <div>
                <Avatar style={{backgroundColor: backgroundColor, height: 16, width: 16, marginRight: 5, marginLeft: 15}}>
                    <StatusIcon style={{color: color, height: 16, width: 16}}/>
                </Avatar>
            </div>
        </Tooltip>;
    }

    const ratedOfferIds = ratings?.offerEvaluationMap ? Object.keys(ratings.offerEvaluationMap).map(id => parseInt(id)) : [];
    const hasWinner = offers && offers.filter(o => o.status === OFFER_STATUS_ACCEPTED).length > 0;

    let titleId;
    let subtitleId;
    if (hasWinner) {
        titleId = "offerRating.firstRound.title";
        subtitleId = "offerRating.firstRound.hasWinner.subtitle";
    } else if (isPreviousRound) {
        titleId = "offerRating.firstRound.title";
        subtitleId = "offerRating.firstRound.previous.subtitle";
    } else {
        titleId = "offerRating.firstRound.title";
        subtitleId = "offerRating.firstRound.current.subtitle";
    }

    const disableEditing = hasWinner || isPreviousRound;

    const numberOfOffers = offers?.filter(offer => ratedOfferIds.includes(offer.id))?.length ?? 0;

    return <React.Fragment>

        <TexisionDialog
            type={DIALOG_TYPE_INFO}
            open={offerId !== null && offerId !== undefined}
            titleId="offerRating.multipleDocuments.title"
            subtitleId="offerRating.multipleDocuments.subtitle"
            replacements={{ratingCategory: ratingCategory ? intl.formatMessage({id: "constants.RatingCategory." + ratingCategory}) : ""}}
            content={offerId !== null && offerId !== undefined && ratingCategory && <React.Fragment>
                <Grid container alignItems="center">
                    {offers.find(o => o.id === offerId).ratingDocuments[ratingCategory].map(document => {
                    return <React.Fragment key={document.filename}>
                        <Grid item>
                            <Grid container wrap="nowrap" alignItems="center">
                                <Grid item>
                                    <IconButton
                                        onClick={() => {onDownload(document)}}>
                                        <Icon>download</Icon>
                                    </IconButton>
                                </Grid>
                                <Grid item style={{marginRight: 30}}>
                                    {cutName(intl, document.filename) + ", " + formatDate(intl, document.lastModified)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>})}
                </Grid>
            </React.Fragment>}
            cancelId="commons.close.label"
            onCancel={() => {
                setOfferId(null);
                setRatingCategory(null);
            }}/>

        <Grid container spacing={2}>
            <Grid item xs>
                <Header titleId={titleId} subtitleId={subtitleId}/>
            </Grid>
            {!disableEditing && <Grid item>
                <Button 
                    variant="contained" 
                    color="primary" 
                    style={{marginBottom: 40}} 
                    disabled={!hasChanges}
                    onClick={() => {
                        onSave(ratings);
                        setHasChanges(false);
                        context.setUnsavedChanges(false);
                    }}>
                    <FormattedMessage id="commons.save.button"/>
                </Button>
            </Grid>}
            <Grid item>
                <Tooltip title={hasChanges ? intl.formatMessage({id: "offerRating.export.button.disabled.tooltip"}) : ""}>
                    <div>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={hasChanges || isExporting}
                            style={{marginBottom: 40}}
                            onClick={async() => {
                                setIsExporting(true);
                                await onExport();
                                setIsExporting(false);
                            }}>
                            <FormattedMessage id="commons.export.excel"/>
                        </Button>
                    </div>
                </Tooltip>
            </Grid>
        </Grid>

        <TableContainer component={Paper} style={{overflowX: "scroll"}}>
            <Table aria-label="offer-rating-table">

                <colgroup>
                    <col style={{width: "10%"}}/>
                    {offers.filter(offer => ratedOfferIds.includes(offer.id)).map(o => <col style={{width: 60/numberOfOffers + "%"}}/>)}
                    <col style={{width: "30%"}}/>
                </colgroup>

                <TableHead style={{backgroundColor: headerColor}}>
                    <TableRow>
                        <TableCell key={"category-column"}>
                            <div style={{fontWeight: "bolder", color: white}}>
                                <FormattedMessage id="offerRating.table.category.title"/>
                            </div>
                        </TableCell>
                        {offers
                        .filter(offer => ratedOfferIds.includes(offer.id))
                        .sort((a,b) => a.id - b.id)
                        .map(offer => <TableCell key={offer.id+"offer"}>
                            <Grid container alignItems="center" wrap="nowrap">
                                <Grid item>
                                    {ratingStatusIcon(offer.id)}
                                </Grid>
                                <Grid item>
                                    <div style={{fontWeight: "bolder", color: white}}>
                                        {offer.user.company}
                                    </div>
                                </Grid>
                            </Grid>
                        </TableCell>)}
                        <TableCell key={"rating-notes"}>
                            <div style={{fontWeight: "bolder", color: white}}>
                                <FormattedMessage id="offerRating.table.notes.title"/>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {ratingCriteria ? Object.values(ratingCriteria)
                    .filter(c => c.ratingCategory !== RATING_CATEGORY.PRICE)
                    .sort((a,b) => intl.formatMessage({id: "constants.RatingCategory." + a.ratingCategory})
                    .localeCompare(intl.formatMessage({id: "constants.RatingCategory." + b.ratingCategory})))
                    .map(c => <React.Fragment key={c.ratingCategory+"category"}>

                        <TableRow style={{backgroundColor: bodyBackgroundColor}}>
                            <TableCell style={{fontWeight: "bolder"}}>
                                <Grid container alignItems="center" wrap="nowrap">
                                    <Grid item>
                                        <IconButton onClick={() => onCollapse(c.ratingCategory)}>
                                            <Icon>{openedCategories.includes(c.ratingCategory) 
                                            ? <ExpandLessIcon/> : <ExpandMoreIcon/>}</Icon>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <FormattedMessage id={"constants.RatingCategory." + c.ratingCategory}/>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            {offers
                            .filter(offer => ratedOfferIds.includes(offer.id))
                            .sort((a,b) => a.id - b.id)
                            .map(o => <TableCell key={o.id+"documents"+c.ratingCategory}>
                                <Tooltip title={<FormattedMessage id="offerRating.download.tooltip" values={{
                                    company: o.user.company, ratingCategory: intl.formatMessage({id: "constants.RatingCategory." + c.ratingCategory})
                                }}/>}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => {
                                            if (o.ratingDocuments && o.ratingDocuments[c.ratingCategory] 
                                                && o.ratingDocuments[c.ratingCategory].length > 1) {
                                                setOfferId(o.id);
                                                setRatingCategory(c.ratingCategory);
                                            } else if (o.ratingDocuments && o.ratingDocuments[c.ratingCategory]) {
                                                onDownload(o.ratingDocuments[c.ratingCategory][0]);
                                            }
                                        }}>
                                        <FormattedMessage id="offerRating.download.button"/>
                                    </Button>
                                </Tooltip>
                            </TableCell>)}
                            <TableCell/>
                        </TableRow>

                        {openedCategories.includes(c.ratingCategory) && <React.Fragment>

                            {c.ratingArguments.map(argument => <TableRow key={argument+c.ratingCategory}>
                                <TableCell>{argument}</TableCell>
                                {offers
                                .filter(offer => ratedOfferIds.includes(offer.id))
                                .sort((a,b) => a.id - b.id)
                                .map(offer => <TableCell key={offer.id+argument}>
                                    <OfferRatingSelection
                                        disabled={disableEditing}
                                        rowKey={"rating-selection-" + c.ratingCategory + "-" + argument}
                                        value={ratings.offerEvaluationMap[offer.id][c.ratingCategory][argument].value}
                                        onChange={(newValue) => onValueChange(offer.id, c.ratingCategory, argument, newValue)}/>
                                </TableCell>)}
                                <TableCell key={"notes-" + c.ratingCategory + "-" + argument}>
                                    <TextField
                                        multiline={true}
                                        minRows={2}
                                        maxRows={5}
                                        fullWidth={true}
                                        disabled={disableEditing}
                                        inputProps={{style: {fontSize: 12}}}
                                        InputLabelProps={{style: {fontSize: 12}}}
                                        placeholder={intl.formatMessage({id: "offerRating.table.notes.title"})}
                                        onChange={(e) => updateOfferRatingReason(c.ratingCategory, argument, e.target.value)}
                                        value={getReason(c.ratingCategory, argument)}/>
                                </TableCell>
                            </TableRow>)}


                        </React.Fragment>}

                    </React.Fragment>) : null}
                </TableBody>
            </Table>
        </TableContainer>
    </React.Fragment>;
}

export {
    OfferRatingTable
}
