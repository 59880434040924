import {CHART_COLOR_PALETTE} from "../../../../util/ColorTheme";
import {CATEGORY_FLAT_LINEN, CATEGORY_WORK_WEAR} from "../../../../util/Constants";
import {getExtrapolatedDataPoints} from "../../../../services/StatisticsService";

export const CategoryStatisticsBarData = ({
   priceOfferMap,
   allSelected,
   workWearSelected,
   flatLinenSelected,
   customerArticlesSelected,
   residentsLaundrySelected,
   operatingResourcesSelected,
   selectedTab,
   showNet,
   minDate
}) => {

    if (!priceOfferMap?.size) {
        return [];
    }

    const tabKey = selectedTab === "price" ? "p" : "a";

    const extrapolate = (data) => {
        let allData = [...data];
        for (let a of [
            {selected: allSelected && showPrices, data, key: "all", color: colorPalette[0], nameId: "cockpit.statistics.categories.fullPrices"},
            {selected: workWearSelected, data, key: "ww", color: colorPalette[1], nameId: "constants.Category.WORKWEAR"},
            {selected: flatLinenSelected, data, key: "fl", color: colorPalette[2], nameId: "constants.Category.FLAT_LINEN"},
            {selected: customerArticlesSelected, data, key: "ca", color: colorPalette[3], nameId: "constants.PRICE_TYPE.CUSTOMER_ARTICLE"},
            {selected: residentsLaundrySelected, data, key: "rl", color: colorPalette[4], nameId: "constants.PRICE_TYPE.RESIDENTS_LAUNDRY"},
            {selected: operatingResourcesSelected, data, key: "or", color: colorPalette[5], nameId: "constants.PRICE_TYPE.OPERATING_RESOURCE"}
        ]) {
            const extrapolation = getExtrapolatedDataPoints(a.selected, a.data, a.key, a.color, a.nameId, tabKey);
            allData.push(...extrapolation);
        }
        let uniqueData = [];
        for (let time of new Set(allData.map(d => d.time))) {
            let uniqueDataPoint = {time: time};
            for (let dataPoint of allData.filter(d => d.time === time)) {
                uniqueDataPoint = {...uniqueDataPoint, ...dataPoint};
            }
            uniqueData.push(uniqueDataPoint);
        }
        return uniqueData.sort((d1, d2) => d1.time - d2.time);
    }

    const showPrices = selectedTab === "price";

    let data = [];
    let keys = new Set();
    const colorPalette = CHART_COLOR_PALETTE;

    for (const [timeString, priceOffer] of Array.from(priceOfferMap.entries())
        .sort(([timeString1, priceOffer1],[timeString2, priceOffer2]) => parseInt(timeString2) - parseInt(timeString1))) {

        const priceUnitMap = priceOffer.priceUnitMap;

        if (!priceUnitMap || !timeString) {
            continue;
        }

        let time = parseInt(timeString);

        if (time < minDate && !data.find(d => new Date(d.time).getMonth() === new Date(minDate).getMonth()
            && new Date(d.time).getFullYear() === new Date(minDate).getFullYear())) {
            time = minDate;
        } else if (time < minDate) {
            continue;
        }

        const priceUnit = Array.from(Object.values(priceUnitMap))[0];

        const dataPoints = {time: time};

        if (allSelected && showPrices) {
            const price = showNet ? priceOffer.sumNet : priceOffer.sumGross;
            dataPoints["all" + tabKey] = price / 12.0;
            keys.add("all" + tabKey);
        }

        if (workWearSelected) {
            const workWearMap = priceUnit.articleGroupMap[CATEGORY_WORK_WEAR];
            let price = 0.0;
            let amount = 0;
            if (workWearMap) {
                Object.values(workWearMap).forEach(priceGroup => {
                    price += (showNet ? priceGroup.sumNet : priceGroup.sumGross);
                    amount += priceGroup.sumAmount;
                });
            }
            dataPoints["ww" + tabKey] = showPrices ? price / 12.0 : amount;
            keys.add("ww" + tabKey);
        }


        if (flatLinenSelected) {
            const flatLinenMap = priceUnit.articleGroupMap[CATEGORY_FLAT_LINEN];
            let price = 0.0;
            let amount = 0;
            if (flatLinenMap) {
                Object.values(flatLinenMap).forEach(priceGroup => {
                    price += (showNet ? priceGroup.sumNet : priceGroup.sumGross)
                    amount += priceGroup.sumAmount;
                });
            }
            dataPoints["fl" + tabKey] = showPrices ? price / 12.0 : amount;
            keys.add("fl" + tabKey);
        }

        if (customerArticlesSelected) {
            const price = (showNet
                ? priceUnit.customerArticleGroup?.sumNet : priceUnit.customerArticleGroup?.sumGross) ?? 0.0;
            dataPoints["ca" + tabKey] = showPrices ? price / 12.0 : (priceUnit.customerArticleGroup?.sumAmount ?? 0);
            keys.add("ca" + tabKey);
        }

        if (residentsLaundrySelected) {
            const price = (showNet
                ? priceUnit.residentsLaundryGroup?.sumNet : priceUnit.residentsLaundryGroup?.sumGross) ?? 0.0;
            dataPoints["rl" + tabKey] = showPrices ? price/12.0 : (priceUnit.residentsLaundryGroup?.sumAmount ?? 0);
            keys.add("rl" + tabKey);
        }

        if (operatingResourcesSelected) {
            const price = (showNet
                ? priceUnit.operatingResourceGroup?.sumNet : priceUnit.operatingResourceGroup?.sumGross) ?? 0.0;
            dataPoints["or" + tabKey] = showPrices ? price/12.0 : (priceUnit.operatingResourceGroup?.sumAmount ?? 0);
            keys.add("or" + tabKey);
        }

        data.push(dataPoints);
    }

    return [extrapolate(data), keys];
}
