import {isBidder} from "../../services/UserService";
import {Button, Card, CardContent, Grid, TextField} from "@material-ui/core";
import React from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import {FormattedMessage, injectIntl} from "react-intl";
import PropTypes from 'prop-types';
import MessageFilePreview from "./MessageFilePreview";
import {defaultTextColor, texisionRed} from "../../util/ColorTheme";
import {Tooltip} from "@mui/material";

class MessageCreateForm extends React.Component {

    static contextType = GeneralContext;

    maxSubjectLength = 250;
    maxTextLength = 5000;

    characterLimit = (text, limit) => {
        const length = text?.length ?? 0;
        const color = length > limit ? texisionRed : defaultTextColor;
        return <div style={{color: color}}>
            ({length} / {limit} {this.props.intl.formatMessage({ id : "commons.character" })})
        </div>
    }

    isValidMessage = () => {
        return !!this.props.text &&
            !!this.props.subject &&
            !this.props.isUploading &&
            this.props.text?.length <= this.maxTextLength &&
            this.props.subject?.length <= this.maxSubjectLength;
    }

    render() {
        const files = this.props.files;
        const disabled = !this.isValidMessage();
        return (
            <Card className="messageCard">

                <CardContent>

                    <Grid container alignItems="center">

                        <Grid item xs={12}>
                            <Grid container wrap="nowrap" alignItems="center" spacing={2}>

                                <Grid item>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => this.props.onCancel()}>
                                        {this.props.intl.formatMessage({id: "commons.cancel.button"})}
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Tooltip
                                        title={disabled && this.props.intl.formatMessage({id: "bidderCommunication.save.button.disabled"})}>
                                        <div style={{width: '100%'}}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={disabled}
                                                onClick={() => this.props.onSave()}>
                                                {this.props.intl.formatMessage({id: "commons.save.button"})}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                        </Grid>

                        {<Grid item xs={12} className="subjectTextField">
                            <TextField
                                multiline
                                label={this.props.intl.formatMessage({id: "bidderCommunication.subject.label"})}
                                value={this.props.subject}
                                style={{width: "50%"}}
                                onChange={(e) => this.props.onSubjectChange(e.target.value)}/>
                                {this.characterLimit(this.props.subject, this.maxSubjectLength)}
                        </Grid>}

                        <Grid item xs={12} className="newTextField">
                            <TextField
                                multiline
                                label={this.props.intl.formatMessage({id: "bidderCommunication." + (isBidder(this.context.currentUser) ? "question" : "information") + ".label"})}
                                value={this.props.text}
                                style={{width: "100%"}}
                                onChange={(e) => this.props.onTextChange(e.target.value)}/>
                                {this.characterLimit(this.props.text, this.maxTextLength)}
                        </Grid>

                        {!!files?.length && <Grid container spacing={2}>
                            {files.map((file) => <MessageFilePreview file={file} isCreating={true} deleteFile={this.props.deleteFile}/>)}
                        </Grid>}

                        {<Button
                            color="primary"
                            component="label">
                            <FormattedMessage id="bidderCommunication.document.select.button"/>
                            <input
                                type="file"
                                id="bidderCommunication-document-input"
                                accept={this.props.acceptedFileTypes}
                                style={{display: "none"}}
                                onChange={(e) => this.props.selectFile(e.target.files[0], true)}
                                onClick={(event) => {event.target.value = null;}}/>
                        </Button>}

                    </Grid>

                </CardContent>

            </Card>
        );
    }
}

MessageCreateForm.propTypes = {
    acceptedFileTypes: PropTypes.string.isRequired,
    files: PropTypes.array.isRequired,
    text: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isUploading: PropTypes.bool.isRequired,
    onSubjectChange: PropTypes.func.isRequired,
    onTextChange: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    selectFile: PropTypes.func.isRequired
};

export default injectIntl(MessageCreateForm);
