import React, {Component} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    Typography
} from '@material-ui/core';
import {FormattedMessage, injectIntl} from 'react-intl';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import '../../apps/App.css';
import {GeneralContext} from "../../contexts/GeneralContext";
import Paper from "@material-ui/core/Paper";
import {bodyBackgroundColor} from "../../../util/ColorTheme";
import {enhanceAssignedResourcesWithMasterData} from "../../../util/Util";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {withSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import {RESOURCE_OFFSETTINGS} from "../../../util/Constants";
import Popover from "@material-ui/core/Popover";
import {
    createBusinessUnitResource,
    getAllResources,
    getAssignedBusinessUnitResourcesForUnit,
    removeAssignedResource,
    updateUnitResourceCount
} from "../../../services/OperatingResourceService";
import {getBusinessUnitVersion} from "../../../services/BusinessUnitService";
import OperatingResourcesSubCategory from "./OperatingResourcesSubCategory";
import {featuresText} from "../../../util/Util";


class OperatingResources extends Component {

    static contextType = GeneralContext;

    state = {
        anchorEl: null,
        operatingResources: [],
        assignedBusinessUnitResources: [],
        businessUnitVersion: 0,
        isLoading: true,
        showAddDialog: false,
        resourceToAdd: null,
        amountToAdd: 1,
        unitResourceIdToEdit: null,
        amountToEdit: null
    }

    async componentDidMount() {
        await Promise.all([
            getAllResources(this.context, this.props),
            getAssignedBusinessUnitResourcesForUnit(this.context, this.props),
            getBusinessUnitVersion(this.context, this.props)
        ]).then(([operatingResources, assignedBusinessUnitResources, businessUnitVersion]) => {
            this.setState({assignedBusinessUnitResources, businessUnitVersion, isLoading: false, operatingResources});
        });
    }

    handleDeleteOperatingResource = async(unitResourceId) => {
        const success = await removeAssignedResource(this.context, this.props, unitResourceId);
        if (success) {
            await Promise.all([
                getAssignedBusinessUnitResourcesForUnit(this.context, this.props),
                getBusinessUnitVersion(this.context, this.props)
            ]).then(([assignedBusinessUnitResources, businessUnitVersion]) => {
                this.setState({assignedBusinessUnitResources, businessUnitVersion});
            });
        }
    }

    handleUpdateCount = async(oldResource, newCount) => {
        let oldCount = oldResource.count;
        if (parseInt(newCount) === 0) {
            // Setting an amount of 0 will result in deleting the operating resource
            await this.handleDeleteOperatingResource(this.state.unitResourceIdToEdit);
        } else {
            let delta = Math.abs(oldCount - newCount);
            if (oldCount >= newCount) {
                // If the user has reduced the amount we need to set the correct algebraic sign
                delta *= -1;
            }
            const countVo = {delta: delta};
            const updatedResource = await updateUnitResourceCount(this.context, this.props, this.state.unitResourceIdToEdit,
                oldResource.version, countVo);
            if (updatedResource) {
                this.setState({
                    assignedBusinessUnitResources: await getAssignedBusinessUnitResourcesForUnit(this.context, this.props),
                    unitResourceIdToEdit: null,
                    amountToEdit: null
                });
            }
        }
    }

    handleAddOperatingResource = async(resourceId, amount) => {
        const unitResource = {
            version: 0,
            businessUnitId: this.context.appData.activeUnitId,
            operatingResourceId: resourceId,
            count: amount,
            resourceOffsetting: RESOURCE_OFFSETTINGS.NONE // Always send NONE to the server. The server will determine, if an offsetting was already set for this subcategory.
        }
        const createdUnitResource = await createBusinessUnitResource(this.context, this.props, unitResource);
        if (createdUnitResource) {
            await Promise.all([
                getBusinessUnitVersion(this.context, this.props, this.context.appData.activeUnitId),
                getAssignedBusinessUnitResourcesForUnit(this.context, this.props)
            ]).then(([businessUnitVersion, assignedBusinessUnitResources]) => {
                this.setState({assignedBusinessUnitResources, businessUnitVersion, showAddDialog: false, resourceToAdd: null, amountToAdd: 1, anchorEl: null});
            });
        }
    }

    actionButtons = (enhancedUnitResource) => {
        const isEditing = this.state.unitResourceIdToEdit || this.state.unitResourceIdToEdit === 0;
        return (
            <Grid container wrap="nowrap">

                {!isEditing && <Grid item>

                    <Tooltip title={this.props.intl.formatMessage({id: "operatingResources.table.editAmount"})}>
                        <IconButton disabled={this.props.readOnly} onClick={() =>
                            this.setState({amountToEdit: enhancedUnitResource.count, unitResourceIdToEdit: enhancedUnitResource.unitResourceId})}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>

                </Grid>}

                {isEditing && <Grid item>

                    <Tooltip title={this.props.intl.formatMessage({id: "commons.save.button"})}>
                        <IconButton
                            disabled={!this.state.amountToEdit && this.state.amountToEdit !== 0 && this.state.amountToEdit !== "0"}
                            onClick={() => this.handleUpdateCount(enhancedUnitResource, this.state.amountToEdit)}>
                            <CheckIcon/>
                        </IconButton>
                    </Tooltip>

                </Grid>}

                {isEditing && <Grid item>

                    <Tooltip title={this.props.intl.formatMessage({id: "commons.cancel.button"})}>
                        <IconButton onClick={() =>
                            this.setState({amountToEdit: null, unitResourceIdToEdit: null})}>
                            <ClearIcon/>
                        </IconButton>
                    </Tooltip>

                </Grid>}

                <Grid item>
                    <Tooltip title={this.props.intl.formatMessage({id: "commons.delete.button"})}>
                        <IconButton
                            color="error"
                            disabled={isEditing || this.props.readOnly}
                            onClick={() => this.handleDeleteOperatingResource(enhancedUnitResource.unitResourceId)}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }

    render() {
        if (this.state.isLoading) {
            return null;
        } else if (!this.state.operatingResources?.length) {
            return (
                <Typography variant="caption" component="span" style={{textAlign: "start"}}>
                    <FormattedMessage id="operatingResources.table.nodata"/>
                </Typography>
            );
        }

        const enhancedUnitResources = enhanceAssignedResourcesWithMasterData(this.state.operatingResources, this.state.assignedBusinessUnitResources);

        return (
            <>
                <Popover
                    id="add-resource-pop-over"
                    open={this.state.showAddDialog}
                    onClose={() => this.setState({showAddDialog: false, resourceToAdd: null, anchorEl: null})}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                    <Grid container spacing={2} style={{margin: 15}} alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="h4">
                                <FormattedMessage id="operatingResource.add.amount"/>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <input
                                style={{width: 50, height: 25}}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={this.state.amountToAdd}
                                onChange={(e) => this.setState({amountToAdd: e.target.value})}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="text"
                                disabled={!this.state.amountToAdd || parseInt(this.state.amountToAdd) < 1 || !this.state.resourceToAdd}
                                onClick={() => this.handleAddOperatingResource(this.state.resourceToAdd.id, parseInt(this.state.amountToAdd))}>
                                <FormattedMessage id="commons.add.button"/>
                            </Button>
                        </Grid>
                    </Grid>
                </Popover>

                {!!enhancedUnitResources?.length && <div style={{marginBottom: 40}}>

                    <Typography variant="h3" style={{marginBottom: 20}}>
                        <FormattedMessage id="operatingResources.assignedResources.title"/>
                    </Typography>


                    {[...new Set(enhancedUnitResources
                        ?.map(r => r.subCategory))]
                        .sort((a,b) => this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + a})
                            .localeCompare(this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + b})))
                        .map(subCategory => <Accordion elevation={0} key={"accordion-" + subCategory + "-added"}>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-label="Expand">

                            <Typography variant="h3" component="span">
                                {this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + subCategory})}
                            </Typography>

                        </AccordionSummary>

                        <AccordionDetails>

                            <TableContainer
                                component={Paper}
                                style={{overflowX: "scroll"}}>

                                <Table aria-label="operating-resource-table-added">

                                    <TableHead style={{backgroundColor: bodyBackgroundColor}}>

                                        <TableRow>
                                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.image"})}</TableCell>
                                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.name"})}</TableCell>
                                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.description"})}</TableCell>
                                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.features"})}</TableCell>
                                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.offsetting"})}</TableCell>
                                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.amount"})}</TableCell>
                                            <TableCell>{this.props.intl.formatMessage({id: "operatingResources.table.actions"})}</TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                        {enhancedUnitResources
                                            .filter(r => r.subCategory === subCategory)
                                            .sort((a,b) => a.name.localeCompare(b.name))
                                            .map(r =>

                                                <TableRow key={"assigned-" + r.unitResourceId}>

                                                    <TableCell component="th" scope="row">
                                                        {!!r.imageVos?.length && r.imageVos[0].image?.url && <img
                                                            alt="OperatingResourceImage"
                                                            src={r.imageVos[0].image?.url}
                                                            style={{height: 90, width: "auto"}}/>}
                                                    </TableCell>

                                                    <TableCell>{r.name}</TableCell>

                                                    <TableCell>{r.description}</TableCell>

                                                    <TableCell>
                                                        <Grid container direction="row" alignItems="flex-start" spacing={1}>
                                                            {featuresText(r.features)}
                                                        </Grid>
                                                    </TableCell>

                                                    <TableCell>
                                                        {this.props.intl.formatMessage({id: "constants.ResourceOffsetting." + r.resourceOffsetting})}
                                                    </TableCell>

                                                    <TableCell>
                                                        {r.unitResourceId === this.state.unitResourceIdToEdit
                                                            ? <input
                                                                style={{width: 50, height: 25}}
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                value={this.state.amountToEdit}
                                                                onChange={(e) => this.setState({amountToEdit: e.target.value})}/>
                                                            : r.count}
                                                    </TableCell>

                                                    <TableCell>{this.actionButtons(r)}</TableCell>

                                                </TableRow>
                                            )
                                        }

                                    </TableBody>

                                </Table>

                            </TableContainer>

                        </AccordionDetails>

                    </Accordion>)}

                </div>}

                <Typography variant="h3" style={{marginBottom: 20}}>
                    <FormattedMessage id={this.state.assignedBusinessUnitResources?.length
                        ? "operatingResources.moreResources.title" : "operatingResources.availableResources.title"}/>
                </Typography>

                {[...new Set(this.state.operatingResources
                    .filter(o => !enhancedUnitResources?.find(r => r.id === o.id))
                    .map(o => o.subCategory))]
                    .sort((a,b) => this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + a})
                        .localeCompare(this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + b})))
                    .map(subCategory => <Accordion elevation={0} key={"accordion-" + subCategory + "-available"}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-label="Expand">

                        <Typography variant="h3" component="span">
                            {this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + subCategory})}
                        </Typography>

                    </AccordionSummary>

                    <AccordionDetails>
                        <OperatingResourcesSubCategory
                            operatingResources={this.state.operatingResources
                                .filter(r => r.subCategory === subCategory && !enhancedUnitResources?.find(e => e.id === r.id))}
                            readOnly={this.props.readOnly}
                            onClick={(resource, target) => this.setState({showAddDialog: true, resourceToAdd: resource, anchorEl: target})}
                        />
                    </AccordionDetails>

                </Accordion>)}
            </>
        );
    }
}

export default injectIntl(withSnackbar(OperatingResources));
