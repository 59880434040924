import React, {Component} from 'react';

import {Grid, TextField, Button, Typography} from '@material-ui/core';

import {createSuccessMessage, createErrorMessage} from '../../util/Util';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withSnackbar} from 'notistack';
import {requestPasswordReset} from '../../services/BackendService';

import TexisionContainer from "../uiLibrary/TexisionContainer";
import {GeneralContext} from "../contexts/GeneralContext";


class ForgotPassword extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.state = {
            username: null,
            resetError: false,
            resetRequested: false
        }
    }

    handleSubmit = async(event) => {
        event.preventDefault();

        const response = await requestPasswordReset(this.state.username);

        if (response?.status === 200) {
            createSuccessMessage(this.props.intl.formatMessage({id: "forgotpassword.success"}), this.props);
            this.setState({loginError: false, resetRequested: true});

        } else if (response?.status === 401) {
            createErrorMessage(this.props.intl.formatMessage({id: "forgotpassword.error"}), this.props);

        } else if (response?.status === 403) {
            createErrorMessage(this.props.intl.formatMessage({id: "forgotpassword.notFound"}), this.props);

        } else if (response?.status === 423) {
            createErrorMessage(this.props.intl.formatMessage({id: "forgotpassword.locked"}), this.props);

        }
    }

    render() {

        return (
            <TexisionContainer showBackButton="true" content={

                <Grid container justifyContent="center">

                    <form onSubmit={this.handleSubmit}>

                        <Grid item xs={12}>

                            <Typography variant="h4">
                                <FormattedMessage id="forgotpassword.title.h1"/>
                            </Typography>

                            <Typography color="textSecondary" style={{marginBottom: "20px"}}>
                                <FormattedMessage id="forgotpassword.title.h2"/>
                            </Typography>

                        </Grid>

                        <Grid item xs={12}>
                            <TextField 
                                id="username_txf" 
                                label={this.props.intl.formatMessage({id: "entities.user.mail"})} 
                                variant="outlined" 
                                style={{marginTop: "2%", width: "100%"}}
                                onChange={(event) => this.setState({username: event.target.value})} />
                        </Grid>

                        <Grid item xs={12}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit" 
                                style={{marginTop: "20px"}}
                                disabled={!this.state.username || this.state.resetRequested}>
                                <FormattedMessage id="forgotpassword.button.label"/>
                            </Button>
                        </Grid>

                    </form>

                </Grid>

            }/>
        );
    }
}

export default injectIntl(withSnackbar(ForgotPassword));
