import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from 'react-intl';

import texisionLogo from '../../resources/texision_logo.svg';
import efre from '../../resources/efre.png';
import Footer from '../navigation/shared/Footer';
import {LOGIN_ROUTE} from '../../util/Constants';
import {Card, Grid, IconButton} from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import history from '../navigation/shared/History'
import {GeneralContext} from "../contexts/GeneralContext";
import MediaQuery from "react-responsive";
import { Tooltip } from '@mui/material';


class TexisionContainer extends Component {

    static contextType = GeneralContext;

    render() {
        return (
            <div className="contentAreaLoggedOut">

                <Grid container alignItems="flex-start" justifyContent='center' style={{textAlign: "center"}}>

                    <Grid item lg={6} style={{paddingRight: "5%", paddingTop: "5%", paddingLeft: "5%"}}>
                        <img 
                            src={texisionLogo} 
                            style={{paddingBottom: "4%", maxWidth: "30rem", width: "100%"}} 
                            alt="Texision"/>
                        <div 
                            style={{fontSize: '45px', fontWeight: "bold", marginBottom: "40px"}}>
                            <FormattedMessage id="texision.slogan"/>
                        </div>
                        <img
                            src={efre}
                            style={{paddingBottom: "4%", paddingTop: 30, maxWidth: "30rem", maxHeight: "20rem"}}
                            alt=""/>
                    </Grid>

                    <Grid item lg={6} xs={12}>

                        <Card 
                            elevation={0} 
                            square={true} 
                            style={{paddingTop: this.props.showBackButton ? "3%" : "10%", paddingBottom: "10%", paddingRight: "10%", paddingLeft: "10%"}}>

                            {this.props.showBackButton &&
                            
                                <Grid container justifyContent="flex-start">
                                    <Tooltip title={this.props.intl.formatMessage({id: "commons.backToLogin.button"})}>
                                        <IconButton 
                                            aria-haspopup="true" 
                                            onClick={() => history.push(LOGIN_ROUTE)} 
                                            color="inherit">
                                            <ArrowBackIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                            }

                            {this.props.content}

                        </Card>

                    </Grid>

                </Grid>

                <MediaQuery minHeight={500}>
                    <Footer sticky={true}/>
                </MediaQuery>

                <MediaQuery maxHeight={499}>
                    <Footer sticky={false}/>
                </MediaQuery>

            </div>
        );
    }
}

export default injectIntl(withRouter(TexisionContainer));
