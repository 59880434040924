import React, {Component} from "react";
import {Grid} from "@mui/material";
import {Typography} from "@material-ui/core";

class BidderSummary extends Component {

    bidderProperty = (text, variant, hideIfEmpty) => {
        return (
            <Grid
                item xs={12}
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}>
                <Typography variant={variant}>
                    {text ? text : (hideIfEmpty ? "" : "-")}
                </Typography>
            </Grid>
        );
    }

    render() {
        const userZipDistanceVo = this.props.userZipDistanceVo;
        if (!userZipDistanceVo?.userVo) {
            return null;
        }
        const user = userZipDistanceVo.userVo;
        let distanceText = "";
        if (userZipDistanceVo.zipDistanceVo?.sourceCoordinates) {
            const distance = userZipDistanceVo.zipDistanceVo.distanceInKiloMeters;
            if (distance === null || distance === undefined) {
                distanceText = this.props.intl.formatMessage({id: "bidderSelection.noZipCode"});
            } else if (distance <= 10) {
                distanceText = "< 10 km";
            } else {
                distanceText = distance + " km";
            }
        }
        return (
            <Grid
                container
                style={{
                    paddingLeft: 10,
                    paddingRight: 25,
                    paddingTop: 7,
                    paddingBottom: 7,
                    textAlign: "left"
                }}>
                {this.bidderProperty(user.company, "h4")}
                {this.bidderProperty(distanceText, null, true)}
                {this.bidderProperty(user.streetAndNumber)}
                {this.bidderProperty(user.zipCode + " " + user.city)}
                <Grid
                    item xs={12}
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}>
                    <a href={"mailto:" + user.email} onClick={(e) => e.stopPropagation()}>
                        {user.email}
                    </a>
                </Grid>
            </Grid>
        );
    }
}

export default BidderSummary;
