import React, {PureComponent} from 'react';
import {injectIntl} from 'react-intl';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import {renderVolumeType, renderFeatures} from '../../../util/TableUtil';
import {texisionFontColorDark, bodyBackgroundColor} from '../../../util/ColorTheme';
import {GeneralContext} from "../../contexts/GeneralContext";



class CustomerArticleTable extends PureComponent {

    static contextType = GeneralContext;

    render() {

        let emptyDataMessage = this.props.intl.formatMessage({id: "customerArticle.table.emptydata"});

        return(
            <MaterialTable
                columns={[
                    {title: this.props.intl.formatMessage({id: "customerArticle.table.header.description"}), field: "description"},
                    {title: this.props.intl.formatMessage({id: "customerArticle.table.header.volumeType"}), field: "volumeType", render: customerArticle => renderVolumeType(customerArticle, this.props.intl)},
                    {title: this.props.intl.formatMessage({id: "customerArticle.create.props.volume"}), field: "volume"},
                    {title: this.props.intl.formatMessage({id: "customerArticle.create.features.title"}), field: "features", render: customerArticle => renderFeatures(customerArticle.features, this.props.intl, "constants.ArticleFeatureType.")},
                ]}
                data={this.props.data}
                options={{
                    grouping: false,
                    draggable: false,
                    filtering: false,
                    search: false,
                    paging: false,
                    showTitle: false,
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontWeight: 'bold',
                        backgroundColor: bodyBackgroundColor,
                        color: texisionFontColorDark,
                    },
                    toolbar: false,
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: emptyDataMessage
                    },
                    header: {
                        actions: this.props.intl.formatMessage({id: "customerArticle.table.header.actions"})
                    }
                }}
                actions={[
                    {
                        icon: () => <EditIcon/>,
                        onClick: (event, rowData) => this.props.onEdit(rowData),
                        position: "row",
                        tooltip: this.props.intl.formatMessage({id: "customerArticle.table.edit"}),
                        disabled: this.props.isInEditMode || this.props.readOnly
                    },
                    {
                        tooltip: this.props.intl.formatMessage({id: "customerArticle.table.delete"}),
                        icon: 'delete',
                        onClick: (evt, rowData) => this.props.onDelete(rowData),
                        disabled: this.props.isInEditMode || this.props.readOnly
                    }
                ]}
                components={{
                    Container: props => (<Paper {...props} key={this.props.type} elevation={0}/>)}
                }
            />
        );
    }
}
export default injectIntl(CustomerArticleTable);
