import React, {Component} from 'react';
import {Typography, Accordion, AccordionSummary, AccordionDetails, LinearProgress, Box} from '@material-ui/core';
import {sortAsc, sortOperatingResource} from '../../../util/Util';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {FormattedMessage, injectIntl} from 'react-intl';
import {withSnackbar} from 'notistack';

import '../../apps/App.css';

import OperatingResourceElement from '../../assortment/operatingResource/OperatingResourceElement';
import {GeneralContext} from "../../contexts/GeneralContext";
import { withRouter } from 'react-router-dom';
import {getAllResources} from "../../../services/OperatingResourceService";


class OperatingResourceOverview extends Component {

    static contextType = GeneralContext;

    state = {
        allOperatingResources: [],
        loading: true
    }

    async componentDidMount() {
        await this.loadAllOperatingResources();
    }

    loadAllOperatingResources = async() => {
        this.setState({allOperatingResources: await getAllResources(this.context, this.props), loading: false});
    }

    render() {

        let elementsToDisplay = [];
        if (this.state.loading) {
            elementsToDisplay.push(
                <React.Fragment key={1}>
                    <Typography style={{marginBottom: "10px"}} color="textSecondary" component="span">
                        <FormattedMessage id="operatingResources.overview.loading"/>
                    </Typography>
                    <LinearProgress variant="indeterminate" style={{marginTop: "10px"}} />
                </React.Fragment>
            );
        } else {

            let categoryMap = new Map();
            this.state.allOperatingResources.forEach(resource => {

                let subCategoryMap = categoryMap.get(resource.category);
                if (!subCategoryMap) {
                    let subCategoryMap = new Map();

                    let resourceList = [];
                    resourceList.push(resource);
                    subCategoryMap.set(resource.subCategory, resourceList);
                    categoryMap.set(resource.category, subCategoryMap);
                } else {

                    let resourceList = subCategoryMap.get(resource.subCategory);
                    if (!resourceList) {
                        resourceList = [];
                    }
                    resourceList.push(resource);
                    subCategoryMap.set(resource.subCategory, resourceList);
                }
            });

            for (let subCategoryMap of categoryMap.values()) {

                let subCategoryMapSorted = new Map([...subCategoryMap.entries()].sort((a, b) =>
                    sortAsc(this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + a[0]}),
                        this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + b[0]}))));

                for (let [subCategory, resourceList] of subCategoryMapSorted) {

                    resourceList.sort((a, b) => sortOperatingResource(a, b));
                    elementsToDisplay.push(
                        <React.Fragment key={subCategory}>

                            <Accordion elevation={0}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-label="Expand"
                                    aria-controls="additional-actions1-content"
                                    id="additional-actions1-header">

                                    <Typography variant="h3" component="span">
                                        {this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + subCategory})}
                                    </Typography>

                                </AccordionSummary>

                                <AccordionDetails>
                                    <ul style={{listStyleType: "none", width: '100%', paddingLeft: 0, marginTop: 0}}>
                                        {resourceList.map(resource => {
                                            return (
                                                <li key={resource.id}>
                                                    <OperatingResourceElement operatingResource={resource} onDelete={() => this.loadAllOperatingResources()}/>
                                                </li>
                                            )})
                                        }
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </React.Fragment>
                    );
                }
            }
        }

        return (
            <div>
                <Box className="accordionContainer">

                    <Typography variant="h2">
                        <FormattedMessage id="operatingResources.navbar.overview"/>
                    </Typography>

                    <Typography variant="subtitle2">
                        <FormattedMessage id="operatingResources.overview.h2"/>
                    </Typography>

                    {elementsToDisplay}
                </Box>
            </div>
        )
    }
}
export default injectIntl(withSnackbar(withRouter(OperatingResourceOverview)));
