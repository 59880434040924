import React, { Component } from "react";
import { GeneralContext } from "../contexts/GeneralContext";
import { Button, Card, CardContent, Grid, InputAdornment, TextField, Tooltip, Typography } from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {putAsync} from "../../services/BackendService";
import {getActiveProjectByOperationId} from "../../services/ProjectService";
import { createErrorMessage, createSuccessMessage } from "../../util/Util";

import "../../css/BidderProjectDetails.css";
import {offerDisabledTooltip, offerSubmittedTooltip} from "../../util/ProcedureUtil";
import { withSnackbar } from "notistack";


class PriceSheetTaxes extends Component {
    
    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {taxes: this.props.offer?.valueAddedTax};
    }

    componentDidUpdate() {
        if (this.props.offer && this.state.taxes !== null && this.state.taxes !== undefined 
            && this.props.offer.valueAddedTax !== parseInt(this.state.taxes)) {
            this.context.setUnsavedChanges(true);
        }
    }

    updateTaxes = async() => {
        this.props.offer.valueAddedTax = this.state.taxes;
        let response = await putAsync("/offer", this.props.offer);
        if (response?.status === 200) {
            createSuccessMessage(this.props.intl.formatMessage({id: "bidder.priceSheet.updateTaxes.successMessage"}), this.props);
            await this.props.reloadData();
            this.context.setUnsavedChanges(false);
        } else if ([401, 403].includes(response?.status)) {
            this.context.logout();
        } else if (response?.status === 405) {
            if (this.props.reloadProject) {
                this.props.reloadProject();
            }
            if (!this.props.notFound) {
                createErrorMessage(this.props.intl.formatMessage({id: "bidder.priceSheet.updateTaxes.deadlineOverMessage"}), this.props);
            }
        } else {
            if (this.props.reloadProject) {
                this.props.reloadProject();
            }
            if (!this.props.notFound) {
                createErrorMessage(this.props.intl.formatMessage({id: "bidder.priceSheet.updateTaxes.errorMessage"}), this.props);
            }
        }
    }

    handleTaxesChanged = (newValue) => {
        const positivNumberRegex = /^[0-9\b]+$/;
        if (newValue === "" || positivNumberRegex.test(newValue)) {
            this.setState({taxes: newValue});
        }
    }

    render() {

        const { project, offer } = this.props;
        const formatMessage = this.props.intl.formatMessage;
        let activeProject = getActiveProjectByOperationId(this.context, project.operationId);
        let isPrevious = activeProject?.id !== project.id;
        const disabledTooltip = offerDisabledTooltip(project, offer, isPrevious, formatMessage)
            || offerSubmittedTooltip(offer, formatMessage);

        const tooltip = disabledTooltip ? disabledTooltip : "";

        return <Card style={{marginBottom: 20}}>
            <CardContent>
                <Grid container justifyContent="space-between" wrap="nowrap">
                    <Grid item xs>
                        <Typography variant="h3">
                            <FormattedMessage id="bidder.priceSheet.setTaxes.title"/>
                        </Typography>
                        <Typography variant="subtitle2">
                            <FormattedMessage id="bidder.priceSheet.setTaxes.subtitle"/>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title={tooltip}>
                            <div>
                                <Button 
                                    variant="contained" 
                                    style={{marginLeft: 10}} 
                                    color="primary" 
                                    onClick={() => this.updateTaxes(offer)}
                                    disabled={!this.state.taxes 
                                        || parseInt(this.state.taxes) === offer.valueAddedTax 
                                        || !!disabledTooltip}>
                                    <FormattedMessage id="bidder.priceSheet.setTaxes.button"/>
                                </Button>
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Tooltip title={tooltip}>
                    <div>
                        <TextField
                            id="taxes" 
                            label={formatMessage({id: "bidder.priceSheet.taxesTextField.label"})} 
                            variant="filled"
                            InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                            value={this.state.taxes}
                            disabled={!!disabledTooltip}
                            onChange={(e) => this.handleTaxesChanged(e.target.value)}/>
                    </div>
                </Tooltip>
            </CardContent>
        </Card>;
    }
}

export default injectIntl(withSnackbar(PriceSheetTaxes));
