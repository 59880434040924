export function getFilterCategoriesText(filterCategories, intl) {
    if (!filterCategories?.length || !intl) {
        return "";
    }

    let result = [];
    for (let i = 0; i < filterCategories.length; i++) {
        const filterCategory = filterCategories[i];
        const options = filterCategory.filterOptions;
        if (!options.length) {
            continue;
        }
        let text = intl.formatMessage({id: "constants.FilterCategory." + filterCategory.filterCategoryType}) + " (";
        for (let j = 0; j < options.length; j++) {
            text += options[j].name;
            if (j < options.length - 1) {
                text += ", ";
            }
        }
        text += ")";
        if (i < filterCategories.length - 1) {
            text += ", ";
        }
        result.push(<div>{text}</div>)
    }
    return (<>{result}</>);
}

export function getFilterOptionsText(filterOptions) {
    if (!filterOptions?.length) {
        return "";
    }
    let text = "";
    for (let i = 0; i < filterOptions.length; i++) {
        text += filterOptions[i].name;
        if (i < filterOptions.length - 1) {
            text += ", ";
        }
    }
    return text;
}

export function wrapOptionsWithCategories(article, articleConfigurations) {
    if (!article || !articleConfigurations?.length) {
        return [];
    }
    let filterCategories = [];
    for (let filterCategory of articleConfigurations.find(ac => ac.id === article.articleConfigurationId)?.filterCategories ?? []) {
        const options = article.filterOptions?.filter(o => o.filterCategoryId === filterCategory.id);
        if (options?.length) {
            filterCategories.push({
                id: filterCategory.id,
                articleConfigurationId: article.articleConfigurationId,
                filterCategoryType: filterCategory.filterCategoryType,
                filterOptions: options
            });
        }
    }
    return filterCategories;
}
