import {Grid, Typography} from "@material-ui/core";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {selectDocument} from "../../../util/DocumentUtil";
import { GeneralContext } from "../../contexts/GeneralContext";
import InvoiceComponent from "./InvoiceComponent";
import {LICENSE_TYPES} from "../../../util/Constants";
import InvoiceUploadButton from "./InvoiceUploadButton";
import {
    getInvoices,
    sortByDate,
    updateInvoice,
    uploadInvoiceFromContract
} from "../../../services/InvoiceService";
import {SimpleTextCard} from "../../uiLibrary/SimpleTextCard";
import {latestActivePayment} from "../../../services/PaymentService";

class InvoicesOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            isLoading: true
        }
    }

    async componentDidMount() {
        if (latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE)) {
            await this.loadInvoices();
        } else {
            this.setState({isLoading: false});
        }
    }

    loadInvoices = async() => {
        const invoices = await getInvoices(this.context, this.props);
        this.setState({invoices, isLoading: false});
    }

    uploadNewInvoice = async(file) => {
        await selectDocument(file, async(document) => {
            this.setState({isLoading: true});
            let invoice = await uploadInvoiceFromContract(this.context, this.props, document);
            if (invoice) {
                this.setState({invoices: [...this.state.invoices, invoice]});
            }
            this.setState({isLoading: false});
        }, this.props, this.state.invoices.map(i => i.document));
    }

    handleDeliveryPeriodChanged = async(invoice, deliveryPeriod) => {
        let invoices = [...this.state.invoices];
        let updated = invoices.find(i => i.id === invoice.id);
        updated.deliveryPeriodVo = deliveryPeriod;
        await updateInvoice(this.context, this.props, invoice);
        void this.loadInvoices();
    }

    render() {
        const hasCooperationLicense = latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE);
        return (
            <>
                <Grid container>
                    <Grid item xs style={{marginRight: 15}}>
                        <Typography variant="h1">
                            <FormattedMessage id="cockpit.invoices.title"/>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <InvoiceUploadButton
                            hasCooperationLicense={hasCooperationLicense}
                            onUpload={f => this.uploadNewInvoice(f)}/>
                    </Grid>

                    {hasCooperationLicense && <Grid item xs={12} style={{marginTop: 30}}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="cockpit.invoices.subtitle"/>
                        </Typography>
                    </Grid>}
                </Grid>

                {this.state.invoices.length === 0 && !this.state.isLoading && <>

                    {hasCooperationLicense && <Typography variant="subtitle2">
                        <FormattedMessage id="cockpit.invoices.noData"/>
                    </Typography>}

                    <SimpleTextCard show={!hasCooperationLicense} textId="cockpit.invoices.noLicense"/>

                </>}

                {this.state.invoices.length !== 0 && !this.state.isLoading && <>

                    <Typography variant="h2" style={{marginBottom: 20}}>
                        <FormattedMessage id="cockpit.invoices.list.title"/>
                    </Typography>

                    {this.state.invoices
                        .sort(sortByDate)
                        .map(i => <InvoiceComponent
                            key={this.state.invoices.length + "-" + i.id}
                            showValidation
                            onChange={(deliveryPeriod) => this.handleDeliveryPeriodChanged(i, deliveryPeriod)}
                            invoice={i}
                            onDelete={() => this.loadInvoices()}/>)}

                </>}

            </>
        );
    }
}

export default injectIntl(withSnackbar(InvoicesOverview));
