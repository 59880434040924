import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Grid, Tooltip, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { uploadBlobCatch } from "../../services/BackendService";
import { texisionGreen, texisionOrange } from "../../util/ColorTheme";
import { FILE_TYPES, selectDocument } from "../../util/DocumentUtil";
import { canChangeOffer, getBidderInfoTextForSuitabilityCriterion, getDocumentsForSuitabilityCriterion, offerDisabledTooltip, offerSubmittedTooltip, requiresSuitabilityDocuments } from "../../util/ProcedureUtil";
import { DocumentPreview } from "../uiLibrary/DocumentPreview";
import { Header } from "../uiLibrary/Header";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import "../../css/BidderProjectDetails.css";
import { useContext } from "react";
import { GeneralContext } from "../contexts/GeneralContext";

const BidderSuitabilityCriteria = ({contract, offer, project, onUpload, reloadOffer, props}) => {

    const formatMessage = useIntl().formatMessage;
    const context = useContext(GeneralContext);

    const procedure = project?.procedure;

    if (!requiresSuitabilityDocuments(procedure) || !offer || !project) {
        return <div/>;
    }

    const suitabilitySelection = (criterion, documents) => {

        let tooltip = offerDisabledTooltip(project, offer, true, formatMessage)
            || offerSubmittedTooltip(offer, formatMessage) || "";

        const addButton = <Button
            disabled={!canChangeOffer(offer, procedure)}
            color="primary" 
            component="label">
            <FormattedMessage id="offers.document.select.button"/>
            <input 
                type="file" 
                id="offer-document-input" 
                accept={FILE_TYPES.PDF} 
                style={{display: "none"}} 
                onChange={(e) => selectDocument(e.target.files[0], async(document) => {
                    onUpload(true);
                    await uploadBlobCatch(context, "/document/offer/" + offer.id + "/suitability/" + criterion, document, null, props);
                    await reloadOffer();
                    onUpload(false);
                }, props, documents)} 
                onClick={(event)=> {event.target.value = null}}/>
        </Button>;

        return <Accordion elevation={1} key={criterion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-label="Expand"
                aria-controls="criterion-content"
                id="criterion-header">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h4" component="span">
                            <FormattedMessage id={"constants.SuitabilityCriterion." + criterion + ".title"}/>
                        </Typography>
                    </Grid>
                    <Grid item>
                        {getDocumentsForSuitabilityCriterion(offer.suitabilityDocuments, criterion).length === 0 
                        ? <MoreHorizIcon style={{color: texisionOrange}}/> : <CheckIcon style={{color: texisionGreen}}/>}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{marginBottom: 20}}>
                        <Typography variant="body2" component="span" style={{whiteSpace: "pre-line"}}>
                            {getBidderInfoTextForSuitabilityCriterion(props.intl, contract, criterion, project?.currency)}
                        </Typography>
                    </Grid>
                    {documents.map((document) => <DocumentPreview
                        key={document.id} 
                        document={document} 
                        deleteEndpoint="/document/suitability/"  
                        disabled={!canChangeOffer(offer, procedure)}
                        tooltipText={tooltip}
                        context={context}
                        props={props} 
                        callback={() => reloadOffer()}/>)}
                    <Grid item xs={12} style={{marginBottom: 20}}>
                        {!canChangeOffer(offer, procedure)
                        ? <Tooltip placement="bottom-start" title={tooltip}>
                            <div>{addButton}</div>
                        </Tooltip> 
                        : addButton}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>;
    }

    return <Card style={{marginBottom: 20}}>
        <CardContent>

            <Header 
                titleId="bidder.offerDocuments.suitabilityCriteria.title" 
                titleVariant="h3" 
                subtitleId="bidder.offerDocuments.suitabilityCriteria.subtitle"
                subtitleVariant="subtitle2"/>

            <Grid container spacing={2}>
                {procedure?.suitabilityCriteria?.map(c =>
                <Grid key={c} item xs={12} className="suitabilityAccordion"> 
                    {suitabilitySelection(c, getDocumentsForSuitabilityCriterion(offer.suitabilityDocuments, c))}
                </Grid>
                )}
            </Grid>

        </CardContent>
    </Card>;
}

export {
    BidderSuitabilityCriteria
}
