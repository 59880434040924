import React, {Component} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import OfferNavBarItem from "./OfferNavBarItem";
import {Collapse, List} from "@material-ui/core";
import {
    BIDDER_OFFER_DOCUMENTS_ROUTE,
    BIDDER_OFFER_PRICE_SHEET_ROUTE, BIDDER_OFFER_SUBMIT_ROUTE,
    buildRouteWithPathVariable
} from "../../../util/Constants";

class OfferNavBarRound extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {roundOpen: false};
    }

    render() {
        return (
            <>
                <OfferNavBarItem
                    level={1}
                    key={this.props.project.id+"bidderProject"}
                    onClick={() => this.setState({roundOpen: !this.state.roundOpen})}
                    open={this.state.roundOpen}
                    directTitle={(this.props.project?.offerIndex + 1) + ". "
                        + this.props.intl.formatMessage({id: "navBar.offerRound.title"})}
                    operationId={this.props.project.operationId}
                    projectId={this.props.project.id}/>

                <Collapse key={this.props.project.id+"-round-collapse"} in={this.state.roundOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <OfferNavBarItem
                            level={2}
                            pathname={buildRouteWithPathVariable(BIDDER_OFFER_DOCUMENTS_ROUTE, this.props.project.id)}
                            projectId={this.props.project.id}
                            title="navBar.offerDocuments.title"/>
                        <OfferNavBarItem
                            level={2}
                            pathname={buildRouteWithPathVariable(BIDDER_OFFER_PRICE_SHEET_ROUTE, this.props.project.id)}
                            projectId={this.props.project.id}
                            title="navBar.offerPriceSheet.title"/>
                        <OfferNavBarItem
                            level={2}
                            pathname={buildRouteWithPathVariable(BIDDER_OFFER_SUBMIT_ROUTE, this.props.project.id)}
                            projectId={this.props.project.id}
                            title="navBar.offerSubmit.title"/>
                    </List>
                </Collapse>
            </>)
    }
}
export default injectIntl(OfferNavBarRound);