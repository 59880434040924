import React, {Component} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {CATEGORY_FLAT_LINEN, CATEGORY_SURGICAL_TEXTILES, CATEGORY_WORK_WEAR} from "../../../util/Constants";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MaterialTable from "material-table";
import {renderImage} from "../../../util/TableUtil";
import {bodyBackgroundColor, texisionFontColorDark} from "../../../util/ColorTheme";
import {injectIntl} from "react-intl";
import {GeneralContext} from "../../contexts/GeneralContext";
import FilterCategoriesPreview from "../../administration/articleOverview/FilterCategoriesPreview";

class RentalLinenTable extends Component {

    static contextType = GeneralContext;

    data = (category) => {
        return this.props.rentalLinen.get(this.props.unitId).filter(r => r.category === category);
    }

    filterOptions = (article) => {
        let filterCategories = [];
        for (let filterCategory of this.props.articleConfigurations?.find(ac => ac.id === article.articleConfigurationId)?.filterCategories ?? []) {
            const options = article.filterOptions?.filter(o => o.filterCategoryId === filterCategory.id);
            if (options?.length) {
                filterCategories.push({
                    id: filterCategory.id,
                    articleConfigurationId: article.articleConfigurationId,
                    filterCategoryType: filterCategory.filterCategoryType,
                    filterOptions: options
                });
            }
        }
        return (
            <FilterCategoriesPreview filterCategories={filterCategories} articleConfigurationId={article.articleConfigurationId}/>
        );
    }

    render() {
        return (
            <Accordion key={this.props.unitId + "-rental"} elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls={this.props.unitId + "-CONTENT"}
                    id={this.props.unitId + "-HEADER"}>
                    <Typography variant="h6">
                        {this.props.intl.formatMessage({id: "rentalLinen.export.title"})}
                    </Typography>
                </AccordionSummary>
                <Grid container>
                    {[CATEGORY_WORK_WEAR, CATEGORY_FLAT_LINEN, CATEGORY_SURGICAL_TEXTILES].map(category => {
                        if (this.props.hasCategoryInUnit(this.props.unitId, category)) {
                            return <Grid item xs={12} key={this.props.unitId + category}>
                                <Accordion elevation={0}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls={this.props.unitId + "-CONTENT"}
                                        id={this.props.unitId + "-HEADER"}>
                                        <ChevronRightIcon style={{paddingTop: "4.5px"}}/>
                                        <Typography variant="h6">
                                            {this.props.intl.formatMessage({id: "constants.Category." + category})}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12}>
                                            <MaterialTable
                                                columns={[
                                                    {title: this.props.intl.formatMessage({id: "entities.article.subCategory"}), field: "subcategory", filtering: false,
                                                        render: article => this.props.intl.formatMessage({id: "constants.SubCategory." + article.subcategory})},
                                                    {title: this.props.intl.formatMessage({id: "entities.article.articleCategory"}), field: "articleCategory", filtering: false,
                                                        render: article => this.props.intl.formatMessage({id: "constants.ArticleCategory." + article.articleCategory})},
                                                    {title: this.props.intl.formatMessage({id: "entities.article.description"}), field: "description", grouping: false, filtering: false},
                                                    {title: this.props.intl.formatMessage({id: "entities.article.image"}), grouping: false, filtering: false,
                                                        render: article => renderImage(article, this.props.showArticleDetails)},
                                                    {title: this.props.intl.formatMessage({id: "entities.article.filterOptions"}), grouping: false, filtering: false,
                                                        render: article => this.filterOptions(article)}
                                                ]}
                                                data={this.data(category)}
                                                options={{
                                                    showTitle: false,
                                                    search: false,
                                                    paging: false,
                                                    draggable: false,
                                                    toolbar: false,
                                                    sorting: this.data(category).length > 0,
                                                    headerStyle: {
                                                        fontWeight: 'bold',
                                                        backgroundColor: bodyBackgroundColor,
                                                        color: texisionFontColorDark
                                                    },
                                                }}
                                                style={{padding: "5px", marginTop: "10px", boxShadow: 'none', borderRadius: 0}}/>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>;
                        } else {
                            return <div key={this.props.unitId + category}/>;
                        }
                    })}
                </Grid>
            </Accordion>
        );
    }
}

export default injectIntl(RentalLinenTable);
