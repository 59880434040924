
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from "react-intl";
import { cutName, downloadS3Document, formatDate } from '../../../util/DocumentUtil';
import { checkSuitabilityStatus, isSuitableForCriterion } from '../../../util/ProcedureUtil';
import {GeneralContext} from "../../contexts/GeneralContext";


const OfferSuitabilityChecking = ({offer, procedure, onChecked, onSave, suitabilities, props}) => {

    const [hasChanges, setHasChanges] = useState(false);

    const context = useContext(GeneralContext);

    const criterionSelection = (criterion) => {
        return <Grid container alignItems='center'>
            <Grid item xs>
                <FormattedMessage id={"constants.SuitabilityCriterion."+criterion+".title"}/>
            </Grid>
            <Grid item>
                <div>
                    <Checkbox
                        color="primary"
                        checked={isSuitableForCriterion(suitabilities.offerEvaluationMap, criterion, offer.id)}
                        disabled={!offer.isRecalled && procedure.isRecalled}
                        onChange={(e) => {
                            onChecked(offer.id, e.target.checked, criterion);
                            setHasChanges(true);
                            context.setUnsavedChanges(true);
                        }}/>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    {offer?.suitabilityDocuments[criterion]?.map(document => <React.Fragment key={document.filename}>
                        <Grid item>
                            <Grid container wrap="nowrap" alignItems="center">
                                <Grid item>
                                    <IconButton
                                        onClick={() => {downloadS3Document(props, document)}}>
                                        <Icon>download</Icon>
                                    </IconButton>
                                </Grid>
                                <Grid item style={{marginRight: 30}}>
                                    {cutName(props.intl, document.filename) + ", " + formatDate(props.intl, document.lastModified)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>)}
                </Grid>
            </Grid>
        </Grid>;
    };

    return <Accordion key="suitability-accordion">

        <AccordionSummary 
            expandIcon={<ExpandMoreIcon/>}
            aria-label="Expand-suitability"
            aria-controls="suitability-content"
            id="suitability-header">
            <Typography variant="h3" component="span">
                <FormattedMessage id="offerSuitability.title"/>
            </Typography>
        </AccordionSummary>

        <AccordionDetails>
            {(!procedure?.suitabilityCriteria || procedure.suitabilityCriteria.length === 0) 
            
            ? <Typography variant="subtitle2" component="span">
                <FormattedMessage id="offerSummary.suitabilityChecking.noCriteria"/>
            </Typography>

            : (!suitabilities || !suitabilities.offerEvaluationMap) 
            
            ? <Typography variant="subtitle2" component="span">
                <FormattedMessage id="offerSummary.suitabilityChecking.noSuitabilities"/>
            </Typography>
            
            : <React.Fragment>
               
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Typography variant="subtitle2" component="span">
                            <FormattedMessage id={"offerSummary.suitabilityChecking.subtitle"}/>
                        </Typography>
                    </Grid>

                    {procedure.suitabilityCriteria.map(c => <Grid key={c+offer.id} item xs={12}>
                        {criterionSelection(c)}
                    </Grid>)}

                    {(offer.isRecalled || !procedure.isRecalled) && <Grid item xs={12} style={{marginTop: 20}}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!hasChanges}
                            onClick={(e) => {
                                onSave();
                                setHasChanges(false);
                                context.setUnsavedChanges(false);
                            }}>
                            <FormattedMessage id={checkSuitabilityStatus(suitabilities?.offerEvaluationMap, offer.id, procedure.suitabilityCriteria) 
                                ? "offerSuitability.suitable.save" : "offerSuitability.notSuitable.save"}/>
                        </Button>
                    </Grid>}

                </Grid>

            </React.Fragment>}
        </AccordionDetails>

    </Accordion>;
}

export {
    OfferSuitabilityChecking
}
