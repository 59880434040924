import {downloadUsingGet, getAsync, getAsyncCatch} from "./BackendService";
import {isSubmitted} from "../util/ProcedureUtil";
import fileDownload from "js-file-download";
import {DE, LICENSE_TYPES} from "../util/Constants";
import {createErrorMessage} from "../util/Util";

export async function loadOffers(context) {
    let offers = [];
    let projectId = context.appData.offerProjectId ?? context.appData.activeProjectId;
    const response1 = await getAsync("/offers/" + projectId);
    if (response1?.status === 200) {
        offers = [...response1.data];
    } else if ([401, 403].includes(response1?.status)) {
        await context.logout();
    }
    return offers;
}

export async function loadOfferResult(context, props) {
    const offerResult = await getAsyncCatch(context, "/offer/results/" 
        + context.appData.offerProjectId, props);
    return offerResult ? offerResult : null;
}

export async function loadSubmittedOffers(context, props) {
    let projectId = context.appData.offerProjectId ?? context.appData.activeProjectId;
    const offers = await getAsyncCatch(context, "/offers/" + projectId, props);
    return offers ? offers.filter(o => isSubmitted(o)) : [];
}

export async function exportRatings(context, props, projectId) {
    const response = await downloadUsingGet("/offer/rating/export/" + projectId + "/" + DE);
    if (response?.status === 200) {
        const fileName = response.headers['content-disposition'].split("filename=")[1];
        fileDownload(response.data, fileName);
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    } else if ([402, 423].includes(response?.status)) {
        context.showNoLicenseDialog(LICENSE_TYPES.TENDER_LICENSE);
    } else {
        createErrorMessage(props.intl.formatMessage(
            {id: "offerRating.export.error"}), props);
    }
}
