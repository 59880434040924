import { Grid } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { de } from "date-fns/locale";
import { useIntl } from "react-intl";

const StatisticsDatePickers = ({
    disabled,
    globalMinDate, 
    globalMaxDate, 
    minDate, 
    maxDate, 
    onMinDateChange, 
    onMaxDateChange,
    isMonthDisabled,
    labelIdMin,
    labelIdMax
}) => {

    const intl = useIntl();

    return (
        <Grid container spacing={2} justifyContent="flex-end">
        
            <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                    <DatePicker
                        disabled={disabled}
                        shouldDisableMonth={(date) => isMonthDisabled(date)}
                        views={["year", "month"]}
                        label={intl.formatMessage({id: labelIdMin})}
                        minDate={globalMinDate}
                        maxDate={globalMaxDate < maxDate ? globalMaxDate : maxDate}
                        value={disabled ? new Date() : minDate}
                        onChange={(newDate) => onMinDateChange(newDate)}
                        renderInput={(params) => <TextField
                            {...params}
                            inputProps={{...params?.inputProps, readOnly: true}}
                            helperText={null}/>
                        }/>
                </LocalizationProvider>
            </Grid>

            <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                    <DatePicker
                        disabled={disabled}
                        shouldDisableMonth={(date) => isMonthDisabled(date)}
                        views={["year", "month"]}
                        label={intl.formatMessage({id: labelIdMax})}
                        minDate={globalMinDate > minDate ? globalMinDate : minDate}
                        maxDate={globalMaxDate}
                        value={disabled ? new Date() : maxDate}
                        onChange={(newDate) => onMaxDateChange(newDate)}
                        renderInput={(params) => <TextField 
                            {...params} 
                            inputProps={{...params?.inputProps, readOnly: true}} 
                            helperText={null}/>
                        }/>
                </LocalizationProvider>
            </Grid>
        
        </Grid>
    );
}

export {
    StatisticsDatePickers
}
