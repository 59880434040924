import React from 'react';
import {Component} from 'react';

import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import {injectIntl} from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import '../../../css/NavBar.css';
import {Tooltip} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {GeneralContext} from "../../contexts/GeneralContext";


class OfferNavBarItem extends Component {

    static contextType = GeneralContext;

    isSelected = () => {
        return this.props.pathname === window.location.pathname && this.props.operationId === this.context.appData.activeOperationId;
    }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        } else if (this.props.pathname) {
            this.context.setActiveOperationId(this.props.operationId);
            if (this.props.projectId) {
                this.context.setOfferProjectId(this.props.projectId);
            }
            this.props.history.push(this.props.pathname);
        }
    }

    menuItem = () => {
        return <div style={{paddingLeft: this.props.level > 1 ? (this.props.level - 1) * 40 : 0}}>
            <MenuItem
                disableRipple
                button
                selected={this.isSelected()}
                onClick={() => this.onClick()}
                style={this.isSelected() ? {backgroundColor: "var(--bodyBackgroundColor)"} : null}
                disabled={this.props.disabled}>
                <React.Fragment>
                    <ListItemIcon className={this.props.icon}/>
                    <ListItemText className="title" primary={this.props.directTitle ?? this.props.intl.formatMessage({id: this.props.title})}/>
                    {this.props.open === undefined ? <div/> :
                        <Icon className={this.props.open ? "icon-caret-up-large" : "icon-caret-down-large"} style={{fontSize: "10pt"}}/>}
                </React.Fragment>
            </MenuItem>
        </div>;
    }

    render() {
        if (this.props.disabled && this.props.tooltip) {
            return <Tooltip title={this.props.intl.formatMessage({id: this.props.tooltip})}>
                {this.menuItem()}
            </Tooltip>;
        }
        return this.menuItem();
    }
}

export default withRouter(injectIntl(OfferNavBarItem));
