
export const getAllArticleNumbers = (priceOffer) => {
    let priceItems = getAllPriceItems(priceOffer);
    let articleNumbers = {};
    priceItems.forEach(priceItem => {
        priceItem.articleNumbers.forEach(number => articleNumbers[number] = 1);
    });
    return articleNumbers;
}

export const getAllPriceItems = (priceOffer) => {
    let allPriceItems = [];
    for (const priceUnit of Object.values(priceOffer.priceUnitMap)) {
        for (const priceGroupMap of Object.values(priceUnit.articleGroupMap)) {
            for (const priceGroup of Object.values(priceGroupMap)) {
                for (const priceItem of Object.values(priceGroup.priceItemMap)) {
                    allPriceItems.push(priceItem);
                }
            }
        }
        for (const priceItem of Object.values(priceUnit.customerArticleGroup.priceItemMap)) {
            allPriceItems.push(priceItem);
        }
        for (const priceItem of Object.values(priceUnit.residentsLaundryGroup.priceItemMap)) {
            allPriceItems.push(priceItem);
        }
        for (const priceItem of Object.values(priceUnit.operatingResourceGroup.priceItemMap)) {
            allPriceItems.push(priceItem);
        }
        for (const priceItem of Object.values(priceUnit.specialServiceGroup.priceItemMap)) {
            allPriceItems.push(priceItem);
        }
    }            
    return allPriceItems;                
}
