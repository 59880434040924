import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CustomerArticleTable from './CustomerArticleTable';

import '../../apps/App.css';

import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';

import CustomerArticleContent from './CustomerArticleContent';

import {withSnackbar} from 'notistack';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {DIALOG_TYPE_WARNING} from '../../../util/Constants';
import {GeneralContext} from "../../contexts/GeneralContext";
import {createCustomerArticle, deleteCustomerArticle, getCustomerArticlesForUnit, updateCustomerArticle} from "../../../services/CustomerArticleService";

export const emptyFormData = () => {
    return {id: null, description: "", features: [], volumeType: "", volume: ""};
};

export const createFormData = (description) => {
    let formData = emptyFormData();
    formData.description = description;
    return formData;
};

class CustomerArticleOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            allCustomerArticles: [],
            editMode: false,
            version: 0,
            formData: emptyFormData(),
            customerArticleToDelete: null,
            showDeleteDialog: false,
            showCreatePanel: false
        }
    }

    async componentDidMount() {
        this.setState({allCustomerArticles: await getCustomerArticlesForUnit(this.context, this.props)});
    }

    componentDidUpdate() {
        if (this.state.formData?.id === null || this.state.formData?.id === undefined) {
            const hasUnsavedChanges = this.state.formData.description 
                || this.state.formData.volumeType
                || this.state.formData.volume 
                || this.state.formData.features.length > 0;
            this.context.setUnsavedChanges(!!hasUnsavedChanges);
        }
    }

    handleCreateOrUpdateCustomerArticle = async() => {
        const customerArticle = {
            id: this.state.formData.id,
            businessUnitId: this.context.appData.activeUnitId,
            description: this.state.formData.description,
            features: this.state.formData.features.filter(feature => feature.type !== ""),
            volumeType: this.state.formData.volumeType,
            volume: this.state.formData.volume,
            version: this.state.editMode ? this.state.version : 0,
        }
        let success;
        if (customerArticle.id === null || customerArticle.id === undefined) {
            success = await createCustomerArticle(this.context, this.props, customerArticle);
        } else {
            success = await updateCustomerArticle(this.context, this.props, customerArticle);
        }
        if (success) {
            this.context.setUnsavedChanges(false);
            this.setState({allCustomerArticles: await getCustomerArticlesForUnit(this.context, this.props)});
            this.resetState();
        }
    }

    resetState = () => {
        this.setState({
            editMode: false,
            version: 0,
            formData: emptyFormData(),
            customerArticleToDelete: null,
            showDeleteDialog: false,
            showCreatePanel: false
        });
    }

    handleEdit = (rowData) => {
        const formData = {
            id: rowData.id,
            description: rowData.description,
            features: rowData.features,
            volumeType: rowData.volumeType,
            volume: rowData.volume};
        this.setState({editMode: true, version: rowData.version, formData: formData, showCreatePanel: true});
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    handleDataChanged = (propertyName, value) => {
        if (this.state.formData?.id !== null && this.state.formData?.id !== undefined) {
            this.context.setUnsavedChanges(true);
        }
        let newFormData = this.state.formData;
        newFormData[propertyName] = value;
        this.setState({formData: newFormData});
    }

    delete = async() => {
        const success = await deleteCustomerArticle(this.context, this.props, this.state.customerArticleToDelete.id);
        this.setState({showDeleteDialog: false, customerArticleToDelete: null});
        if (success) this.setState({allCustomerArticles: await getCustomerArticlesForUnit(this.context, this.props)});
    }

    render() {

        return (
            <div>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDeleteDialog}
                    titleId="customerArticle.dialog.title"
                    subtitleId="customerArticle.dialog.textDelivery"
                    cancelId="commons.no.button"
                    actionId="commons.yes.button"
                    onCancel={() => this.setState({showDeleteDialog: false, customerArticleToDelete: null})}
                    onAction={() => this.delete()}/>

                <Grid className="parent">

                    <Card className="child">

                        <CardContent>

                            <Grid container className="mb-6">

                                <Grid item xs={6}>
                                    <Grid container justifyContent="flex-start">
                                        <Typography variant="h6" component="h6">
                                            <FormattedMessage id="customerArticle.table.article"/>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container justifyContent="flex-end">
                                        <Button 
                                            color="primary"
                                            variant="contained"
                                            onClick={() => this.setState({showCreatePanel: !this.state.showCreatePanel})}
                                            disabled={this.state.editMode || this.props.readOnly}>
                                            {this.state.showCreatePanel === false ?
                                                this.props.intl.formatMessage({id: "customerArticle.create.buttontext.create"}) :
                                                this.props.intl.formatMessage({id: "commons.minimize.button"})}
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Collapse in={this.state.showCreatePanel}>

                                <Divider light/>

                                <CustomerArticleContent 
                                    formData={this.state.formData}
                                    onDataChange={this.handleDataChanged}
                                    isInEditMode={this.state.editMode}
                                    onCancel={this.resetState}
                                    onSubmit={this.handleCreateOrUpdateCustomerArticle}
                                    onFeatureCreate={this.createFeature}/>

                                <Divider light/>
                                
                                <div className="mb-6"/>

                            </Collapse>

                            <CustomerArticleTable
                                readOnly={this.props.readOnly}
                                isInEditMode={this.state.editMode}
                                data={this.state.allCustomerArticles}
                                onDelete={(rowData) => this.setState({customerArticleToDelete: rowData, showDeleteDialog: true})}
                                onEdit={this.handleEdit}/>

                        </CardContent>

                    </Card>

                </Grid>

            </div>
        );
    }
}

export default injectIntl(withSnackbar(CustomerArticleOverview));
