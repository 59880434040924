import React, {Component} from 'react';
import {getAsync, postAsync} from '../../services/BackendService';
import {isAdmin, isBidder, isBidderNewsletter, isBidderReadOnly, isOfferUser, isOrderUser, isProjectAdmin, isUser, Role} from '../../services/UserService';
import { loadStripe } from '@stripe/stripe-js';

import {
    APP_TYPE_KEY, BASE_DATA_ROUTE,
    BILL_OF_QUANTITIES_TYPE,
    COCKPIT_APP, DASHBOARD_ROUTE,
    DE,
    DIALOG_TYPE_INFO,
    DIALOG_TYPE_WARNING,
    EXPORT_TYPE_CONCATENATE,
    FORGOT_PASSWORD_ROUTE, INDUSTRY_TYPES,
    LAST_OFFER_PROJECT_ID,
    LAST_OPERATION_ID,
    LAST_PROJECT_ID,
    LICENSE_TYPES,
    LOGIN_ROUTE,
    OFFER_APP,
    ORDER_APP,
    PAYMENT_MANAGEMENT_ROUTE,
    REGISTRATION_ROUTE,
    RESET_PASSWORD_ROUTE,
    TENDER_APP,
    TERMS_OF_USE_CHECKED
} from '../../util/Constants';

import {isOffer, isOrder, isTender, sortAsc} from "../../util/Util";
import {toJson, toMap} from "../../util/MapUtil";
import {Prompt, Router} from "react-router-dom";
import history from "../navigation/shared/History";
import TermsOfUse from "../legal/TermsOfUse";
import OfferApp from "./OfferApp";
import TenderApp from "./TenderApp";
import CockpitApp from "./CockpitApp";
import CouldNotReachServer from "../uiLibrary/CouldNotReachServer";
import {theme} from "../../util/ColorTheme";
import messagesDE from "../../translations/de.json";
import messagesDE_HEALTH from "../../translations/de_health.json";
import messagesDE_HOTEL from "../../translations/de_hotel.json";
import messagesEN from "../../translations/en.json";
import {FormattedMessage, IntlProvider} from "react-intl";
import {SnackbarProvider} from "notistack";
import {GeneralContext} from "../contexts/GeneralContext";
import {DialogContentText, ThemeProvider, Typography} from '@material-ui/core';
import TexisionDialog from "../uiLibrary/TexisionDialog";
import OrderApp from './OrderApp';
import { loadUserData, storeUserData, resetDatabase } from '../../services/Database';
import { Alert, Snackbar } from '@mui/material';
import {getPaymentsAndEvents, latestActivePayment} from "../../services/PaymentService";
import ScrollToTop from "../uiLibrary/ScrollToTop";
import {updateValidationMap} from "../../services/ProjectService";
import {updatePriceOfferMap} from "../../services/PriceOfferService";
import {loadOrderDataOffline, loadOrderDataOnline} from "../../services/OrderService";
import {getBaseData, hasMissingBaseData} from "../../services/StatisticsService";
import {getContract} from "../../services/ContractService";
import PreLoginApp from "./PreLoginApp";

class App extends Component {

    constructor(props) {
        super(props);

        this.hasUnsavedChanges = false;

        this.state = {
            // app specific data
            appData: null,
            // service that provides methods to get and set app specific data
            appDataService: null,
            // only render children if data has been loaded

            currentUser: null,
            userState: null,
            serverUnavailable: false,
            showOptimisticLockDialog: false,
            showMissingBaseDataDialog: false,
            showRouteUnavailableDialog: false,

            // if current user has bidder_newsletter role, show dialog and redirect to registration
            showNewsletterDialog: false,
            // name of the user with role bidder_newsletter
            bidderName: null,

            showTermsOfUse: false,
            showSupportOverlay: false,

            chat: null,

            isInitializing: true,
            isChangingApp: false,

            offline: false,

            nextPathName: null,

            payments: null,
            operationEvents: null,

            showNoLicenseDialog: false,
            requiredLicenseType: null,
            customNoLicenseText: null
        }

        this.eventListener = e => {
            if (this.hasUnsavedChanges) {
                e.preventDefault();
                return e.returnValue = "Es gibt ungespeicherte Änderungen. Wenn Sie die Seite schließen, gehen diese verloren.";
            }
        };
    }

    async componentDidMount() {
        window.addEventListener("beforeunload", this.eventListener);
        window.addEventListener("offline", () => this.setState({offline: true}));
        window.addEventListener("online", () => this.setState({offline: false}));
        // if user opens an authentication page, logout and do not load user data
        if (window.location.pathname.includes(LOGIN_ROUTE)
            || window.location.pathname.includes(REGISTRATION_ROUTE)
            || window.location.pathname.includes(RESET_PASSWORD_ROUTE)
            || window.location.pathname.includes(FORGOT_PASSWORD_ROUTE)) {
            const redirectToLogin = false;
            this.setState({isInitializing: false});
            await this.logout(redirectToLogin);
            return;
        }
        await this.loadUser();
        await this.loadAppData();
        this.setState({isInitializing: false});
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.eventListener);
        window.removeEventListener("offline", () => this.setState({offline: true}));
        window.removeEventListener("online", () => this.setState({offline: false}));
    }

    getContext = () => {
       return {...this, ...this.state};
    }

    login = async(token) => {
        this.setState({isInitializing: true});
        this.setToken(token);
        await this.loadUser();
        await this.loadAppData();
        this.setState({isInitializing: false});
    }

    setActiveProjectId = (activeProjectId) => {
        const appData = this.state.appData;
        appData.activeProjectId = activeProjectId;
        this.setState({appData});
    }

    setActiveUnitId = (activeUnitId) => {
        const appData = this.state.appData;
        appData.activeUnitId = activeUnitId;
        this.setState({appData});
    }

    setActiveOperationId = (activeOperationId) => {
        const appData = this.state.appData;
        const activeOperation = this.state.appData.operations?.find(o => o.id === activeOperationId);
        appData.activeOperationId = activeOperationId;
        appData.industryType = activeOperation?.industryType;
        this.setState({appData});
    }

    setActiveOperationIdForCockpit = async(activeOperationId) => {
        if (localStorage.getItem(APP_TYPE_KEY) !== COCKPIT_APP) {
            return;
        }

        const operation = this.state.appData.operations.find(o => o.id === activeOperationId);
        let activeProjectId;
        let activeUnitId;
        if (operation) {
            localStorage.setItem(LAST_OPERATION_ID, operation.id);
            const activeProject = operation.activeProject;
            activeProjectId = activeProject.id;
            await updatePriceOfferMap(this.getContext(), activeProjectId);
            const units = activeProject?.businessUnits?.units;
            if (units && units.length > 0) {
                activeUnitId = units[0].id;
            }
        }

        const appData = this.state.appData;
        appData.activeOperationId = activeOperationId;
        appData.activeProjectId = activeProjectId;
        appData.activeUnitId = activeUnitId;

        this.setState({appData});

        await this.loadBaseData(true);

        const workingProjectId = operation?.workingProject?.id;
        if (workingProjectId !== null && workingProjectId !== undefined) {
            await updatePriceOfferMap(this.getContext(), workingProjectId);
            await updateValidationMap(this.getContext(), workingProjectId);
        }

        history.push(DASHBOARD_ROUTE);
    }

    setOfferProjectId = (offerProjectId) => {
        const appData = this.state.appData;
        appData.offerProjectId = offerProjectId;
        this.setState({appData});
    }

    setProcedureVersion = (procedureVersion) => {
        const appData = this.state.appData;
        appData.procedureVersion = procedureVersion;
        this.setState({appData});
    }

    onPaymentsLoaded = (payments) => {
        this.setState({payments});
    }

    onOperationEventsLoaded = (operationEvents) => {
        this.setState({operationEvents});
    }

    setToken = (token) => {
        const appData = this.state.appData == null ? {} : this.state.appData;
        appData.token = token;
        this.setState({appData});
    }

    setIndustryType = (industryType) => {
        const appData = this.state.appData;
        appData.industryType = industryType;
        this.setState({appData});
    }

    setShowOptimisticLockDialog = (showOptimisticLockDialog) => {
        this.setState({showOptimisticLockDialog});
    }

    setActiveApp = async(appType) => {
        this.setState({isChangingApp: true});
        localStorage.setItem(APP_TYPE_KEY, appType);
        await this.loadAppData();
        this.setState({isChangingApp: false});
    }

    getUserStateValue = (key) => {
        return this.state.userState !== null ? toMap(this.state.userState?.jsonData).get(key) : null;
    }

    setUserStateValue = async (key, value) => {
        if (!this.state.userState?.jsonData) {
            return;
        }
        let userStateMap = toMap(this.state.userState?.jsonData).set(key, value);
        const userState = this.state.userState;
        userState.jsonData = toJson(userStateMap);
        this.setState({userState});
        await postAsync("/userstate", userState);
    }

    loadUser = async () => {
        // Send a whoAmI request to the server and await the response.
        let whoAmIResponse = await getAsync('/whoami');
        if (whoAmIResponse?.status === 200) {
            const currentUser = whoAmIResponse.data

            this.setInitialApp(currentUser);

            if (isBidderNewsletter(currentUser)) {
                this.setState({showNewsletterDialog: true, bidderName: currentUser.username});
                return;
            }

            const userStateResponse = await getAsync('/userstate');

            // set default user state map
            if (!userStateResponse.data.jsonData) {
                userStateResponse.data.jsonData = toJson(new Map([[BILL_OF_QUANTITIES_TYPE, EXPORT_TYPE_CONCATENATE]]));
                await postAsync("/userstate", userStateResponse.data);
            }

            let userState;
            if (userStateResponse?.status === 200) {
                userState = userStateResponse.data
            } else if ([401, 403].includes(userStateResponse?.status)) {
                await this.logout();
                return;
            }

            await storeUserData(currentUser);
            this.setState({currentUser, userState});

            let termsOfUseChecked = this.getUserStateValue(TERMS_OF_USE_CHECKED);

            if (!termsOfUseChecked) {
                this.setState({showTermsOfUse: true})
            }

        } else if (!whoAmIResponse) {
            const currentUser = await loadUserData();
            this.setState({currentUser, serverUnavailable: true})
        }
    }

    loadAppData = async(skipBaseDataDialog) => {
        const user = this.state.currentUser;

        if (!user) {
            return;
        }

        await this.loadConstants();

        switch (localStorage.getItem(APP_TYPE_KEY)) {
            case TENDER_APP:
                await this.loadTenderData();
                await getPaymentsAndEvents(this.getContext());
                break;
            case OFFER_APP:
                await this.loadOfferData();
                break;
            case COCKPIT_APP:
                await this.loadCockpitData();
                await getPaymentsAndEvents(this.getContext());
                await this.loadBaseData(skipBaseDataDialog !== true);
                break;
            case ORDER_APP:
                await this.loadOrderData();
                await getPaymentsAndEvents(this.getContext());
                break;
            default:
                break;
        }
    }

    setInitialApp = (user) => {
        if (isOrderUser(user)) {

            localStorage.setItem(APP_TYPE_KEY, ORDER_APP);

        } else if (isBidderReadOnly(user)) {

            localStorage.setItem(APP_TYPE_KEY, OFFER_APP);

        } else if (!localStorage.getItem(APP_TYPE_KEY) && !isBidder(user)) {

            localStorage.setItem(APP_TYPE_KEY, TENDER_APP);

        } else if (!localStorage.getItem(APP_TYPE_KEY) && isBidder(user)) {

            localStorage.setItem(APP_TYPE_KEY, OFFER_APP);

        } else if (isOrder() && isUser(user)) {

            localStorage.setItem(APP_TYPE_KEY, TENDER_APP);

        } else if (isOrder() && isBidder(user)) {

            localStorage.setItem(APP_TYPE_KEY, OFFER_APP);

        } else if (isTender() && isBidder(user)) {

            localStorage.setItem(APP_TYPE_KEY, OFFER_APP);

        } else if (isOffer() && (isProjectAdmin(user) || isUser(user))) {

            localStorage.setItem(APP_TYPE_KEY, TENDER_APP);

        }
    }

    loadConstants = async () => {

        let genders = [];
        let articleFeatures = [];
        let customerArticleFeatures = [];
        let categories = [];
        let subCategories = [];
        let articleCategories = [];
        let filterCategoryTypes = [];
        let addressTypes = [];
        let logisticTypes = [];
        let offsettingModels = [];
        let volumeTypes = [];
        let userTitles = [];
        let systeminfo = null;
        let operatingResourceOffsettings = [];
        let operatingResourceSubCategories = [];
        let professionalGroupTypes = [];
        let stripe = null;
        let calendlyBaseUrl = null;
        let profileAllId = null;
        let maxBusinessUnitCount = null;

        let response = await getAsync('/systeminfo');
        if (response?.status === 200) {
            systeminfo = response.data;
        } else if ([401, 403].includes(response?.status)) {
            await this.logout();
            return;
        }

        response = await getAsync('/constants');
        if (response?.status === 200) {
            genders = response.data.genders;
            articleFeatures = response.data.articleFeatures;
            customerArticleFeatures = response.data.customerArticleFeatures;
            categories = response.data.categories;
            subCategories = response.data.subCategories;
            articleCategories = response.data.articleCategories;
            filterCategoryTypes = response.data.filterCategoryTypes;
            addressTypes = response.data.addressTypes;
            logisticTypes = response.data.logisticTypes;
            volumeTypes = response.data.volumeTypes;
            offsettingModels = response.data.offsettingModels;
            userTitles = response.data.userTitles;
            operatingResourceOffsettings = response.data.operatingResourceOffsettings;
            operatingResourceSubCategories = response.data.operatingResourceSubCategories;
            professionalGroupTypes = response.data.professionalGroupTypes;
            stripe = await loadStripe(response.data.stripePublicKey);
            calendlyBaseUrl = response.data.calendlyClientUrl;
            profileAllId = response.data.profileAllId;
            maxBusinessUnitCount = response.data.maxBusinessUnitCount;

        } else if ([401, 403].includes(response?.status)) {
            await this.logout();
            return;
        }

        const constants =
            {
                genders, articleFeatures, customerArticleFeatures, offsettingModels,
                categories, subCategories, articleCategories, filterCategoryTypes, addressTypes, logisticTypes,
                volumeTypes, userTitles, systeminfo, operatingResourceOffsettings, operatingResourceSubCategories,
                professionalGroupTypes, stripe, calendlyBaseUrl, profileAllId, maxBusinessUnitCount
            }

        this.setState({appData: {...this.state.appData, ...constants}});
    }

    loadTenderData = async () => {
        // initialize app data, containing operations and projects

        let allProjects = [];
        let operations = [];
        let activeOperationId = null;
        let activeProjectId = null;
        let activeUnitId = null;
        let offerProjectId = null;
        let industryType = null;


        const response = await getAsync('/operation');

        if (response?.status === 200) {

            let result = response.data;
            operations = result;
            allProjects = result
                .filter(operation => operation.offerId === null || operation.offerId === undefined)
                .map(operation => operation.workingProject ?? operation.activeProject)
                .sort((a, b) => sortAsc(a.name, b.name));

        } else if ([401, 403].includes(response?.status)) {

            await this.logout();
        }

        const projectIdToUnitsMap = new Map();

        if (allProjects && allProjects.length > 0) {

            for (let project of allProjects) {
                projectIdToUnitsMap.set(project.id, project.businessUnits?.units);
            }
            const lastOperationId = Number(localStorage.getItem(LAST_OPERATION_ID));
            const lastOperation = operations.find(o => o.id === lastOperationId);

            // select operation
            const hasLastOperation = lastOperationId !== null && lastOperationId !== undefined && lastOperation;
            activeOperationId = hasLastOperation ? lastOperationId : allProjects[0].operationId;
            const activeOperation = operations.find(o => o.id === activeOperationId)
            industryType = activeOperation?.industryType;
            localStorage.setItem(LAST_OPERATION_ID, activeOperationId);

            // select project
            const selectedProject = allProjects.find(p => p.operationId === activeOperationId);
            activeProjectId = selectedProject?.id;
            activeUnitId = selectedProject?.businessUnits?.units[0]?.id;
            localStorage.setItem(LAST_PROJECT_ID, selectedProject?.id);

            // set offer project -> define the offer round
            offerProjectId = Number(localStorage.getItem(LAST_OFFER_PROJECT_ID));
            const offerProject = allProjects.find(p => p.id === offerProjectId);

            if (offerProjectId === null || offerProjectId === undefined || !offerProject) {
                offerProjectId = allProjects[0].id;
                localStorage.setItem(LAST_OFFER_PROJECT_ID, allProjects[0].id);
            }

        } else {
            localStorage.setItem(LAST_PROJECT_ID, null);
            localStorage.setItem(LAST_OFFER_PROJECT_ID, null);
            localStorage.setItem(LAST_OPERATION_ID, null);
        }

        if (localStorage.getItem(LAST_PROJECT_ID)) {
            await updateValidationMap(this.getContext(), localStorage.getItem(LAST_PROJECT_ID));
        }

        const projectData = {
            activeOperationId,
            activeProjectId,
            activeUnitId,
            offerProjectId,
            industryType,
            allProjects,
            operations,
            projectIdToUnitsMap
        };

        this.setState({appData: {...this.state.appData, ...projectData}});
    }

    loadOfferData = async () => {
        const user = this.state.currentUser;
        const industryType = INDUSTRY_TYPES.UNDEFINED;
        let allParticipatingProjects = [];
        let allOffers = [];
        let allPublishedProjects = [];
        // load all projects the bidder participates in
        if (isAdmin(user) || isBidder(user)) {
            const allPublishedProjectsResponse = await getAsync("/project/published");
            if (allPublishedProjectsResponse?.status === 200) {
                allPublishedProjects = allPublishedProjectsResponse.data ?? [];
            }
            const participatingProjectsResponse = await getAsync("/project/participating");
            if (participatingProjectsResponse?.status === 200) {
                for (let project of (participatingProjectsResponse.data ?? [])) {
                    if (!allParticipatingProjects.find(p => p.id === project.id)) {
                        project.procedure = allPublishedProjects.find(p => p.id === project.id)?.procedure;
                        allParticipatingProjects = [...allParticipatingProjects, project];
                    }
                }
                const offersResponse = await getAsync("/offers");
                if (offersResponse?.status === 200) {
                    allOffers = [...offersResponse.data];
                }
            }
        }

        let participatingProjectMap = Map.groupBy(allParticipatingProjects, project => {
            return project.operationId;
        });

        const bidderData = {
            participatingProjectMap,
            allOffers,
            industryType
        };

        this.setState({appData: {...this.state.appData, ...bidderData}});
    }

    loadCockpitData = async () => {

        const operationResponse = await getAsync("/operation");

        if (operationResponse?.status === 200) {
            const operations = operationResponse.data?.filter(operation => operation.offerId !== null && operation.offerId !== undefined);
            const lastOperationId = localStorage.getItem(LAST_OPERATION_ID);
            const lastProjectId = localStorage.getItem(LAST_PROJECT_ID);
            let activeOperationId;
            let activeProjectId;
            let activeUnitId;
            const projectIdToUnitsMap = new Map();

            // set active operation id by local storage or if not available take first from list
            if (lastOperationId && operations?.find(o => o.id === parseInt(lastOperationId))) {
                activeOperationId = parseInt(lastOperationId);
            } else if (operations && operations.length > 0) {
                activeOperationId = operations[0].id;
                localStorage.setItem(LAST_OPERATION_ID, activeOperationId);
            } else {
                localStorage.setItem(LAST_OPERATION_ID, null);
                localStorage.setItem(LAST_PROJECT_ID, null);
            }

            // set activeProject and its first unit in state so data can be loaded if a unit specific page is opened first
            if (operations && operations.length > 0) {
                const activeOperation = operations.find(o => o.id === activeOperationId);
                const activeProject = activeOperation?.activeProject;
                activeProjectId = activeProject.id;
                const units = activeProject?.businessUnits?.units;
                projectIdToUnitsMap.set(activeProjectId, units);
                if (units && units.length > 0) {
                    activeUnitId = units[0].id;
                }

                const workingProject = activeOperation?.workingProject;
                if (workingProject) {
                    projectIdToUnitsMap.set(workingProject.id, workingProject.businessUnits?.units);
                    if (lastProjectId && workingProject.id === parseInt(lastProjectId)) {
                        activeProjectId = workingProject.id;
                    }
                    await updateValidationMap(this.getContext(), workingProject.id);
                }
            }

            const activeOperation = operations?.find(o => o.id === activeOperationId);
            const industryType = activeOperation?.industryType;

            const cockpitData = {
                operations, 
                activeOperationId, 
                activeProjectId,
                activeUnitId,
                projectIdToUnitsMap,
                industryType
            };

            await this.setState({appData: {...this.state.appData, ...cockpitData}});
            // load completed phases and conflicts for working projects and price offer for active and working project
            await updatePriceOfferMap(this.getContext(), activeProjectId);
            const workingProjectId = activeOperation?.workingProject?.id;
            if (workingProjectId !== null && workingProjectId !== undefined) {
                await updatePriceOfferMap(this.getContext(), workingProjectId);
                await updateValidationMap(this.getContext(), workingProjectId);
            }
        }
    }

    getActiveContract = async() => {
        const operation = this.state.appData.operations?.find(o => o.id === this.state.appData.activeOperationId);
        const existingContract = this.state.appData.contracts?.find(c => c.projectId === operation.activeProject.id);
        if (this.state.appData.contracts?.find(c => c.projectId === operation.activeProject.id)) {
            return existingContract;
        }
        const contract = await getContract(
            this.getContext(),
            this.props,
            operation.activeProject.id
        );
        if (contract) {
            let allContracts = this.state.appData.contracts ?? [];
            allContracts.push(contract);
            this.setState({appData: {...this.state.appData, contracts: allContracts}});
        }
        return contract;
    }

    loadBaseData = async(showDialog) => {
        const operationId = this.state.appData.activeOperationId;
        if (operationId !== null && operationId !== undefined && latestActivePayment(this.getContext(), LICENSE_TYPES.COOPERATION_LICENSE)) {
            const baseData = await getBaseData(operationId);
            baseData?.forEach(d => {
                let bedsByStation = new Map();
                if (d.bedsByStation?.length) {
                    for (let statisticsBedsVo of d.bedsByStation) {
                        bedsByStation.set(statisticsBedsVo.deliveryAddressId, statisticsBedsVo.bedCount);
                    }
                }
                d.bedsByStation = bedsByStation;
            });
            await this.setState({appData: {...this.state.appData, baseData}});
            const missingBaseData = await hasMissingBaseData(this.getContext());
            // always update if data is missing, but show dialog only in certain cases (login, change operation)
            if (showDialog && !isAdmin(this.state.currentUser)) {
                this.setState({showMissingBaseDataDialog: missingBaseData});
            }
            this.setState({missingBaseData});
            return baseData;
        }
    }

    loadOrderData = async() => {

        const operationResponse = await getAsync("/operation");
        let activeOperationId;
        let offline = false;
        if (operationResponse?.status === 200) {
            let operations = operationResponse.data?.filter(operation => operation.offerId !== null && operation.offerId !== undefined);
            const lastOperationId = localStorage.getItem(LAST_OPERATION_ID);

            // set active operation id by local storage or if not available take first from list
            if (lastOperationId && operations?.find(o => o.id === parseInt(lastOperationId))) {
                activeOperationId = parseInt(lastOperationId);
            } else if (operations && operations.length > 0) {
                activeOperationId = operations[0].id;
                localStorage.setItem(LAST_OPERATION_ID, activeOperationId);
            }

            // set activeProject and its first unit in state so data can be loaded if a unit specific page is opened first
            if (operations && operations.length > 0) {
                await loadOrderDataOnline(this.getContext(), operations, activeOperationId);
            }

        } else {
            offline = true;
            await loadOrderDataOffline(this);
        }

        this.setState({offline});
    }

    logout = async(redirectToLogin = true) => {
        await postAsync('/logout', null, true);
        await resetDatabase();
        this.hasUnsavedChanges = false;
        this.setState({currentUser: null, appData: {}, userState: null, payment: null});
        if (redirectToLogin) {
            history.push(LOGIN_ROUTE);
        }
    }

    texisionApp = () => {
        if (this.state.isChangingApp) {
            return <div/>;
        }

        switch (localStorage.getItem(APP_TYPE_KEY)) {
            case COCKPIT_APP: return <CockpitApp/>;
            case ORDER_APP: return <OrderApp/>;
            case OFFER_APP: return <OfferApp/>;
            case TENDER_APP: 
            default:
                return <TenderApp/>;
        }
    }

    getMessages = (locale) => {
        if (locale === DE) {
            switch (this.state.appData?.industryType) {
                case INDUSTRY_TYPES.HEALTH:
                    return { ...messagesDE, ...messagesDE_HEALTH };
                case INDUSTRY_TYPES.HOTEL:
                    return { ...messagesDE, ...messagesDE_HOTEL };
                case INDUSTRY_TYPES.UNDEFINED:
                default:
                    return messagesDE;
            }
        } else {
            return messagesEN;
        }
    }

    getRouterKey = () => {
        switch (localStorage.getItem(APP_TYPE_KEY)) {
            case TENDER_APP:
            case ORDER_APP:
                return this.state.appData?.activeProjectId;
            case COCKPIT_APP:
                return this.state.appData?.activeOperationId;
            default:
                return "router-key";
        }
    }

    getNoLicenseDialogSubtitle = () => {
        if (isOfferUser(this.state.currentUser)) {
            return "navBar.noLicense.bidder.dialog.subtitle";
        } else if (this.state.customNoLicenseText) {
            return this.state.customNoLicenseText;
        } else {
            switch (this.state.requiredLicenseType) {
                case LICENSE_TYPES.TENDER_LICENSE:
                    return "navBar.noPublishLicense.dialog.subtitle";
                case LICENSE_TYPES.COOPERATION_LICENSE:
                    return "navBar.noOrderLicense.dialog.subtitle";
                default:
                    return null;
            }
        }
    }

    onPaymentRequiredDialogClosed = () => {
        this.setState({showNoLicenseDialog: false, requiredLicenseType: null, customNoLicenseText: null});
        this.hasUnsavedChanges = false;
        history.push(PAYMENT_MANAGEMENT_ROUTE);
    }

    showNoLicenseDialog = (requiredLicenseType, customNoLicenseText) => {
        this.setState({showNoLicenseDialog: true, requiredLicenseType, customNoLicenseText});
    }

    setUnsavedChanges = (newValue) => { this.hasUnsavedChanges = newValue; }

    showRouteUnavailableDialog = () => this.setState({showRouteUnavailableDialog: true})

    handleSupportOverlay = () => this.setState({showSupportOverlay: !this.state.showSupportOverlay})

    setValidationMap = (validationMap) => {
        this.setState({appData: {...this.state.appData, validationMap}});
    }

    setPriceOfferMap = (priceOfferMap) => {
        this.setState({appData: {...this.state.appData, priceOfferMap}});
    }

    setProjectIdToUnitsMap = (projectIdToUnitsMap) => {
        this.setState({appData: {...this.state.appData, projectIdToUnitsMap}});
    }

    setProjectList = (allProjects) => {
        this.setState({appData: {...this.state.appData, allProjects}});
    }

    setOperationList = (operations) => {
        this.setState({appData: {...this.state.appData, operations}});
    }

    setOrderData = (orderData) => {
        this.setState({appData: {...this.state.appData, ...orderData}});
    }

    render() {

        const locale = DE;

        if (this.state.isInitializing) {
            return <div/>;
        } else if (this.state.serverUnavailable && localStorage.getItem(APP_TYPE_KEY) !== ORDER_APP) {
            return (
                <ThemeProvider theme={theme}>
                    <IntlProvider
                        locale={locale}
                        messages={this.getMessages(locale)}>
                        <CouldNotReachServer/>
                    </IntlProvider>
                </ThemeProvider>
            );
        }

        return (
            <ThemeProvider theme={theme}>

                <IntlProvider
                    locale={locale}
                    messages={this.getMessages(locale)}>

                    <SnackbarProvider
                        maxSnack={3}
                        className="snackBar">

                        <Snackbar open={this.state.offline} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
                            <Alert severity="warning" sx={{width: '100%', zIndex: 3000}}>
                                <FormattedMessage id="offline.snackbar"/>
                            </Alert>
                        </Snackbar>

                        <GeneralContext.Provider value={{
                            appData: this.state.appData,
                            appDataService: this.state.appDataService,
                            login: this.login,
                            reloadAppData: this.loadAppData,
                            reloadBaseData: this.loadBaseData,
                            reloadTenderData: this.loadTenderData,
                            setValidationMap: this.setValidationMap,
                            setPriceOfferMap: this.setPriceOfferMap,
                            setProjectIdToUnitsMap: this.setProjectIdToUnitsMap,
                            setProjectList: this.setProjectList,
                            setOperationList: this.setOperationList,
                            payments: this.state.payments,
                            onPaymentsLoaded: this.onPaymentsLoaded,
                            operationEvents: this.state.operationEvents,
                            onOperationEventsLoaded: this.onOperationEventsLoaded,
                            currentUser: this.state.currentUser,
                            userState: this.state.userState,
                            loadUser: this.loadUser,
                            getUserStateValue: this.getUserStateValue,
                            setUserStateValue: this.setUserStateValue,
                            setShowOptimisticLockDialog: this.setShowOptimisticLockDialog,
                            showRouteUnavailableDialog: this.showRouteUnavailableDialog,
                            setActiveApp: this.setActiveApp,
                            setActiveProjectId: this.setActiveProjectId,
                            setActiveUnitId: this.setActiveUnitId,
                            setActiveOperationId: this.setActiveOperationId,
                            setActiveOperationIdForCockpit: this.setActiveOperationIdForCockpit,
                            setOfferProjectId: this.setOfferProjectId,
                            setProcedureVersion: this.setProcedureVersion,
                            setOrderData: this.setOrderData,
                            setToken: this.setToken,
                            setIndustryType: this.setIndustryType,
                            hasUnsavedChanges: () => { return this.hasUnsavedChanges; },
                            setUnsavedChanges: this.setUnsavedChanges,
                            offline: this.state.offline,
                            handleSupportOverlay: this.handleSupportOverlay,
                            showSupportOverlay: this.state.showSupportOverlay,
                            showNoLicenseDialog: this.showNoLicenseDialog,
                            logout: this.logout,
                            getActiveContract: this.getActiveContract,
                            missingBaseData: this.state.missingBaseData
                        }}>


                            <TexisionDialog
                                type={DIALOG_TYPE_INFO}
                                open={this.state.showTermsOfUse}
                                titleId="login.termsOfUse.title"
                                actionId="login.termsOfUse.confirm"
                                cancelId="commons.cancel.button"
                                onCancel={async () => {
                                    this.setState({showTermsOfUse: false})
                                    await postAsync('/logout');
                                    await this.logout();
                                }}
                                onAction={() => {
                                    this.setUserStateValue(TERMS_OF_USE_CHECKED, true)
                                    this.setState({showTermsOfUse: false})
                                }}
                                content={<>
                                    <DialogContentText id="alert-dialog-description-1">
                                        <Typography color="textPrimary">
                                            <FormattedMessage id="login.termsOfUse.hello" values={{
                                                title: this.context.currentUser ? this.props.intl.formatMessage({id: "constants.Salutation." + this.context.currentUser?.title}) : "",
                                                lastName: this.context.currentUser?.lastName
                                            }}/>
                                        </Typography>
                                    </DialogContentText>
                                    <DialogContentText id="alert-dialog-description-2">
                                        <Typography color="textPrimary">
                                            <FormattedMessage id="login.termsOfUse.text"/>
                                        </Typography>
                                    </DialogContentText>
                                    <TermsOfUse usedInDialog/>
                                </>}/>
    
    
                            <Router history={history} key={this.getRouterKey()}>

                                <ScrollToTop history={history}/>
    
                                <Prompt message={(nextLocation, _) => {
                                    if (history.location.pathname !== nextLocation.pathname && this.hasUnsavedChanges) {
                                        this.setState({nextPathName: nextLocation.pathname});
                                        return false;
                                    } else {
                                        return true;
                                    }
                                    }}/>
    
    
                                <TexisionDialog
                                    type={DIALOG_TYPE_WARNING}
                                    open={this.state.nextPathName !== null}
                                    titleId="commons.unsavedChanges.title"
                                    subtitleId="commons.unsavedChanges.subtitle"
                                    cancelId="commons.unsavedChanges.leave"
                                    actionId="commons.unsavedChanges.stay"
                                    onCancel={async() => {
                                        this.hasUnsavedChanges = false;
                                        // if you do not wait for a few milliseconds, routing is still blocked because context is not updated properly
                                        await new Promise(res => setTimeout(res, 50));
                                        history.push(this.state.nextPathName);
                                        this.setState({nextPathName: null});
                                    }}
                                    onAction={() => {
                                        this.setState({nextPathName: null});
                                    }}/>
    
                                <TexisionDialog
                                    type={DIALOG_TYPE_WARNING}
                                    open={this.state.showOptimisticLockDialog}
                                    titleId="commons.optimisticLock.title"
                                    subtitleId="commons.optimisticLock.subtitle"
                                    actionId="commons.optimisticLock.reload"
                                    onAction={() => {
                                        this.setState({showOptimisticLockDialog: false});
                                        window.location.reload();
                                    }}/>

                                <TexisionDialog
                                    type={DIALOG_TYPE_WARNING}
                                    open={this.state.showMissingBaseDataDialog}
                                    titleId="cockpit.baseData.missing.dialog.title"
                                    subtitleId="cockpit.baseData.missing.dialog.subtitle"
                                    cancelId="cockpit.baseData.remind.button"
                                    onCancel={() => this.setState({showMissingBaseDataDialog: false})}
                                    actionId="cockpit.baseData.navigate.button"
                                    onAction={() => {
                                        this.setState({showMissingBaseDataDialog: false});
                                        history.push(BASE_DATA_ROUTE);
                                    }}/>

                                <TexisionDialog
                                    type={DIALOG_TYPE_WARNING}
                                    open={this.state.showNoLicenseDialog}
                                    titleId={isOfferUser(this.state.currentUser)
                                        ? "navBar.paymentRequired.bidder.dialog.title" : "navBar.paymentRequired.dialog.title"}
                                    subtitleId={this.getNoLicenseDialogSubtitle()}
                                    cancelId="commons.close.label"
                                    onCancel={() => this.setState({showNoLicenseDialog: false, requiredLicenseType: null, customNoLicenseText: null})}
                                    actionId={isOfferUser(this.state.currentUser) ? null : "navBar.profile.showSubscription"}
                                    onAction={isOfferUser(this.state.currentUser) ? null : () => this.onPaymentRequiredDialogClosed()}/>
    
                                <TexisionDialog
                                    type={DIALOG_TYPE_WARNING}
                                    open={this.state.showRouteUnavailableDialog}
                                    titleId="commons.routeUnavailable.title"
                                    subtitleId="commons.routeUnavailable.subtitle"
                                    actionId="commons.okay.button"
                                    onAction={() => this.setState({showRouteUnavailableDialog: false})}/>
    
                                <TexisionDialog 
                                    type={DIALOG_TYPE_WARNING}
                                    open={this.state.showNewsletterDialog}
                                    titleId="login.newsletterAccount.title"
                                    subtitleId="login.newsletterAccount.subtitle"
                                    actionId="commons.yes.button"
                                    cancelId="commons.no.button"
                                    onAction={() => {
                                        const redirectToLogin = false;
                                        this.logout(redirectToLogin);
                                        this.setState({showNewsletterDialog: false});
                                        history.push({
                                            pathname: REGISTRATION_ROUTE, 
                                            state: {role: Role.BIDDER_READ_ONLY, mail: this.state.bidderName}
                                        });
                                    }}
                                    onCancel={() => this.setState({showNewsletterDialog: false, bidderName: null})}/>
    
                                {this.state.currentUser && this.state.appData
    
                                    ? this.texisionApp()
    
                                    : <PreLoginApp/>
    }
                            </Router>
        
                        </GeneralContext.Provider>

                    </SnackbarProvider>

                </IntlProvider>

            </ThemeProvider>
        );
    }
}

export default App;
