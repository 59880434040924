import { Card, CardContent, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { formatPrice } from "../../util/Util";

const PriceSheetSummary = ({currency, sumGross, sumNet, valueAddedTax}) => {

    const intl = useIntl();

    const sumNetto = sumNet ? formatPrice(sumNet) : "0,00";
    const sumBrutto = sumGross ? formatPrice(sumGross) : "0,00";

    return (
        <Card style={{marginBottom: 20}}>
            <CardContent>

                <Typography variant="h3">
                    <FormattedMessage id="bidder.priceSheet.fullNettoPrice.title"/>
                </Typography>

                <Typography variant="subtitle2">
                    {sumNetto + " " + currency}
                </Typography>

                <Typography variant="h3">
                    <FormattedMessage id="bidder.priceSheet.taxes.title"/>
                </Typography>

                <Typography variant="subtitle2">
                    {valueAddedTax ? valueAddedTax + " %" : intl.formatMessage({id: "bidder.priceSheet.noTaxes"})}
                </Typography>

                <Typography variant="h3">
                    <FormattedMessage id="bidder.priceSheet.fullBruttoPrice.title"/>
                </Typography>

                <Typography variant="subtitle2">
                    {valueAddedTax ? (sumBrutto + " " + currency) : intl.formatMessage({id: "bidder.priceSheet.noBrutto"})}
                </Typography>

            </CardContent>
        </Card>
    );
}

export {
    PriceSheetSummary
}
