import {Card, CardContent, Grid, LinearProgress, Typography} from "@material-ui/core";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {getAsync} from "../../services/BackendService";
import {isBidder} from "../../services/UserService";

import {withSnackbar} from 'notistack';
import {createErrorMessage, createSuccessMessage, isCockpit, isTender} from "../../util/Util";
import {
    BIDDER_COMMUNICATION_FILTER_ALL, BIDDER_COMMUNICATION_FILTER_NONE,
    BIDDER_DASHBOARD_ROUTE, DIALOG_TYPE_WARNING, MESSAGE_TYPES,
    OFFER_STATUS_SUBMITTED, PROJECT_STATUS_IS_PUBLISHED
} from "../../util/Constants";
import Select from "react-select";
import {FILE_TYPES, getAcceptedFileTypes, MAX_FILE_SIZE_IN_BYTE} from "../../util/DocumentUtil";

import "../../css/BidderCommunication.css";
import {withRouter} from "react-router-dom";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import { Header } from "../uiLibrary/Header";
import {GeneralContext} from "../contexts/GeneralContext";
import {SimpleTextCard} from "../uiLibrary/SimpleTextCard";
import {createAnswer, createInformation, createQuestion, getMessagesForBidder, getMessagesForTender} from "../../services/MessageService";
import {getActiveProject, getActiveProjectByOperationId} from "../../services/ProjectService";
import MessageCreateButton from "./MessageCreateButton";
import MessageCreateForm from "./MessageCreateForm";
import ChatHistory from "./ChatHistory";


class BidderCommunication extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            filter: BIDDER_COMMUNICATION_FILTER_NONE,
            messages: [],
            isInitializing: true,
            isUploading: false,
            hasError: false,
            isCreating: false,
            newText: "",
            newSubject: "",
            openedAnswerId: null,
            newAnswerText: "",
            newFiles: [],
            answerFiles: [],
            notFound: false,
            numberOfOffers: 0,
            showWithdrawOffersWarning: false,
            // function that will be called if the user decides to post the information/answer despite getting the offers withdraw warning
            onWarningIgnored: null,
            showOwnWithdrawOfferWarning: false,
            hasSubmittedOffer: false,
            hasNoOffer: false
        };
    }

    async componentDidMount() {
        this.setState({isInitializing: true});
        await this.loadData();
        this.setState({isInitializing: false});
    }

    async componentDidUpdate(prevProps) {
        let hasUnsavedChanges;

        hasUnsavedChanges = !!(this.state.newText || this.state.newSubject || this.state.newAnswerText
            || (this.state.newFiles && this.state.newFiles.length > 0)
            || (this.state.answerFiles && this.state.answerFiles.length > 0));

        this.context.setUnsavedChanges(hasUnsavedChanges);

        if (this.props.match.params.operationId !== prevProps.match.params.operationId) {
            this.setState({filter: BIDDER_COMMUNICATION_FILTER_NONE});
            await this.loadData();
        }
    }

    loadData = async() => {
        await this.loadMessages();
        await this.loadOffers();
        await this.loadOwnOffer();
    }

    getProject = () => {
        return isCockpit() || isTender()
            ? getActiveProject(this.context)
            : getActiveProjectByOperationId(this.context, this.props.match.params.operationId);
    }

    getProjectId = () => {
        return this.getProject().id;
    }

    loadOffers = async() => {
        if (!isBidder(this.context.currentUser) && this.getProject().procedure) {

            const response = await getAsync("/offers/" + this.getProjectId());

            if (response?.status === 200) {

                this.setState({numberOfOffers: response.data?.filter(o => o.status === OFFER_STATUS_SUBMITTED)?.length ?? 0});

            } else if ([401, 403].includes(response?.status)) {

                await this.context.logout();

            }
        }
    }

    loadOwnOffer = async() => {
        if (isBidder(this.context.currentUser) && this.getProject().procedure) {
            const response = await getAsync("/offer/" + this.getProjectId());

            if (response?.status === 200) {
                this.setState({hasSubmittedOffer: response.data?.status === OFFER_STATUS_SUBMITTED});

            } else if ([401, 403].includes(response?.status)) {

                await this.context.logout();

            }
        }
    }

    loadMessages = async() => {
        let messages = [];
        if (isBidder(this.context.currentUser)) {
            messages = await getMessagesForBidder(this.context, this.props, this.getProjectId());
        } else {
            messages = await getMessagesForTender(this.context, this.props);
        }
        if (messages) {
            for (let i = 0; i < messages.length; i++) {
                if (messages[i].answers && messages[i].answers.length > 1) {
                    messages[i].answers = messages[i].answers.sort((a, b) => a.createdAt - b.createdAt);
                }
            }
            this.setState({messages: messages.sort((a, b) => a.createdAt - b.createdAt)});
        } else {
            this.setState({hasError: true});
        }
    }

    uploadNewMessage = async() => {
        if (isBidder(this.context.currentUser)) {
            if (this.state.hasSubmittedOffer) {
                this.setState({showOwnWithdrawOfferWarning: true});
            } else {
                await this.uploadQuestion();
            }
        } else {
            if (this.state.numberOfOffers > 0) {
                this.setState({
                    showWithdrawOffersWarning: true, 
                    onWarningIgnored: () => this.uploadInformation()
                });
            } else {
                await this.uploadInformation();
            }
        }
    }

    uploadInformation = async() => {

        this.setState({isUploading: true});

        const information = {
            projectId: this.context.appData.activeProjectId,
            type: MESSAGE_TYPES.INFORMATION,
            text: this.state.newText,
            subject: this.state.newSubject,
            username: this.context.currentUser.username
        };

        const response = await createInformation(this.context, this.props, information, this.state.newFiles);

        if (response?.status === 200) {

            await this.loadData();
            this.setState({isCreating: false, newText: "", newSubject: "", newFiles: []});
            createSuccessMessage(this.props.intl.formatMessage({id: "bidderCommunication.createInformation.successMessage"}), this.props);

        } else if ([401, 403].includes(response?.status)) {

            await this.context.logout();

        } else {

            createErrorMessage(this.props.intl.formatMessage({id: "bidderCommunication.uploadInformation.error"}), this.props);

        }

        this.setState({isUploading: false});
    }

    uploadQuestion = async() => {

        this.setState({isUploading: true});

        const question = {
            projectId: this.getProjectId(),
            type: MESSAGE_TYPES.QUESTION,
            text: this.state.newText,
            subject: this.state.newSubject,
            username: this.context.currentUser.username
        };

        const response = await createQuestion(this.context, this.props, question, this.state.newFiles);

        if (response?.status === 200) {

            await this.loadData();
            this.setState({isCreating: false, newText: "", newSubject: "", newFiles: []});
            createSuccessMessage(this.props.intl.formatMessage({id: "bidderCommunication.createQuestion.successMessage"}), this.props);

        } else if ([401, 403].includes(response?.status)) {

            await this.context.logout();

        } else if (response?.status === 405) {
            createErrorMessage(this.props.intl.formatMessage({id: "bidderCommunication.uploadQuestion.deadlineOver"}), this.props);

        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "bidderCommunication.uploadQuestion.error"}), this.props);

        }
        this.setState({isUploading: false});
    }

    uploadAnswer = async(parentId, parentSubject, isPrivate) => {

        this.setState({isUploading: true});

        const answer = {
            projectId: this.getProjectId(),
            type: MESSAGE_TYPES.ANSWER,
            text: this.state.newAnswerText,
            parentId: parentId,
            subject: parentSubject,
            username: this.context.currentUser.username,
            isPrivate: isPrivate
        };

        const response = await createAnswer(this.context, this.props, answer, this.state.answerFiles);

        if (response?.status === 200) {

            await this.loadData();
            this.setState({openedAnswerId: null, newAnswerText: "", answerFiles: []});
            const successMessage = "bidderCommunication.createAnswer." + (isPrivate ? "private" : "public") + ".successMessage";
            createSuccessMessage(this.props.intl.formatMessage({id: successMessage}), this.props);

        } else if ([401, 403].includes(response?.status)) {

            await this.context.logout();

        } else {

            createErrorMessage(this.props.intl.formatMessage({id: "bidderCommunication.uploadAnswer.error"}), this.props);

        }

        this.setState({isUploading: false});
    }

    selectFile = async(file, isCreating) => {
        if (file) {
            if (file.size >= MAX_FILE_SIZE_IN_BYTE) {
                this.setState({showDocumentTooLargeDialog: true});
            } else if ((isCreating && this.state.newFiles.find(f => f.name === file.name)) 
                || (!isCreating && this.state.answerFiles.find(f => f.name === file.name))) {
                createErrorMessage(this.props.intl.formatMessage({id: "bidderCommunication.duplicateFileName"}), this.props);
            } else if (isCreating) {
                const newFiles = this.state.newFiles;
                newFiles.push(file);
                this.setState({newFiles});
            } else {
                const answerFiles = this.state.answerFiles;
                answerFiles.push(file);
                this.setState({answerFiles});
            }
        }
    }

    deleteFile = (file, isCreating) => {
        if (isCreating) {
            let files = this.state.newFiles;
            files = files.filter(f => f.name !== file.name);
            this.setState({newFiles: files});
        } else {
            let files = this.state.answerFiles;
            files = files.filter(f => f.name !== file.name);
            this.setState({answerFiles: files});
        }
    }

    acceptedFileTypes = () => {
        return getAcceptedFileTypes([
            FILE_TYPES.CSV,
            FILE_TYPES.DOC,
            FILE_TYPES.DOCX,
            FILE_TYPES.IMAGE,
            FILE_TYPES.PDF,
            FILE_TYPES.TEXT_CSV,
            FILE_TYPES.TXT,
            FILE_TYPES.XLS,
            FILE_TYPES.XLSX
        ]);
    }

    messageCreateForm = () => {
        return (
            <MessageCreateForm
                acceptedFileTypes={this.acceptedFileTypes()}
                files={this.state.newFiles}
                text={this.state.newText}
                subject={this.state.newSubject}
                isUploading={this.state.isUploading}
                onCancel={() => this.setState({isCreating: false, newText: "", newSubject: "", newFiles: []})}
                onSave={() => this.uploadNewMessage()}
                onSubjectChange={(newSubject) => this.setState({newSubject})}
                onTextChange={(newText) => this.setState({newText})}
                deleteFile={this.deleteFile}
                selectFile={this.selectFile}
            />
        );
    }

    messageCreateButton = () => {
        //procedure is null, if bidder is not invited to offer round
        const notInvited = !this.getProject().procedure
        const questionDeadline = this.getProject().procedure?.questionsDeadline;
        if (isCockpit()) {
            return <></>;
        } else {
            return (
                <MessageCreateButton
                    disabled={ notInvited
                        || this.getProject().status !== PROJECT_STATUS_IS_PUBLISHED
                        || (questionDeadline && questionDeadline < new Date().getTime())}
                    onClick={() => this.setState({isCreating: true, newText: "", newSubject: "", newFiles: [], openedAnswerId: null, newAnswerText: "", answerFiles: []})}
                />
            );
        }
    }

    header = () => {
        if (isTender()) {
            if (getActiveProject(this.context)?.status !== PROJECT_STATUS_IS_PUBLISHED) {
                return (
                    <>
                        <Header titleId="bidderCommunication.title"/>
                        <SimpleTextCard show={true} textId="bidderCommunication.notPublished"/>
                    </>
                );
            }
        } else if (isCockpit()) {
            return <Header
                titleId="bidderCommunication.title"
                subtitleId={"bidderCommunication.cockpit.subtitle"}/>
        }

        return <Header
            titleId="bidderCommunication.title"
            subtitleId={isBidder(this.context.currentUser) ? "bidderCommunication.bidder.subtitle" : "bidderCommunication.user.subtitle"}/>
    }

    render() {



        if (this.state.isInitializing) {
            return (<div/>);
        }

        return (
            <>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.notFound}
                    titleId="commons.projectNotFound.title"
                    subtitleId="commons.projectNotFound.subtitle"
                    actionId="commons.toBidderDashboard.button"
                    onAction={() => {
                        this.context.setUnsavedChanges(false);
                        this.props.history.push(BIDDER_DASHBOARD_ROUTE);
                        this.context.reloadAppData();
                    }}
                />

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showWithdrawOffersWarning && this.state.onWarningIgnored}
                    titleId="bidderCommunication.withdrawOffers.dialog.title"
                    subtitleId="bidderCommunication.withdrawOffers.dialog.subtitle"
                    cancelId="commons.cancel.button"
                    actionId="commons.confirm.button"
                    onCancel={() => this.setState({showWithdrawOffersWarning: false, onWarningIgnored: null})}
                    onAction={() => {
                        this.state.onWarningIgnored();
                        this.setState({showWithdrawOffersWarning: false, onWarningIgnored: null});
                    }}
                />

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showPublicAnswerWarning && this.state.onWarningIgnored}
                    titleId="bidderCommunication.publicAnswer.dialog.title"
                    subtitleId="bidderCommunication.publicAnswer.dialog.subtitle"
                    cancelId="commons.cancel.button"
                    actionId="commons.confirm.button"
                    onCancel={() => this.setState({showPublicAnswerWarning: false, onWarningIgnored: null})}
                    onAction={() => {
                        this.state.onWarningIgnored();
                        this.setState({showPublicAnswerWarning: false, onWarningIgnored: null});
                    }}
                />

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showOwnWithdrawOfferWarning}
                    titleId="bidderCommunication.withdrawOwnOffer.dialog.title"
                    subtitleId="bidderCommunication.withdrawOwnOffer.dialog.subtitle"
                    cancelId="commons.cancel.button"
                    actionId="commons.confirm.button"
                    onCancel={() => this.setState({showOwnWithdrawOfferWarning: false})}
                    onAction={() => {
                        this.uploadQuestion();
                        this.setState({showOwnWithdrawOfferWarning: false});
                    }}
                />

                <div style={{paddingBottom: this.state.isUploading ? "26px" : "30px"}}>
                    {this.state.isUploading && <LinearProgress/>}
                </div>

                {this.header()}

                {!!this.state.messages?.length && <Grid container alignItems="center" style={{marginBottom: 50}}>

                    <Grid item style={{marginRight: 20}}>
                        <FormattedMessage id="bidderCommunication.filter"/>:
                    </Grid>

                    <Grid item style={{width: 200}}>
                        <Select
                            id="bidder-communication-filter-select"
                            value={{ 
                                value: this.state.filter, 
                                label: this.props.intl.formatMessage({id: "bidderCommunication.filter." + this.state.filter})
                            }}
                            onChange={(state) => this.setState({filter: state.value})}
                            options={BIDDER_COMMUNICATION_FILTER_ALL.map(filter => {return {
                                value: filter, 
                                label: this.props.intl.formatMessage({id: "bidderCommunication.filter." + filter})}
                            })}/>
                    </Grid>

                </Grid>}

                {this.state.hasError
                    ? <Card>
                        <CardContent className="emptyOrErrorCard">
                            <Typography variant="subtitle1">
                                <FormattedMessage id="bidderCommunication.error.subtitle"/>
                            </Typography>
                        </CardContent>
                    </Card>

                    : <ChatHistory
                        acceptedFileTypes={this.acceptedFileTypes()}
                        numberOfOffers={this.state.numberOfOffers}
                        selectFile={this.selectFile}
                        messages={this.state.messages}
                        isCreating={this.state.isCreating}
                        openedAnswerId={this.state.openedAnswerId}
                        isUploading={this.state.isUploading}
                        filter={this.state.filter}
                        answerFiles={this.state.answerFiles}
                        deleteFile={this.deleteFile}
                        loadData={this.loadData}
                        onRespond={(id) => this.setState({openedAnswerId: id, newAnswerText: "", answerFiles: [], isCreating: false, newText: "", newSubject: "", newFiles: []})}
                        onResponseCancel={() => this.setState({openedAnswerId: null, newAnswerText: "", answerFiles: []})}
                        messageCreateForm={this.messageCreateForm()}
                        messageCreateButton={this.messageCreateButton()}
                        newAnswerText={this.state.newAnswerText}
                        onAnswerChange={(newAnswerText) => this.setState({newAnswerText})}
                        showWithdrawOffersWarning={(parentMessageId, subject, isPrivate) => this.setState({
                            showWithdrawOffersWarning: true,
                            onWarningIgnored: () => isPrivate
                                ? this.uploadAnswer(parentMessageId, subject, true)
                                : this.setState({
                                    showPublicAnswerWarning: true,
                                    onWarningIgnored: () => this.uploadAnswer(parentMessageId, subject, false)
                                })
                        })}
                        showPublicAnswerWarning={(parentMessageId, subject) => this.setState({
                            showPublicAnswerWarning: true,
                            onWarningIgnored: () => this.uploadAnswer(parentMessageId, subject, false)
                        })}
                        uploadPrivateAnswer={(parentMessageId, subject) => this.uploadAnswer(parentMessageId, subject, true)}/>}
                
            </>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(BidderCommunication)));
