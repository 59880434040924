import {deleteAsyncCatch, getAsyncCatch, postAsyncCatch, putAsyncCatch} from "./BackendService";
import {updateValidationMap} from "./ProjectService";

export async function getUnitProfessionalGroups(context, props) {
    const unitProfessionalGroups = await getAsyncCatch(context, "/unit/professional/" + context.appData.activeUnitId, props);
    return unitProfessionalGroups ? unitProfessionalGroups : [];
}

export async function getProfessionalGroupById(context, props, professionalGroupId) {
    const professionalGroup = await getAsyncCatch(context, "/professional/" + professionalGroupId, props);
    return professionalGroup ? professionalGroup : null;
}

export async function createProfessionalGroup(context, props, professionalGroup, activeProjectId, hideSuccess) {
    const projectId = activeProjectId ? activeProjectId : context.appData.activeProjectId;
    const createdProfessionalGroup = await postAsyncCatch(context, "/professional/" + projectId, professionalGroup, props, hideSuccess);
    return createdProfessionalGroup ? createdProfessionalGroup : null;
}

export async function updateProfessionalGroup(context, props, professionalGroup, activeProjectId) {
    const projectId = activeProjectId ? activeProjectId : context.appData.activeProjectId;
    const updatedProfessionalGroup = await putAsyncCatch(context, "/professional/" + projectId, professionalGroup, props);
    if (updatedProfessionalGroup) {
        await updateValidationMap(context);
    }
    return updatedProfessionalGroup ? updatedProfessionalGroup : null;
}

export async function deleteProfessionalGroup(context, props, professionalGroupId) {
    const success = await deleteAsyncCatch(context, "/professional/" + context.appData.activeProjectId + "/" + professionalGroupId, props);
    if (success) {
        await updateValidationMap(context);
    }
    return success;
}
