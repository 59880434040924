import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Icon, Paper, Table, 
    TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { bodyBackgroundColor } from "../../../util/ColorTheme";
import { offerDisabledTooltip, offerSubmittedTooltip } from "../../../util/ProcedureUtil";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {formatPrice} from "../../../util/Util";
import React, { useContext } from "react";
import { GeneralContext } from "../../contexts/GeneralContext";
import {wordBreak} from "../../../util/TableUtil";
import CalculationTooltip from "../calculations/CalculationTooltip";

const PriceSheetTable = ({
    readOnly,
    singlePriceOnly,
    editButtonLabel,
    editable,
    headerTitles,
    isEditing,
    onSave,
    priceGroup,
    resetItemMap,
    rows,
    setIsEditing,
    title,
    // following props are only used for bidders
    offer,
    project,
    type,
    isPrevious
}) => {

    const formatMessage = useIntl().formatMessage;
    const context = useContext(GeneralContext);

    const disabledTooltip = offerDisabledTooltip(project, offer, isPrevious, formatMessage)
        || offerSubmittedTooltip(offer, formatMessage);

    const tooltip = disabledTooltip ? disabledTooltip : "";

    const isUnitIncomplete = (priceItemMap) => {
        let isIncomplete = false;
        for (let item of Object.values(priceItemMap)) {
            if (!item.price || filteredArticleNumbers(item).length === 0) {
                isIncomplete = true;
                break;
            }
        }
        return isIncomplete;
    }

    const filteredArticleNumbers = (priceItem) => {
        return priceItem.articleNumbers.filter((number) => number !== "" && number !== " ");
    }

    const tablePrices = (priceGroup) => {
        const hasSumNet = priceGroup?.sumNet || priceGroup?.sumNet === 0;
        const hasSumGross = priceGroup?.sumGross || priceGroup?.sumGross === 0;
        if (!hasSumNet && ! hasSumGross) {
            return "";
        }
        return " ("
            + (hasSumNet ? (formatMessage({id: "bidder.priceSheet.tablePrices.netto.title"}) + ": "
            + formatPrice(priceGroup.sumNet) + " " + project?.currency) : "") 
            + ((hasSumNet && hasSumGross) ? ", " : "")
            + (hasSumGross ? (formatMessage({id: "bidder.priceSheet.tablePrices.brutto.title"}) + ": "
            + formatPrice(priceGroup.sumGross) + " " + project?.currency) : "")
            + ")";
    }

    return (
        <Accordion elevation={1} key={title}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-label="Expand"
                aria-controls="operating-resouces-content"
                id="operating-resouces-header">
                <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Grid item xs>
                        <Typography variant="subtitle2" component="span">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" component="span">
                            {tablePrices(priceGroup)}
                        </Typography>
                    </Grid>
                    {!readOnly && <Grid item>
                        {isUnitIncomplete(priceGroup.priceItemMap) && <div style={{color: "orange"}}>
                            {formatMessage({id: "bidder.priceSheet.tableIncomplete.hint"})}
                        </div>}
                    </Grid>}
                    {!readOnly && <Grid item>
                        <Icon style={{paddingLeft: "10px", color: isUnitIncomplete(priceGroup.priceItemMap) ? "orange" : "grey"}}>edit</Icon>
                    </Grid>}
                </Grid>
            </AccordionSummary>

            <AccordionDetails>

                <Grid container>

                    {!readOnly && <Grid item xs={12} style={{marginBottom: 15}}>
                        <Grid container justifyContent="flex-end">
                            {isEditing
                            ? <div>
                                <Button
                                    onClick={() => {
                                        resetItemMap();
                                        setIsEditing(false);
                                        context.setUnsavedChanges(false);
                                    }}
                                    color="primary"
                                    style={{minWidth: "150px"}}>
                                    <FormattedMessage id="commons.cancel.button"/>
                                </Button>
                                <Button
                                    onClick={async() => {
                                        await onSave();
                                        setIsEditing(false);
                                        context.setUnsavedChanges(false);
                                    }}
                                    color="primary"
                                    variant="contained"
                                    style={{minWidth: "150px"}}>
                                    <FormattedMessage id="commons.save.button"/>
                                </Button>
                            </div>
                            : <Tooltip title={tooltip}>
                                <div>
                                    <Button
                                        onClick={() => setIsEditing(true)}
                                        color="primary"
                                        disabled={!!disabledTooltip || !editable}
                                        style={{minWidth: "150px"}}>
                                        <FormattedMessage id={editButtonLabel ?? "bidder.priceSheet.editTable.button"}/>
                                    </Button>
                                </div>
                            </Tooltip>}
                        </Grid>
                    </Grid>}

                    <Grid item xs={12}>

                        <TableContainer component={Paper} style={{overflow: "scroll"}}>

                            <Table aria-label="delivery-quantity-table" size="small">

                                <TableHead style={{backgroundColor: bodyBackgroundColor}}>
                                    <TableRow>

                                        {headerTitles.map(headerTitle => <TableCell key={headerTitle}>
                                            <TableSortLabel>{formatMessage({id: headerTitle})}</TableSortLabel>
                                        </TableCell>)}

                                        <TableCell>
                                            <TableSortLabel>
                                                {formatMessage({id: "bidder.priceSheet.table.price.title"}) + " " + project?.currency}
                                            </TableSortLabel>
                                        </TableCell>

                                        {!singlePriceOnly && <TableCell>
                                            <TableSortLabel>
                                                <Tooltip title={<CalculationTooltip titleId={title} type={type}/>} style={{width: "30%"}}>
                                                    {wordBreak(formatMessage({id: "bidder.priceSheet.table.netto.title"}) + " " + project?.currency)}
                                                </Tooltip>
                                            </TableSortLabel>
                                        </TableCell>}

                                        {!singlePriceOnly && <TableCell>
                                            <TableSortLabel>
                                                {wordBreak(formatMessage({id: "bidder.priceSheet.table.brutto.title"}) + " " + project?.currency)}
                                            </TableSortLabel>
                                        </TableCell>}

                                        <TableCell>
                                            <TableSortLabel>
                                                {wordBreak(formatMessage({id: "bidder.priceSheet.table.alternativeName.title"}))}
                                            </TableSortLabel>
                                        </TableCell>

                                        <TableCell>
                                            <Tooltip title={readOnly ? "" : formatMessage({id: "bidder.priceSheet.articleNumber.tooltip"})}>
                                                <div>
                                                    <TableSortLabel>
                                                        {wordBreak(formatMessage({id: "bidder.priceSheet.table.articleNumber.title"}))}
                                                    </TableSortLabel>
                                                </div>
                                            </Tooltip>
                                        </TableCell>

                                        <TableCell>
                                            <Tooltip title={readOnly ? "" : formatMessage({id: "bidder.priceSheet.articleNumber.tooltip"})}>
                                                <div>
                                                    <InfoOutlinedIcon/>
                                                </div>
                                            </Tooltip>
                                        </TableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {rows}
                                </TableBody>

                            </Table>

                        </TableContainer>

                    </Grid>

                </Grid>

            </AccordionDetails>
        </Accordion>
    );
}

export {
    PriceSheetTable
}
