import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ImageGallery from 'react-image-gallery';
import {FormattedMessage, injectIntl} from 'react-intl';
import Divider from '@material-ui/core/Divider';

import {DE, DIALOG_TYPE_WARNING} from '../../util/Constants';
import {ARTICLE_CONFIGURATOR_ROUTE} from '../../util/Constants'
import GetAppIcon from '@material-ui/icons/GetApp';
import Icon from '@material-ui/core/Icon';
import {downloadUsingGet, deleteRequest} from '../../services/BackendService';
import {isAdmin} from '../../services/UserService';
import FileDownload from 'js-file-download';

import PropTypes from 'prop-types';
import '../../css/ArticleDetail.css';

import TexisionDialog from '../uiLibrary/TexisionDialog';
import {GeneralContext} from "../contexts/GeneralContext";
import {texisionRed} from "../../util/ColorTheme";
import {getFilterCategoriesText, getFilterOptionsText} from "../../util/ArticleHelper";


class ArticleDetail extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {showDeleteWarningDialog: false};
    }

    handleDownloadDatasheet = () => {
        const id = this.props.article.id;
        let projectId = this.context.appData.activeProjectId;
        downloadUsingGet('/datasheet/' + id + "/" + projectId + "/" + DE).then(response => {
            if (response?.status === 200) {
                const fileName = response.headers['content-disposition'].split("filename=")[1];
                FileDownload(response.data, fileName);
            } else if ([401, 403].includes(response?.status)) {
                this.context.logout();
            }
        });
    }

    deleteArticle = () => {
        deleteRequest('/article/configuration/' + this.props.article.id).then(response => {
            if (response?.status === 200) {
                this.props.handleArticleDeleted();
                this.props.handleDialogClose();
            } else if ([401, 403].includes(response?.status)) {
                this.context.logout();
            }
        });
    }

    handleArticleEdit = () => {
        this.props.history.push({pathname: ARTICLE_CONFIGURATOR_ROUTE, state: {article: this.props.article}});
    }

    filterOptions = (article) => {
        if (article.filterCategories?.length) {
            return getFilterCategoriesText(article.filterCategories, this.props.intl);
        } else if (article.filterOptions?.length) {
            return getFilterOptionsText(article.filterOptions);
        } else {
            return null;
        }
    }

    render() {

        const article = this.props.article;
        const showDialog = this.props.showDialog;

        if (!article) {
            return <div/>;
        }
        
        const imageArray = article.images?.map(imageVo => {
            return {
                original: imageVo.image?.url,
                thumbnail: imageVo.thumbnail?.url,
            };
        });

        const hasImages = imageArray?.length > 0;

        return (
            <div>
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDeleteWarningDialog}
                    titleId="commons.warning.label"
                    subtitleId="articleDetails.deleteWarning.continue"
                    actionId="commons.yes.button"
                    cancelId="commons.cancel.button"
                    onAction={() => this.deleteArticle()}
                    onCancel={() => this.setState({showDeleteWarningDialog: false})}/>

                <TexisionDialog
                    open={showDialog}
                    titleId="articleOverview.categories.title"
                    actionId="commons.close.label"
                    onAction={() => this.props.handleDialogClose()}
                    content={<Grid container spacing={2}>

                        {hasImages && <Grid item xs={6}>
                            <ImageGallery
                                items={imageArray} 
                                showPlayButton={false} 
                                showThumbnails={hasImages} 
                                showFullscreenButton={hasImages} 
                                thumbnailPosition={"bottom"}/>
                        </Grid>}

                        <Grid item xs={hasImages ? 6 : 12}>

                            <Grid direction="column" container spacing={3}>

                                <Grid item xs={12}>
                                    <Typography variant="caption" style={{color: "grey"}}>
                                        {this.props.intl.formatMessage({id: "constants.Category." + article.category}) + " - "}
                                        {this.props.intl.formatMessage({id: "constants.SubCategory." + (article.subcategory ?? article.subCategory)}) + " - "}
                                        {this.props.intl.formatMessage({id: "constants.ArticleCategory." + article.articleCategory})}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{fontWeight: "bold"}}>
                                        {article.description}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        {this.filterOptions(article)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>

                                        <Grid item xs={12}>
                                            <br></br>
                                            <Divider/>
                                            <br></br>
                                        </Grid>

                                        <Grid item>
                                            {isAdmin(this.context.currentUser) && this.props.handleArticleDeleted && this.props.mayEditAndDelete
                                                && <Button
                                                    style={{color: texisionRed, borderColor: texisionRed}}
                                                    variant="outlined"
                                                    startIcon={<Icon className="icon-trash" style={{fontSize: "18pt"}}/>}
                                                    onClick={() => this.setState({showDeleteWarningDialog: true})}>
                                                    <FormattedMessage id="commons.delete.button"/>
                                                </Button>
                                            }
                                        </Grid>

                                        <Grid item>
                                            {isAdmin(this.context.currentUser) && this.props.mayEditAndDelete
                                                && <Button
                                                    variant="outlined"
                                                    startIcon={<Icon className="icon-edit" style={{fontSize: "18pt"}}/>}
                                                    onClick={this.handleArticleEdit}>
                                                    <FormattedMessage id="commons.edit.button"/>
                                                </Button>
                                            }
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                onClick={this.handleDownloadDatasheet}
                                                color="primary"
                                                startIcon={<GetAppIcon/>}>
                                                <FormattedMessage id="articleDetails.downloadDatasheet"/>
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>}/>
            </div>
        );
    }
}

ArticleDetail.propTypes = {
    showDialog: PropTypes.bool.isRequired,
    handleDialogClose: PropTypes.func.isRequired
};

export default injectIntl(withRouter(ArticleDetail));
