import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { Component } from "react";
import { injectIntl } from "react-intl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { formatDate } from "../../../util/DocumentUtil";
import OperationDifferencesTable from "../contractchanges/OperationDifferencesTable";
import { Stack } from "@mui/material";

class ContractPlan extends Component {

    getTitle = () => {
        const effectiveFrom = this.props.effectiveFrom;
        if (!effectiveFrom || effectiveFrom === 1) {
            return this.props.intl.formatMessage({id: "contractStatus.differences.start.title"});
        }
        return this.props.intl.formatMessage({id: "contractStatus.differences.title"}) + " " + this.props.intl.formatMessage({id: "cockpit.contractChanges.effectiveFrom"}) + " "
                + formatDate(this.props.intl, effectiveFrom);
    }

    render() {
        if (this.props.workingOperationDifference) {

            return (
                <Accordion
                    style={{marginTop: 40, minWidth: 300}}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}>
                        <h2 style={{fontWeight: "bold", fontSize: 20}}>
                            {this.getTitle()}
                        </h2>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Stack spacing={1} width={"100%"}>
                            <OperationDifferencesTable operationDifference={this.props.workingOperationDifference}/>
                        </Stack>
                    </AccordionDetails>

                </Accordion>
            );
        } else {
            return null;
        }
    }
}

export default injectIntl(ContractPlan);
