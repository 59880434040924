import { Box, Tab, Tabs } from "@material-ui/core";
import { useIntl } from "react-intl";
import {primaryColor} from "../../../../util/ColorTheme";
import { withStyles } from '@material-ui/core';

const StyledTab = withStyles({
    root: {
        '&$selected': {
            backgroundColor: primaryColor,
            color: '#ffffff',
        },
    },
    selected: {},
})(Tab);

const InvoiceStatisticsTabs = ({onTabChange, selectedNodes, selectedTab}) => {

    const intl = useIntl();

    if (!selectedNodes || selectedNodes.length === 0) {
        return null;
    }

    return (
        <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: 25}}>
            <Tabs
                TabIndicatorProps={{style: {backgroundColor: "#ffffff"}}}
                value={selectedTab}
                onChange={(_, newTab) => onTabChange(newTab)}
                style={{width: "100%"}}>
                <StyledTab fullWidth value="priceTotal" label={intl.formatMessage({id: "priceSheet.column.priceTotal"})}/>
                <StyledTab fullWidth value="pricePerCase" label={intl.formatMessage({id: "priceSheet.column.pricePerCase"})}/>
                <StyledTab fullWidth value="pricePerDay" label={intl.formatMessage({id: "priceSheet.column.pricePerDay"})}/>
                <StyledTab fullWidth value="price" label={intl.formatMessage({id: "priceSheet.column.price"})}/>
                <StyledTab fullWidth value="amount" label={intl.formatMessage({id: "priceSheet.column.amount"})}/>
                <StyledTab fullWidth value="amountPerCase" label={intl.formatMessage({id: "priceSheet.column.amountPerCase"})}/>
                <StyledTab fullWidth value="amountPerDay" label={intl.formatMessage({id: "priceSheet.column.amountPerDay"})}/>
            </Tabs>
        </Box>
    );
}

export {
    InvoiceStatisticsTabs
}
