import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {Grid} from "@mui/material";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {withSnackbar} from "notistack";
import DeliveryOrPickupAddressTile from "../shared/DeliveryOrPickupAddressTile";
import ArticleAssignmentTile from "./assignment/ArticleAssignmentTile";
import QuantitiesTile from "./quantities/QuantitiesTile";
import {DELIVERY, LOGISTIC_TYPE_CENTRAL} from "../../../../util/Constants";
import {getActiveUnit} from "../../../../services/BusinessUnitService";

class DeliveryAddressUnit extends Component {

    static contextType = GeneralContext;

    render() {
        const mayAssignArticles = this.props.deliveryAddress?.logisticType === LOGISTIC_TYPE_CENTRAL
            && getActiveUnit(this.context)?.deliveryType === LOGISTIC_TYPE_CENTRAL;
        return (
          <div style={{marginLeft: 30, marginRight: 30}}>

              <Grid container justifyContent="space-between" style={{marginBottom: 30}} wrap="nowrap">

                  <Grid item>
                      <DeliveryOrPickupAddressTile
                          key={this.props.deliveryAddress?.id}
                          readOnly={this.props.readOnly}
                          addressType={DELIVERY}
                          address={this.props.deliveryAddress}
                          allDeliveryAddresses={this.props.allDeliveryAddresses}
                          allPickupAddresses={this.props.allPickUpAddresses}
                          onSave={this.props.onAddressSave}/>
                  </Grid>

                  {mayAssignArticles ? null : <>
                      <Grid item>
                          <ArticleAssignmentTile
                              readOnly={this.props.readOnly}
                              assortmentData={this.props.assortmentData}
                              deliveryAddress={this.props.deliveryAddress}
                              allAreas={this.props.allAreas}
                              allProfessionalGroups={this.props.allProfessionalGroups}
                              allAssignedArticles={this.props.allAssignedArticles?.filter(a => a.deliveryAddressId === this.props.deliveryAddress.id)}
                              onSave={this.props.onAssignmentSave}
                          />
                      </Grid>

                      <Grid item>
                          <QuantitiesTile
                              readOnly={this.props.readOnly}
                              assortmentData={this.props.assortmentData}
                              deliveryAddress={this.props.deliveryAddress}
                              allAreas={this.props.allAreas}
                              allProfessionalGroups={this.props.allProfessionalGroups}
                              allAssignedArticles={this.props.allAssignedArticles?.filter(a => a.deliveryAddressId === this.props.deliveryAddress.id)}
                              onSave={this.props.onQuantitiesSave}/>
                      </Grid>
                  </>}

              </Grid>

          </div>
        );
    }
}

export default withSnackbar(injectIntl(DeliveryAddressUnit));
