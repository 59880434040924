import {Component} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core";
import MaterialTable from "material-table";
import {renderFeaturesWithoutType} from "../../../util/TableUtil";
import {bodyBackgroundColor, texisionFontColorDark} from "../../../util/ColorTheme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {injectIntl} from "react-intl";

class OperatingResourceTable extends Component {

    operatingResourceImage = (resource) => {
        if (!resource.imageVos.length) {
            return null;
        } else {
            return <img
                style={{maxHeight: "6rem"}}
                className="operatingResourceImg"
                alt="OperatingResourceImage"
                src={resource.imageVos[0].image?.url}/>;
        }
    }

    operatingResourcesTable = () => {
        return <Grid item xs={12}>
            <MaterialTable
                columns={[
                    {title: this.props.intl.formatMessage({id: "operatingResources.table.category"}), field: "category",
                        render: resource => this.props.intl.formatMessage({id: "constants.ResourceCategory." + resource.category})},
                    {title: this.props.intl.formatMessage({id: "operatingResources.table.subCategory"}), field: "subCategory",
                        render: resource => this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + resource.subCategory})},
                    {title: this.props.intl.formatMessage({id: "operatingResources.table.name"}), field: "name"},
                    {title: this.props.intl.formatMessage({id: "operatingResources.table.description"}), field: "description"},
                    {title: this.props.intl.formatMessage({id: "operatingResources.table.features"}), field: "features",
                        render: resource => renderFeaturesWithoutType(resource.features)},
                    {title: this.props.intl.formatMessage({id: "operatingResources.table.image"}), field: "imageVos",
                        render: resource => this.operatingResourceImage(resource)}
                ]}
                data={this.props.operatingResources}
                options={{
                    showTitle: false,
                    search: false,
                    paging: false,
                    draggable: false,
                    toolbar: false,
                    sorting: this.props.operatingResources.length > 0,
                    headerStyle: {
                        fontWeight: 'bold',
                        backgroundColor: bodyBackgroundColor,
                        color: texisionFontColorDark
                    },
                }}
                localization={{body: {emptyDataSourceMessage: this.props.intl.formatMessage({id: "assortmentAndResources.export.resources.noContent.title"})}}}
                style={{padding: "5px", marginTop: "10px", boxShadow: 'none', borderRadius: 0}}/>
        </Grid>
    }

    render() {
        if (!this.props.hasContent) {
            return null;
        }
        return (
            <Accordion key={this.props.unitId + "-resources"} elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls={this.props.unitId + "-CONTENT"}
                    id={this.props.unitId + "-HEADER"}>
                    <Typography variant="h6">
                        {this.props.intl.formatMessage({id: "operatingResources.export.title"})}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {this.operatingResourcesTable()}
                </AccordionDetails>
            </Accordion>
        );
    }
}

export default injectIntl(OperatingResourceTable);
