import {Component} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MaterialTable from "material-table";
import {RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL} from "../../../util/Constants";
import {bodyBackgroundColor, texisionFontColorDark} from "../../../util/ColorTheme";
import {injectIntl} from "react-intl";

class ResidentsLaundryTable extends Component {

    render() {
        if (!this.props.hasContent) {
            return null;
        }
        return (
            <Accordion key={this.props.unitId + "-laundry"} elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls={this.props.unitId + "-CONTENT"}
                    id={this.props.unitId + "-HEADER"}>
                    <Typography variant="h6">
                        {this.props.intl.formatMessage({id: "residentsLaundry.title"})}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12}>
                        <MaterialTable
                            columns={[
                                {title: this.props.intl.formatMessage({id: "residentsLaundry.article.name"}), field: "name", filtering: false},
                                {title: this.props.intl.formatMessage({id: "residentsLaundry.table.header.cleanType"}), field: "cleanType", filtering: false,
                                    render: laundry => this.props.intl.formatMessage({id: "residentsLaundry.table.title."
                                            + (laundry.cleanType === RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL ? "chemical" : "wash")})},
                            ]}
                            data={this.props.residentsLaundry}
                            options={{
                                showTitle: false,
                                search: false,
                                paging: false,
                                draggable: false,
                                toolbar: false,
                                sorting: this.props.residentsLaundry.length > 0,
                                headerStyle: {
                                    fontWeight: 'bold',
                                    backgroundColor: bodyBackgroundColor,
                                    color: texisionFontColorDark
                                },
                            }}
                            style={{padding: "5px", marginTop: "10px", boxShadow: 'none', borderRadius: 0}}/>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    }
}

export default injectIntl(ResidentsLaundryTable);
