import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {DIALOG_TYPE_INFO, DIALOG_TYPE_WARNING, PROJECT_TASKS} from "../../../../../util/Constants";
import LogisticsQuantities from "./LogisticsQuantities";
import TexisionDialog from "../../../../uiLibrary/TexisionDialog";
import {GeneralContext} from "../../../../contexts/GeneralContext";
import LogisticsTile from "../../shared/LogisticsTile";

class QuantitiesTile extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            quantitiesOpen: false
        };
    }

    title = () => {
        const numberOfArticles = this.props.allAssignedArticles?.length ?? 0;
        const numberOfArticlesWithAmount = this.props.allAssignedArticles?.filter(a => a.deliveryQuantity || a.equipQuantity)?.length ?? 0;
        return this.props.intl.formatMessage({id: "logistics.quantities.articlesWithAmount"}) + ": " + numberOfArticlesWithAmount + "/" + numberOfArticles;
    }

    render() {
        if (!this.props.deliveryAddress || !this.props.assortmentData) {
            return null;
        }

        const hasNoAssignedArticles = !this.props.allAssignedArticles?.length;

        return (
            <>
                <TexisionDialog
                    size="large"
                    type={hasNoAssignedArticles ? DIALOG_TYPE_WARNING : DIALOG_TYPE_INFO}
                    open={this.state.quantitiesOpen}
                    titleId="logistics.quantities.dialog.title"
                    subtitleId={hasNoAssignedArticles ? "logistics.quantities.empty.dialog.subtitle" : "logistics.quantities.dialog.subtitle"}
                    content={<LogisticsQuantities
                        deliveryAddress={this.props.deliveryAddress}
                        allAreas={this.props.allAreas}
                        allProfessionalGroups={this.props.allProfessionalGroups}
                        allAssignedArticles={this.props.allAssignedArticles}
                        assortmentData={this.props.assortmentData}
                        onSave={this.props.onSave}/>}
                    cancelId="commons.close.label"
                    onCancel={() => this.setState({quantitiesOpen: false})}
                />

                <LogisticsTile
                    content={this.title()}
                    disabled={this.props.readOnly}
                    onEdit={() => this.setState({quantitiesOpen: true})}
                    projectTask={PROJECT_TASKS.DELIVERY_QUANTITY}
                    objectId={this.props.deliveryAddress.id}/>

            </>
        )
    }
}

export default injectIntl(QuantitiesTile);
