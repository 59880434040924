import React from 'react';

import Grid from "@material-ui/core/Grid";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {white} from '../../../util/ColorTheme';
import {FormattedMessage, injectIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import {MY_ACCOUNT_ROUTE, PAYMENT_MANAGEMENT_ROUTE, RELEASE_NOTES_ROUTE} from "../../../util/Constants";
import {withRouter} from 'react-router-dom';
import {GeneralContext} from "../../contexts/GeneralContext";
import {isAdmin, isProjectAdmin} from "../../../services/UserService";
import {Tooltip} from "@mui/material";
import NewReleasesIcon from '@mui/icons-material/NewReleases';


 class ProfileMenu extends React.Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        }
    }

    handleClick = (event) => {
        this.setState({
            open: !this.state.open,
            anchorEl: event.currentTarget
        })
    }

    handleClose = () => {
        this.setState({
            open: !this.state.open,
            anchorEl: null
        })
    }
    
    
    render() {

    const user = this.context.currentUser;

        return (
            <>

                <Tooltip title={this.props.intl.formatMessage({id: "profile.icon.tooltip"})}>
                    <IconButton
                        style={{color: this.props.color ?? white}}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        color="inherit">
                        <AccountCircleIcon />
                    </IconButton>
                </Tooltip>

                <Popover
                    id="profil-popover"
                    open={this.state.open}
                    style={{marginTop: 18}}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    transformOrigin={{vertical: 'top', horizontal: 'center'}}
                    PaperProps={{style: {width: '240px', overflow: 'hidden'}}}>

                    <Grid container spacing={1}>

                        <Grid item xs={12}>

                            <Button
                                startIcon={<AccountCircleIcon/>}
                                onClick={() => {
                                    this.handleClose();
                                    this.props.history.push(MY_ACCOUNT_ROUTE);
                                }}
                                fullWidth={true}
                                style={{justifyContent: "flex-start", marginLeft: "4%", marginTop: "4%"}}>
                                <Typography color="textPrimary" variant="body2">
                                    {user.firstName && user.lastName
                                        ? (user.firstName + " " + user.lastName)
                                        : this.props.intl.formatMessage({id: "constants.UserRole." + user.role})}
                                </Typography>
                            </Button>

                        </Grid>

                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography color="textPrimary" variant="caption"
                                        style={{textAlign: "start", marginLeft: "10%"}}>
                                {user.email}
                            </Typography>
                        </Grid>

                        
                        {user.company && <Grid item xs={12}>
                            <Typography 
                                color="textPrimary" 
                                variant="caption"
                                style={{textAlign: "start", marginLeft: "10%"}}>
                                {user.company}
                            </Typography>
                        </Grid>}

                        {user.jobTitle && <Grid item xs={12}>
                            <Typography 
                                color="textPrimary" 
                                variant="caption"
                                style={{textAlign: "start", marginLeft: "10%"}}>
                                {user.jobTitle}
                            </Typography>
                        </Grid>}

                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>

                        {(isAdmin(user) || isProjectAdmin(user)) && <>
                        
                            <Grid item xs={12}>

                                <Button
                                    startIcon={<AssignmentIcon/>}
                                    onClick={() => {
                                        this.handleClose();
                                        this.props.history.push(PAYMENT_MANAGEMENT_ROUTE);
                                    }}
                                    fullWidth={true}
                                    style={{justifyContent: "flex-start", marginLeft: "4%"}}>
                                    <FormattedMessage id="navBar.profile.showSubscription"/>
                                </Button>

                            </Grid>

                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                        </>}

                        <Grid item xs={12}>
                            <Button
                                startIcon={<NewReleasesIcon/>}
                                onClick={() => {
                                    this.handleClose();
                                    this.props.history.push(RELEASE_NOTES_ROUTE);
                                }}
                                fullWidth={true}
                                style={{justifyContent: "flex-start", marginLeft: "4%"}}>
                                <FormattedMessage id="navBar.releaseNotes"/>
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<LockIcon/>}
                                onClick={this.context.logout}
                                fullWidth={true}
                                style={{justifyContent: "flex-start", marginLeft: "4%", marginBottom: "4%"}}>
                                <FormattedMessage id="navBar.profile.logout"/>
                            </Button>
                        </Grid>
                    </Grid>
                </Popover>

            </>
        );


    }
}

export default withRouter(injectIntl(ProfileMenu));
