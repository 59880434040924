import { Grid, Link } from "@material-ui/core";
import { Component } from "react";
import AppMenu from "../../navigation/shared/AppMenu";
import texisionLogo from '../../../resources/texision_logo.svg';
import tenderLogo from '../../../resources/tender_logo.svg';
import offerLogo from '../../../resources/offer_logo.svg';
import cockpitLogo from '../../../resources/cockpit_logo.svg';
import orderLogo from '../../../resources/order_logo.svg';
import { APP_TYPE_KEY, COCKPIT_APP, OFFER_APP, ORDER_APP, ROOT_ROUTE, TENDER_APP } from "../../../util/Constants";
import {GeneralContext} from "../../contexts/GeneralContext";
import MediaQuery from "react-responsive";
import { isBidderReadOnly, isOrderUser } from "../../../services/UserService";

class AppHeader extends Component {

    static contextType = GeneralContext;

    render() {

        const user = this.context.currentUser;
        
        let appLogo;

        switch (localStorage.getItem(APP_TYPE_KEY)) {
            case COCKPIT_APP:
                appLogo = cockpitLogo;
                break;
            case ORDER_APP:
                appLogo = orderLogo;
                break;
            case OFFER_APP:
                appLogo = offerLogo;
                break;
            case TENDER_APP:
            default:
                appLogo = tenderLogo;
                break;
        }

        const canSwitchApps = !isBidderReadOnly(user) && !isOrderUser(user);

        return (
            <Grid container alignItems='center' wrap="nowrap" style={{paddingTop: '0.4rem', paddingLeft: 5}}>

                {canSwitchApps && <Grid item>
                    <AppMenu 
                        mobile={this.props.mobile}
                        showMobileDrawer={() => this.props.showMobileDrawer()}/>
                </Grid>}
                
                <Grid item xs style={{paddingTop: 12, paddingBottom: 12}}>
                    <Link href={ROOT_ROUTE}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <MediaQuery minWidth={650}>
                                    <img src={texisionLogo} style={{paddingLeft: '0.9rem', height: 20}} alt="Texision"/>
                                </MediaQuery>
                            </Grid>
                            <Grid item xs>
                                <img src={this.props.mockAppLogo ?? appLogo} style={{paddingLeft: 10, height: 20}} alt="App"/>
                            </Grid>
                        </Grid>
                    </Link>
                </Grid>

            </Grid>
        );
    }
}

export default AppHeader;
