import {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {Grid, Typography} from "@material-ui/core";
import PublishBidderSelection from "./PublishBidderSelection";

class LaundryRadar extends Component {

    render() {

        return (
            <>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h1">
                            <FormattedMessage id="laundryRadar.title"/>
                        </Typography>

                        <Typography variant="subtitle1">
                            <FormattedMessage id="laundryRadar.subtitle"/>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PublishBidderSelection
                            readOnly={true}
                            selectedBidders={[]}/>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withSnackbar(injectIntl(LaundryRadar));
