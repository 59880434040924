import { useContext } from "react";
import {useIntl} from "react-intl";
import { white } from "../../../../util/ColorTheme";
import {INVOICE_CONFLICT_TYPE} from "../../../../util/Constants";
import {formatAmount, formatPrice} from "../../../../util/Util";
import { GeneralContext } from "../../../contexts/GeneralContext";
import {getActiveOperation} from "../../../../services/OperationService";
import {getLegendLabel} from "../../../../services/StatisticsService";

const InvoiceStatisticsTooltip = ({
    active,
    activeKey,
    payload, 
    label, 
    getXAxisTickLabel, 
    selectedTab, 
    invoices,
    selectedNodes
}) => {

    const intl = useIntl();
    const context = useContext(GeneralContext);

    const currency = getActiveOperation(context).activeProject.currency;

    if (active) {

        const articleNumberAndPriceOrAmount = payload
            .map(p => [p.dataKey, p.value])
            .find(([dataKey, value]) => dataKey === activeKey);

        if (!articleNumberAndPriceOrAmount) {
            return null;
        }

        const articleNumber = articleNumberAndPriceOrAmount[0];

        const invoice = invoices?.find(i => i.id === label);
        const invoiceItem = invoice?.invoiceItems?.find(item => item.articleNumber === articleNumber || item.articleName === articleNumber);

        let priceOrAmount;

        if (selectedTab.includes("price")) {
            if (invoiceItem?.conflictType === INVOICE_CONFLICT_TYPE.MISSING_ARTICLE) {
                priceOrAmount = intl.formatMessage({id: "cockpit.statistics.price.missingArticle"});
            } else {
                priceOrAmount = formatPrice(articleNumberAndPriceOrAmount[1]) + " " + currency;
            }
        } else if (selectedTab.includes("amount")) {
            if (invoiceItem?.conflictType === INVOICE_CONFLICT_TYPE.MISSING_ARTICLE) {
                priceOrAmount = intl.formatMessage({id: "cockpit.statistics.amount.missingArticle"});
            } else {
                priceOrAmount = formatAmount(articleNumberAndPriceOrAmount[1]);
            }
        }

        let articleName = getLegendLabel(context, selectedNodes, articleNumber);
        let hasArticleNumber = articleName.slice(-1) !== articleNumber.slice(-1);

        return (
            <div style={{backgroundColor: white, border: "1px solid grey", padding: 15}}>
                {getXAxisTickLabel(label)}
                <p>
                    {
                        articleName
                        + (hasArticleNumber && invoiceItem?.conflictType !== INVOICE_CONFLICT_TYPE.UNKNOWN_ARTICLE
                            ? " (" + articleNumber + "): "
                            : ": ")
                    }
                </p>
                <p>
                    {priceOrAmount}
                </p>
            </div>
        );
    }
    return null;
}

export {
    InvoiceStatisticsTooltip
}
