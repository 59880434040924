import React, { Component } from 'react';
import {FormattedMessage} from 'react-intl';
import {Grid, Tooltip} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import '../../../css/OrgaUnitCard.css';
import MajorCustomerInformation, {MAJOR_CUSTOMER_INFORMATION_TYPE} from "../../money/MajorCustomerInformation";


class BusinessUnitPaperNew extends Component {

    render() {
        return (
            <div>
                <Paper elevation={1} className="orgaCardNew">
                    <Grid container justifyContent="center" align="center" alignItems="center" className="orgarCardNewInner">
                        <Grid item xs={12} style={{width: '80%', height: "38%", display: "flex", alignItems: "flex-end"}}>
                            <Typography component="span">
                                <FormattedMessage id="businessUnit.create.createPaper"/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{width: '100%', height: "22%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Tooltip title={this.props.disabled && this.props.disabledTooltip ? this.props.disabledTooltip : ""}>
                                <div style={{width: '100%'}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{width: '100%'}}
                                        disabled={this.props.disabled}
                                        onClick={this.props.handleCreate}>
                                        <FormattedMessage id="commons.create.button"/>
                                    </Button>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", alignItems: "flex-end", height: "40%"}}>
                            <MajorCustomerInformation type={MAJOR_CUSTOMER_INFORMATION_TYPE.UNIT} vertical={true}/>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }
}
export default BusinessUnitPaperNew;
