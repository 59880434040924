import React, {Component} from 'react';

import {Grid, TextField, Button, Typography} from '@material-ui/core';

import {createErrorMessage} from '../../util/Util';
import {LOGIN_ROUTE, FORGOT_PASSWORD_ROUTE} from '../../util/Constants';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withSnackbar} from 'notistack';
import queryString from 'query-string';
import {resetPassword} from '../../services/BackendService';

import TexisionContainer from '../uiLibrary/TexisionContainer';
import {GeneralContext} from "../contexts/GeneralContext";
import { withRouter } from 'react-router-dom';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import { IconButton, InputAdornment } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { checkPasswordStrength } from '../../util/AuthUtil';


class ResetPasswordLanding extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.state = {
            password: null,
            passwordRepeat: null,
            resetError: false,
            resetSent: false,
            passwordVisible: false
        }
    }

    handleReset = async(event) => {

        event.preventDefault();

        let password = this.state.password;
        let passwordRepeat = this.state.passwordRepeat;

        // check passwords are matching
        if (password !== passwordRepeat) {
            let errorMessage = this.props.intl.formatMessage({id: "passwordreset.error.noMatch"});
            createErrorMessage(errorMessage, this.props);
            this.setState({resetError: true});
            return;

        // check password length
        } else if (checkPasswordStrength(password) <= 1) {
            let errorMessage = this.props.intl.formatMessage({id: "passwordreset.error.weak"});
            createErrorMessage(errorMessage, this.props);
            this.setState({resetError: true});
            return;
        }

        this.setState({resetError: false});
        let params = queryString.parse(this.props.location.search);
        let token = params.token;

        const response = await resetPassword(token, passwordRepeat);

        if (response?.status === 200) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "passwordreset.success" }),
                {variant: 'success', persist: true, anchorOrigin: {vertical: 'top', horizontal: 'right'},
                action: (
                    <Button variant="contained" color="primary" size="small" onClick={() => {
                            this.props.closeSnackbar();
                            this.props.history.push(LOGIN_ROUTE)
                        }}>
                        <FormattedMessage id="passwordreset.login.label"/>
                    </Button>
            )});

            this.setState({resetError: false, resetSent: true});

        } else if (response?.status === 400) {
            let errorMessage = this.props.intl.formatMessage({id: "passwordreset.error.weak"});
            createErrorMessage(errorMessage, this.props);
            this.setState({resetError: true});

        } else if (response?.status === 401) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "passwordreset.error.token" }),
                {variant: 'warning', persist: true, anchorOrigin: {vertical: 'top', horizontal: 'right'},
                action: (
                    <Button variant="contained" color="primary" size="small" onClick={() => {
                            this.props.closeSnackbar();
                            this.props.history.push(FORGOT_PASSWORD_ROUTE)
                        }}>
                        <FormattedMessage id="passwordreset.button.token"/>
                    </Button>
            )});
            this.setState({resetError: true});

        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "passwordreset.error"}), this.props);
            this.setState({resetError: true});
        }
    }

    render() {
        let opts = {};

        if (this.state.resetError) {
            opts['error'] = 'error';
        }

        return (
            <TexisionContainer showBackButton="true" content={

                <Grid container justifyContent="center">

                    <form onSubmit={this.handleReset} style={{width: "100%"}}>

                        <Grid item xs={12}>

                            <Typography variant="h4">
                                <FormattedMessage id="passwordreset.title.h1"/>
                            </Typography>

                            <Typography color="textSecondary" style={{marginBottom: "20px"}}>
                                <FormattedMessage id="passwordreset.title.h2"/>
                            </Typography>

                        </Grid>

                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <PasswordStrengthIndicator password={this.state.password}/>
                        </Grid>

                        <Grid item xs={12}>

                            <TextField 
                                id="password_txf" 
                                label={this.props.intl.formatMessage({id: "passwordreset.password.label"})} 
                                {...opts} 
                                fullWidth
                                variant="outlined" 
                                type={this.state.passwordVisible ? "text" : "password"} 
                                style={{marginTop: "2%", maxWidth: 500, marginBottom: 10}}
                                InputProps={{
                                    endAdornment: (<InputAdornment position='end'>
                                        <IconButton
                                            onClick={() => this.setState({passwordVisible: !this.state.passwordVisible})}>
                                            {this.state.passwordVisible ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                    </InputAdornment>)
                                }}
                                onChange={(event) => this.setState({password: event.target.value})}/>

                        </Grid>

                        <Grid item xs={12}>

                            <TextField 
                                id="password_repeat_txf" 
                                label={this.props.intl.formatMessage({id: "passwordreset.repeat.label"})} 
                                {...opts} 
                                fullWidth
                                variant="outlined" 
                                type={this.state.passwordVisible ? "text" : "password"} 
                                style={{marginTop: "2%", maxWidth: 500, marginBottom: 10}}
                                helperText={this.state.password !== this.state.passwordRepeat ? this.props.intl.formatMessage({id: "passwordreset.error.noMatch"}) : ""}
                                onChange={(event) => this.setState({passwordRepeat: event.target.value})}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        this.handleReset(event);
                                    }
                                }}/>

                        </Grid>

                        <Grid item xs={12}>

                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit"
                                maxLength="3"
                                disabled={!this.state.password || !this.state.passwordRepeat || this.state.resetSent}
                                style={{marginTop: "20px"}}>
                                <FormattedMessage id="passwordreset.button.label"/>
                            </Button>

                        </Grid>

                    </form>

                </Grid>}
            />
        );
    }
}

export default injectIntl(withSnackbar(withRouter(ResetPasswordLanding)));
