import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem, Select, TextField, Tooltip} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {de} from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CalendarToday} from "@material-ui/icons";
import {GeneralContext} from "../../contexts/GeneralContext";
import {APP_TYPE_KEY, COCKPIT_APP, DIALOG_TYPE_INFO} from "../../../util/Constants";
import Checkbox from "@material-ui/core/Checkbox";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import {isCockpit, isTender} from "../../../util/Util";

class ContractBlock extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showOrderInfo: false,
            showCockpitInfo: false
        };
    }

    numberWithSeparators = (number) => {
        if (!number) return number;
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    removeSeparators = (number) => {
        return number ? number.replaceAll(".", "") : number;
    }

    removeLeadingZeros = (number) => {
        return number ? parseInt(number, 10) : number;
    }

    invoiceTypeDropDownMenu = () => {
        let contract = {...this.props.contract};
        return (
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid itemScope>
                    <div style={{wordBreak: "break-word"}}>
                        <FormattedMessage id="contract.invoiceType.title"/>*
                    </div>
                </Grid>
                <Grid item>
                    <Select
                        labelId="invoice-type-select"
                        disabled={this.props.readOnly}
                        value={contract.useZugferd ? "zugferd" : (contract.useXRechnung ? "xrechnung" : null)}
                        variant="outlined"
                        style={{minWidth: 170}}>
                        <MenuItem
                            key="zugferd"
                            value="zugferd"
                            onClick={() => {
                                contract.useZugferd = true;
                                contract.useXRechnung = false;
                                this.props.onContractChange(contract);
                            }}>
                            {this.props.intl.formatMessage({id: "contract.invoiceType.zugferd"})}
                        </MenuItem>
                        <MenuItem
                            key="xrechnung"
                            value="xrechnung"
                            onClick={() => {
                                contract.useZugferd = false;
                                contract.useXRechnung = true;
                                this.props.onContractChange(contract);
                            }}>
                            {this.props.intl.formatMessage({id: "contract.invoiceType.xrechnung"})}
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>
        );
    }

    checkBox = (titleId, value, onChange) => {
        return (
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid itemScope>
                    <div style={{wordBreak: "break-word"}}>
                        <FormattedMessage id={titleId}/>
                    </div>
                </Grid>
                <Grid item>
                    <Checkbox
                        color="primary"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        disabled={this.props.readOnly}
                    />
                </Grid>
            </Grid>
        );
    }

    textField = (titleId, value, onChange, withSeparators) => {
        let title = this.props.intl.formatMessage({id: titleId});
        return this.textFieldTitled(title, value, onChange, withSeparators, true);
    }

    textFieldCurrency = (titleId, value, onChange, withSeparators) => {
        let title = this.props.intl.formatMessage({id: titleId}) + " " + (this.props.currency ?? "€");
        return this.textFieldTitled(title, value, onChange, withSeparators, isTender());
    }

    textFieldTitled = (title, value, onChange, withSeparators, mandatory) => {
        let fieldValue = !value || value <= 0 ? "" : value;
        return (
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid itemScope>
                    <div style={{wordBreak: "break-word"}}>
                        {title}{mandatory ? "*" : ""}
                    </div>
                </Grid>
                <Grid item>
                    <TextField
                        value={withSeparators ? this.numberWithSeparators(fieldValue) : fieldValue}
                        onChange={(e) => {
                            let newNumber = withSeparators ? this.removeSeparators(e.target.value) : e.target.value;
                            if (newNumber !== "0" && (newNumber === "" || /^[0-9\b]+$/.test(newNumber))) {
                                this.context.setUnsavedChanges(true);
                                onChange(this.removeLeadingZeros(newNumber));
                            }
                        }}
                        disabled={this.props.readOnly}
                    />
                </Grid>
            </Grid>
        );
    }

    multilineTextField = (titleId, value, onChange) => {
        return <Grid container justifyContent="space-between" alignItems="center">
            <Grid itemScope>
                <div style={{wordBreak: "break-word"}}>
                    {this.props.intl.formatMessage({id: titleId})}
                </div>
            </Grid>
            <Grid item xs>
                <TextField
                    value={value ?? ""}
                    onChange={onChange}
                    disabled={this.props.readOnly}
                    multiline style ={{width: '100%', paddingLeft: "20px"}}/>
            </Grid>
            <Grid item xs={12} style={{paddingTop: "10px"}}>
                {"("}<FormattedMessage id={"contract.noServices.hint"}/>{")"}
            </Grid>
        </Grid>;
    }

    dateTimePicker = (titleId, value, onChange, minDate, isStartTime, tooltip, disabled) => {
        let minDateMessageId;
        if (isStartTime) {
            minDateMessageId = "contract.conflict.inPast";
        } else if (minDate && minDate > 0) {
            minDateMessageId = "contract.conflict.beforeStartTime";
        } else {
            minDateMessageId = "contract.conflict.inPast";
        }
        return (
            <Grid container justifyContent="space-between" alignItems="center" style={{overflow: "visible"}}>
                <Grid item>
                    {this.props.intl.formatMessage({id: titleId})}*
                </Grid>
                <Grid item>
                    <Tooltip title={tooltip}>
                        <div>
                            <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                <DatePicker
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CalendarToday/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    minDate={(minDate && minDate > 0 ? minDate : new Date().getTime()) + 86400000}
                                    minDateMessage={this.props.intl.formatMessage({id: minDateMessageId})}
                                    inputVariant="outlined"
                                    variant="dialog"
                                    emptyLabel={this.props.intl.formatMessage({id: "contract.noDateSelected"})}
                                    okLabel={this.props.intl.formatMessage({id: "commons.confirm.button"})}
                                    cancelLabel={this.props.intl.formatMessage({id: "commons.cancel.button"})}
                                    disablePast={localStorage.getItem(APP_TYPE_KEY) !== COCKPIT_APP}
                                    format="dd.MM.yyyy"
                                    value={!value || value < 1 ? null : value}
                                    onChange={(newValue) => {
                                        this.context.setUnsavedChanges(true);
                                        onChange(newValue);
                                    }}
                                    invalidLabel={this.props.intl.formatMessage({id: "commons.error"})}
                                    disabled={this.props.readOnly || disabled}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }

    contractBlockEditGrid = (items, index) => {
        if (!items || items.length === 0) {
            return <div/>;
        } else {
            return (
                <Grid
                    key={"contract-block-grid-item-" + index}
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{paddingBottom: "30px"}}>
                    {items.map(item => <Grid key={item[1]} container style={{paddingBottom: "20px"}}>
                        {item[0]}
                    </Grid>)}
                </Grid>
            );
        }
    }

    render() {
        let index = 0;
        let items = [];
        let contract = {...this.props.contract};
        switch (this.props.index) {
            case 2: {
                items.push([
                    this.dateTimePicker(
                        "contract.textfield.startOfServiceDate",
                        contract.startOfServiceDate,
                        (newValue) => {contract.startOfServiceDate = newValue.getTime(); this.props.onContractChange(contract);},
                        isCockpit() ? 1 : new Date().getTime(),
                        true,
                        "",
                        false),
                    index++]);

                items.push([
                    this.textField(
                        "contract.textfield.contractDurationMonths",
                        contract.contractDurationMonths,
                        (newNumber) => {contract.contractDurationMonths = newNumber; this.props.onContractChange(contract)}),
                    index++]);

                items.push([
                    this.textField(
                        "contract.textfield.contractRenewalMonths",
                        contract.contractRenewalMonths,
                        (newNumber) => {contract.contractRenewalMonths = newNumber; this.props.onContractChange(contract)}),
                    index++]);
                break;
            }

            case 4: {
                items.push([
                    this.textFieldCurrency(
                        "contract.textfield.propertyDamageEuro",
                        contract.propertyDamageEuro,
                        (newNumber) => {contract.propertyDamageEuro = newNumber; this.props.onContractChange(contract)},
                        true),
                    index++]);

                items.push([
                    this.textFieldCurrency(
                        "contract.textfield.personalDamageEuro",
                        contract.personalDamageEuro,
                        (newNumber) => {contract.personalDamageEuro = newNumber; this.props.onContractChange(contract)},
                        true),
                    index++]);

                items.push([
                    this.textFieldCurrency(
                        "contract.textfield.processingDamageEuro",
                        contract.processingDamageEuro,
                        (newNumber) => {contract.processingDamageEuro = newNumber; this.props.onContractChange(contract)},
                        true),
                    index++]);

                items.push([
                    this.textFieldCurrency(
                        "contract.textfield.keyLossEuro",
                        contract.keyLossEuro,
                        (newNumber) => {contract.keyLossEuro = newNumber; this.props.onContractChange(contract)},
                        true),
                    index++]);

                if (isTender()) {

                    items.push([
                        this.invoiceTypeDropDownMenu(),
                        index++]);

                    items.push([
                        this.checkBox("contract.useCockpit.title",
                            contract.useCockpit,
                            (newValue) => {
                                this.context.setUnsavedChanges(true);
                                if (newValue) {
                                    this.setState({showCockpitInfo: true});
                                }
                                contract.useCockpit = newValue; this.props.onContractChange(contract);
                            }),
                        index++]);

                    items.push([
                        this.checkBox("contract.useOrder.title",
                            contract.useOrder,
                            (newValue) => {
                                this.context.setUnsavedChanges(true);
                                if (newValue) {
                                    this.setState({showOrderInfo: true});
                                }
                                contract.useOrder = newValue; this.props.onContractChange(contract);
                            }),
                        index++]);
                }
                break;
            }

            case 5: {
                items.push([
                    this.textField(
                        "contract.textfield.paymentTermDays",
                        contract.paymentTermDays,
                        (newNumber) => {contract.paymentTermDays = newNumber; this.props.onContractChange(contract)}),
                    index++]);
                break;
            }

            case 6: {
                items.push([
                    this.dateTimePicker(
                        "contract.textfield.priceHedgingTo",
                        contract.priceHedgingTo,
                        (newValue) => {contract.priceHedgingTo = newValue.getTime(); this.props.onContractChange(contract)},
                        contract.startOfServiceDate,
                        false,
                        "",
                        false),
                    index++]);
                break;
            }

            case 8: {
                items.push([
                    this.textField("contract.textfield.contractTerminationMonths",
                        contract.contractTerminationMonths,
                        (newNumber) => {contract.contractTerminationMonths = newNumber; this.props.onContractChange(contract)}),
                    index++]);

                items.push([
                    this.textField("contract.textfield.contractDurationMonths",
                        contract.contractDurationMonths,
                        (newNumber) => {contract.contractDurationMonths = newNumber; this.props.onContractChange(contract)}),
                    index++]);

                items.push([
                    this.textField("contract.textfield.contractRenewalMonths",
                        contract.contractRenewalMonths,
                        (newNumber) => {contract.contractRenewalMonths = newNumber; this.props.onContractChange(contract)}),
                    index++]);
                break;
            }

            case 11: {
                items.push([
                    this.multilineTextField(
                        "contract.textfield.otherAgreements",
                        contract.otherAgreements,
                        (e) => {
                            this.context.setUnsavedChanges(true);
                            contract.otherAgreements = e.target.value;
                            this.props.onContractChange(contract);
                        }),
                    index++]);
                break;
            }
            case 0:
            case 1:
            case 3:
            case 7:
            case 9:
            case 10:
            default: return <div/>;
        }
        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showCockpitInfo || this.state.showOrderInfo}
                    titleId="commons.hint.dialogTitle"
                    subtitleId={"contract." + (this.state.showCockpitInfo ? "cockpit" : "order") + ".info.dialog.subtitle"}
                    actionId="commons.okay.button"
                    onAction={() => this.setState({showCockpitInfo: false, showOrderInfo: false})}/>

                {this.contractBlockEditGrid(items, this.props.index)}
            </>
        );
    }
}

ContractBlock.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    contract: PropTypes.object.isRequired,
    onContractChange: PropTypes.func.isRequired
};

export default injectIntl(ContractBlock);
