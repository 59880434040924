import {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {GeneralContext} from "../../contexts/GeneralContext";
import {getFilterCategoriesText} from "../../../util/ArticleHelper";

class FilterCategoriesPreview extends Component {

    static contextType = GeneralContext;

    state = {readMore: false};

    render() {
        if (!this.props.filterCategories?.length || this.props.articleConfigurationId === null || this.props.articleConfigurationId === undefined) {
            return null;
        }
        const text = getFilterCategoriesText(this.props.filterCategories, this.props.intl);
        const maxLength = 80;
        if (text.length <= maxLength) {
            return text;
        }
        return (
            <div className="text" key={"filter-options-id-" + this.props.articleConfigurationId}>
                {this.state.readMore ? text : `${text.substring(0, maxLength)}...`}
                <button className="btn" onClick={() => this.setState({readMore: !this.state.readMore})}>
                    <FormattedMessage id={this.state.readMore ? "commons.showLess" : "commons.showMore"}/>
                </button>
            </div>
        );
    }
}

export default injectIntl(FilterCategoriesPreview);
