import {getAsync, getAsyncCatch, putAsyncCatch} from "./BackendService";
import {createSuccessMessage} from "../util/Util";
import {CATEGORY_FLAT_LINEN, CATEGORY_WORK_WEAR, DELIVERED_QUANTITY, EXPORT_TYPE_MERGE, FLAT_RATE, PRICE_SHEET_STATE, PriceType} from "../util/Constants";
import {isAdmin, isProjectAdmin, isUser} from "./UserService";
import {getActiveOperation} from "./OperationService";

export async function getPriceOfferByProjectId(context, props, projectId) {
    const priceOffer = await getAsyncCatch(context,"/priceoffer/project/" + projectId, props);
    return priceOffer ? priceOffer : null;
}

export async function getPriceOfferByOfferId(context, props, offerId) {
    const priceOffer = await getAsyncCatch(context, "/priceoffer/" + offerId, props);
    return priceOffer ? priceOffer : null;
}

export async function getPriceOfferListByOfferIds(context, props, offerIds) {
    if (!offerIds?.length) {
        return [];
    }
    let offerIdsPathVariable = "";
    for (let i = 0; i < offerIds.length; i++) {
        offerIdsPathVariable += offerIds[i];
        if (i < offerIds.length - 1) {
            offerIdsPathVariable += ",";
        }
    }
    const priceOfferList = await getAsyncCatch(context, "/priceoffer/list/" + offerIdsPathVariable, props);
    return priceOfferList ? priceOfferList : [];
}

export async function updatePriceOffer(context, props, offerId, projectId, type, itemMap, allArticleNumbers, onSuccess, hideSuccess, priceUnitMap) {
    let items = Object.values(itemMap);

    // find duplicated article numbers
    let duplicateArticleNumber;
    for (const [number, count] of Object.entries(allArticleNumbers)) {
        if (count > 1) {
            duplicateArticleNumber = number;
        }
    }

    // put all articles from other business units with same price type into the list to update
    if (!!priceUnitMap && Object.entries(priceUnitMap).length) {
        Object.values(priceUnitMap).forEach(priceUnit => {
            let group;
            switch (type) {
                case PriceType.CUSTOMER_ARTICLE:
                    group = priceUnit.customerArticleGroup;
                    break;
                case PriceType.OPERATING_RESOURCE:
                    group = priceUnit.operatingResourceGroup;
                    break;
                case PriceType.RESIDENTS_LAUNDRY:
                    group = priceUnit.residentsLaundryGroup;
                    break;
                case PriceType.SPECIAL_SERVICE:
                    group = priceUnit.specialServiceGroup;
                    break;
                case PriceType.ARTICLE_QUANTITY:
                    group = {
                        priceItemMap: {
                            ...((priceUnit.articleGroupMap[CATEGORY_FLAT_LINEN] ? priceUnit.articleGroupMap[CATEGORY_FLAT_LINEN][DELIVERED_QUANTITY] : {})?.priceItemMap),
                            ...((priceUnit.articleGroupMap[CATEGORY_WORK_WEAR] ? priceUnit.articleGroupMap[CATEGORY_WORK_WEAR][DELIVERED_QUANTITY] : {})?.priceItemMap),
                        }
                    };
                    break;
                case PriceType.ARTICLE_WEEKLY:
                    group = {
                        priceItemMap: {
                            ...((priceUnit.articleGroupMap[CATEGORY_FLAT_LINEN] ? priceUnit.articleGroupMap[CATEGORY_FLAT_LINEN][FLAT_RATE] : {})?.priceItemMap),
                            ...((priceUnit.articleGroupMap[CATEGORY_WORK_WEAR] ? priceUnit.articleGroupMap[CATEGORY_WORK_WEAR][FLAT_RATE] : {})?.priceItemMap),
                        }
                    };
                    break;
                default:
                    return;
            }
            items.push(...Object.values(group.priceItemMap).filter(item => !items.find(i => i.objectId === item.objectId)));
        });
    }

    // format prices and filter article numbers
    items.forEach(item => {
        item.price = item.price?.toString()?.replace(",", ".") ?? 0;
        item.articleNumbers = item.articleNumbers.filter((number) => number !== "" && number !== " ");
    });

    // if this component is called by the BidderPriceSheet, the update endpoint contains the offer id
    let url;
    if (offerId !== null && offerId !== undefined) {
        url = "/priceoffer/offer/" + offerId + "/items/" + type
    } else {
        url = "/priceoffer/" + projectId + "/items/" + type;
    }

    let errorTextMap;
    let replacements;
    if (duplicateArticleNumber) {
        errorTextMap = new Map();
        errorTextMap.set(400, "bidder.priceSheet.update.duplicate.errorMessage");
        replacements = {articleNumber: duplicateArticleNumber};
    }

    // reverse list so the items that are consciously updated are last and therefore will be the last backend tasks to override the other data
    const success = await putAsyncCatch(context, url, items.reverse(), props, true, errorTextMap, replacements);
    if (success) {
        if (!hideSuccess && duplicateArticleNumber) {
            createSuccessMessage(props.intl.formatMessage({id: "bidder.priceSheet.update." + type + ".successMessage"}), props);
        } else if (!hideSuccess) {
            createSuccessMessage(props.intl.formatMessage({id: "bidder.priceSheet.update.duplicateArticleNumbers.successMessage"}), props);
        }
        if (onSuccess) {
            onSuccess();
        }
        context.setUnsavedChanges(false);
    }
}

export async function loadPriceOfferMap(context, props, startTime, endTime) {
    const operationId = context.appData.activeOperationId;
    const priceOfferMap = await getAsyncCatch(context,
        "/priceoffer/statistics/" + operationId + "?startTime=" + startTime + "&endTime=" + endTime, props);
    return priceOfferMap ? priceOfferMap : null;
}

export async function updatePriceOfferMap(context, projectId) {
    if (projectId === null || projectId === undefined) {
        return;
    }
    const priceOfferMap = context.appData?.priceOfferMap ?? new Map();
    const response = await getAsync("/priceoffer/project/" + projectId);
    if (response?.status === 200) {
        priceOfferMap.set(projectId, response.data);
    }
    context.setPriceOfferMap(priceOfferMap);
}

export function hasIncompletePriceOfferAsTenderUser(context) {
    const appData = context.appData;
    const activeOperation = getActiveOperation(context);

    const activeProjectId = activeOperation?.activeProject?.id;
    const workingProjectId = activeOperation?.workingProject?.id;

    const activePriceOfferResultVo = appData.priceOfferMap?.get(activeProjectId);
    const workingPriceOfferResultVo = appData.priceOfferMap?.get(workingProjectId);

    if (!workingPriceOfferResultVo?.priceOfferVos?.length) {
        return PRICE_SHEET_STATE.INCOMPLETE;
    }

    const activePriceOffer = activePriceOfferResultVo?.priceOfferVos?.length && activePriceOfferResultVo?.contentVos?.length
        ? activePriceOfferResultVo.priceOfferVos[0] : null;
    const workingPriceOffer = workingPriceOfferResultVo.priceOfferVos[0];

    let workingItems = [];
    let workingPriceUnits = [];

    if (workingPriceOffer.exportType === EXPORT_TYPE_MERGE) {
        workingPriceUnits.push(workingPriceOffer.priceUnitMap[workingProjectId]);
    } else {
        for (let priceUnit of Object.values(workingPriceOffer.priceUnitMap)) {
            workingPriceUnits.push(priceUnit);
        }
    }

    for (let priceUnit of workingPriceUnits) {
        for (let offsettingMapList of Object.values(priceUnit.articleGroupMap)) {
            for (let priceGroup of Object.values(offsettingMapList)) {
                workingItems = workingItems.concat(Object.values(priceGroup.priceItemMap));
            }
        }
        workingItems = workingItems.concat(Object.values(priceUnit.customerArticleGroup.priceItemMap));
        workingItems = workingItems.concat(Object.values(priceUnit.residentsLaundryGroup.priceItemMap));
        workingItems = workingItems.concat(Object.values(priceUnit.operatingResourceGroup.priceItemMap));
        workingItems = workingItems.concat(Object.values(priceUnit.specialServiceGroup.priceItemMap));
    }

    if (activePriceOffer) {
        let activeItems = [];
        let activePriceUnits = [];

        if (activePriceOffer.exportType === EXPORT_TYPE_MERGE) {
            activePriceUnits.push(activePriceOffer.priceUnitMap[activeProjectId]);
        } else {
            for (let priceUnit of Object.values(activePriceOffer.priceUnitMap)) {
                activePriceUnits.push(priceUnit);
            }
        }

        for (let priceUnit of activePriceUnits) {
            for (let offsettingMapList of Object.values(priceUnit.articleGroupMap)) {
                for (let priceGroup of Object.values(offsettingMapList)) {
                    activeItems = activeItems.concat(Object.values(priceGroup.priceItemMap));
                }
            }
            activeItems = activeItems.concat(Object.values(priceUnit.customerArticleGroup.priceItemMap));
            activeItems = activeItems.concat(Object.values(priceUnit.residentsLaundryGroup.priceItemMap));
            activeItems = activeItems.concat(Object.values(priceUnit.operatingResourceGroup.priceItemMap));
            activeItems = activeItems.concat(Object.values(priceUnit.specialServiceGroup.priceItemMap));
        }

        let priceSheetState = PRICE_SHEET_STATE.COMPLETE;

        for (let priceItem of workingItems) {
            const correspondingActiveItems = activeItems.filter(ai =>
                ai.objectId === priceItem.objectId
                && ai.type === priceItem.type
                && ai.category === priceItem.category
                && ai.offsettingModel === priceItem.offsettingModel);
            // if there is an item without price and that is not new (because it is found in the activeItems list too) the price sheet is incomplete
            if (!priceItem.price) {
                priceSheetState = PRICE_SHEET_STATE.COMPLETE_BESIDES_NEW_ITEMS;
                const correspondingActiveUnitId = activeOperation?.activeProject?.businessUnits?.units
                    ?.find(u => u.name === activeOperation.workingProject?.businessUnits?.units?.find(wu => wu.id === priceItem.businessUnitId)?.name)?.id;
                if (correspondingActiveItems.find(cai => cai.businessUnitId === correspondingActiveUnitId)) {
                    return PRICE_SHEET_STATE.INCOMPLETE;
                }
            }
        }

        return priceSheetState;

    } else {

        let priceSheetState = PRICE_SHEET_STATE.COMPLETE;

        for (let priceItem of workingItems) {
            // if there is an item without price and that is not new (because it is found in the activeItems list too) the price sheet is incomplete
            if (!priceItem.price) {
                return PRICE_SHEET_STATE.INCOMPLETE;
            }
        }

        return priceSheetState;
    }
}

export function hasIncompletePriceOfferAsBidder(context, projectId) {
    const priceOfferResultVo = context.appData.priceOfferMap?.get(projectId);
    return !(priceOfferResultVo?.priceOfferVos?.length > 0 && priceOfferResultVo.priceOfferVos[0]?.isComplete);
}

export function disableNewPriceItemForTenderUser(context, priceItem, exportType) {

    if (!priceItem) {
        return false;
    }

    if (!isUser(context.currentUser) && !isProjectAdmin(context.currentUser) && !isAdmin(context.currentUser)) {
        return false;
    }

    const appData = context.appData;
    const activeOperation = getActiveOperation(context);

    const activeProjectId = activeOperation?.activeProject?.id;
    const workingProjectId = activeOperation?.workingProject?.id;

    if (context.appData.activeProjectId !== workingProjectId) {
        return false;
    }

    const activePriceOfferResultVo = appData.priceOfferMap?.get(activeProjectId);
    const workingPriceOfferResultVo = appData.priceOfferMap?.get(workingProjectId);

    if (!activePriceOfferResultVo?.priceOfferVos || activePriceOfferResultVo.priceOfferVos.length === 0
        || !workingPriceOfferResultVo?.priceOfferVos || workingPriceOfferResultVo.priceOfferVos.length === 0) {
        return false;
    }

    switch (priceItem.type) {
        case PriceType.CUSTOMER_ARTICLE:
            return workingPriceOfferResultVo.contentVos[0].customerArticles.find(c => (c.referenceId === null || c.referenceId === undefined || c.referenceId === c.id) && c.id === priceItem.objectId);
        case PriceType.SPECIAL_SERVICE:
            return workingPriceOfferResultVo.contentVos[0].specialServices.find(r => (r.referenceId === null || r.referenceId === undefined || r.referenceId === r.id) && r.id === priceItem.objectId);
        default:
            break;
    }

    const activePriceOffer = activePriceOfferResultVo.priceOfferVos[0];

    let activePriceUnit;

    if (exportType === EXPORT_TYPE_MERGE) {
        activePriceUnit = activePriceOffer.priceUnitMap[activeProjectId];
    } else {
        // workaround because the reference id can refer to future planned contract states which is complex
        activePriceUnit = activePriceOffer.priceUnitMap[activeOperation.activeProject?.businessUnits?.units
            ?.find(u => u.name === activeOperation.workingProject?.businessUnits?.units?.find(wu => wu.id === priceItem.businessUnitId)?.name)?.id];
    }

    if (!activePriceUnit) {
        return false;
    }

    switch (priceItem.type) {
        case PriceType.ARTICLE_QUANTITY:
        case PriceType.ARTICLE_WEEKLY:
            if (!activePriceUnit?.articleGroupMap) {
                return true;
            }
            const offsettingsMapList = Object.values(activePriceUnit.articleGroupMap);
            for (let o of offsettingsMapList) {
                const itemMapList = Object.values(o);
                for (let item of itemMapList) {
                    if (item.priceItemMap[priceItem.objectId]) {
                        return false;
                    }
                }
            }
            return true;
        case PriceType.OPERATING_RESOURCE:
            return !activePriceUnit.operatingResourceGroup.priceItemMap[priceItem.objectId];
        case PriceType.RESIDENTS_LAUNDRY:
            return !activePriceUnit.residentsLaundryGroup.priceItemMap[priceItem.objectId];
        default:
            return true;
    }
}
