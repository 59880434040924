import { Card, CardContent, Typography } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import {
    APP_TYPE_KEY,
    COCKPIT_APP,
    DIALOG_TYPE_INFO,
    EVENT_DURATIONS,
    EVENT_TYPES,
    LICENSE_TYPES,
    ORDER_APP, PAYMENT_METHOD_TYPES,
    TENDER_APP
} from "../../util/Constants";
import { GeneralContext } from "../contexts/GeneralContext";
import TexisionDialog from '../uiLibrary/TexisionDialog';
import React, { Component } from "react";
import {createErrorMessage, isCockpit, isTender} from "../../util/Util";
import UserProfile from "../account/UserProfile";
import Payment from "./Payment";
import { withSnackbar } from "notistack";
import ActiveProduct from "./ActiveProduct";
import {CalendlyPopup} from "../calendly/CalendlyPopup";
import {Button, Grid} from "@mui/material";
import OperationEventCard from "./OperationEventCard";
import {withRouter} from "react-router-dom";
import Product from "./Product";
import MajorCustomerInformation, {MAJOR_CUSTOMER_INFORMATION_TYPE} from "./MajorCustomerInformation";
import {convertStringToDate} from "../../util/DateUtil";
import {getActiveProject} from "../../services/ProjectService";
import {isAdmin} from "../../services/UserService";
import {
    createPayment, disabledProductSelectTooltip, getActivePaymentMethodType, getLatestPaymentStatus,
    getPaymentByPriceId,
    getPaymentsAndEvents,
    getProductsAndPrices, getTrialMonths, getTrialText, getUnpaidSubscriptionPayment,
    latestActivePayment, sendPaymentConfirmationMail, updatePayment
} from "../../services/PaymentService";
import {getActiveOperation} from "../../services/OperationService";
import {getPriceOfferByProjectId} from "../../services/PriceOfferService";
import CouponForm from "./CouponForm";
import {texisionOrange} from "../../util/ColorTheme";

class PaymentManagement extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            products: new Map(),
            prices: new Map(),
            isLoading: true,
            selectedProductId: null,
            selectedPriceId: null,
            showCompleteProfileDialog: false,
            showPaymentDialog: false,
            showPaymentSuccessDialog: false,
            clientSecret: null,
            isRequesting: false,
            showCalendly: false,
            showOtherSubscriptions: false,
            isSetupIntent: false,
            sumGross: 0,
            coupon: null
        }
    }

    async componentDidMount() {
        const productsPricesVo = await getProductsAndPrices(this.context, this.props);
        const products = productsPricesVo?.products ? new Map(Object.entries(productsPricesVo.products)) : new Map();
        const prices = productsPricesVo?.prices ? new Map(Object.entries(productsPricesVo.prices)) : new Map();
        const selectedProductId = new URLSearchParams(window.location.search).get("productId");
        if (localStorage.getItem(APP_TYPE_KEY) !== TENDER_APP) {
            const activeProject = getActiveOperation(this.context).activeProject;
            const priceOfferResult = await getPriceOfferByProjectId(this.context, this.props, activeProject.id);
            if (priceOfferResult?.priceOfferVos?.length) {
                this.setState({sumGross: priceOfferResult.priceOfferVos[0].sumGross});
            }
        }
        this.setState({isLoading: false, products, prices, selectedProductId});
    }

    purchase = async() => {
        this.setState({isRequesting: true, showCouponForm: false});

        const priceId = this.getPriceIdForSelectedProduct();
        const price = this.state.prices.get(priceId);
        const product = this.state.products.get(price.product);
        const licenseType = product.metadata.licenseType;
        const packageType = product.metadata.packageType;
        const methodType = getActivePaymentMethodType(this.context, licenseType);

        // get existing OperationPayment
        let payment;
        if (licenseType === LICENSE_TYPES.TENDER_LICENSE) {
            payment = getPaymentByPriceId(this.context, priceId);
        } else if (licenseType === LICENSE_TYPES.COOPERATION_LICENSE) {
            payment = getUnpaidSubscriptionPayment(this.context);
            if (payment) {
                payment = await updatePayment(this.context, this.props, licenseType, packageType,
                    priceId, null, payment.id, this.state.coupon?.id);
            }
        }

        // create new OperationPayment
        if (payment == null) {
            payment = await createPayment(this.context, this.props, licenseType, packageType, priceId, null, this.state.coupon?.id);
        }

        if (methodType === PAYMENT_METHOD_TYPES.SEPA_OR_CARD) {
            const clientSecret = payment?.paymentIntent?.clientSecret ?? payment?.subscription?.pendingSetupIntentObject?.clientSecret;
            if (clientSecret) {
                this.setState({
                    clientSecret: clientSecret,
                    showPaymentDialog: true,
                    isSetupIntent: !payment.paymentIntent?.clientSecret
                });
            }
        } else if (methodType === PAYMENT_METHOD_TYPES.INVOICE) {
            this.setState({
                clientSecret: null,
                showPaymentDialog: true,
            });
        }

        this.setState({isRequesting: false});
    }

    onSelectProduct = async(productId) => {
        this.setState({selectedProductId: productId});
        if (this.hasMissingUserData()) {
            this.setState({showCompleteProfileDialog: true});
        } else {
            this.setState({showCouponForm: true});
        }
    }

    getPriceIdForSelectedProduct = () => {
        return Array.from(this.state.prices.values()).find(price => price.id === this.getProductById(this.state.selectedProductId)?.defaultPrice)?.id;
    }

    getProductById = (productId) => {
        return Array.from(this.state.products.values()).find(product => product.id === productId);
    }

    onCompleteProfileCancel = () => {
        this.setState({showCompleteProfileDialog: false, selectedProductId: null});
    }

    onProfileSave = async() => {
        await this.context.loadUser();
        if (!this.hasMissingUserData()) {
            this.setState({showCompleteProfileDialog: false});
            await this.onSelectProduct(this.state.selectedProductId);
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "payment.updateProfile.errorMessage"}), this.props);
        }
    }

    onPaymentCancel = async() => {
        this.setState({showPaymentDialog: false, selectedProductId: null});

        await getPaymentsAndEvents(this.context);
    }

    onPaymentSuccess = async(licenseType) => {
        const operationId = getActiveOperation(this.context)?.id;
        await getPaymentsAndEvents(this.context);
        await sendPaymentConfirmationMail(this.context, this.props, licenseType, operationId);
        this.setState({showPaymentDialog: false, showPaymentSuccessDialog: true});
    }

    onPaymentSuccessClose = () => {
        this.setState({showPaymentSuccessDialog: false, selectedProductId: null});
    }

    onSubscriptionCancel = async(productId, subscriptionEndDate) => {
        this.setState({selectedProductId: productId, showCancelDialog: true, subscriptionEndDate});
    }

    hasMissingUserData = () => {
        const user = this.context.currentUser;
        if (!user) {
            return true;
        }
        return !user.title || !user.firstName || !user.lastName 
            || !user.company || !user.streetAndNumber || !user.zipCode || !user.city || !user.phoneNumber;
    }

    getProject = () => {
        switch (localStorage.getItem(APP_TYPE_KEY)) {
            case TENDER_APP:
            case COCKPIT_APP:
                return getActiveProject(this.context);
            case ORDER_APP:
                return this.context.appData.allProjects?.find(p => p.id === this.context.appData.activeProjectId);
            default:
                return null;
        }
    }

    getInactiveProductSubtitle = (licenseType) => {
        if (isCockpit() && licenseType === LICENSE_TYPES.COOPERATION_LICENSE) {
            return "payment.inactive.product.cockpit." + licenseType + ".subtitle";
        } else {
            return "payment.inactive.product." + licenseType + ".subtitle";
        }
    }

    cardForLicenseType = (licenseType) => {
        const methodType = getActivePaymentMethodType(this.context, licenseType);
        const paymentStatus = getLatestPaymentStatus(this.context, licenseType)
        const activePayment = latestActivePayment(this.context, licenseType);

        let component;
        let subtitle;
        if (activePayment) {
            component = (
                <ActiveProduct
                    activePriceId={activePayment.priceId}
                    licenseType={activePayment.licenseType}
                    packageType={activePayment.packageType}
                    prices={Array.from(this.state.prices.values())}
                    products={Array.from(this.state.products.values()).filter(p => p.metadata?.licenseType === licenseType)}
                    onCancel={(productId, subscriptionEndDate) => this.onSubscriptionCancel(productId, subscriptionEndDate)}/>
            );
            subtitle = "payment.active.product." + licenseType + ".subtitle";
        } else {
            const product = Array.from(this.state.products).map(([productId, product]) => product)
                .find((product) => product.metadata.licenseType === licenseType);
            const price = Array.from(this.state.prices).map(([priceId, price]) => price).find(price => price.id === product.defaultPrice);

            if (methodType === PAYMENT_METHOD_TYPES.INVOICE && paymentStatus === "past_due") {
                component = (<Product product={product}
                                      price={price}
                                      showInfoIcon={false}
                                      disabled={true}/>
                );
                subtitle = "payment.error.product." + licenseType + ".subtitle";
            } else {
                component = (
                    <Product
                        product={product}
                        price={price}
                        showInfoIcon={true}
                        selectTooltip={disabledProductSelectTooltip(this.props.intl, licenseType, this.state.sumGross)}
                        disabled={this.state.isRequesting || isAdmin(this.context.currentUser)
                            || disabledProductSelectTooltip(this.props.intl, licenseType, this.state.sumGross)}
                        onSelectProduct={() => this.onSelectProduct(product.id)}/>
                );
                subtitle = this.getInactiveProductSubtitle(licenseType);
            }
        }

        return (
            <Card key={"payment-management-license-card-" + licenseType}>
                <CardContent>

                    <div style={{textAlign: "center"}}>

                        <h2>
                            <FormattedMessage id={"payment.licenseType.title." + licenseType}/>
                        </h2>

                        <Typography variant="subtitle2">
                            <FormattedMessage id={subtitle}/>
                        </Typography>

                        {licenseType === LICENSE_TYPES.OFFER_LICENSE && <div style={{display: "flex", justifyContent: "center"}}><div
                            style={{backgroundColor: texisionOrange, marginBottom: 20, borderRadius: 10, padding: 5, width: 331, fontWeight: "bolder"}}>
                            <FormattedMessage id="payment.OFFER_LICENSE.freeAddOnInfo"/>
                        </div></div>}

                        {component}

                    </div>

                </CardContent>
            </Card>
        );
    }

    cardForAnalysis = () => {

        const hasAnalysisBooked = this.context.operationEvents?.hasAnalysisBooked;
        const hasPublishLicense = latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE);
        if (!hasAnalysisBooked && hasPublishLicense) {
            return null;
        }
        const operationEvent = this.context.operationEvents?.events
            .find(event => event.eventType === EVENT_TYPES.ANALYSIS && event.event?.status === "active");
        // hide card if event is in past
        if (operationEvent?.event && convertStringToDate(operationEvent.event.end_time) < new Date()) {
            return null;
        }
        return (
            <Card style={{marginBottom: 50}}>
                <CardContent>
                    <h2>
                        <FormattedMessage id={hasAnalysisBooked && operationEvent ? "payment.analysisBooked.title" : "navBar.freeAnalysis.dialog.title"}/>
                    </h2>
                    {!hasAnalysisBooked &&
                        <>
                            <Typography variant="subtitle2">
                                <FormattedMessage id={"navBar.freeAnalysis.dialog.subtitle"}/>
                            </Typography>
                            <Button
                                disabled={isAdmin(this.context.currentUser)}
                                color="primary"
                                variant="contained"
                                onClick={() => this.setState({showCalendly: true})}>
                                <FormattedMessage id="calendly.book.button"/>
                            </Button>
                        </>}

                    {operationEvent && <OperationEventCard operationEvent={operationEvent}/>}
                </CardContent>
            </Card>
        );
    }

    render() {
        const hasOperation = !!this.getProject();
        const hasLoaded = !this.state.isLoading && this.state.products && this.state.prices;
        const operationId = getActiveOperation(this.context)?.id;

        let subtitleId;
        if (isTender()) {
            subtitleId = "payment.management.noSubscription.tender.subtitle";
        } else {
            subtitleId = "payment.management.noSubscription.cockpit.subtitle";
        }

        const selectedProduct = this.state.products.get(this.state.selectedProductId);

        return (
            <>
                <CalendlyPopup
                    key={"calendly-payment-management-analysis"}
                    showCalendly={this.state.showCalendly}
                    operationId={operationId}
                    eventType={EVENT_TYPES.ANALYSIS}
                    eventDuration={EVENT_DURATIONS.HALF_HOUR}
                    onClose={() => this.setState({showCalendly: false})}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showCompleteProfileDialog}
                    titleId="payment.completeProfile.dialog.title"
                    subtitleId="payment.completeProfile.dialog.subtitle"
                    content={<UserProfile
                        key={this.context.currentUser?.version}
                        currentUser={this.context.currentUser}
                        onSuccess={() => this.onProfileSave()}/>}
                    cancelId="commons.cancel.button"
                    onCancel={() => this.onCompleteProfileCancel()}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showCouponForm}
                    titleId="payment.coupon.redeem.title"
                    content={<CouponForm
                        licenseType={this.state.products.get(this.state.selectedProductId)?.metadata.licenseType}
                        onCouponAdd={(coupon) => this.setState({coupon})}
                        onCouponRemove={() => this.setState({coupon: null})}
                        currency={this.getProject()?.currency}
                    />}
                    cancelId="commons.cancel.button"
                    onCancel={() => this.setState({showCouponForm: false, coupon: null, selectedProductId: null})}
                    actionId={this.state.coupon ? "payment.coupon.continueWithCoupon.button" : "payment.coupon.continueWithoutCoupon.button"}
                    actionDisabled={this.state.isRequesting}
                    onAction={() => this.purchase()}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    size="lg"
                    open={this.state.showPaymentDialog}
                    titleId="payment.subscribe.dialog.title"
                    content={<Payment
                        operationId={operationId}
                        coupon={this.state.coupon}
                        clientSecret={this.state.clientSecret}
                        isSetupIntent={this.state.isSetupIntent}
                        currency={this.getProject()?.currency}
                        price={this.state.prices.get(this.state.products.get(this.state.selectedProductId)?.defaultPrice)}
                        product={this.state.products.get(this.state.selectedProductId)}
                        onPaymentSuccess={(licenseType) => this.onPaymentSuccess(licenseType)}
                        onPaymentCancel={() => this.onPaymentCancel()}/>}
                    cancelId="commons.close.label"/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showPaymentSuccessDialog}
                    titleId={getTrialText(this.props.intl, selectedProduct)
                        ? "payment.paymentSuccess.trial.dialog.title"
                        : "payment.paymentSuccess.dialog.title"}
                    subtitleId={selectedProduct?.metadata?.licenseType
                        ? ("payment.paymentSuccess." + selectedProduct?.metadata?.licenseType + ".dialog.subtitle")
                        : null}
                    replacements={{trialNotice: getTrialText(this.props.intl, selectedProduct), trialMonths: getTrialMonths(selectedProduct)}}
                    cancelId="commons.close.label"
                    onCancel={() => this.onPaymentSuccessClose()}/>

                <Typography variant="h1">
                    {this.getProject()
                        ? <FormattedMessage id="payment.management.title" values={{tenderName: this.getProject().name}}/>
                        : <FormattedMessage id="payment.management.noOperation.title"/>
                    }
                </Typography>

                <Typography variant="subtitle1" style={{marginBottom: 40}}>
                    <FormattedMessage id={subtitleId}/>
                </Typography>

                {hasOperation
                    ? <>
                        {isTender() && this.cardForAnalysis()}

                        {hasLoaded && <Grid container spacing={4} justifyContent="space-evenly" alignItems="stretch">

                            {isTender() && <Grid item xs={12} md={6}>
                                {this.cardForLicenseType(LICENSE_TYPES.TENDER_LICENSE)}
                            </Grid>}

                            <Grid item xs={12} md={isTender() ? 6 : 12}>
                                {this.cardForLicenseType(isTender() ? LICENSE_TYPES.OFFER_LICENSE : LICENSE_TYPES.COOPERATION_LICENSE)}
                            </Grid>

                            <Grid item xs={12}>
                                <Card>
                                    <MajorCustomerInformation type={MAJOR_CUSTOMER_INFORMATION_TYPE.BOTH}/>
                                </Card>
                            </Grid>

                        </Grid>}
                    </>

                    : <Card>
                        <CardContent>
                            <Typography style={{textAlign: "center"}}>
                                <FormattedMessage id="payment.products.noOperation"/>
                            </Typography>
                        </CardContent>
                    </Card>
                }
            </>
        );
    }
}

export default withRouter(injectIntl(withSnackbar(PaymentManagement)));
