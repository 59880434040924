import React, {Component} from "react";
import {injectIntl} from "react-intl";
import AddIcon from '@mui/icons-material/Add';
import Card from "@material-ui/core/Card";
import {CardActionArea, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from "@material-ui/core/Grid";
import ValidationButton from "../../../validation/ValidationButton";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {PROJECT_TASKS} from "../../../../util/Constants";
import {getActiveOperation} from "../../../../services/OperationService";
import {getActiveProject, isProjectTaskCompleted} from "../../../../services/ProjectService";
import {getActiveUnit} from "../../../../services/BusinessUnitService";
import {isCockpit, isTender} from "../../../../util/Util";

class LogisticsTile extends Component {

    static contextType = GeneralContext;

    state = {disableTooltip: false, tooltipOpen: false};

    getProjectId = () => {
        if (isTender()) {
            return getActiveProject(this.context)?.id;
        } else if (isCockpit()) {
            return getActiveOperation(this.context)?.workingProject?.id;
        }
    }

    isLogisticCompleted = () => {
        return isProjectTaskCompleted(this.context, PROJECT_TASKS.LOGISTIC, getActiveUnit(this.context)?.id);
    }

    getValidationTooltip = (isCompleted) => {
        if (this.isLogisticCompleted()) {
            return this.props.intl.formatMessage({id: "logistics.validation.locked.tooltip"});
        }
        let textId;
        switch (this.props.projectTask) {
            case PROJECT_TASKS.DELIVERY_ADDRESS:
                textId = isCompleted ? "logistics.deliveryAddress.edit.tooltip" : "logistics.deliveryAddress.validation.tooltip";
                break;
            case PROJECT_TASKS.PICKUP_ADDRESS:
                textId = isCompleted ? "logistics.pickupAddress.edit.tooltip" : "logistics.pickupAddress.validation.tooltip";
                break;
            case PROJECT_TASKS.DELIVERY_QUANTITY:
                textId = isCompleted ? "logistics.quantities.edit.tooltip" : "logistics.quantities.validation.tooltip";
                break;
            case PROJECT_TASKS.ARTICLE_ASSIGNMENT:
                textId = isCompleted ? "logistics.assignment.edit.tooltip" : "logistics.assignment.validation.tooltip";
                break;
            default:
                return "";
        }
        return this.props.intl.formatMessage({id: textId});
    }

    render() {
        if (this.props.onAdd && this.props.addTitle) {
            return (
                <Tooltip title={this.isLogisticCompleted() ? this.props.intl.formatMessage({id: "logistics.validation.locked.tooltip"}) : ""}>
                    <Card
                        style={{height: 150, width: 150, backgroundColor: "#CDDBE3", borderRadius: "10px", padding: 0}}
                        onClick={this.props.readOnly || this.isLogisticCompleted() ? null : () => this.props.onAdd()}>
                        <CardActionArea>
                            <div style={{height: 100, width: 150, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <AddIcon fontSize="large"/>
                            </div>
                            <div style={{height: 50, width: 150, display: "flex", justifyContent: "center", alignItems: "flex-start", textAlign: "center"}}>
                                {this.props.addTitle}
                            </div>
                        </CardActionArea>
                    </Card>
                </Tooltip>
            );
        }

        const isCompleted = isProjectTaskCompleted(this.context, this.props.projectTask, this.props.objectId);

        return (
            <Card
                style={{height: 150, width: 150, backgroundColor: "#CDDBE3", borderRadius: "10px", padding: 0}}>

                    <div style={{height: 75, width: 150, display: "flex", justifyContent: "center",
                        alignItems: "center", textAlign: "center", fontWeight: "bold"}}>
                        {this.props.content}
                    </div>

                    <div style={{height: 75, width: 150, display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Grid container justifyContent="space-around">

                            <Grid item xs={4}>
                                {this.props.onEdit && !isCompleted ? <Tooltip title={this.props.intl.formatMessage({id: "commons.edit.button"})}>
                                    <IconButton
                                        onClick={() => this.props.onEdit()}
                                        disabled={this.props.disabled}>
                                        <EditIcon/>
                                    </IconButton>
                                </Tooltip> : null}
                            </Grid>

                            <Grid item xs={4}>
                                {this.props.onDelete && !isCompleted
                                    ? <Tooltip title={this.props.intl.formatMessage({id: "commons.delete.button"})}>
                                        <IconButton
                                            onClick={() => this.props.onDelete()}
                                            disabled={this.props.disabled}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Tooltip> : null}
                            </Grid>

                            <Grid item xs={4}>
                                {this.props.projectTask && this.props.objectId !== undefined && this.props.objectId !== null && !this.props.disabled
                                    && <Tooltip
                                        title={this.state.disableTooltip ? "" : this.getValidationTooltip(isCompleted)}
                                        open={this.state.tooltipOpen}
                                        onOpen={this.state.disableTooltip ? null : () => this.setState({tooltipOpen: true})}
                                        onClose={() => this.setState({tooltipOpen: false})}>
                                        <div>
                                            <ValidationButton
                                                skipRedirect={true}
                                                onDialogOpen={() => this.setState({disableTooltip: true, tooltipOpen: false})}
                                                onDialogClose={async() => {
                                                    // wait because after closing the dialog the tooltips onOpen function might be called, even if the
                                                    // mouse cursor is not near the tooltip component
                                                    await new Promise(res => setTimeout(res, 500));
                                                    this.setState({tooltipOpen: false, disableTooltip: false})
                                                }}
                                                disabled={this.props.disabled}
                                                iconButton={true}
                                                projectTask={this.props.projectTask}
                                                objectId={this.props.objectId}
                                                completed={isCompleted}
                                                projectId={this.getProjectId()}/>
                                        </div>
                                    </Tooltip>}
                            </Grid>

                        </Grid>
                        {this.props.addTitle}
                    </div>

            </Card>
        );
    }
}

export default injectIntl(LogisticsTile);
