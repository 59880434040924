import React, {Component} from 'react';
import TenderNavBar from '../navigation/tender/TenderNavBar';

import ArticleConfigurator from '../administration/articleConfigurator/ArticleConfigurator';
import OffsettingOverview from '../businessUnit/offsetting/OffsettingOverview';
import LogisticsOverview from '../businessUnit/logistics/LogisticsOverview';
import ContractOverview from '../documents/contract/ContractOverview';
import ProjectOverview from '../project/ProjectOverview';
import CustomerArticleOverview from '../assortment/customerArticles/CustomerArticleOverview';

import UserManagement from '../administration/userManagement/UserManagement';

import MyAccount from '../account/MyAccount';

import OperatingResourceOverview from '../administration/operatingResources/OperatingResourceOverview';
import OperatingResources from '../assortment/operatingResource/OperatingResources';
import OperatingResourceConfigurator from '../administration/operatingResources/OperatingResourceConfigurator';

import {
    AGB_ROUTE,
    ARTICLE_CONFIGURATOR_ROUTE,
    ASSORTMENT_EXPORT_ROUTE,
    BILL_OF_QUANTITIES_EXPORT_ROUTE,
    CONTRACT_ROUTE,
    CUSTOMER_ARTICLES_ROUTE,
    IMPRESSUM_ROUTE,
    INVOICE_CONTROLLING_ROUTE,
    LICENSE_ROUTE,
    LOGISTICS_EXPORT_ROUTE,
    LOGISTICS_ROUTE,
    MY_ACCOUNT_ROUTE,
    OPERATING_RESOURCES_ROUTE,
    OFFER_RATING_ROUTE,
    OFFER_RESULT_ROUTE,
    OFFER_SUITABILITY_ROUTE,
    OFFSETTING_ROUTE,
    OPERATING_RESOURCE_CONFIGURATOR_ROUTE,
    OPERATING_RESOURCE_OVERVIEW_ROUTE,
    PRICE_SHEET_EXPORT_ROUTE,
    PRIVACY_ROUTE,
    PROCEDURE_CONDITIONS_ROUTE,
    PROFILES_ROUTE,
    PROJECT_OVERVIEW_ROUTE,
    RENTAL_LINEN_ASSIGNMENT_ROUTE,
    RENTAL_LINEN_ROUTE,
    RESIDENTS_LAUNDRY_ROUTE,
    SPECIAL_SERVICES_ROUTE,
    TENDER_COMMUNICATION_ROUTE,
    TENDER_HISTORY_ROUTE,
    USER_MANAGEMENT_ROUTE,
    MOBILE_THRESHOLD,
    PAYMENT_MANAGEMENT_ROUTE,
    DASHBOARD_ROUTE,
    PROJECT_TASKS,
    BUSINESS_UNIT_OVERVIEW_ROUTE,
    ARTICLE_CONFIGURATION_OVERVIEW_ROUTE,
    OPERATION_ASSIGNMENT_ROUTE,
    RELEASE_NOTES_ROUTE,
    PRICE_COMPARISON_ROUTE,
    INVOLVED_BIDDERS_ROUTE,
    OFFER_DOCUMENT_ROUTE, LAUNDRY_RADAR
} from '../../util/Constants';
import {Role} from '../../services/UserService';

import {Redirect, Route, Switch} from "react-router-dom";

import '../apps/App.css';
import ResidentsLaundry from '../assortment/residentsLaundry/ResidentsLaundry';
import SpecialServices from '../assortment/specialServices/SpecialServices';
import ProfileOverview from '../administration/profile/ProfileOverview';
import LogisticsExport from '../documents/LogisticsExport';
import AssortmentExport from '../documents/assortmentAndResources/AssortmentExport';
import PriceSheetExport from '../documents/PriceSheetExport';
import BillOfQuantitiesExport from '../documents/BillOfQuantitiesExport';
import Licenses from '../administration/licenses/Licenses';
import ProcedureConditions from '../procedure/ProcedureConditions';
import RentalLinen from '../assortment/rentalLinen/RentalLinen';
import RentalLinenAssignment from '../assortment/rentalLinen/RentalLinenAssignment';
import OfferSuitabilityOverview from '../offers/suitability/OfferSuitabilityOverview';
import {OfferRatingOverview} from '../offers/rating/OfferRatingOverview';
import BidderCommunication from '../communication/BidderCommunication';
import {InvoiceControlling} from '../offers/controlling/InvoiceControlling';
import Privacy from '../legal/Privacy';
import TermsOfUse from '../legal/TermsOfUse';
import Impressum from '../legal/Impressum';
import {GeneralContext} from "../contexts/GeneralContext";
import BusinessUnitRoute from '../navigation/shared/BusinessUnitRoute';
import TenderRoute from '../navigation/tender/TenderRoute';
import PrivateRoute from '../navigation/shared/PrivateRoute';
import TenderHistory from '../tender/TenderHistory';

import MediaQuery from "react-responsive";
import PaymentManagement from '../money/PaymentManagement';
import Dashboard from "../validation/Dashboard";
import BusinessUnitOverview from "../businessUnit/baseData/BusinessUnitOverview";
import ArticleConfigurationOverview from "../administration/articleOverview/ArticleConfigurationOverview";
import OperationAssignment from "../project/OperationAssignment";
import ReleaseNotes from "../navigation/shared/ReleaseNotes";
import InvolvedBidders from "../offers/InvolvedBidders";
import OfferDocumentOverview from "../offers/documents/OfferDocumentOverview";
import OfferResultOverview from "../offers/result/OfferResultOverview";
import PriceComparison from "../offers/prices/PriceComparison";
import OfferRoundRoute from "../navigation/shared/OfferRoundRoute";
import LaundryRadar from "../navigation/tender/LaundryRadar";

class TenderApp extends Component {

    static contextType = GeneralContext;

    getRouteSwitch = () => {
        return (
            <Switch>
                        
                <Route path={IMPRESSUM_ROUTE} component={Impressum}/>
                <Route path={AGB_ROUTE} component={TermsOfUse}/>
                <Route path={PRIVACY_ROUTE} component={Privacy}/>

                <Route path={MY_ACCOUNT_ROUTE} component={MyAccount}/>
                <Route path={RELEASE_NOTES_ROUTE} component={ReleaseNotes}/>
                <Route path={LAUNDRY_RADAR} component={LaundryRadar}/>

                <Route path={DASHBOARD_ROUTE} component={Dashboard}/>

                <Route path={PROJECT_OVERVIEW_ROUTE} component={ProjectOverview}/>

                <Route path={PAYMENT_MANAGEMENT_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN]} component={PaymentManagement}/>
                </Route>

                <Route path={ARTICLE_CONFIGURATOR_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ArticleConfigurator}/>
                </Route>

                <Route path={ARTICLE_CONFIGURATION_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ArticleConfigurationOverview}/>
                </Route>

                <Route path={USER_MANAGEMENT_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN]} component={UserManagement}/>
                </Route>

                <Route path={PROFILES_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ProfileOverview}/>
                </Route>

                <Route path={LICENSE_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={Licenses}/>
                </Route>

                <Route path={OPERATING_RESOURCE_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={OperatingResourceOverview}/>
                </Route>

                <Route path={OPERATING_RESOURCE_CONFIGURATOR_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={OperatingResourceConfigurator}/>
                </Route>

                <Route path={BUSINESS_UNIT_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN]} component={BusinessUnitOverview}/>
                </Route>

                <Route path={OPERATION_ASSIGNMENT_ROUTE} component={OperationAssignment}/>

                <TenderRoute
                    path={CONTRACT_ROUTE}
                    component={ContractOverview}/>

                <TenderRoute
                    path={PROCEDURE_CONDITIONS_ROUTE}
                    component={ProcedureConditions}/>

                {/** business unit related components, need key to rerender on changed active unit id */}

                <BusinessUnitRoute 
                    titleId="rentalLinen.title" 
                    subtitleId="rentalLinen.subtitle" 
                    path={RENTAL_LINEN_ROUTE} 
                    component={RentalLinen}
                    projectTask={PROJECT_TASKS.RENTAL_LINEN}/>

                <BusinessUnitRoute 
                    path={RENTAL_LINEN_ASSIGNMENT_ROUTE}
                    projectTask={PROJECT_TASKS.RENTAL_LINEN}
                    component={RentalLinenAssignment}/>

                <BusinessUnitRoute 
                    titleId="customerArticle.title.h1" 
                    subtitleId="customerArticle.title.h2" 
                    path={CUSTOMER_ARTICLES_ROUTE} 
                    component={CustomerArticleOverview}
                    projectTask={PROJECT_TASKS.CUSTOMER_ARTICLES}/>

                <BusinessUnitRoute 
                    titleId="residentsLaundry.h1" 
                    subtitleId="residentsLaundry.h2" 
                    path={RESIDENTS_LAUNDRY_ROUTE} 
                    component={ResidentsLaundry}
                    projectTask={PROJECT_TASKS.RESIDENTS_LAUNDRY}/>

                <BusinessUnitRoute 
                    titleId="operatingResources.navbar.selection"
                    subtitleId="operatingResources.subtitle"
                    path={OPERATING_RESOURCES_ROUTE}
                    component={OperatingResources}
                    projectTask={PROJECT_TASKS.OPERATING_RESOURCES}/>

                <BusinessUnitRoute 
                    titleId="specialServices.title" 
                    subtitleId="specialServices.subtitle" 
                    path={SPECIAL_SERVICES_ROUTE} 
                    component={SpecialServices}
                    projectTask={PROJECT_TASKS.SPECIAL_SERVICES}/>

                <BusinessUnitRoute 
                    titleId="articleOffsetting.offsetting.h1" 
                    subtitleId="articleOffsetting.offsetting.h2" 
                    path={OFFSETTING_ROUTE} 
                    component={OffsettingOverview}
                    projectTask={PROJECT_TASKS.OFFSETTING}/>

                <BusinessUnitRoute 
                    titleId="logistics.title"
                    subtitleId="logistics.subtitle"
                    path={LOGISTICS_ROUTE} 
                    component={LogisticsOverview}
                    projectTask={PROJECT_TASKS.LOGISTIC}/>

                {/** */}

                <TenderRoute path={PRICE_SHEET_EXPORT_ROUTE} component={PriceSheetExport}/>
                <TenderRoute path={ASSORTMENT_EXPORT_ROUTE} component={AssortmentExport}/>
                <TenderRoute path={LOGISTICS_EXPORT_ROUTE} component={LogisticsExport}/>
                <TenderRoute path={BILL_OF_QUANTITIES_EXPORT_ROUTE} component={BillOfQuantitiesExport}/>

                <TenderRoute path={TENDER_COMMUNICATION_ROUTE} component={BidderCommunication}/>

                <OfferRoundRoute path={OFFER_DOCUMENT_ROUTE} component={OfferDocumentOverview}/>
                <OfferRoundRoute path={INVOLVED_BIDDERS_ROUTE} component={InvolvedBidders}/>
                <OfferRoundRoute path={PRICE_COMPARISON_ROUTE} component={PriceComparison}/>
                <OfferRoundRoute path={OFFER_SUITABILITY_ROUTE} component={OfferSuitabilityOverview}/>
                <OfferRoundRoute path={OFFER_RATING_ROUTE} component={OfferRatingOverview}/>
                <OfferRoundRoute path={OFFER_RESULT_ROUTE} component={OfferResultOverview}/>

                <TenderRoute path={INVOICE_CONTROLLING_ROUTE} component={InvoiceControlling}/>

                <TenderRoute path={TENDER_HISTORY_ROUTE} component={TenderHistory}/>


                <Route>
                    <Redirect to={DASHBOARD_ROUTE}/>
                </Route>

            </Switch>
        );
    }

    render() {

        return (
            <>

                <MediaQuery minWidth={MOBILE_THRESHOLD}>

                    <TenderNavBar mobile={false} key={this.context.appData.activeProjectId}/>

                    <div className="contentArea">
                        {this.getRouteSwitch()}
                    </div>

                </MediaQuery>

                <MediaQuery maxWidth={MOBILE_THRESHOLD-1}>

                    <TenderNavBar mobile={true} key={this.context.appData.activeProjectId}/>

                    <div className="contentAreaMobile">
                        {this.getRouteSwitch()}
                    </div>

                </MediaQuery>

            </>
        )
    }
}

export default TenderApp;
