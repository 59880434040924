import React, {Component} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {putAsyncCatch} from "../../../services/BackendService";
import {setOperation} from "../../../services/OperationService";
import {Button, FormControl, Grid, InputLabel, Select} from "@material-ui/core";
import {LICENSE_TYPES, PAYMENT_METHOD_TYPES} from "../../../util/Constants";
import {withSnackbar} from "notistack";
import {deletePayment} from "../../../services/PaymentService";

class PaymentConfiguration extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            oldMap: new Map(Object.entries(this.props.operation.paymentConfiguration)),
            paymentConfiguration: new Map(Object.entries(this.props.operation.paymentConfiguration))
        }
    }

    handleConfigurationChanged = (key, value) => {
        this.setState(prevState => ({
            paymentConfiguration: new Map([...prevState.paymentConfiguration, [key, value]])
        }));
    }

    handleConfigurationSaved = async() => {
        let operation = this.props.operation;
        const path = "/operation/" + operation.id;
        operation.paymentConfiguration = Object.fromEntries(this.state.paymentConfiguration.entries());
        const updatedOperation = await putAsyncCatch(this.context, path, operation, this.props);
        if (updatedOperation) {
            setOperation(this.context, updatedOperation);
            for (let [key, value] of this.state.oldMap.entries()) {
                if (value !== this.state.paymentConfiguration.get(key)) {
                    await deletePayment(this.context, this.props, key, operation.id);
                }
            }
        }
    }

    handleConfigurationReset = () => {
        this.setState({ paymentConfiguration: new Map(Object.entries(this.props.operation.paymentConfiguration)) });
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    {Object.keys(LICENSE_TYPES).map(licenseType => {
                       return <Grid item xs={4}>
                            <FormControl variant="outlined" style={{width: "100%"}}>
                                <InputLabel id="is_private-native-label" variant="filled">
                                    {this.props.intl.formatMessage({id: "payment.licenseType.title." + licenseType})}
                                </InputLabel>

                                <Select
                                    labelId="subcategory-native-simple-label"
                                    native
                                    variant="filled"
                                    value={this.state.paymentConfiguration.get(licenseType) ?? PAYMENT_METHOD_TYPES.SEPA_OR_CARD}
                                    onChange={(e) => this.handleConfigurationChanged(licenseType, e.target.value)}
                                    label={this.props.intl.formatMessage({id: "payment.licenseType.title." + licenseType})}>
                                    {Object.keys(PAYMENT_METHOD_TYPES).map(methodType => {
                                        return <option
                                            key={"option-" + licenseType + "-" + methodType}
                                            value={methodType}>
                                            {this.props.intl.formatMessage({id: "constants.PaymentMethodType." + methodType})}
                                        </option>
                                    })}
                                </Select>
                            </FormControl>
                       </Grid>
                    })}
                </Grid>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => this.handleConfigurationReset()}>
                            {this.props.intl.formatMessage({id: "commons.cancel.button"})}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => this.handleConfigurationSaved()}>
                            {this.props.intl.formatMessage({id: "commons.save.button"})}
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

PaymentConfiguration.propTypes = {
    operation: PropTypes.object.isRequired
}

export default withSnackbar(injectIntl(PaymentConfiguration));
