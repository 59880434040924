import React, {Component} from 'react';
import {getAsync, putAsyncCatch} from '../../../services/BackendService';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {CardContent} from '@material-ui/core';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    ARTICLE_CATEGORY, CATEGORY, DIALOG_TYPE_INFO, DIALOG_TYPE_WARNING,
    NO_OFFSETTING_SELECTED, RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL, RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH,
    ROOT_NODE_NAME, SUB_CATEGORY, USER_DEFINED
} from '../../../util/Constants';
import Divider from '@material-ui/core/Divider';

import ArticleOffsettingTree from './article/ArticleOffsettingTree';
import ArticleOffsettingEntry from './article/ArticleOffsettingEntry';
import ResidentsLaundryOffsettingEntry from './article/ResidentsLaundryOffsettingEntry';

import {createDataStructure} from './article/ArticleOffsettingUtil';

import LinearProgress from '@material-ui/core/LinearProgress';

import '../../apps/App.css';
import '../../../css/ArticleOffsetting.css';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {GeneralContext} from "../../contexts/GeneralContext";
import { withSnackbar } from 'notistack';
import {updateValidationMap} from "../../../services/ProjectService";
import {isTender} from "../../../util/Util";

const internalIdsMap = new Map();
let internalIdCounter = 0;

class ArticleAndLaundryOffsettings extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            offsetting: null,
            tree: new ArticleOffsettingTree(), // data structure that holds of the current offsettings.
            originalTree: new ArticleOffsettingTree(), // original data structure to calculate differences.
            node: null, // If the selection in the tree has changed, and the overwriteCheck returns true, the node and the offsetting will be put into the state.
            nodeOffsetting: null,
            showOverwriteWarningDialog: false,
            showDifferencesDialog: false,
            differences: [] // the changes that are made in the tree
        }
    }

    async componentDidMount() {
        await this.loadArticleOffsettings();
    }

    componentDidUpdate() {
        const hasUnsavedChanges = this.state.differences && this.state.differences.length > 0;
        this.context.setUnsavedChanges(!!hasUnsavedChanges);
    }

    loadArticleOffsettings = async() => {
        let response = await getAsync('/offsetting/' + this.props.unitId);
        if (response?.status === 200) {
            let tree = new ArticleOffsettingTree();
            let originalTree = new ArticleOffsettingTree();
            createDataStructure(tree, originalTree, response.data, this.getInternalId);
            this.setState({offsetting: response.data, tree: tree, originalTree: originalTree, loading: false});
            if (this.props.onOffsettingChange) {
                this.props.onOffsettingChange(response.data);
            }
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    saveArticleOffsettings = async() => {
        
        const offsettingsMap = new Map();
        const currentTree = this.state.tree;
        const root = currentTree.getRoot();

        let flattenedTree = currentTree.flatten(root);
        for (let path of flattenedTree) {
            let category = path[0];
            let subCategory = path[1];
            let articleCategory = path[2];

            let articles = this.findArticles(category.name, subCategory.name, articleCategory.name);
            for (let article of articles) {
                let currentOffsetting = {
                    id: article.id,
                    articleId: article.articleId,
                    businessUnitId: this.props.unitId,
                    categoryOffsetting: category.offsetting,
                    subCategoryOffsetting: subCategory.offsetting,
                    articleCategoryOffsetting: articleCategory.offsetting,
                    version: article.version
                }

                offsettingsMap.set(currentOffsetting.articleId, currentOffsetting);
            }
        }

        let offsettingsToSave = {
            articleOffsetting: Array.from(offsettingsMap.values())
        }

        // update article offsettings
        let responseData = await putAsyncCatch(this.context, '/offsetting/' + this.props.projectId, offsettingsToSave, this.props);
        if (responseData) {
            this.setState({differences: [], showDifferencesDialog: false});
            await this.loadArticleOffsettings();
            await updateValidationMap(this.context);
        } else {
            this.setState({showDifferencesDialog: false});
        }
    }

    getInternalId = (name) => {
        let internalId = internalIdsMap.get(name);
        if (typeof internalId === 'undefined') {
            internalId = internalIdCounter++;
            internalIdsMap.set(name, internalId);
        }
        return internalId;
    }

    writeOffsettingValues = (node, offsetting, currentTree) => {
        currentTree.setOffsetting(node, offsetting);
        const originalTree = this.state.originalTree;
        const differences = originalTree.findDifferences(currentTree, originalTree);
        this.setState({differences: differences, showOverwriteWarningDialog: false});
    }

    findArticles(category, subCategory, articleCategory) {
        const offsetting = this.state.offsetting.articleOffsetting;

        const articles = [];
        for (let article of offsetting) {
            if (article.category === category && article.subCategory === subCategory && article.articleCategory === articleCategory) {
                articles.push(article);
            }
        }
        return articles;
    }

    handleArticleOffsettingChanged = (node, offsetting) => {
        const currentTree = this.state.tree;
        let hasExistingValues = currentTree.overwriteCheck(node.internalId, offsetting);

        // update parent nodes to USER_DEFINED if it's not matching with selected offsetting
        const setParentsOffsetting = () => {
            if (node && offsetting !== NO_OFFSETTING_SELECTED) {
                currentTree.findParents(node)
                    .filter(parentNode => parentNode.offsetting !== offsetting)
                    .forEach(parentNode => parentNode.offsetting = USER_DEFINED);
            }
        }

        if (hasExistingValues) {
           this.setState({
               showOverwriteWarningDialog: true, node: node, nodeOffsetting: offsetting, setParentsOffsetting
           });

       } else if (offsetting === USER_DEFINED) {
            setParentsOffsetting();
            node.offsetting = offsetting;

       } else {
            setParentsOffsetting();
            this.writeOffsettingValues(node, offsetting, currentTree);
        }
    }

    handleCancelArticleOffsettingEdit = async() => {
        await this.loadArticleOffsettings();
        this.setState({differences: [], showDifferencesDialog: false});
    }

    createOffsettingEntries = (tree) => {
        let offsettings = [];
        tree.traverse((node) => {
            if (node.name !== ROOT_NODE_NAME) {
                offsettings.push(this.createOffsettingEntry(node));
            }
        });
        return offsettings;
    }

    createLaundryOffsettingEntries = () => {
        let offsettings = [];
        const chemicalLaundry = this.props.residentsLaundry.sort((a, b) => ("" + a.name).localeCompare(b.name)).filter(l => l.cleanType === RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL);
        const containsChemicalLaundry = chemicalLaundry && chemicalLaundry.length >= 1;
        const washedLaundry = this.props.residentsLaundry.sort((a, b) => ("" + a.name).localeCompare(b.name)).filter(l => l.cleanType === RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH);
        const containsWashedLaundry = washedLaundry && washedLaundry.length >= 1;
        if (containsChemicalLaundry || containsWashedLaundry) {
            offsettings.push(this.createResidentsLaundryOffsettingEntry(CATEGORY, this.props.intl.formatMessage({id: "residentsLaundry.offsetting.title"})));
        }
        if (containsWashedLaundry) {
            offsettings.push(this.createResidentsLaundryOffsettingEntry(SUB_CATEGORY, this.props.intl.formatMessage({id: "residentsLaundry.offsetting.title.wash"})));
            for (let laundry of washedLaundry) {
                offsettings.push(this.createResidentsLaundryOffsettingEntry(ARTICLE_CATEGORY, laundry.name));
            }
        }
        if (containsChemicalLaundry) {
            offsettings.push(this.createResidentsLaundryOffsettingEntry(SUB_CATEGORY, this.props.intl.formatMessage({id: "residentsLaundry.offsetting.title.chemical"})));
            for (let laundry of chemicalLaundry) {
                offsettings.push(this.createResidentsLaundryOffsettingEntry(ARTICLE_CATEGORY, laundry.name));
            }
        }
        return offsettings;
    }

    createOffsettingEntry = (node) => {
        return (
            <Grid key={node.internalId}>
                <ArticleOffsettingEntry
                    key={node.internalId}
                    node={node}
                    handleOffsettingChanged={this.handleArticleOffsettingChanged}
                    readOnly={this.props.readOnly}/>
            </Grid>);
    }

    createResidentsLaundryOffsettingEntry = (category, name) => {
        return <Grid key={name}>
            <ResidentsLaundryOffsettingEntry 
                category={category} 
                name={name}/>
        </Grid>
    }

    showDifferencesDialog = () => {
        this.setState({showDifferencesDialog: true});
    }

    getTitle = () => {
        if (this.state.offsetting?.articleOffsetting?.length && this.props.residentsLaundry?.length) {
            return "articleOffsetting.offsetting.articleAndLaundryCardTitle";
        } else if (this.state.offsetting?.articleOffsetting?.length) {
            return "articleOffsetting.offsetting.articleCardTitle";
        } else if (this.props.residentsLaundry?.length) {
            return "articleOffsetting.offsetting.laundryCardTitle";
        } else {
            return "articleOffsetting.offsetting.articleAndLaundryCardTitle";
        }
    }

    getSubtitle = () => {
        if (this.state.offsetting?.articleOffsetting?.length && this.props.residentsLaundry?.length) {
            return "articleOffsetting.offsetting.articleAndLaundryCardSubtitle";
        } else if (this.state.offsetting?.articleOffsetting?.length) {
            return "articleOffsetting.offsetting.articleCardSubtitle";
        } else if (this.props.residentsLaundry?.length) {
            return "articleOffsetting.offsetting.laundryCardSubtitle";
        } else {
            return null;
        }
    }

    getEmptyCardText = () => {
        if (isTender()) {
            return "articleOffsetting.offsetting.empty.tender";
        } else if (this.props.fromInvoices) {
            return "articleOffsetting.offsetting.empty.projectWithInvoices";
        } else {
            return "articleOffsetting.offsetting.empty.project";
        }
    }

    render() {

        let offsettingUiElements = [];
        let showOffsettingUiElementsIntro = true;
        if (this.state.offsetting) {
            offsettingUiElements = [...this.createOffsettingEntries(this.state.tree)];
        }
        if (this.props.residentsLaundry?.length) {
            offsettingUiElements = [...offsettingUiElements, ...this.createLaundryOffsettingEntries()];
        }

        if (!offsettingUiElements.length) {
            showOffsettingUiElementsIntro = false;
            offsettingUiElements = (
                <Typography variant="body2" component="div" className="gray-dotted pt-5 pb-5 mt-6" style={{textAlign: "center"}}>
                    {this.props.intl.formatMessage({id: this.getEmptyCardText()})}
                </Typography>
            );
        }

        return (
            <>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showOverwriteWarningDialog}
                    titleId="articleOffsetting.dialog.overwrite.title"
                    subtitleId="articleOffsetting.dialog.overwrite.subtitle"
                    actionId="commons.yes.button"
                    cancelId="commons.no.button"
                    onAction={() => {
                        this.state.setParentsOffsetting();
                        this.writeOffsettingValues(this.state.node, this.state.nodeOffsetting, this.state.tree)
                    }}
                    onCancel={() => this.setState({showOverwriteWarningDialog: false, setParentsOffsetting: null})}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showDifferencesDialog}
                    titleId="articleOffsetting.dialog.differences.title"
                    subtitleId="articleOffsetting.dialog.differences.h2"
                    actionId="commons.yes.button"
                    cancelId="commons.no.button"
                    onAction={async() => await this.saveArticleOffsettings()}
                    onCancel={() => this.setState({showDifferencesDialog: false})}
                    content={<div>
                        <br/><br/>
                        {this.state.differences.map((diffEntry) => {
                            let label = null;
                            switch (diffEntry.type) {
                                case CATEGORY:
                                    label = <Typography variant="body2" component="span">
                                        {this.props.intl.formatMessage({id: "constants.Category." + diffEntry.label}) + " (" + this.props.intl.formatMessage({id: "entities.article.category"}) + ")"}
                                    </Typography>
                                    break;
                                case SUB_CATEGORY:
                                    label = <Typography variant="body2" component="span">
                                        {this.props.intl.formatMessage({id: "constants.SubCategory." + diffEntry.label}) + " (" + this.props.intl.formatMessage({id: "entities.article.subCategory"}) + ")"}
                                    </Typography>
                                    break;
                                case ARTICLE_CATEGORY:
                                    label = <Typography variant="body2" component="span">
                                        {this.props.intl.formatMessage({id: "constants.ArticleCategory." + diffEntry.label}) + " (" + this.props.intl.formatMessage({id: "entities.article.articleCategory"}) + ")"}
                                    </Typography>
                                    break;
                                default:
                                    break;
                            }

                            return (
                                <Grid key={diffEntry.internalId} container spacing={1} alignItems="center" style={{paddingLeft: "10%", paddingRight: "5%"}}>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" component="span">{label}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" component="span" style={{color: "red"}}>
                                            {this.props.intl.formatMessage({id: "constants.ArticleOffsetting."+ diffEntry.oldOffsetting})}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" component="span" style={{color: "green"}}>
                                            {this.props.intl.formatMessage({id: "constants.ArticleOffsetting."+ diffEntry.newOffsetting})}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </div>}/>


                    {this.state.loading 
                    ? <LinearProgress style={{marginTop: "10px"}} /> 
                    : <>
                        <Card className="child" style={offsettingUiElements && offsettingUiElements.length > 0 ? {paddingBottom: "2%"} : {}}>

                            <CardContent>

                                <Grid container spacing={2} className="mb-5">

                                    <Grid item xs>
                                        <Typography variant="h6" component="h6">
                                            <FormattedMessage id={this.getTitle()}/>
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        {!!this.state.differences.length &&
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => this.handleCancelArticleOffsettingEdit()}
                                                style={{marginLeft: "10px"}}
                                                disabled={this.props.readOnly}>
                                                <FormattedMessage id="commons.cancel.button"/>
                                            </Button>}
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => this.showDifferencesDialog()}
                                            disabled={this.props.readOnly || !this.state.differences.length || !offsettingUiElements}>
                                            <FormattedMessage id="commons.save.button"/>
                                        </Button>
                                    </Grid>

                                </Grid>

                                <Divider/>

                                {showOffsettingUiElementsIntro && this.getSubtitle() &&
                                    <Typography color="textSecondary" className="mt-5">
                                        <FormattedMessage id={this.getSubtitle()}/>
                                    </Typography>}

                                <Grid container direction="column" className="pt-2">
                                    {offsettingUiElements}
                                </Grid>

                            </CardContent>

                        </Card>
                    </>}
            </>
        );
    }
}

export default injectIntl(withSnackbar(ArticleAndLaundryOffsettings));
