import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {calculateDeliveryTimesMap, createTimeSlotMap, createTimeSlotsMapFromDeliveryTimesMap, hasAtLeastOnTimeSlot} from "../../../../util/LogisticsUtil";
import {isInCentralMode, isInDecentralMode} from "../../../../util/Util";
import {
    DELIVERY, DELIVERY_PICKUP,
    DIALOG_TYPE_INFO,
    DIALOG_TYPE_WARNING,
    LOGISTIC_TYPE_CENTRAL,
    LOGISTIC_TYPE_DECENTRAL,
    LOGISTIC_TYPE_SINGLE, PICKUP,
    PROJECT_TASKS
} from "../../../../util/Constants";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import LogisticsContent from "./LogisticsContent";
import LogisticsTile from "./LogisticsTile";
import {withSnackbar} from "notistack";
import {createAddress, deleteAddress, updateAddress} from "../../../../services/AddressService";
import {updateValidationMap} from "../../../../services/ProjectService";
import {getActiveUnit} from "../../../../services/BusinessUnitService";

class DeliveryOrPickupAddressTile extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            addressOpen: false,
            showDeleteDialog: false,
            addressData: this.getEmptyAddressData(),
            logisticTypeErrorText: ""
        }
    }

    componentDidMount() {
        if (this.props.address) {
            this.setState({addressData: this.getAddressDataFromProps()});
        }
    }

    getEmptyAddressData = () => {
        return {
            id: null,
            logisticType: "",
            description: "",
            shortDescription: "",
            costCenter: "",
            address: "",
            deliveryTimes: {},
            timeSlotMap: createTimeSlotMap(),
            deliveryTimesUnknown: false
        };
    }

    mayCreateOrUpdate = () => {
        const data = this.state.addressData;
        const activeUnit = getActiveUnit(this.context);
        const decentralDelivery = this.props.addressType === DELIVERY && this.state.addressData.logisticType === LOGISTIC_TYPE_DECENTRAL;
        const addressOptional = isInCentralMode(activeUnit) && decentralDelivery;

        return !!data.logisticType && !!data.description && !!data.shortDescription && !!data.costCenter
            && (!isInDecentralMode(activeUnit) || hasAtLeastOnTimeSlot(data.timeSlotMap) || data.deliveryTimesUnknown)
            && (addressOptional || !!data.address)
            && !this.state.logisticTypeErrorText;
    }

    getAddressDataFromProps = () => {
        let deliveryTimes = new Map();
        let timeSlotMap = new Map();
        // only calculate delivery times in these cases:
        if ((isInCentralMode(getActiveUnit(this.context)) && this.props.address.logisticType === LOGISTIC_TYPE_CENTRAL) ||
            (isInDecentralMode(getActiveUnit(this.context)) && !this.props.address.deliveryTimesUnknown)) {
            deliveryTimes = Object.fromEntries(calculateDeliveryTimesMap(this.state.addressData.timeSlotMap));
            timeSlotMap = createTimeSlotsMapFromDeliveryTimesMap(new Map(Object.entries(this.props.address.deliveryTimes)));
        }
        return {
            id: this.props.address.id,
            addressType: this.props.addressType,
            logisticType: this.props.address.logisticType,
            description: this.props.address.description,
            shortDescription: this.props.address.shortDescription,
            costCenter: this.props.address.costCenter,
            address: this.props.address.address,
            deliveryTimes: deliveryTimes,
            timeSlotMap: timeSlotMap,
            deliveryTimesUnknown: !this.props.address.deliveryTimes || !Object.keys(this.props.address.deliveryTimes)?.length
        };
    }

    createOrUpdateAddress = async() => {
        if (this.props.address) {
            if (this.state.deliveryAndPickUpType === LOGISTIC_TYPE_CENTRAL && this.state.formData.logisticType === LOGISTIC_TYPE_CENTRAL
                && this.state.allAssignedArticles.map((article) => article.deliveryAddressId).includes(this.state.formData.id)) {
                // show dialog that articles will be deleted from current delivery station
                this.setState({showDeliveryStationTypeChangeWarning: true});
            } else if (this.state.deliveryAndPickUpType === LOGISTIC_TYPE_CENTRAL && this.state.formData.logisticType === LOGISTIC_TYPE_DECENTRAL) {
                // show dialog that times will be deleted from current delivery station
                this.setState({showTimesWarning: true});
            } else {
                await this.editAddress();
            }
        } else {
            // create new delivery or pickup station
            const createdAddress = await createAddress(this.context, this.props, this.getAddressFromFormData());
            if (createdAddress) {
                this.context.setUnsavedChanges(false);
                await updateValidationMap(this.context);
                this.setState({addressOpen: false});
                this.props.onSave();
            }
        }
    }

    editAddress = async() => {
        let data = this.getAddressFromFormData();
        if (this.props.allDeliveryAddresses.find(d => d.id === data.id)) {
            data.version = this.props.allDeliveryAddresses.find(d => d.id === data.id).version;
        } else if (this.props.allPickupAddresses.find(d => d.id === data.id)) {
            data.version = this.props.allPickupAddresses.find(d => d.id === data.id).version;
        }
        const updatedAddress = await updateAddress(this.context, this.props, data);
        if (updatedAddress) {
            this.context.setUnsavedChanges(false);
            await updateValidationMap(this.context);
            this.setState({addressOpen: false});
            this.props.onSave();
        }
    }

    getAddressFromFormData = () => {
        let deliveryTimes = new Map();
        // only calculate delivery times in these cases:
        if ((isInCentralMode(getActiveUnit(this.context)) && this.state.addressData.logisticType === LOGISTIC_TYPE_CENTRAL) ||
            (isInDecentralMode(getActiveUnit(this.context)) && !this.state.addressData.deliveryTimesUnknown)) {
            deliveryTimes = Object.fromEntries(calculateDeliveryTimesMap(this.state.addressData.timeSlotMap));
        }
        return {
            id: this.state.addressData.id,
            businessUnitId: this.context.appData.activeUnitId,
            address: this.state.addressData.address,
            description: this.state.addressData.description,
            shortDescription: this.state.addressData.shortDescription,
            costCenter: this.state.addressData.costCenter,
            deliveryTimes: deliveryTimes,
            addressType: this.props.addressType,
            logisticType: this.state.addressData.logisticType,
            version: 0
        }
    }

    delete = async() => {
        const success = await deleteAddress(this.context, this.props, this.props.address.id);
        if (success) {
            this.setState({showDeleteDialog: false});
            this.props.onSave();
            await updateValidationMap(this.context);
        } else {
            this.setState({showDeleteDialog: false});
        }
    }

    handleDataChanged = (propertyName, value) => {
        let newFormData = this.state.addressData;
        newFormData[propertyName] = value;
        this.setState({addressData: newFormData});
        if (this.state.addressData?.id !== null && this.state.addressData?.id !== undefined) {
            this.context.setUnsavedChanges(true);
        }
        if (propertyName === "logisticType") {
            this.logisticTypeErrorText(value, this.state.addressData?.id);
        }
    }

    logisticTypeErrorText = (logisticType, id)  => {
        let currentSetting = getActiveUnit(this.context)?.deliveryType;
        let error;
        if (this.props.addressType && logisticType) {
            switch (this.props.addressType) {
                case DELIVERY:
                    switch (currentSetting) {
                        case LOGISTIC_TYPE_CENTRAL:
                            if (logisticType === LOGISTIC_TYPE_CENTRAL && this.hasMultipleCentral(id, this.props.addressType)) {
                                error = "multipleCentralDeliveryStations";
                            }
                            break;
                        case LOGISTIC_TYPE_DECENTRAL:
                            if (logisticType === LOGISTIC_TYPE_CENTRAL) {
                                error = "noCentralDeliveryStations";
                            }
                            break;
                        case LOGISTIC_TYPE_SINGLE:
                            if (logisticType === LOGISTIC_TYPE_CENTRAL && this.hasMultipleCentral(id, this.props.addressType)) {
                                error = "multipleCentralDeliveryStations";
                            }
                            if (logisticType === LOGISTIC_TYPE_DECENTRAL) {
                                error = "noDecentralDeliveryStations";
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case PICKUP:
                    switch (currentSetting) {
                        case LOGISTIC_TYPE_CENTRAL:
                            if (logisticType === LOGISTIC_TYPE_CENTRAL && this.hasMultipleCentral(id, this.props.addressType)) {
                                error = "multipleCentralPickupStations";
                            }
                            if (logisticType === LOGISTIC_TYPE_DECENTRAL) {
                                error = "noDecentralPickupStations";
                            }
                            break;
                        case LOGISTIC_TYPE_DECENTRAL:
                            if (logisticType === LOGISTIC_TYPE_CENTRAL) {
                                error = "noCentralPickupStations";
                            }
                            break;
                        case LOGISTIC_TYPE_SINGLE:
                            if (logisticType === LOGISTIC_TYPE_CENTRAL && this.hasMultipleCentral(id, this.props.addressType)) {
                                error = "multipleCentralPickupStations";
                            }
                            if (logisticType === LOGISTIC_TYPE_DECENTRAL) {
                                error = "noDecentralPickupStations";
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case DELIVERY_PICKUP:
                    switch (currentSetting) {
                        case LOGISTIC_TYPE_CENTRAL:
                            if (this.hasMultipleCentral(id, this.props.addressType)) {
                                error = "multipleCentralDeliveryAndPickupStations";
                            }
                            if (logisticType === LOGISTIC_TYPE_DECENTRAL) {
                                error = "noDecentralPickupStations";
                            }
                            break;
                        case LOGISTIC_TYPE_DECENTRAL:
                            if (logisticType === LOGISTIC_TYPE_CENTRAL) {
                                error = "noCentralDeliveryAndPickupStations";
                            }
                            break;
                        case LOGISTIC_TYPE_SINGLE:
                            if (this.hasMultipleCentral(id, this.props.addressType)) {
                                error = "multipleCentralDeliveryAndPickupStations";
                            }
                            if (logisticType === LOGISTIC_TYPE_DECENTRAL) {
                                error = "noDecentralDeliveryAndPickupStations";
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
        this.setState({logisticTypeErrorText: error ? ("deliveryAndPickup.error." + error) : ""});
    }

    hasMultipleCentral = (id, type) => {
        let addresses = [];
        switch (type) {
            case DELIVERY:
                addresses = this.props.allDeliveryAddresses;
                break;
            case PICKUP:
                addresses = this.props.allPickupAddresses;
                break;
            case DELIVERY_PICKUP:
                addresses = this.props.allDeliveryAddresses.concat(this.props.allPickupAddresses);
                break;
            default:
                break;
        }
        // case 1: create new address and central exists
        if (id === null || id === undefined) {
            let foundAddresses = addresses?.filter((a) => a.logisticType === LOGISTIC_TYPE_CENTRAL);
            if (foundAddresses && foundAddresses.length > 0) {
                return true;
            }
            // case 2: update address and other central exists
        } else {
            let foundAddresses = addresses?.filter((a) => a.logisticType === LOGISTIC_TYPE_CENTRAL && a.id !== id);
            if (foundAddresses && foundAddresses.length > 0) {
                return true;
            }
        }
        return false;
    }

    getAddressDialogText = (isTitle) => {
        let base;
        if (this.props.address && this.props.addressType === DELIVERY) {
            base = "logistics.deliveryAddress.dialog.update.";
        } else if (!this.props.address && this.props.addressType === DELIVERY) {
            base = "logistics.deliveryAddress.dialog.create.";
        } else if (this.props.address && this.props.addressType === PICKUP) {
            base = "logistics.pickupAddress.dialog.update.";
        } else if (!this.props.address && this.props.addressType === PICKUP) {
            base = "logistics.pickupAddress.dialog.create.";
        }
        return base + (isTitle ? "title" : "subtitle");
    }

    render() {
        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.addressOpen}
                    titleId={this.getAddressDialogText(true)}
                    subtitleId={this.getAddressDialogText(false)}
                    onCancel={() => this.setState({addressOpen: false, logisticTypeErrorText: ""})}
                    cancelId="commons.cancel.button"
                    onAction={this.createOrUpdateAddress}
                    actionDisabled={!this.mayCreateOrUpdate()}
                    actionId="commons.save.button"
                    content={<LogisticsContent
                        allDeliveryAddresses={this.props.allDeliveryAddresses}
                        allPickupAddresses={this.props.allPickupAddresses}
                        addressType={this.props.addressType}
                        deliveryAndPickupType={getActiveUnit(this.context)?.deliveryType}
                        formData={this.state.addressData}
                        logisticTypeErrorText={this.state.logisticTypeErrorText}
                        onDataChange={this.handleDataChanged}
                        isInEditMode={!!this.props.address}/>}
                />

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDeleteDialog}
                    titleId="deliveryAndPickup.dialog.title"
                    subtitleId={this.props.addressType === DELIVERY ? "deliveryAndPickup.dialog.textDelivery" : "deliveryAndPickup.dialog.textPickup"}
                    actionId="commons.yes.button"
                    cancelId="commons.no.button"
                    onAction={() => this.delete()}
                    onCancel={() => this.setState({showDeleteDialog: false})}/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDeliveryStationTypeChangeWarning}
                    titleId="deliveryAndPickup.deliveryPoint.change.warning.title"
                    subtitleId="deliveryAndPickup.deliveryPoint.change.warning.description"
                    actionId="commons.confirm.button"
                    cancelId="commons.cancel.button"
                    onAction={() => this.editAddress()}
                    onCancel={() => this.setState({showDeliveryStationTypeChangeWarning: false})}/>

                {/* warning when changing central delivery station to decentral one, when currently the central pre-picked logistic type is set */}
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showTimesWarning}
                    titleId="deliveryAndPickup.deliveryPoint.times.warning.title"
                    subtitleId="deliveryAndPickup.deliveryPoint.times.warning.description"
                    actionId="commons.confirm.button"
                    cancelId="commons.cancel.button"
                    onAction={() => this.editAddress()}
                    onCancel={() => this.setState({showTimesWarning: false})}/>

                {this.props.address

                    ? <LogisticsTile
                        content={<div style={{whiteSpace: "break-spaces"}}>
                            {this.props.address.description + "\n"}
                            {this.props.address.shortDescription + "\n"}
                            {this.props.intl.formatMessage({id: "constants.DeliveryPickup." + this.props.address.logisticType})}
                        </div>}
                        disabled={this.props.readOnly}
                        onEdit={() => this.setState({addressOpen: true, addressData: this.getAddressDataFromProps()})}
                        onDelete={() => this.setState({showDeleteDialog: true})}
                        projectTask={this.props.address.addressType === DELIVERY
                            ? PROJECT_TASKS.DELIVERY_ADDRESS
                            : PROJECT_TASKS.PICKUP_ADDRESS}
                        objectId={this.props.address.id}/>

                    : <LogisticsTile
                        addTitle={this.props.intl.formatMessage({id: this.props.addressType === DELIVERY
                                ? "logistics.deliveryAddress.add" : "logistics.pickupAddress.add"})}
                        disabled={this.props.readOnly}
                        onAdd={() => this.setState({addressOpen: true, addressData: this.getEmptyAddressData()})}/>
                }

            </>
        );
    }
}

export default withSnackbar(injectIntl(DeliveryOrPickupAddressTile));
