import {useContext} from "react";
import {useIntl} from "react-intl";
import {
    AGB_ROUTE,
    ARTICLE_CONFIGURATOR_ROUTE,
    ASSORTMENT_EXPORT_ROUTE,
    BILL_OF_QUANTITIES_EXPORT_ROUTE,
    CONTRACT_ROUTE,
    CUSTOMER_ARTICLES_ROUTE,
    getNavBarTitleId,
    IMPRESSUM_ROUTE,
    INVOICE_CONTROLLING_ROUTE,
    LICENSE_ROUTE,
    LOGISTICS_EXPORT_ROUTE,
    LOGISTICS_ROUTE,
    OPERATING_RESOURCES_ROUTE,
    OFFER_RATING_ROUTE,
    OFFER_RESULT_ROUTE,
    OFFER_SUITABILITY_ROUTE,
    OFFSETTING_ROUTE,
    OPERATING_RESOURCE_CONFIGURATOR_ROUTE,
    OPERATING_RESOURCE_OVERVIEW_ROUTE,
    PRICE_SHEET_EXPORT_ROUTE,
    PRIVACY_ROUTE,
    PROCEDURE_CONDITIONS_ROUTE,
    PROFILES_ROUTE,
    PROJECT_OVERVIEW_ROUTE,
    RENTAL_LINEN_ASSIGNMENT_ROUTE,
    RENTAL_LINEN_ROUTE,
    RESIDENTS_LAUNDRY_ROUTE,
    ROOT_ROUTE,
    SPECIAL_SERVICES_ROUTE,
    TENDER_COMMUNICATION_ROUTE,
    TENDER_HISTORY_ROUTE,
    USER_MANAGEMENT_ROUTE,
    BUSINESS_UNIT_OVERVIEW_ROUTE,
    DASHBOARD_ROUTE,
    INVOLVED_BIDDERS_ROUTE,
    PRICE_COMPARISON_ROUTE,
    OFFER_DOCUMENT_ROUTE
} from "../../../util/Constants";
import {white} from "../../../util/ColorTheme";
import { Breadcrumbs } from "@material-ui/core";
import {GeneralContext} from "../../contexts/GeneralContext";
import {getActiveProject, getOfferProject} from "../../../services/ProjectService";
import {getActiveUnit} from "../../../services/BusinessUnitService";


export function TenderBreadCrumbs () {

    const formatMessage = useIntl().formatMessage;
    const context = useContext(GeneralContext);

    const breadcrumb = (title) => {
        if (!title) {
            return null;
        }
        return (
            <div key={title} style={{color: white, fontWeight: "bold"}}>
                {title}
            </div>
        );
    }

    const crumbs = [];

    // 1. getProjectName from context activeProjectId
    const projectName = getActiveProject(context)?.name

    // 2. getUnitName from context
    const unitName = getActiveUnit(context)?.name;

    // 3. get route-specific crumb
    const route = window.location.pathname;

    const exports = formatMessage({id: "navBar.exports.title"});
    const procedure = formatMessage({id: "navBar.procedure.title"});
    const offerRound = (getOfferProject(context)?.offerIndex + 1) + ". "
        + formatMessage({id: "navBar.offerRound.title"});
    const admin = formatMessage({id: "navBar.administration.title"});
    const legal = formatMessage({id: "navBar.legal.title"});

    switch (route) {
        case ROOT_ROUTE:
        case DASHBOARD_ROUTE:
            crumbs.push(breadcrumb(projectName));
            break;
        case PROJECT_OVERVIEW_ROUTE:
            break;
        case BUSINESS_UNIT_OVERVIEW_ROUTE:
            crumbs.push(breadcrumb(projectName));
            break;
        case RENTAL_LINEN_ROUTE:
        case RENTAL_LINEN_ASSIGNMENT_ROUTE:
        case CUSTOMER_ARTICLES_ROUTE:
        case RESIDENTS_LAUNDRY_ROUTE:
        case SPECIAL_SERVICES_ROUTE:
            crumbs.push(breadcrumb(projectName));
            crumbs.push(breadcrumb(unitName));
            break;
        case OPERATING_RESOURCE_OVERVIEW_ROUTE:
        case OPERATING_RESOURCES_ROUTE:
        case OPERATING_RESOURCE_CONFIGURATOR_ROUTE:
            crumbs.push(breadcrumb(projectName));
            crumbs.push(breadcrumb(unitName));
            break;
        case OFFSETTING_ROUTE:
        case LOGISTICS_ROUTE:
            crumbs.push(breadcrumb(projectName));
            crumbs.push(breadcrumb(unitName));
            break;
        case PRICE_SHEET_EXPORT_ROUTE:
        case ASSORTMENT_EXPORT_ROUTE:
        case LOGISTICS_EXPORT_ROUTE:
        case BILL_OF_QUANTITIES_EXPORT_ROUTE:
        case CONTRACT_ROUTE:
            crumbs.push(breadcrumb(projectName));
            crumbs.push(breadcrumb(exports));
            break;
        case PROCEDURE_CONDITIONS_ROUTE:
        case TENDER_COMMUNICATION_ROUTE:
            crumbs.push(breadcrumb(projectName));
            crumbs.push(breadcrumb(procedure));
            break;
        case OFFER_DOCUMENT_ROUTE:
        case INVOLVED_BIDDERS_ROUTE:
        case PRICE_COMPARISON_ROUTE:
        case OFFER_SUITABILITY_ROUTE:
        case OFFER_RATING_ROUTE:
        case OFFER_RESULT_ROUTE:
        case INVOICE_CONTROLLING_ROUTE:
            crumbs.push(breadcrumb(projectName));
            crumbs.push(breadcrumb(procedure));
            crumbs.push(breadcrumb(offerRound));
            break;
        case TENDER_HISTORY_ROUTE:
            crumbs.push(breadcrumb(projectName));
            break;
        case ARTICLE_CONFIGURATOR_ROUTE:
        case USER_MANAGEMENT_ROUTE:
        case PROFILES_ROUTE:
        case LICENSE_ROUTE:
            crumbs.push(breadcrumb(admin));
            break;
        case IMPRESSUM_ROUTE:
        case AGB_ROUTE:
        case PRIVACY_ROUTE:
            crumbs.push(breadcrumb(legal));
            break;
        default:
            break;
    }

    const routeTitleId = getNavBarTitleId(route);
    if (routeTitleId) {
        crumbs.push(breadcrumb(formatMessage({id: routeTitleId})));
    }

    return (
        <Breadcrumbs 
            separator="›" 
            aria-label="breadcrumb" 
            style={{color: white, fontWeight: "bold", fontSize: "medium", whiteSpace: "nowrap"}}>
            {crumbs}
        </Breadcrumbs>
    );
}
