import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getAsync, putAsync } from "../../../services/BackendService";
import {RATING_CATEGORY} from "../../../util/Constants";
import {
    hasSuitableOffers,
    isFirstDeadlineOver,
    isSubmitted
} from "../../../util/ProcedureUtil";
import {downloadDocument} from "../../../util/DocumentUtil";
import {createErrorMessage, createSuccessMessage, isTender} from "../../../util/Util";

import { Header } from "../../uiLibrary/Header";
import { LinearProgressIndicator } from "../../uiLibrary/LinearProgressIndicator";
import { SimpleTextCard } from "../../uiLibrary/SimpleTextCard";
import { OfferRatingTable } from "./OfferRatingTable";
import {GeneralContext} from "../../contexts/GeneralContext";
import {getProcedure} from "../../../services/ProcedureService";
import {exportRatings} from "../../../services/OfferService";
import {getOfferProject, isPublished} from "../../../services/ProjectService";


const OfferRatingOverview = () => {

    const [isDownloading, setIsDownloading] = useState(false);
    const [procedure, setProcedure] = useState(null);
    const [ratings, setRatings] = useState(null);
    const [offers, setOffers] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(true);

    const context = useContext(GeneralContext);
    const intl = useIntl();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const projectId = context.appData.activeProjectId;
    const offerProjectId = context.appData.offerProjectId;
    const logout = context.logout;

    const loadData = useCallback(async() => {
        setIsLoadingData(true);
        setProcedure(await getProcedure(context, {enqueueSnackbar, closeSnackbar, intl}, offerProjectId));
        const ratingsResponse = await getAsync("/offer/ratings/" + offerProjectId);
        if (ratingsResponse?.status === 200) {
            setRatings(ratingsResponse.data);
        } else if ([401, 403].includes(ratingsResponse?.status)) {
            await logout();
        }
        const offersResponse = await getAsync("/offers/" + offerProjectId);
        if (offersResponse?.status === 200) {
            setOffers(offersResponse.data?.filter(o => isSubmitted(o)));
        } else if ([401, 403].includes(offersResponse?.status)) {
            await logout();
        }
        setIsLoadingData(false);
    }, [offerProjectId, logout, context, closeSnackbar, enqueueSnackbar, intl]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const updateRatings = async(ratings) => {
        const response = await putAsync("/offer/ratings/" + context.appData.offerProjectId, ratings);
        if (response?.status === 200) {
            createSuccessMessage(intl.formatMessage({id: "offerRating.save.success"}), {enqueueSnackbar, closeSnackbar, intl});
            await loadData();
        } else if ([401, 403].includes(response?.status)) {
            await context.logout();
        } else {
            createErrorMessage(
                intl.formatMessage({id: "offerRating.save.error"}),
                {enqueueSnackbar, closeSnackbar, intl}
            );
        }
    }

    const downloadRatingDocument = async(document) => {
        setIsDownloading(true);
        await downloadDocument({intl, enqueueSnackbar, closeSnackbar}, context, document.url, document.filename);
        setIsDownloading(false);
    }

    const offerProject = getOfferProject(context);

    if (isTender() && !isPublished(offerProject)) {
        return (
            <>
                <Header titleId="offerRating.title"/>
                <SimpleTextCard show={true} textId="offerRating.projectNotPublished"/>
            </>
        );
    }

    const isClosed = isFirstDeadlineOver(procedure);
    const isPreviousRound = projectId !== offerProjectId;
    const hasOffers = offers && offers.length > 0;
    const hasSuitable = hasSuitableOffers(ratings?.offerEvaluationMap) && hasOffers;
    const onlyPriceRating = procedure?.ratingCriteria
        && Object.values(procedure.ratingCriteria).length === 1
        && Object.values(procedure.ratingCriteria).find(c => c.ratingCategory === RATING_CATEGORY.PRICE);

    let titleId = "offerRating.firstRound.title";
    let subtitleId;

    if (isPreviousRound) {
        if (!hasOffers) {
            subtitleId = "offerRating.secondRound.noOffers.subtitle";
        } else if (!hasSuitable) {
            subtitleId = "offerRating.secondRound.noSuitableOffers.subtitle";
        }
    } else {
        if (!isClosed) {
            subtitleId = "offerRating.firstRound.notClosed.subtitle";
        } else if (!hasOffers) {
            subtitleId = "offerRating.firstRound.noOffers.subtitle";
        } else if (!hasSuitable) {
            subtitleId = "offerRating.firstRound.noSuitableOffers.subtitle";
        }
    }

    if (onlyPriceRating) {
        subtitleId = "offerRating.onlyPriceRating.subtitle";
    }

    return <>
        <LinearProgressIndicator active={isDownloading}/>

        {isClosed && hasSuitable

        ? <OfferRatingTable
            isPreviousRound={isPreviousRound}
            currentRatings={ratings} 
            onSave={(ratings) => updateRatings(ratings)} 
            onDownload={(document) => downloadRatingDocument(document)}
            onExport={async() => await exportRatings(context, {enqueueSnackbar, closeSnackbar, intl}, offerProjectId)}
            offers={offers}
            ratingCriteria={procedure?.ratingCriteria}/>

        : <React.Fragment>
            <Header titleId={titleId}/>
            <SimpleTextCard show={!isLoadingData} textId={subtitleId}/>
        </React.Fragment>}

    </>;
}

export {
    OfferRatingOverview
}
