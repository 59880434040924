import { Card, CardContent, Grid, InputLabel, Select, Typography } from "@material-ui/core";
import { Component } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import { texisionOrange } from "../../../util/ColorTheme";
import { GeneralContext } from "../../contexts/GeneralContext";
import ValidationIcon from "../../validation/ValidationIcon";

class ArticleCategories extends Component {

    static contextType = GeneralContext;

    render() {

        return (
            <Card className="child">

                <CardContent>

                    <Grid container alignItems="flex-start">
                        <Grid item xs>
                            <Typography variant="h6" component="h2">
                                <FormattedMessage id="articleConfigurator.categories.h1"/>*
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ValidationIcon complete={this.props.category && this.props.subcategory && this.props.articleCategory} size={30}/>
                        </Grid>
                    </Grid>

                    <Typography color="textSecondary">
                        <FormattedMessage id="articleConfigurator.categories.h2"/>
                    </Typography>

                    <Grid container spacing={2}>

                        <Grid item xs={4}>

                            <InputLabel 
                                htmlFor="category-native-simple" 
                                style={!this.props.category ? {color: texisionOrange, marginTop: "20px", marginBottom: 5} : {marginTop: "20px", marginBottom: 5}}>
                                <FormattedMessage id="entities.article.category"/> *
                            </InputLabel>

                            <Select
                                native
                                value={this.props.category}
                                label={this.props.category ? this.props.intl.formatMessage({id: "constants.Category." + this.props.category}) : ""}
                                onChange={(e) => this.props.handleCategoryChanged(e.target.value)}
                                style={{width: "80%"}}
                                inputProps={{name: 'category', id: 'category-native-simple'}}>

                                <option aria-label="None" value=""/>

                                {this.context.appData.categories
                                    ?.sort((a,b) =>
                                        this.props.intl.formatMessage({id: "constants.Category." + a})
                                            .localeCompare(this.props.intl.formatMessage({id: "constants.Category." + b})))
                                    ?.map((category) => {
                                    return (
                                        <option 
                                            key={category}
                                            value={category}>
                                            {this.props.intl.formatMessage({id: "constants.Category." + category})}
                                        </option>
                                    );
                                })}

                            </Select>

                        </Grid>

                        <Grid item xs={4}>

                            <InputLabel 
                                htmlFor="subcategory-native-simple" 
                                style={!this.props.subcategory ? {color: texisionOrange, marginTop: "20px", marginBottom: 5} : {marginTop: "20px", marginBottom: 5}}>
                                <FormattedMessage id="entities.article.subCategory"/> *
                            </InputLabel>

                            <Select
                                native
                                value={this.props.subcategory}
                                label={this.props.subcategory ? this.props.intl.formatMessage({id: "constants.SubCategory." + this.props.subcategory}) : ""}
                                onChange={(e) => this.props.handleSubCategoryChanged(e.target.value)}
                                style={{width: "80%"}}
                                inputProps={{name: 'subcategory', id: 'subcategory-native-simple'}}>

                                <option aria-label="None" value=""/>

                                {this.context.appData.subCategories
                                    ?.sort((a,b) =>
                                        this.props.intl.formatMessage({id: "constants.SubCategory." + a})
                                            .localeCompare(this.props.intl.formatMessage({id: "constants.SubCategory." + b})))
                                    ?.map((subcategory) => {
                                    return (
                                        <option 
                                            key={subcategory}
                                            value={subcategory}>
                                            {this.props.intl.formatMessage({id: "constants.SubCategory." + subcategory})}
                                        </option>
                                    );
                                })}

                            </Select>

                        </Grid>

                        <Grid item xs={4}>

                            <InputLabel 
                                htmlFor="articleCategory-native-simple"
                                style={!this.props.articleCategory ? {color: texisionOrange, marginTop: "20px", marginBottom: 5} : {marginTop: "20px", marginBottom: 5}}>
                                <FormattedMessage id="entities.article.articleCategory"/> *
                            </InputLabel>

                            <Select
                                native
                                value={this.props.articleCategory}
                                label={this.props.articleCategory ? this.props.intl.formatMessage({id: "constants.ArticleCategory." + this.props.articleCategory}) : ""}
                                onChange={(e) => this.props.handleArticleCategoryChanged(e.target.value)}
                                style={{width: "80%"}}
                                inputProps={{name: 'articleCategory', id: 'articleCategory-native-simple'}}>

                                <option aria-label="None" value=""/>

                                {this.context.appData.articleCategories
                                    ?.sort((a,b) =>
                                        this.props.intl.formatMessage({id: "constants.ArticleCategory." + a})
                                            .localeCompare(this.props.intl.formatMessage({id: "constants.ArticleCategory." + b})))
                                    ?.map((articleCategory) => {
                                    return (
                                        <option 
                                            key={articleCategory}
                                            value={articleCategory}>
                                            {this.props.intl.formatMessage({id: "constants.ArticleCategory." + articleCategory})}
                                        </option>
                                    );
                                })}

                            </Select>

                        </Grid>

                    </Grid>

                </CardContent>

            </Card>
        );
    }
}

export default injectIntl(ArticleCategories);
