import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {getAsync} from '../../../services/BackendService';
import {Grid, FormControlLabel, Checkbox} from '@material-ui/core';
import {GeneralContext} from "../../contexts/GeneralContext";


class ProfilePanel extends Component {

    static contextType = GeneralContext;

    constructor(props){
        super(props);
        this.state = {profileIds: this.props.profileIds, otherProfiles: [], defaultProfile: null, allProfile: null}
    }

    async componentDidMount() {
        await this.loadProfiles();
    }

    loadProfiles = async() => {
        let response = await getAsync("/profiles");
        if (response?.status === 200 && response?.data) {
            let defaultProfile = response.data.find(p => p.isDefault);
            let allProfile = response.data.find(p => p.isReadOnly);
            let otherProfiles = response.data.filter(p => !p.isDefault && !p.isReadOnly);
            this.setState({otherProfiles: otherProfiles, defaultProfile: defaultProfile, allProfile: allProfile});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    handleSelectionChanged = (e, id) => {
        if (e.target.checked) {
            this.state.profileIds.push(id);
            this.setState({profileIds: this.state.profileIds});
        } else {
            let index = this.state.profileIds.indexOf(id);
            if (index >= 0) {
                this.state.profileIds.splice(index, 1);
                this.setState({profileIds: this.state.profileIds});
            }
        }
        this.props.onChange(this.state.profileIds);
    }

    allProfileIsDefault = () => {
        return this.state.defaultProfile?.isReadOnly;
    }

    render() {
        if (!this.state.allProfile && !this.state.defaultProfile 
         && (!this.state.otherProfiles || this.state.otherProfiles.length === 0)) {
            return <div/>;
        }
        return <Grid container alignItems="center" style={{paddingLeft: "5px"}}>
            {this.state.allProfile && !this.allProfileIsDefault() &&
            <Grid item xs={3} key={this.state.allProfile.id}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.allDisabled || this.state.profileIds.includes(this.state.allProfile.id)}
                            onChange={this.props.allDisabled ? (e) => {} : (e) => this.handleSelectionChanged(e, this.state.allProfile.id)}
                            disabled={this.props.allDisabled}
                            inputProps={{'aria-label': 'primary checkbox'}}
                            color="primary"
                        />
                    }
                    label={this.state.allProfile.name}
                />
            </Grid>}
            {this.state.defaultProfile && 
            <Grid item xs={3} key={this.state.defaultProfile.id}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={(this.allProfileIsDefault() && this.props.allDisabled) 
                                || this.state.profileIds.includes(this.state.defaultProfile.id)}
                            onChange={(this.allProfileIsDefault() && this.props.allDisabled) 
                                ? (e) => {} : (e) => this.handleSelectionChanged(e, this.state.defaultProfile.id)}
                            disabled={this.allProfileIsDefault() && this.props.allDisabled}
                            inputProps={{'aria-label': 'primary checkbox'}}
                            color="primary"
                        />
                    }
                    label={this.state.defaultProfile.name + " (" + this.props.intl.formatMessage({id: "profiles.isDefault.text"}) + ")"}
                />
            </Grid>}
            {this.state.otherProfiles && this.state.otherProfiles.sort((a, b) => a.name.localeCompare(b.name)).map(p => 
            <Grid item xs={3} key={p.id}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.profileIds.includes(p.id)}
                            onChange={(e) => this.handleSelectionChanged(e, p.id)}
                            inputProps={{'aria-label': 'primary checkbox'}}
                            color="primary"
                        />
                    }
                    label={p.name}
                />
            </Grid>)}
        </Grid>;
    }
}

export default injectIntl(ProfilePanel);
