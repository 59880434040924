import { IconButton } from "@mui/material";
import { Component } from "react";
import { texisionFontColorDark, texisionGreen, texisionOrange, texisionRed } from "../../util/ColorTheme";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { injectIntl } from "react-intl";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import { DIALOG_TYPE_INFO } from "../../util/Constants";
import { DialogContentText } from "@material-ui/core";
import { checkPasswordStrength } from "../../util/AuthUtil";

class PasswordStrengthIndicator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPasswordStrengthDialog: false
        }
    }

    getPasswordStrengthComponent = () => {
        let color;
        let strength;
        switch (checkPasswordStrength(this.props.password)) {
            case 1:
                color = texisionRed;
                strength = "weak";
                break;
            case 2:
                color = texisionOrange;
                strength = "medium";
                break;
            case 3:
                color = texisionGreen;
                strength = "strong";
                break;
            case 0:
            default:
                color = texisionFontColorDark;
                strength = "information";
                break;
        }

        if (color && strength) {
            return (
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>

                    <IconButton
                        onClick={() => this.setState({showPasswordStrengthDialog: true})}>
                        <HelpOutlineIcon/>
                    </IconButton>

                    {this.passwordStrengthText("registration.password.strength." + strength, color)}

                </div>
            );
        } else {
            return null;
        }
    }

    passwordStrengthText = (textId, color) => {
        return (
            <div style={{color}}>
                {this.props.intl.formatMessage({id: textId})}
            </div>
        );
    }

    render() {
        return (
            <>
            
                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showPasswordStrengthDialog}
                    titleId="registration.passwordStrength.dialog.title"
                    subtitleId="registration.passwordStrength.dialog.subtitle"
                    content={<DialogContentText>
                        
                        <div>{'• ' + this.props.intl.formatMessage({id: "registration.passwordStrength.tip.1"})}</div>

                        <div>{'• ' + this.props.intl.formatMessage({id: "registration.passwordStrength.tip.2"})}</div>

                        <div>{'• ' + this.props.intl.formatMessage({id: "registration.passwordStrength.tip.3"})}</div>

                    </DialogContentText>}
                    actionId="commons.close.label"
                    onAction={() => this.setState({showPasswordStrengthDialog: false})}/>

                {this.getPasswordStrengthComponent()}
            
            </>
        );
    }
}

export default injectIntl(PasswordStrengthIndicator);
