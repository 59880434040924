import { TableCell, TableRow } from "@material-ui/core";
import { useIntl } from "react-intl";
import { PriceType, RESOURCE_OFFSETTINGS } from "../../../util/Constants";
import { offerDisabledTooltip } from "../../../util/ProcedureUtil";
import {enhanceAssignedResourcesWithMasterData, formatPrice, isTender} from "../../../util/Util";
import { renderFeaturesWithoutType } from "../../../util/TableUtil";
import { PriceSheetTable } from "./PriceSheetTable";
import ArticleButtonCell from "../cells/ArticleButtonCell";
import ArticleNumberCell from "../cells/ArticleNumberCell";
import ArticlePriceCell from "../cells/ArticlePriceCell";
import { useEffect, useState } from "react";
import AlternateDescriptionCell from "../cells/AlternateDescriptionCell";

const PriceSheetResourcesTable = ({
    readOnly,
    singlePriceOnly,
    allArticleNumbers,
    editable,
    exportType,
    lockArticleNumbers,
    lockPrices,
    operatingResources, 
    priceGroup,
    project,
    resetAllArticleNumbers, 
    show,
    unitResources, 
    updatePriceSheet,
    // following prop is only used for bidders
    offer,
    isPrevious
}) => {

    const [resourceMap, setResourceMap] = useState(priceGroup?.priceItemMap ? JSON.parse(JSON.stringify(priceGroup.priceItemMap)) : null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (priceGroup?.priceItemMap) {
            setResourceMap(JSON.parse(JSON.stringify(priceGroup.priceItemMap)));
        }
    }, [priceGroup])

    const intl = useIntl();

    if (!show) {
        return (<div/>);
    }

    const handleItemChange = (newResource) => {
        let map = resourceMap;
        map[newResource.objectId] = newResource;
        setResourceMap({...map});
    }

    const resetItemMap = () => {
        setResourceMap(JSON.parse(JSON.stringify(priceGroup.priceItemMap)));
        resetAllArticleNumbers();
    }

    let headerTitles = [
        "operatingResources.table.subCategory",
        "operatingResources.table.name",
        "operatingResources.table.description",
        "operatingResources.table.features",
        "operatingResources.table.offsetting"
    ];

    if (!singlePriceOnly) {
        headerTitles.push("operatingResources.table.amount");
    }

    return (
        <PriceSheetTable
            title={intl.formatMessage({id: "bidder.priceSheet.table.operatingResources.title"})}
            type="operatingResources"
            headerTitles={headerTitles}
            editButtonLabel={lockArticleNumbers && lockPrices ? "bidder.priceSheet.editAlternateLabels.button" : null}
            rows={enhanceAssignedResourcesWithMasterData(operatingResources, unitResources)
                .sort((a,b) => a.name.localeCompare(b.name))
                .map(resource => {

                const priceItem = resourceMap[resource.id];

                const disabled = !isEditing || (resource.resourceOffsetting === RESOURCE_OFFSETTINGS.WITHOUT_CHARGE
                    || (!editable && isTender() && offerDisabledTooltip(project, offer, isPrevious, intl.formatMessage)));

                return (
                    <TableRow key={resource.id}>

                        <TableCell>{intl.formatMessage({id: "constants.ResourceSubCategory." + resource.subCategory})}</TableCell>

                        <TableCell>{resource.name}</TableCell>

                        <TableCell>{resource.description}</TableCell>

                        <TableCell>{renderFeaturesWithoutType(resource.features)}</TableCell>

                        <TableCell>{intl.formatMessage({id: "constants.ResourceOffsetting." + resource.resourceOffsetting})}</TableCell>

                        {!singlePriceOnly && <TableCell>{resource.count}</TableCell>}

                        <TableCell>
                            <ArticlePriceCell 
                                disabled={disabled || lockPrices}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                        {!singlePriceOnly && <TableCell>{isEditing ? "" : formatPrice(priceItem?.sumNet)}</TableCell>}

                        {!singlePriceOnly && <TableCell>{isEditing ? "" : formatPrice(priceItem?.sumGross)}</TableCell>}

                        <TableCell>
                            <AlternateDescriptionCell
                                disabled={disabled}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={(newResource) => handleItemChange(newResource)}/>
                        </TableCell>

                        <TableCell>
                            <ArticleNumberCell
                                allArticleNumbers={allArticleNumbers}  
                                disabled={disabled || lockArticleNumbers}
                                exportType={exportType} 
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                        <TableCell>
                            <ArticleButtonCell 
                                allArticleNumbers={allArticleNumbers} 
                                disabled={disabled || lockArticleNumbers}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                    </TableRow>
                );
            })}
            isEditing={isEditing}
            readOnly={readOnly}
            singlePriceOnly={singlePriceOnly}
            offer={offer}
            project={project}
            priceGroup={priceGroup}
            onSave={() => updatePriceSheet(PriceType.OPERATING_RESOURCE, resourceMap)}
            resetItemMap={() => resetItemMap()}
            setIsEditing={(value) => setIsEditing(value)}
            editable={editable && priceGroup.priceItemMap && Object.keys(priceGroup.priceItemMap).length !== 0}
        />
    );
}

export {
    PriceSheetResourcesTable
}
