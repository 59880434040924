import { Collapse, List } from "@material-ui/core";
import { Component } from "react";
import {
    CUSTOMER_ARTICLES_ROUTE,
    LOGISTICS_ROUTE,
    OPERATING_RESOURCES_ROUTE,
    OFFSETTING_ROUTE,
    PROJECT_TASKS,
    RENTAL_LINEN_ROUTE,
    RESIDENTS_LAUNDRY_ROUTE,
    SPECIAL_SERVICES_ROUTE
} from "../../../util/Constants";
import { GeneralContext } from "../../contexts/GeneralContext";
import NavBarItem from "./NavBarItem";

class NavBarUnit extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {unitOpen: false};
    }

    render() {

        return (
            <>
                <NavBarItem
                    level={1}
                    icon="icon-building"
                    open={this.state.unitOpen}
                    projectId={this.props.projectId}
                    onClick={() => this.setState({unitOpen: !this.state.unitOpen})}
                    directTitle={this.props.unit.name}
                    unitId={this.props.unit.id}
                    projectTask={PROJECT_TASKS.BUSINESS_UNIT}
                    objectId={this.props.unit.id}
                />

                <Collapse in={this.state.unitOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        <NavBarItem
                            level={2}
                            icon="icon-clothings"
                            pathname={RENTAL_LINEN_ROUTE}
                            projectId={this.props.projectId}
                            unitId={this.props.unit.id}
                            projectTask={PROJECT_TASKS.RENTAL_LINEN}
                            objectId={this.props.unit.id}
                            singleValidation={true}/>

                        <NavBarItem
                            level={2}
                            icon="icon-clothings"
                            pathname={CUSTOMER_ARTICLES_ROUTE}
                            projectId={this.props.projectId}
                            unitId={this.props.unit.id}
                            projectTask={PROJECT_TASKS.CUSTOMER_ARTICLES}
                            objectId={this.props.unit.id}
                            singleValidation={true}/>

                        <NavBarItem
                            level={2}
                            icon="icon-clothings"
                            pathname={RESIDENTS_LAUNDRY_ROUTE}
                            projectId={this.props.projectId}
                            unitId={this.props.unit.id}
                            projectTask={PROJECT_TASKS.RESIDENTS_LAUNDRY}
                            objectId={this.props.unit.id}
                            singleValidation={true}/>

                        <NavBarItem
                            level={2}
                            pathname={OPERATING_RESOURCES_ROUTE}
                            projectId={this.props.projectId}
                            unitId={this.props.unit.id}
                            projectTask={PROJECT_TASKS.OPERATING_RESOURCES}
                            objectId={this.props.unit.id}
                            singleValidation={true}/>

                        <NavBarItem
                            level={2}
                            icon="icon-note-hook"
                            pathname={SPECIAL_SERVICES_ROUTE}
                            projectId={this.props.projectId}
                            unitId={this.props.unit.id}
                            projectTask={PROJECT_TASKS.SPECIAL_SERVICES}
                            objectId={this.props.unit.id}
                            singleValidation={true}/>

                        <NavBarItem
                            level={2}
                            icon="icon-note-pencil"
                            pathname={OFFSETTING_ROUTE}
                            projectId={this.props.projectId}
                            unitId={this.props.unit.id}
                            projectTask={PROJECT_TASKS.OFFSETTING}
                            objectId={this.props.unit.id}
                            singleValidation={true}/>

                        <NavBarItem
                            level={2}
                            icon="icon-note-pencil"
                            pathname={LOGISTICS_ROUTE}
                            projectId={this.props.projectId}
                            unitId={this.props.unit.id}
                            projectTask={PROJECT_TASKS.LOGISTIC}
                            objectId={this.props.unit.id}
                            singleValidation={true}/>

                    </List>
                </Collapse>
            </>
        );
    }
}

export default NavBarUnit;
