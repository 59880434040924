import React, {Component} from 'react';
import {CartesianGrid, Label, Legend, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatPrice} from "../../../../util/Util";
import {white} from "../../../../util/ColorTheme";
import {getCockpitStatisticsCategoryLabel} from "../../../../util/Constants";
import {injectIntl} from "react-intl";
import {de} from "date-fns/locale";
import {CategoryStatisticsLineData} from "./CategoryStatisticsLineData";
import {accumulateData} from "../../../../services/StatisticsService";

class CategoryStatisticsLineChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeCategory: null,
            activeTime: null
        }
    }

    yAxisLabel = () => {
        return this.props.intl.formatMessage(
            {id: "cockpit.statistics.categories.yLabel." + this.props.selectedTab + (this.props.accumulated ? ".accumulated" : "")},
            {currency: this.props.currency}
        );
    }

    getXAxisTickLabel = (time) => {
        return new Date(parseInt(time)).toLocaleString(de, {month: "long"}) + " " + new Date(parseInt(time)).getFullYear();
    }

    extrapolationSuffix = (activeTime) => {
        if (activeTime > this.props.maxDate) {
            return " (" + this.props.intl.formatMessage({id: "cockpit.statistics.prediction"}) + ")";
        } else {
            return "";
        }
    }

    render() {
        const {
            accumulated, selectedTab, currency, priceOfferMap, allSelected, workWearSelected, flatLinenSelected,
            customerArticlesSelected, residentsLaundrySelected, operatingResourcesSelected, showNet, minDate
        } = this.props;
        const {
            activeCategory, activeTime
        } = this.state;

        let chartData;

        const [data, lines] = CategoryStatisticsLineData({
            intl: this.props.intl,
            priceOfferMap,
            allSelected,
            workWearSelected,
            flatLinenSelected,
            customerArticlesSelected,
            residentsLaundrySelected,
            operatingResourcesSelected,
            selectedTab,
            showNet,
            activeCategory,
            activeTime,
            minDate,
            onHideTooltip: () => this.setState({activeCategory: null, activeTime: null}),
            onShowTooltip: (activeCategory, payload) => this.setState({activeCategory, activeTime: payload.time})
        });

        if (accumulated && !!data?.length) {
            chartData = accumulateData(data, Array.from(Object.keys(data[0])), "time");
        } else {
            chartData = data;
        }

        return (
            <ResponsiveContainer debounce={300} width="100%" aspect={2.6} minWidth={900}>
                <LineChart
                    data={chartData}
                    margin={{top: 30, right: 50, bottom: 90}}>

                    <YAxis
                        allowDecimals={selectedTab === "price"}
                        domain={selectedTab === "price"
                            ? [
                                dataMin => Math.max(0, Math.ceil((dataMin - 5) / 5) * 5),
                                dataMax => Math.ceil((dataMax + 5) / 5) * 5
                            ]
                            : ["auto", "auto"]}
                        tickFormatter={(value) => selectedTab === "price" ? formatPrice(value) : value.toFixed(0)}
                        yAxisId={selectedTab}
                        padding={{bottom: 20, top: 20}}
                        width={160}>
                        <Label
                            value={this.yAxisLabel()}
                            angle={-90}/>
                    </YAxis>

                    <ReferenceLine
                        x={Math.max.apply(null, chartData?.filter(d => !d.extrapolated).map(d => d.time))}
                        xAxisId="time"
                        yAxisId={selectedTab}
                        stroke="gray"
                        strokeWidth={1.5}
                        strokeDasharray="3 3"
                        label={<Label
                            value={this.props.intl.formatMessage({id: "cockpit.statistics.table.prediction"})}
                            position={"top"}
                            offset={10}/>
                        }/>

                    <XAxis
                        type="category"
                        xAxisId="time"
                        dataKey="time"
                        dy={10}
                        padding={{left: 20, right: 20}}
                        tickFormatter={(time) => this.getXAxisTickLabel(time)}>
                        <Label
                            value={this.props.intl.formatMessage({id: "cockpit.statistics.month"})}
                            offset={30}
                            position='bottom'/>
                    </XAxis>

                    <Tooltip
                        content={({active, payload, label}) => active && activeCategory && activeTime

                            ? <div style={{backgroundColor: white, border: "1px solid grey", padding: 15}}>
                                <div>{this.getXAxisTickLabel(activeTime)}</div>
                                <div>{getCockpitStatisticsCategoryLabel(this.props.intl, activeCategory)}</div>
                                <div>{(selectedTab === "price"
                                    ? formatPrice(payload?.find(p => p.dataKey === activeCategory)?.value) + " " + currency
                                    : payload?.find(p => p.dataKey === activeCategory)?.value) + this.extrapolationSuffix(activeTime)}
                                </div>
                            </div>

                            : <div/>}/>

                    <CartesianGrid stroke="#f5f5f5"/>

                    <Legend
                        align="center"
                        payloadUniqBy={(payload) => payload.dataKey?.includes("pred") ? null : payload.dataKey}
                        wrapperStyle={{position: "relative", paddingTop: 20}}/>

                    {lines}

                </LineChart>
            </ResponsiveContainer>
        );
    }
}

export default injectIntl(CategoryStatisticsLineChart);
