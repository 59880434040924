import React, {Component} from 'react';
import {Collapse, Grid, Icon, MenuItem, Typography} from "@material-ui/core";
import {formatPriceDifference} from "../../../util/Util";
import {bodyBackgroundColor, texisionGreen, texisionRed} from "../../../util/ColorTheme";
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";

class AnnualPrices extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            open: true
        }
    }

    getAnnualGrossPrice = (offerId) => {
        return this.getAnnualPrice(offerId, true);
    }
    
    getAnnualNetPrice = (offerId) => {
        return this.getAnnualPrice(offerId, false);
    }

    getAnnualPrice = (offerId, isGross) => {
        const currentPriceOffer = this.props.priceOffers?.find(p => p.offerId === offerId);
        const previousPriceOffer = this.props.previousPriceOfferMap?.get(offerId);
        const currentAnnualPrice = isGross
            ? currentPriceOffer?.sumGross : currentPriceOffer?.sumNet;
        const previousAnnualPrice = isGross
            ? previousPriceOffer?.sumGross : previousPriceOffer?.sumNet;
        return formatPriceDifference(this.props.round, previousAnnualPrice, currentAnnualPrice);
    }

    getTaxes = (offerId) => {
        const currentOffer = this.props.offers?.find(p => p.id === offerId);
        const previousOfferId = this.props.previousPriceOfferMap?.get(offerId)?.offerId;
        const previousOffer = this.props.previousOffers?.find(o => o.id === previousOfferId);
        const currentTaxes = currentOffer?.valueAddedTax;
        const previousTaxes = previousOffer?.valueAddedTax;
        if (this.props.round === 1 || !previousTaxes || previousTaxes === currentTaxes) {
            return currentTaxes + " %";
        } else {
            const difference = currentTaxes - previousTaxes;
            const color = difference < 0 ? texisionGreen : texisionRed;
            const prefix = difference > 0 ? "+" : "";
            return (
                <>
                    {currentTaxes + " %"}
                    <div style={{marginLeft: 10, color: color}}>
                        {"(" + prefix + difference + ")"}
                    </div>
                </>
            );
        }
    }

    tableTitleByText = (text) => {
        return (
            <MenuItem
                onClick={() => this.setState({open: !this.state.open})}
                style={{marginTop: 40, padding: 0, minWidth: 300 + this.props.offers.length*150}}
            >
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid
                        item
                        xs
                        className="tableTitle"
                        style={{backgroundColor: bodyBackgroundColor}}
                    >
                        <Typography variant="h3">
                            {text}
                        </Typography>
                    </Grid>
                    <Grid item className="caretIcon" style={{backgroundColor: bodyBackgroundColor}}>
                        <Icon
                            className={this.state.open
                                ? "icon-caret-up-large"
                                : "icon-caret-down-large"}
                            style={{fontSize: "10pt", marginRight: 10}}
                        />
                    </Grid>
                </Grid>
            </MenuItem>
        );
    }
    
    render() {
        const currency = this.props.project.currency;
        return (
            <>
                <Grid item xs={12} style={{minWidth: 380+this.props.offers.length*200}}>
                    {this.tableTitleByText(
                        this.props.intl.formatMessage(
                            {id: "offers.comparison.fullPrice.title"})
                        + (currency ? (" (" + currency + ")") : ""))}
                </Grid>

                <Grid item xs={12}>

                    <Collapse in={this.state.open}>

                        <Grid item xs={12}>
                            <Grid container wrap="nowrap" alignItems="center">
                                {this.props.firstColumnCell(
                                    this.props.title("offers.comparison.fullPrice.netSum")
                                )}
                                {this.props.priceOffers
                                    .map(p => this.props.cell(
                                        p.offerId+"full-net-sum",
                                        this.getAnnualNetPrice(p.offerId),
                                        true
                                    ))
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container wrap="nowrap" alignItems="center">
                                {this.props.firstColumnCell(
                                    this.props.title("offers.comparison.fullPrice.vat")
                                )}
                                {this.props.offers
                                    .map(o => this.props.cell(
                                        o.id+"vat-cell",
                                        this.getTaxes(o.id),
                                        true
                                    ))
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container wrap="nowrap" alignItems="center">
                                {this.props.firstColumnCell(
                                    this.props.title("offers.comparison.fullPrice.netGross")
                                )}
                                {this.props.priceOffers
                                    .map(p => this.props.cell(
                                        p.offerId+"full-gross-sum",
                                        this.getAnnualGrossPrice(p.offerId),
                                        true
                                    ))
                                }
                            </Grid>
                        </Grid>

                    </Collapse>

                </Grid>  
            </>
        );
    }
}

AnnualPrices.propTypes = {
    offers: PropTypes.array,
    priceOffers: PropTypes.array,
    previousOffers: PropTypes.array,
    previousPriceOfferMap: PropTypes.object,
    project: PropTypes.object,
    round: PropTypes.number
}

export default injectIntl(AnnualPrices);
