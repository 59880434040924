import {Card, Grid} from "@material-ui/core";
import React, {Component} from "react";
import {injectIntl} from "react-intl";
import "../../../css/OfferComparison.css";
import { bodyBackgroundColor, white } from "../../../util/ColorTheme";
import { Header } from "../../uiLibrary/Header";
import {GeneralContext} from "../../contexts/GeneralContext";
import {loadSubmittedOffers} from "../../../services/OfferService";
import {
    getPriceOfferByOfferId,
    getPriceOfferListByOfferIds
} from "../../../services/PriceOfferService";
import {getProcedure} from "../../../services/ProcedureService";
import {isFirstDeadlineOver} from "../../../util/ProcedureUtil";
import PriceInformationHeader from "./PriceInformationHeader";
import AnnualPrices from "./AnnualPrices";
import CategoryPrices from "./CategoryPrices";
import BusinessUnitPrices from "./BusinessUnitPrices";
import {getOfferProject, getPreviousProject} from "../../../services/ProjectService";

class PriceComparison extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showBrutto: true,
            isLoadingData: true,
            offers: [],
            contentVos: [],
            priceOffers: [],
            priceOfferContainer: null,
            previousOffers: [],
            previousPriceOfferMap: null,
            procedure: null
        }
    }
    
    async componentDidMount() {
        let priceOffers = [];
        let contentVos = [];
        let offers = await loadSubmittedOffers(this.context, this.props);
        let priceOfferContainer;
        let previousOffers = [];
        let previousPriceOfferMap;
        const procedure = await getProcedure(this.context, this.props, this.context.appData.offerProjectId);
        if (offers) {
            priceOfferContainer = await getPriceOfferListByOfferIds(this.context, this.props, 
                offers.map(o => o.id));
            priceOffers = priceOfferContainer?.priceOfferVos;
            contentVos = priceOfferContainer?.contentVos;
            const previousPriceOfferContainer 
                = await this.loadPreviousPriceOffers(offers);
            previousOffers = previousPriceOfferContainer[0];
            previousPriceOfferMap = previousPriceOfferContainer[1];
        }

        this.setState({
            isLoadingData: false,
            priceOffers,
            contentVos,
            offers,
            priceOfferContainer,
            previousOffers, 
            previousPriceOfferMap,
            procedure
        });
    }

    loadPreviousPriceOffers = async(offers) => {
        let previousProject = getPreviousProject(this.context);
        if (previousProject !== null) {
            const previousPriceOfferMap = new Map();
            const previousOffers = await loadSubmittedOffers(this.context, this.props);
            if (previousOffers && previousOffers.length > 0) {
                for (let previousOffer of previousOffers) {
                    const previousPriceOfferContainer = await getPriceOfferByOfferId(
                        this.context, this.props, previousOffer.id);
                    if (previousPriceOfferContainer?.priceOfferVos 
                        && previousPriceOfferContainer.priceOfferVos.length > 0) 
                    {
                        const correspondingRecallOfferId = offers
                            .find(o => o.user.id === previousOffer.user.id)?.id;
                        previousPriceOfferMap.set(correspondingRecallOfferId, 
                            previousPriceOfferContainer.priceOfferVos[0]);
                    }
                }
            }
            return [previousOffers, previousPriceOfferMap];
        }
        return [null, null];
    }

    cell = (key, widget, isInnerCell, height) => {
        return (
            <Grid 
                key={key} 
                item xs 
                className="cell" 
                style={{
                    height: height ?? 50, 
                    backgroundColor: isInnerCell ? white : bodyBackgroundColor
                }}
            >
                {widget}
            </Grid>
        );
    }

    firstColumnCell = (widget, isNotBold, height) => {
        return (
            <Grid 
                item xs={3} 
                key={widget} 
                className="firstColumnCell" 
                style={{
                    height: height ?? 50, 
                    backgroundColor: bodyBackgroundColor, 
                    fontWeight: isNotBold ? "normal" : "bolder"
                }}
            >
                {widget}
            </Grid>
        );
    }

    title = (messageId) => {
        return this.titleByText(this.props.intl.formatMessage({id: messageId}));
    }

    titleByText = (text) => {
        return (
            <Grid item xs={12} style={{fontWeight: "bolder"}}>
                {text}
            </Grid>
        );
    }

    render() {
        let subtitleId;
        let renderComparison = false;
        let round = getPreviousProject(this.context) === null ? 1 : 2;
        const procedure = this.state.procedure;
        
        if (this.state.isLoadingData) {
            subtitleId = null;
        } else if (!procedure) {
            subtitleId = "priceComparison.noProcedure.subtitle";
        } else if (!isFirstDeadlineOver(procedure)) {
            subtitleId = "priceComparison.firstRound.deadLineNotOver.subtitle";
        } else if (!this.state.offers?.length || !this.state.priceOffers?.length) {
            subtitleId = "priceComparison.noOffers.subtitle";
        } else {
            renderComparison = true;
            subtitleId = "offerResult.priceComparison.subtitle";
        }
        
        return (
            <>

                <Header 
                    titleId={"priceComparison.firstRound.title"}
                    subtitleId={subtitleId}
                />
    
                {renderComparison && (
                    <div className="comparison">

                        <Card className="previewRow">

                            <PriceInformationHeader
                                offers={this.state.offers}
                                priceOffers={this.state.priceOffers}
                                onShowBruttoChange={(showBrutto) => this.setState({showBrutto})}
                                showBrutto={this.state.showBrutto}
                            />

                        </Card>

                        <Card>

                            <Grid container style={{overflowX: "auto"}}>

                                <AnnualPrices
                                    cell={this.cell}
                                    firstColumnCell={this.firstColumnCell}
                                    offers={this.state.offers}
                                    previousOffers={this.state.previousOffers}
                                    previousPriceOfferMap={this.state.previousPriceOfferMap}
                                    priceOffers={this.state.priceOffers}
                                    project={getOfferProject(this.context)}
                                    round={round}
                                    title={this.title}
                                />

                                <BusinessUnitPrices
                                    cell={this.cell}
                                    firstColumnCell={this.firstColumnCell}
                                    offers={this.state.offers}
                                    previousPriceOfferMap={this.state.previousPriceOfferMap}
                                    priceOffers={this.state.priceOffers}
                                    project={getOfferProject(this.context)}
                                    round={round}
                                    showBrutto={this.state.showBrutto}
                                    titleByText={this.titleByText}
                                />

                                <CategoryPrices
                                    cell={this.cell}
                                    contentVos={this.state.contentVos}
                                    firstColumnCell={this.firstColumnCell}
                                    offers={this.state.offers}
                                    previousPriceOfferMap={this.state.previousPriceOfferMap}
                                    priceOffers={this.state.priceOffers}
                                    project={getOfferProject(this.context)}
                                    round={round}
                                    showBrutto={this.state.showBrutto}
                                    titleByText={this.titleByText}
                                    title={this.title}
                                />

                            </Grid>
                        </Card>
                    </div>
                )}

            </>
        );
    }
}

export default injectIntl(PriceComparison);
