import React from 'react';
import {
    ASSORTMENT_EXPORT_ROUTE,
    BILL_OF_QUANTITIES_EXPORT_ROUTE, BUSINESS_UNIT_OVERVIEW_ROUTE,
    CONTRACT_ROUTE,
    LOGISTICS_EXPORT_ROUTE,
    PRICE_SHEET_EXPORT_ROUTE,
    PROCEDURE_CONDITIONS_ROUTE,
    PROJECT_STATUS_IS_PUBLISHED,
    PROJECT_TASKS,
    TENDER_COMMUNICATION_ROUTE,
    TENDER_HISTORY_ROUTE,
} from "../../../util/Constants";
import { Collapse, List } from '@material-ui/core';
import {GeneralContext} from "../../contexts/GeneralContext";
import NavBarUnit from '../shared/NavBarUnit';
import NavBarItem from '../shared/NavBarItem';
import {getActiveProject} from "../../../services/ProjectService";
import {getUnitsForActiveProjectFromContext} from "../../../services/BusinessUnitService";
import {getActiveOperation} from "../../../services/OperationService";
import NavBarOfferRound from "../shared/NavBarOfferRound";

class Tender extends React.Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            exportsOpen: false,
            procedureOpen: false
        }
    }

    handleExportsOpen = () => {
        this.setState({exportsOpen: !this.state.exportsOpen});
    }

    handleProcedureOpen = () => {
        if (this.state.procedureOpen) {
            this.setState({procedureOpen: false, firstOfferRoundOpen: false, finalOfferRoundOpen: false});
        } else {
            this.setState({procedureOpen: true, firstOfferRoundOpen: false, finalOfferRoundOpen: false});
        }
    }

    render() {

        const projects = getActiveOperation(this.context)?.projects ?? [];
        const activeProject = getActiveProject(this.context);

        const hasUnits = getUnitsForActiveProjectFromContext(this.context).length > 0;
        let isNotPublished = activeProject?.status !== PROJECT_STATUS_IS_PUBLISHED;

        return (
            <>
                <NavBarItem
                    level={1}
                    pathname={BUSINESS_UNIT_OVERVIEW_ROUTE}
                    icon="icon-cog"/>

                {activeProject?.businessUnits?.units
                ?.sort((a,b) => a.name.localeCompare(b.name)).map(u => <NavBarUnit 
                    key={u.id} 
                    unit={u}
                    projectId={activeProject?.id}/>)}

                <NavBarItem
                    level={1}
                    open={this.state.exportsOpen}
                    onClick={() => this.handleExportsOpen()}
                    title="navBar.exports.title"
                    icon="icon-note-pencil"
                    projectTask={PROJECT_TASKS.CONTRACT}
                    objectId={activeProject?.id}
                />

                <Collapse in={this.state.exportsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        <NavBarItem
                            level={2}
                            pathname={PRICE_SHEET_EXPORT_ROUTE}
                            disabled={!hasUnits}
                            disabledTitle="navBar.export.disabled.tooltip"/>

                        <NavBarItem
                            level={2}
                            pathname={ASSORTMENT_EXPORT_ROUTE}
                            disabled={!hasUnits}
                            disabledTitle="navBar.export.disabled.tooltip"/>

                        <NavBarItem
                            level={2}
                            pathname={LOGISTICS_EXPORT_ROUTE}
                            disabled={!hasUnits}
                            disabledTitle="navBar.export.disabled.tooltip"/>

                        <NavBarItem
                            level={2}
                            pathname={BILL_OF_QUANTITIES_EXPORT_ROUTE}
                            disabled={!hasUnits}
                            disabledTitle="navBar.export.disabled.tooltip"/>

                        <NavBarItem
                            level={2}
                            pathname={CONTRACT_ROUTE}
                            projectTask={PROJECT_TASKS.CONTRACT}
                            objectId={activeProject?.id}
                            singleValidation={true}
                        />
                            
                    </List>
                </Collapse>

                <NavBarItem
                    level={1}
                    open={this.state.procedureOpen}
                    onClick={() => this.handleProcedureOpen()}
                    projectTask={PROJECT_TASKS.PROCEDURE}
                    objectId={activeProject?.id}
                    title="navBar.procedure.title"
                    icon="icon-note-hook"/>

                <Collapse in={this.state.procedureOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        <NavBarItem
                            level={2}
                            pathname={PROCEDURE_CONDITIONS_ROUTE}
                            projectTask={PROJECT_TASKS.PROCEDURE}
                            objectId={activeProject?.id}
                            singleValidation={true}/>

                        <NavBarItem
                            level={2}
                            pathname={TENDER_COMMUNICATION_ROUTE}
                            disabled={isNotPublished}
                            disabledTitle={"navBar.communication.disabled.tooltip." + activeProject?.status}/>

                        {projects.sort((a,b) => a.createdAt - b.createdAt).map(function (p, index) {
                            p.offerIndex = index;
                            return <NavBarOfferRound
                                key={p.id}
                                projectId={activeProject?.id}
                                offerProject={p}/>})
                        }
                    </List>
                </Collapse>

                <NavBarItem
                    level={1}
                    pathname={TENDER_HISTORY_ROUTE}
                    icon="icon-documents"/>

            </>
        )
    }
}

export default Tender;
