import React, {Component} from "react";
import { GeneralContext } from "../../contexts/GeneralContext";
import { Grid } from "@mui/material";
import '../../../css/Support.css';
import { FormattedMessage, injectIntl } from "react-intl";
import { isAdmin } from "../../../services/UserService";
import { texisionBlue, texisionFontColorDark, texisionGray, white } from "../../../util/ColorTheme";
import { DividerWithText } from "../../uiLibrary/DividerWithText";
import { APP_TYPE_KEY, OFFER_APP } from "../../../util/Constants";
import defaultUserIcon from '../../../resources/default_user_icon.png';
import {isTender} from "../../../util/Util";

class SupportChat extends Component {

    static contextType = GeneralContext;

    showCreated = (date) => {
        if (!date) {
            return null;
        }
        return (
            <div style={{fontSize: "x-small"}}>
                {new Date(date).toLocaleString()}
            </div>
        );
    }

    getProjectName = (operationId) => {
        let text;
        if (operationId === null || operationId === undefined) {
            if (isTender()) {
                text = <FormattedMessage id="support.divider.noTender"/>;
            } else {
                text = <FormattedMessage id="support.divider.noContract"/>;
            }
        } else {
            const projectName = this.context.appData.operations?.find(o => o.id === operationId)?.activeProject?.name;
            if (projectName) {
                if (isTender()) {
                    text = <FormattedMessage id="support.divider.tender" values={{projectName}}/>;
                } else {
                    text = <FormattedMessage id="support.divider.contract" values={{projectName}}/>;
                }
            }
        }

        if (text) {
            return <DividerWithText text={text}/>
        } else {
            return null;
        }
    }

    messageComponent = (message) => {

        const myUserId = this.context.currentUser.id;
        const iAmAuthor = message.authorId === myUserId;

        const userImageUrl = this.props.userIdToImageUrlMap.get(message.authorId) ?? defaultUserIcon;

        return (
            <>

                {this.userImage(userImageUrl, iAmAuthor, !iAmAuthor)}

                {this.messageText(message.message, iAmAuthor)}

                {this.userImage(userImageUrl, iAmAuthor, iAmAuthor)}

                <Grid
                    item xs={12} 
                    style={{
                        display: "flex", 
                        justifyContent: iAmAuthor ? "flex-end" : "flex-start",
                        marginBottom: 20,
                        marginTop: 5
                    }}>

                    {this.showCreated(message.created)}

                </Grid>
            </>
        );
    }

    userImage = (url, isAuthor, display) => {
        if (!display) {
            return null;
        }
        return (
            <Grid item>
                <img 
                    alt={isAuthor ? "I" : "S"} 
                    src={url} 
                    style={{
                        width: '32px', 
                        height: '32px', 
                        borderRadius: '50%', 
                        marginInlineEnd: (isAuthor ? 0 : '15px'), 
                        marginInlineStart: (isAuthor ? '15px' : 0), 
                        backgroundColor: texisionGray
                    }}/>
            </Grid>
        );
    }

    messageText = (text, isAuthor) => {
        return (
            <Grid
                item xs
                style={{
                    display: "flex", 
                    justifyContent: isAuthor ? "flex-end" : "flex-start",
                }}>

                <div style={{
                    padding: 7,
                    paddingRight: 10,
                    paddingLeft: 10,
                    borderRadius: 20, 
                    backgroundColor: isAuthor ? texisionBlue : texisionGray,
                    color: isAuthor ? white : texisionFontColorDark,
                    marginLeft: isAuthor ? "20%" : 0,
                    marginRight: isAuthor ? 0 : "20%",
                    whiteSpace: "pre-line"
                }}>
                    {text}
                </div>

            </Grid>
        );
    }

    render() {

        let currentOperationId = -1;

        return (
            <div style={{margin: 15, marginBottom: 60}}>

                {!isAdmin(this.context.currentUser) && <div style={{fontSize: "0.8rem", marginBottom: 20}}>
                    {this.messageText(this.props.intl.formatMessage({id: "support.drawer.introduction"}), false)}
                </div>}

                <Grid container alignItems="flex-start">

                    {this.props.messages.sort((a,b) => a.created - b.created).map(message => {
                        const messageBubble = (
                            <React.Fragment key={message.id + "-message-grid-item"}>
                                {this.messageComponent(message)}
                            </React.Fragment>
                        );
                        // show project/contract name where the message was sent by the customer
                        // to avoid confusion ignore admin message origins (because maybe the admin responds while having another project open)
                        if (localStorage.getItem(APP_TYPE_KEY) !== OFFER_APP 
                            && currentOperationId !== message.operationId
                            && message.customerId === message.authorId) {

                            currentOperationId = message.operationId;
                            return (
                                <React.Fragment key={message.id + "-with-project"}>
                                    <Grid item xs={12}>
                                        {this.getProjectName(currentOperationId)}
                                    </Grid>

                                    {messageBubble}
                                </React.Fragment>
                            );
                        } else {
                            return messageBubble;
                        }
                    })}

                </Grid>

            </div>
        );
    }
}

export default injectIntl(SupportChat);
