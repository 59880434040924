import {
    BIDDER_COMMUNICATION_FILTER_ANSWERED,
    BIDDER_COMMUNICATION_FILTER_INFO, BIDDER_COMMUNICATION_FILTER_NONE,
    BIDDER_COMMUNICATION_FILTER_NOT_ANSWERED, BIDDER_COMMUNICATION_FILTER_NOTIFICATION,
    MESSAGE_TYPES
} from "../../util/Constants";
import React from "react";
import {Card, CardContent, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import MessageCard from "./MessageCard";

class ChatHistory extends React.Component {

    noMessagesCard = () => {
        const hasFilter = this.props.filter !== BIDDER_COMMUNICATION_FILTER_NONE;
        return (
            <Card>
                <CardContent className="emptyOrErrorCard">
                    <Typography variant="subtitle1">
                        <FormattedMessage id={hasFilter
                            ? "bidderCommunication.noMessagesForFilter.subtitle" : "bidderCommunication.noMessages.subtitle"}/>
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    filterMessages = (message) => {
        // if message is from other bidder (text is anonymized) and contains no public messages, remove it
        return !(!message.text && !message.answers.find(a => !a.isPrivate));
    }

    render() {
        let firstLevelMessages = this.props.messages?.filter(m => m.type !== MESSAGE_TYPES.ANSWER) ?? [];

        if (this.props.filter === BIDDER_COMMUNICATION_FILTER_INFO) {

            firstLevelMessages = firstLevelMessages.filter(m => m.type === MESSAGE_TYPES.INFORMATION);

        } else if (this.props.filter === BIDDER_COMMUNICATION_FILTER_ANSWERED) {

            firstLevelMessages = firstLevelMessages.filter(m => m.answers && m.answers.length > 0 && m.type === MESSAGE_TYPES.QUESTION);

        } else if (this.props.filter === BIDDER_COMMUNICATION_FILTER_NOT_ANSWERED) {

            firstLevelMessages = firstLevelMessages.filter(m => (!m.answers || m.answers.length === 0) && m.type === MESSAGE_TYPES.QUESTION);

        } else if (this.props.filter === BIDDER_COMMUNICATION_FILTER_NOTIFICATION) {

            firstLevelMessages = firstLevelMessages.filter(m => m.type === MESSAGE_TYPES.NOTIFICATION);

        }

        firstLevelMessages = firstLevelMessages.filter(this.filterMessages).sort((a,b) => b.createdAt - a.createdAt);

        return (
            <div>

                {this.props.isCreating ? this.props.messageCreateForm : this.props.messageCreateButton}

                {!!firstLevelMessages?.length

                    ? firstLevelMessages.map(m => <MessageCard
                        acceptedFileTypes={this.props.acceptedFileTypes}
                        message={m}
                        index={firstLevelMessages.findIndex(message => message === m)}
                        listLength={firstLevelMessages.length}
                        loadData={this.props.loadData}
                        onRespond={this.props.onRespond}
                        openedAnswerId={this.props.openedAnswerId}
                        isUploading={this.props.isUploading}
                        answerFiles={this.props.answerFiles}
                        onCancel={this.props.onResponseCancel}
                        uploadPrivateAnswer={this.props.uploadPrivateAnswer}
                        newAnswerText={this.props.newAnswerText}
                        onAnswerChange={this.props.onAnswerChange}
                        deleteFile={this.props.deleteFile}
                        selectFile={this.props.selectFile}
                        numberOfOffers={this.props.numberOfOffers}
                        showWithdrawOffersWarning={this.props.showWithdrawOffersWarning}
                        showPublicAnswerWarning={this.props.showPublicAnswerWarning}
                    />)

                    : this.noMessagesCard()}

            </div>
        );
    }
}

ChatHistory.propTypes = {
    acceptedFileTypes: PropTypes.string.isRequired,
    filter: PropTypes.string.isRequired,
    isCreating: PropTypes.bool.isRequired,
    openedAnswerId: PropTypes.number.isRequired,
    isUploading: PropTypes.bool.isRequired,
    messages: PropTypes.array.isRequired,
    messageCreateForm: PropTypes.element.isRequired,
    messageCreateButton: PropTypes.element.isRequired,
    loadData: PropTypes.func.isRequired,
    onRespond: PropTypes.func.isRequired,
    answerFiles: PropTypes.array.isRequired,
    onResponseCancel: PropTypes.func.isRequired,
    uploadPrivateAnswer: PropTypes.func.isRequired,
    newAnswerText: PropTypes.string.isRequired,
    onAnswerChange: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    selectFile: PropTypes.func.isRequired,
    numberOfOffers: PropTypes.number.isRequired,
    showPublicAnswerWarning: PropTypes.func.isRequired,
    showWithdrawOffersWarning: PropTypes.func.isRequired
};

export default ChatHistory;
