import React, {Component} from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography, Paper, TableRow, TableHead, Table, TableContainer, TableCell, TableBody, Button} from '@material-ui/core';
import ArticleDetail from '../../share/ArticleDetail';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {ARTICLE_CONFIGURATOR_ROUTE} from '../../../util/Constants';
import {bodyBackgroundColor, texisionBlueGrayDark} from '../../../util/ColorTheme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {renderImage} from '../../../util/TableUtil';
import {isAdmin} from '../../../services/UserService';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {GeneralContext} from "../../contexts/GeneralContext";
import FilterCategoriesPreview from "../../administration/articleOverview/FilterCategoriesPreview";

class ArticleAccordionElement extends Component {

    static contextType = GeneralContext;

    state = {
        articleForDetailView: null,
        showArticleDetails: false
    }

    showArticleDetails = (article) => {
        this.setState({showArticleDetails: true, articleForDetailView: article});
    }

    handleDialogClose = () => {
        this.setState({showArticleDetails: false, articleForDetailView: null});
    }

    handleArticleEdit = (article) => {
        this.props.history.push({pathname: ARTICLE_CONFIGURATOR_ROUTE, state: {article}})
    }

    handleArticleDeleted = () => {
        this.props.reloadData();
    }

    render() {

        const articleDetailsDialog = this.state.articleForDetailView 
            ? <ArticleDetail
                article={this.state.articleForDetailView}
                showDialog={this.state.showArticleDetails}
                handleDialogClose={this.handleDialogClose}
                mayEditAndDelete={true}
                handleArticleDeleted={this.handleArticleDeleted}/>
            : null;
                        
        return (
                
            <Accordion elevation={0} TransitionProps={{unmountOnExit: true}}>

                {articleDetailsDialog}

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-label="Expand"
                    aria-controls="operating-resouces-content"
                    id="operating-resouces-header">

                    <Typography variant="h3" component="span">
                        {this.props.header}
                    </Typography>

                </AccordionSummary>

                <AccordionDetails>

                    <TableContainer component={Paper} style={{overflowX: "scroll"}}>

                        <Table aria-label="delivery-quantity-table">

                            <TableHead style={{backgroundColor: bodyBackgroundColor}}>

                                <TableRow>

                                    {this.props.assignable && <TableCell style={{fontWeight: "bold"}}>
                                        {this.props.intl.formatMessage({id: "articleOverview.table.assign.title"})}
                                    </TableCell>}

                                    <TableCell style={{fontWeight: "bold"}}>
                                        {this.props.intl.formatMessage({id: "entities.article.articleCategory"})}
                                    </TableCell>

                                    <TableCell style={{fontWeight: "bold"}}>
                                        {this.props.intl.formatMessage({id: "entities.article.description"})}
                                    </TableCell>

                                    <TableCell style={{fontWeight: "bold"}}>
                                        {this.props.intl.formatMessage({id: "entities.article.image"})}
                                    </TableCell>

                                    <TableCell style={{fontWeight: "bold", width: "30%"}}>
                                        {this.props.intl.formatMessage({id: "entities.article.filterOptions"})}
                                    </TableCell>

                                    <TableCell style={{fontWeight: "bold"}}>
                                        {this.props.intl.formatMessage({id: "entities.article.details"})}
                                    </TableCell>

                                    {isAdmin(this.context.currentUser) && <TableCell style={{fontWeight: "bold"}}>
                                        {this.props.intl.formatMessage({id: "entities.article.edit"})}
                                    </TableCell>}

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {this.props.articles
                                    ?.sort((a,b) =>
                                        this.props.intl.formatMessage({id: "constants.ArticleCategory." + a.articleCategory})
                                            .localeCompare(this.props.intl.formatMessage({id: "constants.ArticleCategory." + b.articleCategory})))
                                    ?.map(a => (

                                    <TableRow key={a.id}>

                                        <TableCell component="th" scope="row">
                                            <Typography>
                                                <FormattedMessage id={"constants.ArticleCategory." + a.articleCategory}/>
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            {a.description}
                                        </TableCell>

                                        <TableCell>
                                            {renderImage(a, this.showArticleDetails)}
                                        </TableCell>

                                        <TableCell style={{width: "30%"}}>
                                            <FilterCategoriesPreview filterCategories={a.filterCategories} articleConfigurationId={a.id}/>
                                        </TableCell>

                                        <TableCell>
                                            <Button onClick={() => this.showArticleDetails(a)} style={{marginLeft: "auto"}}>
                                                <InfoOutlinedIcon style={{color: texisionBlueGrayDark}}/>
                                            </Button>
                                        </TableCell>

                                        {isAdmin(this.context.currentUser) && <TableCell>
                                            <Tooltip title={this.props.intl.formatMessage({id: "commons.edit.button"})}>
                                                <Button onClick={() => this.handleArticleEdit(a)}>
                                                    <Icon className="icon-edit" style={{fontSize: "21pt"}}/>
                                                </Button>
                                            </Tooltip>
                                        </TableCell>}

                                    </TableRow>
                                ))}

                            </TableBody>

                        </Table>

                    </TableContainer>

                </AccordionDetails>

            </Accordion>
        );
    }
}

export default injectIntl(withRouter(ArticleAccordionElement));
