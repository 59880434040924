import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {LICENSE_TYPES} from "../../util/Constants";

class CouponDetails extends Component {

    getCouponTypeDetails = (coupon) => {
        if (coupon.percentOff) {
            return <FormattedMessage id="payment.coupon.percentOff.text" values={{percentOff: coupon.percentOff}}/>;
        } else if (coupon.amountOff) {
            return <FormattedMessage id="payment.coupon.amountOff.text" values={{amountOff: coupon.amountOff/100, currency: this.props.currency}}/>;
        }
    }

    render() {
        return (
            <div>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs style={{display: "flex", justifyContent: "flex-start"}}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="payment.coupon.code.title"/>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="payment.coupon.code"
                                values={{code: this.props.promoCode ? (this.props.promoCode+ " - ") : "", name: this.props.coupon.name}}/>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs style={{display: "flex", justifyContent: "flex-start"}}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="payment.coupon.type"/>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            {this.getCouponTypeDetails(this.props.coupon)}
                        </Typography>
                    </Grid>
                </Grid>

                {this.props.licenseType === LICENSE_TYPES.COOPERATION_LICENSE && <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs style={{display: "flex", justifyContent: "flex-start"}}>
                        <Typography variant="subtitle1">
                            <FormattedMessage id="payment.coupon.duration"/>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id={"payment.coupon.duration." + this.props.coupon.duration}
                                values={{durationInMonths: this.props.coupon.durationInMonths}}/>
                        </Typography>
                    </Grid>
                </Grid>}

            </div>
        );
    }
}

CouponDetails.propTypes = {
    licenseType: PropTypes.string.isRequired,
    coupon: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired
};

export default injectIntl(CouponDetails);
