import React from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import "../../css/ImageCropper.css";
import {GeneralContext} from "../contexts/GeneralContext";



class ImageCropper extends React.Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            croppedImage: "",
            ratio: props.ratio || 3/2
        };

        this.originalImage = React.createRef();
    }

    createCropper = () => {
        let cropper = new Cropper(this.originalImage.current, {
            zoomable: false,
            scalable: false,
            aspectRatio: this.state.ratio,
            crop: () => {
                const canvas = cropper.getCroppedCanvas();
                this.props.croppedImageCallback(canvas);
                this.setState({croppedImage: canvas.toDataURL("image/png")});
            }
        });

        return cropper;
    }

    componentDidMount() {
        this.cropper = this.createCropper();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.src !== this.props.src) {
            this.cropper.replace(this.props.src);
        }
    }

    render() {
        return (
            <div>
                <div className="img-container">
                    <img ref={this.originalImage} src={this.props.src} alt="OriginalImage"/>
                </div>
                <img src={this.state.croppedImage} className={this.props.previewClass || 'img-preview'} alt="CroppedImagePreview" />
            </div>
        );
    }
}

export default ImageCropper;
