import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import {injectIntl} from "react-intl";
import {getCockpitStatisticsCategoryLabel} from "../../../../util/Constants";

class CategorySelection extends Component {

    checkboxGridItem = (category, value, onChange) => {
        return (
            <Grid item key={"checkbox-" + category}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <Checkbox
                            color="primary"
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}/>
                    </Grid>
                    <Grid item>
                        {getCockpitStatisticsCategoryLabel(this.props.intl, category)}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render() {
        let data = [
            ["ww", this.props.workWearSelected, (checked) => this.props.onWorkWearChanged(checked)],

            ["fl", this.props.flatLinenSelected, (checked) => this.props.onFlatLinenChanged(checked)],

            ["ca", this.props.customerArticlesSelected, (checked) => this.props.onCustomerArticlesChanged(checked)],

            ["rl", this.props.residentsLaundrySelected, (checked) => this.props.onResidentsLaundryChanged(checked)],

            ["or", this.props.operatingResourcesSelected, (checked) => this.props.onOperatingResourcesChanged(checked)]
        ];
        if (this.props.selectedTab === "price") {
            data.splice(0, 0, ["all", this.props.allSelected, (checked) => this.props.onAllChanged(checked)]);
        }
        return (
            <Grid container spacing={2} justifyContent="flex-start">
                {data.map(([textId, value, onChange]) => this.checkboxGridItem(textId, value, onChange))}
            </Grid>
        );
    }
}

CategorySelection.propTypes = {
    allSelected: PropTypes.bool.isRequired,
    workWearSelected: PropTypes.bool.isRequired,
    flatLinenSelected: PropTypes.bool.isRequired,
    customerArticlesSelected: PropTypes.bool.isRequired,
    residentsLaundrySelected: PropTypes.bool.isRequired,
    operatingResourcesSelected: PropTypes.bool.isRequired,
    onAllChanged: PropTypes.func.isRequired,
    onWorkWearChanged: PropTypes.func.isRequired,
    onFlatLinenChanged: PropTypes.func.isRequired,
    onCustomerArticlesChanged: PropTypes.func.isRequired,
    onResidentsLaundryChanged: PropTypes.func.isRequired,
    onOperatingResourcesChanged: PropTypes.func.isRequired,
    selectedTab: PropTypes.string.isRequired
};

export default injectIntl(CategorySelection);
