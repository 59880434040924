import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {withSnackbar} from 'notistack';
import {downloadUsingGet, getAsync} from "../../services/BackendService";
import "../../css/BidderProjectDetails.css";
import {GeneralContext} from "../contexts/GeneralContext";
import { DE, EXPORT_TYPE_CONCATENATE, EXPORT_TYPE_MERGE } from "../../util/Constants";
import fileDownload from "js-file-download";
import { createErrorMessage } from "../../util/Util";
import PriceSheetTaxes from "../pricesheet/PriceSheetTaxes";
import PriceSheetUnit from "../pricesheet/PriceSheetUnit";
import { PriceSheetSummary } from "../pricesheet/PriceSheetSummary";
import { PriceSheetHeader } from "../pricesheet/PriceSheetHeader";
import { PriceSheetErrorCard } from "../pricesheet/PriceSheetErrorCard";
import { PriceSheetNotFoundDialog } from "../pricesheet/PriceSheetNotFoundDialog";
import { PriceSheetNoOfferCard } from "../pricesheet/PriceSheetNoOfferCard";
import { getAllArticleNumbers } from "../pricesheet/PriceSheetUtil";
import {withRouter} from "react-router-dom";
import {getPublishedProjectById} from "../../services/ProjectService";

class BidderPriceSheet extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            allArticleNumbers: {},
            contentVos: null,
            isInitializing: true,
            notFound: false,
            offer: null,
            priceOffer: null,
            project: null
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match?.params?.projectId !== prevProps.match?.params?.projectId) {
            this.setState({isInitializing: true});
            await this.loadData();
        }
    }

    loadData = async() => {
        await this.loadProject();
        const offer = await this.loadOffer();
        let priceOffer;
        let contentVos;
        let allArticleNumbers;
        if (offer?.id === 0 || offer?.id) {
            let result = await this.loadPriceOfferResult(offer.id);
            priceOffer = result.priceOfferVos[0];
            contentVos = result.contentVos;
            allArticleNumbers = getAllArticleNumbers(priceOffer);
        }
        this.setState({allArticleNumbers, contentVos, isInitializing: false, offer, priceOffer});
    }

    loadProject = async() => {
        const project = await getPublishedProjectById(this.context, this.props, parseInt(this.props.match?.params?.projectId));
        this.setState({project, notFound: !project});
    }

    loadOffer = async() => {
        const response = await getAsync("/offer/" + this.props.match?.params?.projectId);
        if (response?.status === 200) {
            return response.data;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    reloadPriceOffer = async(offerId) => {
        const priceOfferResult = await this.loadPriceOfferResult(offerId);
        const priceOffer = priceOfferResult.priceOfferVos[0];
        const allArticleNumbers = getAllArticleNumbers(priceOffer);
        this.setState({allArticleNumbers, priceOffer});
    }

    loadPriceOfferResult = async(offerId) => {
        const response = await getAsync("/priceoffer/" + offerId);
        if (response?.status === 200) {
            if (response.data?.priceOfferVos && response.data.priceOfferVos.length > 0) {
                return response.data;
            } else {
                return null;
            }
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    resetAllArticleNumbers = () => {
        const allArticleNumbers = getAllArticleNumbers(this.state.priceOffer);
        this.setState({allArticleNumbers});
    }

    exportPriceSheet = async(offerId) => {
        this.setState({isDownloading: true});
        const response = await downloadUsingGet('/pricesheet/offer/' + offerId + "/"+ DE);
        if (response?.status === 200) {
            const fileName = response.headers['content-disposition'].split("filename=")[1];
            fileDownload(response.data, fileName);
        } else if ([401, 403].includes(response?.status)) {
            this.context.logout();
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "bidder.priceSheet.export.errorMessage"}), this.props);
        }
        this.setState({isDownloading: false});
    }

    render() {

        const { project, offer, priceOffer, contentVos, isInitializing, notFound, isDownloading } = this.state;

        if (isInitializing) {

            return (
                <div/>
            );

        } else {
        
            if (!project || !priceOffer || !contentVos || contentVos.length === 0 
                || ![EXPORT_TYPE_CONCATENATE, EXPORT_TYPE_MERGE].includes(priceOffer.exportType)) {

                return (
                    <>

                        <PriceSheetNotFoundDialog open={notFound}/>
                        <PriceSheetErrorCard/>

                    </>
                );

            } else if (!offer || !priceOffer) {

                return (
                    <PriceSheetNoOfferCard/>
                );

            } else {

                return (

                    <>

                        <PriceSheetHeader
                            exportPriceSheet={() => this.exportPriceSheet(offer.id)}
                            isDownloading={isDownloading}
                            subtitleId="bidder.priceSheet.subtitle"
                            titleId="bidder.priceSheet.firstRound.title"/>

                        <PriceSheetNotFoundDialog open={notFound}/>

                        <PriceSheetTaxes
                            offer={offer} 
                            project={project}
                            notFound={notFound}
                            reloadData={this.loadData}
                            reloadProject={this.loadProject}/>

                        <PriceSheetSummary
                            currency={project?.currency}
                            sumGross={priceOffer?.sumGross}
                            sumNet={priceOffer?.sumNet}
                            valueAddedTax={offer?.valueAddedTax}/>

                        {contentVos.map(contentVo => <PriceSheetUnit
                            allArticleNumbers={this.state.allArticleNumbers}
                            contentVo={contentVo}
                            editable={true}
                            exportType={priceOffer.exportType}
                            isEditingWorkingProject={false}
                            offer={offer}
                            priceUnitMap={priceOffer.priceUnitMap}
                            project={project}
                            reloadPriceSheet={() => this.reloadPriceOffer(offer.id)}
                            resetAllArticleNumbers={this.resetAllArticleNumbers}
                        />)}

                    </>
                );
            }
        }
    }
}

export default withRouter(injectIntl(withSnackbar(BidderPriceSheet)));
