import React, {Component} from 'react';
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import {COUNTRIES, DIALOG_TYPE_WARNING, LOGISTIC_TYPE_DECENTRAL, UNIT_IMAGE_DESCRIPTOR, UNIT_LOGO_DESCRIPTOR} from "../../../util/Constants";
import {Button, Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {MAX_FILE_SIZE_IN_BYTE} from "../../../util/DocumentUtil";
import {Divider} from "@mui/material";
import {GeneralContext} from "../../contexts/GeneralContext";
import {isEmptyOrBlank, sortAsc} from "../../../util/Util";
import {isAdmin, isBidder, isProjectAdmin} from "../../../services/UserService";
import {createBusinessUnit, getActiveUnit, reloadUnitsForActiveProject, updateBusinessUnit} from "../../../services/BusinessUnitService";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import BusinessUnitForm from "./BusinessUnitForm";
import {getActiveProject, updateValidationMap} from "../../../services/ProjectService";

class BusinessUnitPanel extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        if (this.props.unit) {
            this.state = {
                id: this.props.unit.id,
                name: this.props.unit.name,
                streetAndNumber: this.props.unit.streetAndNumber,
                zipCode: this.props.unit.zipCode,
                city: this.props.unit.city,
                // Business Unit Image
                croppedImageBlob: null,
                selectedImage: null,
                selectedFilename: null,
                imageVo: this.props.unit.imageVo,
                imageToDelete: null,
                // Business Unit Logo
                selectedLogo: null,
                logoVo: this.props.unit.logoVo,
                logoToDelete: null,
                // Other stuff
                showSelectedImageToLargeDialog: false,
                updateInProgress: false
            };
        } else {
            this.state = this.getEmptyData();
        }
    }

    getEmptyData = () => {
        return {
            id: null,
            name: "",
            streetAndNumber: "",
            houseNumber: "",
            zipCode: "",
            city: "",
            // Image
            croppedImageBlob: null,
            selectedImage: null,
            imageVo: null,
            imageToDelete: null,
            // Logo
            selectedLogo: null,
            logoVo: null,
            logoToDelete: null,
            // other stuff
            showSelectedImageToLargeDialog: false,
            updateInProgress: false
        }
    }

    handleCancelEdit = async() => {
        this.setState(this.getEmptyData());
        if (this.props.onClose) this.props.onClose();
    }

    loadUnits = async() => {
        // Loads all units for the given user
        const activeProject = getActiveProject(this.context);
        if (activeProject) {
            let allUnits = activeProject.businessUnits?.units;
            if (!allUnits) {
                if (this.props.onClose) this.props.onClose();
                return;
            } else if (allUnits.length > 0) {
                allUnits.sort((a, b) => sortAsc(a.name, b.name));
                await reloadUnitsForActiveProject(this.context);
                if (!getActiveUnit(this.context)) {
                    if (this.props.onClose) this.props.onClose();
                    await updateValidationMap(this.context);
                    return;
                }
            }
        }
        await updateValidationMap(this.context);
    }

    createButtonDisabled = () => {
        return isEmptyOrBlank(this.state.name)
            || isEmptyOrBlank(this.state.streetAndNumber)
            || isEmptyOrBlank(this.state.zipCode)
            || isEmptyOrBlank(this.state.city)
            || this.state.updateInProgress === true
            || !(isAdmin(this.context.currentUser)
                || isProjectAdmin(this.context.currentUser)
                || isBidder(this.context.currentUser));
    }

    handleCreateOrUpdate = async() => {
        let businessUnit = {
            id: this.state.id,
            projectId: this.context.appData.activeProjectId,
            name: this.state.name,
            streetAndNumber: this.state.streetAndNumber,
            zipCode: this.state.zipCode,
            city: this.state.city,
            country: COUNTRIES.DE,
            deliveryType: LOGISTIC_TYPE_DECENTRAL,
            pickupType: LOGISTIC_TYPE_DECENTRAL,
            version: 0
        }

        this.setState({updateInProgress: true});

        // create
        if (this.state.id === null || this.state.id === undefined) {

            const createdUnit = await createBusinessUnit(this.context, this.props, businessUnit,
                this.state.croppedImageBlob, this.state.selectedLogo, this.context.appData.activeProjectId, false, true);

            if (createdUnit) {
                await this.loadUnits();
                if (this.props.onClose) {
                    this.setState(this.getEmptyData());
                    this.props.onClose();
                }
            }
            // update
        } else {
            businessUnit.version = this.props.unit.version;
            businessUnit.deliveryType = this.props.unit.deliveryType;
            businessUnit.pickupType = this.props.unit.pickupType;

            const updatedUnit = await updateBusinessUnit(this.context, this.props, businessUnit,
                this.state.croppedImageBlob, this.state.imageToDelete, this.state.selectedLogo, this.state.logoToDelete);

            if (updatedUnit) {
                await this.loadUnits();
                if (this.props.onClose) {
                    this.setState(this.getEmptyData());
                    this.props.onClose();
                }
            }
        }

        this.setState({updateInProgress: false});
    }

    handleZipCodeChange = (zipCode) => {
        const zipCodeRegex = /^[0-9]*$/;
        if (zipCode === "" || zipCodeRegex.test(zipCode)) {
            this.setState({zipCode});
        }
    }

    handleImageSelection = async(file, type) => {
        if (file) {
            if (file.size >= MAX_FILE_SIZE_IN_BYTE) {
                this.setState({showSelectedImageToLargeDialog: true});
            } else {
                if (type === UNIT_IMAGE_DESCRIPTOR) {
                    this.setState({selectedImage: URL.createObjectURL(file), selectedFilename: file.name});
                } else if (type === UNIT_LOGO_DESCRIPTOR) {
                    this.setState({selectedLogo: file});
                }
            }
        }
    }

    handleImageCropped = async(croppedImage) => {
        let imageBlob = null;
        if (croppedImage) {
            let blob = await new Promise(resolve => croppedImage.toBlob(resolve, "image/jpeg", 0.9));
            imageBlob = new File([blob], this.state.selectedFilename, {type: 'image/png'});
        }

        if (imageBlob && imageBlob.size >= MAX_FILE_SIZE_IN_BYTE) {
            this.setState({showSelectedImageToLargeDialog: true, selectedImage: null, croppedImageBlob: null});
        } else {
            this.setState({croppedImageBlob: imageBlob});
        }
    }

    handleRemoveImage = () => {
        if (this.state.imageVo && !this.state.selectedImage) {
            this.setState({imageToDelete: {...this.state.imageVo.image}, imageVo: null});
        } else if (!this.state.imageVo && this.state.selectedImage) {
            // also set croppedImage to null, otherwise it will be uploaded to the server
            this.setState({selectedImage: null, selectedFilename: null, croppedImageBlob: null});
        } else if (this.state.imageVo && this.state.selectedImage) {
            this.setState({selectedImage: null, selectedFilename: null, croppedImageBlob: null});
        }
    }

    render() {
        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showSelectedImageToLargeDialog}
                    titleId="commons.image.warningDialog.title"
                    subtitleId="commons.image.warningDialog.text"
                    actionId="commons.close.label"
                    onAction={() => this.setState({showSelectedImageToLargeDialog: false, selectedImage: null, croppedImageBlob: null})}/>

                <Grid container alignItems="flex-start">

                    <BusinessUnitForm
                        name={this.state.name}
                        handleNameChange={(name) => this.setState({name})}
                        streetAndNumber={this.state.streetAndNumber}
                        handleStreetAndNumberChange={(streetAndNumber) => this.setState({streetAndNumber})}
                        zipCode={this.state.zipCode}
                        handleZipCodeChange={this.handleZipCodeChange}
                        city={this.state.city}
                        handleCityChange={(city) => this.setState({city})}
                        imageVo={this.state.imageVo}
                        handleImageSelection={this.handleImageSelection}
                        selectedImage={this.state.selectedImage}
                        logoVo={this.state.logoVo}
                        selectedLogo={this.state.selectedLogo}
                        handleRemoveImage={this.handleRemoveImage}
                        handleRemoveLogo={() => this.setState({logoToDelete: {...this.state.logoVo?.image}, logoVo: null, selectedLogo: null})}
                        handleImageCropped={this.handleImageCropped}
                    />

                    <Divider style={{width: "100%", marginTop: "2rem"}}/>

                    <Grid item style={{marginTop: "2rem"}}>

                        <Button
                            variant="contained"
                            color="primary"
                            disabled={this.createButtonDisabled()}
                            onClick={this.handleCreateOrUpdate}>
                            {this.state.id ? <FormattedMessage id="commons.save.button"/> : <FormattedMessage id="commons.create.button"/>}
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.handleCancelEdit}
                            style={{marginLeft: "10px"}}>
                            <FormattedMessage id="commons.cancel.button"/>
                        </Button>

                        {this.createButtonDisabled() && !this.state.updateInProgress
                            && <Typography
                                color="textSecondary"
                                component="span"
                                style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: "2rem"}}>
                                <FormattedMessage id="articleConfigurator.message.error.mandatory"/>
                            </Typography>
                        }

                    </Grid>
                </Grid>
            </>
        );
    }
}

BusinessUnitPanel.defaultProps = {
    unit: null,
    onClose: () => null,
    onSuccess: () => null
};

BusinessUnitPanel.propTypes = {
    unit: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default withRouter(withSnackbar(injectIntl(BusinessUnitPanel)));
