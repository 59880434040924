export const relevantPriceSheetConflicts = [
    "RESOURCE_OFFSETTING_MISSING", 
    "STATIONARY_OFFSETTING_MISSING", 
    "WORK_WEAR_OFFSETTING_MISSING",
    "SURGICAL_TEXTILES_OFFSETTING_MISSING",
    "ASSORTMENT_ARTICLES_MISSING",
    "DELIVERY_QUANTITIES_MISSING",
    "ASSORTMENT_ARTICLES_AMOUNT_MISSING",
    "RESIDENTS_LAUNDRY_AMOUNT_MISSING",
    "CUSTOMER_ARTICLES_AMOUNT_MISSING",
    "SINGLE_MISSING_CENTRAL_DELIVERY_ADDRESS",
    "MISSING_DECENTRAL_DELIVERY_ADDRESS"
];

export const relevantPriceSheetMismatches = [
    "RESOURCE_OFFSETTING_MISMATCH", 
    "STATIONARY_OFFSETTING_MISMATCH", 
    "WORK_WEAR_OFFSETTING_MISMATCH",
    "SURGICAL_TEXTILES_OFFSETTING_MISMATCH"
];

export const relevantRentalLinenConflicts = [
    "ASSORTMENT_ARTICLES_MISSING"
];

export const relevantAssortmentConflicts = [
    "ASSORTMENT_ARTICLES_MISSING", 
    "RESIDENTS_LAUNDRY_MISSING", 
    "CUSTOMER_ARTICLES_MISSING", 
    "OPERATING_RESOURCES_MISSING"
];

export const assortmentConflictMerges = () => {
    let conflictsToMerge = new Map();
    conflictsToMerge.set(relevantAssortmentConflicts, "ASSORTMENT_AND_RESOURCES_MISSING");
    return conflictsToMerge;
}

export const relevantResidentsLaundryConflicts = [
    "RESIDENTS_LAUNDRY_MISSING"
]

export const relevantCustomerArticlesConflicts = [
    "CUSTOMER_ARTICLES_MISSING"
];

export const relevantOffsettingConflicts = [
    "RESOURCE_OFFSETTING_MISSING", 
    "STATIONARY_OFFSETTING_MISSING", 
    "WORK_WEAR_OFFSETTING_MISSING",
    "SURGICAL_TEXTILES_OFFSETTING_MISSING",
    "SPECIAL_SERVICE_OFFSETTING_MISSING"
];

export const relevantOffsettingMismatches = [
    "RESOURCE_OFFSETTING_MISMATCH", 
    "STATIONARY_OFFSETTING_MISMATCH", 
    "WORK_WEAR_OFFSETTING_MISMATCH",
    "SURGICAL_TEXTILES_OFFSETTING_MISMATCH"
];

export const relevantLogisticsConflicts = [
    "CENTRAL_NO_DECENTRAL_DELIVERY_TIMES", 
    "CENTRAL_NO_DECENTRAL_PICK_UP_ADDRESSES", 
    "CENTRAL_NO_CENTRAL_DELIVERY_QUANTITIES", 
    "CENTRAL_MISSING_CENTRAL_DELIVERY_ADDRESS",
    "CENTRAL_MISSING_CENTRAL_PICKUP_ADDRESS", 
    "CENTRAL_MISSING_CENTRAL_DELIVERY_TIMES", 
    "CENTRAL_MISSING_CENTRAL_PICKUP_TIMES", 
    "DECENTRAL_NO_CENTRAL_DELIVERY_ADDRESSES",
    "DECENTRAL_NO_CENTRAL_PICKUP_ADDRESSES", 
    "MISSING_DECENTRAL_DELIVERY_ADDRESS",
    "DECENTRAL_MISSING_DECENTRAL_PICKUP_ADDRESS", 
    "DECENTRAL_MISSING_DECENTRAL_DELIVERY_TIMES",
    "DECENTRAL_MISSING_DECENTRAL_PICKUP_TIMES", 
    "SINGLE_NO_DECENTRAL_DELIVERY_ADDRESSES",
    "SINGLE_NO_DECENTRAL_PICKUP_ADDRESSES", 
    "SINGLE_MISSING_CENTRAL_DELIVERY_ADDRESS",
    "SINGLE_MISSING_CENTRAL_PICKUP_ADDRESS", 
    "SINGLE_MISSING_CENTRAL_DELIVERY_TIMES",
    "SINGLE_MISSING_CENTRAL_PICKUP_TIMES", 
    "ONLY_ONE_CENTRAL_DELIVERY_ADDRESS",
    "ONLY_ONE_CENTRAL_PICKUP_ADDRESS"
];

export const relevantDeliveryQuantityConflicts = [
    "ASSORTMENT_ARTICLES_AMOUNT_MISSING",
    "RESIDENTS_LAUNDRY_AMOUNT_MISSING",
    "CUSTOMER_ARTICLES_AMOUNT_MISSING",
    "DELIVERY_QUANTITIES_MISSING"
];

export const relevantBillOfQuantitiesConflicts = [
    "CENTRAL_NO_DECENTRAL_DELIVERY_TIMES", 
    "CENTRAL_NO_DECENTRAL_PICK_UP_ADDRESSES", 
    "CENTRAL_NO_CENTRAL_DELIVERY_QUANTITIES", 
    "CENTRAL_MISSING_CENTRAL_DELIVERY_ADDRESS",
    "CENTRAL_MISSING_CENTRAL_PICKUP_ADDRESS", 
    "CENTRAL_MISSING_CENTRAL_DELIVERY_TIMES", 
    "CENTRAL_MISSING_CENTRAL_PICKUP_TIMES", 
    "DECENTRAL_NO_CENTRAL_DELIVERY_ADDRESSES",
    "DECENTRAL_NO_CENTRAL_PICKUP_ADDRESSES", 
    "MISSING_DECENTRAL_DELIVERY_ADDRESS",
    "DECENTRAL_MISSING_DECENTRAL_PICKUP_ADDRESS", 
    "DECENTRAL_MISSING_DECENTRAL_DELIVERY_TIMES",
    "DECENTRAL_MISSING_DECENTRAL_PICKUP_TIMES", 
    "SINGLE_NO_DECENTRAL_DELIVERY_ADDRESSES",
    "SINGLE_NO_DECENTRAL_PICKUP_ADDRESSES", 
    "SINGLE_MISSING_CENTRAL_DELIVERY_ADDRESS",
    "SINGLE_MISSING_CENTRAL_PICKUP_ADDRESS", 
    "SINGLE_MISSING_CENTRAL_DELIVERY_TIMES",
    "SINGLE_MISSING_CENTRAL_PICKUP_TIMES", 
    "ONLY_ONE_CENTRAL_DELIVERY_ADDRESS",
    "ONLY_ONE_CENTRAL_PICKUP_ADDRESS",

    "RESOURCE_OFFSETTING_MISSING", 
    "STATIONARY_OFFSETTING_MISSING", 
    "WORK_WEAR_OFFSETTING_MISSING",
    "SURGICAL_TEXTILES_OFFSETTING_MISSING",

    "ASSORTMENT_ARTICLES_MISSING",
    "DELIVERY_QUANTITIES_MISSING",
    "RESIDENTS_LAUNDRY_MISSING",

    "ASSORTMENT_ARTICLES_AMOUNT_MISSING",
    "RESIDENTS_LAUNDRY_AMOUNT_MISSING",
    "CUSTOMER_ARTICLES_AMOUNT_MISSING"
];

export const relevantBillOfQuantitiesMismatches = [
    "RESOURCE_OFFSETTING_MISMATCH", 
    "STATIONARY_OFFSETTING_MISMATCH", 
    "WORK_WEAR_OFFSETTING_MISMATCH",
    "SURGICAL_TEXTILES_OFFSETTING_MISMATCH"
];

export const billOfQuantitiesMerges = () => {
    let conflictsToMerge = new Map();
    conflictsToMerge.set(["ASSORTMENT_ARTICLES_MISSING", "RESIDENTS_LAUNDRY_MISSING"], "ARTICLES_OR_RESIDENTS_LAUNDRY_MISSING");
    return conflictsToMerge;
}

export const relevantContractConflicts = [
    "ASSORTMENT_ARTICLES_MISSING", 
    "RESIDENTS_LAUNDRY_MISSING"
];

export const contractConflictMerges = () => {
    let conflictsToMerge = new Map();
    conflictsToMerge.set(relevantContractConflicts, "ARTICLES_OR_RESIDENTS_LAUNDRY_MISSING");
    return conflictsToMerge;
}

export const relevantProcedureConflicts = [
    "PROCEDURE_STATE_MISSING",
    "PROCEDURE_DATES_INVALID",
    "PROCEDURE_RATINGS_INVALID"
];
