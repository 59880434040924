import {Button, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import { Component } from "react";
import { injectIntl } from "react-intl";
import {DIALOG_TYPE_INFO} from "../../util/Constants";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { GeneralContext } from "../contexts/GeneralContext";
import {texisionBlue} from "../../util/ColorTheme";
import ProductInformationList from "./ProductInformationList";
import {wordBreak} from "../../util/TableUtil";
import {getRecurringIntervalText} from "../../services/PaymentService";

class Product extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showInfoDialog: false
        }
    }

    getPriceTag = () => {
        const price = this.props.price;
        const intervalText = getRecurringIntervalText(this.props.intl, price);

        if (price.unitAmount && price.transformQuantity?.divideBy) {
            const percent = price.unitAmount/price.transformQuantity.divideBy;
            return (
                <Typography variant="h3">
                    {wordBreak(this.props.intl.formatMessage({id: "product.offer.price.title"}, {percent, 0: "{0}"}))}
                </Typography>
            );
        } else if (price.unitAmount && !intervalText) {
            return (
                <Typography variant="h1">
                    {wordBreak(price.unitAmount/100.0 + " " + price.currency.toUpperCase())}
                </Typography>
            );
        } else if (price.unitAmount && intervalText) {
            return (
                <Typography variant="h1">
                    {wordBreak(price.unitAmount/100.0 + " " + price.currency.toUpperCase() + "/" + intervalText)}
                </Typography>
            );
        } else if (!price.unitAmount && price.tiers?.length) {
            const value = price.tiers[0].unitAmount;
            return (
                <Typography variant="h3">
                    {wordBreak(this.props.intl.formatMessage({id: "product.subscription.cockpit.generalPrice"}, {percent: value, 0: "{0}"}))}
                </Typography>
            );
        }
        return "";
    }

    render() {
        if (!this.props.product || !this.props.price) {
            return null;
        }

        const product = this.props.product;
        const imageUrl = product.images?.find(i => i);

        return (
            <>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showInfoDialog}
                    titleId={"product.info.dialog.title." + product?.metadata?.licenseType}
                    cancelId="commons.close.label"
                    content={<ProductInformationList licenseType={product?.metadata?.licenseType}/>}
                    onCancel={() => this.setState({showInfoDialog: false})}/>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                    <div style={{width: 200, height: 220, borderBottomLeftRadius: 10, borderTopLeftRadius: 10, backgroundColor: texisionBlue, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <img style={{width: 150, backgroundColor: "transparent"}} src={imageUrl} alt=""/>
                    </div>

                    <div style={{width: 200, height: 250, border: "1px solid grey", padding: 10}}>

                        <Grid container>

                            <Grid item xs={12} style={{wordBreak: "break-word", height: 100}}>
                                {!this.props.isActive && this.getPriceTag()}
                            </Grid>

                            <Grid item xs={12} style={{height: 100}}>
                                {product.name.replace(" ", "\n")}
                                {this.props.showInfoIcon && <IconButton
                                    style={{color: texisionBlue}}
                                    onClick={() => this.setState({showInfoDialog: true})}>
                                    <InfoOutlinedIcon/>
                                </IconButton>}
                            </Grid>

                            <Grid item xs={12} style={{height: 50, display: "flex", alignItems: "flex-end", justifyContent: "center"}}>

                                {this.props.onSelectProduct &&
                                    <Tooltip title={this.props.selectTooltip}>
                                        <div>
                                            <Button
                                                onClick={() => this.props.onSelectProduct(this.props.product.id)}
                                                variant="contained"
                                                disabled={!this.props.isPublic && this.props.disabled}
                                                color="primary">
                                                {this.props.intl.formatMessage({id: "product.select.button"})}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                }

                                {this.props.isActive &&
                                    <Button
                                        onClick={() => this.setState({showInfoDialog: true})}
                                        variant="outlined"
                                        color="primary">
                                        {this.props.intl.formatMessage({id: "payment.active.product.content"})}
                                    </Button>}

                            </Grid>

                        </Grid>

                    </div>

                </div>

            </>
        );
    }
}

export default injectIntl(Product);
