import { Button, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { white } from "../../../../util/ColorTheme";
import { COCKPIT_INVOICES_ROUTE } from "../../../../util/Constants";
import { downloadS3Document, formatDate } from "../../../../util/DocumentUtil";
import { formatPrice } from "../../../../util/Util";
import { GeneralContext } from "../../../contexts/GeneralContext";
import {getActiveOperation} from "../../../../services/OperationService";

class InvoiceStatisticsDetails extends Component {

    static contextType = GeneralContext;

    invoiceDetails = (invoice) => {
        const currency = getActiveOperation(this.context).activeProject.currency;
        return [
            ["cockpit.invoices.table.invoiceDate", formatDate(this.props.intl, invoice.invoiceDate), "h3", "subtitle2"],
            ["cockpit.invoices.table.fileName", invoice.document.fileName],
            ["cockpit.invoices.table.creator", invoice.creator],
            ["cockpit.invoices.table.uploadDate", formatDate(this.props.intl, invoice.document.created)],
            ["cockpit.invoices.table.deliveryDate", formatDate(this.props.intl, invoice.deliveryDate)],
            ["cockpit.invoices.table.invoicePaymentDate", formatDate(this.props.intl, invoice.invoicePaymentDate)],
            ["cockpit.invoices.table.totalNet", formatPrice(invoice.totalNet) + " " + currency],
            ["cockpit.invoices.table.totalGross", formatPrice(invoice.totalGross) + " " + currency]
        ]
        .map(([textId, content, textVariant, contentVariant]) => this.getRow(textId, content, textVariant, contentVariant));
    }

    getRow = (textId, content, textVariant, contentVariant) => {
        return (
            <React.Fragment key={textId}>
                <Grid item xs={6}>
                    <Typography variant={textVariant ?? "h4"} style={{wordBreak: "break-all"}}>
                        <FormattedMessage id={textId}/>
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{display: "flex", justifyContent: "flex-end", textAlign: "end"}}>
                    <Typography variant={contentVariant ?? "body1"}>
                        {content}
                    </Typography>
                </Grid>
            </React.Fragment>
        );
    }

    render() {

        const { invoice } = this.props;

        return (
            <div style={{backgroundColor: white}}>

                <div style={{marginLeft: 25, marginRight: 25, paddingTop: 25}}>
                    <h2>
                        <FormattedMessage id="cockpit.statistics.selectedInvoice.title"/>
                    </h2>
                </div>

                {invoice 
                    
                    ? <Grid container alignItems="flex-start" style={{width: "100%", padding: 25}}>

                        <Grid item>
                            <Button 
                                variant="outlined" 
                                color="primary"
                                style={{whiteSpace: "nowrap", marginBottom: 35, marginRight: 20}} 
                                onClick={() => downloadS3Document(this.props, invoice.document)}>
                                <FormattedMessage id="cockpit.invoices.download.button"/>
                            </Button>
                        </Grid>

                        <Grid item xs>
                            <Button 
                                variant="outlined" 
                                color="primary"
                                style={{whiteSpace: "nowrap", marginBottom: 35}} 
                                onClick={() => this.props.history.push(COCKPIT_INVOICES_ROUTE)}>
                                <FormattedMessage id="cockpit.goToInvoices.button"/>
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container alignItems="flex-start">
                                {this.invoiceDetails(invoice)}
                            </Grid>
                        </Grid>

                    </Grid>
                    
                    : <div style={{width: "90%", height: 200, padding: 40, display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <FormattedMessage id="cockpit.statistics.invoiceDetails.info"/>
                    </div>

                }

            </div>
        );
    }
}

export default injectIntl(withRouter(InvoiceStatisticsDetails));
