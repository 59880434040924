import React, {Component} from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import {DELIVERY, DELIVERY_PICKUP, LOGISTICS_ROUTE, PICKUP, PROJECT_TASKS} from "../../util/Constants";
import TenderTile from "./TenderTile";
import {Grid} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import '../../css/Validation.css';
import {getUnitAddresses} from "../../services/AddressService";
import {isProjectTaskCompleted} from "../../services/ProjectService";

class LogisticsTiles extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            deliveryAddresses: [],
            pickupAddresses: [],
            logisticOpen: false,
        };
    }

    async componentDidMount() {
        await this.loadAddresses();
    }

    loadAddresses = async() => {
        if (!this.props.businessUnit) {
            return;
        }
        const addresses = await getUnitAddresses(this.context, this.props, this.props.businessUnit.id);
        this.setState({
            deliveryAddresses: addresses?.filter(a => a.addressType === DELIVERY || a.addressType === DELIVERY_PICKUP) ?? [],
            pickupAddresses: addresses?.filter(a => a.addressType === PICKUP || a.addressType === DELIVERY_PICKUP) ?? [],
        });
    }

    render() {
        if (!this.props.businessUnit) {
            return null;
        }
        return (
            <Grid item>

                <Grid container>

                    <TenderTile
                        class={"tileUnitCard"}
                        projectTask={PROJECT_TASKS.LOGISTIC}
                        objectId={this.props.businessUnit.id}
                        onTileClicked={() => this.setState({logisticOpen: !this.state.logisticOpen})}
                        mayEdit={true}
                        onEdit={() => this.props.history.push(LOGISTICS_ROUTE)}/>

                    {this.state.logisticOpen &&
                    <Grid item xs>
                        <Grid container className={"logisticGrid"} >
                            {this.state.logisticOpen && this.state.deliveryAddresses?.map(deliveryAddress => <Grid item xs={12}>
                                <Grid container wrap="nowrap" >
                                    <TenderTile
                                        class={"tileLogisticCard"}
                                        projectTask={PROJECT_TASKS.DELIVERY_ADDRESS}
                                        objectId={deliveryAddress.id}
                                        concreteTitle={deliveryAddress.shortDescription}
                                        onTileClicked={() => this.props.onTileClicked(PROJECT_TASKS.DELIVERY_ADDRESS)}/>
                                    <TenderTile
                                        class={"tileLogisticCard"}
                                        projectTask={PROJECT_TASKS.ARTICLE_ASSIGNMENT}
                                        objectId={deliveryAddress.id}
                                        onTileClicked={() => this.props.onTileClicked(PROJECT_TASKS.ARTICLE_ASSIGNMENT)}/>
                                    <TenderTile
                                        class={"tileLogisticCard"}
                                        projectTask={PROJECT_TASKS.DELIVERY_QUANTITY}
                                        objectId={deliveryAddress.id}
                                        onTileClicked={() => this.props.onTileClicked(PROJECT_TASKS.DELIVERY_QUANTITY)}/>
                                </Grid>
                            </Grid>)}

                            {this.state.logisticOpen && this.state.pickupAddresses?.map(pickupAddress => <Grid item xs={12}>
                                <TenderTile
                                    class={"tileLogisticCard"}
                                    projectTask={PROJECT_TASKS.PICKUP_ADDRESS}
                                    objectId={pickupAddress.id}
                                    concreteTitle={pickupAddress.shortDescription}
                                    onTileClicked={() => this.props.onTileClicked(PROJECT_TASKS.PICKUP_ADDRESS)}/>
                            </Grid>)}

                            {this.state.logisticOpen
                                && !isProjectTaskCompleted(this.context, PROJECT_TASKS.LOGISTIC, this.props.businessUnit.id)
                                && <TenderTile
                                    class={"tileLogisticCard"}
                                    objectId={this.props.businessUnit.id}
                                    title="deliveryAndPickup.create.buttontext.create"
                                    onCreate={() => this.props.onTileClicked(PROJECT_TASKS.LOGISTIC)}
                                    mayCreate={true}/>}

                        </Grid>
                    </Grid> }

                </Grid>

            </Grid>
        );
    }
}

export default withRouter(injectIntl(LogisticsTiles));
