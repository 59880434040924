import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    Grid,
    Icon,
    Paper,
    Tab,
    Tabs,
    Typography
} from "@material-ui/core";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";

import TexisionDialog from "../../uiLibrary/TexisionDialog";
import ProfessionalGroupCard from "../professionalGroups/ProfessionalGroupCard";
import {withSnackbar} from 'notistack';
import {getAsyncCatch, postAsyncCatch} from "../../../services/BackendService";
import {DIALOG_TYPE_WARNING, EQUIPMENT_TYPE_AREA, EQUIPMENT_TYPE_PROFESSIONAL_GROUP, RENTAL_LINEN_ASSIGNMENT_ROUTE} from "../../../util/Constants";
import AreaCard from "../areas/AreaCard";
import AreaCreateDialog from "../areas/AreaCreateDialog";
import MaterialTable from "material-table";
import ProfessionalGroupCreateDialog from "../professionalGroups/ProfessionalGroupCreateDialog";
import {renderImage} from "../../../util/TableUtil";
import {bodyBackgroundColor, texisionBlueGray, texisionFontColorDark} from "../../../util/ColorTheme";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Box} from "@mui/system";
import ArticleSourcePopover from "./ArticleSourcePopover";
import '../../../css/ArticleOverview.css';
import {GeneralContext} from "../../contexts/GeneralContext";
import ArticleDetail from "../../share/ArticleDetail";
import { withRouter } from "react-router-dom";
import {TabPanel} from "../../uiLibrary/TabPanel";
import FilterCategoriesPreview from "../../administration/articleOverview/FilterCategoriesPreview";
import {wrapOptionsWithCategories} from "../../../util/ArticleHelper";
import {createArea, deleteArea, getUnitAreas, updateArea} from "../../../services/AreaService";
import {createProfessionalGroup, deleteProfessionalGroup, getUnitProfessionalGroups, updateProfessionalGroup} from "../../../services/ProfessionalGroupService";
import {loadArticleConfigurations} from "../../../services/ArticleConfigurationService";

class RentalLinen extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            selectedTabIndex: 0,
            // data
            areas: [],
            professionalGroups: [],
            assignedArticles: [],
            articleConfigurations: [],
            // create dialogs
            showAreaCreationDialog: false,
            showProfessionalGroupCreationDialog: false,
            // edit
            areaToEdit: null,
            professionalGroupToEdit: null,
            // delete
            showAreaDeleteWarningDialog: false,
            showProfessionalGroupDeleteWarningDialog: false,
            areaIdToDelete: null,
            professionalGroupIdToDelete: null,
            // article detail dialog
            showArticleDetails: false,
            articleForDetailView: null,
            showExportPopover: false,
            anchorEl: null,
            showSourcePopover: false,
            anchorSource: null,
            sourcesPopover: []
        };
    }

    async componentDidMount() {
        await this.reloadData();
    }

    reloadData = async() => {
        await Promise.all([
            getUnitAreas(this.context, this.props),
            getUnitProfessionalGroups(this.context, this.props),
            this.loadAssignedArticles(),
            loadArticleConfigurations(this.context, this.props)
        ]).then(([areas, professionalGroups, assignedArticles, articleConfigurations]) => {
            this.setState({areas, professionalGroups, assignedArticles, articleConfigurations});
        });
    }

    // assigned rental linen

    loadAssignedArticles = async() => {
        const data = await getAsyncCatch(this.context, "/assortment/" + this.context.appData.activeUnitId, this.props);
        if (data) {
            let assortmentArticles = data.articles;
            let articles = await postAsyncCatch(this.context, "/articles", {ids: assortmentArticles.map(a => a.articleId)}, this.props, true);
            if (articles) {
                for (let article of articles) {
                    article.sources = assortmentArticles.find(a => a.articleId === article.id)?.sources;
                }
                return articles;
            }
        }
    }

    // create or update area or professional group
    createOrUpdateArea = async(area) => {
        const payload = {
            id: area.id,
            version: area.version ?? 0,
            businessUnitId: this.context.appData.activeUnitId,
            name: area.name,
            articleIds: area.articleIds ?? []
        }
        if (payload.id === null || payload.id === undefined) {
            await createArea(this.context, this.props, payload);
        } else {
            await updateArea(this.context, this.props, payload);
        }
        this.setState({areaToEdit: null, areas: await getUnitAreas(this.context, this.props)});
    }

    createOrUpdateProfessionalGroup = async(professionalGroup) => {
        const payload = {
            id: professionalGroup.id,
            version: professionalGroup.version ?? 0,
            businessUnitId: this.context.appData.activeUnitId,
            name: professionalGroup.name,
            type: professionalGroup.professionalGroupType,
            gender: professionalGroup.gender,
            articleIds: professionalGroup.articleIds ?? []
        }
        if (payload.id === null || payload.id === undefined) {
            await createProfessionalGroup(this.context, this.props, payload);
        } else {
            await updateProfessionalGroup(this.context, this.props, payload);
        }
        this.setState({professionalGroupToEdit: null, professionalGroups: await getUnitProfessionalGroups(this.context, this.props)});
    }

    // assign articles

    assignArticlesToArea = (area) => {
        const assignedArticles = this.state.assignedArticles?.filter(a => a.sources?.find(s => s.sourceId === area.id && s.type === EQUIPMENT_TYPE_AREA));
        const articleConfigurations = this.state.articleConfigurations;
        this.props.history.push({pathname: RENTAL_LINEN_ASSIGNMENT_ROUTE, state: {area, assignedArticles, articleConfigurations}});
    }

    assignArticlesToProfessionalGroup = (professionalGroup) => {
        const assignedArticles = this.state.assignedArticles?.filter(a => a.sources
            ?.find(s => s.sourceId === professionalGroup.id && s.type === EQUIPMENT_TYPE_PROFESSIONAL_GROUP));
        const articleConfigurations = this.state.articleConfigurations;
        this.props.history.push({pathname: RENTAL_LINEN_ASSIGNMENT_ROUTE, state: {professionalGroup, assignedArticles, articleConfigurations}});
    }

    // warning before deleting area or professionalGroup

    deleteAreaConfirmation = (areaId) => {
        this.setState({showAreaDeleteWarningDialog: true, areaIdToDelete: areaId});
    } 

    deleteProfessionalGroupConfirmation = (professionalGroupId) => {
        this.setState({showProfessionalGroupDeleteWarningDialog: true, professionalGroupIdToDelete: professionalGroupId});
    }

    // cancel delete warning dialog

    resetAreaDeleteInformation = async() => {
        this.setState({showAreaDeleteWarningDialog: false, areaIdToDelete: null});
    }

    resetProfessionalGroupDeleteInformation = async() => {
        this.setState({showProfessionalGroupDeleteWarningDialog: false, professionalGroupIdToDelete: null});
    }

    // delete area or professionalGroup

    deleteArea = async() => {
        const success = await deleteArea(this.context, this.props, this.state.areaIdToDelete);
        if (success) {
            this.setState({areas: await getUnitAreas(this.context, this.props)});
            await this.resetAreaDeleteInformation();
        }
    }

    deleteProfessionalGroup = async() => {
        const success = await deleteProfessionalGroup(this.context, this.props, this.state.professionalGroupIdToDelete);
        if (success) {
            this.setState({professionalGroups: await getUnitProfessionalGroups(this.context, this.props)});
            await this.resetProfessionalGroupDeleteInformation();
        }
    }

    // edit area or professionalGroup

    editArea = async(area) => {
        this.setState({showAreaCreationDialog: true, areaToEdit: area});
    }

    editProfessionalGroup = async(professionalGroup) => {
        this.setState({showProfessionalGroupCreationDialog: true, professionalGroupToEdit: professionalGroup});
    }

    // show dialog for creating area or professionalGroup

    showAreaCreateDialog = (show) => {
        if (show) {
            this.setState({showAreaCreationDialog: true, areaToEdit: null});
        } else {
            this.setState({showAreaCreationDialog: false, areaToEdit: null})
        }
    }

    showProfessionalGroupCreateDialog = (show) => {
        if (show) {
            this.setState({showProfessionalGroupCreationDialog: true, professionalGroupToEdit: null});
        } else {
            this.setState({showProfessionalGroupCreationDialog: false, professionalGroupToEdit: null})
        }
    }

    // assortment table

    renderSourceLink = (articleId) => {
        let assortmentArticle = this.state.assignedArticles.find(a => a.id === articleId);
        let hasPersonaSource = assortmentArticle.sources.some((source) => source.type === EQUIPMENT_TYPE_PROFESSIONAL_GROUP);
        let hasAreaSource = assortmentArticle.sources.some((source) => source.type === EQUIPMENT_TYPE_AREA);
        if (hasPersonaSource || hasAreaSource) {
            let sourceId = "source_link_" + articleId;
            return (<Button id={sourceId}
                        onClick={(event) => this.handleSourcePopOverOpen(event, assortmentArticle)}>
                        {hasPersonaSource && <Icon className="icon-medical-doctor" style={{fontSize: "18pt"}}/>}
                        {hasAreaSource && <Icon className="icon-hospital" style={{fontSize: "24pt"}}/>}
                    </Button>);
        } else {
            return (<span/>);
        }
    }

    handleSourcePopOverOpen = (event, assortmentArticle) => {
        this.setState({anchorSource: event.currentTarget, showSourcePopover: true, sourcesPopover: assortmentArticle.sources});
    }

    handleSourcePopOverClose = () => {
        this.setState({showSourcePopover: false, sourcesPopover: []});
    }

    showArticleDetails = (article) => {
        let articleForDetailView = {...article};
        articleForDetailView.filterCategories = wrapOptionsWithCategories(article, this.state.articleConfigurations);
        this.setState({showArticleDetails: true, articleForDetailView});
    }

    hideArticleDetails = () => {
        this.setState({showArticleDetails: false, articleForDetailView: null});
    }

    filterOptions = (article) => {
        return (
            <FilterCategoriesPreview
                filterCategories={wrapOptionsWithCategories(article, this.state.articleConfigurations)}
                articleConfigurationId={article.articleConfigurationId}/>
        );
    }

    rentalLinenTable = (category) => {
        let data = this.state.assignedArticles.filter(r => r.category === category)
            .sort((a,b) => this.props.intl.formatMessage({id: "constants.SubCategory." + a.subcategory})
                .localeCompare(this.props.intl.formatMessage({id: "constants.SubCategory." + b.subcategory})));
        return (
            <Grid item xs={12}>
                <MaterialTable
                    className="rentalLinenTable"
                    columns={[
                        {title: this.props.intl.formatMessage({id: "entities.article.subCategory"}), field: "subcategory", filtering: false, sorting: false,
                            render: article => this.props.intl.formatMessage({id: "constants.SubCategory." + article.subcategory})},
                        {title: this.props.intl.formatMessage({id: "entities.article.articleCategory"}), field: "articleCategory", filtering: false, sorting: false,
                            render: article => this.props.intl.formatMessage({id: "constants.ArticleCategory." + article.articleCategory})},
                        {title: this.props.intl.formatMessage({id: "articleSource.title"}), field: "source", filtering: false,
                            render: article => this.renderSourceLink(article.id)},
                        {title: this.props.intl.formatMessage({id: "entities.article.description"}), field: "description", grouping: false, filtering: false},
                        {title: this.props.intl.formatMessage({id: "entities.article.image"}), field: "features", grouping: false, filtering: false,
                            render: article => renderImage(article, this.showArticleDetails)},
                        {title: this.props.intl.formatMessage({id: "entities.article.filterOptions"}), grouping: false, filtering: false,
                            render: article => this.filterOptions(article), cellStyle: {width: "30%"}}
                    ]}
                    data={data}
                    options={{
                        showTitle: false,
                        search: false,
                        paging: false,
                        draggable: false,
                        toolbar: false,
                        sorting: data.length > 0,
                        headerStyle: {
                            fontWeight: 'bold',
                            backgroundColor: bodyBackgroundColor,
                            color: texisionFontColorDark
                        }
                    }}/>
            </Grid>
        );
    }

    areas = () => {
        return <div>
            <Typography variant="h2">
                <FormattedMessage id="rentalLinen.areas.title"/>
            </Typography>
            <Typography variant="subtitle2">
                <FormattedMessage id="rentalLinen.areas.subtitle"/>
            </Typography>

            <Grid container spacing={2}>
                {this.state.areas.sort((a,b) => a.name.localeCompare(b.name)).map(area => {
                    return <Grid key={area.id} item>
                        <AreaCard 
                            readOnly={this.props.readOnly}
                            mode="withControls" 
                            area={area} 
                            deleteArea={this.deleteAreaConfirmation} 
                            editArea={this.editArea}
                            assignArticlesToArea={this.assignArticlesToArea}/>
                    </Grid>;
                })}
                <Grid item>
                    <AreaCard 
                        readOnly={this.props.readOnly}
                        mode="new" 
                        showDialog={this.showAreaCreateDialog}/>
                </Grid>
            </Grid>
        </div>;
    }

    professionalGroups = () => {
        return <div>
            <Typography variant="h2">
                <FormattedMessage id="rentalLinen.professionalGroups.title"/>
            </Typography>
            <Typography variant="subtitle2">
                <FormattedMessage id="rentalLinen.professionalGroups.subtitle"/>
            </Typography>

            <Grid container spacing={2}>
                {this.state.professionalGroups.sort((a,b) => a.name.localeCompare(b.name)).map(professionalGroup => {
                    return <Grid key={professionalGroup.id} item>
                        <ProfessionalGroupCard 
                            readOnly={this.props.readOnly}
                            mode="withControls" 
                            professionalGroup={professionalGroup} 
                            deleteProfessionalGroup={this.deleteProfessionalGroupConfirmation} 
                            editProfessionalGroup={this.editProfessionalGroup}
                            assignArticlesToProfessionalGroup={this.assignArticlesToProfessionalGroup}/>
                    </Grid>;
                })}
                <Grid item xs={4}>
                    <ProfessionalGroupCard 
                        readOnly={this.props.readOnly}
                        mode="new" 
                        showDialog={this.showProfessionalGroupCreateDialog}/>
                </Grid>
            </Grid>
        </div>;
    }

    assortment = () => {
        const articles = this.state.assignedArticles;
        return (
            <div>

                <Typography variant="h2">
                    <FormattedMessage id="rentalLinen.assortment.title"/>
                </Typography>

                <Typography variant="subtitle2">
                    <FormattedMessage id="rentalLinen.assortment.subtitle"/>
                </Typography>

                {!articles || articles.length === 0 ? <Card>
                    {this.props.intl.formatMessage({id: "rentalLinen.assortment.noArticles"})}
                </Card> : <div/>}

                {this.context.appData.categories
                    ?.sort((a,b) => this.props.intl.formatMessage({id: "constants.Category." + a})
                        .localeCompare(this.props.intl.formatMessage({id: "constants.Category." + b})))
                    ?.map(category => {
                    const filteredArticles = articles?.filter(a => a.category === category);
                    if (filteredArticles?.length) {
                        return (
                            <Grid item xs={12} key={category}>

                                <Accordion elevation={0}>

                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="-CONTENT"
                                        id="-HEADER">

                                        <Typography variant="h6">
                                            {this.props.intl.formatMessage({id: "constants.Category." + category})}
                                        </Typography>

                                    </AccordionSummary>

                                    <AccordionDetails>
                                        {this.rentalLinenTable(category)}
                                    </AccordionDetails>

                                </Accordion>

                            </Grid>
                        );
                    } else {
                        return <div/>;
                    }
                })}
            </div>
        );
    }

    handleTabChange = (event, newValue) => {
        this.setState({selectedTabIndex: newValue});
    }

    render() {

        return <>

            {this.state.articleForDetailView && <ArticleDetail 
                article={this.state.articleForDetailView}
                showDialog={this.state.showArticleDetails}
                handleDialogClose={this.hideArticleDetails}
            />}

            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.state.showAreaDeleteWarningDialog}
                titleId="area.deleteDialog.title"
                subtitleId="area.deleteDialog.text"
                actionId="commons.yes.button"
                cancelId="commons.no.button"
                onAction={() => this.deleteArea()}
                onCancel={() => this.resetAreaDeleteInformation()}/>

            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.state.showProfessionalGroupDeleteWarningDialog}
                titleId="professionalGroup.deleteDialog.title"
                subtitleId="professionalGroup.deleteDialog.text"
                actionId="commons.yes.button"
                cancelId="commons.no.button"
                onAction={() => this.deleteProfessionalGroup()}
                onCancel={() => this.resetProfessionalGroupDeleteInformation()}/>

            {this.state.showAreaCreationDialog && 
                <AreaCreateDialog 
                    area={this.state.areaToEdit} 
                    open={this.state.showAreaCreationDialog} 
                    showDialog={this.showAreaCreateDialog} 
                    createOrUpdateArea={this.createOrUpdateArea}/>}

            {this.state.showProfessionalGroupCreationDialog && 
                <ProfessionalGroupCreateDialog 
                    professionalGroup={this.state.professionalGroupToEdit} 
                    open={this.state.showProfessionalGroupCreationDialog} 
                    showDialog={this.showProfessionalGroupCreateDialog} 
                    createOrUpdateProfessionalGroup={this.createOrUpdateProfessionalGroup}/>}

            <ArticleSourcePopover 
                open={this.state.showSourcePopover} 
                anchorEl={this.state.anchorSource} 
                onClose={this.handleSourcePopOverClose} 
                articleSources={this.state.sourcesPopover}/>

            <Box>
                <Paper elevation={0} square={true} className="articleTabNavigation" style={{borderBottomColor: texisionBlueGray, fIndex: "1", marginBottom: "30px"}}>
                    <Grid container>
                        <Grid item xs={9} className="tabElements">
                            <Tabs className="rentalLinenTab" value={this.state.selectedTabIndex} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                                <Tab key="areas" label={this.props.intl.formatMessage({id: "rentalLinen.tab.areas"})} disableRipple={true}/>
                                <Tab key="professionalGroups" label={this.props.intl.formatMessage({id: "rentalLinen.tab.professionalGroups"})} disableRipple={true}/>
                                <Tab key="assortment" label={this.props.intl.formatMessage({id: "rentalLinen.tab.assortment"})} disableRipple={true}/>
                            </Tabs>
                        </Grid>
                    </Grid>
                </Paper>

                <TabPanel value={this.state.selectedTabIndex} index={0}>
                    {this.areas()}
                </TabPanel>
                <TabPanel value={this.state.selectedTabIndex} index={1}>
                    {this.professionalGroups()}
                </TabPanel>
                <TabPanel value={this.state.selectedTabIndex} index={2}>
                    {this.assortment()}
                </TabPanel>
            </Box>
            
        </>;
    }
}

export default injectIntl(withSnackbar(withRouter(RentalLinen)));
