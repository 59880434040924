import {DIALOG_TYPE_INFO, DIALOG_TYPE_WARNING, MAX_BUSINESS_UNIT_COUNT, PROJECT_TASKS} from '../../../util/Constants';
import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {withSnackbar} from 'notistack';

import BusinessUnitPaper from './BusinessUnitPaper';
import BusinessUnitPaperNew from './BusinessUnitPaperNew';


import '../../apps/App.css';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {GeneralContext} from "../../contexts/GeneralContext";
import { withRouter } from 'react-router-dom';
import {isComponentReadOnly} from "../../uiLibrary/ReadOnlyWrapper";
import {SimpleTextCard} from "../../uiLibrary/SimpleTextCard";
import {deleteBusinessUnit, getUnitsForActiveProjectFromContext, reloadUnitsForActiveProject} from "../../../services/BusinessUnitService";
import BusinessUnitPanel from "./BusinessUnitPanel";
import {getActiveProject} from "../../../services/ProjectService";

class BusinessUnitOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showDeleteDialog: false,
            showCreateOrEditDialog: false,
            businessUnitToDelete: null,
            unitToEdit: null
        }
    }

    async componentDidMount() {
        await reloadUnitsForActiveProject(this.context);
    }

    handleCreateBusinessUnit = () => {
        this.setState({showCreateOrEditDialog: true, unitToEdit: null});
    }

    handleDeleteBusinessUnit = (businessUnit)  => {
        this.setState({showDeleteDialog: true, businessUnitToDelete: businessUnit});
    }

    deleteUnit = async() => {
        await deleteBusinessUnit(this.context, this.props, this.state.businessUnitToDelete.id);
        this.setState({showDeleteDialog: false, businessUnitToDelete: null});
    }

    handleEditBusinessUnit = (businessUnit) => {
        this.setState({showCreateOrEditDialog: true, unitToEdit: businessUnit});
    }

    render() {
        const readOnly = isComponentReadOnly(this.context, PROJECT_TASKS.BUSINESS_UNIT, getActiveProject(this.context)?.id);
        const units = getUnitsForActiveProjectFromContext(this.context);
        const titleId = this.state.unitToEdit ? "businessUnit.edit.h1" : "businessUnit.create.h1";

        return (
            <>

                <TexisionDialog
                    key={"business-unit-dialog-" + this.state.unitToEdit?.id}
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showCreateOrEditDialog}
                    style={{}}
                    titleId={titleId}
                    size="lg"
                    content={<BusinessUnitPanel
                        unit={this.state.unitToEdit}
                        onClose={() => this.setState({showCreateOrEditDialog: false, unitToEdit: null})}/>
                    }/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDeleteDialog}
                    titleId="businessUnit.deleteDialog.title"
                    subtitleId="businessUnit.deleteDialog.text"
                    cancelId="commons.no.button"
                    actionId="commons.yes.button"
                    onCancel={() => this.setState({showDeleteDialog: false, businessUnitToDelete: null})}
                    onAction={() => this.deleteUnit()}/>

                <Typography variant="h1" component="h1">
                    <FormattedMessage id="businessUnit.overview.h1"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id={readOnly ? "businessUnit.overview.readOnly.h2" : "businessUnit.overview.h2"}/>
                </Typography>

                <SimpleTextCard show={readOnly && !units?.length} textId="businessUnit.overview.readOnly.noUnits"/>

                <Grid container direction="row" spacing={3}>

                    {units?.map((unit) => {
                        return (
                            <React.Fragment key={unit.id}>
                                <Grid item>
                                    <BusinessUnitPaper
                                        name={unit.name}
                                        imageUrl={unit.imageVo?.image?.url}
                                        businessUnit={unit}
                                        disabled={readOnly}
                                        handleDelete={this.handleDeleteBusinessUnit}
                                        handleEdit={this.handleEditBusinessUnit}/>
                                </Grid>
                            </React.Fragment>
                        );
                    })}

                    {!readOnly && <Grid item xs={3}>
                        <BusinessUnitPaperNew
                            disabled={units && units.length >= MAX_BUSINESS_UNIT_COUNT}
                            disabledTooltip={this.props.intl.formatMessage({id: "businessUnit.create.disabled.tooltip"}, {maxCount: MAX_BUSINESS_UNIT_COUNT})}
                            handleCreate={this.handleCreateBusinessUnit}/>
                    </Grid>}

                </Grid>

            </>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(BusinessUnitOverview)));
