import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {GeneralContext} from "../contexts/GeneralContext";
import {Grid, Typography} from "@mui/material";
import {formatIsoDate} from "../../util/DocumentUtil";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import "../../css/OperationEvent.css";
import {convertStringToDate} from "../../util/DateUtil";

class OperationEventCard extends Component {

    static contextType = GeneralContext;

    render() {
        const operationEvent = this.props.operationEvent;
        const name = operationEvent.event.name;
        const type = this.props.intl.formatMessage({id: "licenses.eventType.short." + operationEvent.eventType});
        const date = formatIsoDate(this.props.intl, convertStringToDate(operationEvent.event.start_time));
        const time = formatIsoDate(this.props.intl, convertStringToDate(operationEvent.event.start_time), "HH:mm") + " - "
            + formatIsoDate(this.props.intl, convertStringToDate(operationEvent.event.end_time), "HH:mm") + " Uhr";
        const status= this.props.intl.formatMessage({id: "licenses.eventStatus.short." + operationEvent.event.status}).toUpperCase();

        return (
            <Grid className="eventCard" item xs={5}>
                <Grid className="eventContainer" container spacing={1}>
                    <Grid item className="icon-item">
                        <CalendarMonthIcon/>
                    </Grid>
                    <Grid item xs>
                        <Typography sx={{ fontSize: 14}} component="div">
                            {type}
                        </Typography>
                        <Typography sx={{ fontSize: 12}} color="text.secondary">
                            {name} - {status}
                        </Typography>
                        <table className="eventTable">
                            <tr>
                                <td>{this.props.intl.formatMessage({id: "licenses.event.date"})}:</td>
                                <td><b>{date}</b></td>
                            </tr>
                            <tr>
                                <td> {this.props.intl.formatMessage({id: "licenses.event.time"})}:</td>
                                <td><b>{time}</b></td>
                            </tr>
                        </table>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
export default injectIntl(OperationEventCard);