import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Divider, Button, Grid} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

class FooterWrapper extends Component {

    render() {
        return (
            <Grid container>

                <Grid item xs={12} style={{height: 500, overflowY: "auto", overflowX: "hidden"}}>
                    {this.props.children}
                </Grid>

                <Divider style={{width: "100%", marginTop: "1rem"}}/>

                <Grid item xs={12}>
                    <Grid container alignItems="flex-end" spacing={2} style={{marginTop: 20}}>

                        {this.props.showBackButton && <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{height: 36}}
                                disabled={this.props.isLoading}
                                onClick={() => this.props.onBack()}>
                                <FormattedMessage id="commons.back.button"/>
                            </Button>
                        </Grid>}

                        <Grid item>
                            <Button
                                color="primary"
                                variant="outlined"
                                disabled={this.props.isLoading}
                                onClick={() => this.props.onCancel()}>
                                <FormattedMessage id="commons.cancel.button"/>
                            </Button>
                        </Grid>
                        <Grid item xs/>

                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{height: 36}}
                                disabled={this.props.invalidData || this.props.isLoading}
                                onClick={() => this.props.onContinue()}>
                                <FormattedMessage id={this.props.continueButtonTitle}/>
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>
        );
    }
}

FooterWrapper.defaultProps = {
    continueButtonTitle: "commons.continue.button",
    onBack: () => null,
    showBackButton: true,
    isLoading: false
}

FooterWrapper.propTypes = {
    invalidData: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showBackButton: PropTypes.bool.isRequired,
    continueButtonTitle: PropTypes.string.isRequired,
    onBack: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default FooterWrapper;
