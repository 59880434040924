import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {CATEGORY, SUB_CATEGORY, ARTICLE_CATEGORY, USER_DEFINED} from '../../../../util/Constants';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import '../../../../css/ArticleOffsetting.css';
import Select from '@material-ui/core/Select';
import {GeneralContext} from "../../../contexts/GeneralContext";


class ArticleOffsettingEntry extends Component {

    static contextType = GeneralContext;

    handleChanged = (e) => {
        this.props.handleOffsettingChanged(this.props.node, e.target.value);
    }

    render() {
        let label = null;
        let className = "";
        let offsettingModels = this.context.appData.offsettingModels;

        switch (this.props.node.type) {
            case CATEGORY:
                className = "category pl-2 pt-1 pb-1";
                label =
                    <Grid item xs={5} className={className}>
                        <Typography variant="h6">
                            {this.props.intl.formatMessage({id: "constants.Category." + this.props.node.label})}
                        </Typography>
                    </Grid>
                break;
            case SUB_CATEGORY:
                className = "subCategory pl-2 bold";
                label =
                    <Grid item xs={5} className={className}>
                        <Typography variant="h4">
                            {this.props.intl.formatMessage({id: "constants.SubCategory." + this.props.node.label})}
                        </Typography>
                    </Grid>
                break;
            case ARTICLE_CATEGORY:
                className = "articleCategory pl-2";
                label =
                    <Grid item xs={5} className={className}>
                        <Typography className="pl-2">
                            {this.props.intl.formatMessage({id: "constants.ArticleCategory." + this.props.node.label})}
                        </Typography>
                    </Grid>
                offsettingModels = offsettingModels.filter(e => e !== USER_DEFINED);
                break;
            default:
                break;
        }
        return (
            <div>
                <Grid container alignItems="center"
                        style={this.props.node.type === CATEGORY ?
                            {marginBottom: 0,  marginTop: "0"} : {marginBottom: "0", marginTop: "0"}}>
                    {label}
                    <Grid item xs={7} className={className}>
                        <Select
                            id={this.props.node.internalId.toString()}
                            native
                            disableUnderline
                            value={this.props.node.offsetting}
                            onChange={(e) => this.handleChanged(e)}
                            disabled={this.props.readOnly}>
                            {offsettingModels.map(model => {
                                return <option key={model} disabled={model === USER_DEFINED} value={model}>
                                        {this.props.intl.formatMessage({id: "constants.ArticleOffsetting." + model})}
                                        </option>
                            })};
                            </Select>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default injectIntl(ArticleOffsettingEntry);
