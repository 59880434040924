import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import {GeneralContext} from "../contexts/GeneralContext";
import TenderTile from "./TenderTile";
import {
    BUSINESS_UNIT_OVERVIEW_ROUTE,
    CONTRACT_ROUTE,
    CUSTOMER_ARTICLES_ROUTE,
    LAST_OPERATION_ID,
    LAST_PROJECT_ID,
    LOGISTICS_ROUTE,
    OPERATING_RESOURCES_ROUTE,
    OFFSETTING_ROUTE,
    PROCEDURE_CONDITIONS_ROUTE,
    PROJECT_STATUS_IN_PROGRESS,
    PROJECT_TASKS,
    RENTAL_LINEN_ROUTE,
    RESIDENTS_LAUNDRY_ROUTE,
    SPECIAL_SERVICES_ROUTE,
    PROJECT_OVERVIEW_ROUTE,
    COCKPIT_CONTRACT_DOCUMENTS_ROUTE,
    PROJECT_STATUS_IN_EDITING,
    PROJECT_STATUS_IN_POST_PROCESSING,
    DIALOG_TYPE_WARNING,
    MAX_BUSINESS_UNIT_COUNT,
    DIALOG_TYPE_INFO,
    COCKPIT_PRICE_SHEET_ROUTE, INVOLVED_BIDDERS_ROUTE, PROJECT_STATUS_IS_PUBLISHED
} from "../../util/Constants";
import ProjectCreateOrUpdateDialog from "../project/ProjectCreateOrUpdateDialog";
import ProjectDeleteDialog from "../project/ProjectDeleteDialog";
import BusinessUnitTiles from "./BusinessUnitTiles";
import {Grid, Typography} from "@material-ui/core";
import ValidationButton from "./ValidationButton";
import {Tooltip} from "@mui/material";
import {mayCompleteTender} from "../../util/ValidationUtil";
import Divider from "@material-ui/core/Divider";
import '../../css/Validation.css';
import {isOfferUser} from "../../services/UserService";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import {withSnackbar} from "notistack";
import BusinessUnitPanel from "../businessUnit/baseData/BusinessUnitPanel";
import {isProjectTaskCompleted} from "../../services/ProjectService";
import {getAllSucceededPayments} from "../../services/PaymentService";
import {isCockpit, isTender} from "../../util/Util";
import {loadProfiles} from "../../services/ProfileService";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import GroupIcon from "@mui/icons-material/Group";
import Spacer from "../uiLibrary/Spacer";

class Dashboard extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            allProfiles: [],
            showCreateOrUpdateDialog: false,
            showDeleteProjectDialog: false,
            showProjectPublishedDialog: false,
            showProjectUnDeletableDialog: false,
            // operation attributes
            isPrivate: true,
            profileIds: [],
            showUnitDialog: false,
            unitToEdit: null
        }
    }

    async componentDidMount() {
        let allProfiles = await loadProfiles(this.context);
        this.setState({allProfiles});
    }

    setActiveBusinessUnit = (businessUnitId) => {
        if (businessUnitId) {
            this.context.setActiveUnitId(businessUnitId);
        }
    }

    onTileClicked = (projectTask, {projectId, businessUnitId, deliveryAddressId}) => {

        // for cockpit setting the project id is necessary because there are two projects
        // clickable for each operation (active and working project)
        if (isCockpit() && projectId !== null && projectId !== undefined) {
            this.context.setActiveProjectId(projectId);
            localStorage.setItem(LAST_PROJECT_ID, projectId);
        }
        if (businessUnitId !== null && businessUnitId !== undefined) {
            this.context.setActiveUnitId(businessUnitId);
        }

        switch (projectTask) {
            case PROJECT_TASKS.PROJECT:
                this.props.history.push(PROJECT_OVERVIEW_ROUTE);
                break;
            case PROJECT_TASKS.BUSINESS_UNIT:
                this.props.history.push(BUSINESS_UNIT_OVERVIEW_ROUTE);
                break;
            case PROJECT_TASKS.RENTAL_LINEN:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push(RENTAL_LINEN_ROUTE);
                break;
            case PROJECT_TASKS.CUSTOMER_ARTICLES:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push(CUSTOMER_ARTICLES_ROUTE);
                break;
            case PROJECT_TASKS.RESIDENTS_LAUNDRY:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push(RESIDENTS_LAUNDRY_ROUTE);
                break;
            case PROJECT_TASKS.OPERATING_RESOURCES:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push(OPERATING_RESOURCES_ROUTE);
                break;
            case PROJECT_TASKS.SPECIAL_SERVICES:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push(SPECIAL_SERVICES_ROUTE);
                break;
            case PROJECT_TASKS.OFFSETTING:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push(OFFSETTING_ROUTE);
                break;
            case PROJECT_TASKS.LOGISTIC:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push({pathname: LOGISTICS_ROUTE, state: {addressId: deliveryAddressId}});
                break;
            case PROJECT_TASKS.DELIVERY_ADDRESS:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push({pathname: LOGISTICS_ROUTE, state: {addressId: deliveryAddressId}});
                break;
            case PROJECT_TASKS.PICKUP_ADDRESS:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push({pathname: LOGISTICS_ROUTE, state: {addressId: deliveryAddressId}});
                break;
            case PROJECT_TASKS.ARTICLE_ASSIGNMENT:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push({pathname: LOGISTICS_ROUTE, state: {addressId: deliveryAddressId}});
                break;
            case PROJECT_TASKS.DELIVERY_QUANTITY:
                this.setActiveBusinessUnit(businessUnitId);
                this.props.history.push({pathname: LOGISTICS_ROUTE, state: {addressId: deliveryAddressId}});
                break;
            case PROJECT_TASKS.CONTRACT:
                this.props.history.push(CONTRACT_ROUTE);
                break;
            case PROJECT_TASKS.PROCEDURE:
                this.props.history.push(PROCEDURE_CONDITIONS_ROUTE);
                break;
            case PROJECT_TASKS.BIDDERS:
                this.props.history.push(INVOLVED_BIDDERS_ROUTE);
                break;
            default:
                break;
        }
    }

    onCreateProject = () => {
        if (isCockpit()) {
            this.props.history.push(PROJECT_OVERVIEW_ROUTE);
        } else {
            this.setState({showCreateOrUpdateDialog: true});
        }
    }

    handleProjectDelete = (project) => {
        const operation = this.context.appData.operations?.find(o => o.activeProject?.id === project.id || o.workingProject?.id === project.id);
        const isDirectCockpitProject = !isTender() && !isOfferUser(operation?.bidder);
        if ((isTender() && [PROJECT_STATUS_IN_PROGRESS, PROJECT_STATUS_IN_EDITING].includes(project?.status))
            || (isDirectCockpitProject && project?.status === PROJECT_STATUS_IN_POST_PROCESSING)) {
            this.setState({projectToDelete: project, showDeleteProjectDialog: true})
        } else if (isTender()) {
            this.setState({showProjectPublishedDialog: true});
        } else {
            this.setState({showProjectUnDeletableDialog: true});
        }
    }

    onCreateBusinessUnit = (operation) => {
        const projectId = operation?.workingProject ? operation.workingProject.id : operation.activeProject?.id;
        this.context.setActiveProjectId(projectId);
        this.setState({showUnitDialog: true, unitToEdit: null});
    }

    render() {
        const isBidder = isOfferUser(this.context.currentUser);
        const businessUnitDialogTitleId = this.state.unitToEdit ? "businessUnit.edit.h1" : "businessUnit.create.h1";

        const currentOperation = this.context.appData.operations?.find(o => o.id.toString() === localStorage.getItem(LAST_OPERATION_ID));
        const project = currentOperation?.workingProject ?? currentOperation?.activeProject;
        const hasBidder = !isTender() && isOfferUser(currentOperation?.bidder);
        const businessUnits = isTender() ? project?.businessUnits?.units : project?.businessUnits?.units;
        const mayDeleteProject = (isTender() && [PROJECT_STATUS_IN_PROGRESS, PROJECT_STATUS_IN_EDITING].includes(project?.status))
            || (!isTender() && !hasBidder && project?.status === PROJECT_STATUS_IN_POST_PROCESSING);
        const hasLicenses = getAllSucceededPayments(this.context)?.length;

        let subtitle;
        let createTitle;
        let validationButtonText;
        let invalidateButtonText;
        let conditionsTitle;

         switch (isTender()) {
            case true:
                conditionsTitle = "validation.contractAndProcedure.title";
                validationButtonText = "validation.tender.validate.button";
                invalidateButtonText = "validation.tender.edit.button";
                createTitle = "tender.dialog.title.create";
                if (currentOperation) {
                    subtitle = "tender.dashboard.subtitle";
                } else {
                    subtitle = "tender.dashboard.noOperation.subtitle";
                }
                break;
            case false:
            default:
                conditionsTitle = hasBidder ? "validation.contractAndPriceSheet.title" : "validation.contractDocumentsAndPriceSheet.title";
                validationButtonText = "validation.cockpit.validate.button";
                invalidateButtonText = "validation.cockpit.edit.button";
                createTitle = "cockpit.dialog.title.create";
                if (!currentOperation && !isBidder) {
                    subtitle = "cockpit.dashboard.noOperation.subtitle";
                } else if (!currentOperation && isBidder) {
                    subtitle = "cockpit.dashboard.bidder.subtitle";
                } else if (currentOperation.workingProject && currentOperation.editable) {
                    subtitle = "cockpit.dashboard.editableWorkingProject.subtitle";
                } else if (currentOperation.workingProject && !currentOperation.editable) {
                    subtitle = "cockpit.dashboard.nonEditableWorkingProject.subtitle";
                } else if (!currentOperation.workingProject) {
                    subtitle = "cockpit.dashboard.activeProject.subtitle";
                }
                break;
        }

        return (
            <>
                <ProjectCreateOrUpdateDialog
                    key={"create-operation-"+currentOperation?.version}
                    operation={currentOperation}
                    showDialog={this.state.showCreateOrUpdateDialog}
                    allProfiles={this.state.allProfiles}
                    project={project}
                    profileIds={this.state.profileIds}
                    closeDialog={() => this.setState({showCreateOrUpdateDialog: false})}/>

                <ProjectDeleteDialog
                    key={"delete-operation-"+currentOperation?.id}
                    showDeleteProjectDialog={this.state.showDeleteProjectDialog}
                    projectToDelete={project}
                    hasLicenses={hasLicenses}
                    closeDeleteDialog={() => this.setState({showDeleteProjectDialog: false})}/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showProjectPublishedDialog}
                    cancelId="commons.close.label"
                    onCancel={() => this.setState({showProjectPublishedDialog: false})}
                    titleId="tender.overview.published.title"
                    subtitleId="tender.overview.published.subtitle"/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showProjectUnDeletableDialog}
                    cancelId="commons.close.label"
                    onCancel={() => this.setState({showProjectUnDeletableDialog: false})}
                    titleId="project.overview.unDeletable.title"
                    subtitleId="project.overview.unDeletable.subtitle"/>

                <TexisionDialog
                    key={"business-unit-dialog-" + this.state.unitToEdit?.id}
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showUnitDialog}
                    style={{}}
                    titleId={businessUnitDialogTitleId}
                    size="lg"
                    content={<BusinessUnitPanel
                        unit={this.state.unitToEdit}
                        onClose={() => this.setState({showUnitDialog: false, unitToEdit: null})}/>
                    }/>

                <Grid container>
                    <Grid item xs>
                        <Typography variant="h1">
                            <FormattedMessage id="tender.dashboard.title"/>
                        </Typography>
                    </Grid>
                    <Grid item>
                        {!!currentOperation && <Tooltip
                            placement="bottom-start"
                            title={mayCompleteTender(project?.id, this.context.appData.validationMap)
                                ? ""
                                : this.props.intl.formatMessage({id: "validation.tender.incomplete.tooltip"})}>
                            <div style={{marginBottom: 30, width: "25%"}}>
                                <ValidationButton
                                    completed={isProjectTaskCompleted(this.context, PROJECT_TASKS.PROJECT, project?.id)}
                                    projectId={project?.id}
                                    disabled={!mayCompleteTender(project?.id, this.context.appData.validationMap)}
                                    projectTask={PROJECT_TASKS.PROJECT}
                                    objectId={project?.id}
                                    completedButtonText={invalidateButtonText}
                                    incompletedButtonText={validationButtonText}/>
                            </div>
                        </Tooltip>}
                    </Grid>
                </Grid>

                <Typography variant="subtitle1">
                    <FormattedMessage id={subtitle}/>
                </Typography>

                {(!isBidder || currentOperation) && <Grid container alignItems="stretch" wrap="nowrap">

                    <div className={"dashboardColumnItem"}>
                        <div>
                            <div className={"dashboardInnerItem"}>
                                <Typography variant="h3" className={"dashboardColumnTitle"}>
                                    <FormattedMessage id={isTender() ? "validation.tender.title" : "validation.cockpit.title"}/>
                                </Typography>
                                <TenderTile
                                    projectTask={PROJECT_TASKS.PROJECT}
                                    objectId={project?.id}
                                    concreteTitle={project?.name ?? null}
                                    title={currentOperation ? null : createTitle}
                                    onCreate={this.onCreateProject}
                                    mayCreate={!currentOperation}
                                    onEdit={() => this.setState({showCreateOrUpdateDialog: true})}
                                    mayEdit={!!currentOperation}
                                    onDelete={() => this.handleProjectDelete(project)}
                                    mayDelete={!!currentOperation && mayDeleteProject}
                                    onTileClicked={() => this.onTileClicked(PROJECT_TASKS.PROJECT, {})}/>
                            </div>
                            <Spacer size={20}/>
                            {project?.status === PROJECT_STATUS_IS_PUBLISHED &&
                                <div className={"dashboardInnerItem"}>
                                    <Typography variant="h3" className={"dashboardColumnTitle"}>
                                        <FormattedMessage id={isTender() ? "validation.bidder.title" : "validation.cockpit.title"}/>
                                    </Typography>
                                    <TenderTile
                                        IconComponent={GroupIcon}
                                        title="validation.involvedBidder.title"
                                        onTileClicked={() => this.onTileClicked(PROJECT_TASKS.BIDDERS, {})}
                                    />
                                </div>}
                        </div>
                    </div>


                    {currentOperation && <div className={"dashboardGridItem"}>
                        <Typography variant="h3" className={"dashboardColumnTitle"}>
                            <FormattedMessage id="validation.units.title"/>
                        </Typography>

                        <Grid item>

                            <Grid container>

                                {!!businessUnits?.length && <Grid item xs={12}>

                                    {businessUnits.map(unit => <>
                                        <BusinessUnitTiles
                                            key={"business-unit-tile-" + unit.id}
                                            businessUnit={unit}
                                            projectId={project?.id}
                                            onTileClicked={(projectTask, data) => this.onTileClicked(projectTask, data)}/>

                                        <Divider style={{width: "100%", marginTop: 40, marginBottom: 30}}/>
                                    </>)}

                                </Grid>}

                                {!!currentOperation && !isProjectTaskCompleted(this.context, PROJECT_TASKS.PROJECT, project?.id)
                                    && <Grid item xs={12}>

                                        <TenderTile
                                            title="businessUnit.create.button"
                                            IconComponent={LocationCityIcon}
                                            onCreate={() => this.onCreateBusinessUnit(currentOperation)}
                                            createDisabled={businessUnits && businessUnits.length >= MAX_BUSINESS_UNIT_COUNT}
                                            createDisabledTooltip={this.props.intl.formatMessage({id: "businessUnit.create.disabled.tooltip"}, {maxCount: MAX_BUSINESS_UNIT_COUNT})}
                                            mayCreate={true}/>

                                    </Grid>}

                            </Grid>
                        </Grid>
                    </div>}

                    {currentOperation && <div className={"dashboardGridItem"}>

                        <Typography variant="h3" className={"dashboardColumnTitle"}>
                            <FormattedMessage id={conditionsTitle}/>
                        </Typography>

                        {!isTender() && !hasBidder && <TenderTile
                            IconComponent={MenuBookIcon}
                            concreteTitle={this.props.intl.formatMessage({id: "validation.cockpitContract.title"})}
                            onTileClicked={() => this.props.history.push(COCKPIT_CONTRACT_DOCUMENTS_ROUTE)}/>}

                        <TenderTile
                            projectTask={PROJECT_TASKS.CONTRACT}
                            objectId={project?.id}
                            onTileClicked={() => this.onTileClicked(PROJECT_TASKS.CONTRACT, {projectId: project?.id})}/>

                        {isTender() && <TenderTile
                            projectTask={PROJECT_TASKS.PROCEDURE}
                            objectId={project?.id}
                            onTileClicked={() => this.onTileClicked(PROJECT_TASKS.PROCEDURE, {projectId: project?.id})}/>}

                        {!isTender() && <TenderTile
                            IconComponent={RequestPageIcon}
                            concreteTitle={this.props.intl.formatMessage({id: "validation.priceSheet.title"})}
                            onTileClicked={() => this.props.history.push(COCKPIT_PRICE_SHEET_ROUTE)}/>}

                    </div>}

                </Grid>}

            </>
        );
    }
}

export default withRouter(withSnackbar(injectIntl(Dashboard)));
