import { Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { bodyBackgroundColor, headerColor, texisionOrange, white } from "../../../util/ColorTheme";
import defaultUserIcon from '../../../resources/default_user_icon.png';

import '../../../css/OfferComparison.css';
import { address } from "../../../util/Util";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { OFFER_STATUS_ACCEPTED } from "../../../util/Constants";

const OfferResultTable = ({result, offers}) => {

    const intl = useIntl();

    if (!result?.offerResults || result.offerResults.length === 0 || !offers || offers.length === 0)
    {
        return <div/>;
    }

    const awardFlag = (award) => {
        return (
            <Grid item key={award}>
                <div 
                    className="bestPriceFlag" 
                    style={{backgroundColor: headerColor, marginBottom: 5, color: white}}
                >
                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                        <FormattedMessage id={"constants.OfferBadge." + award}/>
                    </div>
                </div>
            </Grid>
        );
    }

    const getAwards = (offerId) => {
        let awards = [];
        if (result.offerBadges) {
            for (let [badge, id] of Object.entries(result.offerBadges)) {
                if (id === offerId) {
                    awards = [...awards, badge];
                }
            }
        }
        return (
            <Grid container justifyContent="center" alignItems="center">
                {awards.map(award => <>
                    {awardFlag(award)}
                    <div style={{width: 10}}/>
                </>)}
            </Grid>
        );
    }

    const categorieEvaluations = [...new Set(result.offerResults[0]
            ?.categoryEvaluations
        ?.map(e => {return {category: e.category, weighting: e.weighting};})
        ?.sort((a,b) => intl.formatMessage(
            {id: "constants.RatingCategory." + a.category})
        .localeCompare(intl.formatMessage(
            {id: "constants.RatingCategory." + b.category}))) 
        ?? [])];

    const border = "1px solid rgba(224, 224, 224, 1)";

    return (
        <>

            <Typography variant="subtitle1">
                <FormattedMessage id="offerResult.ratingsComparison.subtitle"/>
            </Typography>
    
            <Card style={{marginBottom: 50}}>
    
                <TableContainer component={Paper} className="resultTableContainer">
    
                    <Table aria-label="offer-result-table">
    
                        <TableHead 
                            style={{backgroundColor: bodyBackgroundColor, borderTop: border}}
                        >
    
                            <TableRow>
    
                                <TableCell 
                                    style={{
                                        fontWeight: "bold", 
                                        borderRight: border, 
                                        width: "350px", 
                                        minWidth: "350px"
                                    }}
                                >
                                    <FormattedMessage id="offerResult.table.award.title"/>
                                </TableCell>
    
                                {result.offerResults.sort((a,b) => a.rank - b.rank).map(r => (
                                    <TableCell 
                                        key={"result-award-" + r.offerId} 
                                        style={{borderRight: border}}
                                    >
                                        {getAwards(r.offerId)}
                                    </TableCell>
                                ))}
    
                            </TableRow>
    
    
                            <TableRow>
    
                                <TableCell style={{fontWeight: "bold", borderRight: border}}>
                                    <FormattedMessage id="offerResult.table.rank.title"/>
                                </TableCell>
    
                                {result.offerResults.sort((a,b) => a.rank - b.rank).map(r => (
                                    <TableCell 
                                        key={"result-rank-" + r.offerId}
                                        style={{borderRight: border, textAlign: "center"}}>
        
                                        <Grid 
                                            container 
                                            wrap="nowrap" 
                                            justifyContent="center" 
                                            alignItems="center"
                                        >
        
                                            <Typography variant="h1" style={{margin: 0}}>
                                                {r.rank ? (r.rank + ".") : "-"}
                                            </Typography>
        
                                            {offers.find(o => r.offerId === o.id)?.status 
                                                === OFFER_STATUS_ACCEPTED && (
                                                    <Tooltip 
                                                        title={intl.formatMessage(
                                                            {id: "offerResult.winnerIcon.tooltip"})}
                                                    >
                                                        <EmojiEventsIcon 
                                                            style={{
                                                                marginLeft: 20, 
                                                                color: texisionOrange
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
        
                                        </Grid>
        
                                    </TableCell>
                                ))}
    
                            </TableRow>
    
    
                            <TableRow>
    
                                <TableCell style={{fontWeight: "bold", borderRight: border}}>
                                    <FormattedMessage id="offerResult.table.category.title"/>/
                                    <FormattedMessage id="offerResult.table.company.title"/>
                                </TableCell>
    
                                {result.offerResults.sort((a,b) => a.rank - b.rank).map(r => (
                                    <TableCell 
                                        key={"result-data-" + r.offerId}
                                        style={{fontWeight: "bold", borderRight: border}}>
        
                                        <Grid 
                                            container 
                                            wrap="nowrap" 
                                            alignItems="center" 
                                            justifyContent="center" 
                                            style={{textAlign: "center"}}
                                        >
        
                                            <Grid item>
                                                <img 
                                                    src={offers.find(o => o.id === r.offerId)?.user
                                                        ?.imageVo?.image?.url ?? defaultUserIcon} 
                                                    className="profileImage" 
                                                    alt=""/>
                                            </Grid>
        
                                            <Grid 
                                                item 
                                                xs 
                                                style={{whiteSpace: "pre-line", paddingRight: 60}}
                                            >
        
                                                <Typography variant="h3">
                                                    {offers.find(o => o.id === r.offerId)?.user?.company 
                                                        + "\n"}
                                                </Typography>
        
                                                {address(intl, offers.find(o => o.id === r.offerId))}
                                            </Grid>
        
                                        </Grid>
        
                                    </TableCell>
                                ))}
    
                            </TableRow>
    
    
                            <TableRow>
    
                                <TableCell style={{borderRight: border}}/>
                                
                                {result.offerResults.sort((a,b) => a.rank - b.rank).map(r => (
                                    <TableCell 
                                        key={"result-weighted-titles-" + r.offerId}
                                        style={{fontWeight: "bold", borderRight: border}}>
        
                                        <Grid container wrap="nowrap" style={{textAlign: "center"}}>
        
                                            <Grid item xs={6}>
                                                <FormattedMessage 
                                                    id="offerResult.table.unweighted.title"
                                                />
                                            </Grid>
        
                                            <Grid item xs={6}>
                                                <FormattedMessage 
                                                    id="offerResult.table.weighted.title"
                                                />
                                            </Grid>
        
                                        </Grid>
        
                                    </TableCell>
                                ))}
    
                            </TableRow>
    
                        </TableHead>
    
                        <TableBody>
    
                            {categorieEvaluations.map(ev => (
                                <TableRow key={"result-category-title-" + ev.category}>
    
                                    <TableCell style={{fontWeight: "bold", borderRight: border}}>
                                        {intl.formatMessage(
                                            {id: "constants.RatingCategory." + ev.category}) 
                                            + " - " + ev.weighting + " %"}
                                    </TableCell>
        
                                    {result.offerResults.sort((a,b) => a.rank - b.rank).map(r => (
                                        <TableCell 
                                            key={"result-sum-" + r.offerId}
                                            style={{borderRight: border}}>
            
                                            <Grid 
                                                container 
                                                wrap="nowrap" 
                                                style={{textAlign: "center"}}
                                            >
            
                                                <Grid item xs={6}>
                                                    {
                                                        r.categoryEvaluations
                                                            ?.find(e => e.category === ev.category)
                                                            ?.normalizedSum ?? 0
                                                    }
                                                </Grid>
            
                                                <Grid item xs={6}>
                                                    {
                                                        r.categoryEvaluations
                                                            ?.find(e => e.category === ev.category)
                                                            ?.weightedSum ?? 0
                                                    }
                                                </Grid>
            
                                            </Grid>
            
                                        </TableCell>
                                    ))}
        
                                </TableRow>
                            ))}
    
    
                            <TableRow style={{backgroundColor: bodyBackgroundColor}}>
    
                                <TableCell style={{fontWeight: "bold", borderRight: border}}>
                                    <FormattedMessage id="offerResult.table.result.title"/>
                                </TableCell>
                                
                                {result.offerResults.sort((a,b) => a.rank - b.rank).map(r => (
                                    <TableCell 
                                        key={"result-weighted-sum-" + r.offerId}
                                        style={{fontWeight: "bold", borderRight: border}}>
        
                                        <Grid container wrap="nowrap" style={{textAlign: "center"}}>
        
                                            <Grid item xs={6}/>
        
                                            <Grid item xs={6}>{r.weightedSum}</Grid>
        
                                        </Grid>
                                        
                                    </TableCell>
                                ))}
    
                            </TableRow>
    
                        </TableBody>
                    </Table>
                </TableContainer>
    
            </Card>
    
        </>
    );
}

export {
    OfferResultTable
}
