import React, {Component} from 'react';
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import {Box, Button, DialogContentText, Grid, TextField, Typography} from '@material-ui/core';
import {deleteAsync, getAsync, login} from '../../services/BackendService';
import {Link, withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from 'react-intl';
import {createErrorMessage} from '../../util/Util';
import {withSnackbar} from 'notistack';
import {
    ACCOUNT_ACTIVATE_ROUTE, ACCOUNT_DELETE_ROUTE,
    COOKIE_BANNER_SET, DIALOG_TYPE_ERROR,
    DIALOG_TYPE_INFO,
    DIALOG_TYPE_WARNING,
    FORGOT_PASSWORD_ROUTE,
    PRIVACY_ROUTE,
    REGISTRATION_ROUTE,
} from '../../util/Constants';
import TexisionContainer from "../uiLibrary/TexisionContainer";
import TexisionDialog from '../uiLibrary/TexisionDialog';
import {Role} from '../../services/UserService';
import '../../css/Login.css';
import {GeneralContext} from "../contexts/GeneralContext";
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


class Login extends Component {

static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            mail: props.location?.state?.mail ?? "",
            password: "",
            loginError: false,
            showTermsOfUse: false,
            showCookieBanner: !props.cookies.get(COOKIE_BANNER_SET),
            passwordVisible: false,
            showUnsubscribeDialog: false,
            showInvalidTokenDialog: false,
            showUnsubscribeErrorDialog: false,
            showUnsubscribeSuccessDialog: false,
            newsletterDisabledReason: "",

            // for account activation
            showRegistrationSuccessDialog: props.location?.state?.fromRegistration ?? false,
            showLoginErrorDialog: false,
            showActivationSuccessDialog: false,
            showActivationErrorDialog: false,
            showDeletionSuccessDialog: false,
            showDeletionErrorDialog: false,
        }
    }

    async componentDidMount() {
        const requestParameters = new URLSearchParams(window.location.search);
        const unsubscribeToken = requestParameters.get("unsubscribe");
        if (unsubscribeToken) {
            this.setState({showUnsubscribeDialog: true});
        }
        const token = requestParameters.get("token");
        if (token && window.location.pathname === ACCOUNT_ACTIVATE_ROUTE) {
            await this.sendActivationToken(token);
        } else if (token && window.location.pathname === ACCOUNT_DELETE_ROUTE) {
            await this.sendDeletionToken(token);
        }
    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired  
    };

    sendActivationToken = async(token) => {
        const response = await getAsync("/activate?token=" + token, false);
        if (response?.status === 200) {
            this.setState({showActivationSuccessDialog: true});
        } else {
            this.setState({showActivationErrorDialog: true});
        }
    }

    sendDeletionToken = async(token) => {
        const response = await deleteAsync("/delete?token=" + token, false);
        if (response?.status === 200) {
            this.setState({showDeletionSuccessDialog: true});
        } else {
            this.setState({showDeletionErrorDialog: true});
        }
    }

    handleLogin = async(event) => {
        event.preventDefault();
        const response = await login(this.state.mail, this.state.password);
        if (response?.status === 200) {
            await this.context.login(response.data.token);
        } else if (response?.status === 401) {
            createErrorMessage(this.props.intl.formatMessage({id: "login.panel.message.error"}), this.props);
            this.setState({loginError: true});
        } else if (response?.status === 423) {
            this.setState({showLoginErrorDialog: true});
        }
    }

    setCookieBannerChecked = () => {
        let date = new Date();
        date.setTime(date.getTime() + (365*24*60*60*1000));
        const {cookies} = this.props;
        cookies.set(COOKIE_BANNER_SET, true, {path: "/", expires: date, sameSite: "strict"}); 
        this.setState({showCookieBanner: false});
    }

    goToRegistration = (inputRole) => {
        this.props.history.push({
            pathname: REGISTRATION_ROUTE,
            state: {role: inputRole}
        });
    }

    onUnsubscribeCancel = () => {
        this.setState({showUnsubscribeDialog: false});
        this.deleteUnsubscribeRequestParameter();
    }

    onUnsubscribeConfirm = async() => {
        const requestParameters = new URLSearchParams(window.location.search);
        const unsubscribeTokenEncoded = requestParameters.get("unsubscribe");
        const unsubscribeTokenDecoded = Buffer.from(unsubscribeTokenEncoded, "base64").toString("utf-8");
        // request without credentials as endpoint is open
        const unsubscribeResponse = await getAsync("/unsubscribe?token=" + unsubscribeTokenDecoded + "&reason=" + this.state.newsletterDisabledReason, false);
        if (unsubscribeResponse?.status === 200) {
            this.setState({showUnsubscribeSuccessDialog: true, showUnsubscribeDialog: false});
        } else if (unsubscribeResponse?.status === 401) {
            this.setState({showInvalidTokenDialog: true, showUnsubscribeDialog: false});
        } else {
            this.setState({showUnsubscribeErrorDialog: true, showUnsubscribeDialog: false});
        }
        this.deleteUnsubscribeRequestParameter();
    }

    deleteUnsubscribeRequestParameter = () => {
        const requestParameters = new URLSearchParams(window.location.search);
        requestParameters.delete("unsubscribe");
        this.props.history.replace({search: requestParameters.toString()});
    }

    render() {
        let opts = {};
        if (this.state.loginError) {
            opts['error'] = 'error';
        }

        return (
            <TexisionContainer content={
                <div>

                    <TexisionDialog 
                        type={DIALOG_TYPE_INFO}
                        open={this.state.showCookieBanner}
                        titleId="login.cookieBanner.title"
                        actionId="commons.okay.button"
                        onAction={() => this.setCookieBannerChecked()}
                        content={<>

                            <DialogContentText id="alert-dialog-description-1">
                                <Typography color="textPrimary">
                                    <FormattedMessage id="login.cookieBanner.text"/>
                                </Typography>
                            </DialogContentText>

                            <Box justifyContent="center" display="flex" alignItems="center">
                                <Link to={{pathname: PRIVACY_ROUTE}} target="_blank" rel="noopener noreferrer">
                                    <FormattedMessage id="login.cookieBanner.link" />
                                </Link>
                            </Box>

                        </>}/>

                    <TexisionDialog 
                        type={DIALOG_TYPE_WARNING}
                        open={this.state.showUnsubscribeDialog}
                        titleId="login.unsubscribe.title"
                        subtitleId="login.unsubscribe.subtitle"
                        actionId="login.unsubscribe.button"
                        cancelId="commons.cancel.button"
                        content={
                            <TextField 
                                value={this.state.newsletterDisabledReason} 
                                onChange={(e) => this.setState({newsletterDisabledReason: e.target.value})}
                                variant="outlined"
                                fullWidth
                                placeholder={this.props.intl.formatMessage({id: "bidder.newsletter.unsubscribe.reason.placeholder"})}/>
                        }
                        onAction={() => this.onUnsubscribeConfirm()}
                        onCancel={() => this.onUnsubscribeCancel()}/>

                    <TexisionDialog 
                        type={DIALOG_TYPE_WARNING}
                        open={this.state.showInvalidTokenDialog}
                        titleId="login.unsubscribe.invalidToken.title"
                        subtitleId="login.unsubscribe.invalidToken.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showInvalidTokenDialog: false})}/>

                    <TexisionDialog 
                        type={DIALOG_TYPE_INFO}
                        open={this.state.showUnsubscribeSuccessDialog}
                        titleId="login.unsubscribe.success.title"
                        subtitleId="login.unsubscribe.success.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showUnsubscribeSuccessDialog: false})}/>

                    <TexisionDialog 
                        type={DIALOG_TYPE_WARNING}
                        open={this.state.showUnsubscribeErrorDialog}
                        titleId="login.unsubscribe.error.title"
                        subtitleId="login.unsubscribe.error.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showUnsubscribeErrorDialog: false})}/>

                    <TexisionDialog
                        type={DIALOG_TYPE_INFO}
                        open={this.state.showRegistrationSuccessDialog}
                        titleId="registration.successDialog.title"
                        subtitleId="registration.successDialog.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showRegistrationSuccessDialog: false})}/>

                    <TexisionDialog
                        type={DIALOG_TYPE_INFO}
                        open={this.state.showActivationSuccessDialog}
                        titleId="activation.successDialog.title"
                        subtitleId="activation.successDialog.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showActivationSuccessDialog: false})}/>

                    <TexisionDialog
                        type={DIALOG_TYPE_ERROR}
                        open={this.state.showActivationErrorDialog}
                        titleId="activation.errorDialog.title"
                        subtitleId="activation.errorDialog.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showActivationErrorDialog: false})}/>

                    <TexisionDialog
                        type={DIALOG_TYPE_WARNING}
                        open={this.state.showLoginErrorDialog}
                        titleId="activation.locked.title"
                        subtitleId="activation.locked.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showLoginErrorDialog: false})}/>

                    <TexisionDialog
                        type={DIALOG_TYPE_INFO}
                        open={this.state.showDeletionSuccessDialog}
                        titleId="deletion.successDialog.title"
                        subtitleId="deletion.successDialog.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showDeletionSuccessDialog: false})}/>

                    <TexisionDialog
                        type={DIALOG_TYPE_ERROR}
                        open={this.state.showDeletionErrorDialog}
                        titleId="deletion.errorDialog.title"
                        subtitleId="deletion.errorDialog.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showDeletionErrorDialog: false})}/>

                    <Grid container>

                        <form onSubmit={this.handleLogin} style={{width: "100%"}}>

                            <Grid item xs={12} style={{textAlign: "center"}}>

                                <Typography variant="h1">
                                    <FormattedMessage id="login.panel.h1"/>
                                </Typography>

                                <Typography 
                                    variant="subtitle1" 
                                    style={{marginBottom: "20px", padding: "0 15%"}}>
                                    <FormattedMessage id="login.panel.h2"/>
                                </Typography>

                            </Grid>

                            <Grid item xs={12}>

                                <TextField 
                                    id="username_txf"
                                    value={this.state.mail}
                                    label={this.props.intl.formatMessage({id: "userprofil.email"})}
                                    {...opts} 
                                    variant="filled" 
                                    style={{marginTop: "55px", width: "100%"}}
                                    onChange={(event) => this.setState({mail: event.target.value, loginError: false})}/>
                                    
                            </Grid>

                            <Grid item xs={12}>
                                <TextField 
                                    id="password_txf" 
                                    label={this.props.intl.formatMessage({id: "entities.user.password"})} 
                                    {...opts} 
                                    variant="filled" 
                                    type={this.state.passwordVisible ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position='end'>
                                            <IconButton
                                                style={{backgroundColor: "transparent"}}
                                                onClick={() => this.setState({passwordVisible: !this.state.passwordVisible})}>
                                                {this.state.passwordVisible ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                            </IconButton>
                                        </InputAdornment>)
                                    }}
                                    style={{marginTop: "16px", width: "100%"}}
                                    onChange={(event) => this.setState({password: event.target.value, loginError: false})}
                                    onKeyPress={async (event) => {
                                        if (event.key === 'Enter') {
                                            await this.handleLogin(event);
                                        }
                                    }}/>
                            </Grid>

                            <Grid item xs={12} style={{textAlign: "center"}}>

                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit" 
                                    style={{marginTop: "65px", width: 200}}>
                                    <FormattedMessage id="login.panel.button"/>
                                </Button>

                            </Grid>

                            <Grid item xs={12} style={{marginTop: "35px", textAlign: "center"}}>
                                <Link to={FORGOT_PASSWORD_ROUTE}>
                                    <FormattedMessage id="login.panel.forgotpassword"/>
                                </Link>
                            </Grid>

                        </form>


                        <Grid item xs={12} style={{marginTop: "6rem", textAlign: "center"}}>

                            <Typography variant="subtitle1" style={{marginBottom: "20px", padding: "0 15%"}}>
                                <FormattedMessage id="commons.or"/>
                            </Typography>

                        </Grid>


                        <Grid item xs={12} style={{marginTop: "35px", textAlign: "center"}}>

                            <Typography variant="h1">
                                <FormattedMessage id="login.panel.registration.title"/>
                            </Typography>

                            <Typography variant="subtitle1" style={{marginBottom: "20px", padding: "0 15%"}}>
                                <FormattedMessage id="login.panel.registration.subtitle"/>
                            </Typography>

                        </Grid>


                        <Grid item xs={12} style={{marginTop: "35px", marginBottom: 50, textAlign: "center"}}>

                            <Grid container spacing={2} justifyContent="space-between">

                                <Grid item xs={12} md={6}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        type="submit" 
                                        className="registration-button" 
                                        onClick={() => this.goToRegistration(Role.BIDDER_READ_ONLY)}>
                                        <FormattedMessage id="login.panel.registration.bidder"/>
                                    </Button>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        type="submit" 
                                        className="registration-button" 
                                        onClick={() => this.goToRegistration(Role.PROJECT_ADMIN)}>
                                        <FormattedMessage id="login.panel.registration.projectadmin"/>
                                    </Button>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid> 

                </div> 
            }/>
        );
    }
}

export default injectIntl(withCookies(withRouter(withSnackbar(Login))));
