import React, {Component} from 'react';
import {Button} from "@material-ui/core";
import {texisionRed, white} from "../../../../util/ColorTheme";
import {FormattedMessage, injectIntl} from "react-intl";
import {DIALOG_TYPE_WARNING} from "../../../../util/Constants";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {getEffectiveFromSuffix} from "../../../../util/DateUtil";
import {createErrorMessage, createSuccessMessage} from "../../../../util/Util";
import {withSnackbar} from "notistack";
import {deleteWorkingState, getActiveOperation} from "../../../../services/OperationService";

class DeleteWorkingProjectButton extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            isDeleting: false
        }
    }

    delete = async(activeOperation) => {
        this.setState({isDeleting: true});
        await deleteWorkingState(
            this.context,
            activeOperation,
            () => createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.delete.successMessage"}), this.props),
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.delete.errorMessage"}), this.props));
        this.setState({showDeleteDialog: false, isDeleting: false});
    }

    render() {
        const activeOperation = getActiveOperation(this.context);
        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDialog}
                    titleId="cockpit.contractStatus.dialogTitle.delete"
                    subtitleId="cockpit.contractStatus.dialogSubtitle.delete"
                    replacements={{effectiveFrom: getEffectiveFromSuffix(this.props.intl, activeOperation)}}
                    cancelId="commons.no.button"
                    actionId="commons.yes.button"
                    onCancel={() => this.setState({showDialog: false})}
                    cancelDisabled={this.state.isDeleting}
                    onAction={() => this.delete(activeOperation)}
                    actionDisabled={this.state.isDeleting}/>

                <div>
                    <Button
                        variant="contained"
                        disabled={this.state.isDeleting}
                        style={{backgroundColor: texisionRed, color: white}}
                        onClick={() => this.setState({showDialog: true})}>
                        <FormattedMessage id="cockpit.contractStatus.delete.button"/>
                    </Button>
                </div>
            </>
        );
    }
}

export default injectIntl(withSnackbar(DeleteWorkingProjectButton));
