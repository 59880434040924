import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';


import {EQUIPMENT_TYPE_AREA, EQUIPMENT_TYPE_PROFESSIONAL_GROUP} from '../../../util/Constants';
import {GeneralContext} from "../../contexts/GeneralContext";
import {getAreaById} from "../../../services/AreaService";
import {getProfessionalGroupById} from "../../../services/ProfessionalGroupService";

class ArticleSourcePopover extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.state = {
            hasLoaded: false,
            areas: [],
            professionalGroups: []
        }
    }

    async componentDidMount() {
       await this.loadSources();
    }

    async componentDidUpdate() {
        await this.loadSources();
    }

    async loadSources() {
        if (!this.state.hasLoaded && this.props.articleSources.length && this.props.open) {
            let areas = [];
            let professionalGroups = [];
            for (let source of this.props.articleSources) {
                switch (source.type) {
                    case EQUIPMENT_TYPE_AREA:
                        const area = await getAreaById(this.context, this.props, source.sourceId);
                        if (area) areas.push(area);
                        break;
                    case EQUIPMENT_TYPE_PROFESSIONAL_GROUP:
                        const professionalGroup = await getProfessionalGroupById(this.context, this.props, source.sourceId);
                        if (professionalGroup) professionalGroups.push(professionalGroup);
                        break;
                    default:
                        break;
                } 
            }
            this.setState({hasLoaded: true, areas: areas, professionalGroups: professionalGroups});
        }
    }


    handlePopOverClose = () => {
        this.setState({hasLoaded: false, areas: [], professionalGroups: []});
        this.props.onClose();
    }

    render() {

        return (
            <Popover 
                key="source-popover" 
                open={this.props.open} 
                anchorEl={this.props.anchorEl} 
                onClose={this.handlePopOverClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} 
                transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Grid container justifyContent="flex-start" className="popOverContainer">
                    <Grid item container direction="column" style={{margin: "10px", marginRight: "20px"}}>
                        <Typography variant="h4"><FormattedMessage id="articleSource.title" /></Typography>
                        <Typography key="source-title" style={{marginBottom: "1%"}}><FormattedMessage id="articleSource.description" /></Typography>
                        <React.Fragment>
                        {Array.from(this.state.areas).map((area) => {
                            return <Typography key={"area" + area.id}> • {area.name} (
                                <FormattedMessage id="articleSource.area"/>
                            )</Typography>
                        })}
                        </React.Fragment>
                        <React.Fragment>
                        {Array.from(this.state.professionalGroups).map((professionalGroup) => {
                            return <Typography key={"professionalGroup" + professionalGroup.id}> • {professionalGroup.name} (
                                <FormattedMessage id="articleSource.professionalGroup"/>
                            )</Typography>
                        })}
                        </React.Fragment>
                    </Grid>
                </Grid>
            </Popover>
        );
    }
}

export default injectIntl(ArticleSourcePopover);
