import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {Grid} from "@mui/material";

class CalculationTooltip extends Component {

    data = () => {
        switch (this.props.type) {
            case "operatingResources":
            case "specialServices":
                return [
                    ["constants.ResourceOffsetting.CALENDAR_DAY", 365],
                    ["constants.ResourceOffsetting.WEEKLY", 52],
                    ["constants.ResourceOffsetting.MONTHLY", 12],
                    ["constants.ResourceOffsetting.YEARLY", 1],
                    ["constants.ResourceOffsetting.PER_UNIT", 1],
                    ["constants.ResourceOffsetting.WITHOUT_CHARGE", 0]
                ];
            case "rentalLinen":
                return [
                    ["constants.ArticleOffsetting.DELIVERED_QUANTITY", 1],
                    ["constants.ArticleOffsetting.FLAT_RATE", 52]
                ];
            case "customerArticles":
            case "residentsLaundry":
                return [
                    ["constants.ArticleOffsetting.DELIVERED_QUANTITY", 1]
                ];
            default: return [];
        }
    }

    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormattedMessage id={this.props.titleId}/>
                </Grid>
                <Grid item xs={12}>
                    <FormattedMessage id="cockpit.priceSheet.calculation.hint"/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {this.data().map(([offsettingId, multiplier]) => <>

                            <Grid item xs={6}>
                                {this.props.intl.formatMessage({id: offsettingId})}:
                            </Grid>

                            <Grid item xs={6}>
                                <FormattedMessage
                                    id="cockpit.priceSheet.calculation.equation"
                                    values={{multiplier: multiplier === 1 ? " " : (" x " + multiplier + " ")}}/>
                            </Grid>

                        </>)}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

CalculationTooltip.propTypes = {
    titleId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
}

export default injectIntl(CalculationTooltip);
