import React, {Component} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {Button} from "@material-ui/core";
import {LICENSE_TYPES} from "../../../util/Constants";
import {FormattedMessage, injectIntl} from "react-intl";
import {FILE_TYPES, getAcceptedFileTypes} from "../../../util/DocumentUtil";

class InvoiceUploadButton extends Component {

    static contextType = GeneralContext;

    render() {
        return (
            <>
                <Button
                    color="primary"
                    component="label"
                    variant="contained"
                    onClick={this.props.hasCooperationLicense ? null : () => this.context.showNoLicenseDialog(LICENSE_TYPES.COOPERATION_LICENSE)}
                    style={{whiteSpace: "nowrap"}}>
                    <FormattedMessage id="cockpit.invoices.upload.button"/>
                    {this.props.hasCooperationLicense
                        ? <input
                            type="file"
                            id="offer-document-input"
                            accept={getAcceptedFileTypes([FILE_TYPES.PDF, FILE_TYPES.XML])}
                            style={{display: "none"}}
                            onChange={(e) => this.props.onUpload(e.target.files[0])}
                            onClick={(event)=> {event.target.value = null}}/>
                        : null
                    }
                </Button>
            </>
        );
    }
}

export default injectIntl(InvoiceUploadButton);
