import { Button, TextField, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { DIALOG_TYPE_WARNING } from "../../../../util/Constants";
import { createErrorMessage, createSuccessMessage } from "../../../../util/Util";
import { GeneralContext } from "../../../contexts/GeneralContext";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {getActiveOperation, rejectWorkingState} from "../../../../services/OperationService";

class ContractWithdrawPlanHeader extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            showDialog: false
        }
    }

    withdrawPlan = async(activeOperation) => {
        this.setState({comment: "", showDialog: false});
        await rejectWorkingState(
            this.context,
            activeOperation,
            this.state.comment,
            () => createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.delete.successMessage"}), this.props),
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.delete.errorMessage"}), this.props));
    }

    render() {

        const activeOperation = getActiveOperation(this.context);

        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDialog}
                    titleId="cockpit.contractStatus.dialogTitle.cancel"
                    subtitleId="cockpit.contractStatus.dialogSubtitle.cancel"
                    content={
                        <TextField
                            label={<FormattedMessage id="cockpit.contractStatus.comment"/>}
                            onChange={(e) => this.setState({comment: e.target.value})}
                            multiline
                            style={{width: '100%'}}/>
                    }
                    cancelId="commons.no.button"
                    actionId="commons.yes.button"
                    onCancel={() => this.setState({comment: "", showDialog: false})}
                    onAction={() => this.withdrawPlan(activeOperation)}/>

                <Typography variant="h1">
                    <FormattedMessage id="cockpit.contractStatus.title"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id="cockpit.contractStatus.own.inNegotiation.subtitle"/>
                </Typography>

                <Button
                    variant="contained"
                    style={{marginBottom: 20}}
                    color="primary"
                    onClick={() => this.setState({showDialog: true})}>
                    <FormattedMessage id="cockpit.contractStatus.cancel.button"/>
                </Button>
            </>
        );
    }
}

export default injectIntl(withSnackbar(ContractWithdrawPlanHeader));
