import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {LICENSE_TYPES} from "../../util/Constants";
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {texisionGreen} from "../../util/ColorTheme";
import {GeneralContext} from "../contexts/GeneralContext";

class ProductInformationList extends Component {

    static contextType = GeneralContext;

    render() {
        let information = [];
        const checkIcon = <CheckCircleIcon style={{color: texisionGreen}}/>;
        if (this.props.licenseType === LICENSE_TYPES.TENDER_LICENSE) {
            information = [
                ["publish", checkIcon],
                ["export", checkIcon],
                ["bidderCommunication", checkIcon],
                ["priceComparison", checkIcon],
                ["users", 6],
                ["units", 3],
                ["consultation", "30 min"]
            ];
        } else if (this.props.licenseType === LICENSE_TYPES.OFFER_LICENSE) {
            information = [
                ["accept", checkIcon],
                ["free_cockpit", "18 " + this.props.intl.formatMessage({id: "commons.months"})]
            ];
        } else if (this.props.licenseType === LICENSE_TYPES.COOPERATION_LICENSE) {
            information = [
                ["invoices", checkIcon],
                ["statistics", checkIcon],
                ["order", checkIcon],
                ["users", 6],
                ["units", 3]
            ];
        } else {
            return null;
        }
        return (
            <TableContainer component={Paper} style={{overflowX: "scroll"}}>

                <Table aria-label="bidder-dashboard-table">

                    <TableBody>
                        {information.map(([key, value]) => (
                                <TableRow key={"product-info-content-" + key}>
                                    <TableCell width="80%" component="th" scope="row" align="left">
                                        {this.props.intl.formatMessage({id: "payment.product.info." + key})}
                                    </TableCell>
                                    <TableCell width="20%" component="th" scope="row" align="right">
                                        {value}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>

                </Table>

            </TableContainer>
        );
    }
}

export default injectIntl(ProductInformationList);
