import React, {Component} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import PropTypes from "prop-types";
import {withSnackbar} from "notistack";
import {FormattedMessage, injectIntl} from "react-intl";
import {contractBlockText, contractPlaceholder} from "../../../services/ContractService";
import {getActiveProject} from "../../../services/ProjectService";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import {Checkbox, Grid} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {Button} from "@mui/material";
import Tooltip from '@material-ui/core/Tooltip';
import RichTextEditor from "../../uiLibrary/RichTextEditor";
import {isAdmin, isProjectAdmin} from "../../../services/UserService";
import {materialGrey} from "../../../util/ColorTheme";

class ContractChapterText extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showChapterEditDialog : false,
            chapterText : null,
            disclaimerChecked: false
        };
    }

    componentDidMount() {
        this.resetChapterText();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.chapter.author !== this.props.chapter.author) {
            this.resetChapterText();
        }
    }

    resetChapterText = () => {
        this.setState({
            chapterText : this.props.chapter.text,
            disclaimerChecked: false
        });
    }

    lastEditBy = () => {
        const {chapter, intl} = this.props;
        return !!chapter.author
            ? " (" + intl.formatMessage({ id : "contract.chapter.edit.author"})
                + chapter.author.firstName + " "
                + chapter.author.lastName + ")"
            : "";
    }

    render() {
        const {contract, chapter, intl, onChapterChange, onChapterReset} = this.props;
        const currency = getActiveProject(this.context)?.currency;
        const tooltip = intl.formatMessage({ id : "contract.chapter.edit.info"})
            + " " + this.lastEditBy();
        const user = this.context.currentUser;
        const showEditButton = isAdmin(user) || isProjectAdmin(user);

        return(
            <>
                <TexisionDialog
                    open={this.state.showChapterEditDialog}
                    titleId="contract.chapter.dialog.title"
                    subtitleId="contract.chapter.dialog.subtitle"
                    replacements={{chapterHeadline: chapter.headline}}
                    actionId="commons.save.button"
                    onAction={() => {
                        chapter.text = this.state.chapterText;
                        onChapterChange(chapter);
                        this.setState({showChapterEditDialog: false, disclaimerChecked: false})
                    }}
                    actionDisabled={!this.state.chapterText || !this.state.disclaimerChecked}
                    actionId2={"commons.reset.title"}
                    onAction2={() => {
                        onChapterReset(chapter);
                        this.setState({showChapterEditDialog: false, disclaimerChecked: false})
                    }}
                    cancelId="commons.cancel.button"
                    onCancel={async () => {
                        this.resetChapterText();
                        this.setState({showChapterEditDialog: false})
                    }}
                    content={
                    <Grid container
                          direction="column"
                          justifyContent="flex-start"
                          spacing={2}
                          style={{ color: materialGrey }}>
                        <Grid item>
                            {contractPlaceholder(contract, chapter, intl)}
                        </Grid>
                        <Grid item>
                            <RichTextEditor
                                id={"editor-" + chapter.id}
                                htmlText={this.state.chapterText}
                                onChange={(text) => this.setState({chapterText: text})}
                            />
                        </Grid>
                        <Grid item>
                            <Checkbox
                                color="primary"
                                checked={this.state.disclaimerChecked}
                                onChange={(e) => this.setState({disclaimerChecked : e.target.checked})}
                            />
                            <span>
                                <FormattedMessage id={"contract.chapter.dialog.disclaimer"} />
                                {this.lastEditBy()}
                            </span>
                        </Grid>
                    </Grid>}
                />
                <Grid container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                        {contractBlockText(contract, chapter, intl, currency)}
                    </Grid>
                    <Grid item>
                        {showEditButton &&
                            <Tooltip title={tooltip} aria-label="edit">
                            <Button
                                color="primary"
                                variant="contained"
                                style={{marginRight: 15}}
                                onClick={() => this.setState({showChapterEditDialog: true})}
                                disabled={this.props.readOnly}>
                                <EditIcon/>
                            </Button>
                        </Tooltip>}
                    </Grid>
                </Grid>
            </>
        );
    }
}

ContractChapterText.propTypes = {
    contract: PropTypes.object.isRequired,
    chapter: PropTypes.object.isRequired,
    onChapterChange: PropTypes.func.isRequired,
    onChapterReset: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired
};

export default withSnackbar(injectIntl(ContractChapterText));
