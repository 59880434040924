import React, {Component} from "react";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {FormattedMessage, injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {Grid, InputLabel, Select} from "@material-ui/core";
import {getCleanType} from "../../../../services/ResidentsLaundryService";
import Button from "@material-ui/core/Button";

class ResidentsLaundryForm extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            residentsLaundryList: props.residentsLaundryList,
            residentsLaundry: props.residentsLaundry
        }
    }

    getDescription = (residentsLaundry) => {
        return residentsLaundry ? residentsLaundry.name + " (" + getCleanType(this.props, residentsLaundry.cleanType) + ")" : "";
    }

    handleResidentsLaundryChanged = (residentsLaundryId) => {
        let residentsLaundry = this.state.residentsLaundryList.find((i) => i.id.toString() === residentsLaundryId);
        this.setState({residentsLaundry});
    }

    render() {
        return <>
            <Grid container spacing={2} alignItems="bottom">
                <Grid item>

                    <InputLabel
                        htmlFor="residentsLaundry-native-simple"
                        style={{marginTop: "20px", marginBottom: 5}}>
                        <FormattedMessage id="residentsLaundry.article.name"/> *
                    </InputLabel>

                    <Select
                        native
                        value={this.state.residentsLaundry ? this.state.residentsLaundry.id : ""}
                        label={this.props.intl.formatMessage({id: "residentsLaundry.article.name"})}
                        onChange={(e) => this.handleResidentsLaundryChanged(e.target.value)}
                        inputProps={{name: 'residentsLaundry', id: 'residentsLaundry-native-simple'}}>

                        <option aria-label="None" value=""/>

                        {this.state.residentsLaundryList
                            .sort((a,b) => this.getDescription(a).localeCompare(this.getDescription(b)))
                            .map((residentsLaundry) => {
                                return (
                                    <option
                                        key={residentsLaundry.id}
                                        value={residentsLaundry.id}>
                                        {this.getDescription(residentsLaundry)}
                                    </option>
                                );
                            })}

                    </Select>

                </Grid>

            </Grid>

            <Grid container spacing={2} justifyContent="flex-end">

                <Grid item>
                    <Button
                        style={{marginTop: "40px"}}
                        variant="contained"
                        color="secondary"
                        onClick={() => this.props.onCancel()}>
                        <FormattedMessage id="commons.cancel.button"/>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        style={{marginTop: "40px"}}
                        color="primary"
                        onClick={() => this.props.onSubmit(this.state.residentsLaundry)}
                        disabled={!this.state.residentsLaundry}>
                        {this.props.intl.formatMessage({id: "commons.apply.button"})}
                    </Button>

                </Grid>

            </Grid>
        </>
    }
}

ResidentsLaundryForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    residentsLaundryList: PropTypes.array.isRequired
};

export default injectIntl(ResidentsLaundryForm);