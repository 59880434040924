import React, { Component } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Accordion, AccordionDetails, AccordionSummary, CircularProgress, Stack} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getSimpleDate } from "../../util/DateUtil";
import OperationDifferencesTable from "../cockpit/contractchanges/OperationDifferencesTable";
import {DIALOG_TYPE_ERROR} from "../../util/Constants";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import {Button, Grid} from "@material-ui/core";
import {GeneralContext} from "../contexts/GeneralContext";
import {exportBillOfQuantities} from "../../services/ExportService";
import {getExportType} from "../../util/DocumentUtil";

class TenderHistoryItem extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showLockedDialog: false,
            isExporting: false
        };
    }

    downloadBillOfQuantities = async() => {
        this.setState({isExporting: true});
        await exportBillOfQuantities(this.context, this.props.operationDifference.projectTargetId,
            getExportType(this.context), () => this.setState({showLockedDialog: true}));
        this.setState({isExporting: false});
    }

    render() {

        if (this.props.operationDifference) {

            const startDate = new Date(this.props.operationDifference.effectiveFrom);

            return (
                <>

                    <TexisionDialog
                        type={DIALOG_TYPE_ERROR}
                        open={this.state.showLockedDialog}
                        titleId="export.locked.title"
                        subtitleId="export.locked.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showLockedDialog: false})}/>

                    <Accordion>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs>
                                    <h2>
                                        {this.props.intl.formatMessage({id: "tenderHistoryItem.title.h1"}) + getSimpleDate(startDate)}
                                    </h2>
                                </Grid>
                                <Grid item style={{marginRight: 15}}>
                                    <Button
                                        color="primary"
                                        disabled={this.state.isExporting}
                                        variant="contained"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.downloadBillOfQuantities()
                                        }}>
                                        {this.state.isExporting
                                            ? <CircularProgress size={20} style={{color: "white"}}/>
                                            : <FormattedMessage id="billOfQuantities.export.text"/>
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionSummary>

                        <AccordionDetails>

                            <Stack spacing={1} width={"100%"}>
                                <OperationDifferencesTable operationDifference={this.props.operationDifference}/>
                            </Stack>

                        </AccordionDetails>

                    </Accordion>

                </>
            );

        } else {
            return null;
        }
    }
}

export default injectIntl(TenderHistoryItem);
