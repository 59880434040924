import { TableCell, TableRow } from "@material-ui/core";
import { useIntl } from "react-intl";
import { formatArticleNumbers, formatCustomerArticleFeatures } from "../../../../util/TableUtil";
import { formatPrice } from "../../../../util/Util";
import { InvoiceControllingTable } from "./InvoiceControllingTable";

const CustomerArticlesPriceTable = ({priceUnit, contents, currency, unitOrProjectId}) => {

    const intl = useIntl();

    const customerArticles = contents.find(c => c.exportable.id === parseInt(unitOrProjectId))?.customerArticles;

    if (!customerArticles || customerArticles.length === 0) {
        return <div/>;
    }

    return <InvoiceControllingTable 
        key="invoice-table-customer"
        icon="icon-clothings" 
        titleId="navBar.customerArticles.title" 
        currency={currency} 
        titles={[
            "invoiceControlling.table.name.title",
            "entities.article.features",
            "invoiceControlling.table.volumeAndType.title",
            "invoiceControlling.table.price.title",
            "invoiceControlling.table.texisionArticleNumber.title",
            "invoiceControlling.table.bidderArticleNumber.title"
        ]}
        body={
        
        Object.values(priceUnit.customerArticleGroup.priceItemMap).map((priceItem) => {
            
            const customerArticle = customerArticles?.find(c => c.id === priceItem.objectId);
            
            return <TableRow key={"customer-article-price-table-" + priceItem.objectId}>
                <TableCell>
                    {customerArticle?.description}
                </TableCell>
                <TableCell>
                    {formatCustomerArticleFeatures(customerArticle?.features, intl)}
                </TableCell>
                <TableCell>
                    {customerArticle?.volume + " " + intl.formatMessage({id: "constants.VolumeType." + customerArticle?.volumeType})}
                </TableCell>
                <TableCell>
                    {formatPrice(priceItem.price)}
                </TableCell>
                <TableCell>
                    {"TX-" + priceItem.objectId}
                </TableCell>
                <TableCell>
                    {formatArticleNumbers(priceItem.articleNumbers)}
                </TableCell>
            </TableRow>;
        })
    }/>;
}

export {
    CustomerArticlesPriceTable
}
