import {Grid, Typography} from "@material-ui/core";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {BILL_OF_QUANTITIES_TYPE, EXPORT_TYPE_MERGE} from "../../util/Constants";
import {GeneralContext} from "../contexts/GeneralContext";
import ExportButton from "./ExportButton";

class ExportHeader extends Component {

    static contextType = GeneralContext;

    render() {
        return <Grid container alignItems="flex-start" style={{paddingBottom: "30px"}}>

            <Grid item xs>

                <Typography variant="h1">
                    <FormattedMessage id={this.props.titleId}/>
                </Typography>

                {this.props.subtitleId && <Typography variant="subtitle1" style={{marginBottom: "20px"}}>
                    <FormattedMessage id={this.props.subtitleId}/>
                    <div>{""}</div>
                    {this.context.getUserStateValue(BILL_OF_QUANTITIES_TYPE) === EXPORT_TYPE_MERGE
                        && <FormattedMessage id={"export.subtitle.merge"}/>}
                </Typography>}

            </Grid>

            <ExportButton
                handleExportSwitch={this.props.handleExportSwitch}
                exportDisabled={this.props.exportDisabled}
                exportButtonId={this.props.exportButtonId}
                documentType={this.props.documentType}
                isExporting={this.props.isExporting}
                export={this.props.export}/>

        </Grid>;
    }
}

export default injectIntl(ExportHeader);
