import { Grid, Icon, IconButton, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { deleteAsyncCatch } from "../../services/BackendService";
import { DIALOG_TYPE_WARNING } from "../../util/Constants";
import { cutName, downloadS3Document, formatDate } from "../../util/DocumentUtil";
import TexisionDialog from "./TexisionDialog";
import '../apps/App.css';

const DocumentPreview = ({document, deleteEndpoint, disabled, tooltipId, tooltipText, props, callback, context}) => {

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [parentToDelete, setParentToDelete] = useState(null);
    const [filenameToDelete, setFilenameToDelete] = useState(null);

    const deleteButton = (<IconButton
        disabled={disabled}
        onClick={() => {
            setParentToDelete(document.parentId);
            setFilenameToDelete(document.filename);
            setShowDeleteDialog(true);
        }}>
        <Icon>delete</Icon>
    </IconButton>);

    return (<React.Fragment>

        <TexisionDialog
            type={DIALOG_TYPE_WARNING}
            open={showDeleteDialog}
            titleId="documents.delete.title"
            subtitleId="documents.delete.subtitle"
            cancelId="commons.no.button"
            actionId="commons.yes.button"
            onCancel={() => {
                setShowDeleteDialog(false);
                setParentToDelete(null);
                setFilenameToDelete(null);
            }}
            onAction={async() => {
                const url = deleteEndpoint + parentToDelete + "/" + filenameToDelete;
                setShowDeleteDialog(false);
                setParentToDelete(null);
                setFilenameToDelete(null);
                await deleteAsyncCatch(context, url, props);
                callback();
            }}/>

        <Grid item>
            <Grid className="documentPreview" container spacing={1}>
                <Grid item style={{marginRight: "10px", marginTop: "8px"}}>
                    <Icon color="primary">picture_as_pdf</Icon>
                </Grid>
                <Grid item xs style={{fontSize: "12pt"}}>
                    {cutName(props.intl, document.filename) + ", " + formatDate(props.intl, document.lastModified)}
                </Grid>
                <Grid item>
                    <Grid container justifyContent="flex-end" wrap="nowrap">
                        {disabled && !tooltipId && !tooltipText ? null : <Grid item>
                            {disabled ? <Tooltip title={tooltipText ?? props.intl.formatMessage({id: tooltipId})}>
                                <div>
                                    {deleteButton}
                                </div>
                            </Tooltip> : deleteButton}
                        </Grid>}
                        <Grid item>
                            <IconButton
                                onClick={() => {downloadS3Document(props, document)}}>
                                <Icon>download</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>);
};

export {
    DocumentPreview
};
