import React from "react";
import FooterWrapper from "../FooterWrapper";
import PropTypes from "prop-types";
import BusinessUnitForm from "../../../businessUnit/baseData/BusinessUnitForm";
import {MAX_FILE_SIZE_IN_BYTE} from "../../../../util/DocumentUtil";
import {COUNTRIES, DIALOG_TYPE_WARNING, LOGISTIC_TYPE_DECENTRAL, UNIT_IMAGE_DESCRIPTOR, UNIT_LOGO_DESCRIPTOR} from "../../../../util/Constants";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {createBusinessUnit, getBusinessUnitById, updateBusinessUnit} from "../../../../services/BusinessUnitService";
import {injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {Grid} from "@mui/material";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";

class BusinessUnitPage extends React.Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        let unit = this.props.businessUnit;
        this.state = {
            id: unit?.id,
            version: unit?.version ?? 0,
            name: unit?.name,
            streetAndNumber: unit?.streetAndNumber,
            zipCode: unit?.zipCode,
            city: unit?.city,
            logoVo: unit?.logoVo,
            imageVo: unit?.imageVo,
            selectedImage: null,
            selectedFileName: null,
            selectedLogo: null,
            deliveryType: unit?.deliveryType ? unit.deliveryType : LOGISTIC_TYPE_DECENTRAL,
            pickupType: unit?.pickupType ? unit.pickupType : LOGISTIC_TYPE_DECENTRAL,
            loading: false,
            showSelectedImageToLargeDialog: false
        };
    }

    invalidData = () => {
        return !this.state.name || !this.state.streetAndNumber || !this.state.zipCode || !this.state.city;
    }

    createOrUpdateUnit = async() => {
        let businessUnit = {
            id: this.state.id,
            projectId: this.props.projectId,
            name: this.state.name,
            streetAndNumber: this.state.streetAndNumber,
            zipCode: this.state.zipCode,
            city: this.state.city,
            country: COUNTRIES.DE,
            deliveryType: this.state.deliveryType,
            pickupType: this.state.pickupType,
            version: this.state.version
        }

        this.setState({loading: true});

        // create
        if (this.state.id === null || this.state.id === undefined) {

            const createdUnit = await createBusinessUnit(this.context, this.props, businessUnit,
                this.state.croppedImageBlob, this.state.selectedLogo, this.props.projectId, true, false);

            if (createdUnit) {
                const unitWithImages = await getBusinessUnitById(this.context, this.props, createdUnit.id);
                this.setState({loading: false});
                return this.props.onContinue(unitWithImages);
            }
            // update
        } else {

            const updatedUnit = await updateBusinessUnit(this.context, this.props, businessUnit,
                this.state.croppedImageBlob, this.state.imageToDelete, this.state.selectedLogo, this.state.logoToDelete, this.props.projectId,true);

            if (updatedUnit) {
                const unitWithImages = await getBusinessUnitById(this.context, this.props, updatedUnit.id);
                this.setState({loading: false});
                return this.props.onContinue(unitWithImages);
            }
        }
    }

    handleZipCodeChange = (zipCode) => {
        const zipCodeRegex = /^[0-9]*$/;
        if (zipCode === "" || zipCodeRegex.test(zipCode)) {
            this.setState({zipCode});
        }
    }

    handleImageSelection = async(file, type) => {
        if (file) {
            if (file.size >= MAX_FILE_SIZE_IN_BYTE) {
                this.setState({showSelectedImageToLargeDialog: true});
            } else {
                if (type === UNIT_IMAGE_DESCRIPTOR) {
                    this.setState({selectedImage: URL.createObjectURL(file), selectedFilename: file.name});
                } else if (type === UNIT_LOGO_DESCRIPTOR) {
                    this.setState({selectedLogo: file});
                }
            }
        }
    }

    handleImageCropped = async(croppedImage) => {
        let imageBlob = null;
        if (croppedImage) {
            let blob = await new Promise(resolve => croppedImage.toBlob(resolve, "image/jpeg", 0.9));
            imageBlob = new File([blob], this.state.selectedFilename, {type: 'image/png'});
        }

        if (imageBlob && imageBlob.size >= MAX_FILE_SIZE_IN_BYTE) {
            this.setState({showSelectedImageToLargeDialog: true, selectedImage: null, croppedImageBlob: null});
        } else {
            this.setState({croppedImageBlob: imageBlob});
        }
    }

    handleRemoveImage = () => {
        if (this.state.imageVo && !this.state.selectedImage) {
            this.setState({imageToDelete: {...this.state.imageVo.image}, imageVo: null});
        } else if (!this.state.imageVo && this.state.selectedImage) {
            // also set croppedImage to null, otherwise it will be uploaded to the server
            this.setState({selectedImage: null, selectedFilename: null, croppedImageBlob: null});
        } else if (this.state.imageVo && this.state.selectedImage) {
            this.setState({selectedImage: null, selectedFilename: null, croppedImageBlob: null});
        }
    }

    render() {
        return (
            <FooterWrapper
                invalidData={this.invalidData() || this.state.loading}
                onContinue={() => this.createOrUpdateUnit()}
                onCancel={this.props.onCancel}
                onBack={this.props.onBack}>
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showSelectedImageToLargeDialog}
                    titleId="commons.image.warningDialog.title"
                    subtitleId="commons.image.warningDialog.text"
                    actionId="commons.close.label"
                    onAction={() => this.setState({showSelectedImageToLargeDialog: false, selectedImage: null, croppedImageBlob: null})}/>
                <Grid container>
                    <BusinessUnitForm
                        name={this.state.name}
                        handleNameChange={(name) => this.setState({name})}
                        streetAndNumber={this.state.streetAndNumber}
                        handleStreetAndNumberChange={(streetAndNumber) => this.setState({streetAndNumber})}
                        zipCode={this.state.zipCode}
                        handleZipCodeChange={this.handleZipCodeChange}
                        city={this.state.city}
                        handleCityChange={(city) => this.setState({city})}
                        imageVo={this.state.imageVo}
                        handleImageSelection={this.handleImageSelection}
                        selectedImage={this.state.selectedImage}
                        logoVo={this.state.logoVo}
                        selectedLogo={this.state.selectedLogo}
                        handleRemoveImage={this.handleRemoveImage}
                        handleRemoveLogo={() => this.setState({logoToDelete: {...this.state.logoVo?.image}, logoVo: null, selectedLogo: null})}
                        handleImageCropped={this.handleImageCropped}
                    />
                </Grid>
            </FooterWrapper>
        );
    }
}

BusinessUnitPage.defaultProps = {
    businessUnit: null
}

BusinessUnitPage.propTypes = {
    businessUnit: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired
};

export default injectIntl(withSnackbar(BusinessUnitPage));
