import React, {Component} from 'react';
import {Box, Typography} from '@material-ui/core';
import ArticleAccordionElement from './ArticleAccordionElement';
import {injectIntl} from 'react-intl';
import {GeneralContext} from "../../contexts/GeneralContext";

class ArticleTabPanel extends Component {

    static contextType = GeneralContext;

    render() {
      
        let accordions = [];
        
        // Performance optimization => only start calculations, if the panel is currently shown.
        if (this.props.value === this.props.index) {

            let allArticles = this.props.articles;
            let articleSubcategoryMap = new Map();

            allArticles.forEach(article => {
                const subCategory = article.subcategory ?? article.subCategory;
                let articlesOfSubcategory = articleSubcategoryMap.get(subCategory);
                if (!articlesOfSubcategory) {
                    articlesOfSubcategory = [];
                    articleSubcategoryMap.set(subCategory, articlesOfSubcategory);
                }
                articlesOfSubcategory.push(article);
            });
            
            // Sort accordions by article category
            let sortedMap = new Map([...articleSubcategoryMap]
                .sort((a,b) => this.props.intl.formatMessage({id: "constants.SubCategory." + a[0]})
                    .localeCompare(this.props.intl.formatMessage({id: "constants.SubCategory." + b[0]}))));

            let id = 1;
            for (let [subcategory, articles] of sortedMap) {
                accordions.push(<ArticleAccordionElement 
                    key={id++}
                    header={this.props.intl.formatMessage({id: "constants.SubCategory." + subcategory})}
                    articles={articles}
                    reloadData={this.props.reloadData}/>);
            }
        }

        return <div role="tabpanel" hidden={this.props.value !== this.props.index} id={this.props.index} aria-labelledby={this.props.index}>
            {this.props.value === this.props.index &&
            <Box className="articleTabContainer"> 

                <Typography variant="h2" style={{marginBottom: 20}}>
                    {this.props.intl.formatMessage({id: "articleOverview.category.title"}) + " - " + this.props.children}
                </Typography>

                <Box className="accordionContainer">{accordions}</Box>
            </Box>}
        </div>;
    }
}

export default injectIntl(ArticleTabPanel);
