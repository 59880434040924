import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import '../apps/App.css';

import {
    AGB_ROUTE, ARTICLE_CONFIGURATION_OVERVIEW_ROUTE, ARTICLE_CONFIGURATOR_ROUTE,
    buildRouteWithPathVariablePlaceholder, COCKPIT_APP, DASHBOARD_ROUTE,
    IMPRESSUM_ROUTE,
    LICENSE_ROUTE,
    MOBILE_THRESHOLD,
    MY_ACCOUNT_ROUTE,
    OPERATING_RESOURCE_CONFIGURATOR_ROUTE, OPERATING_RESOURCE_OVERVIEW_ROUTE,
    OPERATION_ASSIGNMENT_ROUTE,
    ORDER_CONFIGURATION_ROUTE,
    ORDER_DASHBOARD_ROUTE,
    ORDER_UNIT_ROUTE,
    PAYMENT_MANAGEMENT_ROUTE,
    PRIVACY_ROUTE,
    PROFILES_ROUTE, RELEASE_NOTES_ROUTE, TENDER_APP,
    USER_MANAGEMENT_ROUTE
} from '../../util/Constants';

import { GeneralContext } from '../contexts/GeneralContext';
import Impressum from '../legal/Impressum';
import TermsOfUse from '../legal/TermsOfUse';
import Privacy from '../legal/Privacy';
import MyAccount from '../account/MyAccount';

import MediaQuery from 'react-responsive';
import PrivateRoute from '../navigation/shared/PrivateRoute';
import {isAdmin, isProjectAdmin, Role} from '../../services/UserService';
import UserManagement from '../administration/userManagement/UserManagement';
import OrderNavBar from '../navigation/order/OrderNavBar';
import OrderDashboard from '../order/OrderDashboard';
import OrderUnit from '../order/OrderUnit';
import OrderConfiguration from '../order/OrderConfiguration';
import PaymentManagement from "../money/PaymentManagement";
import ProfileOverview from "../administration/profile/ProfileOverview";
import Licenses from "../administration/licenses/Licenses";
import OperatingResourceConfigurator from "../administration/operatingResources/OperatingResourceConfigurator";
import OperationAssignment from "../project/OperationAssignment";
import ArticleConfigurator from "../administration/articleConfigurator/ArticleConfigurator";
import ArticleConfigurationOverview from "../administration/articleOverview/ArticleConfigurationOverview";
import OperatingResourceOverview from "../administration/operatingResources/OperatingResourceOverview";
import ReleaseNotes from "../navigation/shared/ReleaseNotes";
import {Grid, Popover} from "@mui/material";
import {white} from "../../util/ColorTheme";
import {Button} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import orderComingSoonLogo from "../../resources/order_coming_soon.png";

class OrderApp extends Component {

    static contextType = GeneralContext;

    getRouteSwitch = () => {
        return (
            <Switch>
                    
                <Route path={IMPRESSUM_ROUTE} component={Impressum}/>
                <Route path={AGB_ROUTE} component={TermsOfUse}/>
                <Route path={PRIVACY_ROUTE} component={Privacy}/>

                <Route path={MY_ACCOUNT_ROUTE} component={MyAccount}/>
                <Route path={RELEASE_NOTES_ROUTE} component={ReleaseNotes}/>

                <Route path={ARTICLE_CONFIGURATOR_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ArticleConfigurator}/>
                </Route>

                <Route path={ARTICLE_CONFIGURATION_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ArticleConfigurationOverview}/>
                </Route>

                <Route path={ORDER_DASHBOARD_ROUTE} component={OrderDashboard}/>

                <Route path={PAYMENT_MANAGEMENT_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN]} component={PaymentManagement}/>
                </Route>

                <Route path={USER_MANAGEMENT_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN]} component={UserManagement}/>
                </Route>

                <Route path={ORDER_CONFIGURATION_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN]} component={OrderConfiguration}/>
                </Route>

                <Route path={PROFILES_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ProfileOverview}/>
                </Route>

                <Route path={LICENSE_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={Licenses}/>
                </Route>

                <Route path={OPERATING_RESOURCE_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={OperatingResourceOverview}/>
                </Route>

                <Route path={OPERATING_RESOURCE_CONFIGURATOR_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={OperatingResourceConfigurator}/>
                </Route>

                <Route path={OPERATION_ASSIGNMENT_ROUTE} component={OperationAssignment}/>

                <Route 
                    path={buildRouteWithPathVariablePlaceholder(ORDER_UNIT_ROUTE, ":unitId")} 
                    component={OrderUnit}/>

                <Route>
                    <Redirect to={ORDER_DASHBOARD_ROUTE}/>
                </Route>

            </Switch>
        );
    }

    render() {


        return (
            <>
                <Popover
                    elevation={0}
                    open={true}
                    style={{width: "100vw", height: "100vh", backgroundColor: white, opacity: 0.96, overflow: "hidden"}}>

                    <div style={{display: "flex", alignItems: "center", width: "100vw", height: "90vh", overflow: "hidden"}}>

                        <Grid container spacing={2}>

                            <Grid item xs={12} style={{display: "flex", justifyContent: "center", backgroundColor: "white"}}>
                                <img src={orderComingSoonLogo} style={{height: "75vh", backgroundColor: "white"}} alt="Order"/>
                            </Grid>

                            {(isAdmin(this.context.currentUser) || isProjectAdmin(this.context.currentUser)) && <Grid item xs={12}>

                                <Grid container spacing={4} justifyContent="center">

                                    <Grid item>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            this.context.setActiveApp(TENDER_APP);
                                            this.props.history.push(DASHBOARD_ROUTE);
                                        }}>
                                            <FormattedMessage id="order.coming.soon.tender.button"/>
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            this.context.setActiveApp(COCKPIT_APP);
                                            this.props.history.push(DASHBOARD_ROUTE);
                                        }}>
                                            <FormattedMessage id="order.coming.soon.cockpit.button"/>
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Grid>}

                        </Grid>

                    </div>

                </Popover>

                <MediaQuery minWidth={MOBILE_THRESHOLD}>

                    <OrderNavBar mobile={false} key={this.context.appData.activeProjectId}/>

                    <div className="contentArea">
                        {this.getRouteSwitch()}
                    </div>

                </MediaQuery>

                <MediaQuery maxWidth={MOBILE_THRESHOLD-1}>

                    <OrderNavBar mobile={true} key={this.context.appData.activeProjectId}/>

                    <div className="contentAreaMobile">
                        {this.getRouteSwitch()}
                    </div>

                </MediaQuery>

            </>
        )
    }
}

export default withRouter(injectIntl(OrderApp));
