// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/react-image-gallery/styles/css/image-gallery.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MuiDialog-paperWidthXs {\n  max-width: 60vw !important;\n}\n\n.detailContent {\n  padding: 18px 24px !important;\n}\n\n.image-gallery {\n  height: 90%;\n}\n\n.image-gallery-content.left {\n  height: 90%;\n  max-width: 100%;\n  min-width: 60%;\n  overflow-y: hidden;\n}\n\n.image-gallery-slide-wrapper.left {\n  margin-top: 0;\n  height: 90%;\n  min-width: 60%;\n  max-width: 100%;\n}\n\n.image-gallery-swipe {\n  height: 90%;\n}\n\n.image-gallery-slides {\n  height: 90%;\n  display:flex;\n}\n\n.image-gallery-slide {\n  margin: auto;\n} ", "",{"version":3,"sources":["webpack://./src/css/ArticleDetail.css"],"names":[],"mappings":"AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":["@import \"~react-image-gallery/styles/css/image-gallery.css\";\n\n.MuiDialog-paperWidthXs {\n  max-width: 60vw !important;\n}\n\n.detailContent {\n  padding: 18px 24px !important;\n}\n\n.image-gallery {\n  height: 90%;\n}\n\n.image-gallery-content.left {\n  height: 90%;\n  max-width: 100%;\n  min-width: 60%;\n  overflow-y: hidden;\n}\n\n.image-gallery-slide-wrapper.left {\n  margin-top: 0;\n  height: 90%;\n  min-width: 60%;\n  max-width: 100%;\n}\n\n.image-gallery-swipe {\n  height: 90%;\n}\n\n.image-gallery-slides {\n  height: 90%;\n  display:flex;\n}\n\n.image-gallery-slide {\n  margin: auto;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
