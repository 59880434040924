import { ChevronRightRounded } from "@material-ui/icons";
import { Button, CircularProgress, Grid, IconButton, TextField } from "@mui/material";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import SendIcon from '@mui/icons-material/Send';
import { GeneralContext } from "../../contexts/GeneralContext";
import { withSnackbar } from "notistack";
import SupportChat from "./SupportChat";
import { getAsync, postAsync } from "../../../services/BackendService";
import { createErrorMessage } from "../../../util/Util";
import { APP_TYPE_KEY, COCKPIT_APP, LAST_OPERATION_ID, OFFER_APP, ORDER_APP, TENDER_APP } from "../../../util/Constants";
import Select from "react-select";
import { isAdmin } from "../../../services/UserService";
import { white } from "../../../util/ColorTheme";

class SupportDrawer extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            text: "",
            customerId: null,
            messages : [],
            users: [],
            isLoading: true,
            isSending: false,
            adminImageUrl: null,
            userIdToImageUrlMap: new Map()
        };
    }

    async componentDidMount() {
        const messages = await this.loadSupportChat();

        const userIdToImageUrlMap = new Map();

        let customerId = null;
        let users = [];

        if (isAdmin(this.context.currentUser)) {
            users = await this.loadUsers(messages.map(m => m.customerId));
            if (users?.length > 0) {
                customerId = users[0].id;
            }
        } 

        if (messages) {
            const uniqueUserIds = new Set(messages.map(message => message.authorId));
            for (let userId of uniqueUserIds) {
                const userImageResponse = await getAsync("/user/image/" + userId);
                if (userImageResponse?.status === 200) {
                    userIdToImageUrlMap.set(userId, userImageResponse.data);
                }
            }
        }

        this.setState({customerId, messages, users, userIdToImageUrlMap, isLoading: false});
        this.messagesEnd?.scrollIntoView({behavior: "smooth"});
    }

    loadSupportChat = async() => {
        const appType = localStorage.getItem(APP_TYPE_KEY);
        const user = this.context.currentUser;
        let response;
        if (isAdmin(user)) {
            response = await getAsync("/support/all/" + appType);
        } else {
            response = await getAsync("/support/" + appType);
        }
        if (response?.status === 200) {
            return response.data;
        } else if ([401, 403].includes(response?.status)) {
            this.context.logout();
        }
        return [];
    }

    loadUsers = async(userIds) => {
        if (!userIds || userIds.length === 0) {
            return [];
        }
        const response = await postAsync("/users", userIds);
        if (response?.status === 200) {
            return response.data;
        } else if ([401, 403].includes(response?.status)) {
            this.context.logout();
        }
        return [];
    }

    sendMessage = async() => {
        let operationId = null;
        switch (localStorage.getItem(APP_TYPE_KEY)) {
            case TENDER_APP:
            case ORDER_APP:
                const allProjects = this.context.appData.allProjects;
                if (allProjects && allProjects.length > 0) {
                    operationId = localStorage.getItem(LAST_OPERATION_ID) ?? null;
                }
                break;
            case COCKPIT_APP:
                const allOperations = this.context.appData.operations;
                if (allOperations && allOperations.length > 0) {
                    operationId = localStorage.getItem(LAST_OPERATION_ID) ?? null;
                }
                break;
            case OFFER_APP:
            default:
                break;
        }
        this.setState({isSending: true});
        let customerId;
        if (isAdmin(this.context.currentUser)) {
            customerId = this.state.customerId;
        } else {
            customerId = this.context.currentUser.id;
        }
        const supportMessageVo = {
            operationId,
            customerId,
            message: this.state.text,
            appType: localStorage.getItem(APP_TYPE_KEY)
        };
        const response = await postAsync("/support", supportMessageVo);
        if (response?.status === 200) {
            let messages = this.state.messages;
            messages.push(response.data);
            this.setState({text: "", messages, isSending: false});
            this.messagesEnd.scrollIntoView({behavior: "smooth"});
        } else if ([401, 403].includes(response?.status)) {
            this.context.logout();
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "support.chat.error"}), this.props);
            this.setState({isSending: false});
        }
    }

    getUserLabel = (userId) => {
        const user = this.state.users.find(u => u.id === userId);
        if (!user) {
            return this.props.intl.formatMessage({id: "support.user.unknown"}) + " (userId: " + userId + ")";
        }
        return user.firstName + " " + user.lastName + " (" 
            + this.props.intl.formatMessage({id: "user.role." + user.role}) + ", userId: " + user.id + ")";
    }

    render() {
        const isSuperAdmin = isAdmin(this.context.currentUser);

        const showTextField = !this.state.isLoading && (!isSuperAdmin || (this.state.users && this.state.users.length > 0));
        const showUserSelect = isSuperAdmin && this.state.users && this.state.users.length > 0;
        const showNoUsersHint = isSuperAdmin && (!this.state.users || this.state.users.length === 0);

        let messages;
        if (isSuperAdmin) {
            messages = this.state.messages.filter(m => m.customerId === this.state.customerId);
        } else {
            messages = this.state.messages;
        }

        return (
            <div style={{margin: 10, position: "relative"}}>
            
                <Grid 
                    container 
                    alignItems="center" 
                    justifyContent="space-between" 
                    style={{position: "fixed", top: 0, paddingTop: 10, width: "calc(50% - 40px)", backgroundColor: white}}>

                    <Grid item>
                        {showUserSelect 

                            ? <Select
                                required
                                isDisabled={!this.state.users || this.state.users.length < 2}
                                inputProps={{autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off'}}
                                id="person-title-id"
                                value={{value: this.state.customerId, label: this.getUserLabel(this.state.customerId)}}
                                onChange={(e) => this.setState({customerId: e.value})}
                                options={this.state.users.map(user => {return {value: user.id, label: this.getUserLabel(user.id)};})}>
                            </Select>

                            : <div/>}
                    </Grid>

                    <Grid item xs/>

                    <Grid item>
                        <IconButton onClick={() => this.context.handleSupportOverlay()}>
                            <ChevronRightRounded/>
                        </IconButton>
                    </Grid>

                </Grid>

                <div style={{marginTop: 60}}>
                    {showNoUsersHint 

                        && <FormattedMessage id="support.chat.noUsers"/>}

                        {this.state.isLoading 

                        ? <div style={{display: "flex", justifyContent: "center"}}><CircularProgress/></div>

                        : <SupportChat messages={messages} userIdToImageUrlMap={this.state.userIdToImageUrlMap}/>}

                        {showTextField 

                        && <Grid 
                            container 
                            alignItems="center"
                            style={{position: "fixed", right: 0, bottom: 0, paddingLeft: 30, paddingRight: 30, paddingTop: 20, backgroundColor: white, width: "50%"}}>

                            <Grid item xs style={{marginRight: 20, paddingBottom: 20, display: "flex", justifyContent: "flex-end"}}>

                                <TextField 
                                    fullWidth
                                    multiline
                                    maxRows={8}
                                    value={this.state.text}
                                    onChange={(e) => this.setState({text: e.target.value})}
                                    size="small"/>

                            </Grid>

                            <Grid item style={{paddingBottom: 20}}>
                                <Button 
                                    variant="contained"
                                    disabled={!this.state.text || this.state.isSending}
                                    endIcon={<SendIcon/>}
                                    onClick={() => this.sendMessage()}>
                                    <FormattedMessage id="support.drawer.send.button"/>
                                </Button>
                            </Grid>

                        </Grid>}

                        <div ref={(el) => {this.messagesEnd = el;}}/>
                </div>
            
            </div>
        );
    }
}

export default withSnackbar(injectIntl(SupportDrawer));
