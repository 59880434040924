import { Button, Grid } from "@material-ui/core";
import { Header } from "../../uiLibrary/Header";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {FormattedMessage, useIntl} from "react-intl";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import {useContext, useState} from "react";
import {DIALOG_TYPE_WARNING} from "../../../util/Constants";
import {GeneralContext} from "../../contexts/GeneralContext";
import {getActiveProject, getNextOfferRound} from "../../../services/ProjectService";
import {Tooltip} from "@material-ui/core";

const OfferResultHeader = ({showRecallButton, showWinnerButton, titleId, subtitleId, onRecallClicked, onWinnerClicked}) => {

    const [showUnusedSupportDialog, setShowUnusedSupportDialog] = useState(false);
    const formatMessage = useIntl().formatMessage;
    const context = useContext(GeneralContext);

    let onWinnerSelectClick;
    if (context.operationEvents?.availableSupportTime) {
        onWinnerSelectClick = () => setShowUnusedSupportDialog(true);
    } else {
        onWinnerSelectClick = () => onWinnerClicked();
    }


    const project = getActiveProject(context);

    const recallButton = () => {
        let button = <Button
            disabled={!showRecallButton}
            variant="contained"
            color="primary"
            onClick={() => onRecallClicked()}>
            <NotificationsActiveIcon style={{marginRight: 10}}/>
            <FormattedMessage id="offerResult.recall.button" values={{offerRound: getNextOfferRound(context)}}  />
        </Button>

        if (showRecallButton) {
            return button;
        } else {
            return <Tooltip title={formatMessage({id: "offerResult.recall.tooltip"})}>
                <div>{button}</div>
            </Tooltip>
        }
    }

    const winnerButton = () => {
        let button = <Button
            disabled={!showWinnerButton}
            variant="contained"
            color="primary"
            onClick={() => onWinnerSelectClick()}>
            <EmojiEventsIcon style={{marginRight: 10}}/>
            <FormattedMessage id="offerResult.selectWinner.button"/>
        </Button>

        if (showWinnerButton) {
            return button;
        } else {
            return <Tooltip title={formatMessage({id: "offerResult.selectWinner.tooltip"})}>
                <div>{button}</div>
            </Tooltip>
        }
    }

    return (
        <>

            <TexisionDialog
                open={showUnusedSupportDialog}
                type={DIALOG_TYPE_WARNING}
                titleId="navBar.unusedSupport.accept.dialog.title"
                subtitleId="navBar.unusedSupport.accept.dialog.subtitle"
                replacements={{tender: project?.name}}
                cancelId="commons.close.label"
                onCancel={() => setShowUnusedSupportDialog(false)}
                actionId="commons.continue.button"
                onAction={() => {
                    setShowUnusedSupportDialog(false);
                    onWinnerClicked();
                }}/>

            <Grid container>
                <Grid item xs>
                    <Header titleId={titleId} subtitleId={subtitleId}/>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} direction="column" alignItems="flex-end">
                        <Grid item>
                            {recallButton()}
                        </Grid>
                        <Grid item>
                            {winnerButton()}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export {
    OfferResultHeader
}
