import { Typography } from "@material-ui/core";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import { withSnackbar } from "notistack";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { getAsyncCatch, putAsyncCatch } from "../../services/BackendService";
import {LAST_OPERATION_ID, LICENSE_TYPES} from "../../util/Constants";
import { GeneralContext } from "../contexts/GeneralContext";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {latestActivePayment} from "../../services/PaymentService";

class OrderConfiguration extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            savedConfiguration: null, 
            localConfiguration: null,
            newAddress: ""
        };
    }

    async componentDidMount() {
        await this.loadOrderConfiguration();
    }

    componentDidUpdate() {
        if (this.canSaveLeadHours() || this.state.newAddress || this.receiverAddressesChanged()) {
            this.context.setUnsavedChanges(true);
        } else {
            this.context.setUnsavedChanges(false);
        }
    }

    loadOrderConfiguration = async() => {
        const activeOperationId = localStorage.getItem(LAST_OPERATION_ID);
        const response = await getAsyncCatch(this.context, "/order/configuration/" + activeOperationId, this.props);
        if (response) {
            this.setState({savedConfiguration: {...response}, localConfiguration: {...response}});
        }
    }

    sendOrderConfiguration = async() => {
        if (!latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE)) {
            this.context.showNoLicenseDialog(LICENSE_TYPES.COOPERATION_LICENSE);
            return;
        }
        const activeOperationId = localStorage.getItem(LAST_OPERATION_ID);
        const response = await putAsyncCatch(this.context, "/order/configuration/" + activeOperationId, this.state.localConfiguration, this.props);
        if (response) {
            await this.loadOrderConfiguration();
        }
    }

    onLeadHoursChanged = (event) => {
        const newLeadHours = event.target.value.replace(/\D/, "");
        const localConfiguration = this.state.localConfiguration;
        localConfiguration.leadHours = newLeadHours === "0" ? "" : newLeadHours;
        this.setState({localConfiguration});
    }

    canSaveLeadHours = () => {
        const savedLeadHours = this.state.savedConfiguration?.leadHours;
        const localLeadHours = this.state.localConfiguration?.leadHours;
        return localLeadHours && (!savedLeadHours || parseInt(localLeadHours) !== parseInt(savedLeadHours));
    }

    isNewAddressValid = () => {
        return this.state.newAddress 
            && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.newAddress) 
            && (
                !this.state.localConfiguration?.receiverAddresses 
                || !this.state.localConfiguration.receiverAddresses.includes(this.state.newAddress)
            );
    }

    onNewAddressAdded = () => {
        const localConfiguration = this.state.localConfiguration;
        const receiverAddresses = [...(localConfiguration?.receiverAddresses ?? [])];
        receiverAddresses.push(this.state.newAddress);
        localConfiguration.receiverAddresses = receiverAddresses;
        this.setState({localConfiguration, newAddress: ""});
    }

    onAddressRemoved = (addressToRemove) => {
        const localConfiguration = this.state.localConfiguration;
        let receiverAddresses = localConfiguration.receiverAddresses.filter(a => a !== addressToRemove);
        localConfiguration.receiverAddresses = receiverAddresses;
        this.setState({localConfiguration});
    }

    receiverAddressesChanged = () => {
        const localAddresses = this.state.localConfiguration?.receiverAddresses;
        const savedAddresses = this.state.savedConfiguration?.receiverAddresses;
        return (localAddresses?.length > 0 && !savedAddresses?.length)
            || (savedAddresses?.length > 0 && !localAddresses?.length)
            || (localAddresses?.length > 0 && savedAddresses?.length > 0 
                && savedAddresses.sort().join(",") !== localAddresses.sort().join(","));
    }

    setting = (type, component) => {
        return (
            <div style={{paddingBottom: 30}} key={type}>
                <Typography variant="h2">
                    <FormattedMessage id={"order.configuration." + type + ".title"}/>
                </Typography>

                <Typography variant="subtitle2">
                    <FormattedMessage id={"order.configuration." + type + ".subtitle"}/>
                </Typography>

                {component}
            </div>
        );
    }

    leadHours = () => {
        return (
            <Grid 
                container 
                rowSpacing={2} 
                columnSpacing={2} 
                alignItems="center">

                <Grid item style={{width: 200}}>
                    <TextField
                        label={this.props.intl.formatMessage({id: "order.configuration.leadHours.label"})}
                        id="order-configuration-lead-hours"
                        inputProps={{maxLength: 3}}
                        size="small" 
                        value={this.state.localConfiguration?.leadHours ?? ""} 
                        onChange={event => this.onLeadHoursChanged(event)}/>
                </Grid>

                <Grid item>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        disabled={this.context.offline || !this.canSaveLeadHours()} 
                        onClick={() => this.sendOrderConfiguration()}>
                        <FormattedMessage id="commons.save.button"/>
                    </Button>
                </Grid>

            </Grid>
        );
    }

    receiverAddresses = () => {
        return (
            <>

                {this.state.localConfiguration?.receiverAddresses?.map(address =>

                    <Grid 
                        key={address} 
                        container 
                        alignItems="center" 
                        columnSpacing={1} 
                        style={{marginBottom: 10}}>

                        <Grid item style={{width: 400}}>
                            <div style={{fontSize: 18, width: 400, wordBreak: "break-all"}}>
                                {address}
                            </div>
                        </Grid>

                        <Grid item>
                            <IconButton
                                onClick={() => this.onAddressRemoved(address)}>
                                <RemoveCircleOutlineIcon color="secondary"/>
                            </IconButton>
                        </Grid>

                    </Grid>
                )}

                <Grid container alignItems="center">

                    <Grid item style={{width: 400}}>
                        <TextField
                            fullWidth={true}
                            label={this.props.intl.formatMessage({id: "order.configuration.receiverAddresses.label"})}
                            id="order-configuration-new-address"
                            size="small" 
                            value={this.state.newAddress} 
                            onChange={event => this.setState({newAddress: event.target.value})}/>
                    </Grid>

                    <Grid item>
                        <IconButton 
                            disabled={!this.isNewAddressValid()} 
                            onClick={() => this.onNewAddressAdded()}>
                            <AddCircleOutlineIcon color={this.isNewAddressValid() ? "primary" : "disabled"}/>
                        </IconButton>
                    </Grid>

                </Grid>

                <Button 
                    variant="contained" 
                    color="primary" 
                    disabled={this.context.offline || !this.receiverAddressesChanged()} 
                    onClick={() => this.sendOrderConfiguration()}
                    style={{marginTop: 20}}>
                    <FormattedMessage id="commons.save.button"/>
                </Button>
            </>
        );
    }

    render() {
        return (
            <>

                <Typography variant="h1">
                    <FormattedMessage id="order.configuration.title"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id="order.configuration.subtitle"/>
                </Typography>

                {this.setting("leadHours", this.leadHours())}

                {this.setting("receiverAddresses", this.receiverAddresses())}

            </>
        );
    }
}

export default withSnackbar(injectIntl(OrderConfiguration));
