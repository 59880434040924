import React, {Component} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {headerColor, white} from "../../../../util/ColorTheme";
import {getMonthValue, INTERVAL_TYPE_KEY, INTERVAL_TYPES, MONTHS} from "../../../../util/Constants";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {SimpleTextCard} from "../../../uiLibrary/SimpleTextCard";
import {withSnackbar} from "notistack";
import BaseDataDialog from "./BaseDataDialog";
import EditIcon from '@mui/icons-material/Edit';

class BaseDataTable extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            baseDataToEdit: null
        }
    }

    onEditOpen = (intervalType, value) => {
        const allCorrespondingBaseData = this.props.baseData.filter(d => {
            if (intervalType === INTERVAL_TYPES.MONTHLY) {
                return d.year === this.props.selectedYear && d.month === getMonthValue(value);
            } else if (intervalType === INTERVAL_TYPES.QUARTERLY) {
                return d.year === this.props.selectedYear && d.month >= value*3-2 && d.month <= value*3
            } else if (intervalType === INTERVAL_TYPES.YEARLY) {
                return d.year === value;
            } else {
                return false;
            }
        });
        let careDays = 0;
        let totalBeds = 0;
        let workWearPeople = 0;
        let inpatientCases = 0;
        let bedsByStation = new Map();
        allCorrespondingBaseData.forEach(d => {
           careDays += d.careDays;
           workWearPeople += d.workWearPeople;
           inpatientCases += d.inpatientCases;
           if (!!d.bedsByStation?.size) {
               d.bedsByStation.forEach((count, stationId) => {
                   totalBeds += count;
                   const currentCount = bedsByStation.get(stationId);
                   bedsByStation.set(stationId, count + (currentCount ?? 0));
               });
           }
        });
        const baseDataToEdit = {
            month: intervalType === INTERVAL_TYPES.MONTHLY ? value : null,
            quarter: intervalType === INTERVAL_TYPES.QUARTERLY ? value : null,
            year: intervalType === INTERVAL_TYPES.YEARLY ? value : this.props.selectedYear,
            careDays,
            totalBeds,
            workWearPeople,
            inpatientCases,
            bedsByStation
        };
        this.setState({baseDataToEdit});
    }

    tableHead = () => {
        const intervalType = this.context.getUserStateValue(INTERVAL_TYPE_KEY);
        // titles has the format [value, label]
        let titles;
        switch (intervalType) {
            case INTERVAL_TYPES.MONTHLY:
                titles = Object.values(MONTHS).map(month => [
                    month,
                    this.props.intl.formatMessage({id: "constants.Month." + month}).substring(0, 3)
                ]);
                break;
            case INTERVAL_TYPES.QUARTERLY:
                titles = [
                    [1, "Q1"],
                    [2, "Q2"],
                    [3, "Q3"],
                    [4, "Q4"]
                ];
                break;
            case INTERVAL_TYPES.YEARLY:
                titles = [...new Set(this.props.baseData.map(d => d.year).sort((y1, y2) => y1 > y2))].map(year => [year, year]);
                break;
            default:
                return null;
        }
        return (
            <TableHead style={{backgroundColor: headerColor}}>

                <TableRow>

                    <TableCell style={{fontWeight: "bold", color: white}}>
                        <FormattedMessage id="cockpit.statistics.baseData.dataType"/>
                    </TableCell>

                    {titles.map(([value, label]) => <TableCell key={label+"-head-cell"} style={{fontWeight: "bold", color: white}}>
                        <div style={{cursor: "pointer"}} onClick={() => this.onEditOpen(intervalType, value)}>
                            {label}
                            <br/>
                            <EditIcon fontSize={"small"}/>
                        </div>
                    </TableCell>)}

                    {intervalType !== INTERVAL_TYPES.YEARLY
                        ? <TableCell style={{fontWeight: "bold", color: white}}>
                            <FormattedMessage id="cockpit.statistics.baseData.sum"/>
                        </TableCell>: null}

                </TableRow>

            </TableHead>
        );
    }

    getBaseDataCellsByKey = (key, stationId) => {
        let cells;
        switch (this.context.getUserStateValue(INTERVAL_TYPE_KEY)) {
            case INTERVAL_TYPES.MONTHLY:
                let yearlySum1 = 0;
                cells = Object.values(MONTHS).map(month => {
                    const data = this.props.baseData.find(d => d.month === getMonthValue(month) && d.year === this.props.selectedYear);
                    let value = 0;
                    if (key === "bedsByStation" && stationId !== null && stationId !== undefined && data) {
                        value = data[key] && data[key].get(stationId) ? data[key].get(stationId) : 0;
                    } else if (key === "bedsByStation" && data?.bedsByStation) {
                        let totalBeds = 0;
                        for (let count of data.bedsByStation.values()) {
                            totalBeds += count;
                        }
                        value = totalBeds;
                    } else if (data) {
                        value = data[key] ? data[key] : 0;
                    }
                    yearlySum1 += value;
                    return (
                        <TableCell>
                            {value}
                        </TableCell>
                    );
                });
                cells = [...cells, <TableCell style={{fontWeight: "bold"}}>{yearlySum1}</TableCell>]
                break;
            case INTERVAL_TYPES.QUARTERLY:
                let yearlySum2 = 0;
                cells = [1, 2, 3, 4].map(quarter => {
                    let sum = 0;
                    this.props.baseData
                        .filter(d => d.month >= quarter*3-2 && d.month <= quarter*3 && d.year === this.props.selectedYear)
                        .forEach(d => {
                            let value;
                            if (key === "bedsByStation" && stationId !== null && stationId !== undefined) {
                                value = d[key] && d[key].get(stationId) ? d[key].get(stationId) : 0;
                            } else if (key === "bedsByStation" && d.bedsByStation) {
                                let totalBeds = 0;
                                for (let count of d.bedsByStation.values()) {
                                    totalBeds += count;
                                }
                                value = totalBeds;
                            } else {
                                value = d[key] ? d[key] : 0;
                            }
                            sum += value;
                        });
                    yearlySum2 += sum;
                    return (
                        <TableCell>
                            {sum}
                        </TableCell>
                    );
                });
                cells = [...cells, <TableCell style={{fontWeight: "bold"}}>{yearlySum2}</TableCell>]
                break;
            case INTERVAL_TYPES.YEARLY:
                cells = [...new Set(this.props.baseData.map(d => d.year))].sort((y1, y2) => y1 > y2).map(year => {
                    let sum = 0;
                    this.props.baseData.filter(d => d.year === year).forEach(d => {
                        let value;
                        if (key === "bedsByStation" && stationId !== null && stationId !== undefined) {
                            value = d[key] && d[key].get(stationId) ? d[key].get(stationId) : 0;
                        } else if (key === "bedsByStation" && d.bedsByStation) {
                            let totalBeds = 0;
                            for (let count of d.bedsByStation.values()) {
                                totalBeds += count;
                            }
                            value = totalBeds;
                        } else {
                            value = d[key] ? d[key] : 0;
                        }
                        sum += value;
                    });
                    return (
                        <TableCell>
                            {sum}
                        </TableCell>
                    );
                });
                break;
            default:
                return null;
        }
        return cells;
    }

    tableBody = () => {
        return (
            <TableBody>

                <TableRow>
                    <TableCell><FormattedMessage id="cockpit.statistics.baseData.dialog.totalBeds"/></TableCell>
                    {this.getBaseDataCellsByKey("bedsByStation", null)}
                </TableRow>

                {this.props.deliveryStations.map((station, index) => <TableRow selected={index % 2 === 0}>
                    <TableCell><FormattedMessage id="cockpit.statistics.baseData.dialog.bedsForStation" values={{name: station.shortDescription}}/></TableCell>
                    {this.getBaseDataCellsByKey("bedsByStation", station.id)}
                </TableRow>)}

                <TableRow selected={this.props.deliveryStations.length % 2 === 0}>
                    <TableCell><FormattedMessage id="cockpit.statistics.baseData.dialog.careDays"/></TableCell>
                    {this.getBaseDataCellsByKey("careDays")}
                </TableRow>

                <TableRow selected={this.props.deliveryStations.length % 2 !== 0}>
                    <TableCell><FormattedMessage id="cockpit.statistics.baseData.dialog.inpatientCases"/></TableCell>
                    {this.getBaseDataCellsByKey("inpatientCases")}
                </TableRow>

                <TableRow selected={this.props.deliveryStations.length % 2 === 0}>
                    <TableCell><FormattedMessage id="cockpit.statistics.baseData.dialog.workWearPeople"/></TableCell>
                    {this.getBaseDataCellsByKey("workWearPeople")}
                </TableRow>

            </TableBody>
        );
    }

    render() {
        if (!this.props.baseData?.length) {
            return <SimpleTextCard show={true} textId="cockpit.statistics.baseData.empty"/>;
        }
        return (
            <>
                <BaseDataDialog
                    key={this.state.baseDataToEdit}
                    contract={this.props.contract}
                    show={!!this.state.baseDataToEdit}
                    baseDataToEdit={this.state.baseDataToEdit}
                    allBaseData={this.props.baseData}
                    deliveryStations={this.props.deliveryStations}
                    close={() => this.setState({baseDataToEdit: null})}
                    reload={() => this.props.reload()}/>

                <TableContainer component={Paper} style={{overflowX: "scroll"}}>

                    <Table aria-label="base-data-table">

                        {this.tableHead()}

                        {this.tableBody()}

                    </Table>

                </TableContainer>
            </>
        );
    }
}

export default injectIntl(withSnackbar(BaseDataTable));
