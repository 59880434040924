import { Collapse, List } from "@material-ui/core";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import {
    BIDDER_COMMUNICATION_ROUTE,
    BIDDER_OFFER_RESULT_ROUTE,
    BIDDER_PROJECT_DETAILS_ROUTE,
    buildRouteWithPathVariable
} from "../../../util/Constants";
import OfferNavBarItem from "./OfferNavBarItem";
import OfferNavBarRound from "./OfferNavBarRound";

class Offer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    render() {
        const projectName = this.props.projects[0].name;
        return <React.Fragment key={"operation_" + this.props.operationId + "_offer_navbar"}>
            <OfferNavBarItem
                level={0}
                key={this.props.operationId+"bidderProject"}
                onClick={() => this.setState({open: !this.state.open})}
                open={this.state.open}
                directTitle={projectName}
                operationId={this.props.operationId}
                icon="icon-building"/>
            <Collapse key={this.props.operationId+"-collapse"} in={this.state.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    <OfferNavBarItem
                        level={1}
                        pathname={buildRouteWithPathVariable(BIDDER_PROJECT_DETAILS_ROUTE, this.props.operationId)}
                        operationId={this.props.operationId}
                        title="navBar.offerProjectDetails.title"/>

                    <OfferNavBarItem
                        level={1}
                        pathname={buildRouteWithPathVariable(BIDDER_COMMUNICATION_ROUTE, this.props.operationId)}
                        operationId={this.props.operationId}
                        title="navBar.bidderCommunication.title"/>

                    {this.props.projects.sort((a,b) => a.createdAt - b.createdAt).map(function (p, index) {
                        p.offerIndex = index;
                        return <OfferNavBarRound
                            key={p.id}
                            project={p}/>})
                    }

                    <OfferNavBarItem
                        level={1}
                        pathname={buildRouteWithPathVariable(BIDDER_OFFER_RESULT_ROUTE, this.props.operationId)}
                        operationId={this.props.operationId}
                        title="navBar.offerResult.title"
                        tooltip="bidder.offerResult.tooltip.title"/>
                </List>
            </Collapse>
        </React.Fragment>
    }

}

export default injectIntl(Offer);
