import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {Card, CircularProgress, Grid, Stack, Tooltip} from "@mui/material";
import {Typography} from "@material-ui/core";
import {GeneralContext} from "../../contexts/GeneralContext";
import {getAsyncCatch} from "../../../services/BackendService";
import CheckItem from "./CheckItem";
import {texisionBlueGrayBright} from "../../../util/ColorTheme";
import {SimpleTextCard} from "../../uiLibrary/SimpleTextCard";
import {getProcedure} from "../../../services/ProcedureService";
import {getContract} from "../../../services/ContractService";
import {getActiveOperation} from "../../../services/OperationService";
import {formatDate} from "../../../util/DocumentUtil";

class PreflightCheck extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            checkCategories: [],
            bindingDeadline: null,
            startOfServiceDate: null,
            hasError: false
        }
    }

    async componentDidMount() {
        await this.getData();
    }

    async getData() {
        const projectId = getActiveOperation(this.context).activeProject.id;
        const procedure = await getProcedure(this.context, this.props, projectId);
        const contract = await getContract(this.context, this.props, projectId);
        const checkCategories = await getAsyncCatch(this.context, "/operation/check/" + this.context.appData.activeOperationId, this.props);

        if (!procedure || !contract || !checkCategories) {
            return this.setState({hasError: true});
        }

        const sortedCheckCategories = checkCategories.sort((el1, el2) => el1.deadline - el2.deadline);

        this.setState({
            checkCategories: sortedCheckCategories,
            bindingDeadline: procedure.bindingDeadline,
            startOfServiceDate: contract.startOfServiceDate
        })
    }

    checkItems = () => {
        return this.state.checkCategories.map(c => <CheckItem key={c.category} checkCategory={c}/>);
    }

    header = () => {
        return (
            <Grid container>
                <Grid item xs>
                    <Typography variant="h1">
                        <FormattedMessage id="cockpit.preflight.title"/>
                    </Typography>
                    <Typography variant="subtitle1">
                        <FormattedMessage id="cockpit.preflight.subtitle"/>
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    render() {

        if (this.state.hasError) {
            return (
                <SimpleTextCard show={true} textId="cockpit.preflight.error"/>
            );
        } else if (this.state.checkCategories.length === 0) {
            return (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <CircularProgress/>
                </div>
            );
        } else {

            return (
                <>

                    {this.header()}

                    <Stack spacing={2}>

                        <Card elevation={4} style={{backgroundColor: texisionBlueGrayBright}}>
                            <Grid container justifyContent="center"
                                alignItems="center">
                                <Grid item xs={6}>
                                    <h2>
                                        <FormattedMessage id="cockpit.preflight.bindingdeadline"/>
                                    </h2>
                                </Grid>
                                <Grid item xs={2}>
                                    <Tooltip title="deadline">
                                        <h2>{formatDate(this.props.intl, this.state.bindingDeadline)}</h2>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                        </Card>

                        {this.checkItems()}

                        <Card elevation={4} style={{backgroundColor: texisionBlueGrayBright}}>
                            <Grid container justifyContent="center"
                                    alignItems="center">
                                <Grid item xs={6}>
                                    <h2>
                                        <FormattedMessage id="cockpit.preflight.header.startofservice"/>
                                    </h2>
                                </Grid>
                                <Grid item xs={2}>
                                    <Tooltip title="deadline">
                                        <h2>{formatDate(this.props.intl, this.state.startOfServiceDate)}</h2>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                        </Card>

                    </Stack>
                    
                </>
            );
        }
    }
}

export default injectIntl(withSnackbar(PreflightCheck));
