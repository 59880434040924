import React, {Component} from "react";
import FooterWrapper from "../FooterWrapper";
import PropTypes from "prop-types";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {withSnackbar} from "notistack";
import {FormattedMessage, injectIntl} from "react-intl";
import InvoiceUploadButton from "../../../cockpit/invoices/InvoiceUploadButton";
import {sortByDate, uploadInvoiceWithoutContract} from "../../../../services/InvoiceService";
import InvoiceComponent from "../../../cockpit/invoices/InvoiceComponent";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {selectDocument} from "../../../../util/DocumentUtil";

class InvoicesPage extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            invoices: props.invoices,
            isLoading: false
        };
    }

    removeInvoice = (invoiceId) => {
        this.setState({invoices: [...this.state.invoices.filter(i => i.id !== invoiceId)]});
    }

    uploadNewInvoice = async (file) => {
        await selectDocument(file, async(document) => {
            this.setState({isLoading: true});
            let invoice = await uploadInvoiceWithoutContract(this.context, this.props, document, this.props.operationId);
            if (invoice) {
                this.setState({invoices: [...this.state.invoices, invoice]});
            }
            this.setState({isLoading: false});
        }, this.props, this.state.invoices.map(i => i.document));
    }

    handleDeliveryPeriodChanged = (invoice, deliveryPeriod) => {
        let invoices = [...this.state.invoices];
        let updated = invoices.find(i => i.id === invoice.id);
        updated.deliveryPeriodVo = deliveryPeriod;
        this.setState({invoices});
    }
    
    isValid = () => {
        return this.state.invoices?.length && !this.state.invoices.find(i => !i.deliveryPeriodVo?.from);
    }

    render() {
        return (
            <FooterWrapper
                invalidData={!this.isValid()}
                onContinue={() => this.props.onContinue(this.state.invoices)}
                onCancel={this.props.onCancel}
                onBack={() => this.props.onBack(this.state.invoices)}>

                <Grid container style={{marginBottom: 30}}>
                    <Grid item xs>
                        {(!this.state.invoices.length || this.state.invoices.length < 3) &&
                            <Typography variant="subtitle2">
                                <FormattedMessage id="project.create.invoice.placeholder"/>
                            </Typography>
                        }
                    </Grid>
                    <Grid item>
                        <InvoiceUploadButton
                            hasCooperationLicense={true}
                            onUpload={this.uploadNewInvoice}
                        />
                    </Grid>
                </Grid>

                {this.state.invoices.length !== 0 && !this.state.isLoading && this.state.invoices
                    .sort(sortByDate)
                    .map(i => <>
                        <InvoiceComponent
                            currency={this.props.currency}
                            key={this.state.invoices.length + "-" + i.id}
                            invoice={i}
                            onDelete={(i) => this.removeInvoice(i)}
                            onChange={(deliveryPeriod) => this.handleDeliveryPeriodChanged(i, deliveryPeriod)}
                        />
                    </>)}

            </FooterWrapper>
        );
    }
}

InvoicesPage.propTypes = {
    onBack: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    operationId: PropTypes.number.isRequired
};

export default withSnackbar(injectIntl(InvoicesPage));
