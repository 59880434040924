import {Button, Grid, TextField, Typography} from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { createErrorMessage, createSuccessMessage } from "../../../../util/Util";
import { formatDate } from "../../../../util/DocumentUtil";
import { GeneralContext } from "../../../contexts/GeneralContext";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import { DIALOG_TYPE_WARNING } from "../../../../util/Constants";
import { texisionRed, white } from "../../../../util/ColorTheme";
import { withSnackbar } from "notistack";
import {isOfferUser} from "../../../../services/UserService";
import {acceptWorkingState, getActiveOperation, rejectWorkingState} from "../../../../services/OperationService";

class ContractAcceptOrRejectHeader extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            showAcceptDialog: false,
            showRejectDialog: false
        }
    }

    accept = async(activeOperation) => {
        this.setState({comment: "", showAcceptDialog: false});
        await acceptWorkingState(
            this.context,
            activeOperation,
            this.state.comment,
            () => createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.accept.successMessage"}), this.props),
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.accept.errorMessage"}), this.props));
    }

    reject = async(activeOperation) => {
        this.setState({comment: "", showRejectDialog: false});
        await rejectWorkingState(
            this.context,
            activeOperation,
            this.state.comment,
            () => createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.reject.successMessage"}), this.props),
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.reject.errorMessage"}), this.props));
    }

    effectiveFromSuffix = (activeOperation) => {
        const effectiveFrom = activeOperation?.workingProject?.effectiveFrom; 
        return effectiveFrom && effectiveFrom >= new Date().getTime()
            ? (" " + this.props.intl.formatMessage({id: "cockpit.contractChanges.effectiveFrom"}) + " "
                + formatDate(this.props.intl, effectiveFrom)) 
            : "";
    }

    render() {

        const activeOperation = getActiveOperation(this.context);

        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showAcceptDialog}
                    titleId="cockpit.contractStatus.dialogTitle.accept"
                    subtitleId="cockpit.contractStatus.dialogSubtitle.accept"
                    replacements={{effectiveFrom: this.effectiveFromSuffix(activeOperation)}}
                    cancelId="commons.no.button"
                    actionId="commons.yes.button"
                    content={<>
                        <TextField
                            label={<FormattedMessage id="cockpit.contractStatus.comment"/>}
                            onChange={(e) => this.setState({comment: e.target.value})}
                            multiline
                            style={{width: '100%'}}/>
                    </>}
                    onCancel={() => this.setState({comment: "", showAcceptDialog: false})}
                    onAction={() => this.accept(activeOperation)}
                    actionDisabled={!isOfferUser(this.context.currentUser)}/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showRejectDialog}
                    titleId="cockpit.contractStatus.dialogTitle.reject"
                    subtitleId="cockpit.contractStatus.dialogSubtitle.reject"
                    replacements={{effectiveFrom: this.effectiveFromSuffix()}}
                    cancelId="commons.no.button"
                    actionId="commons.yes.button"
                    content={
                        <TextField
                            label={<FormattedMessage id="cockpit.contractStatus.comment"/>}
                            onChange={(e) => this.setState({comment: e.target.value})}
                            multiline
                            style={{width: '100%'}}/>
                    }
                    onCancel={() => this.setState({comment: "", showRejectDialog: false})}
                    onAction={() => this.reject(activeOperation)}/>

                <Typography variant="h1">
                    <FormattedMessage id="cockpit.contractStatus.title"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id="cockpit.contractStatus.other.inNegotiation.subtitle"/>
                </Typography>

                <Grid container spacing={2}>

                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.setState({showAcceptDialog: true})}>
                            <FormattedMessage id="cockpit.contractStatus.accept.button"/>
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            variant="contained"
                            style={{backgroundColor: texisionRed, color: white}}
                            color="primary"
                            onClick={() => this.setState({showRejectDialog: true})}>
                            <FormattedMessage id="cockpit.contractStatus.reject.button"/>
                        </Button>
                    </Grid>

                </Grid>
            </>
        );
    }
}

export default injectIntl(withSnackbar(ContractAcceptOrRejectHeader));
