import { Button, Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import defaultUserIcon from '../../resources/default_user_icon.png';
import { texisionGray } from "../../util/ColorTheme";
import {MY_ACCOUNT_ROUTE} from "../../util/Constants";
import { GeneralContext } from "../contexts/GeneralContext";
import {isTender} from "../../util/Util";

class ProjectSummary extends Component {

    static contextType = GeneralContext;

    hasMissingUserData = (user) => {
        return !user.title || !user.firstName || !user.lastName || !user.company 
            || !user.streetAndNumber || !user.zipCode || !user.city || !user.country 
            || !user.phoneNumber || !user.jobTitle || !user.imageVo || !user.vatId;
    }

    render() {

        const user = this.context.currentUser;

        const userImageSrcToDisplay = user?.imageVo?.image?.url ?? defaultUserIcon;

        return (
            <div className="mb-8 pt-4" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                <img 
                    alt="profileImage" 
                    src={userImageSrcToDisplay} 
                    style={{width:'8rem', height: '8rem', borderRadius: '50%', marginInlineEnd: '2.5rem', backgroundColor: texisionGray}}/>

                <div>

                    <Grid container justifyContent="flex-start" alignItems="center">

                        <Grid item style={{marginRight: 20}}>
                            <Typography variant="h2">
                                {this.props.intl.formatMessage({id: "constants.hello"}) + " "
                                
                                + (user.title && user.lastName  
                                    ? (
                                        this.props.intl.formatMessage({id: "constants.Salutation." + user.title}) 
                                        + " " 
                                        + user.lastName
                                    )
                                    : (
                                        this.props.intl.formatMessage({id: "constants.UserRole." + user.role})
                                    )
                                )}
                            </Typography>
                        </Grid>

                    {this.hasMissingUserData(user) 
                    && <Grid item>
                            <Button 
                                variant="text" 
                                color="primary"
                                style={{marginLeft: -8}} 
                                onClick={() => this.props.history.push(MY_ACCOUNT_ROUTE)}>
                                <FormattedMessage id="user.addData.link"/>
                            </Button>
                        </Grid>}

                    </Grid>

                    <div style={{marginTop: '0.2rem'}}>

                        <Typography 
                            variant="h4" 
                            component="span">
                            {user.company}
                        </Typography>

                        {(user.company && user.jobTitle) &&
                            <span style={{display: 'inline-block', padding: '0 0.4rem'}}>|</span>
                        }

                        <Typography variant="h4" component="span" style={{fontWeight: 400}}>
                            {user.jobTitle}
                        </Typography>

                    </div>

                    <div style={{marginTop: '0.7rem', marginBottom: '0.5rem'}}>

                        <Typography 
                            variant="h4" 
                            component="span" 
                            style={{fontWeight: 400}}>
                            <FormattedMessage id={isTender() ? "project.currentTenders" : "project.currentProjects"}/>
                            <span style={{fontWeight: 'bold', paddingInlineStart: '0.7rem'}}>
                                {isTender() ? (this.context.appData.allProjects?.length ?? 0) : (this.context.appData.operations?.length ?? 0)}
                            </span>
                        </Typography>

                        {isTender() && <Typography
                            variant="h4" 
                            component="span" 
                            style={{fontWeight: 400, paddingInlineStart: '1.5rem'}}>
                            <FormattedMessage id="project.finishedProjects"/>
                            <span style={{fontWeight: 'bold', paddingInlineStart: '0.7rem'}}>
                                {this.context.appData.operations?.filter(o => o.offerId !== null && o.offerId !== undefined)?.length ?? 0}
                            </span>
                        </Typography>}
                    </div>
                </div>
            </div>
        );
    }

}

export default injectIntl(withRouter(ProjectSummary));
