import React, {Component} from "react";
import {Stack, Tooltip} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import {texisionOrange, white} from "../../../util/ColorTheme";
import Popover from "@material-ui/core/Popover";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {FormattedMessage, injectIntl} from "react-intl";
import {Avatar, Button} from "@material-ui/core";
import {EVENT_TYPES, LICENSE_TYPES, PAYMENT_MANAGEMENT_ROUTE} from "../../../util/Constants";
import {GeneralContext} from "../../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import {latestActivePayment} from "../../../services/PaymentService";
import {isAdmin} from "../../../services/UserService";

class CalendlyMenu extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        }
    }

    handleClick = (event) => {
        this.setState({
            open: !this.state.open,
            anchorEl: event.currentTarget
        })
    }

    handleClose = () => {
        this.setState({
            open: !this.state.open,
            anchorEl: null
        })
    }

    getIconButton = (hasAvailableSupportTime) => {
        const iconButton = (
            <IconButton
                style={{color: this.props.color ?? white}}
                aria-haspopup="true"
                color="inherit"
                onClick={this.handleClick}>
                <CalendarMonthIcon/>
            </IconButton>
        );
        if (!hasAvailableSupportTime || hasAvailableSupportTime <= 0) {
            return iconButton;
        } else {
            return (
                <div style={{display: "flex", position: "relative"}}>

                    {iconButton}

                    <div style={{position: "absolute", top: 8, right: 8}}>

                        <Avatar style={{backgroundColor: texisionOrange, height: 10, width: 10}}>
                            <div style={{color: texisionOrange, height: 10, width: 10}}/>
                        </Avatar>

                    </div>

                </div>
            );
        }
    }

    getNoAvailableSupportTimeText = () => {
        if (latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE)) {
            return "calendly.noAvailableSupportTime.order.text";
        } else {
            return "calendly.noAvailableSupportTime.publish.text";
        }
    }

    getAvailableSupportTimeText = () => {
        if (latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE)) {
            return "calendly.availableSupportTime.order.text";
        } else {
            return "calendly.availableSupportTime.publish.text";
        }
    }

    render() {
        const operationEventsVo = this.context.operationEvents;
        const totalSupportTime = operationEventsVo?.totalSupportTime;
        const availableSupportTime = operationEventsVo?.availableSupportTime;

        let eventType = EVENT_TYPES.ANALYSIS;
        if (latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE) || latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE)) {
            eventType = EVENT_TYPES.CONSULTING;
        }

        return (
            <>

                <Tooltip title={this.props.intl.formatMessage({id: "calendly.icon.tooltip"})}>
                    {this.getIconButton(availableSupportTime)}
                </Tooltip>

                <Popover
                    open={this.state.open}
                    style={{marginTop: 18}}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    PaperProps={{style: {width: 270}}}
                    transformOrigin={{vertical: 'top', horizontal: 'center'}}
                    onClose={this.handleClose}>

                    <Stack component="div" style={{padding: 20}}>

                        {(!totalSupportTime || totalSupportTime < 0)
                            && <FormattedMessage id="calendly.noLicense.text"/>}

                        {totalSupportTime > 0 && availableSupportTime > 0
                            && <FormattedMessage
                                id={this.getAvailableSupportTimeText()}
                                values={{hours: (availableSupportTime/60.0).toString().replace(".", ",")}}/>}

                        {totalSupportTime > 0 && (!availableSupportTime || availableSupportTime < 0)
                            && <FormattedMessage id={this.getNoAvailableSupportTimeText()}/>}

                        {totalSupportTime > 0 && availableSupportTime > 0 && <Button
                            color="primary"
                            variant="contained"
                            disabled={!availableSupportTime || isAdmin(this.context.currentUser)}
                            style={{marginTop: 20}}
                            onClick={() => {
                                this.handleClose();
                                this.props.showCalendly(eventType);
                            }}>
                            <FormattedMessage id="calendly.book.button"/>
                        </Button>}

                        {(!totalSupportTime || !availableSupportTime) && <Button
                            color="primary"
                            variant="outlined"
                            style={{marginTop: 20}}
                            onClick={() => {
                                this.handleClose();
                                this.props.history.push(PAYMENT_MANAGEMENT_ROUTE);
                            }}>
                            <FormattedMessage id="navBar.profile.showSubscription"/>
                        </Button>}

                    </Stack>

                </Popover>
            </>
        );
    }
}

export default withRouter(injectIntl(CalendlyMenu));
