import { Typography } from "@material-ui/core";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { GeneralContext } from "../../../contexts/GeneralContext";

class ContractWaitingHeader extends Component {

    static contextType = GeneralContext;

    render() {
        return (
            <div>
                <Typography variant="h1">
                    <FormattedMessage id="cockpit.contractStatus.title"/>
                </Typography>
                <Typography variant="subtitle1">
                    <FormattedMessage id="cockpit.contractStatus.other.postProcessing.subtitle"/>
                </Typography>
            </div>
        );
    }
}

export default injectIntl(ContractWaitingHeader);
