import { withSnackbar } from "notistack";
import React, { Component } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Paper,
    Grid,
    Typography
} from "@material-ui/core";
import { GeneralContext } from "../../contexts/GeneralContext";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MaterialTable from "material-table";
import { bodyBackgroundColor, texisionFontColorDark, texisionGreen, texisionRed } from "../../../util/ColorTheme";
import { formatPrice } from "../../../util/Util";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { downloadS3Document, formatDate } from "../../../util/DocumentUtil";
import {
    DIALOG_TYPE_WARNING,
    INVOICE_CONFLICT_TYPE
} from "../../../util/Constants";
import { deleteAsyncCatch, getAsyncCatch } from "../../../services/BackendService";
import InvoiceValidation from "./InvoiceValidation";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@material-ui/core/Tooltip";
import DeliveryPeriodSelectBox from "../../project/projectCreateWithInvoices/forms/DeliveryPeriodSelectBox";
import {getActiveOperation} from "../../../services/OperationService";

class InvoiceComponent extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            contractItems: null,
            openDeleteDialog: false
        };
    }

    loadContractItems = async(projectId, expanded) => {
        if (!expanded || projectId === null || projectId === undefined || this.state.contractItems) {
            return;
        }
        const contractItems = await getAsyncCatch(this.context, "/invoice/contractitems/" + projectId, this.props);
        if (contractItems && contractItems !== true) {
            this.setState({contractItems: new Map(Object.entries(contractItems))});
        }
    }

    deleteInvoice = async() => {
        this.setState({openDeleteDialog: false});
        const response = await deleteAsyncCatch(this.context, "/invoice/" + this.props.invoice.id, this.props);
        if (response) {
            this.props.onDelete(this.props.invoice.id);
        }
    }

    getItemsByConflictType = (conflictType) => {
        return this.props.invoice.invoiceItems.filter(i => i.conflictType === conflictType);
    }

    getNumberOfConflicts = () => {
        const invoice = this.props.invoice;
        let conflicts = this.getItemsByConflictType(INVOICE_CONFLICT_TYPE.UNKNOWN_ARTICLE).length 
            + this.getItemsByConflictType(INVOICE_CONFLICT_TYPE.WRONG_PRICE).length;
        if (invoice.contractPaymentDate > invoice.invoicePaymentDate) {
            conflicts += 1;
        }
        if (invoice.contractTax !== invoice.invoiceTax) {
            conflicts += 1;
        }
        return conflicts;
    }

    getValidationIcon = () => {
        const numberOfConflicts = this.getNumberOfConflicts();
        if (numberOfConflicts === 0) {
            return (
                <CheckCircleOutlineIcon style={{color: texisionGreen, marginRight: 15}}/>
            );
        } else {
            return (
                <Badge badgeContent={numberOfConflicts} color="error" style={{marginRight: 15}}>
                    <ErrorOutlineIcon style={{color: texisionRed}}/>
                </Badge>
            );
        }
    }

    actionButtonsOnSummary = (invoice) => {
        return (
            <>
                <Grid item>
                    <DeliveryPeriodSelectBox
                        invoice={invoice}
                        onChange={(deliveryPeriod) => this.props.onChange(deliveryPeriod)}
                    />
                </Grid>

                <Grid item>
                    <Tooltip title={this.props.intl.formatMessage({id: "commons.download.button"})}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                void downloadS3Document(this.props, invoice.document);
                            }}>
                            <DownloadIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid item>
                    <Tooltip title={this.props.intl.formatMessage({id: "commons.delete.button"})}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({openDeleteDialog: true});
                            }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        );
    }
    
    hasDeliveryPeriod = (invoice) => {
        return invoice.deliveryPeriodVo?.from && invoice.deliveryPeriodVo?.to;
    }

    render() {
        const invoice = this.props.invoice;
        return (
            <>
                <TexisionDialog
                    open={this.state.openDeleteDialog}
                    type={DIALOG_TYPE_WARNING}
                    titleId="cockpit.invoices.delete.dialog.title"
                    subtitleId="cockpit.invoices.delete.dialog.subtitle"
                    cancelId="commons.cancel.button"
                    onCancel={() => this.setState({openDeleteDialog: false})}
                    actionId="commons.delete.button"
                    onAction={() => this.deleteInvoice()}/>

                <Accordion 
                    key={invoice.id}
                    onChange={(_, expanded) => this.loadContractItems(invoice.projectId, expanded)}
                    style={{minWidth: 300, marginLeft: 1, marginRight: 1}}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls={"invoices-item-accordion" + invoice.id}
                        id={"invoices-item-accordion-id-" + invoice.id}>

                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs>
                                <Typography variant="h3">
                                    {formatDate(this.props.intl, invoice.invoiceDate) + " - " 
                                        + invoice.document.filename}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {this.props.showValidation && this.hasDeliveryPeriod(invoice) 
                                    && this.getValidationIcon()}
                            </Grid>
                            {this.actionButtonsOnSummary(invoice)}
                        </Grid>

                    </AccordionSummary>

                    <AccordionDetails>

                        <Grid container>

                            <Grid item xs={12}>
                                <MaterialTable
                                    columns={[
                                        {title: this.props.intl.formatMessage({id: "cockpit.invoices.table.creator"}), field: "creator"},
                                        {title: this.props.intl.formatMessage({id: "cockpit.invoices.table.uploadDate"}), render: (invoice) => formatDate(this.props.intl, invoice.document.lastModified)},
                                        {title: this.props.intl.formatMessage({id: "cockpit.invoices.table.invoiceDate"}), render: (invoice) => formatDate(this.props.intl, invoice.invoiceDate)},
                                        {title: this.props.intl.formatMessage({id: "cockpit.invoices.table.deliveryDate"}), render: (invoice) => formatDate(this.props.intl, invoice.deliveryDate)},
                                        {title: this.props.intl.formatMessage({id: "cockpit.invoices.table.invoicePaymentDate"}), render: (invoice) => formatDate(this.props.intl, invoice.invoicePaymentDate)},
                                        {title: this.props.intl.formatMessage({id: "cockpit.invoices.table.totalNet"}), render: (invoice) => formatPrice(invoice.totalNet) 
                                            + " " + (this.props.currency ?? getActiveOperation(this.context)?.activeProject?.currency)},
                                        {title: this.props.intl.formatMessage({id: "cockpit.invoices.table.totalGross"}), render: (invoice) => formatPrice(invoice.totalGross) 
                                            + " " + (this.props.currency ?? getActiveOperation(this.context)?.activeProject?.currency)}
                                    ]}
                                    data={[invoice]}        
                                    options={{
                                        grouping: false,
                                        filtering: false,
                                        draggable: false, 
                                        search: false, 
                                        paging: false, 
                                        selection: false,
                                        showSelectAllCheckbox: false,
                                        headerStyle: {
                                            fontWeight: 'bold',
                                            backgroundColor: bodyBackgroundColor,
                                            color: texisionFontColorDark
                                        },
                                        showTitle: false, 
                                        toolbar: false
                                    }}
                                    style={{padding: "5px", marginTop: "10px", width: "100%"}}
                                    components={{Container: props => (<Paper {...props} key={"invoice-table-" + invoice.id} elevation={0}/>)}}
                                    localization={{
                                        grouping: {
                                            placeholder: this.props.intl.formatMessage({id: "commons.table.grouping.placeholder"}),
                                            groupedBy: this.props.intl.formatMessage({id: "commons.table.grouping.groupedBy"})
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                {this.props.showValidation 
                                    && (this.hasDeliveryPeriod(invoice)
                                        ? (
                                            <InvoiceValidation
                                                invoice={invoice}
                                                contractItems={this.state.contractItems}
                                            />
                                        )
                                        : (
                                            <Typography variant='subtitle2' style={{padding: 10}}>
                                                <FormattedMessage 
                                                    id='invoice.noDeliveryPeriod.hint'
                                                />
                                            </Typography>
                                        )
                                    )
                                }
                            </Grid>
                        </Grid>
                        
                    </AccordionDetails>

                </Accordion>
            </>
        );
    }
}

export default injectIntl(withSnackbar(InvoiceComponent));
