import React, {Component}  from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';

import '../../../css/NavBar.css';

import { BIDDER_DASHBOARD_ROUTE } from '../../../util/Constants';

import OfferNavBarItem from './OfferNavBarItem';
import Offer from './Offer';
import {GeneralContext} from "../../contexts/GeneralContext";
import NavBar from '../shared/NavBar';


class OfferNavBar extends Component {

    static contextType = GeneralContext;

    sideMenuItems = () => {

        const participatingProjectMap = this.context.appData?.participatingProjectMap;
        const allOffers = this.context.appData?.allOffers;
        return (
            <>
                <OfferNavBarItem
                    level={0}
                    pathname={BIDDER_DASHBOARD_ROUTE}
                    title="navBar.bidderDashboard.title"
                    icon="icon-dashboard"/>

                {participatingProjectMap && participatingProjectMap.size > 0 && Array.from(participatingProjectMap.values())
                    .sort((a,b) => a[0].name.localeCompare(b[0].name))
                    .map(projects =>
                    <Offer
                        key={projects[0].operationId+"-offer_project"}
                        operationId={projects[0].operationId}
                        projects={projects}
                        allOffers={allOffers}/>
                )}

            </>
        );
    }

    render() {
        return (
            <NavBar mobile={this.props.mobile} sideMenuItems={this.sideMenuItems()}/>
        );
    }
}

export default injectIntl(withRouter(OfferNavBar));
