import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {GeneralContext} from "../../contexts/GeneralContext";
import {Button, Grid, TextField, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import ImageCropper from "../../share/ImageCropper";
import {FILE_TYPES} from "../../../util/DocumentUtil";
import {UNIT_IMAGE_DESCRIPTOR, UNIT_LOGO_DESCRIPTOR} from "../../../util/Constants";
import "../../../css/BusinessUnitCreate.css";

class BusinessUnitForm extends Component {

    static contextType = GeneralContext;

    render() {
        return (
            <>
                <Grid item xs={6}>
                    <Grid container direction="column" spacing={1}>

                        <Grid item>

                            <Typography variant="h3" component="span">
                                <FormattedMessage id="businessUnit.create.createCard.h1"/>
                            </Typography>

                            <Typography variant="subtitle2" color="textSecondary" style={{marginBottom: "20px"}}>
                                <FormattedMessage id="businessUnit.create.createCard.h2"/>
                            </Typography>

                            <TextField
                                required
                                id="description"
                                value={this.props.name}
                                label={this.props.intl.formatMessage({id: "businessUnit.create.createCard.hint"})}
                                variant="filled"
                                inputProps={{maxLength: 300}}
                                style={{width: "60%"}}
                                onChange={(e) => this.props.handleNameChange(e.target.value)}/>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container direction="column" spacing={1}>

                        <Grid item>

                            <Typography variant="h3" component="span">
                                <FormattedMessage id="businessUnit.create.address.title"/>
                            </Typography>

                            <Typography variant="subtitle2" color="textSecondary" style={{marginBottom: "20px"}}>
                                <FormattedMessage id="businessUnit.create.address.subtitle"/>
                            </Typography>

                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="street"
                                        value={this.props.streetAndNumber ?? ""}
                                        label={this.props.intl.formatMessage({id: "businessUnit.create.streetAndNumber.hint"})}
                                        variant="filled"
                                        inputProps={{maxLength: 300}}
                                        style={{width: "100%"}}
                                        onChange={(e) => this.props.handleStreetAndNumberChange(e.target.value)}/>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        id="zipCode"
                                        value={this.props.zipCode ?? ""}
                                        label={this.props.intl.formatMessage({id: "businessUnit.create.zipCode.hint"})}
                                        variant="filled"
                                        inputProps={{maxLength: 300}}
                                        style={{width: "100%"}}
                                        onChange={(e) => this.props.handleZipCodeChange(e.target.value)}/>
                                </Grid>

                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        id="city"
                                        value={this.props.city ?? ""}
                                        label={this.props.intl.formatMessage({id: "businessUnit.create.city.hint"})}
                                        variant="filled"
                                        inputProps={{maxLength: 300}}
                                        style={{width: "100%"}}
                                        onChange={(e) => this.props.handleCityChange(e.target.value)}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        disabled
                                        id="country"
                                        value={this.props.intl.formatMessage({id: "constants.country.de"})}
                                        label={this.props.intl.formatMessage({id: "commons.country"})}
                                        variant="filled"
                                        inputProps={{maxLength: 300}}
                                        style={{width: "100%"}}/>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={6} style={{marginTop: "2rem"}}>

                    <Typography variant="h3" component="span">
                        <FormattedMessage id="businessUnit.image.h1"/>
                    </Typography>

                    <Typography variant="subtitle2" color="textSecondary" style={{marginBottom: "20px"}}>
                        <FormattedMessage id="businessUnit.image.h2"/>
                    </Typography>

                    <Grid container direction="column" spacing={1}>

                        <Grid item style={this.props.selectedImage || this.props.imageVo ? {marginBottom: '1rem'} : {} }>

                            {this.props.imageVo && !this.props.selectedImage && <img src={this.props.imageVo.image?.url} width="500px" alt="Unitimage"/>}

                            {this.props.selectedImage && <ImageCropper src={this.props.selectedImage} croppedImageCallback={this.props.handleImageCropped}/>}

                        </Grid>

                        <Grid item>

                            <Button
                                variant="contained"
                                color="primary"
                                component="label"
                                style={{marginRight: "10px"}}>

                                {!this.props.selectedImage && !this.props.imageVo ?
                                    <FormattedMessage id="commons.image.choose"/> :
                                    <FormattedMessage id="commons.image.chooseAnother"/>}

                                <input
                                    type="file"
                                    accept={FILE_TYPES.IMAGE}
                                    style={{display: "none"}}
                                    onClick={(event)=> {event.target.value = null}}
                                    onChange={(e) => this.props.handleImageSelection(e.target.files[0], UNIT_IMAGE_DESCRIPTOR)}/>

                            </Button>

                            {(this.props.selectedImage || this.props.imageVo) &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.props.handleRemoveImage}>
                                    <FormattedMessage id="commons.image.remove"/>
                                </Button>}

                        </Grid>

                        {this.props.selectedImage &&
                            <Grid item>
                                <Typography color="textSecondary" style={{marginTop: "2rem"}}>
                                    <FormattedMessage id="commons.image.infotext"/>
                                </Typography>
                            </Grid>}

                    </Grid>

                </Grid>

                <Grid item xs={6} style={{marginTop: "2rem"}}>

                    <Typography variant="h3" component="span">
                        <FormattedMessage id="businessUnit.logo.h1"/>
                    </Typography>

                    <Typography variant="subtitle2" color="textSecondary" style={{marginBottom: "20px"}}>
                        <FormattedMessage id="businessUnit.logo.subTitle"/>
                    </Typography>

                    <Grid container direction="column" spacing={1}>

                        <Grid item>

                            {this.props.logoVo && !this.props.selectedLogo && <img className="logo-preview" src={this.props.logoVo.image?.url} alt="Unitlogo"/>}

                            {this.props.selectedLogo && <img className="logo-preview" src={URL.createObjectURL(this.props.selectedLogo)} alt="Logo"/>}

                        </Grid>

                        <Grid item>

                            <Button variant="contained" color="primary" component="label" style={{marginRight: "10px"}}>

                                {!this.props.selectedLogo && !this.props.logoVo ?
                                    <FormattedMessage id="businessUnit.logo.choose"/> :
                                    <FormattedMessage id="businessUnit.logo.chooseAnother"/>}

                                <input
                                    type="file"
                                    accept={FILE_TYPES.IMAGE}
                                    style={{display: "none"}}
                                    onClick={(event)=> {event.target.value = null}}
                                    onChange={(e) => this.props.handleImageSelection(e.target.files[0], UNIT_LOGO_DESCRIPTOR)}/>

                            </Button>

                            {(this.props.selectedLogo || this.props.logoVo) &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.props.handleRemoveLogo}>
                                    <FormattedMessage id="businessUnit.logo.remove"/>
                                </Button>}

                        </Grid>

                    </Grid>

                </Grid>
            </>
        );
    }
}

BusinessUnitForm.defaultProps = {
    name: "",
    streetAndNumber: "",
    zipCode: "",
    city: "",
    selectedLogo: null,
    logoVo: null,
    selectedImage: null,
    imageVo: null
}

BusinessUnitForm.propTypes = {
    name: PropTypes.string.isRequired,
    handleNameChange: PropTypes.func.isRequired,
    streetAndNumber: PropTypes.string.isRequired,
    handleStreetAndNumberChange: PropTypes.func.isRequired,
    zipCode: PropTypes.string.isRequired,
    handleZipCodeChange: PropTypes.func.isRequired,
    city: PropTypes.string.isRequired,
    handleCityChange: PropTypes.func.isRequired,
    selectedLogo: PropTypes.object.isRequired,
    logoVo: PropTypes.object.isRequired,
    handleRemoveLogo: PropTypes.func.isRequired,
    selectedImage: PropTypes.object.isRequired,
    imageVo: PropTypes.object.isRequired,
    handleImageSelection: PropTypes.func.isRequired,
    handleRemoveImage: PropTypes.func.isRequired,
    handleImageCropped: PropTypes.func.isRequired
};

export default injectIntl(BusinessUnitForm);
