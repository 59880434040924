import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    ALL_CATEGORIES,
    ARTICLE_OFFSETTINGS,
    DASHBOARD_ROUTE,
    ORDER_DASHBOARD_ROUTE,
    PriceType,
    PREDICTED_STATISTICS_ROUTE
} from "../../../../util/Constants";
import {getAllArticleNumbers} from "../../../pricesheet/PriceSheetUtil";
import {getPriceOfferByProjectId, updatePriceOffer} from "../../../../services/PriceOfferService";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {withSnackbar} from "notistack";
import {FormattedMessage, injectIntl} from "react-intl";
import FooterWrapper from "../FooterWrapper";
import {sortByDate} from "../../../../services/InvoiceService";
import {Card, Grid} from "@mui/material";
import Button from "@material-ui/core/Button";
import {Typography} from "@material-ui/core";
import "../../../../css/ProjectDialog.css";
import Spacer from "../../../uiLibrary/Spacer";
import {LinearProgressIndicator} from "../../../uiLibrary/LinearProgressIndicator";

class PriceSheetPage extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            allArticleNumbers: [],
            priceOffer: null,
            contentVo: null,
            selectedButton: DASHBOARD_ROUTE
        }
    }

    async componentDidMount() {
        await this.reloadPriceSheet(true);
        await this.saveInitialPriceSheet(this.state.priceOffer);
        await this.reloadPriceSheet(false);
        this.setState({isLoading: false});
    }

    saveInitialPriceSheet = async(priceOffer) => {
        const priceUnit = priceOffer.priceUnitMap[this.props.unitId];
        let typeToItemsMap = [];
        if (priceUnit.residentsLaundryGroup?.priceItemMap && Object.values(priceUnit.residentsLaundryGroup.priceItemMap).length) {
            typeToItemsMap.push({type: PriceType.RESIDENTS_LAUNDRY, items: Object.values(priceUnit.residentsLaundryGroup.priceItemMap)});
        }
        if (priceUnit.customerArticleGroup?.priceItemMap && Object.values(priceUnit.customerArticleGroup.priceItemMap).length) {
            typeToItemsMap.push({type: PriceType.CUSTOMER_ARTICLE, items: Object.values(priceUnit.customerArticleGroup.priceItemMap)});
        }
        if (priceUnit.operatingResourceGroup?.priceItemMap && Object.values(priceUnit.operatingResourceGroup.priceItemMap).length) {
            typeToItemsMap.push({type: PriceType.OPERATING_RESOURCE, items: Object.values(priceUnit.operatingResourceGroup.priceItemMap)});
        }
        let rentalLinenGroups = [];
        for (let category of ALL_CATEGORIES) {
            for (let offsetting of [ARTICLE_OFFSETTINGS.DELIVERED_QUANTITY, ARTICLE_OFFSETTINGS.FLAT_RATE, ARTICLE_OFFSETTINGS.SPLIT_PRICE]) {
                if (priceUnit.articleGroupMap[category] && priceUnit.articleGroupMap[category][offsetting]) {
                    rentalLinenGroups = [...rentalLinenGroups, priceUnit.articleGroupMap[category][offsetting]];
                }
            }
        }
        let articleQuantityItems = [];
        let articleWeeklyItems = [];
        for (let rentalLinenGroup of rentalLinenGroups) {
            if (rentalLinenGroup.priceItemMap) {
                for (let priceItem of Object.values(rentalLinenGroup.priceItemMap)) {
                    if (priceItem.type === PriceType.ARTICLE_WEEKLY) {
                        articleWeeklyItems.push(priceItem);
                    } else if (priceItem.type === PriceType.ARTICLE_QUANTITY) {
                        articleQuantityItems.push(priceItem);
                    }
                }
            }
        }
        if (articleQuantityItems.length) {
            typeToItemsMap.push({type: PriceType.ARTICLE_QUANTITY, items: articleQuantityItems});
        }
        if (articleWeeklyItems.length) {
            typeToItemsMap.push({type: PriceType.ARTICLE_WEEKLY, items: articleWeeklyItems});
        }
        
        await Promise.all(
            typeToItemsMap.map(entry => updatePriceOffer(
                this.context, this.props, null, this.props.project.id, entry.type, 
                entry.items, getAllArticleNumbers(priceOffer), null, true, 
                priceOffer.priceUnitMap)));
    }

    reloadPriceSheet = async(initializeArticleNumbersAndPrices) => {
        const priceOfferResultVo = await getPriceOfferByProjectId(this.context, this.props, this.props.project.id);
        let priceOffer = priceOfferResultVo?.priceOfferVos[0];
        if (initializeArticleNumbersAndPrices) {
            priceOffer = this.insertArticleNumbersAndPrices(priceOffer);
        }
        const contentVo = priceOfferResultVo?.contentVos[0];
        const allArticleNumbers = getAllArticleNumbers(priceOffer);
        this.setState({allArticleNumbers, contentVo, priceOffer});
    }

    getAllArticleNumbers = (objectId) => {
        return Array.from(this.props.articleMapping.values())
            .filter(articleKit => articleKit.articleObject.id === objectId
                && (articleKit.priceType === PriceType.ARTICLE_WEEKLY || articleKit.priceType === PriceType.ARTICLE_QUANTITY))
            .map(articleKit => articleKit.articleNumber);
    }

    insertArticleNumbersAndPrices = (priceOffer) => {
        let invoiceItems = [];
        for (let invoice of this.props.invoices.sort(sortByDate)) {
            invoiceItems = [...invoiceItems, ...invoice.invoiceItems];
        }
        let priceUnit = priceOffer.priceUnitMap[this.props.unitId];

        for (let articleKit of this.props.articleMapping.values()) {
            const articleNumber = articleKit.articleNumber;
            let articleNumbers = [articleNumber];
            const category = articleKit.articleObject.category;
            let objectId = articleKit.articleObject?.id;

            // find in sorted list to get the newest price
            const invoiceItem = invoiceItems.find(i => i.articleNumber === articleNumber);
            let group;
            switch (articleKit.priceType) {
                case PriceType.ARTICLE_WEEKLY:
                    articleNumbers = this.getAllArticleNumbers(objectId);
                    group = priceUnit.articleGroupMap[category][ARTICLE_OFFSETTINGS.FLAT_RATE];
                    break;
                case PriceType.ARTICLE_QUANTITY:
                    articleNumbers = this.getAllArticleNumbers(objectId);
                    group = priceUnit.articleGroupMap[category][ARTICLE_OFFSETTINGS.DELIVERED_QUANTITY];
                    break;
                case PriceType.CUSTOMER_ARTICLE:
                    group = priceUnit.customerArticleGroup;
                    break;
                case PriceType.OPERATING_RESOURCE:
                    group = priceUnit.operatingResourceGroup;
                    objectId = articleKit.articleObject.operatingResourceId;
                    break;
                case PriceType.RESIDENTS_LAUNDRY:
                    group = priceUnit.residentsLaundryGroup;
                    break;
                case PriceType.SPECIAL_SERVICE:
                    group = priceUnit.specialServiceGroup;
                    break;
                default:
                    break;
            }
            let priceItem = (new Map(Object.entries(group.priceItemMap))).get(objectId.toString());
            priceItem.articleNumbers = articleNumbers;
            priceItem.alternateDescription = invoiceItem.articleName;
            priceItem.price = invoiceItem.price;
        }
        return priceOffer;
    }

    handleSelectedRedirectChanged = (selectedButton) => {
        this.setState({selectedButton});
    }

    getButtonColor = (buttonId) => {
        return this.state.selectedButton === buttonId ? "primary" : "inherit";
    }

    render() {
        const validData = this.state.priceOffer && this.state.contentVo;
        return (
            <FooterWrapper
                invalidData={!validData}
                onContinue={() => {
                    this.setState({isLoading: true});
                    this.props.onContinue(this.state.selectedButton);}}
                onCancel={this.props.onCancel}
                continueButtonTitle="commons.save.button"
                onBack={this.props.onBack}>
                <div>
                    <LinearProgressIndicator active={this.state.isLoading}/>
                    {validData &&
                        <Grid container className="redirectContainer">
                            <Grid item xs={12}>
                                <Typography variant="h3">
                                    <FormattedMessage id="project.create.redirect.info.title"/>
                                </Typography>
                                <Typography variant="subtitle">
                                    <FormattedMessage id="project.create.redirect.info.text" values={{
                                        invoices: this.props.invoices.length,
                                        articles: this.props.articleMapping.size
                                    }}/>
                                </Typography>
                                <Spacer size="12px"/>
                                <Typography variant="subtitle">
                                    <FormattedMessage id="project.create.redirect.info.question"/>
                                </Typography>
                            </Grid>
                            {[{DASHBOARD_ROUTE}, {ORDER_DASHBOARD_ROUTE}, {PREDICTED_STATISTICS_ROUTE}].map((route) => {
                                const identifier = Object.keys(route)[0];
                                const redirect = Object.values(route)[0];
                                return <Grid item xs={4} className="redirectItem">
                                    <Card className="redirectCard">
                                        <div className="redirectDiv">
                                            <Button
                                                key={"redirect-button-" + identifier}
                                                variant="contained"
                                                color={this.getButtonColor(redirect)}
                                                onClick={() => this.handleSelectedRedirectChanged(redirect)}>
                                                <FormattedMessage id={"project.create." + identifier + ".button"}/>
                                            </Button>
                                        </div>
                                        <ul>
                                            <li><FormattedMessage id={"project.create." + identifier + ".list.a"}/></li>
                                            <li><FormattedMessage id={"project.create." + identifier + ".list.b"}/></li>
                                        </ul>
                                    </Card>
                                </Grid>
                            })}
                        </Grid>}
                </div>
            </FooterWrapper>
        );
    }
}

PriceSheetPage.propTypes = {
    onBack: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    invoices: PropTypes.array.isRequired,
    articleMapping: PropTypes.object.isRequired
};

export default injectIntl(withSnackbar(PriceSheetPage));
