import React, {Component} from 'react';

import {injectIntl, FormattedMessage} from 'react-intl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {putAsyncCatch, getAsync} from '../../../../../services/BackendService';
import '../../../../apps/App.css';
import {withSnackbar} from 'notistack';
import LogisticsProfessionalCard from './LogisticsProfessionalCard';
import {withRouter} from "react-router-dom";
import {GeneralContext} from "../../../../contexts/GeneralContext";
import {updateValidationMap} from "../../../../../services/ProjectService";

class LogisticsProfessionalAssignment extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            allAssignedProfessionalGroupIds: [],
            selectedProfessionalGroupIds: new Set()
        }
    }

    async componentDidMount() {
        await this.reloadData();
    }

    componentDidUpdate() {
        this.context.setUnsavedChanges(this.newProfessionalGroupsSelected() && this.props.allProfessionalGroups.length !== 0);
    }

    reloadData = async() => {
        let allIds = await this.loadAllAssignedProfessionalGroups();
        let selectedIds = new Set();
        for (let assignedId of allIds) {
            selectedIds.add(assignedId);
        }
        this.setState({allAssignedProfessionalGroupIds: allIds, selectedProfessionalGroupIds: selectedIds});
    }

    loadAllAssignedProfessionalGroups = async() => {
        let response = await getAsync("/assignments/professional/" + this.props.deliveryAddress.id);
        if (response?.status === 200 && response.data) {
            return response.data.ids;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    newProfessionalGroupsSelected = () => {
        return this.state.allAssignedProfessionalGroupIds.sort().join(',') 
            !== Array.from(this.state.selectedProfessionalGroupIds).sort().join(',');
    }

    handleSaveAssignment = async () => {
        const deliveryPointId = this.props.deliveryAddress?.id;
        const addressVersion = this.props.addressVersion;

        let professionalGroupsToAssign = {ids: []};
        for (let groupId of this.state.selectedProfessionalGroupIds) {
            professionalGroupsToAssign.ids.push(groupId);
        }

        let responseData = await putAsyncCatch(this.context,"/assignments/professional/"
            + this.context.appData.activeProjectId + "/" + deliveryPointId + "/" + addressVersion, professionalGroupsToAssign, this.props);
        if (responseData) {
            await this.props.reloadAddressVersion();
            await this.reloadData();
            this.props.onSave();
            await updateValidationMap(this.context);
        }
        this.setState({});
    }

    handleCheckboxChanged = (group, checked) => {
        group.assigned = checked;
        let selectedIds = this.state.selectedProfessionalGroupIds;
        if (!checked) {
            selectedIds.delete(group.id);
        } else {
            selectedIds.add(group.id);
        }
        this.setState({selectedProfessionalGroupIds: selectedIds});
    }

    render() {

        let allProfessionalGroups = this.props.allProfessionalGroups;

        if (!allProfessionalGroups?.length) {
            return null;
        }

        let professionalGroupsToSet = allProfessionalGroups.map(group => {
            group.assigned = this.state.selectedProfessionalGroupIds.has(group.id);
            return group;
        });

        return (
            <>
                <Grid container alignItems="flex-end" style={{paddingBottom: 10}}>

                    <Grid item xs>

                        <Typography variant="h3">
                            {this.props.intl.formatMessage({id: "professionalDeliveryPointAssignment.h1"}) + " " + this.props.deliveryAddress.description}
                        </Typography>

                        <Typography variant="subtitle2" style={{marginBottom: "20px"}}>
                            <FormattedMessage id="professionalDeliveryPointAssignment.h2"/>
                        </Typography>

                    </Grid>

                    <Grid item>

                        <Button 
                            color="primary" 
                            variant="contained" 
                            onClick={() => this.handleSaveAssignment()}
                            disabled={this.props.allProfessionalGroups.length === 0
                                || !this.newProfessionalGroupsSelected()
                                || this.props.readOnly}>
                            <FormattedMessage id="commons.save.button"/>
                        </Button>

                    </Grid>

                </Grid>

                <Grid container spacing={2}> {

                    professionalGroupsToSet.map((group) => (
                        <Grid key={group.id} item xs style={{maxWidth: "280px", maxHeight: "280px"}}>
                            <LogisticsProfessionalCard
                                professionalGroup={group} 
                                handleCheckboxChanged={this.handleCheckboxChanged}
                                readOnly={this.props.readOnly}/>
                        </Grid>
                    ))}

                </Grid>
            </>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(LogisticsProfessionalAssignment)));
