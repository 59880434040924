import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Accordion, AccordionDetails, AccordionSummary, CircularProgress, IconButton, Stack, Tooltip} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {getSimpleDate} from "../../../util/DateUtil";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import OperationDifferencesTable from "../contractchanges/OperationDifferencesTable";
import {formatDate, getExportType} from "../../../util/DocumentUtil";
import {Button, Grid} from "@material-ui/core";
import {exportBillOfQuantities} from "../../../services/ExportService";
import {DIALOG_TYPE_ERROR} from "../../../util/Constants";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import {GeneralContext} from "../../contexts/GeneralContext";

class ContractHistoryItem extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showLockedDialog: false,
            isExporting: false
        };
    }

    downloadBillOfQuantities = async() => {
        this.setState({isExporting: true});
        await exportBillOfQuantities(this.context, this.props.operationDifference.projectTargetId,
            getExportType(this.context), () => this.setState({showLockedDialog: true}));
        this.setState({isExporting: false});
    }

    getFutureDateSuffix = (date) => {
        return " " + this.props.intl.formatMessage({id: "cockpit.contractChanges.effectiveFrom"}) + " " + getSimpleDate(date);
    }

    getPastDateSuffix = (date) => {
        if (!date || date.getTime() === 1) {
            return this.props.intl.formatMessage({id: "contract.history.startDate"}) + ")";
        }
        return formatDate(this.props.intl, date.getTime()) + " )";
    }

    getTitle = () => {

        const startDate = new Date(this.props.operationDifference.effectiveFrom);
        const today = new Date();

        if (startDate > today) {
            return (
                <>
                    <Tooltip title={this.props.intl.formatMessage({id: "contract.history.tooltip"})}>
                        <IconButton>
                            <InfoOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                    <h2 style={{color: "grey"}}>
                        {this.props.intl.formatMessage({id: "operation.difference.table.title"}) + this.getFutureDateSuffix(startDate)}
                    </h2>
                </>
            );
        } else if (startDate < today) {
            return (
                <h2>
                    {this.props.intl.formatMessage({id: "operation.difference.export.title.h1"}) + this.getPastDateSuffix(startDate)}
                </h2>
            );
        }
    }

    render() {

        if (this.props.operationDifference) {

            return (
                <>

                    <TexisionDialog
                        type={DIALOG_TYPE_ERROR}
                        open={this.state.showLockedDialog}
                        titleId="export.locked.title"
                        subtitleId="export.locked.subtitle"
                        actionId="commons.okay.button"
                        onAction={() => this.setState({showLockedDialog: false})}/>

                    <Accordion>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs>
                                    {this.getTitle()}
                                </Grid>
                                <Grid item style={{marginRight: 15}}>
                                    <Button
                                        color="primary"
                                        disabled={this.state.isExporting}
                                        variant="contained"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.downloadBillOfQuantities()
                                        }}>
                                        {this.state.isExporting
                                            ? <CircularProgress size={20} style={{color: "white"}}/>
                                            : <FormattedMessage id="billOfQuantities.export.text"/>
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionSummary>

                        <AccordionDetails>

                            <Stack spacing={1} width={"100%"}>
                                <OperationDifferencesTable operationDifference={this.props.operationDifference}/>
                            </Stack>

                        </AccordionDetails>

                    </Accordion>

                </>
            );

        } else {
            return null;
        }
    }
}

export default injectIntl(ContractHistoryItem);
