import React, {Component} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import ArticleNameSwitch from "../../../share/ArticleNameSwitch";
import InvoiceStatisticsContainer from "./InvoiceStatisticsContainer";
import {white} from "../../../../util/ColorTheme";
import InvoiceStatisticsItemDetails from "./InvoiceStatisticsItemDetails";
import InvoiceStatisticsDetails from "./InvoiceStatisticsDetails";
import {SimpleTextCard} from "../../../uiLibrary/SimpleTextCard";
import {latestActivePayment} from "../../../../services/PaymentService";
import {LICENSE_TYPES} from "../../../../util/Constants";
import {getActiveOperation} from "../../../../services/OperationService";
import {getAsyncCatch} from "../../../../services/BackendService";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {withSnackbar} from "notistack";
import InvoiceStatisticCategoryDetails from "./InvoiceStatisticCategoryDetails";

class InvoiceStatistics extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            rootNode: null,
            invoices: [],
            selectedInvoice: null,
            selectedInvoiceItem: null,
            selectedStatisticItem: null,
            selectedStatisticNode: null,
            hasDiagramData: false,
            statisticItemMap: new Map(),
            contractItemMap: new Map()
        }
    }

    async componentDidMount() {
        if (latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE)) {
            await this.loadContractItemsAndInvoices(getActiveOperation(this.context)?.activeProject?.id);
        }
    }

    loadContractItemsAndInvoices = async(projectId) => {
        const container = await getAsyncCatch(this.context, "/invoice/statistics/" + projectId, this.props);
        if (container) {
            const rootNode = container.rootNode;
            const statisticItemMap = this.convertJsonToMap(container.statisticItemMap);
            const contractItemMap = new Map(Object.entries(container.contractItemMap));
            const invoices = container.invoices;
            this.setState({rootNode, statisticItemMap, contractItemMap, invoices});
        }
    }

    convertJsonToMap = (json) => {
        return new Map(
            Object.entries(json).map(([key, value]) => [
                Number(key),
                new Map(Object.entries(value)),
            ])
        );
    };

    findNode = (key, node) => {
        if (node.key === key) {
            return node;
        }
        if (!!node.children) {
            for (const child of node.children) {
                let result = this.findNode(key, child);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    onItemSelected = async(key, invoiceId) => {
        if (!key || invoiceId === null || invoiceId === undefined) {
            this.setState({selectedInvoice: null, selectedInvoiceItem: null, selectedStatisticItem: null});
            return;
        }
        const selectedInvoice = this.state.invoices?.find(invoice => invoice.id === invoiceId);
        const selectedInvoiceItem = selectedInvoice?.invoiceItems?.find(item => item.articleNumber === key || item.articleName === key);
        const selectedStatisticItem = this.state.statisticItemMap?.get(invoiceId)?.get(key);
        const selectedStatisticNode = this.findNode(key, this.state.rootNode);

        this.setState({selectedInvoice, selectedInvoiceItem, selectedStatisticItem, selectedStatisticNode});
    }

    render() {
        const { hasDiagramData, rootNode, invoices, contractItemMap, statisticItemMap,
            selectedInvoice, selectedInvoiceItem, selectedStatisticItem, selectedStatisticNode } = this.state;

        const selectedContractItem = selectedInvoiceItem?.articleNumber
            && this.state.contractItemMap?.get(selectedInvoiceItem.articleNumber);

        const hasCooperationLicense = latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE);

        return (
            <>
                <Typography variant="h1">
                    <FormattedMessage id="cockpit.statistics.invoices.title"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id="cockpit.statistics.invoices.subtitle"/>
                </Typography>

                <SimpleTextCard show={!hasCooperationLicense} textId="cockpit.statistics.noBidder"/>

                {hasCooperationLicense && <>

                    <Grid container key={"invoice-statistics-" + invoices?.length} spacing={2}>

                        <ArticleNameSwitch/>

                        <Grid item xs={12}>
                            <InvoiceStatisticsContainer
                                rootNode={rootNode}
                                invoices={invoices}
                                statisticItemMap={statisticItemMap}
                                contractItemMap={contractItemMap}
                                selectedInvoiceId={selectedInvoice?.id}
                                selectedInvoiceItem={selectedInvoiceItem}
                                onItemSelected={(articleNumber, invoiceId) => this.onItemSelected(articleNumber, invoiceId)}
                                onDiagramDataChange={(hasDiagramData) => this.setState({hasDiagramData})}/>
                        </Grid>

                        {hasDiagramData && <>

                            <Grid item xs={12} sm={12} md={12} lg={6} style={{backgroundColor: white, minWidth: 300}}>
                                {selectedContractItem
                                    && <InvoiceStatisticsItemDetails
                                        contractItem={selectedContractItem}
                                        invoiceItem={selectedInvoiceItem}/>}
                                {(!selectedContractItem && selectedStatisticNode)
                                    && <InvoiceStatisticCategoryDetails
                                        statisticNode={selectedStatisticNode}
                                        statisticItem={selectedStatisticItem}/>}
                            </Grid>

                            <Grid item/>

                            <Grid item xs={12} sm={12} md={12} lg style={{backgroundColor: white, minWidth: 300}}>
                                <InvoiceStatisticsDetails
                                    invoice={selectedInvoice}/>
                            </Grid>
                        </>}

                    </Grid>

                </>}
            </>
        );
    }
}

export default injectIntl(withSnackbar(InvoiceStatistics));
