import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {Grid, Typography} from "@material-ui/core";
import PublishBidderSelection from "./PublishBidderSelection";
import texisionLogo from "../../../resources/texision_logo.svg";
import {LOGIN_ROUTE} from "../../../util/Constants";
import {Link} from "react-router-dom";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";

class PublicLaundryRadar extends Component {

    render() {
        return (
            <Grid container style={{justifyContent: "space-between", height: "100vh", width: "100vw", marginTop: "50px", padding: "50px"}}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} style={{textAlign:"center"}}>
                            <TrackChangesIcon fontSize="large" color="primary"/>
                            <Typography variant="h1">
                                <FormattedMessage id="laundryRadar.title"/>
                            </Typography>

                            <Typography variant="subtitle1">
                                <FormattedMessage id="laundryRadar.subtitle"/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <PublishBidderSelection
                                readOnly={true}
                                selectedBidders={[]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{textAlign: "center", marginTop: "40px"}}>
                    <Link to={{pathname: LOGIN_ROUTE}} >
                        <img
                            src={texisionLogo}
                            style={{maxWidth: "10rem", width: "100%"}}
                            alt="Texision"/>
                    </Link>
                    <div
                        style={{fontSize: '15px', fontWeight: "bold", marginBottom: "40px"}}>
                        <FormattedMessage id="texision.slogan"/>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withSnackbar(injectIntl(PublicLaundryRadar));
