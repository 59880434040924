import { Grid, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { EXPORT_TYPE_MERGE } from "../../util/Constants"
import { formatPrice } from "../../util/Util";

const PriceUnitSummary = ({currency, exportType, sumGross, sumNet}) => {

    const intl = useIntl();

    if (exportType === EXPORT_TYPE_MERGE) {
        return null;
    }

    return (
        <>
            <Grid container justifyContent="flex-start" spacing={3}>
                <Grid item style={{marginTop: 2}}>
                    <Typography variant="h3">
                        {intl.formatMessage({id: "bidder.priceSheet.concatenate.netto.title"}) + ": "}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        {(sumNet ? formatPrice(sumNet) : "0,00") + " " + currency}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-start" spacing={3}>
                <Grid item style={{marginTop: 2}}>
                    <Typography variant="h3">
                        {intl.formatMessage({id: "bidder.priceSheet.concatenate.brutto.title"}) + ": "}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        {(sumGross ? formatPrice(sumGross) : "0,00") + " " + currency}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

export {
    PriceUnitSummary
}
