import {
    Card, CardContent, Grid, Icon, IconButton, Paper,
    Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TableSortLabel,
    Typography,
    Tooltip, Button, Avatar, TextField
} from "@material-ui/core";
import Select from "react-select";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {getAddresses, sortAsc} from "../../util/Util";
import {bodyBackgroundColor, texisionOrange} from '../../util/ColorTheme';

import "../../css/BidderDashboard.css";
import {format} from 'date-fns';
import {
    PROJECT_STATUS_IS_PUBLISHED,
    PROJECT_FILTER_ALL,
    PROJECT_FILTER_NONE,
    PROJECT_FILTER_OPEN,
    PROJECT_FILTER_RUNNING,
    PROJECT_FILTER_CLOSED,
    buildRouteWithPathVariable,
    BIDDER_PROJECT_DETAILS_ROUTE,
    LAST_VISIT_BIDDER_DASHBOARD
} from '../../util/Constants';
import LinearProgress from '@material-ui/core/LinearProgress';
import {GeneralContext} from "../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import { withSnackbar } from "notistack";
import { isAdmin, isBidder } from "../../services/UserService";
import {getPublishedProjects} from "../../services/ProjectService";
import {getDistances} from "../../services/LocationService";
import {downloadS3Document} from "../../util/DocumentUtil";
import {getProjectDocumentZip} from "../../services/ExportService";

class BidderDashboard extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            lastVisit: null,
            distances: [],
            isExporting: false,
            sortBy: 1,
            sortOrder: 'asc',
            filter: PROJECT_FILTER_NONE,
            zipcode: ""
        };
    }

    async componentDidMount() {
        await this.setState({lastVisit: localStorage.getItem(LAST_VISIT_BIDDER_DASHBOARD), projects: await getPublishedProjects(this.context, this.props)});
        localStorage.setItem(LAST_VISIT_BIDDER_DASHBOARD, new Date().getTime());
    }

    loadDistances = async() => {
        let zipCodes = [];
        for (let project of this.state.projects) {
            for (let unit of project.businessUnits.units) {
                if (!zipCodes.includes(unit.zipCode)) {
                    zipCodes.push(unit.zipCode);
                }
            }
        }
        const distances = await getDistances(this.context, this.props, this.state.zipcode, zipCodes);
        const uniqueDistances = distances.filter((obj, pos, allZipDistances) => {
            return allZipDistances.map(distanceVo => distanceVo.targetCoordinates?.zipCode).indexOf(obj.targetCoordinates?.zipCode) === pos;
        });
        this.setState({distances: uniqueDistances});
    }

    downloadDocuments = (projectId) => {
        getProjectDocumentZip(this.context, this.props, projectId)
            .then((s3Object) => downloadS3Document(this.props, s3Object));
    }

    formatDate = (milliseconds) => {
        if (!milliseconds || milliseconds < 1) return this.props.intl.formatMessage({id: "procedure.document.noDate"});
        return format(milliseconds, "dd.MM.yyyy");
    }

    downloadButton = (project, disabled) => {
        return (
            <Tooltip title={disabled ? this.props.intl.formatMessage({id: "bidderDashboard.download.tooltip"}) : ""}>
                <div>
                    <IconButton 
                        onClick={() => {this.downloadDocuments(project.id)}}
                        disabled={disabled}>
                        <Icon>download</Icon>
                    </IconButton>
                </div>
            </Tooltip>
        );
    }

    detailsButton = (project) => {
        return (
            <Button
                color="primary"
                style={{whiteSpace: "nowrap"}}
                variant="contained"
                onClick={() => {
                    this.context.setActiveProjectId(project.id);
                    this.props.history.push(buildRouteWithPathVariable(BIDDER_PROJECT_DETAILS_ROUTE, project.operationId));
                }}>
                <FormattedMessage id="bidderDashboard.participate.button"/>
            </Button>
        );
    }

    changeSorting = (column) => {
        let sortOrder = this.state.sortOrder;
        if (column === this.state.sortBy) {
            sortOrder = sortOrder === "asc" ? "desc" : "asc";
        } else {
            sortOrder = "asc";
        }
        this.setState({
            sortOrder: sortOrder,
            sortBy: column
        });
    }

    compareProjects = (a, b) => {
        const isAsc = this.state.sortOrder === "asc";
        switch (this.state.sortBy) {
            case 1:
                return isAsc ? sortAsc(a.name, b.name) : sortAsc(b.name, a.name);
            case 2:
                return isAsc ? sortAsc(getAddresses(a, this), getAddresses(b, this)) : sortAsc(getAddresses(b, this), getAddresses(a, this));
            case 3:
                return isAsc
                    ? sortAsc(this.getMaximumDistance(a.businessUnits.units), this.getMaximumDistance(b.businessUnits.units))
                    : sortAsc(this.getMaximumDistance(b.businessUnits.units), this.getMaximumDistance(a.businessUnits.units));
            case 4:
                return isAsc ? a.procedure.secondOfferDeadline > b.procedure.secondOfferDeadline : b.procedure.secondOfferDeadline > a.procedure.secondOfferDeadline;
            case 5:
                return isAsc ? a.procedure.startOfServiceDate > b.procedure.startOfServiceDate : b.procedure.startOfServiceDate > a.procedure.startOfServiceDate;
            default:
                return 0;
        }
    }

    getFilterState = (project) => {
        if (project.status === PROJECT_STATUS_IS_PUBLISHED) {
            if (project.procedure.isOpenToParticipate) {
                return PROJECT_FILTER_OPEN;
            } else {
                return PROJECT_FILTER_RUNNING;
            }
        } else {
            return PROJECT_FILTER_CLOSED;
        }
    }

    checkFilterState = (project) => {
        return this.state.filter === PROJECT_FILTER_NONE || this.state.filter === this.getFilterState(project);
    }

    handleZipcodeChange = (e) => {
        const newZipcode = e.target.value;
        const zipcodeRegex = /^[0-9]*$/;
        if (newZipcode === "" || zipcodeRegex.test(newZipcode)) {
            this.setState({zipcode: newZipcode});
        }
    }

    showFilter = () => {
        let options = [];
        for (let i = 0; i < PROJECT_FILTER_ALL.length; i++) {
            let filterState = PROJECT_FILTER_ALL[i];
            options[i] = { 
                value: filterState, 
                label: this.props.intl.formatMessage({id: "bidderDashboard.filter.status." + filterState})
            };
        }
        return (
            <Grid container className="filterCard" alignItems="center" justifyContent="space-between">
                <Grid item style={{marginRight: 30, marginBottom: 20}}>
                    <Grid container wrap="nowrap" alignItems="center">
                        <Grid item>
                            <div className="filterLabel">
                                <span><FormattedMessage  id="bidderDashboard.filter.status.label"/></span>:
                            </div>
                        </Grid>
                        <Grid item>
                            <Select className="filterSelect"
                                    id="bidder-dashboard-filter-select"
                                    value={{
                                        value: this.state.filter,
                                        label: this.props.intl.formatMessage({id: "bidderDashboard.filter.status." + this.state.filter})
                                    }}
                                    onChange={(state) => this.setState({filter: state.value})}
                                    options={options}>
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{marginBottom: 20}}>
                    <Grid container wrap="nowrap" alignItems="center">
                        <Grid item>
                            <div className="filterLabel">
                                <span><FormattedMessage id="bidderDashboard.zipcode.label"/></span>:
                            </div>
                        </Grid>
                        <Grid item style={{marginRight: 20}}>
                            <TextField
                                value={this.state.zipcode}
                                onChange={this.handleZipcodeChange}
                                inputProps={{maxLength: 5}}
                                label={this.props.intl.formatMessage({id: "bidderDashboard.zipcode.placeholder"})}
                                variant="filled"/>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.loadDistances()}
                                disabled={this.state.zipcode?.length !== 5}>
                                <FormattedMessage id="commons.calculate"/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        );
    }

    getProjectName = (project) => {
        const lastVisit = this.state.lastVisit;
        if (!lastVisit || parseInt(lastVisit) > project.procedure?.firstCallForSubmissionDate) {
            return project.name;
        } else {
            return (
                <Grid container alignItems="center" spacing={2} wrap="nowrap">
                    <Grid item>
                        <Avatar style={{backgroundColor: texisionOrange, height: 10, width: 10}}>
                            <div style={{color: texisionOrange, height: 10, width: 10}}/>
                        </Avatar>
                    </Grid>
                    <Grid item>
                        {project.name}
                    </Grid>
                </Grid>
            )
        }
    }

    getMaximumDistance = (units) => {
        let maxDistance;
        let distances = [];
        for (let unit of units) {
            const distanceVo = this.state.distances.find(d => d.targetCoordinates?.zipCode === unit.zipCode);
            if (distanceVo && !distances.includes(distanceVo.distanceInKiloMeters)) {
                distances.push(distanceVo.distanceInKiloMeters);
            }
        }
        if (distances.length) {
            maxDistance = Math.max(...distances);
        }
        if (maxDistance === null || maxDistance === undefined) {
            return "-";
        } else if (maxDistance < 10) {
            return "< 10 km";
        } else {
            return "< " + maxDistance + " km";
        }
    }

    render() {
        const projects = this.state.projects;
        let headCells = [
            {id: 1, label: 'name'},
            {id: 2, label: "addresses"},
            {id: 3, label: "distances"},
            {id: 4, label: 'firstOfferDeadline'},
            {id: 5, label: 'startOfServiceDate'},
            {id: 6, label: 'documents'},
            {id: 7, label: "details"}
        ];

        const canDownload = isAdmin(this.context.currentUser) || isBidder(this.context.currentUser);

        const subtitle = canDownload ? "bidderDashboard.subtitle" : "bidderDashboard.readOnly.subtitle";

        return <>

            <Typography variant="h1" className="bidderDashboardTitle">
                <FormattedMessage id="bidderDashboard.title"/>
            </Typography>

            <Typography variant="subtitle1" className="bidderDashboardSubtitle">
                <FormattedMessage id={subtitle}/>
            </Typography>

            <Grid item xs={12} style={{paddingBottom: this.state.isExporting ? "26px" : "30px"}}>
                {this.state.isExporting && <LinearProgress/>}
             </Grid>

             {this.showFilter()}

             {projects.length > 0 
                ?   <TableContainer component={Paper} style={{overflowX: "scroll"}}>

                        <Table aria-label="bidder-dashboard-table">

                            <TableHead style={{backgroundColor: bodyBackgroundColor}}>
                                <TableRow>
                                    {headCells.map((headCell) => {
                                        const title = <b>{this.props.intl.formatMessage({id: "bidderDashboard.project." + headCell.label})}</b>;
                                        if (![1, 2, 3, 4, 5].includes(headCell.id)) {
                                            return <TableCell key={headCell.id}>{title}</TableCell>;
                                        } else {
                                            return (
                                                <TableCell 
                                                    key={headCell.id} 
                                                    sortDirection={this.state.sortBy === headCell.id ? this.state.sortOrder : false}>
                                                    <TableSortLabel
                                                        active={true}
                                                        hideSortIcon={![1, 2, 3, 4, 5].includes(headCell.id)}
                                                        direction={this.state.sortBy === headCell.id ? this.state.sortOrder : 'asc'}
                                                        onClick={() => this.changeSorting(headCell.id)}>
                                                        {title}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ); 
                                        }
                                    })}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {projects.filter((p) => this.checkFilterState(p))
                                        .sort((a, b) => this.compareProjects(a, b))
                                        .map(project => (
                                    <TableRow 
                                        key={project.id} 
                                        className="bidderDashboardTableRow">

                                        <TableCell component="th" scope="row">{this.getProjectName(project)}</TableCell>

                                        <TableCell component="th" scope="row">{getAddresses(project, this)}</TableCell>

                                        <TableCell component="th" scope="row">
                                            {this.state.distances?.length
                                                ? this.getMaximumDistance(project.businessUnits.units)
                                                : this.props.intl.formatMessage({id: "bidderDashboard.zipcode.missing"})}
                                        </TableCell>

                                        <TableCell>{this.formatDate(project.procedure.firstOfferDeadline)}</TableCell>

                                        <TableCell>{this.formatDate(project.procedure.startOfServiceDate)}</TableCell>

                                        <TableCell>
                                            {this.downloadButton(project, !canDownload)}
                                        </TableCell>

                                        <TableCell>{this.detailsButton(project)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>

                    </TableContainer>

                :   <Card>
                        <CardContent>

                            <Typography 
                                variant="subtitle1" 
                                className="bidderDashboardNoProjectsSubtitle" 
                                style={{marginBottom: "20px"}}>
                                <FormattedMessage id="bidderDashboard.noProjects.subtitle"/>
                            </Typography>

                        </CardContent>
                    </Card>}

        </>;
    }
}

export default injectIntl(withRouter(withSnackbar(BidderDashboard)));
