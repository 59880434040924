import { Card, CardContent, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import '../apps/App.css';

const SimpleTextCard = ({show, textId, replacements, style}) => {
    if (!show) {
        return <div/>;
    }
    return (<Card style={style}>
        <CardContent className="emptyOrErrorCard">
            <Typography variant="subtitle1">
                <FormattedMessage id={textId} values={replacements}/>
            </Typography>
        </CardContent>
    </Card>);
}

export {
    SimpleTextCard
}
