import { Component } from "react";
import { injectIntl } from "react-intl";
import { GeneralContext } from "../../contexts/GeneralContext";
import { withSnackbar } from "notistack";
import { getAsync, getAsyncCatch } from "../../../services/BackendService";
import ContractFuture from "./ContractFuture";
import ContractPlan from "./ContractPlan";
import ContractCreateHeader from "./headers/ContractCreateHeader";
import ContractDeleteOrPublishHeader from "./headers/ContractDeleteOrPublishHeader";
import ContractWithdrawPlanHeader from "./headers/ContractWithdrawPlanHeader";
import ContractWaitingHeader from "./headers/ContractWaitingHeader";
import ContractAcceptOrRejectHeader from "./headers/ContractAcceptOrRejectHeader";
import ContractWithdrawRequestedHeader from "./headers/ContractWithdrawRequestedHeader";
import ContractAcceptOrRejectWithdrawHeader from "./headers/ContractAcceptOrRejectWithdrawHeader";
import {
    PRICE_SHEET_STATE,
    PROJECT_STATUS_IN_NEGOTIATION,
    PROJECT_STATUS_IN_POST_PROCESSING,
    PROJECT_STATUS_WITHDRAW_REQUESTED,
    PROJECT_TASKS
} from "../../../util/Constants";
import {isBidder, isOfferUser} from "../../../services/UserService";
import {getContract} from "../../../services/ContractService";
import ContractInviteOrSaveHeader from "./headers/ContractInviteOrSaveHeader";
import {getActiveOperation} from "../../../services/OperationService";
import {hasIncompletePriceOfferAsBidder, hasIncompletePriceOfferAsTenderUser, updatePriceOfferMap} from "../../../services/PriceOfferService";
import {isProjectTaskCompleted} from "../../../services/ProjectService";

class ContractStatus extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            allFutureDifferences: [],
            projectIdToWithdraw: null,
            futureProjects: [],
            requestedWithdrawId: null,
            workingOperationDifference: null,
            activeOperation: null,
            contract: null,
            isLoading: true
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async() => {
        this.setState({isLoading: true});
        const activeOperationId = getActiveOperation(this.context)?.id;
        let activeOperation = await getAsyncCatch(this.context, "/operation/" + activeOperationId, this.props);

        if (!activeOperation) {
            activeOperation = null;
            return;
        }

        const contract = await getContract(this.context, this.props, activeOperation.activeProject?.id);

        const workingProjectId = activeOperation?.workingProject?.id;

        let futureProjects = await getAsyncCatch(this.context, "/operation/" + activeOperation?.id + "/project/future", this.props);
        
        if (!futureProjects) {
            futureProjects = [];
        }

        const allOperationDifferences = await getAsync("operation/" + this.context.appData.activeOperationId + "/diff");
        const allFutureDifferences = allOperationDifferences?.data?.filter(
            od => od.effectiveFrom > new Date().getTime() && od.projectTargetId !== workingProjectId);

        if (workingProjectId !== null && workingProjectId !== undefined) {
            await updatePriceOfferMap(this.context, workingProjectId);
            const url = "/operation/" + activeOperation.id + "/diff/" + activeOperation.workingProject?.id;
            const workingOperationDifference = await getAsyncCatch(this.context, url, this.props);
            // filter for future contracts
            this.setState({
                allFutureDifferences,
                futureProjects,
                workingOperationDifference,
                activeOperation,
                contract
            });
        } else {
            this.setState({
                allFutureDifferences,
                futureProjects,
                activeOperation,
                contract
            });
        }
        this.setState({isLoading: false});
    }

    getNewestProject = () => {
        if (!this.state.futureProjects || this.state.futureProjects.length === 0) {
            return this.state.activeOperation?.activeProject;
        } else {
            return this.state.futureProjects.reduce((prev, current) => {
                return prev.effectiveFrom > current.effectiveFrom ? prev : current;
            });
        }
    }

    hasNoChanges = (workingOperationDifference) => {
        if (!workingOperationDifference?.differences) {
            return true;
        }
        let hasNoChanges = true;
        for (let differences of Object.values(workingOperationDifference.differences)) {
            if (differences && differences.length > 0) {
                hasNoChanges = false;
                break;
            }
        }
        return hasNoChanges;
    }

    header = (requestedWithdrawId, workingOperationDifference) => {
        const activeOperation = this.state.activeOperation;
        const newestProject = this.getNewestProject();
        const newestProjectId = newestProject?.id ?? activeOperation?.activeProject?.id;
        const newestEffectiveFrom = newestProject?.effectiveFrom ? newestProject.effectiveFrom : activeOperation?.activeProject?.effectiveFrom;
        const isWorkingProjectInComplete = !isProjectTaskCompleted(this.context, PROJECT_TASKS.PROJECT, activeOperation?.workingProject?.id);
        let isPriceOfferIncomplete;
        let priceSheetState;

        if (isBidder(this.context.currentUser)) {
            isPriceOfferIncomplete = hasIncompletePriceOfferAsBidder(this.context, activeOperation.workingProject?.id);
        } else {
            priceSheetState = hasIncompletePriceOfferAsTenderUser(this.context);
            isPriceOfferIncomplete = priceSheetState === PRICE_SHEET_STATE.INCOMPLETE;
        }
        const hasBidder = isOfferUser(activeOperation?.bidder);
        const hasNoChanges = this.hasNoChanges(workingOperationDifference);

        if (activeOperation?.workingProject) {

            if (activeOperation.editable && activeOperation.workingProject.status === PROJECT_STATUS_IN_POST_PROCESSING && hasBidder) {

                return <ContractDeleteOrPublishHeader 
                    key={"publish-hasNoChanges-" + hasNoChanges + "-newestEffectiveFrom-" + newestEffectiveFrom}
                    hasNoChanges={hasNoChanges}
                    isPriceOfferIncomplete={isPriceOfferIncomplete}
                    isWorkingProjectIncomplete={isWorkingProjectInComplete}
                    newestEffectiveFrom={newestEffectiveFrom}
                    onEffectiveFromChanged={() => this.loadData()}/>;

            } else if (activeOperation.editable && activeOperation.workingProject.status === PROJECT_STATUS_IN_POST_PROCESSING && !hasBidder) {

                return <ContractInviteOrSaveHeader
                    key={"invite-hasNoChanges-" + hasNoChanges + "-newestEffectiveFrom-" + newestEffectiveFrom}
                    hasNoChanges={hasNoChanges}
                    isPriceOfferIncomplete={isPriceOfferIncomplete}
                    isWorkingProjectIncomplete={isWorkingProjectInComplete}
                    newestEffectiveFrom={newestEffectiveFrom}
                    onEffectiveFromChanged={() => this.loadData()}/>;

            } else if (!activeOperation.editable && activeOperation.workingProject.status === PROJECT_STATUS_IN_POST_PROCESSING) {

                return <ContractWaitingHeader/>;

            } else if (activeOperation.editable && activeOperation.workingProject.status === PROJECT_STATUS_IN_NEGOTIATION) {

                return <ContractWithdrawPlanHeader/>;

            } else if (!activeOperation.editable && activeOperation.workingProject.status === PROJECT_STATUS_IN_NEGOTIATION) {

                return <ContractAcceptOrRejectHeader/>;

            }

        } else {

            if (requestedWithdrawId === null || requestedWithdrawId === undefined) {

                return <ContractCreateHeader 
                    key={"newestProjectId-" + newestProjectId + "-newestEffectiveFrom-" + newestEffectiveFrom}
                    newestProjectId={newestProjectId}
                    contract={this.state.contract}
                    newestEffectiveFrom={newestEffectiveFrom}/>;

            } else if (activeOperation.editable) {

                return <ContractAcceptOrRejectWithdrawHeader/>;

            } else if (!activeOperation.editable) {

                return <ContractWithdrawRequestedHeader/>;

            }

        }

        return null;
    }

    render() {

        const { allFutureDifferences, futureProjects, isLoading, workingOperationDifference } = this.state;
        const requestedWithdrawId = futureProjects?.find(p => p.status === PROJECT_STATUS_WITHDRAW_REQUESTED)?.id;
        const workingProject = this.state.activeOperation?.workingProject;

        return (
            <>

                {!isLoading && this.header(requestedWithdrawId, workingOperationDifference)}

                {!isLoading && <ContractPlan
                    key={workingProject?.status}
                    workingOperationDifference={workingOperationDifference}
                    effectiveFrom={workingProject?.effectiveFrom}/>}

                {!isLoading && <ContractFuture
                    allFutureDifferences={allFutureDifferences}
                    futureProjects={futureProjects}
                    hasWorkingProject={!!workingOperationDifference}
                    requestedWithdrawId={requestedWithdrawId}
                    reloadData={() => this.loadData()}/>}

            </>
        );
    }
}

export default injectIntl(withSnackbar(ContractStatus));
