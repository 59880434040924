import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {withSnackbar} from 'notistack';

import '../../../css/NavBar.css';

import {PROJECT_OVERVIEW_ROUTE, LAST_PROJECT_ID, LAST_OPERATION_ID, DASHBOARD_ROUTE} from '../../../util/Constants';

import Tender from './Tender';
import { Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {GeneralContext} from "../../contexts/GeneralContext";
import NavBarItem from '../shared/NavBarItem';
import NavBar from '../shared/NavBar';
import {getActiveProject, updateValidationMap} from "../../../services/ProjectService";
import {getPaymentsAndEvents} from "../../../services/PaymentService";


class TenderNavBar extends Component {

    static contextType = GeneralContext;

    setActiveOperation = async(projectId) => {
        localStorage.setItem(LAST_PROJECT_ID, projectId);
        this.context.setActiveProjectId(projectId);
        const operationId = getActiveProject(this.context)?.operationId;
        if (operationId !== null && operationId !== undefined) {
            this.context.setActiveOperationId(operationId);
            localStorage.setItem(LAST_OPERATION_ID, operationId);
            updateValidationMap(this.context);
            await getPaymentsAndEvents(this.context);
            this.props.history.push(DASHBOARD_ROUTE);
        }
    }

    sideMenuItems = () => {
        const projects = this.context.appData.allProjects;

        const hasProjects = projects?.length > 0;

        return (
            <>
                {hasProjects && <div style={{margin: 15, marginTop: 30}}>

                    <FormControl fullWidth>

                        <InputLabel 
                            id="project"
                            disabled={!hasProjects}>
                            {this.props.intl.formatMessage({id: hasProjects ? "navBar.tender.select.placeholder" : "navBar.tender.select.empty"})}
                        </InputLabel>

                        <Select
                            labelId="project"
                            label={this.props.intl.formatMessage({id: hasProjects ? "navBar.tender.select.placeholder" : "navBar.tender.select.empty"})}
                            value={getActiveProject(this.context)?.name ?? ""}
                            disabled={!hasProjects}>

                            {projects?.map(p =>
                                <MenuItem 
                                    key={"projekt" + p.id} 
                                    value={p.name}
                                    onClick={() => this.setActiveOperation(p.id)}>{p.name}</MenuItem>
                            )}

                        </Select>

                    </FormControl>
                </div>}

                <NavBarItem
                    level={1}
                    pathname={DASHBOARD_ROUTE}
                    icon="icon-cog"/>

                {hasProjects && <>
                    <Tender/>
                    <Divider style={{width: "100%"}}/>
                    <NavBarItem
                        level={1}
                        pathname={PROJECT_OVERVIEW_ROUTE}
                        icon="icon-cog"/>
                </>}
            </>
        );
    }

    render() {
        return (
            <NavBar mobile={this.props.mobile} sideMenuItems={this.sideMenuItems()}/>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(TenderNavBar)));
