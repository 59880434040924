import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {CardContent} from '@material-ui/core';
import {FormattedMessage, injectIntl} from 'react-intl';
import Divider from '@material-ui/core/Divider';

import '../../apps/App.css';
import '../../../css/ArticleOffsetting.css';
import OffsettingEntry from './OffsettingEntry';
import {GeneralContext} from "../../contexts/GeneralContext";
import { withSnackbar } from 'notistack';

class ResourceOffsettings extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            newResourcesOffsettingMap: new Map(this.props.oldResourcesOffsettingMap)
        }
    }

    componentDidUpdate() {
        const hasUnsavedChanges = this.areOperatingResourcesChanged();
        this.context.setUnsavedChanges(!!hasUnsavedChanges);
    }

    areOperatingResourcesChanged = () => {
        if (!this.props.oldResourcesOffsettingMap || !this.state.newResourcesOffsettingMap) {
            return false;
        }
        let changed = false;
        for (let [subCategory, offsetting] of Array.from(this.props.oldResourcesOffsettingMap)) {
            if (this.state.newResourcesOffsettingMap.get(subCategory) !== offsetting) {
                changed = true;
                break;
            }
        }
        return changed;
    }

    handleResourceOffsettingChanged = (subcategory, offsetting) => {
        if (this.state.newResourcesOffsettingMap && this.state.newResourcesOffsettingMap.get(subcategory)) {
            this.state.newResourcesOffsettingMap.set(subcategory, offsetting);
        }
        this.setState({newResourcesOffsettingMap: this.state.newResourcesOffsettingMap});
    }

    handleCancelResourcesOffsettingEdit = async() => {
        this.setState({newResourcesOffsettingMap: new Map(this.props.oldResourcesOffsettingMap)});
    }

    render() {
        return (
            <Card className="child">
                <CardContent>

                    <Grid container spacing={2} className="mb-5">

                        <Grid item xs>
                            <Typography variant="h6" component="h6">
                                <FormattedMessage id="operatingResources.offsetting.h1"/>
                            </Typography>
                        </Grid>

                        {this.areOperatingResourcesChanged() && <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => this.handleCancelResourcesOffsettingEdit()}
                                style={{marginLeft: "10px"}}
                                disabled={this.props.readOnly}>
                                <FormattedMessage id="commons.cancel.button"/>
                            </Button>
                        </Grid>}

                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.props.onSave(this.state.newResourcesOffsettingMap)}
                                disabled={this.props.readOnly || !this.areOperatingResourcesChanged()}>
                                <FormattedMessage id="commons.save.button"/>
                            </Button>
                        </Grid>

                    </Grid>

                    <Divider/>

                    {!!this.props.operatingResources?.length && <Typography color="textSecondary" className="mt-5">
                        <FormattedMessage id="operatingResources.offsetting.h2"/>
                    </Typography>}

                    <Grid container className="pt-2" spacing={2}>
                        {
                            this.state.newResourcesOffsettingMap && Array.from(this.state.newResourcesOffsettingMap) && Array.from(this.state.newResourcesOffsettingMap).length > 0 ? 
                            Array.from(this.state.newResourcesOffsettingMap).sort((a, b) => a[0].localeCompare(b[0])).map(([subCategory, offsetting]) => {
                                return (<OffsettingEntry
                                    readOnly={this.props.readOnly}
                                    key={subCategory}
                                    id={subCategory}
                                    name={this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + subCategory})}
                                    currentOffsetting={offsetting}
                                    handleChanged={this.handleResourceOffsettingChanged}
                                    offsettings={this.context.appData.operatingResourceOffsettings}
                                />);
                            }) : 
                            <Grid container justifyContent="center" className="mt-6">
                                <Grid item>
                                    <Typography variant="body2" component="div" className="gray-dotted pt-5 pb-5" style={{textAlign: "center"}}>
                                        <FormattedMessage id="operatingResources.offsetting.empty"/>
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    </Grid>

                </CardContent>
            </Card>
        );
    }
}

export default injectIntl(withSnackbar(ResourceOffsettings));
