import React, {Component} from 'react';
import {Card, CardContent, Divider, Grid, Typography, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

class ArticleMainCategoryCard extends Component {

    render() {
        return (
            <Card className="articleMainCategoryCard">
                <CardContent>
                    <Grid container spacing={2} className="articleMainCategoryCard">
                        <Grid item xs={12}>
                            <Typography variant="h3">
                                <FormattedMessage id={"constants.Category." + this.props.category}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                           <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h4"><FormattedMessage id="articleOverview.categories.title"/></Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography><FormattedMessage id="articleOverview.categories.availableCount"/></Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h4">{this.props.articleTotalCount}</Typography>
                                </Grid>
                           </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={(e) => this.props.navigateTo(e, this.props.index)}>
                                            <FormattedMessage id="articleOverview.show.articles"/>
                                        </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </CardContent>
            </Card>
        );
    }
}

ArticleMainCategoryCard.propTypes = {
    category: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    navigateTo: PropTypes.func.isRequired
};

export default ArticleMainCategoryCard;
