import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { texisionFontColorOrange } from "../../../util/ColorTheme";

class ArticleValidation extends Component {

    render() {
        return (
            this.props.validateMandatoryFields() &&

                <Grid container>

                    <Card className="child" elevation={0} style={{backgroundColor: "#f8f4d5"}}>

                        <CardContent>

                            <Typography variant="h6" component="h2" style={{color: texisionFontColorOrange}}>
                                <FormattedMessage id="articleConfigurator.message.validationError"/>
                            </Typography>

                            {this.props.validateMandatoryFields() 
                            
                                ? <Typography style={{color: texisionFontColorOrange, marginTop: "10px"}}>
                                    <FormattedMessage id="articleConfigurator.message.error.mandatory"/>
                                </Typography> 
                                
                                : null}

                        </CardContent>

                    </Card>

                </Grid>
        );
    }
}

export default injectIntl(ArticleValidation);
