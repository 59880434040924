import {Typography} from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { getAsync } from "../../services/BackendService";
import {DELIVERY, DELIVERY_PICKUP} from "../../util/Constants";
import { GeneralContext } from "../contexts/GeneralContext";
import OrderAddress from "./OrderAddress";
import ArticleNameSwitch from "../share/ArticleNameSwitch";
import {getDeliveryAddressesForUnit} from "../../services/AddressService";
import {getUnitById} from "../../services/BusinessUnitService";

class OrderUnit extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.unit = null;
        this.state = {
            deliveryAddresses: []
        }
    }

    async componentDidMount() {
        const pathVariable = this.props.match.params.unitId;
        if (pathVariable) {
            const unitIdFromPath = parseInt(pathVariable);
            this.unit = getUnitById(this.context, unitIdFromPath);
            const deliveryAddressesFromContext = getDeliveryAddressesForUnit(this.context, parseInt(unitIdFromPath));
            if (!deliveryAddressesFromContext) {
                await this.loadDeliveryAddresses(unitIdFromPath);
            } else {
                this.setState({deliveryAddresses: deliveryAddressesFromContext});
            }
        }
    }

    async componentDidUpdate(prevProps) {
        const oldUnitIdFromPath = prevProps.match.params.unitId;
        const newUnitIdFromPath = this.props.match.params.unitId;
        if (newUnitIdFromPath && newUnitIdFromPath !== oldUnitIdFromPath) {
            this.unit = getUnitById(this.context, parseInt(newUnitIdFromPath));
            const deliveryAddressesFromContext = getDeliveryAddressesForUnit(this.context, parseInt(newUnitIdFromPath));
            if (!deliveryAddressesFromContext) {
                await this.loadDeliveryAddresses(newUnitIdFromPath);
            } else {
                this.setState({deliveryAddresses: deliveryAddressesFromContext});
            }
        }
    }

    loadDeliveryAddresses = async(unitId) => {
        const response = await getAsync("/unit/" + unitId + "/addresses");
        if (response?.status === 200) {
            this.setState({deliveryAddresses: response.data?.filter(a => [DELIVERY, DELIVERY_PICKUP].includes(a.addressType))});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else {
            this.setState({});
        }
    }

    render() {
        if (!this.unit || !this.state.deliveryAddresses) {
            return (
                <div/>
            );
        }

        return (
            <>
                <Typography variant="h1">
                    <FormattedMessage id="order.unit.title" values={{unitName: this.unit.name}}/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id="order.unit.subtitle" values={{unitName: this.unit.name}}/>
                </Typography>

                <ArticleNameSwitch/>

                {this.state.deliveryAddresses.map(d => <OrderAddress key={"order-address-component-" + d.id} deliveryAddress={d}/>)}
            </>
        );
    }
}

export default withRouter(injectIntl(OrderUnit));
