import { Card, CardContent, List, ListItem, ListItemSecondaryAction, ListItemAvatar, ListItemText, Button, DialogContentText, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TexisionDialog from '../uiLibrary/TexisionDialog';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { getRatingArguments } from "../../util/Constants";
import { RatingArgumentIcon } from "./RatingArgumentIcon";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const RatingArgumentsSelection = ({criterionName, currentArguments, onSave, onCancel}) => {
    const [ratingArguments, setRatingArguments] = useState(currentArguments);
    const [newArgument, setNewArgument] = useState("");

    const intl = useIntl();

    useEffect(() => {
        setRatingArguments(currentArguments);
    }, [currentArguments, criterionName]);

    const onRatingArgumentAdded = (newArgument) => {
        setRatingArguments([...ratingArguments, newArgument]);
        setNewArgument("");
    }

    const onRatingArgumentRemoved = (argumentToRemove) => {
        setRatingArguments([...ratingArguments.filter(a => a !== argumentToRemove)]);
    }

    const isDuplicate = (name) => {
        return ratingArguments?.map(a => a.toLowerCase())?.includes(name?.toLowerCase()) === true;
    }

    const isDefaultConfiguration = () => {
        return getRatingArguments(criterionName).map(a => intl.formatMessage({id: "constants.RatingType." + a}))
            .sort().join(",") === ratingArguments?.sort()?.join(",");
    }

    const setDefaultConfiguration = () => {
        setRatingArguments(getRatingArguments(criterionName).map(a => intl.formatMessage({id: "constants.RatingType." + a})));
    }

    if (!criterionName || !onSave || !onCancel) {
        return <div/>;
    }

    const hasArguments = ratingArguments?.length > 0;

    return <TexisionDialog
        open={true}
        titleId="procedure.ratingArguments.title"
        subtitleId="procedure.ratingArguments.subtitle"
        replacements={{ratingCriterion: intl.formatMessage({id: "constants.RatingCategory." + criterionName})}}
        cancelId="commons.cancel.button"
        onCancel={() => onCancel()}
        actionId="commons.save.button"
        actionDisabled={!hasArguments}
        onAction={() => onSave(criterionName, ratingArguments)}
        content={<React.Fragment>
            {isDefaultConfiguration() && <DialogContentText>
                <FormattedMessage id="procedure.ratingArguments.suggestions.subtitle"/>
            </DialogContentText>}


            <Card style={{padding:"0px", backgroundColor: "#f3f6f8"}}>
                <CardContent>

                    <Typography style={{marginLeft: 15, marginBottom: 10}} variant="h6" component="div">
                        <FormattedMessage id="procedure.ratingArguments.selected.title"/>
                    </Typography>

                    <Grid item xs={12}>

                        {!hasArguments && <>
                            <Grid 
                                container 
                                alignItems="center" 
                                alignContent="center" 
                                spacing={3} 
                                style={{paddingBottom: "8px", paddingTop: "8px", paddingLeft: "13px", paddingRight: "25px", color: "orange"}}>
                                {<Grid item>
                                    <ReportProblemOutlinedIcon style={{color: "orange", paddingTop: "5px"}}/>
                                </Grid>}
                                <Grid item xs>
                                    <Typography variant="h4">
                                        <FormattedMessage id="procedure.ratingArguments.warning" style={{fontSize: "18px"}}/>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>}

                    </Grid>

                    <Grid item xs={12} md={6}>

                        {hasArguments && <List dense={true}>
                            {ratingArguments?.map(a => 
                            <ListItem>
                                <ListItemAvatar>
                                    <RatingArgumentIcon size="50" criterionName={criterionName} ratingArgument={a}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={a}
                                    secondary={null}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        variant="text"
                                        style={{padding: 5}}
                                        onClick={() => onRatingArgumentRemoved(a)}>
                                        <RemoveCircleOutlineOutlinedIcon color="error"/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            )}
                        </List>}
                    </Grid>
                </CardContent>
            </Card>

            <Grid container wrap="nowrap" alignItems="center" spacing={2} style={{marginBottom: 20, marginTop: 20}}>
                <Grid item>
                    <FormattedMessage id="procedure.ratingArguments.add.title"/>
                </Grid>
                <Grid item>
                    <TextField
                        value={newArgument}
                        variant="outlined"
                        onChange={(e) => setNewArgument(e.target.value)}
                        inputProps={{maxLength: 128}}
                        error={isDuplicate(newArgument)}
                        helperText={isDuplicate(newArgument) 
                            ? intl.formatMessage({id: "procedure.ratingArgument.duplicateName"}) : ""}/>
                </Grid>
                <Grid item>
                    <Button 
                        color="primary" 
                        variant="text" 
                        onClick={() => onRatingArgumentAdded(newArgument)}
                        disabled={!newArgument || isDuplicate(newArgument)}>
                        <AddIcon/>
                    </Button>
                </Grid>
            </Grid>

            {!isDefaultConfiguration() && <Button 
                variant="text" 
                color="primary"
                style={{marginTop: 20}}
                onClick={() => setDefaultConfiguration()}>
                <FormattedMessage id="procedure.ratingArguments.setDefaultConfiguration"/>
            </Button>}

        </React.Fragment>}/>
}

export {
    RatingArgumentsSelection
}
