import {Box} from "@mui/system";
import {Typography} from "@material-ui/core";
import React from "react";

export function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return  <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
            <Box>
                <Typography>{children}</Typography>
            </Box>
        )}
    </div>;
}
