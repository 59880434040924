import {NO_OFFSETTING_SELECTED, ROOT_NODE_NAME} from '../../../../util/Constants';
import {GeneralContext} from "../../../contexts/GeneralContext";


// This class defines the data structure that is holding the article offsettings.
// It also offers functions e.g. to set, traverse and check the current offsettings.
class ArticleOffsettingTree {

    static contextType = GeneralContext;

    constructor(root) {
        this.root = root || null;
    }

    getRoot() {
        return this.root;
    }

    traverseFrom(startNode, callback) {
        for (let childIndex = 0; childIndex < startNode.children.length; childIndex++) {
            const result = this.traverseFrom(startNode.children[childIndex], callback);
            if (result) {
                break;
            }
        }
        return callback(startNode);
    }

    traverse(callback) {
        function goThrough(node) {
            callback(node);
            node.children.forEach((child) => {
                goThrough(child);
            });
        }
        goThrough(this.root);
    }

    flatten(node) {

        let result = [];
        dfs(node, result, []);

        function dfs(node, result, path) {

            if (node.name !== ROOT_NODE_NAME) {
                path.push({name: node.name, offsetting: node.offsetting});
            }

            if (node.children.length === 0) {
                result.push(path);
            } else {
                node.children.forEach((node) => {
                    dfs(node, result, Object.assign([], path));
                });
            }
        }

        return result;
    }

    findParents(childNode) {
        for (let index = 0; index < this.root.children.length; index++) {
            let branch = this.root.children[index];
            let flatBranch = [];
            let branchFound = false;

            this.traverseFrom(branch, node => {
                if (branchFound) {
                    flatBranch.push(node);
                    return true;
                }
                if (node.internalId === childNode.internalId) {
                    branchFound = true;
                    return true;
                }
            });

            if (branchFound) {
                return flatBranch;
            }
        }

        return [];
    }

    addNode(name, internalId, partenInternalId, type, offsetting, label) {

        const newNode = {
            name,
            internalId,
            type,
            offsetting: offsetting === 'undefined' || !offsetting ? NO_OFFSETTING_SELECTED : offsetting,
            label,
            children: []
        };

        if (!this.root) {
            this.root = newNode;
            return;
        }

        this.traverse((node) => {
            if (node.internalId === partenInternalId) {
                // found parent node, but only add the new node, if the node does not already exists.
                let contains = false;
                node.children.forEach((node) => {
                    if (node.name === newNode.name) {
                        contains = true;
                    }
                });

                if (!contains) {
                    node.children.push(newNode);
                }
            }
        });
    }

    // searches a node by the id
    search(internalId) {

        let returnNode = null;
        this.traverse((node) => {
            if (node.internalId === internalId) {
                returnNode = node;
            }
        });
        return returnNode;
    }

    findLeafs(node) {

        let leafsRet = [];
        const parentNode = this.search(node.internalId);

        if (!parentNode) {
            return leafsRet;
        } else {
            leafsRet.push(parentNode);
        }

        // no further children
        if (node.children && !node.children.length) {
            return node;
        }

        parentNode.children.forEach((child) => {
            leafsRet.push(this.findLeafs(child));
        });

        return leafsRet.flat();
    }

    // Checks, if a value other than NO_OFFSETTING_SELECTED has been set.
    overwriteCheck(internalId, offsetting) {

        const node = this.search(internalId);
        if (node) {

            // check if the children already have a value other than NO_OFFSETTING_SELECTED
            for (let child of node.children) {
                // if the child has the identical offsetting, then show no warning dialog
                if (child.offsetting !== NO_OFFSETTING_SELECTED && child.offsetting !== offsetting) {
                    return true;
                }
            }
        }
        return false;
    }

    setOffsetting(node, offsetting) {
        // Sets the offsetting for a single node or for an array of nodes
        const leafs = this.findLeafs(node);
        if (Array.isArray(leafs)) {
            for (let leaf of leafs) {
                leaf.offsetting = offsetting;
            }
        } else {
            leafs.offsetting = offsetting;
        }
    }

    findDifferences(tree) {

        const differences = [];
        tree.traverse((node) => {
            if (node.name !== ROOT_NODE_NAME) {
                const foundNode = this.search(node.internalId);
                if (foundNode.offsetting !== node.offsetting) {
                    differences.push({internalId: node.internalId, oldOffsetting: foundNode.offsetting, newOffsetting:
                        node.offsetting, label: node.label, type: node.type});
                }
            }
        });

        return differences;
    }
}
export default ArticleOffsettingTree;
