// Mobile

import {isTender} from "./Util";

export const MOBILE_THRESHOLD = 1300;

// Local storage

export const LAST_VISIT_BIDDER_DASHBOARD = "LAST_VISIT_BIDDER_DASHBOARD";

// Apps

export const APP_TYPE_KEY = "APP_TYPE";
export const TENDER_APP = "TENDER";
export const OFFER_APP = "OFFER";
export const COCKPIT_APP = "COCKPIT";
export const ORDER_APP = "ORDER";
export const LAST_PROJECT_ID = "LAST_PROJECT_ID";
export const LAST_OFFER_PROJECT_ID = "LAST_OFFER_PROJECT_ID";
export const LAST_OPERATION_ID = "LAST_OPERATION_ID";

// General Routes

export const ROOT_ROUTE = "/";

export const LOGIN_ROUTE = "/login";
export const OPERATION_ASSIGNMENT_ROUTE = "/operationassignment";
export const ACCOUNT_ACTIVATE_ROUTE = "/activate";
export const ACCOUNT_DELETE_ROUTE = "/delete";
export const REGISTRATION_ROUTE = "/registration";
export const FORGOT_PASSWORD_ROUTE = "/forgotpassword";
export const RESET_PASSWORD_ROUTE = "/resetpassword";

export const ARTICLE_CONFIGURATION_OVERVIEW_ROUTE = "/article/configuration/overview"
export const ARTICLE_CONFIGURATOR_ROUTE = "/article/configurator";
export const USER_MANAGEMENT_ROUTE = "/usermanagement";
export const PROFILES_ROUTE = "/profiles";
export const LICENSE_ROUTE = "/licenses";

export const PRICES_ROUTE = "/prices";
export const AGB_ROUTE = "https://texision.de/#page-5";
export const PRIVACY_ROUTE = "https://texision.de/#page-4";
export const IMPRESSUM_ROUTE = "https://texision.de/#page-3";

export const MY_ACCOUNT_ROUTE = "/account";

export const RELEASE_NOTES_ROUTE = "/releasenotes";

// Tender Routes

export const PRICE_SHEET_EXPORT_ROUTE = "/tender/export/pricesheet";
export const ASSORTMENT_EXPORT_ROUTE = "/tender/export/assortment";
export const LOGISTICS_EXPORT_ROUTE = "/tender/export/logistic";
export const BILL_OF_QUANTITIES_EXPORT_ROUTE = "/tender/export/billofquantities";

export const PROCEDURE_CONDITIONS_ROUTE = "/tender/procedure";
export const OFFER_DOCUMENT_ROUTE = "/tender/document";
export const INVOLVED_BIDDERS_ROUTE = "/tender/bidders";
export const TENDER_COMMUNICATION_ROUTE = "/tender/communication";
export const OFFER_SUITABILITY_ROUTE = "/tender/suitability";

export const PRICE_COMPARISON_ROUTE = "/tender/pricecomparison";
export const OFFER_RATING_ROUTE = "/tender/rating";
export const OFFER_RESULT_ROUTE = "/tender/result";

export const INVOICE_CONTROLLING_ROUTE = "/tender/invoice/controlling";

export const TENDER_HISTORY_ROUTE = "/tender/history";

// Offer Routes
export const BIDDER_DASHBOARD_ROUTE = "/offer/dashboard";
export const BIDDER_PROJECT_DETAILS_ROUTE = "/offer/projectdetails";
export const BIDDER_COMMUNICATION_ROUTE = "/offer/communication";
export const BIDDER_OFFER_DOCUMENTS_ROUTE = "/offer/documents";
export const BIDDER_OFFER_SUBMIT_ROUTE = "/offer/submit";
export const BIDDER_OFFER_RESULT_ROUTE = "/offer/result";
export const BIDDER_OFFER_PRICE_SHEET_ROUTE = "/offer/pricesheet";

// Cockpit Routes

export const PREFLIGHT_CHECK_ROUTE = "/cockpit/preflight";
export const COCKPIT_CONTRACT_DOCUMENTS_ROUTE = "/cockpit/documents";
export const CONTRACT_STATUS_ROUTE = "/cockpit/status";
export const COCKPIT_INVOICES_ROUTE = "/cockpit/invoices";
export const CONTRACT_HISTORY_ROUTE = "/cockpit/history";
export const BASE_DATA_ROUTE = "/cockpit/basedata";
export const PREDICTED_STATISTICS_ROUTE = "/cockpit/statistics/prediction";
export const INVOICE_STATISTICS_ROUTE = "/cockpit/statistics/invoices";
export const COCKPIT_PRICE_SHEET_ROUTE = "/cockpit/pricesheet";

// Order Routes

export const ORDER_DASHBOARD_ROUTE = "/order/dashboard";
export const ORDER_UNIT_ROUTE = "/order/unit";

// Shared (tender and cockpit) routes

export const PROJECT_OVERVIEW_ROUTE = "/overview";
export const DASHBOARD_ROUTE = "/dashboard";
export const BUSINESS_UNIT_OVERVIEW_ROUTE = "/businessunit/overview";
export const RENTAL_LINEN_ROUTE = "/rentallinen";
export const RENTAL_LINEN_ASSIGNMENT_ROUTE = "/rentallinenassignment";
export const CUSTOMER_ARTICLES_ROUTE = "/customerarticles";
export const RESIDENTS_LAUNDRY_ROUTE = "/residentslaundry";
export const OPERATING_RESOURCE_OVERVIEW_ROUTE = "/operatingresources/overview";
export const OPERATING_RESOURCES_ROUTE = "/operatingresources";
export const OPERATING_RESOURCE_CONFIGURATOR_ROUTE = "/operatingresources/configurator";
export const SPECIAL_SERVICES_ROUTE = "/specialservices";
export const OFFSETTING_ROUTE = "/offsetting";
export const LOGISTICS_ROUTE = "/logistics";
export const CONTRACT_ROUTE = "/contract";
export const PAYMENT_MANAGEMENT_ROUTE = "/payment";

// Shared (order and cockpit) routes

export const ORDER_CONFIGURATION_ROUTE = "/order/configuration";

// Money

export const MIN_SUBSCRIPTION_PRICE_PER_MONTH = 1;

// Nav bar functions

export function getNavBarTitleId(route) {
    switch (route) {
        case ROOT_ROUTE:
        case DASHBOARD_ROUTE:
            return "navBar.dashboard";
        case PROJECT_OVERVIEW_ROUTE:
            if (isTender()) {
                return "navBar.tenderManagement.title";
            } else {
                return "navBar.projectManagement.title";
            }
        case BUSINESS_UNIT_OVERVIEW_ROUTE:
            return "navBar.unitManagement.title";
        case RENTAL_LINEN_ROUTE:
        case RENTAL_LINEN_ASSIGNMENT_ROUTE:
            return "navBar.rentalLinen.title";
        case CUSTOMER_ARTICLES_ROUTE:
            return "navBar.customerArticles.title";
        case RESIDENTS_LAUNDRY_ROUTE:
            return "navBar.residentsLaundry.title";
        case SPECIAL_SERVICES_ROUTE:
            return "navBar.specialServices.title";
        case OPERATING_RESOURCE_OVERVIEW_ROUTE:
            return "operatingResources.navbar.overview";
        case OPERATING_RESOURCES_ROUTE:
            return "operatingResources.navbar.selection";
        case OPERATING_RESOURCE_CONFIGURATOR_ROUTE:
            return "operatingResources.navbar.configurator";
        case OFFSETTING_ROUTE:
            return "navBar.offsettings";
        case LOGISTICS_ROUTE:
            return "navBar.logistics.title"
        case PRICE_SHEET_EXPORT_ROUTE:
            return "navBar.load.pricesheet.title";
        case ASSORTMENT_EXPORT_ROUTE:
            return "navBar.load.assortmentAndResources.title";
        case LOGISTICS_EXPORT_ROUTE:
            return "navBar.load.delivery.title";
        case BILL_OF_QUANTITIES_EXPORT_ROUTE:
            return "navBar.load.tender.title";
        case CONTRACT_ROUTE:
            return "navBar.load.contract.title";
        case PROCEDURE_CONDITIONS_ROUTE:
            return "navBar.procedureConditions.title";
        case INVOLVED_BIDDERS_ROUTE:
            return "navBar.involvedBidders.title";
        case TENDER_COMMUNICATION_ROUTE:
            return "navBar.bidderCommunication.title";
        case OFFER_SUITABILITY_ROUTE:
            return "navBar.offerSuitability.title";
        case PRICE_COMPARISON_ROUTE:
            return "navBar.priceComparison.title";
        case OFFER_RATING_ROUTE:
            return "navBar.offerRating.title";
        case OFFER_RESULT_ROUTE:
            return "navBar.offerResult.title";
        case INVOICE_CONTROLLING_ROUTE:
            return "invoiceControlling.title";
        case TENDER_HISTORY_ROUTE:
            return "navBar.tenderHistory.title";
        case PREFLIGHT_CHECK_ROUTE:
            return "navBar.preflightCheck.title";
        case COCKPIT_CONTRACT_DOCUMENTS_ROUTE:
            return "navBar.cockpitContract.title";
        case BIDDER_OFFER_PRICE_SHEET_ROUTE:
        case COCKPIT_PRICE_SHEET_ROUTE:
            return "navBar.offerPriceSheet.title";
        case COCKPIT_INVOICES_ROUTE:
            return "navBar.invoices.title";
        case BASE_DATA_ROUTE:
            return "navBar.baseData.title";
        case INVOICE_STATISTICS_ROUTE:
            return "navBar.statistics.contract.title";
        case PREDICTED_STATISTICS_ROUTE:
            return "navBar.statistics.prediction.title";
        case CONTRACT_HISTORY_ROUTE:
            return "navBar.contractHistory.title";
        case ARTICLE_CONFIGURATOR_ROUTE:
            return "navBar.articleConfigurator.title";
        case USER_MANAGEMENT_ROUTE:
            return "navBar.userManagement.title";
        case PROFILES_ROUTE:
            return "navBar.profiles.title";
        case LICENSE_ROUTE:
            return "navBar.licenses.title";
        case IMPRESSUM_ROUTE:
            return "navBar.impressum.title";
        case AGB_ROUTE:
            return "navBar.agb.title";
        case PRIVACY_ROUTE:
            return "navBar.privacy.title";
        case MY_ACCOUNT_ROUTE:
            return "navBar.profile.title";
        case RELEASE_NOTES_ROUTE:
            return "navBar.releaseNotes";
        case PAYMENT_MANAGEMENT_ROUTE:
            return "navBar.paymentManagement.title";
        case OFFER_DOCUMENT_ROUTE:
            return "navBar.offerDocumentOverview.title";
        default:
            return "";
    }
}

// Offer nav bar functions

export const buildRouteWithPathVariablePlaceholder = (rawRoute, placeholder) => {
    return rawRoute + "/" + placeholder;
}

export const buildRouteWithPathVariable = (rawRoute, pathVariable) => {
    return rawRoute + "/" + pathVariable;
}

// language constants
export const DE = "de";

// country constants
export const COUNTRIES = {
    DE: "DE"
}

// size constants
export const NONE = "NONE";
export const UNISEX = "UNISEX";
export const MAX_BUSINESS_UNIT_COUNT = 3;
export const MAX_USER_COUNT = 6;

// Article offsetting constants
export const DELIVERED_QUANTITY = "DELIVERED_QUANTITY";
export const FLAT_RATE = "FLAT_RATE";
export const SPLIT_PRICE = "SPLIT_PRICE";
export const USER_DEFINED = "USER_DEFINED";
export const NO_OFFSETTING_SELECTED = "NO_OFFSETTING_SELECTED";

export const CATEGORY = "CATEGORY";
export const SUB_CATEGORY = "SUB_CATEGORY";
export const ARTICLE_CATEGORY = "ARTICLE_CATEGORY";
export const ROOT_NODE_NAME = "root";

// Delivery point and pick up constants
export const DELIVERY = "DELIVERY";
export const PICKUP = "PICKUP";
export const DELIVERY_PICKUP = "DELIVERY_PICKUP";

export const MONDAY = "MONDAY";
export const TUESDAY = "TUESDAY";
export const WEDNESDAY = "WEDNESDAY";
export const THURSDAY = "THURSDAY";
export const FRIDAY = "FRIDAY";
export const SATURDAY = "SATURDAY";
export const SUNDAY = "SUNDAY";

// Logistics types
// zentral vorkommissioniert
export const LOGISTIC_TYPE_CENTRAL = "CENTRAL";
// dezentral vorkommissioniert
export const LOGISTIC_TYPE_DECENTRAL = "DECENTRAL";
// nur zentral
export const LOGISTIC_TYPE_SINGLE = "SINGLE";

// Bill of Quantities export flags
export const EXPORT_TYPE_MERGE = "MERGE";
export const EXPORT_TYPE_CONCATENATE = "CONCATENATE";

// UserState
export const BILL_OF_QUANTITIES_TYPE = "BILL_OF_QUANTITIES_TYPE";

export const USE_ALTERNATE_ARTICLE_NAMES = "USE_ALTERNATE_ARTICLE_NAMES";
export const TERMS_OF_USE_CHECKED = "TERMS_OF_USE_CHECKED";

// Equipment types
export const EQUIPMENT_TYPE_PROFESSIONAL_GROUP = "PROFESSIONAL";
export const EQUIPMENT_TYPE_ASSORTMENT = "ASSORTMENT";
export const EQUIPMENT_TYPE_AREA = "AREA";

// Residents' laundry clean types
export const RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH = "WASH";
export const RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL = "CHEMICAL";

// Dialog types
export const DIALOG_TYPE_WARNING = "DIALOG_TYPE_WARNING";
export const DIALOG_TYPE_INFO = "DIALOG_TYPE_INFO";
export const DIALOG_TYPE_ERROR = "DIALOG_TYPE_ERROR";

// Cookie names
export const COOKIE_BANNER_SET = "COOKIE_BANNER_SET";

// Categories
export const CATEGORY_WORK_WEAR = "WORKWEAR";
export const CATEGORY_FLAT_LINEN = "FLAT_LINEN";
export const CATEGORY_SURGICAL_TEXTILES = "SURGICAL_TEXTILES";

// Project status
export const PROJECT_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const PROJECT_STATUS_IS_PUBLISHED = "IS_PUBLISHED";
export const PROJECT_STATUS_IN_EDITING = "IN_EDITING";
export const PROJECT_STATUS_IS_ACCEPTED = "IS_ACCEPTED";
export const PROJECT_STATUS_IN_POST_PROCESSING = "IN_POST_PROCESSING";
export const PROJECT_STATUS_IN_NEGOTIATION = "IN_NEGOTIATION";
export const PROJECT_STATUS_WITHDRAW_REQUESTED = "WITHDRAW_REQUESTED";

// Project filter state
export const PROJECT_FILTER_NONE = "NONE";
export const PROJECT_FILTER_OPEN = "OPEN";
export const PROJECT_FILTER_RUNNING = "RUNNING";
export const PROJECT_FILTER_CLOSED = "CLOSED";
export const PROJECT_FILTER_ALL = [
    PROJECT_FILTER_NONE, PROJECT_FILTER_OPEN, PROJECT_FILTER_RUNNING, PROJECT_FILTER_CLOSED
];

// Preflight check types
export const SETUP_ORDER_APP = "SETUP_ORDER_APP";

// Bidder communication filter state
export const BIDDER_COMMUNICATION_FILTER_NONE = "NONE";
export const BIDDER_COMMUNICATION_FILTER_INFO = "INFO";
export const BIDDER_COMMUNICATION_FILTER_ANSWERED = "ANSWERED";
export const BIDDER_COMMUNICATION_FILTER_NOT_ANSWERED = "NOT_ANSWERED";
export const BIDDER_COMMUNICATION_FILTER_NOTIFICATION = "NOTIFICATION";
export const BIDDER_COMMUNICATION_FILTER_ALL = [
    BIDDER_COMMUNICATION_FILTER_NONE,
    BIDDER_COMMUNICATION_FILTER_NOTIFICATION,
    BIDDER_COMMUNICATION_FILTER_ANSWERED,
    BIDDER_COMMUNICATION_FILTER_INFO,
    BIDDER_COMMUNICATION_FILTER_NOT_ANSWERED,
];

// Offers
export const OFFER_STATUS_UNDEFINED = "UNDEFINED";
export const OFFER_STATUS_PARTICIPATING = "PARTICIPATING";
export const OFFER_STATUS_SUBMITTED = "SUBMITTED";
export const OFFER_STATUS_REJECTED = "REJECTED";
export const OFFER_STATUS_ACCEPTED = "ACCEPTED";

// Rental linen offsettings

export const ARTICLE_OFFSETTINGS = {
    DELIVERED_QUANTITY: "DELIVERED_QUANTITY",
    FLAT_RATE: "FLAT_RATE",
    NO_OFFSETTING_SELECTED: "NO_OFFSETTING_SELECTED",
    SPLIT_PRICE: "SPLIT_PRICE",
    USER_DEFINED: "USER_DEFINED"
};

// Resource and special services offsettings

export const RESOURCE_OFFSETTINGS = {
    CALENDAR_DAY: "CALENDAR_DAY",
    MONTHLY: "MONTHLY",
    NONE: "NONE",
    PER_UNIT: "PER_UNIT",
    WEEKLY: "WEEKLY",
    WITHOUT_CHARGE: "WITHOUT_CHARGE",
    YEARLY: "YEARLY"
};

// Price types

export const PriceType = {
    ARTICLE_QUANTITY: "ARTICLE_QUANTITY",
    ARTICLE_WEEKLY: "ARTICLE_WEEKLY",
    CUSTOMER_ARTICLE: "CUSTOMER_ARTICLE",
    RESIDENTS_LAUNDRY: "RESIDENTS_LAUNDRY",
    OPERATING_RESOURCE: "OPERATING_RESOURCE",
    SPECIAL_SERVICE: "SPECIAL_SERVICE"
};

export const OFFER_STATUS_NONE = "NONE";

// Bidder communication, message types

export const MESSAGE_TYPES = {
    INFORMATION: "INFORMATION",
    QUESTION: "QUESTION",
    ANSWER: "ANSWER",
    NOTIFICATION: "NOTIFICATION",
}

export const FEDERAL_STATES = {
    UNDEFINED: "-",
    BW: "Baden-Württemberg",
    BY: "Bayern",
    BE: "Berlin",
    BB: "Brandenburg",
    HB: "Bremen",
    HH: "Hamburg",
    HE: "Hessen",
    MV: "Mecklenburg-Vorpommern",
    NI: "Niedersachsen",
    NW: "Nordrhein-Westfalen",
    RP: "Rheinland-Pfalz",
    SL: "Saarland",
    SN: "Sachsen",
    SA: "Sachsen-Anhalt",
    SH: "Schleswig-Holstein",
    TH: "Thüringen"
}

export const SUITABILITY_CRITERIA = {
    DIN_EN_ISO_9000: "DIN_EN_ISO_9000",
    COMMERCIAL_REGISTER_EXTRACT: "COMMERCIAL_REGISTER_EXTRACT",
    HYGIENE_CERTIFICATE: "HYGIENE_CERTIFICATE",
    DIN_EN_14065: "DIN_EN_14065",
    RESIDENT_LAUNDRY_CERTIFICATE: "RESIDENT_LAUNDRY_CERTIFICATE",
    DECLARATION_BIDDING_CONSORTIUM: "DECLARATION_BIDDING_CONSORTIUM",
    CONTACT_PERSON: "CONTACT_PERSON",
    COOPERATION_AGREEMENTS: "COOPERATION_AGREEMENTS",
    HYGIENE_OFFICER: "HYGIENE_OFFICER",
    INSURANCES: "INSURANCES",
    COMPANY_PROFILE: "COMPANY_PROFILE"
}

export const RATING_TYPES = {
    MANUFACTURING_PROCESS: "MANUFACTURING_PROCESS",
    HEALTH_PROTECTION: "HEALTH_PROTECTION",
    DISTANCE_BUSINESS_EMPLOYER: "DISTANCE_BUSINESS_EMPLOYER",
    ENERGY_EFFICIENCY: "ENERGY_EFFICIENCY",
    DTV_CERTIFICATE: "DTV_CERTIFICATE",
    CONTACT_PERSON: "CONTACT_PERSON",
    FIRST_DELIVERY: "FIRST_DELIVERY",
    RESPONSE_TIMES: "RESPONSE_TIMES",
    TECHNICAL_RESOURCES: "TECHNICAL_RESOURCES",
    DELIVERY_QUANTITY_STATISTICS: "DELIVERY_QUANTITY_STATISTICS",
    COMPLAINT_MANAGEMENT: "COMPLAINT_MANAGEMENT",
    MAINTENANCE: "MAINTENANCE",
    DELIVERY_DESPITE_DISRUPTION: "DELIVERY_DESPITE_DISRUPTION",
    ACTION_ON_VEHICLE_FAILURE: "ACTION_ON_VEHICLE_FAILURE",
    ACTION_ON_OPERATIONAL_DISRUPTIONS: "ACTION_ON_OPERATIONAL_DISRUPTIONS",
    ACTION_ON_SHORT_TERM_FAILURES: "ACTION_ON_SHORT_TERM_FAILURES",
    ACTION_ON_LONG_TERM_FAILURES: "ACTION_ON_LONG_TERM_FAILURES",
    OPTICS_GENERAL: "OPTICS_GENERAL",
    MODERATION: "MODERATION",
    SMOOTHNESS: "SMOOTHNESS",
    FOLDING_SYSTEM: "FOLDING_SYSTEM",
    FOLDING_QUALITY: "FOLDING_QUALITY",
    GRIP_AND_FEEL: "GRIP_AND_FEEL",
    DESIGN_AND_COLOUR: "DESIGN_AND_COLOUR",
    SEAM_PROCESSING: "SEAM_PROCESSING"
}

export const RATING_CATEGORY = {
    PRICE: "PRICE",
    SUSTAINABILITY: "SUSTAINABILITY",
    CUSTOMER_SERVICE: "CUSTOMER_SERVICE",
    SUPPLY_SECURITY: "SUPPLY_SECURITY",
    SAMPLING: "SAMPLING"
}

export const getRatingArguments = (category) => {
    switch (category) {
        case RATING_CATEGORY.CUSTOMER_SERVICE:
            return [
                RATING_TYPES.CONTACT_PERSON,
                RATING_TYPES.FIRST_DELIVERY,
                RATING_TYPES.RESPONSE_TIMES,
                RATING_TYPES.TECHNICAL_RESOURCES,
                RATING_TYPES.DELIVERY_QUANTITY_STATISTICS,
                RATING_TYPES.COMPLAINT_MANAGEMENT
            ];
        case RATING_CATEGORY.SUSTAINABILITY:
            return [
                RATING_TYPES.MANUFACTURING_PROCESS,
                RATING_TYPES.HEALTH_PROTECTION,
                RATING_TYPES.DISTANCE_BUSINESS_EMPLOYER,
                RATING_TYPES.ENERGY_EFFICIENCY,
                RATING_TYPES.DTV_CERTIFICATE
            ];
        case RATING_CATEGORY.SUPPLY_SECURITY:
            return [
                RATING_TYPES.MAINTENANCE,
                RATING_TYPES.DELIVERY_DESPITE_DISRUPTION,
                RATING_TYPES.ACTION_ON_VEHICLE_FAILURE,
                RATING_TYPES.ACTION_ON_OPERATIONAL_DISRUPTIONS,
                RATING_TYPES.ACTION_ON_SHORT_TERM_FAILURES,
                RATING_TYPES.ACTION_ON_LONG_TERM_FAILURES
            ];
        case RATING_CATEGORY.SAMPLING:
            return [
                RATING_TYPES.OPTICS_GENERAL,
                RATING_TYPES.MODERATION,
                RATING_TYPES.SMOOTHNESS,
                RATING_TYPES.FOLDING_SYSTEM,
                RATING_TYPES.FOLDING_QUALITY,
                RATING_TYPES.GRIP_AND_FEEL,
                RATING_TYPES.DESIGN_AND_COLOUR,
                RATING_TYPES.SEAM_PROCESSING
            ];
        case RATING_CATEGORY.PRICE:
        default:
            return [];
    }
}

export const INVOICE_CONFLICT_TYPE = {
    NO_CONFLICT: "NO_CONFLICT",
    INCOMPLETE_ARTICLE: "INCOMPLETE_ARTICLE",
    UNKNOWN_ARTICLE: "UNKNOWN_ARTICLE",
    MISSING_ARTICLE: "MISSING_ARTICLE",
    WRONG_PRICE: "WRONG_PRICE",
    DUPLICATED_ARTICLE: "DUPLICATED_ARTICLE"
}

export const PRICE_SHEET_STATE = {
    COMPLETE: "COMPLETE",
    INCOMPLETE: "INCOMPLETE",
    COMPLETE_BESIDES_NEW_ITEMS: "COMPLETE_BESIDES_NEW_ITEMS"
}

export const PAYMENT_METHOD_TYPES = {
    INVOICE: "INVOICE",
    SEPA_OR_CARD: "SEPA_OR_CARD"
}

export const LICENSE_TYPES = {
    TENDER_LICENSE: "TENDER_LICENSE",
    OFFER_LICENSE: "OFFER_LICENSE",
    COOPERATION_LICENSE: "COOPERATION_LICENSE"
}

export const PACKAGE_TYPES = {
    EASY_FOR_EVERYONE_PACKAGE: "EASY_FOR_EVERYONE_PACKAGE"
}

export const EVENT_TYPES = {
    ANALYSIS: "ANALYSIS",
    CONSULTING: "CONSULTING"
}

export const EVENT_DURATIONS = {
    HALF_HOUR: "30min",
    FULL_HOUR: "60min"
}

export const PROJECT_TASKS = {
    PROJECT: "PROJECT",
    BUSINESS_UNIT: "BUSINESS_UNIT",
    RENTAL_LINEN: "RENTAL_LINEN",
    CUSTOMER_ARTICLES: "CUSTOMER_ARTICLES",
    RESIDENTS_LAUNDRY: "RESIDENTS_LAUNDRY",
    OPERATING_RESOURCES: "OPERATING_RESOURCES",
    SPECIAL_SERVICES: "SPECIAL_SERVICES",
    OFFSETTING: "OFFSETTING",
    LOGISTIC: "LOGISTIC",
    DELIVERY_ADDRESS: "DELIVERY_ADDRESS",
    PICKUP_ADDRESS: "PICKUP_ADDRESS",
    ARTICLE_ASSIGNMENT: "ARTICLE_ASSIGNMENT",
    DELIVERY_QUANTITY: "DELIVERY_QUANTITY",
    CONTRACT: "CONTRACT",
    PROCEDURE: "PROCEDURE",
}

export const PROJECT_TASK_GROUP = {
    PROJECT: "PROJECT",
    BUSINESS_UNIT: "BUSINESS_UNIT",
    LOGISTIC_UNIT: "LOGISTIC_UNIT"
}

export const CONFLICT_TYPES = {
    MISSING_BUSINESS_UNIT: "MISSING_BUSINESS_UNIT",
    ONLY_ONE_CENTRAL_DELIVERY_ADDRESS: "ONLY_ONE_CENTRAL_DELIVERY_ADDRESS",
    ONLY_ONE_CENTRAL_PICKUP_ADDRESS: "ONLY_ONE_CENTRAL_PICKUP_ADDRESS",
    CENTRAL_NO_DECENTRAL_DELIVERY_TIMES: "CENTRAL_NO_DECENTRAL_DELIVERY_TIMES",
    CENTRAL_NO_DECENTRAL_PICK_UP_ADDRESSES: "CENTRAL_NO_DECENTRAL_PICK_UP_ADDRESSES",
    CENTRAL_MISSING_CENTRAL_DELIVERY_ADDRESS: "CENTRAL_MISSING_CENTRAL_DELIVERY_ADDRESS",
    CENTRAL_MISSING_CENTRAL_PICKUP_ADDRESS: "CENTRAL_MISSING_CENTRAL_PICKUP_ADDRESS",
    DECENTRAL_NO_CENTRAL_DELIVERY_ADDRESSES: "DECENTRAL_NO_CENTRAL_DELIVERY_ADDRESSES",
    DECENTRAL_NO_CENTRAL_PICKUP_ADDRESSES: "DECENTRAL_NO_CENTRAL_PICKUP_ADDRESSES",
    DECENTRAL_MISSING_DECENTRAL_PICKUP_ADDRESS: "DECENTRAL_MISSING_DECENTRAL_PICKUP_ADDRESS",
    MISSING_DECENTRAL_DELIVERY_ADDRESS: "MISSING_DECENTRAL_DELIVERY_ADDRESS",
    SINGLE_NO_DECENTRAL_DELIVERY_ADDRESSES: "SINGLE_NO_DECENTRAL_DELIVERY_ADDRESSES",
    SINGLE_NO_DECENTRAL_PICKUP_ADDRESSES: "SINGLE_NO_DECENTRAL_PICKUP_ADDRESSES",
    SINGLE_MISSING_CENTRAL_DELIVERY_ADDRESS: "SINGLE_MISSING_CENTRAL_DELIVERY_ADDRESS",
    SINGLE_MISSING_CENTRAL_PICKUP_ADDRESS: "SINGLE_MISSING_CENTRAL_PICKUP_ADDRESS",
    DELIVERY_QUANTITIES_MISSING: "DELIVERY_QUANTITIES_MISSING",
    CENTRAL_NO_CENTRAL_DELIVERY_QUANTITIES: "CENTRAL_NO_CENTRAL_DELIVERY_QUANTITIES",
    CENTRAL_MISSING_CENTRAL_DELIVERY_TIMES: "CENTRAL_MISSING_CENTRAL_DELIVERY_TIMES",
    CENTRAL_MISSING_CENTRAL_PICKUP_TIMES: "CENTRAL_MISSING_CENTRAL_PICKUP_TIMES",
    DECENTRAL_MISSING_DECENTRAL_DELIVERY_TIMES: "DECENTRAL_MISSING_DECENTRAL_DELIVERY_TIMES",
    DECENTRAL_MISSING_DECENTRAL_PICKUP_TIMES: "DECENTRAL_MISSING_DECENTRAL_PICKUP_TIMES",
    SINGLE_MISSING_CENTRAL_DELIVERY_TIMES: "SINGLE_MISSING_CENTRAL_DELIVERY_TIMES",
    SINGLE_MISSING_CENTRAL_PICKUP_TIMES: "SINGLE_MISSING_CENTRAL_PICKUP_TIMES",
    RESOURCE_OFFSETTING_MISMATCH: "RESOURCE_OFFSETTING_MISMATCH",
    STATIONARY_OFFSETTING_MISMATCH: "STATIONARY_OFFSETTING_MISMATCH",
    WORK_WEAR_OFFSETTING_MISMATCH: "WORK_WEAR_OFFSETTING_MISMATCH",
    SURGICAL_TEXTILES_OFFSETTING_MISMATCH: "SURGICAL_TEXTILES_OFFSETTING_MISMATCH",
    STATIONARY_OFFSETTING_MISSING: "STATIONARY_OFFSETTING_MISSING",
    WORK_WEAR_OFFSETTING_MISSING: "WORK_WEAR_OFFSETTING_MISSING",
    SURGICAL_TEXTILES_OFFSETTING_MISSING: "SURGICAL_TEXTILES_OFFSETTING_MISSING",
    RESOURCE_OFFSETTING_MISSING: "RESOURCE_OFFSETTING_MISSING",
    SPECIAL_SERVICE_OFFSETTING_MISSING: "SPECIAL_SERVICE_OFFSETTING_MISSING",
    ASSORTMENT_ARTICLES_MISSING: "ASSORTMENT_ARTICLES_MISSING",
    RESIDENTS_LAUNDRY_MISSING: "RESIDENTS_LAUNDRY_MISSING",
    RESIDENTS_LAUNDRY_AMOUNT_MISSING: "RESIDENTS_LAUNDRY_AMOUNT_MISSING",
    CUSTOMER_ARTICLES_MISSING: "CUSTOMER_ARTICLES_MISSING",
    CUSTOMER_ARTICLES_AMOUNT_MISSING: "CUSTOMER_ARTICLES_AMOUNT_MISSING",
    OPERATING_RESOURCES_MISSING: "OPERATING_RESOURCES_MISSING",
    PROCEDURE_DATES_MISSING: "PROCEDURE_DATES_MISSING",
    PROCEDURE_STATE_MISSING: "PROCEDURE_STATE_MISSING",
    PROCEDURE_RATINGS_MISSING: "PROCEDURE_RATINGS_MISSING",
    PROCEDURE_DATES_INVALID: "PROCEDURE_DATES_INVALID",
    PROCEDURE_RATINGS_INVALID: "PROCEDURE_RATINGS_INVALID"
}

export const ARTICLE_CATEGORIES = {
    DUVET_COVER: "DUVET_COVER",
    PILLOW_COVER: "PILLOW_COVER"
}

export const TEXISION_VERTRIEB_MAIL_ADDRESS = "vertrieb@texision.de";

export const FEDERAL_STATES_CAPITALS_DATA = [
    {textKey: "schwerin", coordinates: [11.41, 53.63], labelOffsetY: 8},
    {textKey: "berlin", coordinates: [13.39, 52.52], labelOffsetY: -4},
    {textKey: "potsdam", coordinates: [13.06, 52.40], labelOffsetY: 8},
    {textKey: "hanover", coordinates: [9.74, 52.37], labelOffsetY: 8},
    {textKey: "duesseldorf", coordinates: [6.78, 51.23], labelOffsetY: -4},
    {textKey: "magdeburg", coordinates: [11.64, 52.13], labelOffsetY: -4},
    {textKey: "erfurt", coordinates: [11.03, 50.98], labelOffsetY: 8},
    {textKey: "wiesbaden", coordinates: [8.24, 50.08], labelOffsetY: -4},
    {textKey: "saarbruecken", coordinates: [7.00, 49.23], labelOffsetY: -4},
    {textKey: "stuttgart", coordinates: [9.18, 48.78], labelOffsetY: -4},
    {textKey: "munich", coordinates: [11.58, 48.14], labelOffsetY: 8},
    {textKey: "hamburg", coordinates: [10.00, 53.55], labelOffsetY: 8},
    {textKey: "bremen", coordinates: [8.81, 53.08], labelOffsetY: 8},
    {textKey: "mainz", coordinates: [8.28, 50.00], labelOffsetY: 8},
    {textKey: "kiel", coordinates: [10.14, 54.32], labelOffsetY: 8},
    {textKey: "dresden", coordinates: [13.74, 51.05], labelOffsetY: 8}
];

export const GERMANY_CENTER_COORDINATES = {
    longitude: 10.45, latitude: 51.16
};

export const OPERATION_TYPES = {
    ORIGIN_COCKPIT: "ORIGIN_COCKPIT",
    ORIGIN_TENDER: "ORIGIN_TENDER"
}

export const PROFESSIONAL_GROUP_TYPE_PERSONAL = "PERSONAL";
export const GENDER_UNISEX= "UNISEX";

export const UNIT_LOGO_DESCRIPTOR = "UNIT_LOGO";
export const UNIT_IMAGE_DESCRIPTOR = "UNIT_IMAGE"

export const DELIVERY_PERIOD_ONE_WEEK = "DELIVERY_PERIOD_ONE_WEEK";
export const DELIVERY_PERIOD_TWO_WEEKS = "DELIVERY_PERIOD_TWO_WEEKS";
export const DELIVERY_PERIOD_MONTH = "DELIVERY_PERIOD_MONTH";
export const DELIVERY_PERIODS = [
    DELIVERY_PERIOD_ONE_WEEK,
    DELIVERY_PERIOD_TWO_WEEKS,
    DELIVERY_PERIOD_MONTH
];

export const DURATION_ONE_WEEK = 604800000
export const DURATION_TWO_WEEKS = 2 * DURATION_ONE_WEEK;
export const DURATION_MONTH = 4 * DURATION_ONE_WEEK;

export function getCockpitStatisticsCategoryLabel(intl, category) {
    if (!category) {
        return "";
    }
    let textId;
    if (category?.includes("all")) {
        textId = "cockpit.statistics.categories.fullPrices";
    } else if (category?.includes("ww")) {
        textId = "constants.Category.WORKWEAR";
    } else if (category?.includes("fl")) {
        textId = "constants.Category.FLAT_LINEN";
    } else if (category?.includes("ca")) {
        textId = "constants.PRICE_TYPE.CUSTOMER_ARTICLE";
    } else if (category?.includes("rl")) {
        textId = "constants.PRICE_TYPE.RESIDENTS_LAUNDRY";
    } else if (category?.includes("or")) {
        textId = "constants.PRICE_TYPE.OPERATING_RESOURCE";
    } else if (category?.includes("sp")) {
        textId = "constants.PRICE_TYPE.SPECIAL_SERVICE";
    }
    return textId ? intl.formatMessage({id: textId}) : "";
}

export const INTERVAL_TYPES = {
    MONTHLY: "MONTHLY",
    QUARTERLY: "QUARTERLY",
    YEARLY: "YEARLY"
};

export const INTERVAL_TYPE_KEY = "INTERVAL_TYPE";

export const MONTHS = {
    JANUARY: "JANUARY",
    FEBRUARY: "FEBRUARY",
    MARCH: "MARCH",
    APRIL: "APRIL",
    MAY: "MAY",
    JUNE: "JUNE",
    JULY: "JULY",
    AUGUST: "AUGUST",
    SEPTEMBER: "SEPTEMBER",
    OCTOBER: "OCTOBER",
    NOVEMBER: "NOVEMBER",
    DECEMBER: "DECEMBER"
}

export function getMonthValue(month) {
    switch (month) {
        case MONTHS.JANUARY: return 1;
        case MONTHS.FEBRUARY: return 2;
        case MONTHS.MARCH: return 3;
        case MONTHS.APRIL: return 4;
        case MONTHS.MAY: return 5;
        case MONTHS.JUNE: return 6;
        case MONTHS.JULY: return 7;
        case MONTHS.AUGUST: return 8;
        case MONTHS.SEPTEMBER: return 9;
        case MONTHS.OCTOBER: return 10;
        case MONTHS.NOVEMBER: return 11;
        case MONTHS.DECEMBER: return 12;
        default: return 0;
    }
}
