import { Typography } from "@material-ui/core";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { GeneralContext } from "../contexts/GeneralContext";

class OrderDashboard extends Component {

    static contextType = GeneralContext;

    render() {
        const hasContracts = this.context.appData.allProjects && this.context.appData.allProjects.length > 0;
        const activeProjectId = this.context.appData.activeProjectId;
        const hasContractSelected = activeProjectId !== null && activeProjectId !== undefined && hasContracts;
        const contractName = this.context.appData.allProjects?.find(p => p.id === activeProjectId)?.name;

        return (
            <>

                <Typography variant="h1">
                    {hasContractSelected 
                    ? <FormattedMessage id="order.dashboard.contract.title" values={{contractName: contractName}}/>
                    : <FormattedMessage id="order.dashboard.title"/>}
                </Typography>

                <Typography variant="subtitle1">
                    {hasContracts 
                    ? (hasContractSelected 
                        ? <FormattedMessage id="order.dashboard.subtitle"/> 
                        : <FormattedMessage id="order.dashboard.noContractSelected.subtitle"/>)
                    : <FormattedMessage id="order.dashboard.noContracts.subtitle"/>}
                </Typography>

            </>
        );
    }
}

export default injectIntl(OrderDashboard);
