import {deleteAsyncCatch, getAsyncCatch, postAsyncCatch, putAsyncCatch} from "./BackendService";
import {updateValidationMap} from "./ProjectService";

export async function getCustomerArticlesForUnit(context, props, unitId) {
    const businessUnitId = unitId !== null && unitId !== undefined ? unitId : context.appData.activeUnitId;
    const customerArticles = await getAsyncCatch(context, "/customerarticles/" + businessUnitId, props);
    return customerArticles ? customerArticles : [];
}

export async function createCustomerArticle(context, props, customerArticle, projectId, hideSuccess) {
    let activeProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    const createdCustomerArticle = await postAsyncCatch(context, "/customerarticle/" + activeProjectId, customerArticle, props, hideSuccess);
    if (createdCustomerArticle) {
        await updateValidationMap(context);
    }
    return createdCustomerArticle ? createdCustomerArticle : null;
}

export async function updateCustomerArticle(context, props, customerArticle, projectId, hideSuccess) {
    let activeProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    const updatedCustomerArticle = await putAsyncCatch(context, "/customerarticle/" + activeProjectId, customerArticle, props, hideSuccess);
    return updatedCustomerArticle ? updatedCustomerArticle : null;
}

export async function deleteCustomerArticle(context, props, customerArticleId) {
    const success = await deleteAsyncCatch(context, "/customerarticle/" + context.appData.activeProjectId + "/" + customerArticleId, props);
    await updateValidationMap(context);
    return success;
}
