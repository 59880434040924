import {format} from 'date-fns';
import fileDownload from "js-file-download";
import {downloadUsingGet} from "../services/BackendService";
import {createErrorMessage} from "./Util";
import {de} from "date-fns/locale";
import {BILL_OF_QUANTITIES_TYPE, EXPORT_TYPE_MERGE, LICENSE_TYPES} from "./Constants";

export const MAX_FILE_SIZE_IN_BYTE = 5242880;
export const MAX_FILE_NAME_LENGTH = 50;

export function isExportTypeMerge(context) {
    return getExportType(context) === EXPORT_TYPE_MERGE;
}

export function getExportType(context) {
    return context.getUserStateValue(BILL_OF_QUANTITIES_TYPE);
}

export function formatIsoDate(intl, isoDate, dateFormat) {
    let milliseconds = isoDate.getTime();
    if (!milliseconds || milliseconds < 1) return intl.formatMessage({id: "procedure.document.noDate"});
    return format(milliseconds, dateFormat ?? "dd.MM.yyyy", {locale: de});
}

export function formatDate(intl, milliseconds, dateFormat) {
    if (!milliseconds || milliseconds < 1) return intl.formatMessage({id: "procedure.document.noDate"});
    return format(milliseconds, dateFormat ?? "dd.MM.yyyy", {locale: de});
}

export function cutName(intl, name) {
    if (!name) {
        return intl.formatMessage({id: "procedure.document.noName"});
    } else if (name.length <= MAX_FILE_NAME_LENGTH) {
        return name;
    } else {
        return name.substring(0, MAX_FILE_NAME_LENGTH);
    }
}

export const buildDocumentSummary = (intl, document) => {
    return cutName(intl, document.filename) + ", " + formatDate(intl, document.lastModified, "dd.MM.yyyy");
}

export async function downloadS3Document(props, document) {
    let response = await downloadUsingGet(document.url, true);
    const filename = document?.filename ?? props.intl.formatMessage({id: "procedure.document.noName"});
    if (response?.status === 200) {
        fileDownload(response.data, filename);
    } else {
        createErrorMessage(props.intl.formatMessage({id: "procedure.documents.load.errorMessage"}), props);
    }
}

export async function downloadDocument(props, context, url, documentName) {
    const response = await downloadUsingGet(url, true);
    if (response?.status === 200) {
        const filename = documentName ?? props.intl.formatMessage({id: "procedure.document.noName"});
        fileDownload(response.data, filename);
    } else if ([401, 403].includes(response?.status)) {
        await context.logout();
    } else if (response?.status === 402) {
        context.showNoLicenseDialog(LICENSE_TYPES.TENDER_LICENSE);
    } else {
        createErrorMessage(props.intl.formatMessage({id: "commons.download.document.error"}), props);
    }
}

export const selectDocument = async(file, onUpload, props, allFiles) => {
    if (file) {
        if (file.size >= MAX_FILE_SIZE_IN_BYTE) {
            createErrorMessage(props.intl.formatMessage({id: "documents.tooLarge"}), props);
        } else if (allFiles.find(f => f.filename === file.name)) {
            createErrorMessage(props.intl.formatMessage({id: "documents.duplicateFileName"}), props);
        } else {
            onUpload(file);
        }
    }
}

export const FILE_TYPES = {
    PDF: "application/pdf",
    DOC: "application/msword",
    DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    XLS: "application/ms-excel",
    XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    IMAGE: "image/*",
    CSV: ".csv",
    TEXT_CSV: "text/csv",
    TXT: "text/plain",
    XML: "application/xml,text/xml"
}

export function getAcceptedFileTypes(fileTypes) {
    if (!fileTypes?.length) {
        return null;
    }
    let text = "";
    for (let type of fileTypes) {
        text += type + ",";
    }
    return text.slice(0, -1);
}
