import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';

import {FormattedMessage, injectIntl} from 'react-intl';

import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


import '../apps/App.css';
import {getAsync} from '../../services/BackendService';
import {
    FLAT_RATE,
    SPLIT_PRICE,
    NO_OFFSETTING_SELECTED,
    RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL,
    RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH,
    EXPORT_TYPE_CONCATENATE,
    EXPORT_TYPE_MERGE,
    BILL_OF_QUANTITIES_TYPE,
    DIALOG_TYPE_ERROR,
    DE,
    LICENSE_TYPES
} from '../../util/Constants';

import {enhanceAssignedResourcesWithMasterData} from '../../util/Util';
import {renderCategories, renderOffsetting, renderVolumeType, renderFeatures, renderFeaturesWithoutType} from '../../util/TableUtil';

import {bodyBackgroundColor, texisionFontColorDark, texisionWarningOrange} from '../../util/ColorTheme';
import ExportHeader from './ExportHeader';

import fileDownload from  'js-file-download';
import {downloadUsingGet} from '../../services/BackendService';
import TexisionDialog from '../uiLibrary/TexisionDialog';
import {GeneralContext} from "../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import {getCleanType} from "../../services/ResidentsLaundryService";
import {latestActivePayment} from "../../services/PaymentService";


class PriceSheetExport extends Component {

    static contextType = GeneralContext;
    
    constructor(props) {
        super(props)
        this.state = {
            priceSheets: null,
            exportType: null,
            isLoading: true,
            showLockedDialog: false
        }
    }

    async componentDidMount() {
        this.reloadData();
    }

    reloadData = async() => {
        let exportType = this.context.getUserStateValue(BILL_OF_QUANTITIES_TYPE) ?? EXPORT_TYPE_CONCATENATE;
        let priceSheets = await this.loadPriceSheets(exportType);
        this.setState({priceSheets: priceSheets, exportType: exportType, isLoading: false});
    }

    /**
     * Util function to export the price sheet
     * 
     * @param {*} exportType Merge or concatenate business units
     */
    exportPriceSheet = async(exportType) => {
        let projectId = this.context.appData.activeProjectId;
        const response = await downloadUsingGet('/pricesheet/' + projectId + '/' + exportType + '/' + DE);
        if (response?.status === 200) {
            const fileName = response.headers['content-disposition'].split("filename=")[1];
            fileDownload(response.data, fileName);
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else if (response?.status === 402) {
            this.context.showNoLicenseDialog(LICENSE_TYPES.TENDER_LICENSE);
        } else if (response?.status === 423) {
            this.setState({showLockedDialog: true});
        }
    }

    loadPriceSheets = async(exportType) => {
        let projectId = this.context.appData.activeProjectId;
        let response = await getAsync("/pricesheet/" + projectId + "/" + exportType);
        if (response?.status === 200 && response.data) {
            return response.data.contentVos;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else {
            return [];
        }
    }

    handleExportSwitchClicked = async(checked) => {
        let exportType = checked ? EXPORT_TYPE_MERGE : EXPORT_TYPE_CONCATENATE;
        this.context.setUserStateValue(BILL_OF_QUANTITIES_TYPE, exportType);
        let priceSheets = await this.loadPriceSheets(exportType);
        this.setState({priceSheets: priceSheets, exportType: exportType});
    }

    determineQuantityTableHeader = (offsetting) => {
        return offsetting === FLAT_RATE ? this.props.intl.formatMessage({id: "priceSheet.table.titles.equipQuantity"}) : 
            this.props.intl.formatMessage({id: "priceSheet.table.titles.deliveredQuantity"});
    }



    assortment = (assortmentMapping) => {
        let items = [];
        let mappingByCategory = new Map(Object.entries(assortmentMapping));
        if (mappingByCategory.size > 0) {

            // Now we have the offsetting model for each main category
            for (let [category, offsettingObject] of mappingByCategory.entries()) {
                // We need to convert the given object into a Map
                let offsetMapping = new Map(Object.entries(offsettingObject));

                // Now we have a mapping of the offsetting to the articles of the price sheet. 
                // The priceSheetArticles is a List of Objects that contain information about the article itself and the offsetting.
                for (let [offsetting, priceSheetArticles] of offsetMapping) {

                    const quantityTitle = this.determineQuantityTableHeader(offsetting);
                    const columns = [
                        {title: this.props.intl.formatMessage({id: "entities.article.category"}), field: "articleVo.category",
                            render: value => renderCategories(value.articleVo, "Category")},
                        {title: this.props.intl.formatMessage({id: "entities.article.subCategory"}), field: "articleVo.subcategory",
                            render: value => renderCategories(value.articleVo, "SubCategory")},
                        {title: this.props.intl.formatMessage({id: "entities.article.articleCategory"}), field: "articleVo.articleCategory",
                            render: value => renderCategories(value.articleVo, "ArticleCategory")},
                        {title: this.props.intl.formatMessage({id: "entities.article.description"}), field: "articleVo.description"},
                        {title: this.props.intl.formatMessage({id: "articleOffsetting.offsetting.offsetting"}), field: "offsetting.articleOffsetting",
                            render: (value) => renderOffsetting(offsetting, value.offsetting.articleCategoryOffsetting, this.props.intl)}
                    ]

                    // Only add this column, if an offsetting was selected.
                    if (offsetting !== NO_OFFSETTING_SELECTED) {
                        columns.push({title: quantityTitle, field: "quantity", grouping: false, filtering: false});
                    }
                    items.push(
                        this.table(
                            this.props.intl.formatMessage({id: "constants.Category." + category}) + " - " + this.props.intl.formatMessage({id: "constants.ArticleOffsetting." + offsetting}), 
                            priceSheetArticles, 
                            columns, 
                            rowData => ({backgroundColor: (rowData.offsetting.articleCategoryOffsetting === SPLIT_PRICE) ? texisionWarningOrange : '#FFFFFF'})
                        )
                    );
                }
            }
        }
        return items;
    }

    customerArticles = (customerArticles) => {
        return this.table(
            this.props.intl.formatMessage({id: "customerArticle.title"}), 
            customerArticles, 
            [
                {title: this.props.intl.formatMessage({id: "customerArticle.table.header.description"}), field: "description"},
                {title: this.props.intl.formatMessage({id: "customerArticle.create.props.volume"}), field: "volume"},
                {title: this.props.intl.formatMessage({id: "customerArticle.table.header.volumeType"}), field: "volumeType", 
                    render: customerArticle => renderVolumeType(customerArticle, this.props.intl)},
                {title: this.props.intl.formatMessage({id: "customerArticle.create.features.title"}), field: "features", 
                    render: customerArticle => renderFeatures(customerArticle.features, this.props.intl, "constants.ArticleFeatureType.")},
            ]
        );
    }

    residentsLaundry = (residentsLaundry) => {
        const washedLaundry = residentsLaundry.sort((a, b) => ("" + a.name).localeCompare(b.name)).filter(l => l.cleanType === RESIDENTS_LAUNDRY_CLEAN_TYPE_WASH);
        const chemicalLaundry = residentsLaundry.sort((a, b) => ("" + a.name).localeCompare(b.name)).filter(l => l.cleanType === RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL);
        let laundry;
        if (washedLaundry) {
            laundry = washedLaundry.concat(chemicalLaundry);
        } else if (chemicalLaundry) {
            laundry = chemicalLaundry.concat(washedLaundry);
        } else {
            laundry = residentsLaundry;
        }
        return this.table(
            this.props.intl.formatMessage({id: "residentsLaundry.pricesheet.title"}), 
            laundry, 
            [
                {title: this.props.intl.formatMessage({id: "residentsLaundry.pricesheet.table.name"}), field: "name"},
                {title: this.props.intl.formatMessage({id: "residentsLaundry.pricesheet.table.cleanType"}), field: "cleanType",
                    render: article => getCleanType(this.props, article.cleanType)},
                {title: this.props.intl.formatMessage({id: "residentsLaundry.pricesheet.table.amount"}), field: "amount"},
                {title: this.props.intl.formatMessage({id: "residentsLaundry.pricesheet.table.offsetting.title"}), field: "offsetting", 
                    render: article => this.props.intl.formatMessage({id: "constants.ArticleOffsetting.DELIVERED_QUANTITY"})},
            ]
        );
    }

    specialServices = (specialServices) => {
        return this.table(
            this.props.intl.formatMessage({id: "specialServices.title"}), 
            specialServices, 
            [
                {title: this.props.intl.formatMessage({id: "specialServices.table.header.name"}), field: "name"},
                {title: this.props.intl.formatMessage({id: "specialServices.table.header.text"}), field: "text"},
                {title: this.props.intl.formatMessage({id: "specialServices.table.header.offsetting"}), field: "offsetting",
                    render: service => this.props.intl.formatMessage({id: "constants.ResourceOffsetting." + service.offsetting})},
                {title: this.props.intl.formatMessage({id: "specialServices.table.header.amount"}), field: "amount"},
            ]
        );
    }

    operatingResources = (operatingResources, unitResources) => {
        return this.table(
            this.props.intl.formatMessage({id: "operatingResources.overview.title"}), 
            enhanceAssignedResourcesWithMasterData(operatingResources, unitResources),
            [
                {title: this.props.intl.formatMessage({id: "operatingResources.table.category"}), field: "category", 
                    render: resource => this.props.intl.formatMessage({id: "constants.ResourceCategory." + resource.category})},
                {title: this.props.intl.formatMessage({id: "operatingResources.table.subCategory"}), field: "subCategory", 
                    render: resource => this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + resource.subCategory})},
                {title: this.props.intl.formatMessage({id: "operatingResources.table.name"}), field: "name"},
                {title: this.props.intl.formatMessage({id: "operatingResources.table.description"}), field: "description"},
                {title: this.props.intl.formatMessage({id: "operatingResources.table.amount"}), field: "count"},
                {title: this.props.intl.formatMessage({id: "operatingResources.table.offsetting"}), field: "resourceOffsetting", 
                    render: resource => this.props.intl.formatMessage({id: "constants.ResourceOffsetting." + resource.resourceOffsetting})},
                {title: this.props.intl.formatMessage({id: "operatingResources.table.features"}), field: "features", 
                    render: resource => renderFeaturesWithoutType(resource.features)}
            ]
        );
    }

    table = (title, data, columns, rowStyle) => {
        return <Accordion elevation={1} key={title}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="operating-resouces-content"
                id="operating-resouces-header">
                <Typography variant="h6" component="span">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <MaterialTable
                    columns={columns}
                    data={data}        
                    options={{
                        grouping: false,
                        sorting: true,
                        filtering: false,
                        draggable: false, 
                        search: false, 
                        paging: false, 
                        selection: false,
                        showSelectAllCheckbox: false,
                        headerStyle: {
                            fontWeight: 'bold',
                            backgroundColor: bodyBackgroundColor,
                            color: texisionFontColorDark
                        },
                        showTitle: false, 
                        toolbar: false,
                        rowStyle: rowStyle
                    }}
                    style={{
                        padding: "5px",
                        marginTop: "10px",
                        width: "100%",
                    }}
                    components={{
                        Container: props => (<Paper {...props} key={this.props.type} elevation={0}/>)
                    }}
                    localization={{
                        grouping: {
                            placeholder: this.props.intl.formatMessage({id: "commons.table.grouping.placeholder"}),
                            groupedBy: this.props.intl.formatMessage({id: "commons.table.grouping.groupedBy"})
                        },
                        body: {
                            emptyDataSourceMessage: this.props.intl.formatMessage({id: "priceSheet.noData"})
                        },
                        toolbar: {
                            nRowsSelected: this.props.intl.formatMessage({id: "commons.table.message.emptySelection"})
                        }
                    }}
                />
            </AccordionDetails>
        </Accordion>
    }

    hasData = () => {
        if (!this.state.priceSheets || this.state.priceSheets.length === 0) {
            return false;
        }
        let hasData = false;
        for (let priceSheet of this.state.priceSheets) {
            if (this.hasSinglePriceSheetData(priceSheet)) {
                hasData = true;
                break;
            }
        }
        return hasData;
    }

    hasSinglePriceSheetData = (priceSheet) => {
        if (priceSheet && (
               (priceSheet.articles && new Map(Object.entries(priceSheet.articles)).size > 0) 
            || (priceSheet.customerArticles && priceSheet.customerArticles.length > 0) 
            || (priceSheet.residentsLaundry && priceSheet.residentsLaundry.length > 0) 
            || (priceSheet.specialServices && priceSheet.specialServices.length > 0) 
            || (priceSheet.unitResources && priceSheet.unitResources.length > 0 && priceSheet.operatingResources && priceSheet.operatingResources.length > 0))) {
            return true;
        } else {
            return false;
        }
    }

    singlePriceSheet = (priceSheet, key) => {
        if (this.hasSinglePriceSheetData(priceSheet)) {
            return <Grid key={key} item xs={12} style={{minWidth: "400px"}}>
                <Card style={{paddingBottom: "20px"}}>
                    <CardContent>
                        <Typography variant="h2" style={{marginBottom: "1%"}}>{priceSheet.exportable.name}</Typography>
                        {priceSheet.articles && new Map(Object.entries(priceSheet.articles)).size > 0 && this.assortment(priceSheet.articles)}
                        {priceSheet.customerArticles && priceSheet.customerArticles.length > 0 && this.customerArticles(priceSheet.customerArticles)}
                        {priceSheet.residentsLaundry && priceSheet.residentsLaundry.length > 0 && this.residentsLaundry(priceSheet.residentsLaundry)}
                        {priceSheet.specialServices && priceSheet.specialServices.length > 0 && this.specialServices(priceSheet.specialServices)}
                        {priceSheet.unitResources && priceSheet.unitResources.length > 0 && priceSheet.operatingResources && priceSheet.operatingResources.length > 0 && this.operatingResources(priceSheet.operatingResources, priceSheet.unitResources)}
                    </CardContent>
                </Card>
            </Grid>;
        } else {
            return <div/>;
        }
    }

    render() {
        let index = 0;
        return (
            <div className={latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE) ? "contentAreaExportLocked" : null}>
                <ExportHeader
                    documentType="priceSheet"
                    titleId="priceSheet.h1"
                    subtitleId="priceSheet.h2"
                    exportButtonId="priceSheet.export.excel"
                    exportType={this.state.exportType}
                    handleExportSwitch={this.handleExportSwitchClicked}
                    export={(exportType, e) => this.exportPriceSheet(exportType)}
                    forAllUnits={true}
                />

                <TexisionDialog
                    type={DIALOG_TYPE_ERROR}
                    open={this.state.showLockedDialog}
                    titleId="export.locked.title"
                    subtitleId="export.locked.subtitle"
                    actionId="commons.okay.button"
                    onAction={() => this.setState({showLockedDialog: false})}/>

                {!this.state.isLoading && !this.hasData() && <Card>
                    <CardContent style={{textAlign: "center"}} >
                        <Typography variant="subtitle1" component="span">
                            <FormattedMessage id="priceSheet.noData"/>
                        </Typography>
                    </CardContent>
                </Card>}

                {this.hasData() && <Grid container spacing={1}>
                    {this.state.priceSheets.map(p => this.singlePriceSheet(p, index++))}
                </Grid>}
            </div>
        );
    }
}

export default withRouter(injectIntl(PriceSheetExport));
