import React, {Component} from "react";
import {texisionGreen, texisionOrange, white} from "../../util/ColorTheme";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import {Avatar} from "@material-ui/core";

class ValidationIcon extends Component {

    render() {
        const completedSize = this.props.size ?? 16;
        const uncompletedSize = completedSize - 3;
        if (this.props.complete) {
            return (
                <Avatar style={{backgroundColor: white, height: completedSize-4, width: completedSize-4}}>
                    <CheckCircleRoundedIcon
                        style={{color: texisionGreen, height: completedSize, width: completedSize}}/>
                </Avatar>
            );
        } else {
            return (
                <Avatar style={{backgroundColor: texisionOrange, height: uncompletedSize, width: uncompletedSize}}>
                    <MoreHorizRoundedIcon style={{color: white, height: uncompletedSize, width: uncompletedSize}}/>
                </Avatar>
            );
        }
    }
}

export default ValidationIcon;
