import { Button, Checkbox, DialogContentText, Grid, TextField, Typography } from "@material-ui/core";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { isBidder, isBidderReadOnly, isProjectAdmin, Role } from "../../services/UserService";
import { GeneralContext } from "../contexts/GeneralContext";
import Select from "react-select";
import { texisionWarningOrange } from "../../util/ColorTheme";
import { putAsync } from "../../services/BackendService";
import { createErrorMessage, createSuccessMessage } from "../../util/Util";
import { withSnackbar } from "notistack";

class UserProfile extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        const currentUser = this.props.currentUser;
        this.state = {
            oldUser: {...currentUser},
            newUser: {...currentUser},
            newsletterDisabledReason: ""
        }
    }

    componentDidUpdate() {
        let hasUnsavedChanges = !!(this.state.oldUser.title !== this.state.newUser.title
            || this.state.oldUser.firstName !== this.state.newUser.firstName
            || this.state.oldUser.lastName !== this.state.newUser.lastName
            || this.state.oldUser.company !== this.state.newUser.company
            || this.state.oldUser.jobTitle !== this.state.newUser.jobTitle
            || this.state.oldUser.streetAndNumber !== this.state.newUser.streetAndNumber
            || this.state.oldUser.zipCode !== this.state.newUser.zipCode
            || this.state.oldUser.city !== this.state.newUser.city
            || this.state.oldUser.phoneNumber !== this.state.newUser.phoneNumber
            || this.state.oldUser.vatId !== this.state.newUser.vatId
            || this.state.oldUser.hasNewsletter !== this.state.newUser.hasNewsletter
            || this.state.newsletterDisabledReason);
        this.context.setUnsavedChanges(hasUnsavedChanges);
    }

    updateProfile = async() => {

        const user = {...this.state.newUser, newsletterDisabledReason: this.state.newsletterDisabledReason};

        const response = await putAsync('/user', user);

        if (response?.status === 200) {

            await this.context.loadUser();
            this.props.onSuccess();
            createSuccessMessage(this.props.intl.formatMessage({id: "commons.update.message.success"}), this.props);

        } else if ([401, 403].includes(response?.status)) {

            createErrorMessage(this.props.intl.formatMessage({id: "commons.update.message.error"}), this.props);
            this.context.logout();

        } else if (response?.status === 409) {

            this.context.setShowOptimisticLockDialog(true);

        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "commons.update.message.error"}), this.props);
        }
    }

    handleZipCodeChange = (newZipCode) => {
        const zipCodeRegex = /^[0-9]*$/;
        if (newZipCode === "" || zipCodeRegex.test(newZipCode)) {
            this.setState({newUser: {...this.state.newUser, zipCode: newZipCode}});
        }
    }

    cannotSave = () => {
        const user = this.state.newUser;
        if (!user) {
            return true;
        }
        const missingGeneralData = !user.title || !user.firstName || !user.lastName || !user.company;
        let missingSpecificData = false;
        if (isProjectAdmin(user)) {
            missingSpecificData = !user.streetAndNumber || !user.zipCode || !user.city || !user.phoneNumber || !user.vatId;
        } else if (isBidderReadOnly(user) || isBidder(user)) {
            missingSpecificData = !user.streetAndNumber|| !user.zipCode|| !user.city || !user.phoneNumber;
        }
        return missingGeneralData || missingSpecificData;
    }

    render() {
        const user = this.context.currentUser;
        const hasBidderRole = isBidderReadOnly(user) || isBidder(user);
        const showNewsletterDisabledReason = hasBidderRole && this.state.oldUser?.hasNewsletter && !this.state.newUser?.hasNewsletter;

        const areAddressDataMandatory = isProjectAdmin(user) || isBidderReadOnly(user) || isBidder(user);
        const isVatIdMandatory = isProjectAdmin(user);

        return (
            <>
            
                <Typography variant="h5" component="h2" style={{marginBottom: 20}}>
                    <FormattedMessage id="userprofil.personaldata"/>
                </Typography>

                <Grid 
                    container 
                    direction="row" 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    spacing={1}>

                    {/* salutation */}
                    <Grid item xs={2}>
                        <Typography><FormattedMessage id="useradmin.title"/> *</Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <Select
                            id="person-title-id"
                            required
                            value={{
                                value: this.state.newUser?.title ?? "", 
                                label: this.state.newUser?.title 
                                    ? this.props.intl.formatMessage({id: "constants.Salutation." + this.state.newUser?.title}) 
                                    : this.props.intl.formatMessage({id: "userprofil.salutation.hint"}) 
                            }}
                            onChange={(e) => this.setState({newUser: {...this.state.newUser, title: e.value}})}
                            options={this.context.appData.userTitles.map(title => {
                                return {
                                    value: title, 
                                    label: this.props.intl.formatMessage({id: "constants.Salutation." + title}) 
                                };
                            })}>
                        </Select>
                    </Grid>

                    {/* first name */}
                    <Grid item xs={2}>
                        <Typography>
                            <FormattedMessage id="userprofil.firstname"/> *
                        </Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <TextField 
                            id="firstname"
                            required
                            value={this.state.newUser?.firstName}
                            variant="outlined" 
                            inputProps={{maxLength: 64}}
                            style={this.state.newUser?.firstName ? {width: "100%"} : {width: "100%", backgroundColor: texisionWarningOrange}}
                            onChange={(e) => this.setState({newUser: {...this.state.newUser, firstName: e.target.value}})}/>
                    </Grid>

                    {/* last name */}
                    <Grid item xs={2}>
                        <Typography>
                            <FormattedMessage id="userprofil.lastname"/> *
                        </Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <TextField 
                            id="lastname"
                            required
                            value={this.state.newUser?.lastName}
                            variant="outlined" inputProps={{maxLength: 64}}
                            style={this.state.newUser?.lastName ? {width: "100%"} : {width: "100%", backgroundColor: texisionWarningOrange}}
                            onChange={(e) => this.setState({newUser: {...this.state.newUser, lastName: e.target.value}})}/>
                    </Grid>

                </Grid>

                <Typography variant="h5" component="h2" style={{marginBottom: 20, marginTop: 20}}>
                    <FormattedMessage id="userprofil.companyData"/>
                </Typography>

                <Grid 
                    container 
                    direction="row" 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    spacing={1}>

                    {/* company */}
                    <Grid item xs={2} style={{wordBreak: "break-all"}}>
                        <Typography>
                            <FormattedMessage id="userprofil.company"/> *
                        </Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <TextField 
                            id="company"
                            required
                            value={this.state.newUser?.company}
                            variant="outlined" 
                            inputProps={{maxLength: 64}}
                            style={this.state.newUser?.company ? {width: "100%"} : {width: "100%", backgroundColor: texisionWarningOrange}}
                            onChange={(e) => this.setState({newUser: {...this.state.newUser, company: e.target.value}})}/>
                    </Grid>


                    {user?.role && user.role !== Role.ORDERER && <>

                        {/* job title */}
                        <Grid item xs={2} style={{wordBreak: "break-all"}}>
                            <Typography>
                                <FormattedMessage id="userprofil.jobTitle"/>
                            </Typography>
                        </Grid>

                        <Grid item xs={10}>
                            <TextField 
                                id="jobTitle"
                                required
                                value={this.state.newUser?.jobTitle}
                                variant="outlined" 
                                inputProps={{maxLength: 64}}
                                style={{width: "100%"}}
                                onChange={(e) => this.setState({newUser: {...this.state.newUser, jobTitle: e.target.value}})}/>
                        </Grid>
                    
                        {/* street and house number */}
                        <Grid item xs={2} style={{wordBreak: "break-all"}}>
                            <Typography>
                                <FormattedMessage id="userprofil.streetAndNumber"/> {areAddressDataMandatory ? "*" : ""} 
                            </Typography>
                        </Grid>

                        <Grid item xs={10}>
                            <TextField 
                                id="streetAndNumber"
                                required
                                value={this.state.newUser?.streetAndNumber}
                                variant="outlined" 
                                inputProps={{maxLength: 128}}
                                style={(areAddressDataMandatory && !this.state.newUser?.streetAndNumber) 
                                    ? {width: "100%", backgroundColor: texisionWarningOrange} : {width: "100%"}}
                                onChange={(e) => this.setState({newUser: {...this.state.newUser, streetAndNumber: e.target.value}})}/>
                        </Grid>

                        {/* zip code and city */}
                        <Grid item xs={2} style={{wordBreak: "break-all"}}>
                            <Typography>
                                <FormattedMessage id="userprofil.zipCodeAndCity"/> {areAddressDataMandatory ? "*" : ""} 
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <TextField 
                                id="zipCode"
                                required
                                value={this.state.newUser?.zipCode}
                                variant="outlined" 
                                inputProps={{maxLength: 16}}
                                style={(areAddressDataMandatory && !this.state.newUser?.zipCode) 
                                    ? {width: "100%", backgroundColor: texisionWarningOrange} : {width: "100%"}}
                                onChange={(e) => this.handleZipCodeChange(e.target.value)}/>
                        </Grid>

                        <Grid item xs={7}>
                            <TextField 
                                id="city"
                                required
                                value={this.state.newUser?.city}
                                variant="outlined" 
                                inputProps={{maxLength: 128}}
                                style={(areAddressDataMandatory && !this.state.newUser?.city) 
                                    ? {width: "100%", backgroundColor: texisionWarningOrange} : {width: "100%"}}
                                onChange={(e) => this.setState({newUser: {...this.state.newUser, city: e.target.value}})}/>
                        </Grid>

                        {/* phone number */}
                        <Grid item xs={2} style={{wordBreak: "break-all"}}>
                            <Typography>
                                <FormattedMessage id="userprofil.phoneNumber"/> {areAddressDataMandatory ? "*" : ""} 
                            </Typography>
                        </Grid>

                        <Grid item xs={10}>
                            <TextField 
                                id="phoneNumber"
                                required
                                value={this.state.newUser?.phoneNumber}
                                variant="outlined" 
                                inputProps={{maxLength: 20}}
                                style={(areAddressDataMandatory && !this.state.newUser?.phoneNumber) 
                                    ? {width: "100%", backgroundColor: texisionWarningOrange} : {width: "100%"}}
                                onChange={(e) => this.setState({newUser: {...this.state.newUser, phoneNumber: e.target.value}})}/>
                        </Grid>

                        {/* vat id */}
                        <Grid item xs={2} style={{wordBreak: "break-all"}}>
                            <Typography>
                                <FormattedMessage id="userprofil.vatId"/> {isVatIdMandatory ? "*" : ""}
                            </Typography>
                        </Grid>

                        <Grid item xs={10}>
                            <TextField 
                                id="vatId"
                                required
                                value={this.state.newUser?.vatId}
                                variant="outlined" 
                                inputProps={{maxLength: 64}}
                                style={(isVatIdMandatory && !this.state.newUser?.vatId) ? {width: "100%", backgroundColor: texisionWarningOrange} : {width: "100%"}}
                                onChange={(e) => this.setState({newUser: {...this.state.newUser, vatId: e.target.value}})}/>
                        </Grid>

                        {/* country */}
                        <Grid item xs={2} style={{wordBreak: "break-all"}}>
                            <Typography>
                                <FormattedMessage id="userprofil.country"/> *
                            </Typography>
                        </Grid>

                        <Grid item xs={10}>
                            <TextField 
                                disabled
                                required 
                                id="country"
                                value={this.props.intl.formatMessage({id: "constants.country.de"})}
                                variant="outlined" 
                                inputProps={{maxLength: 300}}
                                style={{width: "50%", backgroundColor: "lightgrey"}}/>
                        </Grid>
                    
                    </>}

                </Grid>

                <Typography variant="h5" component="h2" style={{marginBottom: 20, marginTop: 20}}>
                    <FormattedMessage id="userprofil.accountData"/>
                </Typography>

                <Grid
                    container 
                    direction="row" 
                    justifyContent="flex-start" 
                    alignItems="center" 
                    spacing={1}>

                    {/* mail */}
                    <Grid item xs={2} style={{wordBreak: "break-all"}}>
                        <Typography>
                            <FormattedMessage id="userprofil.email"/>
                        </Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <TextField 
                            id="username"
                            required
                            value={user?.username ?? ""}
                            variant="outlined"
                            disabled={true}
                            style={{width: "50%", backgroundColor: "lightgrey"}}/>
                    </Grid>
                    
                    {/* role */}
                    <Grid item xs={2} style={{wordBreak: "break-all"}}>
                        <Typography>
                            <FormattedMessage id="userprofil.role"/>
                        </Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <TextField 
                            id="role"
                            required
                            value={user ? this.props.intl.formatMessage({id: "constants.UserRole." + user.role}) : ""}
                            variant="outlined"
                            disabled={true}
                            style={{width: "50%", backgroundColor: "lightgrey"}}/>
                    </Grid>

                    {/* newsletter option for readonly and normal bidders*/}
                    {hasBidderRole && <>
                        
                        <Grid item xs={2}>
                            <Typography>
                                <FormattedMessage id="userprofil.newsletter"/>
                            </Typography>
                        </Grid>

                        <Grid item xs={10}>
                            <Checkbox
                                color="primary"
                                checked={this.state.newUser?.hasNewsletter}
                                onChange={() => this.setState({
                                    newUser: {...this.state.newUser, hasNewsletter: !this.state.hasNewsletter}, 
                                    newsletterDisabledReason: ""})
                                }
                                style={{marginLeft: -10, paddingLeft: -5}}/>
                        </Grid>

                    </>}

                    {showNewsletterDisabledReason && 
                        <TextField
                            id="newsletterDisabledReason"
                            value={this.state.newsletterDisabledReason}
                            onChange={(e) => this.setState({newsletterDisabledReason: e.target.value})}
                            variant="outlined"
                            label={this.props.intl.formatMessage({id: "bidder.newsletter.unsubscribe.reason.placeholder"})}
                            style={{width: "100%"}}/>}

                    {/* save button */}
                    <Grid item xs={12}>
                        <Button 
                            style={{marginTop: "40px"}} 
                            color="primary" 
                            variant="contained"
                            disabled={this.cannotSave()}
                            onClick={this.updateProfile}>
                            <FormattedMessage id="userprofil.button.updatePersonalData"/>
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <DialogContentText>
                            <FormattedMessage id="useradmin.createDialog.requiredNotice"/>
                        </DialogContentText>
                    </Grid>

                </Grid>
            
            </>
        );
    }
}

export default injectIntl(withSnackbar(UserProfile));
