import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {white} from "../../../util/ColorTheme";
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@material-ui/core/Popover';
import {AGB_ROUTE, IMPRESSUM_ROUTE, PRIVACY_ROUTE} from "../../../util/Constants";
import {Stack, Tooltip} from "@mui/material";
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PopoverItem from './PopoverItem';


class LegalMenu extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        }
    }

    handleClick = (event) => {
        this.setState({
            open: !this.state.open,
            anchorEl: event.currentTarget
        })
    }

    handleClose = () => {
        this.setState({
            open: !this.state.open,
            anchorEl: null
        })
    }

    render() {
        return (
            <>

                <Tooltip title={this.props.intl.formatMessage({id: "legal.icon.tooltip"})}>
                    <IconButton
                        style={{color: this.props.color ?? white}}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={this.handleClick}>
                        <InfoIcon/>
                    </IconButton>
                </Tooltip>

                <Popover
                    open={this.state.open}
                    style={{marginTop: 18}}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    PaperProps={{style: {width: 270}}}
                    transformOrigin={{vertical: 'top', horizontal: 'center'}}
                    onClose={this.handleClose}>

                    <Stack component="div">

                        <PopoverItem pathname={AGB_ROUTE} title={"navBar.agb.title"} handleClose={this.handleClose} openInNewTab={true}/>

                        <PopoverItem pathname={PRIVACY_ROUTE} title={"navBar.privacy.title"} handleClose={this.handleClose} openInNewTab={true}/>

                        <PopoverItem pathname={IMPRESSUM_ROUTE} title={"navBar.impressum.title"} handleClose={this.handleClose} openInNewTab={true}/>
                        
                    </Stack>


                </Popover>

            </>
        )
    }


}

export default injectIntl(withRouter(LegalMenu));
