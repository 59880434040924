import {getAsyncCatch, postAsyncCatch, postJsonAndBlob} from "./BackendService";

export async function getMessagesForBidder(context, props, projectId) {
    const messages = await getAsyncCatch(context, "/message/bidder/" + projectId, props);
    return messages ? messages : null;
}

export async function getMessagesForTender(context, props) {
    const messages = await getAsyncCatch(context, "/message/project/" + context.appData.activeProjectId, props);
    return messages ? messages : null;
}

export async function createQuestion(context, props, question, files) {
    return await postJsonAndBlob("/message/question", "message", question, files);
}

export async function createAnswer(context, props, answer, files) {
    return await postJsonAndBlob("/message/answer", "message", answer, files);
}

export async function createInformation(context, props, information, files) {
    return await postJsonAndBlob("/message/information", "message", information, files);
}

export async function readMessage(context, props, messageId) {
    const readMessageId = await postAsyncCatch(context, "/message/read/" + messageId, null, props, true);
    return readMessageId ? readMessageId : null;
}

export async function areAllMessagesRead(context, props, projectId) {
    return await getAsyncCatch(context, "/message/read/" + projectId, props);
}
