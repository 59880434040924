import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { putAsync } from "../../services/BackendService";
import { texisionWarningOrange } from "../../util/ColorTheme";
import { createErrorMessage, createSuccessMessage } from "../../util/Util";
import { GeneralContext } from "../contexts/GeneralContext";

class UserPassword extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
            version: this.props.version,
        }
    }

    componentDidUpdate() {
        this.context.setUnsavedChanges(
            (this.state.oldPassword || this.state.newPassword || this.state.newPasswordRepeat) ? true : false
        );
    }

    updatePassword = async() => {
        const passwordUpdate = {
            currentPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
            version: this.state.version
        }
        const response = await putAsync("/user/password", passwordUpdate);
        if (response?.status === 200) {
            this.setState({oldPassword: "", newPassword: "", newPasswordRepeat: ""});
            createSuccessMessage(this.props.intl.formatMessage({id: "userprofil.message.passwordUpdateSuccess"}), this.props);
            this.props.onSuccess();
        } else if ([401, 403].includes(response?.status)) {
            createErrorMessage(this.props.intl.formatMessage({id: "userprofil.message.passwordUpdateFailed"}), this.props);
            this.context.logout();
        } else if (response?.status === 409) {
            this.context.setShowOptimisticLockDialog(true);
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "userprofil.message.passwordUpdateFailed"}), this.props);
        }
    }

    render() {
        return (
            <>
            
                <Typography variant="h5" component="h2">
                    <FormattedMessage id="userprofil.changePassword"/>
                </Typography>

                <form autoComplete={"new-password"}>
                <Grid 
                    style={{marginTop: "20px"}} 
                    container 
                    spacing={1} 
                    justifyContent="flex-start" 
                    alignItems="center">

                    <Grid item xs={2}>
                        <Typography>
                            <FormattedMessage id="userprofil.oldPassword"/>
                        </Typography>
                    </Grid>

                    <Grid item xs={10}>
                            <TextField
                                id="oldpassword"
                                value={this.state.oldPassword}
                                type="password"
                                variant="outlined" inputProps={{maxLength: 64}}
                                style={this.state.oldPassword ? {width: "50%"} : {width: "50%", backgroundColor: texisionWarningOrange}}
                                onChange={(e) => this.setState({oldPassword: e.target.value})}/>
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={2}>
                        <Typography>
                            <FormattedMessage id="userprofil.newPassword"/>
                        </Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <TextField id="newpassword"
                            value={this.state.newPassword}
                            type="password"
                            variant="outlined" inputProps={{maxLength: 64}}
                            style={this.state.newPassword ? {width: "50%"} : {width: "50%", backgroundColor: texisionWarningOrange}}
                            onChange={(e) => this.setState({newPassword: e.target.value})}/>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography>
                            <FormattedMessage id="userprofil.newPasswordRepeat"/>
                        </Typography>
                    </Grid>

                    <Grid item xs={10}>
                        <TextField id="newpasswordrepeat"
                            value={this.state.newPasswordRepeat}
                            type="password"
                            helperText={this.state.newPassword !== this.state.newPasswordRepeat ?
                                this.props.intl.formatMessage({id: "userprofil.passwordsNotEqual"}) : ""}
                            variant="outlined" inputProps={{maxLength: 64}}
                            style={this.state.newPassword ? {width: "50%"} : {width: "50%", backgroundColor: texisionWarningOrange}}
                            onChange={(e) => this.setState({newPasswordRepeat: e.target.value})}/>
                    </Grid>

                </Grid>
                </form>

                <Grid item xs={12}>

                    <Button 
                        color="primary"
                        style={{marginTop: "40px"}}
                        variant="contained"
                        onClick={this.updatePassword}
                        disabled={(!this.state.oldPassword || !this.state.newPassword || !this.state.newPasswordRepeat) 
                            || (this.state.newPassword !== this.state.newPasswordRepeat)}>
                        <FormattedMessage id="userprofil.button.changePassword"/>
                    </Button>

                </Grid>
            
            </>
        );
    }
}

export default injectIntl(withSnackbar(UserPassword));
