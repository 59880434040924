import { TextField, Tooltip } from "@material-ui/core";
import { Component } from "react";
import { injectIntl } from "react-intl";
import { GeneralContext } from "../../contexts/GeneralContext";
import {isOfferUser} from "../../../services/UserService";
import {getActiveOperation} from "../../../services/OperationService";
import {disableNewPriceItemForTenderUser} from "../../../services/PriceOfferService";
import {isCockpit} from "../../../util/Util";

class ArticlePriceCell extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.isNewCockpitItemForTenderUser = false;
    }

    componentDidMount() {
        this.isNewCockpitItemForTenderUser = isCockpit()
            && isOfferUser(getActiveOperation(this.context)?.bidder)
            && disableNewPriceItemForTenderUser(this.context, this.props.priceItem, this.props.exportType);
    }

    handlePriceChange = (newPrice) => {
        let priceItem = this.props.priceItem;
        priceItem.price = newPrice;
        this.props.handleItemChange(priceItem);
        this.context.setUnsavedChanges(true);
        this.setState({});
    }

    onFocus = (newPrice) => {
        if (newPrice === "0") {
            let priceItem = this.props.priceItem;
            priceItem.price = "";
            this.props.handleItemChange(priceItem);
            this.setState({});
        }
    }

    render() {

        return (
            <Tooltip title={this.isNewCockpitItemForTenderUser 
                ? this.props.intl.formatMessage({id: "cockpit.priceSheet.newArticle.tooltip"}) : ""}>
                <TextField 
                    value={this.props.priceItem?.price?.toLocaleString("en-GB", {minimumFractionDigits: 2, maximumFractionDigits: 2})?.replace(".", ",")}
                    disabled={this.props.disabled || this.isNewCockpitItemForTenderUser} 
                    onChange={(e) => this.handlePriceChange(e.target.value)}
                    onFocus={(e) => this.onFocus(e.target.value)}
                />
            </Tooltip>
        );
    }
}

export default injectIntl(ArticlePriceCell);
