import React, {Component} from 'react';
import {EXPORT_TYPE_MERGE} from "../../../util/Constants";
import PropTypes from 'prop-types';
import {Collapse, Grid, Icon, MenuItem, Typography} from "@material-ui/core";
import {enhanceAssignedResourcesWithMasterData, formatPriceDifference} from "../../../util/Util";
import {
    renderFeatures,
    renderFeaturesWithoutType, renderImage,
    renderOffsettingAsString,
    renderVolumeType
} from "../../../util/TableUtil";
import {bodyBackgroundColor} from "../../../util/ColorTheme";
import {injectIntl} from "react-intl";

class CategoryPrices extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            openedTables: []
        }
    }

    categoryTablesForUnitOrProject = (unitOrProjectId, isProject) => {
        const priceType = this.props.intl.formatMessage(
            {id: "offers.comparison." + (this.props.showBrutto ? "gross" : "net")}
        );
        const currency = this.props.project.currency;
        const contentVo = this.props.contentVos?.find(c => c.exportable.id === unitOrProjectId);
        const hasRentalLinen = contentVo?.articles
            && new Map(Object.entries(contentVo.articles)).size > 0;
        const hasCustomerArticles = contentVo?.customerArticles
            && contentVo.customerArticles.length > 0;
        const hasResidentsLaundry = contentVo?.residentsLaundry
            && contentVo.residentsLaundry.length > 0;
        const hasSpecialServices = contentVo?.specialServices
            && contentVo.specialServices.length > 0;
        const hasOperatingResources = contentVo?.unitResources
            && contentVo.unitResources.length > 0 && contentVo.operatingResources
            && contentVo.operatingResources.length > 0;
        const table = <>

            {hasRentalLinen && this.rentalLinenTables(contentVo, unitOrProjectId)}

            {hasCustomerArticles && (
                <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center">
                        {this.props.firstColumnCell(
                            <MenuItem
                                onClick={() => this.handleTableClicked(
                                    "customer"+unitOrProjectId)}
                            >
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    <Icon
                                        className={this.state.openedTables
                                            .includes("customer"+unitOrProjectId)
                                            ? "icon-caret-up-large"
                                            : "icon-caret-down-large"}
                                        style={{fontSize: "10pt", marginRight: 10}}
                                    />
                                    {this.props.title(
                                        "bidder.priceSheet.table.customerArticles.title")}
                                </Grid>
                            </MenuItem>
                        )}
                        {this.props.offers
                            .map(o => this.props.cell(
                                o.id+"customer-offer",
                                this.getPriceForCategory(o.id, unitOrProjectId, "customer"),
                                true
                            ))
                        }
                    </Grid>
                </Grid>
            )}

            {hasCustomerArticles && (
                <Grid item xs={12}>
                    <Collapse in={this.state.openedTables.includes("customer"+unitOrProjectId)}>
                        <Grid container>
                            {contentVo.customerArticles.map(customerArticle => (
                                <Grid item xs={12} key={customerArticle}>
                                    <Grid container wrap="nowrap" alignItems="center">
                                        {this.props.firstColumnCell(
                                            this.getItemSummary(
                                                customerArticle, "customer"),
                                            true,
                                            80
                                        )}
                                        {this.props.offers
                                            .map(o => this.props.cell(
                                                o.id+"customer-article-offer",
                                                this.getPriceForItem(o.id, unitOrProjectId,
                                                    "customer", customerArticle.id),
                                                true,
                                                80
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Collapse>
                </Grid>
            )}

            {hasResidentsLaundry && (
                <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center">
                        {this.props.firstColumnCell(
                            <MenuItem
                                onClick={() => this.handleTableClicked(
                                    "laundry"+unitOrProjectId)}
                            >
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    <Icon
                                        className={this.state.openedTables
                                            .includes("laundry"+unitOrProjectId)
                                            ? "icon-caret-up-large"
                                            : "icon-caret-down-large"}
                                        style={{fontSize: "10pt", marginRight: 10}}
                                    />
                                    {this.props.title(
                                        "bidder.priceSheet.table.residentsLaundry.title")}
                                </Grid>
                            </MenuItem>
                        )}
                        {this.props.offers
                            .map(o => this.props.cell(
                                o.id+"laundry-offer",
                                this.getPriceForCategory(o.id, unitOrProjectId, "laundry"),
                                true
                            ))
                        }
                    </Grid>
                </Grid>
            )}

            {hasResidentsLaundry && (
                <Grid item xs={12}>
                    <Collapse in={this.state.openedTables.includes("laundry"+unitOrProjectId)}>
                        <Grid container>
                            {contentVo.residentsLaundry.map(laundry => (
                                <Grid item xs={12} key={laundry}>
                                    <Grid container wrap="nowrap" alignItems="center">
                                        {this.props.firstColumnCell(
                                            this.getItemSummary(laundry, "laundry"),
                                            true,
                                            60
                                        )}
                                        {this.props.offers
                                            .map(o => this.props.cell(
                                                o.id+"laundry-item-offer",
                                                this.getPriceForItem(o.id, unitOrProjectId,
                                                    "laundry", laundry.id),
                                                true,
                                                60
                                            ))
                                        }
                                    </Grid>
                                </Grid>))
                            }
                        </Grid>
                    </Collapse>
                </Grid>
            )}

            {hasSpecialServices && (
                <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center">
                        {this.props.firstColumnCell(
                            <MenuItem
                                onClick={() => this.handleTableClicked("special"+unitOrProjectId)}
                            >
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    <Icon
                                        className={this.state.openedTables
                                            .includes("special"+unitOrProjectId)
                                            ? "icon-caret-up-large"
                                            : "icon-caret-down-large"}
                                        style={{fontSize: "10pt", marginRight: 10}}
                                    />
                                    {this.props.title("bidder.priceSheet.table.specialServices.title")}
                                </Grid>
                            </MenuItem>
                        )}
                        {this.props.offers
                            .map(o => this.props.cell(
                                o.id+"special-offer",
                                this.getPriceForCategory(o.id, unitOrProjectId, "special"),
                                true
                            ))
                        }
                    </Grid>
                </Grid>
            )}

            {hasSpecialServices && (
                <Grid item xs={12}>
                    <Collapse in={this.state.openedTables.includes("special"+unitOrProjectId)}>
                        <Grid container>
                            {contentVo.specialServices
                                .map(service => (
                                    <Grid item xs={12} key={service}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            {this.props.firstColumnCell(
                                                this.getItemSummary(service, "special"),
                                                true,
                                                80
                                            )}
                                            {this.props.offers
                                                .map(o => this.props.cell(
                                                    o.id+"special-item-offer",
                                                    this.getPriceForItem(o.id, unitOrProjectId,
                                                        "special", service.id),
                                                    true,
                                                    80
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Collapse>
                </Grid>
            )}

            {hasOperatingResources && (
                <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center">
                        {this.props.firstColumnCell(
                            <MenuItem
                                onClick={() => this.handleTableClicked(
                                    "resources"+unitOrProjectId)}
                            >
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    <Icon
                                        className={this.state.openedTables
                                            .includes("resources"+unitOrProjectId)
                                            ? "icon-caret-up-large"
                                            : "icon-caret-down-large"}
                                        style={{fontSize: "10pt", marginRight: 10}}
                                    />
                                    {this.props.title(
                                        "bidder.priceSheet.table.operatingResources.title")}
                                </Grid>
                            </MenuItem>
                        )}
                        {this.props.offers
                            .map(o => this.props.cell(
                                o.id+"resources-offer",
                                this.getPriceForCategory(o.id, unitOrProjectId, "resources"),
                                true
                            ))
                        }
                    </Grid>
                </Grid>
            )}

            {hasOperatingResources && (
                <Grid item xs={12}>
                    <Collapse in={this.state.openedTables.includes("resources"+unitOrProjectId)}>
                        <Grid container>
                            {enhanceAssignedResourcesWithMasterData(contentVo.operatingResources,
                                contentVo.unitResources).map(resource => (
                                    <Grid item xs={12} key={resource}>
                                        <Grid container wrap="nowrap" alignItems="center">
                                            {this.props.firstColumnCell(
                                                this.getItemSummary(resource, "resources"),
                                                true,
                                                100
                                            )}
                                            {this.props.offers
                                                .map(o => this.props.cell(
                                                    o.id+"resources-item-offer",
                                                    this.getPriceForItem(o.id, unitOrProjectId,
                                                        "resources", resource.id),
                                                    true,
                                                    100
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Collapse>
                </Grid>
            )}
        </>;

        return (
            <Grid
                item
                xs={12}
                key={unitOrProjectId+isProject}
                style={{minWidth: 380+this.props.offers.length*200}}
            >
                {isProject
                    ? this.tableTitleByText(
                        this.props.intl.formatMessage(
                            {id: "offers.comparison.categoryPrices.forProject"}) + " ("
                        + (currency ? (currency + ", ") : "") + priceType + ")",
                        "categoryPrices"+unitOrProjectId)
                    : this.tableTitleByText(
                        this.props.intl.formatMessage(
                            {id: "offers.comparison.categoryPrices.forUnit"})
                        + " " + (
                            this.props.project.businessUnits.units
                                .find(u => u.id === unitOrProjectId)?.name ?? "-")
                        + " (" + (currency ? (currency + ", ") : "") + priceType + ")",
                        "categoryPrices"+unitOrProjectId)}
                <Collapse
                    in={this.state.openedTables.includes("categoryPrices"+unitOrProjectId)}
                    unmountOnExit
                >
                    {table}
                </Collapse>
            </Grid>
        );
    }

    getItemSummary = (item, category) => {
        let text;
        switch (category) {
            case "customer":
                text = item.description;
                if (item.features && item.features.length > 0) {
                    text += ", " + renderFeatures(item.features, this.props.intl,
                        "constants.ArticleFeatureType.");
                }
                if (item.volumeType) {
                    text += ", "
                        + this.props.intl.formatMessage(
                            {id: "customerArticle.create.props.volumeType"}) + ": "
                        + renderVolumeType(item, this.props.intl);
                }
                if (item.volume || item.volume === 0) {
                    text += ", "
                        + this.props.intl.formatMessage(
                            {id: "priceSheet.column.amount"}) + ": " + item.volume;
                }
                break;
            case "special":
                text = item.name;
                if (item.text) {
                    text += ", " + item.text;
                }
                if (item.amount || item.amount === 0) {
                    text += ", " + this.props.intl.formatMessage(
                            {id: "priceSheet.column.amount"}) + ": "
                        + item.amount;
                }
                if (item.offsetting) {
                    text += ", " + this.props.intl.formatMessage(
                            {id: "specialServices.table.header.offsetting"}) + ": "
                        + this.props.intl.formatMessage({id: "constants.ResourceOffsetting."
                                + item.offsetting});
                }
                break;
            case "laundry":
                text = item.name;
                if (item.cleanType) {
                    text += ", " + this.props.intl.formatMessage(
                        {id: "residentsLaundry.table.title." + item.cleanType.toLowerCase()});
                }
                if (item.amount || item.amount === 0) {
                    text += ", " + this.props.intl.formatMessage(
                        {id: "priceSheet.column.amount"}) + ": " + item.amount
                }
                break;
            case "resources":
                text = this.props.intl.formatMessage(
                        {id: "constants.ResourceSubCategory." + item.subCategory})
                    + ", " + item.name;
                if (item.description) {
                    text += ", " + item.description;
                }
                if (item.features && item.features.length > 0) {
                    text += ", " + renderFeaturesWithoutType(item.features);
                }
                if (item.count || item.count === 0) {
                    text += ", " + this.props.intl.formatMessage(
                            {id: "priceSheet.column.amount"}) + ": "
                        + item.count;
                }
                if (item.resourceOffsetting) {
                    text += ", " + this.props.intl.formatMessage(
                            {id: "specialServices.table.header.offsetting"}) + ": "
                        + this.props.intl.formatMessage(
                            {id: "constants.ResourceOffsetting." + item.resourceOffsetting});
                }
                break;
            default:
                text = "-";
                break;
        }
        return <div style={{marginLeft: 40}}>{text}</div>;
    }

    getPriceGroup = (offerId, unitOrProjectId, category, previousPrice) => {
        switch (category) {
            case "customer":
                return this.getPriceUnit(offerId, unitOrProjectId, previousPrice)
                    ?.customerArticleGroup;
            case "laundry":
                return this.getPriceUnit(offerId, unitOrProjectId, previousPrice)
                    ?.residentsLaundryGroup;
            case "special":
                return this.getPriceUnit(offerId, unitOrProjectId, previousPrice)
                    ?.specialServiceGroup;
            case "resources":
                return this.getPriceUnit(offerId, unitOrProjectId, previousPrice)
                    ?.operatingResourceGroup;
            default:
                return null;
        }
    }

    getPriceForCategory = (offerId, unitOrProjectId, category) => {
        const priceGroup = this.getPriceGroup(offerId, unitOrProjectId, category, false);
        const previousPriceGroup = this.getPriceGroup(offerId, unitOrProjectId, category, true);
        return this.getPrice(priceGroup, previousPriceGroup);
    }

    getPriceForArticleCategory = (offerId, unitOrProjectId, category, offsetting) => {
        const priceGroup = this.getPriceUnit(offerId, unitOrProjectId, false)
            ?.articleGroupMap[category][offsetting];
        const previousPriceGroup = this.getPriceUnit(offerId, unitOrProjectId, true)
            ?.articleGroupMap[category][offsetting];
        return this.getPrice(priceGroup, previousPriceGroup);
    }

    getPriceForArticle = (offerId, unitOrProjectId, category, offsetting, articleId) => {
        const priceItem = this.getPriceUnit(offerId, unitOrProjectId, false)
            ?.articleGroupMap[category][offsetting]?.priceItemMap[articleId];
        const previousPriceItem = this.getPriceUnit(offerId, unitOrProjectId, true)
            ?.articleGroupMap[category][offsetting]?.priceItemMap[articleId];
        return this.getPrice(priceItem, previousPriceItem);
    }

    getArticleSummary = (offsetting, priceSheetArticle) => {
        let text = priceSheetArticle.articleVo.description;
        if (priceSheetArticle.offsetting?.articleCategoryOffsetting) {
            text += ", " + renderOffsettingAsString(offsetting, priceSheetArticle
                .offsetting.articleCategoryOffsetting, this.props.intl);
        }
        if (priceSheetArticle.quantity) {
            text += ", " + this.props.intl.formatMessage({id: "priceSheet.column.amount"}) + ": "
                + priceSheetArticle.quantity;
        }
        return (
            <Grid container alignItems="center" wrap="nowrap" spacing={1} style={{marginLeft: 10}}>
                <Grid item className="articleSummaryImage">
                    {renderImage(priceSheetArticle.articleVo, null)}
                </Grid>
                <Grid item>
                    {text}
                </Grid>
            </Grid>
        );
    }

    getPriceUnit = (offerId, unitOrProjectId, previousPrice) => {
        if (previousPrice && this.props.round === 2) {
            return this.props.previousPriceOfferMap.get(offerId)?.priceUnitMap[unitOrProjectId];
        } else {
            return this.props.priceOffers.find(p => p.offerId === offerId)
                ?.priceUnitMap[unitOrProjectId];
        }
    }

    getPrice = (priceGroup, previousPriceGroup) => {
        const price = this.props.showBrutto
            ? priceGroup?.sumGross : priceGroup?.sumNet;
        const previousPrice = this.props.showBrutto
            ? previousPriceGroup?.sumGross : previousPriceGroup?.sumNet;
        return formatPriceDifference(this.props.round, previousPrice, price);
    }

    getPriceForItem = (offerId, unitOrProjectId, category, itemId) => {
        const priceItem = this.getPriceGroup(offerId, unitOrProjectId, category, false)
            ?.priceItemMap[itemId];
        const previousPriceItem = this.getPriceGroup(offerId, unitOrProjectId, category,
            true)?.priceItemMap[itemId];
        return this.getPrice(priceItem, previousPriceItem);
    }

    handleTableClicked = (id) => {
        if (this.state.openedTables.includes(id)) {
            this.setState({openedTables: this.state.openedTables
                    .filter(tableId => tableId !== id)});
        } else {
            let ids = this.state.openedTables;
            ids.push(id);
            this.setState({openedTables: ids});
        }
    }

    rentalLinenTables = (contentVo, unitOrProjectId) => {
        let tables = [];
        for (let [category, offsettingObject]
            of new Map(Object.entries(contentVo.articles)).entries())
        {
            for (let [offsetting, priceSheetArticles]
                of new Map(Object.entries(offsettingObject)))
            {
                tables.push(
                    <Grid container wrap="nowrap" alignItems="center">
                        {this.props.firstColumnCell(
                            <MenuItem
                                onClick={() => this.handleTableClicked(
                                    category+offsetting+unitOrProjectId)}
                            >
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    <Icon
                                        className={this.state.openedTables
                                            .includes(category+offsetting+unitOrProjectId)
                                            ? "icon-caret-up-large"
                                            : "icon-caret-down-large"}
                                        style={{fontSize: "10pt", marginRight: 10}}
                                    />
                                    {this.props.titleByText(
                                        this.props.intl.formatMessage(
                                            {id: "constants.Category." + category})
                                        + " - "
                                        + this.props.intl.formatMessage(
                                            {id: "constants.ArticleOffsetting." + offsetting})
                                    )}
                                </Grid>
                            </MenuItem>
                        )}
                        {this.props.offers
                            .map(o => this.props.cell(
                                o.id+"articleCategory",
                                this.getPriceForArticleCategory(o.id, unitOrProjectId, category,
                                    offsetting),
                                true
                            ))
                        }
                    </Grid>
                );
                tables.push(
                    <Collapse
                        in={this.state.openedTables.includes(category+offsetting+unitOrProjectId)}
                    >
                        <Grid container>
                            {priceSheetArticles.map(priceSheetArticle => (
                                <Grid item xs={12}>
                                    <Grid container wrap="nowrap" alignItems="center">
                                        {this.props.firstColumnCell(
                                            this.getArticleSummary(offsetting, priceSheetArticle),
                                            true,
                                            60
                                        )}
                                        {this.props.offers
                                            .map(o => this.props.cell(
                                                o.id+"article",
                                                this.getPriceForArticle(o.id, unitOrProjectId,
                                                    category, offsetting,
                                                    priceSheetArticle.articleVo.id),
                                                true,
                                                60
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Collapse>
                );
            }
        }
        return tables;
    }

    tableTitleByText = (text, tableId) => {
        return (
            <MenuItem
                onClick={() => this.handleTableClicked(tableId)}
                style={{marginTop: 40, padding: 0, minWidth: 300 + this.props.offers.length*150}}
            >
                <Grid container wrap="nowrap" alignItems="center">
                    <Grid
                        item
                        xs
                        className="tableTitle"
                        style={{backgroundColor: bodyBackgroundColor}}
                    >
                        <Typography variant="h3">
                            {text}
                        </Typography>
                    </Grid>
                    <Grid item className="caretIcon" style={{backgroundColor: bodyBackgroundColor}}>
                        <Icon
                            className={this.state.openedTables.includes(tableId)
                                ? "icon-caret-up-large"
                                : "icon-caret-down-large"}
                            style={{fontSize: "10pt", marginRight: 10}}
                        />
                    </Grid>
                </Grid>
            </MenuItem>
        );
    }
    
    render() {
        const merged = this.props.priceOffers[0]?.exportType === EXPORT_TYPE_MERGE;
        return !merged
            ? this.props.project.businessUnits.units
                .map(u => this.categoryTablesForUnitOrProject(u.id, false))
            : [this.props.project]
                .map(p => this.categoryTablesForUnitOrProject(p.id, true));
    }
}

CategoryPrices.propTypes = {
    cell: PropTypes.func,
    contentVos: PropTypes.array,
    firstColumnCell: PropTypes.func,
    offers: PropTypes.array,
    previousPriceOfferMap: PropTypes.object,
    priceOffers: PropTypes.array,
    project: PropTypes.object,
    round: PropTypes.number,
    showBrutto: PropTypes.bool,
    title: PropTypes.func,
    titleByText: PropTypes.func
};

export default injectIntl(CategoryPrices);
