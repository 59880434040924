import {postAsyncCatch} from "./BackendService";

export async function getDistances(context, props, sourceZipcode, targetZipcodes) {
    const errorTextMap = new Map();
    errorTextMap.set(400, "locationService.getDistances.400");
    errorTextMap.set(404, "locationService.getDistances.404");
    errorTextMap.set(404, "locationService.getDistances.412");
    const distances = await postAsyncCatch(context, "/distances/" + sourceZipcode, targetZipcodes, props, true, errorTextMap);
    return distances ? distances : [];
}

export async function findBidders(context, props, bidderSearchVo) {
    const bidders = await postAsyncCatch(context, "/find/bidder", bidderSearchVo, props, true);
    return bidders ? bidders : [];
}
