import React, {Component}  from 'react';
import '../../apps/App.css';
import '../../../css/UserAdmin.css';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Accordion from '@material-ui/core/Accordion';
import ArticleDetail from '../../share/ArticleDetail';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import {AccordionSummary, DialogContentText, Icon, IconButton} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import {deleteAsync, getAsync, postAsync, putAsync} from '../../../services/BackendService';

import {withSnackbar} from 'notistack';
import {createErrorMessage, createSuccessMessage} from '../../../util/Util';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, CardContent, Typography} from '@material-ui/core';
import {ArrowUpward} from '@material-ui/icons';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {DIALOG_TYPE_WARNING} from '../../../util/Constants';
import {GeneralContext} from "../../contexts/GeneralContext";
import ProfileArticleRow from "./ProfileArticleRow";
import {loadArticleConfigurations} from "../../../services/ArticleConfigurationService";

class ProfileOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.state = {
            profiles: [],
            id: null,
            name: "",
            isDefault: false,
            articleConfigurationIds: [],
            count: 0,
            version: 0,
            isReadOnly: false,
            isEditing: false,
            showEditOrCreateDialog: false,
            articleConfigurations: [],
            showArticleDetails: false,
            articleForDetailView: null,
            showDeleteDialog: false,
            showConflictDialog: false,
            sortedByCategory: true,
            sortedBySubCategory: null,
            sortedByArticleCategory: null,
            sortedByDescription: null
        }
    }

    async componentDidMount() {
        const profiles = await this.loadProfiles();
        const articleConfigurations = await loadArticleConfigurations(this.context, this.props);
        this.setState({profiles, articleConfigurations});
    }

    loadProfiles = async() => {
        const response = await getAsync("/profiles");
        if (response?.status === 200) {
            return response.data;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    createOrUpdateProfile = async() => {
        let response;
        let profile = {
            id: null,
            name: this.state.name,
            isDefault: this.state.isDefault,
            isReadOnly: this.state.isReadOnly,
            articleConfigurationIds: this.state.articleConfigurationIds,
            version: 0
        };
        if (this.state.isEditing) {
            profile.id = this.state.id;
            profile.version = this.state.version;
            response = await putAsync("/profile", profile);
        } else {
            response = await postAsync("/profile", profile);
        }
        if (response?.status === 200) {
            let text = this.state.isEditing ? "edit" : "create";
            createSuccessMessage(this.props.intl.formatMessage({id: "profiles." + text + ".successMessage"}), this.props);
            this.setState({profiles: await this.loadProfiles(), showEditOrCreateDialog: false, isEditing: false});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else if (response?.status === 406) {
            createErrorMessage(this.props.intl.formatMessage({id: "profiles.name.conflictMessage"}), this.props);
        } else if (response?.status === 409) {
            this.context.setShowOptimisticLockDialog(true);
        } else {
            let text = this.state.isEditing ? "edit" : "create";
            createErrorMessage(this.props.intl.formatMessage({id: "profiles." + text + ".errorMessage"}), this.props);
        }
    }

    deleteProfile = async(id) => {
        let response = await deleteAsync("/profile/" + id);
        if (response?.status === 200) {
            createSuccessMessage(this.props.intl.formatMessage({id: "profiles.delete.successMessage"}), this.props);
            this.setState({profiles: await this.loadProfiles(), showDeleteDialog: false});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else if (response?.status === 406) {
            createErrorMessage(this.props.intl.formatMessage({id: "profiles.delete.conflictMessage"}), this.props);
        } else if (response?.status === 409) {
            this.context.setShowOptimisticLockDialog(true);
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "profiles.delete.errorMessage"}), this.props);
        }
    }

    openEditOrCreateDialog = (profile) => {
        let sortedArticleConfigurations = this.state.articleConfigurations?.sort((a, b) => b.category.localeCompare(a.category));
        if (profile) {
            this.setState({
                articleConfigurations: sortedArticleConfigurations,
                showEditOrCreateDialog: true, 
                id: profile.id, 
                name: profile.name, 
                version: profile.version,
                isDefault: profile.isDefault, 
                articleConfigurationIds: profile.articleConfigurationIds, 
                isEditing: true, 
                sortedByCategory: true, 
                sortedBySubCategory: null, 
                sortedByArticleCategory: null, 
                sortedByDescription: null, 
                isReadOnly: profile.isReadOnly});
        } else {
            this.setState({
                articleConfigurations: sortedArticleConfigurations,
                showEditOrCreateDialog: true, 
                isEditing: false, 
                id: null, 
                name: null, 
                version: 0, 
                isDefault: false, 
                articleConfigurationIds: [], 
                sortedByCategory: true, 
                sortedBySubCategory: null, 
                sortedByArticleCategory: null, 
                sortedByDescription: null, 
                isReadOnly: false});
        }
    }

    closeEditOrCreateDialog = () => {
        this.setState({
            showEditOrCreateDialog: false, 
            isEditing: false, 
            id: null, 
            name: null,
            version: 0,
            isDefault: false, 
            articleConfigurationIds: [], 
            isReadOnly: false
        });
    }

    showArticleDetails = (article) => {
        this.setState({showArticleDetails: true, articleForDetailView: article});
    }

    closeArticleDetails = () => {
        this.setState({showArticleDetails: false, articleForDetailView: null});
    }

    openDeleteOrConflictDialog = (profile) => {
        if (profile.count > 0) {
            this.setState({showConflictDialog: true, count: profile.count});
        } else {
            this.setState({showDeleteDialog: true, id: profile.id, isDefault: profile.isDefault});
        }
    }

    closeDeleteDialog = () => {
        this.setState({showDeleteDialog: false, id: null, isDefault: false});
    }

    closeConflictDialog = () => {
        this.setState({showConflictDialog: false, count: 0});
    }

    handleCheckBoxChanged = (id, newValue) => {
        if (newValue) {
            this.state.articleConfigurationIds.push(id);
            this.setState({articleConfigurationIds: this.state.articleConfigurationIds});
        } else {
            let index = this.state.articleConfigurationIds.indexOf(id);
            if (index >= 0) {
                this.state.articleConfigurationIds.splice(index, 1);
                this.setState({articleConfigurationIds: this.state.articleConfigurationIds});
            }
        }
    }

    handleAllCheckBoxesChanged = (newValue) => {
        if (newValue) {
            this.setState({articleConfigurationIds: this.state.articleConfigurations?.map(article => article.id) ?? []});
        } else {
            this.setState({articleConfigurationIds: []});
        }
    }

    profileItem = (profile) => {
        let name;
        if (profile.isDefault) {
            name = profile.name + " (" + this.props.intl.formatMessage({id: "profiles.isDefault.text"}) + ")";
        } else {
            name = profile.name;
        }
        return (
            <Grid item xs={3} key={profile.id} style={{minWidth: "300px", maxWidth: "300px", minHeight: "140px"}}>

                <Accordion elevation={2} style={{minHeight: "120px", maxHeight: "120px"}}>
                    <AccordionSummary>

                        <Grid container spacing={1} alignItems="center" style={{flexWrap: "nowrap", wordBreak: "break-all"}}>

                            <Grid item xs>
                                <div style={{fontWeight: "bold"}}>
                                    {name}
                                </div>
                                <div>
                                    {this.props.intl.formatMessage({id: "profiles.numberOfArticles.text"}) + ": " + profile.articleConfigurationIds.length}
                                </div>
                                <div>
                                    {this.props.intl.formatMessage({id: "profiles.count.title"}) + ": " + profile.count}
                                </div>
                            </Grid>

                            <Grid item>
                                <Grid container spacing={1} alignItems="center" style={{flexWrap: "nowrap"}}>

                                    <Grid item xs>
                                        <Button onClick={() => this.openEditOrCreateDialog(profile)} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}>
                                            <Icon style={{color: "grey"}}>edit</Icon>
                                        </Button>
                                    </Grid>

                                    {!profile.isReadOnly && <Grid>
                                        <Button onClick={() => this.openDeleteOrConflictDialog(profile)} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}>
                                            <Icon style={{color: "grey"}}>delete</Icon>
                                        </Button>
                                    </Grid>}

                                </Grid>
                            </Grid>

                        </Grid>

                    </AccordionSummary>
                </Accordion>
            </Grid>
        );
    }

    selectAllRow = (disabled) => {
        return (
            <Grid container alignItems="center" style={{paddingBottom: "10px", paddingTop: "10px"}}>

                <Grid item xs={1} style={{width: "50px"}}>
                    <Checkbox
                        color="primary"
                        checked={this.state.articleConfigurations && this.state.articleConfigurationIds
                            && this.state.articleConfigurations.length === this.state.articleConfigurationIds.length}
                        onChange={disabled ? (e) => {} : (e) => this.handleAllCheckBoxesChanged(e.target.checked)}
                        inputProps={{'aria-label': 'primary checkbox'}}
                        disabled={disabled}/>
                </Grid>

                <Grid item xs={3} style={{wordBreak: "break-all"}}>
                    {this.props.intl.formatMessage({id: "commons.select.all"})}
                </Grid>

            </Grid>
        );
    }

    articleTableTitleRow = () => {
        return (
            <Grid container alignItems="center" style={{paddingBottom: "10px", paddingTop: "10px", fontWeight: "bold"}}>

                <Grid item xs={1} style={{width: "50px"}}/>

                <Grid item xs={3} style={{wordBreak: "break-all"}}>
                    {this.props.intl.formatMessage({id: "entities.article.category"})}
                    {this.sortButton(
                        this.state.sortedByCategory,
                        (a, b) => b.category.localeCompare(a.category),
                        (a, b) => a.category.localeCompare(b.category),
                        "category")}
                </Grid>

                <Grid item xs={2} style={{wordBreak: "break-all"}}>
                    {this.props.intl.formatMessage({id: "entities.article.subCategory"})}
                    {this.sortButton(
                        this.state.sortedBySubCategory,
                        (a, b) => b.subcategory.localeCompare(a.subcategory),
                        (a, b) => a.subcategory.localeCompare(b.subcategory),
                        "subcategory")}
                </Grid>

                <Grid item xs={2} style={{wordBreak: "break-all"}}>
                    {this.props.intl.formatMessage({id: "entities.article.articleCategory"})}
                    {this.sortButton(
                        this.state.sortedByArticleCategory,
                        (a, b) => b.articleCategory.localeCompare(a.articleCategory),
                        (a, b) => a.articleCategory.localeCompare(b.articleCategory),
                        "articleCategory")}
                </Grid>

                <Grid item xs={2} style={{wordBreak: "break-all"}}>
                    {this.props.intl.formatMessage({id: "entities.article.description"})}
                    {this.sortButton(
                        this.state.sortedByDescription,
                        (a, b) => b.description.localeCompare(a.description),
                        (a, b) => a.description.localeCompare(b.description),
                        "description")}
                </Grid>

                <Grid item xs={2}>
                    {this.props.intl.formatMessage({id: "entities.article.image"})}
                </Grid>

            </Grid>
        );
    }

    sortButton = (sortedByProperty, sortFromAToZ, sortFromZToA, propertyName) => {
        let icon;
        if (sortedByProperty === true) {
            icon = <ArrowDownwardIcon fontSize="inherit"/>;
        } else if (sortedByProperty === false) {
            icon = <ArrowUpward fontSize="inherit"/>;
        } else {
            icon = <ArrowUpward fontSize="inherit" style={{opacity: 0.3}}/>;
        }
        return <IconButton 
            aria-label="delete" 
            size="small"
            onClick={() => this.sortArticles(sortedByProperty, sortFromAToZ, sortFromZToA, propertyName)}>
            {icon}
        </IconButton>;
    }

    sortArticles = (sortedByProperty, sortFromAToZ, sortFromZToA, propertyName) => {
        let newValue;
        if (sortedByProperty === true) {
            newValue = false;
        } else {
            newValue = true;
        }
        let newList = this.state.articleConfigurations;
        if (this.state.articleConfigurations) {
            if (newValue === true) {
                newList = this.state.articleConfigurations.sort(sortFromAToZ);
            } else {
                newList = this.state.articleConfigurations.sort(sortFromZToA);
            }
        }
        switch (propertyName) {
            case "category":
                this.setState({
                    articleConfigurations: newList,
                    sortedByArticleCategory: null,
                    sortedByCategory: newValue,
                    sortedBySubCategory: null,
                    sortedByDescription: null,
                });
                break;
            case "subcategory":
                this.setState({
                    articleConfigurations: newList,
                    sortedByArticleCategory: null,
                    sortedByCategory: null,
                    sortedBySubCategory: newValue,
                    sortedByDescription: null,
                });
                break;
            case "articleCategory":
                this.setState({
                    articleConfigurations: newList,
                    sortedByArticleCategory: newValue,
                    sortedByCategory: null,
                    sortedBySubCategory: null,
                    sortedByDescription: null,
                });
                break;
            case "description":
                this.setState({
                    articleConfigurations: newList,
                    sortedByArticleCategory: null,
                    sortedByCategory: null,
                    sortedBySubCategory: null,
                    sortedByDescription: newValue,
                });
                break;
            default:
                this.setState({articleConfigurations: newList});
                break;

        }
    }

    createOrEditDialog = (mode) => {
        return (
            <div>

                <Typography variant="h2" className="mt-6 mb-6">
                    <FormattedMessage id={"profiles.dialog.title." + mode}/>
                </Typography>

                <FormattedMessage id={"profiles.dialog.subtitle." + mode}/>

                <Typography variant="h6" className="mt-6 mb-6">
                    <FormattedMessage id="profiles.name.title"/>
                </Typography>

                <Grid container spacing={3} alignItems="center">

                    <Grid item>
                        <TextField
                            value={this.state.name ?? ""}
                            onChange={(e) => this.setState({name: e.target.value})}
                            disabled={this.state.isReadOnly}
                        />
                    </Grid>

                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid>
                                {this.props.intl.formatMessage({id: "profiles.isDefault.title"})}
                            </Grid>
                            <Grid item xs>
                                <Checkbox
                                    color="primary"
                                    checked = {this.state.isDefault}
                                    onChange = {e => this.setState({isDefault: e.target.checked})}
                                    inputProps={{'aria-label': 'primary checkbox'}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Typography variant="h6" className="mt-6 mb-6">
                    <FormattedMessage id="profiles.assignedArticles.title"/>
                </Typography>

                {this.selectAllRow(this.state.isReadOnly)}

                <Divider/>

                {this.articleTableTitleRow()}

                <Divider/>

                {this.state.articleConfigurations?.map(article => <ProfileArticleRow
                    key={article.id}
                    article={article}
                    checked={this.state.articleConfigurationIds.includes(article.id)}
                    checkboxCallback={this.handleCheckBoxChanged}
                    showArticleDetails={this.showArticleDetails}
                    disabled={this.state.isReadOnly}
                />)}
            </div>
        );
    }

    render() {

        let mode = this.state.isEditing ? "edit" : "create";
        let isDefault = this.state.isDefault ? "default." : "";

        return (
            <>

                {this.state.articleForDetailView && this.state.showArticleDetails && <ArticleDetail
                    article={this.state.articleForDetailView}
                    showDialog={this.state.showArticleDetails}
                    handleDialogClose={this.closeArticleDetails}/>}

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDeleteDialog}
                    titleId={"profiles.dialog." + isDefault + "title.delete"}
                    subtitleId={"profiles.dialog." + isDefault + "subtitle.delete"}
                    actionId="commons.delete.button"
                    cancelId="commons.cancel.button"
                    onAction={() => this.deleteProfile(this.state.id)}
                    onCancel={() => this.closeDeleteDialog()}/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showConflictDialog}
                    titleId="profiles.delete.used.warning.title"
                    content={<DialogContentText>
                        <FormattedMessage id="profiles.delete.used.warning.text" values={{count: this.state.count}}/>
                    </DialogContentText>}
                    actionId="commons.okay.button"
                    onAction={() => this.closeConflictDialog()}/>

                <TexisionDialog
                    open={this.state.showEditOrCreateDialog}
                    size="lg"
                    hasNoTitle={true}
                    content={this.createOrEditDialog(mode)}
                    actionId="commons.save.button"
                    cancelId="commons.cancel.button"
                    onAction={() => this.createOrUpdateProfile()}
                    onCancel={() => this.closeEditOrCreateDialog()}
                    actionDisabled={!this.state.name}/>

                <Card className="child">
                    <CardContent>

                        <Grid container spacing={1} justifyContent="space-between">

                            <Grid item xs>
                                <Typography variant="h2" className="mt-6 mb-6">
                                    <FormattedMessage id="profiles.table.title"/> <span style={{fontWeight: 400}}>{" (" 
                                        + this.props.intl.formatMessage({id: "profiles.table.count"}) + ": " + (this.state.profiles?.length ?? 0) + ")"}</span>
                                </Typography>
                            </Grid>

                            <Button variant="contained" color="primary" className="mt-6 mb-6" onClick={() => this.openEditOrCreateDialog(null)} 
                                style={{maxHeight: "40px", minWidth: "200px"}}>
                                <FormattedMessage id="profiles.create.button"/>
                            </Button>

                        </Grid>

                        {this.state.profiles && this.state.profiles.length > 0 
                            ? <Grid container spacing={1}>
                                {this.state.profiles.sort((a, b) => a.name.localeCompare(b.name)).map(p => this.profileItem(p))}
                            </Grid>
                            : <FormattedMessage id="profiles.emptyData"/>}
                    </CardContent>
                </Card>
                
            </>
        );
    }
}

export default injectIntl(withSnackbar(ProfileOverview));
