import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {GeneralContext} from "../contexts/GeneralContext";
import {Grid} from "@mui/material";
import {Typography} from "@material-ui/core";
import ValidationIcon from "./ValidationIcon";
import ValidationButton from "./ValidationButton";
import {PROJECT_STATUS_IS_PUBLISHED} from "../../util/Constants";
import {getActiveOperation} from "../../services/OperationService";
import {getActiveProject, isProjectTaskCompleted} from "../../services/ProjectService";
import {isCockpit, isTender} from "../../util/Util";

class ValidationHeader extends Component {

    static contextType = GeneralContext;

    render() {

        const hasMissingData = !this.props.projectTask || this.props.objectId === null || this.props.objectId === undefined
            || this.props.projectId === null || this.props.projectId === undefined;

        const title = (
            <Typography variant="h1" style={{marginBottom: 30}}>
                {this.props.title}
            </Typography>
        );

        if (hasMissingData) {

            return title;

        } else if (isCockpit()) {

            const activeOperation = getActiveOperation(this.context);
            const isWorkingProject = activeOperation?.workingProject?.id === this.context.appData.activeProjectId;
            const isEditing = activeOperation?.editable;

            if (!isWorkingProject || !isEditing) {
                return title;
            }
        } else if (isTender() && getActiveProject(this.context)?.status === PROJECT_STATUS_IS_PUBLISHED) {
            return title;
        }

        const completed = isProjectTaskCompleted(this.context, this.props.projectTask, this.props.objectId);

        return (
            <Grid container alignItems="flex-start" wrap="nowrap">
                <Grid item style={{marginTop: 7, marginRight: 20}}>
                    <ValidationIcon complete={completed} size={30}/>
                </Grid>

                <Grid item xs>
                    <Typography variant="h1" style={{marginBottom: 30}}>
                        {this.props.title}
                    </Typography>
                </Grid>

                <Grid item>
                    <ValidationButton
                        completed={completed}
                        incomplete={this.props.incomplete}
                        incompleteTextId={this.props.incompleteTextId}
                        projectId={this.props.projectId}
                        projectTask={this.props.projectTask}
                        objectId={this.props.objectId}/>
                </Grid>

            </Grid>
        );
    }
}

export default injectIntl(ValidationHeader);
