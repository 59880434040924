import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import {FormattedMessage, injectIntl} from 'react-intl';
import {isNotEmptyOrBlank} from '../../../util/Util';
import {GeneralContext} from "../../contexts/GeneralContext";



/**
 * The generic feature Component with a fixed feature type. If you interested in using Features, then have a look at ArticleFeautrePanel or OperatingResourceFeaturePanel.
 */
class OperatingResourceFeature extends Component {

    static contextType = GeneralContext;

    constructor(props){
        super(props);

        this.state = {
            value: this.props.value
        }
    }

    handleFeatureValueChanged = (newValue) => {

        let valueToSet;
        if (!newValue) {
            valueToSet = "";
        } else {
            valueToSet = newValue;
        }
        this.setState({value: valueToSet});

        this.props.handleFeatureValueChanged(this.props.id, this.props.internalId, valueToSet);
    }

    matchesOption(input) {

        let options = this.props.allOptions;
        let optionsLength = options.length;

        for (let i = 0; i < optionsLength; i++) {
            if (options[i].startsWith(input)) {
                return true;
            }
        }
        return false;
    }

    render() {
        const filter = createFilterOptions();
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <InputLabel htmlFor="typ" style={{marginTop: "10px"}}><FormattedMessage id="commons.value.label"/></InputLabel>
                        <Autocomplete
                            value={this.state.value}
                            onChange={(event, newValue) => {
                                this.handleFeatureValueChanged(newValue);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                // Suggest the creation of a new value, if not empty and the value starts not with a existing option
                                if (isNotEmptyOrBlank(params.inputValue) && !this.matchesOption(params.inputValue)) {
                                    filtered.push(params.inputValue);
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id={this.props.type + this.state.value}
                            options={this.props.allOptions}
                            onBlur={(e) => {
                                this.handleFeatureValueChanged(e.target.value);
                            }}
                            inputprops={{maxLength: 10}}
                            renderOption={(option) => option}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} variant="standard"/>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="secondary" 
                            onClick={(e) => {return this.props.handleFeatureDelete(this.props.id, this.props.internalId)}}
                            style={{marginLeft: "10px", marginTop: "10px"}}>
                                <FormattedMessage id="commons.remove.button"/>
                        </Button>
                    </Grid>
                </Grid>
            </div>
            )
    }
}

export default injectIntl(OperatingResourceFeature);
