import React, {Component} from 'react';
import {StatisticsDatePickers} from "../StatisticsDatePickers";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {getPriceOfferByProjectId, loadPriceOfferMap} from "../../../../services/PriceOfferService";
import Grid from "@material-ui/core/Grid";
import {white} from "../../../../util/ColorTheme";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Typography} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {getAllArticleNumbers} from "../../../pricesheet/PriceSheetUtil";
import {getActiveOperation} from "../../../../services/OperationService";
import CategoryStatisticsPriceSheet from "./CategoryStatisticsPriceSheet";
import CategoryStatisticsChart from "./CategoryStatisticsChart";
import {getMaxDay} from "../../../../util/DateUtil";
import {SimpleTextCard} from "../../../uiLibrary/SimpleTextCard";
import {latestActivePayment} from "../../../../services/PaymentService";
import {LICENSE_TYPES} from "../../../../util/Constants";

class StatisticsPredictions extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        const currentDate = new Date();
        this.state = {
            priceOffer: null,
            contentVos: [],
            allArticleNumbers: [],
            priceOfferMap: new Map(),
            period: {
                min: {month: currentDate.getMonth(), year: currentDate.getFullYear()},
                max: {month: currentDate.getMonth(), year: currentDate.getFullYear()}
            },
            lastPeriod: null,
        }
    }

    async componentDidMount() {
        const result = await getPriceOfferByProjectId(this.context, this.props, getActiveOperation(this.context).activeProject.id);
        const priceOffer = result?.priceOfferVos[0];
        const contentVos = result?.contentVos;
        const allArticleNumbers = getAllArticleNumbers(priceOffer);
        this.setState({allArticleNumbers, priceOffer, contentVos});
    }

    loadPriceOfferMap = async() => {
        this.setState({isLoading: true});
        const period = this.state.period;
        // date time month starts at 0 so add +1
        const startTime = new Date(period.min.year, period.min.month + 1, 0).getTime();
        const endTime = new Date(period.max.year, period.max.month + 1, getMaxDay(period.max.month + 1)).getTime();
        const priceStatistics = await loadPriceOfferMap(this.context, this.props, startTime, endTime);
        let priceOfferMap;
        if (priceStatistics?.priceOfferMap) {
            priceOfferMap = new Map(Object.entries(priceStatistics.priceOfferMap));
        }
        this.setState({priceOfferMap, lastPeriod: {...this.state.period}, isLoading: false});
    }

    onMinDateChange = async(newDate) => {
        const min = {month: newDate.getMonth(), year: newDate.getFullYear()};
        const period = this.state.period;
        period.min = min;
        this.setState({period});
    }

    onMaxDateChange = async(newDate) => {
        const max = {month: newDate.getMonth(), year: newDate.getFullYear()};
        const period = this.state.period;
        period.max = max;
        this.setState({period});
    }

    hasChangedPeriod = () => {
        const { period, lastPeriod } = this.state;
        return lastPeriod?.min?.month !== period.min.month || lastPeriod?.min?.year !== period.min.year
            || lastPeriod?.max?.month !== period.max.month || lastPeriod?.max?.year !== period.max.year;
    }

    render() {
        const { allArticleNumbers, isLoading, lastPeriod, period, priceOffer, contentVos, priceOfferMap } = this.state;
        const hasCooperationLicense = latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE);
        const activeProject = getActiveOperation(this.context).activeProject;

        const minDate = period.min.year
            ? new Date(period.min.year, period.min.month, 1) : null;
        const maxDate = period.max.year
            ? new Date(period.max.year, period.max.month, 1) : null;
        const hasData = !!priceOfferMap?.size;
        const hasLoaded = !!lastPeriod;

        return (
            <>
                <Typography variant="h1">
                    <FormattedMessage id="cockpit.statistics.project.title"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id="cockpit.statistics.project.subtitle"/>
                </Typography>

                <SimpleTextCard show={!hasCooperationLicense} textId="cockpit.statistics.noBidder"/>

                {hasCooperationLicense && <>

                    <CategoryStatisticsPriceSheet
                        activeProject={activeProject}
                        priceOffer={priceOffer}
                        contentVos={contentVos}
                        allArticleNumbers={allArticleNumbers}/>

                    <Grid container alignItems="center" spacing={2} style={{marginBottom: 30}}>

                        <Grid item xs>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={!this.hasChangedPeriod() || !minDate || !maxDate || isLoading}
                                onClick={() => this.loadPriceOfferMap()}>
                                <FormattedMessage id="cockpit.statistics.categories.load"/>
                            </Button>
                        </Grid>

                        <Grid item>
                            <StatisticsDatePickers
                                labelIdMin="cockpit.statistics.categories.minDate"
                                labelIdMax="cockpit.statistics.categories.maxDate"
                                globalMinDate={new Date(0)}
                                globalMaxDate={new Date("2100-01-01")}
                                minDate={minDate}
                                maxDate={maxDate}
                                isMonthDisabled={() => false}
                                onMinDateChange={(newDate) => this.onMinDateChange(newDate)}
                                onMaxDateChange={(newDate) => this.onMaxDateChange(newDate)}/>
                        </Grid>

                        <Grid item xs={12} style={{backgroundColor: white, marginTop: 30, padding: 0}}>

                            {(!hasLoaded || !hasData)
                                && <div style={{width: "90%", minHeight: 200, padding: 40, display: "flex", justifyContent: "center", alignItems: "center"}}>

                                    {!hasLoaded && <FormattedMessage id="cockpit.statistics.categories.selectPeriod.hint"/>}

                                    {hasLoaded && !hasData && <FormattedMessage id="cockpit.statistics.categories.noData.hint"/>}

                                </div>}

                            {hasLoaded && hasData && <CategoryStatisticsChart
                                currency={activeProject?.currency}
                                priceOfferMap={priceOfferMap}
                                maxDate={this.state.lastPeriod.max?.year
                                    ? new Date(this.state.lastPeriod.max.year, this.state.lastPeriod.max.month,
                                        getMaxDay(this.state.lastPeriod.max.month + 1)).getTime()
                                    : null}
                                minDate={this.state.lastPeriod.min?.year
                                    ? new Date(this.state.lastPeriod.min.year, this.state.lastPeriod.min.month, 1).getTime()
                                    : null}
                            />}

                        </Grid>

                    </Grid>

                    <Typography variant="h1" style={{marginBottom: 30, marginTop: 30}}>
                        <FormattedMessage id="cockpit.statistics.planning.title"/>
                    </Typography>

                    <Typography variant="subtitle1">
                        <FormattedMessage id="cockpit.statistics.planning.subtitle"/>
                    </Typography>

                    <SimpleTextCard show={true} textId="cockpit.statistics.planning.hint"/>
                </>}
            </>
        );
    }
}

export default injectIntl(withSnackbar(StatisticsPredictions));
