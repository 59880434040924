import React, {Component} from 'react';

import MyAccount from '../account/MyAccount';

import {
    AGB_ROUTE,
    COCKPIT_INVOICES_ROUTE,
    CONTRACT_ROUTE,
    CONTRACT_HISTORY_ROUTE,
    CONTRACT_STATUS_ROUTE,
    PREFLIGHT_CHECK_ROUTE,
    IMPRESSUM_ROUTE,
    MY_ACCOUNT_ROUTE,
    PRIVACY_ROUTE,
    USER_MANAGEMENT_ROUTE,
    RENTAL_LINEN_ROUTE,
    RENTAL_LINEN_ASSIGNMENT_ROUTE,
    CUSTOMER_ARTICLES_ROUTE,
    RESIDENTS_LAUNDRY_ROUTE,
    OPERATING_RESOURCE_OVERVIEW_ROUTE,
    OPERATING_RESOURCES_ROUTE,
    SPECIAL_SERVICES_ROUTE,
    OFFSETTING_ROUTE,
    LOGISTICS_ROUTE,
    OPERATING_RESOURCE_CONFIGURATOR_ROUTE,
    PREDICTED_STATISTICS_ROUTE,
    MOBILE_THRESHOLD,
    ORDER_CONFIGURATION_ROUTE,
    PAYMENT_MANAGEMENT_ROUTE,
    PROJECT_TASKS,
    PROFILES_ROUTE,
    LICENSE_ROUTE,
    BUSINESS_UNIT_OVERVIEW_ROUTE,
    PROJECT_OVERVIEW_ROUTE,
    DASHBOARD_ROUTE,
    COCKPIT_CONTRACT_DOCUMENTS_ROUTE,
    OPERATION_ASSIGNMENT_ROUTE,
    ARTICLE_CONFIGURATOR_ROUTE,
    ARTICLE_CONFIGURATION_OVERVIEW_ROUTE,
    RELEASE_NOTES_ROUTE,
    COCKPIT_PRICE_SHEET_ROUTE,
    BASE_DATA_ROUTE,
    INVOICE_STATISTICS_ROUTE,
    BILL_OF_QUANTITIES_EXPORT_ROUTE,
    TENDER_COMMUNICATION_ROUTE
} from '../../util/Constants';

import {Redirect, Route, Switch} from "react-router-dom";

import '../apps/App.css';
import Privacy from '../legal/Privacy';
import TermsOfUse from '../legal/TermsOfUse';
import Impressum from '../legal/Impressum';
import CockpitNavBar from '../navigation/cockpit/CockpitNavBar';
import {GeneralContext} from "../contexts/GeneralContext";
import CockpitPriceSheet from '../cockpit/contractchanges/CockpitPriceSheet';
import ContractOverview from '../documents/contract/ContractOverview';
import ContractStatus from '../cockpit/contractstatus/ContractStatus';
import ContractHistory from "../cockpit/history/ContractHistory";
import PreflightCheck from "../cockpit/preflight/PreflightCheck";
import UserManagement from '../administration/userManagement/UserManagement';
import InvoicesOverview from '../cockpit/invoices/InvoicesOverview';

import RentalLinen from '../assortment/rentalLinen/RentalLinen';
import RentalLinenAssignment from '../assortment/rentalLinen/RentalLinenAssignment';

import CustomerArticleOverview from '../assortment/customerArticles/CustomerArticleOverview';

import ResidentsLaundry from '../assortment/residentsLaundry/ResidentsLaundry';

import OperatingResourceOverview from '../administration/operatingResources/OperatingResourceOverview';
import OperatingResources from '../assortment/operatingResource/OperatingResources';

import SpecialServices from '../assortment/specialServices/SpecialServices';

import OffsettingOverview from '../businessUnit/offsetting/OffsettingOverview';
import LogisticsOverview from '../businessUnit/logistics/LogisticsOverview';
import BusinessUnitRoute from '../navigation/shared/BusinessUnitRoute';
import OperatingResourceConfigurator from '../administration/operatingResources/OperatingResourceConfigurator';
import OperationRoute from '../navigation/cockpit/OperationRoute';
import PrivateRoute from '../navigation/shared/PrivateRoute';
import {isOfferUser, Role} from '../../services/UserService';
import StatisticsPredictions from '../cockpit/statistics/predictions/StatisticsPredictions';

import MediaQuery from "react-responsive";
import OrderConfiguration from '../order/OrderConfiguration';
import PaymentManagement from '../money/PaymentManagement';
import ProfileOverview from "../administration/profile/ProfileOverview";
import Licenses from "../administration/licenses/Licenses";
import BusinessUnitOverview from "../businessUnit/baseData/BusinessUnitOverview";
import ProjectOverview from "../project/ProjectOverview";
import Dashboard from "../validation/Dashboard";
import CockpitContractDocuments from "../cockpit/documents/CockpitContractDocuments";
import OperationAssignment from "../project/OperationAssignment";
import ArticleConfigurator from "../administration/articleConfigurator/ArticleConfigurator";
import ArticleConfigurationOverview from "../administration/articleOverview/ArticleConfigurationOverview";
import ReleaseNotes from "../navigation/shared/ReleaseNotes";
import {getActiveOperation} from "../../services/OperationService";
import BaseData from "../cockpit/statistics/baseData/BaseData";
import InvoiceStatistics from "../cockpit/statistics/invoices/InvoiceStatistics";
import BillOfQuantitiesExport from "../documents/BillOfQuantitiesExport";
import BidderCommunication from "../communication/BidderCommunication";

class CockpitApp extends Component {

    static contextType = GeneralContext;

    getRouteSwitch = () => {
        const activeOperation = getActiveOperation(this.context);
        const activeProjectId = this.context.appData.activeProjectId;
        const activeUnitId = this.context.appData.activeUnitId;

        const key = "cockpit-project-id-" + activeProjectId + "-unit-id-" + activeUnitId;
        
        return (
            <Switch>

                <Route path={IMPRESSUM_ROUTE} component={Impressum}/>
                <Route path={AGB_ROUTE} component={TermsOfUse}/>
                <Route path={PRIVACY_ROUTE} component={Privacy}/>

                <Route path={MY_ACCOUNT_ROUTE} component={MyAccount}/>
                <Route path={RELEASE_NOTES_ROUTE} component={ReleaseNotes}/>

                <Route path={PAYMENT_MANAGEMENT_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN]} component={PaymentManagement}/>
                </Route>

                <Route path={ARTICLE_CONFIGURATOR_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ArticleConfigurator}/>
                </Route>

                <Route path={ARTICLE_CONFIGURATION_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ArticleConfigurationOverview}/>
                </Route>

                <Route path={USER_MANAGEMENT_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN]} component={UserManagement}/>
                </Route>

                <Route path={ORDER_CONFIGURATION_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN]} component={OrderConfiguration}/>
                </Route>

                <Route path={PROFILES_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ProfileOverview}/>
                </Route>

                <Route path={LICENSE_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={Licenses}/>
                </Route>

                <Route path={OPERATING_RESOURCE_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={OperatingResourceOverview}/>
                </Route>

                <Route path={OPERATING_RESOURCE_CONFIGURATOR_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={OperatingResourceConfigurator}/>
                </Route>

                <Route path={OPERATION_ASSIGNMENT_ROUTE} component={OperationAssignment}/>

                <Route path={DASHBOARD_ROUTE} component={Dashboard}/>

                <Route path={PROJECT_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN]} component={ProjectOverview}/>
                </Route>

                <Route path={PREFLIGHT_CHECK_ROUTE}>
                    <PrivateRoute unavailable={!activeOperation || !isOfferUser(activeOperation?.bidder)} component={PreflightCheck}/>
                </Route>

                <OperationRoute
                    path={COCKPIT_CONTRACT_DOCUMENTS_ROUTE}
                    component={CockpitContractDocuments}/>

                <OperationRoute 
                    key={activeOperation?.workingProject?.status}
                    path={CONTRACT_STATUS_ROUTE} 
                    component={ContractStatus}/>

                <OperationRoute key={activeProjectId} path={BILL_OF_QUANTITIES_EXPORT_ROUTE} component={BillOfQuantitiesExport}/>

                <OperationRoute key={activeProjectId} path={TENDER_COMMUNICATION_ROUTE} component={BidderCommunication}/>

                <OperationRoute key={activeProjectId} path={CONTRACT_ROUTE} component={ContractOverview}/>

                <OperationRoute key={activeProjectId} path={COCKPIT_PRICE_SHEET_ROUTE} component={CockpitPriceSheet}/>

                <Route path={BUSINESS_UNIT_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN, Role.PROJECT_ADMIN, Role.BIDDER]} component={BusinessUnitOverview}/>
                </Route>

                <BusinessUnitRoute
                    key={key} 
                    titleId="rentalLinen.title" 
                    subtitleId="rentalLinen.subtitle" 
                    path={RENTAL_LINEN_ROUTE} 
                    component={RentalLinen}
                    projectTask={PROJECT_TASKS.RENTAL_LINEN}/>

                <BusinessUnitRoute 
                    key={key} 
                    path={RENTAL_LINEN_ASSIGNMENT_ROUTE} 
                    component={RentalLinenAssignment}/>

                <BusinessUnitRoute 
                    key={key} 
                    titleId="customerArticle.title.h1" 
                    subtitleId="customerArticle.title.h2" 
                    path={CUSTOMER_ARTICLES_ROUTE} 
                    component={CustomerArticleOverview}
                    projectTask={PROJECT_TASKS.CUSTOMER_ARTICLES}/>

                <BusinessUnitRoute 
                    key={key} 
                    titleId="residentsLaundry.h1" 
                    subtitleId="residentsLaundry.h2" 
                    path={RESIDENTS_LAUNDRY_ROUTE} 
                    component={ResidentsLaundry}
                    projectTask={PROJECT_TASKS.RESIDENTS_LAUNDRY}/>

                <BusinessUnitRoute 
                    key={key} 
                    titleId="operatingResources.navbar.selection"
                    subtitleId="operatingResources.subtitle"
                    path={OPERATING_RESOURCES_ROUTE}
                    component={OperatingResources}
                    projectTask={PROJECT_TASKS.OPERATING_RESOURCES}/>

                <BusinessUnitRoute 
                    key={key} 
                    titleId="specialServices.title" 
                    subtitleId="specialServices.subtitle" 
                    path={SPECIAL_SERVICES_ROUTE} 
                    component={SpecialServices}
                    projectTask={PROJECT_TASKS.SPECIAL_SERVICES}/>

                <BusinessUnitRoute 
                    key={key} 
                    titleId="articleOffsetting.offsetting.h1" 
                    subtitleId="articleOffsetting.offsetting.h2" 
                    path={OFFSETTING_ROUTE} 
                    component={OffsettingOverview}
                    projectTask={PROJECT_TASKS.OFFSETTING}/>

                <BusinessUnitRoute 
                    key={key} 
                    titleId="logistics.title"
                    subtitleId="logistics.subtitle"
                    path={LOGISTICS_ROUTE} 
                    component={LogisticsOverview}
                    projectTask={PROJECT_TASKS.LOGISTIC}/>

                <OperationRoute
                    path={COCKPIT_INVOICES_ROUTE}
                    component={InvoicesOverview}
                    roles={[Role.ADMIN, Role.PROJECT_ADMIN, Role.USER]}/>

                <OperationRoute
                    path={BASE_DATA_ROUTE}
                    component={BaseData}
                    roles={[Role.ADMIN, Role.PROJECT_ADMIN, Role.USER]}/>

                <OperationRoute
                    path={PREDICTED_STATISTICS_ROUTE}
                    component={StatisticsPredictions}
                    roles={[Role.ADMIN, Role.PROJECT_ADMIN, Role.USER]}/>

                <OperationRoute
                    path={INVOICE_STATISTICS_ROUTE}
                    component={InvoiceStatistics}
                    roles={[Role.ADMIN, Role.PROJECT_ADMIN, Role.USER]}/>

                <OperationRoute path={CONTRACT_HISTORY_ROUTE} component={ContractHistory}/>

                <Route>
                    <Redirect to={DASHBOARD_ROUTE}/>
                </Route>

            </Switch>
        );
    }

    render() {

        return (
            <>

                <MediaQuery minWidth={MOBILE_THRESHOLD}>

                    <CockpitNavBar mobile={false} key={this.context.appData.activeOperationId}/>

                    <div className="contentArea">
                        {this.getRouteSwitch()}
                    </div>

                </MediaQuery>

                <MediaQuery maxWidth={MOBILE_THRESHOLD-1}>

                    <CockpitNavBar mobile={true} key={this.context.appData.activeOperationId}/>

                    <div className="contentAreaMobile">
                        {this.getRouteSwitch()}
                    </div>

                </MediaQuery>

            </>
        );
    }
}

export default CockpitApp;
