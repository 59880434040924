import {getAsyncCatch, putAsyncCatch, uploadBlobCatch} from "./BackendService";


export async function getInvoices(context, props, activeOperationId) {
    const operationId = activeOperationId !== null && activeOperationId !== undefined ? activeOperationId : context.appData.activeOperationId;
    const invoices = await getAsyncCatch(context, "/invoices/" + operationId, props);
    return invoices ? invoices : [];
}

export async function uploadInvoiceFromContract(context, props, invoiceFile) {
    const path = "/invoice/" + context.appData.activeOperationId;
    return await uploadInvoice(context, props, invoiceFile, path);
}

export async function uploadInvoiceWithoutContract(context, props, invoiceFile, activeOperationId) {
    const path = "/invoice/without-contract/" + activeOperationId;
    return await uploadInvoice(context, props, invoiceFile, path);
}

async function uploadInvoice(context, props, invoiceFile,path) {
    const errorTextMap = new Map();
    errorTextMap.set(400, "cockpit.invoices.upload.badRequest");
    errorTextMap.set(406, "cockpit.invoices.upload.notAcceptable");
    errorTextMap.set(426, "cockpit.invoices.upload.duplicateFilename");
    errorTextMap.set(428, "cockpit.invoices.upload.duplicateNumber");

    return await uploadBlobCatch(
        context,
        path,
        invoiceFile,
        context.currentUser.username,
        props,
        false,
        errorTextMap)
}

export function sortByDate(invoiceA, invoiceB) {
    return invoiceB.invoiceDate - invoiceA.invoiceDate;
}

export async function updateInvoice(context, props, invoice, hideSuccess) {
    const updatedInvoice = await putAsyncCatch(context, "/invoice", invoice, props, hideSuccess);
    return updatedInvoice ? updatedInvoice : null;
}
