import {Component} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@material-ui/core";
import MaterialTable from "material-table";
import {renderFeatures} from "../../../util/TableUtil";
import {bodyBackgroundColor, texisionFontColorDark} from "../../../util/ColorTheme";
import {injectIntl} from "react-intl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class CustomerArticlesTable extends Component {

    render() {
        if (!this.props.hasContent) {
            return null;
        }
        return (
            <Accordion key={this.props.unitId + "-customer"} elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls={this.props.unitId + "-CONTENT"}
                    id={this.props.unitId + "-HEADER"}>
                    <Typography variant="h6">
                        {this.props.intl.formatMessage({id: "customerArticle.title"})}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12}>
                        <MaterialTable
                            columns={[
                                {title: this.props.intl.formatMessage({id: "customerArticle.table.header.description"}), field: "description"},
                                {title: this.props.intl.formatMessage({id: "customerArticle.create.features.title"}), field: "features",
                                    render: customerArticle => renderFeatures(customerArticle.features, this.props.intl, "constants.ArticleFeatureType.")},
                            ]}
                            data={this.props.customerArticles}
                            options={{
                                showTitle: false,
                                search: false,
                                paging: false,
                                draggable: false,
                                toolbar: false,
                                sorting: this.props.customerArticles.length > 0,
                                headerStyle: {
                                    fontWeight: 'bold',
                                    backgroundColor: bodyBackgroundColor,
                                    color: texisionFontColorDark
                                },
                            }}
                            style={{padding: "5px", marginTop: "10px", boxShadow: 'none', borderRadius: 0}}/>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    }
}

export default injectIntl(CustomerArticlesTable);
