import React from 'react'
import IconButton from "@material-ui/core/IconButton";
import {white} from "../../../util/ColorTheme";
import Popover from "@material-ui/core/Popover";
import {Stack, Tooltip} from "@mui/material";
import {
    APP_TYPE_KEY, ARTICLE_CONFIGURATION_OVERVIEW_ROUTE,
    ARTICLE_CONFIGURATOR_ROUTE,
    LICENSE_ROUTE, OFFER_APP, OPERATING_RESOURCE_CONFIGURATOR_ROUTE, OPERATING_RESOURCE_OVERVIEW_ROUTE,
    ORDER_CONFIGURATION_ROUTE,
    PROFILES_ROUTE,
    USER_MANAGEMENT_ROUTE
} from "../../../util/Constants";
import {isAdmin} from "../../../services/UserService";
import {SupervisorAccount, SportsMartialArtsRounded} from "@mui/icons-material";
import PopoverItem from './PopoverItem';
import {GeneralContext} from "../../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import {isCockpit, isOrder} from "../../../util/Util";

class AdminMenu extends React.Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null
        }
    }

    handleClick = (event) => {
        this.setState({
            open: !this.state.open,
            anchorEl: event.currentTarget
        })
    }

    handleClose = () => {
        this.setState({
            open: !this.state.open,
            anchorEl: null
        })
    }

    getIcon = () => {
        return isAdmin(this.context.currentUser)
            ? <SportsMartialArtsRounded/>
            : <SupervisorAccount/>
    }

    getItems = () => {
        let items = [{pathname: USER_MANAGEMENT_ROUTE, title: "navBar.userManagement.title"}];
        if (isCockpit() || isOrder()) {
            items.push({pathname: ORDER_CONFIGURATION_ROUTE, title: "navBar.orderConfiguration.title"});
        }
        if (isAdmin(this.context.currentUser)) {
            items.push({pathname: ARTICLE_CONFIGURATION_OVERVIEW_ROUTE, title: "navBar.articleConfigurationOverview.title"});
            items.push({pathname: ARTICLE_CONFIGURATOR_ROUTE, title: "navBar.articleConfigurator.title"});
            items.push({pathname: PROFILES_ROUTE, title: "navBar.profiles.title"});
            if (localStorage.getItem(APP_TYPE_KEY) !== OFFER_APP) {
                items.push({pathname: LICENSE_ROUTE, title: "navBar.licenses.title"});
            }
            items.push({pathname: OPERATING_RESOURCE_OVERVIEW_ROUTE, title: "operatingResources.navbar.overview"});
            items.push({pathname: OPERATING_RESOURCE_CONFIGURATOR_ROUTE, title: "operatingResources.navbar.configurator"});
        }
        return items.map(item => <PopoverItem
            key={item.pathname}
            pathname={item.pathname}
            title={item.title}
            handleClose={this.handleClose}/>)
    }

    render() {
        return (
            <>

                <Tooltip title={this.props.intl.formatMessage({id: "administration.icon.tooltip"})}>
                    <IconButton
                        style={{color: this.props.color ?? white}}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={this.handleClick}>
                        {this.getIcon()}
                    </IconButton>
                </Tooltip>

                <Popover
                    open={this.state.open}
                    style={{marginTop: 18}}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    PaperProps={{style: {width: 270}}}
                    transformOrigin={{vertical: 'top', horizontal: 'center'}}
                    onClose={this.handleClose}>

                    <Stack component="div">

                        {this.getItems()}

                    </Stack>


                </Popover>

            </>
        );
    }

}

export default injectIntl(AdminMenu);
