import React, { Component } from "react";
import {Chip, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {materialGrey, texisionBlueGrayBright} from "../../util/ColorTheme";

class ChipSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
        };
    }

    handleChipClick = (chip) => {
        let selected = this.state.selected === chip ? null : chip;

        this.setState({ selected });
        if (this.props.onChange) {
            this.props.onChange(selected);
        }
    };

    render() {
        const { options } = this.props;
        const { selected } = this.state;

        return (
            <Box sx={{
                position: "relative",
                display: "inline-block",
                width: "100%",
                borderColor: texisionBlueGrayBright + " !important",
                border: "1px solid",
                borderRadius: 1
            }}>
                {!!this.props.label && <Typography
                        variant="outlined"
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: "60px",
                            transform: "translate(-50%, -50%)",
                            color: materialGrey,
                            backgroundColor: "white",
                            paddingX: 1,
                        }}
                    >
                        {this.props.label}
                    </Typography>
                }
                <div style={{padding: '10px'}}>
                {options.map((option) => (
                    <Chip
                        key={option}
                        label={this.props.render(option)}
                        clickable
                        color={selected === option ? "primary" : "default"}
                        onClick={() => this.handleChipClick(option)}
                        style={{ margin: 4 }}
                    />
                ))}
                </div>
            </Box>
        );
    }
}

export default ChipSelector;