import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {GeneralContext} from "../contexts/GeneralContext";
import {getProjectTaskGroup} from "../../util/ValidationUtil";
import {PROJECT_TASK_GROUP} from "../../util/Constants";
import {Typography} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {getActiveProject} from "../../services/ProjectService";
import {getUnitsForActiveProjectFromContext} from "../../services/BusinessUnitService";
import {isOffer} from "../../util/Util";

class ConflictsSummary extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            deliveryAddresses: [],
            pickUpAddresses: []
        }
    }

    conflictRow = (projectTaskGroup, validations) => {
        let name = "";
        switch (projectTaskGroup) {
            case PROJECT_TASK_GROUP.PROJECT:
                name = getActiveProject(this.context)?.name;
                break;
            case PROJECT_TASK_GROUP.BUSINESS_UNIT:
                name = getUnitsForActiveProjectFromContext(this.context).find(u => u.id === parseInt(this.props.objectId))?.name;
                break;
            case PROJECT_TASK_GROUP.LOGISTIC_UNIT:
                name = "";
                break;
            default:
                break;
        }
        return (
            <div key={"conflicts-" + this.props.objectId}>

                <Typography variant="h4" style={{paddingBottom: "10px"}}>
                    {name && (name + ": ")}
                </Typography>

                {validations.map(validation => <>
                    {validation.conflictTypes?.map(conflictType => <div key={conflictType} style={{paddingBottom: "10px"}}>
                        <Alert severity="warning">
                            {this.conflictText(conflictType)}
                        </Alert>
                    </div>)}
                </>)}

            </div>
        );
    }

    conflictText = (conflict) => {
        if (conflict) {
            return this.props.intl.formatMessage({id: "validation.conflict." + conflict});
        } else {
            return "";
        }
    }
    render() {

        if (!this.props.validationList?.filter(v => !!v)?.length || isOffer()) {
            return null;
        }

        const projectValidations = this.props.validationList.filter(v => getProjectTaskGroup(v.task) === PROJECT_TASK_GROUP.PROJECT);
        const unitValidations = this.props.validationList.filter(v => getProjectTaskGroup(v.task) === PROJECT_TASK_GROUP.BUSINESS_UNIT);
        const logisticValidations = this.props.validationList.filter(v => getProjectTaskGroup(v.task) === PROJECT_TASK_GROUP.LOGISTIC_UNIT);

        return (
            <>

                {!!projectValidations.length && this.conflictRow(PROJECT_TASK_GROUP.PROJECT, projectValidations)}

                {!!unitValidations.length && this.conflictRow(PROJECT_TASK_GROUP.BUSINESS_UNIT, unitValidations)}

                {!!logisticValidations.length && this.conflictRow(PROJECT_TASK_GROUP.LOGISTIC_UNIT, logisticValidations)}

            </>
        );
    }
}

export default injectIntl(ConflictsSummary);
