export function checkPasswordStrength(password) {
    if (!password) {
        return 0;
    }
    const enoughRegex = new RegExp("(?=.{3,}).*", "g");
    const mediumRegex = new RegExp("^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
    const strongRegex = new RegExp("^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    if (strongRegex.test(password)) {
        return 3;
    } else if (mediumRegex.test(password)) {
        return 2;
    } else if (enoughRegex.test(password)) {
        return 1;
    } else {
        return 1;
    }
}