import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DIALOG_TYPE_WARNING, PRICE_SHEET_STATE} from "../../../../util/Constants";
import {Button, TextField, Tooltip} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {createErrorMessage, createSuccessMessage} from "../../../../util/Util";
import {isBidder} from "../../../../services/UserService";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {getEffectiveFromSuffix} from "../../../../util/DateUtil";
import {withSnackbar} from "notistack";
import {getActiveOperation, publishWorkingState} from "../../../../services/OperationService";
import {hasIncompletePriceOfferAsTenderUser} from "../../../../services/PriceOfferService";

class PublishOrRequestPriceButton extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            showDialog: false,
            isRequesting: false
        }
    }

    publish = async(activeOperation) => {
        this.setState({isRequesting: true});
        await publishWorkingState(
            this.context,
            activeOperation,
            this.state.comment,
            () => createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.publish.successMessage"}), this.props),
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.publish.errorMessage"}), this.props));
        this.setState({comment: "", showDialog: false, isRequesting: false});
    }

    publishDialogTitle = () => {
        if (!isBidder(this.context.currentUser)
            && hasIncompletePriceOfferAsTenderUser(this.context) === PRICE_SHEET_STATE.COMPLETE_BESIDES_NEW_ITEMS) {
            return "cockpit.contractStatus.dialogTitle.requestPrices";
        }
        return "cockpit.contractStatus.dialogTitle.publish";
    }

    publishDialogSubtitle = () => {
        if (!isBidder(this.context.currentUser)
            && hasIncompletePriceOfferAsTenderUser(this.context) === PRICE_SHEET_STATE.COMPLETE_BESIDES_NEW_ITEMS) {
            return "cockpit.contractStatus.dialogSubtitle.requestPrices";

        }
        return "cockpit.contractStatus.dialogSubtitle.publish";
    }

    render() {
        const activeOperation = getActiveOperation(this.context);

        let tooltip = "";
        if (this.props.hasNoChanges) {
            tooltip = this.props.intl.formatMessage({id: "cockpit.contractStatus.noChanges.tooltip"});
        } else if (this.props.isWorkingProjectIncomplete) {
            tooltip = this.props.intl.formatMessage({id: "cockpit.contractStatus.incomplete.tooltip"});
        } else if (this.props.isPriceOfferIncomplete) {
            tooltip = this.props.intl.formatMessage({id: "cockpit.contractStatus.priceOfferIncomplete.tooltip"});
        }

        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDialog}
                    titleId={this.publishDialogTitle()}
                    subtitleId={this.publishDialogSubtitle()}
                    replacements={{effectiveFrom: getEffectiveFromSuffix(this.props.intl, activeOperation)}}
                    cancelId="commons.no.button"
                    actionId="commons.yes.button"
                    content={
                        <>
                            <br/>
                            <TextField
                                label={<FormattedMessage id="cockpit.contractStatus.comment"/>}
                                onChange={(e) => this.setState({comment: e.target.value})}
                                multiline
                                style={{width: '100%'}}/>
                        </>
                    }
                    onCancel={() => this.setState({comment: "", showDialog: false})}
                    cancelDisabled={this.state.isRequesting}
                    onAction={() => this.publish(activeOperation)}
                    actionDisabled={this.state.isRequesting}/>

                <Tooltip title={tooltip}>
                    <div>
                        <Button
                            variant="contained"
                            style={{marginBottom: 20}}
                            color="primary"
                            onClick={() => this.setState({showDialog: true})}
                            disabled={this.props.disabled || this.state.isRequesting}>
                            <FormattedMessage id={this.props.priceSheetState === PRICE_SHEET_STATE.COMPLETE_BESIDES_NEW_ITEMS
                                ? "cockpit.contractStatus.priceRequest.button" : "cockpit.contractStatus.publish.button"}/>
                        </Button>
                    </div>
                </Tooltip>
            </>
        );
    }
}

PublishOrRequestPriceButton.propTypes = {
    priceSheetState: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    hasNoChanges: PropTypes.bool.isRequired,
    isWorkingProjectIncomplete: PropTypes.bool.isRequired,
    isPriceOfferIncomplete: PropTypes.bool.isRequired
};

export default injectIntl(withSnackbar(PublishOrRequestPriceButton));
