import React, {Component} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {FormattedMessage, injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";

class OperatingResourceSubCategorySelect extends Component {

    static contextType = GeneralContext;

    render() {
        return <>
            <Grid container spacing={1} justifyContent="center">

                <Grid item xs={12}>

                    <InputLabel
                        htmlFor="subCategory-native-simple"
                        style={{marginTop: "20px"}}>
                        <FormattedMessage id="operatingResources.configurator.categories.subCategory"/> *
                    </InputLabel>

                    <Select
                        native
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(e.target.value)}
                        style={{width: "50%"}}
                        inputProps={{name: 'subCategory', id: 'subCategory-native-simple'}}>

                        <option aria-label="None" value=""/>

                        {this.context.appData.operatingResourceSubCategories.map((subCategory) => {
                            let optionKey = this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + subCategory});
                            return (
                                <option
                                    key={subCategory}
                                    value={subCategory}>
                                    {optionKey}
                                </option>
                            );
                        })}

                    </Select>

                </Grid>

            </Grid>
        </>
    }
}
OperatingResourceSubCategorySelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};
export default injectIntl(OperatingResourceSubCategorySelect);