import { ListItemText, MenuItem } from "@material-ui/core";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';

class PopoverItem extends Component {

    onClick = () => {
        this.props.handleClose();
        if (this.props.onClick) {
            this.props.onClick();
        } else if (this.props.openInNewTab) {
            window.open(this.props.pathname, "_blank");
        } else {
            this.props.history.push(this.props.pathname);
        }
    }

    render() {
        return (
            <MenuItem
                key={this.props.title}
                disableRipple
                button
                onClick={this.onClick}>
                <React.Fragment>
                    <ListItemText className="title" primary={this.props.intl.formatMessage({id: this.props.title})}/>
                </React.Fragment>
            </MenuItem>
        );
    }
}

export default withRouter(injectIntl(PopoverItem));
