import React, {Component} from 'react';
import {Grid, Select} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {FormattedMessage, injectIntl} from "react-intl";

class ChartTypeSelection extends Component {

    render() {
        return (
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={2}>
                    <Select
                        native
                        value={this.props.chartType}
                        label={this.props.intl.formatMessage({id: "cockpit.statistics." + this.props.chartType + "Chart"})}
                        onChange={(e) => this.props.onChartTypeChange(e.target.value)}
                        inputProps={{name: 'net-gross-select', id: 'nett-gross-native-simple'}}>
                        <option
                            key={"line-chart-option"}
                            value={"line"}>
                            {this.props.intl.formatMessage({id: "cockpit.statistics.lineChart"})}
                        </option>
                        <option
                            key={"bar-chart-option"}
                            value={"bar"}>
                            {this.props.intl.formatMessage({id: "cockpit.statistics.barChart"})}
                        </option>
                    </Select>
                </Grid>

                <Grid item xs={10}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <Checkbox
                                color="primary"
                                checked={this.props.accumulated}
                                onChange={(e) => this.props.onAccumulatedChange(e.target.checked)}/>
                        </Grid>
                        <Grid item>
                            <FormattedMessage id="cockpit.statistics.accumulated"/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default injectIntl(ChartTypeSelection);
