import React from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import MaterialTable from 'material-table';
import {texisionFontColorDark, bodyBackgroundColor} from '../../../util/ColorTheme';
import {withSnackbar} from 'notistack';
import {FormattedMessage, injectIntl} from 'react-intl';
import { Typography } from "@material-ui/core";
import {sortAsc} from '../../../util/Util';

class OperationDifferencesTable extends React.Component {

    static contextType = GeneralContext;

    render() {

        const operationDifference = this.props.operationDifference;

        if (!operationDifference?.differences) {
            return (<div/>);
        }

        let hasDifferences = false;
        for (let differences of Object.values(operationDifference.differences)) {
            if (differences && differences.length > 0) {
                hasDifferences = true;
                break;
            }
        }

        return (<>

            {hasDifferences 

            ? Object.entries(operationDifference.differences)
                .sort((a,b) => sortAsc(
                    this.props.intl.formatMessage({id: "operation.difference.subcategory." + a[0] + ".title"}),
                    this.props.intl.formatMessage({id: "operation.difference.subcategory." + b[0] + ".title"})))
                .map(([projectSubcategory, differences]) => {
                
                if (!differences || differences.length === 0) {
                    return null;
                }

                return (
                    <MaterialTable
                        key={projectSubcategory + "-difference-table"}
                        style={{marginBottom: 20, minWidth: 400}}
                        title={<h3><FormattedMessage id={"operation.difference.subcategory." + projectSubcategory + ".title"}/></h3>}
                        columns={[
                            {width: "25%", title: this.props.intl.formatMessage({id: "operation.difference.name"}), field: "description"},
                            {width: "25%", title: this.props.intl.formatMessage({id: "operation.difference.field"}), field: "fieldName"},
                            {width: "25%", title: this.props.intl.formatMessage({id: "operation.difference.old"}), field: "sourceValue"},
                            {width: "25%", title: this.props.intl.formatMessage({id: "operation.difference.new"}), field: "targetValue"}
                        ]}
                        data={differences.sort((a,b) => sortAsc(a.description + " " + a.fieldName, b.description + " " + b.fieldName))}
                        options={{
                            tableLayout: "fixed",
                            grouping: false,
                            draggable: false,
                            filtering: false,
                            search: false,
                            paging: false,
                            showTitle: true,
                            actionsColumnIndex: -1,
                            headerStyle: {
                                fontWeight: 'bold',
                                backgroundColor: bodyBackgroundColor,
                                color: texisionFontColorDark,
                            },
                            toolbar: true,
                        }}>
                    </MaterialTable>
                );
            })

            : <Typography variant="subtitle2">
                {this.props.intl.formatMessage({id: "operation.difference.emptyDataMessage"})}
            </Typography>}

        </>);
    }

}

export default injectIntl(withSnackbar(OperationDifferencesTable));
