import React, {Component} from "react";
import {GeneralContext} from "../../../contexts/GeneralContext";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {FormattedMessage, injectIntl} from "react-intl";
import {FormControl, Select} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {withSnackbar} from "notistack";
import {getActiveUnit, getBusinessUnitVersion, updateBusinessUnit} from "../../../../services/BusinessUnitService";
import {updateValidationMap} from "../../../../services/ProjectService";

class LogisticTypeOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            logisticType: null,
            unitVersion: null
        };
    }

    async componentDidMount() {
        this.setState({
            logisticType: getActiveUnit(this.context)?.deliveryType,
            unitVersion: await getBusinessUnitVersion(this.context, this.props)
        });
    }

    saveDeliveryAndPickUpType = async(logisticType) => {
        let businessUnit = getActiveUnit(this.context);
        businessUnit.deliveryType = logisticType;
        businessUnit.pickupType = logisticType;
        businessUnit.version = this.state.unitVersion;
        const updatedUnit = await updateBusinessUnit(this.context, this.props, businessUnit);
        if (updatedUnit) {
            this.setState({logisticType: logisticType, unitVersion: updatedUnit.version});
            getActiveUnit(this.context).deliveryType = logisticType;
            getActiveUnit(this.context).pickupType = logisticType;
            await updateValidationMap(this.context);
        }
    }

    render() {
        return (
            <Card className="child">

                <CardContent>

                    <Typography
                        variant="h3"
                        component="span">
                        <FormattedMessage id="businessUnit.create.logistictype.h1"/>
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        style={{marginBottom: "20px"}}>
                        <FormattedMessage id="businessUnit.create.logistictype.h2"/>
                    </Typography>

                    <FormControl style={{width: "100%"}}>

                        <Select
                            native
                            value={this.state.logisticType ?? ""}
                            onChange={(e) => this.saveDeliveryAndPickUpType(e.target.value)}
                            style={{width: "50%"}}
                            disabled={this.props.readOnly}
                            inputProps={{
                                name: 'supplyAndCollectionType',
                                id: 'supplyAndCollectionType-native-simple',
                            }}>
                            {this.context.appData.logisticTypes.map((logisticType) =>
                                <option key={logisticType} value={logisticType}>
                                    {this.props.intl.formatMessage({id: "constants.LogisticType." + logisticType})}
                                </option>
                            )}
                        </Select>

                    </FormControl>

                </CardContent>

            </Card>
        );
    }
}

export default withSnackbar(injectIntl(LogisticTypeOverview));
