import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import {withSnackbar} from 'notistack';
import '../../../css/BillOfQuantities.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import MaterialTable from 'material-table';
import {bodyBackgroundColor, texisionFontColorDark} from '../../../util/ColorTheme';
import {Icon, Paper} from '@material-ui/core';
import {RESOURCE_OFFSETTINGS} from '../../../util/Constants';
import {GeneralContext} from "../../contexts/GeneralContext";
import {createSpecialService, deleteSpecialService, getSpecialServicesForUnit, updateSpecialService} from "../../../services/SpecialServiceService";


class SpecialServices extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            specialServices: [], 
            openEditComponent: false, 
            id: null,
            version: 0,
            name: "", 
            text: "", 
            amount: 0, 
            offsetting: RESOURCE_OFFSETTINGS.NONE, 
            editMode: false
        }
    }

    async componentDidMount() {
        this.setState({specialServices: await getSpecialServicesForUnit(this.context, this.props)});
    }

    componentDidUpdate() {
        if (this.state.id === null || this.state.id === undefined) {
            const hasUnsavedChanges = this.state.name 
                || this.state.text 
                || this.state.amount > 0;
            this.context.setUnsavedChanges(!!hasUnsavedChanges);
        }
    }

    createOrUpdateSpecialService = async() => {
        let service = {
            id: this.state.editMode ? this.state.id : null,
            amount: 0,
            name: this.state.name, 
            text: this.state.text,
            version: this.state.editMode ? this.state.version : 0,
            businessUnitId: this.context.appData.activeUnitId,
            offsetting: this.state.offsetting ?? RESOURCE_OFFSETTINGS.NONE
        };
        let parsedAmount = parseInt(this.state.amount);
        if (typeof parsedAmount === "number" && !isNaN(parsedAmount)) {
            service.amount = parsedAmount;
        }
        let success;
        if (service.id === null || service.id === undefined) {
            success = await createSpecialService(this.context, this.props, service);
        } else {
            success = await updateSpecialService(this.context, this.props, service);
        }
        if (success) {
            this.setState({
                editMode: false,
                id: null,
                name: "",
                text: "",
                amount: 0,
                specialServices: await getSpecialServicesForUnit(this.context, this.props),
                openEditComponent: false
            });
        }
    }

    deleteSpecialService = async(id) => {
        const success = await deleteSpecialService(this.context, this.props, id);
        if (success) {
            this.setState({specialServices: await getSpecialServicesForUnit(this.context, this.props)});
        }
    }

    openCreate = () => {
        this.setState({openEditComponent: true, editMode: false, id: null, offsetting: RESOURCE_OFFSETTINGS.NONE});
    }

    abortCreate = () => {
        this.setState({openEditComponent: false, version: 0});
    }

    openEdit = (service) => {
        this.setState({openEditComponent: true, editMode: true, id: service.id, name: service.name, text: service.text, 
            amount: service.amount, offsetting: service.offsetting, version: service.version});
    }

    abortEdit = () => {
        this.setState({openEditComponent: false, editMode: false, id: null, name: "", text: "",
        amount: 0, offsetting: RESOURCE_OFFSETTINGS.NONE, version: 0});
    }

    onAmountFocus = (value) => {
        if (value === "0") {
            this.setState({amount: ""});
        }
    }

    onAmountBlur = (value) => {
        if (value === "") {
            this.setState({amount: "0"});
        }
    }

    handleEditUnsavedChanges = () => {
        if (this.state.id !== null && this.state.id !== undefined) {
            this.context.setUnsavedChanges(true);
        }
    }

    changeName = (newName) => {
        this.handleEditUnsavedChanges();
        this.setState({name: newName});
    }

    changeText = (newText) => {
        this.handleEditUnsavedChanges();
        this.setState({text: newText});
    }

    changeAmount = (newValue) => {
        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
            this.handleEditUnsavedChanges();
            this.setState({amount: newValue});
        }
    }

    editComponent = () => {
        let mode = this.state.editMode ? "edit" : "create";

        return (
            <div style={{padding: "20px 0"}}>

                <Typography color="primary" style={{marginBottom: "20px", marginTop: "20px"}}>
                    <FormattedMessage id={"specialServices." + mode + ".hint"}/>
                </Typography>

                <Grid container direction="row" spacing={2}>

                    <Grid item xs={4} style={{wordBreak: "break-all"}}>
                        <TextField
                            required
                            multiline
                            id="name"
                            value={this.state.name}
                            label={this.props.intl.formatMessage({id: "specialServices.props.name"})}
                            variant="filled"
                            inputProps={{maxLength: 250}}
                            onChange={(e) => this.changeName(e.target.value)}
                            fullWidth={true}
                            maxRows={4}
                        />
                    </Grid>

                    <Grid item xs={8} style={{wordBreak: "break-all"}}>
                        <TextField
                            required
                            multiline
                            id="text"
                            value={this.state.text}
                            label={this.props.intl.formatMessage({id: "specialServices.props.text"})}
                            variant="filled"
                            inputProps={{maxLength: 4000}}
                            onChange={(e) => this.changeText(e.target.value)}
                            fullWidth={true}
                            maxRows={10}
                        />
                    </Grid>

                    <Grid item xs={12} style={{paddingTop: "20px", wordBreak: "break-all"}}>
                        <TextField
                            required
                            id="amount"
                            value={this.state.amount}
                            label={this.props.intl.formatMessage({id: "specialServices.props.amount"})}
                            onChange={(e) => this.changeAmount(e.target.value)}
                            style={{width: "100px"}}
                            onBlur={(e) => this.onAmountBlur(e.target.value)}
                            onFocus={(e) => this.onAmountFocus(e.target.value)}
                        />
                    </Grid>

                </Grid>

                <Button 
                    variant="contained"
                    style={{marginTop: "40px"}}
                    color="primary"
                    onClick={this.createOrUpdateSpecialService}
                    disabled={!this.state.name || !this.state.text || !this.state.amount || this.state.amount <= 0}>
                    {this.props.intl.formatMessage({id: "specialServices." + mode + ".save"})}
                </Button>

            </div>
        );
    }

    table = (services) => {
        let specialServices = services;
        if (services && services.length > 1) {
            specialServices = specialServices.sort((a, b) => a.name.localeCompare(b.name));
        }
        return(
            <MaterialTable
                columns={[
                    {title: this.props.intl.formatMessage({id: "specialServices.table.header.name"}), field: "name"},
                    {title: this.props.intl.formatMessage({id: "specialServices.table.header.text"}), field: "text"},
                    {title: this.props.intl.formatMessage({id: "specialServices.table.header.amount"}), field: "amount"},
                ]}
                data={specialServices}
                options={{
                    grouping: false,
                    draggable: false,
                    filtering: false,
                    search: false,
                    paging: false,
                    showTitle: false,
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontWeight: 'bold',
                        backgroundColor: bodyBackgroundColor,
                        color: texisionFontColorDark,
                    },
                    toolbar: false,
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: this.props.intl.formatMessage({id: "specialServices.table.emptyData"})
                    },
                    header: {
                        actions: this.props.intl.formatMessage({id: "specialServices.table.header.actions"})
                    }
                }}
                actions={[
                    {
                        icon: () => <Icon style={{paddingLeft: "10px"}}>edit</Icon>,
                        onClick: (_, rowData) => this.openEdit(rowData),
                        position: "row",
                        tooltip: this.props.intl.formatMessage({id: "specialServices.table.action.edit"}),
                        disabled: this.state.editMode || this.props.readOnly
                    },
                    {
                        tooltip: this.props.intl.formatMessage({id: "specialServices.table.action.delete"}),
                        icon: 'delete',
                        onClick: (_, rowData) => this.deleteSpecialService(rowData.id),
                        disabled: this.state.editMode || this.props.readOnly
                    }
                ]}
                components={{
                    Container: props => (<Paper {...props} key={this.props.type} elevation={0}/>)}
                }
            />
        );
    }

    render() {

        let buttonTextId;
        let buttonTap;
        if (!this.state.openEditComponent) {
            buttonTextId = "specialServices.create.button";
            buttonTap = this.openCreate;
        } else if (this.state.editMode) {
            buttonTap = this.abortEdit;
            buttonTextId = "commons.cancel.button";
        } else {
            buttonTap = this.abortCreate;
            buttonTextId = "commons.minimize.button";
        }

        return (
            <div>

                <Grid className="parent">

                    <Card className="child">

                        <CardContent>

                            <Grid container className="mb-6">

                                <Grid item xs={6}>
                                    <Grid container justifyContent="flex-start">
                                        <Typography variant="h6" component="h6">
                                            <FormattedMessage id="specialServices.table.title"/>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container justifyContent="flex-end">
                                        <Button 
                                            color="primary"
                                            variant="contained"
                                            onClick={buttonTap}
                                            disabled={this.props.readOnly}>
                                            {this.props.intl.formatMessage({id: buttonTextId})}
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Collapse in={this.state.openEditComponent}>

                                <Divider light/>

                                {this.editComponent()}

                                <Divider light/>

                                <div className="mb-6"/>

                            </Collapse>

                            {this.table(this.state.specialServices)}

                        </CardContent>

                    </Card>

                </Grid>
            </div>
        );
    }
}

export default injectIntl(withSnackbar(SpecialServices));
