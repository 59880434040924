import React, {Component} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {Grid, InputLabel, Select} from "@material-ui/core";
import Button from "@material-ui/core/Button";

class ArticleConfigurationSelector extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            category: props.value ? props.value.category : "",
            subCategory: props.value ? props.value.subCategory : "",
            articleCategory: props.value ? props.value.articleCategory : "",
            description: props.value ? props.value.description : "",
        };
    }

    getCategories = () => {
        const articleConfigurations = this.props.articleConfigurations;
        if (!articleConfigurations?.length) {
            return [];
        }
        return Array.from(new Set(articleConfigurations.map(a => a.category)));
    }

    getSubCategories = () => {
        const articleConfigurations = this.props.articleConfigurations;
        if (!articleConfigurations?.length) {
            return [];
        }
        return Array.from(new Set(articleConfigurations.filter(a => a.category === this.state.category).map(a => a.subCategory)));
    }

    getArticleCategories = () => {
        const articleConfigurations = this.props.articleConfigurations;
        if (!articleConfigurations?.length) {
            return [];
        }
        return Array.from(new Set(articleConfigurations
            .filter(a => a.category === this.state.category && a.subCategory === this.state.subCategory).map(a => a.articleCategory)));
    }

    getArticleDescriptions = () => {
        const articleConfigurations = this.props.articleConfigurations;
        if (!articleConfigurations?.length) {
            return [];
        }
        return Array.from(new Set(articleConfigurations
            .filter(a => a.category === this.state.category && a.subCategory === this.state.subCategory && a.articleCategory === this.state.articleCategory)
            .map(a => a.description)));
    }

    handleDescriptionChanged = async(newDescription) => {
        await this.setState({description: newDescription});
        if (this.props.onSelect) {
            this.props.onSelect(this.getArticleConfiguration());
        }
    }

    getArticleConfiguration = () => {
        return this.props.articleConfigurations
            ?.find(a => a.category === this.state.category &&
                a.subCategory === this.state.subCategory &&
                a.articleCategory === this.state.articleCategory &&
                a.description === this.state.description);
    }


    render() {
        return <>
            <Grid container spacing={2} id={"article-configuration-selector-container-" + this.props.id}>

                <Grid item xs={3}>

                    <InputLabel
                        htmlFor="category-native-simple"
                        style={{marginTop: "20px", marginBottom: 5}}>
                        <FormattedMessage id="entities.article.category"/> *
                    </InputLabel>

                    <Select
                        native
                        value={this.state.category}
                        label={this.state.category ? this.props.intl.formatMessage({id: "constants.Category." + this.state.category}) : ""}
                        onChange={(e) =>
                            this.setState({category: e.target.value, subCategory: "", articleCategory: "", description: ""})}
                        style={{width: "80%"}}
                        disabled={this.props.readOnly}
                        inputProps={{name: 'category', id: 'category-native-simple'}}>

                        <option aria-label="None" value=""/>

                        {this.getCategories()
                            .sort((a,b) =>
                                this.props.intl.formatMessage({id: "constants.Category." + a})
                                    .localeCompare(this.props.intl.formatMessage({id: "constants.Category." + b})))
                            .map((category) => {
                                return (
                                    <option
                                        key={category}
                                        value={category}>
                                        {this.props.intl.formatMessage({id: "constants.Category." + category})}
                                    </option>
                                );
                            })}

                    </Select>

                </Grid>

                <Grid item xs={3}>

                    <InputLabel
                        htmlFor="subcategory-native-simple"
                        style={{marginTop: "20px", marginBottom: 5}}>
                        <FormattedMessage id="entities.article.subCategory"/> *
                    </InputLabel>

                    <Select
                        native
                        value={this.state.subCategory}
                        disabled={!this.state.category || this.props.readOnly}
                        label={this.state.subcategory ? this.props.intl.formatMessage({id: "constants.SubCategory." + this.state.subCategory}) : ""}
                        onChange={(e) => this.setState({subCategory: e.target.value, articleCategory: "", description: ""})}
                        style={{width: "80%"}}
                        inputProps={{name: 'subcategory', id: 'subcategory-native-simple'}}>

                        <option aria-label="None" value=""/>

                        {this.getSubCategories()
                            .sort((a,b) =>
                                this.props.intl.formatMessage({id: "constants.SubCategory." + a})
                                    .localeCompare(this.props.intl.formatMessage({id: "constants.SubCategory." + b})))
                            .map((subcategory) => {
                                return (
                                    <option
                                        key={subcategory}
                                        value={subcategory}>
                                        {this.props.intl.formatMessage({id: "constants.SubCategory." + subcategory})}
                                    </option>
                                );
                            })}

                    </Select>

                </Grid>

                <Grid item xs={3}>

                    <InputLabel
                        htmlFor="articleCategory-native-simple"
                        style={{marginTop: "20px", marginBottom: 5}}>
                        <FormattedMessage id="entities.article.articleCategory"/> *
                    </InputLabel>

                    <Select
                        native
                        value={this.state.articleCategory}
                        disabled={!this.state.category || !this.state.subCategory || this.props.readOnly}
                        label={this.state.articleCategory ? this.props.intl.formatMessage({id: "constants.ArticleCategory." + this.state.articleCategory}) : ""}
                        onChange={(e) => this.setState({articleCategory: e.target.value, description: ""})}
                        style={{width: "80%"}}
                        inputProps={{name: 'articleCategory', id: 'articleCategory-native-simple'}}>

                        <option aria-label="None" value=""/>

                        {this.getArticleCategories()
                            .sort((a,b) =>
                                this.props.intl.formatMessage({id: "constants.ArticleCategory." + a})
                                    .localeCompare(this.props.intl.formatMessage({id: "constants.ArticleCategory." + b})))
                            .map((articleCategory) => {
                                return (
                                    <option
                                        key={articleCategory}
                                        value={articleCategory}>
                                        {this.props.intl.formatMessage({id: "constants.ArticleCategory." + articleCategory})}
                                    </option>
                                );
                            })}

                    </Select>

                </Grid>

                <Grid item xs={3}>

                    <InputLabel
                        htmlFor="adescription-native-simple"
                        style={{marginTop: "20px", marginBottom: 5}}>
                        <FormattedMessage id="entities.article.description"/> *
                    </InputLabel>

                    <Select
                        native
                        value={this.state.description}
                        disabled={!this.state.category || !this.state.subCategory || !this.state.articleCategory || this.props.readOnly}
                        label={this.state.description ?? ""}
                        onChange={(e) => this.handleDescriptionChanged(e.target.value)}
                        style={{width: "80%"}}
                        inputProps={{name: 'articleDescription', id: 'articleDescription-native-simple'}}>

                        <option aria-label="None" value=""/>

                        {this.getArticleDescriptions()
                            .sort((a,b) => a.localeCompare(b))
                            .map((description) => {
                                return (
                                    <option
                                        key={description}
                                        value={description}>
                                        {description}
                                    </option>
                                );
                            })}

                    </Select>
                </Grid>
            </Grid>

            {this.props.onCancel && <Grid container spacing={2} justifyContent="flex-end">

                <Grid item>
                    <Button
                        style={{marginTop: "40px"}}
                        variant="contained"
                        color="secondary"
                        onClick={() => this.props.onCancel()}>
                        <FormattedMessage id="commons.cancel.button"/>
                    </Button>
                </Grid>

                <Grid item>
                    <Button
                        variant="contained"
                        style={{marginTop: "40px"}}
                        color="primary"
                        onClick={() => this.props.onSubmit(this.getArticleConfiguration())}
                        disabled={!this.getArticleConfiguration()}>
                        {this.props.intl.formatMessage({id: "commons.apply.button"})}
                    </Button>

                </Grid>
            </Grid>}
        </>
    }
}
export default injectIntl(ArticleConfigurationSelector);