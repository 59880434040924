import {Card, CardContent} from "@material-ui/core";
import React, {Component} from "react";
import {injectIntl} from "react-intl";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {warningColor, white} from '../../util/ColorTheme';
import {DIALOG_TYPE_WARNING} from "../../util/Constants";
import {TouchableOpacity} from "react-native";
import ConflictsSummary from "./ConflictsSummary";
import {GeneralContext} from "../contexts/GeneralContext";
import {getFlatValidationList, getRelevantConflictTypes} from "../../util/ValidationUtil";

class ExportConflicts extends Component {

    static contextType = GeneralContext;

    render() {
        return <>
            <TexisionDialog
                open={this.props.dialogOpen}
                type={DIALOG_TYPE_WARNING}
                titleId="conflicts.dialog.title"
                subtitleId={this.props.buttonType && this.props.documentType 
                    ? "conflicts.dialog.subtitle." + this.props.buttonType + "." + this.props.documentType : ""}
                content={<Card className="child">
                    <CardContent>
                        <div style={{paddingTop: "20px"}}>
                            <ConflictsSummary
                                key={"export-conflict-summary-" + this.props.buttonType + this.props.documentType}
                                validationList={getFlatValidationList(this.context.appData.validationMap)}
                                relevantConflictTypes={getRelevantConflictTypes(this.props.documentType)}
                            />
                        </div>
                    </CardContent>
                </Card>}
                actionId="commons.okay.button"
                onAction={() => this.props.handleDialog(false, null)}/>

            <TouchableOpacity 
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: 20,
                    height: 20,
                    backgroundColor: warningColor,
                    shadowColor: warningColor,
                    borderRadius: 20,
                }}
                activeOpacity={1}
                onPress={() => this.props.handleDialog(true, "export")}>
                <PriorityHighIcon style={{color: white, maxWidth: 15, maxHeight: 15}}/>
            </TouchableOpacity>
        </>;
    }
}

export default injectIntl(ExportConflicts);
