import {Grid, Icon, IconButton} from "@material-ui/core";
import {cutName, downloadS3Document, formatDate} from "../../util/DocumentUtil";
import React from "react";
import {injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import PropTypes from "prop-types";

class MessageDocument extends React.Component {

    render() {
        const document = this.props.document;
        return (
            <Grid item key={document.filename}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs className="documentName">
                        {cutName(this.props.intl, document.filename) + ", " + formatDate(this.props.intl, document.lastModified, "dd.MM.yyyy")}
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={() => {downloadS3Document(this.props, document)}}>
                            <Icon>download</Icon>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

MessageDocument.propTypes = {
    document: PropTypes.object.isRequired
};

export default withSnackbar(injectIntl(MessageDocument));
