import { Button, Card, CardContent, Grid, Tooltip } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { uploadBlobCatch } from "../../services/BackendService";
import { FILE_TYPES, selectDocument } from "../../util/DocumentUtil";
import { canChangeOffer, offerDisabledTooltip, offerSubmittedTooltip, requiresSuitabilityDocuments } from "../../util/ProcedureUtil";
import { DocumentPreview } from "../uiLibrary/DocumentPreview";
import { Header } from "../uiLibrary/Header";
import "../../css/BidderProjectDetails.css";
import { useContext } from "react";
import {GeneralContext} from "../contexts/GeneralContext";

const BidderDocuments = ({offer, procedure, props, onUpload, reloadOffer}) => {

    const formatMessage = useIntl().formatMessage;
    const context = useContext(GeneralContext);

    if (!offer || !procedure) {
        return <div/>;
    }

    const documents = offer?.documents;
    const tooltip = offerDisabledTooltip({procedure}, offer, true, formatMessage)
        || offerSubmittedTooltip(offer, formatMessage);

    const addButton = <Button
        disabled={!canChangeOffer(offer, procedure)}
        color="primary" 
        component="label">
        <FormattedMessage id="offers.document.select.button"/>
        <input 
            type="file" 
            id="offer-document-input" 
            accept={FILE_TYPES.PDF} 
            style={{display: "none"}} 
            onChange={(e) => selectDocument(e.target.files[0], async(document) => {
                onUpload(true);
                await uploadBlobCatch(context, "/document/offer/" + offer.id + "/", document, null, props);
                await reloadOffer();
                onUpload(false);
            }, props, documents)} 
            onClick={(event)=> {event.target.value = null}}/>
    </Button>;

    let title;
    let subtitle;
    if (requiresSuitabilityDocuments(procedure)) {
        title = "bidder.offerDocuments.overview.title1";
        subtitle = "bidder.offerDocuments.overview.subtitle1";
    } else {
        title = "bidder.offerDocuments.overview.title2";
        subtitle = "bidder.offerDocuments.overview.subtitle2";
    }
    return <Card>
        <CardContent>

        <Header 
            titleId={title} 
            subtitleId={subtitle} 
            titleVariant="h3"
            subtitleVariant="subtitle2"/>

        {documents && documents.length > 0 
        && <Grid className="offerDocumentsGrid" container spacing={2}>
            {documents.map((document) => 
            <DocumentPreview 
                document={document} 
                deleteEndpoint="/document/offer/" 
                disabled={!canChangeOffer(offer, procedure)} 
                tooltipText={tooltip}
                context={context} 
                props={props} 
                callback={() => reloadOffer()}/>)}
        </Grid>}

        {!canChangeOffer(offer, procedure) 
        ? <Tooltip placement="bottom-start" title={tooltip ?? ""}>
            <div>{addButton}</div>
        </Tooltip> 
        : addButton}

        </CardContent>
    </Card>;
}

export {
    BidderDocuments
}
