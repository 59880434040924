import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {DIALOG_TYPE_INFO} from "../../../../util/Constants";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {createErrorMessage, createSuccessMessage} from "../../../../util/Util";
import {getActiveOperation, saveWorkingState} from "../../../../services/OperationService";
import {withSnackbar} from "notistack";
import {getEffectiveFromSuffix} from "../../../../util/DateUtil";

// „Akzeptiere alles an dir – ich meine wirklich alles. Du bist du, vom Anfang bis zum Ende – keine Entschuldigungen, keine Reue.“ (Henry Kissinger)
class SelfAcceptButton extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false
        }
    }

    onAccept = async() => {
        this.setState({isRequesting: true});
        const activeOperation = getActiveOperation(this.context);
        await saveWorkingState(
            this.context,
            activeOperation,
            () => createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.save.successMessage"}), this.props),
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.save.errorMessage"}), this.props));
        this.setState({showDialog: false, isRequesting: false});
    }

    render() {
        let tooltip;
        if (this.props.hasNoChanges) {
            tooltip = "cockpit.contractStatus.acceptSelf.noChanges.tooltip";
        } else if (this.props.isPriceOfferIncomplete) {
            tooltip = "cockpit.contractStatus.acceptSelf.incompletePriceOffer.tooltip"
        } else if (this.props.isWorkingProjectIncomplete) {
            tooltip = "cockpit.contractStatus.acceptSelf.incompleteProject.tooltip";
        }
        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showDialog}
                    titleId="cockpit.contractStatus.dialogTitle.acceptSelf"
                    subtitleId="cockpit.contractStatus.dialogSubtitle.acceptSelf"
                    replacements={{effectiveFrom: getEffectiveFromSuffix(this.props.intl, getActiveOperation(this.context))}}
                    cancelId="commons.cancel.button"
                    actionId="commons.confirm.button"
                    style={{}}
                    onCancel={() => this.setState({showDialog: false})}
                    onAction={() => this.onAccept()}/>

                <Tooltip title={tooltip ? this.props.intl.formatMessage({id: tooltip}) : ""}>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.setState({showDialog: true})}
                            disabled={this.props.hasNoChanges || this.props.isPriceOfferIncomplete || this.props.isWorkingProjectIncomplete}>
                            <FormattedMessage id="cockpit.contractStatus.acceptSelf.button"/>
                        </Button>
                    </div>
                </Tooltip>
            </>
        );
    }
}

SelfAcceptButton.propTypes = {
    hasNoChanges: PropTypes.bool.isRequired,
    isPriceOfferIncomplete: PropTypes.bool.isRequired,
    isWorkingProjectIncomplete: PropTypes.bool.isRequired
};

export default injectIntl(withSnackbar(SelfAcceptButton));
