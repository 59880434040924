import React, {Component} from "react";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {withSnackbar} from "notistack";
import {injectIntl} from "react-intl";
import {Typography} from "@mui/material";
import FooterWrapper from "../FooterWrapper";
import {Accordion, AccordionDetails, AccordionSummary, Icon, Grid} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArticleConfigurationSelector from "../../../assortment/rentalLinen/ArticleConfigurationSelector";
import {loadArticleConfigurations} from "../../../../services/ArticleConfigurationService";
import PriceTypeSelectBox from "../forms/PriceTypeSelectBox";
import PropTypes from "prop-types";
import {PriceType} from "../../../../util/Constants";
import CustomerArticleContent from "../../../assortment/customerArticles/CustomerArticleContent";
import {createFormData} from "../../../assortment/customerArticles/CustomerArticleOverview";
import {getResidentsLaundryForUnit} from "../../../../services/ResidentsLaundryService";
import ResidentsLaundryForm from "../forms/ResidentsLaundryForm";
import {getAllResources} from "../../../../services/OperatingResourceService";
import OperatingResourcesForm from "../forms/OperatingResourcesForm";
import {formatDate} from "../../../../util/DocumentUtil";
import Spacer from "../../../uiLibrary/Spacer";
import {createErrorMessage} from "../../../../util/Util";

class InvoiceArticlePage extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.state = {
            articleMapping: props.articleMapping,
            invoices: props.invoices,
            articleConfigurations: [],
            residentsLaundryList: [],
            operatingResourceList: [],
            openItemId: null
        }
    }

    async componentDidMount() {
        const articleConfigurations = await loadArticleConfigurations(this.context, this.props)
        const residentsLaundryList = await getResidentsLaundryForUnit(this.context, this.props, this.props.businessUnitId);
        const operatingResourceList = await getAllResources(this.context, this.props);
        this.setState({articleConfigurations, residentsLaundryList, operatingResourceList});
    }

    getArticleKit = (invoiceItem) => {
        let articleMapping = this.state.articleMapping;
        const articleNumber = invoiceItem.articleNumber;
        if (articleMapping.has(articleNumber)) {
            return articleMapping.get(articleNumber)
        } else {
            return {
                articleNumber: articleNumber,
                description: invoiceItem.description,
                isComplete: false,
                articleData: null,
                priceType: null,
                articleObject: null
            };
        }
    }

    setArticleKit = (articleKit) => {
        let articleMapping = this.state.articleMapping;
        articleMapping.set(articleKit.articleNumber, articleKit);
        this.setState({articleMapping});
    }

    handlePriceTypeChanged = (invoiceItem, priceType) => {
        let articleKit = this.getArticleKit(invoiceItem);
        articleKit.priceType = priceType ? priceType : null;
        articleKit.articleData = null;
        articleKit.isComplete = false;
        this.setArticleKit(articleKit);
    }

    handleArticleCompleteChanged = (invoiceItem, isComplete) => {
        let articleKit = this.getArticleKit(invoiceItem);
        articleKit.isComplete = isComplete;
        this.setState({openItemId: null});
        this.setArticleKit(articleKit);
    }

    clearArticleData = (invoiceItem) => {
        let articleKit = this.getArticleKit(invoiceItem);
        if (articleKit.isComplete) {
            this.setState({openItemId: null});
        } else {
            articleKit.articleData = null;
            this.setState({openItemId: null});
            this.setArticleKit(articleKit);
        }
    }

    handleUniqueArticleChanged = (priceType, invoiceItem, article) => {
        let existingArticleKit = Array.from(this.state.articleMapping.values()).find((a) =>
            a.priceType === priceType &&
            a.articleData?.id === article.id &&
            a.articleData?.operatingResourceId === article.operatingResourceId &&
            a.articleNumber !== invoiceItem.articleNumber);

        if (existingArticleKit) {
            createErrorMessage(this.props.intl.formatMessage({id: "articleConfiguration.duplicateObject.error"}) + existingArticleKit.articleNumber, this.props);
        } else {
            this.handleArticleDataChanged(invoiceItem, article);
        }
    }

    handleArticleDataChanged = (invoiceItem, article) => {
        let articleKit = this.getArticleKit(invoiceItem);
        articleKit.articleData = article;
        articleKit.isComplete = true;
        this.setState({openItemId: null});
        this.setArticleKit(articleKit);
    }

    handleCustomerArticleChanged = (invoiceItem, propertyName, value) => {
        let articleKit = this.getArticleKit(invoiceItem);
        if (!articleKit.articleData) {
            articleKit.articleData = createFormData(invoiceItem.articleName);
        }
        articleKit.articleData[propertyName] = value;
        this.setArticleKit(articleKit);
    }

    customerArticleKey = (articleData) => {
        let key = articleData?.volumeType;
        if (articleData?.features && articleData.features.length) {
            for (let feature of articleData.features) {
                key += feature?.type + feature?.value;
            }
        }
        return key;
    }

    renderArticleForm = (articleKit, invoiceItem) => {
        const priceType = articleKit?.priceType;
        switch (priceType) {
            case PriceType.ARTICLE_QUANTITY:
            case PriceType.ARTICLE_WEEKLY:
                return (<div style={{ marginTop: "10px"}}>
                    <ArticleConfigurationSelector
                        key={articleKit.articleData?.category + articleKit.articleData?.subCategory
                            + articleKit.articleData?.articleCategory + articleKit.articleData?.description}
                        id={"article-configuration-selector-" + invoiceItem.articleNumber?.toString()}
                        value={articleKit.articleData}
                        articleConfigurations={this.state.articleConfigurations}
                        onCancel={() => this.clearArticleData(invoiceItem)}
                        onSubmit={(articleConfiguration) => this.handleUniqueArticleChanged(priceType, invoiceItem, articleConfiguration)}
                        />
                </div>);
            case PriceType.CUSTOMER_ARTICLE:
                let formData = createFormData(invoiceItem.articleName);
                return (<CustomerArticleContent
                    key={this.customerArticleKey(articleKit.articleData)}
                    formData={articleKit.articleData ? articleKit.articleData : formData}
                    onDataChange={(propertyName, value) => this.handleCustomerArticleChanged(invoiceItem, propertyName, value)}
                    isInEditMode={true}
                    onCancel={() => this.clearArticleData(invoiceItem)}
                    onSubmit={() => this.handleArticleCompleteChanged(invoiceItem, true)}
                    onFeatureCreate={this.createFeature}
                    hideVolume={true}
                />);
            case PriceType.RESIDENTS_LAUNDRY:
                return this.state.residentsLaundryList.length &&
                    (<ResidentsLaundryForm
                        key={"residents-laundry-" + articleKit.articleData?.name + "-invoice-item-id-" + invoiceItem.id}
                        residentsLaundry={articleKit.articleData}
                        residentsLaundryList={this.state.residentsLaundryList}
                        onCancel={() => this.clearArticleData(invoiceItem)}
                        onSubmit={(residentsLaundry) => this.handleUniqueArticleChanged(priceType, invoiceItem, residentsLaundry)}
                />);
            case PriceType.OPERATING_RESOURCE:
                return this.state.operatingResourceList.length &&
                    (<OperatingResourcesForm
                        key={"operating-resource-" + articleKit.articleData?.description + "-invoice-item-id-" + invoiceItem.id}
                        businessUnitId={this.props.businessUnitId}
                        operatingResource={articleKit.articleData}
                        operatingResourceList={this.state.operatingResourceList}
                        onCancel={() => this.clearArticleData(invoiceItem)}
                        onSubmit={(operatingResource) => this.handleUniqueArticleChanged(priceType, invoiceItem, operatingResource)}
                />);
            case PriceType.SPECIAL_SERVICE:
                return ;
            default:
                return <div/>;
        }
    }

    renderInvoiceItem = (invoiceItem) => {
        const articleNumber = invoiceItem.articleNumber;
        let articleKit = this.state.articleMapping.get(articleNumber);
        let expanded = invoiceItem.id === this.state.openItemId;
        return (
            <Accordion
                key={"invoice-item-" + invoiceItem.id}
                elevation={1}
                expanded={expanded}
                onChange={(e, expanded) =>
                    this.setState({openItemId: expanded ? invoiceItem.id : null})}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls={invoiceItem.articleName + "-CONTENT"}
                    id={invoiceItem.id + "-HEADER"}>
                    <Grid container wrap="nowrap" justifyContent="space-evenly">
                        <Grid item xs={2}>
                            <Typography variant="subtitle2">
                                {invoiceItem.articleNumber}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="subtitle2">
                                {invoiceItem.articleName}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Spacer size={10}/>
                        </Grid>
                        <Grid item>
                            <div style={{color: "orange"}}>
                                {!articleKit?.isComplete ? this.props.intl.formatMessage({id: "contract.chapter.incomplete.hint"}) : ""}
                            </div>
                        </Grid>
                        <Grid item>
                            <Icon style={{
                                paddingLeft: "10px",
                                color: articleKit?.isComplete ? "grey" : "orange"
                            }}>edit
                            </Icon>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width:"100%"}}>
                        <PriceTypeSelectBox
                            id={"price-type-" + invoiceItem.id.toString()}
                            value={articleKit?.priceType}
                            onChange={(priceType) => this.handlePriceTypeChanged(invoiceItem, priceType)}
                        />
                        {articleKit?.priceType && this.renderArticleForm(articleKit, invoiceItem)}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }

    renderInvoices = (invoices) => {
        return invoices.sort((a, b) => (a.invoiceDate > b.invoiceDate))
            .map(invoice => {
                return (
                    <>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                 <Typography variant="h3" sx={{fontSize: '18px', fontWeight: 'bold'}}>
                                    {formatDate(this.props.intl, invoice.invoiceDate) + " - " + invoice.document.filename}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {invoice.invoiceItems.filter(item => item.articleNumber).map(this.renderInvoiceItem)}
                            </Grid>
                        </Grid>
                        <Spacer size={40}/>
                    </>
                );
            });
    }

    isValid = () => {
        return this.state.openItemId === null && !this.state.invoices
            .map(i => i.invoiceItems)
            .flatMap(item => item)
            .filter(item => item.articleNumber)
            .map(item => this.getArticleKit(item))
            .find(a => !a.isComplete)
    }

    render() {
        return (
            <FooterWrapper
                invalidData={!this.isValid()}
                onContinue={() => this.props.onContinue(this.state.articleMapping)}
                onCancel={this.props.onCancel}
                onBack={() => this.props.onBack(this.state.articleMapping)}
                isLoading={this.state.isLoading}>
                {this.renderInvoices(this.state.invoices)}
            </FooterWrapper>);
    }
}

InvoiceArticlePage.propTypes = {
    onBack: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    invoices: PropTypes.array.isRequired,
    businessUnitId: PropTypes.number.isRequired
};

export default withSnackbar(injectIntl(InvoiceArticlePage));
