import { TableCell, TableRow } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { formatArticleNumbers } from "../../../../util/TableUtil";
import { formatPrice } from "../../../../util/Util";
import { InvoiceControllingTable } from "./InvoiceControllingTable";

const SpecialServicesPriceTable = ({priceUnit, contents, currency, unitOrProjectId}) => {

    const specialServices = contents.find(c => c.exportable.id === parseInt(unitOrProjectId))?.specialServices;

    if (!specialServices || specialServices.length === 0) {
        return <div/>;
    }

    return <InvoiceControllingTable 
        key="invoice-table-special"
        icon="icon-note-hook"
        titleId="navBar.specialServices.title" 
        currency={currency}
        titles={[
            "invoiceControlling.table.name.title",
            "specialServices.table.header.text",
            "invoiceControlling.table.offsetting.title",
            "invoiceControlling.table.price.title",
            "invoiceControlling.table.texisionArticleNumber.title",
            "invoiceControlling.table.bidderArticleNumber.title"
        ]}
        body={
        
        Object.values(priceUnit.specialServiceGroup.priceItemMap).map((priceItem) => {

            const specialService = specialServices?.find(c => c.id === priceItem.objectId);
            
            return <TableRow key={"special-services-price-table-" + priceItem.objectId}>
                <TableCell>
                    {specialService?.name}
                </TableCell>
                <TableCell>
                    {specialService?.text}
                </TableCell>
                <TableCell>
                    {specialService?.offsetting && <FormattedMessage id={"constants.ResourceOffsetting." + specialService.offsetting}/>}
                </TableCell>
                <TableCell>
                    {formatPrice(priceItem.price)}
                </TableCell>
                <TableCell>
                    {"TX-" + priceItem.objectId}
                </TableCell>
                <TableCell>
                    {formatArticleNumbers(priceItem.articleNumbers)}
                </TableCell>
            </TableRow>;
        })
    }/>;
}

export {
    SpecialServicesPriceTable
}
