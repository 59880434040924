import React, {Component} from "react";

import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from 'notistack';
import {deleteAsync, getAsync, postAsync} from "../../services/BackendService";
import {createErrorMessage, createSuccessMessage} from "../../util/Util";
import {Card, Typography, Grid, MenuItem, ListItemText, Collapse, Icon, Button, Tooltip, CardContent, 
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper} from "@material-ui/core";
import {downloadS3Document, formatDate} from "../../util/DocumentUtil";
import {
    BIDDER_DASHBOARD_ROUTE,
    BIDDER_PROJECT_DETAILS_ROUTE,
    buildRouteWithPathVariable,
    DIALOG_TYPE_INFO,
    DIALOG_TYPE_WARNING,
    FEDERAL_STATES,
    OFFER_STATUS_ACCEPTED,
    OFFER_STATUS_NONE,
    OFFER_STATUS_PARTICIPATING,
    OFFER_STATUS_REJECTED,
    OFFER_STATUS_SUBMITTED,
    RATING_CATEGORY
} from "../../util/Constants";

import "../../css/BidderProjectDetails.css";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import {bodyBackgroundColor, texisionBlueGrayDark} from "../../util/ColorTheme";
import { getBidderInfoTextForSuitabilityCriterion, requiresSuitabilityDocuments } from "../../util/ProcedureUtil";
import InfoIcon from '@mui/icons-material/Info';
import { Header } from "../uiLibrary/Header";
import { SimpleTextCard } from "../uiLibrary/SimpleTextCard";
import { LinearProgressIndicator } from "../uiLibrary/LinearProgressIndicator";
import { DividerWithText } from "../uiLibrary/DividerWithText";
import {GeneralContext} from "../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import {
    getBiddersOfTender,
    hasMissingUserData,
    inviteBiddersToTender,
    isAdmin,
    isBidder, isOfferUser
} from "../../services/UserService";
import Alert from "@material-ui/lab/Alert";
import {getContract} from "../../services/ContractService";
import {getActiveProjectByOperationId, getPublishedProjectById} from "../../services/ProjectService";
import {getProjectDocumentZip} from "../../services/ExportService";
import UserProfile from "../account/UserProfile";


class BidderProjectDetails extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            project: null,
            contract: null,
            procedureOpen: false,
            suitabilityCriteriaOpen: false,
            ratingCriteriaOpen: false,
            isExporting: false,
            isVerified: false,
            offer: null,
            hasError: false,
            showParticipationStartDialog: false,
            showParticipationSuccessDialog: false,
            showParticipationQuitDialog: false,
            showCompleteProfileDialog: false,
            isInitializing: true,
            isLoading: false,
            notFound: false
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match.params.operationId !== prevProps.match.params.operationId) {
            await this.loadData();
        }
    }

    getProjectId = () => {
        let project = getActiveProjectByOperationId(this.context, this.props.match.params.operationId);
        return project === null ? this.context.appData.activeProjectId : project.id;
    }

    loadData = async() => {
        this.setState({isInitializing: true});
        await this.inviteBidder();
        await this.loadProject();
        if (isBidder(this.context.currentUser) || isAdmin(this.context.currentUser)) {
            await this.loadOffer();
        }
        this.setState({contract: await getContract(this.context, this.props, this.getProjectId())});
        this.setState({isInitializing: false});
    }

    loadProject = async() => {
        const project = await getPublishedProjectById(this.context, this.props, this.getProjectId());
        this.setState({project, notFound: !project});
    }

    loadOffer = async() => {
        const response = await getAsync("/offer/" + this.getProjectId());
        if (response?.status === 200) {
            this.setState({offer: response.data});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else if (response?.status === 404) {
            // if no offer has been sent, server returns 404
            this.setState({offer: null});
        } else {
            // otherwise an error occurred and participation will not be allowed
            this.setState({hasError: true});
        }
    }

    /** invite the bidder to the tender, if he comes by invite link */
    inviteBidder = async() => {
        const user = this.context.currentUser;
        let isVerified = isAdmin(user) || isBidder(user);
        const requestParameters = new URLSearchParams(window.location.search);
        const inviteProjectId = requestParameters.get("invite");
        if (isOfferUser(user) && user?.id != null && inviteProjectId !== null) {
            this.context.setActiveProjectId(inviteProjectId);
            let bidderIds = await getBiddersOfTender(this.context, this.props);
            if (!bidderIds.some(u => u.id === user.id)) {
                bidderIds = await inviteBiddersToTender(this.context, this.props, inviteProjectId, [user.id]);
                isVerified = bidderIds.includes(user.id);
            }
        }
        this.setState({isVerified: isVerified});
    }

    onProfileSave = async() => {
        this.setState({isLoading: true});
        await this.context.loadUser();
        this.setState({isLoading: false});
        if (!hasMissingUserData(this.context)) {
            this.setState({showCompleteProfileDialog: false, showParticipationStartDialog: true});
        }
    }

    startParticipation = async() => {
        this.setState({showParticipationStartDialog: false, isLoading: true});
        const newOffer = {
            version: 0, 
            projectId: this.getProjectId(),
            username: this.context.currentUser.username,
            status: OFFER_STATUS_PARTICIPATING,
            documents: [],
            receipts: []
        };
        let response = await postAsync("/offer", newOffer);
        if (response?.status === 200) {
            this.setState({showParticipationSuccessDialog: true});
            await this.loadOffer();
            await this.context.reloadAppData();
            const allParticipatingProjects = this.context.appData.allParticipatingProjects;
            const index = allParticipatingProjects?.findIndex(p => p.id === this.getProjectId());
            if (index === 0 || (index && index > 0)) {
                this.context.setActiveOperationId(this.props.match.params.operationId);
                this.props.history.push(buildRouteWithPathVariable(BIDDER_PROJECT_DETAILS_ROUTE, this.props.match.params.operationId));
            }
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else if (response?.status === 400) {
            createErrorMessage(this.props.intl.formatMessage({id: "bidder.projectDetails.startParticipation.incompleteUser"}), this.props);
        } else if (response?.status === 405) {
            await this.loadProject();
            if (!this.state.notFound) {
                createErrorMessage(this.props.intl.formatMessage({id: "bidder.projectDetails.startParticipation.deadlineOverMessage"}), this.props);
            }
        } else {
            await this.loadProject();
            if (!this.state.notFound) {
                createErrorMessage(this.props.intl.formatMessage({id: "bidder.projectDetails.startParticipation.errorMessage"}), this.props);
            }
        }
        this.setState({isLoading: false});
    }

    quitParticipation = async() => {
        let response = await deleteAsync("/offer/" + this.getProjectId());

        this.setState({showParticipationQuitDialog: false, isLoading: true});

        if (response?.status === 200) {
            createSuccessMessage(this.props.intl.formatMessage({id: "bidder.projectDetails.quitParticipation.successMessage"}), this.props);
            await this.loadOffer();
            await this.context.reloadAppData();
        } else if ([401, 403].includes(response?.status)) {
            this.context.logout();
        } else if (response?.status === 405) {
            await this.loadProject();
            if (!this.state.notFound) {
                createErrorMessage(this.props.intl.formatMessage({id: "bidder.projectDetails.quitParticipation.deadlineOverMessage"}), this.props);
            }
        } else {
            await this.loadProject();
            if (!this.state.notFound) {
                createErrorMessage(this.props.intl.formatMessage({id: "bidder.projectDetails.quitParticipation.errorMessage"}), this.props);
            }
        }
        this.setState({isLoading: false});
    }

    downloadProjectDocuments = () => {
        getProjectDocumentZip(this.context, this.props, this.getProjectId())
            .then((s3Object) => downloadS3Document(this.props, s3Object));
    }

    isParticipationDeadlineOver = () => {
        return this.state.project?.procedure?.firstOfferDeadline && this.state.project.procedure.firstOfferDeadline < new Date().getTime();
    }

    detailsHeader = () => {
        return <div>
            <Typography variant="h1">
                <FormattedMessage id="bidder.projectDetails.title" values={{projectName: this.state.project.name}}/>
            </Typography>
            <Typography variant="subtitle1">
                <FormattedMessage id="bidder.projectDetails.subtitle"/>
            </Typography>
        </div>;
    }

    ratingCriterionInformation = (c) => {
        let subtitle = "";
        if (!c || c === RATING_CATEGORY.PRICE) {
            return "";
        } else if (c.ratingArguments && c.ratingArguments.length > 0) {
            subtitle += this.props.intl.formatMessage({id: "constants.RatingCategory.tooltipIntroduction"}) + "\n\n";
            c.ratingArguments.forEach(argument => subtitle += "- " + argument + "\n");
            subtitle += "\n";
        }
        return subtitle;
    }

    detailsCard = (canDownload) => {

        return <Card>
            <CardContent>

                <Grid container wrap="nowrap" alignContent="flex-start" justifyContent="space-between" spacing={4} key={"name"}>
                    <Grid item xs>
                        <Typography variant="h3">
                            <FormattedMessage id="bidder.projectDetails.name"/>:
                        </Typography>
                        <Typography variant="subtitle2">
                            {this.state.project.name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {this.downloadButton(!canDownload)}
                    </Grid>
                </Grid>

                <MenuItem 
                    disableRipple 
                    button
                    selected={this.state.procedureOpen}
                    onClick={() => this.setState({procedureOpen: !this.state.procedureOpen})}>
                    <React.Fragment>
                        <ListItemText
                            primary={<Typography variant="h3">
                                <FormattedMessage id="procedure.conditions.dates.title"/>:
                            </Typography>}
                            style={{marginLeft: -15, marginRight: 10}}/>
                        <Icon className={this.state.procedureOpen ? "icon-caret-up-large" : "icon-caret-down-large"} style={{fontSize: "10pt"}}/>
                    </React.Fragment>
                </MenuItem>
                <Collapse in={this.state.procedureOpen} timeout="auto" unmountOnExit>
                    {this.procedureTable()}
                </Collapse>

                {requiresSuitabilityDocuments(this.state.project?.procedure) 
                && <MenuItem 
                    disableRipple
                    button
                    style={{marginTop: 30, marginBottom: 20}}
                    selected={this.state.suitabilityCriteriaOpen}
                    onClick={() => this.setState({suitabilityCriteriaOpen: !this.state.suitabilityCriteriaOpen})}>
                    <React.Fragment>
                        <ListItemText
                            primary={<Typography variant="h3">
                                <FormattedMessage id="bidder.projectDetails.suitabilityCriteria.title"/>:
                            </Typography>}
                            style={{marginLeft: -15, marginRight: 10}}/>
                        <Icon className={this.state.suitabilityCriteriaOpen ? "icon-caret-up-large" : "icon-caret-down-large"} style={{fontSize: "10pt"}}/>
                    </React.Fragment>
                </MenuItem>}
                {requiresSuitabilityDocuments(this.state.project?.procedure) 
                && <Collapse in={this.state.suitabilityCriteriaOpen} timeout="auto" unmountOnExit>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="bidder.projectDetails.suitabilityCriteria.subtitle"/>
                    </Typography>
                    <ul>
                        {this.state.project.procedure.suitabilityCriteria.map(c => <Grid key={c} container wrap="nowrap">
                            <Grid item xs>
                                <li>
                                    <Typography variant="subtitle2">
                                        {this.props.intl.formatMessage({id: "constants.SuitabilityCriterion." + c + ".title"})}
                                    </Typography>
                                </li>
                            </Grid>
                            <Grid item>
                                <Tooltip 
                                    title={<div style={{whiteSpace: "pre-line"}}>
                                        {getBidderInfoTextForSuitabilityCriterion(this.props.intl, this.state.contract, c, this.state.project?.currency)}
                                    </div>}
                                    style={{whiteSpace: "pre-line"}}>
                                    <InfoIcon style={{marginLeft: 10, color: texisionBlueGrayDark}}/>
                                </Tooltip>
                            </Grid>
                        </Grid>)}
                    </ul>
                </Collapse>}

                <MenuItem 
                    disableRipple 
                    button
                    style={{marginTop: 30, marginBottom: 20}}
                    selected={this.state.ratingCriteriaOpen}
                    onClick={() => this.setState({ratingCriteriaOpen: !this.state.ratingCriteriaOpen})}>
                    <React.Fragment>
                        <ListItemText
                            primary={<Typography variant="h3">
                                <FormattedMessage id="bidder.projectDetails.ratingCriteria.title"/>:
                            </Typography>}
                            style={{marginLeft: -15, marginRight: 10}}/>
                        <Icon className={this.state.ratingCriteriaOpen ? "icon-caret-up-large" : "icon-caret-down-large"} style={{fontSize: "10pt"}}/>
                    </React.Fragment>
                </MenuItem>
                <Collapse in={this.state.ratingCriteriaOpen} timeout="auto" unmountOnExit>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="bidder.projectDetails.ratingCriteria.subtitle"/>
                    </Typography>
                    <ul>
                        {this.state.project.procedure.ratingCriteria && Object.values(this.state.project.procedure.ratingCriteria)
                        .sort((a,b) => this.props.intl.formatMessage({id: "constants.RatingCategory." + a.ratingCategory}).localeCompare(this.props.intl.formatMessage({id: "constants.RatingCategory." + b.ratingCategory})))
                        .map(c => <Grid key={c.ratingCategory} container wrap="nowrap">
                            <Grid item xs>
                                <li>
                                    <Typography variant="subtitle2">
                                        {this.props.intl.formatMessage({id: "constants.RatingCategory." + c.ratingCategory}) + " - " + c.weightPercent + " %"}
                                    </Typography>
                                </li>
                            </Grid>
                            {this.ratingCriterionInformation(c) ? <Grid item>
                                <Tooltip 
                                    title={<div style={{whiteSpace: "pre-line"}}>
                                        {this.ratingCriterionInformation(c)}
                                    </div>}
                                    style={{whiteSpace: "pre-line"}}>
                                    <InfoIcon style={{marginLeft: 10, color: texisionBlueGrayDark}}/>
                                </Tooltip>
                            </Grid> : ""}
                        </Grid>)}
                    </ul>
                </Collapse>

                {this.state.project.businessUnits?.units && this.state.project.businessUnits.units.length !== 0 && <div>
                    <Typography variant="h3" style={{marginTop: 30}}>
                        <FormattedMessage id="bidder.projectDetails.businessUnits"/>:
                    </Typography>
                    {this.unitTable(this.state.project.businessUnits.units)}
                </div>}

            </CardContent>
        </Card>;
    }

    procedureTable = () => {
        const {firstCallForSubmissionDate, visitDate, questionsDeadline, responseDeadline,
            firstOfferDeadline, conceptEvaluationDate, negotiationDate,
            bindingDeadline, startOfServiceDate, federalState} = this.state.project.procedure;
        const currentDate = new Date().getTime();
        const allDates = [
            firstCallForSubmissionDate, visitDate, questionsDeadline, responseDeadline,
            firstOfferDeadline, conceptEvaluationDate, negotiationDate,
            bindingDeadline, startOfServiceDate, currentDate
        ];
        let nextDateIndex;
        allDates.sort((a,b) => a > b);
        for (let i = 0; i < allDates.length; i++) {
            if (allDates[i] === currentDate && i !== allDates.length - 1) {
                nextDateIndex = i;
            }
        }
        return <Grid container style={{marginBottom: 20, marginTop: 20}}>
            {[
                {date: firstCallForSubmissionDate, text: "firstCallForSubmissionDate", index: 0, dividerTextId: "procedure.conditions.offerRound"},
                {date: visitDate, text: "visitDate", index: 1}, 
                {date: questionsDeadline, text: "questionsDeadline", index: 2}, 
                {date: responseDeadline, text: "responseDeadline", index: 3}, 
                {date: firstOfferDeadline, text: "firstOfferDeadline", index: 4}, 
                {date: conceptEvaluationDate, text: "conceptEvaluationDate", index: 5}, 
                {date: negotiationDate, text: "negotiationDate", index: 6}, 
                {date: bindingDeadline, text: "bindingDeadline", index: 7, isTemporarily: true, dividerTextId: "procedure.conditions.validityAndContractStart"},
                {date: startOfServiceDate, text: "startOfServiceDate", index: 8}
            ].map(entry => this.procedureRow(entry.date, entry.text, entry.index === nextDateIndex, entry.isTemporarily, entry.dividerTextId))}
            <Grid container wrap="nowrap" justifyContent="flex-start" spacing={4} key={"holidays"} alignItems="center">
                <Grid item>
                    <Typography style={{fontWeight: "bold"}} variant="subtitle2">
                        {this.props.intl.formatMessage({id: "commons.hint.dialogTitle"})}:
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        {this.props.intl.formatMessage({id: "procedure.federal.state.text"}) + " "  + FEDERAL_STATES[federalState]}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>;
    }

    procedureRow = (dateInMillis, textId, isNextDate, isTemporarily, dividerTextId) => {
        const nextDateHint = isNextDate 
            ? " (" + this.props.intl.formatMessage({id: "procedure.nextDate"}) + ")" 
            : "";
        return <Grid key={textId} container>
            {dividerTextId && <Grid item xs={12}>
                <DividerWithText textId={dividerTextId}/>
            </Grid>}
            <Grid item xs={12}>
                <Grid container wrap="nowrap" justifyContent="flex-start" spacing={2} key={textId} alignItems="center">
                    <Grid item xs>
                        <Typography style={{fontWeight: isNextDate ? "bold" : null}} variant="subtitle2">
                            {this.props.intl.formatMessage({id: "procedure." + textId + ".description"}) + nextDateHint}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{fontWeight: "bold"}} variant="subtitle2">
                            {formatDate(this.props.intl, dateInMillis) + (isTemporarily 
                                ? " (" + this.props.intl.formatMessage({id: "procedure.date.temporarily"}) + ")"
                                : "")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>;
    }

    unitTable = (units) => {
        return <TableContainer component={Paper} style={{overflowX: "scroll", marginTop: 20}}>
            <Table aria-label="bidder-dashboard-table">
                <TableHead style={{backgroundColor: bodyBackgroundColor}}>
                    <TableRow>
                        <TableCell key={"name"}>{this.props.intl.formatMessage({id: "bidder.projectDetails.businessUnitName"})}</TableCell>
                        <TableCell key={"country"}>{this.props.intl.formatMessage({id: "bidder.projectDetails.unitTable.country.title"})}</TableCell>
                        <TableCell key={"city"}>{this.props.intl.formatMessage({id: "bidder.projectDetails.unitTable.city.title"})}</TableCell>
                        <TableCell key={"zipCode"}>{this.props.intl.formatMessage({id: "bidder.projectDetails.unitTable.zipCode.title"})}</TableCell>
                        <TableCell key={"streetAndNumber"}>{this.props.intl.formatMessage({id: "bidder.projectDetails.unitTable.streetAndNumber.title"})}</TableCell>
                        <TableCell key={"logisticsType"}>{this.props.intl.formatMessage({id: "bidder.projectDetails.unitTable.logisticsType.title"})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {units.map(unit => (
                    <TableRow key={unit.name}>
                        <TableCell component="th" scope="row">{unit.name}</TableCell>
                        <TableCell component="th" scope="row">{unit.country 
                            ? this.props.intl.formatMessage({id: "constants.country." + unit.country.toLowerCase()}) 
                            : "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">{unit.city ?? "-"}</TableCell>
                        <TableCell component="th" scope="row">{unit.zipCode ?? "-"}</TableCell>
                        <TableCell component="th" scope="row">{unit.streetAndNumber ?? "-"}</TableCell>
                        <TableCell component="th" scope="row">{unit.deliveryType 
                            ? this.props.intl.formatMessage({id: "constants.LogisticType." + unit.deliveryType}) :
                             "-"}
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>;
    }

    downloadButton = (disabled) => {
        return (
            <Tooltip title={disabled ? this.props.intl.formatMessage({id: "bidder.projectDetails.downloadDisabled.tooltip"}) : ""}>
                <div>
                    <Button 
                        variant="contained" 
                        color="primary"
                        disabled={disabled} 
                        onClick={() => this.downloadProjectDocuments()}>
                        {this.props.intl.formatMessage({id: "bidder.projectDetails.downloadDocuments.button"})}
                    </Button>
                </div>
            </Tooltip>
        );
    }

    statusCard = () => {
        const offerStatus = this.state.offer?.status ?? OFFER_STATUS_NONE;
        return (
            <Card style={{marginTop: 30}}>
                <CardContent>
                    <Grid container wrap="nowrap" justifyContent="space-between" spacing={4} key={"name"}>
                        <Grid item xs>
                            <Typography variant="h3">
                                {this.props.intl.formatMessage({id: "bidder.projectDetails.currentParticipationStatus"}) + ": "}
                            </Typography>
                            <Typography variant="subtitle2">
                                {this.props.intl.formatMessage({id: "constants.offerStatus." + offerStatus.toLowerCase()})}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {this.participationButton()}
                        </Grid>
                    </Grid>

                    {offerStatus === OFFER_STATUS_NONE && <Alert severity="info" style={{marginBottom: 10}}>
                        <FormattedMessage id="bidder.projectDetails.participation.hint"/>
                    </Alert>}
                </CardContent>
            </Card>
        );
    }

    participationButton = () => {
        if (this.state.hasError || this.state.isInitializing) {
            return <div/>;
        }
        const quit = this.state.offer;
        const isDisabled = [OFFER_STATUS_REJECTED, OFFER_STATUS_ACCEPTED, OFFER_STATUS_SUBMITTED]
            .includes(this.state.offer?.status) || this.isParticipationDeadlineOver() || !this.state.isVerified;
        
        const button = <Button 
            variant="contained" 
            color="primary" 
            onClick={() => {
                if (quit) {
                    this.setState({showParticipationQuitDialog: true});
                } else if (hasMissingUserData(this.context)) {
                    this.setState({showCompleteProfileDialog: true});
                } else {
                    this.setState({showParticipationStartDialog: true});
                }
            }}
            disabled={this.state.isLoading || isDisabled}>
            {this.props.intl.formatMessage({id: "bidder.projectDetails." + (quit ? "quit" : "start") + "Participation.button"})}
        </Button>;

        if (!isDisabled) {
            return button;
        } else if (!this.state.isVerified) {
            return (
                <Tooltip title={this.props.intl.formatMessage({id: "bidder.projectDetails.participation.readOnly"})}>
                    <div>{button}</div>
                </Tooltip>
            );
        } else {
            let title = "bidder.projectDetails.participation.secondDeadlineOver.tooltip";
            if (this.state.offer?.status === OFFER_STATUS_REJECTED) {
                title = "bidder.projectDetails.participation.rejected.tooltip";
            } else if (this.state.offer?.status === OFFER_STATUS_ACCEPTED) {
                title = "bidder.projectDetails.participation.accepted.tooltip";
            } else if (this.state.offer?.status === OFFER_STATUS_SUBMITTED) {
                title = "bidder.projectDetails.participation.submitted.tooltip";
            }
            return (
                <Tooltip title={this.props.intl.formatMessage({id: title})}>
                    <div>{button}</div>
                </Tooltip>
            );
        }
    }

    render() {
        const participationSubtitle = this.state.isVerified
            ? "bidder.projectDetails.participation.subtitle"
            : "bidder.projectDetails.participation.readOnly";

        return <>

            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.state.notFound}
                titleId="commons.projectNotFound.title"
                subtitleId="commons.projectNotFound.subtitle"
                actionId="commons.toBidderDashboard.button"
                onAction={() => {
                    this.context.setUnsavedChanges(false);
                    this.props.history.push(BIDDER_DASHBOARD_ROUTE);
                    this.context.reloadAppData();
                }}/>

            <TexisionDialog
                type={DIALOG_TYPE_INFO}
                open={this.state.showCompleteProfileDialog}
                titleId="payment.completeProfile.dialog.title"
                subtitleId="payment.completeProfile.dialog.subtitle"
                content={<UserProfile
                    key={this.context.currentUser?.version}
                    currentUser={this.context.currentUser}
                    onSuccess={() => this.onProfileSave()}/>}
                cancelId="commons.cancel.button"
                onCancel={() => this.setState({showCompleteProfileDialog: false })} />

            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.state.showParticipationStartDialog}
                titleId="bidder.projectDetails.startParticipationDialog.title"
                subtitleId="bidder.projectDetails.startParticipationDialog.subtitle"
                cancelId="commons.no.button"
                actionId="commons.yes.button"
                onCancel={() => this.setState({showParticipationStartDialog: false})}
                onAction={() => this.startParticipation()}/>

            <TexisionDialog
                type={DIALOG_TYPE_INFO}
                open={this.state.showParticipationSuccessDialog}
                titleId="bidder.projectDetails.participationSuccessDialog.title"
                subtitleId="bidder.projectDetails.participationSuccessDialog.subtitle"
                replacements={{projectName: this.state.project?.name}}
                actionId="commons.okay.button"
                onAction={() => this.setState({showParticipationSuccessDialog: false})}/>

            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.state.showParticipationQuitDialog}
                titleId="bidder.projectDetails.quitParticipationDialog.title"
                subtitleId="bidder.projectDetails.quitParticipationDialog.subtitle"
                cancelId="commons.no.button"
                actionId="commons.yes.button"
                onCancel={() => this.setState({showParticipationQuitDialog: false})}
                onAction={() => this.quitParticipation()}/>

            <LinearProgressIndicator active={this.state.isExporting}/>

            {/* status of participation */}
            {this.state.project && <>
                <Header
                    titleId="bidder.projectDetails.participation.title"
                    subtitleId={participationSubtitle}/>
                {this.statusCard()}
            </>}

            {/* project details */}
            {this.state.project?.procedure && <div style={{marginTop: 50}}>
                {this.detailsHeader()}
                {this.detailsCard(this.state.isVerified)}
            </div>}

            <SimpleTextCard show={!this.state.project?.procedure} textId="bidder.projectDetails.noProject"/>
            
        </>;
    }
}

export default injectIntl(withSnackbar(withRouter(BidderProjectDetails)));
