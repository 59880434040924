import React, {Component} from "react";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {InputLabel, Select} from "@material-ui/core";
import {PriceType} from "../../../../util/Constants";
import PropTypes from "prop-types";

class PriceTypeSelectBox extends Component {

    static contextType = GeneralContext;

    render() {
        return <>
            <InputLabel
                htmlFor={this.props.id}
                style={{marginTop: "20px", marginBottom: 5}}>
                <FormattedMessage id={"project.create.article.type"}/> *
            </InputLabel>
            <Select
                native
                value={this.props.value}
                label={this.props.value ? this.props.intl.formatMessage({id: "constants.PRICE_TYPE." + this.props.value}) : ""}
                onChange={(e) => this.props.onChange(e.target.value)}
                style={{width: "230px"}}
                inputProps={{name: 'priceType', id: this.props.id}}>

                <option aria-label="None" value=""/>

                {Object.values(PriceType)
                    .filter(priceType => priceType !== PriceType.SPECIAL_SERVICE)
                    .sort((a,b) =>
                        this.props.intl.formatMessage({id: "constants.PRICE_TYPE." + a})
                            .localeCompare(this.props.intl.formatMessage({id: "constants.PRICE_TYPE." + b})))
                    .map((priceType) => {
                        return (
                            <option
                                key={priceType}
                                value={priceType}>
                                {this.props.intl.formatMessage({id: "constants.PRICE_TYPE." + priceType})}
                            </option>
                        );
                    })}
            </Select>
        </>
    }
}
PriceTypeSelectBox.propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
};
export default injectIntl(PriceTypeSelectBox);