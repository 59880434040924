import {isBidder} from "../../services/UserService";
import {Button, Tooltip} from "@material-ui/core";
import React from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import PropTypes from 'prop-types';

class MessageCreateButton extends React.Component {

    static contextType = GeneralContext;

    render() {
        const createButtonTextId = isBidder(this.context.currentUser) ? "createQuestion" : "createInformation";

        let tooltipTitleId;

        if (isBidder(this.context.currentUser) && this.props.disabled) {
            tooltipTitleId = "bidderCommunication.createQuestion.deadlineOver";
        }

        return (
            <Tooltip title={tooltipTitleId ? this.props.intl.formatMessage({id: tooltipTitleId}) : ""}>
                <div className="createButton">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isBidder(this.context.currentUser) && this.props.disabled}
                        onClick={() => this.props.onClick()}>
                        {this.props.intl.formatMessage({id: "bidderCommunication." + createButtonTextId + ".button"})}
                    </Button>
                </div>
            </Tooltip>
        );
    }
}

MessageCreateButton.propTypes = {
    procedure: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
};

export default injectIntl(MessageCreateButton);
