import React, {Component} from 'react';
import {Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Card, CardContent} from '@material-ui/core';
import {injectIntl} from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {getAsync} from '../../services/BackendService';

import {sortAsc} from '../../util/Util';

import '../../css/BillOfQuantities.css';

import {
    CUSTOMER_ARTICLES_ROUTE,
    BILL_OF_QUANTITIES_TYPE,
    RESIDENTS_LAUNDRY_ROUTE,
    OPERATING_RESOURCES_ROUTE,
    SPECIAL_SERVICES_ROUTE,
    EXPORT_TYPE_CONCATENATE,
    EXPORT_TYPE_MERGE,
    DIALOG_TYPE_INFO,
    DIALOG_TYPE_ERROR,
    RENTAL_LINEN_ROUTE,
    LICENSE_TYPES, LOGISTICS_ROUTE
} from '../../util/Constants';
import parse from 'html-react-parser';
import {primaryColor} from '../../util/ColorTheme';
import TexisionDialog from '../uiLibrary/TexisionDialog';
import ExportHeader from './ExportHeader';
import {billOfQuantitiesMerges, relevantBillOfQuantitiesConflicts, relevantBillOfQuantitiesMismatches} from '../../util/ExportUtil';
import {GeneralContext} from "../contexts/GeneralContext";
import { withRouter } from 'react-router-dom';
import {latestActivePayment} from "../../services/PaymentService";
import {exportBillOfQuantities} from "../../services/ExportService";


class BillOfQuantitiesExport extends Component {

    static contextType = GeneralContext;

    state = {
        billOfQuantities: null,
        currentSection: null,
        hintIdToFootnote: new Map(),
        hintIdToText: new Map(),
        showLockedDialog: false
    }

    async componentDidMount() {
        await this.reloadData();
    }

    reloadData = async() => {
        let billOfQuantities = await this.loadAllElements(this.context.getUserStateValue(BILL_OF_QUANTITIES_TYPE));
        await this.createFootnotes(billOfQuantities);
        this.setState({billOfQuantities: billOfQuantities});
    }

    loadAllElements = async() => {
        let projectId = this.context.appData.activeProjectId;
        const response = await getAsync("/billofquantities/" + projectId + "/parts/" + this.context.getUserStateValue(BILL_OF_QUANTITIES_TYPE));
        if (response?.status === 200) {
            return response.data;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    createFootnotes = async(billOfQuantities) => {
        let hintIdToFootnote = new Map();
        let hintIdToText = new Map();
        let currentFootnote = 0;
        if (billOfQuantities?.contentVoList) {
            for (let bill of billOfQuantities.contentVoList.sort((a,b) => sortAsc(a.exportable.name, b.exportable.name))) {
                if (bill?.categoryList) {
                    for (let category of bill.categoryList.sort((a, b) => a.order - b.order)) {
                        if (category?.sections) {
                            for (let section of category.sections.sort((a, b) => a.order - b.order)) {
                                let hintsToTextMap = new Map(Object.entries(bill.hints));
                                if (section?.group && !hintIdToFootnote.has(section.group) && !hintIdToText.has(section.group) && hintsToTextMap.get(section.group)) {
                                    currentFootnote += 1
                                    hintIdToFootnote.set(section.group, currentFootnote);
                                    hintIdToText.set(section.group, hintsToTextMap.get(section.group));
                                }
                            }
                        }
                    }
                }
            }
        }
        this.setState({hintIdToText: hintIdToText, hintIdToFootnote: hintIdToFootnote});
    }

    footNote = (section) => {
        let footnote;
        if (section?.group
            && this.state.hintIdToFootnote.has(section.group) 
            && this.state.hintIdToText.has(section.group)) {
            footnote = this.state.hintIdToFootnote.get(section.group).toString();
        }
        return(<div
            style={{cursor: "pointer", color: primaryColor}}
            onClick={footnote ? () => {this.setState({currentSection: section})} : () => {}}>
                {footnote}
        </div>);
    }

    footNoteDialog = (section) => {
        if (!section) {
            return <div/>;
        }
        let helptextHeader;
        let buttonLink;
        let buttonText;
        switch (section.type) {
            
            case "BASIC_CUSTOMER":
            case "CUSTOM_ARTICLE_INTRO":
            case "CUSTOM_ARTICLE_TYPE_N":
            case "CUSTOM_ARTICLE_SUITABLE":
            case "CUSTOM_ARTICLE_PRICE_SHEET":
                helptextHeader = "billOfQuantities.helptextHeader.CUSTOMER_ARTICLES";
                buttonLink = CUSTOMER_ARTICLES_ROUTE;
                buttonText = "customerArticle.title";
                break;

            case "BASIC_RESIDENTS":
                helptextHeader = "billOfQuantities.helptextHeader.RESIDENTS_LAUNDRY";
                buttonLink = RESIDENTS_LAUNDRY_ROUTE;
                buttonText = "navBar.residentsLaundry.title";
                break;

            case "BASIC_ASSORTMENT_1":
            case "BASIC_ASSORTMENT_2":
            case "STATIONARY_PART_1":
            case "STATIONARY_PART_2":
            case "STATIONARY_ARTICLE_N":
            case "STATIONARY_PART_3":
            case "STATIONARY_PART_4":
            case "STATIONARY_PART_5":
            case "STATIONARY_PART_6":
            case "STATIONARY_OFF_SETTINGS":
            case "WORK_WEAR_INTRO":
            case "WORK_WEAR_SAMPLE":
            case "WORK_WEAR_PRICE_SHEET":
            case "WORK_WEAR_OFF_SETTINGS":
                helptextHeader = "billOfQuantities.helptextHeader.ASSORTMENT";
                buttonLink = RENTAL_LINEN_ROUTE;
                buttonText = "navBar.rentalLinen.title";
                break;

            case "STATIONARY_DATA_1":
            case "STATIONARY_DATA_2":
            case "STATIONARY_DATA_3":
                helptextHeader = "billOfQuantities.helptextHeader.STATIONARY_DATA_ANALYSIS";
                buttonLink = OPERATING_RESOURCES_ROUTE;
                buttonText = "operatingResources.navbar.title";
                break;

            case "WORK_SIZE_BASED_INTRO":
            case "WORK_SIZE_BASED_N_PROFESSIONAL":
            case "WORK_SIZE_BASED_DUTY":
            case "WORK_PERSONALIZED_INTRO":
            case "WORK_PERSONALIZED_N_PROFESSIONAL":
            case "WORK_PERSONALIZED_DUTY":
                helptextHeader = "billOfQuantities.helptextHeader.PROFESSIONAL_GROUP";
                buttonLink = RENTAL_LINEN_ROUTE;
                buttonText = "navBar.rentalLinen.title";
                break;

            case "RESIDENTS_INTRO":
            case "RESIDENTS_PICK_UP":
            case "RESIDENTS_DELIVERY":
            case "RESIDENTS_PRICE_SHEET":
                helptextHeader = "billOfQuantities.helptextHeader.RESIDENTS_LAUNDRY";
                buttonLink = RESIDENTS_LAUNDRY_ROUTE;
                buttonText = "navBar.residentsLaundry.title";
                break;

            case "RESIDENTS_DATA_ANALYSIS":
                helptextHeader = "billOfQuantities.helptextHeader.RESIDENTS_DATA_ANALYSIS";
                buttonLink = OPERATING_RESOURCES_ROUTE;
                buttonText = "operatingResources.navbar.title";
                break;

            case "ORGANIZATIONAL_INTRO":
            case "ORGANIZATIONAL_PRICE_SHEET":
                helptextHeader = "billOfQuantities.helptextHeader.OPERATING_RESOURCES";
                buttonLink = OPERATING_RESOURCES_ROUTE;
                buttonText = "operatingResources.navbar.title";
                break;

            case "ORGANIZATIONAL_RESOURCE_N":
                helptextHeader = "billOfQuantities.helptextHeader.OPERATING_RESOURCES";
                buttonLink = OPERATING_RESOURCES_ROUTE;
                buttonText = "operatingResources.navbar.title";
                break;
            
            case "LOGISTICS_INTRO_ARTICLES":
            case "LOGISTICS_GENERAL":
                helptextHeader = "billOfQuantities.helptextHeader.LOGISTICS";
                buttonLink = LOGISTICS_ROUTE;
                buttonText = "navBar.deliveryAndPickup.title";
                break;
            case "LOGISTICS_INTRO_CUSTOMER_ARTICLES":
            case "LOGISTICS_CUSTOMER_ARTICLES":
                helptextHeader = "billOfQuantities.helptextHeader.LOGISTICS_CUSTOMER_ARTICLES";
                buttonLink = LOGISTICS_ROUTE;
                buttonText = "navBar.deliveryAndPickup.title";
                break;
            case "LOGISTICS_INTRO_WORK_WEAR":
                helptextHeader = "billOfQuantities.helptextHeader.LOGISTICS_WORK_WEAR";
                buttonLink = LOGISTICS_ROUTE;
                buttonText = "navBar.deliveryAndPickup.title";
                break;
            case "LOGISTICS_INTRO_FLAT_LINEN":
            case "LOGISTICS_FLAT_LINEN":
                helptextHeader = "billOfQuantities.helptextHeader.LOGISTICS_FLAT_LINEN";
                buttonLink = LOGISTICS_ROUTE;
                buttonText = "navBar.deliveryAndPickup.title";
                break;
            case "LOGISTICS_INTRO_RESIDENTS_LAUNDRY":
            case "LOGISTICS_RESIDENTS_LAUNDRY":
                helptextHeader = "billOfQuantities.helptextHeader.LOGISTICS_RESIDENTS_LAUNDRY";
                buttonLink = LOGISTICS_ROUTE;
                buttonText = "navBar.deliveryAndPickup.title";
                break;
            case "LOGISTICS_WORK_WEAR_SIZE_BASED":
                helptextHeader = "billOfQuantities.helptextHeader.LOGISTICS_SIZE_BASED_WORK_WEAR";
                buttonLink = LOGISTICS_ROUTE;
                buttonText = "navBar.deliveryAndPickup.title";
                break;
            case "LOGISTICS_WORK_WEAR_PERSONALIZED":
                helptextHeader = "billOfQuantities.helptextHeader.LOGISTICS_PERSONALIZED_WORK_WEAR";
                buttonLink = LOGISTICS_ROUTE;
                buttonText = "navBar.deliveryAndPickup.title";
                break;
            case "LOGISTICS_TIMES":
                helptextHeader = "billOfQuantities.helptextHeader.LOGISTICS_TIMES";
                buttonLink = LOGISTICS_ROUTE;
                buttonText = "navBar.deliveryAndPickup.title";
                break;

            case "SPECIAL_SERVICE_N":
                helptextHeader = "billOfQuantities.helptextHeader.SPECIAL_SERVICES";
                buttonLink = SPECIAL_SERVICES_ROUTE;
                buttonText = "navBar.specialServices.title";
                break;
            case "TEXTILE_CONTROLLING_INTRO":
                helptextHeader = "billOfQuantities.helptextHeader.TEXTILE_CONTROLLING";
                buttonLink = OPERATING_RESOURCES_ROUTE;
                buttonText = "operatingResources.navbar.title";
                break;
            case "BASIC_PART_1":
            case "BASIC_PART_2":
            case "BASIC_PART_3":
            case "BASIC_PART_4":
            case "BASIC_PART_5":
            case "BASIC_PART_6":
            case "BASIC_PART_7":
            default:
                break;
        }

        return <TexisionDialog
            type={DIALOG_TYPE_INFO}
            open={!!this.state.currentSection}
            style={{maxHeight: "60%"}}
            titleId={helptextHeader}
            content={<Typography>{this.state.hintIdToText.get(section.group)}</Typography>}
            cancelId="commons.close.label"
            actionId={buttonText}
            onCancel={() => this.setState({currentSection: null})}
            onAction={this.context.getUserStateValue(BILL_OF_QUANTITIES_TYPE) === EXPORT_TYPE_CONCATENATE ? () => this.props.history.push(buttonLink) : null}/>;
    }

    handleExportSwitchClicked = async(checked) => {
        let exportType = checked ? EXPORT_TYPE_MERGE : EXPORT_TYPE_CONCATENATE;
        await this.context.setUserStateValue(BILL_OF_QUANTITIES_TYPE, exportType);
        let billOfQuantities = await this.loadAllElements();
        this.setState({billOfQuantities: billOfQuantities});
    }

    render() {

        return (
            <div className={latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE) ? "contentAreaExportLocked" : null}>

                <TexisionDialog
                    type={DIALOG_TYPE_ERROR}
                    open={this.state.showLockedDialog}
                    titleId="export.locked.title"
                    subtitleId="export.locked.subtitle"
                    actionId="commons.okay.button"
                    onAction={() => this.setState({showLockedDialog: false})}/>

                {this.footNoteDialog(this.state.currentSection)}

                <ExportHeader
                    documentType="billOfQuantities"
                    titleId="billOfQuantities.title.h1"
                    subtitleId="billOfQuantities.title.subtitle"
                    exportButtonId="billOfQuantities.export.text"
                    handleExportSwitch={this.handleExportSwitchClicked}
                    export={(exportType, e) => exportBillOfQuantities(
                        this.context, this.context.appData.activeProjectId, exportType, () => this.setState({showLockedDialog: true}))}
                    relevantConflicts={relevantBillOfQuantitiesConflicts}
                    relevantMismatches={relevantBillOfQuantitiesMismatches}
                    forAllUnits={true}
                    mergedConflicts={billOfQuantitiesMerges()}
                />

                <Grid container spacing={1}>
                {this.state.billOfQuantities && this.state.billOfQuantities.contentVoList
                    .sort((a,b) => sortAsc(a.exportable.name, b.exportable.name))
                    .map(element => {
                    return (
                        <Grid key={element.exportable.id} item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h2" style={{marginBottom: "1%"}}>{element.exportable.name}</Typography>
                                    {element.categoryList
                                        .sort((a, b) => a.order - b.order)
                                        .map(category => {
                                        return (
                                            <Accordion key={category.category} elevation={1}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={element + "-CONTENT"}
                                                    id={element + "-HEADER"}>
                                                    <Typography variant="h6">
                                                        {category.title}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div style={{width:"100%"}}>
                                                    {Object.entries(category.sections)
                                                        .sort((a, b) => a[1].order - b[1].order)
                                                        .map(section => {
                                                            return (
                                                                <div key={section[1].order} style={{paddingBottom: "10px"}}>
                                                                    <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
                                                                        <Grid item>
                                                                            <div style={{paddingRight: "5px"}}>
                                                                                {parse(section[1].text)}
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            {this.footNote(section[1])}
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                                </AccordionDetails>
                                            </Accordion>);
                                        })
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    );})
                }
                </Grid>
            </div>
        );
    }
}

export default injectIntl(withRouter(BillOfQuantitiesExport));
