import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ImageUploading from "react-images-uploading";

class ArticleImages extends Component {

    render() {
        return (
            <Card className="child">

                <CardContent>

                    <Typography variant="h6" component="h2">
                        <FormattedMessage id="articleConfigurator.pictures.h1"/>
                    </Typography>

                    <Typography color="textSecondary">
                        <FormattedMessage id="articleConfigurator.pictures.h2"/>
                    </Typography>

                    <ImageUploading 
                        multiple 
                        value={this.props.images.map(imageVo => {return {...imageVo, dataURL: imageVo?.dataURL ?? imageVo.image?.url}})}
                        onChange={(imageList) => this.props.handleImagesChanged([...imageList])} 
                        maxNumber={5}>

                        {({imageList, onImageUpload, onImageRemove}) => {

                            return (
                            
                                <div className="upload__image-wrapper">

                                    <Grid container spacing={2} style={{marginTop: "1%"}}>

                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" onClick={onImageUpload}>
                                                <FormattedMessage id="commons.uploadPictures.button"/>
                                            </Button>
                                        </Grid>

                                        {imageList.map(imageVo => {
                                            return (
                                                <Grid key={imageVo.dataURL} item style={{marginTop: "1%"}}>

                                                    <div key={imageVo.dataURL + "-div"} className="image-item">

                                                        <img src={imageVo.dataURL} alt="" width="100"/>

                                                        <div className="image-item__btn-wrapper">
                                                            <Button color="primary" onClick={onImageRemove}>
                                                                <FormattedMessage id="commons.remove.button"/>
                                                            </Button>
                                                        </div>

                                                    </div>

                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </div>
                            );
                        }}

                    </ImageUploading>

                </CardContent>

            </Card>
        );
    }
}

export default injectIntl(ArticleImages);
