import { TableCell, TableRow } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { formatArticleNumbers } from "../../../../util/TableUtil";
import { enhanceAssignedResourcesWithMasterData, formatPrice } from "../../../../util/Util";
import { InvoiceControllingTable } from "./InvoiceControllingTable";

const ResourcesPriceTable = ({priceUnit, contents, currency, unitOrProjectId}) => {

    const content = contents.find(c => c.exportable.id === parseInt(unitOrProjectId));
    const resources = enhanceAssignedResourcesWithMasterData(content.operatingResources, content.unitResources);

    if (!resources || resources.length === 0) {
        return <div/>;
    }

    return <InvoiceControllingTable
        key="invoice-table-resources"
        icon="icon-roll-cage"
        titleId="operatingResources.navbar.title" 
        currency={currency}
        titles={[
            "invoiceControlling.table.name.title",
            "operatingResources.table.description",
            "invoiceControlling.table.offsetting.title",
            "invoiceControlling.table.price.title",
            "invoiceControlling.table.texisionArticleNumber.title",
            "invoiceControlling.table.bidderArticleNumber.title"
        ]} 
        body={
        
        Object.values(priceUnit.operatingResourceGroup.priceItemMap).map((priceItem) => {

            const resource = resources.find(r => r.id === priceItem.objectId);
            
            return <TableRow key={"resources-price-table-" + priceItem.objectId}>
                <TableCell>
                    {resource?.name}
                </TableCell>
                <TableCell>
                    {resource?.description}
                </TableCell>
                <TableCell>
                    {resource?.resourceOffsetting ? <FormattedMessage id={"constants.ResourceOffsetting." + resource.resourceOffsetting}/> : ""}
                </TableCell>
                <TableCell>
                    {formatPrice(priceItem.price)}
                </TableCell>
                <TableCell>
                    {"TX-" + priceItem.objectId}
                </TableCell>
                <TableCell>
                    {formatArticleNumbers(priceItem.articleNumbers)}
                </TableCell>
            </TableRow>;
        })
    }/>;
}

export {
    ResourcesPriceTable
}
