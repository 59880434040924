import React, {Component} from 'react';
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {DIALOG_TYPE_WARNING, INTERVAL_TYPES} from "../../../../util/Constants";

class BaseDataIntervalChangeDialog extends Component {

    getSubtitleId = () => {
        const {oldInterval, newInterval} = this.props;
        if (newInterval === INTERVAL_TYPES.MONTHLY && oldInterval === INTERVAL_TYPES.QUARTERLY) {
            return "cockpit.statistics.baseData.interval.change.quarterlyToMonthly.dialog.subtitle";
        } else if (newInterval === INTERVAL_TYPES.MONTHLY && oldInterval === INTERVAL_TYPES.YEARLY) {
            return "cockpit.statistics.baseData.interval.change.yearlyToMonthly.dialog.subtitle";
        } else if (newInterval === INTERVAL_TYPES.QUARTERLY && oldInterval === INTERVAL_TYPES.YEARLY) {
            return "cockpit.statistics.baseData.interval.change.yearlyToQuarterly.dialog.subtitle";
        }
    }

    render() {
        return (
            <TexisionDialog
                open={this.props.show}
                type={DIALOG_TYPE_WARNING}
                titleId="cockpit.statistics.baseData.interval.change.dialog.title"
                subtitleId={this.getSubtitleId()}
                actionId="commons.confirm.button"
                onAction={() => {this.props.onConfirm(this.props.newInterval); this.props.close();}}
                cancelId="commons.cancel.button"
                onCancel={() => this.props.close()}/>
        );
    }
}

export default BaseDataIntervalChangeDialog;
