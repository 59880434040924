import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Grid, Tooltip, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { uploadBlobCatch } from "../../services/BackendService";
import { texisionGreen, texisionOrange, warningColor } from "../../util/ColorTheme";
import { FILE_TYPES, selectDocument } from "../../util/DocumentUtil";
import { canChangeOffer, getDocumentsForRatingCategory, offerDisabledTooltip, offerSubmittedTooltip } from "../../util/ProcedureUtil";
import { DocumentPreview } from "../uiLibrary/DocumentPreview";
import { Header } from "../uiLibrary/Header";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { RATING_CATEGORY } from "../../util/Constants";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import React, { useContext } from "react";
import "../../css/BidderProjectDetails.css";
import { GeneralContext } from "../contexts/GeneralContext";

const BidderRatingCriteria = ({offer, project, onUpload, reloadOffer, props}) => {

    const formatMessage = useIntl().formatMessage;
    const context = useContext(GeneralContext);

    const procedure = project?.procedure;

    if (!procedure || !offer) {
        return <div/>;
    }

    const ratingSelection = (criterion, documents) => {

        const tooltip = offerDisabledTooltip(project, offer, true, formatMessage)
            || offerSubmittedTooltip(offer, formatMessage) || "";

        const isPrice = criterion.ratingCategory === RATING_CATEGORY.PRICE;

        const addButton = <Button
            disabled={!canChangeOffer(offer, procedure)}
            color="primary" 
            component="label">
            <FormattedMessage id="offers.document.select.button"/>
            <input 
                type="file" 
                id="offer-document-input" 
                accept={FILE_TYPES.PDF} 
                style={{display: "none"}} 
                onChange={(e) => selectDocument(e.target.files[0], async(document) => {
                    onUpload(true);
                    await uploadBlobCatch(context, "/document/offer/" + offer.id + "/rating/" + criterion.ratingCategory, document, null, props);
                    await reloadOffer();
                    onUpload(false);
                }, props, documents)} 
                onClick={(event)=> {event.target.value = null}}/>
        </Button>;

        let subtitle = "";
        if (isPrice) {
            subtitle += props.intl.formatMessage({id: "ratingCriteria.noPriceDocuments.hint"});
        } else if (criterion.ratingArguments && criterion.ratingArguments.length > 0) {
            subtitle += props.intl.formatMessage({id: "constants.RatingCategory.tooltipIntroduction"}) + "\n\n";
            criterion.ratingArguments.forEach(argument => subtitle += "- " + argument + "\n");
            subtitle += "\n";
        }

        let accordionSuffix;
        if (isPrice) {
            accordionSuffix = <div style={{fontStyle: "italic"}}><FormattedMessage id="ratingCriteria.noDocumentsNecessary.hint"/></div>;
        } else if (getDocumentsForRatingCategory(offer.ratingDocuments, criterion.ratingCategory).length === 0) {
            accordionSuffix = <MoreHorizIcon style={{color: texisionOrange}}/>;
        } else {
            accordionSuffix = <CheckIcon style={{color: texisionGreen}}/>;
        }

        return <Accordion elevation={1} key={criterion.ratingCategory}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-label="Expand"
                aria-controls="rating-content"
                id="rating-header">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h4" component="span">
                            {props.intl.formatMessage({id: "constants.RatingCategory." + criterion.ratingCategory}) 
                            + " - " + criterion.weightPercent + " %"}
                        </Typography>
                    </Grid>
                    <Grid item>{accordionSuffix}</Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>

                    <Grid item xs={12} style={{marginBottom: 20}}>
                        <Typography variant="body2" component="span" style={{whiteSpace: "pre-line"}}>
                            {subtitle}
                        </Typography>
                    </Grid>

                    {!isPrice && <React.Fragment>

                        {documents.map((document) => 
                        <DocumentPreview
                            key={document.id}
                            document={document} 
                            deleteEndpoint="/document/rating/"  
                            disabled={!canChangeOffer(offer, procedure)} 
                            tooltipText={tooltip}
                            context={context}
                            props={props} 
                            callback={() => reloadOffer()}/>)}

                        <Grid item xs={12} style={{marginBottom: 20}}>
                            {!canChangeOffer(offer, procedure) 
                            ? <Tooltip placement="bottom-start" title={tooltip}>
                                <div>{addButton}</div>
                            </Tooltip> 
                            : addButton}
                        </Grid>
                        
                    </React.Fragment>}

                </Grid>
            </AccordionDetails>
        </Accordion>;
    }

    return <Card style={{marginBottom: 20}}>
        <CardContent>

            <Header 
                titleId="bidder.offerDocuments.ratingCriteria.title" 
                titleVariant="h3" 
                subtitleId="bidder.offerDocuments.ratingCriteria.subtitle"
                subtitleVariant="subtitle2"/>

            <Grid container alignItems="flex-start" spacing={2} wrap="nowrap">
                <Grid item>
                    <ReportProblemOutlinedIcon style={{color: warningColor, marginTop: 4}}/>
                </Grid>
                <Grid item xs>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="bidder.offerDocuments.ratingCriteria.subtitle2"/>
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {procedure?.ratingCriteria && Object.values(procedure.ratingCriteria)
                ?.sort((a,b) => props.intl.formatMessage({id: "constants.RatingCategory." + a.ratingCategory}).localeCompare(props.intl.formatMessage({id: "constants.RatingCategory." + b.ratingCategory})))
                .map(c => {
                return <Grid key={c.ratingCategory} item xs={12} className="ratingAccordion"> 
                    {ratingSelection(c, getDocumentsForRatingCategory(offer.ratingDocuments, c.ratingCategory))}
                </Grid>}
                )}
            </Grid>

        </CardContent>
    </Card>;
}

export {
    BidderRatingCriteria
}
