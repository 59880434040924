import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import "../../../css/OffersOverview.css";
import "../../../css/Procedure.css";
import "../../../css/ProjectDashboard.css";

const OfferPreviews = ({participationCount, submittedOffersCount}) => {

    const intl = useIntl();

    return <div className="offerContainer">
        <Grid container spacing={2}>
            <Grid item>
                <Card className="equipmentCard">
                    <CardContent>
                        <Typography className="centerContent" variant="h2">
                            {intl.formatMessage({id: "offers.overview.participants.title"})}
                        </Typography>
                        <Typography className="centerContent" variant="body1">
                            {intl.formatMessage({id: "offers.overview.participants.subtitle"})}
                        </Typography>
                        <Typography className="centerContent" variant="h1">
                            {participationCount ?? 0}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card className="equipmentCard">
                    <CardContent>
                        <Typography className="centerContent" variant="h2">
                            {intl.formatMessage({id: "offers.overview.offers.title"})}
                        </Typography>
                        <Typography className="centerContent" variant="body1">
                            {intl.formatMessage({id: "offers.overview.offers.subtitle"})}
                        </Typography>
                        <Typography className="centerContent" variant="h1">
                            {submittedOffersCount ?? 0}
                        </Typography>
                    </CardContent>
                </Card> 
            </Grid>
        </Grid>
    </div>;
}

export {
    OfferPreviews
}
