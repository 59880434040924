import { Component } from "react";
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import { texisionGreen, texisionOrange, texisionRed, white } from "../../../../util/ColorTheme";
import { INVOICE_CONFLICT_TYPE } from "../../../../util/Constants";
import { GeneralContext } from "../../../contexts/GeneralContext";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import InvoiceArticleDetails from "../../invoices/InvoiceArticleDetails";
import { formatPrice } from "../../../../util/Util";
import {getActiveOperation} from "../../../../services/OperationService";

class InvoiceStatisticsItemDetails extends Component {

    static contextType = GeneralContext;

    priceChange = (contractItem, invoiceItem) => {
        const currency = getActiveOperation(this.context).activeProject.currency;
        return (
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                    {" ("}
                </Grid>
                <Grid item>
                    <Typography variant="h4" style={{color: texisionGreen, whiteSpace: "nowrap"}}>
                        {formatPrice(contractItem.price) + " " + currency}
                    </Typography>
                </Grid>
                <Grid item style={{marginTop: 6}}>
                    <ArrowRightAltIcon/>
                </Grid>
                <Grid item>
                    <Typography variant="h4" style={{color: texisionRed, whiteSpace: "nowrap"}}>
                        {formatPrice(invoiceItem.price) + " " + currency}
                    </Typography>
                </Grid>
                <Grid item>
                    {")"}
                </Grid>
            </Grid>
        );
    }

    getValidationIcon = (conflictType) => {
        let icon;
        switch (conflictType) {
            case INVOICE_CONFLICT_TYPE.MISSING_ARTICLE:
            case INVOICE_CONFLICT_TYPE.DUPLICATED_ARTICLE:
                icon = <WarningAmberIcon style={{color: texisionOrange}}/>;
                break;
            case INVOICE_CONFLICT_TYPE.NO_CONFLICT:
                icon = <CheckCircleOutlineIcon style={{color: texisionGreen}}/>;
                break;
            case INVOICE_CONFLICT_TYPE.WRONG_PRICE:
            case INVOICE_CONFLICT_TYPE.UNKNOWN_ARTICLE:
                icon = <ErrorOutlineIcon style={{color: texisionRed}}/>;
                break;
            default:
                break;
        }
        return icon;
    }

    render() {

        const { contractItem, invoiceItem } = this.props;

        return (
            <div style={{backgroundColor: white}}>

                <div style={{marginLeft: 25, marginRight: 25, paddingTop: 25}}>
                    <h2>
                        <FormattedMessage id="cockpit.statistics.selectedItem.title"/>
                    </h2>
                </div>

                {invoiceItem 
                
                    ? <>
                    
                        <List>
                            <ListItem key={invoiceItem.articleName} value={invoiceItem} dense>

                                <ListItemIcon style={{marginLeft: 5}}>
                                    {this.getValidationIcon(invoiceItem.conflictType)}
                                </ListItemIcon>

                                <ListItemText>

                                    <Grid container alignItems="center" spacing={3} wrap="nowrap">

                                        <Grid item style={{fontWeight: "bold"}}>
                                            <FormattedMessage id={"cockpit.statistics.conflictType." + invoiceItem.conflictType}/>
                                        </Grid>

                                        {invoiceItem.conflictType === INVOICE_CONFLICT_TYPE.WRONG_PRICE && <Grid item>
                                            {this.priceChange(contractItem, invoiceItem)}
                                        </Grid>}
                                    </Grid>

                                </ListItemText>

                            </ListItem>
                        </List>

                        <InvoiceArticleDetails 
                            contractItem={contractItem} 
                            invoiceItem={invoiceItem}
                            style={{width: "100%"}}/>
                    
                    </>
                    
                    : <div style={{width: "90%", height: 200, padding: 40, display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <FormattedMessage id="cockpit.statistics.itemDetails.info"/>
                    </div>
                
                }

            </div>
        );
    }
}

export default injectIntl(InvoiceStatisticsItemDetails);
