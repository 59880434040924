import React, { Component } from "react";

import { injectIntl } from "react-intl";
import { withSnackbar } from 'notistack';
import { getAsync } from "../../services/BackendService";
import { LinearProgressIndicator } from "../uiLibrary/LinearProgressIndicator";

import "../../css/BidderProjectDetails.css";
import { BidderDocuments } from "./BidderDocuments";
import { requiresSuitabilityDocuments } from "../../util/ProcedureUtil";
import { Header } from "../uiLibrary/Header";
import { SimpleTextCard } from "../uiLibrary/SimpleTextCard";
import { BidderSuitabilityCriteria } from "./BidderSuitabilityCriteria";
import { BidderRatingCriteria } from "./BidderRatingCriteria";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import { BIDDER_DASHBOARD_ROUTE, DIALOG_TYPE_WARNING } from "../../util/Constants";
import {GeneralContext} from "../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import {getContract} from "../../services/ContractService";
import {getPublishedProjectById} from "../../services/ProjectService";


class BidderOfferDocumentsOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            project: null,
            contract: null,
            offer: null,
            notFound: false,
            isUploading: false
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match.params.projectId !== prevProps.match.params.projectId) {
            await this.loadData();
        }
    }

    loadData = async() => {
        this.setState({
            contract: await getContract(this.context, this.props, this.props.match.params.projectId),
            project: await getPublishedProjectById(this.context, this.props, parseInt(this.props.match.params.projectId))
        });
        await this.loadOffer();
    }

    loadOffer = async() => {
        const response = await getAsync("/offer/" + this.props.match.params.projectId);
        if (response?.status === 200) {
            this.setState({offer: response.data});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
            // if no offer has been sent, server returns 404, otherwise an error occured and participation will not be allowed
        } else if (response?.status === 404) {
            this.setState({notFound: true, offer: null});
            this.props.closeSnackbar();
        } else {
            this.setState({offer: null});
        }
    }

    render() {

        const titleId = "bidder.offerDocuments.firstRound.title";
        let subtitleId;
        if (requiresSuitabilityDocuments(this.state.project?.procedure)) {
            subtitleId = "bidder.offerDocuments.subtitle1";
        } else if (this.state.offer) {
            subtitleId = "bidder.offerDocuments.subtitle2";
        }

        return <>

            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.state.notFound}
                titleId="commons.participationNotFound.title"
                subtitleId="commons.participationNotFound.subtitle"
                actionId="commons.toBidderDashboard.button"
                onAction={() => {
                    this.context.setUnsavedChanges(false);
                    this.props.history.push(BIDDER_DASHBOARD_ROUTE);
                    this.context.reloadAppData();
                }}/>

            <LinearProgressIndicator active={this.state.isUploading}/>

            <Header titleId={titleId} subtitleId={subtitleId}/>

            <SimpleTextCard show={this.state.hasError} textId="bidder.offerDocuments.error.subtitle"/>
            <SimpleTextCard show={!this.state.hasError && !this.state.offer} textId="bidder.offerDocuments.noOffer.subtitle"/>

            <BidderSuitabilityCriteria
                contract={this.state.contract} 
                offer={this.state.offer}
                project={this.state.project}
                props={this.props}
                onUpload={(isUploading) => this.setState({isUploading})} 
                reloadOffer={() => this.loadOffer()}/>

            <BidderRatingCriteria
                offer={this.state.offer}
                project={this.state.project}
                props={this.props}
                onUpload={(isUploading) => this.setState({isUploading})} 
                reloadOffer={() => this.loadOffer()}/>

            <BidderDocuments 
                offer={this.state.offer} 
                procedure={this.state.project?.procedure}
                props={this.props} 
                onUpload={(isUploading) => this.setState({isUploading})} 
                reloadOffer={() => this.loadOffer()}/>
                
        </>;
    }
}

export default injectIntl(withSnackbar(withRouter(BidderOfferDocumentsOverview)));
