import React from "react";
import OperationPanel from "../../OperationPanel";
import PropTypes from "prop-types";
import FooterWrapper from "../FooterWrapper";
import {postAsyncCatch, putAsyncCatch} from "../../../../services/BackendService";
import {PROJECT_STATUS_IS_ACCEPTED} from "../../../../util/Constants";
import {updateProject} from "../../../../services/ProjectService";
import {injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {GeneralContext} from "../../../contexts/GeneralContext";

class BaseDataPage extends React.Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            projectName: props.currentOperation?.workingProject?.name ?? "",
            profileIds: props.currentOperation?.profileIds ?? [],
            isPrivate: true,
            isLoading: false
        }
    }

    onContinue = async() => {
        this.setState({isLoading: true});

        if (this.props.currentOperation) {

            const operation = this.props.currentOperation;
            let workingProject = operation.workingProject;
            workingProject.name = this.state.projectName;
            workingProject.isPrivate = this.state.isPrivate;
            const projectSuccess = await updateProject(this.context, this.props, workingProject, true);
            const updatedOperation = await putAsyncCatch(this.context, "/operation/" + operation.id, operation, this.props, true);
            this.setState({isLoading: false});
            this.props.onContinue(projectSuccess && updatedOperation);

        } else {
            const project = {
                id: null,
                name: this.state.projectName,
                version: 0,
                status: PROJECT_STATUS_IS_ACCEPTED
            };

            let operation = {
                id: null,
                version: 0,
                isPrivate: this.state.isPrivate,
                activeProject: project,
                workingProject: null,
                profileIds: this.state.profileIds
            };

            const operationResult = await postAsyncCatch(this.context, "/operation", operation, this.props, true);
            this.setState({isLoading: false});
            this.props.onContinue(operationResult);
        }
    }

    render() {
        return (
            <FooterWrapper
                invalidData={!this.state.projectName || !this.state.profileIds.length}
                onContinue={this.onContinue}
                showBackButton={false}
                onCancel={this.props.onCancel}
                isLoading={this.state.isLoading}>
                <OperationPanel
                    hideTitle={true}
                    projectId={this.props.currentOperation?.activeProject?.projectId}
                    projectName={this.state.projectName}
                    onNameChange={(projectName) => this.setState({projectName})}
                    isPrivate={this.state.isPrivate}
                    onIsPrivateChange={(isPrivate) => this.setState({isPrivate})}
                    profileIds={this.state.profileIds}
                    onProfileIdsChange={(profileIds) => this.setState({profileIds})}
                />
            </FooterWrapper>
        );
    }
}

BaseDataPage.defaultProps = {
    currentOperation: null
}

BaseDataPage.propTypes = {
    currentOperation: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired
};

export default withSnackbar(injectIntl(BaseDataPage));
