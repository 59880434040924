import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { INVOICE_CONTROLLING_ROUTE } from "../../../util/Constants";
import { getNumberOfArticles } from "../../../util/ProcedureUtil";
import { formatPrice } from "../../../util/Util";
import {GeneralContext} from "../../contexts/GeneralContext";
import { useHistory } from "react-router-dom";
import {getActiveProject} from "../../../services/ProjectService";

const OfferSummaryPrice = ({offer, priceOffer}) => {

    const context = useContext(GeneralContext);
    const history = useHistory();
    const activeProject = getActiveProject(context);
    const currency = activeProject.currency;

    return <Accordion key="price-accordion">

        <AccordionSummary 
            expandIcon={<ExpandMoreIcon/>}
            aria-label="Expand-price"
            aria-controls="price-content"
            id="price-header">
            <Typography variant="h3" component="span">
                <FormattedMessage id="offerSuitability.price.title"/>
            </Typography>
        </AccordionSummary>

        <AccordionDetails>
            <Grid container style={{marginTop: 20}}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        <FormattedMessage id="offers.overview.netSum"/>: {formatPrice(priceOffer.sumNet) + " " + currency}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        <FormattedMessage id="offers.overview.vat"/>: {offer.valueAddedTax + " %"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        <FormattedMessage id="offers.overview.grossSum"/>: {formatPrice(priceOffer.sumGross) + " " + currency}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        <FormattedMessage id="offerSuitability.articleNumbers.title"/>: {getNumberOfArticles(priceOffer)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="text"
                        color="primary"
                        style={{marginLeft: -8}}
                        onClick={() => history.push(INVOICE_CONTROLLING_ROUTE)}>
                        <FormattedMessage id="offerSuitability.invoiceControlling.button"/>
                    </Button>
                </Grid>
            </Grid>
        </AccordionDetails>

    </Accordion>;
}

export {
    OfferSummaryPrice
}
