import { FormattedMessage } from "react-intl";

const InvoiceDiagramEmptyMessage = ({
    hasNoPeriod, 
    invoices,
    mapInvoiceDateToFirstDayOfMonth,
    contractItemMap,
    selectedNodes,
    selectedMinDate, 
    selectedMaxDate
}) => {

    if (selectedNodes && selectedNodes.length > 0) {
        return null;
    }

    let message;

    if (!contractItemMap || contractItemMap.size === 0) {

        message = <FormattedMessage id="cockpit.statistics.noItems"/>;

    } else if (!invoices || invoices.length === 0) {

        message = <FormattedMessage id="cockpit.statistics.noInvoices"/>;

    } else if (hasNoPeriod) {

        message = <FormattedMessage id="cockpit.statistics.noPeriod"/>;

    } else if (!invoices.find(invoice => 
        mapInvoiceDateToFirstDayOfMonth(invoice.invoiceDate).getTime() >= selectedMinDate 
        && mapInvoiceDateToFirstDayOfMonth(invoice.invoiceDate).getTime() <= selectedMaxDate)) {

        message = <FormattedMessage id="cockpit.statistics.noInvoicesInPeriod"/>;

    } else {

        message = <FormattedMessage id="cockpit.statistics.noSelectedItems"/>;

    }

    return (
        <div style={{width: "90%", height: 200, padding: 40, display: "flex", justifyContent: "center", alignItems: "center"}}>
            {message}
        </div>
    );
}

export {
    InvoiceDiagramEmptyMessage
}
