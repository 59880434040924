import React, {Component} from 'react';
import {getAsync} from '../../services/BackendService';
import {Card, CardContent, Typography} from '@material-ui/core';
import {FormattedMessage, injectIntl} from 'react-intl';
import '../apps/App.css';
import {withSnackbar} from 'notistack';
import {GeneralContext} from "../contexts/GeneralContext";
import UserProfile from './UserProfile';
import UserPassword from './UserPassword';
import UserImage from './UserImage';

class MyAccount extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {user: null}
    }

    async componentDidMount() {
        await this.loadUser();
    }

    loadUser = async() => {
        let response = await getAsync("/whoami");
        if (response?.status === 200) {
            this.setState({user: response.data});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    render() {

        const currentUser = this.state.user;
        
        return (
            <>

                <Typography variant="h1">
                    <FormattedMessage id="userprofil.h1"/>
                </Typography>

                <Typography variant="subtitle1" style={{marginBottom: "20px"}}>
                    <FormattedMessage id="userprofil.h2"/>
                </Typography>

                {currentUser && <>

                    <Card className="child">

                        <CardContent>

                            <UserProfile
                                key={currentUser.version} 
                                currentUser={currentUser}
                                onSuccess={() => this.loadUser()}/>

                        </CardContent>
                    </Card>
                    
                    <Card className="child">

                        <CardContent>

                            <UserImage
                                key={currentUser.version}
                                currentImage={currentUser.imageVo}
                                version={currentUser.version}
                                onSuccess={() => this.loadUser()}/>

                        </CardContent>
                    </Card>

                    <Card className="child">

                        <CardContent>

                            <UserPassword 
                                key={currentUser.version} 
                                version={currentUser.version} 
                                onSuccess={() => this.loadUser()}/>

                        </CardContent>

                    </Card>
                </>}
                
            </>
        );
    }
}

export default withSnackbar(injectIntl(MyAccount));
