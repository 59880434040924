import { Card, CardContent, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const PriceSheetErrorCard = () => (
    <Card>
        <CardContent style={{marginTop: 30}}>
            <Typography variant="subtitle1">
                <FormattedMessage id="bidder.priceSheet.error.subtitle"/>
            </Typography>
        </CardContent>
    </Card>
)

export {
    PriceSheetErrorCard
}
