import { Accordion, AccordionDetails, AccordionSummary, Grid, Icon, IconButton, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { cutName, formatDate } from "../../../util/DocumentUtil";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../../../css/OffersOverview.css";
import "../../../css/Procedure.css";
import "../../../css/ProjectDashboard.css";

const OfferSummaryDocuments = ({documents, onDownload}) => {

    const intl = useIntl();

    const noDocuments = !documents || documents.length === 0;

    const documentPreview = (document) => <Grid item key={document.filename} style={{marginRight: 80}}>
        <Grid className="procedureDocumentPreviewContent" container spacing={1}>
            <Grid item className="offerDocumentPreview">
                <Icon color="primary">picture_as_pdf</Icon>
            </Grid>
            <Grid item xs className="offerDocumentName">
                {cutName(intl, document.filename) + ", " + formatDate(intl, document.lastModified, "dd.MM.yyyy")}
            </Grid>
            <Grid item>
                <Grid container justifyContent="flex-end" wrap="nowrap">
                    <Grid item>
                        <IconButton
                            onClick={() => onDownload(document)}>
                            <Icon>download</Icon>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>;

    return <Accordion key="documents-accordion">

        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>} 
            aria-label="Expand-documents"
            aria-controls="documents-content"
            id="documents-header">
            <Typography variant="h3" component="span">
                <FormattedMessage id="offers.overview.documents"/>
            </Typography>
        </AccordionSummary>

        <AccordionDetails>
            {noDocuments 
            ? <Typography variant="subtitle2" component="span">
                <FormattedMessage id="offerSummary.documents.noDocuments"/>
            </Typography>
            : <Grid container>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" component="span">
                        <FormattedMessage id="offerSummary.documents.subtitle"/>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid className="procedureDocumentGrid" container spacing={2}>
                        {documents.map((document) => documentPreview(document))}
                    </Grid>
                </Grid>
            </Grid>}
        </AccordionDetails>

    </Accordion>;
}

export {
    OfferSummaryDocuments
}
