import { TableCell, TableRow } from "@material-ui/core";
import { useIntl } from "react-intl";
import { PriceType } from "../../../util/Constants";
import { offerDisabledTooltip } from "../../../util/ProcedureUtil";
import {formatPrice, isTender} from "../../../util/Util";
import { renderFeatures, renderVolumeType } from "../../../util/TableUtil";
import { PriceSheetTable } from "./PriceSheetTable";
import ArticleButtonCell from "../cells/ArticleButtonCell";
import ArticleNumberCell from "../cells/ArticleNumberCell";
import ArticlePriceCell from "../cells/ArticlePriceCell";
import { useEffect, useState } from "react";
import AlternateDescriptionCell from "../cells/AlternateDescriptionCell";

const PriceSheetCustomerArticlesTable = ({
    readOnly,
    singlePriceOnly,
    allArticleNumbers,
    customerArticles, 
    editable,
    exportType,
    lockArticleNumbers,
    lockPrices,
    priceGroup,
    project,
    resetAllArticleNumbers,
    show,
    updatePriceSheet,
    // following prop is only used for bidders
    offer,
    isPrevious
}) => {

    const [customerArticleMap, setCustomerArticleMap] = useState(priceGroup?.priceItemMap ? JSON.parse(JSON.stringify(priceGroup.priceItemMap)) : null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (priceGroup?.priceItemMap) {
            setCustomerArticleMap(JSON.parse(JSON.stringify(priceGroup.priceItemMap)));
        }
    }, [priceGroup])

    const intl = useIntl();

    if (!show) {
        return (<div/>);
    }

    const handleItemChange = (newCustomerArticle) => {
        let map = customerArticleMap;
        map[newCustomerArticle.objectId] = newCustomerArticle;
        setCustomerArticleMap({...map});
    }

    const resetItemMap = () => {
        setCustomerArticleMap(JSON.parse(JSON.stringify(priceGroup.priceItemMap)));
        resetAllArticleNumbers();
    }

    const disabled = !isEditing || (!editable && isTender() && offerDisabledTooltip(project, offer, isPrevious, intl.formatMessage));

    let headerTitles = [
        "customerArticle.table.header.description",
        "customerArticle.table.header.volumeType",
        "customerArticle.create.features.title"
    ];

    if (!singlePriceOnly) {
        headerTitles.push("customerArticle.create.props.volume");
    }

    return (
        <PriceSheetTable
            title={intl.formatMessage({id: "bidder.priceSheet.table.customerArticles.title"})}
            type="customerArticles"
            headerTitles={headerTitles}
            editButtonLabel={lockArticleNumbers && lockPrices ? "bidder.priceSheet.editAlternateLabels.button" : null}
            rows={customerArticles.sort((a,b) => a.description.localeCompare(b.description)).map(customerArticle => {

                const priceItem = customerArticleMap[customerArticle.id];

                return (
                    <TableRow key={customerArticle.id}>

                        <TableCell>{customerArticle.description}</TableCell>

                        <TableCell>{renderVolumeType(customerArticle, intl)}</TableCell>

                        <TableCell>{renderFeatures(customerArticle.features, intl, "constants.ArticleFeatureType.")}</TableCell>

                        {!singlePriceOnly && <TableCell>{customerArticle.volume}</TableCell>}

                        <TableCell>
                            <ArticlePriceCell 
                                disabled={disabled || lockPrices}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                        {!singlePriceOnly && <TableCell>{isEditing ? "" : formatPrice(priceItem?.sumNet)}</TableCell>}

                        {!singlePriceOnly && <TableCell>{isEditing ? "" : formatPrice(priceItem?.sumGross)}</TableCell>}

                        <TableCell>
                            <AlternateDescriptionCell
                                disabled={disabled}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={(newCustomerArticle) => handleItemChange(newCustomerArticle)}/>
                        </TableCell>

                        <TableCell>
                            <ArticleNumberCell 
                                allArticleNumbers={allArticleNumbers} 
                                disabled={disabled || lockArticleNumbers}
                                exportType={exportType} 
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                        <TableCell>
                            <ArticleButtonCell 
                                allArticleNumbers={allArticleNumbers} 
                                disabled={disabled || lockArticleNumbers}
                                exportType={exportType} 
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                    </TableRow>
                );
            })}
            isEditing={isEditing}
            readOnly={readOnly}
            singlePriceOnly={singlePriceOnly}
            offer={offer}
            project={project}
            priceGroup={priceGroup}
            onSave={() => updatePriceSheet(PriceType.CUSTOMER_ARTICLE, customerArticleMap)}
            resetItemMap={() => resetItemMap()}
            setIsEditing={(value) => setIsEditing(value)}
            editable={editable}
        />
    );
}

export {
    PriceSheetCustomerArticlesTable
}
