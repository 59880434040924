import {deleteAsyncCatch, getAsyncCatch, postAsyncCatch, putAsyncCatch, uploadBlobCatch} from "./BackendService";
import {enhanceAssignedResourcesWithMasterData} from "../util/Util";
import {updateValidationMap} from "./ProjectService";

export async function getAllResources(context, props) {
    const allResources = await getAsyncCatch(context, "/operating/resource", props);
    return allResources ? allResources : [];
}

export async function createResource(context, props, resource) {
    const createdResource = await postAsyncCatch(context, "/operating/resource", resource, props);
    return createdResource ? createdResource : null;
}

export async function updateResource(context, props, resource) {
    const updatedResource = await putAsyncCatch(context, "/operating/resource", resource, props);
    return updatedResource ? updatedResource : null;
}

export async function deleteResource(context, props, resourceId) {
    return await deleteAsyncCatch(context, "/operating/resource/" + resourceId, props);
}

export async function getAssignedBusinessUnitResourcesForUnit(context, props, unitId) {
    const businessUnitId = unitId !== null && unitId !== undefined ? unitId : context.appData.activeUnitId;
    const assignedBusinessUnitResources = await getAsyncCatch(context, "/operating/resource/unit/" + businessUnitId, props);
    return assignedBusinessUnitResources ? assignedBusinessUnitResources : [];
}

export async function getOperatingResourcesForUnit(context, props, unitId) {
    const businessUnitId = unitId !== null && unitId !== undefined ? unitId : context.appData.activeUnitId;
    const operatingResources = await getAsyncCatch(context, "/operating/resource/unit/" + businessUnitId + "/resources", props);
    return operatingResources ? operatingResources : [];
}

export async function createBusinessUnitResource(context, props, unitResource, projectId, hideSuccess) {
    let errorTextMap = new Map();
    errorTextMap.set(404, "operatingResourceService.createBusinessUnitResource.404");
    let activeProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    const createdResource = await postAsyncCatch(context, "/operating/resource/unit/" + activeProjectId,
        unitResource, props, hideSuccess, errorTextMap);
    if (createdResource) {
        await updateValidationMap(context);
    }
    return createdResource ? createdResource : null;
}

export async function updateBusinessUnitResource(context, props, unitResource, hideSuccess, projectId) {
    let activeProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    const updatedResource = await putAsyncCatch(context, "/operating/resource/unit/" + activeProjectId,
        unitResource, props, hideSuccess);
    return updatedResource ? updatedResource : null;
}

export async function updateUnitResourceCount(context, props, resourceId, version, countVo, projectId, hideSuccess) {
    let activeProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    const updatedResource = await putAsyncCatch(context, "/operating/resource/unit/" + activeProjectId + "/"
        + resourceId + "/" + version, countVo, props, hideSuccess);
    return updatedResource ? updatedResource : null;
}

export async function removeAssignedResource(context, props, resourceId) {
    const success = await deleteAsyncCatch(context, "/operating/resource/unit/" + context.appData.activeProjectId + "/" + resourceId, props);
    if (success) {
        await updateValidationMap(context);
    }
    return success;
}

export async function addResourceImage(context, props, resourceId, resourceVersion, imageBlob) {
    return await uploadBlobCatch(context, '/operating/resource/image/' + resourceId + "/" + resourceVersion, imageBlob, null, props);
}

export async function deleteResourceImage(context, props, parentId, filename) {
    return await deleteAsyncCatch(context, '/operating/resource/image/' + parentId + "/" + filename, props);
}

export async function loadOperatingResourcesAndOffsettingMap(context, props, unitId) {
    // Load the operating resources that are used in this business unit
    let operatingResourcesResponse = await getOperatingResourcesForUnit(context, props, unitId);
    // Load the information about the count and the offsetting
    let assignedResourcesResponse = await getAssignedBusinessUnitResourcesForUnit(context, props, unitId);
    if (operatingResourcesResponse && assignedResourcesResponse) {
        // combine both lists to get all data
        let operatingResources = enhanceAssignedResourcesWithMasterData(operatingResourcesResponse, assignedResourcesResponse);
        let oldResourcesOffsettingMap = new Map();
        let newResourcesOffsettingMap = new Map();
        if (operatingResources) {
            operatingResources.forEach((o) => {
                if (!oldResourcesOffsettingMap.has(o.subCategory)) {
                    oldResourcesOffsettingMap.set(o.subCategory, o.resourceOffsetting);
                    newResourcesOffsettingMap.set(o.subCategory, o.resourceOffsetting);
                }
            });
        }
        return {operatingResources, oldResourcesOffsettingMap};
    }
}

export async function saveResourceOffsettings(context, props, oldResourcesOffsettingMap, newResourcesOffsettingMap, resources,
                                              onSuccess, onError, onFinish, projectId) {
    let resultList = [];
    if (newResourcesOffsettingMap && oldResourcesOffsettingMap && resources) {
        let numberOfErrors = 0;
        for (let i = 0; i < resources.length; i++) {
            if (newResourcesOffsettingMap.get(resources[i].subCategory) !== oldResourcesOffsettingMap.get(resources[i].subCategory)) {
                const updateVo = {
                    id: resources[i].unitResourceId, // ID of entry in mapping table
                    version: resources[i].version,
                    businessUnitId: resources[i].unitId,
                    operatingResourceId: resources[i].id, // ID of the corresponding operating resource
                    count: 0, // Note: This count will be ignored. Only the offsetting will be updated.
                    resourceOffsetting: newResourcesOffsettingMap.get(resources[i].subCategory),
                }
                const updatedResource = await updateBusinessUnitResource(context, props, updateVo, true, projectId);
                if (!updatedResource) {
                    numberOfErrors += 1;
                } else {
                    resultList.push(updatedResource);
                }
            }
        }
        if (numberOfErrors === 0) {
            onSuccess();
        } else {
            onError();
        }
        onFinish();
    }
    return resultList;
}
