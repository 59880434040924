import { LinearProgress } from "@material-ui/core";

const LinearProgressIndicator = ({active}) => (
    <div style={{paddingBottom: active ? "26px" : "30px"}}>
        {active && <LinearProgress/>}
    </div>
);

export {
    LinearProgressIndicator
};
