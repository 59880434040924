import {Grid, Typography} from "@material-ui/core";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {PRICE_SHEET_STATE} from "../../../../util/Constants";
import { GeneralContext } from "../../../contexts/GeneralContext";
import { withSnackbar } from "notistack";
import DeleteWorkingProjectButton from "../buttons/DeleteWorkingProjectButton";
import ChangeEffectiveFromButton from "../buttons/ChangeEffectiveFromButton";
import PublishOrRequestPriceButton from "../buttons/PublishOrRequestPriceButton";
import PropTypes from "prop-types";

class ContractDeleteOrPublishHeader extends Component {

    static contextType = GeneralContext;

    render() {

        let subtitle;
        if (this.props.priceSheetState === PRICE_SHEET_STATE.COMPLETE_BESIDES_NEW_ITEMS) {
            subtitle = "cockpit.contractStatus.own.postProcessingWithNewItems.subtitle";
        } else {
            subtitle = "cockpit.contractStatus.own.postProcessing.subtitle";
        }

        const hasNoChangesOrIsIncomplete = this.props.hasNoChanges || this.props.isPriceOfferIncomplete || this.props.isWorkingProjectIncomplete;
        
        return (
            <>
                <Grid container>
                    <Grid item xs style={{marginRight: 10}}>

                        <Typography variant="h1">
                            <FormattedMessage id="cockpit.contractStatus.title"/>
                        </Typography>

                        <Typography variant="subtitle1">
                            <FormattedMessage id={subtitle}/>
                        </Typography>

                    </Grid>

                    <Grid container spacing={2}>

                        <Grid item>
                            <DeleteWorkingProjectButton/>
                        </Grid>

                        <Grid item>
                            <ChangeEffectiveFromButton
                                newestEffectiveFrom={this.props.newestEffectiveFrom}
                                onSave={this.props.onEffectiveFromChanged}/>
                        </Grid>

                        <Grid item>
                            <PublishOrRequestPriceButton
                                hasNoChanges={this.props.hasNoChanges}
                                isWorkingProjectIncomplete={this.props.isWorkingProjectIncomplete}
                                isPriceOfferIncomplete={this.props.isPriceOfferIncomplete}
                                disabled={hasNoChangesOrIsIncomplete}/>
                        </Grid>

                    </Grid>
                </Grid>
            </>
        );
    }
}

ContractDeleteOrPublishHeader.propTypes = {
    hasNoChanges: PropTypes.bool.isRequired,
    isPriceOfferIncomplete: PropTypes.bool.isRequired,
    isWorkingProjectIncomplete: PropTypes.bool.isRequired,
    priceSheetState: PropTypes.string.isRequired,
    onEffectiveFromChanged: PropTypes.func.isRequired
}

export default injectIntl(withSnackbar(ContractDeleteOrPublishHeader));
