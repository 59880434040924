import {Grid, Icon, IconButton} from "@material-ui/core";
import {cutName} from "../../util/DocumentUtil";
import React from "react";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";

class MessageFilePreview extends React.Component {

    render() {
        const {file, deleteFile, isCreating, intl} = this.props;
        return (
            <Grid item key={file.name}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs className="documentName">
                        {cutName(intl, file.name)}
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={() => deleteFile(file, isCreating)}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

MessageFilePreview.propTypes = {
    file: PropTypes.object.isRequired,
    deleteFile: PropTypes.func.isRequired,
    isCreating: PropTypes.bool.isRequired
};

export default injectIntl(MessageFilePreview);
