import {MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY} from './Constants';

export class TimeSlot {
    constructor(weekday, dayhour, selected) {
        this.weekday = weekday;
        this.dayhour = dayhour;
        this.selected = selected;
    }
}

export function createTimeSlotMap() {
    let timeSlotsMap = new Map();
    let weekdays = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];
    weekdays.forEach(weekday => {
        let timeSlots = []
        for (let dayhour = 0; dayhour < 24; dayhour++) {
            timeSlots.push(new TimeSlot(weekday, dayhour, false));
        }
        timeSlotsMap.set(weekday, timeSlots);
    });

    return timeSlotsMap;
}

export function calculateDeliveryTimesMap(timeSlotsMap) {
        
    let deliveryTimesMap = new Map();

    // key = weekday, value = array of timeslots
    for (let [key, value] of timeSlotsMap.entries()) {
            
        let fromHour = 0;
        let toHour = 0;
        let lastState = false;
        let createNewEntry = false;

        for (let i = 0; i < value.length; i++) {
            let timeSlot = value[i];

            // found start 
            if (timeSlot.selected === true && lastState === false) {
                fromHour = timeSlot.dayhour;
                lastState = true;
            // found end
            } else if (lastState === true && timeSlot.selected === false) {
                createNewEntry = true;
                lastState = false;
            }
            
            // create an entry if we found a complete time intervall or if we started an interval, but have reached the end of the day (whole day was selected).
            if (createNewEntry) {
                toHour = timeSlot.dayhour;
                createEntry(fromHour, toHour, deliveryTimesMap, key);
                createNewEntry = false;
            } else if (lastState === true && i === value.length -1) {
                toHour = timeSlot.dayhour + 1;
                createEntry(fromHour, toHour, deliveryTimesMap, key);
                createNewEntry = false;
            }
        }
    }
        
    return deliveryTimesMap;
}

export function hasAtLeastOnTimeSlot(timeSlots) {
    return Array.from(timeSlots.values()).flatMap(value => value).some(slot => slot.selected);
}

function createEntry(fromHour, toHour, deliveryTimesMap, key) {

    let deliveryTime = {
        fromHour: fromHour,
        toHour: toHour
    }

    let deliveryTimes = deliveryTimesMap.get(key);
    if (typeof deliveryTimes === 'undefined') {
        deliveryTimes = [];
        deliveryTimes.push(deliveryTime);
    } else {
        deliveryTimes.push(deliveryTime);
    }
    deliveryTimesMap.set(key, deliveryTimes);

}

export function createTimeSlotsMapFromDeliveryTimesMap(deliveryTimesMap) {
    let timeSlotsMap = new Map();
    let weekdays = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];
    weekdays.forEach(weekday => {
        let timeSlots = []
        for (let dayhour = 0; dayhour < 24; dayhour++) {
            let contains = isTimeSlotInDayhour(weekday, dayhour, deliveryTimesMap);
            timeSlots.push(new TimeSlot(weekday, dayhour, contains));
        }
        timeSlotsMap.set(weekday, timeSlots);
    });

    return timeSlotsMap;
}

function isTimeSlotInDayhour(weekday, dayhour, deliveryTimesMap) {
    
    if (typeof deliveryTimesMap === 'undefined') {
        return false;
    }

    let currentTimeSlots = deliveryTimesMap.get(weekday);
    // Map is empty or the current weekday has no time slots
    if (typeof currentTimeSlots === 'undefined') {
        return false;
    }
    
    for (let timeSlot of currentTimeSlots) {
        let fromHour = timeSlot.fromHour;
        let toHour = timeSlot.toHour;

        if (dayhour >= fromHour && dayhour < toHour) {
            return true;
        }
    }
    return false;
}