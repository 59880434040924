import Dexie from 'dexie';
import {groupBy, collectMap} from "../util/MapUtil";

export const database = new Dexie('order');

database.version(1).stores({
    user: 'id, version, username, title, firstName, lastName, email, company, vatId, streetAndNumber, zipCode, city, country, phoneNumber, jobTitle, role, activated',
    project: 'id, version, operationId, name, status, createdBy, createdAt, effectiveFrom, exportType, currency',
    business_unit: 'id, version, projectId, name, streetAndNumber, zipCode, city, country, imageId, deliveryType, pickupType, createdBy',
    delivery_address: 'id, version, businessUnitId, projectId, address, description, shortDescription, costCenter, addressType, logisticType',
    delivery_time: 'id++, dayOfWeek, deliveryAddressId, projectId, fromHour, toHour',
    contract_item: 'id++, deliveryAddressId, projectId, articleId, type, price, quantity, articleNumbers',
    article: 'id, version, category, subCategory, articleCategory, description, fabric, size, width, height, certificates',
    article_feature: 'id, articleId, type, value',
    article_image: 'id, version, articleId, imageUrl, thumbnailUrl'
});

database.version(2).stores({
    article_image: null
});

database.version(3).stores({
    article_image: 'parentId, filename, articleId, imageUrl, thumbnailUrl'
});

database.version(4).stores({
    article_feature: null,
    article: 'id, version, category, subCategory, articleCategory, description, filterOptions'
});

database.version(5).stores({
    contract_item: 'id++, deliveryAddressId, projectId, articleId, type, price, quantity, alternateDescription, articleNumbers'
});

database.open().catch (function (err) {
    console.error('Failed to open db: ' + (err.stack || err));
});

export async function storeUserData(userVo) {
    await clearUserData();
    let user = {
        id:              userVo.id,
        version:         userVo.version, 
        username:        userVo.username, 
        title:           userVo.title, 
        firstName:       userVo.firstName, 
        lastName:        userVo.lastName, 
        email:           userVo.email, 
        company:         userVo.company, 
        vatId:           userVo.vatId, 
        streetAndNumber: userVo.streetAndNumber, 
        zipCode:         userVo.zipCode, 
        city:            userVo.city, 
        country:         userVo.country, 
        phoneNumber:     userVo.phoneNumber, 
        jobTitle:        userVo.jobTitle, 
        role:            userVo.role,
        activated:       userVo.activated
    }
    await database.user.add(user);
}

export async function loadUserData() {
    return database.transaction('r', [database.user], async () => {
        return await database.user.toArray()
            .then((users) => {
                if (!users || users.length <= 0) {
                    console.error("user could not be loaded");
                    return null;
                }
                return users[0];
            });
        });
}

export async function storeProject(projectVo) {
    let project = {
        id:             projectVo.id,
        version:        projectVo.version,
        operationId:    projectVo.operationId,
        name:           projectVo.name, 
        status:         projectVo.status, 
        createdBy:      projectVo.createdBy, 
        createdAt:      projectVo.createdAt,
        effectiveFrom:  projectVo.effectiveFrom, 
        exportType:     projectVo.exportType, 
        currency:       projectVo.currency
    }
    await database.project.add(project);

    var businessUnits = [];
    for (const businessUnitVo of projectVo.businessUnits?.units) {
        let businessUnit = { 
            id:                 businessUnitVo.id,
            version:            businessUnitVo.version,
            projectId:          businessUnitVo.projectId,
            name:               businessUnitVo.name,
            streetAndNumber:    businessUnitVo.streetAndNumber,
            zipCode:            businessUnitVo.zipCode,
            city:               businessUnitVo.city,
            country:            businessUnitVo.country,
            imageId:            businessUnitVo.imageId,
            deliveryType:       businessUnitVo.deliveryType,
            pickupType:         businessUnitVo.pickupType,
            createdBy:          businessUnitVo.createdBy
        }
        businessUnits.push(businessUnit);
    }
    await database.business_unit.bulkAdd(businessUnits).then(function(lastKey) {
        //console.log("Last business unit id was: " + lastKey);
    }).catch(Dexie.BulkError, function (e) {
        // Explicitely catching the bulkAdd() operation makes those successful
        // additions commit despite that there were errors.
        console.error(e.failures.length + " business units did not succeed.");
    });
}

export async function loadProject(projectId) {
    return database.transaction('r', [database.project, database.business_unit], async () => {
        let project = await database.project.where({id: projectId}).toArray()
            .then((projects) => {
                if (!projects || projects.length <= 0) {
                    console.error("project " + projectId + " could not be loaded");
                    return null;
                }
                return projects[0];
            });

        if (!project) {
            return null;
        }
        project.businessUnits = {
            units: await database.business_unit.where({ projectId: projectId }).toArray()
                    .then((businessUnits) => {
                        if (!businessUnits) {
                            console.error("business units could not be loaded for project " + projectId);
                            return null;
                        }
                        return businessUnits;
                    })
                };
        return project;
    });
}

export async function loadAllProjects() {
    return database.transaction('r', [database.project, database.business_unit], async () => {
        return await database.project.toArray();
    });
}

export async function storeDeliveryAddresses(projectId, deliveryAddressVos) {
    var deliveryAddresses = [];
    var deliveryTimes = []
    for (const deliveryAddressVo of deliveryAddressVos) { 
        let deliveryAddress = { 
            id:                 deliveryAddressVo.id,
            version:            deliveryAddressVo.version,
            businessUnitId:     deliveryAddressVo.businessUnitId,
            projectId:          projectId,
            address:            deliveryAddressVo.address,
            description:        deliveryAddressVo.description,
            shortDescription:   deliveryAddressVo.shortDescription,
            costCenter:         deliveryAddressVo.costCenter,
            addressType:        deliveryAddressVo.addressType,
            logisticType:       deliveryAddressVo.logisticType,
        }
        deliveryAddresses.push(deliveryAddress);

        for (const [dayOfWeek, deliveryTimeVos] of Object.entries(deliveryAddressVo.deliveryTimes)) {
            for (const deliveryTimeVo of deliveryTimeVos) {
                let deliveryTime = {
                    dayOfWeek:          dayOfWeek, 
                    deliveryAddressId:  deliveryAddressVo.id,
                    projectId:          projectId,
                    fromHour:           deliveryTimeVo.fromHour, 
                    toHour:             deliveryTimeVo.toHour
                }
                deliveryTimes.push(deliveryTime);
            }
        }
    }
    await database.delivery_address.bulkAdd(deliveryAddresses).then(function(lastKey) {
        //console.log("Last delivery address id was: " + lastKey);
    }).catch(Dexie.BulkError, function (e) {
        console.error(e.failures.length + " delivery addresses did not succeed.");
    });

    await database.delivery_time.bulkAdd(deliveryTimes).then(function(lastKey) {
        //console.log("Last delivery time id was: " + lastKey);
    }).catch(Dexie.BulkError, function (e) {
        console.error(e.failures.length + " delivery times did not succeed.");
    });
}

export async function loadDeliveryAddresses(businessUnitId) {
    return database.transaction('r', [database.delivery_address, database.delivery_time], async () => {
        return database.delivery_address.where({businessUnitId: businessUnitId}).toArray()
            .then((deliveryAddresses) => {
                if (!deliveryAddresses) {
                    console.error("delivery addresses could not be loaded for business unit " + businessUnitId);
                    return null;
                }
                for (const deliveryAddress of deliveryAddresses) {
                    deliveryAddress.deliveryTimes = database.delivery_time.where({deliveryAddressId: deliveryAddress.id}).toArray()
                        .then((deliveryTimes) => {
                            if (!deliveryTimes) {
                                console.error("delivery times could not be loaded for delivery address " + deliveryAddress.id);
                                return null;
                            }
                            return groupBy(deliveryTimes, t => t.dayOfWeek);
                        });
                }
                return deliveryAddresses;
            });
    });
}

export async function storeContractItems(projectId, contractItemMap) {
    // use maps to avoid duplicated entries
    let contractItems = [];
    let articles = new Map();
    let articleImages = new Map();
    for (const [deliveryAddressId, contractItemVos] of contractItemMap) {
        for (const contractItemVo of contractItemVos) {
            let contractItem = {
                deliveryAddressId:      deliveryAddressId,
                projectId:              projectId,
                articleId:              contractItemVo.item.articleVo.id,
                type:                   contractItemVo.type,
                price:                  contractItemVo.price,
                quantity:               contractItemVo.item.quantity,
                alternateDescription:   contractItemVo.alternateDescription,
                articleNumbers:         contractItemVo.articleNumbers
            }
            contractItems.push(contractItem);

            let articleVo = contractItemVo.item.articleVo;
            let article = {
                id:                 articleVo.id, 
                version:            articleVo.version,
                category:           articleVo.category,
                subCategory:        articleVo.subCategory ?? articleVo.subcategory,
                articleCategory:    articleVo.articleCategory, 
                description:        articleVo.description, 
                filterOptions:      articleVo.filterOptions
            }
            articles.set(article.id, article);

            for (const imageVo of articleVo.images) {
                let articleImage = {
                    parentId:       imageVo.image.parentId ?? imageVo.thumbnail.key,
                    filename:       imageVo.image.filename && imageVo.thumbnail.filename,
                    articleId:      articleVo.id,
                    imageUrl:       imageVo.image.url, 
                    thumbnailUrl:   imageVo.thumbnail.url
                }
                articleImages.set(articleImage.articleId, articleImage);
            }
        }
    }

    await database.contract_item.bulkAdd(contractItems)
            .then(function(lastKey) {
                //console.log("Last contract item id was: " + lastKey);
            }).catch(Dexie.BulkError, function (e) {
                console.error(e.failures.length + " contract items did not succeed.");
            });

    await database.article.bulkAdd(Array.from(articles.values()))
        .then(function(lastKey) {
            //console.log("Last article id was: " + lastKey);
        }).catch(Dexie.BulkError, function (e) {
            console.error(e.failures.length + " articles did not succeed.");
        });

    await database.article_image.bulkAdd(Array.from(articleImages.values()))
        .then(function(lastKey) {
            //console.log("Last article image id was: " + lastKey);
        }).catch(Dexie.BulkError, function (e) {
            console.error(e.failures.length + " article images did not succeed.");
        });
}

export async function loadContractItems(projectId) {
    return database.transaction('r', [database.contract_item, database.article, database.article_image], async () => {
        let imageMap = await database.article_image.toArray()
            .then((articleImages) => {
                if (!articleImages) {
                    console.error("article images could not be loaded.");
                    return null;
                }
                return groupBy(articleImages, i => i.articleId);
            });

        let articleMap = await database.article.toArray()
            .then((articles) => {
                if (!articles) {
                    console.error("articles could not be loaded.");
                    return null;
                }
                for (var article of articles) {
                    article.images = imageMap.get(article.id);
                }
                return collectMap(articles, a => a.id);
            });

        return database.contract_item.where({projectId: projectId}).toArray()
            .then((contractItems) => {
                if (!contractItems) {
                    console.error("contract items could not be loaded.");
                    return null;
                }
                for (let contractItem of contractItems) {
                    contractItem.item = {
                        quantity: contractItem.quantity,
                        articleVo: articleMap.get(contractItem.articleId)
                    }
                }
                return groupBy(contractItems, i => i.deliveryAddressId);
            });
        });
}

export function resetDatabase() {
    return database.transaction('rw', 
        database.user,
        database.project,
        database.business_unit,
        database.delivery_address,
        database.delivery_time,
        database.contract_item,
        database.article,
        database.article_image,
    async () => {
      await Promise.all(database.tables.map(table => table.clear()));
    });
}

export async function clearProjectData(projectId) {
    database.transaction('rw', 
        database.user,
        database.project,
        database.business_unit,
        database.delivery_address,
        database.delivery_time,
        database.contract_item,
    async () => {
        let businessUnitIds = await database.business_unit.where({projectId: projectId}).toArray()
            .then((list) => { return Array.from(collectMap(list, o => o.id).keys()); });
        let deliveryAddressIds = await database.delivery_address.where({projectId: projectId}).toArray()
            .then((list) => { return Array.from(collectMap(list, o => o.id).keys()); });
        let deliveryTimeIds = await database.delivery_time.where({projectId: projectId}).toArray()
            .then((list) => { return Array.from(collectMap(list, o => o.id).keys()); });
        let contractItemIds = await database.contract_item.where({projectId: projectId}).toArray()
            .then((list) => { return Array.from(collectMap(list, o => o.id).keys()); });

        return await Promise.all(
            database.project.where({id: projectId}).delete(),
            database.business_unit.bulkDelete(businessUnitIds),
            database.delivery_address.bulkDelete(deliveryAddressIds),
            database.delivery_time.bulkDelete(deliveryTimeIds),
            database.contract_item.bulkDelete(contractItemIds)
        );
    });
}

export async function clearUserData() {
    await database.transaction('rw', database.user, 
        async () => { 
            await database.user.clear() 
        });
}
