import { postAsync} from './BackendService';
import { isNotEmptyOrBlank} from '../util/Util';

export function getRentalLinenForDeliveryAddress(context, deliveryAddressId) {
    return context.appData.deliveryAddressIdToRentalLinenMap?.get(deliveryAddressId.toString());
}

/**
 * Loads articles for a given article category
 * @param {*} articleCategory
 * @returns Returns an array of articles for the given category.
 * If the given parameter is empty or null, then an empty array will be returned
 */
export async function loadArticlesByArticleCategory(articleCategory, projectId) {
    if (isNotEmptyOrBlank(articleCategory)) {
        let query = {
            categories: [],
            articleCategories: [articleCategory]
        }

        return loadArticlesByQuery(query, projectId);
    } else {
        return [];
    }
}

/** load all articles for the active project of the user. */
export async function loadAllArticlesForProject(projectId) {
    let query = {
        categories: [],
        articleCategories: []
    }

    return loadArticlesByQuery(query, projectId);
}

export async function loadArticlesByQuery(articleQuery, projectId) {
    let articlesResponse = await postAsync('/article/query/' + projectId, articleQuery);
    if (articlesResponse?.status === 200) {
        return articlesResponse.data;
    } else if ([401, 403].includes(articlesResponse?.status)) {
        return await this.context.logout();
    }
}
