import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {DIALOG_TYPE_WARNING, OPERATION_TYPES} from "../../../../util/Constants";
import EffectiveFromDatePicker from "../components/EffectiveFromDatePicker";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {createErrorMessage, createSuccessMessage} from "../../../../util/Util";
import {getActiveOperation, startWorkingState} from "../../../../services/OperationService";
import {withSnackbar} from "notistack";

class ChangeEffectiveFromButton extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            effectiveFrom: null,
            showDialog: false
        }
    }

    changeEffectiveFrom = async(activeOperation) => {
        await startWorkingState(
            this.context,
            activeOperation,
            activeOperation.workingProject?.id,
            this.state.effectiveFrom,
            () => {
                createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.changeEffectiveFrom.successMessage"}), this.props);
                this.props.onSave();
            },
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.changeEffectiveFrom.errorMessage"}), this.props));
        this.setState({effectiveFrom: null, showChangeEffectiveFromDialog: false});
    }

    render() {
        const activeOperation = getActiveOperation(this.context);

        if (activeOperation?.operationType !== OPERATION_TYPES.ORIGIN_TENDER && !activeOperation.activeProject.effectiveFrom) {
            return null;
        }

        return (
            <>
                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDialog}
                    titleId="cockpit.contractStatus.dialogTitle.changeEffectiveFrom"
                    subtitleId="cockpit.contractStatus.dialogSubtitle.changeEffectiveFrom"
                    cancelId="commons.cancel.button"
                    actionId="commons.confirm.button"
                    style={{}}
                    content={<EffectiveFromDatePicker
                        effectiveFrom={this.state.effectiveFrom}
                        newestEffectiveFrom={this.props.newestEffectiveFrom}
                        onChanged={(newDate) => this.setState({effectiveFrom: newDate})}/>
                    }
                    onCancel={() => this.setState({effectiveFrom: null, showDialog: false})}
                    onAction={() => this.changeEffectiveFrom(activeOperation)}
                    actionDisabled={!this.state.effectiveFrom}/>

                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({effectiveFrom: activeOperation?.workingProject?.effectiveFrom, showDialog: true})}>
                        <FormattedMessage id="cockpit.contractStatus.changeEffectiveFrom.button"/>
                    </Button>
                </div>
            </>
        );
    }
}

ChangeEffectiveFromButton.propTypes = {
    newestEffectiveFrom: PropTypes.number.isRequired,
    onSave: PropTypes.func.isRequired
};

export default injectIntl(withSnackbar(ChangeEffectiveFromButton));
