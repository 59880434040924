import {Component} from "react";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {ResponsiveContainer} from "recharts";
import {injectIntl} from "react-intl";
import { DataGrid } from '@mui/x-data-grid';
import {
    accumulateData,
    buildHeatMap,
    getLegendLabel,
    getNodeColor,
    groupInvoicesByYear
} from "../../../../services/StatisticsService";
import {InvoiceStatisticsBarData} from "./InvoiceStatisticsBarData";
import "../../../../css/InvoiceStatistics.css";
import {formatInteger, formatPrice} from "../../../../util/Util";
import CircleIcon from '@mui/icons-material/Circle';

class InvoiceStatisticsTable extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            activeInvoiceId: null,
            activeKey: null
        }
    }

    handleCellClick = (params) => {
        let invoiceId = parseInt(params.field);
        let key = params.row.start.key;
        this.props.onItemSelected(key, invoiceId);
        this.setState({activeKey: key, activeInvoiceId: invoiceId});
    };

    renderCellContent = (params, key, selectedTab, fontWeight) => {
        const value = params.value.value;
        const color = params.value.color;
        const weight = !fontWeight ? 'normal' : fontWeight;
        return (
            <div
                key={'table-key-cell-' + key}
                style={{
                    fontWeight: weight,
                    backgroundColor: color,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                    paddingRight: '10px'
                }}
            >
                {selectedTab.includes("price")
                    ? formatPrice(value, "€")
                    : formatInteger(value)}
            </div>
        );
    };

    valueComparator = (v1, v2) => v1.value - v2.value;

    buildColumns = () => {
        const {selectedTab, selectedView, sortedInvoices, getXAxisTickLabel} = this.props;
        const columns = [
            {
                field: 'start',
                headerName: '#',
                headerClassName: "statistic-table-header",
                width: 50,
                align: "center",
                sortable: false,
                renderCell: (params) => {
                    const key = params.value.key;
                    const color = params.value.color;
                    return (
                        <div
                            key={'table-key-cell-'+key}
                            style={{
                                color: color,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <CircleIcon/>
                        </div>
                    );
                }
            },
            {
                field: 'name',
                headerName: this.props.intl.formatMessage({ id : 'cockpit.statistics.invoice.item.label'}),
                headerClassName: "statistic-table-header",
                flex: 2
            }
        ];
        if (selectedView === "yearly") {
            Object.keys(groupInvoicesByYear(sortedInvoices)).forEach(year => {
                columns.push({
                    field: year,
                    headerName: year,
                    headerClassName: "statistic-table-header",
                    align: "right",
                    flex: 1,
                    sortComparator: this.valueComparator,
                    renderCell: (params) => this.renderCellContent(params, year, selectedTab)
                })
            })
        } else {
            sortedInvoices.forEach(invoice => {
                columns.push({
                    field: ""+invoice.id,
                    headerName: getXAxisTickLabel(invoice.id),
                    headerClassName: "statistic-table-header",
                    align: "right",
                    flex: 1,
                    sortComparator: this.valueComparator,
                    renderCell: (params) => this.renderCellContent(params, invoice.id, selectedTab)
                })
            })
        }
        if (selectedView !== "monthly.accumulated") {
            columns.push({
                field: 'sum',
                headerName: this.props.intl.formatMessage({ id : 'cockpit.statistics.invoice.sum'}),
                headerClassName: "statistic-table-header",
                flex: 1,
                sortComparator: this.valueComparator,
                renderCell: (params) => this.renderCellContent(params, "sum", selectedTab, "bold")
            })
        }
        return columns;
    }

    buildRows = () => {
        const {
            heatMap, sortedInvoices, statisticItemMap, selectedNodes, selectedTab, selectedView
        } = this.props;

        if (selectedNodes.length === 0 || !sortedInvoices || sortedInvoices.length === 0) {
            return [];
        }

        let chartData;
        const [data, dataKeys, sumMap] = InvoiceStatisticsBarData({
            context: this.context, sortedInvoices, statisticItemMap, selectedNodes, selectedTab, selectedView
        });

        if (selectedView === "monthly.accumulated") {
            chartData = accumulateData(data, dataKeys, "invoiceId");
        } else {
            chartData = data;
        }

        let colorMap = !heatMap ? null : buildHeatMap(chartData, dataKeys, "invoiceId");
        let rows = [];
        let index = 1;
        dataKeys.forEach((key) => {

            let name = getLegendLabel(this.context, selectedNodes, key)
            let color = getNodeColor(selectedNodes, key)
            let row = {
                id: index++,
                start: {key: key, color: color},
                name: "‎ " + name,
                sum: {value : sumMap.get(key)}
            };
            chartData.forEach(data => {
                row[""+data.invoiceId] = {
                    value: data[key],
                    color: colorMap == null ? "transparent" : colorMap[data.invoiceId][key]
                }
            });
            rows.push(row);
        })
        return rows;
    }

    render() {
        return (
            <ResponsiveContainer debounce={300} width="100%" aspect={2.6} minWidth={900}>
                <DataGrid className={"statistics-data-grid"}
                          autoHeight
                          hideFooter={true}
                          rows={this.buildRows()}
                          columns={this.buildColumns()}
                          onCellClick={this.handleCellClick}
                          showCellRightBorder={true}
                          disableColumnMenu={true}
                          getRowClassName={(params) =>
                              params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                          sx={{
                              '.MuiDataGrid-cell': {
                                    padding: 0
                                },
                            }}
                />
            </ResponsiveContainer>);
    }
}

export default injectIntl(InvoiceStatisticsTable);