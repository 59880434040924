import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {Bar, BarChart, CartesianGrid, Cell, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatPrice} from "../../../../util/Util";
import {CategoryStatisticsBarData} from "./CategoryStatisticsBarData";
import {de} from "date-fns/locale";
import {getChartColorByCategory, white} from "../../../../util/ColorTheme";
import {getCockpitStatisticsCategoryLabel} from "../../../../util/Constants";
import {accumulateData} from "../../../../services/StatisticsService";

class CategoryStatisticsBarChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeCategory: null,
            activeTime: null
        }
    }

    yAxisLabel = () => {
        return this.props.intl.formatMessage(
            {id: "cockpit.statistics.categories.yLabel." + this.props.selectedTab + (this.props.accumulated ? ".accumulated" : "")},
            {currency: this.props.currency}
        );
    }

    getXAxisTickLabel = (time) => {
        return new Date(parseInt(time)).toLocaleString(de, {month: "long"}) + " " + new Date(parseInt(time)).getFullYear();
    }

    extrapolationSuffix = (activeTime) => {
        if (activeTime > this.props.maxDate) {
            return " (" + this.props.intl.formatMessage({id: "cockpit.statistics.prediction"}) + ")";
        } else {
            return "";
        }
    }

    render() {
        const {
            accumulated, selectedTab, currency, priceOfferMap, allSelected, workWearSelected, flatLinenSelected,
            customerArticlesSelected, residentsLaundrySelected, operatingResourcesSelected, showNet, minDate
        } = this.props;
        const {
            activeCategory, activeTime
        } = this.state;

        let chartData;

        let [data, dataKeys] = CategoryStatisticsBarData({
            intl: this.props.intl,
            priceOfferMap,
            allSelected,
            workWearSelected,
            flatLinenSelected,
            customerArticlesSelected,
            residentsLaundrySelected,
            operatingResourcesSelected,
            selectedTab,
            showNet,
            activeCategory,
            activeTime,
            minDate,
            onHideTooltip: () => this.setState({activeCategory: null, activeTime: null}),
            onShowTooltip: (activeCategory, payload) => this.setState({activeCategory, activeTime: payload.time})
        });

        if (accumulated) {
            chartData = accumulateData(data, dataKeys, "time");
        } else {
            chartData = data;
        }

        return (
            <ResponsiveContainer debounce={300} width="100%" aspect={2.6} minWidth={900}>
                <BarChart
                    data={chartData}
                    margin={{top: 30, right: 50, bottom: 90}}>

                    <YAxis
                        scale={"auto"}
                        allowDecimals={selectedTab === "price"}
                        domain={[0, "auto"]}
                        tickFormatter={(value) => selectedTab === "price" ? formatPrice(value) : value.toFixed(0)}
                        padding={{top: 20}}
                        width={160}>
                        <Label
                            value={this.yAxisLabel()}
                            angle={-90}/>
                    </YAxis>

                    <XAxis
                        dataKey="time"
                        dy={10}
                        padding={{left: 20, right: 20}}
                        tickFormatter={(time) => this.getXAxisTickLabel(time)}>
                        <Label
                            value={this.props.intl.formatMessage({id: "cockpit.statistics.month"})}
                            offset={30}
                            position='bottom'/>
                    </XAxis>

                    <CartesianGrid stroke="#f5f5f5"/>

                    <Tooltip
                        content={({active, payload}) => active && (
                            <div style={{backgroundColor: white, border: "1px solid grey", padding: 15}}>
                                {!!payload?.length && <div>{this.getXAxisTickLabel(payload[0].payload.time)}</div>}
                                {payload?.map(p => <>
                                    <div>{getCockpitStatisticsCategoryLabel(this.props.intl, p.dataKey) + ": " + (selectedTab === "price"
                                            ? formatPrice(p.payload[p.dataKey]) + " " + currency
                                            : p.payload[p.dataKey])
                                        + this.extrapolationSuffix(activeTime)}
                                    </div>
                                </>)}
                            </div>
                        )}
                    />

                    <Legend
                        align="center"
                        payloadUniqBy={(payload) => payload.dataKey?.includes("pred") ? null : payload.dataKey}
                        wrapperStyle={{position: "relative", paddingTop: 20}}/>

                    {[...dataKeys].map(key => (
                        <Bar
                            key={key + "-bar"}
                            fill={getChartColorByCategory(key)}
                            name={getCockpitStatisticsCategoryLabel(this.props.intl, key)}
                            dataKey={key}>
                            {chartData.map((entry, index) => (
                                <Cell
                                    key={"cell-bar-" + index}
                                    opacity={entry.extrapolated ? 0.5 : 1}
                                />
                            ))}
                        </Bar>
                    ))}

                </BarChart>
            </ResponsiveContainer>
        );
    }
}

export default injectIntl(CategoryStatisticsBarChart);
