import {getAsyncCatch, postAsyncCatch} from "./BackendService";

export async function inviteBiddersToTender(context, props, projectId, bidderIds) {
    return await postAsyncCatch(context, "/project/" + projectId + "/bidder", bidderIds, props, true);
}

export async function getBiddersOfTender(context, props) {
    let projectId = context.appData.offerProjectId ?? context.appData.activeProjectId;
    return await getAsyncCatch(context, "/project/" + projectId + "/bidder", props);
}

export const Role = {
    ADMIN: 'ROLE_ADMIN',
    PROJECT_ADMIN: 'ROLE_PROJECT_ADMIN',
    USER: 'ROLE_USER',
    BIDDER: 'ROLE_BIDDER',
    ORDERER: 'ROLE_ORDERER',
    BIDDER_NEWSLETTER: 'ROLE_BIDDER_NEWSLETTER',
    BIDDER_READ_ONLY: 'ROLE_BIDDER_READ_ONLY'
}

// The admin can use every function. Only he can add users with the PROJECT_ADMIN role to the system.
export function isAdmin(user) {
    return user?.role === Role.ADMIN;
}

// The Project can add new users for his projects. And can create new business units for his projects.
export function isProjectAdmin(user) {
    return user?.role === Role.PROJECT_ADMIN;
}

// Users are not allowed to create business units, projects, and users.
export function isUser(user) {
    return user?.role === Role.USER;
}

// The Bidder is only allowed to see published projects and take part in the bidding process.
export function isBidder(user) {
    return user?.role === Role.BIDDER;
}

// The order user is only allowed to open the Order App
export function isOrderUser(user) {
    return user?.role === Role.ORDERER;
}

export function isBidderNewsletter(user) {
    return user?.role === Role.BIDDER_NEWSLETTER;
}

export function isBidderReadOnly(user) {
    return user?.role === Role.BIDDER_READ_ONLY;
}

export function isOfferUser(user) {
    return isBidder(user) || isBidderReadOnly(user) || isBidderNewsletter(user);
}

// Permissions to use the specific apps

export function mayUseTender(user) {
    return isAdmin(user) || isProjectAdmin(user) || isUser(user);
}

export function mayUseOffer(user) {
    return isAdmin(user) || isBidder(user) || isBidderReadOnly(user);
}

export function mayUseCockpit(user) {
    return isAdmin(user) || isProjectAdmin(user) || isUser(user) || isBidder(user);
}

export function mayUseOrder(user) {
    return isAdmin(user) || isProjectAdmin(user) || isOrderUser(user);
}
