import {Button, TextField, Typography} from "@material-ui/core";
import { Elements, ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { Component } from "react";
import {Checkbox, CircularProgress, Grid} from "@mui/material";
import { FormattedMessage, injectIntl } from "react-intl";
import {createErrorMessage, formatPrice, isTender} from "../../util/Util";
import { GeneralContext } from "../contexts/GeneralContext";
import {withSnackbar} from 'notistack';
import {AGB_ROUTE, LICENSE_TYPES, MIN_SUBSCRIPTION_PRICE_PER_MONTH, PAYMENT_METHOD_TYPES} from "../../util/Constants";
import {
    getActivePaymentMethodType,
    getBillingPeriod,
    getCancelPeriod, getPricePerMonthByPriceOffer,
    getSubscriptionExtension, getSubscriptionPercent,
    getSubscriptionPeriod, sendInvoice
} from "../../services/PaymentService";

class Payment extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            isRequesting: false,
            confirmedAgb: false,
            confirmedNoAnalysis: false,
            priceInEuroPerMonth: 0,
            useMinSubPrice: false,
            accountOwner: null,
            name: null,
            streetAndNumber: null,
            zipCode: null,
            city: null,
            country: null,
            email: null
        };
    }

    async componentDidMount() {
        const priceInEuroPerMonth = await getPricePerMonthByPriceOffer(
            this.context, this.props, this.getLicenseType(), this.props.price);
        this.setState({
            priceInEuroPerMonth: priceInEuroPerMonth >= MIN_SUBSCRIPTION_PRICE_PER_MONTH ? priceInEuroPerMonth : MIN_SUBSCRIPTION_PRICE_PER_MONTH,
            useMinSubPrice: priceInEuroPerMonth < MIN_SUBSCRIPTION_PRICE_PER_MONTH,
            accountOwner: this.context.currentUser.firstName + " " + this.context.currentUser.lastName,
            name: this.context.currentUser.firstName + " " + this.context.currentUser.lastName,
            streetAndNumber: this.context.currentUser.streetAndNumber,
            zipCode: this.context.currentUser.zipCode,
            city: this.context.currentUser.city,
            country: this.context.currentUser.country,
            email: this.context.currentUser.email
        })
    }

    completePayment = async(elements, stripe) => {
        this.setState({isRequesting: true});
        const confirmParams = { payment_method_data: {
                billing_details: {
                    name: this.state.accountOwner,
                    email: this.context.currentUser.email,
                    phone: this.context.currentUser.phoneNumber,
                    address: {
                        line1: this.context.currentUser.streetAndNumber,
                        line2: '-',
                        city: this.context.currentUser.city,
                        country: this.context.currentUser.country,
                        state: '-',
                        postal_code: this.context.currentUser.zipCode
                    }
                }
            }}

        if (this.props.isSetupIntent) {
            const result = await stripe.confirmSetup({elements, redirect: "if_required", confirmParams: confirmParams});
            if (!result || result.setupIntent?.last_setup_error) {
                createErrorMessage(this.props.intl.formatMessage({id: "payment.pay.errorMessage"}), this.props);
                this.setState({isRequesting: false});
            } else if (result.setupIntent?.status === "succeeded" || result.setupIntent?.status === "processing") {
                this.props.onPaymentSuccess(this.getLicenseType());
            } else {
                this.setState({isRequesting: false});
            }
        } else {
            const { error, paymentIntent } = await stripe.confirmPayment({elements, redirect: "if_required", confirmParams: confirmParams});
            if (error) {
                console.log("error", error);
                createErrorMessage(this.props.intl.formatMessage({id: "payment.pay.errorMessage"}), this.props);
                this.setState({isRequesting: false});
            } else if (paymentIntent?.status === "succeeded" || paymentIntent?.status === "processing") {
                this.props.onPaymentSuccess(this.getLicenseType());
            } else {
                this.setState({isRequesting: false});
            }
        }
    }

    getLicenseType = () => {
        return this.props.product?.metadata?.licenseType;
    }

    sendInvoice = async() => {
        this.setState({isRequesting: true});

        let customer = {
            name: this.state.name,
            email: this.state.email,
            address: {
                line1: this.state.streetAndNumber,
                line2: null,
                postalCode: this.state.zipCode,
                city: this.state.city,
                country: this.state.country
            }
        }
        let invoice = await sendInvoice(this.context, this.props, this.getLicenseType(), this.props.operationId, customer)

        if (!!invoice) {
            this.props.onPaymentSuccess(this.getLicenseType());
        } else {
            this.setState({isRequesting: false});
        }
    }

    getTenderPriceAfterCoupon = (coupon) => {
        if (coupon?.percentOff && coupon.percentOff <= 100) {
            return formatPrice(this.props.price.unitAmount/100.0*(1-coupon.percentOff/100.0));
        } else if (coupon?.amountOff && coupon.amountOff <= this.props.price.unitAmount) {
            return formatPrice((this.props.price.unitAmount - coupon.amountOff) / 100.0);
        } else {
            return formatPrice(this.props.price.unitAmount/100.0);
        }
    }

    getOfferPriceAfterCoupon = (coupon) => {
        if (coupon?.percentOff && coupon.percentOff <= 100) {
            return formatPrice(this.props.offerLicensePrice*(1-coupon.percentOff/100.0));
        } else if (coupon?.amountOff && coupon.amountOff / 100.0 <= this.props.offerLicensePrice) {
            return formatPrice(this.props.offerLicensePrice - coupon.amountOff / 100.0);
        } else {
            return formatPrice(this.props.offerLicensePrice);
        }
    }

    getTenderCouponText = (coupon) => {
        if (coupon?.percentOff && coupon.percentOff <= 100) {
            return this.props.intl.formatMessage({id: "payment.coupon.percentOff.text"}, {percentOff: coupon.percentOff});
        } else if (coupon?.amountOff && coupon.amountOff <= this.props.price.unitAmount) {
            return this.props.intl.formatMessage({id: "payment.coupon.amountOff.text"}, {amountOff: coupon.amountOff/100.0, currency: this.props.currency});
        } else {
            return this.props.intl.formatMessage({id: "payment.coupon.noOff.text"});
        }
    }

    getOfferCouponText = (coupon) => {
        if (coupon?.percentOff && coupon.percentOff <= 100) {
            return this.props.intl.formatMessage({id: "payment.coupon.percentOff.text"}, {percentOff: coupon.percentOff});
        } else if (coupon?.amountOff && coupon.amountOff / 100.0 <= this.props.offerLicensePrice) {
            return this.props.intl.formatMessage({id: "payment.coupon.amountOff.text"}, {amountOff: coupon.amountOff/100.0, currency: this.props.currency});
        } else {
            return this.props.intl.formatMessage({id: "payment.coupon.noOff.text"});
        }
    }

    getCooperationCouponText = (coupon) => {
        const monthlyPrice = this.state.priceInEuroPerMonth;
        let text = "";
        if (!coupon) {
            return "";
        } else if (coupon.duration === "forever") {
            if (coupon?.percentOff && coupon.percentOff <= 100) {
                const monthlyPriceAfterCoupon = monthlyPrice * (1-coupon.percentOff/100);
                text = this.props.intl.formatMessage({id: "payment.coupon.percentOff.forever.text"}, {
                    percentOff: coupon.percentOff,
                    currency: this.props.currency,
                    yearlyPriceAfterCoupon: formatPrice(monthlyPriceAfterCoupon*12),
                    monthlyPriceAfterCoupon: formatPrice(monthlyPriceAfterCoupon),
                    freeTrialNotice: this.props.trialMonths
                        ? this.props.intl.formatMessage({id: "payment.coupon.freeTrialNotice"}, {trialMonths: this.props.trialMonths})
                        : ""
                });
            } else if (coupon?.amountOff && coupon.amountOff <= monthlyPrice*100) {
                const monthlyPriceAfterCoupon = monthlyPrice - coupon.amountOff/100;
                text = this.props.intl.formatMessage({id: "payment.coupon.amountOff.forever.text"}, {
                    amountOff: coupon.amountOff/100,
                    currency: this.props.currency,
                    yearlyPriceAfterCoupon: formatPrice(monthlyPriceAfterCoupon*12),
                    monthlyPriceAfterCoupon: formatPrice(monthlyPriceAfterCoupon),
                    freeTrialNotice: this.props.trialMonths
                        ? this.props.intl.formatMessage({id: "payment.coupon.freeTrialNotice"}, {trialMonths: this.props.trialMonths})
                        : ""
                });
            } else {
                text = this.props.intl.formatMessage({id: "payment.coupon.noOff.text"});
            }
        } else if (coupon.duration === "repeating") {
            if (coupon?.percentOff && coupon.percentOff <= 100) {
                const monthlyPriceAfterCoupon = monthlyPrice * (1-coupon.percentOff/100);
                text = this.props.intl.formatMessage({id: "payment.coupon.percentOff.repeating.text"}, {
                    percentOff: coupon.percentOff,
                    currency: this.props.currency,
                    durationMonths: coupon.durationInMonths,
                    reducedPriceFrom: 1 + (this.props.trialMonths ?? 0),
                    reducedPriceTo: coupon.durationInMonths + (this.props.trialMonths ?? 0),
                    normalPriceFrom: coupon.durationInMonths + (this.props.trialMonths ?? 0) + 1,
                    monthlyPrice: formatPrice(monthlyPrice),
                    monthlyPriceAfterCoupon: formatPrice(monthlyPriceAfterCoupon),
                    freeTrialNotice: this.props.trialMonths
                        ? this.props.intl.formatMessage({id: "payment.coupon.freeTrialNotice"}, {trialMonths: this.props.trialMonths})
                        : ""
                });
            } else if (coupon?.amountOff && coupon.amountOff <= monthlyPrice*100) {
                const monthlyPriceAfterCoupon = monthlyPrice - coupon.amountOff/100;
                text = this.props.intl.formatMessage({id: "payment.coupon.amountOff.repeating.text"}, {
                    amountOff: coupon.amountOff/100,
                    currency: this.props.currency,
                    durationMonths: coupon.durationInMonths,
                    reducedPriceFrom: 1 + (this.props.trialMonths ?? 0),
                    reducedPriceTo: coupon.durationInMonths + (this.props.trialMonths ?? 0),
                    normalPriceFrom: coupon.durationInMonths + (this.props.trialMonths ?? 0) + 1,
                    monthlyPrice: formatPrice(monthlyPrice),
                    monthlyPriceAfterCoupon: formatPrice(monthlyPriceAfterCoupon),
                    freeTrialNotice: this.props.trialMonths
                        ? this.props.intl.formatMessage({id: "payment.coupon.freeTrialNotice"}, {trialMonths: this.props.trialMonths})
                        : ""
                });
            } else {
                text = this.props.intl.formatMessage({id: "payment.coupon.noOff.text"});
            }
        } else if (coupon.duration === "once") {
            if (coupon?.percentOff && coupon.percentOff <= 100) {
                const monthlyPriceAfterCoupon = monthlyPrice * (1-coupon.percentOff/100);
                text = this.props.intl.formatMessage({id: "payment.coupon.percentOff.once.text"}, {
                    percentOff: coupon.percentOff,
                    currency: this.props.currency,
                    reducedPriceFrom: 1 + (this.props.trialMonths ?? 0),
                    normalPriceFrom: 1 + (this.props.trialMonths ?? 0) + 1,
                    monthlyPrice: formatPrice(monthlyPrice),
                    monthlyPriceAfterCoupon: formatPrice(monthlyPriceAfterCoupon),
                    freeTrialNotice: this.props.trialMonths
                        ? this.props.intl.formatMessage({id: "payment.coupon.freeTrialNotice"}, {trialMonths: this.props.trialMonths})
                        : ""
                });
            } else if (coupon?.amountOff && coupon.amountOff <= monthlyPrice*100) {
                const monthlyPriceAfterCoupon = monthlyPrice - coupon.amountOff/100;
                text = this.props.intl.formatMessage({id: "payment.coupon.amountOff.once.text"}, {
                    amountOff: coupon.amountOff/100,
                    currency: this.props.currency,
                    reducedPriceFrom: 1 + (this.props.trialMonths ?? 0),
                    normalPriceFrom: 1 + (this.props.trialMonths ?? 0) + 1,
                    monthlyPrice: formatPrice(monthlyPrice),
                    monthlyPriceAfterCoupon: formatPrice(monthlyPriceAfterCoupon),
                    freeTrialNotice: this.props.trialMonths
                        ? this.props.intl.formatMessage({id: "payment.coupon.freeTrialNotice"}, {trialMonths: this.props.trialMonths})
                        : ""
                });
            } else {
                text = this.props.intl.formatMessage({id: "payment.coupon.noOff.text"});
            }
        }
        return "\n\n" + text;
    }

    getText = (methodType) => {
        if (this.getLicenseType() === LICENSE_TYPES.TENDER_LICENSE) {
            return this.getTenderText(methodType);
        } else if (this.getLicenseType() === LICENSE_TYPES.OFFER_LICENSE) {
            return this.getOfferText(methodType);
        } else if (this.getLicenseType() === LICENSE_TYPES.COOPERATION_LICENSE) {
            return this.getCooperationText(methodType);
        }
    }

    getTenderText = (methodType) => {
        const coupon = this.props.coupon;
        return (
            <FormattedMessage
                id={coupon ? "payment.tender.dialog." + methodType + ".withCoupon.subtitle"
                           : "payment.tender.dialog." + methodType + ".subtitle"}
                values={{
                    tenderPrice: formatPrice(this.props.price.unitAmount/100),
                    couponText: this.getTenderCouponText(coupon),
                    currency: this.props.currency,
                    tenderPriceAfterCoupon: this.getTenderPriceAfterCoupon(coupon),
                }}
            />
        );
    }

    getOfferText = (methodType) => {
        const coupon = this.props.coupon;
        return (
            <FormattedMessage
                id={coupon ? "payment.offer.dialog." + methodType + ".withCoupon.subtitle"
                           : "payment.offer.dialog." + methodType + ".subtitle"}
                values={{
                    offerPrice: formatPrice(this.props.offerLicensePrice),
                    couponText: this.getOfferCouponText(coupon),
                    currency: this.props.currency,
                    offerPriceAfterCoupon: this.getOfferPriceAfterCoupon(coupon),
                }}
            />
        );
    }

    getCooperationText = (methodType) => {
        const coupon = this.props.coupon;
        const textId = isTender() ? "payment.subscribe.dialog." + methodType + ".subtitle.tender"
                                         : "payment.subscribe.dialog." + methodType + ".subtitle.project";
        let costNotice;
        if (coupon && this.state.useMinSubPrice) {
            costNotice = "payment.subscribe.minPrice.notice.withCoupon";
        } else if (coupon && !this.state.useMinSubPrice) {
            costNotice = "payment.subscribe.price.notice.withCoupon";
        } else if (!coupon && this.state.useMinSubPrice) {
            costNotice = "payment.subscribe.minPrice.notice";
        } else {
            costNotice = "payment.subscribe.price.notice";
        }
        return (
            <FormattedMessage
                id={textId}
                values={{
                    percent: getSubscriptionPercent(this.props.price),
                    freeTrialBillingNotice: this.props.trialMonths
                        ? this.props.intl.formatMessage({id: "payment.subscribe.freeTrialBillingNotice"}) : "",
                    billingPeriod: getBillingPeriod(this.props.intl, this.props.price),
                    subscriptionPeriod: getSubscriptionPeriod(this.props.intl, this.props.price),
                    cancelPeriod: getCancelPeriod(this.props.intl, this.props.price),
                    extension: getSubscriptionExtension(this.props.intl, this.props.price),
                    costNotice: this.state.priceInEuroPerMonth
                        ? this.props.intl.formatMessage({id: costNotice},
                            {
                                monthlyPrice: formatPrice(this.state.priceInEuroPerMonth),
                                yearlyPrice: formatPrice(this.state.priceInEuroPerMonth*12.0),
                                percent: getSubscriptionPercent(this.props.price),
                                monthlyMinPrice: formatPrice(MIN_SUBSCRIPTION_PRICE_PER_MONTH),
                                yearlyMinPrice: formatPrice(MIN_SUBSCRIPTION_PRICE_PER_MONTH*12),
                                currency: this.props.currency,
                                threshold: getSubscriptionPercent(this.props.price) !== 0
                                    ? formatPrice(this.state.priceInEuroPerMonth*12.0*100/getSubscriptionPercent(this.props.price))
                                    : "",
                                couponNotice: this.getCooperationCouponText(coupon),
                                noCouponFreeTrialNotice: this.props.trialMonths
                                    ? "\n\n" + this.props.intl.formatMessage({id: "payment.coupon.freeTrialNotice"}, {trialMonths: this.props.trialMonths})
                                    : ""
                            })
                        : "",
                    currency: this.props.currency
                }}
            />
        );
    }

    getBillingAddressForm = () => {
        return <>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>

                <Grid item xs={2} style={{wordBreak: "break-all"}}>
                    <Typography variant="subtitle2" style={{marginBottom: "0px"}}>
                        <FormattedMessage id="payment.form.address.name"/>*:
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <TextField
                        id="accountOwner"
                        required
                        value={this.state.name}
                        variant="outlined"
                        inputProps={{maxLength: 64}}
                        style={{width: "100%"}}
                        onChange={(e) => this.setState({name: e.target.value})}/>
                </Grid>

                <Grid item xs={2} style={{wordBreak: "break-all"}}>
                    <Typography variant="subtitle2" style={{marginBottom: "0px"}}>
                        <FormattedMessage id="payment.form.address.streetAndNumber"/>*:
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="streetAndNumber"
                        required
                        value={this.state.streetAndNumber}
                        variant="outlined"
                        inputProps={{maxLength: 64}}
                        style={{width: "100%"}}
                        onChange={(e) => this.setState({streetAndNumber: e.target.value})}/>
                </Grid>

                <Grid item xs={2} style={{wordBreak: "break-all"}}>
                    <Typography variant="subtitle2" style={{marginBottom: "0px"}}>
                        <FormattedMessage id="payment.form.address.zipCode"/>*:
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="zipCode"
                        required
                        value={this.state.zipCode}
                        variant="outlined"
                        inputProps={{maxLength: 64}}
                        style={{width: "100%"}}
                        onChange={(e) => this.setState({zipCode: e.target.value})}/>
                </Grid>

                <Grid item xs={2} style={{wordBreak: "break-all"}}>
                    <Typography variant="subtitle2" style={{marginBottom: "0px"}}>
                        <FormattedMessage id="payment.form.address.city"/>*:
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="city"
                        required
                        value={this.state.city}
                        variant="outlined"
                        inputProps={{maxLength: 64}}
                        style={{width: "100%"}}
                        onChange={(e) => this.setState({city: e.target.value})}/>
                </Grid>

                <Grid item xs={2} style={{wordBreak: "break-all"}}>
                    <Typography variant="subtitle2" style={{marginBottom: "0px"}}>
                        <FormattedMessage id="payment.form.address.country"/>*:
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="country"
                        required
                        value={this.props.intl.formatMessage({"id": "constants.country.de"})}
                        variant="outlined"
                        inputProps={{maxLength: 64}}
                        style={{width: "100%"}}
                        disabled={true}
                        aria-readonly={true}/>
                </Grid>

                <Grid item xs={2} style={{wordBreak: "break-all"}}>
                    <Typography variant="subtitle2" style={{marginBottom: "0px"}}>
                        <FormattedMessage id="payment.form.address.email"/>*:
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="email"
                        required
                        value={this.state.email}
                        variant="outlined"
                        inputProps={{maxLength: 64}}
                        style={{width: "100%"}}
                        onChange={(e) => this.setState({email: e.target.value})}/>
                </Grid>
            </Grid>

            {this.getAgbSection()}
            {this.getAnalysisSection()}

            <div style={{textAlign: "right", marginTop: "40px"}}>
                {this.getCancelButton()}
                <Button
                    variant="contained"
                    color="primary"
                    style={{marginLeft: "10px"}}
                    disabled={this.state.isRequesting || !this.isPaymentConfirmed() || !this.isBillingAddressValid()}
                    onClick={() => this.sendInvoice()}>
                    {this.state.isRequesting
                        ? <CircularProgress size={20} style={{color: "white"}}/>
                        : <FormattedMessage id="payment.pay.button"/>}
                </Button>
            </div>
        </>
    }

    isPaymentConfirmed = () => {
        return this.state.confirmedAgb && (this.state.confirmedNoAnalysis ||
            this.context.operationEvents?.hasAnalysisBooked ||
            this.getLicenseType() !== LICENSE_TYPES.TENDER_LICENSE)
    }

    isBillingAddressValid = () => {
        return this.state.name &&
            this.state.streetAndNumber &&
            this.state.zipCode &&
            this.state.city &&
            this.state.country &&
            this.state.email
    }

    getAgbSection = () => {
        return <Grid container alignItems={"center"} style={{marginTop: 15}}>
            <Grid item>
                <Checkbox
                    color="primary"
                    checked={this.state.confirmedAgb}
                    onClick={() => this.setState({confirmedAgb: !this.state.confirmedAgb})}/>
            </Grid>
            <Grid item style={{marginLeft: 12}}>
                <FormattedMessage id="payment.subscribe.dialog.confirmAgb"/>
            </Grid>
            <Grid item xs style={{marginLeft: 12}}>
                {/* eslint-disable-next-line */}
                <a href={AGB_ROUTE} target="_blank" rel="noreferrer">
                    <FormattedMessage id="payment.agb.link"/>
                </a>
            </Grid>
        </Grid>
    }

    getAnalysisSection = () => {
        return <>{!this.context.operationEvents?.hasAnalysisBooked &&
                  this.getLicenseType() === LICENSE_TYPES.TENDER_LICENSE &&
            <Grid container alignItems={"center"}>
                <Grid item>
                    <Checkbox
                        color="primary"
                        checked={this.state.confirmedNoAnalysis}
                        onClick={() => this.setState({confirmedNoAnalysis: !this.state.confirmedNoAnalysis})}/>
                </Grid>
                <Grid item style={{marginLeft: 12}}>
                    <FormattedMessage id="payment.subscribe.dialog.confirmNoAnalysis"/>
                </Grid>
            </Grid>
        }</>
    }

    getCancelButton = () => {
        return <>
                {!this.state.isRequesting && <Button
                    variant="contained"
                    color="secondary"
                    style={{marginLeft: "10px"}}
                    onClick={() => this.props.onPaymentCancel()}>
                    <FormattedMessage id="commons.cancel.payment.button"/>
                </Button>}
            </>
    }

    getPaymentForm = () => {
        return <Elements
            stripe={this.context.appData.stripe}
            options={{clientSecret: this.props.clientSecret}}>
            <ElementsConsumer>
                {({elements, stripe}) => (<>

                    <div style={{marginTop: 15, marginBottom: 15}}>
                        <Typography variant="subtitle2" style={{marginBottom: "0px"}}>
                            <FormattedMessage id="payment.subscribe.dialog.accountowner.title"/>
                        </Typography>
                        <TextField
                            id="accountOwner"
                            required
                            value={this.state.accountOwner}
                            variant="outlined"
                            inputProps={{maxLength: 64}}
                            style={{width: "100%"}}
                            onChange={(e) => this.setState({accountOwner: e.target.value})}/>
                    </div>

                    <PaymentElement options={{
                        paymentMethodOrder: ["sepa_debit", "card"],
                        fields: {billingDetails: "never"}
                    }}/>

                    {this.getAgbSection()}
                    {this.getAnalysisSection()}

                    <div style={{textAlign: "right", marginTop: "40px"}}>
                        {this.getCancelButton()}
                        <Button
                            variant="contained"
                            color="primary"
                            style={{marginLeft: "10px"}}
                            disabled={this.state.isRequesting || !this.isPaymentConfirmed() || !this.state.accountOwner}
                            onClick={() => this.completePayment(elements, stripe)}>
                            {this.state.isRequesting
                                ? <CircularProgress size={20} style={{color: "white"}}/>
                                : <FormattedMessage id="payment.pay.button"/>}
                        </Button>
                    </div>
                </>)}
            </ElementsConsumer>
        </Elements>
    }

    render() {
        const methodType = getActivePaymentMethodType(this.context, this.getLicenseType());

        if ((!this.props.clientSecret && methodType === PAYMENT_METHOD_TYPES.SEPA_OR_CARD)
            || !this.getLicenseType()
            || !this.props.price) {
            return (
                <>
                    <Typography variant="subtitle2">
                        <FormattedMessage id="payment.missingClientSecret.subtitle"/>
                    </Typography>

                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{marginLeft: "10px"}}
                            onClick={() => this.props.onPaymentCancel()}>
                            <FormattedMessage id="commons.close.label"/>
                        </Button>
                    </div>
                </>
            );
        }

        return (
            <div>
                <Typography variant="subtitle2" style={{whiteSpace: "pre-line"}}>
                    {this.getText(methodType)}
                </Typography>

                {methodType === PAYMENT_METHOD_TYPES.SEPA_OR_CARD
                    ? this.getPaymentForm()
                    : this.getBillingAddressForm()
                }
            </div>
        );
    }
}

export default injectIntl(withSnackbar(Payment));
