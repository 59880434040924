import React, {Component} from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import {PROJECT_TASKS} from "../../util/Constants";
import TenderTile from "./TenderTile";
import LogisticsTiles from "./LogisticsTiles";
import {Grid} from "@material-ui/core";
import {withSnackbar} from "notistack";
import '../../css/Validation.css';

class BusinessUnitTiles extends Component {

    static contextType = GeneralContext;

    onClicked = (projectTask) => {
        this.props.onTileClicked(projectTask, {projectId: this.props.projectId, businessUnitId: this.props.businessUnit.id});
    }

    onLogisticsClicked = (projectTask, deliveryAddressId) => {
        this.props.onTileClicked(projectTask,
            {projectId: this.props.projectId, businessUnitId: this.props.businessUnit.id, deliveryAddressId: deliveryAddressId});
    }

    render() {
        if (!this.props.businessUnit) {
            return null;
        }
        return (
            <>

                <Grid item>

                    <Grid container>

                        <TenderTile
                            projectTask={PROJECT_TASKS.BUSINESS_UNIT}
                            objectId={this.props.businessUnit.id}
                            concreteTitle={this.props.businessUnit.name}
                            onTileClicked={() => this.onClicked(PROJECT_TASKS.BUSINESS_UNIT)}/>

                        <Grid item xs>
                            <Grid container className={"businessUnitGrid"} >

                                {[
                                    PROJECT_TASKS.RENTAL_LINEN,
                                    PROJECT_TASKS.CUSTOMER_ARTICLES,
                                    PROJECT_TASKS.RESIDENTS_LAUNDRY,
                                    PROJECT_TASKS.OPERATING_RESOURCES,
                                    PROJECT_TASKS.SPECIAL_SERVICES,
                                    PROJECT_TASKS.OFFSETTING
                                ].map(projectTask => <TenderTile
                                    class={"tileUnitCard"}
                                    projectTask={projectTask}
                                    objectId={this.props.businessUnit.id}
                                    onTileClicked={() => this.onClicked(projectTask)}/>)
                                }

                                <LogisticsTiles
                                    key={"logistic-tile-" + this.props.businessUnit.id}
                                    businessUnit={this.props.businessUnit}
                                    onTileClicked={(projectTask, deliveryAddressId) => this.onLogisticsClicked(projectTask, deliveryAddressId)}/>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>

            </>
        );
    }
}

export default withSnackbar(injectIntl(BusinessUnitTiles));
