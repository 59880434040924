import { Component } from "react";
import { GeneralContext } from "../../contexts/GeneralContext";
import IconButton from "@material-ui/core/IconButton";
import {white} from "../../../util/ColorTheme";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {injectIntl} from "react-intl";
import {Tooltip} from "@mui/material";

class SupportMenu extends Component {

    static contextType = GeneralContext;

    render() {
        return (
            <Tooltip title={this.props.intl.formatMessage({id: "support.icon.tooltip"})}>
                <IconButton
                    style={{color: this.props.color ?? white}}
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => this.context.handleSupportOverlay()}>
                    <SupportAgentIcon/>
                </IconButton>
            </Tooltip>
        );
    }
}

export default injectIntl(SupportMenu);
