import React, {Component} from 'react';
import {getSelectableBaseDataMonths, getSelectableBaseDataQuarters, getSelectableBaseDataYears, isMonthInQuarter} from "../../../../services/StatisticsService";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {getMonthValue, INTERVAL_TYPE_KEY, INTERVAL_TYPES} from "../../../../util/Constants";
import {GeneralContext} from "../../../contexts/GeneralContext";
import BaseDataTimeSelection from "./BaseDataTimeSelection";

class BaseDataCopyDialog extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            monthToCopy: null,
            quarterToCopy: null,
            yearToCopy: null
        }
    }

    copyData = () => {
        const intervalType = this.context.getUserStateValue(INTERVAL_TYPE_KEY);
        let data = [];
        if (intervalType === INTERVAL_TYPES.MONTHLY) {
            data = this.context.appData.baseData.filter(bd => bd.year === this.state.yearToCopy && bd.month === getMonthValue(this.state.monthToCopy));
        } else if (intervalType === INTERVAL_TYPES.QUARTERLY) {
            data = this.context.appData.baseData.filter(bd => bd.year === this.state.yearToCopy && isMonthInQuarter(bd.month, this.state.quarterToCopy));
        } else if (intervalType === INTERVAL_TYPES.YEARLY) {
            data = this.context.appData.baseData.filter(bd => bd.year === this.state.yearToCopy);
        }
        let careDays = 0;
        let inpatientCases = 0;
        let workWearPeople = 0;
        let bedsByStation = new Map();
        for (let d of data) {
            careDays += d.careDays;
            inpatientCases += d.inpatientCases;
            workWearPeople += d.workWearPeople;
            if (d.bedsByStation && !!d.bedsByStation.size) {
                for (let [stationId, count] of d.bedsByStation) {
                    if (bedsByStation.get(stationId)) {
                        let currentCount = bedsByStation.get(stationId);
                        bedsByStation.set(stationId, currentCount + count);
                    } else {
                        bedsByStation.set(stationId, count);
                    }
                }
            }
        }
        this.props.onConfirm(careDays, inpatientCases, workWearPeople, bedsByStation);
        this.setState({monthToCopy: null, quarterToCopy: null, yearToCopy: null});
        this.props.onClose();
    }

    canCopy = () => {
        const intervalType = this.context.getUserStateValue(INTERVAL_TYPE_KEY);
        if (intervalType === INTERVAL_TYPES.YEARLY) {
            return !!this.state.yearToCopy;
        } else if (intervalType === INTERVAL_TYPES.MONTHLY) {
            return !!this.state.yearToCopy && !!this.state.monthToCopy;
        } else if (intervalType === INTERVAL_TYPES.QUARTERLY) {
            return !!this.state.yearToCopy && !!this.state.quarterToCopy;
        }
    }


    render() {
        return (
            <TexisionDialog
                open={this.props.open}
                style={{zIndex: 1300}}
                size="sm"
                titleId="cockpit.statistics.baseData.copy.dialog.title"
                subtitleId="cockpit.statistics.baseData.copy.dialog.subtitle"
                actionId="commons.confirm.button"
                cancelId="commons.cancel.button"
                onAction={() => this.copyData()}
                actionDisabled={!this.canCopy()}
                onCancel={() => {
                    this.setState({monthToCopy: null, quarterToCopy: null, yearToCopy: null});
                    this.props.onClose();
                }}
                content={<BaseDataTimeSelection
                    monthValue={this.state.monthToCopy}
                    months={getSelectableBaseDataMonths(this.context, this.state.yearToCopy)}
                    onMonthChange={(monthToCopy) => this.setState({monthToCopy})}
                    quarterValue={this.state.quarterToCopy}
                    quarters={getSelectableBaseDataQuarters(this.context, this.state.yearToCopy)}
                    onQuarterChange={(quarterToCopy) => this.setState({quarterToCopy})}
                    yearValue={this.state.yearToCopy}
                    years={getSelectableBaseDataYears(this.context)}
                    onYearChange={(yearToCopy) => this.setState({yearToCopy})}
                    copy={true}
                />}/>
        );
    }
}

export default BaseDataCopyDialog;
