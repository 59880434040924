import React from "react";
import FooterWrapper from "../FooterWrapper";
import PropTypes from "prop-types";
import ArticleAndLaundryOffsettings from "../../../businessUnit/offsetting/ArticleAndLaundryOffsettings";
import ResourceOffsettings from "../../../businessUnit/offsetting/ResourceOffsettings";
import {getBusinessUnitVersion} from "../../../../services/BusinessUnitService";
import {loadOperatingResourcesAndOffsettingMap, saveResourceOffsettings} from "../../../../services/OperatingResourceService";
import {createErrorMessage, createSuccessMessage} from "../../../../util/Util";
import {injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {ARTICLE_OFFSETTINGS, RESOURCE_OFFSETTINGS} from "../../../../util/Constants";

class OffsettingsPage extends React.Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            unitVersion: 0,
            operatingResources: [],
            oldResourcesOffsettingMap: new Map(),
            resourceOffsettings: [],
            rentalLinenOffsettings: [],
            rentalLinenOffsettingsLoaded: false
        }
    }

    async componentDidMount() {
        await Promise.all([
            this.updateBusinessUnitVersion(),
            this.loadResourceData()
        ]);
    }

    invalidData = () => {
        return Array.from(this.state.oldResourcesOffsettingMap.values()).includes(RESOURCE_OFFSETTINGS.NONE)
            || !!this.state.rentalLinenOffsettings.find(r => r.articleCategoryOffsetting === ARTICLE_OFFSETTINGS.NO_OFFSETTING_SELECTED);
    }

    updateBusinessUnitVersion = async() => {
        this.setState({unitVersion: await getBusinessUnitVersion(this.context, this.props, this.props.unitId)});
    }

    loadResourceData = async() => {
        const resourcesAndOffsettingMap = await loadOperatingResourcesAndOffsettingMap(this.context, this.props, this.props.unitId);
        this.setState({
            operatingResources: resourcesAndOffsettingMap.operatingResources,
            oldResourcesOffsettingMap: resourcesAndOffsettingMap.oldResourcesOffsettingMap
        });
    }

    saveResourceOffsettings = async(newResourcesOffsettingMap) => {
        return await saveResourceOffsettings(
            this.context, this.props,
            this.state.oldResourcesOffsettingMap, newResourcesOffsettingMap, this.state.operatingResources,
            this.onResourceOffsettingSuccess, this.onResourceOffsettingError, () => null,
            this.props.projectId
        );
    }

    onResourceOffsettingSuccess = async() => {
        createSuccessMessage(this.props.intl.formatMessage({id: "operatingResources.offsetting.update.successMessage"}), this.props);
        await Promise.all([
            this.loadResourceData(),
            this.updateBusinessUnitVersion()
        ]);
    }

    onResourceOffsettingError = async() => {
        createErrorMessage(this.props.intl.formatMessage({id: "operatingResources.offsetting.update.errorMessage"}), this.props);
    }

    render() {
        return (
            <FooterWrapper
                invalidData={this.invalidData()}
                isLoading={!this.state.rentalLinenOffsettingsLoaded}
                onContinue={() => this.props.onContinue(this.state.rentalLinenOffsettings, this.state.resourceOffsettings)}
                onCancel={this.props.onCancel}
                onBack={this.props.onBack}>
                <ArticleAndLaundryOffsettings
                    projectId={this.props.projectId}
                    unitId={this.props.unitId}
                    readOnly={false}
                    fromInvoices={true}
                    residentsLaundry={this.props.residentsLaundry}
                    onOffsettingChange={(offsettingVo) => this.setState({
                        rentalLinenOffsettings: offsettingVo.articleOffsetting,
                        rentalLinenOffsettingsLoaded: true
                    })}
                />
                {!!this.state.operatingResources?.length && <ResourceOffsettings
                    readOnly={false}
                    operatingResources={this.state.operatingResources}
                    oldResourcesOffsettingMap={this.state.oldResourcesOffsettingMap}
                    onSave={async(newResourcesOffsettingMap) => this.setState({
                        resourceOffsettings: await this.saveResourceOffsettings(newResourcesOffsettingMap)
                    })}
                />}
            </FooterWrapper>
        );
    }
}

OffsettingsPage.propTypes = {
    onBack: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    residentsLaundry: PropTypes.array.isRequired,
    unitId: PropTypes.number.isRequired
};

export default withSnackbar(injectIntl(OffsettingsPage));
