import { Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import '../apps/App.css';

const Header = (
    {
        titleVariant = "h1", 
        subtitleVariant = "subtitle1", 
        titleId, 
        subtitleId
    }) => (
    <>
        {titleId && (
            <Typography className="headerTitle" variant={titleVariant}>
                <FormattedMessage id={titleId}/>
            </Typography>
        )}
        {subtitleId && (
            <Typography className="headerSubtitle" variant={subtitleVariant}>
                <FormattedMessage id={subtitleId}/>
            </Typography>
        )}
    </>
);

export {
    Header
};
