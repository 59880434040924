import {injectIntl} from "react-intl";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import PropTypes from "prop-types";
import React from "react";
import {Step, StepLabel, Stepper} from "@material-ui/core";
import {deleteAsyncCatch, postAsyncCatch, putAsyncCatch} from "../../../services/BackendService";
import BaseDataPage from "./pages/BaseDataPage";
import ContractPage from "./pages/ContractPage";
import BusinessUnitPage from "./pages/BusinessUnitPage";
import InvoicesPage from "./pages/InvoicesPage";
import OffsettingsPage from "./pages/OffsettingsPage";
import {withSnackbar} from "notistack";

import {
    CATEGORY_WORK_WEAR, DELIVERED_QUANTITY,
    DELIVERY_PICKUP, FLAT_RATE, GENDER_UNISEX, LAST_OPERATION_ID,
    LOGISTIC_TYPE_DECENTRAL,
    MONDAY, ORDER_APP, ORDER_DASHBOARD_ROUTE, PriceType,
    PROFESSIONAL_GROUP_TYPE_PERSONAL, RESOURCE_OFFSETTINGS, SPLIT_PRICE
} from "../../../util/Constants";
import {calculateDeliveryTimesMap, createTimeSlotMap, TimeSlot} from "../../../util/LogisticsUtil";
import InvoiceArticlePage from "./pages/InvoiceArticlePage";
import {createOrGetArticle, getFirstFilterOptions} from "../../../services/ArticleConfigurationService";
import PriceSheetPage from "./pages/PriceSheetPage";
import {createCustomerArticle, updateCustomerArticle} from "../../../services/CustomerArticleService";
import {getResidentsLaundryForUnit, updateResidentsLaundry} from "../../../services/ResidentsLaundryService";
import {createBusinessUnitResource, updateUnitResourceCount} from "../../../services/OperatingResourceService";
import {getQuantities, saveQuantities} from "../../../services/DeliveryQuantityService";
import {GeneralContext} from "../../contexts/GeneralContext";
import {clearBusinessUnitArticles} from "../../../services/BusinessUnitService";
import {createArea} from "../../../services/AreaService";
import {createProfessionalGroup} from "../../../services/ProfessionalGroupService";
import {updateInvoice} from "../../../services/InvoiceService";
import history from "../../navigation/shared/History";
import {createErrorMessage, createWarnMessage} from "../../../util/Util";
import {acceptWorkingState} from "../../../services/OperationService";

class ProjectCreateWithInvoicesDialog extends React.Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = this.getEmptyState();
    }

    getEmptyState = () => {
        return {
            isLoading: false,
            step: 0,
            allProfiles: this.props.allProfiles,
            createdOperation: null,
            createdBusinessUnit: null,
            createdContract: null,
            createdDeliveryAddress: null,
            createdPickupAddress: null,
            createdArea: null,
            createdProfessionalGroup: null,
            invoices: [],
            articleMapping: new Map(),
            priceSheet: null
        };
    }

    componentDidMount() {
        this.setState({ allProfiles: this.props.allProfiles});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.allProfiles !== this.props.allProfiles) {
            this.setState({ allProfiles: this.props.allProfiles});
        }
    }

    getProjectId = () => {
        return this.state.createdOperation?.workingProject?.id;
    }

    onContinue = (success) => {
        if (success) {
            this.setState({step: this.state.step + 1});
        }
    }

    onBack = () => {
        this.setState({step: this.state.step - 1});
    }

    onCancel = async() => {
        if (this.state.createdOperation) {
            await deleteAsyncCatch(this.context, "/operation/" + this.state.createdOperation.id, this.props, null, true);
            await this.context.reloadAppData();
        }
        this.setState(this.getEmptyState());
        this.props.onCancel();
    }


    createDeliveryAddress = async(businessUnit) => {
        if (!this.state.createdDeliveryAddress) {
            let timeSlotMap = createTimeSlotMap();
            timeSlotMap.get(MONDAY)[0] = new TimeSlot(MONDAY, 0, true);
            let deliveryTimes = Object.fromEntries(calculateDeliveryTimesMap(timeSlotMap));

            let address = {
                businessUnitId: businessUnit.id,
                address: businessUnit.streetAndNumber + ", " + businessUnit.zipCode + " " + businessUnit.city,
                description: this.props.intl.formatMessage({id: "project.create.address.description"}),
                shortDescription: this.props.intl.formatMessage({id: "project.create.address.short.description"}),
                costCenter: this.props.intl.formatMessage({id: "project.create.address.cost.center"}),
                deliveryTimes: deliveryTimes,
                addressType: DELIVERY_PICKUP,
                logisticType: LOGISTIC_TYPE_DECENTRAL,
                version: 0
            }
            const result = await postAsyncCatch(this.context, "/address/" + businessUnit.projectId, address, this.props, true);
            let [createdDeliveryAddress, createdPickupAddress] = result;
            this.setState({createdDeliveryAddress, createdPickupAddress});
        }
    }

    /** delete / remove all existing articles from business unit and create the new articles */
    saveArticles = async(articleMapping) => {
        let articlePromiseList = [];
        const projectId = this.getProjectId();
        const businessUnitId = this.state.createdBusinessUnit.id;
        await clearBusinessUnitArticles(this.context, this.props, projectId, businessUnitId);
        await this.reloadResidentsLaundry();

        const area = {
            version: 0,
            businessUnitId: businessUnitId,
            name: this.props.intl.formatMessage({id: "project.create.area"}),
            articleIds: []
        }
        const professionalGroup = {
            version: 0,
            businessUnitId: businessUnitId,
            name: this.props.intl.formatMessage({id: "project.create.professional.group"}),
            type: PROFESSIONAL_GROUP_TYPE_PERSONAL,
            gender: GENDER_UNISEX,
            articleIds: []
        }
        let residentsLaundryList = [];

        for (let articleKit of articleMapping.values()) {
            switch (articleKit.priceType) {
                case PriceType.ARTICLE_QUANTITY:
                case PriceType.ARTICLE_WEEKLY:
                    let articleConfiguration = articleKit.articleData;
                    let filterOptions = getFirstFilterOptions(articleConfiguration.filterCategories);
                    let articlePromise = createOrGetArticle(this.context, this.props, articleConfiguration, filterOptions)
                        .then((articleVo) => {
                            articleKit.articleObject = articleVo;
                            if (articleVo.category === CATEGORY_WORK_WEAR) {
                                professionalGroup.articleIds.push(articleVo.id);
                            } else {
                                area.articleIds.push(articleVo.id);
                            }
                        });
                    articlePromiseList.push(articlePromise);
                    break;
                case PriceType.CUSTOMER_ARTICLE:
                    let customerArticle = articleKit.articleData;
                    customerArticle.businessUnitId = this.state.createdBusinessUnit.id;
                    let customerArticlePromise = createCustomerArticle(this.context, this.props, customerArticle, this.getProjectId(), true)
                        .then((customerArticleVo) => articleKit.articleObject = customerArticleVo);
                    articlePromiseList.push(customerArticlePromise);
                    break;
                case PriceType.RESIDENTS_LAUNDRY:
                    let residentsLaundry = articleKit.articleData;
                    residentsLaundry.selected = true;
                    residentsLaundryList.push(residentsLaundry);
                    break;
                case PriceType.OPERATING_RESOURCE:
                    let operatingResource = articleKit.articleData;
                    let operatingResourcePromise = createBusinessUnitResource(
                        this.context, this.props, operatingResource, this.getProjectId(), true)
                        .then((operatingResourceVo) => articleKit.articleObject = operatingResourceVo);
                    articlePromiseList.push(operatingResourcePromise);
                    break;
                case PriceType.SPECIAL_SERVICE:
                    break;
                default:
                    break;
            }
        }

        let residentsLaundryPromise = updateResidentsLaundry(
            this.context, this.props, residentsLaundryList, projectId, businessUnitId, true);
        articlePromiseList.push(residentsLaundryPromise);
        await Promise.all(articlePromiseList);
        this.setState({articleMapping});

        await this.assignToDeliveryAddress(area, professionalGroup);
        return true;
    }

    /** assign area and professional group to delivery address */
    assignToDeliveryAddress = async(area, professionalGroup) => {
        const projectId = this.getProjectId();
        let createdArea = await createArea(this.context, this.props, area, projectId, true);
        let createdProfessionalGroup = await createProfessionalGroup(this.context, this.props, professionalGroup, projectId, true);

        const deliveryAddress = this.state.createdDeliveryAddress;
        let areaIds = {ids: [createdArea.id]};
        let updatedAddress = await putAsyncCatch(this.context, "/assignments/areas/" + projectId +
            "/" + deliveryAddress.id + "/" + deliveryAddress.version, areaIds, this.props, true);

        let groupIds = {ids: [createdProfessionalGroup.id]};
        let createdDeliveryAddress = await putAsyncCatch(this.context, "/assignments/professional/" + projectId + "/" +
            updatedAddress.id + "/" + updatedAddress.version, groupIds, this.props, true);
        this.setState({createdArea, createdProfessionalGroup, createdDeliveryAddress});
    }

    getAmountPerDay = (amount, deliveryPeriodVo) => {
        if (!amount || !deliveryPeriodVo?.to || deliveryPeriodVo?.from === null || deliveryPeriodVo?.from === undefined) {
            return 0;
        }
        return amount * 86400000 / (deliveryPeriodVo.to - deliveryPeriodVo.from);
    }

    updateQuantities = async(rentalLinenOffsettings, resourceOffsettings) => {
        const deliveryAddressId = this.state.createdDeliveryAddress.id;
        let deliveryQuantityResults = new Map();
        let residentsLaundryList = [];
        let articlePromises = [];
        await this.reloadResidentsLaundry();

        let rentalLinenOffsettingMap = new Map(rentalLinenOffsettings.map((obj) => [obj.articleId, obj.articleCategoryOffsetting]));
        let resourceOffsettingMap = new Map(resourceOffsettings.map((obj) => [obj.id, obj]));

        let deliveryQuantityMap = new Map((await getQuantities(this.context, this.props, deliveryAddressId))
            .map((obj) => [obj.articleId, obj]));

        let invoiceMap = new Map();
        for (let invoice of this.state.invoices) {
            for (let invoiceItem of invoice.invoiceItems) {
                let articleNumber = invoiceItem.articleNumber;
                let existingItem = {...invoiceMap.get(articleNumber)};
                let amountPerDay;
                let summedAmount;
                if (invoiceMap.has(articleNumber)) {
                    summedAmount = existingItem.summedAmount + invoiceItem.amount;
                    amountPerDay = existingItem.amountPerDay + this.getAmountPerDay(invoiceItem.amount, invoice.deliveryPeriodVo);
                } else {
                    summedAmount = invoiceItem.amount;
                    amountPerDay = this.getAmountPerDay(invoiceItem.amount, invoice.deliveryPeriodVo);
                }
                invoiceMap.set(articleNumber, {...existingItem, amountPerDay, summedAmount});
            }
        }

        for (let articleKit of this.state.articleMapping.values()) {
            let invoiceItem = invoiceMap.get(articleKit.articleNumber);
            switch (articleKit.priceType) {
                case PriceType.ARTICLE_QUANTITY:
                case PriceType.ARTICLE_WEEKLY:
                    let article = articleKit.articleObject;
                    let offsetting = rentalLinenOffsettingMap.get(article.id);
                    let deliveryQuantity = deliveryQuantityMap.get(article.id);

                    switch(offsetting) {
                        case DELIVERED_QUANTITY:
                            deliveryQuantity.deliveryAmount = Math.round(invoiceItem.amountPerDay * 365);
                            articleKit.priceType = PriceType.ARTICLE_QUANTITY;
                            break;
                        case FLAT_RATE:
                            deliveryQuantity.equipAmount = Math.round(invoiceItem.amountPerDay * 7);
                            articleKit.priceType = PriceType.ARTICLE_WEEKLY;
                            break;
                        case SPLIT_PRICE:
                            if (articleKit.priceType === PriceType.ARTICLE_QUANTITY) {
                                deliveryQuantity.deliveryAmount = Math.round(invoiceItem.amountPerDay * 365);
                            } else {
                                deliveryQuantity.equipAmount = Math.round(invoiceItem.amountPerDay * 7);
                            }
                            break;
                        default:
                            console.log("unsupported offsetting.", offsetting);
                            break;
                    }
                    deliveryQuantityResults.set(article.id, deliveryQuantity);
                    break;
                case PriceType.CUSTOMER_ARTICLE:
                    let customerArticle = articleKit.articleObject;
                    customerArticle.volume = invoiceItem.summedAmount;
                    const customerArticlePromise = updateCustomerArticle(this.context, this.props, customerArticle, this.getProjectId(), true)
                        .then((customerArticleVo) => articleKit.articleObject = customerArticleVo);
                    articlePromises.push(customerArticlePromise);
                    break;
                case PriceType.RESIDENTS_LAUNDRY:
                    let residentsLaundry = articleKit.articleObject;
                    residentsLaundry.amount = Math.round(invoiceItem.amountPerDay * 365);
                    residentsLaundryList.push(residentsLaundry);
                    break;
                case PriceType.OPERATING_RESOURCE:
                    let operatingResource = resourceOffsettingMap.get(articleKit.articleObject.id);
                    let amount;
                    switch (operatingResource.resourceOffsetting) {
                        case RESOURCE_OFFSETTINGS.MONTHLY:
                            amount = invoiceItem.amountPerDay * 30;
                            break;
                        case RESOURCE_OFFSETTINGS.PER_UNIT:
                            amount = invoiceItem.summedAmount;
                            break;
                        case RESOURCE_OFFSETTINGS.WEEKLY:
                            amount = invoiceItem.amountPerDay * 7;
                            break;
                        case RESOURCE_OFFSETTINGS.CALENDAR_DAY:
                            amount = invoiceItem.amountPerDay;
                            break;
                        default:
                            amount = invoiceItem.amountPerDay * 365;
                            break;
                    }
                    let operatingResourcePromise = updateUnitResourceCount(this.context, this.props, operatingResource.id,
                        operatingResource.version, {delta: amount}, this.getProjectId(), true)
                        .then((operatingResourceVo) => articleKit.articleObject = operatingResourceVo);
                    articlePromises.push(operatingResourcePromise);
                    break;
                default:
                    break;
            }
        }

        if (residentsLaundryList.length > 0) {
            let residentsLaundryPromise = updateResidentsLaundry(
                this.context, this.props, residentsLaundryList, this.getProjectId(), this.state.createdBusinessUnit.id, true)
            articlePromises.push(residentsLaundryPromise);
        }

        if (deliveryQuantityResults.size > 0) {
            let rentalLinenPromise = saveQuantities(this.context, this.props, this.getProjectId(), deliveryAddressId,
                Array.from(deliveryQuantityResults.values()), true);
            articlePromises.push(rentalLinenPromise);
        }

        await Promise.all(articlePromises);
        this.setState({articleMapping: this.state.articleMapping});
    }

    getResidentsLaundry = () => {
        let laundry = [];
        const articleMapIterator = this.state.articleMapping?.values();
        if (articleMapIterator) {
            for (let article of Array.from(articleMapIterator)) {
                if (article.priceType === PriceType.RESIDENTS_LAUNDRY) {
                    laundry.push(article.articleData);
                }
            }
        }
        return laundry;
    }

    reloadResidentsLaundry = async() => {
        let laundry = await getResidentsLaundryForUnit(this.context, this.props, this.state.createdBusinessUnit.id);
        let laundryMap = new Map(laundry.map(l => [l.id, l]));
        let articleMapping = this.state.articleMapping;
        articleMapping?.forEach((article, articleNumber) => {
                if (article.priceType === PriceType.RESIDENTS_LAUNDRY) {
                    article.articleObject = laundryMap.get(article.articleData.id);
                    article.articleData = article.articleObject;
                }
            });

        this.setState({articleMapping});
    }

    saveInvoices = async(invoices) => {
        let results = [];
        let invoicePromises = invoices.map((invoice) => updateInvoice(this.context, this.props, invoice, true)
            .then((result) => results.push(result)));
        await Promise.all(invoicePromises);
        this.setState({invoices: results});
        return results;
    }

    saveAndCloseDialog = async(redirectRoute) => {
        // project validation
        const path = "/project/complete/" +  this.getProjectId();
        let validation = await putAsyncCatch(this.context, path, null, this.props);
        if (!validation) {
            return;
        } else if (validation.conflictTypes?.length) {
            let messageId = "conflicts.text." + validation.conflictTypes[0];
            createWarnMessage(this.props.intl.formatMessage({id: messageId}), this.props);
        } else if (!validation.complete) {
            createWarnMessage(this.props.intl.formatMessage({id: "commons.notAcceptable.error"}), this.props);
        }

        // send project in status IS_ACCEPTED
        await acceptWorkingState(this.context, this.state.createdOperation, null,
            async() => {
                localStorage.setItem(LAST_OPERATION_ID, this.state.createdOperation.id);
                // reload, close dialog and redirect
                await this.context.reloadAppData(true)
                    .then(() => {
                        this.setState(this.getEmptyState());
                        this.props.onCancel();
                        if (redirectRoute === ORDER_DASHBOARD_ROUTE) {
                            this.context.setActiveApp(ORDER_APP);
                        }
                        history.push(redirectRoute)
                    });
            },
            () => {
                createErrorMessage(this.props.intl.formatMessage({id: "commons.error"}), this.props);
            });
    }

    stepper = () => {
        return (
            <Stepper activeStep={this.state.step} style={{marginBottom: 20, paddingLeft: 0, paddingRight: 0}}>
                {[0, 1, 2, 3, 4, 5, 6].map(step => (
                    <Step key={"project-create-with-invoices-step-" + step} style={{whiteSpace: "nowrap"}}>
                        <StepLabel>
                            {this.props.intl.formatMessage({id: "project.create.withInvoices.step" + step})}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    }

    pageWithFooter = () => {
        switch (this.state.step) {
            case 0: return (
                <BaseDataPage
                    allProfiles={this.state.allProfiles}
                    currentOperation={this.state.createdOperation}
                    onContinue={(createdOperation) => {
                        this.setState({createdOperation});
                        this.onContinue(createdOperation);
                    }}
                    onCancel={this.onCancel}/>
            );
            case 1: return (
                <ContractPage
                    contract={this.state.contract}
                    projectId={this.getProjectId()}
                    onBack={this.onBack}
                    onCancel={this.onCancel}
                    onContinue={(contract) => {
                        this.setState({contract});
                        this.onContinue(contract);
                    }}/>
            );
            case 2: return (
                <BusinessUnitPage
                    businessUnit={this.state.createdBusinessUnit}
                    projectId={this.getProjectId()}
                    onBack={this.onBack}
                    onCancel={this.onCancel}
                    onContinue={(createdBusinessUnit) => {
                        this.setState({createdBusinessUnit});
                        this.createDeliveryAddress(createdBusinessUnit);
                        this.onContinue(createdBusinessUnit)
                    }}/>
            );
            case 3: return (
                <InvoicesPage
                    currency={this.state.createdOperation.workingProject.currency}
                    invoices={this.state.invoices}
                    operationId={this.state.createdOperation.id}
                    onBack={(invoices) => {
                        this.setState({invoices});
                        this.onBack();
                    }}
                    onCancel={this.onCancel}
                    onContinue={(invoices) => {
                        this.saveInvoices(invoices)
                            .then((success) => this.onContinue(success));
                    }}/>
            );
            case 4: return (
                <InvoiceArticlePage
                    businessUnitId={this.state.createdBusinessUnit.id}
                    articleMapping={this.state.articleMapping}
                    invoices={this.state.invoices}
                    onBack={(articleMapping) => {
                        this.setState({articleMapping});
                        this.onBack();
                    }}
                    onCancel={this.onCancel}
                    onContinue={(articleMapping) => {
                        this.setState({articleMapping});
                        this.saveArticles(articleMapping)
                            .then((success) => this.onContinue(success));
                    }}/>
            );
            case 5: return (
                <OffsettingsPage
                    projectId={this.state.createdOperation.workingProject?.id}
                    unitId={this.state.createdBusinessUnit.id}
                    residentsLaundry={this.getResidentsLaundry()}
                    onBack={this.onBack}
                    onCancel={this.onCancel}
                    onContinue={(rentalLinenOffsettings, resourceOffsettings) => {
                        this.updateQuantities(rentalLinenOffsettings, resourceOffsettings).then(() => this.onContinue(true));
                    }}/>
            );
            case 6: return (
                <PriceSheetPage
                    project={this.state.createdOperation.workingProject}
                    unitId={this.state.createdBusinessUnit.id}
                    articleMapping={this.state.articleMapping}
                    invoices={this.state.invoices}
                    onBack={this.onBack}
                    onCancel={this.onCancel}
                    onContinue={async(redirectRoute) => {
                        await this.saveAndCloseDialog(redirectRoute);
                    }}/>
            );
            default: return null;
        }
    }

    render() {
        return (
            <TexisionDialog
                open={this.props.open}
                size="lg"
                style={{zIndex: 1100}}
                titleId="project.create.withInvoices.dialog.title"
                content={<>
                    {this.stepper()}
                    {this.pageWithFooter()}
                </>}
            />
        );
    }
}

ProjectCreateWithInvoicesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default withSnackbar(injectIntl(ProjectCreateWithInvoicesDialog));
