import React, {Component} from 'react';
import {Button, Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {SimpleTextCard} from "../../../uiLibrary/SimpleTextCard";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {latestActivePayment} from "../../../../services/PaymentService";
import {DELIVERY, INTERVAL_TYPE_KEY, INTERVAL_TYPES, LICENSE_TYPES} from "../../../../util/Constants";
import {withSnackbar} from "notistack";
import {InputLabel, MenuItem, Select} from "@mui/material";
import BaseDataTable from "./BaseDataTable";
import BaseDataDialog from "./BaseDataDialog";
import BaseDataIntervalChangeDialog from "./BaseDataIntervalChangeDialog";
import {getActiveOperation} from "../../../../services/OperationService";
import {getUnitAddresses} from "../../../../services/AddressService";
import {getSelectableBaseDataYears} from "../../../../services/StatisticsService";
import {getContract} from "../../../../services/ContractService";

class BaseData extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            selectedYear: "",
            showDialog: false,
            showIntervalChangeHint: false,
            newInterval: null,
            oldInterval: null,
            deliveryStations: [],
            contract: null
        }
    }

    async componentDidMount() {
        this.setState({contract: await getContract(this.context, this.props, getActiveOperation(this.context).activeProject.id)});

        if (!this.context.getUserStateValue(INTERVAL_TYPE_KEY)) {
            await this.context.setUserStateValue(INTERVAL_TYPE_KEY, INTERVAL_TYPES.MONTHLY);
        }

        const baseData = await this.context.reloadBaseData();

        let selectedYear = null;
        if (baseData?.find(d => d.year === new Date().getFullYear())) {
            selectedYear = new Date().getFullYear();
        } else if (baseData?.length) {
            selectedYear = baseData.sort((d1, d2) => d1.year < d2.year)[0].year;
        }

        let deliveryStations = [];
        for (let unit of getActiveOperation(this.context).activeProject.businessUnits.units) {
            deliveryStations.push(...(await getUnitAddresses(this.context, this.props, unit.id)).filter(station => station.addressType === DELIVERY));
        }

        this.setState({baseData, selectedYear, deliveryStations});
    }

    loadBaseData = async() => {
        const baseData = await this.context.reloadBaseData();
        let selectedYear = this.state.selectedYear;
        if (!selectedYear && baseData?.find(d => d.year === new Date().getFullYear())) {
            selectedYear = new Date().getFullYear();
        } else if (!selectedYear && baseData?.length) {
            selectedYear = baseData.sort((d1, d2) => d1.year < d2.year)[0].year;
        }
        this.setState({baseData, selectedYear});
        return baseData;
    }

    onIntervalChange = (newInterval) => {
        const oldInterval = this.context.getUserStateValue(INTERVAL_TYPE_KEY);
        if ((newInterval === INTERVAL_TYPES.MONTHLY && oldInterval === INTERVAL_TYPES.QUARTERLY)
            || (newInterval === INTERVAL_TYPES.MONTHLY && oldInterval === INTERVAL_TYPES.YEARLY)
            || (newInterval === INTERVAL_TYPES.QUARTERLY && oldInterval === INTERVAL_TYPES.YEARLY)) {
            this.setState({showIntervalChangeHint: true, newInterval, oldInterval});
        } else {
            this.context.setUserStateValue(INTERVAL_TYPE_KEY, newInterval);
        }
    }

    render() {
        const hasCooperationLicense = latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE);
        return (
            <>
                <BaseDataIntervalChangeDialog
                    oldInterval={this.state.oldInterval}
                    newInterval={this.state.newInterval}
                    show={this.state.showIntervalChangeHint}
                    close={() => this.setState({showIntervalChangeHint: false, oldInterval: null, newInterval: null})}
                    onConfirm={(newInterval) => this.context.setUserStateValue(INTERVAL_TYPE_KEY, newInterval)}/>

                <BaseDataDialog
                    key={this.state.showDialog}
                    show={this.state.showDialog}
                    contract={this.state.contract}
                    allBaseData={this.context.appData.baseData}
                    deliveryStations={this.state.deliveryStations}
                    close={() => this.setState({showDialog: false})}

                    reload={async() => this.setState({baseData: await this.loadBaseData()})}/>

                <Grid container>
                    <Grid item xs>
                       <>
                           <Typography variant="h1">
                               <FormattedMessage id="cockpit.statistics.baseData.title"/>
                           </Typography>

                           <Typography variant="subtitle1">
                               <FormattedMessage id="cockpit.statistics.baseData.subtitle"/>
                           </Typography>
                       </>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!hasCooperationLicense}
                            onClick={() => this.setState({showDialog: true})}>
                            <FormattedMessage id="cockpit.statistics.baseData.button"/>
                        </Button>
                    </Grid>
                </Grid>

                <SimpleTextCard show={!hasCooperationLicense} textId="cockpit.baseData.noLicense"/>

                {hasCooperationLicense && <>

                    <Grid container spacing={4} style={{marginBottom: 20}}>

                        <Grid item>

                            <InputLabel>{this.props.intl.formatMessage({id: "cockpit.statistics.baseData.interval.select"})}</InputLabel>

                            <Select
                                labelId="select-base-data-interval"
                                label={this.props.intl.formatMessage({id: "cockpit.statistics.baseData.interval.select"})}
                                value={this.context.getUserStateValue(INTERVAL_TYPE_KEY) ?? INTERVAL_TYPES.MONTHLY}>
                                {Object.values(INTERVAL_TYPES).map(interval =>
                                    <MenuItem
                                        key={interval+"-interval-option"}
                                        value={interval}
                                        onClick={() => this.onIntervalChange(interval)}>
                                        <FormattedMessage id={"constants.INTERVAL_TYPES." + interval}/>
                                    </MenuItem>
                                )}
                            </Select>

                        </Grid>

                        {this.context.getUserStateValue(INTERVAL_TYPE_KEY) !== INTERVAL_TYPES.YEARLY && !!this.context.appData.baseData?.length && <Grid item>

                            <InputLabel>{this.props.intl.formatMessage({id: "cockpit.statistics.baseData.year.select"})}</InputLabel>

                            <Select
                                labelId="select-base-data-year"
                                label={this.props.intl.formatMessage({id: "cockpit.statistics.baseData.year.select"})}
                                value={this.state.selectedYear}>
                                {getSelectableBaseDataYears(this.context).map(year =>
                                    <MenuItem
                                        key={year+"-year-option"}
                                        value={year}
                                        onClick={() => this.setState({selectedYear: year})}>
                                        {year}
                                    </MenuItem>
                                )}
                            </Select>

                        </Grid>}

                    </Grid>

                    <BaseDataTable
                        baseData={this.context.appData.baseData}
                        contract={this.state.contract}
                        selectedYear={this.state.selectedYear}
                        deliveryStations={this.state.deliveryStations}
                        reload={async() => this.setState({baseData: await this.loadBaseData()})}
                    />

                </>}
            </>
        );
    }
}

export default injectIntl(withSnackbar(BaseData));
