import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import fileDownload from "js-file-download";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { downloadUsingGet, getAsync } from "../../services/BackendService";
import DownloadIcon from '@mui/icons-material/Download';
import { GeneralContext } from "../contexts/GeneralContext";
import { DE } from "../../util/Constants";

class History extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            operationDifferences: [],
            isLoading: true
        }
    }

    async componentDidMount() {
        if (this.props.operationId !== null && this.props.operationId !== undefined) {
            const result = await getAsync("operation/" + this.props.operationId + "/diff");
            // filter for past contracts
            if (this.props.isTender) {
                // remove tenders that are in editing now because the changes are not published
                this.setState({
                    operationDifferences: result.data?.filter(od => od.effectiveFrom <= new Date().getTime() 
                        && !this.isWorkingProject(od.projectTargetId)), 
                    isLoading: false
                });  
            } else {
                this.setState({
                    operationDifferences: result.data?.filter(od => od.effectiveFrom <= new Date().getTime()), 
                    isLoading: false
                });  
            } 
        } else {
            this.setState({isLoading: false});
        }
    }

    isWorkingProject = (projectId) => {
        return !!this.context.appData.operations?.find(o => o.workingProject?.id === projectId);
    }

    async downloadPdf() {
        const response = await downloadUsingGet("operation/diff/export/" + this.props.operationId + "/" + DE)
        if (response?.status === 200) {
            const fileName = response.headers['content-disposition'].split("filename=")[1];
            fileDownload(response.data, fileName);
        }
    }

    render() {

        if (this.state.isLoading) {

            return (
                <CircularProgress/>
            );

        } else {

            return (
                <>

                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography variant="h1">
                                <FormattedMessage id={this.props.titleId}/>
                            </Typography>
                            <Typography variant="subtitle1">
                                <FormattedMessage id={this.props.subtitleId}/>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={this.state.operationDifferences.length === 0 
                                    || this.props.operationId === null || this.props.operationId === undefined}
                                onClick={() => this.downloadPdf()}
                                variant="contained"
                                color="primary"
                                endIcon={<DownloadIcon/>}>
                                <FormattedMessage id="history.downloadbutton"/>
                            </Button>
                        </Grid>
                    </Grid>

                    {this.state.operationDifferences
                        ?.sort((op1,op2) => {return op2.effectiveFrom - op1.effectiveFrom})
                        .map(opDiff => this.props.historyItem(opDiff))}

                </>
            );


        }
    }
}

export default injectIntl(History);
