import { Typography } from "@material-ui/core";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { GeneralContext } from "../../../contexts/GeneralContext";

class ContractAcceptOrRejectWithdrawHeader extends Component {

    static contextType = GeneralContext;

    render() {
        
        return (
            <>
                <Typography variant="h1">
                    <FormattedMessage id="cockpit.contractStatus.title"/>
                </Typography>
                <Typography variant="subtitle1">
                    <FormattedMessage id="cockpit.contractStatus.acceptOrRejectWithdraw.subtitle"/>
                </Typography>
            </>
        );
    }
}

export default injectIntl(ContractAcceptOrRejectWithdrawHeader);
