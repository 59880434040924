import { TableCell, TableRow } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PriceType, RESOURCE_OFFSETTINGS } from "../../../util/Constants";
import { offerDisabledTooltip } from "../../../util/ProcedureUtil";
import {formatPrice, isTender} from "../../../util/Util";
import ArticleButtonCell from "../cells/ArticleButtonCell";
import ArticleNumberCell from "../cells/ArticleNumberCell";
import ArticlePriceCell from "../cells/ArticlePriceCell";
import { PriceSheetTable } from "./PriceSheetTable";
import AlternateDescriptionCell from "../cells/AlternateDescriptionCell";

const PriceSheetSpecialServicesTable = ({
    readOnly,
    singlePriceOnly,
    allArticleNumbers,
    editable,
    exportType,
    lockArticleNumbers,
    lockPrices,
    priceGroup,
    project, 
    show,
    resetAllArticleNumbers,
    specialServices, 
    updatePriceSheet,
    // following prop is only used for bidders
    offer,
    isPrevious
}) => {

    const [specialServiceMap, setSpecialServiceMap] = useState(priceGroup?.priceItemMap ? JSON.parse(JSON.stringify(priceGroup.priceItemMap)) : null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (priceGroup?.priceItemMap) {
            setSpecialServiceMap(JSON.parse(JSON.stringify(priceGroup.priceItemMap)));
        }
    }, [priceGroup])

    const intl = useIntl();

    if (!show) {
        return (<div/>);
    }

    const handleItemChange = (newSpecialService) => {
        let map = specialServiceMap;
        map[newSpecialService.objectId] = newSpecialService;
        setSpecialServiceMap({...map});
    }

    const resetItemMap = () => {
        setSpecialServiceMap(JSON.parse(JSON.stringify(priceGroup.priceItemMap)));
        resetAllArticleNumbers();
    }

    let headerTitles = [
        "specialServices.table.header.name",
        "specialServices.table.header.text",
        "specialServices.table.header.offsetting"
    ];

    if (!singlePriceOnly) {
        headerTitles.push("specialServices.table.header.amount");
    }

    return (
        <PriceSheetTable
            title={intl.formatMessage({id: "bidder.priceSheet.table.specialServices.title"})}
            type="specialServices"
            headerTitles={headerTitles}
            editButtonLabel={lockArticleNumbers && lockPrices ? "bidder.priceSheet.editAlternateLabels.button" : null}
            rows={specialServices.sort((a,b) => a.name.localeCompare(b.name)).map(service => {

                const priceItem = specialServiceMap[service.id];

                const disabled = !isEditing || (service.offsetting === RESOURCE_OFFSETTINGS.WITHOUT_CHARGE 
                    || (!editable && isTender() && offerDisabledTooltip(project, offer, isPrevious, intl.formatMessage)));

                return (
                    <TableRow key={service.id}>

                        <TableCell>{service.name}</TableCell>

                        <TableCell>{service.text}</TableCell>

                        <TableCell>{intl.formatMessage({id: "constants.ResourceOffsetting." + service.offsetting})}</TableCell>

                        {!singlePriceOnly && <TableCell>{service.amount}</TableCell>}

                        <TableCell>
                            <ArticlePriceCell 
                                disabled={disabled || lockPrices}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                        {!singlePriceOnly && <TableCell>{isEditing ? "" : formatPrice(priceItem?.sumNet)}</TableCell>}

                        {!singlePriceOnly && <TableCell>{isEditing ? "" : formatPrice(priceItem?.sumGross)}</TableCell>}

                        <TableCell>
                            <AlternateDescriptionCell
                                disabled={disabled}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={(newSpecialService) => handleItemChange(newSpecialService)}/>
                        </TableCell>

                        <TableCell>
                            <ArticleNumberCell
                                allArticleNumbers={allArticleNumbers} 
                                disabled={disabled || lockArticleNumbers}
                                exportType={exportType} 
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                        <TableCell>
                            <ArticleButtonCell 
                                allArticleNumbers={allArticleNumbers} 
                                disabled={disabled || lockArticleNumbers}
                                exportType={exportType}
                                priceItem={priceItem}
                                handleItemChange={handleItemChange}/>
                        </TableCell>

                    </TableRow>
                );
            })}
            isEditing={isEditing}
            readOnly={readOnly}
            singlePriceOnly={singlePriceOnly}
            offer={offer}
            project={project}
            priceGroup={priceGroup}
            onSave={() => updatePriceSheet(PriceType.SPECIAL_SERVICE, specialServiceMap)}
            resetItemMap={() => resetItemMap()}
            setIsEditing={(value) => setIsEditing(value)}
            editable={editable && priceGroup.priceItemMap && Object.keys(priceGroup.priceItemMap).length !== 0}
        />
    );
}

export {
    PriceSheetSpecialServicesTable
}
