import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {GeneralContext} from "../contexts/GeneralContext";
import {Grid} from "@mui/material";
import {EVENT_DURATIONS, EVENT_TYPES} from "../../util/Constants";
import {CalendlyPopup} from "../calendly/CalendlyPopup";
import "../../css/OperationEvent.css";
import OperationEventCard from "./OperationEventCard";
import {Card, CardActionArea} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";

class OperationEventList extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showCalendly: false
        }
    }

    render() {
        const operationEventsVo = this.context.operationEvents;
        if (!operationEventsVo) {
            return <FormattedMessage id="calendly.noEvents.error"/>;
        }
        const operationId = operationEventsVo.operationId;
        const operationEventList = operationEventsVo.events.filter(e => e.eventType === EVENT_TYPES.CONSULTING && e.event?.status === "active");
        const totalSupportTime = operationEventsVo.totalSupportTime;
        const availableSupportTime = operationEventsVo.availableSupportTime;

        return (
            <>
                <CalendlyPopup
                    key={"calendly-popup-operation-" + operationId + "-type-" + EVENT_TYPES.CONSULTING + "-duration-" + EVENT_DURATIONS.HALF_HOUR}
                    showCalendly={this.state.showCalendly}
                    operationId={operationId}
                    eventType={EVENT_TYPES.CONSULTING}
                    eventDuration={EVENT_DURATIONS.HALF_HOUR}
                    onClose={() => this.setState({showCalendly: false})}/>

                <Grid container spacing={1} alignItems="stretch" style={{marginBottom: 10}} justifyContent="flex-start">

                    {(!operationEventList || operationEventList.length === 0)
                        && <Grid className="eventCard" item xs={5}>
                                <FormattedMessage id="calendly.noEvents.text"/>
                           </Grid>
                    }

                    {(operationEventList && operationEventList.length > 0)
                        && operationEventList
                            .sort((a,b) => new Date(a.event?.start_time) < new Date(b.event?.start_time) ? -1 : 1)
                            .map(operationEvent => {
                            return (
                                <OperationEventCard
                                    key={"operation-event-card-" + operationEvent?.eventId}
                                    operationEvent={operationEvent}/>
                            );
                        })
                    }

                    {(totalSupportTime > 0 && availableSupportTime > 0) && <Card
                        item
                        className="eventAddCard"
                        onClick={() => this.setState({showCalendly: true})}>
                            <CardActionArea style={{height: "100%", width: 120, padding: 0, margin: 0, backgroundColor: "transparent"}}>
                                <div style={{height: "66%", width: 120, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <AddIcon fontSize="large"/>
                                </div>
                                <div style={{height: "34%", width: 120, display: "flex", justifyContent: "center", alignItems: "flex-start", textAlign: "center"}}>
                                    <FormattedMessage id="calendly.book.tile"/>
                                </div>
                            </CardActionArea>
                    </Card>}

                </Grid>

                {(totalSupportTime > 0 && availableSupportTime > 0) && <FormattedMessage
                    id="calendly.availableSupportTime.publish.text"
                    values={{hours: (availableSupportTime/60.0).toString().replace(".", ",")}}/>
                }
            </>
        );
    }
}
export default injectIntl(OperationEventList);
