import React, {Component} from "react";
import {Grid} from "@mui/material";
import {Typography, Tooltip} from "@material-ui/core";
import {injectIntl} from "react-intl";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import {DIALOG_TYPE_INFO} from "../../../util/Constants";
import {loadUsersByGroup} from "../../../services/UserService";
import {GeneralContext} from "../../contexts/GeneralContext";
import Spacer from "../../uiLibrary/Spacer";
import {bodyBackgroundColor, texisionFontColorDark} from "../../../util/ColorTheme";
import MaterialTable from "material-table";
import LanguageIcon from '@mui/icons-material/Language';

class BidderSummary extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showUserGroupDialog: false,
            usersOfGroup: []
        }
    }

    textBlock = (text, variant, hideIfEmpty) => {
        return (
            <Typography variant={variant}>
                {text ? text : (hideIfEmpty ? "" : "-")}
            </Typography>
        )
    }

    bidderProperty = (content, xs) => {
        return (
            <Grid
                item xs={xs ?? 12}
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}>
                {content}
            </Grid>
        );
    }

    openUserGroupDialog = async(event) => {
        event.stopPropagation();
        let userGroupId = this.props.userGroup.id;
        let users = await loadUsersByGroup(this.context, this.props, userGroupId)
        this.setState({showUserGroupDialog: true, usersOfGroup: users});
    }

    userGroupView = () => {
        return  <>
            <h1>{this.props.userGroup?.name}</h1>
            {this.props.userGroup?.link &&
                <a className="bidder" href={this.props.userGroup?.link} target="_blank" rel="noopener noreferrer">
                    {this.props.userGroup?.link}
                </a>}

            <Spacer size={50} />

            <MaterialTable
                columns={[
                    {
                        title: this.props.intl.formatMessage({id: "bidderSelection.userGroup.table.company"}),
                        field: "company",
                        render: user => {
                            return !user.website
                                ? user.company
                                : <a className="bidder" href={user.website} target="_blank" rel="noopener noreferrer">
                                    {user.company}
                                </a>
                        }
                    },
                    {
                        title: this.props.intl.formatMessage({id: "bidderSelection.userGroup.table.streetAndNumber"}),
                        field: "address",
                        render: user => user.streetAndNumber
                    },
                    {
                        title: this.props.intl.formatMessage({id: "bidderSelection.userGroup.table.zipcode"}),
                        field: "zipcode",
                        render: user => user.zipCode
                    },
                    {
                        title: this.props.intl.formatMessage({id: "bidderSelection.userGroup.table.city"}),
                        field: "city",
                        render: user => user.city
                    },
                    {
                        title: this.props.intl.formatMessage({id: "bidderSelection.userGroup.table.email"}),
                        field: "email",
                        render: user =>
                            <a href={"mailto:" + user.email} onClick={(e) => e.stopPropagation()}>
                                {user.email}
                            </a>
                    },
                ]}
                data={this.state.usersOfGroup}
                options={{
                    showTitle: false,
                    search: false,
                    paging: false,
                    draggable: false,
                    toolbar: false,
                    sorting: this.state.usersOfGroup.length > 0,
                    headerStyle: {
                        fontWeight: 'bold',
                        backgroundColor: bodyBackgroundColor,
                        color: texisionFontColorDark
                    }
                }}/>
        </>
    }

    render() {
        const userZipDistanceVo = this.props.userZipDistanceVo;
        if (!userZipDistanceVo?.userVo) {
            return null;
        }
        const user = userZipDistanceVo.userVo;
        let distanceText = "";
        if (userZipDistanceVo.zipDistanceVo?.sourceCoordinates) {
            const distance = userZipDistanceVo.zipDistanceVo.distanceInKiloMeters;
            if (distance === null || distance === undefined) {
                distanceText = this.props.intl.formatMessage({id: "bidderSelection.noZipCode"});
            } else if (distance <= 10) {
                distanceText = "< 10 km";
            } else {
                distanceText = distance + " km";
            }
        }
        return (
            <>
                <TexisionDialog
                    open={this.state.showUserGroupDialog}
                    type={DIALOG_TYPE_INFO}
                    size="lg"
                    titleId="bidderSelection.userGroup.dialog.title"
                    replacements={{groupName: this.props.userGroup?.name}}
                    content={this.userGroupView()}
                    cancelId="commons.close.label"
                    onCancel={() => {
                        this.setState({ showUserGroupDialog: false })
                    }}
                />

                <Grid
                    container
                    justifyContent="space-between"
                    style={{
                        paddingLeft: 10,
                        paddingRight: 32,
                        paddingTop: 7,
                        paddingBottom: 7,
                        minHeight: '160px'
                    }}>
                    {this.bidderProperty(this.textBlock(user.company, "h4"), 10)}
                    {!!user.website && <Tooltip title={<div>{user.website}</div>}>
                            <LanguageIcon color="primary" onClick={() =>
                                window.open(user.website, "_blank", "noopener,noreferrer")}/>
                        </Tooltip>}
                    {this.bidderProperty(this.textBlock(distanceText, null, true))}
                    {this.bidderProperty(this.textBlock(user.streetAndNumber))}
                    {this.bidderProperty(this.textBlock(user.zipCode + " " + user.city))}
                    {this.bidderProperty(
                        <a className="bidder" href={"mailto:" + user.email} onClick={(e) => e.stopPropagation()}>
                            {user.email}
                        </a>
                    )}

                    {!!this.props.userGroup && this.bidderProperty(
                        <div>
                            {this.props.intl.formatMessage({id: "bidderSelection.group.tooltip"}) + ": "}
                            <a className="bidder" href="#/" onClick={(e) => this.openUserGroupDialog(e)}>
                                {this.props.userGroup?.name}
                            </a>
                        </div>)}
                </Grid>
            </>
        );
    }
}

export default injectIntl(BidderSummary);
