import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { Grid, InputAdornment, Tooltip } from "@material-ui/core";
import {isDateInvalid, lastYear} from "../../util/DateUtil";
import 'date-fns';
import { de } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { CalendarToday } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { CONTRACT_ROUTE, PROJECT_STATUS_IN_EDITING, PROJECT_STATUS_IN_PROGRESS } from "../../util/Constants";
import { texisionBlue } from "../../util/ColorTheme";
import "../../css/Procedure.css";
import { formatDate } from "../../util/DocumentUtil";
import { DividerWithText } from "../uiLibrary/DividerWithText";
import { useHistory } from "react-router-dom";
import {getActiveProject} from "../../services/ProjectService";

const DeadlineSelection = ({condition, procedure, context, disabled}) => {

    const history = useHistory();
    const intl = useIntl();

    const firstCallForSubmissionDateNotSet = !procedure?.firstCallForSubmissionDate && condition.name !== "firstCallForSubmissionDate";
    const hasNoStartTime = !procedure?.startOfServiceDate || procedure.startOfServiceDate < 0;

    const activeProject = getActiveProject(context);

    let tooltip;
    if (condition.isStartTime) {
        tooltip =  <FormattedMessage id="procedure.startOfServiceDate.tooltip"/>;
    } else if (hasNoStartTime) {
        tooltip =  <FormattedMessage id="procedure.conditions.setStartTimeFirst.tooltip"/>;
    } else if (firstCallForSubmissionDateNotSet) {
        tooltip =  <FormattedMessage id="procedure.conditions.setFirstCallForSubmissionDateFirst.tooltip"/>;
    } else {
        tooltip = "";
    }

    return (<Grid key={condition.name} className="procedureDatePickerGrid" container spacing={2}>

        {condition.dividerTextId && <Grid item xs={12}>
           <DividerWithText textId={condition.dividerTextId}/>
        </Grid>}

        <Grid item xs>
            {intl.formatMessage({id: "procedure." + condition.name + ".description"}) + (condition?.isTemporarily
                ? " (" + intl.formatMessage({id: "procedure.date.temporarily"}) + ")" 
                : "")}
        </Grid>

        <Grid item>
            {![PROJECT_STATUS_IN_PROGRESS, PROJECT_STATUS_IN_EDITING].includes(activeProject?.status) || disabled
                ? <div style={{height: 35, display: "flex", alignItems: "center"}}>{formatDate(intl, condition.date)}</div>
                : <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                    <Tooltip 
                        disabled={!condition.isStartTime && !firstCallForSubmissionDateNotSet} 
                        title={tooltip}>
                        <span>
                            <DatePicker
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <CalendarToday/>
                                    </InputAdornment>
                                    ),
                                }}
                                shouldDisableDate={(date) => isDateInvalid(date, procedure.federalState)}
                                // add one day to the min date so you cannot choose the same date as the one before
                                minDate={condition.minDate ? condition.minDate + 86400000 : lastYear()}
                                minDateMessage={(intl.formatMessage({id: condition.date < lastYear()
                                        ? "procedure.date.minDateMessage" : "procedure.validation." + condition.name + ".invalid"}))}
                                inputVariant="outlined"
                                variant="dialog"
                                emptyLabel={intl.formatMessage({id: condition.isStartTime ? "procedure.startOfServiceDate.noDateSet" : "procedure.date.noDateSet"})}
                                okLabel={intl.formatMessage({id: "commons.confirm.button"})}
                                cancelLabel={intl.formatMessage({id: "commons.cancel.button"})}
                                format="dd.MM.yyyy"
                                value={!condition.date || condition.date < 1 ? null : condition.date}
                                // disable button for start time and for all other dates if publish date is not set
                                disabled={condition.isStartTime || firstCallForSubmissionDateNotSet || hasNoStartTime}
                                onChange={(newDate) => {condition.onChange(newDate.getTime());}}
                                invalidLabel={intl.formatMessage({id: "commons.error"})}
                            />
                        </span>
                    </Tooltip>
                </MuiPickersUtilsProvider>}
        </Grid>

        {procedure.federalState !== "UNDEFINED" && condition.date > 0 && !disabled && isDateInvalid(new Date(condition.date), procedure.federalState)
        && <Grid item xs={12} style={{paddingTop: '0px', paddingBottom: '15px'}}>
            <div className="procedureMessage">
                <FormattedMessage id="procedure.validation.holidays.invalid"/>
            </div>
        </Grid>}

        {condition.isStartTime && <Grid item xs={12}>
            <div className="procedureMessage">
                <Link
                    onClick={() => history.push(CONTRACT_ROUTE)}
                    style={{color: texisionBlue}}>
                    <FormattedMessage id="procedure.startOfServiceDate.link"/>
                </Link>
            </div>
        </Grid>}
    </Grid>);
};

export default injectIntl(DeadlineSelection);
