import { useContext } from "react";
import { useHistory } from "react-router-dom";
import {BIDDER_DASHBOARD_ROUTE, DIALOG_TYPE_WARNING} from "../../util/Constants";
import { GeneralContext } from "../contexts/GeneralContext";
import TexisionDialog from "../uiLibrary/TexisionDialog";

const PriceSheetNotFoundDialog = ({open}) => {

    const context = useContext(GeneralContext);
    const history = useHistory();

    return (
        <TexisionDialog
            type={DIALOG_TYPE_WARNING}
            open={open}
            titleId="commons.projectNotFound.title"
            subtitleId="commons.projectNotFound.subtitle"
            actionId="commons.toBidderDashboard.button"
            onAction={() => {
                context.setUnsavedChanges(false);
                history.push(BIDDER_DASHBOARD_ROUTE);
                context.reloadAppData();
            }}/>
    );
}

export {
    PriceSheetNotFoundDialog
}
