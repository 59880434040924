import React from "react";
import FactoryIcon from '@mui/icons-material/Factory';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ContactsIcon from '@mui/icons-material/Contacts';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import InsightsIcon from '@mui/icons-material/Insights';
import HailIcon from '@mui/icons-material/Hail';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import DeveloperBoardOffIcon from '@mui/icons-material/DeveloperBoardOff';
import SwipeUpIcon from '@mui/icons-material/SwipeUp';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import SpaIcon from '@mui/icons-material/Spa';
import GrassIcon from '@mui/icons-material/Grass';
import CompressIcon from '@mui/icons-material/Compress';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { Avatar } from "@material-ui/core";
import { getRatingArguments } from "../../util/Constants";
import { useIntl } from "react-intl";
import { primaryColor } from "../../util/ColorTheme";

const components = {
    MANUFACTURING_PROCESS: FactoryIcon,
    HEALTH_PROTECTION: HealthAndSafetyIcon,
    DISTANCE_BUSINESS_EMPLOYER: SocialDistanceIcon,
    ENERGY_EFFICIENCY: EnergySavingsLeafIcon,
    DTV_CERTIFICATE: WorkspacePremiumIcon,
    CONTACT_PERSON: ContactsIcon,
    FIRST_DELIVERY: DeliveryDiningIcon,
    RESPONSE_TIMES: TimelapseIcon,
    TECHNICAL_RESOURCES: SettingsInputComponentIcon,
    DELIVERY_QUANTITY_STATISTICS: InsightsIcon,
    COMPLAINT_MANAGEMENT: HailIcon,
    MAINTENANCE: EngineeringIcon,
    DELIVERY_DESPITE_DISRUPTION: LocalShippingIcon,
    ACTION_ON_VEHICLE_FAILURE: CarCrashIcon,
    ACTION_ON_OPERATIONAL_DISRUPTIONS: DeveloperBoardOffIcon,
    ACTION_ON_SHORT_TERM_FAILURES: SwipeUpIcon,
    ACTION_ON_LONG_TERM_FAILURES: SwipeRightIcon,
    OPTICS_GENERAL: AccessibilityNewIcon,
    MODERATION: SpaIcon,
    SMOOTHNESS: GrassIcon,
    FOLDING_SYSTEM: CompressIcon,
    FOLDING_QUALITY: FormatAlignLeftIcon,
    GRIP_AND_FEEL: FingerprintIcon,
    DESIGN_AND_COLOUR: ColorLensIcon,
    SEAM_PROCESSING: ContentCutIcon
};

export function RatingArgumentIcon(props) {
    const intl = useIntl();
    const ratingArgumentEnum = getRatingArguments(props.criterionName)
        .find(a => props.ratingArgument === intl.formatMessage({id: "constants.RatingType." + a}));
    const SpecificIcon = components[ratingArgumentEnum];
    const sizeDefault = 30;
    const bgColorDefault = primaryColor;
    return <Avatar style={{
            color: "white", 
            backgroundColor: props.backgroundColor ?? bgColorDefault, 
            height: props.size ?? sizeDefault, 
            width: props.size ?? sizeDefault}}>
        {ratingArgumentEnum ? <SpecificIcon/> : props.ratingArgument.substring(0, 1)}
    </Avatar>
}

