import React, {Component} from "react";

import {injectIntl} from "react-intl";
import {withSnackbar} from 'notistack';
import {downloadUsingGet, getAsync, putAsync} from "../../services/BackendService";
import {createErrorMessage, createSuccessMessage} from "../../util/Util";
import {Card, Typography, Button, Tooltip, Grid, CardContent} from "@material-ui/core";
import {BIDDER_DASHBOARD_ROUTE, DE, DIALOG_TYPE_WARNING, OFFER_STATUS_PARTICIPATING, OFFER_STATUS_SUBMITTED} from "../../util/Constants";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import fileDownload from "js-file-download";
import {buildDocumentSummary} from "../../util/DocumentUtil";
import { SimpleTextCard } from "../uiLibrary/SimpleTextCard";

import "../../css/BidderProjectDetails.css";
import { getAllCriteriaDocuments, hasAllRatingDocuments, hasAllSuitabilityDocuments, 
    isPriceOfferIncomplete, offerDisabledTooltip, offerIncompleteTooltip } from "../../util/ProcedureUtil";
import { Header } from "../uiLibrary/Header";
import {GeneralContext} from "../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import {areAllMessagesRead} from "../../services/MessageService";
import {getActiveProjectByOperationId, getPublishedProjectById} from "../../services/ProjectService";


class BidderOfferSubmit extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            project: null, 
            offer: null,
            priceOffer: null,
            hasError: false,
            showOfferSubmitDialog: false,
            showOfferRevokeDialog: false,
            isInitializing: true,
            isLoading: false,
            notFound: false,
            readAllMessages: false
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match.params.projectId !== prevProps.match.params.projectId) {
            await this.loadData();
        }
    }

    loadData = async() => {
        this.setState({isInitializing: true});
        await this.loadProject();
        const offerId = await this.loadOffer();
        if (offerId) {
            await this.loadPriceOffer(offerId);
        }
        const read = await areAllMessagesRead(this.context, this.props, this.props.match.params.projectId);
        this.setState({isInitializing: false, readAllMessages: read});
    }

    loadProject = async() => {
        const project = await getPublishedProjectById(this.context, this.props, parseInt(this.props.match.params.projectId));
        this.setState({project, notFound: !project});
    }

    loadOffer = async() => {
        const response = await getAsync("/offer/" + this.props.match.params.projectId);
        if (response?.status === 200) {
            this.setState({offer: response.data});
            return response.data?.id;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
            // if no offer has been sent, server returns 404, otherwise an error occurred and participation will not be allowed
        } else if (response?.status !== 404) {
            this.setState({hasError: true});
        } else {
            this.setState({offer: null});
        }
    }

    loadPriceOffer = async(offerId) => {
        const response = await getAsync("/priceoffer/" + offerId);
        if (response?.status === 200) {
            if (response.data?.priceOfferVos && response.data.priceOfferVos.length > 0) {
                this.setState({priceOffer: response.data.priceOfferVos[0]})
            }
        } else if ([401, 403].includes(response?.status)) {
            this.context.logout();
        }
    }

    updateOffer = async(newStatus) => {
        this.setState({showOfferSubmitDialog: false, showOfferRevokeDialog: false});
        const offer = this.state.offer;
        offer.status = newStatus;
        const response = await putAsync("/offer", offer);
        if (response?.status === 200) {
            if (newStatus === OFFER_STATUS_SUBMITTED) {
                createSuccessMessage(this.props.intl.formatMessage({id: "bidder.offerSubmit.submit.successMessage"}), this.props);
            } else if (newStatus === OFFER_STATUS_PARTICIPATING) {
                createSuccessMessage(this.props.intl.formatMessage({id: "bidder.offerSubmit.revoke.successMessage"}), this.props);
            }
            await this.loadOffer();
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else if (response?.status === 405) {
            createErrorMessage(this.props.intl.formatMessage({id: "bidder.offerSubmit.unreadMessages.error"}), this.props);
        } else {
            await this.loadProject();
            if (!this.state.notFound) {
                if (newStatus === OFFER_STATUS_SUBMITTED) {
                    createErrorMessage(this.props.intl.formatMessage({id: "bidder.offerSubmit.submit.errorMessage"}), this.props);
                } else if (newStatus === OFFER_STATUS_PARTICIPATING) {
                    createErrorMessage(this.props.intl.formatMessage({id: "bidder.offerSubmit.revoke.errorMessage"}), this.props);
                }
                await this.loadOffer();
            }
        }
    }

    downloadReceipt = async(receiptId) => {
        this.setState({isExporting: true});
        let response = await downloadUsingGet("/receipt/export/" + receiptId + "/" + DE);
        if (response?.status === 200) {
            const fileName = response.headers['content-disposition'].split("filename=")[1];
            fileDownload(response.data, fileName);
            this.setState({isExporting: false});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else {
            await this.loadProject();
            this.setState({isExporting: false});
        }
    }

    offerCard = () => {
        if (!this.state.offer) {
            return <div/>;
        }
        const hasDocuments = this.state.offer.documents && this.state.offer.documents.length > 0;
        const suitabilityDocuments = getAllCriteriaDocuments(this.state.offer.suitabilityDocuments);
        const ratingDocuments = getAllCriteriaDocuments(this.state.offer.ratingDocuments);
        const hasSuitabilityDocuments = suitabilityDocuments.length > 0;

        const receipts = this.state.offer.receipts;
        let latestReceipt;
        if (receipts && receipts.length > 0) {
            latestReceipt = receipts[0];
            for (let i = 1; i < receipts.length; i++) {
                if (receipts[i].created && receipts[i].created && receipts[i].created > latestReceipt.created) {
                    latestReceipt = receipts[i];
                }
            }
        }
        let title;
        let subtitle;
        const offerStatus = this.state.offer.status;
        if (offerStatus === OFFER_STATUS_SUBMITTED) {
            title = "bidder.offerSubmit.offerWithDocuments.title";
            subtitle = "bidder.offerSubmit.offerWithDocuments.subtitle";
        } else if (!hasAllSuitabilityDocuments(this.state.project?.procedure?.suitabilityCriteria, this.state.offer?.suitabilityDocuments)) {
            title = "bidder.offerSubmit.offer.title";
            subtitle = "bidder.offerSubmit.offerWithMissingSuitabilityDocuments.subtitle";
        } else if (!hasAllRatingDocuments(this.state.project?.procedure?.ratingCriteria, this.state.offer?.ratingDocuments)) {
            title = "bidder.offerSubmit.offer.title";
            subtitle = "bidder.offerSubmit.offerWithMissingRatingDocuments.subtitle";
        } else if (isPriceOfferIncomplete(this.state.priceOffer)) {
            title = "bidder.offerSubmit.offerWithDocuments.title";
            subtitle = "bidder.offerSubmit.offerButton.priceOfferIncomplete.tooltip";
        } else if (!this.state.readAllMessages) {
            title = "bidder.offerSubmit.offerWithDocuments.title";
            subtitle = "bidder.offerSubmit.unreadMessages.subtitle";
        } else {
            title = "bidder.offerSubmit.offerWithDocuments.title";
            subtitle = "bidder.offerSubmit.offerWithDocuments.subtitle";
        }

        return <Card>
            <CardContent>

            <Grid container wrap="nowrap" justifyContent="space-between" spacing={4} key={"offer"}>
                <Grid item xs>
                    <Typography variant="h3">
                        {this.props.intl.formatMessage({id: title})}
                    </Typography>
                    <Typography variant="subtitle2">
                        {this.props.intl.formatMessage({id: subtitle})}
                    </Typography>
                </Grid>
                <Grid item>
                    {this.offerButton()}
                </Grid>
            </Grid>

            {hasDocuments && <Grid container justifyContent="space-between" spacing={1} key={"offerDocumentOverview"}>
                {this.state.offer.documents.map(d => <Grid key={d.id} item xs={12}>{buildDocumentSummary(this.props.intl, d)}</Grid>)}
            </Grid>}

            {hasSuitabilityDocuments && <Grid container justifyContent="space-between" spacing={1} key={"offerSuitabilityDocumentOverview"}>
                {suitabilityDocuments.map(d => <Grid key={d.id} item xs={12}>{buildDocumentSummary(this.props.intl, d)}</Grid>)}
            </Grid>}

            {ratingDocuments.length > 0 && <Grid container justifyContent="space-between" spacing={1} key={"offerRatingDocumentOverview"}>
                {ratingDocuments.map(d => <Grid key={d.id} item xs={12}>{buildDocumentSummary(this.props.intl, d)}</Grid>)}
            </Grid>}

            {latestReceipt && <div className="offerReceipt">
                <Grid container wrap="nowrap" justifyContent="space-between" spacing={4} key={"receipt"}>
                    <Grid item xs>
                        <Typography variant="h3">
                            {this.props.intl.formatMessage({id: "bidder.offerSubmit.currentOfferStatus"}) + ": "}
                        </Typography>
                        <Typography variant="subtitle2">
                            {this.props.intl.formatMessage({id: "bidder.offerSubmit.currentOfferStatus." + (offerStatus === OFFER_STATUS_PARTICIPATING ? "revoked" : "submitted")})}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button 
                            variant="contained"
                            color="primary" 
                            onClick={() => this.downloadReceipt(latestReceipt.id)}>
                            {this.props.intl.formatMessage({id: "bidder.offerSubmit.downloadReceipt.button"})}
                        </Button>
                    </Grid>
                </Grid>
            </div>}

            </CardContent>
        </Card>;
    }

    offerButton = () => {
        const {project, offer, priceOffer} = this.state;
        const offerStatus = offer?.status;
        const formatMessage = this.props.intl.formatMessage;
        let activeProject = getActiveProjectByOperationId(this.context, project.operationId);
        let isPrevious = activeProject?.id !== project.id;

        const disabledTooltip = offerDisabledTooltip(project, offer, isPrevious, formatMessage);
        const incompleteTooltip = offerIncompleteTooltip(project, offer, priceOffer, formatMessage);

        const disabled = disabledTooltip || incompleteTooltip || this.state.isLoading || !this.state.readAllMessages;

        let buttonText;
        let onClickFunction;
        if (offerStatus === OFFER_STATUS_PARTICIPATING) {
            buttonText = "bidder.offerSubmit.submit.button";
            onClickFunction = () => this.setState({showOfferSubmitDialog: true});
        } else {
            buttonText = "bidder.offerSubmit.revoke.button";
            onClickFunction = () => this.setState({showOfferRevokeDialog: true});
        }
        const button = <Button 
            variant="contained"
            color="primary" 
            onClick={() => onClickFunction()} 
            disabled={!!disabled}>
            {formatMessage({id: buttonText})}
        </Button>;

        let tooltipTitle;

        if (!this.state.readAllMessages) {
            tooltipTitle = this.props.intl.formatMessage({id: "bidder.offerSubmit.unreadMessages.tooltip"})
        } else if (disabledTooltip) {
            tooltipTitle = disabledTooltip;
        } else if (incompleteTooltip) {
            tooltipTitle = incompleteTooltip;
        }
        
        if (tooltipTitle) {
            return <Tooltip title={tooltipTitle ?? ""}><div>{button}</div></Tooltip>;
        } else {
            return button;
        }
    }

    render() {
        return <>

            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.state.notFound}
                titleId="commons.projectNotFound.title"
                subtitleId="commons.projectNotFound.subtitle"
                actionId="commons.toBidderDashboard.button"
                onAction={() => {
                    this.context.setUnsavedChanges(false);
                    this.props.history.push(BIDDER_DASHBOARD_ROUTE);
                    this.context.reloadAppData();
                }}/>

            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.state.showOfferSubmitDialog}
                titleId="bidder.offerSubmit.submitOfferDialog.title"
                subtitleId="bidder.offerSubmit.submitOfferDialog.subtitle"
                cancelId="commons.no.button"
                actionId="commons.yes.button"
                onCancel={() => this.setState({showOfferSubmitDialog: false})}
                onAction={() => this.updateOffer(OFFER_STATUS_SUBMITTED)}/>

            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.state.showOfferRevokeDialog}
                titleId="bidder.offerSubmit.revokeOfferDialog.title"
                subtitleId="bidder.offerSubmit.revokeOfferDialog.subtitle"
                cancelId="commons.no.button"
                actionId="commons.yes.button"
                onCancel={() => this.setState({showOfferRevokeDialog: false})}
                onAction={() => this.updateOffer(OFFER_STATUS_PARTICIPATING)}/>

            <SimpleTextCard show={this.state.hasError} textId="bidder.offerSubmit.error.subtitle"/>

            <SimpleTextCard show={!this.state.hasError && !this.state.offer} textId="bidder.offerSubmit.noOffer.subtitle"/>

            {this.state.offer && !this.state.hasError && 
            <Header titleId="bidder.offerSubmit.firstRound.title" subtitleId="bidder.offerSubmit.subtitle"/>}

            {this.offerCard()}

        </>;
    }
}

export default injectIntl(withSnackbar(withRouter(BidderOfferSubmit)));
