import React, {Component} from "react";

import {FormattedMessage, injectIntl} from "react-intl";
import {getAsyncCatch} from "../../services/BackendService";
import {Button, Card, CardContent, Typography} from "@material-ui/core";
import {
    BIDDER_DASHBOARD_ROUTE,
    COCKPIT_APP,
    DIALOG_TYPE_WARNING,
    OFFER_STATUS_ACCEPTED,
    OFFER_STATUS_NONE
} from "../../util/Constants";
import {withSnackbar} from 'notistack';

import "../../css/BidderProjectDetails.css";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import {Header} from "../uiLibrary/Header";
import {format} from 'date-fns';
import {GeneralContext} from "../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import { Stack} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import {getActiveProjectByOperationId, getPublishedProjectById} from "../../services/ProjectService";


class BidderOfferResult extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            offer: null,
            project: null,
            isInitializing: true
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match.params.operationId !== prevProps.match.params.operationId) {
            await this.loadData();
        }
    }

    getProjectId = () => {
        return getActiveProjectByOperationId(this.context, this.props.match.params.operationId)?.id;
    }

    loadData = async() => {
        this.setState({isInitializing: true});
        await this.setState({project: await getPublishedProjectById(this.context, this.props, this.getProjectId())});
        await this.loadOffer();
        this.setState({isInitializing: false});
    }

    loadOffer = async() => {
        const offer = await getAsyncCatch(this.context, "/offer/" + this.getProjectId(), this.props);
        this.setState({offer});
    }

    getOfferStatus = () => {
        const {offer} = this.state;
        if (offer) {
            return offer.status;
        } else {
            return OFFER_STATUS_NONE;
        }
    }

    render() {
        const {project, isInitializing} = this.state;
        const noProject = !this.state.isInitializing && project === null;

        const projectStatus = project?.status ?? "IS_PUBLISHED";
        const textId = "bidder.offerResult." + projectStatus + ".first." + this.getOfferStatus();

        const deadline = project?.procedure?.firstOfferDeadline;
        const deadlineDate = deadline ? format(deadline, "dd.MM.yyyy") : '';


        return <>

            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={noProject}
                titleId="commons.projectNotFound.title"
                subtitleId="commons.projectNotFound.subtitle"
                actionId="commons.toBidderDashboard.button"
                onAction={() => {
                    this.context.setUnsavedChanges(false);
                    this.props.history.push(BIDDER_DASHBOARD_ROUTE);
                    this.context.reloadAppData();
                }}/>

            <Header titleId="bidder.offerSubmit.result.title"/>

            {!isInitializing &&
                <Card>
                    <CardContent>
                        <Stack>

                            <Typography variant="h3">
                                {this.props.intl.formatMessage({id: "bidder.offerSubmit.result.status.title"}) + ": "}
                            </Typography>
                            <Typography variant="subtitle2">
                                <FormattedMessage id={textId} values={{date: deadlineDate}}/>
                            </Typography>


                            {this.getOfferStatus() === OFFER_STATUS_ACCEPTED &&
                                <>
                                    <Button
                                        onClick={() => this.context.setActiveApp(COCKPIT_APP)}
                                        variant="contained" color="primary"
                                        style={{marginTop: 10, maxWidth: "300px"}}
                                        endIcon={<DoubleArrowIcon/>}>
                                        <FormattedMessage id="offerResult.changeApp.button"/>
                                    </Button>
                                </>
                            }

                        </Stack>
                    </CardContent>
                </Card>
            }

        </>
    }
}

export default injectIntl(withSnackbar(withRouter(BidderOfferResult)));
