import { TableCell, TableRow } from "@material-ui/core";
import { useIntl } from "react-intl";
import { ARTICLE_OFFSETTINGS, PriceType } from "../../../util/Constants";
import { offerDisabledTooltip } from "../../../util/ProcedureUtil";
import {formatPrice, isTender} from "../../../util/Util";
import { renderImage, renderOffsetting } from "../../../util/TableUtil";
import { PriceSheetTable } from './PriceSheetTable';
import { useEffect, useState } from "react";
import ArticleDetail from "../../share/ArticleDetail";
import ArticleButtonCell from "../cells/ArticleButtonCell";
import ArticleNumberCell from "../cells/ArticleNumberCell";
import ArticlePriceCell from "../cells/ArticlePriceCell";
import AlternateDescriptionCell from "../cells/AlternateDescriptionCell";

const PriceSheetRentalLinenTable = ({
    readOnly,
    singlePriceOnly,
    allArticleNumbers,
    editable,
    exportType,
    lockArticleNumbers,
    lockPrices,
    priceGroupMap,
    project,
    resetAllArticleNumbers,
    rentalLinen,
    show,
    updatePriceSheet,
    // following prop is only used for bidders
    offer,
    isPrevious
}) => {

    const [rentalLinenMap, setRentalLinenMap] = useState(priceGroupMap ? JSON.parse(JSON.stringify(priceGroupMap)) : null);
    const [isEditing, setIsEditing] = useState(false);
    const [articleForDetailView, setArticleForDetailsView] = useState(null);

    useEffect(() => {
        setRentalLinenMap(priceGroupMap ? JSON.parse(JSON.stringify(priceGroupMap)) : null);
    }, [priceGroupMap])

    const intl = useIntl();

    if (!show) {
        return (<div/>);
    }

    const showArticleDetails = (article) => {
        setArticleForDetailsView(article);
    }

    const hideArticleDetails = () => {
        setArticleForDetailsView(null);
    }

    const handleItemChange = (newRentalLinen, category, offsetting) => {
        let map = rentalLinenMap;
        map[category][offsetting][newRentalLinen.objectId] = newRentalLinen;
        setRentalLinenMap({...map});
    }

    const resetItemMap = () => {
        setRentalLinenMap(JSON.parse(JSON.stringify(priceGroupMap)));
        resetAllArticleNumbers();
    }

    let items = [];

    items.push(
        <ArticleDetail
            key={articleForDetailView}
            article={articleForDetailView}
            showDialog={articleForDetailView !== null && articleForDetailView !== undefined}
            handleDialogClose={hideArticleDetails}
        />
    );

    let mappingByCategory = new Map(Object.entries(rentalLinen));

    if (mappingByCategory.size > 0) {

        for (let [category, offsettingObject] of mappingByCategory.entries()) {

            let offsetMapping = new Map(Object.entries(offsettingObject));

            for (let [offsetting, pricesheetArticles] of offsetMapping) {

                if (!rentalLinenMap || !rentalLinenMap[category] || !rentalLinenMap[category][offsetting]) {
                    break;
                }

                const type = category + offsetting;
                const disabled = !isEditing || (!editable && isTender() && offerDisabledTooltip(project, offer,isPrevious, intl.formatMessage));

                const priceType = offsetting === ARTICLE_OFFSETTINGS.FLAT_RATE ? PriceType.ARTICLE_WEEKLY : PriceType.ARTICLE_QUANTITY;

                let headerTitles = ["entities.article.description", "entities.article.image", "articleOffsetting.offsetting.offsetting"];
                if (!singlePriceOnly) {
                    headerTitles.push(offsetting === ARTICLE_OFFSETTINGS.FLAT_RATE ? "priceSheet.table.titles.equipQuantity" : "priceSheet.table.titles.deliveredQuantity");
                }

                items.push(
                    
                    <PriceSheetTable
                        key={type}
                        type="rentalLinen"
                        title={intl.formatMessage({id: "constants.Category." + category}) + " - " + intl.formatMessage({id: "constants.ArticleOffsetting." + offsetting})}
                        headerTitles={headerTitles}
                        editButtonLabel={lockArticleNumbers && lockPrices ? "bidder.priceSheet.editAlternateLabels.button" : null}
                        rows={pricesheetArticles.sort((a,b) => a.articleVo.description.localeCompare(b.articleVo.description)).map(pricesheetArticle => {

                            const priceItem = rentalLinenMap[category][offsetting].priceItemMap[pricesheetArticle.articleVo.id];

                            return (
                                <TableRow key={pricesheetArticle.articleVo.id+type}>

                                    <TableCell>{pricesheetArticle.articleVo.description}</TableCell>

                                    <TableCell>{renderImage(pricesheetArticle.articleVo, showArticleDetails)}</TableCell>

                                    <TableCell>{renderOffsetting(offsetting, pricesheetArticle.offsetting.articleCategoryOffsetting, intl)}</TableCell>

                                    {!singlePriceOnly && <TableCell>{pricesheetArticle.quantity}</TableCell>}

                                    <TableCell>
                                        <ArticlePriceCell 
                                            disabled={disabled || lockPrices}
                                            exportType={exportType}
                                            priceItem={priceItem}
                                            handleItemChange={(newRentalLinen) => handleItemChange(newRentalLinen, category, offsetting)}/>
                                    </TableCell>

                                    {!singlePriceOnly && <TableCell>{isEditing ? "" : formatPrice(priceItem?.sumNet)}</TableCell>}

                                    {!singlePriceOnly && <TableCell>{isEditing ? "" : formatPrice(priceItem?.sumGross)}</TableCell>}

                                    <TableCell>
                                        <AlternateDescriptionCell
                                            disabled={disabled}
                                            exportType={exportType}
                                            priceItem={priceItem}
                                            handleItemChange={(newRentalLinen) => handleItemChange(newRentalLinen, category, offsetting)}/>
                                    </TableCell>

                                    <TableCell>
                                        <ArticleNumberCell
                                            allArticleNumbers={allArticleNumbers} 
                                            disabled={disabled || lockArticleNumbers}
                                            exportType={exportType} 
                                            priceItem={priceItem}
                                            handleItemChange={(newRentalLinen) => handleItemChange(newRentalLinen, category, offsetting)}/>
                                    </TableCell>

                                    <TableCell>
                                        <ArticleButtonCell
                                            allArticleNumbers={allArticleNumbers} 
                                            disabled={disabled || lockArticleNumbers}
                                            exportType={exportType}
                                            priceItem={priceItem}
                                            handleItemChange={(newRentalLinen) => handleItemChange(newRentalLinen, category, offsetting)}/>
                                    </TableCell>

                                </TableRow>
                            )
                        })}
                        isEditing={isEditing}
                        readOnly={readOnly}
                        singlePriceOnly={singlePriceOnly}
                        offer={offer}
                        project={project}
                        priceGroup={priceGroupMap[category][offsetting]}
                        onSave={() => updatePriceSheet(priceType, rentalLinenMap[category][offsetting].priceItemMap)}
                        resetItemMap={() => resetItemMap()}
                        setIsEditing={(value) => setIsEditing(value)}
                        editable={editable}
                    />
                );
            }
        }
    }
    return items;
}

export {
    PriceSheetRentalLinenTable
}
