import {Button, Grid, Typography} from "@material-ui/core";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { createErrorMessage, createSuccessMessage } from "../../../../util/Util";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import { GeneralContext } from "../../../contexts/GeneralContext";
import {DIALOG_TYPE_WARNING, LICENSE_TYPES} from "../../../../util/Constants";
import {isOfferUser} from "../../../../services/UserService";
import EffectiveFromDatePicker from "../components/EffectiveFromDatePicker";
import {getActiveOperation, startWorkingState} from "../../../../services/OperationService";
import InviteBidderButton from "../buttons/InviteBidderButton";
import {latestActivePayment} from "../../../../services/PaymentService";

class ContractCreateHeader extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            effectiveFrom: null,
            showDialog: false,
            isCreating: false
        }
    }

    onConfirm = async(activeOperation) => {
        this.setState({isCreating: true});
        await startWorkingState(
            this.context,
            activeOperation,
            this.props.newestProjectId,
            this.state.effectiveFrom,
            () => createSuccessMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.start.successMessage"}), this.props),
            () => createErrorMessage(this.props.intl.formatMessage({id: "cockpit.contractStatus.start.errorMessage"}), this.props));
        this.setState({effectiveFrom: null, showDialog: false, isCreating: false});
    }

    render() {

        const activeOperation = getActiveOperation(this.context);
        const hasBidder = isOfferUser(activeOperation?.bidder);
        const hasCooperationLicense = latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE);

        return (
            <>
                 <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDialog}
                    style={{}}
                    titleId="cockpit.contractStatus.dialogTitle.start"
                    subtitleId={hasBidder ? "cockpit.contractStatus.dialogSubtitle.withBidder.start" : "cockpit.contractStatus.dialogSubtitle.noBidder.start"}
                    cancelId="commons.no.button"
                    actionId="commons.yes.button"
                    content={
                        <>
                            <EffectiveFromDatePicker
                                effectiveFrom={this.state.effectiveFrom}
                                newestEffectiveFrom={this.props.newestEffectiveFrom}
                                allowPast={!hasBidder}
                                startOfServiceDate={this.props.contract?.startOfServiceDate}
                                onChanged={(newDate) => this.setState({effectiveFrom: newDate})}/>
                            <br/>
                        </>
                    }
                    onCancel={() => this.setState({effectiveFrom: null, showDialog: false})}
                    cancelDisabled={this.state.isCreating}
                    onAction={() => this.onConfirm(activeOperation)}
                    actionDisabled={(!isOfferUser(this.context.currentUser) && !hasCooperationLicense)
                        || this.state.isCreating || !this.state.effectiveFrom}/>

                <Typography variant="h1">
                    <FormattedMessage id="cockpit.contractStatus.title"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id={hasBidder
                        ? "cockpit.contractStatus.active.withBidder.subtitle" : "cockpit.contractStatus.active.noBidder.subtitle"}/>
                </Typography>

                <Grid container spacing={2}>

                    {!hasBidder && <Grid item>
                        <InviteBidderButton/>
                    </Grid>}

                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={this.state.isCreating}
                            onClick={() => this.setState({showDialog: true})}>
                            <FormattedMessage id="cockpit.contractStatus.start.button"/>
                        </Button>
                    </Grid>

                </Grid>
            </>
        );
    }
}

export default withSnackbar(injectIntl(ContractCreateHeader));
