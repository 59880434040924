import React, {Component} from 'react';
import {GeneralContext} from "../../../contexts/GeneralContext";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import InviteBidderButton from "../buttons/InviteBidderButton";
import SelfAcceptButton from "../buttons/SelfAcceptButton";
import PropTypes from "prop-types";
import {withSnackbar} from "notistack";
import DeleteWorkingProjectButton from "../buttons/DeleteWorkingProjectButton";
import ChangeEffectiveFromButton from "../buttons/ChangeEffectiveFromButton";
import {getActiveOperation} from "../../../../services/OperationService";
import {formatDate} from "../../../../util/DocumentUtil";

class ContractInviteOrSaveHeader extends Component {

    static contextType = GeneralContext;

    render() {
        const effectiveFrom = getActiveOperation(this.context).workingProject?.effectiveFrom;
        return (
            <>
                <Typography variant="h1">
                    <FormattedMessage id="cockpit.contractStatus.title"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id="cockpit.contractStatus.own.directProject.subtitle"/>
                </Typography>

                {effectiveFrom && effectiveFrom > 1 && <Typography variant="subtitle1" style={{marginBottom: 20}}>
                    <FormattedMessage
                        id="cockpit.contractStatus.effectiveFrom.title"
                        values={{effectiveFrom: formatDate(this.props.intl, effectiveFrom)}}/>
                </Typography>}

                <Grid container spacing={2}>

                    <Grid item>
                        <InviteBidderButton
                            disabled={this.props.hasNoChanges || this.props.isPriceOfferIncomplete || this.props.isWorkingProjectIncomplete}/>
                    </Grid>

                    <Grid item>
                        <ChangeEffectiveFromButton
                            newestEffectiveFrom={this.props.newestEffectiveFrom}
                            onSave={this.props.onEffectiveFromChanged}/>
                    </Grid>

                    <Grid item>
                        <SelfAcceptButton
                            newestEffectiveFrom={this.props.newestEffectiveFrom}
                            hasNoChanges={this.props.hasNoChanges}
                            isPriceOfferIncomplete={this.props.isPriceOfferIncomplete}
                            isWorkingProjectIncomplete={this.props.isWorkingProjectIncomplete}/>
                    </Grid>

                    {effectiveFrom && effectiveFrom > 1 && <Grid item>
                        <DeleteWorkingProjectButton/>
                    </Grid>}

                </Grid>
            </>
        );
    }
}

ContractInviteOrSaveHeader.propTypes = {
    newestEffectiveFrom: PropTypes.number.isRequired,
    onEffectiveFromChanged: PropTypes.func.isRequired,
    hasNoChanges: PropTypes.bool.isRequired,
    isPriceOfferIncomplete: PropTypes.bool.isRequired,
    isWorkingProjectIncomplete: PropTypes.bool.isRequired
};

export default injectIntl(withSnackbar(ContractInviteOrSaveHeader));
