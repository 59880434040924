import {getDataPoint, getStatisticItemsByInvoice} from "../../../../services/StatisticsService";

export const InvoiceStatisticsBarData = ({
    sortedInvoices,
    statisticItemMap,
    selectedNodes,
    selectedTab
 }) => {

    let data = [];
    let dataKeys = new Set();

    if (selectedNodes.length === 0 || !sortedInvoices || sortedInvoices.length === 0) {
        return [null, null];
    }

    sortedInvoices.forEach(invoice => {
        const dataPoints = {invoiceId: invoice.id};
        const statisticItems = getStatisticItemsByInvoice(invoice.id, selectedNodes, statisticItemMap);

        statisticItems.forEach(item => {
            dataPoints[item.key] = getDataPoint(item, selectedTab);
            dataKeys.add(item.key);
        })

        data.push(dataPoints);
    });
    return [data, dataKeys];
}
