import React, {Component} from "react";
import {ARTICLE_CATEGORIES, DIALOG_TYPE_INFO} from "../../../util/Constants";
import {
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    Tooltip
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {InfoOutlined} from "@mui/icons-material";
import ArticleConfigurationSelector from "./ArticleConfigurationSelector";
import {renderImage} from "../../../util/TableUtil";
import TexisionDialog from "../../uiLibrary/TexisionDialog";
import {GeneralContext} from "../../contexts/GeneralContext";
import {arraysEqual} from "../../../util/Util";

class ArticleEditDialog extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = this.buildState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.article !== this.props.article) {
            this.setState(this.buildState())
        }
    }

    buildState = () => {
        let article = this.props.article;
        if (!article) {
            return {
                newFilterCategories: [],
                selectedArticleConfiguration: {}
            }
        }
        let articleConfiguration = this.getArticleConfiguration(article);
        let filterCategories = this.fillArticleCategories(article, articleConfiguration);

        return {
            selectedArticleConfiguration: articleConfiguration,
            newFilterCategories: filterCategories
        };
    }

    getArticleConfiguration = (article) => {
        return this.props.articleConfigurations?.find(a => a.id === article?.articleConfigurationId);
    }

    initializeFilterCategories = (articleConfiguration) => {
        let initializedFilterCategories = [];
        if (articleConfiguration?.filterCategories?.length) {
            for (let fc of articleConfiguration.filterCategories) {
                let fcWithEmptyOptions = JSON.parse(JSON.stringify(fc));
                // pre-selection for categories with only one option
                if (fcWithEmptyOptions.filterOptions.length > 1) {
                    // clear initial selection for categories with two or more options
                    fcWithEmptyOptions.filterOptions = [];
                }
                initializedFilterCategories.push(fcWithEmptyOptions);
            }
        }
        return initializedFilterCategories;
    }

    fillArticleCategories = (article, articleConfiguration) => {
        let filterCategories = this.initializeFilterCategories(articleConfiguration);

        articleConfiguration?.filterCategories.forEach(targetCategory => {
            let checkedItems = targetCategory.filterOptions.filter(targetOption => {
                let sourceOption = this.props.article.filterOptions
                    .find(o => o.id === targetOption.id && o.filterCategoryId === targetCategory.id);
                return sourceOption !== null && sourceOption !== undefined;
            });
            let filterCategory = filterCategories?.find(nfc => nfc.id === targetCategory.id);
            filterCategory.filterOptions = checkedItems;
        });
        return filterCategories;
    }

    setFilterOption = (optionCategories, option, checked, isMultipleChoice) => {
        let filterCategory = optionCategories?.find(nfc => nfc.id === option.filterCategoryId);
        if (!filterCategory?.filterOptions) {
            return;
        }
        if (checked && isMultipleChoice) {
            filterCategory.filterOptions = [...filterCategory.filterOptions, option];
        } else if (checked && !isMultipleChoice) {
            filterCategory.filterOptions = [option];
        } else {
            filterCategory.filterOptions = filterCategory.filterOptions.filter(o => o.id !== option.id);
        }
        return optionCategories?.map(nfc => nfc.id === option.filterCategoryId ? filterCategory : nfc);
    }

    handleOptionChanged = (option, checked, isMultipleChoice) => {
        const newFilterCategories = this.setFilterOption(this.state.newFilterCategories, option, checked, isMultipleChoice);
        this.setState({newFilterCategories});
    }

    resetFilterOptions = () => {
        this.setState(this.buildState());
    }

    handleCategoriesSelected = (articleConfiguration) => {
        let initializedFilterCategories = this.initializeFilterCategories(articleConfiguration);
        this.setState({
            newFilterCategories: initializedFilterCategories,
            selectedArticleConfiguration: articleConfiguration
        });
    }

    isOptionSelected = (filterCategory, option) => {
        return !!this.state.newFilterCategories?.find(n => n.filterCategoryType === filterCategory.filterCategoryType)
            ?.filterOptions?.find(o => o.filterCategoryId === filterCategory.id && o.name === option.name && o.id === option.id);
    }

    areAllOptionsSelected = (filterCategory) => {
        for (let option of filterCategory.filterOptions) {
            if (!this.isOptionSelected(filterCategory, option)) {
                return false;
            }
        }
        return true;
    }

    // check that every mandatory option has been selected
    // check that some option has changed (in case of editing)
    maySaveArticle = () => {
        return this.state.selectedArticleConfiguration?.articleCategory &&
            this.state.newFilterCategories.every((fc) => fc.isMultipleChoice || fc.filterOptions.length > 0)
            && !arraysEqual(this.props.article?.filterOptions, this.getFilterOptionsList())
    }

    getFilterOptionsList = () => {
        if (!this.state.newFilterCategories?.length) {
            return [];
        }
        let optionsList = [];
        for (let fc of this.state.newFilterCategories) {
            for (let option of fc.filterOptions) {
                if (!optionsList.find(o => o.filterCategoryId === fc.id && o.name === option.name)) {
                    optionsList.push(option);
                }
            }
        }
        return optionsList;
    }

    setFilterOptionsFromArticle = (articleSet, articleConfiguration) => {
        articleConfiguration?.filterCategories.forEach(targetCategory => {
            if (targetCategory.filterCategoryType !== "SURFACE_MEASURE") {
                targetCategory.filterOptions.forEach(targetOption => {
                    let sourceOption = articleSet.filterOptions
                        .find(o => o.name === targetOption.name);
                    let checked = sourceOption !== null && sourceOption !== undefined;
                    this.handleOptionChanged(targetOption, checked, targetOption.isMultipleChoice);
                });
            }
        });
    }

    filterOptionSelection = () => {
        let articleConfiguration = this.state.selectedArticleConfiguration;
        let articleSet;
        if (articleConfiguration) {
            if (articleConfiguration.articleCategory === ARTICLE_CATEGORIES.DUVET_COVER) {
                articleSet = this.findAssignedArticle(articleConfiguration.category, articleConfiguration.subCategory, ARTICLE_CATEGORIES.PILLOW_COVER);
            } else if (articleConfiguration.articleCategory === ARTICLE_CATEGORIES.PILLOW_COVER) {
                articleSet = this.findAssignedArticle(articleConfiguration.category, articleConfiguration.subCategory, ARTICLE_CATEGORIES.DUVET_COVER);
            }
        }

        return (
            <Grid container style={{marginTop: 20, marginLeft: 0}} spacing={4}>
                {articleSet !== null && articleSet !== undefined &&
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" spacing={3}>
                            <Grid item xs={5}>
                                <Grid item xs={12} style={{marginTop: 10}}>
                                    <FormattedMessage id={"rentalLinen.assignment.article.set.button"}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={7}>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => this.setFilterOptionsFromArticle(articleSet, articleConfiguration)}>
                                    {articleSet.description}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {articleConfiguration?.filterCategories?.map(fc => <Grid item xs={12} className="gridWithLine">
                    <Grid container alignItems="flex-start" spacing={3}>

                        <Grid item xs={4}>
                            <Grid container spacing={1}>

                                <Grid item xs={12} style={{marginTop: 10}}>
                                    {this.props.intl.formatMessage({id: "constants.FilterCategory." + fc.filterCategoryType})
                                        + (fc.isMultipleChoice
                                            ? (" (" + this.props.intl.formatMessage({id: "rentalLinen.assignment.filter.multipleChoice.hint"}) + ")")
                                            : "*")}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={7}>
                            <Grid container justifyContent="flex-start">
                                {fc.isMultipleChoice && fc.filterOptions?.length && fc.filterOptions.length > 1 && <Grid item xs={12}>
                                    <Checkbox
                                        color="primary"
                                        variant="outlined"
                                        checked={this.areAllOptionsSelected(fc)}
                                        onClick={() => this.areAllOptionsSelected(fc)
                                            ? fc.filterOptions.forEach(o => this.handleOptionChanged(o, false, fc.isMultipleChoice))
                                            : fc.filterOptions.forEach(o => this.handleOptionChanged(o, true, fc.isMultipleChoice))}/>
                                    <FormattedMessage id={"rentalLinen.assignment.filter.all"}/>
                                </Grid>}
                                {fc.filterOptions?.map(option => <Grid item>
                                    {fc.isMultipleChoice
                                        ? <>
                                            <Checkbox
                                                disabled={fc.filterOptions.length === 1}
                                                checked={this.isOptionSelected(fc, option)}
                                                color="primary"
                                                onChange={(e) => this.handleOptionChanged(option, e.target.checked, true)}/>
                                            {option.name}
                                        </>
                                        : <FormControlLabel
                                            disabled={fc.filterOptions.length === 1}
                                            checked={this.isOptionSelected(fc, option)}
                                            value={option}
                                            style={{marginLeft: 0}}
                                            onChange={(e, checked) => this.handleOptionChanged(option, checked, false)}
                                            control={<Radio color="primary"/>}
                                            label={option.name}/>
                                    }
                                </Grid>)}
                            </Grid>
                        </Grid>

                        <Grid item xs={1}>
                            {fc.isMultipleChoice &&
                                <Tooltip title={this.areAllOptionsSelected(fc)
                                    ? this.props.intl.formatMessage({id: "rentalLinen.assignment.bidderFriendly.tooltip"})
                                    : this.props.intl.formatMessage({id: "rentalLinen.assignment.bidderFriendly.button"})}>
                                    <InfoOutlined color={this.areAllOptionsSelected(fc) ? "primary" : "action"} style={{marginTop: 0}}/>
                                </Tooltip>}
                        </Grid>

                    </Grid>

                </Grid>)}

                <Grid item xs={12} style={{marginTop: 20}}>
                    <FormattedMessage id="articleConfigurator.filterOptions.mandatory"/>
                </Grid>

            </Grid>
        );
    }

    articleForm = () => {
        const articleConfiguration = this.state.selectedArticleConfiguration;
        return (
            <>
                <Card>
                    <ArticleConfigurationSelector
                        key={"article-configurator-selector-" + this.props.key}
                        articleConfigurations={this.props.articleConfigurations}
                        value={articleConfiguration}
                        onSelect={(articleConfiguration) => this.handleCategoriesSelected(articleConfiguration)}
                    />

                    {articleConfiguration && <Grid item style={{width: 200, marginTop: 20}}>
                        {renderImage(articleConfiguration, () => this.setState({articleForDetailView: articleConfiguration}))}
                    </Grid>}

                    {!!articleConfiguration?.articleCategory && <Grid item xs={12}>
                        <InputLabel
                            htmlFor="filter-categories-native-simple"
                            style={{marginTop: "20px", marginBottom: 5}}>
                            <FormattedMessage id="rentalLinen.assignment.filter.title"/> *
                        </InputLabel>
                    </Grid>}

                    {!!articleConfiguration?.articleCategory && <Grid item xs={12} style={{marginTop: 15}}>
                        {this.filterOptionSelection()}
                    </Grid>}
                </Card>
            </>
        )
    }

    render() {
        const articleConfiguration = this.state.selectedArticleConfiguration;
        const filterOptions = this.getFilterOptionsList();
        return (
            <TexisionDialog
                open={this.props.show}
                type={DIALOG_TYPE_INFO}
                titleId={!this.props.article
                    ? "rentalLinen.assignment.add.title"
                    : "rentalLinen.assignment.save.title"}
                subtitleId={"rentalLinen.assignment.configurator." + this.props.type + ".subtitle"}
                cancelId="commons.cancel.button"
                onCancel={() => {
                    this.resetFilterOptions();
                    this.props.onCancel();
                }}
                actionId={!this.props.article
                    ? "rentalLinen.assignment.add.button"
                    : "rentalLinen.assignment.save.button"}
                onAction={() => this.props.onSubmit(articleConfiguration, filterOptions)}
                actionDisabled={!this.maySaveArticle() || this.props.readOnly}
                actionId2="articleConfigurator.filterOptions.reset.button"
                onAction2={() => this.resetFilterOptions()}
                actionDisabled2={!this.state.selectedArticleConfiguration?.articleCategory || this.props.readOnly}
                content={this.articleForm()}
            />
        )
    }
}
export default injectIntl(ArticleEditDialog);