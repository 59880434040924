import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {de} from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {InputAdornment} from "@mui/material";
import {CalendarToday} from "@material-ui/icons";
import {FormattedMessage, injectIntl} from "react-intl";
import {GeneralContext} from "../../../contexts/GeneralContext";

class EffectiveFromDatePicker extends Component {

    static contextType = GeneralContext;

    getHelperText = () => {
        if (!this.props.allowPast && (!this.props.effectiveFrom || this.props.effectiveFrom < this.getNextDate())) {
            return this.props.intl.formatMessage({id: "contractStatus.minDate.errorMessage"});
        } else {
            return "";
        }
    }

    getNextDate = () => {
        let date;
        if (this.props.allowPast || this.props.newestEffectiveFrom > new Date().getTime()) {
            date = new Date(this.props.newestEffectiveFrom);
        } else {
            date = new Date();
        }
        return new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime();
    }

    isDateInvalid = (date) => {
        return date.getDate() !== 1 || date.getTime() <= this.props.newestEffectiveFrom;
    }

    render() {
        return (
            <>
                <br/>
                <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                    <DatePicker
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CalendarToday/>
                                </InputAdornment>
                            ),
                        }}
                        helperText={this.getHelperText()}
                        shouldDisableDate={(date) => this.isDateInvalid(date)}
                        minDate={this.getNextDate()}
                        maxDate={new Date("2100-01-01")}
                        inputVariant="outlined"
                        variant="dialog"
                        label={<FormattedMessage id="cockpit.contractStatus.effectiveFrom"/>}
                        okLabel={this.props.intl.formatMessage({id: "commons.confirm.button"})}
                        cancelLabel={this.props.intl.formatMessage({id: "commons.cancel.button"})}
                        format="dd.MM.yyyy"
                        value={this.props.effectiveFrom}
                        onChange={(newDate) => this.props.onChanged(newDate.getTime())}
                    />
                </MuiPickersUtilsProvider>
            </>
        );
    }
}

EffectiveFromDatePicker.propTypes = {
    effectiveFrom: PropTypes.number.isRequired,
    newestEffectiveFrom: PropTypes.number.isRequired,
    onChanged: PropTypes.func.isRequired
};

export default injectIntl(EffectiveFromDatePicker);
