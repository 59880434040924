import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {GeneralContext} from "../../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import {Grid, Paper, Tab, Tabs, Typography} from "@material-ui/core";
import {CircularProgress} from "@mui/material";
import {texisionBlueGray} from "../../../util/ColorTheme";
import ArticleOverviewTabPanel from "../../assortment/rentalLinen/ArticleOverviewTabPanel";
import ArticleMainCategoryCard from "../../assortment/rentalLinen/ArticleMainCategoryCard";
import ArticleTabPanel from "../../assortment/rentalLinen/ArticleTabPanel";
import {loadArticleConfigurations} from "../../../services/ArticleConfigurationService";

class ArticleConfigurationOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            articleConfigurationMap: new Map(),
            numberOfArticlesMap: new Map(),
            selectedTabIndex: 0
        }
    }

    async componentDidMount() {
        await this.loadArticleConfigurations();
    }

    loadArticleConfigurations = async() => {
        this.setState({isLoading: true});
        const articleConfigurations = await loadArticleConfigurations(this.context, this.props);
        let articleConfigurationMap = new Map();
        let numberOfArticlesMap = new Map();
        if (articleConfigurations) {
            const uniqueCategorySet = new Set(articleConfigurations.map(a => a.category));
            for (let category of uniqueCategorySet) {
                articleConfigurationMap.set(category, articleConfigurations.filter(a => a.category === category));
                numberOfArticlesMap.set(category, articleConfigurations.filter(a => a.category === category)?.length ?? 0)
            }
        }
        this.setState({isLoading: false, articleConfigurationMap, numberOfArticlesMap});
    }

    handleTabChange = (event, newValue) => {
        this.setState({selectedTabIndex: newValue});
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress/>
                </div>
            );
        }

        const categories = this.state.articleConfigurationMap
            ? Array.from(this.state.articleConfigurationMap.keys())
                .sort((a,b) => this.props.intl.formatMessage({id: "constants.Category." + a})
                    .localeCompare(this.props.intl.formatMessage({id: "constants.Category." + b})))
            :  [];

        return (
          <>
              <Typography variant="h1">
                  {this.props.intl.formatMessage({id: "navBar.articleConfigurationOverview.title"})}
              </Typography>

              <Typography variant="subtitle1">
                  {this.props.intl.formatMessage({id: "articleOverview.default.subtitle"})}
              </Typography>

              <Paper
                  elevation={0}
                  square={true}
                  className="articleTabNavigation"
                  style={{borderBottomColor: texisionBlueGray, fIndex: "1"}}>

                  <Grid container>
                      <Grid item xs={9} className="tabElements">

                          <Tabs
                              value={this.state.selectedTabIndex}
                              onChange={this.handleTabChange}
                              indicatorColor="primary"
                              textColor="primary">

                              <Tab label={this.props.intl.formatMessage({id: "articleOverview.tabPanel.overview.title"})} disableRipple={true}/>

                              {categories.map(category => {
                                  return <Tab
                                      key={"tab-" + category}
                                      label={this.props.intl.formatMessage({id: "constants.Category." + category})}
                                      disableRipple={true}/>
                              })}

                          </Tabs>

                      </Grid>
                  </Grid>
              </Paper>

              <ArticleOverviewTabPanel value={this.state.selectedTabIndex} index={0}>
                  <Grid container spacing={2}>
                      {categories.map((cat, i) => {
                          return <Grid key={"main-category-card-" + cat}>
                              <ArticleMainCategoryCard
                                  category={cat}
                                  index={++i}
                                  navigateTo={this.handleTabChange}
                                  articleTotalCount={this.state.numberOfArticlesMap.get(cat)}/>
                          </Grid>
                      })}
                  </Grid>
              </ArticleOverviewTabPanel>

              {categories.map((category, i) => {
                  return <ArticleTabPanel
                      key={"tab-panel-" + category}
                      value={this.state.selectedTabIndex}
                      index={++i}
                      articles={this.state.articleConfigurationMap?.get(category) ?? []}
                      reloadData={this.loadArticleConfigurations}>
                      {this.props.intl.formatMessage({id: "constants.Category." + category})}
                  </ArticleTabPanel>
              })}
          </>
        );
    }
}

export default injectIntl(withRouter(ArticleConfigurationOverview));
