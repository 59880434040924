import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import {FormattedMessage, injectIntl} from 'react-intl';
import {get} from '../../../services/BackendService';
import {isNotEmptyOrBlank} from '../../../util/Util';
import {GeneralContext} from "../../contexts/GeneralContext";

/**
 * The generic feature Component has a type and a corresponding value. If you interested in using Features, then integrate the FeaturePanel.
 */
class CustomerArticleFeature extends Component {

    static contextType = GeneralContext;

    constructor(props){
        super(props);

        this.state = {
            type: this.props.type,
            value: this.props.value,
            allOptions: [],
            allOptionsLoaded: false // We need this flag to determine, if the allOptions have been loaded or not.
        }
    }

    handleLoadAllOptions(type) {
        get(this.props.valuesEndpoint + type).then(response => {
            if (response?.status === 200) {
                this.setState({allOptions: response.data, allOptionsLoaded: true});
            } else if ([401, 403].includes(response?.status)) {
                this.context.logout();
            }
        });
    }

    handleFeatureTypeChanged = (e) => {

        this.handleLoadAllOptions(e.target.value);

        let oldType = this.state.type;
        this.setState({type: e.target.value, value: ""});
        this.props.handleFeatureTypeChanged(oldType, e.target.value)
    }

    handleFeatureValueChanged = (newValue) => {

        if (this.state.allOptionsLoaded === false) {
            this.handleLoadAllOptions(this.state.type);
        }

        let valueToSet;
        if (!newValue) {
            valueToSet = "";
        } else {
            valueToSet = newValue;
        }
        this.setState({value: valueToSet});

        this.props.handleFeatureValueChanged(this.state.type, valueToSet);
    }

    matchesOption(input) {

        let options = this.state.allOptions;
        let optionsLength = options.length;

        for (let i = 0; i < optionsLength; i++) {
            if (options[i].startsWith(input)) {
                return true;
            }
        }
        return false;
    }

    render() {
        const filter = createFilterOptions();
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <InputLabel htmlFor="feature-typ" style={{marginTop: "10px"}}>
                            <FormattedMessage id="constants.ArticleFeatureType.type.h1"/>
                        </InputLabel>
                        <Select
                            id="feature-typ"
                            native
                            value={this.state.type}
                            onChange={(e) => this.handleFeatureTypeChanged(e)}
                            style={{width: "95%"}}
                            inputProps={{
                                name: 'featuretype',
                                id: 'featuretype-native-simple',
                            }}
                            >
                            <option aria-label="None" value="" />
                            {
                                this.props.allFeatureTypes.map((featureType) => {
                                    // since find() will cause errors, use forEach.
                                    let disabled = false;
                                    this.props.allExistingFeatureTypes.forEach(type => {
                                        if (type === featureType) {
                                            disabled = true;
                                        }
                                    });
                                    
                                    let optionValue = this.props.intl.formatMessage({id: this.props.i18nPrefix + featureType});
                                    return <option disabled={disabled} key={featureType} value={featureType}>{optionValue}</option>
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel htmlFor="typ" style={{marginTop: "10px"}}><FormattedMessage id="commons.value.label"/></InputLabel>
                        <Autocomplete
                            disabled={this.state.type === ""}
                            value={this.state.value}
                            onChange={(event, newValue) => {
                                this.handleFeatureValueChanged(newValue);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                // Suggest the creation of a new value, if not empty and the value starts not with a existing option
                                if (isNotEmptyOrBlank(params.inputValue) && !this.matchesOption(params.inputValue)) {
                                    filtered.push(params.inputValue);
                                }
                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id={this.state.type + this.state.value}
                            options={this.state.allOptions}
                            onBlur={(e) => {
                                this.handleFeatureValueChanged(e.target.value);
                            }}
                            inputprops={{maxLength: 10}}
                            renderOption={(option) => option}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" disabled={this.state.type === ""} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            return this.props.handleFeatureDelete(this.props.id, this.state.type, this.state.value, this.props.internalId)}}
                            style={{marginLeft: "10px", marginTop: "10px"}}><FormattedMessage id="commons.remove.button" /></Button>
                    </Grid>
                </Grid>
            </div>
            )
    }
}

export default injectIntl(CustomerArticleFeature);
