import { Typography } from "@material-ui/core";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { GeneralContext } from "../contexts/GeneralContext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {USE_ALTERNATE_ARTICLE_NAMES} from "../../util/Constants";
import {getRentalLinenForDeliveryAddress} from "../../services/ArticleService";

class OrderAddress extends Component {

    static contextType = GeneralContext;

    articleComponent = (contractItem) => {
        const article = contractItem.item?.articleVo;
        if (!article) {
            return null;
        }
        let articleName;
        if (!!this.context.getUserStateValue(USE_ALTERNATE_ARTICLE_NAMES)) {
            articleName = contractItem.alternateDescription ? contractItem.alternateDescription : article.description;
        } else {
            articleName = article.description;
        }
        return (
            <Grid item xs={12} key={"order-article-" + article.id}>
                <Typography variant="subtitle2">
                    {articleName}
                </Typography>
            </Grid>
        );
    }

    render() {
        const uniqueContractItems = getRentalLinenForDeliveryAddress(this.context, this.props.deliveryAddress.id)
            ?.filter((value, index, self) => index === self.findIndex(s => s.item?.articleVo?.id === value.item?.articleVo?.id))
            ?.sort((a,b) => a.item?.articleVo?.description?.localeCompare(b.item?.articleVo?.description));

        const numberOfArticles = uniqueContractItems?.length ?? 0;
    
        return (
            <>
                <Accordion elevation={0}>

                    <AccordionSummary style={{marginLeft: 10, display: "flex"}} expandIcon={<ExpandMoreIcon/>}>

                        <Grid container alignItems="center">

                            <Grid item xs>
                                <Typography variant="h3">
                                    {this.props.deliveryAddress.shortDescription}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="h3">
                                    {this.props.intl.formatMessage({id: "order.address.articles"}) + ": " + numberOfArticles}
                                </Typography>
                            </Grid>

                        </Grid>

                    </AccordionSummary>

                    <AccordionDetails style={{marginLeft: 10}}>

                        {numberOfArticles > 0
                        
                            ? <Grid container>
                                {uniqueContractItems.map(c => this.articleComponent(c))}
                            </Grid> 
                        
                            : <Typography>
                                <FormattedMessage id="order.address.noArticles"/>
                            </Typography>
                            
                        }
                    </AccordionDetails>

                </Accordion>
            </>
        );
    }
}

export default injectIntl(OrderAddress);
