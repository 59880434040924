import {getAsyncCatch, putAsyncCatch} from "./BackendService";
import {RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL} from "../util/Constants";
import {updateValidationMap} from "./ProjectService";

export async function getResidentsLaundryForUnit(context, props, unitId) {
    const businessUnitId = unitId !== null && unitId !== undefined ? unitId : context.appData.activeUnitId;
    const residentsLaundry = await getAsyncCatch(context, "/residentslaundry/" + businessUnitId, props);
    return residentsLaundry ? residentsLaundry : [];
}

export async function updateResidentsLaundry(context, props, residentsLaundry, projectId, unitId, hideSuccess) {
    let activeProjectId = projectId !== null && projectId !== undefined ? projectId : context.appData.activeProjectId;
    const businessUnitId = unitId !== null && unitId !== undefined ? unitId : context.appData.activeUnitId;
    const updatedResidentsLaundry = await putAsyncCatch(context, "/residentslaundry/" + activeProjectId + "/"
        + businessUnitId, residentsLaundry, props, hideSuccess);
    if (updatedResidentsLaundry) {
        await updateValidationMap(context);
    }
    return updatedResidentsLaundry ? updatedResidentsLaundry : null;
}

export function getCleanType(props, cleanType) {
    if (cleanType === RESIDENTS_LAUNDRY_CLEAN_TYPE_CHEMICAL) {
        return props.intl.formatMessage({id: "residentsLaundry.pricesheet.table.cleanType.chemical"});
    } else {
        return props.intl.formatMessage({id: "residentsLaundry.pricesheet.table.cleanType.wash"});
    }
}