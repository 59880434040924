import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import LogisticsTimeTable from './LogisticsTimeTable';
import {DELIVERY, PICKUP, DELIVERY_PICKUP, LOGISTIC_TYPE_CENTRAL, LOGISTIC_TYPE_DECENTRAL} from '../../../../util/Constants';

import {isInDecentralMode, isInCentralMode} from '../../../../util/Util';

import '../../../apps/App.css';
import {createTimeSlotMap, createTimeSlotsMapFromDeliveryTimesMap} from '../../../../util/LogisticsUtil';
import {GeneralContext} from "../../../contexts/GeneralContext";
import Alert from "@material-ui/lab/Alert";
import {Button} from "@material-ui/core";

class LogisticsContent extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {initializedTimeSlotMap: false};
    }

    initializeTimeSlotMap = () => {
        if (this.props.deliveryAndPickupType === LOGISTIC_TYPE_DECENTRAL) {
            return;
        }
        if (this.props.addressType === DELIVERY) {
            const centralPickUpAddress = this.props.allPickupAddresses?.find(p => p.logisticType === LOGISTIC_TYPE_CENTRAL);
            if (!centralPickUpAddress?.deliveryTimes || !Object.keys(centralPickUpAddress.deliveryTimes).length) {
                return;
            }
            const pickUpTimeSlotMap = createTimeSlotsMapFromDeliveryTimesMap(new Map(Object.entries(centralPickUpAddress.deliveryTimes)));
            this.handleChange("timeSlotMap", pickUpTimeSlotMap);
        } else if (this.props.addressType === PICKUP) {
            const centralDeliveryAddress = this.props.allDeliveryAddresses?.find(d => d.logisticType === LOGISTIC_TYPE_CENTRAL);
            if (!centralDeliveryAddress?.deliveryTimes || !Object.keys(centralDeliveryAddress.deliveryTimes).length) {
                return;
            }
            const deliveryTimeSlotMap = createTimeSlotsMapFromDeliveryTimesMap(new Map(Object.entries(centralDeliveryAddress.deliveryTimes)));
            this.handleChange("timeSlotMap", deliveryTimeSlotMap);
        }
        this.setState({initializedTimeSlotMap: true});
    }

    showCopyTimeSlotsButton = () => {
        if (this.props.deliveryAndPickupType === LOGISTIC_TYPE_DECENTRAL) {
            return false;
        }
        if (this.props.addressType === DELIVERY) {
            const centralPickUpAddress = this.props.allPickupAddresses?.find(p => p.logisticType === LOGISTIC_TYPE_CENTRAL);
            return !(!centralPickUpAddress?.deliveryTimes || !Object.keys(centralPickUpAddress.deliveryTimes).length);

        } else if (this.props.addressType === PICKUP) {
            const centralDeliveryAddress = this.props.allDeliveryAddresses?.find(d => d.logisticType === LOGISTIC_TYPE_CENTRAL);
            return !(!centralDeliveryAddress?.deliveryTimes || !Object.keys(centralDeliveryAddress.deliveryTimes).length);

        }
    }

    handleChange = (propertyName, value) => {
        this.props.onDataChange(propertyName, value);
    }

    handleLogisticTypeChange = (value) => {
        if (value === LOGISTIC_TYPE_CENTRAL) {
            this.initializeTimeSlotMap();
        }
        this.handleChange("logisticType", value);
    }

    buttonText = (key) => {
        return this.props.isInEditMode
            ? this.props.intl.formatMessage({id: "commons.apply.button"})
            : this.props.intl.formatMessage({id: "deliveryAndPickup.create.buttontext." + key});
    }

    timeTableText = (key) => {
        return this.props.intl.formatMessage({id: "deliveryAndPickup.timetable." + key});
    }

    changeTimesUnknown = (newValue) => {
        this.handleChange("timeSlotMap", createTimeSlotMap());
        this.handleChange("deliveryTimesUnknown", newValue);
    }

    hasDifferentDeliveryOrPickUpTimes = () => {
        if (this.props.deliveryAndPickupType === LOGISTIC_TYPE_DECENTRAL) {
            return false;
        }
        if (this.props.addressType === DELIVERY) {
            const centralPickUpAddress = this.props.allPickupAddresses?.find(p => p.logisticType === LOGISTIC_TYPE_CENTRAL);
            if (!centralPickUpAddress) {
                return false;
            }
            const pickUpTimeSlotMap = createTimeSlotsMapFromDeliveryTimesMap(new Map(Object.entries(centralPickUpAddress.deliveryTimes)));
            const deliveryTimeSlotMap = this.props.formData.timeSlotMap;
            return !this.areTimeSlotMapsEqual(pickUpTimeSlotMap, deliveryTimeSlotMap);
        } else if (this.props.addressType === PICKUP) {
            const centralDeliveryAddress = this.props.allDeliveryAddresses?.find(d => d.logisticType === LOGISTIC_TYPE_CENTRAL);
            if (!centralDeliveryAddress) {
                return false;
            }
            const deliveryTimeSlotMap = createTimeSlotsMapFromDeliveryTimesMap(new Map(Object.entries(centralDeliveryAddress.deliveryTimes)));
            const pickUpTimeSlotMap = this.props.formData.timeSlotMap;
            return !this.areTimeSlotMapsEqual(pickUpTimeSlotMap, deliveryTimeSlotMap);
        }
        return false;
    }

    areTimeSlotMapsEqual = (map1, map2) => {
        const keys1 = Array.from(map1.keys());
        const keys2 = Array.from(map2.keys());
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const array1 = map1.get(key);
            const array2 = map2.get(key);
            if (array1.length !== array2.length) {
                return false;
            }
            for (let timeSlot1 of array1) {
               if (!array2.find(timeSlot2 => timeSlot1.weekday === timeSlot2.weekday && timeSlot1.dayhour === timeSlot2.dayhour
                   && timeSlot1.selected === timeSlot2.selected)) {
                   return false;
               }
            }
        }
        return true;
    }

    timeSlotInitializationText = () => {
        if (this.props.addressType === DELIVERY) {
            return this.props.intl.formatMessage({id: "deliveryAndPickup.timeSlotInitialization.delivery"});
        } else {
            return this.props.intl.formatMessage({id: "deliveryAndPickup.timeSlotInitialization.pickUp"});
        }
    }

    timeSlotWarningText = () => {
        if (this.props.addressType === DELIVERY) {
            return this.props.intl.formatMessage({id: "deliveryAndPickup.timeSlotWarning.delivery"});
        } else {
            return this.props.intl.formatMessage({id: "deliveryAndPickup.timeSlotWarning.pickUp"});
        }
    }

    render() {
        const description = this.props.formData.description;
        const shortDescription = this.props.formData.shortDescription;
        const logisticType = this.props.formData.logisticType;
        const costCenter = this.props.formData.costCenter;
        const address = this.props.formData.address;
        let deliveryTimesUnknown = this.props.formData.deliveryTimesUnknown;

        // Check if there is a validation error...
        let error = this.props.logisticTypeErrorText;

        // Determine if the timetable should be disabled or not.
        let disableTimeTable = !logisticType || error ||
            (logisticType === LOGISTIC_TYPE_DECENTRAL 
                && isInCentralMode({deliveryType: this.props.deliveryAndPickupType, pickupType: this.props.deliveryAndPickupType})) ||
            deliveryTimesUnknown;

        let timetableTitle = "";
        switch (this.props.addressType) {
            case DELIVERY:
                timetableTitle = this.timeTableText("titleDelivery");
                break;
            case PICKUP:
                timetableTitle = this.timeTableText("titlePickup");
                break;
            case DELIVERY_PICKUP:
                timetableTitle = this.timeTableText("titleDeliveryPickup");
                break;
            default:
                timetableTitle = this.timeTableText("general");
                break;
        }

        // conditions if delivery address is required
        const decentralDelivery = this.props.addressType === DELIVERY && logisticType === LOGISTIC_TYPE_DECENTRAL;
        const addressOptional = isInCentralMode({deliveryType: this.props.deliveryAndPickupType, pickupType: this.props.deliveryAndPickupType})
            && decentralDelivery;

        return (
            <div className="mt-6" style={{padding: 0}}>

                <Typography color="textSecondary" style={{marginBottom: "10px", marginTop: "10px"}}>
                    <FormattedMessage id="deliveryAndPickup.create.h2"/>
                </Typography>

                <Typography variant="h5" className="mb-2" style={{marginTop: "20px"}}>
                    <FormattedMessage id="deliveryAndPickup.create.props.title"/>
                </Typography>

                <Grid container direction="row" spacing={1}>

                    <Grid item xs={12}>

                        <Grid container direction="column" spacing={2}>

                            <Grid item className="mb-4">

                                <FormControl style={{width: "50%"}} error={!!error}>

                                    <InputLabel htmlFor="logisticType-native-simple">
                                        <FormattedMessage id="deliveryAndPickup.table.header.logisticType"/> *
                                    </InputLabel>

                                    <Select
                                        native
                                        value={logisticType}
                                        onChange={(e) => this.handleLogisticTypeChange(e.target.value)}
                                        style={{width: "100%"}}
                                        inputProps={{name: 'logisticType', id: 'logisticType-native-simple'}}>

                                        <option aria-label="None" value=""/>

                                        {[LOGISTIC_TYPE_CENTRAL, LOGISTIC_TYPE_DECENTRAL]
                                        .map((logisticType) => {
                                            let optionValue = this.props.intl.formatMessage({id: "constants.DeliveryPickup." + logisticType});
                                            return (
                                                <option key={logisticType} value={logisticType}>
                                                    {optionValue}
                                                </option>
                                            );
                                        })}

                                    </Select>

                                    {error && <FormHelperText><FormattedMessage id={error}/></FormHelperText>}

                                </FormControl>

                            </Grid>

                            <Grid item className="mb-4">

                                <TextField 
                                    required 
                                    id="description"
                                    style={{width: '40%'}}
                                    value={description}
                                    label={this.props.intl.formatMessage({id: "deliveryAndPickup.create.props.description"})}
                                    variant="filled"
                                    inputProps={{maxLength: 300}}
                                    onChange={(e) => this.handleChange("description", e.target.value)}
                                    fullWidth={true}/>

                                <TextField 
                                    required 
                                    id="shortDescription"
                                    style={{width: '20%', marginLeft: '2rem'}}
                                    value={shortDescription}
                                    label={this.props.intl.formatMessage({id: "deliveryAndPickup.create.props.shortDescription"})}
                                    variant="filled"
                                    inputProps={{maxLength: 300}}
                                    onChange={(e) => this.handleChange("shortDescription", e.target.value)}
                                    fullWidth={true}/>

                            </Grid>

                            <Grid item className="mb-4">

                                <TextField 
                                    required 
                                    id="costCenter"
                                    style={{width: '20%'}}
                                    value={costCenter}
                                    label={this.props.intl.formatMessage({id: "deliveryAndPickup.create.props.costCenter"})}
                                    variant="filled"
                                    inputProps={{maxLength: 300}}
                                    onChange={(e) => this.handleChange("costCenter", e.target.value)}
                                    fullWidth={true}/>

                            </Grid>

                            <Grid item className="mb-4">

                                <TextField 
                                    required={!addressOptional} 
                                    id="address"
                                    style={{width: 'calc(60% + 2rem)'}}
                                    value={address}
                                    label={this.props.intl.formatMessage({id: "deliveryAndPickup.create.props.address"})}
                                    variant="filled"
                                    inputProps={{maxLength: 300}}
                                    onChange={(e) => this.handleChange("address", e.target.value)}
                                    fullWidth={true}/>

                            </Grid>

                            <Grid item>

                                {(((this.props.formData.logisticType === LOGISTIC_TYPE_CENTRAL 
                                    && isInCentralMode({deliveryType: this.props.deliveryAndPickupType, pickupType: this.props.deliveryAndPickupType}))
                                    && !error)
                                        || (isInDecentralMode({deliveryType: this.props.deliveryAndPickupType, pickupType: this.props.deliveryAndPickupType})
                                            && !error))
                                    && <>
                                        <InputLabel htmlFor="deliveryTimes" style={{marginTop: "20px"}}>
                                            <Checkbox
                                                id="deliveryTimes"
                                                color="primary"
                                                style={{marginLeft: '-9px'}}
                                                checked={deliveryTimesUnknown}
                                                onChange={(e) => this.changeTimesUnknown(e.target.checked)}/>
                                            <FormattedMessage id="deliveryAndPickup.create.props.deliveryTimeUnknown"/>
                                        </InputLabel>
                                    </>
                                }

                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>

                <Grid container direction="row" spacing={1}>

                    <Grid item xs={12}>

                        <Typography variant="h5" className="mb-4" style={{marginTop: "20px"}}>
                            {timetableTitle}
                        </Typography>

                        {this.props.formData.logisticType === LOGISTIC_TYPE_DECENTRAL && this.props.deliveryAndPickupType === LOGISTIC_TYPE_CENTRAL

                            ? <Typography color="textSecondary">
                                <FormattedMessage id="logistics.noTimesNecessary"/>
                            </Typography>

                            : <>

                                {this.state.initializedTimeSlotMap && !deliveryTimesUnknown && !disableTimeTable && !this.hasDifferentDeliveryOrPickUpTimes()
                                    && <Alert severity="info" style={{marginBottom: 10}}>
                                        {this.timeSlotInitializationText()}
                                    </Alert>}

                                {this.hasDifferentDeliveryOrPickUpTimes() && !deliveryTimesUnknown && !disableTimeTable
                                    && <>
                                        <Alert severity="warning" style={{marginBottom: 20}}>
                                            {this.timeSlotWarningText()}
                                        </Alert>

                                        {this.showCopyTimeSlotsButton() && <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => this.initializeTimeSlotMap()}
                                            style={{marginBottom: 30}}>
                                            <FormattedMessage id={this.props.addressType === DELIVERY ? "deliveryTimes.copy.button" : "pickUpTimes.copy.button"}/>
                                        </Button>}
                                    </>}

                                <LogisticsTimeTable
                                    key={"time-slots-different-" + this.hasDifferentDeliveryOrPickUpTimes()}
                                    deliveryTimesUnknown={disableTimeTable}
                                    isInEditMode={this.props.isInEditMode}
                                    timeSlots={this.props.formData.timeSlotMap}
                                    onTimeSlotSelected={this.handleChange}/>

                            </>}

                    </Grid>

                </Grid>
                
            </div>
        );
    }
}
export default injectIntl(LogisticsContent);
