import React, {Component} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {bodyBackgroundColor, headerColor, white} from "../../../util/ColorTheme";
import Select from "react-select";
import defaultUserIcon from "../../../resources/default_user_icon.png";
import {address} from "../../../util/Util";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";

class PriceInformationHeader extends Component {

    bestPriceFlag = () => {
        return (
            <div
                className="bestPriceFlag"
                style={{
                    backgroundColor: headerColor, color: white, paddingLeft: 10, paddingRight: 10
                }}
            >
                {this.props.intl.formatMessage({id: "constants.OfferBadge.PRICE_WINNER"})}
            </div>
        );
    }

    controlPanel = () => {
        const showBrutto = this.props.showBrutto;
        return (
            <Grid
                item xs={3}
                className="controlPanel"
                style={{backgroundColor: bodyBackgroundColor}}
            >

                <div className="priceTypeSelect">

                    <Select
                        id="net-or-gross"
                        required
                        value={{
                            value: showBrutto,
                            label: showBrutto
                                ? this.props.intl.formatMessage(
                                    {id: "offers.comparison.priceType.button.gross"})
                                : this.props.intl.formatMessage(
                                    {id: "offers.comparison.priceType.button.net"})
                        }}
                        onChange={() => this.props.onShowBruttoChange(!showBrutto)}
                        options={[
                            {
                                value: true,
                                label: this.props.intl.formatMessage(
                                    {id: "offers.comparison.priceType.button.gross"})
                            },
                            {
                                value: false,
                                label: this.props.intl.formatMessage(
                                    {id: "offers.comparison.priceType.button.net"})
                            }
                        ]}
                    />

                </div>

            </Grid>
        );
    }

    offerOverview = (offer) => {
        let offerIdFromPriceWinner;
        if (this.props.priceOffers) {
            let cheapestPriceOffer = this.props.priceOffers[0];
            for (let i = 1; i < this.props.priceOffers.length; i++) {
                if (this.props.priceOffers[i].sumGross < cheapestPriceOffer.sumGross) {
                    cheapestPriceOffer = this.props.priceOffers[i];
                }
            }
            offerIdFromPriceWinner = cheapestPriceOffer?.offerId;
        }

        return (
            <Grid container>

                <Grid item xs={12}>

                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        style={{textAlign: "center"}}
                        wrap="nowrap"
                    >

                        <Grid item>

                            <img
                                src={offer?.user?.imageVo?.image?.url ?? defaultUserIcon}
                                className="profileImage"
                                alt=""/>

                        </Grid>

                        <Grid item xs style={{whiteSpace: "pre-line", marginRight: 60}}>

                            <Typography variant="h3">
                                {offer?.user?.company + "\n"}
                            </Typography>

                            <div style={{fontWeight: "bold"}}>
                                {address(this.props.intl, offer)}
                            </div>

                        </Grid>

                    </Grid>

                </Grid>

                <Grid
                    item xs={12}
                    style={{marginTop: 30, display: "flex", justifyContent: "center"}}
                >
                    {offer.id === offerIdFromPriceWinner && this.bestPriceFlag()}
                </Grid>

            </Grid>
        );
    }

    previewCell = (key, widget) => {
        return (
            <Grid
                key={key}
                item xs
                className="previewCell"
                style={{backgroundColor: bodyBackgroundColor}}>
                {widget}
            </Grid>
        );
    }
    
    render() {
        return (
            <Grid container style={{overflowX: "auto"}}>

                <Grid item xs={12}>

                    <Grid container alignItems="flex-start" wrap="nowrap">

                        {this.controlPanel()}

                        {this.props.offers
                            .map(o => this.previewCell(
                                o.id+"previewcell", this.offerOverview(o)
                            ))
                        }

                    </Grid>

                </Grid>

            </Grid>
        );
    }
}

PriceInformationHeader.propTypes = {
    offers: PropTypes.array,
    onShowBruttoChange: PropTypes.func,
    priceOffers: PropTypes.array,
    showBrutto: PropTypes.bool
};

export default injectIntl(PriceInformationHeader);
