import {createTheme} from '@material-ui/core/styles';
import {DIALOG_TYPE_ERROR, DIALOG_TYPE_INFO, DIALOG_TYPE_WARNING} from './Constants';

export const primaryColor = "#0066ff";
export const secondaryColor = "#189AB4";

export const headerColor = "#0d47a1";

export const texisionRed = "#C72856";
export const texisionBlue = "#004A94";
export const texisionLightBlue = "#CDDBE3";
export const texisionGray = "#CCCCCC";
export const texisionGreen = "#19AD7E";
export const texisionOrange = "#F0A000";
export const texisionWarningOrange = "#F8F4D5";

export const texisionBlueGray = "#c8d6de";
export const texisionBlueGrayBright = "#cddbe3";
export const texisionBlueGrayDark = "#b3c0c7";

export const texisionFontColorDark = "#333333";
export const texisionFontColorBright = "#FFFFFF";
export const texisionFontColorOrange = "#96722E";

export const bodyBackgroundColor = "#f3f6f8";

export const warningColor = "#F89A1C";
export const infoColor = "#0066FF";
export const errorColor = "#FF0060";

export const white = "#FFFFFF";
export const defaultTextColor = "#333333";

export const CHART_COLOR_PALETTE = [
    texisionBlue,
    texisionGreen,
    texisionOrange,
    "cyan",
    "purple",
    "black",
    texisionRed,
    "green",
    infoColor,
    "red",
];

export function getChartColorByCategory(category) {
    if (category?.includes("all")) {
        return CHART_COLOR_PALETTE[0];
    } else if (category?.includes("ww")) {
        return CHART_COLOR_PALETTE[1];
    } else if (category?.includes("fl")) {
        return CHART_COLOR_PALETTE[2];
    } else if (category?.includes("ca")) {
        return CHART_COLOR_PALETTE[3];
    } else if (category?.includes("rl")) {
        return CHART_COLOR_PALETTE[4];
    } else if (category?.includes("or")) {
        return CHART_COLOR_PALETTE[5];
    } else if (category?.includes("sp")) {
        return CHART_COLOR_PALETTE[6];
    } else {
        return CHART_COLOR_PALETTE[7];
    }
}


export function getDialogColor(type) {
    switch (type) {
        case DIALOG_TYPE_ERROR:
            return errorColor;
        case DIALOG_TYPE_WARNING:
            return warningColor;
        case DIALOG_TYPE_INFO:
        default:
            return infoColor;
    }
}

export const theme = createTheme({
    typography: {
        fontFamily: [
            '"Source Sans Pro"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
        h1: {
            fontSize: '30px',
            lineHeight: '42px',
            fontWeight: 'bold',
            marginBottom: '2rem',
        },
        h2: {
            fontSize: '24px',
            lineHeight: '34px',
            fontWeight: 'bold',
        },
        h3: {
            fontSize: '18px',
            lineHeight: '26px',
            fontWeight: 'bold',
        },
        h4: {
            fontSize: '15px',
            lineHeight: '24px',
            fontWeight: 'bold',
        },
        h5: {
            fontSize: '15px',
            lineHeight: '23px',
            fontWeight: 'bold',
        },
        subtitle1: {
            fontSize: '17px',
            lineHeight: '25px',
            marginTop: 'calc(-2rem + 5px)',
            marginBottom: '2rem',
        },
        subtitle2: {
            fontSize: '16px',
            lineHeight: '22px',
            marginTop: 'calc(0.2rem)',
            marginBottom: '2rem',
        },
        body1: {
            fontSize: '14px',
        },
    },
    palette: {
        primary: {
            main: '#0066ff',
            light: '#0066ff',
            dark: '#0052cc',
        },
        secondary: {
            main: '#189AB4',
            light: '#15859C',
            dark: '#189AB4',
        },
        action: {
            selected: '#DDFFEE'
        }
    },
    overrides: {
        //---- BUTTONS ----
        MuiButton: {
            root: {
                borderRadius: 5,
                padding: '8px 16px',
            },
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
                '&:active': {
                    boxShadow: 'none',
                }
            },
            containedSecondary: {
                backgroundColor: 'transparent',
                color: texisionFontColorDark,
                '&:hover': {
                    color: primaryColor,
                    backgroundColor: 'transparent',
                }
            },
        },
        //---- TEXT FIELDS ----
        MuiFilledInput: {
            root: {
                borderRadius: '2px',
                borderTopLeftRadius: '2px',
                borderTopRightRadius: '2px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: texisionBlueGrayBright,
                backgroundColor: '#fff',
                '&:hover': {
                    borderColor: texisionBlueGrayDark,
                    backgroundColor: '#fff',
                },
                '&$focused': {
                    borderWidth: '1px',
                    borderColor: primaryColor,
                    backgroundColor: '#fff',
                }
            },
            underline: {
                '&:before, &:after': {
                    display: 'none',
                },
            },
            input: {
                padding: '22px 12px 7px',
            },
        },
        MuiInputLabel: {
            filled: {
                transform: 'translate(12px, 16px) scale(1)',
                '&$shrink': {
                    transform: 'translate(12px, 7px) scale(0.75)',
                }
            },
        },
        MuiOutlinedInput: { //outlined should not be used anymore
            root: {
                borderRadius: 5,
                borderColor: texisionBlueGrayBright,
                '&:hover $notchedOutline': {
                    borderColor: texisionBlueGrayDark,
                },
                '&$focused $notchedOutline': {
                    borderWidth: '1px',
                    borderColor: primaryColor,
                }
            },
            notchedOutline: {
                borderColor: texisionBlueGrayBright,
            }
        },
        //---- FORM ELEMENTS ----
        MuiFormHelperText: {
            contained: {
                marginLeft: 0,
                marginRight: 0,
                marginTop: '0.4rem',
            },
        },
        MuiSelect: { //standard select box
            root: {
                borderRadius: '2px',
                borderTopLeftRadius: '2px',
                borderTopRightRadius: '2px',
                borderWidth: 0,
                borderStyle: 'solid',
                borderColor: texisionBlueGrayBright,
                backgroundColor: '#fff',
                '&:hover': {
                    borderColor: texisionBlueGrayDark,
                    backgroundColor: '#fff',
                },
                '&$focused': {
                    borderWidth: '1px',
                    borderColor: primaryColor,
                    backgroundColor: '#fff',
                }
            },
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: bodyBackgroundColor,
                },
                '&$selected:hover': {
                    backgroundColor: bodyBackgroundColor,
                }
            },
        },
        //---- CHECKBOXES ------
        MuiCheckbox: {

        },
        //---- CARDS, PAPERS, .... ----
        MuiCard: {
            root: {
                padding: '22px 26px',
                boxShadow: 'none',
                borderRadius: 5,
            },
        },
        MuiPaper: {
            root: {
                boxShadow: 'none',
                borderRadius: 0,
            },
        },
        //---- TABLES ----
        MuiTable: { 
            root: {
                boxShadow: 'none',
                borderRadius: 0,
            },
        },
        //---- ACCORDIONS ----
        MuiAccordionSummary: {
            root: {
                padding: '0 1.5rem',
            },
            content: {
                margin: '1.7rem 0 !important',
            },
        },
        MuiAccordion: {
            root: {
                '&:before': {
                    borderLeft: '1.5rem solid #fff',
                    borderRight: '1.5rem solid #fff',
                },
                '&:before:first-child': {
                    height: 0,
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                paddingTop: 0,
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
            }
        },
        //---- APPBAR ----
        MuiAppBar: {
            colorDefault: {
                backgroundColor: '#fff',
            },
        },
        //---- BADGED ----
        MuiBadge: {
            colorPrimary: {
                backgroundColor: '#fff',
                color: texisionBlue,
            },
        },
        //---- DIVIDER ----
        MuiDivider: {
            root: {
                backgroundColor: texisionBlueGrayBright,
            },
        },

    }
});

export class ColorProvider {
    constructor() {
        this.keyMap = new Map();
        this.colorIndex = 0;
        this.colorPalette = structuredClone(CHART_COLOR_PALETTE);
    }

    getColor(key) {
        let color;
        if (this.keyMap.has(key)) {
            color = this.keyMap.get(key);
        } else {
            color = this.colorPalette[this.colorIndex];
            if (color !== undefined) {
                this.colorIndex++;
                this.keyMap.set(key, color);
            }
        }
        return color;
    }

    releaseColor(key) {
        if (this.keyMap.has(key)) {
            let color = this.keyMap.get(key);
            this.colorPalette.push(color);
            this.keyMap.delete(key);
        }
    }
}
