import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Card, CardActions, CardContent, Grid, Typography, Tooltip, IconButton, Icon, Divider} from '@material-ui/core';
import AssignmentIcon from '@mui/icons-material/Assignment';

import '../../../css/EquipmentOverview.css';
import {GeneralContext} from "../../contexts/GeneralContext";
import DomainIcon from '@mui/icons-material/Domain';


class AreaCard extends Component {

    static contextType = GeneralContext;

    render() {
        
        let cardToRender = null;

        switch (this.props.mode) {
            
            case "new":
                cardToRender = 
                    <Card className="equipmentCard">
                        <CardContent style={{height: "160px"}}>
                            <Grid container direction="column" alignItems="center" justifyContent="space-around" spacing={2} style={{height: "100%"}}>
                                <Grid item>
                                    <Typography variant="body1">
                                        <FormattedMessage id="area.create.title"/>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.props.showDialog(true)}
                                        disabled={this.props.readOnly}>
                                        <FormattedMessage id="commons.create.button"/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card> 
                break;

            case "withControls":
                cardToRender = 
                    <Card className="equipmentCard">    
                        <CardContent>
                            <Grid container direction="column" alignItems="center" justifyContent="space-around" >
                                <Grid item>
                                    <Typography className="equipmentCardName" variant="h3">{this.props.area.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2">
                                        {this.props.intl.formatMessage({id: "area.articleCount"}) + this.props.area.articleIds.length}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container item justifyContent="center" alignItems="center">
                                        <DomainIcon style={{fontSize: 80}}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>

                        <Divider/>

                        <CardActions>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item>
                                    <Tooltip title={this.props.intl.formatMessage({id: this.props.readOnly
                                            ? "area.showAssignedArticles.tooltip" : "area.assignArticles.tooltip"})}>
                                        <IconButton 
                                            edge="end" 
                                            aria-label="assign" 
                                            onClick={() => this.props.assignArticlesToArea(this.props.area)}>
                                            <AssignmentIcon style={{fontSize: "21pt"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={this.props.intl.formatMessage({id: "area.edit.tooltip"})}>
                                        <IconButton 
                                            edge="end" 
                                            aria-label="edit" 
                                            onClick={() => this.props.editArea(this.props.area)}
                                            disabled={this.props.readOnly}>
                                            <Icon className="icon-edit" style={{fontSize: "21pt"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={this.props.intl.formatMessage({id: "area.delete.tooltip"})}>
                                        <IconButton 
                                            aria-label="delete" 
                                            onClick={() => this.props.deleteArea(this.props.area.id)}
                                            disabled={this.props.readOnly}>
                                            <Icon className="icon-trash" style={{fontSize: "21pt"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                break;
            default:
                break;
        }
        
        return cardToRender;
    }
}

export default injectIntl(AreaCard);
