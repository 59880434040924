import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import '../apps/App.css';

import {FormattedMessage, injectIntl} from 'react-intl';

class CouldNotReachServer extends Component {

    render() {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <h1 style={{textAlign: "center"}}>
                        <FormattedMessage id="commons.couldNotReachServer.text"/>                        
                    </h1>
                </Grid>
            </Grid>
        );
    }
}

export default injectIntl(CouldNotReachServer);
