import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import OperatingResourceFeature from './OperatingResourceFeature';
import PropTypes from 'prop-types';
import {isEmptyOrBlank} from '../../../util/Util';
import {getAsync} from '../../../services/BackendService';
import {GeneralContext} from "../../contexts/GeneralContext";



/**
 * This Component allows you have a feature list, with a hidden feature type. 
 * All added features will have a fixed feature type, which is not displayed to the user.
 * 
 * For the user it will look like a simple list of values. 
 * This component should be abstracted further, if we need this list-like behavior someplace else.
 */
let internalId = 1;

class OperatingResourceFeaturePanel extends Component {

    static contextType = GeneralContext;
    
    state = {
        allOptions: []
    }

    async componentDidMount() {
        await this.handleLoadAllOptions()
    }

    handleLoadAllOptions = async() => {
        let response = await getAsync(this.props.valuesEndpoint + this.props.featureType)
        if (response?.status === 200) {
            this.setState({allOptions: response.data});
        } else if ([401, 403].includes(response?.status)) {
            this.context.logout();
        }
    }

    createFeature = () => {
        // create an internalId for new features, so we can delete the entry from the GUI, if value have not been set.
        let feature = {id: null, type: this.props.featureType, value:"", internalId: internalId++};
        let featureList = this.props.features;
        featureList.push(feature);
        this.props.onChange(featureList);
    }

    handleFeatureValueChanged = (id, internalId, value) => {
        let currentFeatures = this.props.features;

        for (var i in currentFeatures) {
            if (currentFeatures[i].id === id && currentFeatures[i].internalId === internalId) {
                currentFeatures[i].value = value;
                break;
            }
        }
        this.props.onChange(currentFeatures);
    }

    handleFeatureDelete = (id, internalId) => {
        let currentFeatures = this.props.features;
        for (var i in currentFeatures) {
            if (internalId === currentFeatures[i].internalId && id === currentFeatures[i].id) {
                delete currentFeatures[i];
            }
        }
        this.props.onChange(currentFeatures);
    }

    render() {
        
        let buttonText = ""
        if (isEmptyOrBlank(this.props.addButtonText)){
            buttonText = this.props.intl.formatMessage({id: "commons.add.button"});
        } else {
            buttonText = this.props.addButtonText;
        }

        // Set a internal id for (used by the key property) for existing features
        this.props.features.filter(
            feature => feature.internalId === null || feature.internalId === undefined).forEach(
                feature => feature.internalId = internalId++);

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button variant="contained" color="secondary" onClick={this.createFeature} style={{marginTop: "20px"}}>
                           {buttonText}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                    {
                        this.props.features.map((feature) => {
                            return <OperatingResourceFeature
                                key={feature.internalId}
                                handleFeatureValueChanged={this.handleFeatureValueChanged.bind(this)}
                                handleFeatureDelete={this.handleFeatureDelete.bind(this)}
                                valuesEndpoint={this.props.valuesEndpoint}
                                id={feature.id}
                                type={feature.type}
                                value={feature.value}
                                allOptions={this.state.allOptions}
                                internalId={feature.internalId}
                                i18nPrefix={this.props.i18nPrefix}/>
                        })
                    }
                    </Grid>
                </Grid>
            </div>
        );
    }
}

OperatingResourceFeaturePanel.propTypes = {
    features: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    featureType:  PropTypes.string.isRequired,
    valuesEndpoint: PropTypes.string.isRequired,
};

export default injectIntl(OperatingResourceFeaturePanel);
