import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {Checkbox, Typography} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

import '../../../../css/DeliverypointTimetable.css';
import {GeneralContext} from "../../../contexts/GeneralContext";



class LogisticsTimeTable extends Component {

    static contextType = GeneralContext;

    state = {
        changed: false
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.changed !== nextState.changed || this.props.isInEditMode !== nextProps.isInEditMode 
            || this.props.deliveryTimesUnknown !== nextProps.deliveryTimesUnknown) {
            return true;
        }
        return false;
    }

    handleSelectTimeSlot = (e, timeSlotsMap) => {
        let weekday = null;
        if (e.target.attributes.getNamedItem("weekday")) {
            weekday = e.target.attributes.getNamedItem("weekday").nodeValue;
        }

        let dayhour = null;
        if (e.target.attributes.getNamedItem("dayhour")) {
            dayhour = e.target.attributes.getNamedItem("dayhour").nodeValue;
        }

        if (weekday && dayhour) {

            for (let timeSlot of timeSlotsMap.get(weekday)) {
                if (timeSlot.dayhour === parseInt(dayhour)) {
                    timeSlot.selected = !timeSlot.selected;
                }
            }
            this.setState({changed: !this.state.changed});
            this.props.onTimeSlotSelected("timeSlotMap", timeSlotsMap);
        }
    }

    handleSelectCheckbox = (weekday, e) => {

        let timeSlotMap = this.props.timeSlots;
        for (let timeSlot of timeSlotMap.get(weekday)) {
            timeSlot.selected = e.target.checked;
        }
        this.setState({changed: !this.state.changed});
        this.props.onTimeSlotSelected("timeSlotMap", timeSlotMap);
    }

    isEverySlotSelected = (weekday) => {
        
        let timeSlotMap = this.props.timeSlots;
        for (let timeSlot of timeSlotMap.get(weekday)) {
            if (!timeSlot.selected) {
                return false;
            }
        }
        return true;
    }

    render() {

        return (
            <div>
                <table cellPadding={0} cellSpacing={0} className="deliveryPickupTimetable">
                    <tbody>
                        {Array.from(this.props.timeSlots).map(timeSlots => {
                            return (
                                <tr key={timeSlots[0]}>
                                    <td style={{paddingRight: '0.8rem'}}>
                                        <Typography style={this.props.deliveryTimesUnknown ? {color: "grey"} : {}} variant="body2" component="span"><FormattedMessage id={"constants.Weekdays." + timeSlots[0]}/></Typography>
                                    </td>
                                    <td>
                                        <Checkbox color="primary" disabled={this.props.deliveryTimesUnknown} checked={this.isEverySlotSelected(timeSlots[0])} onChange={(e) => this.handleSelectCheckbox(timeSlots[0], e)}/>
                                    </td>
                                    <td>
                                        <Icon className="icon-clock" />
                                    </td>
                                    <td style={{width: '100%', paddingTop: '0.2rem', paddingBottom: '1.4rem'}}>
                                        <table className="timeSlotTable" cellSpacing={0} cellPadding={0} style={{opacity: (!this.props.deliveryTimesUnknown ? '1' : '0.5')}}>
                                            <tbody>
                                                <tr>
                                                {
                                                    timeSlots[1].map((timeSlot, index) => {
                                                            let leftLineActive = false;
                                                            let rightLineActive = false;

                                                            //get active status for left "line"
                                                            if ((timeSlots[1][index - 1]) && //we have an entry before
                                                                (timeSlot.selected === true) && //the current entry is selected
                                                                (timeSlots[1][index - 1].selected === true)) { //the entry bevore is selcted
                                                                    leftLineActive = true;
                                                                }

                                                            //get active status for right "line"
                                                            if ((timeSlots[1][index + 1]) &&
                                                                (timeSlot.selected === true) &&
                                                                (timeSlots[1][index + 1].selected === true)) {
                                                                    rightLineActive = true;
                                                                }

                                                            return (
                                                                <td key={timeSlot.weekday + timeSlot.dayhour} style={{textAlign: 'center'}}>
                                                                    <div style={{position: 'relative'}}>
                                                                        <div className={timeSlot.selected ? "timeSlotSelectedLabel" : "timeSlotLabel"}>
                                                                            {timeSlot.dayhour}
                                                                        </div>
                                                                        <div className={timeSlot.selected ? "timeSlotSelected" : "timeSlot"}
                                                                            style={{cursor: (!this.props.deliveryTimesUnknown ? 'pointer' : 'default')}}
                                                                            weekday={timeSlot.weekday} 
                                                                            dayhour={timeSlot.dayhour}
                                                                            disabled={this.props.deliveryTimesUnknown}  
                                                                            onClick={(event) => !this.props.deliveryTimesUnknown ? this.handleSelectTimeSlot(event, this.props.timeSlots) : () => false}/>
                                                                        <div className={'timeSlotLine timeSlotLineLeft' + (leftLineActive ? ' timeSlotLineActive' : '')}></div>
                                                                        { index < 23 &&
                                                                        <div className={'timeSlotLine timeSlotLineRight' + (rightLineActive ? ' timeSlotLineActive' : '')}></div>
                                                                        }
                                                                    </div>
                                                                </td>)
                                                    })
                                                }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>);
                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}
export default LogisticsTimeTable;
