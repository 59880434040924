import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {GeneralContext} from "../../../contexts/GeneralContext";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DeliveryAddressUnit from "./DeliveryAddressUnit";
import Card from "@material-ui/core/Card";
import {getAsync} from "../../../../services/BackendService";
import {DIALOG_TYPE_WARNING, LOGISTIC_TYPE_CENTRAL} from "../../../../util/Constants";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {Step, StepLabel, Stepper} from "@material-ui/core";
import {getUnitAreas} from "../../../../services/AreaService";
import {getUnitProfessionalGroups} from "../../../../services/ProfessionalGroupService";
import {deleteAddress} from "../../../../services/AddressService";
import {updateValidationMap} from "../../../../services/ProjectService";
import {getActiveUnit} from "../../../../services/BusinessUnitService";

class DeliveryAddressOverview extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            assortmentData: [],
            allAssignedArticles: [],
            allAreas: [],
            allProfessionalGroups: [],
            showDeleteDialog: false,
            deliveryAddressIdToDelete: null
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.loadAssortmentData(),
            this.loadAllAssignedArticles(),
            getUnitProfessionalGroups(this.context, this.props),
            getUnitAreas(this.context, this.props)
        ]).then(([assortmentData, allAssignedArticles, allProfessionalGroups, allAreas]) => {
            this.setState({assortmentData, allAssignedArticles, allProfessionalGroups, allAreas});
        });
    }

    loadAllAssignedArticles = async() => {
        let response = await getAsync("/quantity/articles/" + this.context.appData.activeUnitId);
        if (response?.status === 200) {
            return response.data ?? [];
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
        return [];
    }

    loadAssortmentData = async() => {
        let response = await getAsync("assortment/" + this.context.appData.activeUnitId + "/articles");
        if (response?.status === 200) {
            return response.data;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
        return null;
    }

    delete = async() => {
        const success = await deleteAddress(this.context, this.props, this.state.deliveryAddressIdToDelete);
        if (success) {
            this.setState({showDeleteDialog: false, deliveryAddressIdToDelete: null});
            this.props.reloadAddresses();
            await updateValidationMap(this.context);
        } else {
            this.setState({showDeleteDialog: false, deliveryAddressIdToDelete: null});
        }
    }

    render() {
        return (
            <>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showDeleteDialog}
                    titleId="deliveryAndPickup.dialog.title"
                    subtitleId="deliveryAndPickup.dialog.textPickup"
                    actionId="commons.yes.button"
                    cancelId="commons.no.button"
                    onAction={() => this.delete()}
                    onCancel={() => this.setState({showDeleteDialog: false, deliveryAddressIdToDelete: null})}/>

                <Card style={{marginBottom: 20, marginTop: 20}}>

                    <CardContent>

                        <Grid container className="mb-5">

                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-start">
                                    <Typography variant="h6" component="h6">
                                        <FormattedMessage id="deliveryAndPickup.table.delivery"/>
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Divider/>

                        <Typography variant="h5" className="mt-6 mb-6">

                            <FormattedMessage id="deliveryAndPickup.table.delivery.headline"/>

                            <span style={{fontWeight: 400}}>
                                {" (" + this.props.intl.formatMessage({id: "deliveryAndPickup.table.count"})
                                    + " " + (this.props.allDeliveryAddresses?.length ?? 0) + ")"}
                            </span>

                        </Typography>

                        {getActiveUnit(this.context)?.deliveryType === LOGISTIC_TYPE_CENTRAL
                            && <Typography variant="subtitle2" component="h6">
                                <FormattedMessage id="deliveryAndPickup.central.hint"/>
                            </Typography>}

                        {this.props.allDeliveryAddresses?.length
                            ? <Stepper activeStep={-1} style={{marginBottom: 20}}>
                                <Step key="delivery-address-step">
                                    <StepLabel>{this.props.intl.formatMessage({id: "logistics.wizard.step.1"})}</StepLabel>
                                </Step>
                                <Step key="assignment-step">
                                    <StepLabel>{this.props.intl.formatMessage({id: "logistics.wizard.step.2"})}</StepLabel>
                                </Step>
                                <Step key="quantities-step">
                                    <StepLabel>{this.props.intl.formatMessage({id: "logistics.wizard.step.3"})}</StepLabel>
                                </Step>
                            </Stepper>
                            : null}

                        {this.props.allDeliveryAddresses?.map(a => <DeliveryAddressUnit
                            deliveryAddress={a}
                            readOnly={this.props.readOnly}
                            assortmentData={this.state.assortmentData}
                            allDeliveryAddresses={this.props.allDeliveryAddresses}
                            allPickUpAddresses={this.props.allPickupAddresses}
                            allAreas={this.state.allAreas}
                            allProfessionalGroups={this.state.allProfessionalGroups}
                            allAssignedArticles={this.state.allAssignedArticles}
                            onAddressSave={() => this.props.reloadAddresses()}
                            onAssignmentSave={async() => this.setState({allAssignedArticles: await this.loadAllAssignedArticles()})}
                            onQuantitiesSave={async() => this.setState({allAssignedArticles: await this.loadAllAssignedArticles()})}
                            onDelete={() => this.setState({showDeleteDialog: true, deliveryAddressIdToDelete: a.id})}
                        />)}

                        <DeliveryAddressUnit
                            deliveryAddress={null}
                            readOnly={this.props.readOnly}
                            onAddressSave={() => this.props.reloadAddresses()}
                            allDeliveryAddresses={this.props.allDeliveryAddresses}
                            allPickUpAddresses={this.props.allPickupAddresses}/>

                    </CardContent>

                </Card>

            </>
        );
    }
}

export default injectIntl(withSnackbar(DeliveryAddressOverview));
