import React, {Component} from 'react';
import {putAsyncCatch} from '../../../services/BackendService';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {CardContent} from '@material-ui/core';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createErrorMessage, createSuccessMessage} from '../../../util/Util';
import Divider from '@material-ui/core/Divider';

import '../../apps/App.css';
import '../../../css/ArticleOffsetting.css';
import OffsettingEntry from './OffsettingEntry';
import {GeneralContext} from "../../contexts/GeneralContext";
import { withSnackbar } from 'notistack';
import {getSpecialServicesForUnit} from "../../../services/SpecialServiceService";
import {updateValidationMap} from "../../../services/ProjectService";

class SpecialServiceOffsettings extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            oldSpecialServices: [],
            newSpecialServices: []
        }
    }

    async componentDidMount() {
        await this.loadSpecialServices();
    }

    componentDidUpdate() {
        const hasUnsavedChanges = this.areSpecialServicesChanged();
        this.context.setUnsavedChanges(!!hasUnsavedChanges);
    }

    loadSpecialServices = async() => {
        const specialServices = await getSpecialServicesForUnit(this.context, this.props);
        this.setState({oldSpecialServices: specialServices.map(s => ({...s})), newSpecialServices: specialServices.map(s => ({...s}))});
    }

    saveSpecialServicesOffsettings = async() => {
        if (this.state.newSpecialServices) {
            let newServices = this.state.newSpecialServices;
            let numberOfErrors = 0;
            for (let i = 0; i < newServices.length; i++) {
                if (newServices[i].offsetting !== this.state.oldSpecialServices.find(s => s.id === newServices[i].id).offsetting) {
                    let responseData = await putAsyncCatch(this.context, "/special/service/" + this.context.appData.activeProjectId, newServices[i], this.props);
                    if (!responseData) {
                        numberOfErrors += 1;
                    }
                }
            }
            if (numberOfErrors === 0) {
                createSuccessMessage(this.props.intl.formatMessage({id: "specialServices.offsetting.update.successMessage"}), this.props);
                await this.loadSpecialServices();
            } else {
                createErrorMessage(this.props.intl.formatMessage({id: "specialServices.offsetting.update.errorMessage"}), this.props);
            }
            await updateValidationMap(this.context);
        }
    }

    areSpecialServicesChanged = () => {
        if (!this.state.oldSpecialServices || this.state.oldSpecialServices.length === 0 
            || !this.state.newSpecialServices || this.state.newSpecialServices.length === 0) {
            return false;
        }
        let newServices = this.state.newSpecialServices;
        let changed = false;
        for (let i = 0; i < this.state.newSpecialServices.length; i++) {
            if (newServices[i].offsetting !== this.state.oldSpecialServices.find(s => s.id === newServices[i].id).offsetting) {
                changed = true;
                break;
            }
        }
        return changed;
    }

    handleSpecialServiceOffsettingChanged = (id, offsetting) => {
        let service = this.state.newSpecialServices.find(s => s.id === id);
        if (service) {
            service.offsetting = offsetting;
        }
        this.setState({newSpecialServices: this.state.newSpecialServices});
    }

    handleCancelSpecialServicesEdit = async() => {
        let oldSpecialServicesCopy = this.state.oldSpecialServices.map(service => ({...service}));
        this.setState({newSpecialServices: oldSpecialServicesCopy});
    }

    render() {
        return (
            <Card className="child">
                <CardContent>

                    <Grid container spacing={2} className="mb-5">

                        <Grid item xs>
                            <Typography variant="h6" component="h6">
                                <FormattedMessage id="specialServices.offsetting.h1"/>
                            </Typography>
                        </Grid>

                        {this.areSpecialServicesChanged() && <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => this.handleCancelSpecialServicesEdit()}
                                style={{marginLeft: "10px"}}
                                disabled={this.props.readOnly}>
                                <FormattedMessage id="commons.cancel.button" />
                            </Button>
                        </Grid>}

                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.saveSpecialServicesOffsettings()}
                                disabled={this.props.readOnly || !this.areSpecialServicesChanged()}>
                                <FormattedMessage id="commons.save.button" />
                            </Button>
                        </Grid>

                    </Grid>

                    <Divider/>

                    {(this.state.newSpecialServices && this.state.newSpecialServices.length > 0) &&
                        <Typography color="textSecondary" className="mt-5">
                            <FormattedMessage id="specialServices.offsetting.h2"/>
                        </Typography>}

                    <Grid container className="pt-2" spacing={2}>
                        {this.state.newSpecialServices && this.state.newSpecialServices.length > 0
                            ? this.state.newSpecialServices.sort((a, b) => a.name.localeCompare(b.name)).map(service => {
                                return (
                                    <OffsettingEntry
                                        readOnly={this.props.readOnly}
                                        key={service.id}
                                        id={service.id}
                                        name={service.name}
                                        currentOffsetting={service.offsetting}
                                        handleChanged={this.handleSpecialServiceOffsettingChanged}
                                        offsettings={this.context.appData.operatingResourceOffsettings}
                                    />
                                );
                            })

                            : (
                                <Grid container justifyContent="center" className="mt-6">
                                    <Grid item>
                                        <Typography variant="body2" component="div" className="gray-dotted pt-5 pb-5" style={{textAlign: "center"}}>
                                            <FormattedMessage id="specialServices.offsetting.empty"/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>

                </CardContent>
            </Card>
        );
    }
}

export default injectIntl(withSnackbar(SpecialServiceOffsettings));
