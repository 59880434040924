import React, {Component} from 'react';
import {renderChecked} from '../../../../../util/TableUtil';
import {Card, Grid, Typography, Tooltip, Icon} from '@material-ui/core';
import {injectIntl} from 'react-intl';
import {GeneralContext} from "../../../../contexts/GeneralContext";

class LogisticsAreaCard extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            area: props.area,
            handleCheckboxChanged: props.handleCheckboxChanged,
        };
    }

    render() {
        return (
            <div>
                <Card style={{width: "140px", height: "140px", borderRadius: 10, backgroundColor: "#CDDBE3"}}>

                    <Grid container spacing={3} alignItems="center">

                        <Grid container justifyContent="space-between" alignItems="center">

                            <Grid item xs style={{overflow: "hidden", whiteSpace: "nowrap"}}>
                                <Typography variant="h3">
                                    {this.props.area.name}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Tooltip title={this.props.intl.formatMessage({id: "areaDeliveryPointAssignment.tooltip.checkbox"})}>
                                    {renderChecked(this.props.area, this.props.handleCheckboxChanged, this.props.readOnly)}
                                </Tooltip>
                            </Grid>

                        </Grid>

                        <Typography variant="subtitle2">
                            {this.props.intl.formatMessage({id: "areaDeliveryPointAssignment.description.articleCount"}) + " " + this.props.area.articleIds.length}
                        </Typography>

                        <Grid container justifyContent="center" alignItems="flex-start">

                            <Icon className="icon-hospital" style={{fontSize: 60}}/>

                        </Grid>
                    </Grid> 
                </Card>
            </div>
        );
    }
}
export default injectIntl(LogisticsAreaCard);
