import {Component} from "react";
import {injectIntl} from "react-intl";
import {GeneralContext} from "../../contexts/GeneralContext";
import {getFilterCategoriesText} from "../../../util/ArticleHelper";

class FilterCategoriesPreview extends Component {

    static contextType = GeneralContext;

    state = {readMore: false};

    render() {
        if (!this.props.filterCategories?.length || this.props.articleConfigurationId === null || this.props.articleConfigurationId === undefined) {
            return null;
        }
        return (getFilterCategoriesText(this.props.filterCategories, this.props.intl));
    }
}

export default injectIntl(FilterCategoriesPreview);
