import { Checkbox, Grid, Tooltip } from "@material-ui/core";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import ProcedureAccordion from "./ProcedureAccordion";
import InfoIcon from '@mui/icons-material/Info';
import { SUITABILITY_CRITERIA } from "../../util/Constants";
import React, { useEffect, useState } from 'react';
import { texisionBlueGrayDark } from "../../util/ColorTheme";

const SuitabilityCriteriaSelection = ({currentSuitabilityCriteria, onChange, onSave}) => {

    const [suitabilityCriteria, setSuitabilityCriteria] = useState(currentSuitabilityCriteria);
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        setSuitabilityCriteria(currentSuitabilityCriteria);
    }, [currentSuitabilityCriteria]);

    const intl = useIntl();

    if (!suitabilityCriteria) {
        return <div/>;
    }

    const onSuitabilityCriteriaChange = (checked, criteria) => {
        if (checked) {
            setSuitabilityCriteria([...suitabilityCriteria, criteria]);
        } else {
            setSuitabilityCriteria([...suitabilityCriteria.filter(c => c !== criteria)]);
        }
        setHasChanges(true);
        onChange(true);
    }

    return (
        <ProcedureAccordion
            mandatory={false}
            complete={true}
            titleId="procedure.conditions.suitabilityCriteria.title"
            subtitle={intl.formatMessage({id: "procedure.conditions.suitabilityCriteria.subtitle"})}
            saveButtonId="procedure.suitabilityCriteria.save.button"
            onSave={() => {
                onSave(suitabilityCriteria);
                setHasChanges(false);
                onChange(false);
            }}
            disableSaveButton={!hasChanges}>
            <Grid container>
                {Object.keys(SUITABILITY_CRITERIA).map(criteria => <Grid key={criteria} item xs={12} style={{marginBottom: 20}}>
                    <Grid container justifyContent="flex-start" wrap="nowrap" alignItems="center">
                        <Grid item xs>
                            <Grid container wrap="nowrap" alignItems="center">
                                <Checkbox
                                    color="primary"
                                    checked={suitabilityCriteria.includes(criteria)}
                                    onChange={(event) => onSuitabilityCriteriaChange(event.target.checked, criteria)}/>
                                <FormattedMessage id={"constants.SuitabilityCriterion." + criteria + ".title"}/>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title={intl.formatMessage({id: "constants.SuitabilityCriterion."
                                + (criteria === SUITABILITY_CRITERIA.INSURANCES ? (criteria + ".general") : criteria)
                                + ".tooltip"})}
                                style={{whiteSpace: "pre-line"}}>
                                <InfoIcon style={{marginLeft: 10, color: texisionBlueGrayDark}}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>)}
            </Grid>
        </ProcedureAccordion>
    );
}

export default injectIntl(SuitabilityCriteriaSelection);
