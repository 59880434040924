import { TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { formatArticleNumbers, renderImage } from "../../../../util/TableUtil";
import { formatPrice } from "../../../../util/Util";
import ArticleDetail from "../../../share/ArticleDetail";
import { InvoiceControllingTable } from "./InvoiceControllingTable";

const RentalLinenPriceTable = ({priceUnit, contents, currency, unitOrProjectId}) => {

    const [articleForDetailView, setArticleForDetailView] = useState(null);

    const intl = useIntl();

    const content = contents.find(c => c.exportable.id === parseInt(unitOrProjectId));
    const articlesMap = content?.articles ? Object.values(content?.articles) : [];
    let articles = [];
    if (articlesMap) {
        for (let entry of articlesMap) {
            const entryArticles = entry ? Object.values(entry) : [];
            if (entryArticles) {
                for (let list of entryArticles) {
                    articles = [...articles, ...list];
                }
            }
        }
    }

    if (articles.length === 0) {
        return <div/>;
    }

    let body = [];

    const offsettingToGroupMaps = Object.values(priceUnit.articleGroupMap);

    for (let offsettingToGroupMap of offsettingToGroupMaps) {

        const offsettingToGroupEntries = Object.entries(offsettingToGroupMap);

        for (let [offsetting, priceGroup] of offsettingToGroupEntries) {

            for (let priceItem of Object.values(priceGroup.priceItemMap)) {

                const article = articles.find(a => a.articleVo.id === priceItem.objectId)?.articleVo;
    
                body.push(<TableRow key={"rental-linen-price-table-" + priceItem.objectId + offsetting}>
                    <TableCell>
                        {article?.category ? intl.formatMessage({id: "constants.Category." + article.category}) : ""}
                    </TableCell>
                    <TableCell>
                        {article?.subcategory ? intl.formatMessage({id: "constants.SubCategory." + article.subcategory}) : ""}
                    </TableCell>
                    <TableCell>
                        {article?.articleCategory ? <FormattedMessage id={"constants.ArticleCategory." + article.articleCategory}/> : ""}
                    </TableCell>
                    <TableCell>
                        {renderImage(article, () => setArticleForDetailView(article))}
                    </TableCell>
                    <TableCell>
                        {offsetting ? intl.formatMessage({id: "constants.ArticleOffsetting." + offsetting}) : ""}
                    </TableCell>
                    <TableCell>
                        {formatPrice(priceItem.price)}
                    </TableCell>
                    <TableCell>
                        {"TX-" + priceItem.objectId}
                    </TableCell>
                    <TableCell>
                        {formatArticleNumbers(priceItem.articleNumbers)}
                    </TableCell>
                </TableRow>);
            }
        }
    }

    return <React.Fragment>
        
        {articleForDetailView && <ArticleDetail article={articleForDetailView}
                showDialog={articleForDetailView !== null && articleForDetailView !== undefined}
                handleDialogClose={() => setArticleForDetailView(null)}/>}

        <InvoiceControllingTable
            key="invoice-table-rental"
            icon="icon-clothings"
            titleId="navBar.rentalLinen.title" 
            currency={currency}
            titles={[
                "operatingResources.table.category",
                "operatingResources.table.subCategory",
                "invoiceControlling.table.name.title",
                "entities.article.image",
                "invoiceControlling.table.offsetting.title",
                "invoiceControlling.table.price.title",
                "invoiceControlling.table.texisionArticleNumber.title",
                "invoiceControlling.table.bidderArticleNumber.title"
            ]} 
            body={body}/>

    </React.Fragment>;
}

export {
    RentalLinenPriceTable
}
