import {Component} from "react";
import {Checkbox, Divider, FormControlLabel, Grid, Tooltip} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import DialogActions from "@material-ui/core/DialogActions";
import MediaQuery from "react-responsive";
import Button from "@material-ui/core/Button";
import {DE, LICENSE_TYPES} from "../../../util/Constants";
import {downloadUsingPost} from "../../../services/BackendService";
import fileDownload from "js-file-download";
import {GeneralContext} from "../../contexts/GeneralContext";

class DocumentsSelection extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        let categoriesSelected = new Map();
        if (props.rentalLinenMap) {
            Array.from(props.rentalLinenMap.values())?.map(articles => {
                return articles?.map(article => {
                    if (article?.category && !categoriesSelected.get(article.category)) {
                        categoriesSelected.set(article?.category, false);
                    }
                    return article;
                });
            });
        }
        this.state = {
            categoriesSelected: categoriesSelected,
            residentsLaundrySelected: false,
            customerArticlesSelected: false,
            operatingResourcesSelected: false
        }
    }

    exportAssortmentOrOperatingResources = async() => {
        this.props.onExportStart();
        let selectedCategories = [];
        Array.from(this.state.categoriesSelected).forEach(([category, selected]) => {
            if (selected) selectedCategories.push(category);
        });
        let rentalLinenIds = [];
        for (let rentalLinenList of Array.from(this.props.rentalLinenMap.values())) {
            for (let rentalLinen of rentalLinenList) {
                if (selectedCategories.includes(rentalLinen.category) && !rentalLinenIds.includes(rentalLinen.id)) {
                    rentalLinenIds.push(rentalLinen.id);
                }
            }
        }
        let exportVo = {
            locale: DE,
            rentalLinenIds: rentalLinenIds,
            includeResidentsLaundry: this.state.residentsLaundrySelected,
            includeCustomerArticles: this.state.customerArticlesSelected,
            includeOperatingResources: this.state.operatingResourcesSelected
        };
        let projectId = this.context.appData.activeProjectId;
        const response = await downloadUsingPost("/assortment/export/" + projectId, exportVo);
        if (response?.status === 200) {
            const fileName = response.headers['content-disposition'].split("filename=")[1];
            fileDownload(response.data, fileName);
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else if (response?.status === 402) {
            this.context.showNoLicenseDialog(LICENSE_TYPES.TENDER_LICENSE);
        } else if (response?.status === 423) {
            this.props.onExportLocked();
        }
        this.props.onExportEnd();
    }

    selectAll = () => {
        let areAllSelected = this.allSelected();
        if (this.props.hasRentalLinenInDocument) {
            let selectedCategories = this.state.categoriesSelected;
            Array.from(selectedCategories.keys()).forEach((category) => {
                if (this.props.hasCategoryInDocument(category)) {
                    selectedCategories.set(category, !areAllSelected);
                }
            });
            this.setState({categoriesSelected: selectedCategories});
        }
        if (this.props.hasResidentsLaundryInDocument) {
            this.setState({residentsLaundrySelected: !areAllSelected});
        }
        if (this.props.hasCustomerArticlesInDocument) {
            this.setState({customerArticlesSelected: !areAllSelected});
        }
        if (this.props.hasOperatingResourcesInDocument) {
            this.setState({operatingResourcesSelected: !areAllSelected});
        }
    }

    selectAllRentalLinen = () => {
        let allSelected = this.allRentalLinenSelected();
        let selectedCategories = this.state.categoriesSelected;
        Array.from(selectedCategories.keys()).forEach((category) => {
            if (this.props.hasCategoryInDocument(category)) {
                selectedCategories.set(category, !allSelected);
            }
        });
        this.setState({categoriesSelected: selectedCategories});
    }

    selectCategory = (category) => {
        let selectedCategories = this.state.categoriesSelected;
        if (selectedCategories.get(category)) {
            selectedCategories.set(category, false);
        } else {
            selectedCategories.set(category, true);
        }
        this.setState({categoriesSelected: selectedCategories});
    }

    selectResidentsLaundry = () => {
        this.setState({residentsLaundrySelected: !this.state.residentsLaundrySelected});
    }

    selectCustomerArticles = () => {
        this.setState({customerArticlesSelected: !this.state.customerArticlesSelected});
    }

    selectOperatingResources = () => {
        this.setState({operatingResourcesSelected: !this.state.operatingResourcesSelected});
    }

    allRentalLinenSelected = () => {
        if (!this.state.categoriesSelected || this.state.categoriesSelected.size === 0) {
            return false;
        }
        return !Array.from(this.state.categoriesSelected.values()).includes(false);
    }

    allSelected = () => {
        if (!this.props.hasContentInDocument) {
            return false;
        }
        return (this.state.residentsLaundrySelected || !this.props.hasResidentsLaundryInDocument)
            && (this.state.customerArticlesSelected || !this.props.hasCustomerArticlesInDocument)
            && (this.state.operatingResourcesSelected || !this.props.hasOperatingResourcesInDocument)
            && (this.allRentalLinenSelected() || !this.props.hasRentalLinenInDocument);
    }

    hasSelectedAnything = () => {
        return this.state.residentsLaundrySelected || this.state.customerArticlesSelected || this.state.operatingResourcesSelected
            || Array.from(this.state.categoriesSelected.values()).includes(true);
    }

    actionButtons = () => {
        return (
            <>
                <Grid item>

                    <Button
                        onClick={() => this.props.closeDialog()}
                        color="inherit"
                        disabled={false}
                        style={{minWidth: "150px"}}>
                        <FormattedMessage id="commons.cancel.button"/>
                    </Button>

                </Grid>

                <Grid item>

                    <Button
                        onClick={() => this.exportAssortmentOrOperatingResources()}
                        color="primary"
                        disabled={!this.hasSelectedAnything()}
                        variant="contained"
                        style={{minWidth: "150px"}}>
                        <FormattedMessage id="commons.download.button"/>
                    </Button>

                </Grid>
            </>
        );
    }

    render() {
        return (
            <>
                <Grid container alignContent="flex-start">
                    <Grid item xs={12}>
                        <Tooltip placement="bottom-end" title={!this.props.hasContentInDocument
                            ? this.props.intl.formatMessage({id: "assortmentAndResources.export.noContent.tooltip"})
                            : ""}>
                            <FormControlLabel
                                key={"all"}
                                control={
                                    <Checkbox
                                        checked={this.allSelected()}
                                        onChange={() => this.selectAll()}
                                        disabled={!this.props.hasContentInDocument}
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                        color="primary"
                                    />
                                }
                                label={<div style={{fontWeight: "bold"}}>{this.props.intl.formatMessage({id: "assortmentAndResources.export.selectAll"})}</div>}
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <Tooltip placement="bottom-end" title={!this.props.hasRentalLinenInDocument
                            ? this.props.intl.formatMessage({id: "assortmentAndResources.export.noRentalLinen.tooltip"})
                            : ""}>
                            <FormControlLabel
                                key={"allRentalLinen"}
                                control={
                                    <Checkbox
                                        checked={this.allRentalLinenSelected()}
                                        onChange={() => this.selectAllRentalLinen()}
                                        disabled={!this.props.hasRentalLinenInDocument}
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                        color="primary"
                                    />
                                }
                                label={<div style={{fontWeight: "bold"}}>{this.props.intl.formatMessage({id: "rentalLinen.export.title"})}</div>}
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <Tooltip placement="bottom-end" title={!this.props.hasRentalLinenInDocument
                            ? this.props.intl.formatMessage({id: "assortmentAndResources.export.noRentalLinen.tooltip"})
                            : ""}>
                            <div style={{paddingLeft: "30px"}}>
                                {Array.from(this.state.categoriesSelected).map(([category, selected]) => {
                                    return <div key={category}><FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selected}
                                                onChange={() => this.selectCategory(category)}
                                                disabled={!this.props.hasRentalLinenInDocument}
                                                inputProps={{'aria-label': 'primary checkbox'}}
                                                color="primary"
                                            />
                                        }
                                        label={this.props.intl.formatMessage({id: "constants.Category." + category})}
                                    /></div>
                                })}
                            </div>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <Tooltip placement="bottom-end" title={!this.props.hasResidentsLaundryInDocument
                            ? this.props.intl.formatMessage({id: "assortmentAndResources.export.noResidentsLaundry.tooltip"})
                            : ""}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.residentsLaundrySelected}
                                        onChange={() => this.selectResidentsLaundry()}
                                        disabled={!this.props.hasResidentsLaundryInDocument}
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                        color="primary"
                                    />
                                }
                                label={<div style={{fontWeight: "bold"}}>{this.props.intl.formatMessage({id: "residentsLaundry.title"})}</div>}
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <Tooltip placement="bottom-end" title={!this.props.hasCustomerArticlesInDocument
                            ? this.props.intl.formatMessage({id: "assortmentAndResources.export.noCustomerArticles.tooltip"})
                            : ""}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.customerArticlesSelected}
                                        onChange={() => this.selectCustomerArticles()}
                                        disabled={!this.props.hasCustomerArticlesInDocument}
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                        color="primary"
                                    />
                                }
                                label={<div style={{fontWeight: "bold"}}>{this.props.intl.formatMessage({id: "customerArticle.title"})}</div>}
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <Tooltip placement="bottom-end" title={!this.props.hasOperatingResourcesInDocument
                            ? this.props.intl.formatMessage({id: "assortmentAndResources.export.noOperatingResources.tooltip"})
                            : ""}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.operatingResourcesSelected}
                                        onChange={() => this.selectOperatingResources()}
                                        disabled={!this.props.hasOperatingResourcesInDocument}
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                        color="primary"
                                    />
                                }
                                label={<div style={{fontWeight: "bold"}}>{this.props.intl.formatMessage({id: "operatingResources.export.title"})}</div>}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>

                <Divider style={{marginLeft: "50px", marginRight: "50px"}}/>

                <DialogActions style={{paddingTop: "30px", paddingBottom: "40px", paddingLeft: "50px", paddingRight: "50px"}}>

                    <MediaQuery minWidth={800}>
                        <Grid container justifyContent='flex-end' spacing={2}>
                            {this.actionButtons()}
                        </Grid>
                    </MediaQuery>

                    <MediaQuery maxWidth={799}>
                        <Grid container justifyContent='center' spacing={2}>
                            {this.actionButtons()}
                        </Grid>
                    </MediaQuery>

                </DialogActions>
            </>
        );
    }
}

export default injectIntl(DocumentsSelection);
