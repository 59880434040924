import React, {Component} from "react";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {
    DELIVERY_PERIOD_MONTH,
    DELIVERY_PERIOD_ONE_WEEK,
    DELIVERY_PERIOD_TWO_WEEKS,
    DELIVERY_PERIODS,
    DURATION_MONTH,
    DURATION_ONE_WEEK,
    DURATION_TWO_WEEKS
} from "../../../../util/Constants";
import {Select, Typography} from "@material-ui/core";
import '../../../../css/Validation.css';

class DeliveryPeriodSelectBox extends Component {

    static contextType = GeneralContext;

    handleDeliveryPeriodChanged = (invoice, value) => {
        let deliveryPeriod = {
            from: invoice.deliveryDate,
            to: invoice.deliveryDate
        }
        switch(value) {
            case DELIVERY_PERIOD_ONE_WEEK:
                deliveryPeriod.to += DURATION_ONE_WEEK;
                break;
            case DELIVERY_PERIOD_TWO_WEEKS:
                deliveryPeriod.to += DURATION_TWO_WEEKS;
                break;
            case DELIVERY_PERIOD_MONTH:
                deliveryPeriod.to += DURATION_MONTH;
                break;
            default:
                deliveryPeriod = null;
                break;
        }
        this.props.onChange(deliveryPeriod);
    }

    getDeliveryPeriodConstant = (invoice) => {
        if (!invoice.deliveryPeriodVo) {
            return "";
        } else {
            const durationMilliseconds = parseInt(invoice.deliveryPeriodVo.to) - parseInt(invoice.deliveryPeriodVo.from);
            if (durationMilliseconds >= DURATION_MONTH) {
                return DELIVERY_PERIOD_MONTH;
            } else if(durationMilliseconds >= DURATION_TWO_WEEKS) {
                return DELIVERY_PERIOD_TWO_WEEKS;
            } else if(durationMilliseconds >= DURATION_ONE_WEEK) {
                return DELIVERY_PERIOD_ONE_WEEK;
            } else {
                return "";
            }
        }
    }

    render() {
        let invoice = this.props.invoice;
        let selectedPeriod = this.getDeliveryPeriodConstant(invoice);
        return <>
            <Select
                native
                disableUnderline
                key={"delivery-period-select-" + invoice.id.toString()}
                value={selectedPeriod}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => this.handleDeliveryPeriodChanged(invoice, e.target.value)}
                className={selectedPeriod ? "validationBorder grayBorder" : "validationBorder redBorder"}>
                <option key={"placeholder"} value="" disabled>-</option>
                {
                    DELIVERY_PERIODS.map(period => {
                        return <option key={period} value={period}>
                            {this.props.intl.formatMessage({id: "constants.DeliveryPeriod." + period})}
                        </option>
                    })
                }
            </Select>
            {!selectedPeriod && <Typography className="redText">
                    {this.props.intl.formatMessage({id: "project.create.deliveryPeriod.placeholder"})}
                </Typography>
            }
        </>;
    }
}
DeliveryPeriodSelectBox.propTypes = {
    invoice: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};
export default injectIntl(DeliveryPeriodSelectBox);
