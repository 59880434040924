import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Card, Typography} from "@material-ui/core";
import TexisionContainer from "../uiLibrary/TexisionContainer";
import {GeneralContext} from "../contexts/GeneralContext";


class Privacy extends Component {

    static contextType = GeneralContext;

    privacy = () => {
        return <div>
            <Typography variant="h1">
                <FormattedMessage id="privacy.title"/>
            </Typography>

            <Typography variant="subtitle2">
                <FormattedMessage id="privacy.text1"/>
            </Typography>

            <Typography variant="subtitle2">
                <FormattedMessage id="privacy.text2"/>
            </Typography>
        </div>;
    }

    render() {
        return this.context.currentUser
            ? <Card style={{padding: "3%"}}>{this.privacy()}</Card>
            : <TexisionContainer showBackButton="true" content={this.privacy()}/>;
    }
}

export default injectIntl(Privacy);
