import React, {Component} from 'react';
import "../../css/SelectableCard.css";

class SelectableCard extends Component {
    render() {
        const isSelected = this.props.selected ? "selected" : "";
        const className = "selectable " + isSelected;
        return (
            <div id="selectable-card">
                <div className="card">
                    <div className={className} onClick={this.props.onSelect}>
                        {this.props.children}
                        <div className="check"><span className="checkmark">✔</span></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SelectableCard;
