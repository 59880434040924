import {Grid, Typography, Card, CardContent} from "@material-ui/core";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {getAsync} from "../../../services/BackendService";
import {CATEGORY_FLAT_LINEN, CATEGORY_SURGICAL_TEXTILES, CATEGORY_WORK_WEAR, DIALOG_TYPE_ERROR, DIALOG_TYPE_INFO, LICENSE_TYPES} from "../../../util/Constants";
import {enhanceAssignedResourcesWithMasterData, sortArticles} from "../../../util/Util";

import TexisionDialog from "../../uiLibrary/TexisionDialog";
import TexisionExportHeader from "../ExportHeader";
import ArticleDetail from "../../share/ArticleDetail";
import {assortmentConflictMerges, relevantAssortmentConflicts} from "../../../util/ExportUtil";
import {GeneralContext} from "../../contexts/GeneralContext";
import DocumentsSelection from "./DocumentsSelection";
import CustomerArticlesTable from "./CustomerArticlesTable";
import ResidentsLaundryTable from "./ResidentsLaundryTable";
import RentalLinenTable from "./RentalLinenTable";
import OperatingResourceTable from "./OperatingResourceTable";
import {getCustomerArticlesForUnit} from "../../../services/CustomerArticleService";
import {getResidentsLaundryForUnit} from "../../../services/ResidentsLaundryService";
import {getAssignedBusinessUnitResourcesForUnit, getOperatingResourcesForUnit} from "../../../services/OperatingResourceService";
import {loadArticleConfigurations} from "../../../services/ArticleConfigurationService";
import {getUnitsForActiveProjectFromContext} from "../../../services/BusinessUnitService";
import {latestActivePayment} from "../../../services/PaymentService";


class AssortmentExport extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showNoAssortmentDialog: false, 
            showNoResourcesDialog: false, 
            showSelectionDialog: false,
            rentalLinenMap: null,
            residentsLaundryMap: null,
            customerArticlesMap: null,
            operatingResourcesMap: null,
            showArticleDetails: false,
            articleForDetailView: null,
            isExporting: false,
            isLoading: true,
            showLockedDialog: false
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async() => {
        const articleConfigurations = await loadArticleConfigurations(this.context, this.props);
        let rentalLinenMap = new Map();
        let residentsLaundryMap = new Map();
        let customerArticlesMap = new Map();
        let operatingResourcesMap = new Map();
        for (let unit of getUnitsForActiveProjectFromContext(this.context)) {
            rentalLinenMap.set(unit.id, await this.loadRentalLinen(unit.id));
            let residentsLaundry = await getResidentsLaundryForUnit(this.context, this.props, unit.id);
            if (residentsLaundry) {
                residentsLaundry = residentsLaundry.filter(r => r.selected && r.amount);
            }
            residentsLaundryMap.set(unit.id, residentsLaundry);
            customerArticlesMap.set(unit.id, await getCustomerArticlesForUnit(this.context, this.props, unit.id));
            operatingResourcesMap.set(unit.id, await this.loadOperatingResources(unit.id));
        }
        this.setState({
            articleConfigurations: articleConfigurations,
            rentalLinenMap: rentalLinenMap,
            residentsLaundryMap: residentsLaundryMap,
            customerArticlesMap: customerArticlesMap,
            operatingResourcesMap: operatingResourcesMap,
            isLoading: false
        });
    }

    loadRentalLinen = async(unitId) => {
        const response = await getAsync('/assortment/' + unitId + '/articles');
        if (response?.status === 200) {
            return response.data?.sort((a, b) => sortArticles(a, b));
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    loadOperatingResources = async(unitId) => {
        const operatingResourcesResponse = await getOperatingResourcesForUnit(this.context, this.props, unitId);
        const assignedResourcesResponse = await getAssignedBusinessUnitResourcesForUnit(this.context, this.props, unitId);
        if (operatingResourcesResponse?.status === 200 && assignedResourcesResponse?.status === 200) {
            return enhanceAssignedResourcesWithMasterData(operatingResourcesResponse.data, assignedResourcesResponse.data);
        } else if ([401, 403].includes(operatingResourcesResponse?.status) || [401, 403].includes(assignedResourcesResponse?.status)) {
            await this.context.logout();
        }
    }

    hasCategoryInUnit = (unitId, category) => {
        return this.state.rentalLinenMap && this.state.rentalLinenMap.get(unitId)
            && this.state.rentalLinenMap.get(unitId).filter(r => r.category === category).length > 0;
    }

    hasRentalLinenInUnit = (unitId) => {
        return this.state.rentalLinenMap && this.state.rentalLinenMap.get(unitId) && this.state.rentalLinenMap.get(unitId).length > 0;
    }

    hasResidentsLaundryInUnit = (unitId) => {
        return this.state.residentsLaundryMap && this.state.residentsLaundryMap.get(unitId) && this.state.residentsLaundryMap.get(unitId).length > 0;
    }

    hasCustomerArticlesInUnit = (unitId) => {
        return this.state.customerArticlesMap && this.state.customerArticlesMap.get(unitId) && this.state.customerArticlesMap.get(unitId).length > 0;
    }

    hasOperatingResourcesInUnit = (unitId) => {
        return this.state.operatingResourcesMap && this.state.operatingResourcesMap.get(unitId) && this.state.operatingResourcesMap.get(unitId).length > 0;
    }

    hasContentInUnit = (unitId) => {
        return this.hasCategoryInUnit(unitId, CATEGORY_WORK_WEAR)
            || this.hasCategoryInUnit(unitId, CATEGORY_FLAT_LINEN)
            || this.hasCategoryInUnit(unitId, CATEGORY_SURGICAL_TEXTILES)
            || this.hasResidentsLaundryInUnit(unitId)
            || this.hasCustomerArticlesInUnit(unitId)
            || this.hasOperatingResourcesInUnit(unitId);
    }

    unitPanel = (unitId) => {
        return <Grid key={unitId} item xs={12} style={{minWidth: "400px"}}>
            <Card style={{paddingBottom: "20px"}}>
                <CardContent>
                    <Typography variant="h2" style={{marginBottom: "1%"}}>
                        {getUnitsForActiveProjectFromContext(this.context).find(u => u.id === parseInt(unitId))?.name}
                    </Typography>

                    <RentalLinenTable
                        hasContent={this.hasRentalLinenInUnit(unitId)}
                        hasCategoryInUnit={(unitId, category) => this.hasCategoryInUnit(unitId, category)}
                        unitId={unitId}
                        rentalLinen={this.state.rentalLinenMap}
                        articleConfigurations={this.state.articleConfigurations}
                        showArticleDetails={(article) => this.showArticleDetails(article)}/>

                    <ResidentsLaundryTable
                        hasContent={this.hasResidentsLaundryInUnit(unitId)}
                        unitId={unitId}
                        residentsLaundry={this.state.residentsLaundryMap.get(unitId)}/>

                    <CustomerArticlesTable
                        hasContent={this.hasCustomerArticlesInUnit(unitId)}
                        unitId={unitId}
                        customerArticles={this.state.customerArticlesMap.get(unitId)}/>

                    <OperatingResourceTable
                        hasContent={this.hasOperatingResourcesInUnit(unitId)}
                        unitId={unitId}
                        operatingResources={this.state.operatingResourcesMap.get(unitId)}/>

                </CardContent>
            </Card>
        </Grid>
    }

    showArticleDetails = (article) => {
        this.setState({showArticleDetails: true, articleForDetailView: article});
    }

    hideArticleDetails = () => {
        this.setState({showArticleDetails: false, articleForDetailView: null});
    }

    hasContentInDocument = () => {
        return this.hasRentalLinenInDocument() || this.hasResidentsLaundryInDocument()
            || this.hasCustomerArticlesInDocument() || this.hasOperatingResourcesInDocument();
    }

    hasRentalLinenInDocument = () => {
        if (!this.state.rentalLinenMap) {
            return false;
        }
        for (let articlesList of Array.from(this.state.rentalLinenMap.values())) {
            if (articlesList && articlesList.length > 0) {
                return true;
            }
        }
        return false;
    }

    hasCategoryInDocument = (category) => {
        if (!this.state.rentalLinenMap) {
            return false;
        }
        for (let articlesList of Array.from(this.state.rentalLinenMap.values())) {
            if (articlesList && articlesList.filter(r => r.category === category).length > 0) {
                return true;
            }
        }
        return false;
    }

    hasResidentsLaundryInDocument = () => {
        if (!this.state.residentsLaundryMap) {
            return false;
        }
        for (let laundryList of Array.from(this.state.residentsLaundryMap.values())) {
            if (laundryList && laundryList.length > 0) {
                return true;
            }
        }
        return false;
    }

    hasCustomerArticlesInDocument = () => {
        if (!this.state.customerArticlesMap) {
            return false;
        }
        for (let customerArticlesList of Array.from(this.state.customerArticlesMap.values())) {
            if (customerArticlesList && customerArticlesList.length > 0) {
                return true;
            }
        }
        return false;
    }

    hasOperatingResourcesInDocument = () => {
        if (!this.state.operatingResourcesMap) {
            return false;
        }
        for (let resourcesList of Array.from(this.state.operatingResourcesMap.values())) {
            if (resourcesList && resourcesList.length > 0) {
                return true;
            }
        }
        return false;
    }

    render() {
        return <div className={latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE) ? "contentAreaExportLocked" : null}>

            <TexisionDialog
                type={DIALOG_TYPE_ERROR}
                open={this.state.showLockedDialog}
                titleId="export.locked.title"
                subtitleId="export.locked.subtitle"
                actionId="commons.okay.button"
                onAction={() => this.setState({showLockedDialog: false})}/>

            <TexisionDialog
                type={DIALOG_TYPE_INFO}
                open={this.state.showSelectionDialog}
                titleId="assortmentAndResources.export.selection.title"
                subtitleId="assortmentAndResources.export.selection.subtitle"
                content={<DocumentsSelection
                    closeDialog={() => this.setState({showSelectionDialog: false})}
                    hasContentInDocument={this.hasContentInDocument()}
                    hasRentalLinenInDocument={this.hasRentalLinenInDocument()}
                    hasCategoryInDocument={(category) => this.hasCategoryInDocument(category)}
                    hasCustomerArticlesInDocument={this.hasCustomerArticlesInDocument()}
                    hasResidentsLaundryInDocument={this.hasResidentsLaundryInDocument()}
                    hasOperatingResourcesInDocument={this.hasOperatingResourcesInDocument()}
                    rentalLinenMap={this.state.rentalLinenMap}
                    onExportStart={() => this.setState({isExporting: true, showSelectionDialog: false})}
                    onExportEnd={() => this.setState({isExporting: false})}
                    onExportLocked={() => this.setState({showLockedDialog: true})}/>}
                />

            <TexisionExportHeader
                documentType="assortmentAndResources"
                titleId="assortmentAndResources.export.title"
                subtitleId="assortmentAndResources.export.subtitle"
                exportButtonId="assortmentAndResources.export.button"
                export={(exportType, e) => this.setState({showSelectionDialog: true})}
                relevantConflicts={relevantAssortmentConflicts}
                forAllUnits={true}
                isExporting={this.state.isExporting}
                mergedConflicts={assortmentConflictMerges()}
            />

            {this.state.articleForDetailView && <ArticleDetail 
                article={this.state.articleForDetailView}
                showDialog={this.state.showArticleDetails}
                handleDialogClose={this.hideArticleDetails}
            />}

            {!this.state.isLoading && !this.hasContentInDocument() && 
            <Card>
                <CardContent style={{textAlign: "center"}} >
                    <Typography variant="subtitle1" component="span">
                        <FormattedMessage id="assortmentAndResources.export.noContent.tooltip"/>
                    </Typography>
                </CardContent>
            </Card>}

            <Grid container spacing={1}>
                {getUnitsForActiveProjectFromContext(this.context)?.filter(unit => this.hasContentInUnit(unit.id))?.map(unit => this.unitPanel(unit.id))}
            </Grid>

        </div>;
    }
}

export default injectIntl(AssortmentExport);
