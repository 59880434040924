import { Card, CardContent, Typography } from "@material-ui/core";
import fileDownload from "js-file-download";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {downloadUsingGet} from "../../services/BackendService";
import {
    DE,
    DELIVERY,
    DELIVERY_PICKUP,
    DIALOG_TYPE_ERROR,
    DIALOG_TYPE_WARNING,
    PICKUP,
    LICENSE_TYPES
} from "../../util/Constants";
import {relevantLogisticsConflicts} from "../../util/ExportUtil";

import TexisionDialog from "../uiLibrary/TexisionDialog";
import ExportHeader from "./ExportHeader";
import {GeneralContext} from "../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import {getUnitAddresses} from "../../services/AddressService";
import {latestActivePayment} from "../../services/PaymentService";


class Logistics extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showNoContentDialog: false, 
            deliveryAddresses: [], 
            pickupAddresses: [], 
            isLoading: true,
            showLockedDialog: false
        };
    }

    async componentDidMount() {
        await this.loadDeliveryAddresses();
    }

    loadDeliveryAddresses = async() => {
        const addresses = await getUnitAddresses(this.context, this.props);
        this.setState({
            deliveryAddresses: addresses?.filter(a => a.addressType === DELIVERY || a.addressType === DELIVERY_PICKUP) ?? [],
            pickupAddresses: addresses?.filter(a => a.addressType === PICKUP || a.addressType === DELIVERY_PICKUP) ?? [],
            isLoading: false
        });
    }

    /**
     * Util function to export the delivery points and pick-up stations
     */
    exportDeliveryPoints = async() => {
        let projectId = this.context.appData.activeProjectId;
        let response = await downloadUsingGet('/deliveryaddresslist/' + projectId + "/" + DE);
        if (response?.status === 200) {
            const fileName = response.headers['content-disposition'].split("filename=")[1];
            fileDownload(response.data, fileName);
        } else if (response?.status === 204) {
            this.setState({showNoContentDialog: true});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else if (response?.status === 402) {
            this.context.showNoLicenseDialog(LICENSE_TYPES.TENDER_LICENSE);
        } else if (response?.status === 423) {
            this.setState({showLockedDialog: true});
        }
    }

    hasData = () => {
        return !!this.state.deliveryAddresses?.length && !!this.state.pickupAddresses?.length;
    }

    render() {
        return <div className={latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE) ? "contentAreaExportLocked" : null}>
            {/* the state for the dialog is set in the export method from ExportUtil */}
            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.state.showNoContentDialog}
                titleId="logistics.export.noContent.title"
                subtitleId="logistics.export.noContent.title"
                actionId="commons.okay.button"
                onAction={() => this.setState({showNoContentDialog: false})}/>

            <TexisionDialog
                type={DIALOG_TYPE_ERROR}
                open={this.state.showLockedDialog}
                titleId="export.locked.title"
                subtitleId="export.locked.subtitle"
                actionId="commons.okay.button"
                onAction={() => this.setState({showLockedDialog: false})}/>
            
            <ExportHeader
                documentType="logistics"
                titleId="logistics.export.title"
                subtitleId="logistics.export.subtitle"
                exportButtonId="logistics.export.button"
                export={(exportType, e) => this.exportDeliveryPoints()}
                relevantConflicts={relevantLogisticsConflicts}
                forAllUnits={true}
            />

            {!this.state.isLoading && !this.hasData() && <Card>
                    <CardContent style={{textAlign: "center"}} >
                        <Typography variant="subtitle1" component="span">
                            <FormattedMessage id="logistics.export.noData"/>
                        </Typography>
                    </CardContent>
                </Card>}
        </div>;
    }
}

export default withRouter(injectIntl(Logistics));
