import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {GeneralContext} from "../../../contexts/GeneralContext";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import {PICKUP} from "../../../../util/Constants";
import DeliveryOrPickupAddressTile from "../shared/DeliveryOrPickupAddressTile";

class PickupAddressOverview extends Component {

    static contextType = GeneralContext;

    render() {
        return (
            <Card className="child">

                <CardContent>

                    <Grid container className="mb-5">

                        <Grid item xs={12}>
                            <Typography variant="h6" component="h6">
                                <FormattedMessage id="deliveryAndPickup.table.pickup"/>
                            </Typography>
                        </Grid>

                    </Grid>

                    <Divider/>

                    <Typography variant="h5" className="mt-6 mb-6">
                        <FormattedMessage id="deliveryAndPickup.table.pickup.headline"/>
                        <span style={{fontWeight: 400}}>
                            {" (" + this.props.intl.formatMessage({id: "deliveryAndPickup.table.count"}) + " " + (this.props.allPickupAddresses?.length ?? 0) + ")"}
                        </span>
                    </Typography>

                    <Grid container style={{marginLeft: 30, marginRight: 30, marginBottom: 30}}>
                        {this.props.allPickupAddresses?.map(a => <Grid item style={{marginRight: 20}}>
                            <DeliveryOrPickupAddressTile
                                key={a.id}
                                readOnly={this.props.readOnly}
                                addressType={PICKUP}
                                address={a}
                                allDeliveryAddresses={this.props.allDeliveryAddresses}
                                allPickupAddresses={this.props.allPickupAddresses}
                                onSave={() => this.props.reloadAddresses()}/>
                        </Grid>)}
                    </Grid>

                    <div style={{marginLeft: 30, marginRight: 30}}>
                        <DeliveryOrPickupAddressTile
                            key={"create-new-pick-up-address"}
                            readOnly={this.props.readOnly}
                            addressType={PICKUP}
                            allDeliveryAddresses={this.props.allDeliveryAddresses}
                            allPickupAddresses={this.props.allPickupAddresses}
                            onSave={() => this.props.reloadAddresses()}/>
                    </div>

                </CardContent>

            </Card>
        );
    }
}

export default injectIntl(withSnackbar(PickupAddressOverview));
