import React, {Component} from 'react';
import {Typography, Grid, TextField} from '@material-ui/core';
import {isEmptyOrBlank} from '../../../util/Util';

import {FormattedMessage, injectIntl} from 'react-intl';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {DIALOG_TYPE_INFO} from '../../../util/Constants';
import '../../../css/EquipmentOverview.css';
import {GeneralContext} from "../../contexts/GeneralContext";


class AreaCreateDialog extends Component {

    static contextType = GeneralContext;
    
    constructor(props) {
        super(props);
        if (this.props.area) {
            this.state = {
                id: this.props.area.id, 
                name: this.props.area.name,
                businessUnitId: this.props.area.businessUnitId,
                articleIds: this.props.area.articleIds,
                version: this.props.area.version
            }
        } else {
            this.state = {
                id: null, 
                name: "",
                businessUnitId: null,
                articleIds: [],
                version: 0
            }
        }
    }

    createOrUpdateArea = async() => {

        let result = await this.props.createOrUpdateArea({
            id: this.state.id,
            name: this.state.name,
            businessUnitId: this.state.businessUnitId,
            articleIds: this.state.articleIds,
            version: this.state.version
        });

        if (result) {
            this.resetState();
            this.props.showDialog(false);
        }
    }

    hideDialog = () => {
        this.resetState();
        this.props.showDialog(false);
    }

    resetState = () => {
        this.setState({id: null, name: "", version: 0});
    }

    render() {
        const mode = this.props.area ? "edit" : "create";
        return (
            <div>
                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.props.open}
                    hasNoTitle={true}
                    content={<Grid container spacing={2}>
                        <Grid item xs={12} className="equipmentCreateTitle">
                            <Typography variant="h2"><FormattedMessage id={"area.dialog." + mode + ".title"}/></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography><FormattedMessage id={"area.dialog." + mode + ".subtitle"}/></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="equipmentCreateInput"
                                id="description"
                                value={this.state.name}
                                label={this.props.intl.formatMessage({id: "area.dialog.name"})}
                                variant="outlined" 
                                inputProps={{maxLength: 300}}
                                onChange={(e) => this.setState({name: e.target.value})}/>
                        </Grid>
                    </Grid>}
                    actionId="commons.save.button"
                    cancelId="commons.cancel.button"
                    onAction={() => this.createOrUpdateArea()}
                    onCancel={() => this.hideDialog()}
                    actionDisabled={isEmptyOrBlank(this.state.name)}/>
            </div>
        );
    }
}

export default injectIntl(AreaCreateDialog);
