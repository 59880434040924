import {Card, CardContent, Grid, TextField, Typography} from "@material-ui/core";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { texisionOrange } from "../../../util/ColorTheme";
import ValidationIcon from "../../validation/ValidationIcon";

class ArticleDescription extends Component {

    render() {
        return (
            <Card className="child">

                <CardContent>

                    <Grid container alignItems="flex-start">
                        <Grid item xs>
                            <Typography variant="h6" component="h2">
                                <FormattedMessage id="articleConfigurator.description.h1"/>*
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ValidationIcon complete={this.props.description} size={30}/>
                        </Grid>
                    </Grid>

                    <Typography color="textSecondary" style={{marginBottom: "20px"}}>
                        <FormattedMessage id="articleConfigurator.description.h2"/>
                    </Typography>

                    <TextField 
                        id="description"
                        value={this.props.description}
                        label={<Typography 
                            style={!this.props.description ? {color: texisionOrange} : {}}>
                            <FormattedMessage id="entities.article.description"/> *
                        </Typography>}
                        variant="outlined" 
                        inputProps={{maxLength: 300}} 
                        style={{width: "94%"}}
                        onChange={(e) => this.props.handleDescriptionChanged(e.target.value)}/>

                </CardContent>

            </Card>
        );
    }
}

export default injectIntl(ArticleDescription);
