import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Card, Typography} from "@material-ui/core";
import {PRIVACY_ROUTE} from "../../util/Constants";
import {Link} from 'react-router-dom';
import TexisionContainer from "../uiLibrary/TexisionContainer";
import {GeneralContext} from "../contexts/GeneralContext";


class Impressum extends Component {

    static contextType = GeneralContext;

    impressum = () => {
        return <div>
            <Typography variant="h1">
                <FormattedMessage id="impressum.title"/>
            </Typography>

            <Typography variant="h3" style={{paddingBottom: "10px"}}>
                <FormattedMessage id="impressum.responsibility.title"/>
            </Typography>
            <Typography variant="subtitle2">
                <div style={{fontWeight: "bold", paddingBottom: "5px", opacity: 0.6}}><FormattedMessage id="impressum.company"/></div>
                <div><FormattedMessage id="impressum.address.street"/></div>
                <FormattedMessage id="impressum.address.city"/>
            </Typography>

            <Typography variant="subtitle2">
                <div style={{opacity: 0.9}}>{this.props.intl.formatMessage({id: "impressum.mail.title"}) + ":"}</div>
                {this.props.intl.formatMessage({id: "impressum.mail.address"})}
            </Typography>

            <Typography variant="h3" style={{paddingBottom: "10px"}}>
                <FormattedMessage id="impressum.ceo.title"/>
            </Typography>
            <Typography variant="subtitle2">
                <FormattedMessage id="impressum.ceo.name"/>
            </Typography>

            <Typography variant="h3" style={{paddingBottom: "10px"}}>
                <FormattedMessage id="impressum.court.title"/>
            </Typography>
            <Typography variant="subtitle2">
                <FormattedMessage id="impressum.court.name"/>
            </Typography>

            <Typography variant="h3" style={{paddingBottom: "10px"}}>
                <FormattedMessage id="impressum.taxId.title"/>
            </Typography>
            <Typography variant="subtitle2">
                <FormattedMessage id="impressum.taxId.number"/>
            </Typography>

            <Typography variant="subtitle2">
                <FormattedMessage id="impressum.conflict.title"/>{": "}
                <a href={this.props.intl.formatMessage({id: "impressum.conflict.link"})} target="_blank" rel="noreferrer noopener">
                    <FormattedMessage id="impressum.conflict.link"/>
                </a>
            </Typography>
            <Typography variant="subtitle2">
                <FormattedMessage id="impressum.conflict.text"/>
            </Typography>

            <Typography variant="h3" style={{paddingBottom: "20px"}}>
                <FormattedMessage id="impressum.privacy.title"/>
            </Typography>
            <Typography variant="subtitle2">
                <FormattedMessage id="impressum.privacy.text"/>{" "}
                <Link to={PRIVACY_ROUTE}><FormattedMessage id="impressum.privacy.link"/></Link>
            </Typography>
        </div>;
    }

    render() {
        return this.context.currentUser
            ? <Card style={{padding: "3%"}}>{this.impressum()}</Card>
            : <TexisionContainer showBackButton="true" content={this.impressum()}/>;
    }
}

export default injectIntl(Impressum);
