import {Component} from "react";
import {injectIntl} from "react-intl";
import {GeneralContext} from "../contexts/GeneralContext";
import {APP_TYPE_KEY, COCKPIT_APP, DIALOG_TYPE_ERROR, DIALOG_TYPE_INFO, DIALOG_TYPE_WARNING, ROOT_ROUTE} from "../../util/Constants";
import {postAsync} from "../../services/BackendService";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import {withRouter} from "react-router-dom";
import {getPaymentsAndEvents} from "../../services/PaymentService";

class OperationAssignment extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showInvalidLinkDialog: false,
            showSuccessDialog: false,
            showInvalidTokenDialog: false,
            showErrorDialog: false,
            operationId: null
        }
    }

    async componentDidMount() {
        const requestParameters = new URLSearchParams(window.location.search);
        const operationId = requestParameters.get("operationId");
        const token = requestParameters.get("token");
        if (!operationId || !token) {
            this.setState({showInvalidLinkDialog: true});
        } else {
            await this.assignToOperation(token, operationId);
        }
    }

    assignToOperation = async(token, operationId) => {
        const userId = this.context.currentUser?.id;
        const response = await postAsync("/user/" + userId + "/operation/" + operationId + "?token=" + token, null);
        if (response?.status === 200) {
            this.setState({showSuccessDialog: true, operationId: parseInt(operationId)});
        } else if (response?.status === 403) {
            this.setState({showInvalidTokenDialog: true});
        } else {
            this.setState({showErrorDialog: true});
        }
    }

    onSuccess = async() => {
        localStorage.setItem(APP_TYPE_KEY, COCKPIT_APP);
        this.props.history.push(ROOT_ROUTE);
        await this.context.reloadAppData();
        await this.context.setActiveOperationIdForCockpit(this.state.operationId);
        await getPaymentsAndEvents(this.context);
    }

    render() {
        return (
            <>
                <TexisionDialog
                    open={this.state.showInvalidLinkDialog}
                    type={DIALOG_TYPE_WARNING}
                    titleId="operationAssignment.invalidLink.dialog.title"
                    subtitleId="operationAssignment.invalidLink.dialog.subtitle"
                    cancelId="commons.close.label"
                    onCancel={() => this.props.history.push(ROOT_ROUTE)}/>

                <TexisionDialog
                    open={this.state.showSuccessDialog}
                    type={DIALOG_TYPE_INFO}
                    titleId="operationAssignment.success.dialog.title"
                    subtitleId="operationAssignment.success.dialog.subtitle"
                    actionId="commons.continue.button"
                    onAction={() => this.onSuccess()}/>

                <TexisionDialog
                    open={this.state.showInvalidTokenDialog}
                    type={DIALOG_TYPE_WARNING}
                    titleId="operationAssignment.invalidToken.dialog.title"
                    subtitleId="operationAssignment.invalidToken.dialog.subtitle"
                    cancelId="commons.close.label"
                    onCancel={() => this.props.history.push(ROOT_ROUTE)}/>

                <TexisionDialog
                    open={this.state.showErrorDialog}
                    type={DIALOG_TYPE_ERROR}
                    titleId="operationAssignment.error.dialog.title"
                    subtitleId="operationAssignment.error.dialog.subtitle"
                    cancelId="commons.close.label"
                    onCancel={() => this.props.history.push(ROOT_ROUTE)}/>
            </>
        );
    }
}

export default withRouter(injectIntl(OperationAssignment));
