import React, {Component} from 'react';
import {renderChecked} from '../../../../../util/TableUtil';
import {Card, Grid, Typography, Tooltip, Icon} from '@material-ui/core';
import {injectIntl} from 'react-intl';
import {GeneralContext} from "../../../../contexts/GeneralContext";

class LogisticsProfessionalCard extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            professionalGroup: props.professionalGroup,
            handleCheckboxChanged: props.handleCheckboxChanged,
        };
    }

    render() {
        return (
            <div>
                <Card style={{width: "140px", height: "140px", borderRadius: 10, backgroundColor: "#CDDBE3"}}>

                    <Grid container spacing={3} alignItems="center">

                        <Grid container justifyContent="space-between" alignItems="center">

                            <Grid item xs style={{overflow: "hidden", whiteSpace: "nowrap"}}>
                                <Typography variant="h3">
                                    {this.props.professionalGroup.name}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Tooltip title={this.props.intl.formatMessage({id: "professionalDeliveryPointAssignment.tooltip.checkbox"})}>
                                    {renderChecked(this.props.professionalGroup, this.props.handleCheckboxChanged, this.props.readOnly)}
                                </Tooltip>
                            </Grid>

                        </Grid>

                        <Grid container={true} justifyContent="flex-start">

                            <Typography variant="subtitle2">
                                {this.props.intl.formatMessage({id: "areaDeliveryPointAssignment.description.articleCount"}) + " " + this.props.professionalGroup.articleIds.length}
                            </Typography>

                        </Grid>

                        <Grid container={true} justifyContent="center" alignItems="flex-start">
                            <Icon className="icon-medical-doctor" style={{fontSize: 60}}/>
                        </Grid>

                    </Grid> 
                </Card>
            </div>
        );
    }
}
export default injectIntl(LogisticsProfessionalCard);
