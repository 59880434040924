import React, {Component} from 'react';

import MyAccount from '../account/MyAccount';

import {
    MY_ACCOUNT_ROUTE,
    IMPRESSUM_ROUTE,
    AGB_ROUTE,
    PRIVACY_ROUTE,
    BIDDER_DASHBOARD_ROUTE,
    BIDDER_COMMUNICATION_ROUTE,
    BIDDER_OFFER_DOCUMENTS_ROUTE,
    BIDDER_OFFER_PRICE_SHEET_ROUTE,
    BIDDER_OFFER_SUBMIT_ROUTE,
    BIDDER_OFFER_RESULT_ROUTE,
    BIDDER_PROJECT_DETAILS_ROUTE,
    buildRouteWithPathVariablePlaceholder,
    DIALOG_TYPE_INFO,
    COCKPIT_APP,
    OFFER_STATUS_ACCEPTED,
    OFFER_STATUS_REJECTED,
    MOBILE_THRESHOLD,
    USER_MANAGEMENT_ROUTE,
    OPERATION_ASSIGNMENT_ROUTE,
    ARTICLE_CONFIGURATOR_ROUTE,
    ARTICLE_CONFIGURATION_OVERVIEW_ROUTE,
    OPERATING_RESOURCE_OVERVIEW_ROUTE,
    OPERATING_RESOURCE_CONFIGURATOR_ROUTE, PROFILES_ROUTE, RELEASE_NOTES_ROUTE
} from '../../util/Constants';

import {Route} from "react-router-dom";

import '../apps/App.css';
import BidderCommunication from '../communication/BidderCommunication';
import TexisionDialog from '../uiLibrary/TexisionDialog';
import { Redirect } from 'react-router-dom';
import Privacy from '../legal/Privacy';
import TermsOfUse from '../legal/TermsOfUse';
import Impressum from '../legal/Impressum';
import { Switch } from 'react-router-dom';
import BidderProjectDetails from "../bidder/BidderProjectDetails";
import BidderOfferDocumentsOverview from "../bidderDocuments/BidderOfferDocumentsOverview";
import BidderOfferSubmit from "../bidder/BidderOfferSubmit";
import BidderOfferResult from "../bidder/BidderOfferResult";
import BidderDashboard from "../bidder/BidderDashboard";
import OfferNavBar from '../navigation/offer/OfferNavBar';
import {GeneralContext} from "../contexts/GeneralContext";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import MediaQuery from "react-responsive";
import { Button } from '@material-ui/core';
import {getAsync, postAsync} from '../../services/BackendService';
import { createErrorMessage } from '../../util/Util';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import { Role } from '../../services/UserService';
import PrivateRoute from '../navigation/shared/PrivateRoute';
import UserManagement from '../administration/userManagement/UserManagement';
import OperationAssignment from "../project/OperationAssignment";
import ArticleConfigurator from "../administration/articleConfigurator/ArticleConfigurator";
import ArticleConfigurationOverview from "../administration/articleOverview/ArticleConfigurationOverview";
import OperatingResourceOverview from "../administration/operatingResources/OperatingResourceOverview";
import OperatingResourceConfigurator from "../administration/operatingResources/OperatingResourceConfigurator";
import ProfileOverview from "../administration/profile/ProfileOverview";
import ReleaseNotes from "../navigation/shared/ReleaseNotes";
import BidderPriceSheet from "../bidder/BidderPriceSheet";

 class OfferApp extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            allOffers: []
        }
    }

    async componentDidMount() {
        if (!this.context.appData.allOffers) {
            return;
        }
        let allOffers = [];
        for (let offer of this.context.appData.allOffers) {
            const o = {...offer};
            if ([OFFER_STATUS_ACCEPTED, OFFER_STATUS_REJECTED].includes(o.status) && !o.resultNoticed) {
                o.projectName = await this.getProjectName(o.id);
            }
            allOffers.push(o);
        }
        this.setState({allOffers});
    }

    noticeOfferResult = async(offer, changeApp) => {
        const response = await postAsync("/offer/noticeresult?offerId=" + offer.id, null);
        if (response?.status === 200) {
            if (changeApp) {
                await this.context.setActiveApp(COCKPIT_APP);
                this.context.setActiveProjectId(offer.projectId);
                const activeOperationId = this.context.appData.operations
                    ?.find(o => o.activeProject.id === offer.projectId)?.id;
                if (activeOperationId !== null && activeOperationId !== undefined) {
                    await this.context.setActiveOperationIdForCockpit(activeOperationId);
                }
            } else {
                this.context.reloadAppData();
            }
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "offer.noticeResult.errorMessage"}), this.props);
        }
        const allOffers = this.state.allOffers.filter((o) => o.id !== offer.id);
        this.setState({allOffers});
    }

    getProjectName = async(offerId) => {
        const response = await getAsync("/project/offer/" + offerId);
        if (response?.status === 200) {
            return response.data?.name;
        } else {
            return "";
        }
    }

    getRouteSwitch = () => {
        return (
            <Switch>
                <Route path={IMPRESSUM_ROUTE} component={Impressum}/>
                <Route path={AGB_ROUTE} component={TermsOfUse}/>
                <Route path={PRIVACY_ROUTE} component={Privacy}/>

                <Route path={MY_ACCOUNT_ROUTE} component={MyAccount}/>
                <Route path={RELEASE_NOTES_ROUTE} component={ReleaseNotes}/>

                <Route path={ARTICLE_CONFIGURATOR_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ArticleConfigurator}/>
                </Route>

                <Route path={ARTICLE_CONFIGURATION_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ArticleConfigurationOverview}/>
                </Route>

                <Route path={PROFILES_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={ProfileOverview}/>
                </Route>

                <Route path={OPERATING_RESOURCE_OVERVIEW_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={OperatingResourceOverview}/>
                </Route>

                <Route path={OPERATING_RESOURCE_CONFIGURATOR_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={OperatingResourceConfigurator}/>
                </Route>

                <Route path={USER_MANAGEMENT_ROUTE}>
                    <PrivateRoute roles={[Role.ADMIN]} component={UserManagement}/>
                </Route>

                <Route path={OPERATION_ASSIGNMENT_ROUTE} component={OperationAssignment}/>

                <Route path={BIDDER_DASHBOARD_ROUTE} component={BidderDashboard}/>

                <Route path={buildRouteWithPathVariablePlaceholder(BIDDER_PROJECT_DETAILS_ROUTE, ":operationId")} component={BidderProjectDetails}/>

                <Route path={buildRouteWithPathVariablePlaceholder(BIDDER_COMMUNICATION_ROUTE, ":operationId")}>
                    <PrivateRoute roles={[Role.ADMIN, Role.BIDDER]} component={BidderCommunication}/>
                </Route>

                <Route path={buildRouteWithPathVariablePlaceholder(BIDDER_OFFER_DOCUMENTS_ROUTE, ":projectId")}>
                    <PrivateRoute roles={[Role.ADMIN, Role.BIDDER]} component={BidderOfferDocumentsOverview}/>
                </Route>

                <Route path={buildRouteWithPathVariablePlaceholder(BIDDER_OFFER_PRICE_SHEET_ROUTE, ":projectId")}>
                    <PrivateRoute roles={[Role.ADMIN, Role.BIDDER]} component={BidderPriceSheet}/>
                </Route>

                <Route path={buildRouteWithPathVariablePlaceholder(BIDDER_OFFER_SUBMIT_ROUTE, ":projectId")}>
                    <PrivateRoute roles={[Role.ADMIN, Role.BIDDER]} component={BidderOfferSubmit}/>
                </Route>

                <Route path={buildRouteWithPathVariablePlaceholder(BIDDER_OFFER_RESULT_ROUTE, ":operationId")}>
                    <PrivateRoute roles={[Role.ADMIN, Role.BIDDER]} component={BidderOfferResult}/>
                </Route>

                <Route>
                    <Redirect to={BIDDER_DASHBOARD_ROUTE}/>
                </Route>
                
            </Switch>
        );
    }

    render() {
        return (
            <>

                {this.state.allOffers?.map(offer => 
                
                    <TexisionDialog
                        key={"offer-result-noticed-dialog-" + offer.id}
                        type={DIALOG_TYPE_INFO}
                        open={[OFFER_STATUS_ACCEPTED, OFFER_STATUS_REJECTED].includes(offer.status) && !offer.resultNoticed}
                        titleId={"offer.noticeResult." + offer.status.toLowerCase() + ".dialog.title"}
                        subtitleId={"offer.noticeResult." + offer.status.toLowerCase() + ".dialog.subtitle"}
                        replacements={{projectName: offer.projectName ?? ""}}
                        actionId={offer.status === OFFER_STATUS_ACCEPTED ? null : "commons.close.label"}
                        onAction={offer.status === OFFER_STATUS_ACCEPTED ? null : () => this.noticeOfferResult(offer, false)}
                        content={offer.status === OFFER_STATUS_ACCEPTED 
                            
                            ? <Button
                                onClick={() => this.noticeOfferResult(offer, true)}
                                variant="contained" 
                                color="primary"
                                style={{marginTop: 10, maxWidth: "300px"}}
                                endIcon={<DoubleArrowIcon/>}>
                                <FormattedMessage id="offerResult.changeApp.button"/>
                            </Button> 
                            
                            : <div/>
                        }
                    />

                )}
            

                <MediaQuery minWidth={MOBILE_THRESHOLD}>

                    <OfferNavBar mobile={false}/>

                    <div className="contentArea">
                        {this.getRouteSwitch()}
                    </div>

                </MediaQuery>

                <MediaQuery maxWidth={MOBILE_THRESHOLD-1}>

                    <OfferNavBar mobile={true}/>

                    <div className="contentAreaMobile">
                        {this.getRouteSwitch()}
                    </div>

                </MediaQuery>

            </>
        );
    }
}

export default withSnackbar(injectIntl(OfferApp));
