import { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import {CONTRACT_STATUS_ROUTE, DASHBOARD_ROUTE} from "../../../util/Constants";
import { GeneralContext } from "../../contexts/GeneralContext";
import ReadOnlyWrapper from "../../uiLibrary/ReadOnlyWrapper";
import {isCockpit} from "../../../util/Util";

class BusinessUnitRoute extends Component {

    static contextType = GeneralContext;
    
    render() {
        const activeProjectId = this.context.appData.activeProjectId;
        if (activeProjectId !== null && activeProjectId !== undefined) {
            return (
                <Route 
                    path={this.props.path} 
                    render={(_) => 
                        <ReadOnlyWrapper 
                            key={this.context.appData.activeUnitId} 
                            titleId={this.props.titleId} 
                            subtitleId={this.props.subtitleId}
                            component={this.props.component}
                            projectId={activeProjectId}
                            projectTask={this.props.projectTask}
                            objectId={this.context.appData.activeUnitId}
                        />
                    }
                />
            );
        } else {
            return (
                <Redirect to={isCockpit() ? CONTRACT_STATUS_ROUTE : DASHBOARD_ROUTE}/>
            );
        }
    }
}

export default BusinessUnitRoute;
