import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {withSnackbar} from 'notistack';
import "../../../css/BidderProjectDetails.css";
import {GeneralContext} from "../../contexts/GeneralContext";
import {PROJECT_STATUS_IN_NEGOTIATION, PROJECT_STATUS_IN_POST_PROCESSING} from "../../../util/Constants";
import PriceSheetUnit from "../../pricesheet/PriceSheetUnit";
import { PriceSheetHeader } from "../../pricesheet/PriceSheetHeader";
import { getAllArticleNumbers } from "../../pricesheet/PriceSheetUtil";
import {isOfferUser} from "../../../services/UserService";
import {SimpleTextCard} from "../../uiLibrary/SimpleTextCard";
import {getActiveOperation} from "../../../services/OperationService";
import {getPriceOfferByProjectId, updatePriceOfferMap} from "../../../services/PriceOfferService";
import {exportCockpitPriceSheet} from "../../../services/ExportService";

class CockpitPriceSheet extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            allArticleNumbers: {},
            contentVos: null,
            isInitializing: true,
            isDownloading: false,
            priceOffer: null,
            useAlternativeNames: false
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async() => {
        await updatePriceOfferMap(this.context, this.context.appData.activeProjectId);
        const result = await getPriceOfferByProjectId(this.context, this.props, this.context.appData.activeProjectId);
        const priceOffer = result?.priceOfferVos[0];
        const contentVos = result?.contentVos;
        const allArticleNumbers = getAllArticleNumbers(priceOffer);
        this.setState({isInitializing: false, priceOffer, contentVos, allArticleNumbers});
    }

    reloadPriceSheet = async() => {
        await updatePriceOfferMap(this.context, this.context.appData.activeProjectId);
        const result = await getPriceOfferByProjectId(this.context, this.props, this.context.appData.activeProjectId);
        const priceOffer = result?.priceOfferVos[0];
        const allArticleNumbers = getAllArticleNumbers(priceOffer);
        this.setState({priceOffer, allArticleNumbers});
    }

    resetAllArticleNumbers = () => {
        const allArticleNumbers = getAllArticleNumbers(this.state.priceOffer);
        this.setState({allArticleNumbers});
    }

    export = async() => {
        this.setState({isDownloading: true});
        await exportCockpitPriceSheet(this.context, this.props, this.context.appData.activeProjectId);
        this.setState({isDownloading: false});
    }

    getTextId = (activeOperation, editable, isActiveProject) => {
        const hasBidder = isOfferUser(activeOperation?.bidder);
        if (!hasBidder) {
            return "cockpit.priceSheet.noBidder";
        } else if (isActiveProject) {
            return "cockpit.priceSheet.active";
        } else if (!editable && activeOperation.workingProject?.status === PROJECT_STATUS_IN_NEGOTIATION) {
            return "cockpit.priceSheet.readonly.inNegotiation";
        } else if (!editable && activeOperation.workingProject?.status === PROJECT_STATUS_IN_POST_PROCESSING) {
            return "cockpit.priceSheet.readonly.inProcess";
        } else if (activeOperation.workingProject?.status === PROJECT_STATUS_IN_NEGOTIATION) {
            return "cockpit.priceSheet.working.inNegotiation";
        } else {
            return "cockpit.priceSheet.working.inProcess";
        }
    }

    render() {

        const activeOperation = getActiveOperation(this.context);

        if (this.state.isInitializing || !activeOperation) {

            return (
                <div/>
            );

        } else {

            const { priceOffer, contentVos } = this.state;

            let project;
            let isActiveProject;
            if (activeOperation.activeProject.id === this.context.appData.activeProjectId) {
                project = activeOperation.activeProject;
                isActiveProject = true;
            } else if (activeOperation.workingProject?.id === this.context.appData.activeProjectId) {
                project = activeOperation.workingProject;
                isActiveProject = false;
            }
    
            const editable = !isActiveProject && activeOperation?.editable;

            const textId = this.getTextId(activeOperation, editable, isActiveProject);

            let hasContent = false;
            if (contentVos) {
                for (let contentVo of contentVos) {
                    if ((contentVo.articles && Object.keys(contentVo.articles)?.length) || contentVo.customerArticles?.length
                        || contentVo.operatingResources?.length || contentVo.residentsLaundry?.length
                        || contentVo.specialServices?.length || contentVo.unitResources?.length) {
                        hasContent = true;
                        break;
                    }
                }
            }
        
            return (

                <>

                    <PriceSheetHeader
                        disabled={!hasContent}
                        exportPriceSheet={() => this.export(activeOperation.offerId)}
                        isDownloading={this.state.isDownloading} 
                        subtitleId={textId+".subtitle"} 
                        titleId={textId+".title"}/>

                    <SimpleTextCard show={!hasContent} textId="cockpit.priceSheet.noContent"/>

                    {contentVos.map(contentVo => <PriceSheetUnit
                        singlePriceOnly={true}
                        allArticleNumbers={this.state.allArticleNumbers}
                        contentVo={contentVo}
                        editable={editable}
                        exportType={priceOffer.exportType}
                        isEditingWorkingProject={!isActiveProject}
                        priceUnitMap={priceOffer.priceUnitMap}
                        project={project}
                        reloadPriceSheet={this.reloadPriceSheet}
                        resetAllArticleNumbers={this.resetAllArticleNumbers}
                    />)}

                </>
            );
        }
    }
}

export default injectIntl(withSnackbar(CockpitPriceSheet));
