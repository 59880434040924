import {deleteAsyncCatch, getAsyncCatch, postAsyncCatch, putAsyncCatch} from "./BackendService";
import {updateValidationMap} from "./ProjectService";

export async function getSpecialServicesForUnit(context, props) {
    const specialServices = await getAsyncCatch(context, "/special/service/unit/" + context.appData.activeUnitId, props);
    return specialServices ? specialServices : [];
}

export async function createSpecialService(context, props, specialService) {
    const createdSpecialService = await postAsyncCatch(context, "/special/service/" + context.appData.activeProjectId, specialService, props);
    if (createdSpecialService) {
        await updateValidationMap(context);
    }
    return createdSpecialService ? createdSpecialService : null;
}

export async function updateSpecialService(context, props, specialService) {
    const updatedSpecialService = await putAsyncCatch(context, "/special/service/" + context.appData.activeProjectId, specialService, props);
    if (updatedSpecialService) {
        await updateValidationMap(context);
    }
    return updatedSpecialService ? updatedSpecialService : null;
}

export async function deleteSpecialService(context, props, specialServiceId) {
    const success = await deleteAsyncCatch(context, "/special/service/" + context.appData.activeProjectId + "/" + specialServiceId, props);
    if (success) {
        await updateValidationMap(context);
    }
    return success;
}
