import React, {Component} from 'react';
import {INTERVAL_TYPE_KEY, INTERVAL_TYPES} from "../../../../util/Constants";
import Grid from "@material-ui/core/Grid";
import {InputLabel, MenuItem, Select} from "@mui/material";
import {FormattedMessage, injectIntl} from "react-intl";
import {GeneralContext} from "../../../contexts/GeneralContext";

class BaseDataTimeSelection extends Component {

    static contextType = GeneralContext;

    timeSelectGrid = (textId, selectProps, options) => {
        return (
            <Grid container justifyContent="flex-end">
                <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
                    <InputLabel>{this.props.intl.formatMessage({id: textId})}</InputLabel>
                </Grid>
                <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
                    <Select disabled={!!this.props.baseDataToEdit} {...selectProps}>
                        {options}
                    </Select>
                </Grid>
            </Grid>
        );
    }

    yearSelection = (years, yearValue, copy, baseDataToEdit, onYearChange) => {
        return this.timeSelectGrid(
            "cockpit.statistics.baseData.year.select",
            {
                labelId: "select-base-data-time-year",
                disabled: !copy && !!baseDataToEdit,
                style: {minWidth: 100},
                label: this.props.intl.formatMessage({id: "cockpit.statistics.year"}),
                value: yearValue
            },
            [years.map(year =>
                <MenuItem
                    key={year+"-time-option-year"}
                    value={year}
                    onClick={() => onYearChange(year)}>
                    {year}
                </MenuItem>
            )]);
    }

    render() {
        const {copy, monthValue, months, onMonthChange, quarterValue, quarters, onQuarterChange, yearValue, years, onYearChange, baseDataToEdit} = this.props;
        const intervalType = this.context.getUserStateValue(INTERVAL_TYPE_KEY);
        return (
            <Grid container alignItems="center" justifyContent="flex-end" key={"time-selector-" + copy}>

                {copy && <Grid item>
                    {this.yearSelection(years, yearValue, copy, baseDataToEdit, onYearChange)}
                </Grid>}

                {intervalType === INTERVAL_TYPES.MONTHLY && <Grid item>
                    {this.timeSelectGrid(
                        "cockpit.statistics.month",
                        {
                            labelId: "select-base-data-time-month",
                            disabled: (!copy && !!baseDataToEdit) || (copy && !yearValue),
                            style: {minWidth: 150},
                            label: this.props.intl.formatMessage({id: "cockpit.statistics.month"}),
                            value: monthValue
                        },
                        [months.map(month =>
                            <MenuItem
                                key={month+"-time-option-month"}
                                value={month}
                                onClick={() => onMonthChange(month)}>
                                <FormattedMessage id={"constants.Month." + month}/>
                            </MenuItem>
                        )])}
                </Grid>}

                {intervalType === INTERVAL_TYPES.QUARTERLY && <Grid item>
                    {this.timeSelectGrid(
                        "cockpit.statistics.quarter",
                        {
                            labelId: "select-base-data-time-quarter",
                            disabled: (!copy && !!baseDataToEdit) || (copy && !yearValue),
                            style: {minWidth: 70},
                            label: this.props.intl.formatMessage({id: "cockpit.statistics.quarter"}),
                            value: quarterValue
                        },
                        [quarters.map(quarter =>
                            <MenuItem
                                key={quarter+"-time-option-quarter"}
                                value={quarter}
                                onClick={() => onQuarterChange(quarter)}>
                                {quarter}
                            </MenuItem>
                        )])}
                </Grid>}

                {!copy && <Grid item xs={3}>
                    {this.yearSelection(years, yearValue, copy, baseDataToEdit, onYearChange)}
                </Grid>}
            </Grid>
        );
    }
}

export default injectIntl(BaseDataTimeSelection);
