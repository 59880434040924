import { useCallback, useContext, useEffect, useState } from "react";
import { getAsync } from "../../../services/BackendService";

import { Header } from "../../uiLibrary/Header";
import { SimpleTextCard } from "../../uiLibrary/SimpleTextCard";
import { InvoiceControllingOffer } from "./InvoiceControllingOffer";
import {GeneralContext} from "../../contexts/GeneralContext";
import {getProcedure} from "../../../services/ProcedureService";
import {useIntl} from "react-intl";
import {useSnackbar} from "notistack";
import {OFFER_STATUS_PARTICIPATING} from "../../../util/Constants";
import {loadOffers} from "../../../services/OfferService";


const InvoiceControlling = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [offers, setOffers] = useState([]);
    const [priceOfferContainer, setPriceOfferContainer] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isClosed, setIsClosed] = useState(false);

    const context = useContext(GeneralContext);
    const intl = useIntl();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const loadData = useCallback(async() => {

        setIsLoading(true);

        let closed;
        let offerIds = [];

        const procedure = await getProcedure(context, {intl, enqueueSnackbar, closeSnackbar}, context.appData.offerProjectId);
        closed = !procedure?.isOpenToOffers;
        setIsClosed(!procedure?.isOpenToOffers);
        setHasError(!procedure);
    
        if (closed) {
            let allOffers = await loadOffers(context);
            if (!allOffers) {
                setHasError(true);
            }

            setOffers(allOffers.sort((a,b) => a?.user?.company?.localeCompare(b?.user?.company)));
            offerIds = allOffers.map(o => o.id);
    
            if (offerIds.length > 0) {
                let offerIdsPathVariable = "";
                for (let i = 0; i < offerIds.length; i++) {
                    offerIdsPathVariable += offerIds[i];
                    if (i < offerIds.length - 1) {
                        offerIdsPathVariable += ",";
                    }
                }
                const priceOffersResponse = await getAsync("/priceoffer/list/" + offerIdsPathVariable);
                if (priceOffersResponse?.status === 200 && priceOffersResponse.data) {
                    setPriceOfferContainer(priceOffersResponse.data);
                } else if ([401, 403].includes(priceOffersResponse?.status)) {
                    await context.logout();
                    return null;
                } else {
                    setHasError(true);
                }
            }
        }

        setIsLoading(false);
    }, [context, intl, enqueueSnackbar, closeSnackbar]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const hasData = offers.length && priceOfferContainer.priceOfferVos?.length && priceOfferContainer.contentVos?.length;

    return <>

        <Header 
            titleId="invoiceControlling.title" 
            subtitleId={hasData && !hasError && isClosed ? "invoiceControlling.subtitle" : null}/>

        <SimpleTextCard show={!hasData && !hasError && isClosed && !isLoading} textId="invoiceControlling.noOffers.subtitle"/>

        <SimpleTextCard show={!isClosed && !hasError && !isLoading} textId="invoiceControlling.notClosed.subtitle"/>

        <SimpleTextCard show={hasError && !isLoading} textId="invoiceControlling.error.subtitle"/>

        {!!hasData && !hasError && isClosed && (priceOfferContainer.priceOfferVos
            .map(p => {
                let offer = offers.find(o => o.id === p.offerId && OFFER_STATUS_PARTICIPATING !== o.status)
                return offer === null || offer === undefined ? <></>
                    : <InvoiceControllingOffer
                        key={p.offerId}
                        contents={priceOfferContainer.contentVos}
                        offer={offer}
                        priceOffer={p}/>
            }))}

    </>;
}

export {
    InvoiceControlling
}
