import React, {Component} from 'react';

import {injectIntl, FormattedMessage} from 'react-intl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {putAsyncCatch, getAsync} from '../../../../../services/BackendService';
import '../../../../apps/App.css';
import {withSnackbar} from 'notistack';
import LogisticsAreaCard from './LogisticsAreaCard';
import {withRouter} from "react-router-dom";
import {GeneralContext} from "../../../../contexts/GeneralContext";
import {updateValidationMap} from "../../../../../services/ProjectService";


class LogisticsAreaAssignment extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            allAssignedAreaIds: [],
            selectedAreaIds: new Set()
        }
    }

    async componentDidMount() {
        await this.reloadData();
    }

    reloadData = async() => {
        let allAssignedAreaIds = await this.loadAllAssignedAreas();
        let selectedAreaIds = new Set();
        for (let assignedAreaId of allAssignedAreaIds) {
            selectedAreaIds.add(assignedAreaId);
        }
        this.setState({allAssignedAreaIds: allAssignedAreaIds, selectedAreaIds: selectedAreaIds});
    }

    componentDidUpdate() {
        this.context.setUnsavedChanges(this.newAreasSelected() && this.props.allAreas.length !== 0);
    }

    loadAllAssignedAreas = async() => {
        let response = await getAsync("/assignments/areas/" + this.props.deliveryAddress.id);
        if (response?.status === 200 && response.data) {
            return response.data.ids;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    newAreasSelected = () => {
        return this.state.allAssignedAreaIds.sort().join(',') !== Array.from(this.state.selectedAreaIds).sort().join(',');
    }

    handleSaveAssignment = async () => {
        const deliveryPointId = this.props.deliveryAddress.id;
        const addressVersion = this.props.addressVersion;

        let areasToAssign = {ids: []};
        for (let areaId of this.state.selectedAreaIds) {
            areasToAssign.ids.push(areaId);
        }

        let responseData = await putAsyncCatch(this.context, "/assignments/areas/"
            + this.context.appData.activeProjectId + "/" + deliveryPointId + "/" + addressVersion, areasToAssign, this.props);
        if (responseData) {
            await this.props.reloadAddressVersion();
            await this.reloadData();
            this.props.onSave();
            await updateValidationMap(this.context);
        }
        this.setState({});
    }

    handleCheckboxChanged = (area, checked) => {
        area.assigned = checked;
        let selectedAreaIds = this.state.selectedAreaIds;
        if (!checked) {
            selectedAreaIds.delete(area.id);
        } else {
            selectedAreaIds.add(area.id);
        }
        this.setState({selectedAreaIds: selectedAreaIds});
    }

    render() {
        let allAreas = this.props.allAreas;

        if (!allAreas?.length) {
            return null;
        }

        let areasToSet = allAreas.map(area => {
            area.assigned = this.state.selectedAreaIds.has(area.id);
            return area;
        });
        return (
            <div style={{marginBottom: 30}}>

                <Grid container alignItems="flex-end" style={{paddingBottom: 10}}>

                    <Grid item xs>

                        <Typography variant="h3">
                            {this.props.intl.formatMessage({id: "areaDeliveryPointAssignment.h1"}) + " " + this.props.deliveryAddress.description}
                        </Typography>

                        <Typography variant="subtitle2" style={{marginBottom: "20px"}}>
                            <FormattedMessage id="areaDeliveryPointAssignment.h2"/>
                        </Typography>

                    </Grid>

                    <Grid item>

                        <Button 
                            color="primary" 
                            variant="contained" 
                            onClick={() => this.handleSaveAssignment()}
                            disabled={this.props.allAreas.length === 0
                                || !this.newAreasSelected()
                                || this.props.readOnly}>
                            <FormattedMessage id="commons.save.button"/>
                        </Button>

                    </Grid>
                </Grid>

                <Grid container spacing={2}> {

                    areasToSet.map((area) => (
                        <Grid key={area.id} item xs style={{maxWidth: "280px", maxHeight: "280px"}}>
                            <LogisticsAreaCard
                                area={area} 
                                handleCheckboxChanged={this.handleCheckboxChanged}
                                readOnly={this.props.readOnly}/>
                        </Grid>
                    ))}

                </Grid>

            </div>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(LogisticsAreaAssignment)));
