import React, {Component} from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import PriceSheetUnit from "../../../pricesheet/PriceSheetUnit";
import {EXPORT_TYPE_MERGE} from "../../../../util/Constants";
import {formatPrice} from "../../../../util/Util";

class CategoryStatisticsPriceSheet extends Component {

    fullPrice = () => {
        const currency = this.props.activeProject?.currency;
        const priceOffer = this.props.priceOffer;
        if (!priceOffer) {
            return "";
        }
        return " (" + this.props.intl.formatMessage({id: "cockpit.statistics.fullPrice"}) + ": "
            + (priceOffer?.sumNet ? (this.props.intl.formatMessage({id: "bidder.priceSheet.tablePrices.netto.title"}) + ": "
                + formatPrice(priceOffer.sumNet) + " " + currency) : "")
            + ((priceOffer.sumNet && priceOffer.sumGross) ? ", " : "")
            + (priceOffer?.sumGross ? (this.props.intl.formatMessage({id: "bidder.priceSheet.tablePrices.brutto.title"}) + ": "
                + formatPrice(priceOffer.sumGross) + " " + currency) : "")
            + ")";
    }

    render() {
        return (
            <>
                {this.props.priceOffer && this.props.contentVos?.length && <Accordion elevation={0} key={"accordion-current-priceoffer"} style={{marginBottom: 30}}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-label="Expand">
                        <Grid container>
                            <Grid item xs>
                                <Typography variant="h3">
                                    <FormattedMessage id="cockpit.statistics.currentPriceSheet"/>
                                </Typography>
                            </Grid>
                            <Grid item style={{marginRight: 20}}>
                                <Typography variant="h3">
                                    {this.fullPrice()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            {this.props.contentVos?.map(contentVo =>
                                <Grid item xs={12} key={"category-statistics-price-sheet" + contentVo.exportable.id}>
                                    <PriceSheetUnit
                                        lockPrices={true}
                                        lockArticleNumbers={true}
                                        readOnly={true}
                                        allArticleNumbers={this.props.allArticleNumbers}
                                        contentVo={contentVo}
                                        editable={false}
                                        exportType={EXPORT_TYPE_MERGE}
                                        isEditingWorkingProject={false}
                                        priceUnitMap={this.props.priceOffer?.priceUnitMap}
                                        project={this.props.activeProject}
                                        reloadPriceSheet={() => {}}
                                        resetAllArticleNumbers={() => {}}/>
                                </Grid>
                            )}
                        </Grid>
                    </AccordionDetails>
                </Accordion>}
            </>
        );
    }
}

export default injectIntl(CategoryStatisticsPriceSheet);
