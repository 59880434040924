import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Card} from "@material-ui/core";
import TexisionContainer from "../uiLibrary/TexisionContainer";
import {GeneralContext} from "../contexts/GeneralContext";
import {AGB_ROUTE} from "../../util/Constants";

class TermsOfUse extends Component {

    static contextType = GeneralContext;

    termsOfUse = () => {
        return <div align="center">
            <a href={AGB_ROUTE} target="_blank" rel="noreferrer">
                <FormattedMessage id="payment.agb.link"/>
            </a>
        </div>;
    }

    render() {
        if (this.props.usedInDialog) {
            return this.termsOfUse()
        }

        return this.context.currentUser
            ? <Card style={{padding: "3%"}}>{this.termsOfUse()}</Card>
            : <TexisionContainer showBackButton="true" content={this.termsOfUse()}/>;
    }
}

export default injectIntl(TermsOfUse);

