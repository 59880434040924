import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import Product from "./Product";
import {GeneralContext} from "../contexts/GeneralContext";
import {Button, Grid} from "@mui/material";
import {DIALOG_TYPE_INFO, DIALOG_TYPE_WARNING, LICENSE_TYPES} from "../../util/Constants";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import {createErrorMessage, formatPrice} from "../../util/Util";
import {withSnackbar} from "notistack";
import OperationEventList from "./OperationEventList";
import {
    cancelSubscription, getCancelAt, getCanceledAt, getCancelPeriod, getNextSubscriptionPrice,
    getPaymentsAndEvents,
    getRecurringIntervalText,
    getSubscriptionEndDate, getSubscriptionExtension, getSubscriptionStartDate, getTrialMonths,
    latestActivePayment,
    resubscribe
} from "../../services/PaymentService";
import {getActiveOperation} from "../../services/OperationService";
import CouponDetails from "./CouponDetails";
import {formatDate} from "../../util/DocumentUtil";
import {texisionGreen, texisionOrange} from "../../util/ColorTheme";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";

class ActiveProduct extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            showUpgradeOptions: false,
            selectedProductId: null,
            isRequesting: false,
            clientSecret: null,
            showCancelConfirmDialog: false,
            showSuccessfulCancelDialog: false,
            showErrorCancelDialog: false,
            showResubscribeDialog: false,
            showSuccessfulResubscribeDialog: false
        };
    }

    onCancelSubscription = async() => {
        this.setState({isRequesting: true, showCancelConfirmDialog: false});
        const canceledSubscription = await cancelSubscription(this.context, this.props);
        if (canceledSubscription) {
            this.setState({showSuccessfulCancelDialog: true, isRequesting: false});
            await getPaymentsAndEvents(this.context);
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "payment.cancel.errorMessage"}), this.props);
            this.setState({isRequesting: false});
        }
    }

    onResubscribe = async() => {
        this.setState({isRequesting: true, showResubscribeDialog: false});
        const subscription = await resubscribe(this.context, this.props);
        if (subscription) {
            this.setState({isRequesting: false, showSuccessfulResubscribeDialog: true});
            await getPaymentsAndEvents(this.context);
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "payment.resubscribe.errorMessage"}), this.props);
            this.setState({isRequesting: false});
        }
    }

    getPeriodInformation = (activePrice) => {
        const subscription = latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE)?.subscription;
        let subscriptionStatus = subscription?.status;
        const endDate = subscription?.currentPeriodEnd;
        if (!endDate) {
            return (
                <FormattedMessage id="payment.subscription.noEndDate.text"/>
            );
        }
        const cancelAt = subscription.cancelAt;
        const canceledManually = subscription.canceledAt !== subscription.created;
        const currency = getActiveOperation(this.context).activeProject?.currency;

        switch (subscriptionStatus) {
            case "active":
                if (cancelAt) {
                    return (
                        <FormattedMessage
                            id={canceledManually ? "payment.canceledSubscription.text" : "payment.freeSubscriptionEnd.text"}
                            values={{
                                endDate: getCancelAt(this.props.intl, subscription, true),
                                cancelDate: getCanceledAt(this.props.intl, subscription)
                            }}/>
                    );
                } else {
                    let periodEnd = new Date(subscription.currentPeriodEnd*1000);
                    periodEnd.setMonth(periodEnd.getMonth() + 1, 1);
                    return (
                        <FormattedMessage
                            id="payment.activeSubscription.text"
                            values={{
                                endDate: getSubscriptionEndDate(this.props.intl, subscription, activePrice),
                                recurringInterval: getRecurringIntervalText(this.props.intl, activePrice),
                                cancelPeriod: getCancelPeriod(this.props.intl, activePrice),
                                costs: getNextSubscriptionPrice(subscription),
                                currency: currency,
                                nextPaymentDate: formatDate(this.props.intl, periodEnd)
                            }}/>
                    );
                }
            case "canceled":
                return (
                    <FormattedMessage
                        id={canceledManually ? "payment.canceledSubscription.text" : "payment.freeSubscriptionEnd.text"}
                        values={{
                            endDate: getCancelAt(this.props.intl, subscription, true),
                            cancelDate: getCanceledAt(this.props.intl, subscription)
                        }}/>
                );
            case "trialing":
                const trialEnd = subscription.trialEnd;
                if (!trialEnd) {
                    return null;
                }
                if (subscription.cancelAt) {
                    return (
                        <FormattedMessage
                            id={canceledManually ? "payment.canceledSubscription.text" : "payment.freeSubscriptionEnd.text"}
                            values={{
                                endDate: getCancelAt(this.props.intl, subscription, true),
                                cancelDate: getCanceledAt(this.props.intl, subscription)
                            }}/>
                    );
                } else {
                    const activePrice = this.props.prices.find(price => price.id === this.props.activePriceId);
                    const activeProduct = this.props.products.find(product => product.id === activePrice.product);
                    const trialMonths = getTrialMonths(activeProduct);
                    return (
                        <FormattedMessage
                            id="payment.trialSubscription.text"
                            values={{
                                firstPaymentDate: getSubscriptionStartDate(this.props.intl, subscription),
                                endDate: getSubscriptionEndDate(this.props.intl, subscription, activePrice),
                                extension: getSubscriptionExtension(this.props.intl, activePrice),
                                costs: getNextSubscriptionPrice(subscription),
                                currency: currency,
                                cancelPeriod: getCancelPeriod(this.props.intl, activePrice),
                                trialNotice: trialMonths ? this.props.intl.formatMessage({id: "payment.active.product.COOPERATION_LICENSE.trialNotice"},
                                    {trialMonths: trialMonths}) : ""
                            }}/>
                    );
                }
            default:
                return null;
        }
    }

    getInvoiceInformation = () => {
        const invoice = latestActivePayment(this.context, this.props.licenseType)?.invoice;
        if (invoice && invoice.total > 0) {
            return <div style={{marginBottom: 20, fontSize: 16}}>
                 <FormattedMessage id={"payment.invoice.status." + invoice.status}
                                   values={{
                                       number: invoice.number,
                                       created: formatDate(this.props.intl, invoice.created*1000),
                                       total: formatPrice(invoice.total/100)
                                   }}/>
                <span style={{padding: "3px", verticalAlign: "middle"}}>
                    {invoice.status === "paid"
                        ? <CheckIcon fontSize="small" style={{color: texisionGreen}}/>
                        : <InfoIcon fontSize="small" style={{color: texisionOrange}}/> }
                </span>
            </div>
        } else {
            return null;
        }
    }

    isCanceled = () => {
        const subscription = latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE)?.subscription;
        if (!subscription) {
            return false;
        }
        return subscription.status === "canceled" || subscription.cancelAtPeriodEnd || subscription.cancelAt;
    }

    render() {
        if (this.props.activePriceId === null || this.props.activePriceId === undefined || !this.props.prices?.length || !this.props.products?.length) {
            return null;
        }

        const activePrice = this.props.prices.find(price => price.id === this.props.activePriceId);
        const activeProduct = this.props.products.find(product => product.id === activePrice.product);
        const tenderPayment = latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE);
        const offerPayment = latestActivePayment(this.context, LICENSE_TYPES.OFFER_LICENSE);
        const subscription = latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE)?.subscription

        return (
            <>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showCancelConfirmDialog}
                    titleId="payment.cancel.dialog.title"
                    subtitleId="payment.cancel.dialog.subtitle"
                    replacements={{subscriptionEndDate: getSubscriptionEndDate(this.props.intl, subscription, activePrice, true)}}
                    cancelId="commons.close.label"
                    onCancel={() => this.setState({showCancelConfirmDialog: false})}
                    actionId="commons.confirm.button"
                    onAction={() => this.onCancelSubscription()}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showSuccessfulCancelDialog}
                    titleId="payment.cancelSuccess.dialog.title"
                    subtitleId="payment.cancelSuccess.dialog.subtitle"
                    replacements={{subscriptionEndDate: getSubscriptionEndDate(this.props.intl, subscription, activePrice, true)}}
                    actionId="commons.okay.button"
                    onAction={() => this.setState({showSuccessfulCancelDialog: false})}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showResubscribeDialog}
                    titleId="payment.resubscribe.dialog.title"
                    subtitleId="payment.resubscribe.dialog.subtitle"
                    replacements={{
                        subscriptionEndDate: getSubscriptionEndDate(this.props.intl, subscription, activePrice),
                        recurringInterval: getRecurringIntervalText(this.props.intl, activePrice),
                        cancelPeriod: getCancelPeriod(this.props.intl, activePrice)
                    }}
                    cancelId="commons.cancel.button"
                    actionId="commons.confirm.button"
                    onCancel={() => this.setState({showResubscribeDialog: false})}
                    onAction={() => this.onResubscribe()}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showSuccessfulResubscribeDialog}
                    titleId="payment.resubscribeSuccess.dialog.title"
                    subtitleId="payment.resubscribeSuccess.dialog.subtitle"
                    replacements={{
                        subscriptionEndDate: getSubscriptionEndDate(this.props.intl, subscription, activePrice),
                        recurringInterval: getRecurringIntervalText(this.props.intl, activePrice),
                        cancelPeriod: getCancelPeriod(this.props.intl, activePrice)
                    }}
                    cancelId="commons.close.label"
                    onCancel={() => this.setState({showSuccessfulResubscribeDialog: false})}/>

                <h2>
                    <FormattedMessage id="payment.active.product.title"/>
                </h2>

                {this.props.licenseType === LICENSE_TYPES.COOPERATION_LICENSE &&
                    <div style={{marginTop: 10, marginBottom: 20, fontSize: 16}}>
                        {this.getPeriodInformation(activePrice)}
                    </div>}

                {this.getInvoiceInformation()}

                <Grid container spacing={4} alignItems={"center"}>

                    {this.props.licenseType === LICENSE_TYPES.COOPERATION_LICENSE && subscription?.discount?.coupon && <>

                        <Grid item xs={7}>
                            <Product product={activeProduct} price={activePrice} isActive={true}/>
                        </Grid>

                        <Grid item xs={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div style={{fontWeight: "bold", fontSize: 20, display: "flex", justifyContent: "flex-start"}}>
                                        <FormattedMessage id="payment.coupon.redeemed.title"/>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <CouponDetails coupon={subscription.discount.coupon} licenseType={LICENSE_TYPES.COOPERATION_LICENSE}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>}

                    {this.props.licenseType === LICENSE_TYPES.COOPERATION_LICENSE && !subscription?.discount?.coupon && <Grid item xs={12}>
                        <Product product={activeProduct} price={activePrice} isActive={true}/>
                    </Grid>}

                    {this.props.licenseType === LICENSE_TYPES.COOPERATION_LICENSE && <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button
                            onClick={async() => {
                                if (this.isCanceled()) {
                                    this.setState({showResubscribeDialog: true});
                                } else {
                                    this.setState({showCancelConfirmDialog: true});
                                }
                            }}
                            disabled={this.state.isRequesting}
                            variant="text"
                            color="primary">
                            <FormattedMessage id={this.isCanceled() ? "product.subscribeAgain.button" : "product.cancel.button"}/>
                        </Button>
                    </Grid>}

                    {this.props.licenseType === LICENSE_TYPES.TENDER_LICENSE && <>

                        <Grid item xs={12}>
                            <Product product={activeProduct} price={activePrice} isActive={true}/>
                        </Grid>

                        {tenderPayment?.coupon?.id && <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div style={{fontWeight: "bold", fontSize: 20, display: "flex", justifyContent: "flex-start"}}>
                                        <FormattedMessage id="payment.coupon.redeemed.title"/>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <CouponDetails coupon={tenderPayment.coupon} licenseType={LICENSE_TYPES.TENDER_LICENSE}/>
                                </Grid>
                            </Grid>
                        </Grid>}

                        <Grid item xs={12} style={{display: "flex", justifyContent: "flex-start", marginBottom: 20}}>
                            <div style={{fontWeight: "bold", fontSize: 20, display: "flex", justifyContent: "flex-start"}}>
                                <FormattedMessage id="payment.active.product.events"/>:
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <OperationEventList licenseType={this.props.licenseType}/>
                        </Grid>

                    </>}

                    {this.props.licenseType === LICENSE_TYPES.OFFER_LICENSE && <>

                        <Grid item xs={12}>
                            <Product product={activeProduct} price={activePrice} isActive={true}/>
                        </Grid>

                        {offerPayment?.coupon?.id && <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div style={{fontWeight: "bold", fontSize: 20, display: "flex", justifyContent: "flex-start"}}>
                                        <FormattedMessage id="payment.coupon.redeemed.title"/>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <CouponDetails coupon={offerPayment.coupon} licenseType={LICENSE_TYPES.OFFER_LICENSE}/>
                                </Grid>
                            </Grid>
                        </Grid>}

                    </>}

                </Grid>
            </>
        );
    }
}

export default withSnackbar(injectIntl(ActiveProduct));
