import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArticleFeature from './CustomerArticleFeature';
import PropTypes from 'prop-types';
import {isEmptyOrBlank} from '../../../util/Util';
import {GeneralContext} from "../../contexts/GeneralContext";


/**
 * The CustomerArticleFeaturePanel gives you the ability to create, add, modify, and delete Features.
 * This Component allows you to have key-value pairs aka Features. This Features are currently be used by Articles and Customer Articles.
 * The user can set the feature type and the corresponding value. If you want to have just a list of values with a fixed feautre type, then have look at OperatingResourceFeature.
 */
let internalId = 1;

class CustomerArticleFeaturePanel extends Component {

    static contextType = GeneralContext;
    
    createFeature = () => {
        // create an internalId for new features, so we can delete the entry from the GUI, if the type or the value have not been set.
        let feature = {id: null, type: "", value:"", internalId: internalId++};

        let featureList = this.props.features;
        featureList.push(feature);
        this.props.onChange(featureList);
    }

    handleFeatureValueChanged = (type, value) => {
        let currentFeatures = this.props.features;

        for (var i in currentFeatures) {
            if (currentFeatures[i].type === type) {
                currentFeatures[i].value = value;
                break;
            }
        }
        this.props.onChange(currentFeatures);
    }

    handleFeatureTypeChanged = (oldType, newType) => {
        let currentFeatures = this.props.features;

        for (var i in currentFeatures) {
            if (currentFeatures[i].type === oldType) {
                currentFeatures[i].type = newType;
                break;
            }
        }
        this.props.onChange(currentFeatures);
    }

    handleFeatureDelete = (id, type, value, internalId) => {
        let currentFeatures = this.props.features;
        for (var i in currentFeatures) {
            if ((internalId === currentFeatures[i].internalId || currentFeatures[i].id === id)
                    && type === currentFeatures[i].type) {
                delete currentFeatures[i];
            }
        }
        this.props.onChange(currentFeatures);
    }

    render() {
        
        let buttonText = ""
        if (isEmptyOrBlank(this.props.addButtonText)){
            buttonText = this.props.intl.formatMessage({id: "customerArticle.create.props.add.button"});
        } else {
            buttonText = this.props.addButtonText;
        }

        // Set a internal id for (used by the key property) for existing features
        this.props.features.filter(
            feature => !feature.internalId).forEach(
                feature => feature.internalId = internalId++);

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="secondary" onClick={this.createFeature} style={{marginTop: "20px"}}>
                           {buttonText}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                    {
                        this.props.features.map((feature) => {
                            return <ArticleFeature
                                key={feature.internalId}
                                handleFeatureTypeChanged={this.handleFeatureTypeChanged.bind(this)}
                                handleFeatureValueChanged={this.handleFeatureValueChanged.bind(this)}
                                handleFeatureDelete={this.handleFeatureDelete.bind(this)}
                                valuesEndpoint={this.props.valuesEndpoint}
                                id={feature.id}
                                type={feature.type}
                                value={feature.value}
                                allFeatureTypes={this.props.allFeatureTypes}
                                allExistingFeatureTypes={this.props.features.map(feature => feature.type)}
                                internalId={feature.internalId}
                                i18nPrefix={this.props.i18nPrefix}/>
                        })
                    }
                    </Grid>
                </Grid>
            </div>
        );
    }
}

CustomerArticleFeaturePanel.propTypes = {
    features: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    allFeatureTypes:  PropTypes.array.isRequired,
    valuesEndpoint: PropTypes.string.isRequired,
};

export default injectIntl(CustomerArticleFeaturePanel);
