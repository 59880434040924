import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Link} from 'react-router-dom';
import {withSnackbar} from "notistack";
import {Accordion, AccordionDetails, AccordionSummary, Card, Checkbox, Grid, Stack, Tooltip} from "@mui/material";
import {isAdmin, isBidder, isProjectAdmin} from "../../../services/UserService";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {GeneralContext} from "../../contexts/GeneralContext";
import {texisionRed} from "../../../util/ColorTheme";
import {putAsyncCatch} from "../../../services/BackendService";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SETUP_ORDER_APP, ORDER_CONFIGURATION_ROUTE, USER_MANAGEMENT_ROUTE } from "../../../util/Constants";
import {getActiveOperation} from "../../../services/OperationService";
import {formatDate} from "../../../util/DocumentUtil";

class CheckItem extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.state = {
            checks: this.props.checkCategory.operationChecks,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.checkCategory !== this.props.checkCategory) {
            this.setState({checks: this.props.checkCategory.operationChecks})
        }
    }

    getFormattedDate = (deadlineMillis) => {
        let nowMillis = new Date().getTime()
        let date = formatDate(this.props.intl, deadlineMillis)

        let deadline = new Date(new Date(deadlineMillis).setHours(0, 0, 0, 0))
        let today = new Date(new Date(nowMillis).setHours(0, 0, 0, 0))


        if (deadline < today && !this.allItemsChecked()) {

            return (
                <Tooltip title={this.props.intl.formatMessage({id: "operation.check.overdue"})}>
                    <h3 style={{color: texisionRed}}>{date}</h3>
                </Tooltip>
            )
        } else {
            return <h3>{date}</h3>
        }

    }

    getCheckBox = (operationCheck, isBidder) => {
        let triState = isBidder ? operationCheck.doneBidder : operationCheck.doneProject;
        if (triState === null) {
            return <Checkbox disabled/>
        } else {
            return (
                <Checkbox
                    color="primary"
                    checked={triState}
                    onChange={() => this.handleChange(operationCheck.id, isBidder)}/>
            )
        }
    }

    getCheckBoxes = (operationCheck) => {
        const user = this.context.currentUser

        if (isBidder(user)) {
            return (
                <React.Fragment>
                    <Grid item xs={2} >
                        { this.getCheckBox(operationCheck, true) }
                    </Grid>
                    <Grid item xs={2} >
                        {operationCheck.doneProject ? <CheckIcon/> : <CloseIcon/>}
                    </Grid>
                </React.Fragment>
            )

        } else if (isProjectAdmin(user)) {
            return (
                <React.Fragment>
                    <Grid item xs={2} >
                        { this.getCheckBox(operationCheck, false) }
                    </Grid>
                    <Grid item xs={2} >
                        {operationCheck.doneBidder ? <CheckIcon/> : <CloseIcon/>}
                    </Grid>
                </React.Fragment>
            )

        } else if (isAdmin(user)) {
            return (
                <React.Fragment>
                    <Grid item xs={2} >
                         { this.getCheckBox(operationCheck, true) }
                    </Grid>
                    <Grid item xs={2} >
                         { this.getCheckBox(operationCheck, false) }
                    </Grid>
                </React.Fragment>
            )
        
        } else {
            return (
                <React.Fragment>
                    <Grid item xs={2} >
                        {operationCheck.doneBidder ? <CheckIcon/> : <CloseIcon/>}
                    </Grid>
                    <Grid item xs={2} >
                        {operationCheck.doneProject ? <CheckIcon/> : <CloseIcon/>}
                    </Grid>
                </React.Fragment>
            )
        }


    }

    handleChange = async (operationCheckId, isBidderUpdate) => {

        let operationCheck = this.state.checks.find(c => c.id === operationCheckId)
        const index = this.state.checks.findIndex(c => c.id === operationCheckId)
        const operationId = this.context.appData.activeOperationId


        if (isBidderUpdate) {
            operationCheck.doneBidder = !operationCheck.doneBidder
        } else {
            operationCheck.doneProject = !operationCheck.doneProject
        }


        let updatedOperationCheck = await putAsyncCatch(this.context, "/operation/check/" + operationId, operationCheck, this.props)
        if (updatedOperationCheck) {
            let updatedChecks = this.state.checks
            updatedChecks[index] = updatedOperationCheck
            this.setState({checks: updatedChecks})
        }
    }

    allItemsChecked() {
        return this.state.checks.every(check => check.doneBidder === true && check.doneProject === true)
    }

    categoryHeader() {


        return (
            <Card style={{padding: 0}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>

                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center">

                        <Grid item xs={10}>

                            <h2 style={this.allItemsChecked() ? {color: "green"} : {}}>
                                <FormattedMessage id={"operation.check.category." + this.props.checkCategory.category}/>
                            </h2>

                        </Grid>
                        <Grid item xs={2}>
                            {this.getFormattedDate(this.props.checkCategory.deadline)}
                        </Grid>


                    </Grid>
                </AccordionSummary>
            </Card>

        );
    }

    categoryItems = () => {
        return this.props.checkCategory.operationChecks.sort((a,b) => a.id - b.id).map(operationCheck =>

            <Grid
                key={operationCheck.point}
                container
                justifyContent="flex-start"
                alignItems="center">

                {this.getCheckBoxes(operationCheck)}

                <Grid item xs={6}>
                    <h3>
                        <FormattedMessage id={"operation.check.point." + operationCheck.point}/>
                        {
                            operationCheck.point === SETUP_ORDER_APP &&
                            <>
                                <React.Fragment>&nbsp;(</React.Fragment>
                                <Link to={ORDER_CONFIGURATION_ROUTE}><FormattedMessage id="operation.check.link.order"/></Link>
                                <React.Fragment>&nbsp;,&nbsp;</React.Fragment>
                                <Link to={USER_MANAGEMENT_ROUTE}><FormattedMessage id="operation.check.link.user"/></Link>
                                <React.Fragment>)</React.Fragment>
                            </>
                        }
                    </h3>
                </Grid>

            </Grid>
        )
    }

    getTableHeadings = () => {
        const user = this.context.currentUser;
        const operation = getActiveOperation(this.context);
        const bidderCompany = operation.bidder.company;
        const projectCompany = operation.projectAdmin.company;

        if (isProjectAdmin(user)) {

            return (
                <React.Fragment>

                    <Grid item xs={2} >
                        <h3>{projectCompany}</h3>
                    </Grid>

                    <Grid item xs={2} overflow="hidden" >
                        <h3>{bidderCompany}</h3>
                    </Grid>
                </React.Fragment>
            )
        } else {
            
            return (
                <React.Fragment>

                    <Grid item xs={2} >
                        <h3>{bidderCompany}</h3>
                    </Grid>

                    <Grid item xs={2} overflow="hidden" >
                        <h3>{projectCompany}</h3>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    render() {
        return (

            <Card elevation={4} style={{padding: 0}}>
                <Accordion>
                    {this.categoryHeader()}
                    <AccordionDetails>
                        <Stack style={{width: "100%", padding: 0}}>


                            <Grid container justifyContent="flex-start" alignItems="center">

                                {this.getTableHeadings()}

                                <Grid item xs={6}>
                                    <h3>
                                        <FormattedMessage id="cockpit.preflight.header.task"/>
                                    </h3>
                                </Grid>
                            </Grid>

                            {this.categoryItems()}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </Card>

        );
    }


}

export default injectIntl(withSnackbar(CheckItem));
