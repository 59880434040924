import { Route } from "@mui/icons-material";
import { Component } from "react";
import { Redirect } from "react-router-dom";
import { PROJECT_OVERVIEW_ROUTE } from "../../../util/Constants";
import { GeneralContext } from "../../contexts/GeneralContext";

class TenderRoute extends Component {

    static contextType = GeneralContext;

    render() {
        if (this.context.appData.activeProjectId !== null && this.context.appData.activeProjectId !== undefined) {
            return (
                <Route path={this.props.path} component={this.props.component}/>
            );
        } else {
            return (
                <Redirect to={PROJECT_OVERVIEW_ROUTE}/>
            );
        }
    }
}

export default TenderRoute;
