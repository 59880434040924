import {getAsyncCatch, postAsyncCatch} from "./BackendService";


export async function loadArticleConfigurations(context, props) {
    return await getAsyncCatch(context, "/article/configuration", props);
}

export async function createOrGetArticle(context, props, articleConfiguration, filterOptionList) {
    const article = {
        id: null,
        category: articleConfiguration.category,
        subcategory: articleConfiguration.subCategory,
        articleCategory: articleConfiguration.articleCategory,
        description: articleConfiguration.description,
        filterOptions: filterOptionList,
        images: articleConfiguration.images
    }
    return await postAsyncCatch(context, "/article", article, props, true);
}

export function getFirstFilterOptions(filterCategories) {
    let optionsList = [];
    for (let filterCategory of filterCategories) {
        if (filterCategory.filterOptions.length) {
            optionsList.push(filterCategory.filterOptions[0]);
        }
    }
    return optionsList;
}
