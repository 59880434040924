import React, {Component} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import '../../../css/UserAdmin.css';

import {FormattedMessage, injectIntl} from 'react-intl';
import {getDialogIcon, sortAsc} from '../../../util/Util';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {DIALOG_TYPE_INFO} from '../../../util/Constants';
import {getDialogColor} from '../../../util/ColorTheme';
import {Typography} from '@material-ui/core';
import {GeneralContext} from "../../contexts/GeneralContext";


class UserManagementOperationAssignmentDialog extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            assignedOperationIds: this.props.allOperations.filter(o => this.props.selectedUser?.operationIds?.includes(o.id)).map(o => o.id),
            paymentRequiredDialog: false
        }
    }

    handleAssignOperations = (operation) => {
        let assignedOperationIds = this.state.assignedOperationIds;
        if (assignedOperationIds.includes(operation.id)) {
            assignedOperationIds = assignedOperationIds.filter(id => id !== operation.id);
        } else {
            assignedOperationIds.push(operation.id);
        }
        this.setState({assignedOperationIds});
    }

    handleCloseDialog = () => {
        this.props.hideOperationAssignmentDialog();
    }

    assignOperations = () => {
        this.props.assignedOperations(this.state.assignedOperationIds);
        this.handleCloseDialog();
    }

    getName = (operation) => {
        return !operation.workingProject ? operation.activeProject?.name : operation.workingProject.name
    }

    render() {
        return (
            <TexisionDialog
                type={DIALOG_TYPE_INFO}
                open={this.props.showUserAssignmentDialog}
                actionId="commons.apply.button"
                actionDisabled={!this.state.assignedOperationIds?.length}
                cancelId="commons.cancel.button"
                onAction={() => this.assignOperations()}
                onCancel={() => this.handleCloseDialog()}
                dialogTitle={<DialogTitle id="texision-dialog-title" style={{backgroundColor: getDialogColor(DIALOG_TYPE_INFO)}}>
                    <Grid
                        container
                        alignItems="center"
                        alignContent="center"
                        spacing={3}
                        style={{paddingBottom: "8px", paddingTop: "8px", paddingLeft: "25px", paddingRight: "25px"}}>

                        <Grid item>
                            {getDialogIcon(DIALOG_TYPE_INFO)}
                        </Grid>

                        <Grid item>
                            <Typography variant="h4">
                                <FormattedMessage id="useradmin.assignProjectsDialog.title" values={{count: this.state.assignedOperationIds.length}}/>
                            </Typography>
                        </Grid>

                    </Grid>
                </DialogTitle>}
                content={<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>

                    <Grid item xs={12}>
                        <DialogContentText>
                            <FormattedMessage id="useradmin.assignProjectsDialog.text" values={{mail: this.props.selectedUser?.username}}/>
                        </DialogContentText>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper elevation={0} style={{maxHeight: 300, overflow: 'auto'}}>
                            <List  style={{maxHeight: 300, minHeight: 300}}>
                                {this.props.allOperations
                                    .sort((a,b) => sortAsc(this.getName(a), this.getName(b)))
                                    .map((value) => {
                                    return (
                                        <ListItem key={value.id} value={value} dense button onClick={() => this.handleAssignOperations(value)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    color="primary"
                                                    key={value}
                                                    checked={this.state.assignedOperationIds.includes(value.id)}
                                                    edge="start"/>
                                            </ListItemIcon>
                                            <ListItemText id={value.id} primary={this.getName(value)}/>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>}/>
        );
    }
}

export default injectIntl(UserManagementOperationAssignmentDialog);
