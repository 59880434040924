import { TextField, Typography } from "@material-ui/core"
import React, { useContext } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { DIALOG_TYPE_INFO } from "../../../../util/Constants"
import TexisionDialog from "../../../uiLibrary/TexisionDialog"
import {GeneralContext} from "../../../contexts/GeneralContext";
import {getActiveProject} from "../../../../services/ProjectService";

const WinnerTextDialog = ({hasCooperationLicense, actionButtonDisabled, offers, winnerId, winnerText, onChange, onCancel, onAction, show}) => {

    const context = useContext(GeneralContext);
    const intl = useIntl();

    const greetingStart = intl.formatMessage({id: "offer.answer.greeting.start"});
    const greetingEnd = intl.formatMessage({id: "offer.answer.greeting.end"});
    const ownName = context.currentUser?.firstName + " " + context.currentUser?.lastName;
    const activeProject = getActiveProject(context);
    const acceptText = intl.formatMessage({id: "offer.answer.rejected"}).replace("{0}", activeProject.name);
    const loserText = greetingStart + "\n\n" + acceptText + "\n\n" + greetingEnd + "\n\n" + ownName;

    return <TexisionDialog
        type={DIALOG_TYPE_INFO}
        open={show}
        titleId="offers.overview.winnerDialog.title"
        actionId={hasCooperationLicense ? "offers.overview.winnerDialog.confirm" : "offers.overview.winnerDialog.payAndConfirm"}
        cancelId="commons.cancel.button"
        onCancel={() => onCancel()}
        onAction={() => onAction()}
        actionDisabled={!winnerText || actionButtonDisabled}
        content={<React.Fragment>
            <Typography variant="subtitle2" className="dialogTitle">
                <FormattedMessage 
                    id={hasCooperationLicense ? "offers.overview.winnerDialog.subtitle" : "offers.overview.winnerDialog.noLicense.subtitle"}
                    values={{winner: offers?.find(o => o.id === winnerId)?.user?.company ?? ""}}/>
            </Typography>
            <Typography variant="h3" className="dialogTitle">
                <FormattedMessage id="offers.overview.winnerDialog.winnerTitle"/>
            </Typography>
            <TextField 
                id="winnerText"
                multiline={true}
                variant="outlined"
                value={winnerText}
                style={{marginTop: "16px", width: "100%", whiteSpace: "pre-wrap"}}
                onChange={(event) => onChange(event.target.value)}/>
            <Typography variant="h3" className="dialogTitle">
                <FormattedMessage id="offers.overview.winnerDialog.loserTitle"/>
            </Typography>
            <Typography variant="subtitle2" className="loserText">
                {loserText}
            </Typography>
        </React.Fragment>}/>;
}

export {
    WinnerTextDialog
}
