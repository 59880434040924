import {
    Card,
    Grid,
    Typography,
    DialogContentText,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Tooltip,
    DialogTitle,
    Button
} from "@material-ui/core";
import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {getAsync, STAGE} from "../../../services/BackendService";
import {DIALOG_TYPE_INFO, LICENSE_TYPES} from "../../../util/Constants";
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {getDialogIcon, isTender, sortAsc} from '../../../util/Util';
import {withSnackbar} from 'notistack';
import {bodyBackgroundColor, errorColor, getDialogColor, texisionFontColorDark, texisionGreen} from "../../../util/ColorTheme";
import MaterialTable from "material-table";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {format} from 'date-fns';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {GeneralContext} from "../../contexts/GeneralContext";
import {formatDate, formatIsoDate} from "../../../util/DocumentUtil";
import {convertStringToDate} from "../../../util/DateUtil";
import {getSnapshotsByProjectId, getStatistics} from "../../../services/ProjectService";
import {refreshSubscriptionPrices} from "../../../services/PaymentService";
import PaymentConfiguration from "./PaymentConfiguration";

class Licenses extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            operationToPayments: new Map(),
            operationToEvents: new Map(),
            projectToStatistics: new Map(),
            projectsToSnapshots: new Map(),
            showComparisonDialog: false,
            projectIdToCompare: null,
            snapshotIdToCompare: null
        }
    }

    loadOperationData = (operation) => {
        const project = operation.workingProject ?? operation.activeProject;

        if (!this.state.operationToPayments.get(operation.id)) {
            this.loadPayments(operation.id).then((payments) => {
                let operationToPayments = this.state.operationToPayments;
                operationToPayments.set(operation.id, payments);
                this.setState({operationToPayments});
            });
        }

        if (!this.state.operationToEvents.get(operation.id)) {
            this.loadEvents(operation.id).then((events) => {
               let operationToEvents = this.state.operationToEvents;
               operationToEvents.set(operation.id, events);
               this.setState({operationToEvents});
            });
        }

        if (!this.state.projectToStatistics.get(project.id)) {
            getStatistics(this.context, this.props, project.id).then((statistics) => {
                let projectToStatistics = this.state.projectToStatistics;
                projectToStatistics.set(project.id, statistics);
                this.setState({projectToStatistics});
            });
        }

        if (!this.state.projectsToSnapshots.get(project.id)) {
            getSnapshotsByProjectId(this.context, this.props, project.id).then((snapshots) => {
                let projectsToSnapshots = this.state.projectsToSnapshots;
                projectsToSnapshots.set(project.id, snapshots);
                this.setState({projectsToSnapshots});
            });
        }
    }

    loadPayments = async(operationId) => {
        let response = await getAsync("/payment/" + operationId.toString());
        if (response?.status === 200) {
            return response.data;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else {
            return null;
        }
    }

    loadEvents = async(operationId) => {
        let response = await getAsync("/operation/events/" + operationId.toString());
        if (response?.status === 200) {
            return response.data;
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        } else {
            return null;
        }
    }

    sortOperations = (operationA, operationB) => {
        const projectA = operationA.workingProject ?? operationA.activeProject;
        const projectB = operationB.workingProject ?? operationB.activeProject;
        return sortAsc(projectA.name, projectB.name);
    }

    getProjectStatusAsText = (status) => {
        let statusTextId = "project.status." + status + ".title";

        return (
            <Typography variant="body1" style={{fontWeight: "bold", paddingTop: "7.5px"}}>
                {(this.props.intl.formatMessage({id: "project.status.title"}) 
                + ": " + this.props.intl.formatMessage({id: statusTextId})).toUpperCase()}
            </Typography>
        );
    }

    getProjectName = (projectId) => {
        if (isTender()) {
            return this.context.appData.allProjects.find(p => p.id === projectId)?.name ?? "";
        } else {
            return this.context.appData.operations.find(o => o.activeProject?.id === projectId)?.name ?? "";
        }
    }

    getDate = (dateInMilliseconds) => {
        return format(dateInMilliseconds, "dd.MM.yyyy");
    }

    getSnapshotDate = (projectId, snapshotId) => {
        if (!projectId || !snapshotId) {
            return "";
        }
        let snapshots = this.state.projectsToSnapshots.get(projectId.toString());
        if (!snapshots) {
            return "";
        }
        let snapshot = snapshots.find(s => s.id === snapshotId);
        if (!snapshot) {
            return "";
        } else {
            return this.getDate(snapshot.created);
        }
    }

    getSnapshot = (projectId, snapshotId) => {
        return this.state.projectsToSnapshots.get(projectId)?.find(snapshot => snapshot.id === snapshotId);
    }

    statistic = (statistic) => {
        let data;
        if (statistic) {
            data = [statistic];
        } else {
            data = [];
        }
        return <div>
            <Typography variant="h6" style={{marginBottom: "10px", marginTop: "20px"}}>
                <FormattedMessage id="licenses.statistics.table.title"/>
            </Typography>
            {this.dataTable(data, this.statisticColumns())}
        </div>;
    }

    allSnapshots = (projectId, snapshots) => {
        if (!snapshots || snapshots.length === 0) {
            return <div/>;
        }
        return <div>
            <Typography variant="h6" style={{marginBottom: "10px", marginTop: "20px"}}>
                <FormattedMessage id="snapshots.title"/>
            </Typography>
            {snapshots.map(snapshot => this.singleSnapshot(projectId, snapshot))}
        </div>;
    }

    renderPaymentConfig(operation) {
        return (
            <div>
                <Typography variant="h6" style={{marginBottom: "10px", marginTop: "20px"}}>
                    <FormattedMessage id="licenses.configuration.grid.title"/>
                </Typography>
                <PaymentConfiguration operation={operation} />
            </div>
        );
    }

    renderPayments(payments) {
        if (!payments || payments.length === 0) {
            return <div/>;
        }
        let data = [];
        payments.forEach((payment) => {
            let paymentData = {};
            if (payment.licenseType === LICENSE_TYPES.COOPERATION_LICENSE) {
                paymentData.status = payment.subscription?.status;
                paymentData.created = payment.subscription?.created;
                paymentData.currency = payment.subscription?.currency;
                paymentData.amount = "1 %";
            } else if (payment.paymentIntent) {
                paymentData.status = payment.paymentIntent?.status;
                paymentData.created = payment.paymentIntent?.created;
                paymentData.currency = payment.paymentIntent?.currency;
                paymentData.amount = parseFloat(payment.paymentIntent.amount+"e-2") + " " 
                    + paymentData.currency;
            } else if (payment.invoice) {
                paymentData.status = payment.invoice?.status;
                paymentData.created = payment.invoice?.created;
                paymentData.currency = payment.invoice?.currency;
                paymentData.amount = parseFloat(payment.invoice.amount+"e-2") + " "
                    + paymentData.currency;
            }
            data.push({
                licenseType: this.props.intl.formatMessage({id: "payment.licenseType.short." + payment.licenseType}),
                packageType: this.props.intl.formatMessage({id: "payment.packageType.short." + payment.packageType}),
                createdAt: formatDate(this.props.intl, paymentData.created * 1000, "dd.MM.yyyy HH:mm") + " Uhr",
                amount: paymentData.amount,
                payed: paymentData.status === "succeeded" || paymentData.status === "processing"
                    ? this.props.intl.formatMessage({id: "commons.yes.button"})
                    : (paymentData.status === "trialing" 
                        ? this.props.intl.formatMessage({id: "licenses.trialing.hint"}) 
                        : this.props.intl.formatMessage({id: "commons.no.button"}))
            });
        });
        let columns = [
            {title: this.props.intl.formatMessage({id: "payment.product.metaData.licenseType"}), field: "licenseType"},
            {title: this.props.intl.formatMessage({id: "payment.product.metaData.packageType"}), field: "packageType"},
            {title: this.props.intl.formatMessage({id: "licenses.payment.createdAt"}), field: "createdAt"},
            {title: this.props.intl.formatMessage({id: "licenses.payment.amount"}), field: "amount"},
            {title: this.props.intl.formatMessage({id: "licenses.payment.payed"}), field: "payed"}
        ];
        return (
            <div>
                <Typography variant="h6" style={{marginBottom: "10px", marginTop: "20px"}}>
                    <FormattedMessage id="licenses.payments.table.title"/>
                </Typography>
                {this.dataTable(data, columns)}
            </div>
        );
    }

    renderEvents(events) {
        if (!events || events.length === 0) {
            return <div/>;
        }
        let data = [];
        events.events.forEach(e => {
            data.push({
                name: e.event.name,
                type: this.props.intl.formatMessage({id: "licenses.eventType.short." + e.eventType}),
                date: formatIsoDate(this.props.intl, convertStringToDate(e.event.start_time)),
                time: formatIsoDate(this.props.intl, convertStringToDate(e.event.start_time), "HH:mm") + " - "
                    + formatIsoDate(this.props.intl, convertStringToDate(e.event.end_time), "HH:mm") + " Uhr",
                status: this.props.intl.formatMessage({id: "licenses.eventStatus.short." + e.event.status}),
            });
        });
        let columns = [
            {title: this.props.intl.formatMessage({id: "licenses.event.name"}), field: "name"},
            {title: this.props.intl.formatMessage({id: "licenses.event.type"}), field: "type"},
            {title: this.props.intl.formatMessage({id: "licenses.event.date"}), field: "date"},
            {title: this.props.intl.formatMessage({id: "licenses.event.time"}), field: "time"},
            {title: this.props.intl.formatMessage({id: "licenses.event.status"}), field: "status"}
        ];
        let hasSupportTime = events.availableSupportTime > 0;
        let hasAnalysis = !(events.hasAnalysisBooked || events.totalSupportTime > 0);
        let hasNoTime = !hasAnalysis && !hasSupportTime;
        let availableHours = (events.availableSupportTime/60).toFixed(1);
        let totalHours = (events.totalSupportTime/60).toFixed(1);
        return <div>
            <Typography variant="h6" style={{marginBottom: "10px", marginTop: "20px"}}>
                <FormattedMessage id="licenses.events.table.title"/>
            </Typography>
            {hasSupportTime && <FormattedMessage id="licenses.events.available.supportTime"
                                                 values={{available: availableHours, total: totalHours }}/>}
            {hasAnalysis && <FormattedMessage id="licenses.events.analysis.supportTime" />}
            {hasNoTime && <FormattedMessage id="licenses.events.no.supportTime" />}
            {this.dataTable(data, columns)}
        </div>;
    }

    singleSnapshot = (projectId, snapshot) => {
        if (!snapshot) {
            return <div/>;
        }
        let data;
        if (snapshot.statistics) {
            data = [snapshot.statistics];
        } else {
            data = [];
        }
        let title = this.props.intl.formatMessage({id: "snapshot.title"}) + " " + this.getDate(snapshot.created);
        return (
            <Accordion elevation={1} key={snapshot.id}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-label="Expand"
                    aria-controls="snapshot-content"
                    id="snapshot-header">
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h6" component="span">{title}</Typography>
                        </Grid>
                        <Tooltip title={this.props.intl.formatMessage({id: "snapshot.compare.tooltip"})}>
                            <IconButton size="small" onClick={(e) => {e.stopPropagation(); this.openComparison(projectId, snapshot.id)}}>
                                <TrendingUpIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    {this.dataTable(data, this.statisticColumns())}
                </AccordionDetails>
            </Accordion>
        );
    }

    statisticColumns = () => {
        return [
            {title: this.props.intl.formatMessage({id: "statistics.table.businessUnits"}), field: "numberOfBusinessUnits"},
            {title: this.props.intl.formatMessage({id: "statistics.table.users"}), field: "numberOfUsers"},
            {title: this.props.intl.formatMessage({id: "statistics.table.articles"}), field: "numberOfArticles"},
            {title: this.props.intl.formatMessage({id: "statistics.table.areas"}), field: "numberOfAreas"},
            {title: this.props.intl.formatMessage({id: "statistics.table.professionalGroups"}), field: "numberOfProfessionalGroups"},
            {title: this.props.intl.formatMessage({id: "statistics.table.operatingResources"}), field: "numberOfOperatingResources"},
            {title: this.props.intl.formatMessage({id: "statistics.table.specialServices"}), field: "numberOfSpecialServices"},
            {title: this.props.intl.formatMessage({id: "statistics.table.customerArticles"}), field: "numberOfCustomerArticles"},
            {title: this.props.intl.formatMessage({id: "statistics.table.logisticsPoints"}), field: "numberOfLogisticsPoints"}
        ];
    }

    comparisonColumns = (snapshot, statistic) => {
        return [
            {title: this.props.intl.formatMessage({id: "statistics.table.businessUnits"}), 
            render: () => this.comparisonCell(snapshot.statistics?.numberOfBusinessUnits, statistic.numberOfBusinessUnits)},
            {title: this.props.intl.formatMessage({id: "statistics.table.users"}), 
            render: () => this.comparisonCell(snapshot.statistics?.numberOfUsers, statistic.numberOfUsers)},
            {title: this.props.intl.formatMessage({id: "statistics.table.articles"}), 
            render: () => this.comparisonCell(snapshot.statistics?.numberOfArticles, statistic.numberOfArticles)},
            {title: this.props.intl.formatMessage({id: "statistics.table.areas"}), 
            render: () => this.comparisonCell(snapshot.statistics?.numberOfAreas, statistic.numberOfAreas)},
            {title: this.props.intl.formatMessage({id: "statistics.table.professionalGroups"}), 
            render: () => this.comparisonCell(snapshot.statistics?.numberOfProfessionalGroups, statistic.numberOfProfessionalGroups)},
            {title: this.props.intl.formatMessage({id: "statistics.table.operatingResources"}), 
            render: () => this.comparisonCell(snapshot.statistics?.numberOfOperatingResources, statistic.numberOfOperatingResources)},
            {title: this.props.intl.formatMessage({id: "statistics.table.specialServices"}), 
            render: () => this.comparisonCell(snapshot.statistics?.numberOfSpecialServices, statistic.numberOfSpecialServices)},
            {title: this.props.intl.formatMessage({id: "statistics.table.customerArticles"}), 
            render: () => this.comparisonCell(snapshot.statistics?.numberOfCustomerArticles, statistic.numberOfCustomerArticles)},
            {title: this.props.intl.formatMessage({id: "statistics.table.logisticsPoints"}), 
            render: () => this.comparisonCell(snapshot.statistics?.numberOfLogisticsPoints, statistic.numberOfLogisticsPoints)}
        ];
    }

    comparisonCell = (snapshotNumber, statisticNumber) => {
        if (snapshotNumber === null || snapshotNumber === undefined || statisticNumber === null || statisticNumber === undefined) {
            return this.props.intl.formatMessage({id: "snapshot.compare.noData"});
        }
        let difference = statisticNumber - snapshotNumber;
        let prefix = "";
        let color;
        if (difference === 0) {
            color = texisionFontColorDark;
        } else if (difference > 0) {
            color = errorColor;
            prefix = "+";
        } else {
            color = texisionGreen;
        }
        return <div style={{color: color}}>{prefix+difference}</div>;
    }

    dataTable = (data, columns) => {
        return (
            <MaterialTable
                columns={columns}
                data={data}        
                options={{
                    grouping: false,
                    sorting: true,
                    filtering: false,
                    draggable: false, 
                    search: false, 
                    paging: false, 
                    selection: false,
                    showSelectAllCheckbox: false,
                    headerStyle: {
                        fontWeight: 'bold',
                        backgroundColor: bodyBackgroundColor,
                        color: texisionFontColorDark
                    },
                    showTitle: false, 
                    toolbar: false
                }}
                style={{
                    padding: "5px",
                    marginTop: "10px",
                    width: "100%",
                }}
                components={{
                    Container: props => (<Paper {...props} key={this.props.type} elevation={0}/>)
                }}
                localization={{
                    grouping: {
                        placeholder: this.props.intl.formatMessage({id: "commons.table.grouping.placeholder"}),
                        groupedBy: this.props.intl.formatMessage({id: "commons.table.grouping.groupedBy"})
                    },
                    body: {
                        emptyDataSourceMessage: this.props.intl.formatMessage({id: "statistics.noData"})
                    }
                }}
            />
        );
    }

    projectCard = (operation) => {
        const project = operation.workingProject ?? operation.activeProject;

        return (
            <Grid item xs={12} key={"license-operation-card-" + operation.id}>

                <Accordion elevation={1} key={"license-operation-accordion-" + operation.id} onClick={() => this.loadOperationData(operation)}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-label="Expand"
                        aria-controls="snapshot-content"
                        id="snapshot-header">
                        <Grid container justifyContent="space-between" alignContent="center">
                            <Grid item xs>
                                <Typography variant="h2">{project.name}</Typography>
                            </Grid>
                            {this.getProjectStatusAsText(project.status)}
                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails>

                        <Grid container justifyContent="space-between" alignContent="center">
                            <Grid item xs={12}>
                                {this.renderPaymentConfig(operation)}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderPayments(this.state.operationToPayments.get(operation.id))}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderEvents(this.state.operationToEvents.get(operation.id))}
                            </Grid>
                            <Grid item xs={12}>
                                {this.statistic(this.state.projectToStatistics.get(project.id))}
                            </Grid>

                            <Grid item xs={12}>
                                {this.allSnapshots(project.id, this.state.projectsToSnapshots.get(project.id))}
                            </Grid>

                            <Grid item xs={12}>

                                <Grid container justifyContent="flex-end" style={{marginTop: "30px"}}>

                                </Grid>

                            </Grid>

                        </Grid>

                    </AccordionDetails>

                </Accordion>

            </Grid>
        );
    }

    openComparison = (projectId, snapshotId) => {
        this.setState({projectIdToCompare: projectId, snapshotIdToCompare: snapshotId, showComparisonDialog: true});
    }

    statisticsComparison = (projectId, snapshotId) => {
        if (!projectId || !snapshotId) {
            return <div/>;
        }

        let snapshot = this.getSnapshot(projectId, snapshotId);
        let statistic = this.state.projectToStatistics.get(projectId);
        if (!snapshot || !statistic) {
            return <div/>;
        }

        return (
            <div>
                <DialogContentText>
                    <FormattedMessage 
                        id="project.statistic.comparison.dialog.subtitle"
                        values={{date: this.getSnapshotDate(projectId, snapshotId)}}
                    />
                    {this.dataTable([snapshot.statistics], this.comparisonColumns(snapshot, statistic))}
                </DialogContentText>
            </div>
        );
    }

    render() {

        const operations = this.context.appData.operations;

        return <>

            <TexisionDialog
                type={DIALOG_TYPE_INFO}
                open={this.state.showComparisonDialog}
                dialogTitle={

                    <DialogTitle id="texision-dialog-title"
                                 style={{backgroundColor: getDialogColor(DIALOG_TYPE_INFO)}}>
                        <Grid container alignItems="center" alignContent="center" spacing={3}
                              style={{
                                  paddingBottom: "8px",
                                  paddingTop: "8px",
                                  paddingLeft: "25px",
                                  paddingRight: "25px"
                              }}>
                            <Grid item>
                                {getDialogIcon(DIALOG_TYPE_INFO)}
                            </Grid>
                            <Grid item>
                                <Typography variant="h4">
                                    <FormattedMessage
                                        id="project.statistic.comparison.dialog.title"
                                        values={{tender: this.getProjectName(this.state.projectIdToCompare)}}
                                        style={{fontSize: "18px"}}/>
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>

                }
                content={this.statisticsComparison(this.state.projectIdToCompare, this.state.snapshotIdToCompare)}
                cancelId="commons.close.label"
                onCancel={() => {
                    this.setState({
                        showComparisonDialog: false,
                        snapshotIdToCompare: null,
                        projectIdToCompare: null
                    })
                }}
                size="xl"/>

            <Typography variant="h1">
                <FormattedMessage id="licenses.title"/>
            </Typography>

            <Typography variant="subtitle1" style={{marginBottom: "20px"}}>
                <FormattedMessage id="licenses.subtitle"/>
            </Typography>

            <br/>

            {STAGE !== "prod" && (
                <>
                    <Typography variant="h2">
                        <FormattedMessage id="licenses.checkSubscriptions.title"/>
                    </Typography>

                    <Grid container>
                        <Grid item xs>
                            <Typography variant="subtitle2" style={{marginBottom: "20px"}}>
                                <FormattedMessage id="licenses.checkSubscriptions.subtitle"/>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    refreshSubscriptionPrices(this.context, this.props)}
                            >
                                <FormattedMessage id="commons.update.button"/>
                            </Button>
                        </Grid>
                    </Grid>

                    <br/>
                </>
            )}

            <Typography variant="h2">
                <FormattedMessage id="licenses.overview.subtitle"/>
            </Typography>

            <br/>

            <Card style={{minWidth: "400px"}}>
                <Grid container spacing={5}>
                    {operations.sort(this.sortOperations).map(o => this.projectCard(o))}
                </Grid>
            </Card>

        </>;
    }
}

export default withSnackbar(injectIntl(Licenses));
