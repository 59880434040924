import React, {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import {renderImage} from "../../../util/TableUtil";
import Divider from "@material-ui/core/Divider";
import {GeneralContext} from "../../contexts/GeneralContext";

class ProfileArticleRow extends Component {

    static contextType = GeneralContext;

    shouldComponentUpdate(nextProps) {
        return nextProps.checked !== this.props.checked;
    }

    handleCheckBoxChanged = (newValue) => {
        this.props.checkboxCallback(this.props.article.id, newValue);
    }

    render() {
        return (
            <div key={this.props.id}>
                <Grid container alignItems="center" style={{paddingBottom: "10px", paddingTop: "10px"}}>
                    <Grid item xs={1} style={{width: "50px"}}>
                        <Checkbox
                            color="primary"
                            checked={this.props.checked}
                            onChange={this.props.disabled ? (e) => {} : (e) => this.handleCheckBoxChanged(e.target.checked)}
                            inputProps={{'aria-label': 'primary checkbox'}}
                            disabled={this.props.disabled}
                        />
                    </Grid>
                    <Grid item xs={3} style={{wordBreak: "break-all"}}>
                        <FormattedMessage id={"constants.Category." + this.props.article.category}/>
                    </Grid>
                    <Grid item xs={2} style={{wordBreak: "break-all"}}>
                        <FormattedMessage id={"constants.SubCategory." + this.props.article.subCategory}/>
                    </Grid>
                    <Grid item xs={2} style={{wordBreak: "break-all"}}>
                        <FormattedMessage id={"constants.ArticleCategory." + this.props.article.articleCategory}/>
                    </Grid>
                    <Grid item xs={2} style={{wordBreak: "break-all"}}>
                        {this.props.article.description}
                    </Grid>
                    <Grid item xs={2}>
                        {renderImage(this.props.article, this.props.showArticleDetails)}
                    </Grid>
                </Grid>
                <Divider/>
            </div>
        );
    }
}

export default injectIntl(ProfileArticleRow);
