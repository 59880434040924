import { Typography } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
    APP_TYPE_KEY,
    COCKPIT_APP, PROJECT_STATUS_IN_EDITING,
    PROJECT_STATUS_IN_NEGOTIATION,
    PROJECT_STATUS_IN_POST_PROCESSING,
    PROJECT_STATUS_IN_PROGRESS
} from "../../util/Constants";
import { GeneralContext } from "../contexts/GeneralContext";
import ValidationHeader from "../validation/ValidationHeader";
import {isAdmin, isOfferUser} from "../../services/UserService";
import {getActiveOperation} from "../../services/OperationService";
import {getActiveProject, isProjectTaskCompleted} from "../../services/ProjectService";
import {isCockpit, isTender} from "../../util/Util";

export function isComponentReadOnly(context, projectTask, objectId) {
    const isCompleted = isProjectTaskCompleted(context, projectTask, objectId);

    if (isCockpit()) {
        const activeOperation = getActiveOperation(context);
        const isActiveProject = context.appData.activeProjectId === getActiveOperation(context).activeProject.id;
        return isActiveProject || !activeOperation?.editable || isCompleted;
    } else if (isTender()) {
        const project = getActiveProject(context);
        return !isAdmin(context.currentUser)
            && (isCompleted || ![PROJECT_STATUS_IN_PROGRESS, PROJECT_STATUS_IN_EDITING].includes(project?.status));
    } else {
        return true;
    }
}

// this wraps cockpit and tender components in order to make them reusable, but use different title, subtitle
// and make it readonly for the active cockpit contract
class ReadOnlyWrapper extends Component {

    static contextType = GeneralContext;

    getCockpitTextBase = () => {
        const activeOperation = getActiveOperation(this.context);
        const isActive = activeOperation?.activeProject?.id === this.context.appData.activeProjectId;
        const editable = !isActive && activeOperation?.editable;
        const hasBidder = isOfferUser(activeOperation?.bidder);

        let base;

        if (isActive) {
            base = "cockpit.active.";
        } else if (!editable && activeOperation?.workingProject?.status === PROJECT_STATUS_IN_NEGOTIATION) {
            base = "cockpit.readonly.inNegotiation.";
        } else if (!editable && activeOperation?.workingProject?.status === PROJECT_STATUS_IN_POST_PROCESSING) {
            base = "cockpit.readonly.inProcess.";
        } else if (activeOperation?.workingProject?.status === PROJECT_STATUS_IN_NEGOTIATION) {
            base = "cockpit.working.inNegotiation.";
        } else if (hasBidder) {
            base = "cockpit.working.inProcess.";
        }
        return base;
    }

    suffix = () => {
        if (localStorage.getItem(APP_TYPE_KEY) !== COCKPIT_APP || !this.getCockpitTextBase()) {
            return "";
        } else {
            return " " + this.props.intl.formatMessage({id: this.getCockpitTextBase() + "suffix"});
        }
    }

    cockpitSubtitle = () => {
        if (localStorage.getItem(APP_TYPE_KEY) !== COCKPIT_APP || !this.getCockpitTextBase()) {
            return null;
        }
        return (
            <Typography 
                variant="subtitle1" 
                style={{fontWeight: "bold", paddingBottom: 15, paddingTop: 15}}>
                <FormattedMessage id={this.getCockpitTextBase() + "subtitle"}/>
            </Typography>
        );
    }

    render() {
        const Component = this.props.component;
        return (
            <>

                {this.props.titleId && <>

                    <ValidationHeader
                        title={this.props.intl.formatMessage({id: this.props.titleId}) + this.suffix()}
                        projectTask={this.props.projectTask}
                        objectId={this.props.objectId}
                        projectId={this.props.projectId}/>

                    {this.cockpitSubtitle()}

                </>}

                {this.props.subtitleId && <Typography variant="subtitle2">
                    {this.props.intl.formatMessage({id: this.props.subtitleId})}
                </Typography>}

                <Component readOnly={isComponentReadOnly(this.context, this.props.projectTask, this.props.objectId)}/>
                
            </>
        );
    }
}

export default injectIntl(ReadOnlyWrapper);
