import React, {Component} from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';


import {withSnackbar} from 'notistack';
import {FormattedMessage, injectIntl} from 'react-intl';
import {isEmptyOrBlank, validateAllFeaturesHaveValues, isNotEmptyOrBlank} from '../../../util/Util';
import {texisionFontColorOrange} from '../../../util/ColorTheme';
import {DIALOG_TYPE_WARNING, OPERATING_RESOURCE_OVERVIEW_ROUTE} from '../../../util/Constants';

import OperatingResourceFeaturePanel from './OperatingResourceFeaturePanel';
import ImageCropper from '../../share/ImageCropper';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import {FILE_TYPES} from '../../../util/DocumentUtil';
import {GeneralContext} from "../../contexts/GeneralContext";
import { withRouter } from 'react-router-dom';
import {addResourceImage, createResource, deleteResourceImage, updateResource} from "../../../services/OperatingResourceService";
import OperatingResourceSubCategorySelect from "./OperatingResourceSubCategorySelect";


const MAX_FILE_SIZE_IN_BYTE = 5000000;

class OperatingResourceConfigurator extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        if (this.props.location.state?.operatingResource) {
            const operatingResource = this.props.location.state.operatingResource;
            let image = null;

            if (operatingResource.imageVos?.length > 0) {
                const imageVo = operatingResource.imageVos[0];
                image = imageVo?.image
            }

            this.state = {
                id: operatingResource.id,
                name: operatingResource.name,
                description: operatingResource.description,
                category: "OPERATING_RESOURCE", // All operating resources currently have this category.
                subCategory: operatingResource.subCategory,
                features: operatingResource.features,
                imageVos: operatingResource.imageVos,
                editMode: true,
                imageToDelete: null,
                image: image,
                croppedImage: null,
                selectedImage: null,
                selectedFilename: null,
                showSelectedImageToLargeDialog: false,
                saveOrUpdateInProgress: false,
                version: operatingResource.version
            };

        } else {

            this.state = {
                id: null,
                name: "",
                description: "",
                category: "OPERATING_RESOURCE", // All operating resources currently have this category.
                subCategory: "",
                features: [],
                imageVos: [],
                editMode: false,
                imageToDelete: null,
                croppedImage: null,
                selectedImage: null,
                selectedFilename: null,
                image: null,
                showSelectedImageToLargeDialog: false,
                saveOrUpdateInProgress: false,
                version: 0
            };
        }
    }

    componentDidUpdate() {
        if (this.state.id === null || this.state.id === undefined) {
            const hasUnsavedChanges = this.state.name
               || this.state.description
               || this.state.subCategory
               || this.state.selectedImage;
            this.context.setUnsavedChanges(!!hasUnsavedChanges);
        }
    }

    saveOrUpdate = async() => {

        this.setState({saveOrUpdateInProgress: true});

        let featuresToSave = [];
        // clear empty slots...
        this.state.features.forEach(feature => {
            featuresToSave.push(feature);
        });

        let operatingResource = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            features: featuresToSave,
            category: this.state.category,
            subCategory: this.state.subCategory,
            imageVos: this.state.imageVos,
            version: this.state.version
        }

        let imageBlob = null;
        if (this.state.croppedImage) {
            let blob = await new Promise(resolve => this.state.croppedImage.toBlob(resolve));
            imageBlob = new File([blob], this.state.selectedFilename, {type: 'image/png'});
        }

        const imageToDelete = this.state.imageToDelete;

        if (imageToDelete) {
            await deleteResourceImage(this.context, this.props, imageToDelete.parentId, imageToDelete.filename);
        }

        if (this.state.id === null || this.state.id === undefined) {
            const createdResource = await createResource(this.context, this.props, operatingResource);
            if (createdResource) {
                if (imageBlob) {
                    await addResourceImage(this.context, this.props, createdResource.id, createdResource.version, imageBlob);
                }
                this.context.setUnsavedChanges(false);
            }
        } else {
            const updatedResource = await updateResource(this.context, this.props, operatingResource);
            if (updatedResource) {
                if (imageBlob) {
                    await addResourceImage(this.context, this.props, updatedResource.id, updatedResource.version, imageBlob);
                } else if (imageToDelete) {
                    await deleteResourceImage(this.context, this.props, imageToDelete.parentId, imageToDelete.filename);
                }
                this.context.setUnsavedChanges(false);
            } else {
                this.setState({saveOrUpdateInProgress: false});
            }
        }
        this.props.history.push(OPERATING_RESOURCE_OVERVIEW_ROUTE);
    }

    handleEditUnsavedChanges = () => {
        if (this.state.id !== null && this.state.id !== undefined) {
            this.context.setUnsavedChanges(true);
        }
    }

    validateMandatoryFields = () => {
        return isEmptyOrBlank(this.state.name)
            || isEmptyOrBlank(this.state.description)
            || isEmptyOrBlank(this.state.subCategory) 
            || validateAllFeaturesHaveValues(this.state.features);
    }

    handleNameChanged = (newName) => {
        this.handleEditUnsavedChanges();
        this.setState({name: newName});
    }

    handleDescriptionChanged = (newDescription) => {
        this.handleEditUnsavedChanges();
        this.setState({description: newDescription});
    }

    handleSubCategoryChanged = (subCategory) => {
        this.handleEditUnsavedChanges();
        // If the subcategory changes, then delete all features.
        this.setState({subCategory: subCategory, features: []});
    }

    featureCallback = (features) => {
        this.handleEditUnsavedChanges();
        this.setState({features: features});
    }

    handleImageSelection = (file) => {
        if (file.size >= MAX_FILE_SIZE_IN_BYTE) {
            this.setState({showSelectedImageToLargeDialog: true});
        } else {
            let url = URL.createObjectURL(file);
            if (this.state.image) {
              this.setState({imageToDelete: {...this.state.image}, selectedImage: url, selectedFilename: file.name});
            } else {
              this.setState({selectedImage: url, selectedFilename: file.name});
            }
            this.handleEditUnsavedChanges();
        }
    }

    handleImageCropped = (croppedImage) => {
        this.setState({croppedImage: croppedImage})
    }

    handleRemoveImage = () => {
        this.handleEditUnsavedChanges();
        if (!this.state.selectedImage && this.state.image) {
            this.setState({imageToDelete: {...this.state.image}, image: null, croppedImage: null});
        } else if (!this.state.image && this.state.selectedImage) {
            this.setState({selectedImage: null, selectedFilename: null, croppedImage: null});
        } else if (this.state.selectedImage && this.state.image) {
            this.setState({selectedImage: null, selectedFilename: null, croppedImage: null});
        }
    }

    handleAbortEdit = () => {
        this.context.setUnsavedChanges(false);
        this.props.history.push(OPERATING_RESOURCE_OVERVIEW_ROUTE);
    }

    render() {

        return (
            <>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showSelectedImageToLargeDialog}
                    titleId="commons.image.warningDialog.title"
                    subtitleId="commons.image.warningDialog.text"
                    actionId="commons.close.label"
                    onAction={() => this.setState({showSelectedImageToLargeDialog: false})}/>

                <Typography variant="h1">
                    <FormattedMessage id="operatingResources.configurator.h1"/>
                </Typography>

                <Typography variant="subtitle1" style={{marginBottom: "20px", marginRight: "4%"}}>
                    <FormattedMessage id="operatingResources.configurator.h2"/>
                </Typography>

                <Grid className="parent">

                    <Card className="child">

                        <CardContent>

                            <Typography variant="h6" component="h2">
                                <FormattedMessage id="operatingResources.configurator.masterdata.h1"/>
                            </Typography>

                            <Typography color="textSecondary" style={{marginBottom: "20px"}}>
                                <FormattedMessage id="operatingResources.configurator.masterdata.h2"/>
                            </Typography>

                            <Grid container direction="row" spacing={2}>

                                <Grid item xs={12}>
                                    <TextField 
                                        id="name"
                                        value={this.state.name}
                                        label={this.props.intl.formatMessage({id: "operatingResources.configurator.masterdata.name"}) + " *"}
                                        variant="outlined" inputProps={{maxLength: 300}} 
                                        style={{width: "50%"}}
                                        onChange={(e) => this.handleNameChanged(e.target.value)}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField 
                                        id="description"
                                        value={this.state.description}
                                        label={this.props.intl.formatMessage({id: "operatingResources.configurator.masterdata.description"}) + " *"}
                                        variant="outlined" inputProps={{maxLength: 300}} 
                                        style={{width: "94%"}}
                                        onChange={(e) => this.handleDescriptionChanged(e.target.value)}/>
                                </Grid>

                            </Grid>

                        </CardContent>

                    </Card>

                    <Card className="child">

                        <CardContent>

                            <Typography variant="h6" component="h2">
                                <FormattedMessage id="operatingResources.configurator.categories.h1"/>
                            </Typography>

                            <Typography color="textSecondary" style={{marginBottom: "20px"}}>
                                <FormattedMessage id="operatingResources.configurator.categories.h2"/>
                            </Typography>

                            <OperatingResourceSubCategorySelect
                                value={this.state.subCategory}
                                onChange={(subCategory) => this.handleSubCategoryChanged(subCategory)}
                            />

                            <Grid container spacing={1} justifyContent="center">

                                <Grid item xs={12}>

                                    <InputLabel 
                                        htmlFor="subCategory-native-simple" 
                                        style={{marginTop: "20px"}}>
                                        <FormattedMessage id="operatingResources.configurator.categories.subCategory"/> *
                                    </InputLabel>

                                    <Select
                                        native
                                        value={this.state.subCategory}
                                        onChange={(e) => this.handleSubCategoryChanged(e.target.value)}
                                        style={{width: "50%"}}
                                        inputProps={{name: 'subCategory', id: 'subCategory-native-simple'}}>

                                        <option aria-label="None" value=""/>

                                        {this.context.appData.operatingResourceSubCategories.map((subCategory) => {
                                            let optionKey = this.props.intl.formatMessage({id: "constants.ResourceSubCategory." + subCategory});
                                            return (
                                                <option 
                                                    key={subCategory} 
                                                    value={subCategory}>
                                                    {optionKey}
                                                </option>
                                            );
                                        })}

                                    </Select>

                                </Grid>

                            </Grid>

                        </CardContent>

                    </Card>

                    {isNotEmptyOrBlank(this.state.subCategory) && <Card className="child">
                        <CardContent>

                            <Typography variant="h6" component="h2">
                                <FormattedMessage id="operatingResources.configurator.features.h1"/>
                            </Typography>

                            <Typography color="textSecondary" style={{marginBottom: "20px"}}>
                                <FormattedMessage id="operatingResources.configurator.features.h2"/>
                            </Typography>

                            <OperatingResourceFeaturePanel
                                features={this.state.features}
                                featureType={this.state.subCategory + "_FEATURE"}
                                onChange={this.featureCallback}
                                valuesEndpoint="resource/featureValues/"
                                i18nPrefix="operatingResources.featureType."/>

                        </CardContent>
                    </Card>}

                    <Card className="child">

                        <CardContent>

                            <Typography variant="h6" component="h2">
                                <FormattedMessage id="operatingResources.configurator.image.h1"/>
                            </Typography>

                            <Typography color="textSecondary" style={{marginBottom: "20px"}}>
                                <FormattedMessage id="operatingResources.configurator.image.h2"/>
                            </Typography>

                            <Grid container direction="column" spacing={1}>

                                <Grid item>

                                    <Button 
                                        variant="contained" 
                                        color="secondary" 
                                        component="label" 
                                        style={{marginRight: "10px"}}>

                                        {!this.state.selectedImage && !this.state.image 
                                            ? <FormattedMessage id="commons.image.choose"/> 
                                            : <FormattedMessage id="commons.image.chooseAnother"/>}

                                        <input 
                                            type="file" 
                                            accept={FILE_TYPES.IMAGE} 
                                            style={{ display: "none" }} 
                                            onChange={(e) => this.handleImageSelection(e.target.files[0])}/>

                                    </Button>

                                    {(this.state.selectedImage || this.state.image) &&
                                        <Button 
                                            variant="contained" 
                                            color="secondary" 
                                            onClick={this.handleRemoveImage}>
                                            <FormattedMessage id="commons.image.remove"/>
                                        </Button>}

                                </Grid>

                                <Grid item>

                                    {this.state.image && !this.state.selectedImage && 
                                        <img 
                                            src={this.state.image.url} 
                                            style={{width: "33%", marginBottom: "45px"}} 
                                            alt="Unitimage"/>}

                                    {this.state.selectedImage && 
                                        <ImageCropper 
                                            src={this.state.selectedImage} 
                                            croppedImageCallback={this.handleImageCropped}>
                                        </ImageCropper>}

                                </Grid>

                                <Grid item>
                                    {this.state.selectedImage && 
                                        <Typography color="textSecondary" style={{marginBottom: "20px"}}>
                                            <FormattedMessage id="commons.image.infotext"/>
                                        </Typography>}
                                </Grid>

                            </Grid>

                        </CardContent>

                    </Card>

                </Grid>


                {this.validateMandatoryFields() &&
                    <Grid container>

                        <Card className="child" elevation={0} style={{backgroundColor: "#f8f4d5"}}>

                            <CardContent>

                                <Typography variant="h6" component="h2" style={{color: texisionFontColorOrange}}>
                                    <FormattedMessage id="operatingResources.messages.validationError"/>
                                </Typography>

                                <Typography style={{color: texisionFontColorOrange, marginTop: "10px"}}>
                                    <FormattedMessage id="operatingResources.messages.error.mandatory"/>
                                </Typography>

                            </CardContent>

                        </Card>

                    </Grid>
                }

                <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-end">

                    <Grid item>
                        <Button 
                            variant="contained" 
                            color="primary"
                            disabled={this.validateMandatoryFields() || this.state.saveOrUpdateInProgress}
                            onClick={() => this.saveOrUpdate()}
                            style={{marginTop: "10px"}}>
                            <FormattedMessage id="commons.save.button"/>
                        </Button>
                    </Grid>

                    <Grid item>

                    {this.state.editMode &&
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={() => this.handleAbortEdit()} 
                            style={{marginTop: "10px"}}>
                            <FormattedMessage id="commons.cancel.button"/>
                        </Button>}

                    </Grid>

                </Grid>
                
            </>
        );
    }
}

export default withSnackbar(injectIntl(withRouter(OperatingResourceConfigurator)));
