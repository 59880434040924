import {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Box, Card, Grid, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {GeneralContext} from "../../contexts/GeneralContext";
import {loadVersionMarkdownById, loadVersions} from "../../../services/VersionService";
import {withSnackbar} from "notistack";
import {SimpleTextCard} from "../../uiLibrary/SimpleTextCard";
import {ListItemButton} from "@mui/material";
import ReactMarkdown from "react-markdown";
import packageJson from '../../../../package.json';

class ReleaseNotes extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            versions: [],
            versionMarkdownMap: new Map(),
            selectedVersion: null,
            isLoadingVersions: false,
            isLoadingReleaseNotes: false
        }
    }

    async componentDidMount() {
        this.setState({isLoadingVersions: true});
        await this.setState({versions: await loadVersions(this.context, this.props)});
        this.setState({isLoadingVersions: false});
    }

    onVersionClicked = async(version) => {
        this.setState({selectedVersion: version});
        if (!this.state.versionMarkdownMap.get(version)) {
            let versionMarkdownMap = this.state.versionMarkdownMap;
            this.setState({isLoadingReleaseNotes: true});
            const releaseNotes = await loadVersionMarkdownById(this.context, this.props, version);
            this.setState({isLoadingReleaseNotes: false});
            if (releaseNotes) {
                versionMarkdownMap.set(version, releaseNotes);
                this.setState({versionMarkdownMap});
            }
        }
    }

    compareVersions = (a, b) => {
        if (!a) {
            return -1
        } else if (!b) {
            return 1
        }
        const indexA1 = a.indexOf(".")
        const indexA2 = a.lastIndexOf(".")
        const valueA1 = a.substring(0, indexA1)
        const valueA2 = a.substring(indexA1+1, indexA2)

        const indexB1 = b.indexOf(".")
        const indexB2 = b.lastIndexOf(".")
        const valueB1 = b.substring(0, indexB1)
        const valueB2 = b.substring(indexB1+1, indexB2)

        if (valueA1 === valueB1) {
            return valueB2 - valueA2
        } else {
            return valueB1 - valueA1
        }
    }

    render() {
        const clientVersion = packageJson.version;
        const serverVersion = this.context.appData?.systeminfo?.version ?? this.props.intl.formatMessage({id: "commons.unknown"});
        const selectedReleaseNotes = this.state.versionMarkdownMap.get(this.state.selectedVersion);

        return (
          <>

              <Typography variant="h1">
                  <FormattedMessage id="releaseNotes.title"/>
              </Typography>

              <Typography variant="subtitle1">
                  <FormattedMessage id="releaseNotes.currentVersions" values={{clientVersion, serverVersion}}/>
              </Typography>

              <Typography variant="subtitle1">
                  <FormattedMessage id="releaseNotes.subtitle"/>
              </Typography>

              <SimpleTextCard show={!this.state.versions?.length && !this.state.isLoadingVersions} textId="releaseNotes.noIds"/>

              {!!this.state.versions?.length && <Grid container spacing={6}>

                  <Grid item>
                      <Box sx={{bgcolor: "white"}}>
                          <List dense disablePadding>
                              {this.state.versions
                                  .sort((v1, v2) => this.compareVersions(v1, v2))
                                  .map(version => <ListItem disableGutters dense style={{paddingBottom: 0, paddingTop: 0}}>
                                      <ListItemButton
                                          style={{paddingLeft: 30, paddingRight: 30}}
                                          selected={this.state.selectedVersion === version}
                                          onClick={() => this.onVersionClicked(version)}>
                                          <ListItemText primary={version}/>
                                      </ListItemButton>
                                  </ListItem>)}
                          </List>
                      </Box>
                  </Grid>

                  {!!this.state.selectedVersion && <Grid item xs>

                      <Card>

                          <ReactMarkdown>
                              {selectedReleaseNotes}
                          </ReactMarkdown>

                          {!selectedReleaseNotes && !this.state.isLoadingReleaseNotes
                              && <FormattedMessage id="releaseNotes.noChangeLog"/>}
                      </Card>
                  </Grid>}

              </Grid>}
          </>
        );
    }
}

export default withSnackbar(injectIntl(ReleaseNotes));
