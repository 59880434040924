import {Accordion, AccordionDetails, AccordionSummary, Button, Card, Grid, Icon, Tooltip, Typography} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {FormattedMessage, useIntl} from "react-intl";
import "../../css/Procedure.css";
import React from "react";

const ProcedureAccordion = ({
    complete,
    titleId, 
    subtitle, 
    saveButtonId, 
    onSave, 
    disableSaveButton,
    readOnly,
    children,
    tooltip,
    tooltipId,
    mandatory
}) => {
    const intl = useIntl();
    return (
        <Card className="procedureCard">
            <Accordion className="procedureAccordion" elevation={1} key={titleId}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-label="Expand"
                    aria-controls="procedure-content"
                    id="procedure-header">
                    <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
                        <Grid item xs>
                            <Typography variant="h3" component="span">
                                <FormattedMessage id={titleId}/>
                            </Typography>
                        </Grid>
                        <Grid item>
                            {mandatory && !readOnly && !complete && <div style={{color: "orange"}}>
                                {intl.formatMessage({id: "contract.chapter.incomplete.hint"})}
                            </div>}
                        </Grid>
                        <Grid item>
                            <Icon style={{paddingLeft: "10px", color: readOnly || complete ? "grey" : "orange"}}>edit</Icon>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width: "100%"}}>
                        <Grid container wrap="nowrap">
                            <Grid item xs>
                                <Typography className="procedureSubtitle" variant="subtitle2">
                                    {subtitle}
                                </Typography>
                            </Grid>
                            {saveButtonId && onSave &&
                            <Grid item>
                                <Tooltip
                                    title={tooltip ?? (tooltipId
                                        ? <div style={{whiteSpace: "pre-line"}}>
                                            <FormattedMessage id={tooltipId}/>
                                        </div>
                                        : "")}>
                                    <div>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            component="label"
                                            disabled={disableSaveButton}
                                            onClick={() => onSave()}>
                                            <FormattedMessage id={saveButtonId}/>
                                        </Button>
                                    </div>
                                </Tooltip>
                            </Grid>}
                        </Grid>
                        {children}
                    </div>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default ProcedureAccordion;
