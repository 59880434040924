import React, { Component } from "react";
import { GeneralContext } from "../contexts/GeneralContext";


class KeyWrapper extends Component {

    static contextType = GeneralContext;

    render() {
        const Component = this.props.component;
        return (
            <>
                <Component key={"child-" + this.props.key}/>
            </>
        );
    }
}

export default KeyWrapper;
