import React, {Component} from 'react';
import {GeneralContext} from "../../../contexts/GeneralContext";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {FormattedMessage, injectIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import {getMonthValue, INTERVAL_TYPE_KEY, INTERVAL_TYPES, MONTHS} from "../../../../util/Constants";
import {createBaseDataSet} from "../../../../services/StatisticsService";
import {withSnackbar} from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";
import {getActiveOperation} from "../../../../services/OperationService";
import {Button} from "@material-ui/core";
import BaseDataTimeSelection from "./BaseDataTimeSelection";
import BaseDataCopyDialog from "./BaseDataCopyDialog";

class BaseDataDialog extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            careDays: this.props.baseDataToEdit?.careDays ?? "",
            inpatientCases: this.props.baseDataToEdit?.inpatientCases ?? "",
            workWearPeople: this.props.baseDataToEdit?.workWearPeople ?? "",
            totalBeds: this.props.baseDataToEdit?.totalBeds ?? "",
            bedsByStation: this.props.baseDataToEdit?.bedsByStation ?? new Map(),
            month: this.props.baseDataToEdit?.month ?? "",
            quarter: this.props.baseDataToEdit?.quarter ?? "",
            editBedsByStation: false,
            showCopyDialog: false
        };
    }

    componentDidMount() {
        this.setState({year: this.props.baseDataToEdit?.year
                ?? (this.context.getUserStateValue(INTERVAL_TYPE_KEY) === INTERVAL_TYPES.YEARLY ? "" : new Date().getFullYear())});
    }

    resetState = () => {
        this.setState({
            careDays: "",
            inpatientCases: "",
            workWearPeople: "",
            totalBeds: "",
            bedsByStation: new Map(),
            month: "",
            quarter: "",
            year: this.context.getUserStateValue(INTERVAL_TYPE_KEY) === INTERVAL_TYPES.YEARLY ? "" : new Date().getFullYear(),
            editBedsByStation: false
        })
    }

    convertTotalBedsToBedsByStation = () => {
        const totalBeds = this.state.totalBeds ? parseInt(this.state.totalBeds) : 0;
        if (totalBeds === 0 || !totalBeds) {
            return new Map();
        } else {
            const bedsByStation = new Map();
            const stations = this.props.deliveryStations;
            const numberOfStations = stations.length;
            for (let i = 0; i < numberOfStations; i++) {
                bedsByStation.set(stations[i].id, Math.floor(totalBeds / numberOfStations));
                if (i === numberOfStations - 1) {
                    bedsByStation.set(stations[i].id, Math.floor(totalBeds / numberOfStations) + (totalBeds % numberOfStations));
                }
            }
            return bedsByStation;
        }
    }

    onSave = async(addNew) => {
        const intervalType = this.context.getUserStateValue(INTERVAL_TYPE_KEY);
        const baseData = {
            operationId: getActiveOperation(this.context).id,
            careDays: this.state.careDays ? parseInt(this.state.careDays) : 0,
            inpatientCases: this.state.inpatientCases ? parseInt(this.state.inpatientCases) : 0,
            workWearPeople: this.state.workWearPeople ? parseInt(this.state.workWearPeople) : 0,
            bedsByStation: this.state.editBedsByStation ? (this.state.bedsByStation ?? new Map()) : this.convertTotalBedsToBedsByStation(),
            month: intervalType === INTERVAL_TYPES.MONTHLY ? getMonthValue(this.state.month) : null,
            quarter: intervalType === INTERVAL_TYPES.QUARTERLY ? this.state.quarter : null,
            year: this.state.year
        }
        const response = await createBaseDataSet(this.context, this.props, baseData, intervalType);
        if (response) {
            this.props.reload();
        }
        if (addNew) {
            this.resetState();
        } else {
            this.props.close();
        }
    }

    validInput = (value) => {
        return value === "" || value === "0" || value === 0 || new RegExp(/^[1-9][0-9]*$/).test(value);
    }

    maySave = () => {
        const intervalType = this.context.getUserStateValue(INTERVAL_TYPE_KEY);
        let hasValidBedsByStation = !!this.state.bedsByStation?.size;
        for (let numberOfBeds of this.state.bedsByStation.values()) {
            if (!numberOfBeds && numberOfBeds !== 0) {
                hasValidBedsByStation = false;
                break;
            }
        }
        return !!this.state.year
            && (intervalType === INTERVAL_TYPES.YEARLY
                || (intervalType === INTERVAL_TYPES.MONTHLY && !!this.state.month)
                || (intervalType === INTERVAL_TYPES.QUARTERLY && !!this.state.quarter))
            && (!!this.state.careDays
                || !!this.state.workWearPeople
                || !!this.state.totalBeds
                || !!this.state.inpatientCases
                || (this.state.editBedsByStation && hasValidBedsByStation));
    }

    onBedsByStationChanged = (stationId, newValue) => {
        if (!this.validInput(newValue)) return;
        let bedsByStation = this.state.bedsByStation;
        bedsByStation.set(stationId, newValue ? parseInt(newValue) : "");
        this.setState({bedsByStation});
    }

    onTotalBedsChanged = (newValue) => {
        if (!this.validInput(newValue)) return;
        this.setState({totalBeds: newValue});
    }

    onInpatientCasesChanged = (newValue) => {
        if (!this.validInput(newValue)) return;
        this.setState({inpatientCases: newValue});
    }

    onCareDaysChanged = (newValue) => {
        if (!this.validInput(newValue)) return;
        this.setState({careDays: newValue});
    }

    onWorkWearPeopleChanged = (newValue) => {
        if (!this.validInput(newValue)) return;
        this.setState({workWearPeople: newValue});
    }

    getYears = () => {
        if (!this.props.contract?.startOfServiceDate) {
            return [];
        }
        const startOfServiceYear = new Date(this.props.contract.startOfServiceDate).getFullYear();
        let years = [];
        for (let year = new Date().getFullYear(); year >= startOfServiceYear; year--) {
            years.push(year);
        }
        return years;
    }

    overridesData = () => {
        const intervalType = this.context.getUserStateValue(INTERVAL_TYPE_KEY);
        return !!this.props.baseDataToEdit
            || (intervalType === INTERVAL_TYPES.MONTHLY && this.props.allBaseData?.find(d => d.month === getMonthValue(this.state.month)
                && d.year === this.state.year))
            || (intervalType === INTERVAL_TYPES.QUARTERLY
                && this.props.allBaseData?.find(d => (d.quarter === this.state.quarter || (d.month >= this.state.quarter*3-2 && d.month <= this.state.quarter*3))
                    && d.year === this.state.year))
            || (intervalType === INTERVAL_TYPES.YEARLY && this.props.allBaseData?.find(d => d.year === this.state.year));
    }

    hasTotalBedsConflict = () => {
        let sum = 0;
        for (let numberOfBeds of this.state.bedsByStation.values()) {
            sum += (numberOfBeds ?? 0);
        }
        return !!this.state.totalBeds && !!sum && parseInt(this.state.totalBeds) !== sum;
    }

    render() {
        return (
            <>

                <BaseDataCopyDialog
                    open={this.state.showCopyDialog}
                    onClose={() => this.setState({showCopyDialog: false})}
                    onConfirm={(careDays, inpatientCases, workWearPeople, bedsByStation,) => this.setState({
                        careDays,
                        inpatientCases,
                        workWearPeople,
                        bedsByStation,
                        editBedsByStation: true
                    })}
                />

                <TexisionDialog
                    open={this.props.show}
                    style={{zIndex: 1300}}
                    titleId={"cockpit.statistics.baseData.dialog." + (this.props.edit ? "edit" : "create") + ".title"}
                    subtitleId={"cockpit.statistics.baseData.dialog." + (this.props.edit ? "edit" : "create") + ".subtitle"}
                    actionId="commons.save.button"
                    onAction={() => this.onSave(false)}
                    actionDisabled={!this.maySave()}
                    actionId2="cockpit.statistics.baseData.saveAndAddNew"
                    onAction2={() => this.onSave(true)}
                    actionDisabled2={!this.maySave()}
                    cancelId="commons.cancel.button"
                    onCancel={() => this.props.close()}
                    content={<Grid container spacing={2} alignItems="center">

                        <Grid item xs={3}>
                            <Typography>
                                <FormattedMessage id="cockpit.statistics.baseData.dialog.time"/>
                            </Typography>
                        </Grid>
                        <Grid item xs={9} style={{display: "flex", justifyContent: "flex-end"}}>
                            <BaseDataTimeSelection
                                baseDataToEdit={this.props.baseDataToEdit}
                                monthValue={this.state.month}
                                months={Object.values(MONTHS)}
                                onMonthChange={(month) => this.setState({month})}
                                quarterValue={this.state.quarter}
                                quarters={[1, 2, 3, 4]}
                                onQuarterChange={(quarter) => this.setState({quarter})}
                                yearValue={this.state.year}
                                years={this.getYears()}
                                onYearChange={(year) => this.setState({year})}
                                copy={false}
                            />
                        </Grid>

                        {this.overridesData() && <Grid item xs={12}>
                            <Alert severity="warning">
                                <FormattedMessage id={!!this.props.baseDataToEdit
                                    ? "cockpit.statistics.baseData.edit.override" : "cockpit.statistics.baseData.create.override"}/>
                            </Alert>
                        </Grid>}

                        <Grid item xs={12}>
                            <Button variant="text" color="primary" style={{padding: 0}} onClick={() => this.setState({showCopyDialog: true})}>
                                <FormattedMessage id="cockpit.statistics.baseData.copy.button"/>
                            </Button>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography>
                                <FormattedMessage id="cockpit.statistics.baseData.dialog.editBedsByStation"/>
                            </Typography>
                        </Grid>
                        <Grid item xs={9} style={{display: "flex", justifyContent: "flex-end"}}>
                            <Checkbox
                                color="primary"
                                checked={this.state.editBedsByStation}
                                onChange={() => this.setState({editBedsByStation: !this.state.editBedsByStation})}/>
                        </Grid>

                        {!this.state.editBedsByStation && <>
                            <Grid item xs={3}>
                                <Typography>
                                    <FormattedMessage id="cockpit.statistics.baseData.dialog.totalBeds"/>
                                </Typography>
                            </Grid>
                            <Grid item xs={9} style={{display: "flex", justifyContent: "flex-end"}}>
                                <TextField
                                    id={"totalBeds-text-field"}
                                    maxLength={8}
                                    value={this.state.totalBeds}
                                    variant="outlined"
                                    onChange={(e) => this.onTotalBedsChanged(e.target.value)}/>
                            </Grid>
                        </>}

                        {!!this.props.baseDataToEdit && !this.state.editBedsByStation && this.hasTotalBedsConflict() && <Grid item xs={12}>
                            <Alert severity="warning">
                                <FormattedMessage id="cockpit.statistics.baseData.totalBeds.conflict"/>
                            </Alert>
                        </Grid>}

                        {this.state.editBedsByStation && this.props.deliveryStations.map(station => <>
                            <Grid item xs={3}>
                                <Typography>
                                    <FormattedMessage
                                        id="cockpit.statistics.baseData.dialog.bedsForStation"
                                        values={{name: station.shortDescription}}/>
                                </Typography>
                            </Grid>
                            <Grid item xs={9} style={{display: "flex", justifyContent: "flex-end"}}>
                                <TextField
                                    id={"beds-by-station-text-field-" + station.id}
                                    maxLength={8}
                                    value={this.state.bedsByStation.get(station.id) ?? ""}
                                    variant="outlined"
                                    onChange={(e) => this.onBedsByStationChanged(station.id, e.target.value)}/>
                            </Grid>
                        </>)}

                        <Grid item xs={3}>
                            <Typography>
                                <FormattedMessage id="cockpit.statistics.baseData.dialog.careDays"/>
                            </Typography>
                        </Grid>
                        <Grid item xs={9} style={{display: "flex", justifyContent: "flex-end"}}>
                            <TextField
                                id={"care-days-text-field"}
                                maxLength={8}
                                value={this.state.careDays}
                                variant="outlined"
                                onChange={(e) => this.onCareDaysChanged(e.target.value)}/>
                        </Grid>
                        
                        <Grid item xs={3}>
                            <Typography>
                                <FormattedMessage id="cockpit.statistics.baseData.dialog.inpatientCases"/>
                            </Typography>
                        </Grid>
                        <Grid item xs={9} style={{display: "flex", justifyContent: "flex-end"}}>
                            <TextField
                                id={"inpatientCases-text-field"}
                                maxLength={8}
                                value={this.state.inpatientCases}
                                variant="outlined"
                                onChange={(e) => this.onInpatientCasesChanged(e.target.value)}/>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography>
                                <FormattedMessage id="cockpit.statistics.baseData.dialog.workWearPeople"/>
                            </Typography>
                        </Grid>
                        <Grid item xs={9} style={{display: "flex", justifyContent: "flex-end"}}>
                            <TextField
                                id={"workWearPeople-text-field"}
                                maxLength={8}
                                value={this.state.workWearPeople}
                                variant="outlined"
                                onChange={(e) => this.onWorkWearPeopleChanged(e.target.value)}/>
                        </Grid>

                    </Grid>}/>

            </>
        );
    }
}

export default injectIntl(withSnackbar(BaseDataDialog));
