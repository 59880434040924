import React, {Component}  from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';

import {uploadImage, deleteImage, getAsync, putAsyncCatch, postAsync} from '../../../services/BackendService';
import {createErrorMessage} from '../../../util/Util';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import '../../apps/App.css';

import {withSnackbar} from 'notistack';
import {GeneralContext} from "../../contexts/GeneralContext";
import {withRouter} from "react-router-dom";
import ArticleDescription from './ArticleDescription';
import ArticleCategories from './ArticleCategories';
import ArticleImages from './ArticleImages';
import ArticleProfiles from './ArticleProfiles';
import ArticleValidation from './ArticleValidation';
import ArticleFilterCategories from "./ArticleFilterCategories";
import {ARTICLE_CONFIGURATION_OVERVIEW_ROUTE} from "../../../util/Constants";

class ArticleConfigurator extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        const article = props.location.state?.article;
        this.state = {
            id: article?.id,
            version: article?.version ?? 0,
            category: article?.category,
            subcategory: article?.subcategory ?? article?.subCategory,
            articleCategory: article?.articleCategory,
            description: article?.description,
            images: article?.images ?? [],
            filterCategories: article?.filterCategories ?? [],
            createAnotherArticle: false,
            profileIds: [],
            allProfiles: []
        };
    }

    async componentDidMount() {
        this.initializeFilterCategories(this.props.location?.state?.article);
        await this.loadAllProfiles();
    }

    componentDidUpdate() {
        if (this.state.id === null || this.state.id === undefined) {
            const hasUnsavedChanges = !this.areAllFieldsEmpty();
            this.context.setUnsavedChanges(!!hasUnsavedChanges);
        }
    }

    resetState = async() => {
        this.setState({
            id: null,
            version: 0,
            category: "",
            subcategory: "",
            articleCategory: "",
            description: "",
            images: [],
            filterCategories: [],
            createAnotherArticle: this.state.createAnotherArticle,
            profileIds: [],
            allProfiles: []
        });
        this.initializeFilterCategories(null);
    }

    initializeFilterCategories = (article) => {
        let filterCategories = [];
        this.context.appData.filterCategoryTypes?.forEach(type => {
            const filterCategory = article?.filterCategories?.find(fc => fc.filterCategoryType === type);
            if (filterCategory) {
                filterCategories.push(filterCategory);
            } else {
                filterCategories.push({
                    id: null,
                    articleConfigurationId: article?.id,
                    filterCategoryType: type,
                    isMultipleChoice: false,
                    filterOptions: []
                });
            }
        });
        this.setState({filterCategories});
    }

    /// REQUESTS

    loadAllProfiles = async() => {
        let response = await getAsync("/profiles");
        if (response?.status === 200) {
            const article = this.props.location.state?.article;
            let profileIds = [];
            if (article?.id !== null && article?.id !== undefined) {
                profileIds = response.data?.filter(p => p.articleConfigurationIds?.includes(article.id)).map(p => p.id);
            }
            this.setState({allProfiles: response.data, profileIds});
        } else if ([401, 403].includes(response?.status)) {
            await this.context.logout();
        }
    }

    saveOrUpdateArticleConfiguration = async() => {
        const articleConfiguration = {
            id: this.state.id,
            version: this.state.version,
            category: this.state.category,
            subCategory: this.state.subcategory,
            articleCategory: this.state.articleCategory,
            description: this.state.description,
            filterCategories: this.state.filterCategories.filter(fc => !!fc.filterOptions?.length)
        }

        const isEditing = articleConfiguration.id !== null && articleConfiguration.id !== undefined;

        const articleResponse = await postAsync("/article/configuration", articleConfiguration);

        if (articleResponse?.status === 200 && articleResponse.data) {
            const articleConfigurationId = articleResponse.data.id;
            const version = articleResponse.data.version;

            if (isEditing) {
                await this.deleteImages();
                await this.uploadImagesForExistingArticle(version);
            } else {
                await this.uploadImagesForNewArticle(articleConfigurationId, version);
            }

            await putAsyncCatch(this.context, "/profile/article", {articleConfigurationId, profileIds: this.state.profileIds}, this.props);

            this.context.setUnsavedChanges(false);

            if (isEditing) {
                this.props.history.goBack();
            } else if (this.state.createAnotherArticle) {
                await this.resetState();
                window.scrollTo(0, 0);
            } else {
                this.props.history.push(ARTICLE_CONFIGURATION_OVERVIEW_ROUTE);
            }
        } else if ([401, 403].includes(articleResponse?.status)) {
            await this.context.logout();
        } else if (articleResponse?.status === 409) {
            createErrorMessage(this.props.intl.formatMessage({id: "articleConfiguration.duplicateKey.error"}), this.props);
        } else {
            createErrorMessage(this.props.intl.formatMessage({id: "articleConfiguration.error"}), this.props);
        }
    }

    deleteImages = async() => {
        const listToDelete = this.props.location.state.article.images.map(imageVo => imageVo.image).filter(image => !this.state.images.find(currentImage =>
            currentImage?.image?.parentId === image.parentId
            && currentImage?.image?.filename === image.filename
            && currentImage?.image?.folder === image.folder));
        if (!listToDelete || listToDelete.length === 0) {
            return;
        }
        await Promise.all(listToDelete.map(async(image) => {
            await this.deleteArticleImage(image);
            return true;
        }));
    }

    uploadImagesForNewArticle = async(articleConfigurationId, version) => {
        if (!this.state.images || this.state.images.length === 0) {
            return;
        }
        for (let imageVo of this.state.images) {
            await this.uploadImage(imageVo, articleConfigurationId, version);
            version++;
        }
    }

    uploadImagesForExistingArticle = async(version) => {
        if (!this.state.images || this.state.images.length === 0) {
            return;
        }
        for (let imageVo of this.state.images) {
            if (!imageVo?.image?.url) {
                await this.uploadImage(imageVo, this.state.id, version);
                version++;
            }
        }
    }

    uploadImage = async(imageVo, articleConfigurationId, version) => {
        const path = '/article/image/' + articleConfigurationId + "/" + version;
        const imageResponse = await uploadImage(path, imageVo);
        if (imageResponse?.status !== 200) {
            createErrorMessage("Fehler beim Hochladen des Artikelbildes", this.props);
        }
    }

    deleteArticleImage = async(image) => {
        const path = '/article/image/' + image.parentId + "/" + image.filename;
        const response = await deleteImage(path);
        if (response?.status !== 200) {
            createErrorMessage("Fehler beim Löschen des Artikelbilds", this.props);
        }
    }

    /// STATE CHANGES

    handleEditUnsavedChanges = () => {
        if (this.state.id !== null && this.state.id !== undefined) {
            this.context.setUnsavedChanges(true);
        }
    }

    handleDescriptionChanged = (newDescription) => {
        this.handleEditUnsavedChanges();
        this.setState({description: newDescription});
    }

    handleCategoryChanged = (newCategory) => {
        this.handleEditUnsavedChanges();
        this.setState({category: newCategory});
    }

    handleSubCategoryChanged = (newSubCategory) => {
        this.handleEditUnsavedChanges();
        this.setState({subcategory: newSubCategory});
    }

    handleArticleCategoryChanged = (newArticleCategory) => {
        this.handleEditUnsavedChanges();
        this.setState({articleCategory: newArticleCategory});
    }

    handleFilterCategoryOptionsChanged = (filterCategoryType, newOptions) => {
        this.handleEditUnsavedChanges();
        let newFilterCategory = this.state.filterCategories.find(fc => fc.filterCategoryType === filterCategoryType);
        newFilterCategory.filterOptions = newOptions;
        let newFilterCategories = this.state.filterCategories.map(currentFilterCategory => currentFilterCategory.filterCategoryType === filterCategoryType
            ? newFilterCategory : currentFilterCategory);
        this.setState({filterCategories: newFilterCategories});
    }

    handleFilterCategoryMultipleChoiceChanged = (filterCategoryType, newValue) => {
        this.handleEditUnsavedChanges();
        let newFilterCategory = this.state.filterCategories.find(fc => fc.filterCategoryType === filterCategoryType);
        newFilterCategory.isMultipleChoice = newValue;
        let newFilterCategories = this.state.filterCategories.map(currentFilterCategory => currentFilterCategory.filterCategoryType === filterCategoryType
            ? newFilterCategory : currentFilterCategory);
        this.setState({filterCategories: newFilterCategories});
    }

    handleImagesChanged = (newImageList) => {
        this.handleEditUnsavedChanges();
        this.setState({images: newImageList});
    }

    handleProfileChanged = (profileIds) => {
        this.handleEditUnsavedChanges();
        this.setState({profileIds: profileIds});
    }

    validateMandatoryFields = () => {
        return !this.state.description || !this.state.category || !this.state.subcategory || !this.state.articleCategory || !this.hasNonEmptyFilterCategory();
    }

    areAllFieldsEmpty = () => {
        return !this.state.description && !this.state.category && !this.state.subcategory && !this.state.articleCategory
            && !this.state.images.length && !this.state.profileIds.length && !this.hasNonEmptyFilterCategory();
    }

    hasNonEmptyFilterCategory = () => {
        return !!this.state.filterCategories?.filter(fc => !!fc.filterOptions?.length)?.length;
    }

    render() {
        const isEditing = this.state.id !== null && this.state.id !== undefined;

        return (
            <>
                <Typography variant="h1">
                    {isEditing
                        ? <FormattedMessage id="articleConfigurator.title.h1.editmode"/> 
                        : <FormattedMessage id="articleConfigurator.title.h1"/>}
                </Typography>

                <Typography variant="subtitle1">
                    {isEditing
                        ? <FormattedMessage id="articleConfigurator.title.h2.editmode"/> 
                        : <FormattedMessage id="articleConfigurator.title.h2"/>}
                </Typography>

                <Grid className="parent">

                    <ArticleCategories
                        key={this.state.category + this.state.subcategory + this.state.articleCategory}
                        articleCategoryToEdit={this.props.location.state?.article?.articleCategory}
                        articleCategory={this.state.articleCategory}
                        category={this.state.category}
                        subcategory={this.state.subcategory}
                        handleCategoryChanged={this.handleCategoryChanged}
                        handleSubCategoryChanged={this.handleSubCategoryChanged}
                        handleArticleCategoryChanged={this.handleArticleCategoryChanged}/>

                    <ArticleDescription
                        description={this.state.description}
                        handleDescriptionChanged={this.handleDescriptionChanged}/>

                    <ArticleFilterCategories
                        key={"filter-categories-" + this.state.filterCategories?.length + this.state.filterCategories?.map(fc => fc.isMultipleChoice)}
                        filterCategories={this.state.filterCategories}
                        isValid={this.hasNonEmptyFilterCategory()}
                        articleConfigurationId={this.state.id}
                        handleFilterCategoryOptionsChanged={this.handleFilterCategoryOptionsChanged}
                        handleFilterCategoryMultipleChoiceChanged={this.handleFilterCategoryMultipleChoiceChanged}/>

                    <ArticleImages
                        key={"article-images-length-" + this.state.images?.length}
                        images={this.state.images}
                        handleImagesChanged={this.handleImagesChanged}/>

                    <ArticleProfiles
                        key={"profile-ids-length-" + this.state.profileIds?.length}
                        profileIds={this.state.profileIds}
                        handleProfileChanged={this.handleProfileChanged}/>

                </Grid>

                <ArticleValidation
                    validateMandatoryFields={this.validateMandatoryFields}/>

                <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-end">

                    <Grid item>

                        <Button 
                            variant="contained" 
                            color="primary"
                            disabled={this.validateMandatoryFields()}
                            onClick={() => this.saveOrUpdateArticleConfiguration()}
                            style={{marginTop: "10px"}}>
                            <FormattedMessage id="commons.save.button"/>
                        </Button>

                    </Grid>

                    <Grid item>

                        {isEditing &&
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick={() =>  this.props.history.goBack()}
                                style={{marginTop: "10px"}}>
                                <FormattedMessage id="commons.cancel.button"/>
                            </Button>}

                    </Grid>

                    {!isEditing &&

                        <Grid item>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.createAnotherArticle}
                                        color="primary"
                                        onChange={() => this.setState({createAnotherArticle: !this.state.createAnotherArticle})}
                                        name="createAnotherArticle"/>
                                }
                                label={<Typography color="textSecondary">
                                    <FormattedMessage id="articleConfigurator.createAnotherArticle"/>
                                </Typography>}/>

                        </Grid>}

                </Grid>

            </>
        );
    }
}

export default injectIntl(withSnackbar(withRouter(ArticleConfigurator)));
