import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Divider, Grid} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';

import '../../../css/OrgaUnitCard.css';

import {texisionBlueGrayBright, texisionFontColorDark} from '../../../util/ColorTheme';
import {GeneralContext} from "../../contexts/GeneralContext";
import ValidationButton from "../../validation/ValidationButton";
import {PROJECT_TASKS} from "../../../util/Constants";
import ValidationIcon from "../../validation/ValidationIcon";
import {isProjectTaskCompleted} from "../../../services/ProjectService";


class BusinessUnitPaper extends Component {

    static contextType = GeneralContext;

    render() {

        const streetAndNumber = this.props.businessUnit.streetAndNumber;
        let codeAndCity = "";
        if (this.props.businessUnit.zipCode) {
            codeAndCity += this.props.businessUnit.zipCode;
        }
        if (this.props.businessUnit.city) {
            if (this.props.businessUnit.zipCode) {
                codeAndCity += " ";
            }
            codeAndCity += this.props.businessUnit.city;
        }
        const country = this.props.businessUnit.country;

        const isBusinessUnitComplete = isProjectTaskCompleted(this.context, PROJECT_TASKS.BUSINESS_UNIT, this.props.businessUnit.id);

        return (
            <div style={{position: "relative", display: "flex"}}>
                <Paper elevation={1} className="orgaCard">

                    <Grid container direction="column" alignItems="center" justifyContent="center">

                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                className="orgaCardTopArea"
                                style={!this.props.imageUrl ? {backgroundColor: texisionBlueGrayBright, color: texisionFontColorDark} : {}}>

                                <Grid item>
                                    {this.props.imageUrl ?
                                        <div className="orgaCardImg">
                                            <img src={this.props.imageUrl} width="300px" alt="Bild der Einheit"/>
                                        </div> :
                                        <Icon className="icon-building" style={{fontSize: "42pt"}}/>}
                                </Grid>
                            </Grid>

                            <Grid container direction="column">

                                <Grid item className="orgaCardSubArea">
                                    <Typography variant="h5">{this.props.name}</Typography>
                                </Grid>

                                <Grid item className="orgaCardAddress">

                                    {streetAndNumber && <Typography variant="h5">
                                        {streetAndNumber}
                                    </Typography>}

                                    {codeAndCity && <Typography variant="h5">
                                        {codeAndCity}
                                    </Typography>}

                                    {country && <Typography variant="h5">
                                        {this.props.intl.formatMessage({id: "constants.country." + country.toLowerCase()})}
                                    </Typography>}

                                </Grid>

                                <Grid className="orgaCardDivider">
                                    <Divider/>
                                </Grid>

                                <Grid item className="orgaCardActionArea">

                                    <Tooltip title={this.props.intl.formatMessage({id: "commons.edit.button"})}>

                                        <IconButton
                                            edge="end"
                                            aria-label="edit"
                                            disabled={this.props.disabled}
                                            onClick={() => this.props.handleEdit(this.props.businessUnit)}>
                                            <Icon className="icon-edit" style={{fontSize: "21pt"}}/>
                                        </IconButton>

                                    </Tooltip>

                                    <Box ml={1} mr={1} component="span"/>

                                    <Tooltip title={this.props.intl.formatMessage({id: "commons.delete.button"})}>
                                <span>
                                    <IconButton
                                        aria-label="delete"
                                        disabled={this.props.disabled}
                                        onClick={() => this.props.handleDelete(this.props.businessUnit)}>
                                        <Icon className="icon-trash" style={{fontSize: "21pt"}}/>
                                    </IconButton>
                                </span>
                                    </Tooltip>

                                </Grid>

                                <Grid className="orgaCardDivider">
                                    <Divider/>
                                </Grid>

                                <Grid item className="orgaCardActionArea" style={{marginTop: 10}}>
                                    <ValidationButton
                                        completed={isBusinessUnitComplete}
                                        projectId={this.props.businessUnit.projectId}
                                        projectTask={PROJECT_TASKS.BUSINESS_UNIT}
                                        objectId={this.props.businessUnit.id}/>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>
                </Paper>

                {!this.props.disabled && <div style={{position: "absolute", top: -7, right: -7}}>

                    <ValidationIcon complete={isBusinessUnitComplete} size={30}/>

                </div>}
            </div>
        )
    }
}

export default injectIntl(BusinessUnitPaper);
