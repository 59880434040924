import React, {Component} from 'react';
import {Typography, Grid, TextField, InputLabel, Select} from '@material-ui/core';
import {isEmptyOrBlank} from '../../../util/Util';

import {FormattedMessage, injectIntl} from 'react-intl';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import '../../../css/EquipmentOverview.css';
import {GeneralContext} from "../../contexts/GeneralContext";
import {CATEGORY_WORK_WEAR} from "../../../util/Constants";
import ChipSelector from "../../uiLibrary/ChipSelector";
import Spacer from "../../uiLibrary/Spacer";
import {createOrGetArticle} from "../../../services/ArticleConfigurationService";


class ProfessionalGroupCreateDialog extends Component {

    static contextType = GeneralContext;
    
    constructor(props) {
        super(props);
        if (this.props.professionalGroup) {
            this.state = {
                id: this.props.professionalGroup.id, 
                name: this.props.professionalGroup.name,
                professionalGroupType: this.props.professionalGroup.type,
                gender: this.props.professionalGroup.gender,
                businessUnitId: this.props.professionalGroup.businessUnitId,
                articleIds: this.props.professionalGroup.articleIds,
                version: this.props.professionalGroup.version
            }
        } else {
            this.state = {
                id: null, 
                name: "",
                professionalGroupType: "",
                gender: "",
                businessUnitId: null,
                articleIds: [],
                version: 0,
                subcategory: null
            }
        }
    }

    createOrUpdateProfessionalGroup = async () => {

        let articleIds = !!this.state.subcategory
            ? await this.loadArticleIdsBySubcategory()
            : this.state.articleIds;

        let result = await this.props.createOrUpdateProfessionalGroup({
            id: this.state.id, 
            name: this.state.name, 
            professionalGroupType: this.state.professionalGroupType,
            gender: this.state.gender, 
            businessUnitId: this.state.businessUnitId, 
            articleIds: articleIds,
            version: this.state.version
        });

        if (result) {
            this.resetState();
            this.props.showDialog(false);
        }
    }

    hideDialog = () => {
        this.resetState();
        this.props.showDialog(false);
    }

    resetState = () => {
        this.setState({id: null, name: "", professionalGroupType: "", gender: "", version: 0});
    }

    getSubcategories = () => {
        return Array.from(new Set(this.props.articleConfigurations
            .filter(a => a.category === CATEGORY_WORK_WEAR)
            .map(a => a.subCategory)));
    }

    loadArticleIdsBySubcategory = async() => {
        return await Promise.all(this.props.articleConfigurations
            .filter(a => a.category === CATEGORY_WORK_WEAR)
            .filter(a => a.subCategory === this.state.subcategory)
            .map(configuration => createOrGetArticle(this.context, this.props, configuration)))
            .then(articles => articles.map(a => a.id))
    }

    render() {
        const mode = this.props.professionalGroup ? "edit" : "create";
        return (
            <div>
                <TexisionDialog
                    open={this.props.open}
                    hasNoTitle={true}
                    actionId="commons.save.button"
                    cancelId="commons.cancel.button"
                    onAction={() => this.createOrUpdateProfessionalGroup()}
                    onCancel={() => this.hideDialog()}
                    actionDisabled={isEmptyOrBlank(this.state.name) || isEmptyOrBlank(this.state.professionalGroupType) || isEmptyOrBlank(this.state.gender)}
                    content={<Grid container spacing={2}>
                        <Grid item xs={12} className="equipmentCreateTitle">
                            <Typography variant="h2"><FormattedMessage id={"professionalGroup.dialog." + mode + ".title"}/></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography><FormattedMessage id={"professionalGroup.dialog." + mode + ".subtitle"}/></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Spacer size={10} />
                        </Grid>
                        {!this.state.id &&
                            <Grid item xs={12}>
                                <ChipSelector
                                    label={this.props.intl.formatMessage({id: "professionalGroup.dialog.presets"})}
                                    options={this.getSubcategories()}
                                    onChange={(subcategory) => this.setState({
                                        subcategory,
                                        name: !subcategory ? ""
                                            : this.props.intl.formatMessage({id: "constants.SubCategory." + subcategory})
                                    })}
                                    render={(subcategory) => this.props.intl.formatMessage({id: "constants.SubCategory." + subcategory})}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TextField
                                className="equipmentCreateInput"
                                id="description"
                                value={this.state.name}
                                label={this.props.intl.formatMessage({id: "professionalGroup.dialog.name"})}
                                variant="outlined"
                                disabled={!!this.state.subcategory}
                                inputProps={{maxLength: 300}}
                                onChange={(e) => this.setState({name: e.target.value})}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="category-native-simple">
                                <FormattedMessage id="professionalGroup.dialog.referenceSystem"/>
                            </InputLabel>
                            <Select
                                className="equipmentCreateInput"
                                native
                                value={this.state.professionalGroupType}
                                onChange={(e) => this.setState({professionalGroupType: e.target.value})}
                                inputProps={{name: 'category', id: 'category-native-simple'}}>
                                <option key="DEFAULT_NONE" value=""/> 
                                {this.context.appData.professionalGroupTypes.map(type => {
                                    return <option key={type} value={type}>
                                        {this.props.intl.formatMessage({id: "constants.ProfessionalGroupType." + type})}
                                    </option>;
                                }) }
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="gender-native-simple">
                                <FormattedMessage id="professionalGroup.dialog.gender"/>
                            </InputLabel>
                            <Select
                                className="equipmentCreateInput"
                                native
                                value={this.state.gender}
                                onChange={(e) => this.setState({gender: e.target.value})}
                                inputProps={{name: 'gender', id: 'gender-native-simple'}}>
                                <option aria-label="None" value=""/>
                                {// Do not render the NONE gender in this use case here.
                                this.context.appData.genders.filter(gender => gender !== "NONE").map(gender => {
                                    return <option key={gender} value={gender}>
                                        {this.props.intl.formatMessage({id: "constants.Gender." + gender})}
                                    </option>;
                                })}
                            </Select>
                        </Grid>
                    </Grid>}/>
            </div>
        );
    }
}

export default injectIntl(ProfessionalGroupCreateDialog);
