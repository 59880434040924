import {getAsyncCatch, putAsyncCatch} from "./BackendService";

export async function getQuantities(context, props, deliveryAddressId) {
    const quantities = await getAsyncCatch(context, "/quantity/" + deliveryAddressId, props);
    return quantities ? quantities : [];
}

export async function saveQuantities(context, props, projectId, deliveryAddressId, quantities, hideSuccess) {
    let resultList = await putAsyncCatch(context, "/quantity/" + projectId + "/" + deliveryAddressId, quantities, props, hideSuccess);
    return resultList ? resultList : [];
}