import React, {Component} from "react";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {white} from "../../../../util/ColorTheme";
import {FormattedMessage, injectIntl} from "react-intl";
import {Divider, Grid} from "@material-ui/core";
import {getRow} from "../../../../util/TableUtil";
import {formatAmount, formatPrice} from "../../../../util/Util";
import {getActiveOperation} from "../../../../services/OperationService";

class InvoiceStatisticCategoryDetails extends Component {

    static contextType = GeneralContext;

    getChildren = (statisticNode) => {
        return <div style={{textAlign:"right"}}>
            {statisticNode?.children?.map(child => {
                    return <div>{child.description}</div>
            })}
        </div>
    }

    render() {

        const {statisticNode, statisticItem} = this.props;
        const currency = " " + getActiveOperation(this.context).activeProject.currency;

        return (
            <div style={{backgroundColor: white}}>

                <div style={{marginLeft: 25, marginRight: 25, paddingTop: 25}}>
                    <h2>
                        <FormattedMessage id="cockpit.statistics.selectedCategory.title"/>
                    </h2>
                </div>

                {!!statisticItem
                    ? <Grid container alignItems="flex-start" style={{width: "100%", padding: 25, ...this.props.style}}>
                        <>
                            {getRow("cockpit.statistics.selectedCategory.description", statisticItem.description)}

                            <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
                                <Divider orientation="horizontal"/>
                            </Grid>

                            {getRow("priceSheet.column.priceTotal",  formatPrice(statisticItem.priceTotal) + currency)}
                            {getRow("priceSheet.column.pricePerCase",  formatPrice(statisticItem.pricePerCase)  + currency)}
                            {getRow("priceSheet.column.pricePerDay",  formatPrice(statisticItem.pricePerDay)  + currency)}
                            {getRow("priceSheet.column.price",  formatPrice(statisticItem.price)  + currency)}
                            {getRow("priceSheet.column.amount", formatAmount(statisticItem.amount))}
                            {getRow("priceSheet.column.amountPerCase", formatAmount(statisticItem.amountPerCase))}
                            {getRow("priceSheet.column.amountPerDay", formatAmount(statisticItem.amountPerDay))}

                            <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
                                <Divider orientation="horizontal"/>
                            </Grid>

                            {getRow("cockpit.statistics.selectedCategory.children", this.getChildren(statisticNode))}

                        </>
                      </Grid>

                    : <div style={{width: "90%", height: 200, padding: 40, display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <FormattedMessage id="cockpit.statistics.itemDetails.info"/>
                      </div>}
            </div>);
    }

}
export default injectIntl(InvoiceStatisticCategoryDetails);