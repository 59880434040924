import { Collapse, List } from "@material-ui/core";
import React, { Component } from "react";
import {
    BILL_OF_QUANTITIES_EXPORT_ROUTE,
    BUSINESS_UNIT_OVERVIEW_ROUTE, COCKPIT_CONTRACT_DOCUMENTS_ROUTE, COCKPIT_PRICE_SHEET_ROUTE,
    CONTRACT_ROUTE,
    CONTRACT_STATUS_ROUTE,
    PROJECT_TASKS, TENDER_COMMUNICATION_ROUTE
} from "../../../util/Constants";
import { GeneralContext } from "../../contexts/GeneralContext";
import NavBarItem from "../shared/NavBarItem";
import NavBarUnit from "../shared/NavBarUnit";

class Operation extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            activeContractOpen: false,
            workingStateOpen: false
        }
    }

    handleActiveContractOpen = () => {
        this.setState({activeContractOpen: !this.state.activeContractOpen});
    }

    handleWorkingStateOpen = () => {
        this.setState({workingStateOpen: !this.state.workingStateOpen});
    }

    render() {
        if (!this.props.operation) {
            return <div/>;
        }

        const hasValidActiveProject = !!this.props.operation.activeProject?.businessUnits?.units?.length;
        const workingStateTitle = hasValidActiveProject ? "navBar.workingState.title" : "navBar.cockpitImport.title";

        return (
            <>

                <NavBarItem
                    level={0}
                    title="navBar.contractStatus.title"
                    pathname={CONTRACT_STATUS_ROUTE}
                    icon="icon-overview"/>

                {hasValidActiveProject && <NavBarItem
                    level={0}
                    open={this.state.activeContractOpen}
                    onClick={() => this.handleActiveContractOpen()}
                    title="navBar.activeContract.title"
                    icon="icon-note-hook"/>}

                {hasValidActiveProject && <Collapse in={this.state.activeContractOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        <NavBarItem
                            level={1}
                            icon="icon-note-pencil"
                            pathname={BILL_OF_QUANTITIES_EXPORT_ROUTE}
                            projectId={this.props.operation.activeProject.id}/>

                        <NavBarItem
                            level={1}
                            icon="icon-documents"
                            pathname={TENDER_COMMUNICATION_ROUTE}
                            projectId={this.props.operation.activeProject.id}/>

                         <NavBarItem
                            level={1}
                            icon="icon-note-pencil"
                            pathname={CONTRACT_ROUTE}
                            projectId={this.props.operation.activeProject.id}/>

                        <NavBarItem
                            level={1}
                            icon="icon-note-euro"
                            pathname={COCKPIT_PRICE_SHEET_ROUTE}
                            projectId={this.props.operation.activeProject.id}/>

                        {this.props.operation.activeProject.businessUnits.units
                        .sort((a,b) => a.name.localeCompare(b.name)).map(activeUnit => <NavBarUnit
                            key={activeUnit.id} 
                            projectId={this.props.operation.activeProject.id} 
                            unit={activeUnit}/>)}

                    </List>
                </Collapse>}

                {this.props.operation.workingProject && <>

                    <NavBarItem
                        level={0}
                        open={this.state.workingStateOpen}
                        onClick={() => this.handleWorkingStateOpen()}
                        title={workingStateTitle}
                        icon="icon-edit"/>
                        
                    <Collapse in={this.state.workingStateOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                            <NavBarItem
                                level={1}
                                icon="icon-note-pencil"
                                pathname={COCKPIT_CONTRACT_DOCUMENTS_ROUTE}
                                projectId={this.props.operation.workingProject.id}/>

                            <NavBarItem
                                level={1}
                                icon="icon-note-pencil"
                                pathname={BILL_OF_QUANTITIES_EXPORT_ROUTE}
                                projectId={this.props.operation.workingProject.id}/>

                            <NavBarItem
                                level={1}
                                icon="icon-documents"
                                pathname={TENDER_COMMUNICATION_ROUTE}
                                projectId={this.props.operation.workingProject.id}/>

                            <NavBarItem
                                level={1}
                                icon="icon-note-pencil"
                                pathname={CONTRACT_ROUTE}
                                projectTask={PROJECT_TASKS.CONTRACT}
                                objectId={this.props.operation.workingProject.id}
                                singleValidation={true}
                                projectId={this.props.operation.workingProject.id}/>

                            <NavBarItem
                                level={1}
                                title="navBar.offerPriceSheet.title"
                                icon="icon-note-euro"
                                pathname={COCKPIT_PRICE_SHEET_ROUTE}
                                projectId={this.props.operation.workingProject.id}/>

                            <NavBarItem
                                level={1}
                                pathname={BUSINESS_UNIT_OVERVIEW_ROUTE}
                                icon="icon-cog"
                                projectId={this.props.operation.workingProject.id}/>

                            {this.props.operation.workingProject.businessUnits.units
                            .sort((a,b) => a.name.localeCompare(b.name)).map(workingUnit => <NavBarUnit 
                                key={workingUnit.id}
                                projectId={this.props.operation.workingProject.id} 
                                unit={workingUnit}/>)}

                        </List>
                    </Collapse>

                </>}

            </>
        );
    }
}

export default Operation;
