import PropTypes from 'prop-types';
import {Button, Card, CardContent, Divider, Grid, TextField, Tooltip, Typography} from "@material-ui/core";
import {formatDate} from "../../util/DocumentUtil";
import {isBidder, isOfferUser} from "../../services/UserService";
import {primaryColor} from "../../util/ColorTheme";
import {MESSAGE_TYPES} from "../../util/Constants";
import {FormattedMessage, injectIntl} from "react-intl";
import React from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {readMessage} from "../../services/MessageService";
import {createSuccessMessage, isTender} from "../../util/Util";
import {withSnackbar} from "notistack";
import MessageFilePreview from "./MessageFilePreview";
import MessageDocument from "./MessageDocument";

class MessageCard extends React.Component {

    static contextType = GeneralContext;

    markAsRead = async(messageId) => {
        const readId = await readMessage(this.context, this.props, messageId);
        if (readId !== null && readId !== undefined && readId !== false) {
            createSuccessMessage(this.props.intl.formatMessage({id: "bidderCommunication.markAsRead.success"}), this.props);
            await this.props.loadData();
        }
    }

    answerButton = (id, hasAnswer) => {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={() => this.props.onRespond(id)}>
                {this.props.intl.formatMessage({id: hasAnswer ? "bidderCommunication.responseMore.button" : "bidderCommunication.response.button"})}
            </Button>
        );
    }

    answerComponent = (parentMessage) => {
        const files = this.props.answerFiles;
        return (
            <Grid container alignItems="center">

                <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center" spacing={2}>

                        <Grid item>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => this.props.onCancel()}>
                                {this.props.intl.formatMessage({id: "commons.cancel.button"})}
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!this.props.newAnswerText || this.props.isUploading}
                                onClick={() => {
                                    if (this.props.numberOfOffers > 0) {
                                        this.props.showWithdrawOffersWarning(parentMessage.id, parentMessage.subject, false);
                                    } else {
                                        this.props.showPublicAnswerWarning(parentMessage.id, parentMessage.subject);
                                    }
                                }}>
                                {this.props.intl.formatMessage({id: "bidderCommunication.response.public.button"})}
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!this.props.newAnswerText || this.props.isUploading}
                                onClick={() => {
                                    if (this.props.numberOfOffers > 0) {
                                        this.props.showWithdrawOffersWarning(parentMessage.id, parentMessage.subject, true);
                                    } else {
                                        this.props.uploadPrivateAnswer(parentMessage.id, parentMessage.subject);
                                    }
                                }}>
                                {this.props.intl.formatMessage({id: "bidderCommunication.response.private.button"})}
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12} className="answerTextField">
                    <TextField
                        multiline
                        label={this.props.intl.formatMessage({id: "bidderCommunication.answer.label"})}
                        value={this.props.newAnswerText}
                        style={{width: "100%"}}
                        onChange={(e) => this.props.onAnswerChange(e.target.value)}/>
                </Grid>

                <Grid item xs={12}>
                    {files && files.length > 0 && <Grid container spacing={2}>
                        {files.map((file) => <MessageFilePreview file={file} isCreating={false} deleteFile={this.props.deleteFile}/>)}
                    </Grid>}
                </Grid>

                <Grid item xs={12}>
                    <Button
                        color="primary"
                        component="label">
                        <FormattedMessage id="bidderCommunication.document.select.button"/>
                        <input
                            type="file"
                            id="bidderCommunication-document-input"
                            accept={this.props.acceptedFileTypes}
                            style={{display: "none"}}
                            onChange={(e) => this.props.selectFile(e.target.files[0], false)}
                            onClick={(event) => {event.target.value = null}}/>
                    </Button>
                </Grid>

            </Grid>
        );
    }

    messageAuthor = (message) => {
        let name;
        if (isOfferUser(this.context.currentUser)) {
            if (message.type !== MESSAGE_TYPES.QUESTION) {
                return this.props.intl.formatMessage({id: "bidderCommunication.tenderUser.placeholder"})
                    + " " + this.props.intl.formatMessage({id: "bidderCommunication.authorSuffix"}) + ": ";
            } else if (message.text) {
                return this.props.intl.formatMessage({id: "bidderCommunication.self"}) + ":";
            } else {
                return this.props.intl.formatMessage({id: "bidderCommunication.anonymousBidder"});
            }
        } else {
            if (message.type !== MESSAGE_TYPES.QUESTION) {
                return this.props.intl.formatMessage({id: "bidderCommunication.self"}) + ":";
            } else if (message.user?.firstName) {
                name = message.user.firstName;
                if (message.user.lastName) {
                    name += " " + message.user.lastName;
                    if (message.user.company) {
                        name += " (" + message.user.company + ")";
                    }
                }
            } else if (message.user?.lastName) {
                name = message.user.lastName;
                if (message.user.company) {
                    name += " (" + message.user.company + ")";
                }
            } else {
                name = message.user.company;
            }
            return name + " " + this.props.intl.formatMessage({id : "bidderCommunication.authorSuffix"}) + ": ";
        }
    }

    answerAuthor = () => {
        if (isOfferUser(this.context.currentUser)) {
            return this.props.intl.formatMessage({id: "bidderCommunication.tenderUser.placeholder"}) + " " + this.props.intl.formatMessage({id: "bidderCommunication.authorSuffix.answer"}) + ": ";
        } else {
            return this.props.intl.formatMessage({id: "bidderCommunication.self"}) + ":";
        }
    }

    getIcon = (type) => {
        switch (type) {
            case MESSAGE_TYPES.INFORMATION:
                return <InfoOutlinedIcon className="messageIcon"/>;
            case MESSAGE_TYPES.QUESTION:
                return <HelpOutlineIcon className="messageIcon"/>;
            case MESSAGE_TYPES.ANSWER:
                return <QuestionAnswerOutlinedIcon className="messageIcon"/>;
            case MESSAGE_TYPES.NOTIFICATION:
                return <NotificationsNoneIcon className="messageIcon"/>;
            default:
                return <InfoOutlinedIcon className="messageIcon"/>;
        }
    }

    canBeMarkedAsRead = (message) => {
        let userId = this.context.currentUser.id;
        let isRead = message.readerIds.includes(userId) && message.answers?.every(a => a.readerIds.includes(userId));
        return isBidder(this.context.currentUser) && !isRead
            && [MESSAGE_TYPES.QUESTION, MESSAGE_TYPES.ANSWER, MESSAGE_TYPES.INFORMATION].includes(message.type);
    }

    render() {
        const {message, index, listLength} = this.props;

        const answers = message.answers;
        const documents = message.documents;

        return (
            <Card key={message.id} className="messageCard">

                <CardContent>

                    <Grid container justifyContent="space-between" alignItems="center">

                        <Grid item xs>
                            {formatDate(this.props.intl, message.createdAt)}
                        </Grid>

                        {this.canBeMarkedAsRead(message) && <Grid item>
                            <div style={{
                                backgroundColor: primaryColor,
                                width: 15,
                                height: 15,
                                borderRadius: "50%",
                                marginRight: 20,
                                marginBottom: 3
                            }}/>
                        </Grid>}

                        <Grid item>
                            {this.getIcon(message.type)}
                        </Grid>

                    </Grid>


                    <Grid container style={{marginBottom: 15, marginTop: 10}}>

                        <Grid item style={{marginRight: 20, height: 40, display: "flex", alignItems: "flex-end"}}>
                            <div style={{fontSize: 30}}>
                                {(listLength - index) + "."}
                            </div>
                        </Grid>

                        <Grid item xs style={{height: 38, display: "flex", alignItems: "flex-end"}}>
                            <Typography variant="h3">
                                {this.messageAuthor(message)}
                            </Typography>
                        </Grid>

                    </Grid>


                    <Typography variant="h5" className="messageSubject">
                        {message.subject}
                    </Typography>


                    <Typography variant="subtitle2" className="messageText">
                        {message.text}
                    </Typography>


                    {documents && documents.length > 0 && documents.map(d => <MessageDocument document={d}/>)}


                    {answers && answers.length > 0 && answers.map(answer => <div key={answer.id}>
                        <Divider style={{marginBottom: 20}}/>

                        <Grid container justifyContent="space-between">

                            <Grid item xs>
                                {formatDate(this.props.intl, answer.createdAt)}
                            </Grid>
                            {answer.isPrivate && <Grid item>
                                <Tooltip title={this.props.intl.formatMessage({id: "bidderCommunication.private.icon.tooltip"})}>
                                    <AdminPanelSettingsIcon className="messageIcon"/>
                                </Tooltip>
                            </Grid>}
                            <Grid item>
                                {this.getIcon(answer.type)}
                            </Grid>

                        </Grid>

                        <Typography variant="h3" className="messageAuthor">
                            {this.answerAuthor()}
                        </Typography>

                        <Typography variant="subtitle2">
                            {answer.text}
                        </Typography>

                        {answer.documents && answer.documents.length > 0 && answer.documents.map(d => <MessageDocument document={d}/>)}
                    </div>)}


                    {isTender() && message.type === MESSAGE_TYPES.QUESTION && <div>

                        <Divider style={{marginBottom: 20}}/>

                        {this.props.openedAnswerId === message.id
                            ? this.answerComponent(message)
                            : this.answerButton(message.id, answers && answers.length > 0)}

                    </div>}


                    {this.canBeMarkedAsRead(message) && <Button
                        color="primary"
                        variant="text"
                        onClick={() => this.markAsRead(message.id)}>
                        <FormattedMessage id="bidderCommunication.markRead.button"/>
                    </Button>}

                </CardContent>

            </Card>
        );
    }
}

MessageCard.propTypes = {
    openedAnswerId: PropTypes.number.isRequired,
    acceptedFileTypes: PropTypes.string.isRequired,
    message: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    listLength: PropTypes.number.isRequired,
    loadData: PropTypes.func.isRequired,
    onRespond: PropTypes.func.isRequired,
    answerFiles: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    uploadAnswer: PropTypes.func.isRequired,
    newAnswerText: PropTypes.string.isRequired,
    onAnswerChange: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    selectFile: PropTypes.func.isRequired,
    numberOfOffers: PropTypes.number.isRequired,
    isUploading: PropTypes.bool.isRequired
};

export default withSnackbar(injectIntl(MessageCard));
