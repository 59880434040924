import { Button, Grid, TextField, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {getBiddersOfTender, inviteBiddersToTender, isAdmin, isProjectAdmin} from "../../../services/UserService";
import {
    DIALOG_TYPE_ERROR,
    DIALOG_TYPE_WARNING,
    PROJECT_STATUS_IN_EDITING,
    PROJECT_STATUS_IN_PROGRESS,
    PROJECT_STATUS_IS_PUBLISHED,
    LICENSE_TYPES, PROJECT_TASKS, DIALOG_TYPE_INFO
} from "../../../util/Constants";
import { GeneralContext } from "../../contexts/GeneralContext";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import TexisionDialog from '../../uiLibrary/TexisionDialog';
import { createErrorMessage, createSuccessMessage } from "../../../util/Util";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import {getProcedure} from "../../../services/ProcedureService";
import PublishBidderSelection from "./PublishBidderSelection";
import {
    cancelEditingProject,
    getActiveProject,
    getOfferRound,
    publishProject, recallProject,
    saveEditingProject,
    startEditingProject,
    unpublishProject
} from "../../../services/ProjectService";
import {latestActivePayment} from "../../../services/PaymentService";
import {getActiveOperation} from "../../../services/OperationService";
import {loadOffers} from "../../../services/OfferService";
import {formatDate} from "../../../util/DocumentUtil";
import {isFirstDeadlineOver} from "../../../util/ProcedureUtil";

class TenderActionButtons extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props)
        this.state = {
            confirmPublishing: false,
            comment: "",
            procedure: null,
            showBidderSelectionDialog: false,
            showPublishUndoDialog: false,
            showConfirmUndoDialog: false,
            showLockedDialog: false,
            showStartEditingDialog: false,
            showSaveEditingDialog: false,
            showCancelEditingDialog: false,
            showUnusedSupportDialog: false,
            showNoAnalysisBookedDialog: false,
            selectedBidders: [],
            offers: [],
            loading: false,
        };
    }

    async componentDidMount() {
        await this.loadProcedure();
    }

    async componentDidUpdate() {
        if (this.state.procedure?.projectId !== this.props.projectId ||
            this.state.procedure?.version !== this.context.appData.procedureVersion) {
            await this.loadProcedure();
        }
    }

    loadProcedure = async() => {
        const project = getActiveProject(this.context);
        if (project && (isProjectAdmin(this.context.currentUser) || isAdmin(this.context.currentUser))) {
            const procedure = await getProcedure(this.context, this.props, project.id);
            this.setState({procedure});
            this.context.setProcedureVersion(procedure.version);
        }
    }

    isFirstRound = () => {
        return getActiveOperation(this.context)?.activeProject === null;
    }

    isComplete = () => {
        const project = getActiveProject(this.context);
        return this.context.appData.validationMap?.get(PROJECT_TASKS.PROJECT)?.get(project?.id)?.complete;
    }

    isProcedureReady = () => {
        return this.state.procedure?.firstCallForSubmissionDate < new Date().getTime();
    }

    tryPublishing = async () => {
        await this.initialize();
        if (!latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE)) {
            this.context.showNoLicenseDialog(LICENSE_TYPES.TENDER_LICENSE);
        } else if (this.context.operationEvents?.availableSupportTime) {
            this.setState({showUnusedSupportDialog: true});
        } else {
            this.setState({showBidderSelectionDialog: true, confirmPublishing: false});
        }
    }

    initialize = async() => {
        await Promise.all([
            getBiddersOfTender(this.context, this.props),
            loadOffers(this.context)
        ]).then(([selectedBidders, offers]) => {
            this.setState({selectedBidders, offers});
        });
    }

    publishForBidders = async(operation) => {
        this.setState({loading: true});
        if (!this.isFirstRound()) {
            const offerIds = this.state.offers
                .filter(o => this.state.selectedBidders.find(b => b.username === o.username))
                .map(o => o.id)
            const recallVo = {
                projectId: operation.workingProject.id,
                offerIds: offerIds
            };
            await recallProject(this.context, this.props, recallVo)
        }
        const bidderSelectionSuccess = await inviteBiddersToTender(this.context, this.props, operation.workingProject.id, this.state.selectedBidders.map(b => b.id));
        if (bidderSelectionSuccess) {
            if (this.isFirstRound()) {
                await publishProject(
                    this.context,
                    this.props,
                    operation,
                    this.onPublishSuccess,
                    this.onPublishError
                );
            } else {
                await saveEditingProject(
                    this.context,
                    this.props,
                    operation,
                    this.state.comment,
                    () => createSuccessMessage(this.props.intl.formatMessage({id: "tender.edit.save.successMessage"}), this.props),
                    () => createErrorMessage(this.props.intl.formatMessage({id: "tender.edit.save.errorMessage"}), this.props));
            }
            this.setState({showBidderSelectionDialog: false, confirmPublishing: false, loading: false, comment: "", selectedBidders: []});
        } else {
            this.setState({loading: false});
        }
    }

    onPublishSuccess = () => {
        createSuccessMessage(this.props.intl.formatMessage({id: "navBar.publish.successMessage"}), this.props);
    }

    onPublishError = () => {
        createErrorMessage(this.props.intl.formatMessage({id: "navBar.publish.errorMessage"}), this.props);
    }

    onUnpublishSuccess = () => {
        createSuccessMessage(this.props.intl.formatMessage({id: "navBar.unpublish.successMessage"}), this.props);
    }

    onUnpublishError = () => {
        createErrorMessage(this.props.intl.formatMessage({id: "navBar.publish.undo.errorMessage"}), this.props);
    }

    handleBidderSelectionChange = (select, bidder) => {
        let selectedBidders = this.state.selectedBidders;
        if (select && !selectedBidders?.find(b => b.id === bidder?.id)) {
            selectedBidders.push(bidder);
        } else if (!select) {
            selectedBidders = selectedBidders?.filter(b => b.id !== bidder?.id);
        }
        this.setState({selectedBidders});
    }

    getActionButtons = (status, operationId) => {

        const canEdit = this.state.procedure?.questionsDeadline && this.state.procedure.questionsDeadline > new Date().getTime();
        const canUndo = !isFirstDeadlineOver(this.state.procedure);

        let buttons = [];

        if (status === PROJECT_STATUS_IN_PROGRESS) {
            const offerRound = getOfferRound(this.context);
            const isComplete = this.isComplete();
            const isProcedureReady = this.isProcedureReady();
            let disabledTitle = "";
            disabledTitle = !isProcedureReady ? this.props.intl.formatMessage({id: "navBar.publish.button.disabled.tooltip2"},
                {date: formatDate(this.props.intl, this.state.procedure?.firstCallForSubmissionDate)}) : disabledTitle;
            disabledTitle = !isComplete ? this.props.intl.formatMessage({id: "navBar.publish.button.disabled.tooltip"}) : disabledTitle;


            if (!this.isFirstRound()) {
                buttons.push(
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({showUndoOfferRoundDialog: true})}
                        style={{borderRadius: 5, fontSize: 12}}>
                        {this.props.intl.formatMessage({id: "navBar.offerRound.undo"}, {offerRound: offerRound})}
                    </Button>
                );
            }

            buttons.push(
                <Tooltip title={disabledTitle}>
                    <Button
                        className={!isComplete || !isProcedureReady ? "publishButtonDisabled" : null}
                        variant="contained"
                        color="primary"
                        onClick={() => isComplete ? this.tryPublishing() : {}}
                        style={{borderRadius: 5, fontSize: 12}}>
                        {isComplete ? <RocketLaunchIcon style={{marginRight: 10, height: 20, width: 20}}/> : <div/>}
                        {this.isFirstRound()
                            ? this.props.intl.formatMessage({id: "navBar.publish.button"})
                            : this.props.intl.formatMessage({id: "navBar.offerRound.publish"}, {offerRound: offerRound})
                        }
                    </Button>
                </Tooltip>
            );

        } else if (status === PROJECT_STATUS_IS_PUBLISHED) {

            if (canUndo) {
                buttons.push(
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({showPublishUndoDialog: true})}
                        style={{borderRadius: 5, fontSize: 12}}>
                        {this.props.intl.formatMessage({id: "navBar.publish.undo.button"})}
                    </Button>
                );
            }

            if (canEdit) {
                buttons.push(
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({showStartEditingDialog: true})}
                        style={{borderRadius: 5, fontSize: 12}}>
                        {this.props.intl.formatMessage({id: "navBar.edit.start.button"})} 
                    </Button>
                );
            }

        } else if (status === PROJECT_STATUS_IN_EDITING) {

            buttons.push(
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({showCancelEditingDialog: true})}
                    style={{borderRadius: 5, fontSize: 12}}>
                    {this.props.intl.formatMessage({id: "navBar.edit.cancel.button"})} 
                </Button>
            );

            buttons.push(
                <Tooltip title={canEdit ? "" : this.props.intl.formatMessage({id: "tender.edit.deadlineOver.tooltip"})}>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!canEdit}
                            onClick={() => this.setState({showSaveEditingDialog: true})}
                            style={{borderRadius: 5, fontSize: 12}}>
                            {this.props.intl.formatMessage({id: "navBar.edit.save.button"})} 
                        </Button>
                    </div>
                </Tooltip>
            );

        }

        return buttons;
    }

    render() {

        if (!isProjectAdmin(this.context.currentUser) && !isAdmin(this.context.currentUser)) {
            return <div/>;
        }

        const project = getActiveProject(this.context);
        if (!project) {
            return <div/>;
        }

        const operation = this.context.appData.operations.find(operation => operation.activeProject?.id === project.id
            || operation.workingProject?.id === project.id);

        const actionButtons = this.getActionButtons(project.status, operation?.id);

        let key = 0;

        return (
            <>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showUnusedSupportDialog}
                    titleId="navBar.unusedSupport.dialog.title"
                    subtitleId="navBar.unusedSupport.dialog.subtitle"
                    replacements={{tender: project.name}}
                    cancelId="commons.cancel.button"
                    actionId="commons.confirm.button"
                    onCancel={() => this.setState({showUnusedSupportDialog: false})}
                    onAction={() => this.setState({showUnusedSupportDialog: false, showBidderSelectionDialog: true, confirmPublishing: false})}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    size="lg"
                    open={this.state.showBidderSelectionDialog}
                    titleId="navBar.bidderSelection.title"
                    subtitleId="navBar.bidderSelection.subtitle"
                    content={<PublishBidderSelection
                        isRepublishing={!this.isFirstRound()}
                        isLoading={this.state.loading}
                        projectName={project.name}
                        confirmPublishing={this.state.confirmPublishing}
                        changeConfirmPublishing={() => this.setState({confirmPublishing: !this.state.confirmPublishing})}
                        selectedBidders={this.state.selectedBidders}
                        onBidderSelect={(select, bidder) => this.handleBidderSelectionChange(select, bidder)}/>}
                    replacements={{tender: project.name}}
                    cancelId="commons.cancel.button"
                    actionId="navBar.publish.button"
                    onCancel={() => this.setState({
                        showBidderSelectionDialog: false, confirmPublishing: false, comment: "", selectedBidders: []
                    })}
                    onAction={() => this.publishForBidders(operation)}
                    isLoading={this.state.loading}
                    actionDisabled={!this.state.selectedBidders?.length || !this.state.confirmPublishing || this.state.loading}/>

                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showPublishUndoDialog}
                    titleId="navBar.publish.undo.dialog.title"
                    subtitleId="navBar.publish.undo.dialog.subtitle"
                    replacements={{
                        tender: project.name,
                        continueButtonName: this.props.intl.formatMessage({id: "commons.continue.button"})
                    }}
                    actionId="commons.continue.button"
                    cancelId="commons.cancel.button"
                    onCancel={() => this.setState({showPublishUndoDialog: false})}
                    onAction={() => this.setState({showPublishUndoDialog: false, showConfirmUndoDialog: true})}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showUndoOfferRoundDialog}
                    titleId="navBar.offerRound.undo.dialog.title"
                    subtitleId="navBar.offerRound.undo.dialog.subtitle"
                    cancelId="commons.cancel.button"
                    actionId="commons.okay.button"
                    onCancel={() => this.setState({showUndoOfferRoundDialog: false})}
                    onAction={async() => {
                        await cancelEditingProject(
                            this.context,
                            this.props,
                            operation?.id,
                            () => createSuccessMessage(this.props.intl.formatMessage({id: "tender.edit.cancel.successMessage"}), this.props),
                            () => createErrorMessage(this.props.intl.formatMessage({id: "tender.edit.cancel.errorMessage"}), this.props));
                        this.setState({showUndoOfferRoundDialog: false});
                    }}/>


                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showConfirmUndoDialog}
                    titleId="navBar.publish.undo.dialog.title"
                    subtitleId="navBar.publish.confirm.undo.dialog.subtitle"
                    content={<TextField
                        id="confirm-undo-textfield"
                        variant="outlined"
                        inputProps={{maxLength: 32}}
                        style={{marginTop: "55px", width: "50%"}}
                        onChange={(event) => this.setState({confirmUndoText: event.target.value})}
                    />}
                    replacements={{
                        tender: project.name,
                        confirmationKey: this.props.intl.formatMessage({id: "navBar.publish.undo.confirmationKey"}),
                        deleteButtonName: this.props.intl.formatMessage({id: "commons.delete.irrevocable.button"})
                    }}
                    actionId="commons.delete.irrevocable.button"
                    cancelId="commons.cancel.button"
                    actionDisabled={this.state.confirmUndoText !== this.props.intl.formatMessage({id: "navBar.publish.undo.confirmationKey"})}
                    onCancel={() => this.setState({showConfirmUndoDialog: false, confirmUndoText: ""})}
                    onAction={async() => {
                        await unpublishProject(
                            this.context,
                            this.props,
                            operation,
                            this.onUnpublishSuccess, 
                            this.onUnpublishError
                        );
                        this.setState({showConfirmUndoDialog: false, confirmUndoText: ""});
                    }}/>


                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showCancelEditingDialog}
                    titleId="navBar.edit.cancel.dialog.title"
                    subtitleId="navBar.edit.cancel.dialog.subtitle"
                    actionId="commons.confirm.button"
                    cancelId="commons.cancel.button"
                    onCancel={() => this.setState({showCancelEditingDialog: false})}
                    onAction={async() => {
                        await cancelEditingProject(
                            this.context,
                            this.props,
                            operation?.id,
                            () => createSuccessMessage(this.props.intl.formatMessage({id: "tender.edit.cancel.successMessage"}), this.props),
                            () => createErrorMessage(this.props.intl.formatMessage({id: "tender.edit.cancel.errorMessage"}), this.props));
                        this.setState({showCancelEditingDialog: false});
                    }}/>

                <TexisionDialog
                    type={DIALOG_TYPE_INFO}
                    open={this.state.showSaveEditingDialog}
                    titleId="navBar.edit.save.dialog.title"
                    subtitleId="navBar.edit.save.dialog.subtitle"
                    content={
                        <>
                            <br/>
                            <TextField
                                label={<FormattedMessage id="tender.edit.save.comment.placeholder"/>}
                                onChange={(e) => this.setState({comment: e.target.value})}
                                multiline
                                style={{width: '100%'}}/>
                        </>
                    }
                    actionId="navBar.bidderSelection.continue"
                    cancelId="commons.cancel.button"
                    actionDisabled={!this.state.comment || this.state.loading}
                    onCancel={() => this.setState({showSaveEditingDialog: false, comment: ""})}
                    onAction={async () => {
                        await this.initialize();
                        this.setState({showBidderSelectionDialog: true, showSaveEditingDialog: false})
                    }}/>


                <TexisionDialog
                    type={DIALOG_TYPE_WARNING}
                    open={this.state.showStartEditingDialog}
                    titleId="navBar.edit.start.dialog.title"
                    subtitleId="navBar.edit.start.dialog.subtitle"
                    actionId="commons.confirm.button"
                    cancelId="commons.cancel.button"
                    onCancel={() => this.setState({showStartEditingDialog: false})}
                    onAction={async() => {
                        await startEditingProject(
                            this.context,
                            this.props,
                            operation,
                            () => createSuccessMessage(this.props.intl.formatMessage({id: "tender.edit.start.successMessage"}), this.props),
                            () => createErrorMessage(this.props.intl.formatMessage({id: "tender.edit.start.errorMessage"}), this.props));
                        this.setState({showStartEditingDialog: false});
                    }}/>


                <TexisionDialog
                    type={DIALOG_TYPE_ERROR}
                    open={this.state.showLockedDialog}
                    titleId="navBar.publish.locked.dialog.title"
                    subtitleId="navBar.publish.locked.dialog.subtitle"
                    actionId="commons.okay.button"
                    onAction={() => this.setState({showLockedDialog: false})}/>


                <Grid 
                    container
                    alignItems="center"
                    spacing={2}
                    wrap={this.props.mobile ? null : "nowrap"}
                    style={{whiteSpace: "nowrap", marginRight: 15, marginTop: 5, marginBottom: 5, marginLeft: 20}}>
                    {actionButtons.map(button => <Grid key={"action-button-" + key++} item>{button}</Grid>)}
                </Grid>
            </>
        );
    }
}

export default withRouter(withSnackbar(injectIntl(TenderActionButtons)));
