import { Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { deleteAsyncCatch } from "../../services/BackendService";
import { DIALOG_TYPE_WARNING } from "../../util/Constants";
import { GeneralContext } from "../contexts/GeneralContext";
import TexisionDialog from "../uiLibrary/TexisionDialog";
import TextField from "@material-ui/core/TextField";

class ProjectDeleteDialog extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
          confirmText: ""
        };
    }

    deleteProject = async() => {
        const operationId = this.props.projectToDelete?.operationId;
        let responseData = await deleteAsyncCatch(this.context, "/operation/" + operationId, this.props);
        if (responseData) {
            this.context.reloadAppData();
        }
        this.setState({confirmText: ""});
        this.props.closeDeleteDialog();
    }

    render() {
        const confirmText = this.props.intl.formatMessage({id: this.props.hasLicenses ? "commons.empty.button" : "commons.delete.button"});
        return (
            <TexisionDialog
                type={DIALOG_TYPE_WARNING}
                open={this.props.showDeleteProjectDialog}
                titleId="project.delete.dialog.title"
                content={<>
                    <Typography>
                        {this.props.hasLicenses
                            ? <FormattedMessage id="project.empty.dialog" values={{confirmText}}/>
                            : <FormattedMessage id="project.delete.dialog" values={{confirmText}}/>
                        }
                    </Typography>
                    <TextField
                        id="delete-tender-text-field"
                        variant="outlined"
                        inputProps={{maxLength: 32}}
                        style={{marginTop: "25px", width: "50%"}}
                        value={this.state.confirmText}
                        onChange={(e) => this.setState({confirmText: e.target.value})}/>
                </>}
                actionId={this.props.hasLicenses ? "commons.empty.button" : "commons.delete.button"}
                cancelId="commons.cancel.button"
                onAction={() => this.deleteProject()}
                actionDisabled={this.state.confirmText !== confirmText}
                onCancel={() => {
                    this.setState({confirmText: ""});
                    this.props.closeDeleteDialog();
                }}/>
        );
    }

}

export default withSnackbar(injectIntl(ProjectDeleteDialog));
