import { 
    OFFER_STATUS_ACCEPTED,
    OFFER_STATUS_PARTICIPATING,
    OFFER_STATUS_REJECTED,
    OFFER_STATUS_SUBMITTED,
    RATING_CATEGORY,
    SUITABILITY_CRITERIA
} from "./Constants";
import { formatPrice } from "./Util";

export const ratingCriteriaSum = (ratingCriteria) => {
    if (!ratingCriteria) {
        return 0;
    }
    let sum = 0;
    for (let criterion of ratingCriteria) {
        sum += criterion.weightPercent ? parseInt(criterion.weightPercent) : 0;
    }
    return sum;
}

export const foundZeroWeighting = (ratingCriteria) => {
    if (!ratingCriteria) {
        return false;
    }
    let foundZero = false;
    for (let criterion of ratingCriteria) {
        if (!criterion.weightPercent) {
            foundZero = true;
            break;
        }
    }
    return foundZero;
}

export const canSaveRatingCriteria = (ratingCriteria) => {
    const priceWeight = ratingCriteria?.find(c => c.ratingCategory === RATING_CATEGORY.PRICE)?.weightPercent;
    return !hasMissingRatingArguments(ratingCriteria) 
        && priceWeight >= 30 
        && ratingCriteriaSum(ratingCriteria) === 100 
        && !foundZeroWeighting(ratingCriteria);
}

export const hasMissingRatingArguments = (ratingCriteria) => {
    if (!ratingCriteria) {
        return false;
    }
    let hasMissingArguments = false;
    for (let criterion of ratingCriteria) {
        if (criterion.ratingCategory !== RATING_CATEGORY.PRICE && (!criterion.ratingArguments || criterion.ratingArguments.length === 0)) {
            hasMissingArguments = true;
            break;
        }
    }
    return hasMissingArguments;
}

export const isFirstDeadlineOver = (procedure) => {
    return procedure?.firstOfferDeadline && procedure.firstOfferDeadline < new Date().getTime();
}

export const canChangeOffer = (offer, procedure) => {
    return offer?.status === OFFER_STATUS_PARTICIPATING && !isFirstDeadlineOver(procedure);
}

export const requiresSuitabilityDocuments = (procedure) => {
    return procedure?.suitabilityCriteria && procedure.suitabilityCriteria.length > 0;
}

export const getDocumentsForSuitabilityCriterion = (suitabilityDocuments, criterion) => {
    if (!suitabilityDocuments || !Object.keys(suitabilityDocuments).includes(criterion)) {
        return [];
    }
    return suitabilityDocuments[criterion];
}

export const checkSuitabilityStatus = (offerEvaluationMap, offerId, suitabilityCriteria) => {
    if (!suitabilityCriteria || suitabilityCriteria.length === 0) {
        return true;
    } else if (!offerEvaluationMap || !offerEvaluationMap[offerId] || Object.values(offerEvaluationMap[offerId]).length === 0) {
        return false;
    }
    const mapForOfferId = offerEvaluationMap[offerId];
    let isSuitable = true;
    for (let criterion of suitabilityCriteria) {
        if (!mapForOfferId[criterion] || !mapForOfferId[criterion].value) {
            isSuitable = false;
            break;
        }
    }
    return isSuitable;
}

export const isSuitableForCriterion = (offerEvaluationMap, criterion, offerId) => {
    if (!offerEvaluationMap || !offerEvaluationMap[offerId] || Object.values(offerEvaluationMap[offerId]).length === 0) {
         return false;
    }
    const mapForOfferId = offerEvaluationMap[offerId];
    if (!mapForOfferId[criterion] || !mapForOfferId[criterion].value) {
        return false;
    } else {
        return true;
    }
}

export const hasSuitableOffers = (offerEvaluationMap) => {
    return offerEvaluationMap && Object.values(offerEvaluationMap).length > 0;
}

export const getAllCriteriaDocuments = (documentsMap) => {
    if (!documentsMap || Object.keys(documentsMap).length === 0) {
        return [];
    }
    const allDocuments = [];
    for (let documents of Object.values(documentsMap)) {
        if (documents && documents.length > 0) {
            allDocuments.push(...documents);
        }
    }
    return allDocuments;
}

export const getDocumentsForRatingCategory = (ratingDocumentsMap, category) => {
    if (!ratingDocumentsMap || !Object.keys(ratingDocumentsMap).includes(category)) {
        return [];
    }
    return ratingDocumentsMap[category];
}

export const getBidderInfoTextForSuitabilityCriterion = (intl, contract, criterion, currency) => {
    if (criterion === SUITABILITY_CRITERIA.INSURANCES) {
        currency = currency ?? "EUR";
        return intl.formatMessage({id: "constants.SuitabilityCriterion.INSURANCES.tooltip"})
            .replace("{personalDamage}", contract?.personalDamageEuro ? (formatPrice(contract.personalDamageEuro) + " " + currency) : "-")
            .replace("{propertyDamage}", contract?.propertyDamageEuro ? (formatPrice(contract.propertyDamageEuro) + " " + currency) : "-")
            .replace("{processingDamage}", contract?.processingDamageEuro ? (formatPrice(contract.processingDamageEuro) + " " + currency) : "-")
            .replace("{keyLoss}", contract?.keyLossEuro ? (formatPrice(contract.keyLossEuro) + " " + currency) : "-");
    } else {
        return intl.formatMessage({id: "constants.SuitabilityCriterion." + criterion + ".tooltip"});
    }
}

export const isPriceOfferIncomplete = (priceOffer) => {
    return !priceOffer?.isComplete;
}

export const hasAllSuitabilityDocuments = (suitabilityCriteria, suitabilityDocuments) => {
    return hasDocumentsForEachCriterion(suitabilityCriteria, suitabilityDocuments);
}

export const hasAllRatingDocuments = (ratingCriteria, ratingDocuments) => {
    if (!ratingCriteria) {
        return false;
    }
    return hasDocumentsForEachCriterion(Object.values(ratingCriteria).map(c => c.ratingCategory), 
        ratingDocuments, [RATING_CATEGORY.PRICE]);
}

export const hasDocumentsForEachCriterion = (criteria, documents, exceptions = []) => {
    if (!criteria || criteria.length === 0) {
        return true;
    } else if (!documents) {
        return false;
    }
    let hasAll = true;
    for (let criterion of criteria) {
        if ((!Object.keys(documents).includes(criterion)
        || documents[criterion].length === 0) && !exceptions.includes(criterion)) {
            hasAll = false;
            break;
        }
    }
    return hasAll;
}

export const isSubmitted = (offer) => {
    return (offer?.status === OFFER_STATUS_SUBMITTED || offer?.status === OFFER_STATUS_ACCEPTED || offer?.status === OFFER_STATUS_REJECTED);
}

export const isFullyEvaluated = (offerEvaluationMap, offerId) => {
    if (!offerEvaluationMap || offerId === null || offerId === undefined) {
        return false;
    }
    const valuesLists = Object.values(offerEvaluationMap[offerId]).map(argumentMap => Object.values(argumentMap).map(r => r.value));
    let isComplete = true;
    for (let list of valuesLists) {
        for (let value of list) {
            if (value === null || value === undefined || value <= 0) {
                isComplete = false;
                break;
            }
        }
    }
    return isComplete;
}

export const getNumberOfArticles = (priceOffer) => {
    if (!priceOffer?.priceUnitMap) {
        return 0;
    }
    let sum = 0;
    for (let priceUnit of Object.values(priceOffer.priceUnitMap)) {
        // rental linen articles
        let articlePriceGroups = [];
        const offsettingToGroupMaps = Object.values(priceUnit.articleGroupMap);
        for (let offsettingToGroupMap of offsettingToGroupMaps) {
            const groups = Object.values(offsettingToGroupMap);
            articlePriceGroups = [...articlePriceGroups, ...groups];
        }
        let articlePriceItems = [];
        const priceItemMaps = articlePriceGroups.map(a => a.priceItemMap);
        for (let priceItemMap of priceItemMaps) {
            const priceItems = Object.values(priceItemMap);
            articlePriceItems = [...articlePriceItems, ...priceItems];
        }
        for (let articlePriceItem of articlePriceItems) {
            sum += articlePriceItem.articleNumbers.length;
        }

        // customer articles
        const customerPriceItemMap = priceUnit.customerArticleGroup.priceItemMap;
        for (let customerPriceItem of Object.values(customerPriceItemMap)) {
            sum += customerPriceItem.articleNumbers.length;
        }

        // residents laundry
        const residentsPriceItemMap = priceUnit.residentsLaundryGroup.priceItemMap;
        for (let residentsPriceItem of Object.values(residentsPriceItemMap)) {
            sum += residentsPriceItem.articleNumbers.length;
        }

        // special services
        const specialPriceItemMap = priceUnit.specialServiceGroup.priceItemMap;
        for (let specialPriceItem of Object.values(specialPriceItemMap)) {
            sum += specialPriceItem.articleNumbers.length;
        }

        // operating resources
        const resourcesPriceItemMap = priceUnit.operatingResourceGroup.priceItemMap;
        for (let resourcePriceItem of Object.values(resourcesPriceItemMap)) {
            sum += resourcePriceItem.articleNumbers.length;
        }
    }
    return sum;
}

export const offerDisabledTooltip = (project, offer, isPreviousProject, formatMessage) => {
    const offerStatus = offer?.status;
    if (offerStatus === OFFER_STATUS_REJECTED) {
        return formatMessage({id: "bidder.offer.rejected.tooltip"});
    } else if (offerStatus === OFFER_STATUS_ACCEPTED) {
        return formatMessage({id: "bidder.offer.accepted.tooltip"});
    } else if (isPreviousProject) {
        return formatMessage({id: "bidder.offer.previous.tooltip"});
    } else if (isFirstDeadlineOver(project?.procedure) && !isPreviousProject) {
        return formatMessage({id: "bidder.offer.firstDeadlineOver.tooltip"});
    }
}

export const offerSubmittedTooltip = (offer, formatMessage) => {
    if (offer?.status === OFFER_STATUS_SUBMITTED) {
        return formatMessage({id: "bidder.offer.submitted.tooltip"});
    }
}

export const offerIncompleteTooltip = (project, offer, priceOffer, formatMessage) => {
    const offerStatus = offer?.status;
    const procedure = project?.procedure;
    if (!hasAllSuitabilityDocuments(procedure?.suitabilityCriteria, offer?.suitabilityDocuments) && offerStatus !== OFFER_STATUS_SUBMITTED) {
        return formatMessage({id: "bidder.offerSubmit.offerButton.missingSuitabilityDocuments.tooltip"});
    } else if (!hasAllRatingDocuments(procedure?.ratingCriteria, offer?.ratingDocuments) && offerStatus !== OFFER_STATUS_SUBMITTED) {
        return formatMessage({id: "bidder.offerSubmit.offerButton.missingRatingDocuments.tooltip"});
    } else if (isPriceOfferIncomplete(priceOffer) && offerStatus !== OFFER_STATUS_SUBMITTED) {
        return formatMessage({id: "bidder.offerSubmit.offerButton.priceOfferIncomplete.tooltip"});
    }
}
